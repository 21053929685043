export enum LogAction {
  // Reservation ops
  RESERVATION_CREATE = 'reg-new',
  RESERVATION_DELETE = 'reg-delete',
  RESERVATION_MOVE_TO_BIN = 'reg-move-to-bin',
  RESERVATION_MOVE = 'reg-move-to',
  RESERVATION_MOVE_TO_FROM = 'reg-move-to-from', // old, for retrocompatibility
  RESERVATION_COPY = 'reg-copy',
  RESERVATION_ADD_STEP = 'res-add-step',
  RESERVATION_UPDATE_STEP = 'res-update-step',
  RESERVATION_DELETE_STEP = 'res-delete-step',
  RESERVATION_RESTORE = 'reg-restore',
  ADDITION_CREATE = 'addition-new',
  ADDITION_DELETE = 'addition-delete',
  ABSENCE_CREATE = 'absence-new',
  ABSENCE_DELETE = 'absence-delete',
  RESERVATION_COPY_SEASONAL = 'res-copy-seasonal',
  // Reservation Update
  RESERVATION_SET_CONTACT_ID = 'reg-contact-id',
  RESERVATION_SET_FIRST_NAME = 'reg-name',
  RESERVATION_EDIT_FIRST_NAME = 'reg-name-edit',
  RESERVATION_SET_LAST_NAME = 'reg-surname',
  RESERVATION_EDIT_LAST_NAME = 'reg-surname-edit',
  RESERVATION_SET_EMAIL = 'reg-email',
  RESERVATION_EDIT_EMAIL = 'reg-email-edit',
  RESERVATION_SET_PHONE = 'reg-phone',
  RESERVATION_EDIT_PHONE = 'reg-phone-edit',
  RESERVATION_SET_ADD_TAG = 'reg-tag-add',
  RESERVATION_SET_REMOVE_TAG = 'reg-tag-remove',
  RESERVATION_SET_NOTES = 'reg-notes',
  RESERVATION_SET_START_DATE = 'reg-date-from',
  RESERVATION_SET_END_DATE = 'reg-date-to',
  RESERVATION_SET_HALFDAY_DAY = 'reg-date-day',
  RESERVATION_SET_HALFDAY_MORNING = 'reg-date-morning',
  RESERVATION_SET_HALFDAY_AFTERNOON = 'reg-date-afternoon',
  RESERVATION_SET_SEASONAL = 'reg-date-seasonal',
  RESERVATION_SET_NOT_SEASONAL = 'reg-date-not-seasonal',
  RESERVATION_SET_STATUS_AVAILABLE = 'reg-status-available',
  RESERVATION_SET_STATUS_CONFIRMED = 'reg-status-confirmed',
  RESERVATION_SET_STATUS_NOT_CONFIRMED = 'reg-status-not-confirmed',
  RESERVATION_CONFIRMED = 'reg-confirmed',
  RESERVATION_UNCONFIRMED = 'reg-unconfirmed',
  RESERVATION_SET_BEDS = 'reg-beds',
  RESERVATION_SET_MAXI_BEDS = 'reg-maxi-beds',
  RESERVATION_SET_CHAIRS = 'reg-chairs',
  RESERVATION_SET_DECK_CHAIRS = 'reg-deck-chairs',
  // Reservation Update prices
  RESERVATION_SET_FORCED_TOTAL = 'reg-amount',
  RESERVATION_SET_FORCED_BEACH = 'reg-beach-amount',
  // Reservation Payment
  RESERVATION_ADD_CASH_FLOW = '',
  CASH_FLOW_SET_FISCAL = 'cash-set-fiscal',
  CASH_FLOW_SET_NOT_FISCAL = 'cash-set-not-fiscal',
  RESERVATION_UPDATE_CASH_FLOW_DATE = 'reg-update-cash-flow-date',
  RESERVATION_UPDATE_CASH_FLOW_TYPE = 'reg-update-cash-flow-type',
  // Print
  FISCAL_PRINTER_REQUEST = 'fiscal-printer-request',
  FISCAL_PRINTER_CONFIRM = 'fiscal-printer-confirm',
  FISCAL_PRINTER_INTERRUPED_WITH_FISCAL = 'fiscal-printer-interrupted-with-fiscal',
  FISCAL_PRINTER_INTERRUPED_WITHOUT_FISCAL = 'fiscal-printer-interrupted-without-fiscal',
  FISCAL_PRINTER_ERROR = 'fiscal-printer-error',
  // Print online receipt
  ONLINE_PRINT_BOOKING_RECEIPT_REQUEST = 'online-fiscal-printer-receipt-request',
  ONLINE_PRINT_BOOKING_RECEIPT = 'online-fiscal-printer-receipt',
  // CHECK IN OUT
  RESERVATION_CHECK_IN = 'check-in',
  RESERVATION_CHECK_OUT = 'check-out',
  // Summary
  SUMMARY_SEND_WHATSAPP = 'summary-send-whatsapp',
  SUMMARY_SEND_WHATSAPP_PAY = 'summary-send-whatsapp-pay',
  SUMMARY_SEND_EMAIL = 'summary-send-email',
  SUMMARY_SEND_EMAIL_PAY = 'summary-send-email-pay',
  // import backup json file
  IMPORT_BACKUP_FILE_REQUEST = 'import-backup-file-request',
  IMPORT_BACKUP_FILE_CONFIRM = 'import-backup-file-confirm',
  // Super user added
  SUPERUSER_PERMISSION_ADDED = 'superuser-permission-add',
  // pos
  POS_POLLING_INTERRUPED = 'pos-polling-interrupted',
  POS_POLLING_EXPIRED = 'pos-polling-expired',
  POS_PAYMENT_REQUEST = 'pos-payment-request',
  POS_PAYMENT_CONFIRM = 'pos-payment-confirm',
}

export interface Log {
  id: number;
  userId: number;
  action: LogAction;
  reservationId: number;
  value: string;
  createdAt: number;
  updatedAt: number;
  firstName: string;
  lastName: string;
}
