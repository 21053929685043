import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01555eba"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "top-bar-card-reader"
}
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "col-12" }
const _hoisted_9 = { class: "col-12" }
const _hoisted_10 = { class: "col-12" }
const _hoisted_11 = { class: "col-12" }
const _hoisted_12 = { class: "col-12" }
const _hoisted_13 = { class: "col-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.cardReader)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Button, {
            class: "p-button-outlined p-button-rounded p-button-white card-reader-button",
            icon: "spi-card",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onReadCard(_ctx.cardReader)))
          })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Dialog, {
      class: "dialog",
      modal: "",
      visible: _ctx.cardDialog,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.cardDialog) = $event)),
      onHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.cardDialog = false))
    }, {
      default: _withCtx(() => [
        _createElementVNode("header", null, [
          _createElementVNode("h6", null, _toDisplayString(_ctx.$t('topBarCardReader.title')), 1)
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('topBarCardReader.customerName')) + ": " + _toDisplayString(_ctx.cardCustomerName), 1),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('topBarCardReader.hotelRoom')) + ": " + _toDisplayString(_ctx.cardHotelRoom), 1),
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('topBarCardReader.reservationNumber')) + ": " + _toDisplayString(_ctx.cardReservationNumber), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('topBarCardReader.checkIn')) + ": " + _toDisplayString(_ctx.cardReservationCheckIn), 1),
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('topBarCardReader.checkOut')) + ": " + _toDisplayString(_ctx.cardReservationCheckOut), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('topBarCardReader.adults')) + ": " + _toDisplayString(_ctx.cardReservationAdultsNum), 1),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('topBarCardReader.children')) + ": " + _toDisplayString(_ctx.cardReservationChildrenNum), 1),
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('topBarCardReader.reservationService')) + ": " + _toDisplayString(_ctx.cardReservationService), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardWallets, (wallet, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "col-12",
              key: index
            }, _toDisplayString(_ctx.$t('topBarCardReader.wallets')) + ": " + _toDisplayString(wallet.number) + " | " + _toDisplayString(wallet.balance), 1))
          }), 128)),
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('topBarCardReader.priceMode')) + ": " + _toDisplayString(_ctx.cardPriceMode), 1),
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('topBarCardReader.reservationAgency')) + ": " + _toDisplayString(_ctx.cardReservationAgency), 1),
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t('topBarCardReader.reservationOrigin')) + ": " + _toDisplayString(_ctx.cardReservationOrigin), 1)
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}