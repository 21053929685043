import i18n from '@/i18n';
import { DropdownOption, Timestamp } from '.';

export enum PriceListType {
  STANDARD = 'standard',
  SEASONAL = 'seasonal',
  EXTRA_SERVICES = 'extra-service',
}

export enum ExtraDaysMode {
  STANDARD = 0,
  RECURSIVE = 1,
}

export enum PeriodsMode {
  LINEAR = 0,
  SPECULAR = 1,
}

export enum PeriodsShiftMode {
  SIMPLE = 0,
  AVERAGE = 1,
  START_OVER = 2,
  SIMPLE_REVERSED = 3,
}

export interface PricePeriod {
  id: number;
  priceListId: number;
  name: string;
  startDate: string;
  endDate: string;
  useWeekend: boolean;
  weekendRules: Array<string>;
}

interface PriceValueDay {
  [day: string]: number;
}
export interface PriceValue {
  halfDay: number;
  extraDay: number;
  days: {
    [key: string]: PriceValueDay;
  };
}

export interface PriceCombination {
  id: number;
  priceListId: number;
  pricePeriodId: number;
  weekend: boolean;
  sector: number;
  numUmbrellas: number;
  numBeds: number;
  numMaxibeds: number;
  numDeckchairs: number;
  numChairs: number;
  numCabins: number;
  numExtras: number;
  value: PriceValue;
}

export interface PriceList {
  id: number;
  priceConfigId: number | null; // TODO remove
  name: string;
  numSectors: number;
  numPeriods: number;
  listDaysLength: number;
  extraDaysMode: ExtraDaysMode;
  periodsMode: PeriodsMode;
  periodsShiftMode: PeriodsShiftMode;
  periodsData: any; // TODO
  periods: Array<PricePeriod>;
  createdAt: Timestamp;
  updatedAt: Timestamp | null;
  draftPriceConfigId: number | null;
  activePriceConfigId: number | null;
}

export const EXTRA_DAYS_MODE_OPTIONS: Array<DropdownOption> = [
  {
    value: ExtraDaysMode.STANDARD,
    label: i18n.global.t('extraDaysMode.standard'),
  },
  {
    value: ExtraDaysMode.RECURSIVE,
    label: i18n.global.t('extraDaysMode.recursive'),
  },
];

export const PERIODS_MODE_OPTIONS: Array<DropdownOption> = [
  {
    value: PeriodsMode.LINEAR,
    label: i18n.global.t('periodsMode.linear'),
  },
  {
    value: PeriodsMode.SPECULAR,
    label: i18n.global.t('periodsMode.specular'),
  },
];

export const PERIODS_SHIFT_MODE_OPTIONS: Array<DropdownOption> = [
  {
    value: PeriodsShiftMode.SIMPLE,
    label: i18n.global.t('periodsShiftMode.simple'),
  },
  {
    value: PeriodsShiftMode.AVERAGE,
    label: i18n.global.t('periodsShiftMode.average'),
  },
  {
    value: PeriodsShiftMode.START_OVER,
    label: i18n.global.t('periodsShiftMode.startOver'),
  },
  // {
  //   value: PeriodsShiftMode.SIMPLE_REVERSED,
  //   label: i18n.global.t('periodsShiftMode.simpleReversed'),
  // },
];

export interface PriceListQualityCheckSectorsShiftItem {
  sectors: Array<number>;
  price: number;
}

export interface PriceListQualityCheckSectorsShiftPeriodData {
  days: Array<number>;
  sectorShifts: Array<PriceListQualityCheckSectorsShiftItem>;
}

export interface PriceListQualityCheckSectorsShiftPeriod {
  periodId: number;
  data: Array<PriceListQualityCheckSectorsShiftPeriodData>;
}

export interface PriceListQualityCheckPeriodsDataPeriodSectorValue {
  isThreshold: boolean;
  token: string;
  value: number;
}

export interface PriceListQualityCheckPeriodsDataPeriodSector {
  sectorId: number;
  values: Array<PriceListQualityCheckPeriodsDataPeriodSectorValue>;
}

export interface PriceListQualityCheckPeriodsDataPeriod {
  periodId: number;
  sectors: Array<PriceListQualityCheckPeriodsDataPeriodSector>;
}

export interface PriceListQualityCheckSectorsDataSectorPeriodValue {
  isThreshold: boolean;
  token: string;
  value: number;
}

export interface PriceListQualityCheckSectorsDataSectorPeriod {
  periodId: number;
  values: Array<PriceListQualityCheckSectorsDataSectorPeriodValue>;
}

export interface PriceListQualityCheckSectorsDataSector {
  sectorId: number;
  periods: Array<PriceListQualityCheckSectorsDataSectorPeriod>;
}

export interface PriceListQualityCheck {
  sectorsData: Array<PriceListQualityCheckSectorsDataSector>;
  periodsData: Array<PriceListQualityCheckPeriodsDataPeriod>;
  sectorsShiftData: Array<PriceListQualityCheckSectorsShiftPeriod>;
}

export interface PriceListEstimate {
  sectorId: number;
  price: number;
}
