/**
 * Warehouse service
 */

import axios, { AxiosResponse } from 'axios';

import apiService from '@/services/apiService';
import {
  ApiWarehouseAlertPayload,
  ApiWarehouseAlertResponse,
  ApiWarehouseCreatePayload,
  ApiWarehouseCreateResponse,
  ApiWarehouseListResponse,
  ApiWarehouseUpdatePayload,
  ApiWarehouseUpdateResponse,
} from '@/models/api';
import { Warehouse, WarehouseAlert } from '@/models/warehouse';

export default {
  async list(date: number | null = null): Promise<Array<Warehouse>> {
    const res: AxiosResponse<ApiWarehouseListResponse> = await axios.get(
      apiService.warehouse.list(date),
    );
    return res.data.result.warehouses;
  },
  async create(payload: ApiWarehouseCreatePayload): Promise<Warehouse> {
    const res: AxiosResponse<ApiWarehouseCreateResponse> = await axios.post(
      apiService.warehouse.create(),
      payload,
    );
    return res.data.result.warehouse;
  },
  async update(
    id: number,
    payload: ApiWarehouseUpdatePayload,
  ): Promise<Warehouse> {
    const res: AxiosResponse<ApiWarehouseUpdateResponse> = await axios.patch(
      apiService.warehouse.update(id),
      payload,
    );
    return res.data.result.warehouse;
  },
  async delete(id: number): Promise<void> {
    await axios.delete(apiService.warehouse.delete(id));
  },
  async alert(
    payload: ApiWarehouseAlertPayload,
  ): Promise<Array<WarehouseAlert>> {
    const res: AxiosResponse<ApiWarehouseAlertResponse> = await axios.post(
      apiService.warehouse.alert(),
      payload,
    );
    return res.data.result.alerts;
  },
};
