
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { MapElement, MapMode, MapShiftStep, MapSpot } from '@/models/map';
import reservationService from '@/services/reservationService';
import {
  ApiReservationCopyPayload,
  ApiReservationCreatePayload,
  ApiReservationMergePayload,
} from '@/models/api';
import { Reservation } from '@/models/reservation';
import { SpotBasic } from '@/models/spot';

interface Selection {
  icon: string;
  count: number;
  cta: {
    label: string;
    enabled: boolean;
    click: () => void;
  };
  close: () => void;
}

export default defineComponent({
  name: 'MapSelection',
  data() {
    return {
      mapMode: cloneDeep(MapMode),
      mapShiftStep: cloneDeep(MapShiftStep),
      joinAccountDialog: false,
      isJoinButtonEnabled: true,
    };
  },
  methods: {
    resetShift(): void {
      if (this.reservationId) {
        // shift from reservation
        this.backToReservation();
      }
      this.$store.commit('map/setMode', this.mapMode.NONE);
      this.$store.commit('map/resetShift');
    },
    resetMultiple(): void {
      this.$store.commit('map/setMode', this.mapMode.NONE);
    },
    onReset(): void {
      this.$store.commit('map/setSelected', []);
    },
    backToReservation(): void {
      this.$router.push(`/reservation/${this.reservationId}`);
    },
    async onJoinAccount(join: boolean): Promise<void> {
      const startDate = this.calendar[0].toSeconds();
      const endDate = this.calendar[1].toSeconds();
      this.isJoinButtonEnabled = false;
      /* MULTIPLE SELECTION */
      if (this.mode === MapMode.MULTIPLE_SELECTION) {
        const payload = [] as Array<ApiReservationCreatePayload>;
        this.selected.map((s: MapSpot) => {
          payload.push({
            spotType: s.type,
            spotName: s.name,
            startDate,
            endDate,
          } as ApiReservationCreatePayload);
        });
        let reservations = [] as Array<Reservation>;
        try {
          reservations = await reservationService.createMany(payload);
        } catch (error) {
          // console.log(error);
        } finally {
          this.isJoinButtonEnabled = true;
        }

        if (join) {
          const reservationMerged: Reservation = await reservationService.merge(
            {
              reservations: reservations.map((res: Reservation) => res.id),
            } as ApiReservationMergePayload,
          );
          this.$router.push(`/reservation/${reservationMerged.id}`);
        } else {
          const propagate = reservations.slice(1);
          this.$store.commit('reservation/setPropagate', propagate);
          this.$router.push(`/reservation/${reservations[0].id}`);
        }
      }

      /* COPY */
      if (this.mode === MapMode.COPY) {
        const payload = {
          spots: [] as Array<SpotBasic>,
        } as ApiReservationCopyPayload;
        this.selected.map((s: MapElement) => {
          payload.spots.push({
            spotType: s.type,
            spotName: s.name,
          });
        });
        const reservations: Array<Reservation> = await reservationService.copy(
          this.reservationId,
          payload,
        );

        if (join) {
          const reservationMerged: Reservation = await reservationService.merge(
            {
              reservations: [
                this.reservationId,
                ...reservations.map((res: Reservation) => res.id),
              ],
            } as ApiReservationMergePayload,
          );
          this.$router.push(`/reservation/${reservationMerged.id}`);
        } else {
          // await this.$router.replace({ query: {} });
          await this.$store.dispatch('map/refresh');
          this.resetMultiple();
          this.$spiagge.toast.success(this.$t('mapSelection.toast.success'));
        }
      }
    },
  },
  computed: {
    ...mapState('map', [
      'calendar',
      'mode',
      'filter',
      'scale',
      'selected',
      'shift',
    ]),
    reservationId(): number {
      // reservation id from reservation
      return this.$route.query.reservationId
        ? Number(this.$route.query.reservationId)
        : 0;
    },
    selection(): Selection | null {
      const selection = {
        icon: '',
        count: 0,
        cta: {
          label: '',
          enabled: false,
          click: () => null,
        },
        close: () => null,
      } as Selection;
      if (this.mode === MapMode.SHIFT) {
        selection.icon = 'spi-move';
        selection.close = this.resetShift;
        selection.cta.label = this.$t('common.move');
        if (this.shift.step === MapShiftStep.SELECT_FROM) {
          selection.count = this.shift.from ? 1 : 0;
          selection.cta.enabled = this.shift.from !== null;
          selection.cta.click = () =>
            this.$store.commit('map/setShiftStep', MapShiftStep.SELECT_MODE);
        }
        if (this.shift.step === MapShiftStep.SELECT_TO) {
          selection.count = this.shift.to ? 1 : 0;
          selection.cta.enabled = this.shift.to !== null;
          selection.cta.click = () =>
            this.$store.commit('map/setShiftStep', MapShiftStep.CONFIRM);
        }
        return selection;
      }
      if (this.mode === MapMode.PROGRAMMED_SHIFT) {
        selection.icon = 'spi-move';
        selection.close = this.backToReservation;
        selection.cta.label = this.$t('common.move');
        if (this.shift.step === MapShiftStep.SELECT_TO) {
          selection.count = this.shift.to ? 1 : 0;
          selection.cta.enabled = this.shift.to !== null;
          selection.cta.click = () =>
            this.$store.commit('map/setShiftStep', MapShiftStep.CONFIRM);
        }
        return selection;
      }
      if (this.mode === MapMode.MULTIPLE_SELECTION) {
        selection.icon = 'spi-multiple-selection';
        selection.close = this.resetMultiple;
        selection.cta.label = this.$t('common.next');
        selection.count = this.selected.length;
        selection.cta.enabled = this.selected.length > 0;
        selection.cta.click = () => {
          this.joinAccountDialog = true;
        };
        return selection;
      }
      if (this.mode === MapMode.COPY) {
        selection.icon = 'spi-multiple-selection';
        selection.close = this.backToReservation;
        selection.cta.label = this.$t('common.next');
        selection.count = this.selected.length;
        selection.cta.enabled = this.selected.length > 0;
        selection.cta.click = () => {
          this.joinAccountDialog = true;
        };
        return selection;
      }
      return null;
    },
    active(): boolean {
      return this.mode > 0;
    },
    classes(): Array<string> {
      const classes = [];
      if (this.active) {
        classes.push('active');
      }
      if (this.selection && this.selection.count > 0) {
        classes.push('has-selected');
      }
      return classes;
    },
  },
});
