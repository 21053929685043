
import { defineComponent, WatchStopHandle } from 'vue';
import { DateTime } from 'luxon';
import { mapState } from 'vuex';
import { debounce, inRange, round } from 'lodash';
import MapDecoration from '@/components/shared/MapDecoration.vue';
import {
  MapView,
  MapDecoration as MapDecorationI,
  MapSpot as MapSpotI,
} from '@/models/map';
import { ApiMapDataPayload } from '@/models/api';
import cookieUtil from '@/utils/cookieUtil';
import MapSpot from '@/components/map/MapSpot.vue';
import { DropdownOption, Point2D, Css } from '@/models';
import {
  PrintFormat,
  PrintOrientation,
  PrintSize,
  PRINT_SIZES,
} from '@/models/print';

interface MapPrintScreenshot {
  column: number;
  row: number;
  spots: Array<MapSpotI>;
  decorations: Array<MapDecorationI>;
}

interface MapPrintPreferences {
  format: PrintFormat;
  orientation: PrintOrientation;
  scale: number;
  printBackground: boolean;
  printDecorations: boolean;
  removeEmpty: boolean;
}

export default defineComponent({
  name: 'MapPrintView',
  components: {
    MapDecoration,
    MapSpot,
  },
  data() {
    return {
      // data/params
      startDate: null as unknown as DateTime,
      endDate: null as unknown as DateTime,
      // settings
      format: PrintFormat.A4,
      formatOptions: [
        {
          label: this.$t('mapPrint.printFormat.a4'),
          value: PrintFormat.A4,
        },
        {
          label: this.$t('mapPrint.printFormat.a3'),
          value: PrintFormat.A3,
        },
      ] as Array<DropdownOption>,
      orientation: PrintOrientation.PORTRAIT,
      orientationOptions: [
        {
          label: this.$t('mapPrint.printOrientation.vertical'),
          value: PrintOrientation.PORTRAIT,
        },
        {
          label: this.$t('mapPrint.printOrientation.horizontal'),
          value: PrintOrientation.LANDSCAPE,
        },
      ] as Array<DropdownOption>,
      scale: 100,
      printBackground: false,
      printDecorations: false,
      removeEmpty: false,
      view: MapView.FULL,
      watcher: null as unknown as WatchStopHandle,
      buildScreenshotsDebounce: debounce(
        this.buildScreenshots as () => void,
        1500,
      ),
      mapWidth: 0,
      mapHeight: 0,
      mapRows: 0,
      mapColumns: 0,
      screenshots: [] as Array<MapPrintScreenshot>,
      printSize: null as unknown as PrintSize,
      scaleFactor: 1,
      processing: false,
      previewDate: '',
      //----
    };
  },
  async beforeMount() {
    /**
     * Setup store, preferences, watchers ad build
     */
    try {
      // reset map store module
      this.$store.dispatch('map/reset');
      // get cookie preferences
      this.setupPreferences();
      // get query string parameters if setted
      const params = this.$route.query;
      this.startDate = params.startDate
        ? DateTime.fromSeconds(Number(params.startDate)).startOf('day')
        : DateTime.utc().startOf('day');
      this.endDate = params.startDate
        ? DateTime.fromSeconds(Number(params.endDate)).startOf('day')
        : DateTime.utc().startOf('day');
      this.view = (params.view as MapView) ?? MapView.FULL;
      // get map data and set view
      this.$store.commit('map/setView', this.view);
      await this.$store.dispatch('map/setData', {
        from: this.startDate.toSeconds(),
        to: this.endDate.toSeconds(),
      } as ApiMapDataPayload);
      // watch settings for change
      this.watcher = this.$watch(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (vm: any) => [vm.format, vm.orientation, vm.scale],
        async () => {
          this.buildScreenshotsDebounce();
          this.updatePreferences();
        },
      );
      // build
      this.buildScreenshots();
    } catch (e) {
      // console.log(e);
      this.$spiagge.toast.error(this.$t('mapPrint.toast.retrievalError'));
    }
  },
  methods: {
    updatePreferences(): void {
      /**
       * Update preferences cookie
       */
      cookieUtil.set(
        `map_${this.license.license}_print`,
        JSON.stringify({
          format: this.format,
          orientation: this.orientation,
          scale: this.scale,
          printBackground: this.printBackground,
          printDecorations: this.printDecorations,
          removeEmpty: this.removeEmpty,
        } as MapPrintPreferences),
      );
    },
    setupPreferences(): void {
      /**
       * Setup cookie preferences (or create if cookie doesn't exist)
       */
      const preferences = cookieUtil.get(
        `map_${this.license.license}_print`,
      ) as string;
      if (!preferences) {
        this.updatePreferences();
      } else {
        const preferencesObj: MapPrintPreferences = JSON.parse(preferences);
        this.format = preferencesObj.format;
        this.orientation = preferencesObj.orientation;
        this.scale = preferencesObj.scale;
        this.printBackground = preferencesObj.printBackground;
        this.printDecorations = preferencesObj.printDecorations;
        this.removeEmpty = preferencesObj.removeEmpty;
      }
    },
    async buildScreenshots(): Promise<void> {
      /**
       * Build map screenshots
       */

      // loader
      this.processing = true;

      // scale factor
      this.scaleFactor = round(this.scale / 100, 2);
      // print size
      this.printSize = PRINT_SIZES.find(
        (size: PrintSize) =>
          size.format === this.format && size.orientation === this.orientation,
      ) as PrintSize;
      // get rows/columns
      this.mapHeight = round(
        this.data.height * this.cellHeight * this.scaleFactor,
        2,
      );
      this.mapWidth = round(
        this.data.width * this.cellWidth * this.scaleFactor,
        2,
      );
      this.mapColumns = Math.ceil(this.mapWidth / this.printSize.width);
      this.mapRows = Math.ceil(this.mapHeight / this.printSize.height);

      // build
      const screenshots: Array<MapPrintScreenshot> = [];
      for (let r = 0; r < this.mapRows; r += 1) {
        for (let c = 0; c < this.mapColumns; c += 1) {
          screenshots.push({
            column: c,
            row: r,
            spots: this.spots.filter((spot: MapSpotI) =>
              this.inRange(r, c, spot),
            ),
            decorations: this.decorations.filter((decoration: MapDecorationI) =>
              this.inRange(r, c, decoration),
            ),
          });
        }
      }
      this.screenshots = screenshots;
      this.previewDate = DateTime.now()
        .setZone('Europe/Rome')
        .toLocaleString(DateTime.DATETIME_SHORT);
      this.processing = false; // NOT WORKING
    },
    // prettier-ignore
    inRange(
      row: number,
      column: number,
      element: MapDecorationI | MapSpotI,
    ): boolean {
      /**
       * Check if element is in a specific range
       */
      const startX = (column * this.printSize.width) + (column * 1); // 0->200 201->401 402->602
      const endX = startX + this.printSize.width;
      const startY = (row * this.printSize.height) + (row * 1);
      const endY = startY + this.printSize.height;
      const vertices = this.getVertices(element);
      return vertices.some(
        (v: Point2D) =>
          inRange(v.x, startX, endX) && inRange(v.y, startY, endY),
      );
    },
    // prettier-ignore
    wrapperStyle(screenshot: MapPrintScreenshot): Css {
      /**
       * Calc wrapper elements style
       */
      // FIXXXXXXXXX
      // console.log(screenshot.column);
      const scaleTransform = `scale(${this.scaleFactor})`;
      const baseTranslateX = Math.round(screenshot.column * this.printSize.width);
      const offsetTranslateX = baseTranslateX - ((baseTranslateX * this.scaleFactor));
      const translateX = `-${baseTranslateX + offsetTranslateX}px`;
      const baseTranslateY = Math.round(screenshot.row * this.printSize.height);
      const offsetTranslateY = baseTranslateY - ((baseTranslateY * this.scaleFactor));
      const translateY = `-${baseTranslateY + offsetTranslateY}px`;
      const translateTransform = `translate(${translateX}, ${translateY})`;
      // console.log(scaleTransform, translateTransform);
      return {
        transform: `${scaleTransform} ${translateTransform}`,
        transformOrigin: 'top left',
      } as Css;
    },
    // prettier-ignore
    getVertices(element: MapSpotI | MapDecorationI): Array<Point2D> {
      /**
       * Calc vertics of element
       */
      return [
        // top-left
        {
          x: Math.round((element.posX * this.cellWidth) * this.scaleFactor),
          y: Math.round((element.posY * this.cellHeight) * this.scaleFactor),
        },
        // top-right
        {
          x: Math.round(((element.posX + element.width) * this.cellWidth) * this.scaleFactor),
          y: Math.round((element.posY * this.cellHeight) * this.scaleFactor),
        },
        // bottom-right
        {
          x: Math.round(((element.posX + element.width) * this.cellWidth) * this.scaleFactor),
          y: Math.round(((element.posY + element.height) * this.cellHeight) * this.scaleFactor),
        },
        // bottom-left
        {
          x: Math.round((element.posX * this.cellWidth) * this.scaleFactor),
          y: Math.round(((element.posY + element.height) * this.cellHeight) * this.scaleFactor),
        },
      ];
    },
    screenshotClasses(screenshot: MapPrintScreenshot): Array<string> {
      /**
       * Calc screenhot classes
       */
      const classes = [''];
      if (this.printBackground) {
        classes.push('background');
      }
      if (this.printDecorations) {
        classes.push('decorations');
      }
      if (
        this.removeEmpty &&
        ((screenshot.decorations.length === 0 &&
          screenshot.spots.length === 0) ||
          (!this.printDecorations &&
            screenshot.decorations.length > 0 &&
            screenshot.spots.length === 0))
      ) {
        classes.push('empty');
      }
      return classes;
    },
    // prettier-ignore
    screenshotStyle(screenshot: MapPrintScreenshot): Css {
      /**
       * Calc screenshot style
       */
      const backgroundStyle: { [key: string]: string } =
        this.data.background && this.printBackground
          ? {
              backgroundImage: this.data.background
                ? `url(${this.data.background.url})`
                : '',
              backgroundSize: `${
                this.data.background.width * this.cellWidth * this.scaleFactor
              }px ${
                this.data.background.height * this.cellHeight * this.scaleFactor
              }px`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: `${
                ((this.data.background.posX * this.cellWidth) -
                  (this.printSize.width * screenshot.column))
              }px ${
                ((this.data.background.posY * this.cellHeight) -
                  (this.printSize.height * screenshot.row))
              }px`,
            }
          : {};
      return {
        width: `${this.printSize.width}px`,
        height: `${this.printSize.height}px`,
        ...backgroundStyle,
      };
    },
    onPrint(): void {
      /**
       * Print map
       */
      const screenshotsElement = document.getElementById(
        'screenshots',
      ) as unknown as HTMLElement;

      this.$spiagge.utils.global.printHtml(
        screenshotsElement,
        [],
        true,
        this.format,
        this.orientation,
      );
    },
  },
  computed: {
    ...mapState('map', ['data']),
    ...mapState('app', [
      'windowWidth',
      'windowHeight',
      'breakpoints',
      'loading',
    ]),
    ...mapState('session', ['license', 'permissions']),
    cellHeight(): number {
      return 40;
    },
    cellWidth(): number {
      return this.view === MapView.FULL ? 60 : 40;
    },
    spots(): Array<MapSpotI> {
      return this.data ? this.data.elements.spots : [];
    },
    decorations(): Array<MapDecorationI> {
      return this.data ? this.data.elements.decorations : [];
    },
  },
  beforeUnmount() {
    /**
     * Reset map and watcher
     */
    this.$store.commit('map/reset');
    this.watcher();
  },
});
