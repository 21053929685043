import { OrderByItem } from '@/models';

export default {
  buildQueryString(payload: { [key: string]: any }): string {
    let queryString = new URLSearchParams(payload).toString();
    if (queryString) {
      queryString = `?${queryString}`;
    }
    queryString = queryString.replaceAll('true', '1');
    queryString = queryString.replaceAll('false', '0');
    return queryString;
  },
  buildOrderBy(items: Array<OrderByItem>): string {
    let orderBy = '';
    items.map((item: OrderByItem, index: number) => {
      if (['asc', 'desc'].includes(item.value)) {
        orderBy += `${item.field}_${item.value}`;
        if (index !== items.length - 1) {
          orderBy += '&';
        }
      }
    });
    return orderBy;
  },
};
