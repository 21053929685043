import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76ea13a7"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "free-draw",
    style: _normalizeStyle(_ctx.style)
  }, [
    _createElementVNode("canvas", {
      id: "canvas",
      width: _ctx.width,
      height: _ctx.height
    }, null, 8, _hoisted_1),
    (_ctx.clear)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "clear",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClear && _ctx.onClear(...args)))
        }, _toDisplayString(_ctx.$t('button.clean')), 1))
      : _createCommentVNode("", true)
  ], 4))
}