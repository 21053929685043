import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-325eb1de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-3" }
const _hoisted_2 = { class: "grid mt-3" }
const _hoisted_3 = {
  key: 0,
  class: "p-field sectors-field col-12 p-0"
}
const _hoisted_4 = { class: "col-12 sm:col-6" }
const _hoisted_5 = { for: "input-sector-group-id" }
const _hoisted_6 = {
  key: 0,
  class: "error-message"
}
const _hoisted_7 = { class: "col-12 flex flex-wrap p-0" }
const _hoisted_8 = { class: "p-field col-12 sm:col-6" }
const _hoisted_9 = { for: "input-name" }
const _hoisted_10 = {
  key: 0,
  class: "error-message"
}
const _hoisted_11 = { class: "p-field icon-field col-12 sm:col-6" }
const _hoisted_12 = { for: "input-icon" }
const _hoisted_13 = {
  key: 0,
  class: "item"
}
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "item" }
const _hoisted_16 = ["src"]
const _hoisted_17 = {
  key: 1,
  class: "p-field sectors-field col-12 p-0"
}
const _hoisted_18 = { class: "col-12 sm:col-6" }
const _hoisted_19 = { for: "input-sector-group-id" }
const _hoisted_20 = { class: "p-field price-mode-field col-12 p-0" }
const _hoisted_21 = { class: "col-12 sm:col-6" }
const _hoisted_22 = { for: "input-price-mode" }
const _hoisted_23 = {
  key: 2,
  class: "p-field price-list-field col-12 sm:col-6"
}
const _hoisted_24 = { for: "input-price-list-id" }
const _hoisted_25 = {
  key: 0,
  class: "error-message"
}
const _hoisted_26 = {
  key: 3,
  class: "p-field price-field col-12 sm:col-6"
}
const _hoisted_27 = { for: "input-price" }
const _hoisted_28 = { class: "p-field vat-field col-12 sm:col-6" }
const _hoisted_29 = { for: "input-vat" }
const _hoisted_30 = { class: "p-field map switch-field col-12 pt-3 pb-3" }
const _hoisted_31 = {
  class: "cursor-pointer",
  for: "input-map"
}
const _hoisted_32 = { class: "p-field background-field col-12" }
const _hoisted_33 = { for: "input-background" }
const _hoisted_34 = { class: "col-12 sm:col-6 pl-0" }
const _hoisted_35 = { class: "item" }
const _hoisted_36 = { class: "item" }
const _hoisted_37 = { class: "col-12 pb-0" }
const _hoisted_38 = { for: "" }
const _hoisted_39 = { class: "p-field-radio vertical col-12 sm:col-6" }
const _hoisted_40 = { class: "p-field-radiobutton" }
const _hoisted_41 = { class: "p-field-radiobutton" }
const _hoisted_42 = {
  id: "input-daily-items-container",
  class: "p-field"
}
const _hoisted_43 = { class: "bordered-box" }
const _hoisted_44 = { class: "p-field switch-field col-12 p-3" }
const _hoisted_45 = {
  class: "font-bold cursor-pointer",
  for: "purchase-offline-flag"
}
const _hoisted_46 = {
  key: 0,
  class: "col-12 p-2"
}
const _hoisted_47 = { class: "p-field-checkbox p-2" }
const _hoisted_48 = {
  class: "cursor-pointer",
  for: "input-fast-cash"
}
const _hoisted_49 = { class: "p-field-checkbox p-2" }
const _hoisted_50 = {
  class: "cursor-pointer",
  for: "input-reservation-schedule"
}
const _hoisted_51 = {
  key: 0,
  class: "p-field-checkbox p-2"
}
const _hoisted_52 = {
  class: "cursor-pointer",
  for: "input-sold-alone-offline"
}
const _hoisted_53 = { class: "bordered-box" }
const _hoisted_54 = { class: "p-field switch-field col-12 p-3" }
const _hoisted_55 = {
  class: "font-bold cursor-pointer",
  for: "purchase-online-flag"
}
const _hoisted_56 = {
  key: 0,
  class: "col-12 p-2"
}
const _hoisted_57 = {
  key: 0,
  class: "p-field-checkbox p-2"
}
const _hoisted_58 = {
  class: "cursor-pointer",
  for: "input-hide-when-not-available-online"
}
const _hoisted_59 = { class: "p-field-checkbox p-2" }
const _hoisted_60 = {
  class: "cursor-pointer",
  for: "input-reservation-schedule-online"
}
const _hoisted_61 = {
  key: 1,
  class: "col-12 flex flex-wrap"
}
const _hoisted_62 = { class: "p-field col-12 sm:col-6" }
const _hoisted_63 = { for: "input-min-items-type" }
const _hoisted_64 = { class: "p-field max-items-type-field col-12 sm:col-6" }
const _hoisted_65 = { for: "input-max-items-type" }
const _hoisted_66 = { class: "p-field-checkbox p-2" }
const _hoisted_67 = {
  class: "cursor-pointer",
  for: "input-sold-alone-online"
}
const _hoisted_68 = {
  key: 0,
  class: "col-12 flex flex-wrap"
}
const _hoisted_69 = { class: "p-field col-12 sm:col-6" }
const _hoisted_70 = { for: "min-items-type-alone" }
const _hoisted_71 = { class: "p-field max-items-type-field col-12 sm:col-6" }
const _hoisted_72 = { for: "max-items-type-alone" }
const _hoisted_73 = {
  key: 4,
  class: "p-field sectors-field col-12"
}
const _hoisted_74 = { for: "sectors" }
const _hoisted_75 = { class: "pl-0 pr-0 col-12" }
const _hoisted_76 = { class: "flex p-3 justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Field = _resolveComponent("Field")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_divider = _resolveComponent("divider")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    id: "services-dialog",
    modal: true
  }, {
    header: _withCtx(() => [
      _createElementVNode("h6", _hoisted_1, _toDisplayString(_ctx.getTitle()), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_76, [
        _createVNode(_component_Button, {
          class: "p-button-outlined",
          label: _ctx.$t('button.cancel'),
          icon: "spi-times",
          onClick: _cache[34] || (_cache[34] = ($event: any) => (this.visible = false))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          id: "service-modal-save-button",
          form: "form-services",
          class: "p-2",
          label: _ctx.$t('button.save'),
          icon: "pi pi-save",
          type: "submit"
        }, null, 8, ["label"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        id: "form-services",
        onSubmit: _cache[33] || (_cache[33] = ($event: any) => (_ctx.onSubmit()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.isTicket && _ctx.showServiceGroupDropdown())
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(_component_Field, {
                    name: "input-sector-group-id",
                    modelValue: _ctx.model.serviceGroupId,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.serviceGroupId) = $event)),
                    rules: "required"
                  }, {
                    default: _withCtx(({ field, handleChange, meta, errorMessage }) => [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('extraServices.dialog.serviceGroup')), 1),
                        _createVNode(_component_Dropdown, _mergeProps({
                          id: "input-sector-group-id",
                          modelValue: _ctx.model.serviceGroupId,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.serviceGroupId) = $event)),
                          options: _ctx.serviceGroupOptions,
                          optionLabel: "label",
                          optionValue: "value",
                          placeholder: _ctx.$t('button.select')
                        }, field.value, {
                          "onUpdate:modelValue": handleChange,
                          class: !meta.valid && meta.touched ? 'p-invalid' : ''
                        }), null, 16, ["modelValue", "options", "placeholder", "onUpdate:modelValue", "class"]),
                        errorMessage
                          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(errorMessage), 1))
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('extraServices.dialog.name')), 1),
                _createVNode(_component_Field, {
                  name: "input-name",
                  modelValue: _ctx.model.name,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.name) = $event)),
                  rules: "required"
                }, {
                  default: _withCtx(({ field, meta, errorMessage }) => [
                    _createVNode(_component_InputText, _mergeProps({
                      id: "input-name",
                      type: "text"
                    }, field, {
                      class: !meta.valid && meta.touched ? 'p-invalid' : ''
                    }), null, 16, ["class"]),
                    errorMessage
                      ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(errorMessage), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('extraServices.dialog.icon')), 1),
                _createVNode(_component_Dropdown, {
                  id: "input-icon",
                  modelValue: _ctx.model.icon,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.icon) = $event)),
                  options: _ctx.serviceIconOptions,
                  optionLabel: "label",
                  optionValue: "icon",
                  panelClass: "extra-services-icon-dropwdown-panel"
                }, {
                  value: _withCtx(() => [
                    (_ctx.serviceIconValue)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                          _createElementVNode("img", {
                            alt: "service-icon",
                            src: _ctx.serviceIconValue.image
                          }, null, 8, _hoisted_14),
                          _createElementVNode("span", null, _toDisplayString(_ctx.serviceIconValue.label), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  option: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("img", {
                        alt: "option-image",
                        src: slotProps.option.image
                      }, null, 8, _hoisted_16),
                      _createElementVNode("span", null, _toDisplayString(slotProps.option.label), 1)
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "options"])
              ])
            ]),
            (_ctx.isTicket && false)
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('extraServices.dialog.halfDay')), 1),
                    _createVNode(_component_Dropdown, {
                      id: "input-sector-group-id",
                      modelValue: _ctx.model.halfDay,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.halfDay) = $event)),
                      options: _ctx.halfDayOptions,
                      optionLabel: "label",
                      optionValue: "value",
                      placeholder: _ctx.$t('button.select')
                    }, null, 8, ["modelValue", "options", "placeholder"])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t('extraServices.dialog.rateMode')), 1),
                _createVNode(_component_Dropdown, {
                  id: "input-price-mode",
                  modelValue: _ctx.model.priceMode,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.priceMode) = $event)),
                  options: _ctx.servicePriceModeOptions,
                  optionLabel: "label",
                  optionValue: "mode",
                  onChange: _ctx.onPriceModeChange
                }, null, 8, ["modelValue", "options", "onChange"])
              ])
            ]),
            (_ctx.model.priceMode === _ctx.servicePriceMode.TABLE)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.$t('settingsExtraServicesView.priceList')), 1),
                  _createVNode(_component_Field, {
                    name: "input-price-list-id",
                    modelValue: _ctx.model.priceListId,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.priceListId) = $event)),
                    rules: "required"
                  }, {
                    default: _withCtx(({ field, handleChange, meta, errorMessage }) => [
                      _createVNode(_component_Dropdown, {
                        id: "input-price-list-id",
                        options: _ctx.priceListOptions,
                        optionLabel: "label",
                        optionValue: "value",
                        placeholder: _ctx.$t('button.select'),
                        "model-value": field.value,
                        "onUpdate:modelValue": handleChange,
                        class: _normalizeClass(!meta.valid && meta.touched ? 'p-invalid' : '')
                      }, null, 8, ["options", "placeholder", "model-value", "onUpdate:modelValue", "class"]),
                      errorMessage
                        ? (_openBlock(), _createElementBlock("span", _hoisted_25, _toDisplayString(errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_26, [
                  _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t('extraServices.dialog.price')), 1),
                  _createVNode(_component_InputNumber, {
                    id: "input-price",
                    step: 0.01,
                    mode: "decimal",
                    maxFractionDigits: 2,
                    modelValue: _ctx.model.cost,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.cost) = $event))
                  }, null, 8, ["step", "modelValue"])
                ])),
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t('extraServices.dialog.iva')), 1),
              _createVNode(_component_InputNumber, {
                id: "input-vat",
                step: 1,
                min: 0,
                max: 100,
                modelValue: _ctx.model.vat,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.vat) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_30, [
              _createVNode(_component_InputSwitch, {
                id: "input-map",
                modelValue: _ctx.model.map,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.map) = $event))
              }, null, 8, ["modelValue"]),
              _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.$t('extraServices.dialog.mapShow')), 1)
            ]),
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t('extraServices.dialog.backgroundColor')), 1),
              _createElementVNode("div", _hoisted_34, [
                _createVNode(_component_Dropdown, {
                  id: "input-background",
                  modelValue: _ctx.model.background,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.background) = $event)),
                  options: _ctx.serviceBackgroundOptions,
                  optionLabel: "label",
                  optionValue: "value",
                  panelClass: "extra-services-icon-dropwdown-panel"
                }, {
                  value: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_35, [
                      _createElementVNode("span", {
                        class: "color-preview",
                        style: _normalizeStyle({
                          backgroundColor: _ctx.getServiceBackground(_ctx.model),
                        })
                      }, null, 4),
                      _createElementVNode("span", null, _toDisplayString(_ctx.serviceBackgroundOptions.find(
                          (option) => option.value === (slotProps.value ?? 'default'),
                      )?.label), 1)
                    ])
                  ]),
                  option: _withCtx((slotProps) => [
                    _createElementVNode("div", _hoisted_36, [
                      _createElementVNode("span", {
                        class: "color-preview",
                        style: _normalizeStyle({
                        backgroundColor: slotProps.option.colorSpec.background,
                      })
                      }, null, 4),
                      _createElementVNode("span", null, _toDisplayString(slotProps.option.label), 1)
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("label", _hoisted_38, _toDisplayString(_ctx.getDailyItemsTitle()), 1),
              _createElementVNode("div", _hoisted_39, [
                _createElementVNode("div", _hoisted_40, [
                  _createVNode(_component_RadioButton, {
                    value: false,
                    modelValue: _ctx.dailyLimitEnabled,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.dailyLimitEnabled) = $event))
                  }, null, 8, ["modelValue"]),
                  _createElementVNode("label", {
                    class: "cursor-pointer",
                    for: "use-cabin-radio",
                    onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.dailyLimitEnabled = false))
                  }, _toDisplayString(_ctx.$t('common.unlimited')), 1)
                ]),
                _createElementVNode("div", _hoisted_41, [
                  _createVNode(_component_RadioButton, {
                    value: true,
                    modelValue: _ctx.dailyLimitEnabled,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.dailyLimitEnabled) = $event))
                  }, null, 8, ["modelValue"]),
                  _createElementVNode("label", {
                    class: "cursor-pointer",
                    for: "use-cabin-radio",
                    onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.dailyLimitEnabled = true))
                  }, _toDisplayString(_ctx.$t('common.limited')), 1),
                  _createElementVNode("div", _hoisted_42, [
                    _createVNode(_component_InputNumber, {
                      id: "input-daily-items",
                      step: 1,
                      min: 0,
                      class: "col-6 pt-0 pb-0",
                      modelValue: _ctx.model.dailyItems,
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model.dailyItems) = $event))
                    }, null, 8, ["modelValue"])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_43, [
              _createElementVNode("div", _hoisted_44, [
                _createVNode(_component_InputSwitch, {
                  id: "purchase-offline-flag",
                  modelValue: _ctx.purchaseOffline,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.purchaseOffline) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("label", _hoisted_45, _toDisplayString(_ctx.$t('extraServices.dialog.canBeSoldOffline')), 1)
              ]),
              _createVNode(_component_divider, { class: "m-0" }),
              (_ctx.purchaseOffline)
                ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                    _createElementVNode("div", _hoisted_47, [
                      _createVNode(_component_Checkbox, {
                        id: "input-fast-cash",
                        binary: true,
                        modelValue: _ctx.model.fastCash,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model.fastCash) = $event))
                      }, null, 8, ["modelValue"]),
                      _createElementVNode("label", _hoisted_48, _toDisplayString(_ctx.$t('extraServices.dialog.canBeSoldInFastCash')), 1)
                    ]),
                    _createElementVNode("div", _hoisted_49, [
                      _createVNode(_component_Checkbox, {
                        id: "input-reservation-schedule",
                        binary: true,
                        modelValue: _ctx.model.reservationSchedule,
                        "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.model.reservationSchedule) = $event))
                      }, null, 8, ["modelValue"]),
                      _createElementVNode("label", _hoisted_50, _toDisplayString(_ctx.$t('extraServices.dialog.canBeSoldWithSpot')), 1)
                    ]),
                    (_ctx.isTicket)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                          _createVNode(_component_Checkbox, {
                            id: "input-sold-alone-offline",
                            binary: true,
                            modelValue: _ctx.model.canBeSoldAloneOffline,
                            "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.model.canBeSoldAloneOffline) = $event))
                          }, null, 8, ["modelValue"]),
                          _createElementVNode("label", _hoisted_52, _toDisplayString(_ctx.$t('extraServices.dialog.canBeSoldAlone')), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_53, [
              _createElementVNode("div", _hoisted_54, [
                _createVNode(_component_InputSwitch, {
                  id: "purchase-online-flag",
                  modelValue: _ctx.purchaseOnline,
                  "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.purchaseOnline) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("label", _hoisted_55, _toDisplayString(_ctx.$t('extraServices.dialog.canBeSoldOnline')), 1)
              ]),
              _createVNode(_component_divider, { class: "m-0" }),
              (_ctx.purchaseOnline)
                ? (_openBlock(), _createElementBlock("div", _hoisted_56, [
                    (_ctx.isTicket)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_57, [
                          _createVNode(_component_Checkbox, {
                            id: "input-hide-when-not-available-online",
                            binary: true,
                            modelValue: _ctx.model.hideWhenNotAvailableOnline,
                            "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.model.hideWhenNotAvailableOnline) = $event))
                          }, null, 8, ["modelValue"]),
                          _createElementVNode("label", _hoisted_58, _toDisplayString(_ctx.$t('extraServices.dialog.hideWhenNotAvailable')), 1)
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_59, [
                      _createVNode(_component_Checkbox, {
                        id: "input-reservation-schedule-online",
                        binary: true,
                        modelValue: _ctx.model.online,
                        "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.model.online) = $event))
                      }, null, 8, ["modelValue"]),
                      _createElementVNode("label", _hoisted_60, _toDisplayString(_ctx.$t('extraServices.dialog.canBeSoldWithSpot')), 1)
                    ]),
                    (_ctx.model.online)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                          _createElementVNode("div", _hoisted_62, [
                            _createElementVNode("label", _hoisted_63, _toDisplayString(_ctx.$t('extraServices.dialog.minNumAvailableOnline')), 1),
                            _createVNode(_component_Dropdown, {
                              id: "input-min-items-type",
                              modelValue: _ctx.model.minItemsType,
                              "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.model.minItemsType) = $event)),
                              options: _ctx.serviceSpotLimitOptions,
                              optionLabel: "label",
                              optionValue: "limit"
                            }, null, 8, ["modelValue", "options"]),
                            (_ctx.isFixedLimit(_ctx.model.minItemsType))
                              ? (_openBlock(), _createBlock(_component_InputNumber, {
                                  key: 0,
                                  min: 0,
                                  modelValue: _ctx.model.minItemsFixed,
                                  "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.model.minItemsFixed) = $event)),
                                  class: "mt-2"
                                }, null, 8, ["modelValue"]))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("div", _hoisted_64, [
                            _createElementVNode("label", _hoisted_65, _toDisplayString(_ctx.$t('extraServices.dialog.maxNumAvailableOnline')), 1),
                            _createVNode(_component_Dropdown, {
                              id: "input-max-items-type",
                              modelValue: _ctx.model.maxItemsType,
                              "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.model.maxItemsType) = $event)),
                              options: _ctx.serviceSpotLimitOptions,
                              optionLabel: "label",
                              optionValue: "limit"
                            }, null, 8, ["modelValue", "options"]),
                            (_ctx.isFixedLimit(_ctx.model.maxItemsType))
                              ? (_openBlock(), _createBlock(_component_InputNumber, {
                                  key: 0,
                                  min: 0,
                                  modelValue: _ctx.model.maxItemsFixed,
                                  "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.model.maxItemsFixed) = $event)),
                                  class: "mt-2"
                                }, null, 8, ["modelValue"]))
                              : _createCommentVNode("", true)
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.isTicket)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                          _createElementVNode("div", _hoisted_66, [
                            _createVNode(_component_Checkbox, {
                              id: "input-sold-alone-online",
                              binary: true,
                              modelValue: _ctx.model.canBeSoldAloneOnline,
                              "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.model.canBeSoldAloneOnline) = $event))
                            }, null, 8, ["modelValue"]),
                            _createElementVNode("label", _hoisted_67, _toDisplayString(_ctx.$t('extraServices.dialog.canBeSoldAlone')), 1)
                          ]),
                          (_ctx.model.canBeSoldAloneOnline)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_68, [
                                _createElementVNode("div", _hoisted_69, [
                                  _createElementVNode("label", _hoisted_70, _toDisplayString(_ctx.$t('extraServices.dialog.minNumAvailableOnline')), 1),
                                  _createVNode(_component_Dropdown, {
                                    id: "min-items-type-alone",
                                    modelValue: _ctx.model.minItemsTypeAlone,
                                    "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.model.minItemsTypeAlone) = $event)),
                                    options: _ctx.serviceNoSpotLimitOptions,
                                    optionLabel: "label",
                                    optionValue: "limit"
                                  }, null, 8, ["modelValue", "options"]),
                                  (_ctx.isFixedLimit(_ctx.model.minItemsTypeAlone))
                                    ? (_openBlock(), _createBlock(_component_InputNumber, {
                                        key: 0,
                                        min: 0,
                                        modelValue: _ctx.model.minItemsFixedAlone,
                                        "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.model.minItemsFixedAlone) = $event)),
                                        class: "mt-2"
                                      }, null, 8, ["modelValue"]))
                                    : _createCommentVNode("", true)
                                ]),
                                _createElementVNode("div", _hoisted_71, [
                                  _createElementVNode("label", _hoisted_72, _toDisplayString(_ctx.$t('extraServices.dialog.maxNumAvailableOnline')), 1),
                                  _createVNode(_component_Dropdown, {
                                    id: "max-items-type-alone",
                                    modelValue: _ctx.model.maxItemsTypeAlone,
                                    "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.model.maxItemsTypeAlone) = $event)),
                                    options: _ctx.serviceNoSpotLimitOptions,
                                    optionLabel: "label",
                                    optionValue: "limit"
                                  }, null, 8, ["modelValue", "options"]),
                                  (_ctx.isFixedLimit(_ctx.model.maxItemsTypeAlone))
                                    ? (_openBlock(), _createBlock(_component_InputNumber, {
                                        key: 0,
                                        min: 0,
                                        modelValue: _ctx.model.maxItemsFixedAlone,
                                        "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.model.maxItemsFixedAlone) = $event)),
                                        class: "mt-2"
                                      }, null, 8, ["modelValue"]))
                                    : _createCommentVNode("", true)
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ], 64))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]),
            (_ctx.showSectorOptions())
              ? (_openBlock(), _createElementBlock("div", _hoisted_73, [
                  _createElementVNode("label", _hoisted_74, _toDisplayString(_ctx.getSectorTitle()) + ":", 1),
                  _createElementVNode("div", _hoisted_75, [
                    _createVNode(_component_MultiSelect, {
                      id: "sectors",
                      modelValue: _ctx.model.sectors,
                      "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.model.sectors) = $event)),
                      filter: true,
                      options: _ctx.sectorOptions,
                      optionLabel: "label",
                      optionValue: "value"
                    }, null, 8, ["modelValue", "options"])
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}