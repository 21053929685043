import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7be5cfd0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "reservation-beach-ticket-service",
  class: "service print-addition"
}
const _hoisted_2 = { class: "flex flex-column p-3" }
const _hoisted_3 = { class: "flex flex-row align-items-center tw-gap-2" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "service-name font-bold pl-2 pr-3" }
const _hoisted_6 = {
  key: 0,
  class: "mt-2 text-sm"
}
const _hoisted_7 = {
  key: 0,
  class: "actions"
}
const _hoisted_8 = {
  key: 0,
  class: "validate"
}
const _hoisted_9 = {
  key: 1,
  class: "invalidate"
}
const _hoisted_10 = {
  key: 2,
  class: "validate"
}
const _hoisted_11 = {
  key: 2,
  class: "webtic-tickets-list"
}
const _hoisted_12 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_ctx.enabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("img", {
              class: "service-icon",
              src: require('@/assets/images/extra-services/qr-code.svg'),
              alt: ""
            }, null, 8, _hoisted_4),
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('reservationBeachTicketService.entrances')), 1),
            _createVNode(_component_Button, {
              class: "p-button-plain p-button-outlined no-print service-button",
              icon: "spi-plus",
              disabled: !_ctx.hasUpdatePermission(),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openBeachTicketDialog()))
            }, null, 8, ["disabled"])
          ]),
          (_ctx.ticketsUsed > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.ticketsUsed) + " " + _toDisplayString(_ctx.ticketsUsed === 1
            ? _ctx.$t('reservationBeachTicketService.usedTicket')
            : _ctx.$t('reservationBeachTicketService.usedTickets')), 1))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_Dialog, {
          id: "beach-ticket-dialog",
          class: "custom-dialog",
          modal: true,
          visible: _ctx.beachTicketDialog,
          "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.beachTicketDialog) = $event)),
          onHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.beachTicketDialog = false))
        }, {
          default: _withCtx(() => [
            _createElementVNode("h2", null, _toDisplayString(_ctx.$t('reservationBeachTicketService.title')), 1),
            _createElementVNode("h5", null, _toDisplayString(_ctx.$t('reservationBeachTicketService.subtitle')), 1),
            _createElementVNode("h3", null, _toDisplayString(_ctx.ticketsUsed) + " / " + _toDisplayString(_ctx.ticketsAvailable), 1),
            (_ctx.showActions)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  (!_ctx.license.webticId)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_Button, {
                          label: _ctx.$t('reservationBeachTicketService.validateOne'),
                          class: "p-button-lg",
                          onClick: _ctx.validateOne,
                          disabled: !_ctx.canValidate
                        }, null, 8, ["label", "onClick", "disabled"]),
                        _createVNode(_component_Button, {
                          label: _ctx.$t('reservationBeachTicketService.validateAll'),
                          class: "p-button-lg",
                          onClick: _ctx.validateAll,
                          disabled: !_ctx.canValidate
                        }, null, 8, ["label", "onClick", "disabled"])
                      ]))
                    : _createCommentVNode("", true),
                  (!_ctx.license.webticId)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_component_Button, {
                          label: _ctx.$t('reservationBeachTicketService.invalidateOne'),
                          class: "p-button-outlined",
                          onClick: _ctx.invalidateOne,
                          disabled: !_ctx.canInvalidate
                        }, null, 8, ["label", "onClick", "disabled"]),
                        _createVNode(_component_Button, {
                          label: _ctx.$t('reservationBeachTicketService.invalidateAll'),
                          class: "p-button-outlined",
                          onClick: _ctx.invalidateAll,
                          disabled: !_ctx.canInvalidate
                        }, null, 8, ["label", "onClick", "disabled"])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.license.webticId)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        _createVNode(_component_Button, {
                          label: _ctx.$t('reservationBeachTicketService.validateAll'),
                          class: "p-button-lg",
                          onClick: _ctx.markAllSiaeTicket,
                          disabled: !_ctx.canValidate
                        }, null, 8, ["label", "onClick", "disabled"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createBlock(_component_Message, {
                  key: 1,
                  severity: "warn",
                  closable: false
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('reservationBeachTicketService.warningMessage')), 1)
                  ]),
                  _: 1
                })),
            (_ctx.webticTickets && _ctx.webticTickets.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.webticTickets, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: _normalizeClass(
            'item' +
            (item.marked ? ' marked' : '') +
            (item.eventDate !== _ctx.currentDay ? ' not-valid' : '') +
            (item.barcode ? ' rbo' : '') +
            (!item.marked && item.eventDate == _ctx.currentDay && item.barcode
              ? ' clickable'
              : '')
          ),
                      onClick: ($event: any) => (
            !item.marked && item.eventDate == _ctx.currentDay && item.barcode
              ? _ctx.markSiaeTicket(item.barcode)
              : null
          )
                    }, _toDisplayString(item.barcode ?? 'RBO') + " - " + _toDisplayString(item.eventDate), 11, _hoisted_12))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["visible"])
      ]))
    : _createCommentVNode("", true)
}