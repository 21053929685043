/* eslint-disable import/no-cycle */
import axios, { AxiosResponse } from 'axios';
import {
  ApiPriceListUpdatePayload,
  ApiPriceListUpdateResponse,
  ApiPriceListFindPayload,
  ApiPriceListFindResponse,
  ApiPriceListGetPeriodCombinationsPayload,
  ApiPriceListGetPeriodCombinationsResponse,
  ApiPricePeriodUpdatePayload,
  ApiPricePeriodUpdateResponse,
  ApiPricePeriodUploadResponse,
  ApiPricePeriodCopyPayload,
  ApiPriceListSwitchPayload,
  ApiPriceListGetResponse,
  ApiPriceListCreateResponse,
  ApiPriceListCreatePayload,
  ApiPriceListSearchResponse,
  ApiPriceListQualityCheckPayload,
  ApiPriceListQualityCheckResponse,
  ApiPriceListEstimatePayload,
  ApiPriceListEstimateResponse,
} from '@/models/api';
import apiService from './apiService';
import {
  PriceList,
  PriceListEstimate,
  PriceListQualityCheck,
} from '@/models/priceList';

export default {
  async get(priceListId: number): Promise<PriceList> {
    const res: AxiosResponse<ApiPriceListGetResponse> = await axios.get(
      apiService.priceList.get(priceListId),
    );
    return res.data.result.priceList;
  },
  async list(): Promise<Array<PriceList>> {
    const res: AxiosResponse<ApiPriceListSearchResponse> = await axios.get(
      apiService.priceList.list(),
    );
    return res.data.result.priceLists;
  },
  async find(payload: ApiPriceListFindPayload): Promise<Array<PriceList>> {
    const res: AxiosResponse<ApiPriceListFindResponse> = await axios.get(
      apiService.priceList.find(payload),
    );
    return res.data.result.priceLists;
  },
  async create(payload: ApiPriceListCreatePayload): Promise<PriceList> {
    const res: AxiosResponse<ApiPriceListCreateResponse> = await axios.post(
      apiService.priceList.create(),
      payload,
    );
    return res.data.result.priceList;
  },
  async switch(
    priceListId: number,
    payload: ApiPriceListSwitchPayload,
  ): Promise<void> {
    return axios.post(apiService.priceList.switch(priceListId), payload);
  },
  async update(
    priceListId: number,
    payload: ApiPriceListUpdatePayload,
  ): Promise<PriceList> {
    const res: AxiosResponse<ApiPriceListUpdateResponse> = await axios.patch(
      apiService.priceList.update(priceListId),
      payload,
    );
    return res.data.result.priceList;
  },
  async delete(priceListId: number): Promise<any> {
    const res: AxiosResponse<any> = await axios.delete(
      apiService.priceList.delete(priceListId),
    );
    return res.data;
  },
  async getPeriodCombinations(
    payload: ApiPriceListGetPeriodCombinationsPayload,
  ): Promise<ApiPriceListGetPeriodCombinationsResponse> {
    const res: AxiosResponse<ApiPriceListGetPeriodCombinationsResponse> =
      await axios.get(
        apiService.priceList.getPeriodCombinations(
          payload.pricePeriodId,
          payload.weekend,
        ),
      );
    return res.data;
  },
  async createPeriod(
    payload: ApiPricePeriodUpdatePayload,
  ): Promise<ApiPricePeriodUpdateResponse> {
    const res: AxiosResponse<ApiPricePeriodUpdateResponse> = await axios.post(
      apiService.priceList.createPeriod(),
      payload,
    );
    return res.data;
  },
  async updatePeriod(
    pricePeriodId: number,
    payload: ApiPricePeriodUpdatePayload,
  ): Promise<ApiPricePeriodUpdateResponse> {
    const res: AxiosResponse<ApiPricePeriodUpdateResponse> = await axios.patch(
      apiService.priceList.updatePeriod(pricePeriodId),
      payload,
    );
    return res.data;
  },
  async deletePeriod(pricePeriodId: number): Promise<any> {
    const res: AxiosResponse<any> = await axios.delete(
      apiService.priceList.deletePeriod(pricePeriodId),
    );
    return res.data;
  },
  async importPeriod(
    pricePeriodId: number,
    payload: FormData,
  ): Promise<ApiPricePeriodUploadResponse> {
    const res: AxiosResponse<ApiPricePeriodUploadResponse> = await axios.post(
      apiService.priceList.importPeriod(pricePeriodId),
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res.data;
  },
  async copyPeriod(
    pricePeriodId: number,
    payload: ApiPricePeriodCopyPayload,
  ): Promise<ApiPricePeriodUploadResponse> {
    const res: AxiosResponse<ApiPricePeriodUploadResponse> = await axios.post(
      apiService.priceList.copyPeriod(pricePeriodId),
      payload,
    );
    return res.data;
  },
  async deleteCombinations(
    pricePeriodId: number,
    weekend: number,
  ): Promise<any> {
    const res: AxiosResponse<any> = await axios.delete(
      apiService.priceList.deleteCombinations(pricePeriodId, weekend),
    );
    return res.data;
  },
  async exportPeriod(pricePeriodId: number, payload: any): Promise<any> {
    const res: AxiosResponse<any> = await axios.post(
      apiService.priceList.exportPeriod(pricePeriodId),
      payload,
      {
        headers: {
          Accept: 'text/csv',
        },
      },
    );
    return res.data;
  },
  async qualityCheck(
    priceListId: number,
    payload: ApiPriceListQualityCheckPayload,
  ): Promise<PriceListQualityCheck> {
    const res: AxiosResponse<ApiPriceListQualityCheckResponse> =
      await axios.post(apiService.priceList.qualityCheck(priceListId), payload);
    return res.data.result;
  },
  async estimate(
    priceListId: number,
    payload: ApiPriceListEstimatePayload,
  ): Promise<Array<PriceListEstimate>> {
    const res: AxiosResponse<ApiPriceListEstimateResponse> = await axios.post(
      apiService.priceList.estimate(priceListId),
      payload,
    );
    return res.data.result.sectorEstimations;
  },
};
