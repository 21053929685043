
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { Field, Form } from 'vee-validate';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { DateTime } from 'luxon';
import clone from 'lodash/clone';
import { DropdownChangeEvent } from 'primevue/dropdown';
import InputTooltip from '@/components/shared/InputTooltip.vue';
import ToggleButtonCustomVue from '@/components/shared/ToggleButtonCustom.vue';
import {
  ListElementBool,
  ListElementNumber,
  ListElementString,
  StagingConfigurator,
  StripePayload,
  DropdownOption,
} from '@/models';
import {
  License,
  LicenseType,
  PayloadBookingSettings,
  LicenseBookingSectorFull,
  LicenseBookingSectorServices,
  LicenseSectorStagingDetail,
  LicenseSectorBookingStagingDetail,
  LicenseSharingCalculationMode,
  LicenseBookingCartMode,
} from '@/models/license';
import licenseService from '@/services/licenseService';
import toastUtil from '@/utils/toastUtil';
import { ToastSeverity } from '@/models/toast';
import { StripeTransferSchedule } from '@/models/stripe';
import {
  BookingInvoiceType,
  BookingOnlineServiceCostType,
  BookingPaymentFeesBracketsMode,
  BookingPaymentFeesCustomerMode,
  BookingPaymentFeesMode,
  BookingPaymentInvoiceMode,
  BookingRefundPolicy,
  BookingRule,
  BookingRuleConfig,
} from '@/models/booking';
import CalendarInput from '@/components/shared/CalendarInput.vue';
import { ApiCardTypeListResponse } from '@/models/api';
import cardTypeService from '@/services/cardTypeService';
import { CardType } from '@/models/cardType';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';
import { Sector } from '@/models/sector';

interface PanelStates {
  [key: string]: boolean;
}

export default defineComponent({
  name: 'bookingConfig',
  components: {
    Form,
    Field,
    CalendarInput,
    ToggleButtonCustomVue,
    InputTooltip,
  },
  data() {
    const bookingCartModeOptions: DropdownOption[] = [
      {
        label: this.$t('BookingConfigView.bookingCartMode.old'),
        value: LicenseBookingCartMode.OLD,
      },
      {
        label: this.$t('BookingConfigView.bookingCartMode.2022'),
        value: LicenseBookingCartMode.NEW,
      },
      {
        label: this.$t('BookingConfigView.bookingCartMode.2024'),
        value: LicenseBookingCartMode.TICKET_ONLY,
      },
    ];

    return {
      isLoading: true,
      legacyYBUrl:
        process.env.VUE_APP_LEGACY_DOMAIN + process.env.VUE_APP_LEGACY_YB,
      rules: [] as Array<BookingRuleConfig>,
      cardTypes: [] as Array<CardType>,
      bookingCartModeOptions,
      states: [
        {
          name: this.$t('BookingConfigView.stateDates.state.public'),
          code: true,
        },
        {
          name: this.$t('BookingConfigView.stateDates.state.private'),
          code: false,
        },
      ] as Array<ListElementBool>,
      specialModes: [
        {
          name: this.$t('BookingConfigView.stateDates.specialMode.none'),
          code: 0,
        },
        {
          name: this.$t('BookingConfigView.stateDates.specialMode.castel'),
          code: 1,
        },
      ] as Array<ListElementNumber>,
      halfDayReservations: [
        {
          name: this.$t(
            'BookingConfigView.stateDates.halfDayReservation.disable',
          ),
          code: 0,
        },
        {
          name: this.$t(
            'BookingConfigView.stateDates.halfDayReservation.available',
          ),
          code: 1,
        },
        {
          name: this.$t(
            'BookingConfigView.stateDates.halfDayReservation.halfDay',
          ),
          code: 2,
        },
      ] as Array<ListElementNumber>,
      blockReservatioNDays: [
        {
          name: this.$t('BookingConfigView.stateDates.blockReservatioNDay.no'),
          code: 0,
        },
        {
          name: `1 ${this.$t(
            'BookingConfigView.stateDates.blockReservatioNDay.day',
          )}`,
          code: 1,
        },
        {
          name: `2 ${this.$t(
            'BookingConfigView.stateDates.blockReservatioNDay.days',
          )}`,
          code: 2,
        },
        {
          name: `3 ${this.$t(
            'BookingConfigView.stateDates.blockReservatioNDay.days',
          )}`,
          code: 3,
        },
        {
          name: `7 ${this.$t(
            'BookingConfigView.stateDates.blockReservatioNDay.days',
          )}`,
          code: 7,
        },
        {
          name: `30 ${this.$t(
            'BookingConfigView.stateDates.blockReservatioNDay.days',
          )}`,
          code: 30,
        },
      ] as Array<ListElementNumber>,
      modes: [
        { name: this.$t('BookingConfigView.mode.map'), code: 1 },
        { name: this.$t('BookingConfigView.mode.ticket'), code: 2 },
      ] as Array<ListElementNumber>,
      notifyStatusList: [
        { name: this.$t('BookingConfigView.notify.enable'), code: '1' },
      ] as Array<ListElementString>,
      state: String,
      modePaymentList: [
        {
          name: this.$t('BookingConfigView.payment.modePayment.sea'),
          code: 1,
        },
        {
          name: this.$t('BookingConfigView.payment.modePayment.online'),
          code: 2,
        },
        {
          name: this.$t('BookingConfigView.payment.modePayment.transfer'),
          code: 3,
        },
        {
          name: this.$t('BookingConfigView.payment.modePayment.seaOnline'),
          code: 4,
        },
        {
          name: this.$t('BookingConfigView.payment.modePayment.transferOnline'),
          code: 5,
        },
        {
          name: this.$t('BookingConfigView.payment.modePayment.seaTransfer'),
          code: 6,
        },
        {
          name: this.$t('BookingConfigView.payment.modePayment.all'),
          code: 7,
        },
      ] as Array<ListElementNumber>,
      paymentCommissionModes: [
        {
          name: this.$t('BookingConfigView.paymentCommission.mode.gestore'),
          code: BookingPaymentFeesMode.MANAGER,
        },
        {
          name: this.$t('BookingConfigView.paymentCommission.mode.client'),
          code: BookingPaymentFeesMode.CUSTOMER,
        },
      ] as Array<ListElementNumber>,
      bookingPaymentFeesCustomerModeOptions: [
        {
          name: this.$t(
            'BookingConfigView.paymentCommission.customerMode.proportional',
          ),
          code: BookingPaymentFeesCustomerMode.PROPORTIONAL,
        },
        {
          name: this.$t(
            'BookingConfigView.paymentCommission.customerMode.brackets',
          ),
          code: BookingPaymentFeesCustomerMode.BRACKETS,
        },
      ] as Array<ListElementNumber>,
      bookingPaymentFeesBracketsModeOptions: [
        {
          name: this.$t(
            'BookingConfigView.paymentCommission.bracketsMode.fixed',
          ),
          code: BookingPaymentFeesBracketsMode.FIXED,
        },
        {
          name: this.$t(
            'BookingConfigView.paymentCommission.bracketsMode.dynamic',
          ),
          code: BookingPaymentFeesBracketsMode.DYNAMIC,
        },
      ] as Array<ListElementNumber>,
      bookingOnlineServiceCostTypeOptions: [
        {
          name: this.$t('common.fixed'),
          code: BookingOnlineServiceCostType.FIXED,
        },
        {
          name: this.$t('common.percentage'),
          code: BookingOnlineServiceCostType.PERCENTAGE,
        },
      ] as Array<ListElementNumber>,
      onlineTaxationsList: [
        {
          name: this.$t('BookingConfigView.receipts.onlineTaxation.spiagge'),
          code: 1,
        },
        {
          name: this.$t('BookingConfigView.receipts.onlineTaxation.own'),
          code: 2,
        },
        {
          name: this.$t(
            'BookingConfigView.receipts.onlineTaxation.spiaggeThirdParty',
          ),
          code: 3,
        },
      ] as Array<ListElementNumber>,
      typeReceiptsList: [
        {
          name: this.$t('BookingConfigView.receipts.typeReceipts.received'),
          code: 3,
        },
        {
          name: this.$t('BookingConfigView.receipts.typeReceipts.Invoice'),
          code: 1,
        },
        {
          name: this.$t('BookingConfigView.receipts.typeReceipts.both'),
          code: 2,
        },
      ] as Array<ListElementNumber>,
      justificationList: [
        {
          name: this.$t('BookingConfigView.receipts.justification.balance'),
          code: 1,
        },
        {
          name: this.$t('BookingConfigView.receipts.justification.deposit'),
          code: 2,
        },
      ] as Array<ListElementNumber>,
      reservatioPaymentStateList: [
        {
          name: this.$t(
            'BookingConfigView.receipts.reservatioPaymentState.confirmed',
          ),
          code: 2,
        },
        {
          name: this.$t(
            'BookingConfigView.receipts.reservatioPaymentState.provisional',
          ),
          code: 0,
        },
      ] as Array<ListElementNumber>,
      stripeAccountTypes: [
        {
          name: this.$t('BookingConfigView.stripe.accountType.standard'),
          code: 'standard',
        },
        {
          name: this.$t('BookingConfigView.stripe.accountType.custom'),
          code: 'custom',
        },
      ] as Array<ListElementString>,
      listScheduleTransferStripe: [
        {
          name: this.$t('BookingConfigView.stripe.transferSchedule.weekly'),
          code: StripeTransferSchedule.WEEKLY,
        },
        {
          name: this.$t('BookingConfigView.stripe.transferSchedule.biweekly'),
          code: StripeTransferSchedule.BIWEEKLY,
        },
        {
          name: this.$t('BookingConfigView.stripe.transferSchedule.monthly'),
          code: StripeTransferSchedule.MONTHLY,
        },
        {
          name: this.$t('BookingConfigView.stripe.transferSchedule.otherDay'),
          code: StripeTransferSchedule.OTHER_DAY,
        },
      ] as Array<ListElementNumber>,
      localLicense: {} as License,
      bookingVisible: false,
      show: false,
      showBookingLimit: false,
      stateDatesIsCollapsed: true,
      servicesIsCollapsed: true,
      modeIsCollapsed: true,
      notifyIsCollapsed: true,
      paymentIsCollapsed: true,
      receiptsIsCollapsed: true,
      refundsIsCollapsed: true,
      regulationIsCollapsed: true,
      noteIsCollapsed: true,
      analysesIsCollapsed: true,
      umbrellaSharingIsCollapsed: true,
      showPreview: false,
      notifyStatus: '1',
      creditDayResold: 0,
      ruleSelected: 0,
      panelStates: {
        dates: true,
        modes: true,
        services: true,
        notify: true,
        payment: true,
        receipts: true,
        refunds: true,
        regulation: true,
        note: true,
        analyses: true,
        umbrella: true,
      } as PanelStates,
      sharingCalculationModeOptions: [
        {
          label: this.$t(
            'BookingConfigView.umbrellaSharing.calculationModeOption.flat',
          ),
          value: LicenseSharingCalculationMode.FLAT,
        },
        {
          label: this.$t(
            'BookingConfigView.umbrellaSharing.calculationModeOption.percentage',
          ),
          value: LicenseSharingCalculationMode.PERCENTAGE,
        },
      ] as Array<DropdownOption>,
      refundPolicyOptions: [
        {
          name: this.$t('BookingConfigView.Refunds.cancellationCond.never'),
          code: BookingRefundPolicy.NEVER,
        },
        {
          name: this.$t('BookingConfigView.Refunds.cancellationCond.day'),
          code: BookingRefundPolicy.DAY_BEFORE,
        },
        {
          name: this.$t('BookingConfigView.Refunds.cancellationCond.days2'),
          code: BookingRefundPolicy.TWO_DAYS,
        },
        {
          name: this.$t('BookingConfigView.Refunds.cancellationCond.days5'),
          code: BookingRefundPolicy.FIVE_DAYS,
        },
        {
          name: this.$t('BookingConfigView.Refunds.cancellationCond.days6'),
          code: BookingRefundPolicy.SIX_DAYS,
        },
        {
          name: this.$t('BookingConfigView.Refunds.cancellationCond.days7'),
          code: BookingRefundPolicy.SEVEN_DAYS,
        },
        {
          name: this.$t('BookingConfigView.Refunds.cancellationCond.days10'),
          code: BookingRefundPolicy.TEN_DAYS,
        },
        {
          name: this.$t('BookingConfigView.Refunds.cancellationCond.days14'),
          code: BookingRefundPolicy.FOURTEEN_DAYS,
        },
        {
          name: this.$t('BookingConfigView.Refunds.cancellationCond.days15'),
          code: BookingRefundPolicy.FIFTEEN_DAYS,
        },
      ] as Array<ListElementNumber>,
      bookingPaymentInvoiceMode: clone(BookingPaymentInvoiceMode),
    };
  },
  methods: {
    onBookingPaymentInvoiceMode(): void {
      /**
       *  Set booking invoice type default to receipt
       */
      this.localLicense.bookingInvoiceTypes = BookingInvoiceType.RECEIPT;
    },
    catchStagingValue(response: StagingConfigurator) {
      this.sectors.forEach((element: LicenseBookingSectorFull) => {
        if (response.sector === element.id) {
          switch (response.type) {
            case 'beds':
              // eslint-disable-next-line no-param-reassign
              element.staging.beds.default = response.default;
              // eslint-disable-next-line no-param-reassign
              element.staging.beds.min = response.min;
              // eslint-disable-next-line no-param-reassign
              element.staging.beds.max = response.max;
              break;
            case 'deckChairs':
              // eslint-disable-next-line no-param-reassign
              element.staging.deckChairs.default = response.default;
              // eslint-disable-next-line no-param-reassign
              element.staging.deckChairs.min = response.min;
              // eslint-disable-next-line no-param-reassign
              element.staging.deckChairs.max = response.max;
              break;
            case 'chairs':
              // eslint-disable-next-line no-param-reassign
              element.staging.chairs.default = response.default;
              // eslint-disable-next-line no-param-reassign
              element.staging.chairs.min = response.min;
              // eslint-disable-next-line no-param-reassign
              element.staging.chairs.max = response.max;
              break;
            case 'maxiBeds':
              // eslint-disable-next-line no-param-reassign
              element.staging.maxiBeds.default = response.default;
              // eslint-disable-next-line no-param-reassign
              element.staging.maxiBeds.min = response.min;
              // eslint-disable-next-line no-param-reassign
              element.staging.maxiBeds.max = response.max;
              break;
            case 'minMax':
              // eslint-disable-next-line no-param-reassign
              element.staging.minMax.min = response.min;
              // eslint-disable-next-line no-param-reassign
              element.staging.minMax.max = response.max;
              break;
            default:
              break;
          }
        }
      });
    },
    async submit() {
      const sectors = cloneDeep(this.sectors);
      this.sectors = sectors;
      const licenseToSend = cloneDeep(this.localLicense);
      const send = {
        bookingStatus: licenseToSend.bookingStatus,
        bookingStartPeriod: licenseToSend.bookingStartPeriod,
        bookingEndPeriod: licenseToSend.bookingEndPeriod,
        specialMode: licenseToSend.specialMode,
        bookingToday: licenseToSend.bookingToday,
        bookingNextYear: licenseToSend.bookingNextYear,
        bookingTimeLimit: licenseToSend.bookingTimeLimit,
        bookingHalfDay: licenseToSend.bookingHalfDay,
        bookingSeasonal: licenseToSend.bookingSeasonal,
        bookingEndPeriodOffset: licenseToSend.bookingEndPeriodOffset,
        bookingUserUmbrellasLimit: licenseToSend.bookingUserUmbrellasLimit,
        bookingWidgetShowImg: licenseToSend.bookingWidgetShowImg,
        bookingMode: licenseToSend.bookingMode,
        bookingSectorServices: licenseToSend.bookingSectorServices,
        bookingEmailContact: licenseToSend.bookingEmailContact,
        bookingPaymentMode: licenseToSend.bookingPaymentMode,
        bookingPaymentCreditcardLimit:
          licenseToSend.bookingPaymentCreditcardLimit,
        bookingPaymentPayCardsActive:
          licenseToSend.bookingPaymentPayCardsActive,
        bookingPaymentPaypal: licenseToSend.bookingPaymentPaypal,
        bookingPaymentPaypalSandbox: licenseToSend.bookingPaymentPaypalSandbox,
        bookingPaymentFeesMode: licenseToSend.bookingPaymentFeesMode,
        bookingPaymentFeesCustomerMode:
          licenseToSend.bookingPaymentFeesCustomerMode,
        bookingPaymentFeesBracketsMode:
          licenseToSend.bookingPaymentFeesBracketsMode,
        bookingPaymentFeesPercentage:
          licenseToSend.bookingPaymentFeesPercentage,
        bookingPaymentStripeTest: licenseToSend.bookingPaymentStripeTest,
        bookingPaymentStripeAcctId: licenseToSend.bookingPaymentStripeAcctId,
        bookingPaymentStripeAcctType:
          licenseToSend.bookingPaymentStripeAcctType,
        bookingPaymentStripeAcctActive:
          licenseToSend.bookingPaymentStripeAcctActive,
        bookingPaymentStripeSecondAcctId:
          licenseToSend.bookingPaymentStripeSecondAcctId,
        bookingPaymentStripeSecondAcctType:
          licenseToSend.bookingPaymentStripeSecondAcctType,
        bookingPaymentStripeSecondAcctActive:
          licenseToSend.bookingPaymentStripeSecondAcctActive,
        bookingPaymentFeesFixed: licenseToSend.bookingPaymentFeesFixed,
        bookingPaymentIban: licenseToSend.bookingPaymentIban,
        bookingOnlineServiceCost: licenseToSend.bookingOnlineServiceCost,
        bookingOnlineServiceCostType:
          licenseToSend.bookingOnlineServiceCostType,
        bookingOnlineServiceMaxCost: licenseToSend.bookingOnlineServiceMaxCost,
        portalPaymentFeesPercentage: licenseToSend.portalPaymentFeesPercentage,
        bookingPaymentInvoiceMode: licenseToSend.bookingPaymentInvoiceMode,
        bookingInvoiceTypes: licenseToSend.bookingInvoiceTypes,
        bookingInvoiceDescription: licenseToSend.bookingInvoiceDescription,
        bookingPrintFiscalInvoice: licenseToSend.bookingPrintFiscalInvoice,
        bookingTaxCodeRequired: licenseToSend.bookingTaxCodeRequired,
        bookingConfirmStatus: licenseToSend.bookingConfirmStatus,
        bookingRefundPolicy: licenseToSend.bookingRefundPolicy,
        bookingPolicy: licenseToSend.bookingPolicy,
        bookingPolicyUrl: licenseToSend.bookingPolicyUrl,
        bookingSelectPeriodNotes: licenseToSend.bookingSelectPeriodNotes,
        bookingMapNotes: licenseToSend.bookingMapNotes,
        bookingServicesNotes: licenseToSend.bookingServicesNotes,
        bookingUserDataNotes: licenseToSend.bookingUserDataNotes,
        bookingEmailNotes: licenseToSend.bookingEmailNotes,
        bookingEmailNotesCheckin: licenseToSend.bookingEmailNotesCheckin,
        bookingNotesFillable: licenseToSend.bookingNotesFillable,
        bookingPixelAds: licenseToSend.bookingPixelAds,
        bookingPixelFb: licenseToSend.bookingPixelFb,
        sharing: licenseToSend.sharing,
        sharingCardType: licenseToSend.sharingCardType,
        sharingValue: licenseToSend.sharingValue,
        sharingCalculationMode: licenseToSend.sharingCalculationMode,
        bookingVisible: this.bookingVisible,
        bookingPaymentStripeTransferSchedule:
          licenseToSend.bookingPaymentStripeTransferSchedule,
        bookingCartMode: licenseToSend.bookingCartMode,
        bookingWeatherInsuranceEnabled:
          licenseToSend.bookingWeatherInsuranceEnabled,
        bookingNoShowInsuranceEnabled:
          licenseToSend.bookingNoShowInsuranceEnabled,
        bookingOnlineCabin: licenseToSend.bookingOnlineCabin,
        bookingSponsorEnabled: licenseToSend.bookingSponsorEnabled,
      } as PayloadBookingSettings;

      try {
        const response = await licenseService.updateBookingSettings(
          this.license?.license as string,
          send,
        );
        this.localLicense = cloneDeep(response.result.license);
        this.$store.commit('session/setLicense', response.result.license);
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
        this.scrollToTop();
      } catch (error) {
        const errorCode: string = (error as any).response.data.error;
        let errMessage = this.$t('toast.error.msg');
        if (['i_booking_001', 'i_booking_002'].includes(errorCode)) {
          errMessage = this.$t(`errors.${errorCode}`);
        }
        this.$spiagge.toast.error(errMessage);
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async addNewRule() {
      const rule = {
        id: 0,
        sector: null,
        start: Math.floor(DateTime.now().startOf('day').toSeconds()),
        end: Math.floor(DateTime.now().startOf('day').toSeconds()),
        minDays: 0,
        maxDays: 0,
        startDate: DateTime.now().startOf('day').toJSDate(),
        endDate: DateTime.now().startOf('day').toJSDate(),
      } as BookingRuleConfig;
      const toSend = this.convertViewToModel(rule);
      try {
        if (this.license) {
          const ruleReceive = await licenseService.addBookingSectorsRule(
            this.license.license,
            toSend,
          );
          this.rules.push(this.convertModelToView(ruleReceive));
        }
      } catch (error) {
        // console.log(error);
      }
    },
    async removeNewRule(index: number) {
      const rule = cloneDeep(this.rules[index]) as BookingRuleConfig;
      try {
        if (this.license) {
          await licenseService.removeBookingSectorsRule(
            this.license.license,
            rule.id.toString(),
          );
          this.rules.splice(index, 1);
        }
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.dataBaseDeleteTitle'),
            this.$t('toast.save.dataBaseDeleteContent'),
          ),
        );
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
        // console.log(error);
      }
    },
    async updateNewRule(index: number) {
      const rule = cloneDeep(this.rules[index]) as BookingRuleConfig;
      const toSend = this.convertViewToModel(rule);
      try {
        if (this.license) {
          await licenseService.updateBookingSectorsRule(
            this.license.license,
            toSend,
          );
          this.$toast.add(
            toastUtil.build(
              ToastSeverity.SUCCESS,
              this.$t('toast.save.title'),
              this.$t('toast.save.content'),
            ),
          );
        }
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
        // console.log(error);
      }
    },
    selectionRule(index: number): void {
      this.ruleSelected = index;
    },
    convertModelToView(value: BookingRule): BookingRuleConfig {
      const rule = {
        id: value.id,
        end: value.end,
        start: value.start,
        sector: value.sector,
        maxDays: value.maxDays,
        minDays: value.minDays,
      } as BookingRuleConfig;
      rule.endDate = DateTime.fromSeconds(
        value.end || DateTime.now().startOf('day').toSeconds(),
      ).toJSDate();
      rule.startDate = DateTime.fromSeconds(
        value.start || DateTime.now().startOf('day').toSeconds(),
      ).toJSDate();
      return rule;
    },
    convertViewToModel(value: BookingRuleConfig): BookingRule {
      const rule = {
        id: value.id,
        sector: value.sector,
        maxDays: value.maxDays,
        minDays: value.minDays,
      } as BookingRule;
      const start = value.startDate || new Date();
      const end = value.endDate || new Date();
      const dateTimeStart = DateTime.fromFormat(
        `${start.getDate()}-${
          (start.getMonth() || 0) + 1
        }-${start?.getFullYear()}`,
        'd-M-yyyy',
      );
      const dateTimeEnd = DateTime.fromFormat(
        `${end.getDate()}-${(end.getMonth() || 0) + 1}-${end.getFullYear()}`,
        'd-M-yyyy',
      );
      rule.start = dateTimeStart.toSeconds();
      rule.end = dateTimeEnd.toSeconds();
      return rule;
    },
    toggle(name: string) {
      // eslint-disable-next-line no-param-reassign
      this.panelStates[name] = !this.panelStates[name];
    },
    async stripePosAccountConnection(type: string) {
      const payload: StripePayload = {
        type,
        scope: null,
        code: null,
        state: null,
      };

      try {
        const licenseCode = this.localLicense.license;
        const response = await licenseService.stripePosAccountInitConnection(
          licenseCode,
          payload,
        );
        window.open(response.stripeAccountLink);
      } catch (error) {
        // console.log(error);
      }
    },
    async stripeFunction(type: string) {
      const payload = {} as StripePayload;
      payload.type = type;
      try {
        const licenseCode = this.localLicense.license;
        const response = await licenseService.stripeConnectionInit(
          licenseCode,
          payload,
        );
        if (type === 'custom') {
          window.open(response.url);
        } else {
          window.open(response as unknown as string);
        }
      } catch (error) {
        // console.log(error);
      }
    },
    checkStagingValues(value?: LicenseSectorStagingDetail): boolean {
      if (value) {
        if (value.min > 0) {
          return true;
        }
        if (value.max > 0) {
          return true;
        }
        if (value.default > 0) {
          return true;
        }
      }
      return true;
    },
    checkStagingMinMaxValues(
      value?: LicenseSectorBookingStagingDetail,
    ): boolean {
      if (value) {
        if (value.min > 0) {
          return true;
        }
        if (value.max > 0) {
          return true;
        }
      }
      return false;
    },
    checkSectorValues(value: LicenseBookingSectorFull): boolean {
      if (this.checkStagingValues(value.staging.beds)) {
        return true;
      }
      if (this.checkStagingValues(value.staging.chairs)) {
        return true;
      }
      if (this.checkStagingValues(value.staging.deckChairs)) {
        return true;
      }
      if (this.checkStagingValues(value.staging.maxiBeds)) {
        return true;
      }
      if (this.checkStagingMinMaxValues(value.staging.minMax)) {
        return true;
      }
      return false;
    },
    onBookingSponsorEnabledChange(): void {
      if (this.localLicense.bookingSponsorEnabled) {
        this.$confirm.require({
          message: this.$t('BookingConfigView.confirm.message'),
          header: this.$t('BookingConfigView.confirm.title'),
          icon: 'pi pi-info-circle',
          acceptLabel: this.$t('BookingConfigView.confirm.acceptLabel'),
          accept: () => {
            // do nothing
          },
          rejectLabel: this.$t('button.cancel'),
          reject: () => {
            this.localLicense.bookingSponsorEnabled = false;
          },
        });
      }
    },
  },
  computed: {
    ...mapState('session', ['sectors']),
    FEATURE_PERMISSION_ACTION_CONFIG() {
      return FEATURE_PERMISSION_ACTION_CONFIG;
    },
    FEATURE_PERMISSION_CONFIG() {
      return FEATURE_PERMISSION_CONFIG;
    },
    permissionsUtil() {
      return permissionsUtil;
    },
    license(): License | null {
      return this.$store.getters['session/license'];
    },
    canEditSupport(): boolean {
      return this.$store.getters['session/roleUser'] === 'superuser';
    },
    isSuperUser(): boolean {
      return this.$store.getters['session/roleUser'] === 'superuser';
    },
    bookingStartCalendar: {
      get(): Date {
        return DateTime.fromFormat(
          this.localLicense.bookingStartPeriod ?? '',
          'dd-MM',
        ).toJSDate();
      },
      set(value: Date): void {
        const dateString = `${value.getDate()}-${value.getMonth() + 1}`;
        this.localLicense.bookingStartPeriod = DateTime.fromFormat(
          dateString,
          'd-M',
        ).toFormat('dd-MM');
      },
    },
    bookingEndCalendar: {
      get(): Date {
        return DateTime.fromFormat(
          this.localLicense.bookingEndPeriod ?? '',
          'dd-MM',
        ).toJSDate();
      },
      set(value: Date): void {
        const dateString = `${value.getDate()}-${value.getMonth() + 1}`;
        this.localLicense.bookingEndPeriod = DateTime.fromFormat(
          dateString,
          'd-M',
        ).toFormat('dd-MM');
      },
    },

    ruleSectorOptions(): Array<DropdownOption> {
      const options = [
        {
          label: this.$t('common.all'),
          value: null,
        },
      ] as Array<DropdownOption>;

      const sectors = this.sectors.map((sector: Sector) => ({
        label: sector.header.name,
        value: sector.header.id,
      }));

      return [...options, ...sectors];
    },
    cardTypeOptions(): Array<DropdownOption> {
      const options = [
        {
          label: this.$t('BookingConfigView.umbrellaSharing.card.none'),
          value: null,
        },
      ] as Array<DropdownOption>;
      // eslint-disable-next-line no-restricted-syntax
      for (const cardType of this.cardTypes) {
        options.push({
          label: cardType.name,
          value: cardType.idWorldCardType,
        });
      }
      return options;
    },
    ...mapGetters('session', ['bookingSectors']),
  },
  async beforeMount() {
    // TODO: send back to license select if no license is set
    if (this.license) {
      // Update license config in store
      await this.$store.dispatch('session/setLicense', this.license.license);
      // Get booking rules
      const rules = await licenseService.getBookingSectorsRules(
        this.license.license,
      );
      if (rules) {
        this.rules = rules.map((element) => this.convertModelToView(element));
      }
      // Get portal file
      // Get card types
      const res: ApiCardTypeListResponse = await cardTypeService.list();
      this.cardTypes = res.result.cardTypes;
    }
    merge(this.localLicense, this.license as License);
    this.bookingVisible = this.localLicense.beach?.bookingVisible ?? false;
    this.localLicense.bookingPaymentFeesPercentage =
      this.localLicense.bookingPaymentFeesPercentage &&
      this.localLicense.bookingPaymentFeesPercentage > 0
        ? this.localLicense.bookingPaymentFeesPercentage
        : 0;
    this.localLicense.bookingPaymentFeesFixed =
      this.localLicense.bookingPaymentFeesFixed &&
      this.localLicense.bookingPaymentFeesFixed > 0
        ? this.localLicense.bookingPaymentFeesFixed
        : 0;
    this.localLicense.bookingUserUmbrellasLimit =
      !this.localLicense.bookingUserUmbrellasLimit ||
      this.localLicense.bookingUserUmbrellasLimit === 0
        ? 1
        : this.localLicense.bookingUserUmbrellasLimit;
    this.isLoading = false;
  },
});
