/**
 * TODO list
 * Rafactor wrong interfaces (es Channel -> BookingChannel, Offer->BookingOffer)
 *
 * Maybe create an enum with his exported const data
 * of each BookingChannel (prevent switch in the BookingView page)
 */

import { Refund } from './refund';
import { SectorHeader } from './sector';

export enum BookingMode {
  MAP = 1,
  TICKET,
}

export enum BookingHalfDay {
  DISABLED,
  ENABLED,
  EXCLUSIVE,
}

export enum BookingEndPeriod {
  DISABLED,
  ONE,
  TWO,
  THREE,
  SEVEN = 7,
  THIRTY = 30,
}

export enum BookingPaymentMode {
  BEACH = 1,
  ONLINE,
  TRANSFER,
  BEACH_OR_ONLINE,
  ONLINE_OR_TRANSFER,
  BEACH_OR_TRANSFER,
  ALL,
}

export enum BookingPaymentFeesMode {
  MANAGER = 1,
  CUSTOMER,
}

export enum BookingPaymentFeesCustomerMode {
  PROPORTIONAL = 1,
  BRACKETS,
}

export enum BookingPaymentFeesBracketsMode {
  FIXED = 1,
  DYNAMIC,
}

export enum BookingConfirmStatus {
  TEMPORARY,
  CONFIRMED = 2,
}

export enum BookingInvoiceType {
  INVOICE = 1,
  RECEIPT_OR_INVOICE,
  RECEIPT,
}

export enum BookingPaymentInvoiceMode {
  SPIAGGE = 1,
  OWN,
  THIRD_PARTY,
}

export enum BookingInvoiceDescription {
  PAYMENT = 1,
  DEPOSIT = 2,
}

export enum BookingRefundPolicy {
  NEVER = 1,
  DAY_BEFORE,
  FIFTEEN_DAYS,
  TWO_DAYS,
  SEVEN_DAYS,
  FIVE_DAYS,
  SIX_DAYS,
  TEN_DAYS,
  FOURTEEN_DAYS,
}

export enum BookingOnlineServiceCostType {
  FIXED = 1,
  PERCENTAGE,
}

export interface BookingRule {
  id: number;
  sector: string | null;
  end: number | null;
  start: number | null;
  minDays: number;
  maxDays: number;
}

export interface BookingRuleConfig extends BookingRule {
  startDate: Date;
  endDate: Date;
}

export interface Channel {
  title: string;
  img: string;
  code: string;
}

export interface PayloadReservation {
  type: string;
  online: string;
  deleted: string;
  orderBy: string;
  page: string;
  offset: string;
}

export interface ReservationBooking {
  firstName: string;
  lastName: string;
  spotName: string;
  spotType: string;
  channel: string;
  startDate: number;
  endDate: number;
  id: number;
}

export interface ReservationBookingView {
  firstName: string;
  lastName: string;
  spotName: string;
  spotType: string;
  channel: Channel;
  startDate: number;
  endDate: number;
  id: number;
}

export interface Sectors {
  name: string;
  validate: number;
}

export interface Reservations {
  reservations: Array<string>;
}

export interface Offer {
  id: number;
  name: string;
  from: number;
  to: number;
  percentage: number;
  sectors: Array<SectorHeader>;
}

export interface Discount {
  id: number;
  code: string;
  applyFrom: number;
  applyTo: number;
  validFrom: number;
  validTo: number;
  maxUses: number;
  remainingUses: number;
  discountPercentage: number;
  discountFixed: number;
  payAtBeach: boolean;
  allowPortals: boolean;
  flagWeek: boolean;
  flagPreHoliday: boolean;
  flagHoliday: boolean;
  serviceId: number;
  validOnSectorIds: Array<number>;
  validOnCities: Array<string>;
  validOnProvinces: Array<string>;
  validOnRegions: Array<string>;
  influencer: boolean;
  hasRefund?: boolean;
  refund?: Refund | null;
}

export interface Booking {
  bookingActive: boolean;
  bookingTest: boolean;
  lastBookingsRep: Array<ReservationBookingView>;
  offersRep: Array<Offer>;
  discountCodesRep: Array<Discount>;
}
