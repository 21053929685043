
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { Field, Form } from 'vee-validate';
import voucherService from '@/services/voucherService';
import { Voucher } from '@/models/voucher';
import { ApiVoucherCreatePayload } from '@/models/api';

export default defineComponent({
  name: 'PriceListsVoucherCreate',
  components: { Field, Form },
  data() {
    return {
      model: {} as ApiVoucherCreatePayload,
    };
  },
  beforeMount(): void {
    this.initModel();
  },
  methods: {
    initModel(): void {
      /**
       * Init model
       */
      this.model = {
        name: '',
      } as ApiVoucherCreatePayload;
    },
    async onCreate(): Promise<void> {
      /**
       * Create voucher
       */
      try {
        const voucher: Voucher = await voucherService.create(this.model);
        this.$spiagge.toast.success(
          this.$t('priceListsVoucherCreate.toast.success'),
        );
        this.$store.commit('priceLists/setShowVoucherCreateDialog', false);
        this.$router.push(`/price-lists/voucher/${voucher.id}`);
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('priceListsVoucherCreate.toast.error'),
        );
      }
    },
  },
  computed: {
    ...mapState('priceLists', ['priceLists']),
    visible: {
      get(): boolean {
        return true;
      },
      set(visible: boolean): void {
        this.$emit('close', visible);
        this.$store.commit('priceLists/setShowVoucherCreateDialog', visible);
      },
    },
  },
});
