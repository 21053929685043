import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b64d9dd6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "contact-view" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "col-12 lg:col-3" }
const _hoisted_4 = { class: "col-12 lg:col-9" }
const _hoisted_5 = { class: "p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_contacts_navigation = _resolveComponent("contacts-navigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_contacts_navigation)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_ScrollPanel, {
            style: _normalizeStyle({ width: '100%', height: _ctx.panelHeight })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_router_view)
            ]),
            _: 1
          }, 8, ["style"])
        ])
      ])
    ])
  ]))
}