/* eslint-disable import/no-cycle */
/**
 * Session store module
 */

import cloneDeep from 'lodash/cloneDeep';
import forOwn from 'lodash/forOwn';
import { MutationTree, ActionTree, ActionContext, GetterTree } from 'vuex';
import { DateTime } from 'luxon';
import { RootState, SessionState, DEFAULT_SESSION_STATE } from '@/models/store';
import { Locale } from '@/models/locale';
import authService from '@/services/authService';
import {
  ApiAuthSignInResponse,
  ApiLicenseResponse,
  ApiLicenseUpdatePayload,
  ApiSectorListPayload,
} from '@/models/api';
import { User } from '@/models/user';
import { Links } from '@/models/links';
import {
  License,
  LicenseBookingSector,
  LicenseBookingSectorFull,
  LicenseDocumentTemplate,
  LicenseSector,
  LicenseSectorDetailFormatted,
  LicenseSectorFull,
} from '@/models/license';
import licenseService from '@/services/licenseService';
import { Voucher } from '@/models/voucher';
import { Printer } from '@/models/printer';
import {
  BEACH_TICKET_SERVICE_ID,
  Service,
  ServiceType,
} from '@/models/service';
import voucherService from '@/services/voucherService';
import printerService from '@/services/printerService';
import serviceService from '@/services/serviceService';
import sectorService from '@/services/sectorService';
import cookieUtil from '@/utils/cookieUtil';
import permissionsUtil from '@/utils/permissionsUtil';
import { StorePermission } from '@/models/permissions';
import { PosTerminal } from '@/models/posTerminal';
import posTerminalService from '@/services/posTerminalService';
import { WebticTerminal } from '@/models/webtic';
import webticTerminalService from '@/services/webticTerminalService';
import { Sector } from '@/models/sector';
import { ServiceGroup } from '@/models/serviceGroup/serviceGroup';
import serviceGroupService from '@/services/serviceGroupService';
import gtmUtil from '@/utils/gtmUtil';
import { ServiceInformation } from '@/models/service/serviceInformation';

type SessionContext = ActionContext<SessionState, RootState>;

const namespaced = true;

const state = (): SessionState => cloneDeep(DEFAULT_SESSION_STATE);

const getters: GetterTree<SessionState, RootState> = {
  setup: (state) => state.setup,
  links: (state) => state.links,
  permissions: (state) => state.permissions,
  roleUser: (state) => state.roleUser,
  maxSector: (state) => state.maxSector,
  user: (state) => state.user,
  locale: (state) => state.locale,
  darkMode: (state) => state.darkMode,
  license: (state) => state.license,
  vouchers: (state) => state.vouchers,
  printers: (state) => state.printers,
  services: (state) => state.services,
  serviceGroups: (state) => state.serviceGroups,
  sectors: (state) => state.sectors,
  chatBotOpen: (state) => state.chatBotOpen,
  appPlatform: (state) => state.appPlatform,
  appPlatformBuild: (state) => state.appPlatformBuild,
  /* services - custom getters */
  beachServices: (state) =>
    state.services.filter(
      (service: Service) => service.type === ServiceType.BEACH,
    ),
  utilityServices: (state) =>
    state.services.filter(
      (service: Service) => service.type === ServiceType.UTLITY,
    ),
  dailyTicketServices:
    (state) =>
    (excludeBeachTicketService = false) => {
      let dailyTicketServices = state.services.filter(
        (service: Service) => service.type === ServiceType.DAILY_TICKET,
      );
      if (excludeBeachTicketService) {
        dailyTicketServices = dailyTicketServices.filter(
          (service: Service) => service.id !== BEACH_TICKET_SERVICE_ID,
        );
      }
      return dailyTicketServices;
    },
  beachTicketService: (state, getters) =>
    getters.dailyTicketServices.find(
      (dailyTicketService: Service) =>
        dailyTicketService.id === BEACH_TICKET_SERVICE_ID,
    ) || null,
  /* printers - custom getters */
  fiscalPrinters: (state) =>
    state.printers.filter((printer: Printer) => printer.printMode.fis === 1),
  nonFiscalPrinters: (state) =>
    state.printers.filter((printer: Printer) => printer.printMode.nfis === 1),
  textPrinters: (state) =>
    state.printers.filter((printer: Printer) => printer.printMode.text === 1),
  /* sectors - custom getters */
  standardSectors: (state) => {
    const sectors: Array<LicenseSectorFull> = [];
    forOwn(
      state.license?.standardSectorServices,
      (sector: LicenseSector, key: string) => {
        sectors.push({
          id: Number(key),
          staging: {
            maxiBeds: {
              min: sector.m_min,
              default: sector.m_d,
              max: sector.m_max,
            },
            beds: {
              min: sector.b_min,
              default: sector.b_d,
              max: sector.b_max,
            },
            deckChairs: {
              min: sector.d_min,
              default: sector.d_d,
              max: sector.d_max,
            },
            chairs: {
              min: sector.c_min,
              default: sector.c_d,
              max: sector.c_max,
            },
          },
          ...(state.license?.sectorsDetails?.[Number(key)] ?? {
            name: key,
            description: '',
            image: '',
          }),
        } as LicenseSectorFull);
      },
    );
    return sectors;
  },
  bookingSectors: (state) => {
    const sectors: Array<LicenseBookingSectorFull> = [];
    forOwn(
      state.license?.bookingSectorServices,
      (sector: LicenseBookingSector, key: string) => {
        sectors.push({
          id: Number(key),
          staging: {
            maxiBeds: {
              min: sector.m_min,
              default: sector.m_d,
              max: sector.m_max,
            },
            beds: {
              min: sector.b_min,
              default: sector.b_d,
              max: sector.b_max,
            },
            deckChairs: {
              min: sector.d_min,
              default: sector.d_d,
              max: sector.d_max,
            },
            chairs: {
              min: sector.c_min,
              default: sector.c_d,
              max: sector.c_max,
            },
            minMax: {
              min: sector.i_min,
              max: sector.i_max,
            },
          },
          ...(state.license?.sectorsDetails?.[Number(key)] ?? {
            name: key,
            description: '',
            image: '',
          }),
        } as LicenseBookingSectorFull);
      },
    );
    return sectors;
  },
  sectorsDetails: (state) => {
    const sectors: Array<LicenseSectorDetailFormatted> = [];
    Array.from({ length: state.maxSector ?? 0 }, (_, i) => i + 1).forEach(
      (sectorId: number) => {
        const sectorDetail = (state.license as License).sectorsDetails[
          sectorId
        ];
        const sectorName =
          sectorDetail && sectorDetail.name
            ? sectorDetail.name
            : `Settore ${sectorId.toString()}`;
        const sectorImage =
          sectorDetail && sectorDetail.image ? sectorDetail.image : '';
        const sectorDescription =
          sectorDetail && sectorDetail.description
            ? sectorDetail.description
            : '';
        sectors.push({
          id: sectorId,
          name: sectorName,
          image: sectorImage,
          description: sectorDescription,
        } as LicenseSectorDetailFormatted);
      },
    );
    return sectors;
  },
  hasWorkTags: (state) =>
    state.license && state.license.tags && state.license.tags.length > 0,
} as GetterTree<SessionState, RootState>;

const mutations: MutationTree<SessionState> = {
  setSetup(state: SessionState, setup: boolean) {
    state.setup = setup;
  },
  setLinks(state: SessionState, links: Links | null) {
    state.links = links;
  },
  setUser(state: SessionState, user: User | null) {
    state.user = user;
  },
  setPermissions(state: SessionState, permissions: StorePermission) {
    state.permissions = permissions;
  },
  setRoleUser(state: SessionState, roleUser: string) {
    state.roleUser = roleUser;
  },
  setMaxSector(state: SessionState, maxSector: number) {
    state.maxSector = maxSector;
  },
  setLocale(state: SessionState, locale: Locale) {
    state.locale = locale;
  },
  setDarkMode(state: SessionState, darkMode: boolean) {
    state.darkMode = darkMode;
  },
  setLicense(state: SessionState, license: License) {
    state.license = license;
  },
  setVouchers(state: SessionState, vouchers: Array<Voucher>) {
    state.vouchers = vouchers;
  },
  setPrinters(state: SessionState, printers: Array<Printer>) {
    state.printers = printers;
  },
  setPosTerminals(state: SessionState, posTerminals: Array<PosTerminal>) {
    state.posTerminals = posTerminals;
  },
  setWebticTerminals(
    state: SessionState,
    webticTerminals: Array<WebticTerminal>,
  ) {
    state.webticTerminals = webticTerminals;
  },
  setServices(state: SessionState, services: Array<Service>) {
    state.services = services;
  },
  setServiceGroups(state: SessionState, serviceGroups: Array<ServiceGroup>) {
    state.serviceGroups = serviceGroups;
  },
  setServiceAvailabilities(
    state: SessionState,
    availability: ServiceInformation,
  ) {
    state.serviceAvailabilities = availability;
  },
  setSectors(state: SessionState, sectors: Array<Sector>) {
    state.sectors = sectors;
  },
  setChatBotStatus(state: SessionState, chatBotOpen: boolean) {
    state.chatBotOpen = chatBotOpen;
  },
  setDocumentTemplates(
    state: SessionState,
    documentTemplates: Array<LicenseDocumentTemplate>,
  ) {
    state.documentTemplates = documentTemplates;
  },
  setAppPlatform(state: SessionState, appPlatform: string | undefined) {
    state.appPlatform = appPlatform;
    if (appPlatform) {
      cookieUtil.set(
        'anm22_world_app_os',
        appPlatform,
        365,
        '/',
        '.spiagge.it',
      );
    } else {
      cookieUtil.remove('anm22_world_app_os', '/', '.spiagge.it');
    }
  },
  setAppPlatformBuild(
    state: SessionState,
    appPlatformBuild: number | undefined,
  ) {
    state.appPlatformBuild = appPlatformBuild;
    if (appPlatformBuild) {
      cookieUtil.set(
        'anm22_world_app_build',
        appPlatformBuild.toString(),
        365,
        '/',
        '.spiagge.it',
      );
    } else {
      cookieUtil.remove('anm22_world_app_build', '/', '.spiagge.it');
    }
  },
} as MutationTree<SessionState>;

const actions: ActionTree<SessionState, RootState> = {
  async signIn(context: SessionContext) {
    const res: ApiAuthSignInResponse = await authService.signIn();
    context.commit('setUser', res.result.user);
    context.commit('setLinks', res.result.links);
  },
  async setLicense(context: SessionContext, id: string) {
    const data: ApiLicenseResponse = await licenseService.one(id);
    context.commit('setLicense', data.result.license);
    context.commit('setMaxSector', data.result.maxSector);
    context.dispatch('style/build', null, { root: true });
    await Promise.all([
      context.dispatch('setSectors'),
      context.dispatch('setDocumentTemplates'),
    ]);
    await permissionsUtil.retrieveAndStoreDataPermission(
      context.state.license?.license as string,
    );

    gtmUtil.trackUser();
  },
  async setVouchers({ commit }) {
    const vouchers: Array<Voucher> = await voucherService.list();
    commit('setVouchers', vouchers);
  },
  async setPrinters({ commit }) {
    const printers: Array<Printer> = await printerService.list();
    commit('setPrinters', printers);
  },
  async setPosTerminals({ commit }) {
    const posTerminals: Array<PosTerminal> = await posTerminalService.list();
    commit('setPosTerminals', posTerminals);
  },
  async setWebticTerminals({ commit }) {
    const webticTerminals: Array<WebticTerminal> =
      await webticTerminalService.list();
    commit('setWebticTerminals', webticTerminals);
  },
  async setServices({ commit }) {
    const services: Array<Service> = await serviceService.list();
    commit('setServices', services);
  },
  async setServiceGroups({ commit }) {
    const serviceGroups: Array<ServiceGroup> = await serviceGroupService.list();
    commit('setServiceGroups', serviceGroups);
  },
  async setServiceAvailabilities({ commit }) {
    const payload = {
      from: DateTime.now().startOf('day').toSeconds(),
      to: DateTime.now().startOf('day').toSeconds(),
    };
    const availability: ServiceInformation = await serviceService.availability(
      payload,
    );
    commit('setServiceAvailabilities', availability);
  },
  async setSectors(
    { commit },
    checkSectorRelationships: boolean | null | undefined,
  ) {
    const sectorsApiRequestPayload: ApiSectorListPayload = {
      enabled: true,
      checkSectorRelationships: checkSectorRelationships ?? false,
    };
    const sectors: Array<Sector> = await sectorService.list(
      sectorsApiRequestPayload,
    );
    commit('setSectors', sectors);
  },
  async setChatBotStatus({ commit }, chatBotOpen: boolean) {
    commit('setChatBotStatus', chatBotOpen);
  },
  async setup({ dispatch, commit }) {
    await Promise.all([
      dispatch('setVouchers'),
      dispatch('setPrinters'),
      dispatch('setServices'),
      dispatch('setServiceGroups'),
      dispatch('setPosTerminals'),
      dispatch('setWebticTerminals'),
      dispatch('setSectors'),
    ]);
    commit('setSetup', true);
  },
  async setDocumentTemplates(context: SessionContext) {
    const documentTemplates = await licenseService.documentTemplates();
    context.commit('setDocumentTemplates', documentTemplates);
  },
  async updateLicense(
    context: SessionContext,
    payload: ApiLicenseUpdatePayload,
  ) {
    const license: License = await licenseService.updateNew(payload);
    context.commit('setLicense', license);
  },
} as ActionTree<SessionState, RootState>;

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
