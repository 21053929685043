
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { Spot, SpotType } from '@/models/spot';
import { ApiReservationCreateAdditionPayload } from '@/models/api';
import { Reservation } from '@/models/reservation';
import reservationService from '@/services/reservationService';
import { AppAction } from '@/models/app';
import { CabinMode } from '@/models/license';
import CabinsDialog from '@/components/shared/CabinsDialog.vue';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  ActionPermissionType,
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

export default defineComponent({
  name: 'ReservationCabinService',
  components: { CabinsDialog },
  data() {
    return {
      showCabinsDialog: false,
    };
  },
  methods: {
    hasUpdatePermission(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.reservations,
        FEATURE_PERMISSION_ACTION_CONFIG.reservations.UPDATE,
      );
    },
    async onSelectCabin(cabin: Spot): Promise<void> {
      try {
        const cabinAddition: Reservation =
          await reservationService.createAddition(this.id, {
            spotType: SpotType.CABIN,
            spotName: cabin.name,
            startDate: this.startDate.toSeconds(),
            endDate: this.endDate.toSeconds(),
          } as ApiReservationCreateAdditionPayload);
        this.$spiagge.toast.success(
          this.$t('reservationCabinService.toast.success'),
        );
        this.$store.commit('reservation/setCanExit', true);
        this.$store.commit('app/setAction', AppAction.CREATE_RESERVATION);
        this.$router.push(`/reservation/${cabinAddition.id}`);
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationCabinService.toast.error'),
        );
      }
    },
    total(cabin: Reservation): number {
      if (cabin.forcedTotal !== null) {
        return cabin.forcedTotal;
      }
      return (
        (cabin.forcedBeach ? cabin.forcedBeach : cabin.listBeach || 0) +
        (cabin.listAdditions || 0) +
        (cabin.listExpenses || 0) +
        (cabin.listServices || 0)
      );
    },
    isCabinModeService(cabin: Reservation): boolean {
      return (
        this.license?.cabinMode === CabinMode.SERVICE &&
        cabin.startDate === this.startDate.toSeconds() &&
        cabin.endDate === this.endDate.toSeconds()
      );
    },
  },
  computed: {
    ...mapGetters('reservation', [
      'isCabin',
      'isAddition',
      'isSimpleAddition',
      'isPlayField',
    ]),
    ...mapState('reservation', ['id', 'startDate', 'endDate']),
    ...mapState('session', ['license']),
    enabled(): boolean {
      let enabled = true;
      if (this.isCabin || this.isPlayField) {
        enabled = false;
      }
      if (this.isAddition && !this.isSimpleAddition) {
        enabled = false;
      }
      return enabled;
    },
    cabins(): Array<Reservation> {
      const cabins =
        this.$store.getters['reservation/cabins'] ?? ([] as Array<Reservation>);
      return cabins.filter((element: Reservation) => !element.deleted);
    },
  },
});
