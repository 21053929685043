import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e361bd16"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "country-dropdown" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Dropdown, {
      class: "w-full",
      modelValue: _ctx.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      options: _ctx.countries,
      filter: "",
      optionLabel: "name",
      placeholder: _ctx.$t('countryDropdown.placeholder')
    }, null, 8, ["modelValue", "options", "placeholder"])
  ]))
}