
import { defineComponent, PropType } from 'vue';
import { Field, Form } from 'vee-validate';
import { cloneDeep } from 'lodash';
import { mapState } from 'vuex';
import {
  SERVICE_ICON_INFOS,
  SERVICE_LIMIT_INFO_NO_SPOT,
  SERVICE_LIMIT_INFO_SPOT,
  SERVICE_PRICE_MODE_INFOS,
  SERVICE_TYPE_INFOS,
  ServiceIconInfo,
  ServiceLimit,
  ServicePriceMode,
  ServiceType,
  ServiceTypeInfo,
} from '@/models/service';
import { DropdownOption } from '@/models';
import { Sector } from '@/models/sector';
import { ColorName, ColorSpec, colorSpecs, SERVICE_BG_COLORS } from '@/models/color';
import { ApiServiceCreatePayload } from '@/models/api';
import { ServiceGroup } from '@/models/serviceGroup/serviceGroup';
import { ReservationHalfDay } from '@/models/reservation';
import { HALF_DAY_OPTIONS } from '@/enum/halfDay';

interface BackgroundDropdownOption extends DropdownOption {
  colorSpec: ColorSpec;
}

type ServiceModel = ApiServiceCreatePayload;

export default defineComponent({
  name: 'SettingsServiceDialog',
  emits: ['close', 'submitForm'],
  components: {
    Form,
    Field,
  },
  props: {
    serviceType: {
      type: Number as PropType<ServiceType>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<ServiceModel>,
      required: true,
    },
    serviceId: {
      type: Number,
      required: true,
    },
    priceListOptions: {
      type: Array as PropType<Array<DropdownOption>>,
      required: true,
    },
    showDialog: {
      type: Boolean,
      required: true,
    },
    defaultServiceGroup: {
      type: Object as PropType<ServiceGroup>,
      required: false,
    },
  },
  data() {
    return {
      servicePriceMode: cloneDeep(ServicePriceMode),
      colorSpecs: cloneDeep(colorSpecs),
      // options
      halfDayOptions: cloneDeep(HALF_DAY_OPTIONS),
      serviceIconOptions: cloneDeep(SERVICE_ICON_INFOS),
      serviceTypeOptions: cloneDeep(SERVICE_TYPE_INFOS)
          .filter(
              (serviceTypeInfo: ServiceTypeInfo) =>
                  serviceTypeInfo.type !== ServiceType.UTLITY,
          ),
      servicePriceModeOptions: cloneDeep(SERVICE_PRICE_MODE_INFOS),
      serviceSpotLimitOptions: cloneDeep(SERVICE_LIMIT_INFO_SPOT),
      serviceNoSpotLimitOptions: cloneDeep(SERVICE_LIMIT_INFO_NO_SPOT),
      serviceBackgroundOptions: [] as Array<BackgroundDropdownOption>,
      serviceGroupOptions: [] as Array<DropdownOption>,
      dailyLimitEnabled: false,
      purchaseOffline: false,
      purchaseOnline: false,
      isTicket: this.$props.serviceType === ServiceType.DAILY_TICKET,
    };
  },
  beforeMount() {
    this.buildServiceGroupOptions();
    this.buildBackgroundOptions();
  },
  methods: {
    /**
     * This dialog is used to create both extra services and tickets
     */
    getTitle(): string {
      return this.$props.serviceType === ServiceType.BEACH
        ? this.getServiceTitle()
        : this.getTicketTitle();
    },
    getServiceTitle(): string {
      return this.$props.serviceId
        ? this.$t('extraServices.dialog.title.editService')
        : this.$t('extraServices.dialog.title.addService');
    },
    getTicketTitle(): string {
      return this.$props.serviceId
        ? this.$t('extraServices.dialog.title.editTicket')
        : this.$t('extraServices.dialog.title.addTicket');
    },
    getDailyItemsTitle(): string {
      return this.$props.serviceType === ServiceType.BEACH
          ? this.$t('extraServices.dialog.maxNumAvailableDailyService')
          : this.$t('extraServices.dialog.maxNumAvailableDailyTicket');
    },
    getSectorTitle(): string {
      return this.$props.serviceType === ServiceType.BEACH
          ? this.$t('extraServices.dialog.serviceOnSectors')
          : this.$t('extraServices.dialog.ticketOnSectors');
    },
    // Find background from options
    getServiceBackground(service: ServiceModel): string {
      return (
          this.serviceBackgroundOptions.find(
              (option: BackgroundDropdownOption) =>
                  option.value === service.background,
          )?.colorSpec.background ?? ''
      );
    },
    // Resets price list id
    onPriceModeChange(): void {
      if (this.model.priceMode !== this.servicePriceMode.TABLE) {
        this.model.priceListId = null;
      }
    },
    showServiceGroupDropdown(): boolean {
      return this.$props.defaultServiceGroup === undefined
        || this.serviceId !== 0;
    },
    // Service group options
    buildServiceGroupOptions(): void {
      this.serviceGroupOptions = [];
      // Load service groups
      if (
          this.$props.defaultServiceGroup !== undefined
          && this.serviceId === 0
      ) {
        this.serviceGroupOptions.push({
          label: this.$props.defaultServiceGroup.name,
          value: this.$props.defaultServiceGroup.id,
        });
        this.model.serviceGroupId = this.$props.defaultServiceGroup.id;
        return;
      }
      // If a default group is not set, load all groups
      this.serviceGroupOptions.push(
          ...this.serviceGroups.map(
              (serviceGroup: ServiceGroup) => ({
                label: serviceGroup.name,
                value: serviceGroup.id,
              }),
          ),
      );
    },
    // Background options
    buildBackgroundOptions(): void {
      this.serviceBackgroundOptions = [];
      this.serviceBackgroundOptions.push(...SERVICE_BG_COLORS.map((name: ColorName | null) => {
        if (!name) {
          return {
            label: this.$t('common.none'),
            value: 'default',
            colorSpec: this.colorSpecs.get('default'),
          } as BackgroundDropdownOption;
        }
        return {
          label: this.$t(`color.${name.toLowerCase()}`),
          value: name,
          colorSpec: this.colorSpecs.get(name),
        } as BackgroundDropdownOption;
      }));
    },
    calculateSwitchValues(): void {
      // Purchase offline flag
      this.purchaseOffline = this.model.canBeSoldAloneOffline
        || this.model.reservationSchedule
        || this.model.fastCash;
      // Purchase online flag
      this.purchaseOnline = this.model.online
        || this.model.canBeSoldAloneOnline;
    },
    updateLimitFlags(): void {
      this.dailyLimitEnabled = this.model.dailyItems > 0;
    },
    isFixedLimit(serviceLimit: ServiceLimit) {
      // Enable manual limit if mode is fixed
      return serviceLimit === ServiceLimit.FIXED_LIMIT;
    },
    showSectorOptions(): boolean {
      return this.model.online
        || this.model.reservationSchedule;
    },
    onSubmit(): void {
      // Check if limits are enabled or not
      if (!this.dailyLimitEnabled) {
        this.model.dailyItems = 0;
      }
      // Offline Purchase flag
      if (!this.purchaseOffline) {
        this.model.fastCash = false;
        this.model.reservationSchedule = false;
        this.model.canBeSoldAloneOffline = false;
      }
      // Online Purchase flag
      if (!this.purchaseOnline) {
        this.model.online = false;
        this.model.canBeSoldAloneOnline = false;
      }
      // clean up null values for input numbers
      this.model.minItemsFixed = this.model.minItemsFixed ?? 0;
      this.model.minItemsFixedAlone = this.model.minItemsFixedAlone ?? 0;
      this.model.maxItemsFixed = this.model.maxItemsFixed ?? 0;
      this.model.maxItemsFixedAlone = this.model.maxItemsFixedAlone ?? 0;
      this.model.cost = this.model.cost ?? 0;
      this.model.vat = this.model.vat ?? 0;
      this.model.dailyItems = this.model.dailyItems ?? 0;
      // Double check on half day (only available on tickets)
      if (!this.isTicket) {
        this.model.halfDay = ReservationHalfDay.DAILY;
      }
      // Emit model and close dialog
      this.$emit('submitForm', this.model);
      this.visible = false;
    },
  },
  computed: {
    ...mapState(
        'session',
        [
          'license',
          'permissions',
          'sectors',
          'serviceGroups',
        ],
    ),
    ...mapState('app', ['loading']),
    model: {
      get(): ServiceModel {
        return this.modelValue;
      },
      set(data: any) {
        //
      },
    },
    visible: {
      get(): boolean {
        return this.showDialog;
      },
      set(visible: boolean): void {
        if (!visible) {
          this.$emit('close');
        }
      },
    },
    sectorOptions(): Array<DropdownOption> {
      return this.sectors.map((sector: Sector) => ({
        label: sector.header.name,
        value: sector.header.id,
      }));
    },
    serviceIconValue(): ServiceIconInfo | undefined {
      if (!this.model.icon) {
        return undefined;
      }
      const serviceIcon = this.serviceIconOptions.find(
          (s) => s.icon === this.model.icon,
      );
      if (!serviceIcon) {
        return undefined;
      }
      return serviceIcon;
    },
  },
  watch: {
    showDialog(visible: boolean) {
      if (visible) {
        this.calculateSwitchValues();
        this.buildServiceGroupOptions();
        this.updateLimitFlags();
      }
    },
  },
});
