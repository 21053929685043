
import { defineComponent } from 'vue';
import { productFruits } from 'product-fruits';
import { mapState } from 'vuex';
import { License } from '@/models/license';

// copied from product fruits
type ProductFruitsUserObject = {
  username: string;
  email: string;
  role: string;
  firstname: string;
  lastname: string;
  props: {
    license: License;
  };
};

export default defineComponent({
  name: 'ProdutFruits',
  data() {
    return {
      workspaceCode: process.env.VUE_APP_PRODUCT_FRUITS_WORKSPACE_CODE,
      languageCode: 'it',
      userObject: {} as ProductFruitsUserObject,
    };
  },
  mounted() {
    this.userObject = {
      username: this.user.email,
      email: this.user.email,
      role: this.roleUser,
      firstname: this.user.name,
      lastname: this.user.surname,
      props: {
        license: this.license,
      },
    } as ProductFruitsUserObject;

    productFruits.init(
      this.workspaceCode,
      this.languageCode,
      this.userObject as any,
    );
  },
  computed: {
    ...mapState('session', ['license', 'user', 'roleUser']),
  },
});
