
import { defineComponent } from 'vue';
import { mapState, mapGetters } from 'vuex';

export default defineComponent({
  name: 'MapZoom',
  data() {
    return {
      zoomOffsetY: 64,
    };
  },
  methods: {
    zoomIn(): void {
      /*
        this.instance.zoomIn();
        diagonal zoom behavior because panzoom html element
        set a transform with negative coordinates (??)
      */
      this.instance.zoomToPoint(this.instance.getScale() + 0.3, {
        clientX: window.innerWidth / 2 + this.zoomOffsetX,
        clientY: window.innerHeight / 2 - this.zoomOffsetY,
      });
    },
    zoomOut(): void {
      /*
        this.instance.zoomOut();
        diagonal zoom behavior because panzoom html element
        set a transform with negative coordinates (??)
      */
      this.instance.zoomToPoint(this.instance.getScale() - 0.3, {
        clientX: window.innerWidth / 2 + this.zoomOffsetX,
        clientY: window.innerHeight / 2 - this.zoomOffsetY,
      });
    },
  },
  computed: {
    ...mapState('map', ['instance']),
    ...mapState('app', ['breakpoints']),
    ...mapGetters('map', ['planner']),
    zoomOffsetX(): number {
      // desktop navigation
      return this.breakpoints.mobile ? 0 : 80;
    },
  },
});
