
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import { pick, cloneDeep } from 'lodash';
import { mapState } from 'vuex';
import { License } from '@/models/license';
import { Printer } from '@/models/printer';
import { Sector, SectorHeader } from '@/models/sector';
import printerService from '@/services/printerService';
import { Printers, ListElementNumber, DropdownOption } from '@/models';
import toastUtil from '@/utils/toastUtil';
import { ToastSeverity } from '@/models/toast';
import InputTooltip from '@/components/shared/InputTooltip.vue';
import cookieUtil from '@/utils/cookieUtil';
import { ApiPrinterCreatePayload } from '@/models/api';

interface DepartmentsStruct {
  index: string;
  value: number;
}

type PrinterFormModel = ApiPrinterCreatePayload;

const DEFAULT_PRINTER_MODEL = {
  ip: '',
  name: '',
  ssl: false,
  remote: false,
  invoiceFooter: '',
  mode: 1,
  port: 80,
  invoiceWidth: 0,
  invoiceTitle: '',
  invoiceSubtitle: '',
  fis: false,
  nfis: false,
  list: false,
  text: false,
  model: Printers.EPSON_FP81II,
  registrationNumber: '',
  departments: [],
  sectors: [],
} as PrinterFormModel;

export default defineComponent({
  components: { Form, Field, InputTooltip },
  data() {
    return {
      visibilities: [] as Array<boolean>,
      // printerModelIndex: 0,
      printerModel: {} as PrinterFormModel,
      showPrinterDialog: false,
      printersList: [
        {
          name: 'CORISTECH_PRINTF',
          code: Printers.CORISTECH_PRINTF,
        },
        {
          name: 'CUSTOM',
          code: Printers.CUSTOM,
        },
        {
          name: 'OLIVETTI_NETTUNO7000_OLD_DRIVER_V2',
          code: Printers.OLIVETTI_NETTUNO7000_OLD_DRIVER_V2,
        },
        {
          name: 'OLIVETTI_NETTUNA_300',
          code: Printers.OLIVETTI_NETTUNA_300,
        },
        {
          name: 'OLIVETTI_NETTUNA_PRT200FX_OLD_DRIVER',
          code: Printers.OLIVETTI_NETTUNA_PRT200FX_OLD_DRIVER,
        },
        {
          name: 'OLIVETTI_NETTUNA_PRT200FX',
          code: Printers.OLIVETTI_NETTUNA_PRT200FX,
        },
        {
          name: 'OLIVETTI_NETTUNO7000',
          code: Printers.OLIVETTI_NETTUNO7000,
        },
        {
          name: 'RCH_PRINTF',
          code: Printers.RCH_PRINTF,
        },
        {
          name: 'RCH_PRINTF_FIDELITY',
          code: Printers.RCH_PRINTF_FIDELITY,
        },
        {
          name: 'CUSTOM_RAW',
          code: Printers.CUSTOM_RAW,
        },
        {
          name: 'CUSTOM_ESC_POS',
          code: Printers.CUSTOM_ESC_POS,
        },
        {
          name: 'CUSTOM_REMOTE',
          code: Printers.CUSTOM_REMOTE,
        },
        {
          name: 'EPSON_FP81II',
          code: Printers.EPSON_FP81II,
        },
        {
          name: 'EPSON_TM_P80_021',
          code: Printers.EPSON_TM_P80_021,
        },
        {
          name: 'EPSON_TM_T20II',
          code: Printers.EPSON_TM_T20II,
        },
        {
          name: 'EPSON_ESC_POS',
          code: Printers.EPSON_ESC_POS,
        },
        {
          name: 'ITALRETAIL_PRX',
          code: Printers.ITALRETAIL_PRX,
        },
        {
          name: 'OLIVETTI_NETTUNO7000_OLD_DRIVER',
          code: Printers.OLIVETTI_NETTUNO7000_OLD_DRIVER,
        },
        {
          name: 'RETEX_FISCAL',
          code: Printers.RETEX_FISCAL,
        },
        {
          name: 'RETEX_FISCAL_EFT',
          code: Printers.RETEX_FISCAL_EFT,
        },
        {
          name: 'RETEX_FISCAL_WALLET',
          code: Printers.RETEX_FISCAL_WALLET,
        },
      ] as Array<ListElementNumber>,
      wardPercent: [
        { name: '0%', code: 0.0 },
        { name: '4%', code: 4.0 },
        { name: '10%', code: 10.0 },
        { name: '22%', code: 22.0 },
      ] as Array<ListElementNumber>,
      departmentModels: [] as Array<Array<DepartmentsStruct>>,
      editingPrinterId: 0,
    };
  },
  methods: {
    onDeleteClick(id: number): void {
      this.$confirm.require({
        message: this.$t('printerSettingsView.confirm.message'),
        header: this.$t('printerSettingsView.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('common.yes'),
        accept: () => {
          this.deletePrinter(id);
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          // nothing
        },
      });
    },
    async deletePrinter(id: number) {
      const printer = this.printers.find((p: Printer) => p.id === id);
      if (!printer) {
        return;
      }
      try {
        await printerService.delete(printer.id);
        // Update printers from db
        await this.$store.dispatch('session/setPrinters');
        this.initPrintersData();
      } catch (error) {
        // TODO
      }
    },
    async submit() {
      // console.log(this.printerModel, this.editingPrinterId);
      /** Build departments */
      this.departmentModels[this.editingPrinterId].forEach((element, index) => {
        this.printerModel.departments[index] = element.value;
      });
      try {
        if (this.editingPrinterId) {
          await printerService.update(this.editingPrinterId, this.printerModel);
        } else {
          await printerService.create(this.printerModel);
        }

        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
        this.closePrinterDialog();
        await this.$store.dispatch('session/setPrinters');
        this.initPrintersData();
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
    openPrinterDialog(id: number): void {
      const printer = this.printers.find((p: Printer) => p.id === id);
      if (!printer) {
        return;
      }
      this.editingPrinterId = printer.id;
      this.printerModel = cloneDeep(DEFAULT_PRINTER_MODEL);
      // copy service property to model
      this.printerModel = pick(
        printer,
        Object.keys(this.printerModel),
      ) as unknown as PrinterFormModel;

      this.printerModel.fis = !!printer.printMode.fis;
      this.printerModel.nfis = !!printer.printMode.nfis;
      this.printerModel.text = !!printer.printMode.text;
      this.printerModel.list = !!printer.printMode.list;

      this.printerModel.sectors = printer.sectors
        ? printer.sectors.map((sector: SectorHeader) => sector.id)
        : [];
      this.showPrinterDialog = true;
    },
    closePrinterDialog(): void {
      this.showPrinterDialog = false;
      this.editingPrinterId = 0;
    },
    modelToString(value: number): string {
      return Printers[value];
    },
    addPrinter(): void {
      this.editingPrinterId = 0;
      const newDepartments = [] as Array<DepartmentsStruct>;
      this.printerModel.departments = [] as Array<number>;
      for (let i = 0; i < 5; i += 1) {
        newDepartments.push({
          index: i.toString(),
          value: 0,
        } as DepartmentsStruct);
      }
      this.departmentModels[0] = newDepartments;
      this.printerModel = cloneDeep(DEFAULT_PRINTER_MODEL);
      this.showPrinterDialog = true;
    },

    togglePrinterVisibility(id: number) {
      const printer = this.printers.find((p: Printer) => p.id === id);
      if (!printer) {
        return;
      }
      if (this.visibilities[id]) {
        cookieUtil.set(`spit_prt-hide-${id}`, '1', 365 * 10);
      } else {
        cookieUtil.remove(`spit_prt-hide-${id}`);
      }
      this.visibilities[id] = !this.visibilities[id];
    },
    initPrintersData(): void {
      /**
       * Build support arrays (visibilities and departments)
       */
      this.printers.forEach((printer) => {
        /** Departments */
        const departmentModel = [] as Array<DepartmentsStruct>;
        printer.departments.forEach((value: number, index: number) => {
          const struct = {} as DepartmentsStruct;
          struct.index = `${index}`;
          struct.value = value;
          departmentModel.push(struct);
        });
        this.departmentModels[printer.id] = departmentModel;
        /** Visibilities (based on cookie) */
        this.visibilities[printer.id] = !cookieUtil.get(
          `spit_prt-hide-${printer.id}`,
        );
      });
    },
  },
  computed: {
    license(): License {
      return this.$store.getters['session/license'];
    },
    printers(): Array<Printer> {
      return this.$store.getters['session/printers'];
    },
    sectorOptions(): Array<DropdownOption> {
      return this.sectors.map((sector: Sector) => ({
        label: sector.header.name,
        value: sector.header.id,
      }));
    },
    ...mapState('session', ['sectors']),
  },
  async mounted() {
    this.initPrintersData();
  },
  async beforeMount(): Promise<void> {
    await this.$store.dispatch('session/setSectors');
  },
});
