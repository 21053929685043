
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import { PriceList, PricePeriod } from '@/models/priceList';
import PricePeriodDetails from '@/components/priceList/PricePeriodDetails.vue';
import PricePeriodDialog from '@/components/priceList/PricePeriodDialog.vue';
import priceListService from '@/services/priceListService';
import { ToastSeverity } from '@/models/toast';
import toastUtil from '@/utils/toastUtil';

interface PeriodInterval {
  startDate: string;
  endDate: string;
}

export default defineComponent({
  name: 'PriceListDetails',
  emits: ['update-periods'],
  components: {
    PricePeriodDetails,
    PricePeriodDialog,
  },
  props: {
    priceList: {
      type: Object as () => PriceList,
      default: null as PriceList | null,
    },
  },
  data() {
    return {
      showEditDialog: false,
      periodSelected: this.priceList.periods[0] ?? null,
      // List edit
      showPeriodEditDialog: false,
      periodEdit: null as PricePeriod | null,
    };
  },
  computed: {
    periods(): Array<PricePeriod> {
      return this.priceList.periods
        .slice(0)
        .sort(
          (a, b) =>
            DateTime.fromFormat(a.startDate, 'dd-MM').toSeconds() -
            DateTime.fromFormat(b.startDate, 'dd-MM').toSeconds(),
        );
    },
    nextSlot(): PeriodInterval | null {
      let startDate = DateTime.fromFormat('01-01', 'dd-MM');
      let endDate = DateTime.fromFormat('31-12', 'dd-MM');
      // eslint-disable-next-line no-restricted-syntax
      for (let i = 0; i < this.periods.length; i += 1) {
        const period = this.periods[i];
        const pStartDate = DateTime.fromFormat(period.startDate, 'dd-MM');
        const pEndDate = DateTime.fromFormat(period.endDate, 'dd-MM');
        if (startDate >= pStartDate) {
          startDate = pEndDate.plus({ days: 1 });
        }
        if (startDate >= endDate) {
          endDate = DateTime.fromFormat('31-12', 'dd-MM');
        }
        if (this.periods[i + 1]) {
          endDate = DateTime.fromFormat(
            this.periods[i + 1].startDate,
            'dd-MM',
          ).minus({ days: 1 });
        }
      }
      // No space available
      if (startDate > endDate) return null;
      return {
        startDate: startDate.toFormat('dd-MM'),
        endDate: endDate.toFormat('dd-MM'),
      };
    },
    tabs() {
      return this.periods.map((p) => ({
        period: p,
      }));
    },
  },
  methods: {
    onPeriodClick(period: PricePeriod): void {
      this.periodSelected = period;
    },
    onPeriodEditClick(period: PricePeriod): void {
      this.periodEdit = period;
      this.showPeriodEditDialog = true;
    },
    getOtherIntervals(period: PricePeriod): Array<PeriodInterval> {
      return this.periods
        .filter((p) => p.id !== period.id)
        .map(
          (p) =>
            ({
              startDate: p.startDate,
              endDate: p.endDate,
            } as PeriodInterval),
        );
    },
    onPricePeriodDialogHide(): void {
      this.showPeriodEditDialog = false;
      this.periodEdit = null;
    },
    addPeriod() {
      if (!this.nextSlot) return;
      this.periodEdit = {
        priceListId: this.priceList.id,
        startDate: this.nextSlot.startDate,
        endDate: this.nextSlot.endDate,
        useWeekend: false,
        weekendRules: [] as Array<string>,
      } as PricePeriod;
      this.showPeriodEditDialog = true;
    },
    async onPeriodSave(payload: any): Promise<void> {
      this.onPricePeriodDialogHide();
      const pricePeriodToSave = payload.period as PricePeriod;
      try {
        const toUpdate = [] as Array<PricePeriod>;
        if (!pricePeriodToSave.id) {
          const promises = [priceListService.createPeriod(pricePeriodToSave)];
          // eslint-disable-next-line no-restricted-syntax
          for (const e of payload.extras as Array<PeriodInterval>) {
            promises.push(
              priceListService.createPeriod({
                priceListId: pricePeriodToSave.priceListId,
                name: pricePeriodToSave.name,
                useWeekend: false,
                weekendRules: [] as Array<string>,
                endDate: e.endDate,
                startDate: e.startDate,
              } as PricePeriod),
            );
          }
          const responses = await Promise.all(promises);
          // eslint-disable-next-line no-restricted-syntax
          for (const response of responses) {
            toUpdate.push(response.result.pricePeriod);
          }
        } else {
          const response = await priceListService.updatePeriod(
            pricePeriodToSave.id,
            pricePeriodToSave,
          );
          toUpdate.push(response.result.pricePeriod);
        }
        // Show success toast
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
        // Update state
        const periods = this.priceList.periods.slice(0);
        // eslint-disable-next-line no-restricted-syntax
        for (const period of toUpdate) {
          let index = periods.findIndex((p) => p.id === period.id);
          if (index !== -1) {
            periods[index] = cloneDeep(period);
          } else {
            periods.push(cloneDeep(period));
            index = periods.length - 1;
          }
        }
        this.$emit('update-periods', periods);
        this.$nextTick(() => {
          this.periodSelected = toUpdate[0];
        });
      } catch (e) {
        console.error(e);
      }
    },
    onDeleteEvent(): void {
      this.$confirm.require({
        message: this.$t('priceListDetails.confirm.message'),
        header: this.$t('priceListDetails.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('common.yes'),
        accept: () => {
          this.onDelete();
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          // nothing
        },
      });
    },
    async onDelete(): Promise<void> {
      if (!this.periodEdit) return;
      const period = cloneDeep(this.periodEdit);
      this.onPricePeriodDialogHide();
      try {
        await priceListService.deletePeriod(period.id);
        // Show success toast
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        ); // TODO: proper toast
        // Update state
        const periods = this.priceList.periods.slice(0);
        const index = periods.findIndex((p) => p.id === period.id);
        if (index !== -1) {
          periods.splice(index, 1);
        }
        this.$emit('update-periods', periods);
        this.$nextTick(() => {
          this.periodSelected = this.priceList.periods[0] ?? null;
        });
      } catch (e) {
        // console.log(e);
      }
    },
  },
  watch: {
    priceList() {
      this.periodSelected = this.priceList.periods[0] ?? null;
    },
  },
});
