

    import { defineComponent } from 'vue';
    import { AxiosError } from 'axios';
    import { useRoute } from 'vue-router';
    import { License, StripeAccountInitResponse } from '@/models/license';
    import licenseService from '@/services/licenseService';
    import { StripeFinalizeAccountPayload, StripePayload } from '@/models';

    export default defineComponent({
      data() {
        return {
          isCustom: false,
          stripePosAccountConnectionSuccess: false,
          errorType: '',
          response: null,
          isLoading: true,
        };
      },
      methods: {
        async stripePosAccountInitConnection() {
          try {
            if (this.license) {
              const payload: StripePayload = { type: 'custom' };
              const response: StripeAccountInitResponse = await licenseService
              .stripePosAccountInitConnection(this.license.license, payload);
              window.open(response.stripeAccountLink);
            }
          } catch (error) {
            // console.log(error);
          }
        },
        goToBookingConfig() {
          this.$router.push('/settings/booking');
        },
      },
      computed: {
        license(): License | null {
          return this.$store.getters['session/license'];
        },
        isStripePosAccountConnected(): boolean {
          return this.stripePosAccountConnectionSuccess;
        },
      },
      async beforeMount() {
        const route = useRoute();
        const type: string = route.params.type as string;
        if (type) {
          this.isCustom = type === 'custom';
          try {
            if (this.license) {
              const payload: StripeFinalizeAccountPayload = {
                 type,
                 code: undefined,
                 state: undefined,
                };
              if (!this.isCustom) {
                payload.code = route.query.code as string;
                payload.state = route.query.state as string;
              }
              await licenseService
              .stripePosAccountFinalizeConnection(this.license.license, payload);
              this.stripePosAccountConnectionSuccess = true;
              this.isLoading = false;
            } else {
              this.$spiagge.toast.error(
                this.$t('stripePosConnectionReturnView.missing_license_error'),
              );
            }
          } catch (error) {
            this.errorType = (error as AxiosError)?.response?.data.error;
            this.stripePosAccountConnectionSuccess = false;
            this.isLoading = false;
          }
        } else {
          this.$spiagge.toast.error(
            this.$t('stripePosConnectionReturnView.missing_type_error'),
        );
        }
      },
    });

    