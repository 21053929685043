
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import {
  ApiOrderPayload,
  ApiReservationCreatePayload,
  ApiReservationFindPayload,
  ApiReservationFindResponse,
} from '@/models/api';
import { AppAction } from '@/models/app';
import reservationService from '@/services/reservationService';
import { Reservation, ReservationStatus } from '@/models/reservation';
import { SpotAttribute, SpotType, SpotAttributesPayload } from '@/models/spot';
import { StyleSpotAttribute, StyleSpotAttributeIcons } from '@/models/style';

export default defineComponent({
  name: 'MapBedsArea',
  props: {
    spotName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      ready: false,
      reservations: [] as Array<Reservation>,
    };
  },
  methods: {
    async onNewReservation(): Promise<void> {
      const res = await reservationService.createOne({
        spotName: this.spotName,
        spotType: SpotType.BEDS,
        startDate: this.$store.getters['map/calendar'][0].toSeconds(),
        endDate: this.$store.getters['map/calendar'][1].toSeconds(),
      } as ApiReservationCreatePayload);
      this.$store.commit('app/setAction', AppAction.CREATE_RESERVATION);
      this.$router.push(`/reservation/${res.id}`);
    },
    onPrint(): void {
      this.$spiagge.utils.global.printHtml(
        this.$refs.printContent as unknown as HTMLElement,
      );
    },
    async getReservations(): Promise<void> {
      const res: ApiReservationFindResponse = await reservationService.find({
        startDate: this.$store.getters['map/calendar'][0].toSeconds(),
        endDate: this.$store.getters['map/calendar'][1].toSeconds(),
        spotType: SpotType.BEDS,
        spotName: this.spotName,
        deleted: false,
        orderBy: [
          {
            field: 'createdAt',
            value: 'desc',
          },
        ] as ApiOrderPayload,
      } as ApiReservationFindPayload);
      this.reservations = res.result.reservations;
    },
    attributes(reservation: Reservation): Array<SpotAttribute> {
      const spotAttributesPayload = {
        temporary: reservation.status === ReservationStatus.NOT_CONFIRMED,
        seasonal: !!reservation.seasonal,
        halfDay: reservation.halfDay,
        paid: !!reservation.paid,
        startDate: DateTime.fromSeconds(reservation.startDate),
        endDate: DateTime.fromSeconds(reservation.endDate),
        startDateRef: this.$store.getters['map/calendar'][0],
        endDateRef: this.$store.getters['map/calendar'][1],
      } as SpotAttributesPayload;
      return this.$spiagge.utils.global.spotAttributes(spotAttributesPayload);
    },
    style(reservation: Reservation): StyleSpotAttribute {
      return this.$spiagge.utils.global.spotStyleAttribute(
        this.attributes(reservation),
      );
    },
    icon(reservation: Reservation): string {
      return (this.style(reservation).icons as StyleSpotAttributeIcons).beds;
    },
  },
  computed: {
    show: {
      get(): boolean {
        return this.spotName !== '';
      },
      set(): void {
        this.$emit('close');
      },
    },
  },
  watch: {
    async spotName(spotName: string): Promise<void> {
      if (spotName) {
        this.ready = false;
        await this.getReservations();
        this.ready = true;
      }
    },
  },
});
