
import { defineComponent } from 'vue';
import { Locale } from '@/models/locale';
import localeUtil from '@/utils/localeUtil';

export default defineComponent({
  name: 'TopBarLocaleSelector',
  data() {
    return {
      locales: [] as Array<Locale>,
    };
  },
  beforeMount(): void {
    this.locales = localeUtil.getSupportedLocales();
  },
  methods: {
    onLocaleChange(locale: Locale): void {
      this.$confirm.require({
        message: this.$t('topBarLocaleSelector.confirm.message'),
        header: this.$t('topBarLocaleSelector.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('common.yes'),
        rejectLabel: this.$t('common.no'),
        accept: () => {
          this.setLocale(locale);
        },
      });
    },
    setLocale(locale: Locale): void {
      localeUtil.setLocale(locale);
      this.$store.commit('session/setLocale', locale);
      this.$router.go(0);
    },
  },
  computed: {
    currentLocale(): Locale {
      return this.$store.getters['session/locale'];
    },
  },
});
