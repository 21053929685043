
import { defineComponent } from 'vue';
import clone from 'lodash/clone';
import { mapState } from 'vuex';
import { MapMode } from '@/models/map';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

export default defineComponent({
  name: 'MapModes',
  data() {
    return {
      mapMode: clone(MapMode),
    };
  },
  computed: {
    FEATURE_PERMISSION_CONFIG() {
      return FEATURE_PERMISSION_CONFIG;
    },
    FEATURE_PERMISSION_ACTION_CONFIG() {
      return FEATURE_PERMISSION_ACTION_CONFIG;
    },
    permissionsUtil() {
      return permissionsUtil;
    },
    ...mapState('session', ['permissions']),
    mode: {
      get(): MapMode {
        return this.$store.getters['map/mode'];
      },
      set(mode: MapMode): void {
        this.$store.commit('map/setMode', mode);
      },
    },
  },
  methods: {
    canAddUpdateReservation(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.map,
        FEATURE_PERMISSION_ACTION_CONFIG.map.ADD_UPDATE_RESERVATIONS,
      );
    },
  },
});
