
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import {
  ApiStatisticsCountersVoucherPayload,
  ApiStatisticsCountersVoucherResponse,
} from '@/models/api';
import StatisticsCountersTable from '@/components/statistics/StatisticsCountersTable.vue';
import StatisticsDoughnutChart from '@/components/statistics/charts/StatisticsDoughnutChart.vue';
import ToggleButtonCustom from '@/components/shared/ToggleButtonCustom.vue';
import {
  StatisticCountersTableColumn,
  StatisticCounterVoucher,
  StatisticDateMode,
  StatisticDoughnutChartModel,
  StatisticCard as StatisticCardI,
} from '@/models/statistic';
import statisticService from '@/services/statisticService';
import StatisticsCard from '@/components/statistics/StatisticsCard.vue';
import { PricesVoucherFilterMode } from '@/models/license';

export default defineComponent({
  name: 'StatisticsCountersHotelView',
  components: {
    StatisticsCountersTable,
    StatisticsDoughnutChart,
    StatisticsCard,
    ToggleButtonCustom,
  },
  data() {
    return {
      groupFilter: false,
      widthToggle: 130,
      trueLabel: this.$t('statisticsCounters.trueLabel'),
      falseLabel: this.$t('statisticsCounters.falseLabel'),
      selectedTab: 0,
      tabs: [
        {
          label: this.$t('statisticsCounters.tab.tableView'),
          icon: 'pi pi-table',
        },
        {
          label: this.$t('statisticsCounters.tab.chartView'),
          icon: 'pi pi-chart-bar',
        },
      ],
      // table
      columns: [
        {
          label: this.$t('statisticsCountersVoucherView.column.hotelName'),
          field: 'name',
        },
        {
          label: this.$t('statisticsCountersVoucherView.column.vouchers'),
          field: 'vouchers',
        },
        {
          label: this.$t('statisticsCountersVoucherView.column.duration'),
          field: 'duration',
        },
        {
          label: this.$t('statisticsCountersVoucherView.column.value'),
          field: 'value',
        },
      ] as Array<StatisticCountersTableColumn>,
      counters: [] as Array<StatisticCounterVoucher>,
      // charts
      reservationsChart: {
        label: this.$t('statisticsCountersVoucherView.reservationsChart.label'),
        chartData: {
          labels: [
            this.$t(
              'statisticsCountersVoucherView.reservationsChart.withVoucherLabel',
            ),
            this.$t(
              'statisticsCountersVoucherView.reservationsChart.withoutVoucherLabel',
            ),
          ],
          datasets: [
            {
              data: [] as Array<number>,
              backgroundColor: ['#FFE596', '#E89300'],
            },
          ],
        },
      },
      daysChart: {
        label: this.$t('statisticsCountersVoucherView.daysChart.label'),
        chartData: {
          labels: [
            this.$t('statisticsCountersVoucherView.daysChart.withVoucherLabel'),
            this.$t(
              'statisticsCountersVoucherView.daysChart.withoutVoucherLabel',
            ),
          ],
          datasets: [
            {
              data: [] as Array<number>,
              backgroundColor: ['#96DFFF', '#0083E8'],
            },
          ],
        },
      },
      // cards
      voucherTotalCard: {
        label: this.$t('statisticsCountersVoucherView.voucherTotalCard.label'),
        value: null,
        tooltip: this.$t(
          'statisticsCountersVoucherView.voucherTotalCard.tooltip',
        ),
      } as StatisticCardI,
      totalValueCard: {
        label: this.$t('statisticsCountersVoucherView.totalValueCard.label'),
        value: null,
        tooltip: this.$t(
          'statisticsCountersVoucherView.totalValueCard.tooltip',
        ),
      } as StatisticCardI,
    };
  },
  beforeMount(): void {
    this.$store.commit(
      'statistic/setTitle',
      this.$t('statisticsCountersVoucherView.setTitle'),
    );
    this.$store.commit(
      'statistic/setSubtitle',
      this.$t('statisticsCountersVoucherView.setSubtitle'),
    );
    this.groupFilter = this.groupVoucherData;
    this.runQuery();

    if (this.windowWidth < 992) this.widthToggle = 108;
    else this.widthToggle = 130;

    this.$store.dispatch('session/setLicense', this.license?.license);
  },
  methods: {
    async runQuery(): Promise<void> {
      try {
        const payload = {
          dateMode:
            this.license?.pricesVoucherFilterMode ===
            PricesVoucherFilterMode.ENDING
              ? StatisticDateMode.DEPARTURE
              : StatisticDateMode.ARRIVAL,
          startDate: this.calendar[0].toSeconds(),
          endDate: this.calendar[1].toSeconds(),
          groupData: this.groupFilter,
        } as ApiStatisticsCountersVoucherPayload;
        const res: ApiStatisticsCountersVoucherResponse =
          await statisticService.countersVoucher(payload);
        this.counters = res.result.counters;
        this.reservationsChart.chartData.datasets[0].data =
          res.result.charts.reservations;
        this.daysChart.chartData.datasets[0].data = res.result.charts.days;
        this.voucherTotalCard.value = res.result.cards.voucherTotal.toString();
        this.totalValueCard.value = `${res.result.cards.totalValue.toString()}€`;
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('statisticsCounters.toast.retrievalError'),
        );
      }
    },
  },
  computed: {
    ...mapState('statistic', ['calendar', 'groupVoucherData']),
    ...mapState('app', ['windowWidth', 'windowHeight']),
    ...mapState('session', ['license']),
    hotelVouchersNumberChart(): StatisticDoughnutChartModel {
      const chartModel = {
        label: this.$t(
          'statisticsCountersVoucherView.vouchersNumberChartModelLabel',
        ),
        chartData: {
          labels: [] as Array<string>,
          datasets: [
            {
              data: [] as Array<number>,
              backgroundColor: ['#CB4DFF', '#242424', '#964B00', '#AFAFAF'],
            },
          ],
        },
      };
      if (!this.counters.length) {
        return chartModel;
      }
      let aggregate = [] as Array<StatisticCounterVoucher>;
      const orderedCounters = cloneDeep(this.counters).sort(
        // eslint-disable-next-line no-confusing-arrow
        (a: StatisticCounterVoucher, b: StatisticCounterVoucher) =>
          a.vouchers < b.vouchers ? 1 : -1,
      );
      // max 4 values
      if (orderedCounters.length > 4) {
        aggregate = orderedCounters.splice(3);
      }
      orderedCounters.map((counter: StatisticCounterVoucher) => {
        chartModel.chartData.labels.push(counter.name);
        chartModel.chartData.datasets[0].data.push(counter.vouchers);
      });
      if (aggregate.length > 0) {
        chartModel.chartData.labels.push(this.$t('common.other'));
        chartModel.chartData.datasets[0].data.push(
          aggregate.reduce(
            (count: number, counter: StatisticCounterVoucher) =>
              count + counter.vouchers,
            0,
          ),
        );
      }
      return chartModel;
    },
    hotelVouchersValueChart(): StatisticDoughnutChartModel {
      const chartModel = {
        label: this.$t(
          'statisticsCountersVoucherView.vouchersValueChartModelLabel',
        ),
        chartData: {
          labels: [] as Array<string>,
          datasets: [
            {
              data: [] as Array<number>,
              backgroundColor: ['#FF80BF', '#199CFF', '#C7EA46', '#AFAFAF'],
            },
          ],
        },
      };
      if (!this.counters.length) {
        return chartModel;
      }
      let aggregate = [] as Array<StatisticCounterVoucher>;
      const orderedCounters = cloneDeep(this.counters).sort(
        // eslint-disable-next-line no-confusing-arrow
        (a: StatisticCounterVoucher, b: StatisticCounterVoucher) =>
          a.value < b.value ? 1 : -1,
      );
      // max 4 values
      if (orderedCounters.length > 4) {
        aggregate = orderedCounters.splice(3);
      }
      orderedCounters.map((counter: StatisticCounterVoucher) => {
        chartModel.chartData.labels.push(counter.name);
        chartModel.chartData.datasets[0].data.push(counter.value);
      });
      if (aggregate.length > 0) {
        chartModel.chartData.labels.push(this.$t('common.other'));
        chartModel.chartData.datasets[0].data.push(
          aggregate.reduce(
            (count: number, counter: StatisticCounterVoucher) =>
              count + counter.value,
            0,
          ),
        );
      }
      return chartModel;
    },
    groupDataFilter: {
      set(value: boolean) {
        this.groupFilter = !value;
        this.$store.commit('statistic/setGroupVoucherData', this.groupFilter);
        this.runQuery();
      },
      get(): boolean {
        return !this.groupFilter;
      },
    },
  },
  watch: {
    calendar() {
      if (this.calendar[1] === null) return;
      this.runQuery();
    },
    windowWidth() {
      if (this.windowWidth === null) return;
      if (this.windowWidth < 992) this.widthToggle = 108;
      else this.widthToggle = 130;
    },
  },
});
