import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e166a348"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "reservation-joint-account-summary" }
const _hoisted_2 = {
  key: 0,
  class: "joints-cards"
}
const _hoisted_3 = { class: "spot-price" }
const _hoisted_4 = { class: "spot" }
const _hoisted_5 = { class: "price" }
const _hoisted_6 = { class: "label" }
const _hoisted_7 = { class: "value" }
const _hoisted_8 = { class: "columns" }
const _hoisted_9 = { class: "column informations" }
const _hoisted_10 = { class: "info staging" }
const _hoisted_11 = { class: "label" }
const _hoisted_12 = { class: "value" }
const _hoisted_13 = { class: "info start-date" }
const _hoisted_14 = { class: "label" }
const _hoisted_15 = { class: "value" }
const _hoisted_16 = { class: "info end-date" }
const _hoisted_17 = { class: "label" }
const _hoisted_18 = { class: "value" }
const _hoisted_19 = { class: "info displacements" }
const _hoisted_20 = { class: "label" }
const _hoisted_21 = {
  key: 0,
  class: "value"
}
const _hoisted_22 = { key: 1 }
const _hoisted_23 = { class: "column informations" }
const _hoisted_24 = { class: "info parkings" }
const _hoisted_25 = { class: "value" }
const _hoisted_26 = {
  key: 0,
  class: "value"
}
const _hoisted_27 = { key: 1 }
const _hoisted_28 = { class: "info services" }
const _hoisted_29 = { class: "label" }
const _hoisted_30 = {
  key: 0,
  class: "value"
}
const _hoisted_31 = ["src"]
const _hoisted_32 = {
  key: 1,
  class: "no-value"
}
const _hoisted_33 = { class: "info additions" }
const _hoisted_34 = { class: "label" }
const _hoisted_35 = {
  key: 0,
  class: "value"
}
const _hoisted_36 = { key: 1 }
const _hoisted_37 = { class: "info cabins" }
const _hoisted_38 = { class: "label" }
const _hoisted_39 = {
  key: 0,
  class: "value"
}
const _hoisted_40 = { key: 1 }
const _hoisted_41 = { class: "column informations" }
const _hoisted_42 = { class: "info expenses" }
const _hoisted_43 = { class: "label" }
const _hoisted_44 = {
  key: 0,
  class: "value"
}
const _hoisted_45 = { key: 1 }
const _hoisted_46 = { class: "info notes" }
const _hoisted_47 = { class: "label" }
const _hoisted_48 = {
  key: 0,
  class: "value"
}
const _hoisted_49 = { key: 1 }
const _hoisted_50 = { class: "column actions" }
const _hoisted_51 = { class: "action open" }
const _hoisted_52 = { class: "action disjoin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_DisjoinAccountButton = _resolveComponent("DisjoinAccountButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h6", null, _toDisplayString(_ctx.jointsCards.length) + " " + _toDisplayString(_ctx.$t('reservationJointAccountSummary.title')), 1)
    ]),
    (_ctx.jointsCards)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.jointsCards, (jointCard, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "joint-card"
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(jointCard.spotType) + " #" + _toDisplayString(jointCard.spotName), 1),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("strong", _hoisted_6, _toDisplayString(_ctx.$t('common.price')) + ":", 1),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(jointCard.price) + " €", 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("strong", _hoisted_11, _toDisplayString(_ctx.$t('reservationJointAccountSummary.staging')) + ":", 1),
                    _createElementVNode("span", _hoisted_12, _toDisplayString(jointCard.staging), 1)
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("strong", _hoisted_14, _toDisplayString(_ctx.$t('common.from')) + ":", 1),
                    _createElementVNode("span", _hoisted_15, _toDisplayString(jointCard.startDate), 1)
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createElementVNode("strong", _hoisted_17, _toDisplayString(_ctx.$t('common.to')) + ":", 1),
                    _createElementVNode("span", _hoisted_18, _toDisplayString(jointCard.endDate), 1)
                  ]),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("strong", _hoisted_20, _toDisplayString(_ctx.$t('reservationJointAccountSummary.displacements')) + ":", 1),
                    (jointCard.displacements.length > 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_21, _toDisplayString(jointCard.displacements
                    .map((displacement) => '#' + displacement.spotName)
                    .join(', ')), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.$t('common.none')), 1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("strong", _hoisted_25, _toDisplayString(_ctx.$t('reservationJointAccountSummary.parkings')) + ":", 1),
                    (jointCard.parkings.length > 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(jointCard.parkings
                    .map((parking) => '#' + parking.spotName)
                    .join(', ')), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_27, _toDisplayString(_ctx.$t('common.none')), 1))
                  ]),
                  _createElementVNode("div", _hoisted_28, [
                    _createElementVNode("strong", _hoisted_29, _toDisplayString(_ctx.$t('reservationJointAccountSummary.services')) + ":", 1),
                    (jointCard.services.length > 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_30, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(jointCard.services, (service, index) => {
                            return (_openBlock(), _createElementBlock("img", {
                              key: index,
                              src: _ctx.getServiceUrl(service)
                            }, null, 8, _hoisted_31))
                          }), 128))
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_32, _toDisplayString(_ctx.$t('common.none')), 1))
                  ]),
                  _createElementVNode("div", _hoisted_33, [
                    _createElementVNode("strong", _hoisted_34, _toDisplayString(_ctx.$t('reservationJointAccountSummary.additions')) + ":", 1),
                    (jointCard.additions.length > 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_35, _toDisplayString(_ctx.getAdditionsStaging(jointCard.additions)), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_36, _toDisplayString(_ctx.$t('common.none')), 1))
                  ]),
                  _createElementVNode("div", _hoisted_37, [
                    _createElementVNode("strong", _hoisted_38, _toDisplayString(_ctx.$t('reservationJointAccountSummary.cabins')) + ":", 1),
                    (jointCard.cabins.length > 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_39, _toDisplayString(jointCard.cabins
                    .map((cabin) => '#' + cabin.spotName)
                    .join(',')), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_40, _toDisplayString(_ctx.$t('reservationJointAccountSummary.none')), 1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_41, [
                  _createElementVNode("div", _hoisted_42, [
                    _createElementVNode("strong", _hoisted_43, _toDisplayString(_ctx.$t('reservationJointAccountSummary.expenses')) + ":", 1),
                    (jointCard.expenses.length > 0)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_44, _toDisplayString(jointCard.expenses.map((expense) => expense.name).join(', ')), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_45, _toDisplayString(_ctx.$t('reservationJointAccountSummary.none')), 1))
                  ]),
                  _createElementVNode("div", _hoisted_46, [
                    _createElementVNode("strong", _hoisted_47, _toDisplayString(_ctx.$t('reservationJointAccountSummary.notes')) + ":", 1),
                    (jointCard.notes)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_48, _toDisplayString(jointCard.notes), 1))
                      : (_openBlock(), _createElementBlock("span", _hoisted_49, _toDisplayString(_ctx.$t('reservationJointAccountSummary.none')), 1))
                  ])
                ]),
                _createElementVNode("div", _hoisted_50, [
                  _createElementVNode("div", _hoisted_51, [
                    _createVNode(_component_Button, {
                      label: _ctx.$t('reservationJointAccountSummary.openDetail'),
                      class: "p-button-text",
                      onClick: ($event: any) => (_ctx.$router.push(`/reservation/${jointCard.id}`)),
                      icon: "spi-document"
                    }, null, 8, ["label", "onClick"])
                  ]),
                  _createElementVNode("div", _hoisted_52, [
                    _createVNode(_component_DisjoinAccountButton, {
                      reservationId: jointCard.id,
                      spotName: jointCard.spotName,
                      class: "p-button-text"
                    }, null, 8, ["reservationId", "spotName"])
                  ])
                ])
              ])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}