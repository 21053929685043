import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-25dfbb62"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tooltip" }
const _hoisted_2 = { class: "title-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toast = _resolveComponent("toast")!

  return (_openBlock(), _createElementBlock("div", {
    id: "map-tooltips",
    class: _normalizeClass(["map-control", _ctx.active ? 'active' : ''])
  }, [
    _createVNode(_component_toast, {
      id: "map-toast",
      position: "top-center",
      group: "map"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("i", {
        class: _normalizeClass(_ctx.tooltip.icon)
      }, null, 2),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.tooltip.title), 1),
        _createElementVNode("p", null, _toDisplayString(_ctx.tooltip.content), 1)
      ])
    ])
  ], 2))
}