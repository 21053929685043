/**
 * Statistic store module
 */

import cloneDeep from 'lodash/cloneDeep';
import { MutationTree, ActionTree, ActionContext, GetterTree } from 'vuex';
import {
  RootState,
  StatisticState,
  DEFAULT_STATISTIC_STATE,
} from '@/models/store';
import { CalendarRange } from '@/models';

type StatisticContext = ActionContext<StatisticState, RootState>;

const namespaced = true;

const state = (): StatisticState => cloneDeep(DEFAULT_STATISTIC_STATE);

const getters: GetterTree<StatisticState, RootState> = {} as GetterTree<
  StatisticState,
  RootState
>;

const mutations: MutationTree<StatisticState> = {
  setTitle(state: StatisticState, title: string) {
    state.title = title;
    state.subtitle = '';
  },
  setSubtitle(state: StatisticState, subtitle: string) {
    state.subtitle = subtitle;
  },
  setCalendar(state: StatisticState, calendar: CalendarRange) {
    state.calendar = calendar;
  },
  setBackRoute(state: StatisticState, backRoute: string | null) {
    state.backRoute = backRoute;
  },
  setGroupHotelData(state: StatisticState, groupHotelData: boolean) {
    state.groupHotelData = groupHotelData;
  },
  setGroupVoucherData(state: StatisticState, groupVoucherData: boolean) {
    state.groupVoucherData = groupVoucherData;
  },
  setPrintFn(state: StatisticState, printFn: (() => void) | null) {
    state.printFn = printFn;
  },
  setExportFn(state: StatisticState, exportFn: (() => void) | null) {
    state.exportFn = exportFn;
  },
  reset(state: StatisticState) {
    Object.assign(state, DEFAULT_STATISTIC_STATE);
  },
} as MutationTree<StatisticState>;

const actions: ActionTree<StatisticState, RootState> = {
  async something(context: StatisticContext): Promise<void> {
    // async
    // console.log(context);
  },
} as ActionTree<StatisticState, RootState>;

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
