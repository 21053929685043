
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { uniqBy } from 'lodash';
import {
  Reservation,
  ReservationExpense,
  ReservationExpenseAppData,
  ReservationService,
  RESERVATION_EXPENSE_APP_DATA,
} from '@/models/reservation';
import { SpotType } from '@/models/spot';
import { Staging } from '@/models';
import { Service } from '@/models/service';
import DisjoinAccountButton from '@/components/shared/DisjoinAccountButton.vue';

interface JointCard {
  id: number;
  spotType: string;
  spotName: string;
  staging: string;
  startDate: string;
  endDate: string;
  displacements: Array<Reservation>;
  voucher: boolean;
  parkings: Array<Reservation>;
  services: Array<Service>;
  additions: Array<Reservation>;
  cabins: Array<Reservation>;
  expenses: Array<ReservationExpenseAppData>;
  notes: string;
  price: number;
}

export default defineComponent({
  name: 'ReservationJointAccountSummary',
  components: { DisjoinAccountButton },
  methods: {
    reservationTotal(res: Reservation) {
      return this.$spiagge.utils.reservation.getTotalOfReservation(res);
    },
    getServiceUrl(service: Service): string {
      // eslint-disable-next-line import/no-dynamic-require
      return require(`@/assets/images/extra-services/${service.icon}`);
    },
    getAdditionsStaging(additions: Array<Reservation>): string {
      const additionsStaging: Staging = {
        maxiBeds: 0,
        beds: 0,
        chairs: 0,
        deckChairs: 0,
      };
      additions.map((addition: Reservation) => {
        additionsStaging.maxiBeds += addition.maxiBeds;
        additionsStaging.beds += addition.beds;
        additionsStaging.deckChairs += addition.deckChairs;
        additionsStaging.chairs += addition.chairs;
      });
      return this.$spiagge.utils.global.stagingLabel(
        additionsStaging.maxiBeds,
        additionsStaging.beds,
        additionsStaging.deckChairs,
        additionsStaging.chairs,
        '+',
      );
    },
  },
  computed: {
    ...mapState('reservation', [
      'id',
      'joints',
      'cashFlows',
      'cabins',
      'parkings',
      'additions',
      'expenses',
    ]),
    ...mapGetters('reservation', ['isJointAccount', 'canDisjoinAccount']),
    ...mapGetters('session', {
      beachServices: 'beachServices',
    }),
    ...mapState('reservation', {
      reservationServices: 'services',
    }),
    jointsCards(): Array<JointCard> {
      return (
        this.joints
          .filter(
            (res: Reservation) =>
              this.id === res.masterId && res.deleted === false,
          )
          // eslint-disable-next-line no-confusing-arrow
          .sort((res1: Reservation, res2: Reservation) =>
            res2.startDate <= res1.startDate ? 1 : -1,
          )
          .map((joint: Reservation) => {
            // join card
            const jointCard: JointCard = {
              id: joint.id,
              spotType: this.$spiagge.utils.spot.getLabel(
                joint.spotType as SpotType,
              ),
              spotName: joint.spotName,
              staging: this.$spiagge.utils.global.stagingLabel(
                joint.maxiBeds,
                joint.beds,
                joint.deckChairs,
                joint.chairs,
                '+',
              ),
              startDate: this.$spiagge.utils.date.fromSeconds(joint.startDate),
              endDate: this.$spiagge.utils.date.fromSeconds(joint.endDate),
              displacements: [],
              voucher: joint.voucherId > 0,
              parkings: [],
              services: [],
              additions: [],
              cabins: [],
              expenses: [],
              notes: joint.notes ?? '',
              price: joint.totalPrice,
            };

            // cabins
            jointCard.cabins = this.cabins.filter(
              (cabin: Reservation) => joint.id === cabin.masterId,
            );

            // parkings
            jointCard.parkings = this.parkings.filter(
              (parking: Reservation) => joint.id === parking.masterId,
            );

            // additions
            jointCard.additions = this.additions.filter(
              (addition: Reservation) => joint.id === addition.masterId,
            );

            // displacements
            jointCard.displacements = this.joints
              .filter(
                (displacement: Reservation) =>
                  joint.id === displacement.masterId,
              )
              // eslint-disable-next-line no-confusing-arrow
              .sort((displacment1: Reservation, displacment2: Reservation) =>
                displacment1.startDate <= displacment2.startDate ? 1 : -1,
              );

            // expenses
            const expenses = this.expenses.map(
              (expense: ReservationExpense) =>
                RESERVATION_EXPENSE_APP_DATA.find(
                  (appData: ReservationExpenseAppData) =>
                    expense.app === appData.app,
                ) as ReservationExpenseAppData,
            );
            jointCard.expenses = uniqBy(
              expenses,
              'app',
            ) as unknown as Array<ReservationExpenseAppData>;

            // services
            this.reservationServices
              .filter(
                (reservationService: ReservationService) =>
                  reservationService.reservationId === joint.id,
              )
              .map((reservationService: ReservationService) => {
                const beachService: Service = this.beachServices.find(
                  (beachService: Service) =>
                    beachService.id === reservationService.serviceId,
                );
                if (beachService) {
                  jointCard.services.push(beachService);
                }
              });
            return jointCard;
          })
      );
    },
  },
});
