import { Timestamp } from '.';
import { SectorHeader } from './sector';

export interface WebticTicket {
  id: number;
  ticketId: number;
  barcode: string;
  amount: number;
  quantity: number;
}

export interface WebticTerminal {
  id: number;
  licenseId: number;
  name: string;
  terminalId: number;
  sectors: Array<SectorHeader>;
  disabled: boolean;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}
