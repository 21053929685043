import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7543b9f3"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "map-legend",
  class: "map-control"
}
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Legend = _resolveComponent("Legend")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Legend, { view: 'map' }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: 'icon-' + index,
            class: _normalizeClass(["item", item.attribute])
          }, [
            (item.icon)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: item.icon,
                  alt: ""
                }, null, 8, _hoisted_2))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["spot", item.attribute]),
                  style: _normalizeStyle(item.style)
                }, [
                  (item.attributeIcon)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: "attr-icon",
                        style: _normalizeStyle({ backgroundColor: item.style.borderColor }),
                        src: item.attributeIcon
                      }, null, 12, _hoisted_3))
                    : _createCommentVNode("", true)
                ], 6)),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t(item.label)), 1)
          ], 2))
        }), 128))
      ]),
      _: 1
    })
  ]))
}