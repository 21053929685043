import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7dbfc038"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "top-bar",
  class: "print-top-bar"
}
const _hoisted_2 = { class: "left" }
const _hoisted_3 = { class: "right no-print" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_TopBarSearch = _resolveComponent("TopBarSearch")!
  const _component_TopBarNewTicketReservation = _resolveComponent("TopBarNewTicketReservation")!
  const _component_TopBarNewReservation = _resolveComponent("TopBarNewReservation")!
  const _component_TopBarChatBot = _resolveComponent("TopBarChatBot")!
  const _component_TopBarCheckoutPointSelector = _resolveComponent("TopBarCheckoutPointSelector")!
  const _component_TopBarCardReader = _resolveComponent("TopBarCardReader")!
  const _component_TopBarOtherServices = _resolveComponent("TopBarOtherServices")!
  const _component_TopBarNotifications = _resolveComponent("TopBarNotifications")!
  const _component_TopBarProfile = _resolveComponent("TopBarProfile")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!
  const _component_ProgressBarStriped = _resolveComponent("ProgressBarStriped")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Button, {
        class: "navigation-button p-button-text p-button-white p-button-lg no-print",
        icon: 'pi pi-bars',
        iconPos: "left",
        onClick: _ctx.toggleNavigation
      }, null, 8, ["onClick"]),
      _createVNode(_component_Logo, { type: 'print-logo' }),
      _createVNode(_component_TopBarSearch, { class: "no-print" })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_TopBarNewTicketReservation),
      _createVNode(_component_TopBarNewReservation),
      (_ctx.isChatBotEnabled())
        ? (_openBlock(), _createBlock(_component_TopBarChatBot, {
            key: 0,
            class: "hidden md:block"
          }))
        : _createCommentVNode("", true),
      (_ctx.license?.multipleCheckoutPointsEnabled)
        ? (_openBlock(), _createBlock(_component_TopBarCheckoutPointSelector, { key: 1 }))
        : _createCommentVNode("", true),
      (
          _ctx.license?.license === 'it-me-98035-naxos-beach---unahotel' ||
          _ctx.license?.license === 'demo2'
        )
        ? (_openBlock(), _createBlock(_component_TopBarCardReader, { key: 2 }))
        : _createCommentVNode("", true),
      _createVNode(_component_TopBarOtherServices),
      _createVNode(_component_TopBarNotifications),
      _createVNode(_component_TopBarProfile)
    ]),
    false
      ? (_openBlock(), _createBlock(_component_ProgressBar, {
          key: 0,
          mode: "indeterminate"
        }))
      : _createCommentVNode("", true),
    (_ctx.loading || _ctx.processing === 'topbar')
      ? (_openBlock(), _createBlock(_component_ProgressBarStriped, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}