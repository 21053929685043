/**
 * Admin service
 */

import axios, { AxiosResponse } from 'axios';
import {
  ApiAdminListResponse,
  ApiAdminCreateResponse,
  ApiAdminUpdateResponse,
  ApiAdminDeleteResponse,
} from '@/models/api';
import apiService from '@/services/apiService';
import { Admin } from '@/models/admin';

export default {
  async list(): Promise<ApiAdminListResponse> {
    const res: AxiosResponse<ApiAdminListResponse> = await axios.get(
      apiService.admin.list(),
    );
    return res.data;
  },
  async create(payload: Admin): Promise<Admin> {
    const res: AxiosResponse<ApiAdminCreateResponse> = await axios.post(
      apiService.admin.create(),
      payload,
    );
    return res.data.result.admin;
  },
  async update(id: number, payload: Admin): Promise<Admin> {
    const res: AxiosResponse<ApiAdminUpdateResponse> = await axios.patch(
      apiService.admin.update(id),
      payload,
    );
    return res.data.result.admin;
  },
  async delete(id: number): Promise<Admin> {
    const res: AxiosResponse<ApiAdminDeleteResponse> = await axios.delete(
      apiService.admin.delete(id),
    );
    return res.data.result.admin;
  },
};
