
import { defineComponent, PropType } from 'vue';
import { Field, Form } from 'vee-validate';
import {
  PriceList,
} from '@/models/priceList';
import priceConfigService from '@/services/priceConfigService';
import { PriceConfig } from '@/models/priceConfig';

export default defineComponent({
  name: 'PriceListsPriceListClone',
  emits: ['update', 'close'],
  components: { Field, Form },
  props: {
    priceList: {
      type: Object as PropType<PriceList>,
      required: true,
    },
  },
  data() {
    return {
      model: {
        name: '',
      },
    };
  },
  beforeMount(): void {
    this.initModel();
  },
  methods: {
    initModel(): void {
        this.model.name = `${this.priceList.name} Copia`;
    },
    async onSave(): Promise<void> {
      try {
        if (this.priceList.activePriceConfigId === null) {
          throw new Error('Id configurazione non specificato');
        }

        const priceConfig: PriceConfig = await priceConfigService.clone(
            this.priceList.activePriceConfigId,
            this.model.name,
        );
        this.$spiagge.toast.success('Listino clonato con successo');
        this.close();
        await this.$router.push(`/price-lists/${priceConfig.priceListId}`);
      } catch (e) {
        this.$spiagge.toast.error(
            'Si è verificato un errore durante la clonazione del listino',
        );
      }
    },
    close(): void {
      this.visible = false;
    },
  },
  computed: {
    visible: {
      get(): boolean {
        return true;
      },
      set(visible: boolean): void {
        this.$emit('close');
        this.$store.commit('priceLists/setShowPriceListCloneDialog', visible);
      },
    },
  },
});
