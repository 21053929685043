import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileUpload = _resolveComponent("FileUpload")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_FileUpload, {
      name: "imgUpl",
      onSelect: _ctx.onSelect,
      multiple: false,
      showUploadButton: false,
      fileLimit: 1,
      accept: _ctx.fileExtension,
      maxFileSize: _ctx.maxSize,
      customUpload: true
    }, {
      empty: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('uploadImg.placeholder')), 1)
      ]),
      _: 1
    }, 8, ["onSelect", "accept", "maxFileSize"])
  ]))
}