/**
 * Locale models
 */

export interface Locale {
  id: string;
  label: string;
  code2: string;
  code3: string;
  iso: string;
  image: string;
  supported: boolean;
  default: boolean;
}

export const LOCALES: Array<Locale> = [
  {
    id: 'en',
    label: 'English',
    code2: 'en',
    code3: 'eng',
    iso: 'en_US',
    image: require('@/assets/images/flags/en.png'),
    supported: true,
    default: false,
  },
  {
    id: 'it',
    label: 'Italiano',
    code2: 'it',
    code3: 'ita',
    iso: 'it_IT',
    image: require('@/assets/images/flags/it.png'),
    supported: true,
    default: true,
  },
  {
    id: 'fr',
    label: 'Français',
    code2: 'fr',
    code3: 'fra',
    iso: 'fr_FR',
    image: require('@/assets/images/flags/fr.png'),
    supported: true,
    default: false,
  },
  {
    id: 'ru',
    label: 'Russo',
    code2: 'ru',
    code3: 'rus',
    iso: 'ru_RU',
    image: require('@/assets/images/flags/ru.png'),
    supported: false,
    default: false,
  },
];
