import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f71701bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex-column align-items-center" }
const _hoisted_2 = { class: "label-contract mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", null, _toDisplayString(_ctx.$t('subscriptionsConfigView.title')), 1),
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t('subscriptionsConfigView.msg')), 1),
    _createVNode(_component_Button, {
      class: "mt-4",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openTeam())),
      label: _ctx.$t('subscriptionsConfigView.btn'),
      icon: "pi pi-check"
    }, null, 8, ["label"])
  ]))
}