
import { defineComponent, PropType } from 'vue';
import { cloneDeep } from 'lodash';
import { InputNumberInputEvent } from 'primevue/inputnumber';
import {
  PriceConfigModel,
  PriceConfigThresholdValue,
} from '@/models/priceConfig';

export default defineComponent({
  name: 'FieldPriceConfigPercentageDiscount',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Array as PropType<Array<PriceConfigThresholdValue>>,
      required: true,
    },
    data: {
      type: Object as PropType<PriceConfigModel>,
      required: true,
    },
  },
  mounted(): void {
    /**
     * On add/remove discount type update model
     */
    this.$watch('data.discountType', () => {
      this.$emit('update:modelValue', this.thresholdPercentageValues);
    });
  },
  methods: {
    onInput(
      event: InputNumberInputEvent,
      threshold: PriceConfigThresholdValue,
    ): void {
      /**
       * Update model on input value
       */
      let value = event.value as number;
      if (value > 100) {
        value = 100;
      }
      const updatedModel = cloneDeep(this.thresholdPercentageValues);
      const thresholdToUpdate = updatedModel.find(
        (t: PriceConfigThresholdValue) => t.threshold === threshold.threshold,
      );
      if (thresholdToUpdate) {
        thresholdToUpdate.value = value;
        this.$emit('update:modelValue', updatedModel);
      }
    },
  },
  computed: {
    thresholdPercentageValues(): Array<PriceConfigThresholdValue> {
      /**
       * For each discount type get the threshold/value
       */
      return this.data.discountType.map((discountType: number) => {
        const threshold = this.modelValue.find(
          (thresholdPercentageValue: PriceConfigThresholdValue) =>
            thresholdPercentageValue.threshold === discountType,
        );

        return {
          threshold: discountType,
          value: threshold ? threshold.value : 0,
        } as PriceConfigThresholdValue;
      });
    },
  },
});
