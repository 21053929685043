
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import revenueManagementService from '@/services/revenueManagementService';
import { RevenueManagementHistoricalDetail } from '@/models/revenueManagement';

export default defineComponent({
  name: 'RevenueManagementHistoricalDetails',
  data() {
    return {
      data: [] as Array<RevenueManagementHistoricalDetail>,
      columns: [
        {
          header: this.$t(
            'revenueManagementHistoricalDetails.column.reservationsCount',
          ),
          field: 'reservationsCount',
        },
        {
          header: this.$t(
            'revenueManagementHistoricalDetails.column.totalSpots',
          ),
          field: 'totalSpots',
        },
        {
          header: this.$t(
            'revenueManagementHistoricalDetails.column.totalAvailableSpots',
          ),
          field: 'totalAvailableSpots',
        },
        {
          header: this.$t(
            'revenueManagementHistoricalDetails.column.occupancy',
          ),
          field: 'occupancy',
        },
        {
          header: this.$t('revenueManagementHistoricalDetails.column.revenue'),
          field: 'revenue',
        },
        {
          header: this.$t(
            'revenueManagementHistoricalDetails.column.averageRevenue',
          ),
          field: 'averageRevenue',
        },
        {
          header: this.$t(
            'revenueManagementHistoricalDetails.column.daySevenPlus',
          ),
          field: 'daySevenPlus',
          day: true,
        },
        {
          header: this.$t('revenueManagementHistoricalDetails.column.daySeven'),
          field: 'daySeven',
          day: true,
        },
        {
          header: this.$t('revenueManagementHistoricalDetails.column.daySix'),
          field: 'daySix',
          day: true,
        },
        {
          header: this.$t('revenueManagementHistoricalDetails.column.dayFive'),
          field: 'dayFive',
          day: true,
        },
        {
          header: this.$t('revenueManagementHistoricalDetails.column.dayFour'),
          field: 'dayFour',
          day: true,
        },
        {
          header: this.$t('revenueManagementHistoricalDetails.column.dayThree'),
          field: 'dayThree',
          day: true,
        },
        {
          header: this.$t('revenueManagementHistoricalDetails.column.dayTwo'),
          field: 'dayTwo',
          day: true,
        },
        {
          header: this.$t('revenueManagementHistoricalDetails.column.dayOne'),
          field: 'dayOne',
          day: true,
        },
        {
          header: this.$t('revenueManagementHistoricalDetails.column.dayZero'),
          field: 'dayZero',
          day: true,
        },
      ],
    };
  },
  async beforeMount(): Promise<void> {
    this.data = await revenueManagementService.historicalDetails();
  },
  methods: {},
  computed: {
    ...mapState('app', ['breakpoints']),
  },
});
