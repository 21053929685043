import { License } from '@/models/license';

export const phoneUtils = {
  getPhoneAreaCodeByLicense(license: License): string {
    const defaultPhoneAreaCode = '+39';

    if (license.beach) {
      switch (license.beach.country) {
        case 'fr':
          return '+33';

        default:
          return defaultPhoneAreaCode;
      }
    }

    return defaultPhoneAreaCode;
  },
};
