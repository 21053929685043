import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05ee24a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "reservation-order-summary"
}
const _hoisted_2 = { class: "providers no-print" }
const _hoisted_3 = { class: "label" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "content" }
const _hoisted_6 = {
  key: 0,
  class: "dialog-field"
}
const _hoisted_7 = { for: "send-link-checkbox" }
const _hoisted_8 = {
  key: 1,
  class: "payment-link"
}
const _hoisted_9 = { class: "info" }
const _hoisted_10 = { class: "info" }
const _hoisted_11 = { class: "info" }
const _hoisted_12 = { class: "info" }
const _hoisted_13 = { class: "dialog-field" }
const _hoisted_14 = { for: "are-fees-customer-fees" }
const _hoisted_15 = { class: "info total" }
const _hoisted_16 = { class: "info" }
const _hoisted_17 = { class: "dialog-field" }
const _hoisted_18 = { for: "link-without-expiration-checkbox" }
const _hoisted_19 = {
  key: 0,
  class: "link-with-expiration"
}
const _hoisted_20 = { class: "expiration-fields" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_CalendarInput = _resolveComponent("CalendarInput")!
  const _component_InputMask = _resolveComponent("InputMask")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (!_ctx.isJointAccountPart && _ctx.hasLinkPermission())
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('reservationOrderSummary.sendOrderSummaryBy')) + ":", 1),
          _createElementVNode("a", {
            id: "whatsapp-link",
            ref: "whatsappLink",
            "data-action": "share/whatsapp/share",
            href: _ctx.whatsappLink,
            target: "_blank"
          }, null, 8, _hoisted_4),
          _createVNode(_component_Button, {
            label: _ctx.$t('reservationOrderSummary.provider.whatsapp.name'),
            icon: "spi-whatsapp",
            class: "p-button-plain p-button-outlined whatsapp provider",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open(_ctx.providerType.WHATSAPP)))
          }, null, 8, ["label"]),
          _createVNode(_component_Button, {
            label: _ctx.$t('reservationOrderSummary.provider.email.name'),
            icon: "pi pi-envelope",
            class: _normalizeClass([
          'p-button-plain',
          'p-button-outlined',
          'email',
          'provider',
          _ctx.emailSent ? 'sent' : '',
        ]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open(_ctx.providerType.EMAIL)))
          }, null, 8, ["label", "class"]),
          (_ctx.provider)
            ? (_openBlock(), _createBlock(_component_Dialog, {
                key: 0,
                class: "dialog",
                modal: true,
                visible: _ctx.show,
                "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.show) = $event)),
                onHide: _cache[10] || (_cache[10] = ($event: any) => (_ctx.show = false)),
                style: {"background":"white"}
              }, {
                header: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.provider.title), 1)
                ]),
                footer: _withCtx(() => [
                  _createVNode(_component_Button, {
                    class: "p-button-outlined",
                    label: _ctx.$t('button.close'),
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.show = false))
                  }, null, 8, ["label"]),
                  _createVNode(_component_Button, {
                    label: _ctx.$t('button.send'),
                    icon: "pi pi-chevron-right",
                    iconPos: "right",
                    onClick: _ctx.onSend
                  }, null, 8, ["label", "onClick"])
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    (!_ctx.isJointAccount)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createVNode(_component_InputSwitch, {
                            id: "send-link-checkbox",
                            modelValue: _ctx.sendPaymentLink,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.sendPaymentLink) = $event))
                          }, null, 8, ["modelValue"]),
                          _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('reservationOrderSummary.sendOnlineLink')), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (_ctx.sendPaymentLink)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('reservationOrderSummary.reservationPrice')) + ":", 1),
                            _createElementVNode("span", null, _toDisplayString(_ctx.numberFormatter.format(_ctx.totalPreview)) + " € ", 1)
                          ]),
                          _createElementVNode("div", _hoisted_10, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('reservationOrderSummary.remainingAmount')) + ":", 1),
                            _createElementVNode("span", null, _toDisplayString(_ctx.numberFormatter.format(_ctx.totalToPayPreview)) + " € ", 1)
                          ]),
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('reservationOrderSummary.requestedAmount')) + ":", 1),
                            _createElementVNode("span", null, [
                              _createVNode(_component_InputNumber, {
                                inputClass: "custom-field",
                                modelValue: _ctx.requestedAmount,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.requestedAmount) = $event)),
                                mode: "decimal",
                                step: 0.01,
                                min: 0.5,
                                max: _ctx.totalToPayPreview,
                                maxFractionDigits: 2
                              }, null, 8, ["modelValue", "step", "min", "max"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_12, [
                            _createElementVNode("div", _hoisted_13, [
                              _createVNode(_component_Checkbox, {
                                id: "are-fees-customer-fees",
                                modelValue: _ctx.areFeesOnCustomer,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.areFeesOnCustomer) = $event)),
                                binary: true
                              }, null, 8, ["modelValue"]),
                              _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('reservationOrderSummary.customerFees')), 1)
                            ]),
                            _createElementVNode("span", null, _toDisplayString(_ctx.numberFormatter.format(_ctx.feesAmount)) + " €", 1)
                          ]),
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t('reservationOrderSummary.reservationTotal')) + ":", 1),
                            _createElementVNode("span", null, _toDisplayString(_ctx.numberFormatter.format(_ctx.dialogTotal)) + " €", 1)
                          ]),
                          _createElementVNode("div", _hoisted_16, [
                            _createElementVNode("div", _hoisted_17, [
                              _createVNode(_component_InputSwitch, {
                                id: "link-without-expiration-checkbox",
                                modelValue: _ctx.linkWithExpiration,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.linkWithExpiration) = $event))
                              }, null, 8, ["modelValue"]),
                              _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('reservationOrderSummary.linkWithExpirationSwitch')), 1)
                            ])
                          ]),
                          (_ctx.linkWithExpiration)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                                _createElementVNode("span", null, _toDisplayString(_ctx.$t('reservationOrderSummary.linkWithExpiration')), 1),
                                _createElementVNode("div", _hoisted_20, [
                                  _createVNode(_component_CalendarInput, {
                                    modelValue: _ctx.date,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.date) = $event)),
                                    format: "dd/MM/yy",
                                    minDate: _ctx.minDate
                                  }, null, 8, ["modelValue", "minDate"]),
                                  _createVNode(_component_InputMask, {
                                    class: "custom-field",
                                    modelValue: _ctx.time,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.time) = $event)),
                                    slotChar: "_",
                                    mask: "99:99",
                                    placeholder: "00:00",
                                    onChange: _ctx.onTimeChange
                                  }, null, 8, ["modelValue", "onChange"])
                                ])
                              ]))
                            : _createCommentVNode("", true)
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["visible"]))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}