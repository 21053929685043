import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, _mergeProps(_ctx.attrs, {
    ref: _ctx.id,
    class: ["processing-button", { processing: _ctx.isProcessing }],
    label: _ctx.label,
    disabled: _ctx.disabled,
    onClick: _ctx.onHandler,
    loading: _ctx.isProcessing
  }), null, 16, ["class", "label", "disabled", "onClick", "loading"]))
}