/* eslint-disable import/no-dynamic-require */
/**
 * Store models
 */
import { DateTime } from 'luxon';
import { PanzoomObject } from '@panzoom/panzoom';
import cloneDeep from 'lodash/cloneDeep';
import { _RouteLocationBase } from 'vue-router';
import { Locale } from '@/models/locale';
import { User } from '@/models/user';
import {
  MapEditorElementRaw,
  MapEditorForm,
  MapEditorGridHighlight,
  MapEditorSidebarStep,
  MapEditorSpot,
  MAP_CELL_HEIGHT,
  MAP_CELL_WIDTH,
  MAP_EDITOR_DEFAULT_FORM,
  MAP_EDITOR_DEFAULT_GRID_HIGHLIGHT,
} from '@/models/mapEditor';
import { Links } from './links';
import { License } from './license';
import { Voucher } from './voucher';
import { Printer } from './printer';
import { Service } from './service';
import {
  MapCalendar,
  MapData,
  MapDecoration,
  MapElement,
  MapMode,
  MapPlanner,
  MapRefundConfirmation,
  MapShift,
  MapShiftMode,
  MapShiftStep,
  MapView,
} from './map';
import {
  ReservationBookingTemporary,
  DEFAULT_RESERVATION_TOTALS,
  ReminderLog,
  Reservation,
  ReservationExpense,
  ReservationHalfDay,
  ReservationList,
  ReservationService,
  ReservationSpot,
  ReservationStatus,
  ReservationTotals,
  ReservationType,
  ReservationWeatherPolicy,
  ReservationNoShowPolicy,
  ReservationFiscalPrint,
  OrderSummaryProviderType,
} from './reservation';
import { Customer, DEFAULT_CUSTOMER, Guest } from './customer';
import { CashFlow } from './cashFlow';
import {
  AppAction,
  AppProgressDialog,
  AppRequestStatus,
  AppSpotDialog,
} from './app';
import {
  PlannerData,
  PlannerMode,
  PlannerBuilder,
  // PlannerBuilderReservation,
  PlannerElementReservation,
} from './planner';
import { SmartSearchModel, SmartSearchSpot } from './smartSearch';
import { StyleSpot, StyleSpotAttributeVariable } from '@/models/style';
import { ColorName, colorSpecs } from './color';
import { Breadcrumb, Breakpoints, CalendarRange } from '@/models';
import { PriceList } from './priceList';
import { Card } from './card';
import { SpotType } from './spot';
import { ApiReservationUpdatePayload } from './api';
import cookieUtil from '@/utils/cookieUtil';
import { DiscountCode } from './discountCode';
import { PriceConfig } from './priceConfig';
import type { StorePermission } from '@/models/permissions';
import { PosTerminal } from './posTerminal';
import { WebticTerminal, WebticTicket } from './webtic';
import { Sector, SectorHeader } from './sector';
import { ServiceGroup } from '@/models/serviceGroup/serviceGroup';
import { ServiceInformation } from '@/models/service/serviceInformation';

/* App */

export interface DeviceSpecs {
  ua: string;
  browser: string;
  os: string;
  mobile: string | boolean;
  tablet: boolean;
  touch: boolean;
}

export interface AppState {
  navigation: boolean;
  loading: boolean; // flag for page/content loading
  processing: string | null; // flag for waiting an output/api result
  focus: string; // element with focus
  action: AppAction;
  returnToRoute: string | null;
  reservationEntryPoint: string | null;
  windowWidth: number;
  windowHeight: number;
  deviceSpecs: DeviceSpecs | null;
  route: _RouteLocationBase | null;
  breakpoints: Breakpoints;
  spotDialog: AppSpotDialog;
  requestStatus: AppRequestStatus;
  breadcrumbs: Array<Breadcrumb>;
  httpRequests: Array<string>;
  progressDialog: AppProgressDialog | null;
}

export const DEFAULT_APP_STATE: AppState = {
  navigation: false,
  loading: false,
  processing: null,
  focus: '',
  action: AppAction.NONE,
  returnToRoute: null,
  reservationEntryPoint: null,
  windowWidth: 0,
  windowHeight: 0,
  deviceSpecs: null,
  route: null,
  breakpoints: {
    mobile: false,
    desktop: false,
    xs: false,
    sm: false,
    md: false,
    lg: false,
    smUp: false,
  },
  spotDialog: {
    spotName: '',
    spotType: SpotType.UMBRELLA,
    callback: () => {
      //
    },
  } as AppSpotDialog,
  requestStatus: AppRequestStatus.NONE,
  breadcrumbs: [],
  httpRequests: [],
  progressDialog: null,
};

/* Reservation */

export interface ReservationState {
  id: number;
  spot: ReservationSpot;
  list: Array<ReservationList>;
  master: Reservation | null;
  /* configuration */
  temporary: boolean;
  startDate: DateTime;
  endDate: DateTime;
  isCheckInAvailable: boolean;
  isCheckOutAvailable: boolean;
  halfDay: ReservationHalfDay;
  status: ReservationStatus;
  seasonal: boolean;
  maxiBeds: number;
  beds: number;
  deckChairs: number;
  chairs: number;
  displacements: Array<Reservation>;
  absences: Array<Reservation>;
  /* customer */
  customer: Customer;
  guests: Array<Guest>;
  hotel: string | null;
  hotelRoom: string | null;
  travelGroup: string;
  /* additional services default */
  cabins: Array<Reservation>;
  additions: Array<Reservation>;
  parkings: Array<Reservation>;
  /* additional services custom */
  services: Array<ReservationService>;
  serviceAvailability: ServiceInformation;
  expenses: Array<ReservationExpense>;
  selectedExpensesStored: Array<ReservationExpense>;
  depositTransferStored?: number | null;
  /* misc */
  canExit: boolean;
  edit: boolean;
  type: ReservationType;
  joints: Array<Reservation>;
  propagate: Array<Reservation>;
  voucherId: number;
  overbooking: boolean;
  deleted: boolean;
  discountCode: DiscountCode | null;
  fiscalPrint: ReservationFiscalPrint | null;
  isFiscalPrinting: boolean;
  /* totals */
  paid: boolean;
  totals: ReservationTotals;
  listTotal: Reservation['listTotal'];
  /* cash flows */
  cashFlows: Array<CashFlow>;
  notes: string;
  vehiclePlate: string;
  online: boolean;
  reminderLogs: Array<ReminderLog>;
  token: string;
  qrCode: string;
  sector: SectorHeader;
  cards: Array<Card>;
  stepId: number | null;
  // reservation from booking and is temporary
  bookingTemp: ReservationBookingTemporary | null;
  weatherPolicy: ReservationWeatherPolicy | null;
  noShowPolicy: ReservationNoShowPolicy | null;
  tags: Array<number>;
  webticTickets: Array<WebticTicket>;
  orderSummary: {
    provider: OrderSummaryProviderType;
    sendPaymentLink: boolean;
  } | null;
}

export const DEFAULT_RESERVATION_STATE: ReservationState = {
  id: 0,
  spot: {
    type: SpotType.UMBRELLA,
    name: '',
  },
  list: [],
  master: null,
  temporary: false,
  startDate: DateTime.now(),
  endDate: DateTime.now(),
  halfDay: ReservationHalfDay.DAILY,
  status: ReservationStatus.AVAILABLE,
  seasonal: false,
  maxiBeds: 0,
  beds: 2,
  deckChairs: 0,
  chairs: 0,
  displacements: [],
  absences: [],
  /* customer */
  customer: cloneDeep(DEFAULT_CUSTOMER),
  guests: [],
  hotel: '',
  hotelRoom: '',
  travelGroup: '',
  /* additional services default */
  cabins: [],
  additions: [],
  parkings: [],
  /* additional services custom */
  services: [],
  serviceAvailability: { extraServiceLimits: [], serviceGroupLimits: [] },
  expenses: [],
  selectedExpensesStored: [],
  depositTransferStored: null,
  /* misc */
  canExit: false,
  edit: true,
  type: ReservationType.STANDARD,
  joints: [],
  propagate: [], // (multiple selection) store other reservations to propagate on patch
  voucherId: 0,
  overbooking: false,
  deleted: false,
  discountCode: null,
  fiscalPrint: null,
  isFiscalPrinting: false,
  /* totals */
  paid: false,
  totals: cloneDeep(DEFAULT_RESERVATION_TOTALS),
  listTotal: null,
  /* cash flows */
  cashFlows: [],
  notes: '',
  vehiclePlate: '',
  online: false,
  reminderLogs: [],
  token: '',
  qrCode: '',
  sector: {
    id: 0,
    name: '',
    description: '',
    enabled: true,
    image: '',
  },
  cards: [],
  stepId: null,
  // booking temp is default null
  bookingTemp: null,
  weatherPolicy: null,
  noShowPolicy: null,
  isCheckInAvailable: false,
  isCheckOutAvailable: false,
  tags: [],
  webticTickets: [],
  orderSummary: null,
};

/* Session */

export interface SessionState {
  setup: boolean;
  links: Links | null;
  user: User | null;
  permissions: StorePermission | null;
  roleUser: string | null;
  maxSector: number | null;
  locale: Locale | null;
  darkMode: boolean;
  license: License | null;
  vouchers: Array<Voucher>;
  printers: Array<Printer>;
  posTerminals: Array<PosTerminal>;
  webticTerminals: Array<WebticTerminal>;
  services: Array<Service>;
  serviceGroups: Array<ServiceGroup>;
  serviceAvailabilities: ServiceInformation;
  sectors: Array<Sector>;
  chatBotOpen: boolean;
  documentTemplates: Array<any>;
  appPlatform: string | undefined;
  appPlatformBuild: number | undefined;
}

export const DEFAULT_SESSION_STATE: SessionState = {
  setup: false,
  links: null,
  user: null,
  permission: null,
  roleUser: null,
  locale: null,
  darkMode: false,
  license: null,
  vouchers: [] as Array<Voucher>,
  printers: [] as Array<Printer>,
  posTerminals: [] as Array<PosTerminal>,
  webticTerminals: [] as Array<WebticTerminal>,
  services: [] as Array<Service>,
  serviceGroups: [] as Array<ServiceGroup>,
  serviceAvailabilities: {} as ServiceInformation,
  sectors: [] as Array<Sector>,
  chatBotOpen: false,
  documentTemplates: [] as any,
  appPlatform: cookieUtil.get('anm22_world_app_os'),
  appPlatformBuild: cookieUtil.get('anm22_world_app_build')
    ? Number(cookieUtil.get('anm22_world_app_build'))
    : undefined,
  permissions: null,
  maxSector: null,
} as SessionState;

/* Map */

export interface MapState {
  instance: PanzoomObject | null;
  panning: boolean;
  scale: number;
  mode: MapMode;
  view: MapView;
  elements: Array<MapElement>;
  spots: Array<MapElement>;
  planner: MapPlanner;
  calendar: MapCalendar;
  selected: Array<MapElement>;
  shift: MapShift;
  data: MapData | null;
  reservationPayload: ApiReservationUpdatePayload;
  refundConfirmation: MapRefundConfirmation | null;
  ticketAvailabilities: ServiceInformation;
  activeOverlay: boolean;
  ticketDetailOpen: boolean;
}

export const DEFAULT_MAP_STATE: MapState = {
  instance: null,
  panning: false,
  scale: 0,
  mode: MapMode.NONE,
  view: MapView.FULL,
  elements: [],
  spots: [],
  planner: {
    active: false,
    data: null,
    spot: null,
  } as MapPlanner,
  calendar: [DateTime.now().startOf('day'), DateTime.now().startOf('day')],
  selected: [] as Array<MapElement>,
  shift: {
    step: MapShiftStep.SELECT_FROM,
    from: null,
    mode: MapShiftMode.TODAY,
    range: [DateTime.now().startOf('day'), DateTime.now().startOf('day')],
    to: null,
  },
  data: null,
  reservationPayload: {} as ApiReservationUpdatePayload,
  refundConfirmation: null,
  ticketAvailabilities: {} as ServiceInformation,
  activeOverlay: false,
  ticketDetailOpen: false,
};

/* Map Editor */
export interface MapEditorState {
  cellWidth: number;
  cellHeight: number;
  elements: Array<MapEditorElementRaw>;
  spots: Array<MapEditorSpot>;
  decorations: Array<MapDecoration>;
  sidebarStep: MapEditorSidebarStep;
  form: MapEditorForm;
  priceLists: Array<PriceList>;
  sectorOptions: Array<Sector>;
  gridHighlight: MapEditorGridHighlight;
}

export const DEFAULT_MAP_EDITOR_STATE: MapEditorState = {
  cellWidth: MAP_CELL_WIDTH,
  cellHeight: MAP_CELL_HEIGHT,
  elements: [],
  spots: [],
  decorations: [],
  sidebarStep: MapEditorSidebarStep.HOME,
  form: cloneDeep(MAP_EDITOR_DEFAULT_FORM),
  priceLists: [],
  sectorOptions: [],
  gridHighlight: cloneDeep(MAP_EDITOR_DEFAULT_GRID_HIGHLIGHT),
};

/* Planner */

export interface PlannerState {
  data: PlannerData | null;
  zoom: number;
  year: string;
  mode: PlannerMode;
  builder: PlannerBuilder;
  visibleSectorIds: number[];
  sectorIds: number[];
  isLoading: boolean;
  now: DateTime;
  scrollToToday: boolean;
}

export const DEFAULT_PLANNER_STATE: PlannerState = {
  data: null,
  zoom: 100,
  year: DateTime.now().toFormat('yyyy'),
  now: DateTime.now(),
  mode: PlannerMode.VIEW,
  builder: {
    busyCells: new Set<string>(),
    edited: false,
    spotCategory: null,
    spotKeys: new Set<string>(),
    originals: new Map<number, PlannerElementReservation>(),
    movingId: null,
    reservationId: null,
    reservations: new Array<PlannerElementReservation>(),
    editDirection: null,
  },
  visibleSectorIds: [],
  sectorIds: [],
  isLoading: false,
  scrollToToday: false,
};

/* Smart Search */

export interface SmartSearchState {
  active: boolean;
  model: SmartSearchModel | null;
  spots: Array<SmartSearchSpot>;
}

export const DEFAULT_SMART_SEARCH_STATE: SmartSearchState = {
  active: false,
  model: null,
  spots: [],
};

/* Price lists */

export interface PriceListsState {
  priceLists: Array<PriceList>;
  priceConfigs: Array<PriceConfig>;
  vouchers: Array<Voucher>;
  showVoucherCreateDialog: boolean;
  showPriceListCreateDialog: boolean;
  showSeasonalSettingsDialog: boolean;
}

export const DEFAULT_PRICE_LISTS_STATE: PriceListsState = {
  priceLists: [],
  priceConfigs: [],
  vouchers: [],
  showVoucherCreateDialog: false,
  showPriceListCreateDialog: false,
  showSeasonalSettingsDialog: false,
};

/* Style */

export interface StyleState {
  spot: StyleSpot;
}

export const DEFAULT_STYLE_STATE: StyleState = {
  spot: {
    free: {
      label: 'spotStatus.free',
      variable: StyleSpotAttributeVariable.MAP_COLOR_FREE,
      color: ColorName.GREEN,
      icons: {
        umbrella: require(`@/assets/images/map/umbrella/${ColorName.GREEN.toLowerCase()}.svg`),
        bed: require(`@/assets/images/map/bed/${ColorName.GREEN.toLowerCase()}.svg`),
        cabins: require(`@/assets/images/map/cabins/${ColorName.GREEN.toLowerCase()}.svg`),
        parking: require(`@/assets/images/map/parking/${ColorName.GREEN.toLowerCase()}.svg`),
        gazebo: require(`@/assets/images/map/gazebo/${ColorName.GREEN.toLowerCase()}.svg`),
        beds: require(`@/assets/images/map/beds/${ColorName.GREEN.toLowerCase()}.svg`),
      },
      css: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#878787',
        backgroundColor: '#FFFFFF',
      },
    },
    busy: {
      label: 'spotStatus.reserved',
      variable: StyleSpotAttributeVariable.MAP_COLOR_RESERVED,
      color: ColorName.RED,
      icons: {
        umbrella: require(`@/assets/images/map/umbrella/${ColorName.RED.toLowerCase()}.svg`),
        bed: require(`@/assets/images/map/bed/${ColorName.RED.toLowerCase()}.svg`),
        cabins: require(`@/assets/images/map/cabins/${ColorName.RED.toLowerCase()}.svg`),
        parking: require(`@/assets/images/map/parking/${ColorName.RED.toLowerCase()}.svg`),
        gazebo: require(`@/assets/images/map/gazebo/${ColorName.RED.toLowerCase()}.svg`),
        beds: require(`@/assets/images/map/beds/${ColorName.RED.toLowerCase()}.svg`),
      },
      css: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#878787',
        backgroundColor: '#FFFFFF',
      },
    },
    busyFirstDay: {
      label: 'spotStatus.reservedFirstDay',
      variable: StyleSpotAttributeVariable.MAP_COLOR_RESERVED_FIRST_DAY,
      color: ColorName.RED,
      icons: {
        umbrella: require(`@/assets/images/map/umbrella/${ColorName.RED.toLowerCase()}.svg`),
        bed: require(`@/assets/images/map/bed/${ColorName.RED.toLowerCase()}.svg`),
        cabins: require(`@/assets/images/map/cabins/${ColorName.RED.toLowerCase()}.svg`),
        parking: require(`@/assets/images/map/parking/${ColorName.RED.toLowerCase()}.svg`),
        gazebo: require(`@/assets/images/map/gazebo/${ColorName.RED.toLowerCase()}.svg`),
        beds: require(`@/assets/images/map/beds/${ColorName.RED.toLowerCase()}.svg`),
      },
      css: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#878787',
        backgroundColor: '#FFFFFF',
      },
    },
    busyLastDay: {
      label: 'spotStatus.reservedLastDay',
      variable: StyleSpotAttributeVariable.MAP_COLOR_RESERVED_LAST_DAY,
      color: ColorName.RED,
      icons: {
        umbrella: require(`@/assets/images/map/umbrella/${ColorName.RED.toLowerCase()}.svg`),
        bed: require(`@/assets/images/map/bed/${ColorName.RED.toLowerCase()}.svg`),
        cabins: require(`@/assets/images/map/cabins/${ColorName.RED.toLowerCase()}.svg`),
        parking: require(`@/assets/images/map/parking/${ColorName.RED.toLowerCase()}.svg`),
        gazebo: require(`@/assets/images/map/gazebo/${ColorName.RED.toLowerCase()}.svg`),
        beds: require(`@/assets/images/map/beds/${ColorName.RED.toLowerCase()}.svg`),
      },
      css: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#878787',
        backgroundColor: '#FFFFFF',
      },
    },
    halfDayMorning: {
      label: 'spotStatus.halfDayMorning',
      variable: StyleSpotAttributeVariable.MAP_COLOR_HALF_DAY_MORNING,
      color: ColorName.PINK,
      icons: {
        umbrella: require(`@/assets/images/map/umbrella/${ColorName.PINK.toLowerCase()}.svg`),
        bed: require(`@/assets/images/map/bed/${ColorName.PINK.toLowerCase()}.svg`),
        cabins: require(`@/assets/images/map/cabins/${ColorName.PINK.toLowerCase()}.svg`),
        parking: require(`@/assets/images/map/parking/${ColorName.PINK.toLowerCase()}.svg`),
        gazebo: require(`@/assets/images/map/gazebo/${ColorName.PINK.toLowerCase()}.svg`),
        beds: require(`@/assets/images/map/beds/${ColorName.PINK.toLowerCase()}.svg`),
      },
      css: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#878787',
        backgroundColor: '#FFFFFF',
      },
    },
    halfDayAfternoon: {
      label: 'spotStatus.halfDayAfternoon',
      variable: StyleSpotAttributeVariable.MAP_COLOR_HALF_DAY_AFTERNOON,
      color: ColorName.ORANGE,
      icons: {
        umbrella: require(`@/assets/images/map/umbrella/${ColorName.ORANGE.toLowerCase()}.svg`),
        bed: require(`@/assets/images/map/bed/${ColorName.ORANGE.toLowerCase()}.svg`),
        cabins: require(`@/assets/images/map/cabins/${ColorName.ORANGE.toLowerCase()}.svg`),
        parking: require(`@/assets/images/map/parking/${ColorName.ORANGE.toLowerCase()}.svg`),
        gazebo: require(`@/assets/images/map/gazebo/${ColorName.ORANGE.toLowerCase()}.svg`),
        beds: require(`@/assets/images/map/beds/${ColorName.ORANGE.toLowerCase()}.svg`),
      },
      css: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#878787',
        backgroundColor: '#FFFFFF',
      },
    },
    fullDay: {
      label: 'spotStatus.fullDay',
      variable: StyleSpotAttributeVariable.MAP_COLOR_FULL_DAY,
      color: ColorName.RED,
      icons: {
        umbrella: require(`@/assets/images/map/umbrella/${ColorName.RED.toLowerCase()}.svg`),
        bed: require(`@/assets/images/map/bed/${ColorName.RED.toLowerCase()}.svg`),
        cabins: require(`@/assets/images/map/cabins/${ColorName.RED.toLowerCase()}.svg`),
        parking: require(`@/assets/images/map/parking/${ColorName.RED.toLowerCase()}.svg`),
        gazebo: require(`@/assets/images/map/gazebo/${ColorName.RED.toLowerCase()}.svg`),
        beds: require(`@/assets/images/map/beds/${ColorName.RED.toLowerCase()}.svg`),
      },
      css: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#878787',
        backgroundColor: '#FFFFFF',
      },
    },
    temporary: {
      label: 'spotStatus.temporary',
      variable: StyleSpotAttributeVariable.MAP_COLOR_TEMPORARY,
      color: ColorName.BLUE,
      icons: {
        umbrella: require(`@/assets/images/map/umbrella/${ColorName.BLUE.toLowerCase()}.svg`),
        bed: require(`@/assets/images/map/bed/${ColorName.BLUE.toLowerCase()}.svg`),
        cabins: require(`@/assets/images/map/cabins/${ColorName.BLUE.toLowerCase()}.svg`),
        parking: require(`@/assets/images/map/parking/${ColorName.BLUE.toLowerCase()}.svg`),
        gazebo: require(`@/assets/images/map/gazebo/${ColorName.BLUE.toLowerCase()}.svg`),
        beds: require(`@/assets/images/map/beds/${ColorName.BLUE.toLowerCase()}.svg`),
      },
      css: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#878787',
        backgroundColor: '#FFFFFF',
      },
    },
    seasonal: {
      label: 'spotStatus.seasonal',
      variable: StyleSpotAttributeVariable.MAP_COLOR_SEASONAL,
      color: ColorName.PURPLE,
      icons: {
        umbrella: require(`@/assets/images/map/umbrella/${ColorName.PURPLE.toLowerCase()}.svg`),
        bed: require(`@/assets/images/map/bed/${ColorName.PURPLE.toLowerCase()}.svg`),
        cabins: require(`@/assets/images/map/cabins/${ColorName.PURPLE.toLowerCase()}.svg`),
        parking: require(`@/assets/images/map/parking/${ColorName.PURPLE.toLowerCase()}.svg`),
        gazebo: require(`@/assets/images/map/gazebo/${ColorName.PURPLE.toLowerCase()}.svg`),
        beds: require(`@/assets/images/map/beds/${ColorName.PURPLE.toLowerCase()}.svg`),
      },
      css: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#878787',
        backgroundColor: '#FFFFFF',
      },
    },
    // TODO: Make dynamic
    overbooking: {
      label: 'spotStatus.overbooking',
      css: {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: colorSpecs.get(ColorName.RED)?.rgb ?? '#FF00000',
        backgroundColor: colorSpecs.get(ColorName.RED)?.background ?? '#FFE3E3',
      },
    },
    // TODO: Make dynamic
    sharing: {
      label: 'spotStatus.sharing',
      css: {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: colorSpecs.get(ColorName.BLUE)?.rgb ?? '#00B0FF',
        backgroundColor: '#FFFFFF',
      },
    },
    unpaid: {
      label: 'spotStatus.unpaid',
      variable: StyleSpotAttributeVariable.MAP_COLOR_NOT_PAID,
      attributeIcon: require('@/assets/iconset/euro-circle-small.svg'),
      css: {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: colorSpecs.get(ColorName.YELLOW)?.rgb ?? '#FFE133',
        backgroundColor: '#FFFFFF',
      },
    },
    unpaidLast: {
      label: 'spotStatus.unpaidLast',
      variable: StyleSpotAttributeVariable.MAP_COLOR_NOT_PAID,
      attributeIcon: require('@/assets/iconset/euro-circle-small.svg'),
      css: {
        borderWidth: '2px',
        borderStyle: 'solid',
        borderColor: colorSpecs.get(ColorName.YELLOW)?.rgb ?? '#FFE133',
        backgroundColor:
          colorSpecs.get(ColorName.YELLOW)?.background ?? '#FFF9D4',
      },
    },
  },
};

/* Statistic */

export interface StatisticState {
  title: string;
  subtitle: string;
  calendar: CalendarRange;
  backRoute: string | null;
  groupHotelData: boolean;
  groupVoucherData: boolean;
  printFn: (() => void) | null;
  exportFn: (() => void) | null;
}

export const DEFAULT_STATISTIC_STATE: StatisticState = {
  title: '',
  subtitle: '',
  calendar: [DateTime.now().startOf('day'), DateTime.now().startOf('day')],
  backRoute: null,
  groupHotelData: false,
  groupVoucherData: false,
  printFn: null,
  exportFn: null,
};

/* Root */

export interface RootState {
  app: AppState;
  session: SessionState;
  map: MapState;
  planner: PlannerState;
  reservation: ReservationState;
  smartSearch: SmartSearchState;
  style: StyleState;
  statistic: StatisticState;
  priceLists: PriceListsState;
}
