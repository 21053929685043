/**
 * Store with relative modules
 */

import { createStore } from 'vuex';
import app from './modules/appModule';
import map from './modules/mapModule';
import mapEditor from './modules/mapEditorModule';
import planner from './modules/plannerModule';
import smartSearch from './modules/smartSearchModule';
import reservation from './modules/reservationModule';
import session from './modules/sessionModule';
import style from './modules/styleModule';
import statistic from './modules/statisticModule';
import priceLists from './modules/priceListsModule';

export default createStore({
  modules: {
    app,
    map,
    mapEditor,
    planner,
    reservation,
    smartSearch,
    session,
    style,
    statistic,
    priceLists,
  },
});
