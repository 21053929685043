import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsDataTable = _resolveComponent("StatisticsDataTable")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_StatisticsDataTable, {
      id: _ctx.id,
      columns: _ctx.columns,
      searchColumns: _ctx.searchColumns,
      defaultView: _ctx.defaultView,
      endpoint: _ctx.endpoint,
      basePayload: _ctx.basePayload,
      rowClass: _ctx.rowClass,
      onRowClick: _ctx.openReservation,
      tooltipText: _ctx.tooltipText,
      exportEndpoint: _ctx.exportEndpoint,
      printTitle: _ctx.service.name
    }, null, 8, ["id", "columns", "searchColumns", "defaultView", "endpoint", "basePayload", "rowClass", "onRowClick", "tooltipText", "exportEndpoint", "printTitle"])
  ]))
}