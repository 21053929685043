import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-743f75ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-row flex-nowrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      style: _normalizeStyle(`width:${_ctx.buttonWidth}px`),
      class: _normalizeClass(['btn on', _ctx.modelValue ? 'selected' : '']),
      label: _ctx.trueLabel,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClick(true)))
    }, null, 8, ["style", "class", "label"]),
    _createVNode(_component_Button, {
      style: _normalizeStyle(`width:${_ctx.buttonWidth}px`),
      class: _normalizeClass(['btn off', !_ctx.modelValue ? 'selected' : '']),
      label: _ctx.falseLabel,
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClick(false)))
    }, null, 8, ["style", "class", "label"])
  ]))
}