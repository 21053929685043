/**
 * Customer service
 */

import axios, { AxiosResponse } from 'axios';
import {
  ApiContactHintPayload,
  ApiContactHintResponse,
  ApiResponse,
} from '@/models/api';
import apiService from '@/services/apiService';
import { Contact, Customer } from '@/models/customer';

export default {
  async hint(payload: ApiContactHintPayload): Promise<Array<Contact>> {
    const res: AxiosResponse<ApiContactHintResponse> = await axios.post(
      apiService.contact.hint(),
      payload,
    );
    return res.data.result.hints;
  },
  async create(customer: Customer): Promise<ApiResponse> {
    return axios.post(apiService.customer.create(), customer);
  },
};
