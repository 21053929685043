
import { defineComponent } from 'vue';
import { cloneDeep } from 'lodash';
import RevenueManagementPeriodPanel from '@/components/revenue-management/RevenueManagementPeriodPanel.vue';
import {
  RevenueManagementBaseRule,
  RevenueManagementPeriod,
} from '@/models/revenueManagement';
import revenueManagementService from '@/services/revenueManagementService';
import RevenueManagementHistoricalDetails from '@/components/revenue-management/RevenueManagementHistoricalDetails.vue';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';
import RevenueManagerOverrideRulePanel from '@/components/revenue-management/OverrideRules/RevenueManagerOverrideRulePanel.vue';

export default defineComponent({
  name: 'RevenueManagementAnalystView',
  components: {
    RevenueManagementHistoricalDetails,
    RevenueManagementPeriodPanel,
    RevenueManagerOverrideRulePanel,
  },
  data() {
    return {
      periods: [] as Array<RevenueManagementPeriod>,
      baseRules: [] as Array<RevenueManagementBaseRule>,
      periodsKey: 0,
    };
  },
  async beforeMount(): Promise<void> {
    try {
      this.periods = await revenueManagementService.periods();
    } catch (e) {
      this.$spiagge.toast.error(
        this.$t('revenueManagementAnalystView.toast.error'),
      );
    }
  },
  methods: {
    async getData(): Promise<void> {
      try {
        this.baseRules = await revenueManagementService.baseRule.list();
      } catch (error) {
        // console.log(error);
      }
      this.periodsKey += 1;
    },
  },
  computed: {
    FEATURE_PERMISSION_ACTION_CONFIG() {
      return FEATURE_PERMISSION_ACTION_CONFIG;
    },
    FEATURE_PERMISSION_CONFIG() {
      return FEATURE_PERMISSION_CONFIG;
    },
    permissionsUtil() {
      return permissionsUtil;
    },
    periodRules(): Array<RevenueManagementBaseRule> {
      return cloneDeep(this.baseRules);
    },
  },
});
