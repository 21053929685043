
import { PropType, defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import { clone } from 'lodash';
import { mapState } from 'vuex';
import { SectorHeader } from '@/models/sector';
import { AppProgressDialog } from '@/models/app';
import { ApiWebticOnsiteEmitTicketPayload } from '@/models/api';
import webticService from '@/services/webticService';
import { WebticTicket, WebticTerminal } from '@/models/webtic';
import cookieUtil from '@/utils/cookieUtil';

interface WebticTerminalPickerFormModel {
  webticTerminal: WebticTerminal | null;
}

interface WebticTerminalOption {
  label: string;
  terminals: Array<WebticTerminal>;
}

export default defineComponent({
  name: 'WebticTicketEmission',
  components: { Form, Field },
  emit: [
    'picker',
    'before-emission',
    'after-emission',
    'success',
    'failure',
    'exit',
    'close',
  ],
  props: {
    payload: {
      type: Object as PropType<ApiWebticOnsiteEmitTicketPayload>,
      required: true,
    },
    sector: {
      type: Object as PropType<SectorHeader>,
      required: false,
    },
    pollingInterval: {
      type: Number,
      required: false,
      default: 2000,
    },
    pollingIterations: {
      type: Number,
      required: false,
      default: 60,
    },
  },
  data() {
    return {
      showWebticTerminalPickerDialog: false,
      webticTerminalPickerFormModel: {} as WebticTerminalPickerFormModel,
      webticTerminalPickerValidationSchema: yup.object({
        webticTerminal: yup.object().required(),
      }),
      currentPollingIterations: 0,
      poller: 0,
    };
  },
  methods: {
    async getWebticTerminals(): Promise<void> {
      /**
       * Refresh store Webtic terminals
       */
      try {
        await this.$store.dispatch('session/setWebticTerminals');
      } catch (error) {
        this.$spiagge.toast.error(
          this.$t('webticTerminalPayment.toast.retrievalError'),
        );
      }
    },
    async emitTicket(): Promise<void> {
      /**
       * Call method with actions before the emission process
       */
      this.$emit('before-emission');

      // wait for reservation id
      while (!this.payload?.reservation) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((r) => setTimeout(r, 500));
      }

      try {
        const emitResult: Array<WebticTicket> = await webticService.emitTickets(
          {
            ...{
              terminal: (
                this.webticTerminalPickerFormModel
                  .webticTerminal as WebticTerminal
              ).terminalId,
            },
            ...this.payload,
          } as ApiWebticOnsiteEmitTicketPayload,
        );

        this.$store.commit('app/setProgressDialog', null);

        this.$emit('success', null);
        this.$emit('after-emission');
      } catch (error) {
        this.$store.commit('app/setProgressDialog', null);
        this.$spiagge.toast.error(
          (error as any).response.data.error &&
            (error as any).response.data.error !== ''
            ? (error as any).response.data.error
            : this.$t('webticTerminalPayment.toast.operationError'),
        );
      }
    },
    async onOnsiteEmitTicket(): Promise<void> {
      /**
       * Method to check if there is one or more reflector.
       * This method che open showWebticTerminalPickerDialog.
       */

      // if Webtic terminals > 1 open the picker dialog, otherwise go to next step
      if (this.webticTerminalsAvailable.length > 1) {
        this.showWebticTerminalPickerDialog = true;
        this.$emit('picker');
      } else {
        // set progress dialog
        this.$store.commit('app/setProgressDialog', {
          title: this.$t('webticTerminalPayment.progressDialogTitle'),
          content: this.$t('webticTerminalPayment.progressDialogContent'),
          actions: [],
        } as AppProgressDialog);

        this.webticTerminalPickerFormModel.webticTerminal = clone(
          this.webticTerminalsAvailable[0],
        );
        this.emitTicket();
      }
    },
    onWebticTerminalPick(): void {
      /**
       * Pos terminal selected
       */
      this.showWebticTerminalPickerDialog = false;
      this.emitTicket();
    },
    onAbort(): void {
      /**
       * Close picker dialog
       */
      this.reset();
    },
    reset(): void {
      /**
       * Reset component
       */
      this.$emit('close');
      this.$store.commit('app/setProgressDialog', null);
      if (this.poller > 0) {
        clearInterval(this.poller);
      }
    },
  },
  computed: {
    ...mapState('session', [
      'license',
      'webticTerminals',
      'permission',
      'appPlatform',
    ]),
    webticTerminalOptions(): Array<WebticTerminalOption> {
      /**
       * Pos terminal options grouped by model and filtered by disabled flag
       */
      const webticTerminalOptions = [] as Array<WebticTerminalOption>;
      webticTerminalOptions.push({
        label: 'Terminali',
        terminals: this.webticTerminalsAvailable,
      });

      return webticTerminalOptions;
    },
    webticTerminalsAvailable(): Array<WebticTerminal> {
      return this.webticTerminals.filter(
        (webticTerminal: WebticTerminal) =>
          !webticTerminal.disabled &&
          !cookieUtil.get(`spit_siaeterm-hide-${webticTerminal.id}`) &&
          (this.sector && this.sector?.id
            ? webticTerminal.sectors.find(
                (s: SectorHeader) => s.id === this.sector?.id,
              )
            : true),
      );
    },
  },
});
