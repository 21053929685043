import axios, { AxiosResponse } from 'axios';
import { CashDeskPayload } from '@/models/cashdesk';
import apiService from './apiService';
import { Reservation } from '@/models/reservation';
import { ApiCashDeskCreateResponse } from '@/models/api';

export default {
  // TODO horrible, to remake
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async createOne(payload: CashDeskPayload): Promise<Reservation> {
    const res: AxiosResponse<ApiCashDeskCreateResponse> = await axios.post(
      apiService.cashDesk.store(),
      payload,
    );
    return res.data.result.reservation;
  },
};
