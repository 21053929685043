/**
 * Toast models
 */

export enum ToastSeverity {
  SUCCESS = 'success',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

export const TOAST_DURATION = 3000;

export interface Toast {
  severity: ToastSeverity;
  summary: string;
  detail?: string | null;
  closable?: boolean;
  life?: number;
  styleClass?: string;
  group?: string;
}
