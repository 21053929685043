import { RouteLocationRaw } from 'vue-router';

export interface MenuItem {
  label: string;
  icon?: string;
  to?: string;
  url?: string;
  visible?: (() => boolean) | boolean;
  disabled?: (() => boolean) | boolean;
  items?: Array<MenuItem>; // nesting
}

export interface MenuBlockItem {
  id?: string;
  label: string;
  icon?: string;
  action?: () => void;
  items?: Array<MenuBlockItem>;
  classes?: Array<string>;
  to?: RouteLocationRaw;
}

interface MenuBlockHeaderSplitButton {
  label: string;
  items: Array<{ label: string; command: () => void }>;
}

export interface MenuBlockHeader {
  id: string;
  label: string;
  icon?: string;
  to?: RouteLocationRaw;
  splitButton?: MenuBlockHeaderSplitButton;
}

export interface MenuBlock {
  header?: MenuBlockHeader;
  items: Array<MenuBlockItem>;
}

export type Menu = Array<MenuBlock>;
