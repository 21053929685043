
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import {
  PriceList,
  PriceListEstimate,
  PriceListQualityCheck,
  PricePeriod,
  PriceListQualityCheckSectorsShiftPeriod,
  PriceListQualityCheckSectorsShiftPeriodData,
  PriceListQualityCheckSectorsShiftItem,
  PriceListQualityCheckPeriodsDataPeriodSector,
  PriceListQualityCheckSectorsDataSectorPeriod,
  PriceListQualityCheckPeriodsDataPeriod,
  PriceListQualityCheckPeriodsDataPeriodSectorValue,
  PriceListQualityCheckSectorsDataSector,
  PriceListQualityCheckSectorsDataSectorPeriodValue,
} from '@/models/priceList';
import priceListService from '@/services/priceListService';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { SpotType } from '@/models/spot';
import {
  ApiPriceListEstimatePayload,
  ApiPriceListQualityCheckPayload,
} from '@/models/api';
import { DropdownOption } from '@/models';
import {
  LicenseSectorFull,
  LicenseSectorDetailFormatted,
} from '@/models/license';
import { PriceConfig } from '@/models/priceConfig';
import priceConfigService from '@/services/priceConfigService';
import { Sector } from '@/models/sector';

enum PacketsTableView {
  PERIOD,
  SECTOR,
}

interface PacketsTableViewData {
  name: string;
  view: PacketsTableView;
}

interface PriceEstimateFilters {
  interval: any; // TODO fix
  sectors: Array<number>;
  processing: boolean;
}

interface PacketsTableFilters {
  period: number;
  sector: number;
  view: PacketsTableView;
  allDays: boolean;
  processing: boolean;
}

interface SectorsShiftFilters {
  period: number;
  processing: boolean;
}

interface Row {
  [key: string]: string | number | boolean;
}

interface Column {
  header: string;
  field: string;
}

const PACKETS_TABLE_VIEW_DATA: Array<PacketsTableViewData> = [
  {
    // name: this.$t('priceListsQualityCheckView.period'),
    name: 'Periodo',
    view: PacketsTableView.PERIOD,
  },
  {
    // name: this.$t('priceListsQualityCheckView.sector'),
    name: 'Settore',
    view: PacketsTableView.SECTOR,
  },
];

export default defineComponent({
  name: 'PriceListsQualityCheckView',
  components: { Breadcrumbs },
  data() {
    return {
      // misc
      ready: false,
      mounted: false,
      maxSectors: 5,
      model: {
        spot: SpotType.UMBRELLA,
        numBeds: 2,
        numDeckChairs: 0,
        numChairs: 0,
        numMaxiBeds: 0,
        useCabin: false,
      } as ApiPriceListQualityCheckPayload,
      priceList: undefined as unknown as PriceList,
      priceConfig: undefined as unknown as PriceConfig,
      qualityCheck: undefined as unknown as PriceListQualityCheck,
      // configuration
      spotOptions: [
        {
          label: this.$t('priceListsQualityCheckView.spotOption.umbrella'),
          value: SpotType.UMBRELLA,
        },
        {
          label: this.$t('priceListsQualityCheckView.spotOption.bed'),
          value: SpotType.BED,
        },
      ],
      stagingOptions: [...Array(6).keys()].map((stagingOption: number) => ({
        label: stagingOption.toString(),
        value: stagingOption,
      })),
      // price estimate
      priceEstimate: undefined as unknown as Array<PriceListEstimate>,
      priceEstimateFilters: {
        interval: [],
        sectors: [],
        processing: false,
      } as PriceEstimateFilters,
      packetsTableView: cloneDeep(PacketsTableView),
      // packets table
      packetsTableFilters: {
        period: 0,
        sector: 0,
        view: PacketsTableView.PERIOD,
        allDays: false,
        processing: false,
      } as PacketsTableFilters,
      // sectors shift
      sectorsShiftFilters: {
        period: 0,
        processing: false,
      } as SectorsShiftFilters,
      // options
      sectorsOptions: [] as Array<DropdownOption>,
      periodsOptions: [] as Array<DropdownOption>,
      packetsTableViewOptions: [] as Array<DropdownOption>,
    };
  },
  async beforeMount(): Promise<void> {
    /**
     * Init view
     */
    this.$store.commit('app/setLoading', true);
    const priceListId = this.$route.params.id
      ? Number(this.$route.params.id)
      : undefined;
    if (!priceListId) {
      this.$spiagge.toast.error(
        this.$t('priceListsQualityCheckView.toast.error'),
      );
      this.$router.push('/price-lists');
    }
    try {
      // set price list
      this.priceList = await priceListService.get(priceListId as number);

      // set price config
      if (this.priceList.activePriceConfigId) {
        this.priceConfig = await priceConfigService.get(
          this.priceList.activePriceConfigId,
        );
      }

      // sectors options
      this.sectorsOptions = this.sectorsDetails.map(
        (sector: Sector) => ({
          label: sector.header.name,
          value: sector.header.id,
        }),
      );

      // periods options
      this.periodsOptions = this.priceList.periods.map(
        (period: PricePeriod) => ({
          label: period.name ?? this.$t('priceListsQualityCheckView.period'),
          value: period.id,
        }),
      );

      // packets table view options
      this.packetsTableViewOptions = PACKETS_TABLE_VIEW_DATA.map(
        (viewData: PacketsTableViewData) => ({
          label: `${this.$t('priceListsQualityCheckView.viewOption')} ${
            viewData.name
          }`,
          value: viewData.view,
        }),
      );

      // set initial periods/sectors to filters
      const defaultPeriod = this.periodsOptions[0].value as number;
      const defaultSectors = this.sectorsOptions
        .slice(0, 5)
        .map(
          (sectorOption: DropdownOption) => sectorOption.value,
        ) as Array<number>;
      this.priceEstimateFilters.sectors = defaultSectors;
      this.sectorsShiftFilters.period = defaultPeriod;
      this.packetsTableFilters.period = defaultPeriod;
      this.packetsTableFilters.sector = defaultSectors[0] ?? 0;

      // load data
      await this.loadData();
      // set breadcrumbs
      this.$store.commit('app/setBreadcrumbs', [
        {
          label: this.priceList.name,
          to: `/price-lists/${this.priceList.id}`,
          icon: 'spi-arrow-left',
        },
        {
          label: this.$t('priceListsQualityCheckView.qualityCheck'),
        },
      ]);

      this.ready = true;
    } catch (e) {
      this.$spiagge.toast.error(
        this.$t('priceListsQualityCheckView.toast.retrievalError'),
      );
    } finally {
      this.$store.commit('app/setLoading', false);
    }
  },
  mounted() {
    /**
     * Mounted
     */
    this.mounted = true;
  },
  methods: {
    async loadData(): Promise<void> {
      /**
       * Load data
       */
      try {
        // this.$store.commit('app/setLoading', true);

        // if there is price simulation, then refresh it
        if (this.canEstimate) {
          await this.onPriceEstimate();
        }

        this.sectorsShiftFilters.processing = true;
        this.packetsTableFilters.processing = true;
        // quality check data
        this.qualityCheck = await priceListService.qualityCheck(
          this.priceList.id,
          this.model,
        );
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('priceListsQualityCheckView.toast.dataRetrievalError'),
        );
      } finally {
        // this.$store.commit('app/setLoading', false);
        this.sectorsShiftFilters.processing = false;
        this.packetsTableFilters.processing = false;
      }
    },
    async onApply(): Promise<void> {
      /**
       * Apply configuration
       */
      await this.loadData();
    },
    async onPriceEstimate(): Promise<void> {
      /**
       * Get price estimate data
       */
      try {
        if (!this.canEstimate) {
          this.$spiagge.toast.warn(
            this.$t('priceListsQualityCheckView.toast.priceEstimateValidation'),
          );
          return;
        }
        this.priceEstimateFilters.processing = true;
        this.priceEstimate = await priceListService.estimate(
          this.priceList.id,
          {
            ...this.model,
            startDate: this.priceEstimateFilters.interval[0].toSeconds(),
            endDate: this.priceEstimateFilters.interval[1].toSeconds(),
            sectors: this.priceEstimateFilters.sectors,
          } as ApiPriceListEstimatePayload,
        );
        this.$spiagge.toast.success(
          this.$t('priceListsQualityCheckView.toast.priceEstimateSuccess'),
        );
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('priceListsQualityCheckView.toast.priceEstimateError'),
        );
      } finally {
        this.priceEstimateFilters.processing = false;
      }
    },
    onPriceEstimateRangeSelect(): void {
      /**
       * Close range panel
       */
      if (this.priceEstimateFilters.interval[1] !== null) {
        (this.$refs.range as any).overlayVisible = false;
      }
    },
    onPriceEstimateRangeHide(): void {
      /**
       * Fill range end date if missing
       */
      if (this.priceEstimateFilters.interval[1] === null) {
        this.priceEstimateRange = [
          this.priceEstimateRange[0],
          this.priceEstimateRange[0],
        ];
      }
    },
    packetsTableRowClass(row: any): string {
      return row.threshold ? 'threshold' : '';
    },
    getTokenLabel(token: string): string {
      let label = '';
      if (token === 'HD') {
        label = this.$t('priceListsQualityCheckView.halfDay');
      } else if (token === 'DE') {
        label = this.$t('priceListsQualityCheckView.dayExtra');
      } else if (token === 'WE') {
        label = this.$t('priceListsQualityCheckView.weekend');
      } else if (token.charAt(0) === 'G') {
        const days = Number(token.slice(1));
        label = this.$tc('common.daysCount', days);
      } else {
        label = token;
      }
      return label;
    },
  },
  computed: {
    ...mapState('app', ['loading']),
    ...mapState('session', ['license']),
    ...mapGetters('session', { sectorsDetails: 'sectors' }),
    ...mapGetters('priceLists', ['wizardEnabled']),
    defaultSectorsOptions(): Array<DropdownOption> {
      const defaultSectorsOptions: Array<DropdownOption> = [];
      if (this.sectorsOptions.length === 0) {
        return defaultSectorsOptions;
      }
      return this.sectorsOptions.slice(0, this.maxSectors);
    },
    // price estimate
    priceEstimateRange: {
      get(): Array<Date | null> {
        if (!this.priceEstimateFilters) return [];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (this.priceEstimateFilters.interval as any[]).map(
          (d: DateTime | null) => d?.toJSDate() ?? null,
        );
      },
      set(calendar: Array<Date>) {
        const datetimes = (calendar as any[]).map((d: Date | null) => {
          if (!d) return null;
          const date = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
          return DateTime.fromFormat(date, 'd-M-yyyy');
        });

        // Hack double click on same day
        if (
          this.priceEstimateFilters.interval[0] &&
          this.priceEstimateFilters.interval[0].toMillis() ===
            datetimes[0]?.toMillis() &&
          !this.priceEstimateFilters.interval[1] &&
          !datetimes[1]
        ) {
          // eslint-disable-next-line prefer-destructuring
          datetimes[1] = datetimes[0];
        }
        // this.calendar = datetimes;
        this.priceEstimateFilters.interval = [datetimes[0], datetimes[1]];
      },
    },
    priceEstimateColumns(): Array<{ header: string; field: string }> {
      if (!this.priceEstimate) return [];
      const priceEstimateColumns = [] as Array<{
        header: string;
        field: string;
      }>;
      this.priceEstimate.forEach((estimate: PriceListEstimate) => {
        const sectorData: Sector = this.sectorsDetails.find(
          (s: Sector) => s.header.id === estimate.sectorId,
        );
        if (sectorData) {
          priceEstimateColumns.push({
            header: `${this.$t('priceListsQualityCheckView.sector')} ${
              sectorData.header.name
            }`,
            field: `s_${sectorData.header.id}`,
          });
        }
      });
      return priceEstimateColumns;
    },
    priceEstimateRows(): Array<{ [key: string]: string }> {
      if (!this.priceEstimate) return [];
      const priceEstimateRow = {} as { [key: string]: string };
      this.priceEstimate.forEach((estimate: PriceListEstimate) => {
        priceEstimateRow[`s_${estimate.sectorId}`] = `€ ${estimate.price}`;
      });
      return [priceEstimateRow];
    },
    // packets table
    packetsTableColumns(): Array<{ header: string; field: string }> {
      /**
       * Packets table shift columns
       */

      const packetsTableColumns: Array<Column> = [];

      // no columns if quality check data or sector/period data is missing
      if (
        !this.qualityCheck ||
        (this.packetsTableFilters.view === PacketsTableView.SECTOR &&
          !this.qualityCheck.sectorsData) ||
        (this.packetsTableFilters.view === PacketsTableView.PERIOD &&
          !this.qualityCheck.periodsData)
      ) {
        return packetsTableColumns;
      }

      // init columns
      packetsTableColumns.push({
        header: this.$t('priceListsQualityCheckView.packet'),
        field: 'packet',
      });

      // period view
      if (this.packetsTableFilters.view === PacketsTableView.PERIOD) {
        this.qualityCheck.periodsData[0].sectors.forEach(
          (sector: PriceListQualityCheckPeriodsDataPeriodSector) => {
            const sectorDetail: Sector = this.sectorsDetails.find(
              (s: Sector) =>
                s.header.id === sector.sectorId,
            );
            if (sectorDetail) {
              packetsTableColumns.push({
                header: `${this.$t('priceListsQualityCheckView.sector')} ${
                  sectorDetail.header.name
                }`,
                field: `s_${sector.sectorId.toString()}`,
              });
            }
          },
        );
      }

      // sector view
      if (this.packetsTableFilters.view === PacketsTableView.SECTOR) {
        this.qualityCheck.sectorsData[0].periods.forEach(
          (period: PriceListQualityCheckSectorsDataSectorPeriod) => {
            const periodDetail = this.priceList.periods.find(
              (periodDetail: PricePeriod) =>
                periodDetail.id === period.periodId,
            );
            if (periodDetail) {
              packetsTableColumns.push({
                header: `${periodDetail.name}`,
                field: `p_${periodDetail.id.toString()}`,
              });
            }
          },
        );
      }

      return packetsTableColumns;
    },
    packetsTableRows(): Array<Row> {
      /**
       * Sectors shift rows
       */
      const rows: Array<Row> = [];

      if (
        !this.qualityCheck ||
        (this.packetsTableFilters.view === PacketsTableView.SECTOR &&
          !this.qualityCheck.sectorsData) ||
        (this.packetsTableFilters.view === PacketsTableView.PERIOD &&
          !this.qualityCheck.periodsData)
      ) {
        return rows;
      }

      // period view
      if (this.packetsTableFilters.view === PacketsTableView.PERIOD) {
        // get period data
        const period: PriceListQualityCheckPeriodsDataPeriod | undefined =
          this.qualityCheck.periodsData.find(
            (p: PriceListQualityCheckPeriodsDataPeriod) =>
              p.periodId === this.packetsTableFilters.period,
          );

        // no rows if period data request is missing
        if (!period) return rows;

        const tokens: Array<string> = period.sectors[0].values.map(
          (sectorValue: PriceListQualityCheckPeriodsDataPeriodSectorValue) =>
            sectorValue.token,
        );

        tokens.forEach((token: string) => {
          const row: Row = {
            packet: this.getTokenLabel(token),
            token,
          };
          period.sectors.forEach(
            (periodSector: PriceListQualityCheckPeriodsDataPeriodSector) => {
              const sectorValue:
                | PriceListQualityCheckPeriodsDataPeriodSectorValue
                | undefined = periodSector.values.find(
                (v: PriceListQualityCheckPeriodsDataPeriodSectorValue) =>
                  v.token === token,
              );
              if (sectorValue) {
                row[`s_${periodSector.sectorId.toString()}`] = sectorValue.value
                  ? `€ ${sectorValue.value.toString()}`
                  : '-';
                row.threshold = sectorValue.isThreshold;
              }
            },
          );
          rows.push(row);
        });
      }

      // sector view
      if (this.packetsTableFilters.view === PacketsTableView.SECTOR) {
        // get sector data
        const sector: PriceListQualityCheckSectorsDataSector | undefined =
          this.qualityCheck.sectorsData.find(
            (s: PriceListQualityCheckSectorsDataSector) =>
              s.sectorId === this.packetsTableFilters.sector,
          );

        // no rows if sector data request is missing
        if (!sector) return rows;

        const tokens: Array<string> = sector.periods[0].values.map(
          (periodValue: PriceListQualityCheckSectorsDataSectorPeriodValue) =>
            periodValue.token,
        );

        tokens.forEach((token: string) => {
          const row: Row = {
            packet: this.getTokenLabel(token),
            token,
          };
          sector.periods.forEach(
            (sectorPeriod: PriceListQualityCheckSectorsDataSectorPeriod) => {
              const periodValue:
                | PriceListQualityCheckSectorsDataSectorPeriodValue
                | undefined = sectorPeriod.values.find(
                (v: PriceListQualityCheckSectorsDataSectorPeriodValue) =>
                  v.token === token,
              );

              if (periodValue) {
                row[`p_${sectorPeriod.periodId.toString()}`] = periodValue.value
                  ? `€ ${periodValue.value.toString()}`
                  : '-';
                row.threshold = periodValue.isThreshold;
              }

              rows.push(row);
            },
          );
        });
      }

      if (this.hasPackets && !this.packetsTableFilters.allDays) {
        const packetsTokens =
          this.priceConfig.discountType?.map(
            (discountType: number) => `G${discountType}`,
          ) ?? [];
        return rows.filter((row: Row) => {
          // if threshold return always
          if (row.threshold) {
            return true;
          }
          const index = rows.findIndex((r: Row) => r.token === row.token);

          if (
            (rows[index + 1] &&
              packetsTokens.includes(rows[index + 1].token as string)) ||
            (rows[index - 1] &&
              packetsTokens.includes(rows[index - 1].token as string))
          ) {
            return true;
          }
          return false;
        });
      }
      return rows;
    },
    // sectors shift
    sectorsShiftColumns(): Array<{ header: string; field: string }> {
      /**
       * Sectors shift table columns
       */

      // no columns if quality check data or sectors shift data is missing
      if (
        !this.qualityCheck ||
        this.qualityCheck.sectorsShiftData.length === 0
      ) {
        return [];
      }

      // get period data
      const period: PriceListQualityCheckSectorsShiftPeriod | undefined =
        this.qualityCheck.sectorsShiftData.find(
          (p: PriceListQualityCheckSectorsShiftPeriod) =>
            p.periodId === this.sectorsShiftFilters.period,
        );

      // no columns if period data request is missing
      if (!period) return [];

      // init columns
      const sectorsShiftColumns = [
        {
          header: this.$t('common.days'),
          field: 'days',
        },
      ] as Array<{
        header: string;
        field: string;
      }>;

      period.data[0].sectorShifts.forEach(
        (ss: PriceListQualityCheckSectorsShiftItem) => {
          const sector1: Sector = this.sectorsDetails.find(
            (s: Sector) => s.header.id === ss.sectors[0],
          );
          const sector2: Sector = this.sectorsDetails.find(
            (s: Sector) => s.header.id === ss.sectors[1],
          );

          sectorsShiftColumns.push({
            header: `${this.$t('priceListsQualityCheckView.sector')} ${
              sector1.header.name
            }/${this.$t('priceListsQualityCheckView.sector')} ${sector2.header.name}`,
            field: `s_${ss.sectors[0].toString()}_${ss.sectors[1].toString()}`,
          });
        },
      );

      return sectorsShiftColumns;
    },
    sectorsShiftRows(): Array<Row> {
      /**
       * Sectors shift rows
       */
      const rows: Array<Row> = [];
      if (
        !this.qualityCheck ||
        this.qualityCheck.sectorsShiftData.length === 0
      ) {
        return rows;
      }

      // get period data
      const period: PriceListQualityCheckSectorsShiftPeriod | undefined =
        this.qualityCheck.sectorsShiftData.find(
          (p: PriceListQualityCheckSectorsShiftPeriod) =>
            p.periodId === this.sectorsShiftFilters.period,
        );

      // no rows if period data request is missing
      if (!period) return rows;

      period.data.forEach(
        (periodData: PriceListQualityCheckSectorsShiftPeriodData) => {
          const row: Row = {
            days: `${this.$tc(
              'common.daysCount',
              periodData.days[0],
            )} + ${this.$tc('common.daysCount', periodData.days[1])}`,
          };

          periodData.sectorShifts.forEach(
            (ss: PriceListQualityCheckSectorsShiftItem) => {
              row[
                `s_${ss.sectors[0].toString()}_${ss.sectors[1].toString()}`
              ] = `€ ${ss.price}`;
            },
          );

          rows.push(row);
        },
      );

      return rows;
    },
    canEstimate(): boolean {
      return (
        this.priceEstimateFilters &&
        this.priceEstimateFilters.sectors.length > 0 &&
        this.priceEstimateFilters.interval.length === 2 &&
        this.priceEstimateFilters.interval.every(
          (date: Date | null) => date !== null,
        )
      );
    },
    hasPackets(): boolean {
      return this.priceConfig && this.priceConfig.discountType !== null;
    },
  },
});
