import axios, { AxiosResponse } from 'axios';
import {
  ApiStripeCreatePaymentIntentPayload,
  ApiStripeCreatePaymentIntentResponse,
  ApiStripeGetPaymentIntentResponse,
} from '@/models/api';
import apiService from './apiService';
import {
  StripePaymentIntent,
  StripePaymentIntentCreate,
} from '@/models/stripe';

export default {
  // TODO move here all stripe services/payloads from license
  async createPaymentIntent(
    payload: ApiStripeCreatePaymentIntentPayload,
  ): Promise<StripePaymentIntentCreate> {
    const res: AxiosResponse<ApiStripeCreatePaymentIntentResponse> =
      await axios.post(apiService.stripe.createPaymentIntent(), payload);
    return res.data.result.stripeIntent;
  },
  async getPaymentIntent(
    paymentIntentId: string,
  ): Promise<StripePaymentIntent> {
    const res: AxiosResponse<ApiStripeGetPaymentIntentResponse> =
      await axios.get(apiService.stripe.getPaymentIntent(paymentIntentId));
    return res.data.result;
  },
};
