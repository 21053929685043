import { computed } from 'vue';
import { useStore } from 'vuex';
import { RootState } from '@/models/store';

type UseLocalStorageReturn<T> = {
  getItem: () => T | null;
  removeItem: () => void;
  setItem: (value: T) => void;
};

type Namespace = 'planner-sector-filter' | 'planner-sector-state';

export function useLocalStorage<T>(
  namespace: Namespace,
): UseLocalStorageReturn<T> {
  const store = useStore<RootState>();

  const email = computed(() => store.state?.session.user?.email ?? '');
  const license = computed(() => store.state?.session.license?.license ?? '');

  const key = computed(() => `${license.value}-${email.value}-${namespace}`);

  return {
    getItem() {
      const item = localStorage.getItem(key.value);
      if (item === null) {
        return item;
      }

      return JSON.parse(item);
    },
    removeItem() {
      localStorage.removeItem(key.value);
    },
    setItem(value) {
      localStorage.setItem(key.value, JSON.stringify(value));
    },
  };
}
