import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  id: "reservation-service-groups",
  class: "flex flex-column"
}
const _hoisted_2 = {
  key: 0,
  class: "font-bold p-2 pl-0 text-xl"
}
const _hoisted_3 = {
  key: 0,
  class: "flex flex-column p-2 text-2xl"
}
const _hoisted_4 = { class: "font-bold text-lg" }
const _hoisted_5 = { class: "services text-base" }
const _hoisted_6 = {
  key: 0,
  class: "service print-service"
}
const _hoisted_7 = { class: "pr-3" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "flex flex-column name pl-4 pr-2" }
const _hoisted_10 = { class: "font-bold" }
const _hoisted_11 = { class: "flex-grow-1 pl-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReservationBeachTicketService = _resolveComponent("ReservationBeachTicketService")!
  const _component_divider = _resolveComponent("divider")!
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      (this.serviceGroups.length > 0)
        ? (_openBlock(), _createElementBlock("h6", _hoisted_2, _toDisplayString(_ctx.$t('settingsNavigation.ticket')), 1))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ReservationBeachTicketService),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.serviceGroups, (serviceGroup, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: serviceGroup.id
      }, [
        (_ctx.isServiceGroupVisible(serviceGroup))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              (index > 0)
                ? (_openBlock(), _createBlock(_component_divider, {
                    key: 0,
                    class: "mb-4"
                  }))
                : _createCommentVNode("", true),
              _createElementVNode("header", _hoisted_4, _toDisplayString(serviceGroup.name) + " " + _toDisplayString(` (${_ctx.formatLimitLabel(_ctx.mappedServiceGroupLimits.get(serviceGroup.id))})`), 1),
              _createElementVNode("div", _hoisted_5, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.mappedServicesToGroups.get(serviceGroup.id) ?? []), (service) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: service.id
                  }, [
                    (_ctx.isServiceVisible(service))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("img", {
                              class: "service-icon flex-shrink-0",
                              src: require(`@/assets/images/extra-services/${service.icon}`),
                              alt: ""
                            }, null, 8, _hoisted_8),
                            _createElementVNode("div", _hoisted_9, [
                              _createElementVNode("span", null, _toDisplayString(service.name), 1),
                              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.formatLimitLabel(_ctx.mappedServiceLimits.get(service.id))), 1)
                            ]),
                            (_ctx.mappedPaidServices?.[service.id] !== undefined)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createVNode(_component_InputNumber, {
                                    modelValue: this.mappedPaidServices[service.id],
                                    "onUpdate:modelValue": ($event: any) => ((this.mappedPaidServices[service.id]) = $event),
                                    showButtons: "",
                                    class: "no-print",
                                    step: 1,
                                    disabled: !_ctx.hasUpdatePermission() || !_ctx.isServiceSellable(service),
                                    onInput: ($event: any) => (_ctx.updateServiceDebounce(service)),
                                    onKeypress: _withKeys(($event: any) => (_ctx.updateServiceDebounce(service)), ["enter"]),
                                    onBlur: ($event: any) => (_ctx.updateServiceDebounce(service))
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onInput", "onKeypress", "onBlur"]),
                                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.reservationServices.get(service.id)?.price ?? 0) + " € ", 1)
                                ], 64))
                              : _createCommentVNode("", true)
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}