import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "reservation-logs"
}
const _hoisted_2 = {
  key: 0,
  class: "logs"
}
const _hoisted_3 = { class: "user-date" }
const _hoisted_4 = { class: "user" }
const _hoisted_5 = { class: "date" }
const _hoisted_6 = { class: "action-value" }
const _hoisted_7 = { class: "action" }
const _hoisted_8 = {
  key: 0,
  class: "value"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (
      _ctx.hasPermission(
        _ctx.FEATURE_PERMISSION_ACTION_CONFIG.reservations.SHOW_PAYMENT_DETAILS,
      )
    )
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "logs-button no-print",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLogsDialog && _ctx.onLogsDialog(...args)))
        }, _toDisplayString(_ctx.$t('reservationLogs.log')), 1),
        _createVNode(_component_Dialog, {
          id: "reservation-logs-dialog",
          class: "dialog",
          modal: true,
          visible: _ctx.show,
          "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.show) = $event)),
          onHide: _cache[3] || (_cache[3] = ($event: any) => (_ctx.show = false))
        }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('reservationLogs.title')), 1)
          ]),
          footer: _withCtx(() => [
            _createVNode(_component_Button, {
              class: "send-button",
              label: _ctx.$t('button.close'),
              icon: "spi-times",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.show = false))
            }, null, 8, ["label"])
          ]),
          default: _withCtx(() => [
            (_ctx.logs.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logs, (log, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "log"
                    }, [
                      _createElementVNode("div", _hoisted_3, [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(log.firstName + ' ' + log.lastName), 1),
                        _createElementVNode("span", _hoisted_5, "[" + _toDisplayString(_ctx.getLogDate(log)) + "]", 1)
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.getLogLabel(log)), 1),
                        (log.value)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.getLogValue(log)), 1))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  }), 128))
                ]))
              : (_openBlock(), _createBlock(_component_Message, {
                  key: 1,
                  severity: "info",
                  closable: false
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('reservationLogs.noLogMessage')), 1)
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        }, 8, ["visible"])
      ]))
    : _createCommentVNode("", true)
}