
import { DateTime } from 'luxon';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import { DataTableRowClickEvent } from 'primevue/datatable';
import { SpotInfo, SPOT_INFOS } from '@/models/spot';
import {
  StatisticDataTableColumn,
  StatisticDataTableView,
  StatisticDateMode,
} from '@/models/statistic';
import StatisticsDataTable from '@/components/statistics/StatisticsDataTable.vue';
import statisticService from '@/services/statisticService';
import { Service } from '@/models/service';
import { ApiStatisticsExtraServicePayload } from '@/models/api';

export default defineComponent({
  components: { StatisticsDataTable },
  data() {
    return {
      service: {} as Service,
      endpoint: this.buildEndpoint,
      exportEndpoint: this.buildExportEndpoint,
      id: 'extra-service-details',
      columns: [
        {
          label: this.$t('statisticsExtraServiceView.column.spotType'),
          field: 'spotType',
          sortable: true,
          formatter: (value: string) =>
            SPOT_INFOS.find((spot: SpotInfo) => spot.type === value)?.label,
        },
        {
          label: this.$t('statisticsExtraServiceView.column.spotName'),
          field: 'spotName',
          sortable: true,
        },
        {
          label: this.$t('statisticsExtraServiceView.column.firstName'),
          field: 'firstName',
          sortable: true,
        },
        {
          label: this.$t('statisticsExtraServiceView.column.lastName'),
          field: 'lastName',
          sortable: true,
        },
        {
          label: this.$t('statisticsExtraServiceView.column.startDate'),
          field: 'startDate',
          sortable: true,
          formatter: (value: number) => this.$spiagge.date.fromMillis(value),
        },
        {
          label: this.$t('statisticsExtraServiceView.column.endDate'),
          field: 'endDate',
          sortable: true,
          formatter: (value: number) => this.$spiagge.date.fromMillis(value),
        },
        {
          label: this.$t('statisticsExtraServiceView.column.duration'),
          field: 'duration',
          sortable: true,
        },
        {
          label: this.$t('statisticsExtraServiceView.column.quantity'),
          field: 'quantity',
          sortable: true,
        },
        {
          label: this.$t('statisticsExtraServiceView.column.value'),
          field: 'value',
          sortable: true,
        },
      ] as Array<StatisticDataTableColumn>,
      basePayload: {
        dateMode: StatisticDateMode.ATTENDANCE,
      },
      searchColumns: [],
      defaultView: {
        id: DateTime.now().startOf('day').toSeconds(),
        label: this.$t('statisticsExtraServiceView.defaultViewLabel'),
        columns: [
          'spotType',
          'spotName',
          'firstName',
          'lastName',
          'startDate',
          'endDate',
          'duration',
          'quantity',
          'value',
        ],
        orderBy: [],
      } as StatisticDataTableView,
      tooltipText: this.$t('statisticsExtraServiceView.tooltipText'),
    };
  },
  methods: {
    buildEndpoint(payload: ApiStatisticsExtraServicePayload) {
      return statisticService.extraServiceStatistic(this.service.id, payload);
    },
    buildExportEndpoint(payload: ApiStatisticsExtraServicePayload) {
      return statisticService.exportExtraServiceStatistic(this.service.id, payload);
    },
    rowClass() {
      return 'row';
    },
    openReservation(event: DataTableRowClickEvent) {
      const routeData = this.$router.resolve({
        name: 'ReservationEdit',
        params: { id: `${event.data.reservationId}` },
      });
      this.$spiagge.utils.global.openLink(routeData.href, true);
    },
  },
  computed: {
    ...mapGetters('session', ['services']),
  },
  beforeMount() {
    if (!this.$route.params.id) {
      this.$router.push('/statistics/extra-services');
    }
    const serviceId = Number(this.$route.params.id);

    this.service = this.services.find((s: Service) => s.id === serviceId);
    if (!this.service) {
      this.$router.push('/statistics/extra-services');
    }
    this.$store.commit(
      'statistic/setTitle',
      `${this.$t('statisticsExtraServiceView.setTitle')} ${this.service.name}`,
    );
  },
});
