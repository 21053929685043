import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2fea9e67"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "statistics-counters-hotel-view",
  class: "statistics-view view"
}
const _hoisted_2 = {
  key: 0,
  id: "counters-table"
}
const _hoisted_3 = {
  key: 1,
  id: "counters-charts"
}
const _hoisted_4 = { class: "grid" }
const _hoisted_5 = { class: "col-12 md:col-6 lg:col-12 xl:col-6" }
const _hoisted_6 = { class: "col-12 md:col-6 lg:col-12 xl:col-6" }
const _hoisted_7 = { id: "other-charts" }
const _hoisted_8 = { class: "grid" }
const _hoisted_9 = { class: "col-12 md:col-6 lg:col-12 xl:col-6" }
const _hoisted_10 = { class: "col-12 md:col-6 lg:col-12 xl:col-6" }
const _hoisted_11 = { class: "col-12 md:col-6 lg:col-12 xl:col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleButtonCustom = _resolveComponent("ToggleButtonCustom")!
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _component_StatisticsCountersTable = _resolveComponent("StatisticsCountersTable")!
  const _component_StatisticsDoughnutChart = _resolveComponent("StatisticsDoughnutChart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.license?.hasGroup)
      ? (_openBlock(), _createBlock(_component_ToggleButtonCustom, {
          key: 0,
          buttonWidth: _ctx.widthToggle,
          modelValue: _ctx.groupDataFilter,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.groupDataFilter) = $event)),
          trueLabel: _ctx.trueLabel,
          falseLabel: _ctx.falseLabel,
          class: "mt-2"
        }, null, 8, ["buttonWidth", "modelValue", "trueLabel", "falseLabel"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TabMenu, {
        model: _ctx.tabs,
        activeIndex: _ctx.selectedTab,
        "onUpdate:activeIndex": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedTab) = $event))
      }, null, 8, ["model", "activeIndex"]),
      (_ctx.selectedTab === 0)
        ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
            _createVNode(_component_StatisticsCountersTable, {
              counters: _ctx.counters,
              columns: _ctx.columns,
              searchField: 'name'
            }, null, 8, ["counters", "columns"])
          ]))
        : _createCommentVNode("", true),
      (_ctx.selectedTab === 1)
        ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_StatisticsDoughnutChart, {
                  label: _ctx.hotelCountersReservationsChart.label,
                  chartData: _ctx.hotelCountersReservationsChart.chartData,
                  showTotal: ""
                }, null, 8, ["label", "chartData"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_StatisticsDoughnutChart, {
                  label: _ctx.hotelCountersDurationChart.label,
                  chartData: _ctx.hotelCountersDurationChart.chartData,
                  showTotal: ""
                }, null, 8, ["label", "chartData"])
              ])
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("section", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_StatisticsDoughnutChart, {
              label: _ctx.reservationsChart.label,
              chartData: _ctx.reservationsChart.chartData,
              showTotal: ""
            }, null, 8, ["label", "chartData"])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_StatisticsDoughnutChart, {
              label: _ctx.umbrellasChart.label,
              chartData: _ctx.umbrellasChart.chartData,
              showTotal: ""
            }, null, 8, ["label", "chartData"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_StatisticsDoughnutChart, {
              label: _ctx.bulkPiecesChart.label,
              chartData: _ctx.bulkPiecesChart.chartData,
              showTotal: ""
            }, null, 8, ["label", "chartData"])
          ])
        ])
      ])
    ])
  ], 64))
}