
import { defineComponent, PropType } from 'vue';
import { Field, Form } from 'vee-validate';
import { EditableServiceGroup } from '@/models/serviceGroup/editableServiceGroup';

export default defineComponent({
  name: 'SettingsServiceGroupDialog',
  components: { Field, Form },
  emits: ['close', 'submitForm'],
  props: {
    modelValue: {
      type: Object as PropType<EditableServiceGroup>,
      required: true,
    },
    groupId: {
      type: Number,
      required: true,
    },
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // Fake flags to enable limits
      hasReservationLimit: false,
      hasDailyLimit: false,
    };
  },
  methods: {
    updateLimitFlags(): void {
      this.hasReservationLimit = this.model.reservationLimit > 0;
      this.hasDailyLimit = this.model.dailyLimit > 0;
    },
    getServiceGroupTitleDialog(): string {
      return this.$props.groupId > 0
        ? this.$t('settingsServiceGroupView.dialog.titleEditGroup')
        : this.$t('settingsServiceGroupView.dialog.titleAddGroup');
    },
    async onSaveServiceGroup(): Promise<void> {
      // Check if limits are enabled or not
      if (!this.hasReservationLimit) {
        this.model.reservationLimit = 0;
      }
      if (!this.hasDailyLimit) {
        this.model.dailyLimit = 0;
      }
      // Emit submit event with updated model
      this.$emit('submitForm', this.model);
      // Close dialog
      this.visible = false;
    },
  },
  computed: {
    model: {
      get(): EditableServiceGroup {
        return this.modelValue;
      },
      set(data: any) {
        //
      },
    },
    visible: {
      get(): boolean {
        return this.showDialog;
      },
      set(visible: boolean): void {
        if (!visible) {
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    showDialog(visible: boolean) {
      if (visible) {
        this.updateLimitFlags();
      }
    },
  },
});
