
import { defineComponent, PropType } from 'vue';
import { Field, Form } from 'vee-validate';
import { cloneDeep } from 'lodash';
import CustomerTags from './CustomerTags.vue';
import { Address, Customer, OtherContact, Pet, Phone } from '@/models/customer';
import {
  NATION_PREFIXES,
  ListElementNumber,
  DropdownOption,
} from '@/models/index';
import { License } from '@/models/license';
import customerService from '@/services/customerService';
import { ToastSeverity } from '@/models/toast';
import toastUtil from '@/utils/toastUtil';
import { AreaCode } from '@/enum';

export default defineComponent({
  emits: ['onSave', 'closeDialog'],
  components: { Form, Field, CustomerTags },
  props: {
    customerRef: {
      type: Object as PropType<Customer>,
      required: true,
    },
    edit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      customer: {} as Customer,
      otherEmails: [] as Array<Address>,
      otherPhones: [] as Array<Phone>,
      pets: [] as Array<Pet>,
      phonesAreaCodes: [] as Array<DropdownOption>,
      nationCode: NATION_PREFIXES,
      genderDropDownList: [
        { name: this.$t('common.other'), code: 0 },
        { name: this.$t('common.female'), code: 1 },
        { name: this.$t('common.male'), code: 2 },
      ] as Array<ListElementNumber>,
      dialogVisibility: true,
    };
  },
  methods: {
    async submitCustomer() {
      let response = null;
      if (!this.customer.invoicePlaceId) {
        this.customer.invoicePlaceId = 0;
      }
      const otherContacts = {} as OtherContact;
      if (this.otherEmails.length) {
        otherContacts.emails = this.otherEmails;
      }
      if (this.otherPhones.length) {
        otherContacts.phones = this.otherPhones;
      }
      if (this.pets.length) {
        this.customer.dogs = this.pets;
      }
      this.customer.otherContacts = otherContacts;
      try {
        if (this.edit) {
          response = await customerService.update(this.customer);
        } else {
          response = await customerService.create(this.customer);
        }
        if (response.contactId && response.contactId > 0) {
          this.customer.tags = this.customer.tags ? this.customer.tags : [];
          response.tags = await customerService.updateCustomerTags(
            response.contactId,
            this.customer.tags,
          );
        }
        if (!response) {
          this.$toast.add(
            toastUtil.build(
              ToastSeverity.ERROR,
              this.$t('toast.error.title'),
              this.$t('toast.error.msg'),
            ),
          );
          return;
        }
        this.$emit('onSave', response);
        this.$emit('closeDialog');
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
    closeShowDialog() {
      this.$emit('closeDialog');
      this.customer = {} as Customer;
    },
    hideDialog() {
      this.$emit('closeDialog');
      this.customer = {} as Customer;
    },
    addOtherEmail() {
      this.otherEmails.push({} as Address);
    },
    deleteOtherEmail(index: number) {
      this.otherEmails.splice(index, 1);
    },
    addOtherPhone() {
      this.otherPhones.push({} as Phone);
    },
    addPet() {
      this.pets.push({} as Pet);
    },
    deletePet(index: number) {
      this.customer.dogs.splice(index, 1);
    },
    deleteOtherPhone(index: number) {
      this.otherPhones.splice(index, 1);
    },
    onChangeTags(value: Array<number>) {
      this.customer.tags = value;
    },
  },
  computed: {
    license(): License | null {
      return this.$store.getters['session/license'];
    },
    appWidth(): string {
      const width = this.$store.getters['app/windowWidth'];
      if (width) {
        return width > 768 ? '50%' : '100%';
      }
      return '50%';
    },
    show: {
      get(): boolean {
        return this.dialogVisibility;
      },
      set(value: boolean) {
        this.dialogVisibility = value;
        if (!value) {
          this.$emit('closeDialog');
        }
      },
    },
  },
  created() {
    this.customer = cloneDeep(this.customerRef);
    if (this.customer.otherContacts) {
      if (this.customer.otherContacts.emails) {
        this.otherEmails = this.customer.otherContacts.emails;
      }
      if (this.customer.otherContacts.phones) {
        this.otherPhones = this.customer.otherContacts.phones;
      }
    }
    if (this.customer.dogs) {
      this.pets = this.customer.dogs;
    }
    this.phonesAreaCodes = Object.values(AreaCode).map(
      (element: string) =>
        ({
          label: this.$t(`areaCode.${element}`),
          value: element,
        } as DropdownOption),
    );
  },
});
