/* eslint-disable camelcase */
/**
 * License models
 */

import {
  BookingConfirmStatus,
  BookingEndPeriod,
  BookingHalfDay,
  BookingInvoiceDescription,
  BookingInvoiceType,
  BookingMode,
  BookingOnlineServiceCostType,
  BookingPaymentFeesBracketsMode,
  BookingPaymentFeesCustomerMode,
  BookingPaymentFeesMode,
  BookingPaymentInvoiceMode,
  BookingPaymentMode,
  BookingRefundPolicy,
} from './booking';
import { MapView } from './map';
import { SectorService, SectorsShiftMode } from './sector';
import { StripeAccountType, StripeTransferSchedule } from './stripe';
import { ColorName } from './color';
import { CustomerTag } from './customer';
import { PriceRoundingMode } from '.';

export interface StickerSettingsFields {
  id: number;
  printCustomer: boolean;
  printHotelRoom: boolean;
  printReservationDates: boolean;
  printSpot: boolean;
  printStaging: boolean;
}

export enum LicenseMapCabinMode {
  OPEN_LIST,
  OPEN_CABIN,
}

export enum LicenseFreeSpotOpeningMode {
  MODAL,
  DIRECT,
}

export enum LicenseSharingCalculationMode {
  FLAT,
  PERCENTAGE,
}

export enum LicenseType {
  BOOKING,
  PREMIUM,
  DEMO,
}
export interface LicenseList {
  code: string;
  img: string;
  name: string;
}

/** Enums without context (move in the future) */

export enum SpecialMode {
  NONE,
  SPIAGGIA_LIBERA_A,
}

export enum MergedReservationMode {
  SUM,
  MERGE,
}

export enum LicenseBeachTicketsMode {
  DISABLED,
  BEDS,
  SERVICE,
}

export enum PricesVoucherFilterMode {
  ENDING,
  STARTING,
}

export enum CashFlowAnalyticsMode {
  DISABLED,
  ENABLED,
  INVOICE_ONLY,
}

export enum LicenseRecycleBinMode {
  DISABLED,
  ENABLED,
}

export enum CabinMode {
  SERVICE,
  RESERVATION,
}

export enum TemporaryFutureReservation {
  DISABLED = 'disabled',
  ENABLED_NEXT_DAY = 'enabled-next-day',
}

export enum QrCodeReadMode {
  MANUAL = 'manual',
  AUTO_QR_READER = 'auto-qr-reader',
  AUTO_RESERVATION = 'auto-reservation',
}

export enum PosCommandPaper {
  NO_PRINT = 0,
  PRINT_ORDER = 1,
  PRINT_SUMMARY = 2,
}

export enum TemporaryOnCheckOut {
  DISABLED = 'disabled',
  DAILY = 'daily',
  DAILY_NO_SEASONAL = 'daily-no-seasonal',
}

/* SECTOR */

export interface LicenseSector {
  b_d: number;
  b_max: number;
  b_min: number;
  c_d: number;
  c_max: number;
  c_min: number;
  d_d: number;
  d_max: number;
  d_min: number;
  m_d: number;
  m_max: number;
  m_min: number;
}

export interface LicenseBookingSector extends LicenseSector {
  i_max: number;
  i_min: number;
}

export interface LicenseSectors {
  [key: number]: LicenseSector;
}

export interface LicenseSectorDetail {
  name?: string;
  image?: string;
  description?: string;
}

export interface LicenseSectorDetails {
  [key: number]: LicenseSectorDetail;
}

export interface LicenseStandardSectorServices {
  [key: number]: SectorService;
}

export interface LicenseBookingSectorServices {
  [key: number]: LicenseBookingSector;
}
/* prettied interfaces for internal use (custom getters on session store) */

export interface LicenseSectorStagingDetail {
  min: number;
  default: number;
  max: number;
}
export interface LicenseSectorBookingStagingDetail {
  min: number;
  max: number;
}
export interface LicenseSectorStaging {
  maxiBeds?: LicenseSectorStagingDetail;
  beds?: LicenseSectorStagingDetail;
  deckChairs?: LicenseSectorStagingDetail;
  chairs?: LicenseSectorStagingDetail;
}

export interface LicenseBookingSectorStaging {
  maxiBeds: LicenseSectorStagingDetail;
  beds: LicenseSectorStagingDetail;
  deckChairs: LicenseSectorStagingDetail;
  chairs: LicenseSectorStagingDetail;
  minMax: LicenseSectorBookingStagingDetail;
}

export interface LicenseSectorFull extends LicenseSectorDetail {
  id: number;
  staging: LicenseSectorStaging;
}

export interface LicenseBookingSectorFull extends LicenseSectorDetail {
  id: number;
  staging: LicenseBookingSectorStaging;
}

export enum LicenseUmbrellaMultipleBookings {
  BLOCKED,
  NOTICE,
}

export interface Beach {
  bookingVisible: boolean;
  country: string;
}

export enum LicenseBookingCartMode {
  OLD,
  NEW,
  TICKET_ONLY,
}

export interface LicenseFeatureFlags {
  revenueManagementEnabled: boolean;
  whiteLabelEnabled: boolean;
  chatBotEnabled: boolean;
  stripePaymentsPageV2: boolean;
}

export interface LicenseTicketLimits {
  ticketMaximumCapacity: number;
  ticketReservationLimit: number;
}

export interface License extends LicenseTicketLimits {
  id: number;
  license: string;
  name: string;
  licenseType: LicenseType;
  beachDefaultView: MapView;
  umbrellaMultipleBookings: LicenseUmbrellaMultipleBookings;
  halfDay: boolean;
  mapOffset: number;
  mapOffsetVertical: number;
  mapIconSmallFormat: 0 | 1;
  mapZoom: number;
  mapVoidUmbrellaBeds: number;
  mapQOrder: number;
  mapBg: string | null;
  mapBgWidth: number;
  mapBgHeight: number;
  mapBgPosX: number;
  mapBgPosY: number;
  mapColorFree: ColorName;
  mapColorReserved: ColorName;
  mapColorReservedFirstDay: ColorName;
  mapColorReservedLastDay: ColorName;
  mapColorTemporary: ColorName;
  mapColorHalfDayMorning: ColorName;
  mapColorHalfDayAfternoon: ColorName;
  mapColorFullDay: ColorName;
  mapColorSeasonal: ColorName;
  mapColorHotel: ColorName;
  mapColorNotPaid: ColorName;
  reservationMultiContact: boolean;
  temporaryOnCheckOut: TemporaryOnCheckOut;
  temporaryFutureReservation: TemporaryFutureReservation;
  qrCodeReadMode: QrCodeReadMode;
  standardSectorServices: LicenseStandardSectorServices;
  cabinMultipleBookings: boolean;
  cabinMode: CabinMode;
  mapCabinMode: LicenseMapCabinMode;
  bookingStatus: boolean;
  specialMode: SpecialMode;
  bookingMode: BookingMode;
  bookingStartPeriod: string;
  bookingEndPeriod: string;
  bookingToday: boolean;
  bookingNextYear: boolean;
  bookingTimeLimit: null | string;
  bookingHalfDay: BookingHalfDay;
  bookingSeasonal: boolean;
  bookingEndPeriodOffset: BookingEndPeriod;
  bookingUserUmbrellasLimit: number;
  bookingWidgetShowImg: boolean;
  bookingEmailContact: string | null;
  bookingPaymentMode: BookingPaymentMode;
  bookingPaymentCreditcardLimit: number;
  bookingPaymentPayCardsActive: boolean;
  bookingPaymentIban: string | null;
  bookingPaymentFeesMode: BookingPaymentFeesMode;
  bookingPaymentFeesCustomerMode: BookingPaymentFeesCustomerMode;
  bookingPaymentFeesBracketsMode: BookingPaymentFeesBracketsMode;
  bookingPaymentFeesPercentage: number;
  bookingPaymentFeesFixed: number;
  portalPaymentFeesPercentage: number;
  portalPaymentFeesSponsoredPercentage: number;
  bookingConfirmStatus: BookingConfirmStatus;
  bookingPaymentInvoiceMode: BookingPaymentInvoiceMode;
  bookingInvoiceTypes: BookingInvoiceType;
  bookingInvoiceDescription: BookingInvoiceDescription;
  bookingPrintFiscalInvoice: boolean;
  bookingRefundPolicy: BookingRefundPolicy;
  bookingTaxCodeRequired: boolean;
  bookingOnlineServiceCost: number;
  bookingOnlineServiceCostType: BookingOnlineServiceCostType;
  bookingOnlineServiceMaxCost: number;
  bookingOnlineCabin: boolean;
  bookingSectorServices: LicenseBookingSectorServices;
  bookingPaymentStripeTest: boolean;
  bookingPaymentStripePk: string | null;
  bookingPaymentStripeSk: string | null;
  bookingPaymentStripeAcctId: string | null;
  bookingPaymentStripeAcctType: StripeAccountType;
  bookingPaymentStripeRefreshToken: string | null;
  bookingPaymentStripeAcctActive: boolean;
  bookingPaymentStripeSecondAcctId: string | null;
  bookingPaymentStripeSecondAcctType: StripeAccountType;
  bookingPaymentStripeSecondAcctActive: boolean;
  bookingPaymentStripeTransferSchedule: StripeTransferSchedule;
  bookingPaymentPaypalSandbox: boolean;
  bookingPaymentPaypal: string;
  bookingPolicy: string | null;
  bookingPolicyUrl: string | null;
  bookingSelectPeriodNotes: string | null;
  bookingMapNotes: string | null;
  bookingServicesNotes: string | null;
  bookingUserDataNotes: string | null;
  bookingEmailNotes: string | null;
  bookingEmailNotesCheckin: boolean;
  bookingNotesFillable: boolean;
  bookingPixelAds: string | null;
  bookingPixelFb: number;
  bookingCartMode: LicenseBookingCartMode;
  bookingWeatherInsuranceEnabled: boolean;
  bookingSponsorEnabled: boolean;
  bookingNoShowInsuranceEnabled: boolean;
  pricesChangeSectorMode: SectorsShiftMode;
  pricesMergedRegMode: MergedReservationMode;
  beachTickets: LicenseBeachTicketsMode;
  pricesVoucherFilterMode: PricesVoucherFilterMode;
  pricesVoucherDefaultButton: boolean;
  pricesVoucherLock: boolean;
  pricesCashFlow: CashFlowAnalyticsMode;
  pricesCashFlowDelete: boolean;
  pricesBedsOld: boolean;
  seasonalLastCopy: number | null;
  seasonalPriceListId: number;
  seasonalStartDate: string | null;
  seasonalEndDate: string | null;
  sectorsDetails: LicenseSectorDetails;
  sharing: boolean;
  sharingValue: number;
  sharingCardType: number;
  invoiceName: string | null;
  invoiceTaxCode: string | null;
  invoiceVatCode: string | null;
  invoiceAddress1: string | null;
  invoiceAddress2: string | null;
  invoiceZip: string | null;
  invoiceCity: string | null;
  invoiceState: string | null;
  invoiceCountry: string | null;
  invoiceCodeFamily: number;
  recycleBinMode: LicenseRecycleBinMode;
  recycleBinPermission: null;
  registrationView: boolean;
  fastCash: boolean;
  fastCashStdBtns: boolean;
  posCommandPaper: PosCommandPaper;
  weatherCityId: number;
  statisticsAdvanced: boolean;
  qrReader: boolean;
  warehouseMode: boolean;
  warehouseOnline: false;
  freeSpotOpeningMode: LicenseFreeSpotOpeningMode;
  beach?: Beach;
  sharingCalculationMode: LicenseSharingCalculationMode;
  hasGroup: boolean;
  tags: Array<CustomerTag>;
  reservationCopyServices: boolean;
  receiptPrintCustomerName: boolean;
  priceListWizardEnabled: boolean;
  checkInOutEnabled: boolean;
  multipleCheckoutPointsEnabled: boolean;
  featureFlags: LicenseFeatureFlags;
  priceRoundingMode: PriceRoundingMode;
  mapLazyLoadingEnabled: boolean;
  forceRectangularMapSpotsEnabled: boolean;
  stripePosTerminalEnabled: boolean;
  explodePlannerOnFirstLoading: boolean;
  stickerSettingsFields: StickerSettingsFields;
  webticId: number | null;
}

export interface PayloadBookingSettings {
  bookingStatus: boolean;
  bookingStartPeriod: string;
  bookingEndPeriod: string;
  specialMode: number;
  bookingToday: boolean;
  bookingTimeLimit: string;
  bookingHalfDay: number;
  bookingSeasonal: boolean;
  bookingEndPeriodOffset: number;
  bookingUserUmbrellasLimit: number;
  bookingWidgetShowImg: boolean;
  bookingMode: number;
  bookingSectorServices: LicenseStandardSectorServices;
  bookingEmailContact: string;
  bookingPaymentMode: number;
  bookingPaymentCreditcardLimit: number;
  bookingPaymentPayCardsActive: boolean;
  bookingPaymentPaypal: string;
  bookingPaymentPaypalSandbox: boolean;
  bookingPaymentFeesMode: number;
  bookingPaymentFeesCustomerMode: number;
  bookingPaymentFeesBracketsMode: number;
  bookingPaymentFeesPercentage: number;
  bookingPaymentStripeTest: boolean;
  bookingPaymentStripeAcctId: string;
  bookingPaymentStripeAcctType: StripeAccountType;
  bookingPaymentStripeAcctActive: boolean;
  bookingPaymentFeesFixed: number;
  bookingPaymentIban: string;
  bookingOnlineServiceCost: number;
  bookingOnlineServiceCostType: number;
  bookingOnlineServiceMaxCost: number;
  portalPaymentFeesPercentage: number;
  bookingPaymentInvoiceMode: number;
  bookingInvoiceTypes: number;
  bookingInvoiceDescription: number;
  bookingPrintFiscalInvoice: boolean;
  bookingTaxCodeRequired: boolean;
  bookingConfirmStatus: number;
  bookingRefundPolicy: number;
  bookingPolicy: string;
  bookingPolicyUrl: string;
  bookingSelectPeriodNotes: string;
  bookingMapNotes: string;
  bookingServicesNotes: string;
  bookingUserDataNotes: string;
  bookingEmailNotes: string;
  bookingEmailNotesCheckin: boolean;
  bookingPixelAds: string;
  bookingPixelFb: number;
  bookingNextYear: boolean;
  sharing: boolean;
  sharingCardType: number;
  sharingValue: number;
  bookingCartMode: LicenseBookingCartMode;
  bookingWeatherInsuranceEnabled: boolean;
  /** Portal file */
  bookingVisible: boolean;
  bookingPaymentStripeTransferSchedule: StripeTransferSchedule;
  bookingOnlineCabin: boolean;
  bookingSponsorEnabled: boolean;
}

// TODO move to api module and make a generic license update payload
export interface PayloadBeachSettings {
  beachDefaultView: MapView;
  checkInOutEnabled: boolean;
  mapOffset: number;
  mapOffsetVertical: number;
  mapZoom: number;
  mapIconSmallFormat: number;
  mapVoidUmbrellaBeds: number;
  mapQOrder: number;
  mapBgWidth: number;
  mapBgHeight: number;
  mapBgPosX: number;
  mapBgPosY: number;
  mapColorFree: ColorName;
  mapColorReserved: ColorName;
  mapColorTemporary: ColorName;
  mapColorHalfDayMorning: ColorName;
  mapColorHalfDayAfternoon: ColorName;
  mapColorFullDay: ColorName;
  mapColorSeasonal: ColorName;
  mapColorHotel: ColorName;
  mapColorNotPaid: ColorName;
  mapColorReservedFirstDay: ColorName;
  mapColorReservedLastDay: ColorName;
  umbrellaMultipleBookings: LicenseUmbrellaMultipleBookings;
  registrationView: boolean;
  halfDay: boolean;
  reservationMultiContact: boolean;
  temporaryOnCheckOut: TemporaryOnCheckOut;
  temporaryFutureReservation: TemporaryFutureReservation;
  qrCodeReadMode: QrCodeReadMode;
  cabinMultipleBookings: boolean;
  standardSectorServices: Array<SectorService>;
  mapCabinMode: LicenseMapCabinMode;
  cabinMode: number;
  sectorsDetails: LicenseSectorDetails;
  freeSpotOpeningMode: LicenseFreeSpotOpeningMode;
  reservationCopyServices: boolean;
  priceRoundingMode: PriceRoundingMode;
}

export interface PayloadPriceListSettings {
  pricesVoucherDefaultButton: boolean;
  pricesVoucherFilterMode: PricesVoucherFilterMode;
  pricesChangeSectorMode: SectorsShiftMode;
  pricesMergedRegMode: MergedReservationMode;
  fastCash: boolean;
  fastCashStdBtns: boolean;
  posCommandPaper: PosCommandPaper;
  pricesCashFlow: CashFlowAnalyticsMode;
  pricesCashFlowDelete: boolean;
  pricesBedsOld: boolean;
  statisticsAdvanced: boolean;
  beachTickets: LicenseBeachTicketsMode;
  cabinMode: number;
  receiptPrintCustomerName: boolean;
  stickerSettingsPrintCustomer: boolean;
  stickerSettingsPrintHotelRoom: boolean;
  stickerSettingsPrintReservationDates: boolean;
  stickerSettingsPrintSpot: boolean;
  stickerSettingsPrintStaging: boolean;
}

// TODO remove
export interface PayloadSeasonalSettings {
  seasonalPriceListId: number | null;
  seasonalStartDate: string;
  seasonalEndDate: string;
}

export enum LicenseDocumentTemplateAction {
  DRAW = '1',
  FILE = '2',
}

export interface LicenseDocumentTemplate {
  actionType: LicenseDocumentTemplateAction;
  createdAt: number | null;
  deleted: number;
  id: number;
  label: string;
  licenseId: string;
  templateData: null;
  templateFilePath: null;
  updatedAt: number;
  url: string;
}

export interface StripeResponse {
  created: number;
  expiresAt: number;
  object: string;
  url: string;
}

export interface StripeAccountInitResponse {
  stripeAccountLink: string;
}

export const LICENSE_FIVE_BEACH = [
  'it-fc-47042-claudio',
  'it-fc-47042-pippo',
  'it-fc-47042-snoopy',
  'it-fc-47042-birimbao',
  'it-fc-47042-piera',
].concat(process.env.NODE_ENV === 'staging' ? ['it-te-64021-unica-beach'] : []);

export interface LicenseSectorDetailFormatted {
  id: number;
  name: string;
  image: string;
  description: string;
}
