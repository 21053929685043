
import { defineComponent, PropType } from 'vue';
import {
  PriceConfigWizardSchemaCombinationsRow,
  PriceConfigWizardSchemaInputData,
  PriceConfigWizardSchemaRow,
  PriceConfigWizardSchemaRowStatus,
  PriceConfigWizardSchemaSwitchData,
} from '@/models/priceConfig';
import FieldPriceConfigWizardRow from './FieldPriceConfigWizardRow.vue';

export default defineComponent({
  name: 'FieldPriceConfigWizardExpandableRow',
  emits: ['collapse', 'expand', 'fieldInput', 'switch'],
  components: { FieldPriceConfigWizardRow },
  props: {
    data: {
      type: Object as PropType<PriceConfigWizardSchemaCombinationsRow>,
      required: true,
    },
    errors: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
  },
  methods: {
    getVisibleRows(): Array<PriceConfigWizardSchemaRow> {
      // never show hidden rows
      const rows = Object.values(this.data.rows).filter(
        (r: PriceConfigWizardSchemaRow) =>
          r.status !== PriceConfigWizardSchemaRowStatus.HIDDEN,
      );
      // if collapsed show only filled or fulfilled rows (pinned)
      if (!this.data.expanded) {
        return rows.filter((r: PriceConfigWizardSchemaRow) =>
          [
            PriceConfigWizardSchemaRowStatus.FILLED,
            PriceConfigWizardSchemaRowStatus.FULFILLED,
          ].includes(r.status),
        );
      }
      // expanded, show all rows
      return rows;
    },
    onExpander(): void {
      this.$emit(this.data.expanded ? 'collapse' : 'expand', this.data);
    },
    onFieldInput($data: PriceConfigWizardSchemaInputData): void {
      this.$emit('fieldInput', {
        ...$data,
        ...{
          pieces: this.data.pieces,
          withCabin: this.data.withCabin,
        },
      });
    },
    onSwitch($data: PriceConfigWizardSchemaSwitchData): void {
      this.$emit('switch', {
        ...$data,
        ...{
          pieces: this.data.pieces,
          withCabin: this.data.withCabin,
        },
      });
    },
  },
});
