import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-73b07dee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field-price-config-combinations" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["id"]
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "label" }
const _hoisted_7 = { class: "actions" }
const _hoisted_8 = ["id"]
const _hoisted_9 = { class: "label" }
const _hoisted_10 = { class: "actions" }
const _hoisted_11 = ["id"]
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "actions" }
const _hoisted_14 = ["id"]
const _hoisted_15 = { class: "label" }
const _hoisted_16 = { class: "actions" }
const _hoisted_17 = { class: "rows" }
const _hoisted_18 = { class: "flex align-items-center period-item" }
const _hoisted_19 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _component_Message = _resolveComponent("Message")!
  const _component_InlineMessage = _resolveComponent("InlineMessage")!
  const _component_FieldPriceConfigWizardRow = _resolveComponent("FieldPriceConfigWizardRow")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_FieldPriceConfigWizardExpandableRow = _resolveComponent("FieldPriceConfigWizardExpandableRow")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TabMenu, {
      class: "sticky-periods",
      model: _ctx.periodsTabs,
      activeIndex: _ctx.selectedPeriodTab,
      "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedPeriodTab) = $event)),
      onTabChange: _ctx.onPeriodChange
    }, null, 8, ["model", "activeIndex", "onTabChange"]),
    _withDirectives(_createVNode(_component_Message, {
      severity: "info",
      closable: false
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('fieldPriceListCombinations.loadingMessage')), 1)
      ]),
      _: 1
    }, 512), [
      [_vShow, _ctx.isBuilding]
    ]),
    (_ctx.ready)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(Object.values(_ctx.wizardSchema), (period) => {
          return _withDirectives((_openBlock(), _createElementBlock("section", {
            key: period.token,
            id: period.token,
            class: "period"
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(period.sectors), (sector) => {
              return (_openBlock(), _createBlock(_component_Panel, {
                key: sector.fullToken,
                id: sector.fullToken,
                class: "sector",
                header: sector.label,
                toggleable: true,
                collapsed: sector.collapsed,
                onToggle: ($event: any) => (sector.collapsed = !sector.collapsed)
              }, {
                header: _withCtx(() => [
                  _createElementVNode("span", {
                    class: "sector-name",
                    onClick: ($event: any) => (sector.collapsed = !sector.collapsed)
                  }, [
                    _createElementVNode("span", null, _toDisplayString(sector.label), 1),
                    (_ctx.parsedErrors.includes(sector.fullToken))
                      ? (_openBlock(), _createBlock(_component_InlineMessage, {
                          key: 0,
                          severity: "error",
                          closable: false
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t('fieldPriceListCombinations.fieldMessage')), 1)
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true)
                  ], 8, _hoisted_3)
                ]),
                default: _withCtx(() => [
                  (!sector.collapsed && Object.values(sector.rows).filter(
                (row) => row.type === _ctx.rowType.EXTRA
              ).length > 0)
                    ? (_openBlock(), _createElementBlock("section", {
                        key: 0,
                        id: `extra-${sector.fullToken}`
                      }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(sector.rows).filter(
                    (row) => row.type === _ctx.rowType.EXTRA
                  ), (extraRow) => {
                          return (_openBlock(), _createBlock(_component_FieldPriceConfigWizardRow, {
                            key: extraRow.fullToken,
                            data: extraRow,
                            errors: _ctx.parsedErrors,
                            showSwitch: false,
                            onFieldInput: _ctx.onFieldInput,
                            onAction: _ctx.onRowAction,
                            onSwitch: _ctx.onSwitch
                          }, null, 8, ["data", "errors", "onFieldInput", "onAction", "onSwitch"]))
                        }), 128))
                      ], 8, _hoisted_4))
                    : _createCommentVNode("", true),
                  (!sector.collapsed && Object.values(sector.rows).filter(
              (row) => row.type === _ctx.rowType.SPOT
              ).length > 0)
                    ? (_openBlock(), _createElementBlock("section", {
                        key: 1,
                        id: `spots-${sector.fullToken}`
                      }, [
                        _createVNode(_component_Divider, { align: "left" }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('fieldPriceListCombinations.spots')), 1),
                            _createElementVNode("div", _hoisted_7, [
                              _withDirectives(_createVNode(_component_Button, {
                                label: _ctx.$t('fieldPriceListCombinations.copyIn'),
                                class: "p-button-sm p-button-text",
                                onClick: ($event: any) => (_ctx.onCopyDialog(period, sector, _ctx.copyModes.SPOT))
                              }, null, 8, ["label", "onClick"]), [
                                [_directive_tooltip, 
                      _ctx.$t('fieldPriceListCombinations.copyInOtherSectors')
                    ]
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1024),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(sector.rows).filter(
                (row) => row.type === _ctx.rowType.SPOT
                ), (spotRow) => {
                          return (_openBlock(), _createBlock(_component_FieldPriceConfigWizardRow, {
                            key: spotRow.fullToken,
                            data: spotRow,
                            errors: _ctx.parsedErrors,
                            showSwitch: spotRow.fullToken.includes('W'),
                            onFieldInput: _ctx.onFieldInput,
                            onAction: _ctx.onRowAction,
                            onSwitch: _ctx.onSwitch
                          }, null, 8, ["data", "errors", "showSwitch", "onFieldInput", "onAction", "onSwitch"]))
                        }), 128))
                      ], 8, _hoisted_5))
                    : _createCommentVNode("", true),
                  (!sector.collapsed && Object.values(sector.rows).filter(
              (row) => row.type === _ctx.rowType.STAGING
              ).length > 0)
                    ? (_openBlock(), _createElementBlock("section", {
                        key: 2,
                        id: `stagings-${sector.fullToken}`
                      }, [
                        _createVNode(_component_Divider, { align: "left" }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('fieldPriceListCombinations.stagings')), 1),
                            _createElementVNode("div", _hoisted_10, [
                              _withDirectives(_createVNode(_component_Button, {
                                label: _ctx.$t('fieldPriceListCombinations.copyIn'),
                                class: "p-button-sm p-button-text",
                                onClick: ($event: any) => (_ctx.onCopyDialog(period, sector, _ctx.copyModes.STAGING))
                              }, null, 8, ["label", "onClick"]), [
                                [_directive_tooltip, _ctx.$t('fieldPriceListCombinations.copyInOtherSectors')]
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1024),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(sector.rows).filter(
                (row) => row.type === _ctx.rowType.STAGING
                ), (stagingRow) => {
                          return (_openBlock(), _createBlock(_component_FieldPriceConfigWizardRow, {
                            key: stagingRow.fullToken,
                            data: stagingRow,
                            errors: _ctx.parsedErrors,
                            showSwitch: "",
                            onFieldInput: _ctx.onFieldInput,
                            onSwitch: _ctx.onSwitch
                          }, null, 8, ["data", "errors", "onFieldInput", "onSwitch"]))
                        }), 128))
                      ], 8, _hoisted_8))
                    : _createCommentVNode("", true),
                  (!sector.collapsed && Object.values(sector.rows).filter(
              (row) => row.type === _ctx.rowType.COMBINATION
              ).length > 0)
                    ? (_openBlock(), _createElementBlock("section", {
                        key: 3,
                        id: `combinations-${sector.fullToken}`
                      }, [
                        _createVNode(_component_Divider, { align: "left" }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('fieldPriceListCombinations.combinations')), 1),
                            _createElementVNode("div", _hoisted_13, [
                              _withDirectives(_createVNode(_component_Button, {
                                label: _ctx.$t('fieldPriceListCombinations.copyIn'),
                                class: "p-button-sm p-button-text",
                                onClick: ($event: any) => (_ctx.onCopyDialog(period, sector, _ctx.copyModes.UMBRELLA_COMBINATION))
                              }, null, 8, ["label", "onClick"]), [
                                [_directive_tooltip, 
                    _ctx.$t('fieldPriceListCombinations.copyInOtherSectors')
                  ]
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1024),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(sector.rows).filter(
                  (row) =>
                    row.type === _ctx.rowType.COMBINATION
                ), (combinationRow) => {
                          return (_openBlock(), _createBlock(_component_FieldPriceConfigWizardExpandableRow, {
                            key: combinationRow.fullToken,
                            data: combinationRow,
                            errors: _ctx.parsedErrors,
                            onFieldInput: _ctx.onFieldInput,
                            onCollapse: _ctx.onCollapse,
                            onExpand: _ctx.onExpand,
                            onSwitch: _ctx.onSwitch
                          }, null, 8, ["data", "errors", "onFieldInput", "onCollapse", "onExpand", "onSwitch"]))
                        }), 128))
                      ], 8, _hoisted_11))
                    : _createCommentVNode("", true),
                  (!sector.collapsed && Object.values(sector.rows).filter(
              (row) => row.type === _ctx.rowType.CABIN_COMBINATION
              ).length > 0)
                    ? (_openBlock(), _createElementBlock("section", {
                        key: 4,
                        id: `cabin-combinations-${sector.fullToken}`
                      }, [
                        _createVNode(_component_Divider, { align: "left" }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t('fieldPriceListCombinations.cabinCombinations')), 1),
                            _createElementVNode("div", _hoisted_16, [
                              _withDirectives(_createVNode(_component_Button, {
                                label: _ctx.$t('fieldPriceListCombinations.copyIn'),
                                class: "p-button-sm p-button-text",
                                onClick: ($event: any) => (_ctx.onCopyDialog(period, sector, _ctx.copyModes.CABIN_COMBINATION))
                              }, null, 8, ["label", "onClick"]), [
                                [_directive_tooltip, _ctx.$t('fieldPriceListCombinations.copyInOtherSectors')]
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1024),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values(sector.rows).filter(
                  (row) =>
                    row.type === _ctx.rowType.CABIN_COMBINATION
                ), (cabinCombinationRow) => {
                          return (_openBlock(), _createBlock(_component_FieldPriceConfigWizardExpandableRow, {
                            key: cabinCombinationRow.fullToken,
                            data: (cabinCombinationRow as SchemaComboRow),
                            errors: _ctx.parsedErrors,
                            onFieldInput: _ctx.onFieldInput,
                            onCollapse: _ctx.onCollapse,
                            onExpand: _ctx.onExpand,
                            onSwitch: _ctx.onSwitch
                          }, null, 8, ["data", "errors", "onFieldInput", "onCollapse", "onExpand", "onSwitch"]))
                        }), 128))
                      ], 8, _hoisted_14))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1032, ["id", "header", "collapsed", "onToggle"]))
            }), 128))
          ], 8, _hoisted_2)), [
            [_vShow, !_ctx.isBuilding && _ctx.selectedPeriodToken === period.token]
          ])
        }), 128))
      : _createCommentVNode("", true),
    _createVNode(_component_Dialog, {
      visible: _ctx.copyDialog,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.copyDialog) = $event)),
      id: "copy-dialog",
      class: "dialog",
      modal: "",
      onHide: _cache[5] || (_cache[5] = ($event: any) => (_ctx.copyDialog = false))
    }, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(this.getDialogHeader()) + " " + _toDisplayString(_ctx.copyMode === _ctx.copyModes.SPOT
            ? _ctx.$t('fieldPriceListCombinations.copySpot')
            : _ctx.$t('fieldPriceListCombinations.copyStaging')), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button-outlined",
          label: _ctx.$t('button.cancel'),
          icon: "spi-times",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.copyDialog = false))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('fieldPriceListCombinations.copy'),
          icon: "pi pi-chevron-right",
          onClick: _ctx.onCopy
        }, null, 8, ["label", "onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_17, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.copyRowsOptions, (row, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "field-checkbox row-checkbox"
            }, [
              _createVNode(_component_Checkbox, {
                value: row.value,
                modelValue: _ctx.copyRows,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.copyRows) = $event))
              }, null, 8, ["value", "modelValue"]),
              _createElementVNode("label", null, _toDisplayString(row.label), 1)
            ]))
          }), 128))
        ]),
        _createVNode(_component_Listbox, {
          modelValue: _ctx.copySectors,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.copySectors) = $event)),
          options: _ctx.copyPeriodsOptions,
          optionLabel: "label",
          optionGroupLabel: "label",
          optionGroupChildren: "items",
          optionValue: "value",
          optionDisabled: _ctx.copySectorsOptionDisabled,
          placeholder: _ctx.$t('fieldPriceListCombinations.selectSectors'),
          filterPlaceholder: _ctx.$t('fieldPriceListCombinations.searchSector'),
          multiple: "",
          filter: ""
        }, {
          optiongroup: _withCtx((slotProps) => [
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("span", _hoisted_19, _toDisplayString(slotProps.option.label), 1),
              _createVNode(_component_Button, {
                class: "p-button-sm p-button-link",
                onClick: ($event: any) => (_ctx.onSelectAllPeriodSectors(slotProps.option)),
                label: _ctx.$t('common.selectAll')
              }, null, 8, ["onClick", "label"]),
              _createVNode(_component_Button, {
                class: "p-button-sm p-button-link",
                onClick: ($event: any) => (_ctx.onDeselectAllPeriodSectors(slotProps.option)),
                label: _ctx.$t('common.deselectAll')
              }, null, 8, ["onClick", "label"])
            ])
          ]),
          _: 1
        }, 8, ["modelValue", "options", "optionDisabled", "placeholder", "filterPlaceholder"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}