
import { defineComponent } from 'vue';
import { RouteLocationNormalized } from 'vue-router';
import { mapGetters, mapState } from 'vuex';
import { DateTime } from 'luxon';
import { AxiosError } from 'axios';
import ReservationList from '@/components/reservation/ReservationList.vue';
import ReservationPayment from '@/components/reservation/ReservationPayment.vue';
import ReservationExtraServices from '@/components/reservation/ReservationExtraServices.vue';
import ReservationExtraExpenses from '@/components/reservation/ReservationExtraExpenses.vue';
import ReservationCustomer from '@/components/reservation/ReservationCustomer.vue';
import ReservationJointAccountSummary from '@/components/reservation/ReservationJointAccountSummary.vue';
import ReservationConfiguration from '@/components/reservation/ReservationConfiguration.vue';
import ReservationAlerts from '@/components/reservation/ReservationAlerts.vue';
import ReservationHeaderPrintSummary from '@/components/reservation/header/ReservationHeaderPrintSummary.vue';
import { AppAction, AppProgressDialog } from '@/models/app';
import reservationService from '@/services/reservationService';
import {
  ApiLogCreatePayload,
  ApiReservationCreatePayload,
  ApiReservationDeletePayload,
} from '@/models/api';
import { ReservationDeleteMode } from '@/models/reservation';
import { RefundType } from '@/models/refund';
import logService from '@/services/logService';
import { LogAction } from '@/models/log';
import ReservationTickets from '@/components/reservation/ReservationTickets.vue';
import ReservationParkingService from '@/components/reservation/services/ReservationParkingService.vue';
import ReservationCabinService from '@/components/reservation/services/ReservationCabinService.vue';
import ReservationAdditionService from '@/components/reservation/services/ReservationAdditionService.vue';

export default defineComponent({
  name: 'ReservationView',
  components: {
    ReservationAdditionService,
    ReservationCabinService,
    ReservationParkingService,
    ReservationTickets,
    ReservationList,
    ReservationPayment,
    ReservationExtraServices,
    ReservationCustomer,
    ReservationConfiguration,
    ReservationExtraExpenses,
    ReservationJointAccountSummary,
    ReservationAlerts,
    ReservationHeaderPrintSummary,
  },
  data() {
    return {
      exitDialog: false,
      to: null as unknown as RouteLocationNormalized,
      create: false,
    };
  },
  async beforeMount() {
    if (this.$route.params.id) {
      if (this.action === AppAction.NONE) {
        this.$store.commit('app/setAction', AppAction.VIEW_RESERVATION);
      }
      try {
        await this.$store.dispatch(
          'reservation/getReservation',
          this.$route.params.id,
        );
        /* non blocking dispatches */
        const dispatches: any[] = [
          this.$store.dispatch('reservation/getGuests'),
          this.$store.dispatch('reservation/getCashFlows'),
          this.$store.dispatch('reservation/getServices'),
          this.$store.dispatch('reservation/getCards'),
          this.$store.dispatch('reservation/getDiscountCode'),
        ];
        // Avoid listing for bill and joint accounts
        if (!this.isJointAccount && !this.isBillReservation) {
          dispatches.push(this.$store.dispatch('reservation/getList'));
        }
        await Promise.all(dispatches);
        // this.$store.dispatch('reservation/getExpenses'); MAYBE MOVE EXPENSES ON SEPARATE ROUTE
        // if (this.action !== AppAction.CREATE_RESERVATION) {
        //   this.$store.commit('app/setAction', AppAction.VIEW_RESERVATION);
        // }
      } catch (e) {
        // TODO toast with specific error case (ex error on extra services, error on expenses ecc)
      }
    } else if (this.$store.getters['map/selected'].length === 0) {
      this.$store.commit('app/setAction', AppAction.NONE);
      this.$store.commit('reservation/setCanExit', true);
      this.$router.push('/map');
    }
  },
  methods: {
    handleExit(to: RouteLocationNormalized | null, create: boolean): boolean {
      // fiscal print is running
      if (this.fiscalPrint) {
        // set progress dialog
        this.$store.commit('app/setProgressDialog', {
          title: this.$t('reservationView.printingProgressDialog.title'),
          content: this.$t('reservationView.printingProgressDialog.content'),
          actions: [
            {
              label: this.$t('reservationView.action.exitWithTaxation'),
              classes: ['p-button-danger', 'p-button-outlined'],
              icon: '',
              callback: () => {
                this.fiscalPrintExit(to as RouteLocationNormalized, true);
              },
            },
            {
              label: this.$t('reservationView.action.exitWithoutTaxation'),
              classes: ['p-button-danger', 'p-button-outlined'],
              icon: '',
              callback: () => {
                this.fiscalPrintExit(to as RouteLocationNormalized);
              },
            },
            {
              label: this.$t('reservationView.action.wait'),
              classes: [],
              icon: '',
              callback: () => {
                this.$store.commit('app/setProgressDialog', null);
              },
            },
          ],
        } as AppProgressDialog);
        return false;
      }
      this.create = create;
      if (
        this.canExit ||
        [AppAction.VIEW_RESERVATION, AppAction.EDIT_RESERVATION].includes(
          this.action,
        )
      ) {
        if (!this.create) {
          this.$store.commit('reservation/reset');
        } else {
          this.createReservation();
        }
        return true;
      }
      this.to = to as unknown as RouteLocationNormalized;
      this.exitDialog = true;
      return false;
    },
    async onExitWithoutSave(): Promise<void> {
      if (this.action === AppAction.CREATE_RESERVATION) {
        await this.$store.dispatch('reservation/deleteReservation', {
          id: this.id,
          refundType: RefundType.NONE,
          mode: ReservationDeleteMode.DEL,
          creating: true,
        } as ApiReservationDeletePayload);
      }
      if (this.create) {
        this.createReservation();
      } else {
        this.$store.commit('reservation/setCanExit', true);
        this.$store.commit('app/setAction', AppAction.NONE);
        this.$store.commit('reservation/setPropagate', []);

        if (this.to) {
          // check if we are going to the map in "displacement mode"
          // if so, just redirect to the map because
          // the reservation has been deleted
          if (
            this.to.path === '/map' &&
            this.to.query.mode === '4' &&
            this.to.query.reservationId === `${this.id}`
          ) {
            this.$router.push('/map');
          } else {
            this.$router.push(this.to);
          }
        } else {
          this.$router.push(this.$store.getters['app/returnToRoute']);
        }
      }
    },
    onSaveAndExit(): void {
      // maybe patch all?
      if (this.create) {
        this.createReservation();
      } else {
        this.$store.commit('reservation/setCanExit', true);
        this.$store.commit('app/setAction', AppAction.NONE);
        this.$store.commit('reservation/setPropagate', []);
        this.$router.push(this.to ?? this.$store.getters['app/returnToRoute']);
      }
    },
    async onNewReservation(): Promise<void> {
      this.handleExit(null, true);
    },
    async createReservation(): Promise<void> {
      try {
        const res = await reservationService.createOne({
          spotName: this.spot.name,
          spotType: this.spot.type,
          startDate: DateTime.now().startOf('day').toSeconds(),
          endDate: DateTime.now().startOf('day').toSeconds(),
        } as ApiReservationCreatePayload);
        this.$store.commit('reservation/setCanExit', true);
        this.$store.commit('reservation/setSpot', this.spot);
        this.$store.commit('app/setAction', AppAction.CREATE_RESERVATION);
        this.$router.push(`/reservation/${res.id}`);
      } catch (error) {
        const errorCode: string = (error as AxiosError)?.response?.data.error;
        if (errorCode === 'i_r_001') {
          this.$spiagge.toast.error(this.$t('errors.i_r_001'));
          this.exitDialog = false;
        }
      }
    },
    async fiscalPrintExit(
      to: RouteLocationNormalized,
      forceFiscal = false,
    ): Promise<void> {
      /**
       * Log the action, force the exit and close the progress dialog
       */
      try {
        await logService.create({
          reservationId: this.id,
          action: forceFiscal
            ? LogAction.FISCAL_PRINTER_INTERRUPED_WITH_FISCAL
            : LogAction.FISCAL_PRINTER_INTERRUPED_WITHOUT_FISCAL,
          value: '',
        } as ApiLogCreatePayload);

        if (forceFiscal) {
          await this.fiscalPrint.callback(
            null,
            this.fiscalPrint.print,
            this.fiscalPrint.printer,
            this.fiscalPrint.mode,
          );
        }
        this.$store.dispatch('reservation/fiscalPrintEnd');
        this.$router.push(to);
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationView.toast.operationError'),
        );
      }
    },
  },
  computed: {
    ...mapState('reservation', [
      'id',
      'spot',
      'canExit',
      'edit',
      'fiscalPrint',
    ]),
    ...mapGetters('reservation', [
      'isJointAccount',
      'isBillReservation',
      'hasOrderWithPaymentLink',
    ]),
    ...mapState('app', ['action']),
  },
  beforeRouteLeave(to): boolean {
    return this.handleExit(to, false);
  },
  beforeRouteUpdate(to): boolean {
    return this.handleExit(to, false);
  },
});
