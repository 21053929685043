import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_openBlock(), _createBlock(_component_Calendar, {
    modelValue: _ctx.model,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
    showIcon: true,
    dateFormat: _ctx.dateFormat,
    disabledDates: _ctx.disabledDates?.map((d) => d.toJSDate()),
    minDate: _ctx.minDate?.toJSDate(),
    maxDate: _ctx.maxDate?.toJSDate(),
    onMonthChange: _ctx.onMonthChange,
    onYearChange: _ctx.onYearChange,
    onShow: _ctx.onShow,
    onDateSelect: _ctx.onDateSelect
  }, null, 8, ["modelValue", "dateFormat", "disabledDates", "minDate", "maxDate", "onMonthChange", "onYearChange", "onShow", "onDateSelect"]))
}