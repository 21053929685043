
import { defineComponent, PropType } from 'vue';
import _ from 'lodash';

import { Field } from '@/models/field';
import FieldBinder from '@/components/dynamic-form/FieldBinder.vue';
import {
  PeriodConfiguration,
  PriceConfigPeriodData,
  PriceConfigTab,
  PriceWizardStep,
} from '@/models/priceConfig';
import priceWizardUtil from '@/utils/priceWizardUtil';

interface CopyPeriodConfigOption {
  label: string;
  value: number;
}

export default defineComponent({
  name: 'DynamicForm',
  emits: ['update:modelValue'],
  components: {
    FieldBinder,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array as PropType<Array<Field>>,
      required: true,
    },
    types: {
      type: Object as PropType<{ [key: string]: string }>,
      required: true,
    },
    errors: {
      type: Object as PropType<{ [key: string]: string }>,
      required: false,
    },
    data: {
      type: Object as PropType<{ [key: string]: any }>,
      required: true,
    },
    step: {
      type: String as PropType<PriceWizardStep>,
      required: true,
      default: () => PriceWizardStep.BASIC,
    },
  },
  data() {
    return {
      // Model section
      selectedTab: 0,
      copyPeriodConfigDialog: false,
      copyPeriodConfigId: 0,
      copyPeriodSettings: [] as number[],
      copyPeriodOptions: [] as CopyPeriodConfigOption[],
      priceCopy: true,
      halfDayCopy: true,
      weekendCopy: true,
    };
  },
  methods: {
    getTabFields(): Array<Field> {
      if (!this.hasTabs) return this.fields;
      // Steps with tabs allow only one field
      const field: Field | undefined = this.fields.slice(0).pop();
      // This should never happen
      if (!field) {
        return [];
      }
      let res: Array<Field> = [];
      (field.o as Array<Field>).forEach((f: Field) => {
        if (f.n === String(this.selectedTab)) {
          res = f.o as Array<Field>;
        }
      });
      return res;
    },
    onPeriodConfigCopy(id: number): void {
      if (this.copyPeriodOptions.length === 0) {
        this.initCopyDialog();
      }
      this.copyPeriodConfigId = id;
      this.copyPeriodConfigDialog = true;
    },
    onPeriodCopyConfirm(): void {
      // Check the selected boxes and filter the related fields
      const configToCopy = this.data.periodsConfigurations.find(
          (p: PeriodConfiguration) => p.periodId === this.copyPeriodConfigId,
      );
      // Skip if the id is not there (shouldn't really happen)
      if (!configToCopy) {
        this.copyPeriodConfigDialog = false;
        return;
      }
      // Keys to assign
      const assignKeys: string[] = [];
      // Weekend settings
      if (this.weekendCopy) {
        assignKeys.push('useWeekend', 'weekendDays', 'holidays');
      }
      // Price method copy
      if (this.priceCopy) {
        assignKeys.push(
          'priceMode',
          'discountType',
          'thresholdCalculationMode',
          'thresholdPercentageValues',
          'extraDaysMode',
          'nextDayMode',
          'dailyValuesCalculationMode',
        );
      }
      // Half day settings
      if (this.halfDayCopy) {
        assignKeys.push('useHalfDay');
      }
      // Pick only the keys selected in the modal
      const partialConfig = _.pick(configToCopy, assignKeys);
      this.copyPeriodSettings.forEach((periodId: number) => {
        const targetPeriodConfig = this.data.periodsConfigurations.find(
          (p: PeriodConfiguration) => p.periodId === periodId,
        );
        if (!targetPeriodConfig) {
          this.copyPeriodConfigDialog = false;
          return;
        }
        Object.assign(
          targetPeriodConfig,
          partialConfig,
        );
      });
      this.$emit('update:modelValue', this.data);
      this.copyPeriodConfigDialog = false;
    },
    initCopyDialog(): void {
      this.data.periodsConfigurations.forEach((p: PeriodConfiguration) => {
        this.copyPeriodOptions.push({
          label: p.label,
          value: p.periodId,
        });
      });
    },
    copyPeriodOptionDisabled(option: CopyPeriodConfigOption): boolean {
      return option.value === this.copyPeriodConfigId;
    },
    changeTab(activeIndex: number): void {
      this.selectedTab = activeIndex;
    },
  },
  computed: {
    model: {
      get(): any {
        // If there are no tabs we just get the standard model
        if (!this.hasTabs) {
          return this.modelValue;
        }
        // Get the main field that's generating the tabs and exit if we don't find it
        // We assume that these kind of fields are the only ones in the form
        const field: Field | undefined = this.fields.slice(0).pop();
        if (!field) return {};
        // Everything is fine, fetch data at the current index
        return this.modelValue[field.n][this.selectedTab] as { [key: string]: any };
      },
      set(data: any) {
        // this.$emit('update:modelValue', data);
      },
    },
    tabs(): Array<PriceConfigTab> {
      // Generic tab - step 2 has periods
      const isStepWithTabs: boolean = priceWizardUtil.hasTabs(this.step);
      if (!isStepWithTabs || this.fields.length === 0) {
        return [];
      }
      // Steps with tabs allow only one field
      const field: Field | undefined = this.fields.slice(0).pop();
      // This should never happen
      if (!field) {
        return [];
      }
      // Somewhat generic as long as the options have an id and label
      let tabIndex = 0;
      const key: string = field.n;
      const res: Array<PriceConfigTab> = [];
      (field.o as Array<Field>).forEach(
        (f: Field) => {
          let id = 0;
          let label = '';
          (f.o as Array<Field>).forEach(
            (x: Field) => {
              if (x.n === 'periodId') id = x.dv as number;
              if (x.n === 'label') label = x.dv as string;
            },
          );
          res.push({ id, key, label, tabIndex } as PriceConfigTab);
          tabIndex += 1;
        },
      );
      return res;
    },
    hasTabs(): boolean {
      return (this.tabs?.length ?? 0) > 0;
    },
    hasMoreThanOneTab(): boolean {
      return (this.tabs?.length ?? 0) > 1;
    },
  },
});
