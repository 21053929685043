
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
import { cloneDeep, range } from 'lodash';
import { PriceCombination, PriceList, PricePeriod } from '@/models/priceList';
import priceListService from '@/services/priceListService';
import ListUploadDialog from '@/components/priceList/ListUploadDialog.vue';
import ToggleButtonCustom from '@/components/shared/ToggleButtonCustom.vue';
import { ToastSeverity } from '@/models/toast';
import toastUtil from '@/utils/toastUtil';
import InputTooltip from '@/components/shared/InputTooltip.vue';
import { Sector, SectorHeader } from '@/models/sector';

interface Rule {
  index: number;
  date: Date;
}

enum ListPage {
  WEEK = 'week',
  WEEKEND = 'weekend',
}

// const GRID_CELL_WIDTH = 95;
// const GRID_CELL_HEIGHT = 20;

export default defineComponent({
  name: 'PricePeriodDetails',
  emits: ['save'],
  components: {
    ListUploadDialog,
    ToggleButtonCustom,
    InputTooltip,
  },
  props: {
    priceList: {
      type: Object as () => PriceList,
      default: null as PriceList | null,
    },
    period: {
      type: Object as () => PricePeriod,
      default: null as PricePeriod | null,
    },
  },
  data() {
    return {
      pricePeriod: cloneDeep(this.period),
      mappedSectors: new Map<number, SectorHeader>(),
      priceCombinations: [] as Array<PriceCombination>,
      weekendEdited: false,
      listPage: ListPage.WEEK,
      daysOfWeek: ['1', '2', '3', '4', '5', '6', '7'],
      legendLabels: [
        { short: 'M', long: this.$t('pricePeriodDetail.legendLabel.maxibed') },
        { short: 'B', long: this.$t('pricePeriodDetail.legendLabel.bed') },
        {
          short: 'D',
          long: this.$t('pricePeriodDetail.legendLabel.deckchair'),
        },
        { short: 'C', long: this.$t('pricePeriodDetail.legendLabel.chair') },
        { short: 'W', long: this.$t('pricePeriodDetail.legendLabel.cabin') },
        {
          short: 'E',
          long: this.$t('pricePeriodDetail.legendLabel.extraService'),
        },
      ],
      showUploadDialog: false,
      sectorBgColors: [
        '#A5C4FF',
        '#A5F4FF',
        '#A8E6CF',
        '#C2A5FF',
        '#CAB4A0',
        '#D7FFA5',
        '#FFA5EB',
        '#FFAAA5',
        '#FFD0A5',
        '#FFEFB6',
      ],
    };
  },
  computed: {
    ...mapState('app', ['windowWidth', 'windowHeight']),
    ...mapState('session', ['sectors']),
    minDate(): DateTime {
      return DateTime.fromFormat(this.pricePeriod.startDate, 'dd-MM');
    },
    maxDate(): DateTime {
      return DateTime.fromFormat(this.pricePeriod.endDate, 'dd-MM');
    },
    holidayRules(): Array<Rule> {
      const rules = [];
      for (let i = 0; i < this.pricePeriod.weekendRules.length; i += 1) {
        const rule = this.pricePeriod.weekendRules[i];
        if (rule.substring(0, 2) === 'd-') {
          rules.push({
            index: i,
            date: DateTime.fromFormat(rule.substring(2), 'dd-MM').toJSDate(),
          });
        }
      }
      return rules.sort((a, b) => a.date.getTime() - b.date.getTime());
    },
    gridHeight(): string {
      return `${
        this.windowHeight - 365
      }px`;
    },
    isWeekendSelected(): boolean {
      return this.listPage === ListPage.WEEKEND;
    },
    numDays(): number {
      if (!this.priceCombinations) return 0;
      let days = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const c of this.priceCombinations) {
        days = Math.max(days, Object.keys(c.value.days).length);
      }
      return days;
    },
    hasListing(): boolean {
      return this.priceCombinations.length > 0;
    },
  },
  methods: {
    onWeekendToggle(newValue: boolean): void {
      const previous = this.pricePeriod.useWeekend;
      if (!newValue && previous) {
        // Ask for confirmation
        this.showWeekendDisableConfirmDialog();
        return;
      }
      if (newValue && !previous) {
        // I'm setting weekend for the first time
        this.weekendEdited = true;
      }
      this.pricePeriod.useWeekend = newValue;
      this.save();
    },
    showWeekendDisableConfirmDialog(): void {
      this.$confirm.require({
        message: this.$t('pricePeriodDetail.confirm.disableMessage'),
        header: this.$t('pricePeriodDetail.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('common.yes'),
        accept: () => {
          this.pricePeriod.useWeekend = false;
          this.save();
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          // nothing
        },
      });
    },
    getDayName(d: string): string {
      return DateTime.fromFormat(d, 'c').toFormat('ccc');
    },
    onHolidayRuleSelect(newDate: Date, index: number): void {
      const dateString = `${newDate.getDate()}-${
        newDate.getMonth() + 1
      }-${newDate.getFullYear()}`;
      this.pricePeriod.weekendRules[index] = `d-${DateTime.fromFormat(
        dateString,
        'd-M-yyyy',
      ).toFormat('dd-MM')}`;
    },
    addHoliday(): void {
      const last = this.holidayRules[this.holidayRules.length - 1] ?? null;
      const dateTime = last
        ? DateTime.fromFormat(
            `${last.date.getDate()}-${
              last.date.getMonth() + 1
            }-${last.date.getFullYear()}`,
            'd-M-yyyy',
          ).plus({ days: 1 })
        : DateTime.now();
      this.pricePeriod.weekendRules.push(`d-${dateTime.toFormat('dd-MM')}`);
      this.$nextTick(() => {
        // Extreme hack - do not try this at home
        const calendar = this.$refs[
          `holiday-calendar-${this.pricePeriod.weekendRules.length - 1}`
        ] as any;
        if (calendar) {
          (calendar.$el as HTMLElement)
            .getElementsByTagName('input')[0]
            .focus();
        }
      });
    },
    deleteHoliday(index: number): void {
      this.pricePeriod.weekendRules.splice(index, 1);
    },
    showWeekPage(): void {
      this.listPage = ListPage.WEEK;
    },
    showWeekendPage(): void {
      this.listPage = ListPage.WEEKEND;
    },
    getListDaysString(): string {
      const strings = [] as Array<string>;
      if (!this.pricePeriod.useWeekend) {
        // Print all week days
        // eslint-disable-next-line no-restricted-syntax
        for (const d of range(1, 8)) {
          strings.push(DateTime.fromFormat(`${d}`, 'c').toFormat('cccc'));
        }
      } else {
        // get weekend rules
        const weekendDays = this.pricePeriod.weekendRules
          .filter((r) => r.substring(0, 2) === 'w-')
          .map((r) => Number.parseInt(r.substring(2), 10));
        // eslint-disable-next-line no-confusing-arrow
        const days = range(1, 8).filter((d) =>
          this.listPage === ListPage.WEEK
            ? !weekendDays.includes(d)
            : weekendDays.includes(d),
        );
        // eslint-disable-next-line no-restricted-syntax
        for (const d of days) {
          strings.push(DateTime.fromFormat(`${d}`, 'c').toFormat('cccc'));
        }
        if (this.listPage === ListPage.WEEKEND) {
          const holidays = this.pricePeriod.weekendRules
            .filter((r) => r.substring(0, 2) === 'd-')
            .map((r) => r.substring(2));
          // eslint-disable-next-line no-restricted-syntax
          for (const d of holidays) {
            strings.push(DateTime.fromFormat(d, 'dd-MM').toFormat('D'));
          }
        }
      }
      return this.$t('pricePeriodDetail.listDays', {
        days: strings.join(', '),
      });
    },
    getCombinationLabel(combination: PriceCombination): string {
      const stringParts = [] as Array<string>;
      if (combination.numUmbrellas) {
        stringParts.push(`${combination.numUmbrellas}U`);
      }
      if (combination.numMaxibeds) {
        stringParts.push(`${combination.numMaxibeds}M`);
      }
      if (combination.numBeds) stringParts.push(`${combination.numBeds}B`);
      if (combination.numDeckchairs) {
        stringParts.push(`${combination.numDeckchairs}D`);
      }
      if (combination.numChairs) stringParts.push(`${combination.numChairs}C`);
      if (combination.numCabins) stringParts.push(`${combination.numCabins}W`);
      if (combination.numExtras) stringParts.push(`${combination.numExtras}E`);
      return stringParts.join(' ');
    },
    save(): void {
      this.$emit('save', { period: this.pricePeriod });
    },
    onListUploadSuccess(priceCombinations: Array<PriceCombination>): void {
      this.priceCombinations = priceCombinations;
      this.showUploadDialog = false;
    },
    async onExportClick(): Promise<void> {
      try {
        const response = await priceListService.exportPeriod(
          this.pricePeriod.id,
          { weekend: this.isWeekendSelected ? 1 : 0 },
        );
        const blob = new Blob([response]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.priceList.name.replace(' ', '-')}-periodo-${
          this.pricePeriod.name
        }${this.isWeekendSelected ? '-weekend' : ''}.csv`;
        link.click();
      } catch (e) {
        // console.log(e);
      }
    },
    onDeleteClick(): void {
      this.$confirm.require({
        message: this.$t('pricePeriodDetail.confirm.deleteMessage'),
        header: this.$t('pricePeriodDetail.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('common.yes'),
        accept: () => {
          this.onDelete();
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          // nothing
        },
      });
    },
    async onDelete(): Promise<void> {
      try {
        await priceListService.deleteCombinations(
          this.pricePeriod.id,
          this.isWeekendSelected ? 1 : 0,
        );
        // Upload listing
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
        this.priceCombinations = [];
      } catch (e) {
        // console.log(e);
      }
    },
    async loadCombinations(): Promise<void> {
      try {
        // Get price combinations
        const response = await priceListService.getPeriodCombinations({
          pricePeriodId: this.pricePeriod.id,
          weekend: this.isWeekendSelected ? 1 : 0,
        });
        this.priceCombinations = response.result.priceCombinations;
      } catch (e) {
        // console.log(e);
        this.$spiagge.toast.error(this.$t('pricePeriodDetail.toast.error'));
      }
    },
    loadSectors(): void {
      this.sectors.forEach((s: Sector) => {
        this.mappedSectors.set(s.header.id, s.header);
      });
    },
  },
  watch: {
    period(): void {
      this.pricePeriod = cloneDeep(this.period);
      if (this.listPage === ListPage.WEEK) {
        this.loadCombinations();
      } else {
        this.listPage = ListPage.WEEK;
      }
    },
    'pricePeriod.weekendRules': function (): void {
      this.weekendEdited = true;
    },
    async listPage(): Promise<void> {
      await this.loadCombinations();
    },
  },
  async beforeMount() {
    this.loadSectors();
    await this.loadCombinations();
  },
});
