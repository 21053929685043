import i18n from '@/i18n';
import { Timestamp } from '.';
import { Field } from './field';
import { User } from './user';

export const PRICE_CONFIG_LOCALSTORAGE_NAME = 'priceConfig';
export const PRICE_CONFIG_LOCALSTORAGE_ITEM_SESSION = 'session';

export type PriceConfigDiscountType = Array<number>;

export enum PriceWizardStep {
  BASIC = 'basic',
  PERIODS = 'period',
  PRICES = 'price',
}

export interface Step {
  value: PriceWizardStep; // basic, period, price, review
  label: string;
  number: number;
  action: () => void;
}

export enum PriceConfigListingMode {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

/* EXTRA DAYS MODE */

export enum PriceConfigExtraDaysMode {
  STANDARD,
  REPEAT,
  MONTHLY,
}

export interface PriceConfigExtraDaysModeData {
  mode: PriceConfigExtraDaysMode;
  label: string;
}

export const PRICE_CONFIG_EXTRA_DAYS_MODE_DATA: Array<PriceConfigExtraDaysModeData> =
  [
    {
      mode: PriceConfigExtraDaysMode.STANDARD,
      label: i18n.global.t('priceConfig.extraDaysMode.standard'),
    },
    {
      mode: PriceConfigExtraDaysMode.REPEAT,
      label: i18n.global.t('priceConfig.extraDaysMode.repeat'),
    },
    {
      mode: PriceConfigExtraDaysMode.MONTHLY,
      label: i18n.global.t('priceConfig.extraDaysMode.monthly'),
    },
  ];

/* PERIODS SHIFT MODE */

export enum PriceConfigPeriodsShiftMode {
  SIMPLE,
  AVERAGE,
  START_OVER,
  SIMPLE_REVERSED,
}

export interface PriceConfigPeriodsShiftModeData {
  mode: PriceConfigPeriodsShiftMode;
  label: string;
}

export const PRICE_CONFIG_PERIODS_SHIFT_MODE_DATA: Array<PriceConfigPeriodsShiftModeData> =
  [
    {
      mode: PriceConfigPeriodsShiftMode.SIMPLE,
      label: i18n.global.t('priceConfig.periodsShiftMode.simple'),
    },
    {
      mode: PriceConfigPeriodsShiftMode.AVERAGE,
      label: i18n.global.t('priceConfig.periodsShiftMode.average'),
    },
    {
      mode: PriceConfigPeriodsShiftMode.START_OVER,
      label: i18n.global.t('priceConfig.periodsShiftMode.startOver'),
    },
    {
      mode: PriceConfigPeriodsShiftMode.SIMPLE_REVERSED,
      label: i18n.global.t('priceConfig.periodsShiftMode.simpleReversed'),
    },
  ];

/* NEXT DAY MODE */

export enum PriceConfigNextDayMode {
  CEIL = 'ceil',
  EXCEED = 'exceed',
}

export interface PriceConfigNextDayModeData {
  mode: PriceConfigNextDayMode;
  label: string;
}

export const PRICE_CONFIG_NEXT_DAY_MODE_DATA: Array<PriceConfigNextDayModeData> =
  [
    {
      mode: PriceConfigNextDayMode.CEIL,
      label: i18n.global.t('priceConfig.nextDayMode.ceil'),
    },
    {
      mode: PriceConfigNextDayMode.EXCEED,
      label: i18n.global.t('priceConfig.nextDayMode.exceed'),
    },
  ];

/* DAILY VALUES CALCULATION MODE */

export enum PriceConfigDailyValuesCalculationMode {
  DAILY_VALUE = 'daily-value',
  RECURSIVE = 'recursive',
  AVERAGE = 'average',
  ADDED_AVERAGE = 'added-average',
}

export interface PriceConfigDailyValuesCalculationModeData {
  mode: PriceConfigDailyValuesCalculationMode;
  label: string;
}

export const PRICE_CONFIG_DAILY_VALUES_CALCULATION_MODE_DATA = [
  {
    mode: PriceConfigDailyValuesCalculationMode.DAILY_VALUE,
    label: i18n.global.t('priceConfig.dailyValuesCalculationMode.dailyValue'),
  },
  {
    mode: PriceConfigDailyValuesCalculationMode.RECURSIVE,
    label: i18n.global.t('priceConfig.dailyValuesCalculationMode.recursive'),
  },
  {
    mode: PriceConfigDailyValuesCalculationMode.AVERAGE,
    label: i18n.global.t('priceConfig.dailyValuesCalculationMode.average'),
  },
  {
    mode: PriceConfigDailyValuesCalculationMode.ADDED_AVERAGE,
    label: i18n.global.t('priceConfig.dailyValuesCalculationMode.addedAverage'),
  },
] as Array<PriceConfigDailyValuesCalculationModeData>;

/* STATUS */

export enum PriceConfigStatus {
  ACTIVE = 'active',
  DRAFT = 'draft',
  READY = 'ready',
  DISABLED = 'disabled',
}

/* THRESHOLD CALCULATION MODE */

export enum PriceConfigThresholdCalculationMode {
  NUMERIC = 'numeric',
  PERCENTAGE = 'percentage',
}

export interface PriceConfigThresholdCalculationModeData {
  mode: PriceConfigThresholdCalculationMode;
  label: string;
}

export const PRICE_CONFIG_THRESHOLD_CALCULATION_MODE_DATA = [
  {
    mode: PriceConfigThresholdCalculationMode.NUMERIC,
    label: i18n.global.t('priceConfig.thresholdCalculationMode.numeric'),
  },
  {
    mode: PriceConfigThresholdCalculationMode.PERCENTAGE,
    label: i18n.global.t('priceConfig.thresholdCalculationMode.percentage'),
  },
] as Array<PriceConfigThresholdCalculationModeData>;

export interface PriceConfigThresholdValue {
  threshold: number;
  value: number;
}

/* PRICE MODE */

export enum PriceConfigPriceMode {
  DAILY = 'daily',
  SEASONAL = 'seasonal',
  DISCOUNT = 'discount',
  CUSTOM = 'custom',
}

export interface PriceConfigPriceModeData {
  priceMode: PriceConfigPriceMode;
  label: string;
}

export const PRICE_LIST_WIZARD_PRICE_MODE_DATA: Array<PriceConfigPriceModeData> =
  [
    {
      priceMode: PriceConfigPriceMode.DAILY,
      label: i18n.global.t('priceConfig.priceMode.daily'),
    },
    {
      priceMode: PriceConfigPriceMode.SEASONAL,
      label: i18n.global.t('priceConfig.priceMode.seasonal'),
    },
    {
      priceMode: PriceConfigPriceMode.DISCOUNT,
      label: i18n.global.t('priceConfig.priceMode.discount'),
    },
    {
      priceMode: PriceConfigPriceMode.CUSTOM,
      label: i18n.global.t('priceConfig.priceMode.custom'),
    },
  ];

export interface PriceConfigPeriodDataInterval {
  startDate: string;
  endDate: string;
}

export interface PriceConfigPeriodData {
  id: number;
  name: string;
  intervals: Array<PriceConfigPeriodDataInterval>;
}

export type PriceConfigCombinations = { [key: string]: number | null }; // P1_S1_U1: 5 | null;

export interface SectorCombinationPieces {
  id: number;
  combinationPieces: number;
}

export interface PeriodConfiguration {
  [key: string]: any;
  periodId: number;
  label: string;
  priceMode: PriceConfigPriceMode;
  useWeekend: boolean;
  useHalfDay: boolean;
  weekendDays: Array<string>;
  thresholdCalculationMode: PriceConfigThresholdCalculationMode;
  thresholdPercentageValues: Array<PriceConfigThresholdValue>;
  dailyValuesCalculationMode: PriceConfigDailyValuesCalculationMode | null;
  nextDayMode: PriceConfigNextDayMode | null;
  discountType: PriceConfigDiscountType | null;
  extraDaysMode: PriceConfigExtraDaysMode | null;
  holidays: Array<string>;
}

export interface PriceConfig {
  [key: string]: any;
  sectorCombinationPieces: Array<SectorCombinationPieces>;
  combinationPieces: number;
  createdAt: Timestamp;
  id: number;
  listingMode: PriceConfigListingMode;
  lockUser: User;
  lockUserId: number;
  name: string;
  year: number;
  periodsData: Array<PriceConfigPeriodData>;
  periodsConfigurations: Array<PeriodConfiguration>;
  periodsShiftMode: PriceConfigPeriodsShiftMode | null;
  priceCombinations: PriceConfigCombinations | null;
  priceListId: number | null;
  status: PriceConfigStatus;
  step: PriceWizardStep;
  updatedAt: Timestamp;
  useHalfDay: boolean;
  useCabinCombination: boolean;
  useSingleComboPiecesLimit: boolean;
  priceMode: PriceConfigPriceMode;
  useWeekend: boolean;
  weekendDays: Array<string>;
  thresholdCalculationMode: PriceConfigThresholdCalculationMode;
  thresholdPercentageValues: Array<PriceConfigThresholdValue>;
  dailyValuesCalculationMode: PriceConfigDailyValuesCalculationMode | null;
  nextDayMode: PriceConfigNextDayMode | null;
  discountType: PriceConfigDiscountType | null;
  extraDaysMode: PriceConfigExtraDaysMode | null;
  holidays: Array<string>;
}

export interface PriceConfigWizard {
  [key: string]: string | Array<Field> | Array<PriceWizardStep>;
  currentStep: PriceWizardStep; // basic, periods and price
  steps: Array<PriceWizardStep>;
  basicStep: Array<Field>;
  periodStep: Array<Field>;
  priceStep: Array<Field>;
}

export interface PriceConfigModel {
  [key: string]: any;
  sectorCombinationPieces: Array<SectorCombinationPieces>;
  combinationPieces: number;
  dataValues: null;
  discountType: PriceConfigDiscountType;
  extraDaysMode: PriceConfigExtraDaysMode | null;
  holidays: Array<string>;
  listingMode: PriceConfigListingMode;
  name: string;
  dailyValuesCalculationMode: PriceConfigDailyValuesCalculationMode | null;
  nextDayMode: PriceConfigNextDayMode | null;
  periodsData: Array<PriceConfigPeriodData>;
  periodsConfigurations: Array<PeriodConfiguration>;
  periodsShiftMode: PriceConfigPeriodsShiftMode | null;
  priceListId: number | null;
  priceMode: PriceConfigPriceMode;
  priceCombinations: PriceConfigCombinations | null;
  useHalfDay: boolean;
  useCabinCombination: boolean;
  useSingleComboPiecesLimit: boolean;
  useWeekend: boolean;
  weekendDays: Array<string>;
  thresholdCalculationMode: PriceConfigThresholdCalculationMode;
  thresholdPercentageValues: Array<PriceConfigThresholdValue>;
}

// localstorage session stored
export interface PriceConfigSession extends PriceConfigModel {
  id: number | undefined;
  model: PriceConfigModel;
}

export interface PriceConfigWizardSchemaField {
  label: string;
  token: string;
  fullToken: string;
  value: number | null;
  placeholder: number | null;
}

export type PriceConfigWizardSchemaFields = {
  [key: string]: PriceConfigWizardSchemaField;
};

export enum PriceConfigWizardSchemaRowStatus {
  EMPTY = 'empty',
  FILLED = 'filled',
  FULFILLED = 'fulfilled',
  DISABLED = 'disabled',
  HIDDEN = 'hidden',
}

export enum PriceConfigWizardSchemaRowType {
  EXTRA = 'extra',
  SPOT = 'spot',
  STAGING = 'staging',
  COMBINATION = 'combination',
  CABIN_COMBINATION = 'cabin-combination',
}

export enum PriceConfigWizardSchemaRowActionValue {
  UMBRELLA_TO_COMBINATIONS = 'umbrella-to-combinations',
  CABIN_TO_COMBINATIONS = 'cabin-to-combinations',
}

export interface PriceConfigWizardSchemaRowAction {
  icon: string;
  label: string;
  value: PriceConfigWizardSchemaRowActionValue;
}

export interface PriceConfigWizardSchemaRow {
  label: string;
  token: string;
  fullToken: string;
  fields: PriceConfigWizardSchemaFields;
  status: PriceConfigWizardSchemaRowStatus;
  type: PriceConfigWizardSchemaRowType;
  actions: Array<PriceConfigWizardSchemaRowAction>;
}

export type PriceConfigWizardSchemaRows = {
  [key: string]: PriceConfigWizardSchemaRow;
};

export interface PriceConfigWizardSchemaCombinationsRow
  extends PriceConfigWizardSchemaRow {
  expanded: boolean;
  pieces: number;
  withCabin: boolean;
  rows: PriceConfigWizardSchemaRows;
}

export type PriceConfigWizardSchemaCombinationsRows = {
  [key: string]: PriceConfigWizardSchemaCombinationsRow;
};

export type PriceConfigWizardSchemaSpots = {
  [key: string]: PriceConfigWizardSchemaRow;
};

export type PriceConfigWizardSchemaStagings = {
  [key: string]: PriceConfigWizardSchemaRow;
};

export type PriceConfigWizardSchemaCombinations = {
  [key: string]: PriceConfigWizardSchemaCombinationsRow;
};

export type PriceConfigWizardSchemaSectorRows = {
  [key: string]:
    | PriceConfigWizardSchemaRow
    | PriceConfigWizardSchemaCombinationsRow;
};

export interface PriceConfigWizardSchemaSector {
  [key: string]: any;
  id: number;
  label: string;
  token: string;
  fullToken: string;
  collapsed: boolean;
  rows: PriceConfigWizardSchemaSectorRows;
}

export type PriceConfigWizardSchemaSectors = {
  [key: string]: PriceConfigWizardSchemaSector;
};
export interface PriceConfigWizardSchemaPeriod {
  id: number;
  label: string;
  token: string;
  sectors: PriceConfigWizardSchemaSectors;
}

export type PriceConfigWizardSchema = {
  [key: string]: PriceConfigWizardSchemaPeriod;
};

export enum PriceConfigWizardSchemaToken {
  // containers
  PERIOD = 'P',
  SECTOR = 'S',
  // spots
  UMBRELLA = 'U',
  CABIN = 'W',
  // stagings
  BED = 'B',
  MAXIBED = 'M',
  DECKCHAIR = 'D',
  CHAIR = 'C',
  // Extra
  EXTRA = 'E',
  // combinations
  PIECE = '#',
  // fields
  DAY = 'G',
  HALF_DAY = 'HD',
  HALF_DAY_WEEKEND = 'HDWE',
  WEEKEND = 'WE',
  DAY_EXTRA = 'DE',
}

export enum PriceConfigWizardSchemaAttribute {
  CONTAINER = 'container',
  PERIOD = 'period',
  SECTOR = 'sector',
  ROW = 'row',
  EXPANDABLE_ROW = 'expandable-row',
  EXTRA = 'extra',
  SPOT = 'spot',
  STAGING = 'staging',
  COMBINATION = 'combination',
  CABIN_COMBINATION = 'cabin-combination',
  FIELD = 'field',
}

export interface PriceConfigWizardSchemaDictionaryItem {
  id: string;
  token: PriceConfigWizardSchemaToken;
  label: string;
  pluralLabel: string;
  attributes: Array<PriceConfigWizardSchemaAttribute>;
  default?: boolean;
}
export type PriceConfigWizardSchemaDictionary =
  Array<PriceConfigWizardSchemaDictionaryItem>;

export const PRICE_CONFIG_WIZARD_SCHEMA_DICTIONARY: PriceConfigWizardSchemaDictionary =
  [
    // containers
    {
      id: 'period',
      token: PriceConfigWizardSchemaToken.PERIOD,
      label: i18n.global.tc('priceConfig.wizardSchemaToken.period', 1),
      pluralLabel: i18n.global.tc('priceConfig.wizardSchemaToken.period', 2),
      attributes: [
        PriceConfigWizardSchemaAttribute.CONTAINER,
        PriceConfigWizardSchemaAttribute.PERIOD,
      ],
    },
    {
      id: 'sector',
      token: PriceConfigWizardSchemaToken.SECTOR,
      label: i18n.global.tc('priceConfig.wizardSchemaToken.sector', 1),
      pluralLabel: i18n.global.tc('priceConfig.wizardSchemaToken.sector', 2),
      attributes: [
        PriceConfigWizardSchemaAttribute.CONTAINER,
        PriceConfigWizardSchemaAttribute.SECTOR,
      ],
    },
    // spots
    {
      id: 'umbrella',
      token: PriceConfigWizardSchemaToken.UMBRELLA,
      label: i18n.global.tc('priceConfig.wizardSchemaToken.umbrella', 1),
      pluralLabel: i18n.global.tc('priceConfig.wizardSchemaToken.umbrella', 2),
      attributes: [
        PriceConfigWizardSchemaAttribute.ROW,
        PriceConfigWizardSchemaAttribute.SPOT,
      ],
    },
    {
      id: 'cabin',
      token: PriceConfigWizardSchemaToken.CABIN,
      label: i18n.global.tc('priceConfig.wizardSchemaToken.cabin', 1),
      pluralLabel: i18n.global.tc('priceConfig.wizardSchemaToken.cabin', 2),
      attributes: [
        PriceConfigWizardSchemaAttribute.ROW,
        PriceConfigWizardSchemaAttribute.SPOT,
      ],
    },
    {
      id: 'extra',
      token: PriceConfigWizardSchemaToken.EXTRA,
      label: i18n.global.tc('priceConfig.wizardSchemaToken.extra', 1),
      pluralLabel: i18n.global.tc('priceConfig.wizardSchemaToken.extra', 2),
      attributes: [
        PriceConfigWizardSchemaAttribute.ROW,
        PriceConfigWizardSchemaAttribute.EXTRA,
      ],
    },
    // stagings
    {
      id: 'bed',
      token: PriceConfigWizardSchemaToken.BED,
      label: i18n.global.tc('priceConfig.wizardSchemaToken.bed', 1),
      pluralLabel: i18n.global.tc('priceConfig.wizardSchemaToken.bed', 2),
      attributes: [
        PriceConfigWizardSchemaAttribute.ROW,
        PriceConfigWizardSchemaAttribute.STAGING,
      ],
    },
    {
      id: 'maxibed',
      token: PriceConfigWizardSchemaToken.MAXIBED,
      label: i18n.global.tc('priceConfig.wizardSchemaToken.maxibed', 1),
      pluralLabel: i18n.global.tc('priceConfig.wizardSchemaToken.maxibed', 2),
      attributes: [
        PriceConfigWizardSchemaAttribute.ROW,
        PriceConfigWizardSchemaAttribute.STAGING,
      ],
    },
    {
      id: 'deckchair',
      token: PriceConfigWizardSchemaToken.DECKCHAIR,
      label: i18n.global.tc('priceConfig.wizardSchemaToken.deckchair', 1),
      pluralLabel: i18n.global.tc('priceConfig.wizardSchemaToken.deckchair', 2),
      attributes: [
        PriceConfigWizardSchemaAttribute.ROW,
        PriceConfigWizardSchemaAttribute.STAGING,
      ],
    },
    {
      id: 'chair',
      token: PriceConfigWizardSchemaToken.CHAIR,
      label: i18n.global.tc('priceConfig.wizardSchemaToken.chair', 1),
      pluralLabel: i18n.global.tc('priceConfig.wizardSchemaToken.chair', 2),
      attributes: [
        PriceConfigWizardSchemaAttribute.ROW,
        PriceConfigWizardSchemaAttribute.STAGING,
      ],
    },
    // combinations
    {
      id: 'piece',
      token: PriceConfigWizardSchemaToken.PIECE,
      label: i18n.global.tc('priceConfig.wizardSchemaToken.piece', 1),
      pluralLabel: i18n.global.tc('priceConfig.wizardSchemaToken.piece', 2),
      attributes: [
        PriceConfigWizardSchemaAttribute.ROW,
        PriceConfigWizardSchemaAttribute.COMBINATION,
      ],
    },
    // fields
    {
      id: 'day',
      token: PriceConfigWizardSchemaToken.DAY,
      label: i18n.global.tc('priceConfig.wizardSchemaToken.day', 1),
      pluralLabel: i18n.global.tc('priceConfig.wizardSchemaToken.day', 2),
      attributes: [PriceConfigWizardSchemaAttribute.FIELD],
    },
    {
      id: 'half-day',
      token: PriceConfigWizardSchemaToken.HALF_DAY,
      label: i18n.global.tc('priceConfig.wizardSchemaToken.halfDay', 1),
      pluralLabel: i18n.global.tc('priceConfig.wizardSchemaToken.halfDay', 2),
      attributes: [PriceConfigWizardSchemaAttribute.FIELD],
    },
    {
      id: 'half-day-weekend',
      token: PriceConfigWizardSchemaToken.HALF_DAY_WEEKEND,
      label: i18n.global.tc('priceConfig.wizardSchemaToken.halfDayWeekend', 1),
      pluralLabel: i18n.global.tc(
        'priceConfig.wizardSchemaToken.halfDayWeekend',
        2,
      ),
      attributes: [PriceConfigWizardSchemaAttribute.FIELD],
    },
    {
      id: 'weekend',
      token: PriceConfigWizardSchemaToken.WEEKEND,
      label: i18n.global.tc('priceConfig.wizardSchemaToken.weekend', 1),
      pluralLabel: i18n.global.tc('priceConfig.wizardSchemaToken.weekend', 2),
      attributes: [PriceConfigWizardSchemaAttribute.FIELD],
    },
    {
      id: 'day-extra',
      token: PriceConfigWizardSchemaToken.DAY_EXTRA,
      label: i18n.global.tc('priceConfig.wizardSchemaToken.dayExtra', 1),
      pluralLabel: i18n.global.tc('priceConfig.wizardSchemaToken.dayExtra', 2),
      attributes: [PriceConfigWizardSchemaAttribute.FIELD],
    },
  ];

export interface PriceConfigWizardSchemaInputData {
  value: number | null;
  field: PriceConfigWizardSchemaField;
  row: PriceConfigWizardSchemaRow | PriceConfigWizardSchemaCombinationsRow;
  pieces?: number;
  withCabin?: boolean;
}

export interface PriceConfigWizardSchemaSwitchData {
  row: PriceConfigWizardSchemaRow | PriceConfigWizardSchemaCombinationsRow;
  pieces?: number;
  withCabin?: boolean;
}

export interface PriceConfigWizardSchemaActionData {
  row: PriceConfigWizardSchemaRow;
  action: PriceConfigWizardSchemaRowActionValue;
}

export interface PriceConfigErrors {
  [key: string]: string;
}

export interface PriceConfigTab {
  id: number;
  key: string;
  label: string;
  tabIndex: number;
}
