import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2bbcc3ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "top-bar-checkout-point-selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Button, {
        class: "p-button-outlined p-button-rounded p-button-white checkout-point-selector-button",
        icon: "spi-print",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = true))
      })
    ]),
    _createVNode(_component_Dialog, {
      class: "dialog",
      modal: "",
      visible: _ctx.visible,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visible) = $event)),
      onHide: _cache[4] || (_cache[4] = ($event: any) => (_ctx.hideCallback()))
    }, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('topBarCheckoutPointSelector.title')), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button-outlined",
          label: _ctx.$t('button.cancel'),
          icon: "spi-times",
          onClick: _ctx.close
        }, null, 8, ["label", "onClick"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('common.select'),
          icon: "spi-check",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSelected(_ctx.selectedCheckoutPointId))),
          disabled: _ctx.selectedCheckoutPointId === null
        }, null, 8, ["label", "disabled"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Listbox, {
          modelValue: _ctx.selectedCheckoutPointId,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedCheckoutPointId) = $event)),
          options: _ctx.checkoutPointOptions,
          optionValue: "value",
          optionLabel: "label",
          filter: true,
          filterPlaceholder: _ctx.$t('topBarCheckoutPointSelector.searchPlaceholder'),
          listStyle: "max-height: 256px"
        }, null, 8, ["modelValue", "options", "filterPlaceholder"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}