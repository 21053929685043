
import { defineComponent } from 'vue';
import clone from 'lodash/clone';
import { DateTime } from 'luxon';
import { mapState } from 'vuex';
import {
  MapMode,
  MapShiftMode,
  MapShiftStep,
  MapCalendar,
  MapSpotElement,
} from '@/models/map';
import OverlayCard from '@/components/shared/OverlayCard.vue';
import MapSpot from '@/components/map/MapSpot.vue';
import reservationService from '@/services/reservationService';
import {
  ApiMapDataPayload,
  ApiReservationCreateStepPayload,
  ApiReservationMovePayload,
} from '@/models/api';
import { Reservation } from '@/models/reservation';
import { SpotType } from '@/models/spot';
import cookieUtil from '@/utils/cookieUtil';
import { SPIAGGE_COOKIES } from '@/models/cookies';

export default defineComponent({
  name: 'MapShift',
  components: { OverlayCard, MapSpot },
  data() {
    return {
      mapMode: clone(MapMode),
      shiftMode: clone(MapShiftMode),
      priceDifference: 0,
    };
  },
  methods: {
    onToday(): void {
      this.$store.commit('map/setShiftMode', MapShiftMode.TODAY);
      // update calendar picker
      this.$store.commit('map/setCalendar', [
        this.calendar[0].startOf('day'),
        DateTime.fromSeconds(this.shift.from.endDate),
      ] as MapCalendar);
      // update map data
      const payload = {
        from: this.calendar[0].toSeconds(),
        to: this.calendar[1].toSeconds(),
      } as ApiMapDataPayload;
      if (this.reservationId) {
        payload.reservationId = this.reservationId;
      }
      this.$store.dispatch('map/setData', payload);
      this.onMove();
    },
    onTomorrow(): void {
      // set shift mode
      this.$store.commit('map/setShiftMode', MapShiftMode.TOMORROW);
      // update calendar picker
      this.$store.commit('map/setCalendar', [
        this.calendar[0].startOf('day').plus({ days: 1 }),
        DateTime.fromSeconds(this.shift.from.endDate),
      ] as MapCalendar);
      // update map data
      const payload = {
        from: this.calendar[0].toSeconds(),
        to: this.calendar[1].toSeconds(),
      } as ApiMapDataPayload;
      if (this.reservationId) {
        payload.reservationId = this.reservationId;
      }
      this.$store.dispatch('map/setData', payload);
      this.onMove();
    },
    showCustomized(): void {
      this.$store.commit('map/setShiftMode', MapShiftMode.CUSTOM);
      const reservationEndDate = DateTime.fromSeconds(
        this.shift.from.endDate,
      ).startOf('day');
      const reservationStartDate = DateTime.fromSeconds(
        this.shift.from.startDate,
      ).startOf('day');
      this.$store.commit('map/setShiftRange', [
        DateTime.max(this.calendar[0], reservationStartDate),
        DateTime.min(this.calendar[1], reservationEndDate),
      ] as MapCalendar);
    },
    onCustomized(): void {
      this.$store.commit('map/setCalendar', [
        this.from,
        this.to,
      ] as MapCalendar);
      // update map data
      const payload = {
        from: this.from.toSeconds(),
        to: this.to.toSeconds(),
      } as ApiMapDataPayload;
      if (this.reservationId) {
        payload.reservationId = this.reservationId;
      }
      this.$store.dispatch('map/setData', payload);
      this.onMove();
    },
    closeMode(): void {
      this.$store.commit('map/setShiftStep', MapShiftStep.SELECT_FROM);
    },
    closeConfirm(): void {
      this.$store.commit('map/setShiftStep', MapShiftStep.SELECT_TO);
    },
    onMove(): void {
      this.$store.commit('map/setShiftStep', MapShiftStep.SELECT_TO);
    },
    async onConfirm(): Promise<void> {
      try {
        let startDate: DateTime = this.$store.getters['map/calendar'][0];
        let endDate: DateTime = this.$store.getters['map/calendar'][1];
        let reservation: Reservation = {} as Reservation;

        /* SHIFT */
        try {
          if (this.mode === MapMode.SHIFT) {
            if (this.shift.mode === MapShiftMode.CUSTOM) {
              startDate = this.shift.range[0];
              endDate = this.shift.range[1];
            }
            reservation = await reservationService.createStep(
              (this.shift.from as MapSpotElement).id as number,
              {
                startDate: startDate.toSeconds(),
                spotType: (this.shift.to as MapSpotElement)
                  .type as unknown as SpotType,
                spotName: (this.shift.to as MapSpotElement).name,
                endDate: endDate.toSeconds(),
              } as ApiReservationCreateStepPayload,
            );

            if (this.reservationId) {
              // shift from reservation, global toast then redirect
              this.$spiagge.toast.success(
                this.$t('mapShift.toast.shiftSuccess'),
              );
              this.$router.push(`/reservation/${reservation.id.toString()}`);
            } else {
              this.$spiagge.toast.success(
                this.$t('mapShift.toast.shiftSuccess'),
                '',
                'map',
              );
            }
          }
        } catch (error) {
          this.$spiagge.toast.error(
            this.$t('mapShift.toast.shiftError'),
            '',
            'map',
          );
        }

        /* PROGRAMMED SHIFT */
        try {
          if (this.mode === MapMode.PROGRAMMED_SHIFT) {
            reservation = await reservationService.createStep(
              (this.shift.from as MapSpotElement).id as number,
              {
                startDate: startDate.toSeconds(),
                spotType: (this.shift.to as MapSpotElement)
                  .type as unknown as SpotType,
                spotName: (this.shift.to as MapSpotElement).name,
                endDate: endDate.toSeconds(),
              } as ApiReservationMovePayload,
            );
            this.$spiagge.toast.success(
              this.$t('mapShift.toast.scheduledShiftSuccess'),
            );
            this.$router.push(`/reservation/${reservation.id}`);
          }
        } catch (error) {
          // console.log(error);
          this.$spiagge.toast.error(
            this.$t('mapShift.toast.scheduledShiftError'),
            '',
            'map',
          );
        }

        this.$store.commit('map/resetShift');
        this.$store.commit('map/setMode', MapMode.NONE);

        /* UPDATE MAP DATA */
        // use map date cookies if present
        const cookieFrom = cookieUtil.get(SPIAGGE_COOKIES.MAP_DATE_FROM);
        const cookieTo = cookieUtil.get(SPIAGGE_COOKIES.MAP_DATE_TO);
        const calendarFrom = cookieFrom
          ? Number(cookieFrom)
          : this.calendar[0].toSeconds();
        const calendarTo = cookieTo
          ? Number(cookieTo)
          : this.calendar[1].toSeconds();

        // if map date cookies update calendar
        if (cookieFrom || cookieTo) {
          this.$store.commit('map/setCalendar', [
            DateTime.fromSeconds(calendarFrom),
            DateTime.fromSeconds(calendarTo),
          ]);
        }

        // update map
        this.$store.dispatch('map/setData', {
          from: calendarFrom,
          to: calendarTo,
        });
      } catch (error) {
        // console.log(error);
        this.$spiagge.toast.error(
          this.$t('mapShift.toast.shiftError'),
          '',
          'map',
        );
      }
    },
    onCustomFromSelect(fromDate: Date) {
      if (this.toCalendar.getTime() < fromDate.getTime()) {
        this.toCalendar = fromDate;
      }
    },
  },
  computed: {
    ...mapState('map', ['mode', 'shift', 'calendar']),
    reservationId(): number {
      // reservation id from reservation
      return this.$route.query.reservationId
        ? Number(this.$route.query.reservationId)
        : 0;
    },
    isSelectMode(): boolean {
      return this.shift.step === MapShiftStep.SELECT_MODE;
    },
    isConfirm(): boolean {
      return this.shift.step === MapShiftStep.CONFIRM;
    },
    from: {
      get(): DateTime {
        return this.shift.range[0];
      },
      set(from: DateTime): void {
        this.$store.commit('map/setShiftRange', [
          from,
          this.to < from ? from : this.to,
        ]);
      },
    },
    // FIXME:
    fromCalendar: {
      get(): Date {
        return this.from.toJSDate();
      },
      set(d: Date) {
        const date = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
        this.from = DateTime.fromFormat(date, 'd-M-yyyy');
      },
    },
    minFrom(): Date {
      const reservationStartDate = DateTime.fromSeconds(
          this.shift.from.startDate,
      ).startOf('day');
      return reservationStartDate.toJSDate();
    },
    maxFrom(): Date {
      return this.calendar[0].startOf('day').toJSDate();
    },
    to: {
      get(): DateTime {
        return this.shift.range[1];
      },
      set(to: DateTime): void {
        this.$store.commit('map/setShiftRange', [
          this.from > to ? to : this.from,
          to,
        ]);
      },
    },
    // FIXME:
    toCalendar: {
      get(): Date {
        return this.to.toJSDate();
      },
      set(d: Date) {
        const date = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
        this.to = DateTime.fromFormat(date, 'd-M-yyyy');
      },
    },
    minTo(): Date {
      return this.shift.range[0].toJSDate();
    },
    maxTo(): Date {
      const reservationEndDate = DateTime.fromSeconds(
        this.shift.from.endDate,
      ).startOf('day');
      return reservationEndDate.toJSDate();
    },
    show: {
      get(): boolean {
        return [MapShiftStep.SELECT_MODE, MapShiftStep.CONFIRM].includes(
          this.shift.step,
        );
      },
      set(mode: MapMode): void {
        this.$store.commit('map/setMode', mode);
      },
    },
    canShiftToday(): boolean {
      if (!this.shift.from) return false;
      if (this.mode === MapMode.SHIFT) {
        const start = DateTime.fromSeconds(this.shift.from.startDate).startOf('day');
        const end = DateTime.fromSeconds(this.shift.from.endDate).startOf('day');
        const today = this.calendar[0];
        if (start > today || end < today) {
          return false;
        }
        return true;
      }
      return true;
    },
    canShiftTomorrow(): boolean {
      if (!this.shift.from) return false;
      if (this.mode === MapMode.SHIFT) {
        const start = DateTime.fromSeconds(this.shift.from.startDate).startOf('day');
        const end = DateTime.fromSeconds(this.shift.from.endDate).startOf('day');
        const tomorrow = this.calendar[0].plus({ days: 1 });
        if (start > tomorrow || end < tomorrow) {
          return false;
        }
        return true;
      }
      return true;
    },
    canShiftCustomized(): boolean {
      if (!this.shift.from) return false;
      /* if (this.mode === MapMode.SHIFT) {
        // cant shift reservation if last day
        if (
          this.calendar[1] >=
          DateTime.fromSeconds(this.shift.from.endDate).startOf('day')
        ) {
          return false;
        }
        return true;
      } */
      return true;
    },
    todayLabel(): string {
      return `${this.$t('mapShift.todayLabel')} (${this.calendar[0].toFormat(
        'dd/MM',
      )})`;
    },
    tomorrowLabel(): string {
      return `${this.$t('mapShift.tomorrowLabel')} (${this.calendar[0]
        .plus({ days: 1 })
        .toFormat('dd/MM')})`;
    },
  },
  watch: {
    isConfirm(confirm: boolean) {
      if (confirm) {
        // make api for price difference
        // this.priceDifference = difference;
      } else {
        this.priceDifference = 0;
      }
    },
  },
});
