
import { defineComponent } from 'vue';
import licenseService from '@/services/licenseService';
import { LicenseList } from '@/models/license';
import cookieUtil from '@/utils/cookieUtil';

export default defineComponent({
  name: 'LicenseSelectView',
  data() {
    return {
      licenses: [] as Array<LicenseList>,
    };
  },
  async beforeMount() {
    this.$store.commit('app/setLoading', true);
    try {
      this.licenses = await licenseService.list();
    } catch (e) {
      this.$spiagge.toast.error(this.$t('licenseSelect.toast.retrievalError'));
    } finally {
      this.$store.commit('app/setLoading', false);
    }
  },
  methods: {
    async onSelect(license: LicenseList) {
      try {
        cookieUtil.set(process.env.VUE_APP_LICENSE_COOKIE, license.code, 365);
        await this.$store.dispatch('session/setLicense', license.code);
        await this.$store.dispatch('session/setup');
        this.$spiagge.toast.success(
          this.$t('licenseSelect.toast.selectionSuccess'),
        );
        this.$router.push(
          this.$route.redirectedFrom
            ? this.$route.redirectedFrom.fullPath
            : '/',
        );
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('licenseSelect.toast.selectionError'),
        );
        this.$store.commit('session/setLicense', null);
      }
    },
    isSelected(license: LicenseList): boolean {
      return this.$store.getters['session/license']?.name === license.name;
    },
  },
  computed: {
    loading(): boolean {
      return this.$store.getters['app/loading'];
    },
  },
});
