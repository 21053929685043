
import { defineComponent } from 'vue';
import SettingsNavigation from '@/components/settings/SettingsNavigation.vue';

export default defineComponent({
  name: 'SettingsView',
  components: {
    SettingsNavigation,
  },
  methods: {},
  computed: {
    loading(): boolean {
      return this.$store.getters['app/loading'];
    },
    windowWidth(): number {
      return this.$store.getters['app/windowWidth'];
    },
    windowHeight(): number {
      return this.$store.getters['app/windowHeight'];
    },
    pageWidth(): string {
      const width =
        this.windowWidth >= 992 ? this.windowWidth - 80 : this.windowWidth;
      return `${width}px`;
    },
    panelHeight(): string {
      return `${this.windowHeight - 82}px`;
    },
    isEditorView(): boolean {
      return this.$route.name === 'SettingsMapEditorView' || this.$route.name === 'SettingsMapEditor2View';
    },
  },
});
