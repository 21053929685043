/**
 * Work service
 */

import axios, { AxiosResponse } from 'axios';
import { ApiWorkListResponse } from '@/models/api';
import apiService from '@/services/apiService';

export default {
  async list(): Promise<ApiWorkListResponse> {
    const res: AxiosResponse<ApiWorkListResponse> = await axios.get(
      apiService.work.list(),
    );
    return res.data;
  },
};
