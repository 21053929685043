import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16ec5f7f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "selection" }
const _hoisted_2 = { class: "content" }
const _hoisted_3 = { class: "actions-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_ctx.selection !== null)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: "map-selection",
        class: _normalizeClass(["map-control", _ctx.classes])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("i", {
              class: _normalizeClass(_ctx.selection.icon)
            }, null, 2),
            _createElementVNode("p", null, _toDisplayString(_ctx.selection.count + ' ' + _ctx.$t('common.selectedElements')), 1),
            _createVNode(_component_Button, {
              class: "p-button-text p-button-sm close-button",
              label: _ctx.$t('button.close'),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selection?.close()))
            }, null, 8, ["label"])
          ]),
          _createVNode(_component_Button, {
            class: "cta p-button-sm",
            disabled: !_ctx.selection.cta.enabled,
            label: _ctx.selection.cta.label,
            icon: "pi pi-chevron-right",
            iconPos: "right",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selection?.cta.click()))
          }, null, 8, ["disabled", "label"])
        ]),
        _createVNode(_component_Dialog, {
          id: "join-account-dialog",
          class: "custom-dialog",
          modal: true,
          visible: _ctx.joinAccountDialog,
          "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.joinAccountDialog) = $event)),
          onHide: _cache[5] || (_cache[5] = ($event: any) => (_ctx.joinAccountDialog = false))
        }, {
          default: _withCtx(() => [
            _createElementVNode("h2", null, _toDisplayString(_ctx.$t('mapSelection.joinAccountTitle')), 1),
            _createElementVNode("h5", null, _toDisplayString(_ctx.$t('mapSelection.joinAccountMessage')), 1),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Button, {
                disabled: !_ctx.isJoinButtonEnabled,
                class: "p-button-outlined",
                icon: "spi-times",
                label: _ctx.$t('common.no'),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onJoinAccount(false)))
              }, null, 8, ["disabled", "label"]),
              _createVNode(_component_Button, {
                disabled: !_ctx.isJoinButtonEnabled,
                icon: "spi-check",
                label: _ctx.$t('common.yes'),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onJoinAccount(true)))
              }, null, 8, ["disabled", "label"])
            ])
          ]),
          _: 1
        }, 8, ["visible"])
      ], 2))
    : _createCommentVNode("", true)
}