import { CashFlowMethod } from './cashFlow';
import { ChartData } from './chart';
import { ReservationType } from './reservation';
import { SpotType } from './spot';

export interface StatisticCard {
  label: string;
  value: string | null; // null = loader
  tooltip?: string;
  subValue?: string;
  link?: string;
}

export enum StatisticDataTableColumnRender {
  BOOLEAN_ICON = 'boolean-icon',
  TAG = 'tag',
  DISCOUNT_TYPE_TAG = 'discount-type-tag',
  REVENUE_MANAGER_TAG = 'revenue-manager-tag',
}

export enum StatisticDiscountCodeType {
  NONE = '',
  MANAGER = 'Gestore',
  SPIAGGE = 'Spiagge',
}

export type StatisticDataTableRowValue =
  | string
  | number
  | boolean
  | Array<number>
  | null;

export interface StatisticDataTableRow {
  [key: string]: StatisticDataTableRowValue;
}

export interface StatisticDataTableColumn {
  label: string;
  field: string;
  sortable: boolean;
  formatter?: (
    value: StatisticDataTableRowValue,
    row?: StatisticDataTableRow,
  ) => string;
  renderer?: StatisticDataTableColumnRender;
  classes?: Array<string>;
  icon?: (value: any) => string;
  average?: boolean;
}

export interface StatisticDataTableColumnIcon {
  label: string;
  icon: string;
  show: (row: StatisticDataTableRow) => boolean;
}

export interface StatisticDataTableSearch {
  columns: Array<string>;
  value: string;
}

export interface StatisticDataTableFilter {
  name: string;
  value: boolean | string | number | Array<number> | SpotType;
}

export interface StatisticDataTableOrderBy {
  column: string;
  value: 'asc' | 'desc';
}

export interface StatisticDataTableView {
  id: number; // timestamp creation
  label: string;
  // icon: string;
  columns: Array<string>;
  orderBy: Array<StatisticDataTableOrderBy>;
  // filters: Array<string>; TODO
}

export interface StatisticCountersTableColumn {
  label: string;
  field: string;
}

export enum StatisticDateMode {
  INCLUDED = 'included',
  FUTURE = 'future',
  COINCIDE = 'coincide',
  ARRIVAL = 'arrival',
  DEPARTURE = 'departure',
  ATTENDANCE = 'attendance',
}

export interface StatisticDoughnutChartModel {
  label: string;
  chartData: ChartData;
  tooltip?: string;
}

export interface StatisticLineChartModel {
  label: string;
  tooltip?: string;
  chartData: ChartData;
}

export interface StatisticBarChartModel {
  label: string;
  chartData: ChartData;
}

/* STATISTICS TREE */

export interface StatisticTreeItem {
  icon?: string;
  image?: string;
  label: string;
  value?: string;
  children?: Array<StatisticTreeItem>;
  cta?: () => void;
}

/* STATISTICS CASH FLOWS */

export interface StatisticCashFlowsReservationTypeItem {
  days: number;
  total: number;
  umbrella?: number;
  gazebo?: number;
  bed?: number;
  cabins?: number;
  parking?: number;
  playField?: number;
  boat?: number;
  cardRecharge?: number;
  services: {
    [key: string]: number;
    total: number;
  };
}

export interface StatisticCashFlowsPaymentMethodItem {
  method: number; // id payment method (0 -> others)
  total: number;
  fiscal: number;
  notFiscal: number;
  withReceipt: number;
  withInvoice: number;
}

export enum StatisticFiscalMode {
  RECEIPT = 'receipt',
  INVOICE = 'invoice',
  NOT_FISCAL = 'not-fiscal',
}

export interface StatisticCashFlowsPayment {
  cashFlowAmount: number;
  cashFlowFiscalAmount: number | null;
  date: number;
  method: CashFlowMethod;
  spot: string;
  printer: string | null;
  customer: string | null;
  user: string | null;
  online: boolean;
  fiscalMode: StatisticFiscalMode;
  reservationId: number | null;
  endDate: number;
  startDate: number;
  // isAdditionFlow: boolean;
  // reservationDuration: number;
  reservationType: ReservationType;
  receiptNumber: string | null;
}

/* STATISTICS COUNTERS */

export interface StatisticCounterHotel {
  id: number;
  name: string;
  reservations: number;
  duration: number;
}

export interface StatisticCounterVoucher {
  id: number;
  name: string;
  vouchers: number;
  duration: number;
  value: number;
}

/* STATISTICS ASTORIA CASH FLOWS */

export interface StatisticAstoriaCashFlowDetail {
  umbrellas: number;
  beds: number;
  cabins: number;
  gazebo: number;
}

export interface StatisticAstoriaCashFlowShopService {
  serviceId: number;
  amount: number;
}

export interface StatisticAstoriaCashFlowShop {
  bar: number;
  services: Array<StatisticAstoriaCashFlowShopService>;
}

export interface StatisticAstoriaCashFlowDetails {
  seasonal: StatisticAstoriaCashFlowDetail;
  daily: StatisticAstoriaCashFlowDetail;
  subscriptions: StatisticAstoriaCashFlowDetail;
  shop: StatisticAstoriaCashFlowShop;
}

export interface StatisticAstoriaCashFlows {
  astoria: StatisticAstoriaCashFlowDetails;
  nettuno: StatisticAstoriaCashFlowDetails;
}
export interface StatisticCustomer {
  cards: {
    paidTotal: number;
    priceTotal: number;
    reservationsTotal: number;
    toPay: number;
  };
  doughnutChart: {
    priceTotal: Array<number>;
    priceTotalAverage: Array<number>;
    priceTotalServiceAverage: Array<number>;
  };
}

export interface StatisticDurationReservation {
  days: number; // 32 = grather than a month
  count: number;
  value: number;
  weekend?: boolean; // only if days = 1
}
