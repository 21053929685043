import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b6af28a8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "statistics-dashboard-view",
  class: "statistics-view view"
}
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "col-12 sm:col-6 xl:col-3 xl:flex-order-0 cards-left" }
const _hoisted_4 = { class: "col-12 sm:col-6 xl:col-3 xl:flex-order-2 cards-right" }
const _hoisted_5 = { class: "col-12 xl:col-6 xl:flex-order-1" }
const _hoisted_6 = { class: "grid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_StatisticsCard = _resolveComponent("StatisticsCard")!
  const _component_StatisticsDoughnutChart = _resolveComponent("StatisticsDoughnutChart")!
  const _component_StatisticsLineChart = _resolveComponent("StatisticsLineChart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      icon: _ctx.exporting ? 'pi pi-spin pi-spinner' : 'spi-document',
      label: _ctx.exporting ? '' : _ctx.$t('button.export'),
      class: "export-button p-button-white p-button-ouline",
      onClick: _ctx.onExport,
      disabled: _ctx.exporting
    }, null, 8, ["icon", "label", "onClick", "disabled"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardsLeft, (card, index) => {
          return (_openBlock(), _createBlock(_component_StatisticsCard, {
            key: index,
            label: card.label,
            value: card.value,
            subValue: card.subValue ? card.subValue : '',
            tooltip: card.tooltip || '',
            link: card.link
          }, null, 8, ["label", "value", "subValue", "tooltip", "link"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardsRight, (card, index) => {
          return (_openBlock(), _createBlock(_component_StatisticsCard, {
            key: index,
            label: card.label,
            value: card.value,
            subValue: card.subValue ? card.subValue : '',
            tooltip: card.tooltip || '',
            link: card.link
          }, null, 8, ["label", "value", "subValue", "tooltip", "link"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.doughnutCharts, (chart, index) => {
          return (_openBlock(), _createBlock(_component_StatisticsDoughnutChart, {
            key: index,
            label: chart.label,
            chartData: chart.chartData,
            showTotal: ""
          }, null, 8, ["label", "chartData"]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lineCharts, (chart, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "col-12 sm:col-6"
        }, [
          _createVNode(_component_StatisticsLineChart, {
            label: chart.label,
            chartData: chart.chartData,
            tooltip: chart.tooltip || ''
          }, null, 8, ["label", "chartData", "tooltip"])
        ]))
      }), 128))
    ])
  ]))
}