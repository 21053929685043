
import { defineComponent, PropType, WatchStopHandle } from 'vue';
import draggable from 'vuedraggable';
import cloneDeep from 'lodash/cloneDeep';
import xor from 'lodash/xor';
import { StatisticDataTableColumn } from '@/models/statistic';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  ActionPermissionType,
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

export default defineComponent({
  name: 'StatisticsEditColumns',
  components: {
    draggable,
  },
  props: {
    columns: {
      type: Array as PropType<Array<StatisticDataTableColumn>>,
      required: true,
    },
    columnsView: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      columnsOptions: [] as Array<StatisticDataTableColumn>,
      columnsModel: [] as Array<string>,
      columnsActive: [] as Array<StatisticDataTableColumn>,
      drag: false,
      dragOptions: {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
      },
      watcher: null as unknown as WatchStopHandle,
    };
  },
  methods: {
    hasUpdatePermission(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.stats,
        FEATURE_PERMISSION_ACTION_CONFIG.stats.EDIT_DISPLAY_COLUMNS,
      );
    },
    onRemoveColumn(column: StatisticDataTableColumn): void {
      // console.log(column);
      this.columnsActive = this.columnsActive.filter(
        (c: StatisticDataTableColumn) => c !== column,
      );
      this.columnsModel = this.columnsModel.filter(
        (c: string) => c !== column.field,
      );
    },
    onColumnsModelChange(updated: Array<string>, old: Array<string>): void {
      const columnTmp = xor(updated, old)[0];
      if (updated.length > old.length) {
        // addition
        this.columnsActive.push(
          this.columns.find(
            (column: StatisticDataTableColumn) => column.field === columnTmp,
          ) as StatisticDataTableColumn,
        );
      } else {
        // removing
        this.columnsActive = this.columnsActive.filter(
          (column: StatisticDataTableColumn) => column.field !== columnTmp,
        );
      }
    },
    onSave(): void {
      this.$emit(
        'save',
        this.columnsActive.map(
          (column: StatisticDataTableColumn) => column.field,
        ),
      );
      this.dialog = false;
    },
    onAbort(): void {
      this.dialog = false;
    },
  },
  watch: {
    dialog(): void {
      if (this.dialog) {
        this.columnsOptions = cloneDeep(this.columns);
        this.columnsModel = cloneDeep(this.columnsView);
        this.columnsActive = this.columnsView.map(
          (columnModel: string) =>
            this.columnsOptions.find(
              (columnOption: StatisticDataTableColumn) =>
                columnOption.field === columnModel,
            ) as StatisticDataTableColumn,
        );
        this.watcher = this.$watch('columnsModel', this.onColumnsModelChange);
      } else {
        this.watcher();
        this.columnsOptions = [];
        this.columnsModel = [];
        this.columnsActive = [];
      }
    },
  },
});
