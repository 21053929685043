export enum PrintFormat {
  A4 = 'A4',
  A3 = 'A3',
}

export enum PrintOrientation {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
}

export interface PrintSize {
  format: PrintFormat;
  orientation: PrintOrientation;
  width: number;
  height: number;
}

export const PRINT_SIZES: Array<PrintSize> = [
  {
    format: PrintFormat.A4,
    orientation: PrintOrientation.PORTRAIT,
    width: 720,
    height: 1040,
  },
  {
    format: PrintFormat.A4,
    orientation: PrintOrientation.LANDSCAPE,
    width: 1040,
    height: 720,
  },
  {
    format: PrintFormat.A3,
    orientation: PrintOrientation.PORTRAIT,
    width: 1020,
    height: 1500,
  },
  {
    format: PrintFormat.A3,
    orientation: PrintOrientation.LANDSCAPE,
    width: 1500,
    height: 1020,
  },
];

export enum PrintLineAction {
  TEXT = 'textStyle',
  FONT = 'fontSize',
}

export interface PrintLineBase {
  action: PrintLineAction;
}

export interface PrintLineTextStyle extends PrintLineBase {
  reverse: boolean;
  underline: boolean;
  bold: boolean;
}

export interface PrintLineFontSize extends PrintLineBase {
  height: number;
  width: number;
}

export type PrintLine = string | PrintLineTextStyle | PrintLineFontSize;
