import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "statistics-edit-views" }
const _hoisted_2 = { class: "new-view" }
const _hoisted_3 = { class: "p-field" }
const _hoisted_4 = {
  key: 0,
  class: "views-active"
}
const _hoisted_5 = { class: "view" }
const _hoisted_6 = { class: "actions-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      icon: "pi pi-pencil",
      label: _ctx.$t('statisticsEditViews.editViews'),
      class: "edit-views-button p-button-white p-button-oulined",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog = true))
    }, null, 8, ["label"]),
    _createVNode(_component_Dialog, {
      id: "statistics-edit-views-dialog",
      class: "custom-dialog",
      modal: true,
      visible: _ctx.dialog,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dialog) = $event)),
      onHide: _cache[6] || (_cache[6] = ($event: any) => (_ctx.dialog = false))
    }, {
      default: _withCtx(() => [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('statisticsEditViews.title')), 1),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_InputText, {
              id: "name",
              type: "text",
              placeholder: _ctx.$t('statisticsEditViews.newView'),
              modelValue: _ctx.name,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.name) = $event))
            }, null, 8, ["placeholder", "modelValue"])
          ]),
          _createVNode(_component_Button, {
            label: _ctx.$t('button.add'),
            icon: "spi-save",
            onClick: _ctx.onAddView,
            disabled: !_ctx.name
          }, null, 8, ["label", "onClick", "disabled"])
        ]),
        (_ctx.viewsActive.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("h6", null, _toDisplayString(_ctx.$t('statisticsEditViews.activeViews')), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('statisticsEditViews.orderViews')), 1),
              _createVNode(_component_draggable, _mergeProps({
                class: "views",
                modelValue: _ctx.viewsActive,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.viewsActive) = $event)),
                onStart: _cache[3] || (_cache[3] = ($event: any) => (_ctx.drag = true)),
                onEnd: _cache[4] || (_cache[4] = ($event: any) => (_ctx.drag = false)),
                "item-key": "value"
              }, _ctx.dragOptions), {
                item: _withCtx(({ element, index }) => [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_InputText, {
                      class: "label",
                      type: "text",
                      modelValue: _ctx.viewsActive[index].label,
                      "onUpdate:modelValue": ($event: any) => ((_ctx.viewsActive[index].label) = $event)
                    }, null, 8, ["modelValue", "onUpdate:modelValue"]),
                    _createVNode(_component_Button, {
                      icon: "pi pi-times",
                      class: "p-button-rounded p-button-danger p-button-text p-button-sm",
                      onClick: ($event: any) => (_ctx.onRemoveView(element))
                    }, null, 8, ["onClick"])
                  ])
                ]),
                _: 1
              }, 16, ["modelValue"])
            ]))
          : (_openBlock(), _createBlock(_component_Message, {
              key: 1,
              class: "no-data",
              severity: "info",
              closable: false
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('statisticsEditViews.noViewMessage')), 1)
              ]),
              _: 1
            })),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Button, {
            label: _ctx.$t('button.cancel'),
            class: "p-button-outlined",
            onClick: _ctx.onAbort
          }, null, 8, ["label", "onClick"]),
          _createVNode(_component_Button, {
            label: _ctx.$t('button.save'),
            onClick: _ctx.onSave
          }, null, 8, ["label", "onClick"])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}