import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "wip-view" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: require('@/assets/iconset/exclamation-triangle.svg')
    }, null, 8, _hoisted_2),
    _createElementVNode("h6", null, _toDisplayString(_ctx.$t('wipView.title')), 1)
  ]))
}