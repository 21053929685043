
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import {
  ApiStatisticsCountersHotelPayload,
  ApiStatisticsCountersHotelResponse,
} from '@/models/api';
import StatisticsCountersTable from '@/components/statistics/StatisticsCountersTable.vue';
import StatisticsDoughnutChart from '@/components/statistics/charts/StatisticsDoughnutChart.vue';
import ToggleButtonCustom from '@/components/shared/ToggleButtonCustom.vue';
import {
  StatisticCounterHotel,
  StatisticCountersTableColumn,
  StatisticDateMode,
  StatisticDoughnutChartModel,
} from '@/models/statistic';
import statisticService from '@/services/statisticService';

export default defineComponent({
  name: 'StatisticsCountersHotelView',
  components: {
    StatisticsCountersTable,
    StatisticsDoughnutChart,
    ToggleButtonCustom,
  },
  data() {
    return {
      selectedTab: 0,
      groupFilter: false,
      widthToggle: 130,
      trueLabel: this.$t('statisticsCounters.trueLabel'),
      falseLabel: this.$t('statisticsCounters.falseLabel'),
      tabs: [
        {
          label: this.$t('statisticsCounters.tab.tableView'),
          icon: 'pi pi-table',
        },
        {
          label: this.$t('statisticsCounters.tab.chartView'),
          icon: 'pi pi-chart-bar',
        },
      ],
      // table
      columns: [
        {
          label: this.$t('statisticsCountersHotelView.hotelName'),
          field: 'name',
        },
        {
          label: this.$t('statisticsCountersHotelView.reservations'),
          field: 'reservations',
        },
        {
          label: this.$t('statisticsCountersHotelView.duration'),
          field: 'duration',
        },
      ] as Array<StatisticCountersTableColumn>,
      counters: [] as Array<StatisticCounterHotel>,
      // charts
      reservationsChart: {
        label: this.$t('statisticsCountersHotelView.reservationsChart.label'),
        chartData: {
          labels: [
            this.$t(
              'statisticsCountersHotelView.reservationsChart.withHotelLabel',
            ),
            this.$t(
              'statisticsCountersHotelView.reservationsChart.withoutHotelLabel',
            ),
          ],
          datasets: [
            {
              data: [] as Array<number>,
              backgroundColor: ['#FFE596', '#E89300'],
            },
          ],
        },
      },
      umbrellasChart: {
        label: this.$t('statisticsCountersHotelView.umbrellasChart.label'),
        chartData: {
          labels: [
            this.$t(
              'statisticsCountersHotelView.umbrellasChart.withHotelLabel',
            ),
            this.$t(
              'statisticsCountersHotelView.umbrellasChart.withoutHotelLabel',
            ),
          ],
          datasets: [
            {
              data: [] as Array<number>,
              backgroundColor: ['#96DFFF', '#0083E8'],
            },
          ],
        },
      },
      bulkPiecesChart: {
        label: this.$t('statisticsCountersHotelView.bulkPiecesChart.label'),
        chartData: {
          labels: [
            this.$t(
              'statisticsCountersHotelView.bulkPiecesChart.withHotelLabel',
            ),
            this.$t(
              'statisticsCountersHotelView.bulkPiecesChart.withoutHotelLabel',
            ),
          ],
          datasets: [
            {
              data: [] as Array<number>,
              backgroundColor: ['#FF80BF', '#CB4DFF'],
            },
          ],
        },
      },
    };
  },
  beforeMount(): void {
    this.$store.commit(
      'statistic/setTitle',
      this.$t('statisticsCountersHotelView.setTitle'),
    );
    this.$store.commit(
      'statistic/setSubtitle',
      this.$t('statisticsCountersHotelView.setSubtitle'),
    );
    this.groupFilter = this.groupHotelData;
    this.runQuery();

    if (this.windowWidth < 992) {
      this.widthToggle = 108;
    } else {
      this.widthToggle = 130;
    }

    this.$store.dispatch('session/setLicense', this.license?.license);
  },
  methods: {
    async runQuery(): Promise<void> {
      try {
        const payload = {
          dateMode: StatisticDateMode.ATTENDANCE,
          startDate: this.calendar[0].toSeconds(),
          endDate: this.calendar[1].toSeconds(),
          groupData: this.groupFilter,
        } as ApiStatisticsCountersHotelPayload;
        const res: ApiStatisticsCountersHotelResponse =
          await statisticService.countersHotel(payload);
        this.counters = res.result.counters;
        this.reservationsChart.chartData.datasets[0].data =
          res.result.charts.reservations;
        this.umbrellasChart.chartData.datasets[0].data =
          res.result.charts.umbrellas;
        this.bulkPiecesChart.chartData.datasets[0].data =
          res.result.charts.bulkPieces;
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('statisticsCounters.toast.retrievalError'),
        );
      }
    },
  },
  computed: {
    ...mapState('statistic', ['calendar', 'groupHotelData']),
    ...mapState('app', ['windowWidth', 'windowHeight']),
    ...mapState('session', ['license']),
    hotelCountersReservationsChart(): StatisticDoughnutChartModel {
      const chartModel = {
        label: this.$t(
          'statisticsCountersHotelView.reservationsChartModelLabel',
        ),
        chartData: {
          labels: [] as Array<string>,
          datasets: [
            {
              data: [] as Array<number>,
              backgroundColor: ['#CB4DFF', '#242424', '#964B00', '#AFAFAF'],
            },
          ],
        },
      };
      if (!this.counters.length) {
        return chartModel;
      }
      let aggregate = [] as Array<StatisticCounterHotel>;
      const orderedCounters = cloneDeep(this.counters).sort(
        // eslint-disable-next-line no-confusing-arrow
        (a: StatisticCounterHotel, b: StatisticCounterHotel) =>
          a.reservations < b.reservations ? 1 : -1,
      );
      // max 4 values
      if (orderedCounters.length > 4) {
        aggregate = orderedCounters.splice(3);
      }
      orderedCounters.map((counter: StatisticCounterHotel) => {
        chartModel.chartData.labels.push(counter.name);
        chartModel.chartData.datasets[0].data.push(counter.reservations);
      });
      if (aggregate.length > 0) {
        chartModel.chartData.labels.push(this.$t('common.other'));
        chartModel.chartData.datasets[0].data.push(
          aggregate.reduce(
            (count: number, counter: StatisticCounterHotel) =>
              count + counter.reservations,
            0,
          ),
        );
      }
      return chartModel;
    },
    hotelCountersDurationChart(): StatisticDoughnutChartModel {
      const chartModel = {
        label: this.$t('statisticsCountersHotelView.durationChartModelLabel'),
        chartData: {
          labels: [] as Array<string>,
          datasets: [
            {
              data: [] as Array<number>,
              backgroundColor: ['#FF80BF', '#199CFF', '#C7EA46', '#AFAFAF'],
            },
          ],
        },
      };
      if (!this.counters.length) {
        return chartModel;
      }
      let aggregate = [] as Array<StatisticCounterHotel>;
      const orderedCounters = cloneDeep(this.counters).sort(
        // eslint-disable-next-line no-confusing-arrow
        (a: StatisticCounterHotel, b: StatisticCounterHotel) =>
          a.duration < b.duration ? 1 : -1,
      );
      // max 4 values
      if (orderedCounters.length > 4) {
        aggregate = orderedCounters.splice(3);
      }
      orderedCounters.map((counter: StatisticCounterHotel) => {
        chartModel.chartData.labels.push(counter.name);
        chartModel.chartData.datasets[0].data.push(counter.duration);
      });
      if (aggregate.length > 0) {
        chartModel.chartData.labels.push(this.$t('common.other'));
        chartModel.chartData.datasets[0].data.push(
          aggregate.reduce(
            (count: number, counter: StatisticCounterHotel) =>
              count + counter.duration,
            0,
          ),
        );
      }
      return chartModel;
    },
    groupDataFilter: {
      set(value: boolean) {
        this.groupFilter = !value;
        this.$store.commit('statistic/setGroupHotelData', this.groupFilter);
        this.runQuery();
      },
      get(): boolean {
        return !this.groupFilter;
      },
    },
  },
  watch: {
    calendar() {
      if (this.calendar[1] === null) return;
      this.runQuery();
    },
    windowWidth() {
      if (this.windowWidth === null) return;
      if (this.windowWidth < 992) this.widthToggle = 108;
      else this.widthToggle = 130;
    },
  },
});
