import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12 sm:col-6" }
const _hoisted_3 = { class: "p-field name-field" }
const _hoisted_4 = { for: "name-input" }
const _hoisted_5 = {
  key: 0,
  class: "p-error"
}
const _hoisted_6 = { class: "col-12 sm:col-6" }
const _hoisted_7 = { class: "p-field list-days-length-field" }
const _hoisted_8 = { for: "list-days-length-input" }
const _hoisted_9 = { class: "col-12 sm:col-6" }
const _hoisted_10 = { class: "p-field extra-days-mode-field" }
const _hoisted_11 = { for: "extra-days-mode-dropdown" }
const _hoisted_12 = { class: "col-12 sm:col-6" }
const _hoisted_13 = { class: "p-field period-shift-mode-field" }
const _hoisted_14 = { for: "period-shift-mode-dropdown" }
const _hoisted_15 = {
  ref: "submit",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Field = _resolveComponent("Field")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputTooltip = _resolveComponent("InputTooltip")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    id: "price-lists-price-list-create",
    class: "dialog",
    modal: "",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.visible) = $event))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.priceList
          ? _ctx.$t('priceListsPriceListCreate.editPriceList')
          : _ctx.$t('priceListsPriceListCreate.newPriceList')), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-outlined",
        label: _ctx.$t('button.cancel'),
        icon: "spi-times",
        onClick: _ctx.close
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('button.save'),
        icon: "spi-check",
        onClick: _cache[4] || (_cache[4] = ($event: any) => ((_ctx.$refs.submit as HTMLButtonElement).click()))
      }, null, 8, ["label"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        ref: "form",
        onSubmit: _ctx.onSave
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Field, {
                name: "name",
                modelValue: _ctx.model.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event)),
                rules: "required"
              }, {
                default: _withCtx(({ field, meta, errorMessage }) => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('priceListsPriceListCreate.name')), 1),
                    _createVNode(_component_InputText, _mergeProps({ id: "name-input" }, field, {
                      type: "text",
                      class: !meta.valid && meta.touched ? 'p-invalid' : ''
                    }), null, 16, ["class"]),
                    (!meta.valid && meta.touched)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(errorMessage), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('priceListsPriceListCreate.listDaysLength')) + ":", 1),
                _createVNode(_component_InputNumber, {
                  id: "list-days-length-input",
                  modelValue: _ctx.model.listDaysLength,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.listDaysLength) = $event)),
                  step: 1,
                  min: 0,
                  max: 999,
                  allowEmpty: false
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("label", _hoisted_11, [
                  _createTextVNode(_toDisplayString(_ctx.$t('priceListsPriceListCreate.extraDaysMode')) + ": ", 1),
                  _createVNode(_component_InputTooltip, {
                    message: _ctx.$t('priceListDialog.tooltip.extra')
                  }, null, 8, ["message"])
                ]),
                _createVNode(_component_Dropdown, {
                  id: "extra-days-mode-dropdown",
                  modelValue: _ctx.model.extraDaysMode,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.extraDaysMode) = $event)),
                  options: _ctx.extraDaysModeOptions,
                  optionLabel: "label",
                  optionValue: "value"
                }, null, 8, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("label", _hoisted_14, [
                  _createTextVNode(_toDisplayString(_ctx.$t('priceListsPriceListCreate.periodsShiftMode')) + ": ", 1),
                  _createVNode(_component_InputTooltip, {
                    message: _ctx.$t('priceListDialog.tooltip.period')
                  }, null, 8, ["message"])
                ]),
                _createVNode(_component_Dropdown, {
                  id: "period-shift-mode-dropdown",
                  modelValue: _ctx.model.periodsShiftMode,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.periodsShiftMode) = $event)),
                  options: _ctx.periodsShiftModeOptions,
                  optionLabel: "label",
                  optionValue: "value"
                }, null, 8, ["modelValue", "options"])
              ])
            ]),
            _withDirectives(_createElementVNode("button", _hoisted_15, null, 512), [
              [_vShow, false]
            ])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }, 8, ["visible"]))
}