
import { PropType, defineComponent } from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { Printer } from '@/models/printer';
import { License } from '@/models/license';
import cookieUtil from '@/utils/cookieUtil';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';
import { SectorHeader } from '@/models/sector';

export default defineComponent({
  name: 'CashFlows',
  emits: [
    'abort',
    'fiscalPrint',
    'invoicePayment',
    'nonFiscalPrint',
    'noPrint',
  ],
  props: {
    hideFiscal: {
      type: Boolean,
      default: false,
    },
    hideNonFiscal: {
      type: Boolean,
      default: false,
    },
    hideNoPrint: {
      type: Boolean,
      default: false,
    },
    hideInvoice: {
      type: Boolean,
      default: false,
    },
    disableFiscal: {
      type: Boolean,
      default: false,
    },
    disableNonFiscal: {
      type: Boolean,
      default: false,
    },
    disableNoPrint: {
      type: Boolean,
      default: false,
    },
    disableInvoice: {
      type: Boolean,
      default: false,
    },
    sector: {
      type: Object as PropType<SectorHeader>,
      require: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      printerPickerDialog: false,
      printersPicker: [] as Array<Printer>,
      selectedPrinter: null as unknown as Printer,
      emitEvent: '',
    };
  },
  methods: {
    onAbort(): void {
      /**
       * Custom close button for handle 'abort-emit' because
       * default @hide listener is triggered also with dialog model
       */
      this.$emit('abort');
      this.printerPickerDialog = false;
    },
    onFiscalPrint(): void {
      if (this.fiscalPrinters.length > 1) {
        this.printersPicker = cloneDeep(this.fiscalPrinters);
        this.printerPickerDialog = true;
        this.emitEvent = 'fiscalPrint';
      } else {
        this.$emit('fiscalPrint', this.fiscalPrinters[0]);
      }
    },
    onNonFiscalPrint(): void {
      if (this.nonFiscalPrinters.length > 1) {
        this.printersPicker = cloneDeep(this.nonFiscalPrinters);
        this.printerPickerDialog = true;
        this.emitEvent = 'nonFiscalPrint';
      } else {
        this.$emit('nonFiscalPrint', this.nonFiscalPrinters[0]);
      }
    },
    onNoPrint(): void {
      this.$emit('noPrint');
    },
    onSelectPrinter(printer: Printer): void {
      this.printerPickerDialog = false;
      this.$emit(this.emitEvent as any, printer);
    },
    onInvoicePayment() {
      this.$emit('invoicePayment');
    },
    hasPermission(action: string): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.reservations,
        action,
      );
    },
  },
  computed: {
    isFiscalPrinting(): boolean {
      return this.$store.state.reservation.isFiscalPrinting;
    },
    FEATURE_PERMISSION_ACTION_CONFIG() {
      return FEATURE_PERMISSION_ACTION_CONFIG;
    },
    license(): License {
      return this.$store.getters['session/license'];
    },
    fiscalPrinters(): Array<Printer> {
      let printers = this.$store.getters['session/fiscalPrinters'].filter(
        (p: Printer) => !cookieUtil.get(`spit_prt-hide-${p.id}`),
      );
      if (this.sector && this.sector?.id !== 0) {
        // eslint-disable-next-line no-confusing-arrow
        printers = printers.filter((p: Printer) =>
          p.sectors && p.sectors.length > 0
            ? p.sectors.find((s: SectorHeader) => s.id === this.sector?.id)
            : true,
        );
      }
      return printers;
    },
    nonFiscalPrinters(): Array<Printer> {
      let printers = this.$store.getters['session/nonFiscalPrinters'].filter(
        (p: Printer) => !cookieUtil.get(`spit_prt-hide-${p.id}`),
      );
      if (this.sector && this.sector?.id !== 0) {
        // eslint-disable-next-line no-confusing-arrow
        printers = printers.filter((p: Printer) =>
          p.sectors && p.sectors.length > 0
            ? p.sectors.find((s: SectorHeader) => s.id === this.sector?.id)
            : true,
        );
      }
      return printers;
    },
  },
});
