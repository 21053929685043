/**
 * Printer service
 */

import axios, { AxiosResponse } from 'axios';
import { ApiCardTypeListResponse, ApiCardTypeCreateResponse, ApiCardTypeUpdateResponse } from '@/models/api';
import { CardType } from '@/models/cardType';
import apiService from '@/services/apiService';

export default {
  async list(): Promise<ApiCardTypeListResponse> {
    const res: AxiosResponse<ApiCardTypeListResponse> = await axios.get(
      apiService.cardtype.list(),
    );
    return res.data;
  },
  async create(payload: FormData):Promise<CardType> {
    const res: AxiosResponse<ApiCardTypeCreateResponse> = await axios.post(
      apiService.cardtype.create(),
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res.data.result.cardType;
  },
  async update(id: number, payload: FormData): Promise<CardType> {
    const res: AxiosResponse<ApiCardTypeUpdateResponse> = await axios.post(
      apiService.cardtype.update(id),
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return res.data.result.cardType;
  },
};
