
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
import logService from '@/services/logService';
import { Log } from '@/models/log';
import { ApiLogFindPayload } from '@/models/api';
import { OrderByItem } from '@/models';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

export default defineComponent({
  name: 'ReservationLogs',
  data() {
    return {
      show: false,
      logs: [] as Array<Log>,
      FEATURE_PERMISSION_ACTION_CONFIG,
    };
  },
  methods: {
    getLogDate(log: Log): string {
      return DateTime.fromSeconds(log.createdAt)
        .setZone('Europe/Rome')
        .toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
    },
    getLogLabel(log: Log) {
      return this.$te(`logAction.${log.action}`)
        ? this.$t(`logAction.${log.action}`)
        : log.action;
    },
    getLogValue(log: Log): string {
      if (!log) {
        return '';
      }
      if (log.action === 'fiscal-printer-request') {
        return '';
      }
      return `: ${log.value}`;
    },
    hasPermission(action: string): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.reservations,
        action,
      );
    },
    async onLogsDialog(): Promise<void> {
      const res = await logService.find({
        reservationId: this.id,
        orderBy: [
          {
            field: 'createdAt',
            value: 'desc',
          } as OrderByItem,
        ],
      } as ApiLogFindPayload);
      this.logs = res.result.logs;
      this.show = true;
    },
  },
  computed: {
    ...mapState('reservation', ['id']),
  },
});
