
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
import { PricePeriod } from '@/models/priceList';
import toastUtil from '@/utils/toastUtil';
import { ToastSeverity } from '@/models/toast';
import priceListService from '@/services/priceListService';
import InputTooltip from '@/components/shared/InputTooltip.vue';

interface PeriodToSelect {
  id: number;
  weekend: boolean;
  name: string;
}

export default defineComponent({
  name: 'ListUploadDialog',
  emits: ['success'],
  components: {
    InputTooltip,
  },
  props: {
    pricePeriods: {
      type: Array as () => Array<PricePeriod>,
      required: true,
    },
    pricePeriod: {
      type: Object as () => PricePeriod,
      required: true,
    },
    weekend: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formatOptions: [
        {
          value: ',',
          label: this.$t('priceUploadDialog.formatOption.american'),
        },
        {
          value: ';',
          label: this.$t('priceUploadDialog.formatOption.italian'),
        },
      ],
      format: ',',
      file: null as File | null,
      previousSelected: null as PeriodToSelect | null,
    };
  },
  computed: {
    ...mapState('session', ['license']),
    selectables(): Array<PeriodToSelect> {
      const toReturn = [] as Array<PeriodToSelect>;
      const periods = this.pricePeriods
        .slice(0)
        .filter((p) => p.id !== this.pricePeriod.id)
        .sort(
          (a, b) =>
            DateTime.fromFormat(a.startDate, 'dd-MM').toSeconds() -
            DateTime.fromFormat(b.startDate, 'dd-MM').toSeconds(),
        );
      // eslint-disable-next-line no-restricted-syntax
      for (const p of periods) {
        toReturn.push({
          id: p.id,
          weekend: false,
          name: p.name,
        });
        if (p.useWeekend) {
          toReturn.push({
            id: p.id,
            weekend: true,
            name: p.name,
          });
        }
      }
      return toReturn;
    },
  },
  methods: {
    selectPrevious(previous: PeriodToSelect): void {
      this.previousSelected = previous;
      this.file = null;
    },
    onFileSelectClick(e: any): void {
      (this.$refs['list-file-upload'] as HTMLElement).click();
    },
    async onFileSelect(event: any): Promise<void> {
      this.file = event.target.files[0];
      this.previousSelected = null;
    },
    async onFormSubmit(): Promise<void> {
      if (!this.file && !this.previousSelected) return;
      if (this.file) {
        const payload = new FormData();
        payload.append('weekend', `${+this.weekend}`);
        payload.append('delimiter', this.format);
        payload.append('listing', this.file);
        try {
          const response = await priceListService.importPeriod(
            this.pricePeriod.id,
            payload,
          );
          // Upload listing
          this.$toast.add(
            toastUtil.build(
              ToastSeverity.SUCCESS,
              this.$t('toast.save.title'),
              this.$t('toast.save.content'),
            ),
          );
          this.$emit('success', response.result.priceCombinations);
        } catch (e) {
          // console.log(e);
        }
      } else {
        try {
          const payload = {
            weekend: this.weekend,
            copyId: this.previousSelected?.id ?? 0,
            copyWeekend: this.previousSelected?.weekend ?? false,
          };
          const response = await priceListService.copyPeriod(
            this.pricePeriod.id,
            payload,
          );
          // Upload listing
          this.$toast.add(
            toastUtil.build(
              ToastSeverity.SUCCESS,
              this.$t('toast.save.title'),
              this.$t('toast.save.content'),
            ),
          );
          this.$emit('success', response.result.priceCombinations);
        } catch (e) {
          // console.log(e);
        }
      }
    },
  },
});
