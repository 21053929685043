/**
 * License service
 */

import axios, { AxiosResponse } from 'axios';
import {
  ApiLicenseResponse,
  ApiLicenseListResponse,
  ApiLicenseLogoResponse,
  ApiLicenseMapBgResponse,
  ApiLicenseDocumentTemplatesResponse,
  ApiLicenseUpdatePayload,
  ApiLicenseUpdateResponse,
  ApiBookingSectorsRulesResponse,
  ApiBookingSectorsRuleResponse,
  ApiLicenseImgSectorsResponse,
  ApiStripeResponse,
  ApiStripeFinalizeResponse,
  ApiStripeAccountInitConnectionResponse,
} from '@/models/api';
import apiService from './apiService';
import {
  License,
  LicenseDocumentTemplate,
  LicenseList,
  PayloadBeachSettings,
  PayloadBookingSettings,
  PayloadPriceListSettings,
  PayloadSeasonalSettings,
  StripeAccountInitResponse,
  StripeResponse,
} from '@/models/license';
import { StripePayload } from '@/models';
import { BookingRule } from '@/models/booking';

export default {
  async list(): Promise<Array<LicenseList>> {
    const res: AxiosResponse<ApiLicenseListResponse> = await axios.get(
      apiService.license.list(),
    );
    return res.data.result.licenses;
  },
  async one(id: string): Promise<ApiLicenseResponse> {
    const res: AxiosResponse<ApiLicenseResponse> = await axios.get(
      apiService.license.one(id),
    );
    return res.data;
  },
  async update(id: string, license: License): Promise<ApiLicenseResponse> {
    const res: AxiosResponse<ApiLicenseResponse> = await axios.patch(
      apiService.license.one(id),
      license,
    );
    return res.data;
  },
  // a different method to update with a payload (full or partial) intead using a full license
  async updateNew(payload: ApiLicenseUpdatePayload): Promise<License> {
    const res: AxiosResponse<ApiLicenseUpdateResponse> = await axios.patch(
      apiService.license.update(),
      payload,
    );
    return res.data.result.license;
  },
  async updateBookingSettings(
    id: string,
    license: PayloadBookingSettings,
  ): Promise<ApiLicenseResponse> {
    const res: AxiosResponse<ApiLicenseResponse> = await axios.patch(
      apiService.license.one(id),
      license,
    );
    return res.data;
  },
  async updateBeachSettings(
    id: string,
    license: PayloadBeachSettings,
  ): Promise<ApiLicenseResponse> {
    const res: AxiosResponse<ApiLicenseResponse> = await axios.patch(
      apiService.license.one(id),
      license,
    );
    return res.data;
  },
  async updatePricesSettings(
    id: string,
    license: PayloadPriceListSettings,
  ): Promise<ApiLicenseResponse> {
    const res: AxiosResponse<ApiLicenseResponse> = await axios.patch(
      apiService.license.one(id),
      license,
    );
    return res.data;
  },
  // TODO remove
  async updateSeasonalSettings(
    id: string,
    license: PayloadSeasonalSettings,
  ): Promise<ApiLicenseResponse> {
    const res: AxiosResponse<ApiLicenseResponse> = await axios.patch(
      apiService.license.one(id),
      license,
    );
    return res.data;
  },
  async logo(license: string, file: unknown): Promise<ApiLicenseLogoResponse> {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    const res: AxiosResponse<ApiLicenseLogoResponse> = await axios.post(
      apiService.license.logo(license),
      file,
      config,
    );
    return res.data;
  },
  async sectorImage(
    license: string,
    file: unknown,
  ): Promise<ApiLicenseImgSectorsResponse> {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    const res: AxiosResponse<ApiLicenseImgSectorsResponse> = await axios.post(
      apiService.license.sectorImage(license),
      file,
      config,
    );
    return res.data;
  },
  async mapBackground(
    license: string,
    file: unknown,
  ): Promise<ApiLicenseMapBgResponse> {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    const res: AxiosResponse<ApiLicenseMapBgResponse> = await axios.post(
      apiService.license.mapBg(license),
      file,
      config,
    );
    return res.data;
  },
  async documentTemplates(): Promise<Array<LicenseDocumentTemplate>> {
    const res: AxiosResponse<ApiLicenseDocumentTemplatesResponse> =
      await axios.get(apiService.license.documentTemplates());
    return res.data.result.documentTemplates;
  },
  async getBookingSectorsRules(id: string): Promise<Array<BookingRule>> {
    const res: AxiosResponse<ApiBookingSectorsRulesResponse> = await axios.get(
      apiService.license.rules(id),
    );
    return res.data.result.bookingRules;
  },
  async addBookingSectorsRule(
    id: string,
    rule: BookingRule,
  ): Promise<BookingRule> {
    const res: AxiosResponse<ApiBookingSectorsRuleResponse> = await axios.post(
      apiService.license.rules(id),
      rule,
    );
    return res.data.result.bookingRule;
  },
  async removeBookingSectorsRule(id: string, ruleId: string): Promise<string> {
    await axios.delete(apiService.license.rule(id, ruleId));
    return 'success';
  },
  async updateBookingSectorsRule(
    id: string,
    rule: BookingRule,
  ): Promise<BookingRule> {
    const res: AxiosResponse<ApiBookingSectorsRuleResponse> = await axios.patch(
      apiService.license.rule(id, rule.id.toString()),
      rule,
    );
    return res.data.result.bookingRule;
  },
  async stripeConnectionInit(
    id: string,
    paylod: StripePayload,
  ): Promise<StripeResponse> {
    const res: AxiosResponse<ApiStripeResponse> = await axios.post(
      apiService.license.stripe(id),
      paylod,
    );
    return res.data.result.link;
  },
  async stripePosAccountInitConnection(
    id: string,
    payload: StripePayload,
  ): Promise<StripeAccountInitResponse> {
    const res: AxiosResponse<ApiStripeAccountInitConnectionResponse> = await axios.post(
      apiService.license.stripePosAccountInitConnection(id),
      payload,
    );
    return res.data.result;
  },
  async stripeConnectionFinalize(
    id: string,
    paylod: StripePayload,
  ): // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Promise<any> {
    const res: AxiosResponse<ApiStripeFinalizeResponse> = await axios.post(
      apiService.license.finalize(id),
      paylod,
    );
    return res.data.result;
  },
  async stripePosAccountFinalizeConnection(
    id: string,
    payload: StripePayload,
  ):
  Promise<void> {
      await axios.post(
      apiService.license.stripePosAccountFinalizeConnection(id),
      payload,
    );
  },
  async backupJson(): Promise<Blob> {
    const res: AxiosResponse<Blob> = await axios.get(
      apiService.license.backupJson(),
      {
        headers: {
          Accept: 'text/json',
        },
      },
    );
    return new Blob([JSON.stringify(res.data)]);
  },
  async importJson(file: unknown): Promise<string> {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    const res: AxiosResponse<string> = await axios.post(
      apiService.license.importJson(),
      file,
      config,
    );
    return res.data;
  },
};
