
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { RouteLocationRaw } from 'vue-router';

export default defineComponent({
  name: 'AsideContentLayout',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sidebar: false,
    };
  },
  methods: {
    onSplitButton(index: string): void {
      const splitButtonElement: HTMLElement = (
        this.$refs[`split-button-${index}`] as any
      ).$el;
      const menuButton = splitButtonElement.children[1] as HTMLButtonElement;
      menuButton.click();
    },
  },
  computed: {
    ...mapState('app', ['breakpoints']),
  },
});
