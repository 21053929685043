import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f3dc5bc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "layout-aside-content" }
const _hoisted_2 = { id: "aside" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("aside", _hoisted_2, [
      (_ctx.menu)
        ? (_openBlock(), _createBlock(_component_Menu, {
            key: 0,
            data: _ctx.menu
          }, null, 8, ["data"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", {
      id: "content",
      class: _normalizeClass(this.showPadding ? 'price-wizard-container' : '')
    }, [
      _renderSlot(_ctx.$slots, "content", {}, undefined, true)
    ], 2)
  ]))
}