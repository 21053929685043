/**
 * Booking service
 */

/**
 * TODO list
 * Fix resevation (spelling)
 * Service name must be the same of api service
 * Remove license from parameters (handled by api service)
 * Remove resevationsList (reservationService already have a find service)
 * Fix/check interfaces (see booking.ts model)
 */

import axios, { AxiosResponse } from 'axios';
import {
  ApiDiscountMessageResponse,
  ApiDiscountResponse,
  ApiDiscountsListResponse,
  ApiOfferMessageResponse,
  ApiOfferResponse,
  ApiOffersListResponse,
  ApiOfferUpdatePayload,
  ApiOfferCreatePayload,
  ApiReservationArrayResponse,
  ApiReservationListResponse,
  ApiReservationMergeResponse,
} from '@/models/api';
import apiService from './apiService';
import {
  Discount,
  Offer,
  ReservationBooking,
  Reservations,
} from '@/models/booking';
import { Reservation } from '@/models/reservation';

export default {
  async resevationsList(url: string): Promise<Array<ReservationBooking>> {
    const res: AxiosResponse<ApiReservationListResponse> = await axios.get(
      apiService.reservation.reservationsContacts() + url,
    );
    return res.data.result.reservations;
  },

  async resevationsContacts(url: string): Promise<ApiReservationArrayResponse> {
    const res: AxiosResponse<ApiReservationArrayResponse> = await axios.get(
      apiService.reservation.reservationsContacts() + url,
    );
    return res.data;
  },
  async resevationsMerge(reservations: Reservations): Promise<Reservation> {
    const res: AxiosResponse<ApiReservationMergeResponse> = await axios.post(
      apiService.reservation.mergeReservations(),
      reservations,
    );
    return res.data.result.reservation;
  },

  async offerList(): Promise<Array<Offer>> {
    const res: AxiosResponse<ApiOffersListResponse> = await axios.get(
      apiService.offers.list(),
    );
    return res.data.result.offers;
  },
  async addNewOffer(payload: ApiOfferCreatePayload): Promise<Offer> {
    const res: AxiosResponse<ApiOfferResponse> = await axios.post(
      apiService.offers.create(),
      payload,
    );
    return res.data.result.offer;
  },
  async updateOffer(
    id: number,
    payload: ApiOfferUpdatePayload,
  ): Promise<Array<Offer>> {
    const res: AxiosResponse<ApiOffersListResponse> = await axios.patch(
      apiService.offers.update(id),
      payload,
    );
    return res.data.result.offers;
  },
  async deleteOffer(offerId: number): Promise<string> {
    const res: AxiosResponse<ApiOfferMessageResponse> = await axios.delete(
      apiService.offers.delete(offerId),
    );
    return res.data.result;
  },
  async discountList(): Promise<Array<Discount>> {
    const res: AxiosResponse<ApiDiscountsListResponse> = await axios.get(
      `${apiService.discountCodes.list()}?expand=refund&orderBy=id_desc`,
    );
    return res.data.result.discountCodes;
  },
  async addNewDiscount(discount: Discount): Promise<Discount> {
    const res: AxiosResponse<ApiDiscountResponse> = await axios.post(
      apiService.discountCodes.create(),
      discount,
    );
    return res.data.result.discountCode;
  },
  async updateDiscount(discount: Discount): Promise<Discount> {
    const res: AxiosResponse<ApiDiscountResponse> = await axios.patch(
      apiService.discountCodes.update(discount.id),
      discount,
    );
    return res.data.result.discountCode;
  },
  async deleteDiscount(discountId: number): Promise<string> {
    const res: AxiosResponse<ApiDiscountMessageResponse> = await axios.delete(
      apiService.discountCodes.delete(discountId),
    );
    return res.data.result;
  },
};
