
import { defineComponent } from 'vue';
import { cloneDeep } from 'lodash';
import UploadImg from '@/components/shared/UploadImg.vue';
import { License } from '@/models/license';
import { CardType, CardTypeWork } from '@/models/cardType';
import { ToastSeverity } from '@/models/toast';
import toastUtil from '@/utils/toastUtil';
import cardTypeService from '@/services/cardTypeService';
import { ApiCardTypeListResponse } from '@/models/api';

const CARD_TYPE_MODEL_DEFAULT = {
  cardImage: '',
  name: '',
  emitter: 0,
  exchangeRate: 1,
  overdraftLimit: 0,
  idWorldCardType: 0,
} as CardType;

export default defineComponent({
  components: { UploadImg },
  data() {
    return {
      cardType: cloneDeep(CARD_TYPE_MODEL_DEFAULT),
      cardTypeDialog: false,
      isEdit: false,
      cardTypes: [] as Array<CardType>,
      extension: 'image/*',
      size: 1000000,
      file: {} as any,
      workOptions: [
        {
          label: '',
          value: 0,
        },
      ],
    };
  },
  computed: {
    license(): License | null {
      return this.$store.getters['session/license'];
    },
  },
  methods: {
    cardTypeCreate(): void {
      this.undo();
      this.cardTypeDialog = true;
      this.isEdit = false;
      this.cardType.emitter = this.cardTypes[0].emitter;
    },
    cardTypeEdit(cardType: CardType): void {
      this.isEdit = true;
      this.cardType = cloneDeep(cardType);
      this.cardTypeDialog = true;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async catchFile(file: any) {
      this.file = file;
    },
    async submit() {
      const data = new FormData();
      // per comodità usiamo lo stesso nome della prop. per inviare il file
      if (Object.keys(this.file).length > 0) {
        data.append('cardImage', this.file);
      }
      data.append('name', this.cardType.name);
      data.append('emitter', this.cardType.emitter.toString());
      data.append('exchangeRate', this.cardType.exchangeRate.toString());
      data.append('overdraftLimit', this.cardType.overdraftLimit.toString());
      try {
        const cardType: CardType = await cardTypeService.create(data);
        this.cardTypes.push(cardType);
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
        this.cardType = cloneDeep(CARD_TYPE_MODEL_DEFAULT);
        this.cardTypeDialog = false;
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
      this.file = {};
    },
    async edit() {
      const data = new FormData();
      if (Object.keys(this.file).length > 0) {
        data.append('cardImage', this.file);
      }
      data.append('name', this.cardType.name);
      data.append('emitter', this.cardType.emitter.toString());
      data.append('exchangeRate', this.cardType.exchangeRate.toString());
      data.append('overdraftLimit', this.cardType.overdraftLimit.toString());
      try {
        const cardType: CardType = await cardTypeService.update(
          this.cardType.idWorldCardType,
          data,
        );
        // eslint-disable-next-line no-inner-declarations
        const idres = this.cardTypes.findIndex(
          (c: CardType) => c.idWorldCardType === this.cardType.idWorldCardType,
        );
        this.cardTypes[idres] = cardType;
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
        this.cardType = cloneDeep(CARD_TYPE_MODEL_DEFAULT);
        this.cardTypeDialog = false;
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
      this.file = {};
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    undo() {
      this.cardType = cloneDeep(CARD_TYPE_MODEL_DEFAULT);
      this.file = {};
      this.cardTypeDialog = false;
    },
    getCardTypeImageSource(cardType: CardType) {
      if (!cardType.cardImage) {
        return require('@/assets/images/placeholders/card.svg');
      }
      return this.cardsUrlControl(cardType.cardImage);
    },
    cardsUrlControl(url: string): string {
      const check = url.includes('http');
      let result = url;
      if (!check) {
        result = `https://www.anm22.it/app/pay/utilities/img/cards/${url}`;
      }
      return result;
    },
  },
  async beforeMount() {
    try {
      const res: ApiCardTypeListResponse = await cardTypeService.list();
      this.workOptions = res.result.works.map((work: CardTypeWork) => ({
        label: work.name,
        value: work.id,
      }));
      this.cardTypes = res.result.cardTypes;
    } catch (error) {
      // console.log(error);
    }
  },
});
