import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-3 pl-4" }
const _hoisted_2 = { class: "col-12 mt-2" }
const _hoisted_3 = { class: "p-field-radio vertical col-12 sm:col-6 pl-0 mt-2" }
const _hoisted_4 = { class: "p-field-radiobutton" }
const _hoisted_5 = { class: "p-field-radiobutton" }
const _hoisted_6 = { class: "p-field" }
const _hoisted_7 = { class: "col-12 mt-2" }
const _hoisted_8 = { class: "p-field-radio vertical col-12 sm:col-6 pl-0 mt-2" }
const _hoisted_9 = { class: "p-field-radiobutton" }
const _hoisted_10 = { class: "p-field-radiobutton" }
const _hoisted_11 = { class: "p-field" }
const _hoisted_12 = { class: "flex p-3 justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    id: "settings-ticket-limits-dialog",
    modal: true
  }, {
    header: _withCtx(() => [
      _createElementVNode("h6", _hoisted_1, _toDisplayString(_ctx.$t('settingsServiceGroupView.settingsDialog.title')), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_12, [
        _createVNode(_component_Button, {
          class: "p-button-outlined",
          label: _ctx.$t('button.cancel'),
          icon: "spi-times",
          onClick: _cache[10] || (_cache[10] = ($event: any) => (this.visible = false))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          id: "service-group-modal-save-button",
          onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.onSaveSettings())),
          icon: "pi pi-save",
          class: "pt-2 pb-2 pl-3 pr-3",
          label: _ctx.$t('button.save')
        }, null, 8, ["label"])
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('settingsServiceGroupView.settingsDialog.dailyLimitLabel')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_RadioButton, {
              value: false,
              modelValue: _ctx.hasDailyLimit,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.hasDailyLimit) = $event))
            }, null, 8, ["modelValue"]),
            _createElementVNode("label", {
              class: "cursor-pointer",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hasDailyLimit = false))
            }, _toDisplayString(_ctx.$t('common.unlimited')), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_RadioButton, {
              value: true,
              modelValue: _ctx.hasDailyLimit,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.hasDailyLimit) = $event))
            }, null, 8, ["modelValue"]),
            _createElementVNode("label", {
              class: "cursor-pointer",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hasDailyLimit = true))
            }, _toDisplayString(_ctx.$t('common.limited')), 1),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_InputNumber, {
                id: "input-license-reservation-limit",
                step: 1,
                min: 0,
                class: "col-6 pt-0 pb-0",
                modelValue: this.model.ticketMaximumCapacity,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((this.model.ticketMaximumCapacity) = $event))
              }, null, 8, ["modelValue"])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('settingsServiceGroupView.settingsDialog.reservationLimitLabel')), 1),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_component_RadioButton, {
              value: false,
              modelValue: _ctx.hasReservationLimit,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.hasReservationLimit) = $event))
            }, null, 8, ["modelValue"]),
            _createElementVNode("label", {
              for: "use-cabin-radio",
              class: "cursor-pointer",
              onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.hasReservationLimit = false))
            }, _toDisplayString(_ctx.$t('common.unlimited')), 1)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_RadioButton, {
              value: true,
              modelValue: _ctx.hasReservationLimit,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.hasReservationLimit) = $event))
            }, null, 8, ["modelValue"]),
            _createElementVNode("label", {
              for: "use-cabin-radio",
              class: "cursor-pointer",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.hasReservationLimit = true))
            }, _toDisplayString(_ctx.$t('common.limited')), 1),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_InputNumber, {
                id: "input-daily-items",
                step: 1,
                min: 0,
                class: "col-6 pt-0 pb-0",
                modelValue: this.model.ticketReservationLimit,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((this.model.ticketReservationLimit) = $event))
              }, null, 8, ["modelValue"])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}