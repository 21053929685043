
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { BEACH_TICKET_SERVICE_ID, Service } from '@/models/service';
import { License } from '@/models/license';
import {
  Printer,
  PrintMode,
  PrintArticle,
  Print,
  PrinterResponse,
} from '@/models/printer';
import { DeviceSpecs } from '@/models/store';
import CashFlows from '@/components/shared/CashFlows.vue';
import { CashFlow, CashFlowMethod } from '@/models/cashFlow';
import {
  Reservation,
  ReservationType,
  ReservationService,
} from '@/models/reservation';
import {
  CashDeskPayload,
  CashDeskArticle,
  CashDeskCashFlow,
} from '@/models/cashdesk';
import cashdeskService from '@/services/cashdeskService';
import cookieUtil from '@/utils/cookieUtil';
import ExtentionChromeMessage from '@/components/shared/ExtentionChromeMessage.vue';
import PosTerminalPayment from '@/components/shared/PosTerminalPayment.vue';
import WebticTicketEmission from '@/components/shared/WebticTicketEmission.vue';
import {
  ApiStripeCreatePaymentIntentPayload,
  ApiWebticOnsiteEmitTicketPayload,
} from '@/models/api';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';
import { StripePaymentIntent } from '@/models/stripe';
import cashFlowService from '@/services/cashFlowService';
import { ServiceGroup } from '@/models/serviceGroup/serviceGroup';
import {
  LimitInformation,
  ServiceGroupInfo,
  SingleServiceInfo,
} from '@/models/service/serviceInformation';

interface Product {
  quantity: number;
  name: string;
  cost: number;
  unitCost: number;
  vat: number;
  id: number;
  code?: string;
}

interface FixProduct {
  id: number;
  name: string;
  cost: number;
  code: string;
}

export default defineComponent({
  name: 'CashDeskView',
  components: {
    CashFlows,
    ExtentionChromeMessage,
    PosTerminalPayment,
    WebticTicketEmission,
  },
  data() {
    return {
      localServices: [] as Array<Service>,
      localTickets: [] as Array<Service>,
      products: [] as Array<Product>,
      fixedProducts: [
        {
          id: -1,
          name: this.$t('cashDeskView.articles.maxibed'),
          code: 'maxiBeds',
          cost: 5,
        },
        {
          id: -2,
          name: this.$t('cashDeskView.articles.bed'),
          code: 'beds',
          cost: 5,
        },
        {
          id: -3,
          name: this.$t('cashDeskView.articles.deckchair'),
          code: 'deckChairs',
          cost: 5,
        },
        {
          id: -4,
          name: this.$t('cashDeskView.articles.chair'),
          code: 'chairs',
          cost: 5,
        },
      ] as Array<FixProduct>,
      paymentSelected: CashFlowMethod.CASH,
      printerIdSelected: 0,
      cashSelected: true,
      bancomatSelected: false,
      creditCardSelected: false,
      giftCardSelected: false,
      sum: 0,
      discount: 0,
      productsShow: true,
      settingsShow: false,
      printerReceiptDialog: false,
      cancelReceiptDialog: false,
      printerReceiptDate: DateTime.now().startOf('day'),
      cancelReceiptDate: DateTime.now().startOf('day'),
      receiptId: '',
      reservationId: 0,
      // pos
      stripePaymentIntent: null as unknown as StripePaymentIntent,
      isPrintingAfterPOS: false,
      isPrintingAfterWebtic: false,
      mappedServicesToGroups: new Map<number, Array<Service>>(),
      ticketServiceIds: [] as Array<number>,
      mounted: false,
    };
  },
  async beforeMount() {
    await Promise.all([
      this.$store.dispatch('session/setServices'),
      this.$store.dispatch('session/setServiceGroups'),
      this.$store.dispatch('session/setPrinters'),
      this.$store.dispatch('session/setServiceAvailabilities'),
    ]);
    const reservationBill = {} as Reservation;
    reservationBill.type = ReservationType.BILL;
    this.localServices = cloneDeep(
      this.services
        .filter((element: Service) => element.fastCash)
        .filter(
          (element: Service) =>
            element.priceMode !== 3 && element.priceMode !== 4,
        ),
    );
    const tickets: Array<Service> =
      this.$store.getters['session/dailyTicketServices'](true);
    tickets.forEach((service: Service) => {
      const serviceGroupId = service.serviceGroupId;
      // Only tickets visible in the cashDesk associated to a group
      // Also they need to not have a price related to staging items
      if (
        !service.fastCash ||
        service.priceMode === 3 ||
        service.priceMode === 4 ||
        serviceGroupId === null ||
        serviceGroupId === 0
      ) {
        return;
      }
      this.ticketServiceIds.push(service.id);
      this.localTickets.push(service);
      // Push service inside the right collection
      if (!this.mappedServicesToGroups.has(serviceGroupId)) {
        this.mappedServicesToGroups.set(serviceGroupId, []);
      }
      (this.mappedServicesToGroups.get(serviceGroupId) as Array<Service>).push(
        service,
      );
    });
    this.$spiagge.utils.printMode.setup();
    this.mounted = true;
  },
  methods: {
    resetPosFlow(): void {
      this.isPrintingAfterPOS = false;
      this.stripePaymentIntent = null as unknown as StripePaymentIntent;
    },
    onCashFlowsAbort(): void {
      /**
       * Reset products and flag on printing after POS abort
       */
      if (this.isPrintingAfterPOS) {
        this.resetPosFlow();
        this.clearProducts();
      }
    },
    // Add staging items to the cart
    addProduct(productId: number): void {
      const product = this.fixedProducts.find(
        (p: FixProduct) => p.id === productId,
      );
      if (product === undefined) {
        return;
      }
      const productIndex = this.products.findIndex(
        (p: Product) => p.id === product.id,
      );
      if (productIndex !== -1) {
        this.products[productIndex].quantity += 1;
        this.products[productIndex].cost =
          product.cost * this.products[productIndex].quantity;
      } else {
        this.products.push({
          quantity: 1,
          name: product.name,
          cost: product.cost,
          unitCost: product.cost,
          code: product.code,
          vat: 22,
          id: product.id,
        } as Product);
      }
    },
    // Add extra service to the cart
    addService(serviceId: number): void {
      const service = this.localServices.find(
        (s: Service) => s.id === serviceId,
      );
      if (!service) return;
      const serviceIndex = this.products.findIndex(
        (p: Product) => p.id === service.id,
      );
      const servicePrice =
        this.mappedServicePrices.get(service.id) ?? service.cost;
      if (serviceIndex !== -1) {
        this.products[serviceIndex].quantity += 1;
        this.products[serviceIndex].cost =
          servicePrice * this.products[serviceIndex].quantity;
      } else {
        this.products.push({
          quantity: 1,
          name: service.name,
          cost: servicePrice,
          unitCost: servicePrice,
          vat: service.vat,
          id: service.id,
        } as Product);
      }
    },
    addTicket(serviceGroupId: number, ticketId: number): void {
      const tickets = this.mappedServicesToGroups.get(serviceGroupId);
      const ticket = tickets?.find((t: Service) => t.id === ticketId);
      if (ticket === undefined) {
        return;
      }
      const serviceIndex = this.products.findIndex(
        (p: Product) => p.id === ticket.id,
      );
      const ticketPrice =
        this.mappedServicePrices.get(ticket.id) ?? ticket.cost;
      if (serviceIndex !== -1) {
        this.products[serviceIndex].quantity += 1;
        this.products[serviceIndex].cost =
          ticketPrice * this.products[serviceIndex].quantity;
      } else {
        this.products.push({
          quantity: 1,
          name: ticket.name,
          cost: ticketPrice,
          unitCost: ticketPrice,
          vat: ticket.vat,
          id: ticket.id,
        } as Product);
      }
    },
    clearProducts() {
      this.products.splice(0);
      this.sum = 0;
      this.discount = 0;
    },
    selectPayment(value: number) {
      this.paymentSelected = value;

      this.cashSelected = false;
      this.bancomatSelected = false;
      this.creditCardSelected = false;
      this.giftCardSelected = false;

      if (value === CashFlowMethod.CASH) {
        this.cashSelected = true;
      } else if (value === CashFlowMethod.BANCOMAT) {
        this.bancomatSelected = true;
      } else if (value === CashFlowMethod.CREDIT_CARD) {
        this.creditCardSelected = true;
      } else {
        this.giftCardSelected = true;
      }
    },
    displayProduct() {
      this.productsShow = true;
      this.settingsShow = false;
    },
    displaySettings() {
      this.productsShow = false;
      this.settingsShow = true;
    },
    deleteProduct(index: number) {
      this.products.splice(index, 1);
    },
    onFiscalPrint(printer: Printer) {
      this.printFuncton(printer, PrintMode.FISCAL);
    },
    onNonFiscalPrint(printer: Printer) {
      this.printFuncton(printer, PrintMode.NON_FISCAL);
    },
    onNoPrint() {
      this.printFuncton();
    },
    printFuncton(printer?: Printer, printMode?: PrintMode): void {
      if (printer && printer.id && printMode) {
        const print = this.$spiagge.utils.printMode.buildPrint(
          printMode,
          this.isPrintingAfterPOS
            ? CashFlowMethod.POS_STRIPE
            : this.paymentSelected,
        );
        const licenseServices = this.licenseServices;
        if (this.products && this.products.length > 0) {
          if (printMode === PrintMode.FISCAL) {
            print.articles = this.products
              .filter((product: Product) => {
                const indexService = licenseServices.findIndex(
                  (item: Service) => item.id === product.id,
                );
                if (indexService < 0) {
                  return true;
                }
                const service = licenseServices[indexService] as Service;

                if (service.webticPriceId) {
                  return false;
                }
                return true;
              })
              .map((element: Product): PrintArticle => {
                const article = {} as PrintArticle;
                article.description = element.name;
                article.quantity = element.quantity;
                article.price = element.cost / element.quantity;
                article.vat = element.vat;

                return article;
              });
          } else {
            print.articles = this.products.map(
              (element: Product): PrintArticle => {
                const article = {} as PrintArticle;
                article.description = element.name;
                article.quantity = element.quantity;
                article.price = element.cost / element.quantity;
                article.vat = element.vat;

                return article;
              },
            );
          }
        }
        const receiptTotal = print.articles.reduce(
          (acc, curr) => acc + curr.price * curr.quantity,
          0,
        );
        if (this.sumCount < receiptTotal) {
          // add the discount as absolute value to avoid rounding error
          const discount = receiptTotal - this.sumCount;
          print.discount = discount;
        } else if (this.sumCount > receiptTotal) {
          // add surplus to only one article to avoid rounding errors
          const surplus = this.sumCount - receiptTotal;
          const minVatArticleIndex = print.articles.reduce(
            (minIndex, curr, index, arr) => ((curr.vat < arr[minIndex].vat) ? index : minIndex),
            0,
          );
          const minVatArticle = print.articles[minVatArticleIndex];
          print.articles[minVatArticleIndex].price =
            minVatArticle.price * minVatArticle.quantity + surplus;
          print.articles[minVatArticleIndex].quantity = 1;
        }
        this.$spiagge.utils.printMode.print(
          printer.id,
          print,
          async (resData) => {
            // if printing after pos -> set to fis the cash flow created
            if (this.isPrintingAfterPOS) {
              try {
                const cashFlows: Array<CashFlow> = await cashFlowService.find({
                  reservationId: this.stripePaymentIntent.reservation_id,
                });
                if (cashFlows) {
                  await cashFlowService.update({
                    id: cashFlows[0].id,
                    fiscal: true,
                  });
                } else {
                  this.$spiagge.toast.warn(
                    this.$t('cashDeskView.toast.cashflowError'),
                  );
                }
              } catch (e) {
                this.$spiagge.toast.warn(
                  this.$t('cashDeskView.toast.taxationError'),
                );
              }
              this.resetPosFlow();
              // set
              this.clearProducts();
              await this.$store.dispatch('session/setServiceAvailabilities');
            } else if (this.isPrintingAfterWebtic) {
              try {
                const noSIAECashFlow = await cashFlowService.create({
                  reservationId: this.reservationId,
                  method: this.paymentSelected,
                  amount: resData?.receiptAmount ?? cloneDeep(this.sumCount),
                  notes: null,
                  expenses: [],
                  cardId: null,
                  receipt: {
                    printerId: printer.id,
                    date: Math.round(DateTime.now().toSeconds()),
                    method: print.paymentMethod,
                    number: resData?.receiptNumber ?? '',
                    total: Number(
                      resData?.receiptAmount ?? cloneDeep(this.sumCount),
                    ),
                    subTotal1: Number(
                      resData?.receiptAmount ?? cloneDeep(this.sumCount),
                    ),
                    vat1: 22,
                    subTotal2: null,
                    vat2: null,
                    subTotal3: null,
                    vat3: null,
                    subTotal4: null,
                    vat4: null,
                  },
                });
              } catch (e) {
                this.$spiagge.toast.warn(
                  this.$t('cashDeskView.toast.cashflowError'),
                );
              }
              // clear cart
              this.clearProducts();
              this.isPrintingAfterWebtic = false;
              this.reservationId = 0;
              await this.$store.dispatch('session/setServiceAvailabilities');
            } else {
              this.save(resData, print, printer, printMode);
            }
            /* Print order receipt */
            this.printOrderReceipt(print);
          },
        );
      } else {
        this.save();
      }
    },
    /**
     * Function to print the order receipt after printing a fis/nfis receipt
     * @param receiptContent Receipt already printed
     */
    printOrderReceipt(receiptContent: Print): void {
      if (!this.orderPrinter) {
        return;
      }

      const receiptToPrint = receiptContent;
      receiptToPrint.printMode = PrintMode.LIST;
      this.$spiagge.utils.printMode.print(
        this.orderPrinter.id,
        receiptToPrint,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
      );
    },
    async save(
      printerResponse?: PrinterResponse, // Printer response
      print?: Print, // data sent to the printer
      printer?: Printer, // Printer object
      printMode?: string,
    ) {
      const payload = {} as CashDeskPayload;
      // Generate articles
      payload.articles = this.buildArticles();
      // Generate cashflow
      const cashFlow = {
        method: this.paymentSelected,
        amount: this.sum,
      } as CashDeskCashFlow;
      if (printMode === PrintMode.FISCAL && printer && print) {
        cashFlow.receipt = {
          printerId: printer.id,
          date: Math.round(DateTime.now().toSeconds()),
          method: print.paymentMethod,
          number: printerResponse?.receiptNumber ?? '',
          total: cloneDeep(this.sumCount),
          subTotal1: cloneDeep(this.sumCount),
          vat1: 22,
        };
      }
      payload.cashFlow = cashFlow;
      try {
        await cashdeskService.createOne(payload);
        this.clearProducts();
        await this.$store.dispatch('session/setServiceAvailabilities');
        this.$spiagge.toast.success(
          this.$t('cashDeskView.toast.createOrderSuccess'),
        );
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('cashDeskView.toast.createOrderError'),
        );
      }
    },
    openPrinterReceipt(printerId: number) {
      this.printerReceiptDialog = true;
      this.printerIdSelected = printerId;
    },
    openCancelReceipt(printerId: number) {
      this.cancelReceiptDialog = true;
      this.printerIdSelected = printerId;
    },
    printFinancialReport(idPrinter: number) {
      window[
        `world_printer_${idPrinter}` as unknown as number
      ].execDailyReport();
    },
    printDailyClosure(idPrinter: number) {
      window[
        `world_printer_${idPrinter}` as unknown as number
      ].execDailyClosure();
    },
    printReceipts() {
      const date = this.printerReceiptDate;
      const dateParts = [
        date.day.toString(),
        date.month.toString(),
        date.year.toString(),
      ];
      window[
        `world_printer_${this.printerIdSelected}` as unknown as number
      ].printReceiptsByDate(
        dateParts[2],
        dateParts[1],
        dateParts[0],
        () => null,
      );
      this.printerReceiptDialog = false;
    },
    cancelReceipt() {
      const code = this.receiptId;
      const date = this.cancelReceiptDate;
      const dateParts = [
        date.day >= 10 ? date.day.toString() : `0${date.day.toString()}`,
        date.month >= 10 ? date.month.toString() : `0${date.month.toString()}`,
        date.year.toString(),
      ];
      window[
        `world_printer_${this.printerIdSelected}` as unknown as number
      ].receiptCancellationAutomatic(
        code,
        dateParts[2],
        dateParts[1],
        dateParts[0],
        null,
      );
      this.cancelReceiptDialog = false;
    },
    buildArticles(): Array<CashDeskArticle> {
      const articles = [] as Array<CashDeskArticle>;
      this.products.forEach((element: Product) => {
        const article = {
          qnt: element.quantity,
          priceForced: element.cost,
          priceList: element.unitCost * element.quantity,
        } as CashDeskArticle;
        if (element.code) {
          article.name = element.code;
        } else if (element.id) {
          article.name = element.name;
          article.serviceId = element.id;
        }
        articles.push(article);
      });
      return articles;
    },
    // pos terminal
    async onPosTerminalSuccess(
      stripePaymentIntent: StripePaymentIntent,
    ): Promise<void> {
      /**
       * Pos terminal success
       */
      this.$spiagge.toast.success(
        this.$t('cashDeskView.toast.posTerminalSuccess'),
      );
      this.stripePaymentIntent = stripePaymentIntent;
      /* automatic fiscalization after pos payment */
      const fiscalPrintBtn = document.querySelector(
        '.cash-flow.fiscal',
      ) as HTMLElement;
      if (fiscalPrintBtn) {
        this.isPrintingAfterPOS = true;
        fiscalPrintBtn.click();
      }
    },
    async onPosTerminalFailure(): Promise<void> {
      /**
       * Pos terminal failure
       */
      this.$spiagge.toast.error(this.$t('cashDeskView.toast.posTerminalError'));
    },
    async onPosTerminalIntent(): Promise<void> {
      /**
       * Create cash desk order (without cash flow) before payment intent
       */
      try {
        const reservation: Reservation = await cashdeskService.createOne({
          articles: this.buildArticles(),
        } as CashDeskPayload);
        this.reservationId = reservation.id;
      } catch (error) {
        this.$spiagge.toast.error(
          this.$t('cashDeskView.toast.createOrderSuccess'),
        );
      }
    },
    hasPosPermission(): boolean {
      return (
        this.license.stripePosTerminalEnabled &&
        permissionsUtil.isActionPermissionAllowed(
          FEATURE_PERMISSION_CONFIG.pos,
          FEATURE_PERMISSION_ACTION_CONFIG.pos.SHOW_BUTTON_POS,
        )
      );
    },
    // Webtic terminal
    async onWebticTerminalSuccess(): Promise<void> {
      /**
       * Webtic terminal success
       */
      this.$spiagge.toast.success(
        this.$t('cashDeskView.toast.webticTerminalSuccess'),
      );
      /* Chech if there are no SIAE articles */
      const numNoSIAEArticles = this.products.filter((product: Product) => {
        const indexService = this.licenseServices.findIndex(
          (item: Service) => item.id === product.id,
        );
        if (indexService < 0) {
          return true;
        }
        const service = this.licenseServices[indexService] as Service;

        if (service.webticPriceId) {
          return false;
        }
        return true;
      }).length;

      if (numNoSIAEArticles > 0) {
        /* automatic fiscalization after ticket emission */
        const fiscalPrintBtn = document.querySelector(
          '.cash-flow.fiscal',
        ) as HTMLElement;
        if (fiscalPrintBtn) {
          this.isPrintingAfterWebtic = true;
          fiscalPrintBtn.click();
        }
      } else {
        // Reset the cart
        this.clearProducts();
        this.reservationId = 0;
        await this.$store.dispatch('session/setServiceAvailabilities');
      }
    },
    async onWebticTerminalFailure(): Promise<void> {
      /**
       * Webtic terminal failure
       */
      this.$spiagge.toast.error(
        this.$t('cashDeskView.toast.webticTerminalError'),
      );
    },
    async onWebticTerminalIntent(): Promise<void> {
      /**
       * Create cash desk order (without cash flow) before payment intent
       */
      try {
        const reservation: Reservation = await cashdeskService.createOne({
          articles: this.buildArticles(),
        } as CashDeskPayload);
        this.reservationId = reservation.id;
      } catch (error) {
        this.$spiagge.toast.error(
          this.$t('cashDeskView.toast.createOrderSuccess'),
        );
      }
    },
    formatLimitLabel(limit: LimitInformation | undefined): string {
      if (limit === undefined) {
        return '-/-';
      }
      return `${limit.reservedQuantity}/${
        limit.maximumQuantity > 0 ? limit.maximumQuantity : '∞'
      }`;
    },
    formatExtra(quantity?: number): string {
      if (!quantity) return '';
      return `(+${String(quantity)})`;
    },
  },
  computed: {
    ...mapState('session', {
      licenseServiceGroups: 'serviceGroups',
      licenseServices: 'services',
      availabilities: 'serviceAvailabilities',
    }),
    license(): License {
      return this.$store.getters['session/license'];
    },
    services(): Array<Service> {
      return this.$store.getters['session/beachServices'];
    },
    printers(): Array<Printer> {
      return this.$store.getters['session/printers'];
    },
    fiscalPrinters(): Array<Printer> {
      return this.$store.getters['session/fiscalPrinters'].filter(
        (p: Printer) => !cookieUtil.get(`spit_prt-hide-${p.id}`),
      );
    },
    windowWidth(): number {
      return this.$store.getters['app/windowWidth'];
    },
    windowHeight(): number {
      return this.$store.getters['app/windowHeight'];
    },
    panelHeight(): number {
      return this.windowHeight - 64;
    },
    deviceSpecs(): DeviceSpecs {
      return this.$store.getters['app/deviceSpecs'];
    },
    isChrome(): boolean {
      if (this.deviceSpecs.browser === 'gc' && !this.deviceSpecs.mobile) {
        return (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).chromeExtensionEnable !== 'yb' &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).chromeExtensionId !==
            'kddlpmnnlnnlbdmlmneclkmgbgfifeag'
        );
      }
      return false;
    },
    isElectron(): boolean {
      if (cookieUtil.get('anm22_world_app_os') === 'electron') {
        return true;
      }
      return false;
    },
    isAndroid(): boolean {
      if (cookieUtil.get('anm22_world_app_os') === 'android') {
        return true;
      }
      return false;
    },
    isIos(): boolean {
      if (cookieUtil.get('anm22_world_app_os') === 'ios') {
        return true;
      }
      return false;
    },
    sumCount: {
      get(): number {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sum = this.products
          .map((element) => element.cost)
          .reduce((partialSum, a) => partialSum + a, 0);
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sum =
          this.discount !== 0
            ? this.sum - (this.sum / 100) * this.discount
            : this.sum;
        return this.sum;
      },
      set(value: number): void {
        const sumProduct = this.products.reduce(
          (partialSum, element) => partialSum + element.cost,
          0,
        );
        if (value && value > 0 && sumProduct - value !== 0) {
          this.discount = ((sumProduct - value) * 100) / sumProduct;
          this.sum = value;
        } else {
          this.sum = this.products
            .map((element) => element.cost)
            .reduce((partialSum, a) => partialSum + a, 0);
        }
      },
    },
    discountValue: {
      get(): number {
        return this.discount;
      },
      set(value: number): void {
        this.discount = value;
      },
    },
    printerReceiptDateComputed: {
      get(): Date {
        return this.printerReceiptDate.toJSDate();
      },
      set(d: Date): void {
        const dateTime = DateTime.fromFormat(
          `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`,
          'd-M-yyyy',
        );
        this.printerReceiptDate = dateTime;
      },
    },
    cancelReceiptDateComputed: {
      get(): Date {
        return this.cancelReceiptDate.toJSDate();
      },
      set(d: Date): void {
        const dateTime = DateTime.fromFormat(
          `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`,
          'd-M-yyyy',
        );
        this.cancelReceiptDate = dateTime;
      },
    },
    /**
     * Order receipt after fiscal/non-fiscal receipt
     */
    orderPrinter(): Printer | null {
      if (!this.license.posCommandPaper) {
        return null;
      }
      return (
        (this.printers as Array<Printer>).filter(
          (p: Printer) =>
            p.printMode.list === 1 && !cookieUtil.get(`spit_prt-hide-${p.id}`),
        )[0] ?? null
      );
    },
    posTerminalPaymentPayload(): Partial<ApiStripeCreatePaymentIntentPayload> {
      return {
        amount: this.sumCount,
        email: '',
        reservation: this.reservationId ?? undefined,
        description: 'cash-desk',
      };
    },
    webticTerminalPaymentPayload(): ApiWebticOnsiteEmitTicketPayload {
      const payload: ApiWebticOnsiteEmitTicketPayload = {
        reservation: this.reservationId ?? undefined,
      };

      return payload;
    },
    serviceGroups(): Array<ServiceGroup> {
      return cloneDeep(this.licenseServiceGroups);
    },
    mappedServiceLimits(): Map<number, LimitInformation> {
      const result = new Map<number, LimitInformation>();
      this.availabilities.serviceGroupLimits.forEach(
        (groupLimit: ServiceGroupInfo) => {
          groupLimit.serviceLimits.forEach(
            (serviceLimit: SingleServiceInfo) => {
              result.set(serviceLimit.serviceId, {
                hasLimit: serviceLimit.hasLimit,
                reservedQuantity: serviceLimit.reservedQuantity,
                availableQuantity: serviceLimit.availableQuantity,
                maximumQuantity: serviceLimit.maximumQuantity,
              } as LimitInformation);
            },
          );
        },
      );
      return result;
    },
    mappedServiceGroupLimits(): Map<number, LimitInformation> {
      const result = new Map<number, LimitInformation>();
      this.availabilities.serviceGroupLimits.forEach(
        (groupLimit: ServiceGroupInfo) => {
          result.set(groupLimit.serviceGroupId, {
            hasLimit: groupLimit.hasLimit,
            reservedQuantity: groupLimit.reservedQuantity,
            availableQuantity: groupLimit.availableQuantity,
            maximumQuantity: groupLimit.maximumQuantity,
          } as LimitInformation);
        },
      );
      return result;
    },
    mappedServicePrices(): Map<number, number> {
      const result = new Map<number, number>();
      this.availabilities.serviceGroupLimits.forEach(
        (groupLimit: ServiceGroupInfo) => {
          groupLimit.serviceLimits.forEach(
            (serviceLimit: SingleServiceInfo) => {
              result.set(serviceLimit.serviceId, serviceLimit.price);
            },
          );
        },
      );
      this.availabilities.extraServiceLimits.forEach(
        (serviceLimit: SingleServiceInfo) => {
          result.set(serviceLimit.serviceId, serviceLimit.price);
        },
      );
      return result;
    },
    boughtServices(): Map<number, number> {
      const result = new Map<number, number>();
      this.products.forEach((p: Product) => {
        result.set(p.id, p.quantity);
      });
      return result;
    },
    groupCounters(): Map<number, number> {
      const result = new Map<number, number>();
      this.products.forEach((p: Product) => {
        const ticket = this.localTickets.find((s: Service) => s.id === p.id);
        if (!ticket) return;
        const groupId = ticket.serviceGroupId;
        if (!groupId) return;
        result.set(groupId, p.quantity + (result.get(groupId) ?? 0));
      });
      return result;
    },
  },
});
