/* eslint-disable import/no-cycle */
/**
 * Auth service
 */

import axios from 'axios';
import { ApiAuthSignInResponse } from '@/models/api';
import apiService from '@/services/apiService';

export default {
  async signIn(): Promise<ApiAuthSignInResponse> {
    const res = await axios.get(apiService.auth.signInUrl());
    return res.data;
  },
};
