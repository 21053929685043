
import { defineComponent } from 'vue';
import { cloneDeep } from 'lodash';
import { DropdownOption } from '@/models';
import cardTypeService from '@/services/cardTypeService';
import { ApiCardTypeListResponse } from '@/models/api';
import { CardTypeWork } from '@/models/cardType';
import WipView from '../WipView.vue';
import customerService from '@/services/customerService';

interface CsvImportFilter {
  separator: string;
  multipleContact: boolean;
  work: number;
}

const CSV_IMPORT_FILTER_MODEL_DEFAULT = {
  separator: ';',
  multipleContact: true,
  work: 6,
} as CsvImportFilter;

export default defineComponent({
  name: 'ExportView',
  components: { WipView },
  data() {
    return {
      wipView: true,
      fileNameVisible: false,
      importing: cloneDeep(CSV_IMPORT_FILTER_MODEL_DEFAULT),
      componentKey: 0,
      fileFormats: [
        { label: this.$t('importView.americanDelimeter'), value: ',' },
        { label: this.$t('importView.italianDelimeter'), value: ';' },
      ] as Array<DropdownOption>,
      mockedOptions: [
        {
          label: '',
          value: 0,
        },
      ],
      file: null as File | null,
    };
  },
  computed: {},
  methods: {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    onFileSelectClick(): void {
      (this.$refs['list-file-upload'] as HTMLElement).click();
    },
    async onFileSelect(event: any): Promise<void> {
      this.file = event.target.files[0];
      this.fileNameVisible = true;
      const fileName = this.$refs['file-name'] as HTMLElement;
      fileName.textContent = '';
      fileName.textContent = event.target.files[0].name;
    },
    async onFormSubmit(): Promise<void> {
      if (this.file) {
        const payload = new FormData();
        payload.append('separator', this.importing.separator);
        payload.append(
          'multipleContacts',
          this.importing.multipleContact ? '1' : '0',
        );
        payload.append('work', `${+this.importing.work}`);
        payload.append('importCustomers', this.file);
        try {
          await customerService.importCustomers(payload);
        } catch (e) {
          // console.log(e);
        } finally {
          const fileName = this.$refs['file-name'] as HTMLElement;
          fileName.textContent = '';
          this.fileNameVisible = false;
          this.componentKey += 1;
          this.file = null as File | null;
        }
      } else {
        this.$spiagge.toast.error(this.$t('importView.toast.error'));
      }
    },
    confirmImport() {
      this.$confirm.require({
        message: this.$t('importView.confirm.message'),
        header: this.$t('importView.confirm.title'),
        icon: 'pi pi-info-circle',
        acceptLabel: this.$t('common.yes'),
        accept: () => {
          this.onFormSubmit();
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          // do nothing
        },
      });
    },
  },
  async beforeMount() {
    try {
      const res: ApiCardTypeListResponse = await cardTypeService.list();
      this.mockedOptions = res.result.works.map((work: CardTypeWork) => ({
        label: work.name,
        value: work.id,
      }));
    } catch (error) {
      // console.log(error);
    }
  },
});
