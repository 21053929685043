
import { PropType, defineComponent } from 'vue';
import OverlayPanel from 'primevue/overlaypanel';
import { DateTime } from 'luxon';
import { PriceAlteration } from '@/models/priceAlteration';
import revenueManagementService from '@/services/revenueManagementService';
import {
  ApiRevenueManagementPriceAlterationDeletePayload,
  ApiRevenueManagementSlotDetailPayload,
} from '@/models/api';
import {
  RevenueManagementPriceHint,
  RevenueManagementPrices,
  RevenueManagementSlotDetail,
} from '@/models/revenueManagement';

export default defineComponent({
  name: 'RevenueManagementCard',
  emits: ['acceptHint', 'declineHint', 'delete'],
  props: {
    priceAlteration: {
      type: [Object, null] as PropType<PriceAlteration | null>,
      required: true,
    },
    priceHint: {
      type: [Object, null] as PropType<RevenueManagementPriceHint | null>,
      required: true,
    },
    prices: {
      type: [Object, null] as PropType<RevenueManagementPrices | null>,
      required: true,
    },
    day: {
      type: Object as PropType<DateTime>,
      required: true,
    },
    sectorId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      slotDetail: null as unknown as RevenueManagementSlotDetail,
      deleteDialog: false,
    };
  },
  methods: {
    async onOpenDetail($event: PointerEvent): Promise<void> {
      // already open, close it
      if (this.slotDetail) {
        this.onCloseDetail();
      } else {
        try {
          const slotDetail: RevenueManagementSlotDetail =
            await revenueManagementService.slotDetail({
              day: this.day.toSeconds(),
              sectorId: this.sectorId,
            } as ApiRevenueManagementSlotDetailPayload);
          this.slotDetail = slotDetail;
          (this.$refs.op as OverlayPanel).show($event, $event.target);
        } catch (e) {
          this.$spiagge.toast.error(
            this.$t('revenueManagementCard.toast.retrievalError'),
          );
        }
      }
    },
    onCloseDetail(): void {
      this.slotDetail = null as unknown as RevenueManagementSlotDetail;
      (this.$refs.op as OverlayPanel).hide();
    },
    getPriceRange(percentage = 0): string {
      const offlinePriceLabel = this.prices?.offlinePrice
        ? `${this.$spiagge.utils.global
            .percentage(this.prices.offlinePrice, percentage, true)
            .toString()}€`
        : '-';
      /* const onlinePriceLabel = this.prices?.onlinePrice
        ? `${this.$spiagge.utils.global
            .percentage(this.prices.onlinePrice, percentage, true)
            .toString()}€`
        : '-'; */
      // return [offlinePriceLabel, onlinePriceLabel].join(' • ');
      return offlinePriceLabel;
    },
    async onDelete(): Promise<void> {
      if (!this.priceAlteration) return;
      this.$store.commit('app/setProcessing', true);
      try {
        await revenueManagementService.priceAlteration.delete({
          from: this.priceAlteration.day,
          to: this.priceAlteration.day,
          sectorIds: [this.priceAlteration.sectorId],
        } as ApiRevenueManagementPriceAlterationDeletePayload);
        this.$spiagge.toast.success(
          this.$t('revenueManagementCard.toast.deleteSuccess'),
        );
        this.$emit('delete');
      } catch (error) {
        this.$spiagge.toast.error(
          this.$t('revenueManagementCard.toast.deleteError'),
        );
      } finally {
        this.$store.commit('app/setProcessing', false);
      }
    },
  },
  computed: {
    classes(): Array<string> {
      const classes = [] as Array<string>;
      if (this.priceAlteration) {
        if (this.priceAlteration.percentageDiscount > 0) {
          classes.push('price-increased');
        }
        if (this.priceAlteration.percentageDiscount < 0) {
          classes.push('price-decreased');
        }
      }
      return classes;
    },
    pricesLabel(): string {
      return this.getPriceRange();
    },
    alteredPricesLabel(): string {
      return this.priceAlteration
        ? this.getPriceRange(this.priceAlteration.percentageDiscount)
        : this.pricesLabel;
    },
  },
});
