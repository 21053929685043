import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createBlock(_component_Button, {
    icon: "pi pi-print",
    label: _ctx.$t('button.print'),
    class: "print-button no-print",
    onClick: _ctx.onPrint
  }, null, 8, ["label", "onClick"]))
}