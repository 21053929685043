
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import permissionsUtil from '@/utils/permissionsUtil';
import { FEATURE_PERMISSION_ACTION_CONFIG, FEATURE_PERMISSION_CONFIG } from '@/models/permissions';
import { ServiceAvailabilityPayload } from '@/models/api/request/service/serviceAvailabilityPayload';
import { Service, ServiceType } from '@/models/service';
import { ServiceGroup } from '@/models/serviceGroup/serviceGroup';
import { CalendarRange } from '@/models';
import {
  LimitInformation,
  ServiceGroupInfo,
  ServiceInformation,
  SingleServiceInfo,
} from '@/models/service/serviceInformation';

export default defineComponent({
  name: 'mapTicketWarehouse',
  data() {
    return {
      isDetailOpen: false,
      upperLimit: 0,
      latestFrom: 0,
      latestTo: 0,
      mappedServicesToGroups: new Map<number, Array<Service>>(),
    };
  },
  async beforeMount() {
    this.latestFrom = this.calendar[0].toSeconds();
    this.latestTo = this.calendar[1].toSeconds();
    await Promise.all([
      this.$store.dispatch('session/setServices'),
      this.$store.dispatch('session/setServiceGroups'),
      this.$store.dispatch(
        'map/setTicketAvailabilities',
        this.getAvailabilityPayload(),
      ),
    ]);
    // Map tickets
    const tickets: Array<Service> = this.$store.getters['session/dailyTicketServices'](true);
    tickets.forEach((service: Service) => {
      const serviceGroupId = service.serviceGroupId ?? 0;
      if (!this.mappedServicesToGroups.has(serviceGroupId)) {
        this.mappedServicesToGroups.set(serviceGroupId, []);
      }
      (this.mappedServicesToGroups.get(serviceGroupId) as Array<Service>).push(service);
    });
    this.upperLimit = this.license.ticketMaximumCapacity ?? 0;
  },
  methods: {
    hasActiveTickets(): boolean {
      const tickets: Array<Service> = this.$store.getters['session/dailyTicketServices'](true);
      return tickets.filter((ticket: Service) =>
        ticket.canBeSoldAloneOffline
          || ticket.canBeSoldAloneOnline
          || ticket.reservationSchedule
          || ticket.online
          || ticket.fastCash,
      ).length > 0;
    },
    getAvailabilityPayload(): ServiceAvailabilityPayload {
      return {
        from: this.latestFrom,
        to: this.latestTo,
        serviceType: ServiceType.DAILY_TICKET,
      };
    },
    loadWarehouseDetails(): void {
      if (!this.isWarehouseOpen) {
        return;
      }
      if (
        this.latestFrom === this.calendar[0].toSeconds()
        && this.latestTo === this.calendar[1].toSeconds()
      ) {
        return;
      }
      this.latestFrom = this.calendar[0].toSeconds();
      this.latestTo = this.calendar[1].toSeconds();
      this.$store.dispatch(
          'map/setTicketAvailabilities',
          this.getAvailabilityPayload(),
      );
    },
    openWarehouseDetails(): void {
      this.isWarehouseOpen = !this.isWarehouseOpen;
      this.loadWarehouseDetails();
    },
    formatLimitLabel(
        limit: LimitInformation | undefined,
    ): string {
      if (limit === undefined) {
        return '-/-';
      }
      return `${
        limit.reservedQuantity
      }/${
        limit.maximumQuantity > 0 ? limit.maximumQuantity : '∞'
      }`;
    },
    formatGroupServices(serviceGroup: ServiceGroup): string {
      // Add number of related services
      return ` (${
          serviceGroup?.serviceCounter ?? 0
      } ${
          this.$t('settingsExtraServicesView.dailyTicketTitle')
      })`;
    },
    boxClasses(isLast: boolean): Array<string> {
      const classes = [
        'p-2',
        'flex',
        'flex-column',
        'justify-content-center',
        'service-group-box',
      ];
      if (isLast) {
        classes.push('no-border');
      }
      return classes;
    },
    getExtraClassesForButton(): Array<string> {
      const classes = [];
      if (this.smartSearchActive && this.planner.active) {
        classes.push('medium-lift-button');
      } else if (this.planner.active) {
        classes.push('lift-button');
      } else if (this.smartSearchActive) {
        classes.push('small-lift-button');
      }
      if (this.isDetailOpen && this.isWarehouseOpen) {
        let length = 0;
        this.mappedServicesToGroups.forEach(
          (services: Array<Service>) => {
            if (services.length > 0) {
              length += 1;
            }
          },
        );
        if (length < 3) {
          classes.push('enlarge-sm-details-button');
        } else if (length < 5) {
          classes.push('enlarge-md-details-button');
        } else {
          classes.push('enlarge-lg-details-button');
        }
      }
      return classes;
    },
    getExtraClassesForSection(): Array<string> {
      const classes = [];
      if (this.smartSearchActive && this.planner.active) {
        classes.push('medium-lift');
      } else if (this.planner.active) {
        classes.push('lift');
      } else if (this.smartSearchActive) {
        classes.push('small-lift');
      }
      if (this.isDetailOpen && this.isWarehouseOpen) {
        let length = 0;
        this.mappedServicesToGroups.forEach(
            (services: Array<Service>) => {
              if (services.length > 0) {
                length += 1;
              }
            },
        );
        if (length < 3) {
          classes.push('enlarge-sm-details');
        } else if (length < 5) {
          classes.push('enlarge-md-details');
        } else {
          classes.push('enlarge-lg-details');
        }
      }
      return classes;
    },
  },
  computed: {
    ...mapState('smartSearch', ['spots']),
    ...mapState('session', {
      license: 'license',
      licenseServiceGroups: 'serviceGroups',
    }),
    ...mapGetters('smartSearch', ['suggestionAvailable']),
    ...mapGetters('map', {
      planner: 'planner',
      calendar: 'calendar',
      ticketAvailabilities: 'ticketAvailabilities',
      activeOverlay: 'activeOverlay',
      isTicketDetailOpen: 'ticketDetailOpen',
    }),
    FEATURE_PERMISSION_ACTION_CONFIG() {
      return FEATURE_PERMISSION_ACTION_CONFIG;
    },
    FEATURE_PERMISSION_CONFIG() {
      return FEATURE_PERMISSION_CONFIG;
    },
    permissionsUtil() {
      return permissionsUtil;
    },
    isWarehouseOpen: {
        get(): boolean {
          return this.isTicketDetailOpen;
        },
        set(open: boolean): void {
          this.$store.commit('map/setTicketDetailOpen', open);
        },
    },
    smartSearchActive(): boolean {
      return this.$store.state.smartSearch.active;
    },
    availabilities(): ServiceInformation {
      return cloneDeep(this.ticketAvailabilities);
    },
    isOverlayActive(): boolean {
      return this.activeOverlay;
    },
    totalReservedTickets(): number {
      let result = 0;
      if (!this.availabilities?.serviceGroupLimits) {
        return result;
      }
      this.availabilities.serviceGroupLimits.forEach((groupLimit: ServiceGroupInfo) => {
        groupLimit.serviceLimits.forEach((serviceLimit: SingleServiceInfo) => {
          result += serviceLimit.reservedQuantity;
        });
      });
      return result;
    },
    showDetailToggle(): string {
      return this.isDetailOpen
        ? this.$t('common.hideDetail')
        : this.$t('common.showDetail');
    },
    buttonClasses(): Array<string> {
      const classes = [
        'map-control',
        this.isWarehouseOpen ? 'open' : 'closed',
      ];
      classes.push(...this.getExtraClassesForButton());

      if (this.isDetailOpen && this.isWarehouseOpen) {
        classes.push('enlarge-details-button');
      }
      return classes;
    },
    warehouseSectionClasses(): Array<string> {
      const classes = [
        'map-control',
        this.isWarehouseOpen ? 'open' : 'closed',
      ];
      classes.push(...this.getExtraClassesForSection());

      if (this.isDetailOpen && this.isWarehouseOpen) {
        classes.push('enlarge-details');
      }
      return classes;
    },
    warehouseDetailClasses(): Array<string> {
      const classes = [
        'map-control',
        'mt-2',
        'p-0',
        this.isDetailOpen ? 'open' : 'closed',
      ];
      return classes;
    },
    serviceGroups(): Array<ServiceGroup> {
      return cloneDeep(this.licenseServiceGroups);
    },
    mappedServiceLimits(): Map<number, LimitInformation> {
      const result = new Map<number, LimitInformation>();
      if (!this.availabilities?.serviceGroupLimits) {
        return result;
      }
      this.availabilities.serviceGroupLimits.forEach((groupLimit: ServiceGroupInfo) => {
        groupLimit.serviceLimits.forEach((serviceLimit: SingleServiceInfo) => {
          result.set(
            serviceLimit.serviceId,
            {
              hasLimit: serviceLimit.hasLimit,
              reservedQuantity: serviceLimit.reservedQuantity,
              availableQuantity: serviceLimit.availableQuantity,
              maximumQuantity: serviceLimit.maximumQuantity,
            } as LimitInformation,
          );
        });
      });
      return result;
    },
    mappedServiceGroupLimits(): Map<number, LimitInformation> {
      const result = new Map<number, LimitInformation>();
      if (!this.availabilities?.serviceGroupLimits) {
        return result;
      }
      this.availabilities.serviceGroupLimits.forEach((groupLimit: ServiceGroupInfo) => {
        result.set(
          groupLimit.serviceGroupId,
          {
            hasLimit: groupLimit.hasLimit,
            reservedQuantity: groupLimit.reservedQuantity,
            availableQuantity: groupLimit.availableQuantity,
            maximumQuantity: groupLimit.maximumQuantity,
          } as LimitInformation,
        );
      });
      return result;
    },
  },
  watch: {
    calendar(range: CalendarRange) {
      if (range.length > 0 && range[1]) {
        this.loadWarehouseDetails();
      }
    },
  },
});
