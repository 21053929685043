import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_Row = _resolveComponent("Row")!
  const _component_ColumnGroup = _resolveComponent("ColumnGroup")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Message = _resolveComponent("Message")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    class: "statistics-payments-history-data-table",
    scrollable: "",
    "scroll-height": "400px",
    value: _ctx.source,
    rowClass: _ctx.rowClass,
    onRowClick: _cache[0] || (_cache[0] = 
      (row) => {
        _ctx.$emit('rowClick', row);
      }
    )
  }, {
    empty: _withCtx(() => [
      _createVNode(_component_Message, {
        class: "inline-block",
        severity: "info",
        closable: false
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('statisticsPaymentsHistoryDataTable.noPaymentMessage')), 1)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_ColumnGroup, { type: "header" }, {
        default: _withCtx(() => [
          _createVNode(_component_Row, null, {
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                header: _ctx.$t('statisticsPaymentsHistoryDataTable.date')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('statisticsPaymentsHistoryDataTable.spot')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('statisticsPaymentsHistoryDataTable.customer')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('statisticsPaymentsHistoryDataTable.paymentMethod')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('statisticsPaymentsHistoryDataTable.printer')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('statisticsPaymentsHistoryDataTable.receiptNumber')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('statisticsPaymentsHistoryDataTable.fiscalMode')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('statisticsPaymentsHistoryDataTable.user')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('statisticsPaymentsHistoryDataTable.onlineOffline')
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: _ctx.$t('statisticsPaymentsHistoryDataTable.cashflowAmount'),
                frozen: "",
                alignFrozen: "right"
              }, null, 8, ["header"]),
              _createVNode(_component_Column, {
                header: 
            _ctx.$t('statisticsPaymentsHistoryDataTable.cashflowFiscalAmount')
          ,
                frozen: "",
                alignFrozen: "right"
              }, null, 8, ["header"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "date",
        style: {"min-width":"120px"}
      }, {
        body: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(_ctx.$spiagge.date.fromMillis(slotProps.data.date, 'dd/MM/yyyy')), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "spot",
        style: {"min-width":"120px"}
      }, {
        body: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(slotProps.data.spot &&
          ![5, 6].includes(slotProps.data.reservationType)
            ? slotProps.data.spot
            : _ctx.EMPTY_TEXT), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "customer",
        style: {"min-width":"120px"}
      }),
      _createVNode(_component_Column, {
        field: "method",
        style: {"min-width":"120px"}
      }, {
        body: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(_ctx.getPaymentMethod(slotProps.data.method)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "printer",
        style: {"min-width":"120px"}
      }, {
        body: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(slotProps.data.printer ?? _ctx.EMPTY_TEXT), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "receiptNumber",
        style: {"min-width":"120px"}
      }, {
        body: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(slotProps.data.receiptNumber ?? _ctx.EMPTY_TEXT), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "fiscalMode",
        style: {"min-width":"120px"}
      }, {
        body: _withCtx((slotProps) => [
          (
            ![
              _ctx.StatisticFiscalMode.RECEIPT,
              _ctx.StatisticFiscalMode.INVOICE,
            ].includes(slotProps.data.fiscalMode)
          )
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(_toDisplayString(_ctx.EMPTY_TEXT), 1)
              ], 64))
            : (_openBlock(), _createBlock(_component_Tag, _normalizeProps(_mergeProps({ key: 1 }, _ctx.getFiscalModeTagProps(slotProps.data.fiscalMode))), null, 16))
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "user",
        style: {"min-width":"120px"}
      }, {
        body: _withCtx((slotProps) => [
          _createTextVNode(_toDisplayString(slotProps.data.user ?? _ctx.EMPTY_TEXT), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "online",
        style: {"min-width":"120px"}
      }, {
        body: _withCtx((slotProps) => [
          _createVNode(_component_Tag, _normalizeProps(_guardReactiveProps(_ctx.getOnlineTagProps(slotProps.data.online))), null, 16)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "cashFlowAmount",
        frozen: "",
        alignFrozen: "right",
        style: {"min-width":"120px"}
      }, {
        body: _withCtx((slotProps) => [
          _createElementVNode("span", {
            class: _normalizeClass(
            _ctx.getCashFlowAmountsCssClasses(slotProps.data.cashFlowAmount ?? 0.0)
          )
          }, _toDisplayString(_ctx.format(slotProps.data.cashFlowAmount ?? 0.0)), 3)
        ]),
        _: 1
      }),
      _createVNode(_component_Column, {
        field: "cashFlowFiscalAmount",
        frozen: "",
        alignFrozen: "right",
        style: {"min-width":"120px"}
      }, {
        body: _withCtx((slotProps) => [
          _createElementVNode("span", {
            class: _normalizeClass(
            _ctx.getCashFlowAmountsCssClasses(
              slotProps.data.cashFlowFiscalAmount ?? 0.0,
            )
          )
          }, _toDisplayString(_ctx.format(slotProps.data.cashFlowFiscalAmount ?? 0.0)), 3)
        ]),
        _: 1
      }),
      (!!_ctx.source?.length)
        ? (_openBlock(), _createBlock(_component_ColumnGroup, {
            key: 0,
            type: "footer"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_Column, {
                    footer: _ctx.$t('statisticsPaymentsHistoryDataTable.total'),
                    footerStyle: "text-align:left",
                    style: {"min-width":"120px"},
                    frozen: "",
                    "align-frozen": "left",
                    colspan: 10
                  }, null, 8, ["footer"]),
                  _createVNode(_component_Column, {
                    footer: _ctx.format(_ctx.totalCashFlowAmount),
                    footerStyle: "text-align:right; right: 120px;",
                    style: {"min-width":"120px"},
                    frozen: "",
                    "align-frozen": "right"
                  }, null, 8, ["footer"]),
                  _createVNode(_component_Column, {
                    footer: _ctx.format(_ctx.totalCashFlowFiscalAmount),
                    footerStyle: "text-align:right",
                    style: {"min-width":"120px"},
                    frozen: "",
                    "align-frozen": "right"
                  }, null, 8, ["footer"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["value", "rowClass"]))
}