import {
  SpotInfo,
  SpotPlayFieldData,
  SpotType,
  SPOT_INFOS,
} from '@/models/spot';

export default {
  /**
   * Return if spot type is shiftable to another
   * @param from
   * @param to
   * @returns Spot type is shiftable
   */
  isShiftable(from: SpotType, to: SpotType): boolean {
    return (
      SPOT_INFOS.find(
        (spotInfo: SpotInfo) => spotInfo.type === from,
      )?.shiftableTo.includes(to) || false
    );
  },
  /**
   * Return if spot type is copyable to another
   * @param from
   * @param to
   * @returns Spot type is copyable
   */
  isCopyable(from: SpotType, to: SpotType): boolean {
    return (
      SPOT_INFOS.find(
        (spotInfo: SpotInfo) => spotInfo.type === from,
      )?.copyableTo.includes(to) || false
    );
  },
  /**
   * Return if spot type is selectable with another
   * @param from
   * @param to
   * @returns Spot type is selectable
   */
  isSelectable(from: SpotType, to: SpotType): boolean {
    return (
      SPOT_INFOS.find(
        (spotInfo: SpotInfo) => spotInfo.type === from,
      )?.selectableWith.includes(to) || false
    );
  },
  /**
   * Return spot type label
   * @param spotType
   * @returns Spot type label
   */
  getLabel(spotType: SpotType): string {
    return (
      SPOT_INFOS.find((spotInfo: SpotInfo) => spotInfo.type === spotType)
        ?.label || ''
    );
  },
  /**
   * Return if the spot type provided is custom
   * @param spotType
   * @returns Spot type custom
   */
  isCustomType(spotType: SpotType): boolean {
    return (
      SPOT_INFOS.find((spotInfo: SpotInfo) => spotInfo.type === spotType)
        ?.custom || false
    );
  },
  /**
   * Split playfield name into the real name and timeslot
   * @param playfieldName The playField name
   * @returns The playField data (name and timrslot)
   */
  getPlayFieldDataByName(playfieldName: string): SpotPlayFieldData {
    const playFieldData = {} as SpotPlayFieldData;
    const splittedName = playfieldName.split(' ');
    playFieldData.timeSlot = splittedName[splittedName.length - 1];
    splittedName.pop(); // remove timeslot
    playFieldData.name = splittedName.join(' '); // rebuild name except timeslot
    return playFieldData;
  },
  /**
   * Return spot type label
   * @param spotType
   * @returns Spot type label
   */
  getInfos(spotType: SpotType): SpotInfo | undefined {
    return SPOT_INFOS.find((spotInfo: SpotInfo) => spotInfo.type === spotType);
  },
};
