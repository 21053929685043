import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-3 text-2xl font-bold" }
const _hoisted_2 = { class: "mt-2 text-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h5", null, _toDisplayString(_ctx.$t('versionView.title')), 1),
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('app.name')), 1),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.version), 1)
  ], 64))
}