
import { defineComponent, PropType } from 'vue';
import { mapState } from 'vuex';
import draggable from 'vuedraggable';
import cloneDeep from 'lodash/cloneDeep';
import { DateTime } from 'luxon';
import { StatisticDataTableView } from '@/models/statistic';

export default defineComponent({
  name: 'StatisticsEditViews',
  components: {
    draggable,
  },
  props: {
    views: {
      type: Array as PropType<Array<StatisticDataTableView>>,
      required: true,
    },
    defaultView: {
      type: Object as PropType<StatisticDataTableView>,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      name: '',
      viewsActive: [] as Array<StatisticDataTableView>,
      drag: false,
      dragOptions: {
        animation: 200,
        disabled: false,
        ghostClass: 'ghost',
      },
    };
  },
  methods: {
    onAddView(): void {
      this.viewsActive.push({
        ...cloneDeep(this.defaultView),
        ...{ label: this.name, id: Math.round(DateTime.now().toSeconds()) },
      });
      this.name = '';
    },
    onRemoveView(view: StatisticDataTableView): void {
      this.viewsActive = this.viewsActive.filter(
        (v: StatisticDataTableView) => v !== view,
      );
    },
    onSave(): void {
      if (this.viewsActive.length === 0) {
        this.viewsActive.push({
          ...cloneDeep(this.defaultView),
          ...{ id: Math.round(DateTime.now().toSeconds()) },
        });
      }
      this.$emit('save', this.viewsActive);
      this.dialog = false;
    },
    onAbort(): void {
      this.dialog = false;
    },
  },
  computed: {
    ...mapState('app', ['windowWidth', 'breakpoints']),
  },
  watch: {
    dialog(): void {
      if (this.dialog) {
        this.viewsActive = cloneDeep(this.views);
      }
    },
  },
});
