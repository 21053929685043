
import { defineComponent, PropType } from 'vue';
import { mapState } from 'vuex';
import { ContentLoader } from 'vue-content-loader';
import { cloneDeep } from 'lodash';
import { ChartData } from '@/models/chart';

export default defineComponent({
  name: 'StatisticsDoughnutChart',
  components: {
    ContentLoader,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    chartData: {
      type: Object as PropType<ChartData>,
      required: true,
    },
    showTotal: {
      type: Boolean,
      required: false,
      default: false,
    },
    roundTotal: {
      type: Number,
      required: false,
      default: 0,
    },
    tooltip: {
      type: String,
      required: false,
    },
    suffix: {
      type: String,
      require: false,
      default: null,
    },
  },
  data() {
    return {
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
      },
      showTotalRounded: this.roundTotal >= 0,
    };
  },
  methods: {
    getLegendItemColor(index: number): string {
      let color = '';
      if (
        this.formattedChartData.datasets.length > 0 &&
        this.formattedChartData.datasets[0].backgroundColor &&
        this.formattedChartData.datasets[0].backgroundColor[index]
      ) {
        color = this.formattedChartData.datasets[0].backgroundColor[index];
      }
      return color;
    },
  },
  computed: {
    ...mapState('app', ['windowWidth', 'breakpoints']),
    formattedChartData(): ChartData {
      const formmattedChartData = cloneDeep(this.chartData);
      return formmattedChartData;
    },
    total(): number {
      return this.formattedChartData.datasets[0].data.reduce(
        (prev: number, current: number) => prev + current,
        0,
      );
    },
    classes(): Array<string> {
      const classes = [];
      if (this.showTotal) {
        classes.push('show-total');
      }
      return classes;
    },
    totalRounded(): number {
      const total = this.formattedChartData.datasets[0].data.reduce(
        (prev: number, current: number) => prev + current,
        0,
      );
      const pow = this.roundTotal <= 3 ? this.roundTotal : 3;
      const value = 10 ** pow;
      return Math.round(total * value) / value;
    },
  },
});
