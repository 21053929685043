
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      // Version
      version: process.env.VUE_APP_VERSION,
    };
  },
  methods: {},
  computed: {
    loading(): boolean {
      return this.$store.getters['app/loading'];
    },
  },
});
