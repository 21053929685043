
import { defineComponent, PropType } from 'vue';
import { mapState } from 'vuex';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartData, ChartDataset } from '@/models/chart';

interface ChartContext {
  dataIndex: number;
  dataset: {
    data: Array<number>;
  };
}

export default defineComponent({
  name: 'StatisticsBarChart',
  props: {
    label: {
      type: String,
      required: false,
    },
    height: {
      type: Number,
      required: false,
    },
    chartData: {
      type: Object as PropType<ChartData>,
      required: true,
    },
    horizontal: {
      type: Boolean,
      requred: false,
      default: true,
    },
    chartDataLabels: {
      type: Boolean,
      requred: false,
      default: true,
    },
    preValue: {
      type: String,
      required: false,
      default: '',
    },
    repositionLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    useTicks: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      canvasImage: '',
      plugins: this.chartDataLabels ? [ChartDataLabels] : [],
      options: {
        indexAxis: this.horizontal ? 'x' : 'y',
        maintainAspectRatio: false,
        scaleShowValues: true,
        scales: {
          x: {
            grid: {
              borderDash: [4, 4],
              color: '#AFAFAF',
              display: true,
            },
            ...this.useTicks ? {
              ticks: {
                callback: (value: number) => `${this.preValue} ${value.toString()}`,
              },
            } : {},
          },
          y: {
            beginAtZero: true,
            ticks: {
              precision: 0,
              autoSkip: false,
            },
            grid: {
              display: false,
            },
          },
        },
        plugins: {
          legend: {
            position: 'top',
            align: 'end',
            labels: {
              usePointStyle: true,
              boxWidth: 6,
              boxHeight: 6,
            },
          },
          datalabels: {
            anchor: 'end',
            align: 'start',
            offset: (context: ChartContext) => {
              const defaultOffset = -4;
              return this.repositionLabel
                ? this.calculateOffset(context.dataset.data, context.dataIndex)
                : defaultOffset;
            },
            font: {
              weight: 'bold',
            },
            color: '#000',
            // eslint-disable-next-line no-confusing-arrow
            formatter: (value: number) =>
              value === 0 ? '' : `${this.preValue} ${value.toString()}`,
          },
        },
        animation: {
          onComplete: () => {
            /**
             * Get canvas image
             */
            // linter see 'this' as chartjs instance instead vue component, any is required
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const chart = this.$refs.chart as any;
            if (chart && chart.getBase64Image) {
              this.canvasImage = chart.getBase64Image();
            }
          },
        },
      },
    };
  },
  computed: {
    ...mapState('app', ['windowWidth', 'breakpoints']),
    ready(): boolean {
      /**
       * Chart is ready when labels and data are present
       */
      return (
        this.chartData.labels.length > 0 &&
        this.chartData.datasets.some(
          (dataset: ChartDataset) => dataset.data.length > 0,
        )
      );
    },
  },
  methods: {
    calculateOffset(dataset: Array<number>, dataIndex: number): number {
      let offset = -4;
      const value = dataset[dataIndex];
      /** If it's greater than the half of the greatest value */
      if (value < Math.max(...dataset) / 2) {
        const hasDot = Math.abs(value) !== value;
        /** Digits offset */
        offset -= (String(value).length - (hasDot ? 1 : 0)) * 6;
        /** PreValue size */
        offset -= this.preValue.length * 12;
      }
      return offset;
    },
  },
});
