
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { useRouter } from 'vue-router';
import { DateTime } from 'luxon';
import {
  Reservation,
  ReservationHalfDay,
  ReservationStatus,
  RESERVATION_HALF_DAY_DATA,
} from '@/models/reservation';
import { ApiReservationUpdatePayload } from '@/models/api';
import { PrintArticle, PrintDeposit } from '@/models/printer';
import { SpotAttribute, SpotAttributesPayload } from '@/models/spot';
import { StyleSpotAttribute, StyleSpotAttributeIcons } from '@/models/style';
import ReservationDisplacementsAbsences from '@/components/reservation/ReservationDisplacementsAbsences.vue';
import ReservationHeaderPrintSummary from '@/components/reservation/header/ReservationHeaderPrintSummary.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import spotUtil from '@/utils/spotUtil';
import { LICENSE_FIVE_BEACH } from '@/models/license';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';
import ProcessingButton from '@/components/shared/ProcessingButton.vue';
import errorUtil from '@/utils/errorUtil';

export default defineComponent({
  name: 'ReservationConfiguration',
  components: {
    ReservationDisplacementsAbsences,
    ReservationHeaderPrintSummary,
    Breadcrumbs,
    ProcessingButton,
  },
  data() {
    return {
      halfDayOptions: cloneDeep(RESERVATION_HALF_DAY_DATA),
      printArticles: [] as Array<PrintArticle>,
      printDeposit: [] as Array<PrintDeposit>,
    };
  },
  methods: {
    hasPermissionCheckin(): boolean {
      return (
        !this.isAddition &&
        permissionsUtil.isActionPermissionAllowed(
          FEATURE_PERMISSION_CONFIG.reservations,
          FEATURE_PERMISSION_ACTION_CONFIG.reservations.CHECK_IN,
        )
      );
    },
    hasPermissionCheckout(): boolean {
      return (
        !this.isAddition &&
        permissionsUtil.isActionPermissionAllowed(
          FEATURE_PERMISSION_CONFIG.reservations,
          FEATURE_PERMISSION_ACTION_CONFIG.reservations.CHECK_OUT,
        )
      );
    },
    hasUpdatePermission(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.reservations,
        FEATURE_PERMISSION_ACTION_CONFIG.reservations.UPDATE,
      );
    },
    hasMovePermission(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.reservations,
        FEATURE_PERMISSION_ACTION_CONFIG.reservations.MOVE,
      );
    },
    async updateReservation(
      payload: ApiReservationUpdatePayload,
      getServices = false,
    ): Promise<void> {
      try {
        await this.$store.dispatch('reservation/updateReservation', payload);
        if (getServices) {
          this.$store.dispatch('reservation/getServices');
        }
        // this.$spiagge.toast.success('Prenotazione aggiornata con successo');
      } catch (e) {
        const internalErrorCode = errorUtil.getInternalErrorCode(e);

        if (internalErrorCode === 'INTERNAL_MANAGED_ERROR_EXCEPTION_I_R_004') {
          this.$spiagge.toast.error(
            this.$t('reservationConfiguration.toast.itinerantReservationError'),
          );
        } else {
          this.$spiagge.toast.error(
            this.$t('reservationConfiguration.toast.updateError'),
          );
        }
      }
    },
    onShift(): void {
      this.$store.commit('reservation/setCanExit', true);
      let startDate = this.startDate.toSeconds();
      let endDate = this.endDate.toSeconds();
      let spotType = this.spot.type;
      let spotName = this.spot.name;
      let id = this.id;
      /**
       * To properly use the forcedId functionality in the map endpoint
       * The information on the route must be updated
       * with the ones of the currently selected step
       */
      if (this.isMoving) {
        const step: Reservation = this.displacements.find(
          (displacement: Reservation) =>
            displacement.id === Number(this.$route.params.id),
        ) as Reservation;
        startDate = step.startDate;
        endDate = step.endDate;
        spotType = step.spotType;
        spotName = step.spotName;
        id = step.id;
      }
      this.$router.push(
        `/map?mode=2&startDate=${startDate}&endDate=${endDate}&spotType=${spotType}&spotName=${spotName}&reservationId=${id}`,
      );
    },
    onCopy(): void {
      this.$store.commit('reservation/setCanExit', true);
      this.$router.push(
        `/map?mode=3&startDate=${this.startDate.toSeconds()}&endDate=${this.endDate.toSeconds()}&spotType=${
          this.spot.type
        }&spotName=${this.spot.name}&reservationId=${this.id}`,
      );
    },
    async onCheckIn(): Promise<void> {
      try {
        await this.$store.dispatch('reservation/checkIn');
        this.$spiagge.toast.success(
          this.$t('reservationConfiguration.toast.checkInSuccess'),
        );
      } catch (error) {
        this.$spiagge.utils.error.handle(error);
      } finally {
        this.$store.commit('app/setProcessing', null);
      }
    },
    onCheckOut(): void {
      this.$confirm.require({
        header: this.$t('reservationConfiguration.confirm.title'),
        message: this.$t('reservationConfiguration.confirm.message'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('button.confirm'),
        rejectLabel: this.$t('button.cancel'),
        rejectClass: 'p-button-outlined',
        accept: async () => {
          try {
            await this.$store.dispatch('reservation/checkOut');
            this.$spiagge.toast.success(
              this.$t('reservationConfiguration.toast.checkOutSuccess'),
            );
          } catch (error) {
            this.$spiagge.utils.error.handle(error);
          } finally {
            this.$store.commit('app/setProcessing', null);
          }
        },
        reject: async () => {
          this.$store.commit('app/setProcessing', null);
        },
      });
    },
  },
  computed: {
    ...mapGetters('reservation', [
      'id',
      'totalDays',
      'isAddition',
      'isParking',
      'isMoving',
      'isCreate',
      'isAreaBeds',
      'isBoat',
      'isPlayField',
      'isSimpleAddition',
      'isCabinAddition',
      'totalToPay',
      'hasBookingTemp',
      'isBillReservation',
    ]),
    ...mapState('reservation', [
      'status',
      'spot',
      'master',
      'startDate',
      'endDate',
      'propagate',
      'totals',
      'cabins',
      'maxiBeds',
      'beds',
      'deckChairs',
      'chairs',
      'absences',
      'displacements',
      'online',
      'paid',
      'voucherId',
      'weatherPolicy',
      'noShowPolicy',
      'isCheckInAvailable',
      'isCheckOutAvailable',
    ]),
    ...mapState('session', ['services', 'license']),
    ...mapState('app', ['returnToRoute', 'reservationEntryPoint']),
    spotStyle(): StyleSpotAttribute {
      const spotAttributesPayload = {
        free: this.status === ReservationStatus.AVAILABLE,
        temporary: this.temporary,
        seasonal: this.seasonal,
        halfDay: this.halfDay,
        paid: this.paid,
        startDate: this.startDate,
        endDate: this.endDate,
        startDateRef: DateTime.now().startOf('day'),
        endDateRef: DateTime.now().startOf('day'),
      } as SpotAttributesPayload;

      const attributes = this.$spiagge.utils.global.spotAttributes(
        spotAttributesPayload,
      );
      const spotStyle = this.$store.getters['style/spot'];

      const attributeStyle = {} as StyleSpotAttribute;
      attributes.map((attribute: SpotAttribute) => {
        merge(attributeStyle, spotStyle[attribute]);
      });
      return attributeStyle;
    },
    temporary: {
      get(): boolean {
        return this.$store.getters['reservation/temporary'];
      },
      async set(temporary: boolean): Promise<void> {
        this.updateReservation({
          status: temporary
            ? ReservationStatus.NOT_CONFIRMED
            : ReservationStatus.CONFIRMED,
        });
      },
    },
    startDateCalendar: {
      get(): Date {
        return this.startDate.toJSDate();
      },
      async set(d: Date): Promise<void> {
        const dateTime = DateTime.fromFormat(
          `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`,
          'd-M-yyyy',
        );
        const payload: ApiReservationUpdatePayload = {
          startDate: dateTime.toSeconds(),
        };
        if (dateTime.toSeconds() > this.endDate.toSeconds()) {
          payload.endDate = dateTime.toSeconds();
        }
        this.updateReservation(payload, true);
      },
    },
    endDateCalendar: {
      get(): Date {
        return this.endDate.toJSDate();
      },
      async set(d: Date): Promise<void> {
        const dateTime = DateTime.fromFormat(
          `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`,
          'd-M-yyyy',
        );
        this.updateReservation(
          {
            endDate: dateTime.toSeconds(),
          },
          true,
        );
      },
    },
    halfDay: {
      get(): ReservationHalfDay {
        return this.$store.getters['reservation/halfDay'];
      },
      async set(halfDay: ReservationHalfDay): Promise<void> {
        this.updateReservation({
          halfDay,
        });
      },
    },
    seasonal: {
      get(): boolean {
        return this.$store.getters['reservation/seasonal'];
      },
      async set(seasonal: boolean): Promise<void> {
        try {
          await this.$store.dispatch('reservation/setSeasonal', seasonal);
          this.$store.dispatch('reservation/getServices');
        } catch (e) {
          this.$spiagge.toast.error(
            this.$t('reservationConfiguration.toast.updateError'),
          );
        }
      },
    },
    maxiBeds: {
      get(): number {
        return this.$store.getters['reservation/maxiBeds'];
      },
      set(maxiBeds: number): void {
        this.updateReservation(
          {
            maxiBeds,
          },
          true,
        );
      },
    },
    beds: {
      get(): number {
        return this.$store.getters['reservation/beds'];
      },
      set(beds: number): void {
        this.updateReservation(
          {
            beds,
          },
          true,
        );
      },
    },
    deckChairs: {
      get(): number {
        return this.$store.getters['reservation/deckChairs'];
      },
      set(deckChairs: number): void {
        this.updateReservation(
          {
            deckChairs,
          },
          true,
        );
      },
    },
    chairs: {
      get(): number {
        return this.$store.getters['reservation/chairs'];
      },
      set(chairs: number): void {
        this.updateReservation(
          {
            chairs,
          },
          true,
        );
      },
    },
    vehiclePlate(): string {
      return this.$store.getters['reservation/vehiclePlate'] || '';
    },
    spotIcon(): string {
      let icon = (this.spotStyle.icons as StyleSpotAttributeIcons)[
        this.spot.type
      ];
      if (this.isAddition && !this.isCabinAddition) {
        if (this.isSimpleAddition) {
          icon = this.$store.getters['style/spot'].busy.icons.bed;
        } else {
          icon = this.$store.getters['style/spot'].busy.icons[this.spot.type];
        }
      }
      return icon;
    },
    name(): string {
      let name = this.spot.name;
      /* moving of a reservation -> use moving spot name */
      if (this.isMoving && Number(this.$route.params.id) !== this.id) {
        const step: Reservation = this.displacements.find(
          (displacement: Reservation) =>
            displacement.id === Number(this.$route.params.id),
        ) as Reservation;
        name = step.spotName;
      }
      if (this.isPlayField) {
        name = spotUtil.getPlayFieldDataByName(this.spot.name).name;
      }
      if (this.isBillReservation) {
        name = this.$t('reservationConfiguration.ticketReservation');
      }
      return `${spotUtil.getLabel(this.spot.type)} ${name}`;
    },
    hasMessages(): boolean {
      return this.propagate.length > 0;
    },
    showMoveButton(): boolean {
      return (
        !this.isBillReservation && !this.isAddition && this.hasMovePermission()
      );
    },
    showDuplicateButton(): boolean {
      return (
        !this.isBillReservation &&
        !this.isAddition &&
        this.hasUpdatePermission()
      );
    },
    showDays(): boolean {
      return !this.isBoat;
    },
    showSeasonal(): boolean {
      return (
        !this.isAddition &&
        !this.isBoat &&
        !this.isPlayField &&
        !this.isBillReservation
      );
    },
    showHalfDay(): boolean {
      return !this.isPlayField && !this.isBillReservation;
    },
    showStaging(): boolean {
      return (
        !this.isParking &&
        !this.isBoat &&
        !this.isPlayField &&
        !this.isBillReservation
      );
    },
    showDisplacementsAbsences(): boolean {
      return this.hasUpdatePermission() && !this.isBillReservation;
    },
    timeSlot(): string {
      return this.isPlayField
        ? spotUtil.getPlayFieldDataByName(this.spot.name).timeSlot
        : '';
    },
    calendarDisabled(): boolean {
      return (
        (this.isMoving ||
          (this.voucherId &&
            LICENSE_FIVE_BEACH.includes(this.license.license))) &&
        !this.hasUpdatePermission()
      );
    },
  },
  beforeMount() {
    const router = useRouter();
    const historyBack =
      router.options.history.state.back?.toString().toLowerCase() ?? '';

    // set reservation entrypoint
    if (!historyBack.includes('/reservation') && this.returnToRoute) {
      this.$store.commit('app/setReservationEntryPoint', this.returnToRoute);
    }

    if (this.isAddition) {
      const masterReservationRoute = `/reservation/${this.master.id}`;
      const masterReservationName = `${spotUtil.getLabel(
        this.master.spotType,
      )} ${this.master.spotName}`;
      const isAdditionOfAddition = this.master.masterId !== null;
      // set breadcrumbs
      this.$store.commit('app/setBreadcrumbs', [
        {
          label: isAdditionOfAddition
            ? this.$t('reservationConfiguration.breadcrumbs.extra', {
                name: masterReservationName,
              })
            : this.$t('reservationConfiguration.breadcrumbs.reservation', {
                name: masterReservationName,
              }),
          to: masterReservationRoute,
          icon: 'spi-arrow-left',
        },
        {
          label: this.isSimpleAddition
            ? this.$t('reservationConfiguration.breadcrumbs.extra', {
                name: this.name,
              })
            : this.name,
        },
      ]);
      // set return route to previous page
      let returnToRoute = '/map';
      if (historyBack) {
        returnToRoute =
          historyBack === this.reservationEntryPoint
            ? this.reservationEntryPoint
            : masterReservationRoute;
      }
      this.$store.commit('app/setReturnToRoute', returnToRoute);
    } else {
      // if is master reservation set return route to the entrypoint
      this.$store.commit(
        'app/setReturnToRoute',
        this.reservationEntryPoint ?? '/map',
      );
    }
  },
  mounted() {
    this.$spiagge.utils.printMode.setup();
  },
});
