import axios, { AxiosResponse } from 'axios';
import {
  ApiLogFindPayload,
  ApiLogFindResponse,
  ApiLogCreatePayload,
} from '@/models/api';
import apiService from './apiService';

export default {
  async find(payload: ApiLogFindPayload): Promise<ApiLogFindResponse> {
    const res: AxiosResponse<ApiLogFindResponse> = await axios.get(
      apiService.log.find(payload),
    );
    return res.data;
  },
  async create(payload: ApiLogCreatePayload): Promise<void> {
    axios.post(apiService.log.create(), payload);
  },
};
