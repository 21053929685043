/* eslint-disable no-param-reassign */
import { DateTime } from 'luxon';
import { cloneDeep, has, isArray, isObject, omit, values } from 'lodash';
import {
  Reservation,
  ReservationExpense,
  ReservationExpenseRaw,
  ReservationExpenseRawDataNormal,
  ReservationExpenseRawDataObjectWithoutProducts,
  ReservationExpenseRawDataObjectWithProducts,
  ReservationType,
} from '@/models/reservation';
import { SpotType } from '@/models/spot';
import { License } from '@/models/license';
import { phoneUtils } from './phoneUtils';

export default {
  deserializeExpense(expense: ReservationExpenseRaw): ReservationExpense {
    const deserializedExpense: ReservationExpense = cloneDeep(
      omit(expense, 'data'),
    ) as ReservationExpense;
    if (isArray(expense.data)) {
      deserializedExpense.items =
        expense.data as ReservationExpenseRawDataNormal;
    }
    if (isObject(expense.data)) {
      if (has(expense.data, 'products')) {
        deserializedExpense.items = (
          expense.data as ReservationExpenseRawDataObjectWithProducts
        ).products;
      } else {
        deserializedExpense.signature = (
          expense.data as ReservationExpenseRawDataObjectWithoutProducts
        ).signature;
        deserializedExpense.items = values(
          omit(
            expense.data as ReservationExpenseRawDataObjectWithoutProducts,
            'signature',
          ),
        );
      }
    }
    deserializedExpense.signature = deserializedExpense.signature ?? '';
    return deserializedExpense;
  },
  getTotalOfReservation(reservation: Reservation): number {
    if (reservation.forcedTotal !== null) return reservation.forcedTotal;

    let total = 0;
    if (reservation.forcedBeach !== null) {
      total += reservation.forcedBeach;
    } else {
      total += reservation.listBeach || 0;
    }
    total += reservation.forcedAdditions ?? (reservation.listAdditions || 0);
    total += reservation.forcedServices ?? (reservation.listServices || 0);
    total += reservation.forcedExpenses ?? (reservation.listExpenses || 0);
    return total;
  },
  getSpotReceiptDescription(
    reservation: Reservation,
    areaBedsName: string,
  ): string {
    switch (reservation.spotType) {
      case SpotType.CABIN:
        return `Cab ${reservation.spotName}`;
      case SpotType.PARKING:
        return `Park ${reservation.spotName}`;
      case SpotType.BEDS:
        return `Area ${areaBedsName}`;
      case SpotType.BED:
        return `Lett ${reservation.spotName}`;
      case SpotType.GAZEBO:
        return `Gazebo ${reservation.spotName}`;
      default:
        if (reservation.type === ReservationType.ADDITION) {
          return '';
        }
    }
    return `Omb ${reservation.spotName}`;
  },
  /**
   * Maybe change
   * @param reservation
   */
  getReceiptDescription(reservation: Reservation): string {
    const description = [] as Array<string>;
    const spotName = reservation.spotName;

    // detail of the reservation sector
    const areaBedsName = reservation.sector?.name ?? spotName;

    // Joint Account description
    if (reservation.type === ReservationType.JOINT_ACCOUNT) {
      const elementDescriptions: Array<string> = [];
      const children: Array<Reservation> = reservation.children ?? [];
      children.forEach((element: Reservation) =>
        elementDescriptions.push(
          this.getSpotReceiptDescription(element, areaBedsName),
        ),
      );
      const desc = elementDescriptions.join(' - ');
      return `CU ${desc}`;
    }
    // Standard reservation description
    const spotDescription = this.getSpotReceiptDescription(
      reservation,
      areaBedsName,
    );
    if (spotDescription.length > 0) {
      description.push(spotDescription);
    }

    if (reservation.maxiBeds) {
      description.push(`Max ${reservation.maxiBeds}`);
    }
    if (reservation.beds) {
      description.push(`Lett ${reservation.beds}`);
    }
    if (reservation.chairs) {
      description.push(`Sedie ${reservation.chairs}`);
    }
    if (reservation.deckChairs) {
      description.push(`Sdraio ${reservation?.deckChairs}`);
    }
    let string = description.join(' + ');
    const startDate = reservation.startDate;
    const endDate = reservation.endDate;
    string += ` ${DateTime.fromSeconds(startDate).toFormat('dd/MM')}`;
    if (startDate !== endDate) {
      string += ` - ${DateTime.fromSeconds(endDate).toFormat('dd/MM')}`;
    }
    return `Serv. ${string}`;
  },
  getPhoneAreaCode(reservation: Reservation, license: License): string {
    if (typeof reservation.phoneAreaCode === 'string') {
      return reservation.phoneAreaCode;
    }

    return phoneUtils.getPhoneAreaCodeByLicense(license);
  },
};
