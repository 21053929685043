import { DateTime } from 'luxon';
import {
  MapDecorationType,
  MapDecorationTypeRaw,
  MapElementTypeRaw,
} from './map';
import { SpotType } from './spot';

export const MAP_CELL_WIDTH = 40;
export const MAP_CELL_HEIGHT = 40;
export const MAP_OFFSET_WIDTH = 3; // columns available after last element
export const MAP_OFFSET_HEIGHT = 3; // rows available after last element

export interface MapEditorSpot {
  id: number;
  name: string;
  posX: number; // cell
  posY: number; // cell
  width: number; // cell
  height: number; // cell
  online: boolean;
  type: SpotType;
  priceListId: number;
  priceListOnlineId: number;
  sector: number;
  background: string;
  onlineNotAvailableDays: Array<string>;
  timeSlot: string;
}

export interface MapEditorElementRawData {
  'background-color'?: string;
  elementType?: MapDecorationTypeRaw;
  height?: number;
  width?: number;
  rotation?: number;
  url?: string;
  timeSlot?: string;
}

export interface MapEditorOnlineNotAvailableDaysRaw {
  [key: string]: boolean;
}

export interface MapEditorElementRawJsonData {
  onlineNotAvailableDays: MapEditorOnlineNotAvailableDaysRaw;
}

export interface MapEditorElementRaw {
  id: number;
  name: string;
  posX: number;
  posY: number;
  online: number;
  type: MapElementTypeRaw;
  data: MapEditorElementRawData | null;
  createdAt: null; // not used
  updatedAt: null; // not used
  jsonData: MapEditorElementRawJsonData | null;
  priceListId: number;
  priceListOnlineId: number;
  sector: number;
}

export enum MapEditorSidebarStep {
  HOME,
  ELEMENTS_LIST,
  DECORATIONS_LIST,
  FORM,
}

export interface MapEditorSidebarItem {
  label: string;
  icon: string;
  type: SpotType | MapDecorationType | null;
}

export interface MapEditorForm {
  [key: string]:
    | number
    | string
    | Array<DateTime>
    | boolean
    | SpotType
    | MapDecorationType
    | Array<string>;
  type: SpotType | MapDecorationType;
  id: number;
  name: string; // name edit
  nameBefore: string; // name create
  nameNumber: number; // name create
  nameAfter: string; // name create
  posX: number;
  posY: number;
  width: number;
  height: number;
  rotation: number;
  onlineNotAvailableDays: Array<string>;
  backgroundColor: string;
  backgroundUrl: string;
  color: string;
  online: boolean;
  orientation: 'v' | 'o';
  sector: number;
  priceListId: number;
  priceListOnlineId: number;
  timeSlot: string;
}

export const MAP_EDITOR_DEFAULT_FORM = {
  id: 0,
  type: SpotType.UMBRELLA,
  name: '',
  nameBefore: '',
  nameNumber: 1,
  nameAfter: '',
  posX: 0,
  posY: 0,
  width: 1,
  height: 1,
  rotation: 0,
  onlineNotAvailableDays: [],
  backgroundColor: '',
  backgroundUrl: '',
  color: '',
  online: true,
  orientation: 'v',
  sector: 0,
  priceListId: 0,
  priceListOnlineId: 0,
  timeSlot: '',
} as MapEditorForm;

export interface MapEditorFormField {
  name: string;
  value: number | string | boolean | Array<string>;
}

/* GRID */

export enum MapEditorGridActionLayout {
  COLUMNS,
  ROWS,
}

export enum MapEditorGridAction {
  ADD_COL = 'add-col',
  DEL_COL = 'remove-col',
  ADD_ROW = 'add-row',
  DEL_ROW = 'remove-row',
}

export enum MapEditorGridHighlightType {
  NONE,
  CELL,
  COLUMN,
  ROW,
}

export enum MapEditorGridHighlightColor {
  NONE = '', // none
  PRW = 'rgb(0, 176 ,255, .5)', // preview
  DEL = 'rgba(255, 171, 171, .5)', // delete
  ADD = 'rgba(187, 247, 165, .5)', // add
}

export interface MapEditorGridHighlight {
  type: MapEditorGridHighlightType;
  x: number;
  y: number;
  width: number;
  height: number;
  color: MapEditorGridHighlightColor;
}

export const MAP_EDITOR_DEFAULT_GRID_HIGHLIGHT = {
  type: MapEditorGridHighlightType.NONE,
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  color: MapEditorGridHighlightColor.NONE,
} as MapEditorGridHighlight;
