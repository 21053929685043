
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import TopBarProfile from '@/components/topbar/TopBarProfile.vue';
import TopBarSearch from '@/components/topbar/TopBarSearch.vue';
import TopBarOtherServices from '@/components/topbar/TopBarOtherServices.vue';
import TopBarNotifications from '@/components/topbar/TopBarNotifications.vue';
import TopBarNewReservation from '@/components/topbar/TopBarNewReservation.vue';
import TopBarCheckoutPointSelector from './topbar/TopBarCheckoutPointSelector.vue';
import TopBarCardReader from './topbar/TopBarCardReader.vue';
import TopBarChatBot from './topbar/TopBarChatBot.vue';
import ProgressBarStriped from '@/components/ProgressBarStriped.vue';
import Logo from '@/components/Logo.vue';
import TopBarNewTicketReservation from '@/components/topbar/TopBarNewTicketReservation.vue';

export default defineComponent({
  name: 'TopBar',
  components: {
    TopBarNewTicketReservation,
    TopBarProfile,
    TopBarSearch,
    TopBarOtherServices,
    TopBarCheckoutPointSelector,
    TopBarCardReader,
    TopBarNotifications,
    TopBarNewReservation,
    Logo,
    ProgressBarStriped,
    TopBarChatBot,
  },
  data() {
    return {
      open: '',
    };
  },
  methods: {
    toggleNavigation(): void {
      this.$store.commit('app/toggleNavigation');
    },
    isChatBotEnabled(): boolean {
      return (
        this.license &&
        this.license.featureFlags &&
        this.license.featureFlags.chatBotEnabled &&
        window.matchMedia('(min-width: 768px)').matches
      );
    },
  },
  computed: {
    ...mapState('session', ['license', 'user']),
    ...mapState('app', ['loading', 'processing']),
  },
});
