/**
 * format
 * @description Return the formatted number string in the given format
 * @param {string|number} number
 */
const format = (number: number | string, locale = 'it'): string => {
  const options: Intl.NumberFormatOptions = {
    currency: 'EUR',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
    style: 'currency',
  };

  return new Intl.NumberFormat(locale, options).format(Number(number));
};

export default { format };
