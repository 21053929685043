
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { has, orderBy } from 'lodash';
import OverlayPanel from 'primevue/overlaypanel';
import statisticService from '@/services/statisticService';
import { Spot, SpotType } from '@/models/spot';
import {
  MapBackground,
  MapDecoration as MapDecorationI,
  MapElementTypeRaw,
  MapType,
} from '@/models/map';
import Map from '@/components/shared/Map.vue';
import {
  MAP_OFFSET_WIDTH,
  MAP_OFFSET_HEIGHT,
  MapEditorElementRaw,
} from '@/models/mapEditor';
import spotService from '@/services/spotService';
import {
  ApiStatisticsSpotPayload,
  ApiStatisticsSpotResponse,
} from '@/models/api';
import MapDecoration from '@/components/shared/MapDecoration.vue';
import { StatisticDateMode } from '@/models/statistic';
import mapEditorUtil from '@/utils/mapEditorUtil';
import { CalendarRange, Css, DropdownOption } from '@/models';

interface Interval {
  from: number;
  to: number;
  background: string;
}

interface StatisticSpot {
  posX: number;
  posY: number;
  value: number;
  name: string;
  background: string;
}

interface SpotData {
  [key: string]: string | number;
  spotName: string;
  reservations: number;
  people: number;
  totalDays: number;
  totalRevenue: number;
  averageRevenue: number;
  productivity: number;
  inRangeReservations: number;
  inRangePeople: number;
  inRangeDays: number;
  cashFlow: number;
}

export default defineComponent({
  name: 'StatisticsSpotMapView',
  components: { Map, MapDecoration },
  data() {
    return {
      elements: [] as Array<Spot>,
      spotsData: [] as Array<SpotData>,

      mapBackground: null as unknown as MapBackground,
      ready: false,
      cellWidth: 60,
      cellHeight: 40,
      type: MapType.STATISTICS,

      intervalColors: ['#96DFFF', '#00B0FF', '#0083E8'],
      // filters
      reservationFilter: ['offline'],
      viewFilter: 'totalDays',
      // options
      reservationOptions: [
        {
          label: this.$t('statisticsSpotMapView.option.online'),
          value: 'online',
        },
        {
          label: this.$t('statisticsSpotMapView.option.offline'),
          value: 'offline',
        },
      ] as Array<DropdownOption>,
      viewOptions: [
        {
          label: this.$t('statisticsSpotMapView.option.reservations'),
          value: 'reservations',
        },
        {
          label: this.getSpotReservationsColumnName(),
          value: 'spotReservations',
        },
        {
          label: this.$t('statisticsSpotMapView.option.extraReservations'),
          value: 'extraReservations',
        },
        {
          label: this.$t('statisticsSpotMapView.option.people'),
          value: 'people',
        },
        {
          label: this.$t('statisticsSpotMapView.option.totalDays'),
          value: 'totalDays',
        },
        {
          label: this.$t('statisticsSpotMapView.option.totalRevenue'),
          value: 'totalRevenue',
        },
        {
          label: this.$t('statisticsSpotMapView.option.averageRevenue'),
          value: 'averageRevenue',
        },
        {
          label: this.$t('statisticsSpotMapView.option.averageCart'),
          value: 'averageCart',
        },
        {
          label: this.$t('statisticsSpotMapView.option.productivity'),
          value: 'productivity',
        },
        {
          label: this.$t('statisticsSpotMapView.option.arrivedReservations'),
          value: 'arrivedReservations',
        },
        {
          label: this.$t('statisticsSpotMapView.option.arrivedPeople'),
          value: 'arrivedPeople',
        },
        {
          label: this.$t('statisticsSpotMapView.option.arrivalsDays'),
          value: 'arrivalsDays',
        },
        {
          label: this.$t('statisticsSpotMapView.option.arrivalsRevenue'),
          value: 'arrivalsRevenue',
        },
        {
          label: this.$t('statisticsSpotMapView.option.cashFlow'),
          value: 'cashFlow',
        },
      ],
    };
  },
  async beforeMount(): Promise<void> {
    // reset title
    this.$store.commit('statistic/setTitle', '');

    // get elements and spots data
    this.getElements();
    this.getSpotsData();
    // set background
    if (this.license.mapBg) {
      this.mapBackground = {
        url: this.license.mapBg,
        width: this.license.mapBgWidth,
        height: this.license.mapBgHeight,
        posX: this.license.mapBgPosX,
        posY: this.license.mapBgPosY,
      } as MapBackground;
    }
    this.ready = true;
  },
  methods: {
    async getElements(): Promise<void> {
      /**
       * Get mal elements
       */
      this.elements = (await spotService.find({})).result.spots;
    },
    async getSpotsData(): Promise<void> {
      /**
       * Get spots statistics data
       */
      try {
        const res: ApiStatisticsSpotResponse = await statisticService.spot({
          dateMode: StatisticDateMode.ATTENDANCE,
          startDate: this.calendar[0].toSeconds(),
          endDate: this.calendar[1].toSeconds(),
          limit: 99999,
          offset: 0,
          search: { columns: [], value: '' },
          orderBy: [],
          columns: [
            'spotName',
            'reservations',
            'spotReservations',
            'extraReservations',
            'people',
            'totalDays',
            'totalRevenue',
            'averageRevenue',
            'averageCart',
            'productivity',
            'arrivalsDays',
            'arrivedReservations',
            'arrivedPeople',
            'arrivalsRevenue',
            'cashFlow',
          ],
          groupData: false,
          filters: [
            {
              name: 'spotType',
              value: this.$route.params.spotType,
            },
            {
              name: 'online',
              value: this.reservationFilter.includes('online'),
            },
            {
              name: 'offline',
              value: this.reservationFilter.includes('offline'),
            },
          ],
        } as ApiStatisticsSpotPayload);
        this.spotsData = res.result.dataTable.elements;
      } catch (e) {
        this.$spiagge.toast.error(this.$t('statisticsSpotMapView.toast.error'));
      }
    },
    getSpotReservationsColumnName(): string {
      return this.$route.params.spotType === SpotType.CABIN
        ? this.$t('statisticsSpotMapView.option.cabinReservations')
        : this.$t('statisticsSpotMapView.option.umbrellaReservations');
    },
    openFilters(event: Event): void {
      /**
       * Open filters panel
       */
      (this.$refs.op as OverlayPanel).toggle(event);
    },
    openLegend(event: Event): void {
      /**
       * Open legend panel
       */
      (this.$refs.legendPanel as OverlayPanel).toggle(event);
    },
    onTableView(): void {
      /**
       * Move to statistics view
       */
      this.$router.push(`/statistics/spot/${this.$route.params.spotType}`);
    },
    getBackgroundValue(value: number): string {
      /**
       * Get spot background vby value
       */
      return (
        this.intervals.find(
          (interval: Interval) =>
            value >= interval.from && value <= interval.to,
        )?.background ?? ''
      );
    },
    getSpotStyle(spot: StatisticSpot): Css {
      return {
        backgroundColor: this.getBackgroundValue(spot.value),
        position: 'absolute',
        left: `${spot.posX * this.cellWidth}px`,
        top: `${spot.posY * this.cellHeight}px`,
        height: `${this.cellHeight - 1}px`,
        width: `${this.cellWidth - 1}px`,
      } as Css;
    },
  },
  computed: {
    ...mapState('session', ['license']),
    ...mapState('statistic', ['calendar']),
    ...mapState('app', ['breakpoints', 'windowWidth', 'windowHeight']),
    decorations(): Array<MapDecorationI> {
      return this.elements
        .filter((element: Spot) =>
          [
            MapElementTypeRaw.MAP_LABEL,
            MapElementTypeRaw.MAP_LINE_H,
            MapElementTypeRaw.MAP_LINE_V,
            MapElementTypeRaw.FOOTBOARD,
            MapElementTypeRaw.ELEMENT,
          ].includes(element.type),
        )
        .map((decoration: Spot) =>
          mapEditorUtil.decorationDeserialize(
            decoration as MapEditorElementRaw,
          ),
        );
    },
    spots(): Array<Spot> {
      return this.elements.filter((element: Spot) =>
        Object.values(SpotType).includes(element.type as unknown as SpotType),
      );
    },
    offsetWidth(): number {
      /**
       * Add extra offset if last spot is close to the map ending (width)
       */
      let offsetWidth = 0;
      const orderedElements = orderBy(this.elements, ['posX'], 'desc');
      if (orderedElements.length > 0) {
        const lastElement = orderedElements[0];
        if (
          Math.ceil(
            Math.max(
              this.elementsWidth,
              this.containerWidthCells,
              this.backgroundWidth,
            ),
          ) -
            Math.ceil(lastElement.posX) <
          MAP_OFFSET_WIDTH
        ) {
          offsetWidth = MAP_OFFSET_WIDTH;
        }
      }
      return offsetWidth;
    },
    offsetHeight(): number {
      /**
       * Add extra offset if last spot is close to the map ending (height)
       */
      let offsetHeight = 0;
      const orderedElements = orderBy(this.elements, ['posY'], 'desc');
      if (orderedElements.length > 0) {
        const lastElement = orderedElements[0];
        if (
          Math.ceil(
            Math.max(
              this.elementsHeight,
              this.containerHeightCells,
              this.backgroundHeight,
            ),
          ) -
            Math.ceil(lastElement.posY) <
          MAP_OFFSET_HEIGHT
        ) {
          offsetHeight = MAP_OFFSET_HEIGHT;
        }
      }
      return offsetHeight;
    },
    mapWidth(): number {
      /**
       * Map width based on elements area, container and background
       */
      return Math.ceil(
        Math.max(
          this.elementsWidth,
          this.containerWidthCells,
          this.backgroundWidth,
        ) + this.offsetWidth,
      );
    },
    mapHeight(): number {
      /**
       * Map height based on elements area, container and background
       */
      return Math.ceil(
        Math.max(
          this.elementsHeight,
          this.containerHeightCells,
          this.backgroundHeight,
        ) + this.offsetHeight,
      );
    },
    elementsWidth(): number {
      /**
       * Elements width surface area
       */
      let elementsWidth = 0;
      this.elements.map((element: Spot) => {
        if (element.posX > elementsWidth) {
          elementsWidth = element.posX;
        }
      });
      return elementsWidth > 0 ? Math.ceil(elementsWidth) + 1 : 0;
    },
    elementsHeight(): number {
      /**
       * Elements height surface area
       */
      let elementsHeight = 0;
      this.elements.map((element: Spot) => {
        if (element.posY > elementsHeight) {
          elementsHeight = element.posY;
        }
      });
      return Math.ceil(elementsHeight) + 1;
    },
    containerWidth(): number {
      /**
       * Container width (px)
       */
      let containerWidth = this.windowWidth;
      if (this.breakpoints.desktop) {
        containerWidth -= 80; // navigation
        containerWidth -= 300; // statistics navigation
      }
      return containerWidth;
    },
    containerHeight(): number {
      /**
       * Container height (px)
       */
      let containerHeight = this.windowHeight;
      containerHeight -= 64; // topbar
      if (this.breakpoints.mobile) {
        containerHeight -= 64; // statistics navigation mobile
      }
      return containerHeight;
    },
    containerWidthCells(): number {
      /**
       * Container width (cells)
       */
      return Math.ceil(this.containerWidth / this.cellWidth);
    },
    containerHeightCells(): number {
      /**
       * Container height (cells)
       */
      return Math.ceil(this.containerHeight / this.cellHeight);
    },
    backgroundWidth(): number {
      /**
       * Background width (cells), considering background x offset
       */
      return this.mapBackground?.width
        ? Math.ceil(this.mapBackground.width + this.mapBackground.posX)
        : 0;
    },
    backgroundHeight(): number {
      /**
       * Background height (cells), considering background y offset
       */
      return this.mapBackground?.height
        ? Math.ceil(this.mapBackground.height + this.mapBackground.posY)
        : 0;
    },
    intervals(): Array<Interval> {
      /**
       * Intervals (view max value based)
       */
      const nIntervals = this.intervalColors.length;
      const values: Array<number> = this.spotsData.map(
        (spotData: SpotData) => spotData[this.viewFilter] as number,
      );
      const maxValue = Math.max(...values);
      const intervalValue = Math.round(maxValue / nIntervals);
      const intervals: Array<Interval> = [];
      for (let i = 0; i < nIntervals; i += 1) {
        intervals.push({
          from: i * intervalValue,
          to: (i + 1) * intervalValue,
          background: this.intervalColors[i],
        });
      }
      intervals[intervals.length - 1].to = maxValue;
      return intervals;
    },
    statisticSpots(): Array<StatisticSpot> {
      const statisticSpots: Array<StatisticSpot> = [];
      const spotType = this.$route.params.spotType;
      let otherParam = {} as MapElementTypeRaw | null;
      otherParam = null;

      if (spotType === MapElementTypeRaw.UMBRELLA) {
        otherParam = MapElementTypeRaw.GAZEBO;
      }

      if (spotType === MapElementTypeRaw.GAZEBO) {
        otherParam = MapElementTypeRaw.UMBRELLA;
      }

      this.spots
        .filter((spot: Spot) => {
          if (otherParam) {
            return spot.type === spotType || spot.type === otherParam;
          }
          return spot.type === spotType;
        })
        .forEach((spot: Spot) => {
          const spotData = this.spotsData.find(
            (spotData: SpotData) => spotData.spotName === spot.name,
          );
          if (spotData && has(spotData, this.viewFilter)) {
            statisticSpots.push({
              posX: spot.posX,
              posY: spot.posY,
              value: spotData[this.viewFilter],
              name: spot.name,
              background: this.getBackgroundValue(
                spotData[this.viewFilter] as number,
              ),
            } as StatisticSpot);
          }
        });
      return statisticSpots;
    },
  },
  watch: {
    calendar(calendar: CalendarRange) {
      if (calendar[1]) {
        this.getSpotsData();
      }
    },
  },
});
