import { normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_tooltip = _resolveDirective("tooltip")!

  return _withDirectives((_openBlock(), _createElementBlock("i", {
    class: "spi-help",
    style: _normalizeStyle(`font-size:${_ctx.size}px`)
  }, null, 4)), [
    [_directive_tooltip, _ctx.message]
  ])
}