import { Card } from './card';

export interface Phone {
  phoneAreaCode: string;
  phoneNumber: string;
}

export interface Address {
  address: string;
}

export interface OtherContact {
  emails: Array<Address>;
  phones: Array<Phone>;
}
export enum Gender {
  UNKNOWN = 0,
  FEMALE,
  MALE,
}

export interface CustomerTag {
  color: string;
  idWorldTag: number;
  label: string;
  work: number;
}

export interface Pet {
  name: string;
  gender?: Gender;
  breed?: string;
  chipCode?: string;
  documentNumber?: string;
}
export interface Customer {
  contactId?: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneAreaCode: string;
  hasAssociatedCard?: boolean;
  phoneNumber: string;
  invoiceCompany: string;
  invoiceVatCode: string;
  invoiceLotteryCode: string;
  invoiceTaxCode: string;
  invoicePec: string;
  invoiceSdi: string;
  invoicePlaceId: number;
  invoiceAddress1: string;
  invoiceAddress2: string;
  invoiceCity: string;
  invoiceZip: string;
  invoiceState: string;
  invoiceCountry: string;
  otherContacts: OtherContact;
  dogs: Array<Pet>;
  cards: Array<Card>;
  tags: Array<number>;
}

export interface Guest extends Customer {
  contactId: number;
}

export interface Contact extends Customer {
  contactId: number;
}

export const DEFAULT_CUSTOMER: Customer = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  phoneAreaCode: '+39',
  invoiceCompany: '',
  invoiceVatCode: '',
  invoiceTaxCode: '',
  invoiceLotteryCode: '',
  invoicePec: '',
  invoiceSdi: '',
  invoiceAddress1: '',
  invoiceAddress2: '',
  invoiceCity: '',
  invoiceZip: '',
  invoiceState: '',
  invoiceCountry: '',
  cards: [] as Array<Card>,
} as Customer;
