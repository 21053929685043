import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = {
  id: "main",
  class: "print-main"
}
const _hoisted_3 = { class: "actions-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toast = _resolveComponent("toast")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_SpotDialog = _resolveComponent("SpotDialog")!
  const _component_PendingRequestLoader = _resolveComponent("PendingRequestLoader")!
  const _component_ProgressDialog = _resolveComponent("ProgressDialog")!
  const _component_top_bar = _resolveComponent("top-bar")!
  const _component_navigation = _resolveComponent("navigation")!
  const _component_ProductFruits = _resolveComponent("ProductFruits")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_CheckToday = _resolveComponent("CheckToday")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_toast, {
      id: "app-toast",
      group: "app"
    }),
    _createVNode(_component_ConfirmDialog),
    _createVNode(_component_SpotDialog),
    _createVNode(_component_PendingRequestLoader),
    _createVNode(_component_ProgressDialog),
    _createVNode(_component_top_bar),
    _createVNode(_component_navigation),
    (_ctx.user && _ctx.roleUser)
      ? (_openBlock(), _createBlock(_component_ProductFruits, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("main", _hoisted_2, [
      _createVNode(_component_router_view, {
        key: _ctx.$route.fullPath
      })
    ]),
    _createVNode(_component_Dialog, {
      id: "refresh-dialog",
      class: "custom-dialog",
      modal: true,
      closable: false,
      visible: _ctx.showRefreshDialog,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showRefreshDialog) = $event)),
      onHide: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showRefreshDialog = false))
    }, {
      default: _withCtx(() => [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('app.updateAvailableTitle')), 1),
        _createElementVNode("h5", null, _toDisplayString(_ctx.$t('app.updateAvailableMessage')), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Button, {
            label: _ctx.$t('button.update'),
            class: "p-button-success",
            onClick: _ctx.onRefresh
          }, null, 8, ["label", "onClick"])
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_CheckToday)
  ]))
}