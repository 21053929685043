
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
// import isArray from 'lodash/isArray';
import has from 'lodash/has';
import { MapCalendar, MapPlanner } from '@/models/map';
import { DEFAULT_MAP_STATE } from '@/models/store';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  ActionPermissionType,
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

enum Weight {
  'none',
  'low',
  'high',
}

interface Months {
  [monthName: string]: {
    label: string;
    days: {
      [key: string]: string;
    };
  };
}

export default defineComponent({
  name: 'MapPlanner',
  data() {
    return {
      currentYear: DateTime.now().year,
    };
  },
  methods: {},
  computed: {
    FEATURE_PERMISSION_ACTION_CONFIG() {
      return FEATURE_PERMISSION_ACTION_CONFIG;
    },
    FEATURE_PERMISSION_CONFIG() {
      return FEATURE_PERMISSION_CONFIG;
    },
    ActionPermissionType() {
      return ActionPermissionType;
    },
    permissionsUtil() {
      return permissionsUtil;
    },
    calendar(): MapCalendar {
      return this.$store.getters['map/calendar'];
    },
    planner(): MapPlanner {
      return this.$store.getters['map/planner'];
    },
    plannerActive: {
      get(): boolean {
        return this.$store.getters['map/planner'].active;
      },
      set(active: boolean): void {
        this.$store.commit('map/setPlannerActive', active);
      },
    },
    classes(): Array<string> {
      const classes = ['map-control', this.plannerActive ? 'open' : 'closed'];
      if (!this.planner.data) {
        classes.push('no-data');
      }
      return classes;
    },
    months(): Months {
      let cursor = DateTime.fromFormat('01-05', 'dd-MM');
      const end = DateTime.fromFormat('30-09', 'dd-MM');
      const months = {} as Months;
      while (cursor <= end) {
        const monthKey = cursor.toFormat('MM');
        if (!months[monthKey]) {
          months[monthKey] = {
            label: cursor.toFormat('MMM'),
            days: {},
          };
        }
        const classes = [];
        // Active
        if (
          (!this.calendar[1] &&
            this.calendar[0].toMillis() === cursor.toMillis()) ||
          (this.calendar[1] &&
            cursor >= this.calendar[0] &&
            cursor <= this.calendar[1])
        ) {
          classes.push('active');
        }
        let weight = Weight[0];
        if (
          this.planner.data &&
          this.planner.data.preview &&
          has(this.planner.data.preview, cursor.toSeconds())
        ) {
          weight = Weight[this.planner.data.preview[cursor.toSeconds()]];
        }
        classes.push(weight);
        months[monthKey].days[cursor.toSeconds().toString()] =
          classes.join(' ');
        cursor = cursor.plus({ days: 1 });
      }
      return months;
    },
  },
  beforeUnmount() {
    this.$store.commit('map/setPlanner', DEFAULT_MAP_STATE.planner);
  },
});
