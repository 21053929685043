
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
import licenseService from '@/services/licenseService';
import { PayloadSeasonalSettings } from '@/models/license';
import { DropdownOption } from '@/models';
import { PriceList } from '@/models/priceList';
import CalendarInput from '@/components/shared/CalendarInput.vue';
import reservationService from '@/services/reservationService';

export default defineComponent({
  name: 'SeasonalDialog',
  emits: ['close'],
  components: {
    CalendarInput,
  },
  props: {
    priceLists: {
      type: Array as () => Array<PriceList>,
      default: [] as Array<PriceList>,
    },
  },
  data() {
    return {
      model: {} as PayloadSeasonalSettings,
      currentYear: DateTime.now().year,
      copySeasonalDisabled: false,
    };
  },
  computed: {
    ...mapState('session', ['license']),
    priceListOptions(): Array<DropdownOption> {
      const options: Array<DropdownOption> = [
        { value: null, label: this.$t('seasonalDialog.noPriceList') },
      ];
      this.priceLists.map((p: PriceList) => {
        options.push({
          value: p.id,
          label: p.name,
        } as DropdownOption);
      });
      return options;
    },
    canCopySeasonalRes(): boolean {
      let result = true;
      if (this.license) {
        result = this.license.seasonalLastCopy < this.copyYear;
      }
      return result;
    },
    copyYear(): number {
      return DateTime.now().month < 9
        ? DateTime.now().year
        : DateTime.now().year + 1;
    },
  },
  methods: {
    async onFormSubmit(): Promise<void> {
      try {
        const response = await licenseService.updateSeasonalSettings(
          this.license.license,
          this.model,
        );
        this.$store.commit('session/setLicense', response.result.license);
        this.$spiagge.toast.success(
          this.$t('toast.save.title'),
          this.$t('toast.save.content'),
        );
        this.$emit('close');
      } catch (e) {
        // console.log(e);
      }
    },
    async copyLastYearSeasonalRes(): Promise<void> {
      this.copySeasonalDisabled = true;
      try {
        await reservationService.copySeasonal();
        this.$spiagge.toast.success(this.$t('seasonalDialog.toast.success'));
        this.$store.dispatch('session/setLicense', this.license?.license);
      } catch (error) {
        this.$spiagge.toast.error(this.$t('seasonalDialog.toast.error'));
        // console.log(error);
      } finally {
        this.copySeasonalDisabled = false;
      }
    },
    confirmSeasonalCopy() {
      this.$confirm.require({
        message: this.$t('seasonalDialog.confirm.message'),
        header: this.$t('seasonalDialog.confirm.title'),
        icon: 'pi pi-info-circle',
        acceptLabel: this.$t('common.yes'),
        accept: () => {
          this.copyLastYearSeasonalRes();
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          // do nothing
        },
      });
    },
  },
  created() {
    this.model = {
      seasonalPriceListId: this.license.seasonalPriceListId,
      seasonalStartDate: this.license.seasonalStartDate,
      seasonalEndDate: this.license.seasonalEndDate,
    } as PayloadSeasonalSettings;
  },
});
