
import { defineComponent, PropType } from 'vue';
import { DateTime } from 'luxon';
import { mapGetters, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import { PageState } from 'primevue/paginator';
import ContextMenu from 'primevue/contextmenu';
import { DataTableSortMeta } from 'primevue/datatable';
import OverlayPanel from 'primevue/overlaypanel';
import cookieUtil from '@/utils/cookieUtil';
import {
  StatisticDataTableColumn,
  StatisticDataTableColumnIcon,
  StatisticDataTableOrderBy,
  StatisticDataTableRow,
  StatisticDataTableView,
  StatisticDiscountCodeType,
} from '@/models/statistic';
import StatisticsEditColumns from '@/components/statistics/StatisticsEditColumns.vue';
import StatisticsEditViews from '@/components/statistics/StatisticsEditViews.vue';
import { ApiStatisticsDataTablePayload } from '@/models/api';
import { CalendarRange } from '@/models';
import fileUtil from '@/utils/fileUtil';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';
import { Service } from '@/models/service';
import { ServiceGroup } from '@/models/serviceGroup/serviceGroup';

interface MenuModel {
  label: string;
  command: () => void;
}

export default defineComponent({
  name: 'StatisticsDataTable',
  components: {
    StatisticsEditColumns,
    StatisticsEditViews,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    columns: {
      type: Array as PropType<Array<StatisticDataTableColumn>>,
      required: true,
    },
    endpoint: {
      type: Function,
      required: true,
    },
    defaultView: {
      type: Object as PropType<StatisticDataTableView>,
      required: true,
    },
    searchColumns: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    basePayload: {
      type: Object,
      required: false,
    },
    rowClass: {
      type: Function as PropType<() => string>,
      required: false,
      default: () => '',
    },
    onRowClick: {
      type: Function,
      required: false,
    },
    contextMenu: {
      type: Array as PropType<Array<MenuModel>>,
      required: false,
      default: [] as Array<MenuModel>,
    },
    tooltipText: {
      type: String,
      required: false,
    },
    exportEndpoint: {
      type: Function,
      required: false,
    },
    icons: {
      type: Array as PropType<Array<StatisticDataTableColumnIcon>>,
      required: false,
    },
    paginate: {
      type: Boolean,
      default: true,
    },
    totalsRow: {
      type: Boolean,
      required: false,
      default: false,
    },
    serverSorting: {
      type: Boolean,
      required: false,
      default: true,
    },
    printTitle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      viewIndex: -1,
      view: {} as StatisticDataTableView,
      views: [] as Array<StatisticDataTableView>,
      cookieViews: [] as Array<StatisticDataTableView>,
      showFilters: false,
      filters: [],
      appliedFilters: 0,
      onSearchDebounce: debounce(this.onSearch as unknown as () => void, 1250),
      search: '',
      editColumns: false,
      ready: false,
      multiSortMeta: [] as Array<DataTableSortMeta>,
      tableRowsOptions: [10, 25, 50, 100, 500, 1000],
      tableRows: 10,
      elements: [] as Array<StatisticDataTableRow>,
      totalRecords: 0,
      selection: [] as Array<{ [key: string]: string }>,
      loading: true,
      printing: false,
      selectedRow: {} as { [key: string]: number | string },
      exporting: false,
      hideService: true,
      mappedGroups: {} as Map<number, ServiceGroup>,
      mappedServices: {} as Map<number, Service>,
    };
  },
  mounted() {
    this.loadViews();
  },
  methods: {
    hasExportPermission(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.stats,
        FEATURE_PERMISSION_ACTION_CONFIG.stats.EXPORT_STATISTICHE,
      );
    },
    async onPage(event: PageState): Promise<void> {
      /**
       * On page change
       */
      this.tableRows = event.rows;
      this.runQuery(event.page * event.rows);
    },
    onSort(e: { multiSortMeta: Array<DataTableSortMeta> }) {
      /**
       * Sort
       */
      this.multiSortMeta = e.multiSortMeta;
      if (this.serverSorting) {
        this.sortByServer();
      } else {
        this.sortByClient();
      }
    },
    sortByServer(): void {
      const orderBy = [] as Array<StatisticDataTableOrderBy>;
      this.multiSortMeta.map((meta: DataTableSortMeta) => {
        orderBy.push({
          column: meta.field,
          value:
            typeof meta.order === 'number'
              ? meta.order < 0
                ? 'desc'
                : 'asc'
              : 'asc',
        });
      });
      this.view.orderBy = orderBy;
    },
    sortByClient(): void {
      this.multiSortMeta.map((meta: DataTableSortMeta) => {
        if (!this.serverSorting && meta && meta.field) {
          /** Remove totals row from dataTable elements */
          this.elements.pop();
          const sortCondition = (
            a: StatisticDataTableRow,
            b: StatisticDataTableRow,
          ) => {
            if (typeof meta.order === 'number') {
              if (meta.order > 0) {
                return (a[meta.field] ?? 0) < (b[meta.field] ?? 0) ? -1 : 1;
              }
              return (a[meta.field] ?? 0) > (b[meta.field] ?? 0) ? -1 : 1;
            }
            return 1;
          };
          this.elements.sort(sortCondition);
          this.addTotalsRow();
        }
      });
    },
    updateColumns(columns: Array<string>): void {
      /**
       * Update view columns
       */
      this.view.columns = columns;
    },
    sanitizeViews(): void {
      /**
       * Remove invalid columns stored in the cookie
       */
      const columns = this.columns.map(
        (column: StatisticDataTableColumn) => column.field,
      );
      const sanitizedViews = this.cookieViews.map(
        (view: StatisticDataTableView) => {
          const sanitizedColumns = [] as Array<string>;
          view.columns.forEach((column: string) => {
            if (columns.includes(column)) {
              sanitizedColumns.push(column);
            }
          });
          return {
            ...view,
            ...{
              columns: sanitizedColumns,
            },
          };
        },
      );
      cookieUtil.set(
        `${this.id}-${this.license.license}-views`,
        JSON.stringify(sanitizedViews),
      );
      this.cookieViews = sanitizedViews;
    },
    updateViews(views: Array<StatisticDataTableView>): void {
      /**
       * Update views
       */
      this.views = views;
      this.cookieViews = views;
      cookieUtil.set(
        `${this.id}-${this.license.license}-views`,
        JSON.stringify(this.views),
      );
      const currentView = this.views.findIndex(
        (view: StatisticDataTableView) => this.view.id === view.id,
      );
      if (currentView === -1) {
        // current view does not exist anymore, use the first available
        this.view = this.views[0];
      } else {
        this.views.map((view: StatisticDataTableView) => {
          // update current view
          if (view.id === this.view.id) {
            this.view = view;
          }
        });
      }
    },
    updateViewIndex(): void {
      /**
       * Update cookie view
       */
      cookieUtil.set(
        `${this.id}-${this.license.license}-view`,
        String(this.view.id),
      );
    },
    onViewChange(): void {
      /**
       * View change
       */
      cookieUtil.set(
        `${this.id}-${this.license.license}-view`,
        this.view.id.toString(),
      );
      this.multiSortMeta = [];
      this.view.orderBy.map((orderBy: StatisticDataTableOrderBy) => {
        this.multiSortMeta.push({
          field: orderBy.column,
          order: orderBy.value === 'desc' ? -1 : 1,
        } as DataTableSortMeta);
      });
    },
    onViewSave(): void {
      /**
       * View save
       */
      const updatedViews = cloneDeep(this.cookieViews);
      const index = updatedViews.findIndex(
        (view: StatisticDataTableView) => view.id === this.view.id,
      );
      if (index !== -1) {
        updatedViews[index] = cloneDeep(this.view);
        cookieUtil.set(
          `${this.id}-${this.license.license}-views`,
          JSON.stringify(updatedViews),
        );
        this.cookieViews = cloneDeep(updatedViews);
      }
    },
    loadViews(): void {
      /**
       * Load views by cookie
       */
      this.views = [];
      this.cookieViews = [];
      if (
        cookieUtil.get(`${this.id}-${this.license.license}-views`) !== undefined
      ) {
        this.cookieViews = JSON.parse(
          cookieUtil.get(`${this.id}-${this.license.license}-views`) ?? '',
        );
        this.sanitizeViews();
      }
      const cookieView = Number(
        cookieUtil.get(`${this.id}-${this.license.license}-view`),
      );
      if (this.cookieViews && this.cookieViews.length > 0) {
        this.views = cloneDeep(this.cookieViews);
        const index = this.cookieViews.findIndex(
          (v: StatisticDataTableView) => v.id === cookieView,
        );
        if (index === -1) {
          // last view used does not exist anymore, use the first available
          this.view = this.views[0];
        } else {
          this.view = this.views[index];
        }
        this.view.orderBy.map((orderBy: StatisticDataTableOrderBy) => {
          this.multiSortMeta.push({
            field: orderBy.column,
            order: orderBy.value === 'desc' ? -1 : 1,
          } as DataTableSortMeta);
        });
        // GET LAST VIEW USED BY COOKIE
      } else {
        // no view available, create default one with specific id
        const defaultView = {
          ...cloneDeep(this.defaultView),
          ...{ id: Math.round(DateTime.now().toSeconds()) },
        };
        this.views.push(defaultView);
        this.cookieViews.push(defaultView);
        this.view = this.views[0];
        this.onViewSave();
        // SAVE TO COOKIE
      }
      // Load Groups
      this.mappedGroups = new Map<number, ServiceGroup>();
      this.licenseServiceGroups.forEach((group: ServiceGroup) => {
        this.mappedGroups.set(group.id, cloneDeep(group));
      });
      this.mappedServices = new Map<number, Service>();
      this.services.forEach((service: Service) => {
        this.mappedServices.set(service.id, cloneDeep(service));
      });
      this.ready = true;
    },
    onSearch(): void {
      /**
       * Search
       */
      this.runQuery();
    },
    onRowContextMenu(event: { originalEvent: PointerEvent }) {
      /**
       * Open context menu
       */
      if (this.contextMenu.length > 0) {
        this.$emit('selected-row', this.selectedRow);
        (this.$refs as { cm: ContextMenu }).cm.show(event.originalEvent);
      }
    },
    addListenerToTableRows(): void {
      /**
       * Add row listener
       */
      const htmlRows = document.querySelectorAll('tr.row');
      for (let i = 0; i < htmlRows.length; i += 1) {
        const tr = document.getElementsByClassName('row').item(i);
        if (tr) {
          tr.setAttribute('title', this.tooltipText || '');
        }
      }
    },
    buildPayload(offset = 0, limit = 0): ApiStatisticsDataTablePayload {
      /**
       * Build datatable payload
       */
      const payload = {
        startDate: this.calendar[0].toSeconds(),
        endDate: this.calendar[1].toSeconds(),
        search: {
          columns: this.searchColumns,
          value: this.search,
        },
        columns: this.view.columns,
        orderBy: this.view.orderBy,
      } as ApiStatisticsDataTablePayload;
      if (this.paginatorEnabled) {
        Object.assign(payload, {
          limit: limit > 0 ? limit : this.tableRows,
          offset,
        });
      }
      if (this.basePayload) {
        Object.assign(payload, this.basePayload);
      }
      return payload;
    },
    async runQuery(offset = 0, limit = 0): Promise<void> {
      /**
       * Run datatable query
       */
      this.elements = [];
      this.loading = true;
      const payload = this.buildPayload(offset, limit);
      try {
        const res = await this.endpoint(payload);
        this.totalRecords = res.result.dataTable.count;
        this.elements = res.result.dataTable.elements;
        this.$emit('result', res);
        if (this.totalsRow) {
          this.addTotalsRow();
        }
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('statisticsDataTable.toast.retrievalError'),
        );
      } finally {
        this.loading = false;
        if (this.tooltipText) {
          this.$nextTick(() => {
            this.addListenerToTableRows();
          });
        }
      }
    },
    async onPrint(): Promise<void> {
      /**
       * Print the table. Remove pagination to show all the elements available.
       */
      this.printing = true;
      await this.runQuery(0, 99999999);
      const dataTableHtml: HTMLElement = document.getElementById(
        'data-table',
      ) as HTMLElement;
      if (dataTableHtml) {
        if (this.printTitle) {
          const title = document.createElement('h2');
          title.id = 'print-title';
          title.classList.add('print-only');
          title.innerText = this.printTitle;
          dataTableHtml.prepend(title);
        }
        const totalsHtml = document.getElementsByClassName('table-after-slot');
        if (totalsHtml.length > 0) {
          dataTableHtml.append(totalsHtml[0]);
        }
        this.$spiagge.utils.global.printHtml(dataTableHtml);
      }
      // restore table
      // eslint-disable-next-line no-unused-expressions
      document.getElementById('print-title')?.remove();
      await this.runQuery(0);
      this.printing = false;
    },
    async onExport(): Promise<void> {
      /**
       * Export the datatable
       */
      if (!this.exportEndpoint) return;
      this.exporting = true;
      const payload = this.buildPayload();
      try {
        const res = await this.exportEndpoint(payload);
        fileUtil.download(
          res,
          `${this.id}-${Math.round(DateTime.now().toSeconds())}.csv`,
        );
        this.$spiagge.toast.success(
          this.$t('statisticsDataTable.toast.exportSuccess'),
        );
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('statisticsDataTable.toast.exportError'),
        );
      } finally {
        this.exporting = false;
      }
    },
    getIcon(columnIcon: StatisticDataTableColumnIcon): string {
      /**
       * Get the icon
       */
      const splitName = columnIcon.icon.split('-');
      const icon = splitName[1] ?? '';
      // eslint-disable-next-line import/no-dynamic-require
      return require(`@/assets/iconset/${icon}.svg`);
    },
    getDiscountTypeTagClass(value: string | null): string {
      return value === null
        ? ''
        : value === StatisticDiscountCodeType.SPIAGGE
        ? 'light-yellow-orange'
        : 'light-purple-purple';
    },
    getRevenueManagerTagClass(value: number): string {
      return value === 0
        ? ''
        : value > 0
        ? 'light-green-green'
        : 'light-grey-grey';
    },
    getOnlineTagClass(value: boolean): string {
      return value ? 'light-green-green' : 'light-red';
    },
    getRevenueManagerIconClass(value: number): string {
      return value === 0
        ? ''
        : value > 0
        ? 'p-tag-icon pi pi-arrow-up'
        : 'p-tag-icon pi pi-arrow-down';
    },
    getRevenueManagerDescription(value: number): string {
      return value === 0 ? '' : value > 0 ? `+${value}%` : `-${value}%`;
    },
    addTotalsRow(): void {
      /**
       * Append a row with totals by column
       */
      const totalsRow = {} as StatisticDataTableRow;
      let columnTotal = 0;
      let invalidColumn = false;
      this.filteredColumns.forEach((column: StatisticDataTableColumn) => {
        // reset variables
        columnTotal = 0;
        invalidColumn = false;
        let nullValues = 0;
        this.elements.every((element: StatisticDataTableRow) => {
          // TODO prevent total on timestamp
          if (!isNumber(element[column.field])) {
            if (element[column.field] === null) {
              nullValues += 1;
            } else {
              invalidColumn = true;
              return false;
            }
          }
          columnTotal += (element[column.field] ?? 0) as number;
          return true;
        });
        if (!invalidColumn) {
          totalsRow[column.field] = Math.round(columnTotal * 100) / 100;

          if (column.average) {
            if ((this.totalRecords - nullValues) > 0) {
              totalsRow[column.field] =
                Math.round((columnTotal / this.totalRecords - nullValues) * 100) / 100;
            } else {
              totalsRow[column.field] = 0;
            }
          }
        }
      });
      totalsRow.totalsRow = this.$t('statisticsDataTable.total');
      this.elements.push(totalsRow);
    },
    // Ticket detail
    async onOpenTicketDetail(
      $event: PointerEvent,
      index: number,
    ): Promise<void> {
      // already open, close it
      if (!this.hideService) {
        this.onCloseTicketDetail(index);
      } else {
        const ref = `op-t-${index}`;
        (this.$refs[ref] as OverlayPanel).show($event, $event.target);
      }
    },
    onCloseTicketDetail(index: number): void {
      this.hideService = true;
      const ref = `op-t-${index}`;
      // eslint-disable-next-line no-unused-expressions
      (this.$refs[ref] as OverlayPanel)?.hide();
    },
    // Services detail
    async onOpenServiceDetail(
      $event: PointerEvent,
      index: number,
    ): Promise<void> {
      // already open, close it
      if (!this.hideService) {
        this.onCloseServiceDetail(index);
      } else {
        const ref = `op-s-${index}`;
        (this.$refs[ref] as OverlayPanel).show($event, $event.target);
      }
    },
    onCloseServiceDetail(index: number): void {
      this.hideService = true;
      const ref = `op-s-${index}`;
      // eslint-disable-next-line no-unused-expressions
      (this.$refs[ref] as OverlayPanel)?.hide();
    },
    getServiceIcon(id: number): string {
      const service = this.services.find(
        (service: Service) => service.id === id,
      );
      if (!service) {
        return '';
      }
      return service.icon;
    },
    getServiceName(id: number): string {
      const service = this.services.find(
        (service: Service) => service.id === id,
      );
      if (!service) {
        return '';
      }
      return service.name;
    },
    getTotalServices(services: Record<number, number>): number {
      return Array.from(Object.values(services)).reduce(
        (total: number, qnt: number) => total + qnt,
        0,
      );
    },
    getTicketLabel(tickets: Record<number, number>): string {
      const total = this.getTotalServices(tickets);
      if (total < 1) return '';
      return `${String(total)} ${this.$tc(
        'statisticsArrivalsDepartures.column.ticket',
        total > 1 ? 2 : 1,
      )}`;
    },
    getServiceLabel(services: Record<number, number>): string {
      const total = this.getTotalServices(services);
      if (total < 1) return '';
      return `${String(total)} ${this.$tc(
        'statisticsArrivalsDepartures.column.service',
        total > 1 ? 2 : 1,
      )}`;
    },
    getGroupedServices(
      services: Record<number, number>,
    ): Map<number, Map<number, number>> {
      const result = new Map<number, Map<number, number>>();
      Array.from(Object.keys(services)).forEach((serviceId: string) => {
        const sId = Number(serviceId);
        const quantity = services[sId];
        if (quantity < 1) {
          return;
        }
        const groupId = this.mappedServices.get(sId)?.serviceGroupId;
        if (!groupId) return;
        const group = this.mappedGroups.get(groupId);
        if (!group) return;
        if (!result.has(groupId)) {
          result.set(groupId, new Map<number, number>());
        }
        const currentGroup = result.get(groupId);
        if (!currentGroup) return;
        currentGroup.set(sId, quantity);
      });
      return result;
    },
    getGroupLabel(groupId: number): string {
      const group = this.mappedGroups.get(groupId);
      if (!group) {
        return `id: ${groupId}`;
      }
      return group.name;
    },
  },
  computed: {
    ...mapState('session', ['license', 'services']),
    ...mapState('statistic', ['calendar']),
    ...mapGetters('session', {
      licenseServiceGroups: 'serviceGroups',
    }),
    viewChanged(): boolean {
      /**
       * Hightlight view updated
       */
      const cookieView = this.cookieViews.find(
        (v: StatisticDataTableView) => v.id === this.view.id,
      );
      return !isEqual(cookieView, this.view);
    },
    scrollHeight(): string {
      let height = '';
      height = 'auto';

      return height;
    },
    paginatorEnabled(): boolean {
      return this.paginate && !this.printing;
    },
    filteredColumns(): Array<StatisticDataTableColumn> {
      /**
       * Datatable columns (intersection between view columns and all columns available)
       */
      const filteredColumns: Array<StatisticDataTableColumn> = [];
      this.view.columns.map((column: string) => {
        const c = this.columns.find(
          (c: StatisticDataTableColumn) => c.field === column,
        );
        if (c) {
          filteredColumns.push(c);
        }
      });
      return filteredColumns;
    },
    tableClasses(): string {
      /**
       * Datatable table classes
       */
      const tableClasses = [];
      if (this.totalsRow) {
        tableClasses.push('totals-row');
      }
      return tableClasses.join(' ');
    },
  },
  watch: {
    view: {
      handler() {
        this.runQuery();
      },
      deep: true,
    },
    calendar(calendar: CalendarRange) {
      if (calendar[1]) {
        this.runQuery();
      }
    },
    basePayload: {
      handler() {
        this.runQuery();
      },
      deep: true,
    },
  },
});
