
/* eslint-disable import/no-dynamic-require */
/* eslint-disable @typescript-eslint/no-var-requires */
import { defineComponent } from 'vue';
import { ColorName, colorSpecs, SPOT_ICON_COLORS } from '@/models/color';
import { DropdownOption } from '@/models';

export default defineComponent({
  name: 'SettingsSpotColorDropdown',
  emits: ['selected'],
  props: {
    label: {
      required: true,
      type: String,
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      colorOptions: [] as Array<DropdownOption>,
      selectedColor: '',
      optionStyle: {
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
      },
    };
  },
  beforeMount() {
    this.colorOptions = SPOT_ICON_COLORS.map(
      (name: ColorName) =>
        ({
          label: this.$t(`color.${name.toLowerCase()}`),
          value: name as unknown as string,
        } as DropdownOption),
    );
    this.selectedColor = (this.value ?? ColorName.GREEN) as string;
  },
  methods: {
    iconSrc(value: string): string {
      const colorSpec = colorSpecs.get(value);
      if (!colorSpec) return '';
      return require(`@/assets/images/map/umbrella/${colorSpec.icon}.svg`);
    },
    onColorChange(event: { value: string }): void {
      this.$emit('selected', event.value);
    },
  },
  watch: {
    value(value: string) {
      this.selectedColor = value;
    },
  },
});
