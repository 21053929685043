import { PrintPaymentMethod } from './printer';

export interface CashDeskArticle {
  name: string;
  qnt: number;
  priceList: number;
  priceForced: number;
  serviceId?: number;
}

export interface CashDeskCashFlow {
  amount: number;
  method: number;
  notes?: string;
  receipt?: {
    printerId: number;
    date: number;
    method: PrintPaymentMethod;
    number: string;
    total: number;
    subTotal1: number;
    vat1: number;
    subTotal2?: number;
    vat2?: number;
    subTotal3?: number;
    vat3?: number;
    subTotal4?: number;
    vat4?: number;
  };
}

export interface CashDeskPayload {
  articles: Array<CashDeskArticle>;
  cashFlow?: CashDeskCashFlow; // optional for pos payment
}
