import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8bea2624"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "field-binder" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "field-header" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { class: "field-body" }
const _hoisted_7 = { for: "city1" }
const _hoisted_8 = { class: "radio-options field-radiobutton" }
const _hoisted_9 = { for: "city1" }
const _hoisted_10 = { class: "radio-options field-radiobutton" }
const _hoisted_11 = { for: "city1" }
const _hoisted_12 = { for: "city1" }
const _hoisted_13 = {
  key: 0,
  class: "block"
}
const _hoisted_14 = {
  key: 0,
  class: "specific-error"
}
const _hoisted_15 = {
  key: 1,
  class: "generic-error"
}
const _hoisted_16 = {
  key: 2,
  class: "unknown-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FieldPriceConfigPeriods = _resolveComponent("FieldPriceConfigPeriods")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_FieldPriceConfigSectorCombinationPieces = _resolveComponent("FieldPriceConfigSectorCombinationPieces")!
  const _component_InlineMessage = _resolveComponent("InlineMessage")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field, fieldIndex) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: `f-${fieldIndex}`
      }, [
        (_ctx.fieldsVisibilityC[field.n])
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "dynamic-field",
              id: `${field.n}-field`
            }, [
              _createElementVNode("header", _hoisted_3, [
                (_ctx.$te(`dynamicForm.${_ctx.id}.${field.n}.label`))
                  ? (_openBlock(), _createElementBlock("h6", _hoisted_4, _toDisplayString(_ctx.$t(`dynamicForm.${_ctx.id}.${field.n}.label`)), 1))
                  : _createCommentVNode("", true),
                (_ctx.$te(`dynamicForm.${_ctx.id}.${field.n}.description`))
                  ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t(`dynamicForm.${_ctx.id}.${field.n}.description`)), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_6, [
                (_ctx.types[field.n] === 'input-text')
                  ? (_openBlock(), _createBlock(_component_InputText, {
                      key: 0,
                      type: "text",
                      modelValue: _ctx.model[field.n],
                      "onUpdate:modelValue": ($event: any) => ((_ctx.model[field.n]) = $event),
                      disabled: field.d
                    }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]))
                  : (_ctx.types[field.n] === 'input-number')
                    ? (_openBlock(), _createBlock(_component_InputNumber, {
                        key: 1,
                        modelValue: _ctx.model[field.n],
                        "onUpdate:modelValue": ($event: any) => ((_ctx.model[field.n]) = $event),
                        disabled: field.d,
                        useGrouping: false
                      }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]))
                    : (_ctx.types[field.n] === 'radio')
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(field.o, (option, optionIndex) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: `o-${optionIndex}`,
                            class: "radio-options field-radiobutton"
                          }, [
                            _createVNode(_component_RadioButton, {
                              class: "radio-option",
                              value: option,
                              modelValue: _ctx.model[field.n],
                              "onUpdate:modelValue": ($event: any) => ((_ctx.model[field.n]) = $event),
                              disabled: field.d
                            }, null, 8, ["value", "modelValue", "onUpdate:modelValue", "disabled"]),
                            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t(`dynamicForm.${_ctx.id}.${field.n}.options.${option}`)), 1)
                          ]))
                        }), 128))
                      : (_ctx.types[field.n] === 'yes/no')
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                            _createElementVNode("div", _hoisted_8, [
                              _createVNode(_component_RadioButton, {
                                class: "radio-option",
                                value: false,
                                modelValue: _ctx.model[field.n],
                                "onUpdate:modelValue": ($event: any) => ((_ctx.model[field.n]) = $event),
                                disabled: field.d
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
                              _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('common.no')), 1)
                            ]),
                            _createElementVNode("div", _hoisted_10, [
                              _createVNode(_component_RadioButton, {
                                class: "radio-option",
                                value: true,
                                modelValue: _ctx.model[field.n],
                                "onUpdate:modelValue": ($event: any) => ((_ctx.model[field.n]) = $event),
                                disabled: field.d
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"]),
                              _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('common.yes')), 1)
                            ])
                          ], 64))
                        : (_ctx.types[field.n] === 'checkbox')
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 4 }, _renderList(field.o, (option, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: `o-${index}`,
                                class: "checkbox-options field-checkbox"
                              }, [
                                _createVNode(_component_Checkbox, {
                                  inputId: "city1",
                                  value: option,
                                  modelValue: _ctx.model[field.n],
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.model[field.n]) = $event),
                                  disabled: field.d
                                }, null, 8, ["value", "modelValue", "onUpdate:modelValue", "disabled"]),
                                _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t(`dynamicForm.${_ctx.id}.${field.n}.options.${option}`)), 1)
                              ]))
                            }), 128))
                          : (_ctx.types[field.n] === 'dropdown')
                            ? (_openBlock(), _createBlock(_component_Dropdown, {
                                key: 5,
                                modelValue: _ctx.model[field.n],
                                "onUpdate:modelValue": ($event: any) => ((_ctx.model[field.n]) = $event),
                                optionLabel: "label",
                                optionValue: "value",
                                options: _ctx.getDropdownOptions(field),
                                placeholder: _ctx.$t('button.select'),
                                disabled: field.d
                              }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "placeholder", "disabled"]))
                            : (_ctx.types[field.n] === 'FieldPriceConfigPeriods')
                              ? (_openBlock(), _createBlock(_component_FieldPriceConfigPeriods, {
                                  key: 6,
                                  modelValue: _ctx.model[field.n],
                                  "onUpdate:modelValue": ($event: any) => ((_ctx.model[field.n]) = $event),
                                  data: _ctx.data,
                                  year: this.selectedYear,
                                  errors: _ctx.errors ? _ctx.errors[field.n] : {},
                                  disabled: field.d
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "data", "year", "errors", "disabled"]))
                              : (_ctx.types[field.n] === 'multiselect')
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 7 }, [
                                    _createVNode(_component_MultiSelect, {
                                      modelValue: _ctx.model[field.n],
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.model[field.n]) = $event),
                                      optionLabel: "label",
                                      optionValue: "value",
                                      options: _ctx.getDropdownOptions(field),
                                      placeholder: _ctx.$t('button.select'),
                                      disabled: field.d,
                                      selectionLimit: field.max ?? 99,
                                      showToggleAll: false
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "placeholder", "disabled", "selectionLimit"]),
                                    (field.max)
                                      ? (_openBlock(), _createElementBlock("small", _hoisted_13, _toDisplayString(_ctx.$t('common.max')) + " " + _toDisplayString(field.max), 1))
                                      : _createCommentVNode("", true)
                                  ], 64))
                                : (_ctx.types[field.n] === 'FieldPriceConfigCombinations')
                                  ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.types[field.n]), {
                                      key: 8,
                                      modelValue: _ctx.model[field.n],
                                      "onUpdate:modelValue": ($event: any) => ((_ctx.model[field.n]) = $event),
                                      optionNames: _ctx.getFieldConfigCombinations(field.o),
                                      data: _ctx.data,
                                      errors: _ctx.errors ? _ctx.errors[field.n] : {},
                                      disabled: field.d
                                    }, null, 8, ["modelValue", "onUpdate:modelValue", "optionNames", "data", "errors", "disabled"]))
                                  : (_ctx.types[field.n] === 'FieldPriceConfigSectorCombinationPieces')
                                    ? (_openBlock(), _createBlock(_component_FieldPriceConfigSectorCombinationPieces, {
                                        key: 9,
                                        id: this.id,
                                        modelValue: _ctx.model[field.n],
                                        "onUpdate:modelValue": ($event: any) => ((_ctx.model[field.n]) = $event),
                                        fields: _ctx.getNestedFields(field),
                                        data: _ctx.data,
                                        errors: _ctx.errors ? _ctx.errors[field.n] : {},
                                        disabled: field.d
                                      }, null, 8, ["id", "modelValue", "onUpdate:modelValue", "fields", "data", "errors", "disabled"]))
                                    : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.types[field.n]), {
                                        key: 10,
                                        modelValue: _ctx.model[field.n],
                                        "onUpdate:modelValue": ($event: any) => ((_ctx.model[field.n]) = $event),
                                        data: _ctx.data,
                                        errors: _ctx.errors ? _ctx.errors[field.n] : {},
                                        disabled: field.d
                                      }, null, 8, ["modelValue", "onUpdate:modelValue", "data", "errors", "disabled"])),
                (_ctx.errors && _ctx.errors[field.n])
                  ? (_openBlock(), _createBlock(_component_InlineMessage, {
                      key: 11,
                      id: `${field.n}-error`,
                      closable: false,
                      severity: "error",
                      class: "error"
                    }, {
                      default: _withCtx(() => [
                        (
                _ctx.$te(`dynamicForm.${_ctx.id}.${field.n}.errors.${_ctx.errors[field.n]}`)
              )
                          ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(_ctx.$t(`dynamicForm.${_ctx.id}.${field.n}.errors.${_ctx.errors[field.n]}`)), 1))
                          : (_ctx.$te(`dynamicForm.errors.${_ctx.errors[field.n]}`))
                            ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.$t(`dynamicForm.errors.${_ctx.errors[field.n]}`)), 1))
                            : (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(_ctx.$t('dynamicForm.errorMessage')), 1))
                      ]),
                      _: 2
                    }, 1032, ["id"]))
                  : _createCommentVNode("", true)
              ])
            ], 8, _hoisted_2))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}