import { App } from 'vue';
import { DateTime } from 'luxon';
import toastUtil from '@/utils/toastUtil';
import { ToastSeverity } from '@/models/toast';
import dateUtil from '@/utils/dateUtil';
import globalUtil from '@/utils';
import printerUtil from '@/utils/printerUtil';
import reservationUtil from '@/utils/reservationUtil';
import { SpotAttribute, SpotAttributesPayload, SpotType } from '@/models/spot';
import { StyleSpotAttribute } from '@/models/style';
import { CashFlowMethod } from '@/models/cashFlow';
import {
  Print,
  PrinterError,
  PrintMode,
  PrintPaymentMethod,
} from '@/models/printer';
import { Reservation } from '@/models/reservation';
import { PrintFormat, PrintOrientation } from '@/models/print';
import { Timestamp } from '@/models';
import spotUtil from '@/utils/spotUtil';
import { Service } from '@/models/service';
import serviceUtil from '@/utils/serviceUtil';
import errorUtil from '@/utils/errorUtil';
import { ErrorLog } from '@/models/error';

export interface SpiaggeToast {
  success: (title: string, content?: string, group?: string) => void;
  info: (title: string, content?: string, group?: string) => void;
  warn: (title: string, content?: string, group?: string) => void;
  error: (title: string, content?: string, group?: string) => void;
  show: (
    severity: ToastSeverity,
    title: string,
    content?: string,
    group?: string,
  ) => void;
}

export interface SpiaggePluginGlobalUtil {
  openLink: (url: string, blank?: boolean) => void;
  stagingLabel: (
    maxiBeds: number,
    beds: number,
    deckChairs: number,
    chairs: number,
    separator?: string,
  ) => string;
  customerLabel: (firstName: string | null, lastName: string | null) => string;
  spotAttributes: (payload: SpotAttributesPayload) => Array<SpotAttribute>;
  spotStyleAttribute: (attributes: Array<SpotAttribute>) => StyleSpotAttribute;
  seasonalDates: () => Array<DateTime>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  loadScript(
    id: string,
    src?: string,
    content?: string,
    onload?: () => void,
  ): void;
  printHtml(
    html: HTMLElement,
    stylesheets?: Array<string>,
    cloneHead?: boolean,
    format?: PrintFormat,
    orientation?: PrintOrientation,
  ): void;
  percentage(value: number, percentage: number, returnValue: boolean): number;
}

export interface SpiaggePluginPrintUtil {
  setup(): void;
  paymentMethodToPrintMethod(paymentMethod: CashFlowMethod): PrintPaymentMethod;
  buildPrint(printMode: PrintMode, cashFlowMethod: CashFlowMethod): Print;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  buildRepPrint(printMode: PrintMode, cashFlowMethod: CashFlowMethod): any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  print(
    id: number,
    print: Print,
    cb: (resData: any) => void,
    failCb?: (error: PrinterError) => void,
  ): void;
}

export interface SpiaggePluginErrorUtil {
  handle: (
    e: any,
    fallbackError?: string,
    silent?: boolean,
    log?: Array<ErrorLog>,
  ) => void;
}

export interface SpiaggePluginReservationUtil {
  getTotalOfReservation: (reservation: Reservation) => number;
  getReceiptDescription: (Reservation: Reservation) => string;
}

export interface SpiaggePluginDateUtil {
  fromSeconds: (timestamp: Timestamp, format?: string) => string;
}

export interface SpiaggePluginSpotUtil {
  getLabel: (spotType: SpotType) => string;
}

export interface SpiaggePluginServiceUtil {
  getServiceImage: (service: Service) => string;
  getServiceLabel: (service: Service) => string;
  getServiceType: (service: Service) => string;
}

export interface SpiaggePluginUtils {
  global: SpiaggePluginGlobalUtil;
  printMode: SpiaggePluginPrintUtil;
  reservation: SpiaggePluginReservationUtil;
  date: SpiaggePluginDateUtil;
  spot: SpiaggePluginSpotUtil;
  service: SpiaggePluginServiceUtil;
  error: SpiaggePluginErrorUtil;
}

export interface SpiaggePlugin {
  toast: SpiaggeToast;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  date: any;
  utils: SpiaggePluginUtils;
}

export default {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  install: (app: App) => {
    const spiaggeToast: SpiaggeToast = {
      success(title: string, content = '', group = 'app') {
        app.config.globalProperties.$toast.add(
          toastUtil.build(ToastSeverity.SUCCESS, title, content, group),
        );
      },
      info(title: string, content = '', group = 'app') {
        app.config.globalProperties.$toast.add(
          toastUtil.build(ToastSeverity.INFO, title, content, group),
        );
      },
      warn(title: string, content = '', group = 'app') {
        app.config.globalProperties.$toast.add(
          toastUtil.build(ToastSeverity.WARN, title, content, group),
        );
      },
      error(title: string, content = '', group = 'app') {
        app.config.globalProperties.$toast.add(
          toastUtil.build(ToastSeverity.ERROR, title, content, group),
        );
      },
      show(type: string, title: string, content = '', group = 'app') {
        let toastStype = type;
        if (!['success', 'info', 'warn', 'error'].includes(type)) {
          toastStype = 'info';
        }
        app.config.globalProperties.$toast.add(
          toastUtil.build(toastStype as ToastSeverity, title, content, group),
        );
      },
    };

    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$spiagge = {
      toast: spiaggeToast,
      date: dateUtil,
      utils: {
        global: globalUtil,
        printMode: printerUtil,
        reservation: reservationUtil,
        date: dateUtil,
        spot: spotUtil,
        service: serviceUtil,
        error: errorUtil,
      },
    };
  },
};

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $spiagge: SpiaggePlugin;
  }
}
