/**
 * MapEditot service
 */

import axios, { AxiosResponse } from 'axios';
import {
  ApiSetAllOnlineResponse,
  ApiMapEditorElementsResponse,
  ApiMapEditorElementPayload,
  ApiMapEditorUploadResponse,
  ApiMapEditorElementResponse,
  ApiMapEditorEditGridPayload,
  ApiMapEditorEditGridResponse,
} from '@/models/api';
import { MapEditorElementRaw } from '@/models/mapEditor';
import apiService from '@/services/apiService';

export default {
  async elements(): Promise<Array<MapEditorElementRaw>> {
    const res: AxiosResponse<ApiMapEditorElementsResponse> = await axios.get(
      apiService.mapEditor.elements(),
    );
    return res.data.result.spots;
  },
  async uploadImage(data: FormData): Promise<string> {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    const res: AxiosResponse<ApiMapEditorUploadResponse> = await axios.post(
      apiService.mapEditor.uploadImage(),
      data,
      config,
    );
    return res.data.result.url;
  },
  async setAllOnline(): Promise<ApiSetAllOnlineResponse> {
    const res: AxiosResponse<ApiSetAllOnlineResponse> = await axios.post(
      apiService.mapEditor.setAllOnline(),
    );
    return res.data;
  },
  async create(
    payload: ApiMapEditorElementPayload,
  ): Promise<MapEditorElementRaw> {
    const res: AxiosResponse<ApiMapEditorElementResponse> = await axios.post(
      apiService.mapEditor.create(),
      payload,
    );
    return res.data.result.spot;
  },
  async update(
    id: number,
    payload: ApiMapEditorElementPayload,
  ): Promise<MapEditorElementRaw> {
    const res: AxiosResponse<ApiMapEditorElementResponse> = await axios.patch(
      apiService.mapEditor.update(id),
      payload,
    );
    return res.data.result.spot;
  },
  async delete(id: number): Promise<void> {
    return axios.delete(apiService.mapEditor.delete(id));
  },
  async editGrid(payload: ApiMapEditorEditGridPayload): Promise<void> {
    const res: ApiMapEditorEditGridResponse = await axios.post(
      apiService.mapEditor.editGrid(),
      payload,
    );
    return res.result;
  },
};
