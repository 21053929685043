import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "map-decoration",
    style: _normalizeStyle(_ctx.mapDecorationStyle)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["decoration", _ctx.decorationClasses]),
      style: _normalizeStyle(_ctx.decorationStyle)
    }, _toDisplayString(_ctx.isLabel && _ctx.decoration.name ? _ctx.decoration.name : ''), 7)
  ], 4))
}