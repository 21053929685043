
import { defineComponent } from 'vue';
import { cloneDeep } from 'lodash';
import { ToastSeverity } from '@/models/toast';
import toastUtil from '@/utils/toastUtil';
import { Log, LogAction } from '@/models/log';
import { Admin } from '@/models/admin';
import { ApiLogFindPayload, ApiLogFindResponse } from '@/models/api';
import logService from '@/services/logService';

const ADMIN_MODEL_DEFAULT = {
  adminId: 0,
  email: '',
  facebook: 0,
  firstName: '',
  lastName: '',
  password: '',
  id: 0,
  role: 0,
} as Admin;

export default defineComponent({
  data() {
    return {
      loading: true,
      logsLimit: 15,
      count: 0,
      id: [] as any,
      admin: cloneDeep(ADMIN_MODEL_DEFAULT),
      logs: [] as Array<Log>,
      selectedLogs: null,
      logsTable: [
        this.$t('admins.status'),
        this.$t('admins.spotType'),
        this.$t('admins.logId'),
        this.$t('admins.logFullName'),
        this.$t('admins.logAction'),
        this.$t('admins.logCreatedAt'),
        this.$t('admins.logUpdatedAt'),
      ],
    };
  },
  methods: {
    onReg(element: Log) {
      if (element.action) {
        return require('@/assets/iconset/trash.svg');
      }
    },
    checkUpdatedAt(element: Log) {
      if (element.updatedAt) {
        return this.$spiagge.date.fromMillis(element.updatedAt);
      }
    },
    async page(event: any) {
      const id = 1;
      const currentPage = event.page;
      const offset = event.rows;
      try {
        const res: ApiLogFindResponse = await logService.find({
          userId: id,
          orderBy: [
            {
              field: 'createdAt',
              value: 'desc',
            },
          ],
          action: [
            LogAction.RESERVATION_DELETE,
            LogAction.RESERVATION_MOVE_TO_BIN,
          ],
          page: currentPage,
          offset: this.logsLimit,
        } as ApiLogFindPayload);

        this.logs = res.result.logs;
        this.count = res.result.count;
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
  },
  async created() {
    this.id = this.$route.params.id;
    try {
      const res: ApiLogFindResponse = await logService.find({
        userId: this.id,
        orderBy: [
          {
            field: 'createdAt',
            value: 'desc',
          },
        ],
        action: [
          LogAction.RESERVATION_DELETE,
          LogAction.RESERVATION_MOVE_TO_BIN,
        ],
        page: 0,
        offset: this.logsLimit,
      } as ApiLogFindPayload);

      this.logs = res.result.logs;
      this.count = res.result.count;
      this.loading = false;
    } catch (error) {
      this.$toast.add(
        toastUtil.build(
          ToastSeverity.ERROR,
          this.$t('toast.error.title'),
          this.$t('toast.error.msg'),
        ),
      );
    }
  },
});
