import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  id: "map-view",
  class: "view"
}
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MapDecoration = _resolveComponent("MapDecoration")!
  const _component_MapSpot = _resolveComponent("MapSpot")!
  const _component_MapRect = _resolveComponent("MapRect")!
  const _component_Map = _resolveComponent("Map")!
  const _component_MapViews = _resolveComponent("MapViews")!
  const _component_MapCalendar = _resolveComponent("MapCalendar")!
  const _component_MapZoom = _resolveComponent("MapZoom")!
  const _component_MapModes = _resolveComponent("MapModes")!
  const _component_MapPlanner = _resolveComponent("MapPlanner")!
  const _component_MapLegend = _resolveComponent("MapLegend")!
  const _component_MapSelection = _resolveComponent("MapSelection")!
  const _component_MapTooltips = _resolveComponent("MapTooltips")!
  const _component_MapShift = _resolveComponent("MapShift")!
  const _component_MapBedsArea = _resolveComponent("MapBedsArea")!
  const _component_MapPrint = _resolveComponent("MapPrint")!
  const _component_MapSmartSearch = _resolveComponent("MapSmartSearch")!
  const _component_MapTicketWarehouse = _resolveComponent("MapTicketWarehouse")!
  const _component_MapRefundConfirmation = _resolveComponent("MapRefundConfirmation")!
  const _component_CabinsDialog = _resolveComponent("CabinsDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.draw)
      ? (_openBlock(), _createBlock(_component_Map, {
          key: 0,
          background: _ctx.background,
          width: _ctx.width,
          height: _ctx.height,
          cellWidth: _ctx.cellWidth,
          cellHeight: _ctx.cellHeight,
          containerHeight: _ctx.containerHeight,
          containerWidth: _ctx.containerWidth,
          type: _ctx.type,
          panzoomEnabled: true,
          innerSpacer: true,
          onPanzoomInstance: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$store.commit('map/setInstance', $event))),
          onPanzoomReady: _ctx.onPanzoomReady,
          onPanzoomPan: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$store.commit('map/setPanning', $event)))
        }, {
          elements: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mapRects, (mapRect, index) => {
              return (_openBlock(), _createBlock(_component_MapRect, {
                key: index,
                "map-rect": mapRect
              }, {
                rect: _withCtx(({ isLoaded }) => [
                  _createVNode(_Transition, {
                    mode: "out-in",
                    name: "fade"
                  }, {
                    default: _withCtx(() => [
                      isLoaded
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mapRect.getMapElements(
                    _ctx.decorations,
                    _ctx.cellWidth,
                    _ctx.cellHeight,
                  ), (d, i) => {
                              return (_openBlock(), _createBlock(_component_MapDecoration, {
                                key: i + 'decorator',
                                decoration: d,
                                cellWidth: _ctx.cellWidth,
                                cellHeight: _ctx.cellHeight,
                                mapView: _ctx.view
                              }, null, 8, ["decoration", "cellWidth", "cellHeight", "mapView"]))
                            }), 128)),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(mapRect.getMapElements(
                    _ctx.spots,
                    _ctx.cellWidth,
                    _ctx.cellHeight,
                  ), (s, i) => {
                              return (_openBlock(), _createBlock(_component_MapSpot, {
                                key: i + 'spot',
                                spot: s,
                                cellWidth: _ctx.cellWidth,
                                cellHeight: _ctx.cellHeight,
                                onBedsArea: _ctx.onBedsArea,
                                onCabinsDialog: _ctx.onCabinsDialog,
                                onMouseenter: ($event: any) => (_ctx.updateMapPlannerDebounce(s)),
                                onMouseleave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateMapPlannerDebounce.cancel()))
                              }, null, 8, ["spot", "cellWidth", "cellHeight", "onBedsArea", "onCabinsDialog", "onMouseenter"]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["map-rect"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["background", "width", "height", "cellWidth", "cellHeight", "containerHeight", "containerWidth", "type", "onPanzoomReady"]))
      : _createCommentVNode("", true),
    _createVNode(_component_MapViews),
    _createVNode(_component_MapCalendar),
    _createVNode(_component_MapZoom),
    _createVNode(_component_MapModes),
    _createVNode(_component_MapPlanner),
    _createVNode(_component_MapLegend),
    _createVNode(_component_MapSelection),
    _createVNode(_component_MapTooltips),
    _createVNode(_component_MapShift),
    _createVNode(_component_MapBedsArea, {
      spotName: _ctx.bedsArea,
      onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.bedsArea = ''))
    }, null, 8, ["spotName"]),
    _createVNode(_component_MapPrint),
    _createVNode(_component_MapSmartSearch),
    _createVNode(_component_MapTicketWarehouse),
    (_ctx.refundConfirmation)
      ? (_openBlock(), _createBlock(_component_MapRefundConfirmation, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.showCabinsDialog)
      ? (_openBlock(), _createBlock(_component_CabinsDialog, {
          key: 2,
          startDate: _ctx.calendar[0],
          endDate: _ctx.calendar[1] ?? _ctx.calendar[0],
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showCabinsDialog = false)),
          onSelect: _ctx.onSelectCabin
        }, null, 8, ["startDate", "endDate", "onSelect"]))
      : _createCommentVNode("", true)
  ]))
}