import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Tag = _resolveComponent("Tag")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-number-threshold", _ctx.classes])
  }, [
    _createVNode(_component_InputNumber, _mergeProps({ autocomplete: "off" }, _ctx.$attrs, {
      modelValue: _ctx.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event))
    }), null, 16, ["modelValue"]),
    _createVNode(_component_Tag, {
      id: "tag",
      ref: "tag",
      icon: `pi pi-${
        _ctx.modelValue !== null && _ctx.modelValue > _ctx.threshold
          ? 'arrow-up'
          : 'arrow-down'
      }`,
      severity: 
        _ctx.modelValue !== null && _ctx.modelValue > _ctx.threshold ? 'success' : 'danger'
      ,
      value: _ctx.tagLabel,
      style: _normalizeStyle(_ctx.tagStyle)
    }, null, 8, ["icon", "severity", "value", "style"])
  ], 2))
}