
import debounce from 'lodash/debounce';
import { defineComponent, PropType } from 'vue';
import { mapState } from 'vuex';
import Panzoom, { PanzoomObject, PanzoomOptions } from '@panzoom/panzoom';
import { Point2D } from '@/models';
import cookieUtil from '@/utils/cookieUtil';
import {
  MapBackground,
  MapType,
  MAP_PAN_MOBILE_SENSIBILITY,
} from '@/models/map';

interface PanzoomEventDetail {
  x: number;
  y: number;
}

export default defineComponent({
  name: 'Map',
  props: {
    background: {
      type: Object as PropType<MapBackground>,
      required: false,
      default: null,
    },
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    cellWidth: {
      type: Number,
      required: true,
    },
    cellHeight: {
      type: Number,
      required: true,
    },
    type: {
      type: String as PropType<MapType>,
      required: false,
      default: MapType.STANDARD,
    },
    panzoomEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    containerWidth: {
      type: Number,
      required: true,
    },
    containerHeight: {
      type: Number,
      required: true,
    },
    innerSpacer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      /* PANZOOM */
      panzoomInstance: undefined as unknown as PanzoomObject,
      panzoomElement: undefined as unknown as HTMLElement,
      refreshPanzoomDebounce: debounce(this.initPanzoom as () => void, 500),
      panEndDebounce: debounce(
        this.onPanzoomEnd as () => void,
        1000,
      ),
      panStartAt: {} as Point2D,
      panEndAt: {} as Point2D,
      bedsArea: '',
    };
  },
  async beforeMount() {
    this.panzoomElement = document.getElementById('panzoom') as HTMLElement;
  },
  mounted() {
    if (this.panzoomEnabled) {
      this.initPanzoom();
      window.addEventListener('resize', this.refreshPanzoomDebounce);
      // to avoid map issue randering (small map on the bottom right corner)
      this.panzoomInstance.pan(1, 1);
      this.$emit('panzoomReady');
    }
  },
  methods: {
    initPanzoom(): void {
      if (!this.panzoomEnabled) return;
      if (!this.panzoomElement) {
        this.panzoomElement = document.getElementById('panzoom') as HTMLElement;
      }

      // prevent white stripes if window width in greather then cookie value
      const width = Number(cookieUtil.get(`map_${this.license.license}_w`));
      if (window.innerWidth > width) {
        cookieUtil.remove(`map_${this.license.license}_x`);
        cookieUtil.remove(`map_${this.license.license}_y`);
        cookieUtil.remove(`map_${this.license.license}_scale`);
      }

      // init preferences
      let startX = Number(cookieUtil.get(`map_${this.license.license}_x`));
      let startY = Number(cookieUtil.get(`map_${this.license.license}_y`));
      let startScale = Number(
        cookieUtil.get(`map_${this.license.license}_scale`),
      );

      if (!startScale) {
        // get license preference zoom
        startScale = this.license.mapZoom;
        cookieUtil.set(`map_${this.license.license}_scale`, String(startScale));
      }

      if (!startX) {
        // get license preference offset x
        startX = this.license.mapOffset * -1;
        cookieUtil.set(`map_${this.license.license}_scale`, String(startX));
      }

      if (!startY) {
        // get license preference offset y
        startY = this.license.mapOffsetVertical * 60 * -1;
        cookieUtil.set(`map_${this.license.license}_scale`, String(startY));
      }

      const options = {
        contain: 'outside',
        excludeClass: 'decorative',
        step: 0.08, // scrollwheel/pinch only, zoom with buttons has a custom step,
        minScale: 0.6,
        maxScale: 3,
      } as PanzoomOptions;

      if (startX) {
        options.startX = startX;
      }

      if (startY) {
        options.startY = startY;
      }

      if (startScale) {
        options.startScale = startScale;
      }

      options.handleStartEvent = (): void => {
        this.panStartAt = {
          x: Math.round(this.panzoomInstance.getPan().x),
          y: Math.round(this.panzoomInstance.getPan().y),
        };
      };

      this.panzoomInstance = Panzoom(this.panzoomElement, options);
      this.$emit('panzoomInstance', this.panzoomInstance);

      (this.panzoomElement.parentElement as HTMLElement).addEventListener(
        'wheel',
        this.panzoomInstance.zoomWithWheel,
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore:next-line
      this.panzoomElement.addEventListener('panzoomend', this.panEndDebounce);

      // this.panzoomElement.addEventListener(
      //   'panzoomchange',
      //   this.updatePreferenceDebounce,
      // );

      setTimeout(() => {
        if (!startScale) {
          // if no preference scale -> set all map visible
          this.panzoomInstance.zoom(-100);
        }
      }, 1000);
    },
    updatePreference(): void {
      if (this.panzoomEnabled && this.panzoomInstance) {
        const pan = this.panzoomInstance.getPan();
        const scale = this.panzoomInstance.getScale();
        cookieUtil.set(
          `map_${this.license.license}_x`,
          String(pan.x.toFixed(2)),
        );
        cookieUtil.set(
          `map_${this.license.license}_y`,
          String(pan.y.toFixed(2)),
        );
        cookieUtil.set(
          `map_${this.license.license}_scale`,
          String(scale.toFixed(2)),
        );
        cookieUtil.set(
          `map_${this.license.license}_w`,
          String(window.innerWidth.toFixed()),
        );
      }
    },
    onPanzoomEnd(event: { detail: PanzoomEventDetail }): void {
      this.panEndAt = {
        x: Math.round(event.detail.x),
        y: Math.round(event.detail.y),
      };
      this.updatePreference();
      if (this.isClickItemEnabled()) {
        this.$emit('panzoomPan', false);
      } else {
        this.$emit('panzoomPan', true);
      }
    },
    isClickItemEnabled() {
      if (
        this.panStartAt.x === this.panEndAt.x &&
        this.panStartAt.y === this.panEndAt.y
      ) {
        return true;
      }
      if (
        (this.appPlatform === 'ios' || this.appPlatform === 'android') &&
        Math.abs(this.panStartAt.x - this.panEndAt.x) <
          MAP_PAN_MOBILE_SENSIBILITY &&
        Math.abs(this.panStartAt.y - this.panEndAt.y) <
          MAP_PAN_MOBILE_SENSIBILITY
      ) {
        return true;
      }
      return false;
    },
    resetPanzoom(): void {
      if (this.panzoomEnabled) {
        // remove wheel listener
        (this.panzoomElement.parentElement as HTMLElement).removeEventListener(
          'wheel',
          this.panzoomInstance.zoomWithWheel,
        );
        // remove panzoomend listener
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore:next-line
        this.panzoomElement.removeEventListener('panzoomend', this.panEndDebounce);
        // remove panzoom change listener
        this.panEndDebounce.cancel();
        // this.panzoomElement.removeEventListener(
        //   'panzoomchange',
        //   this.panEndDebounce,
        // );
      }
    },
  },
  computed: {
    ...mapState('session', ['license', 'appPlatform']),
    ...mapState('app', ['windowWidth', 'windowHeight', 'breakpoints']),
    mapStyle(): { [key: string]: string } {
      return {
        height: `${
          this.panzoomEnabled ? this.containerHeight : this.containerHeight
        }px`,
        width: `${
          this.panzoomEnabled ? this.containerWidth : this.containerWidth
        }px`,
      };
    },
    /* SEA */
    seaHeight(): number {
      // return 4 * this.cellHeight;
      return 100;
    },
    seaStyle(): { [key: string]: string } {
      return {
        height: `${this.seaHeight}px`,
        backgroundImage: `url("${require('@/assets/images/map/sea.svg')}")`,
      };
    },
    /* BACKGROUND */
    backgroundImageStyle(): { [key: string]: string } {
      if (!this.background) return {};
      return {
        height: `${this.background.height * this.cellHeight}px`,
        width: `${this.background.width * this.cellWidth}px`,
        position: 'absolute',
        top: `${this.background.posY * this.cellHeight}px`,
        left: `${this.background.posX * this.cellWidth}px`,
      };
    },
    mapBackgroundWidth(): number {
      if (!this.background) return 0;
      let width = this.background.width;
      if (this.background.posX) {
        width += this.background.posX;
      }
      return width * this.cellWidth;
    },
    mapBackgroundHeight(): number {
      if (!this.background) return 0;
      let height = this.background.height;
      if (this.background.posY) {
        height += this.background.posY;
      }
      return height * this.cellHeight;
    },
    /* ELEMENTS WRAPPER */
    elementsWrapperStyle(): { [key: string]: string } {
      return {
        width: `${Math.max(this.mapWidth, this.mapBackgroundWidth)}px`,
        height: `${Math.max(this.mapHeight, this.mapBackgroundHeight)}px`,
        display: 'flex',
        flexWrap: 'wrap',
      };
    },
    /* MAP */
    mapWidth(): number {
      return this.width * this.cellWidth;
    },
    mapHeight(): number {
      return this.height * this.cellHeight;
    },
    /* PANZOOM */
    panzoomWidth(): number {
      const panzoomWidth = Math.max(
        // right spacer only on standard mode
        this.mapWidth + (this.innerSpacer ? 90 : 0),
        this.mapBackgroundWidth,
      );
      return Math.max(panzoomWidth, this.containerWidth);
    },
    panzoomHeight(): number {
      const panzoomHeight =
        Math.max(
          // bottom spacer only on standard mode
          this.mapHeight + (this.innerSpacer ? 45 : 0),
          this.mapBackgroundHeight,
        ) + this.seaHeight;
      return Math.max(panzoomHeight, this.containerHeight);
    },
    panzoomStyle(): { [key: string]: string } {
      return {
        width: `${this.panzoomWidth}px`,
        height: `${this.panzoomHeight}px`,
        // overflow: this.panzoomEnabled ? '' : 'auto',
      };
    },
    mapClasses(): Array<string> {
      const classes: Array<string> = [this.type];
      if (this.panzoomEnabled) {
        classes.push('panzoom');
      }
      if (this.innerSpacer) {
        classes.push('inner-spacer');
      }
      if (this.background) {
        classes.push('background');
      }
      return classes;
    },
  },
  watch: {
    cellWidth(): void {
      if (this.panzoomEnabled) {
        this.resetPanzoom();
        this.initPanzoom();
      }
    },
  },
  beforeUnmount() {
    // remove window size listener
    this.refreshPanzoomDebounce.cancel();
    window.removeEventListener('resize', this.refreshPanzoomDebounce);
    this.resetPanzoom();
  },
});
