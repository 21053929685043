
/* eslint-disable no-case-declarations */
import { defineComponent } from 'vue';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import {
  PlannerBuilder,
  PlannerMode,
  PlannerElementReservation,
} from '@/models/planner';
import {
  ApiPlannerReservationWithStepsPayload,
  ApiReservationCreatePayload,
  ApiReservationMovePayload,
  ApiReservationUpdatePayload,
} from '@/models/api';
import reservationService from '@/services/reservationService';
import { License } from '@/models/license';
import { DEFAULT_PLANNER_STATE } from '@/models/store';
import plannerUtil from '@/utils/plannerUtil';
import { AppAction } from '@/models/app';
import { ColorName, colorSpecs } from '@/models/color';
import { SpotType } from '@/models/spot';
import { Reservation } from '@/models/reservation';

interface Tooltip {
  icon: string;
  title: string;
  content: string;
}

export default defineComponent({
  name: 'PlannerTooltips',
  emits: ['exit', 'cancel-exit'],
  props: {
    showExitDialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    license(): License {
      return this.$store.getters['session/license'];
    },
    mode(): PlannerMode {
      return this.$store.getters['planner/mode'];
    },
    builder(): PlannerBuilder {
      return this.$store.getters['planner/builder'];
    },
    active(): boolean {
      return this.mode !== PlannerMode.VIEW;
    },
    tooltip(): Tooltip {
      const tooltip = {} as Tooltip;
      // eslint-disable-next-line default-case
      switch (this.mode) {
        case PlannerMode.NEW:
          // eslint-disable-next-line no-case-declarations
          const lastSegment =
            this.builder.reservations[this.builder.reservations.length - 1];
          tooltip.icon = 'spi-plus';
          if (!lastSegment) {
            tooltip.title = this.$t('planner.tooltips.new.startTitle');
            tooltip.content = this.$t('planner.tooltips.new.startContent');
          } else if (!lastSegment.endDate) {
            tooltip.title = this.$t('planner.tooltips.new.endTitle');
            tooltip.content = this.$t('planner.tooltips.new.endContent');
          } else {
            tooltip.title = this.$t('planner.tooltips.verifyTitle');
            tooltip.content = this.$t('planner.tooltips.verifyContent');
          }
          break;
        case PlannerMode.MOVE:
          tooltip.icon = 'spi-move';
          if (this.builder.reservations.length === 0) {
            tooltip.title = this.$t('planner.tooltips.move.startTitle');
            tooltip.content = this.$t('planner.tooltips.move.startContent');
          } else if (!this.builder.edited) {
            tooltip.title = this.$t('planner.tooltips.move.endTitle');
            tooltip.content = this.$t('planner.tooltips.move.endContent');
          } else {
            tooltip.title = this.$t('planner.tooltips.verifyTitle');
            tooltip.content = this.$t('planner.tooltips.verifyContent');
          }
          break;
        case PlannerMode.EDIT:
          tooltip.icon = 'spi-stretch';
          if (!this.builder.editDirection) {
            tooltip.title = this.$t('planner.tooltips.edit.startTitle');
            tooltip.content = this.$t('planner.tooltips.edit.startContent');
          } else if (!this.builder.edited) {
            tooltip.title = this.$t('planner.tooltips.edit.endTitle');
            tooltip.content = this.$t('planner.tooltips.edit.endContent');
          } else {
            tooltip.title = this.$t('planner.tooltips.verifyTitle');
            tooltip.content = this.$t('planner.tooltips.verifyContent');
          }
          break;
      }
      return tooltip;
    },
    canConfirm(): boolean {
      let result = false;
      switch (this.mode) {
        case PlannerMode.NEW:
          const lastRes =
            this.builder.reservations[this.builder.reservations.length - 1];
          result = lastRes && !!lastRes.endDate;
          break;
        case PlannerMode.MOVE:
        case PlannerMode.EDIT:
          result = !!this.builder.reservationId && this.builder.edited;
          break;
        default:
          result = false;
      }
      return result;
    },
    confirmReservation(): PlannerElementReservation | null {
      if (!this.canConfirm) return null;
      let reservation;
      if (!this.builder.reservationId) {
        reservation = this.builder.reservations[0];
      } else {
        const rIndex = this.builder.reservations.findIndex(
          (r) => r.id === this.builder.reservationId,
        );
        if (rIndex === -1) return null;
        reservation = this.builder.reservations[rIndex];
      }
      return reservation;
    },
    spot(): any | null {
      if (!this.confirmReservation) return null;
      const colorName = this.license.mapColorReserved as unknown as ColorName;
      const color = colorSpecs.get(colorName);
      return {
        name: this.confirmReservation.spotName,
        // eslint-disable-next-line import/no-dynamic-require
        icon: require(`@/assets/images/map/${this.confirmReservation.spotType}/${color?.icon}.svg`),
      };
    },
    dates(): any | null {
      if (!this.confirmReservation) return null;
      if (this.mode === PlannerMode.NEW) {
        const lastSegment =
          this.builder.reservations[this.builder.reservations.length - 1];
        return {
          startDate: DateTime.fromSeconds(this.confirmReservation.startDate),
          endDate: DateTime.fromSeconds(lastSegment.endDate),
        };
      }
      return {
        startDate: DateTime.fromSeconds(this.confirmReservation.startDate),
        endDate: DateTime.fromSeconds(this.confirmReservation.endDate),
      };
    },
  },
  methods: {
    onKeyPressed(event: KeyboardEvent): void {
      const key = event.key;
      if (key === 'Escape') {
        this.stepBack();
      }
    },
    stepBack(): void {
      const builder = cloneDeep(this.builder);
      switch (this.mode) {
        case PlannerMode.NEW:
          if (builder.reservations.length > 0) {
            builder.reservations = builder.reservations.slice(
              0,
              builder.reservations.length - 1,
            );
          } else {
            this.$store.commit('planner/setMode', PlannerMode.VIEW);
          }
          break;
        case PlannerMode.MOVE:
        case PlannerMode.EDIT:
          if (builder.reservations.length === 0) {
            this.$store.commit('planner/setMode', PlannerMode.VIEW);
            break;
          }
          if (builder.edited) {
            builder.edited = false;
            builder.reservations.forEach((r) =>
              plannerUtil.builderFreeCells(builder, r),
            );
            builder.reservations = cloneDeep(
              Array.from(builder.originals.values()),
            );
            builder.reservations.forEach((r) =>
              plannerUtil.builderBookCells(builder, r),
            );
            plannerUtil.builderUpdateSpotKeys(builder);
          } else {
            plannerUtil.builderReset(builder);
          }
          break;
        default:
      }
      this.$store.commit('planner/setBuilder', builder);
    },
    cancel(): void {
      const builder = cloneDeep(this.builder);
      if (builder.edited) {
        builder.edited = false;
        builder.reservations.forEach((r) =>
          plannerUtil.builderFreeCells(builder, r),
        );
        builder.reservations = cloneDeep(
          Array.from(builder.originals.values()),
        );
        builder.reservations.forEach((r) =>
          plannerUtil.builderBookCells(builder, r),
        );
        plannerUtil.builderUpdateSpotKeys(builder);
      }
      this.$store.commit('planner/setBuilder', builder);
      this.$store.commit('planner/setMode', PlannerMode.VIEW);
    },
    onExitConferm(): void {
      this.confirm();
      this.$emit('exit');
    },
    confirm(): void {
      // eslint-disable-next-line default-case
      switch (this.mode) {
        case PlannerMode.NEW:
          this.confirmNew();
          break;
        case PlannerMode.MOVE:
          this.confirmMove();
          break;
        case PlannerMode.EDIT:
          this.confirmEdit();
          break;
      }
    },
    async confirmNew(): Promise<void> {
      // Calc entire reservation duration
      const builder = cloneDeep(this.builder);
      const mainReservationPayload = {
        spotType: builder.reservations[0].spotType as unknown as SpotType,
        spotName: builder.reservations[0].spotName,
        startDate: builder.reservations[0].startDate,
        endDate: builder.reservations[builder.reservations.length - 1].endDate,
      } as ApiReservationCreatePayload;
      // Create reservation on first element on all period
      let mainReservation: Reservation;
      if (builder.reservations.length > 1) {
        const reservationSteps = [];
        for (let i = 1; i < builder.reservations.length; i += 1) {
          const segment = builder.reservations[i];
          reservationSteps.push({
            startDate: segment.startDate,
            endDate: segment.endDate,
            spotType: segment.spotType,
            spotName: segment.spotName,
          });
        }
        const mainReservationPayload = {
          spotType: builder.reservations[0].spotType as unknown as SpotType,
          spotName: builder.reservations[0].spotName,
          startDate: builder.reservations[0].startDate,
          endDate:
            builder.reservations[builder.reservations.length - 1].endDate,
          steps: reservationSteps,
        } as ApiPlannerReservationWithStepsPayload;
        mainReservation = await reservationService.createReservationWithSteps(
          mainReservationPayload,
        );
      } else {
        mainReservation = await reservationService.createOne(
          mainReservationPayload,
        );
      }
      // Open main reservation
      this.$store.commit('app/setAction', AppAction.CREATE_RESERVATION);
      this.$router.push(`/reservation/${mainReservation.id}`);
    },
    async confirmMove(): Promise<void> {
      // Calc entire reservation duration
      const builder = cloneDeep(this.builder);
      const rIndex = builder.reservations.findIndex(
        (r) => r.id === builder.reservationId,
      );
      if (rIndex === -1) return;
      const reservation = builder.reservations[rIndex];
      const movePayload = {
        spotType: reservation.spotType,
        spotName: reservation.spotName,
        startDate: reservation.startDate,
      } as ApiReservationMovePayload;
      await reservationService.move(reservation.id, movePayload);
      // Reload planner data
      await this.$store.dispatch('planner/loadData');
      this.$store.commit('planner/setBuilder', DEFAULT_PLANNER_STATE.builder);
      this.$store.commit('planner/setMode', PlannerMode.VIEW);
    },
    async confirmEdit(): Promise<void> {
      // Calc entire reservation duration
      const builder = cloneDeep(this.builder);
      const rIndex = builder.reservations.findIndex(
        (r) => r.id === builder.reservationId,
      );
      if (rIndex === -1) return;
      const reservation = builder.reservations[rIndex];
      const editPayload = {
        startDate: reservation.startDate,
        endDate: reservation.endDate,
      } as ApiReservationUpdatePayload;
      // if (builder.editDirection === EditDirection.LEFT) {
      //   editPayload.startDate = reservation.startDate;
      // } else {
      //   editPayload.endDate = reservation.endDate;
      // }
      await reservationService.update(reservation.id, editPayload);
      // Reload planner data
      await this.$store.dispatch('planner/loadData');
      this.$store.commit('planner/setBuilder', DEFAULT_PLANNER_STATE.builder);
      this.$store.commit('planner/setMode', PlannerMode.VIEW);
    },
  },
  beforeMount() {
    window.addEventListener('keyup', this.onKeyPressed);
  },
  unmounted() {
    window.removeEventListener('keyup', this.onKeyPressed);
  },
});
