/* eslint-disable import/no-cycle */
import axios, { AxiosResponse } from 'axios';
import apiService from './apiService';
import { ApiSmartSearchPayload, ApiSmartSearchResponse } from '@/models/api';
import { SmartSearchSpot } from '@/models/smartSearch';

export default {
  async smartSearch(
    payload: ApiSmartSearchPayload,
  ): Promise<Array<SmartSearchSpot>> {
    const res: AxiosResponse<ApiSmartSearchResponse> = await axios.post(
      apiService.smartSearch.smartSearch(),
      payload,
    );
    return res.data.result.steps;
  },
};
