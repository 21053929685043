
import { PropType, defineComponent } from 'vue';
import { Css } from '@/models';

export default defineComponent({
  name: 'InputNumberThreshold',
  emits: ['update:modelValue'],
  inheritAttrs: false,
  props: {
    modelValue: {
      type: [Number, null] as PropType<number | null>,
      required: false,
      default: null,
    },
    threshold: {
      type: Number,
      required: false,
      default: 0,
    },
    showLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    showOutside: {
      type: Boolean,
      required: false,
      default: false,
    },
    aboveThresholdLabel: {
      type: String,
      required: false,
      default: '',
    },
    belowThresholdLabel: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      tagStyle: {} as Css,
      mounted: false,
    };
  },
  mounted(): void {
    this.mounted = true;
    this.getTagStyle();
  },
  methods: {
    getTagStyle(): void {
      if (!this.mounted || !this.showTag) {
        this.tagStyle = {
          display: 'none',
        };
        return;
      }
      // outside
      if (this.showOutside) {
        this.tagStyle = {
          display: 'inline-flex',
        };
        return;
      }

      // inside
      // only for getting the width
      this.tagStyle = {
        display: 'block',
      };
      this.$nextTick(() => {
        const width = this.showLabel
          ? ((this.$refs.tag as any).$el as HTMLElement).clientWidth
          : 24;
        this.tagStyle = {
          display: 'inline-flex',
          marginLeft: `-${width + 4}px`,
        };
      });
    },
  },
  computed: {
    model: {
      get(): number | null {
        return this.modelValue === this.threshold ? 0 : this.modelValue;
      },
      set(value: number): void {
        this.$emit('update:modelValue', value);
      },
    },
    showTag(): boolean {
      return this.modelValue !== null && this.modelValue !== this.threshold;
    },
    tagLabel(): string {
      return this.modelValue !== null && this.modelValue > this.threshold
        ? this.aboveThresholdLabel
        : this.belowThresholdLabel;
    },
    classes(): Array<string> {
      const classes: Array<string> = [];
      classes.push(`show-label__${this.showLabel}`);
      classes.push(`show-outside__${this.showOutside}`);
      return classes;
    },
  },
  watch: {
    showTag(): void {
      this.getTagStyle();
    },
  },
});
