
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import Menu from 'primevue/menu';
import { RouteLocationRaw } from 'vue-router';
import { MenuItem } from '@/models/menu';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

export default defineComponent({
  name: 'StatisticsNavigation',
  methods: {
    isRouteMatching(path: RouteLocationRaw): boolean {
      return this.$route.fullPath === path;
    },
    toggle(event: Event) {
      (this.$refs as { menu: Menu }).menu.toggle(event);
    },
    hasPermission(action: string): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.stats,
        action,
      );
    },
  },
  computed: {
    ...mapState('app', ['windowWidth', 'breakpoints']),
    menuItems(): Array<MenuItem> {
      return [
        // Main section
        {
          label: this.$t('statisticsNavigation.dashboard'),
          icon: 'spi-home',
          items: [
            {
              label: this.$t('statisticsNavigation.dashboard'),
              to: '/statistics/dashboard',
            },
          ],
        },
        {
          label: this.$t('statisticsNavigation.arrivalsDepartures'),
          icon: 'spi-home',
          visible: this.hasPermission(
            FEATURE_PERMISSION_ACTION_CONFIG.stats.SHOW_ARRIVALS_DEPARTURES,
          ),
          items: [
            {
              label: this.$t('statisticsNavigation.arrivalsToday'),
              to: '/statistics/arrivals-today',
            },
            {
              label: this.$t('statisticsNavigation.departuresToday'),
              to: '/statistics/departures-today',
            },
            {
              label: this.$t('statisticsNavigation.arrivalsTomorrow'),
              to: '/statistics/arrivals-tomorrow',
            },
            {
              label: this.$t('statisticsNavigation.departuresTomorrow'),
              to: '/statistics/departures-tomorrow',
            },
            /* {
              label: 'Modifiche ombrelloni',
              to: '/statistics/arrival-todat',
            }, */
            {
              label: this.$t('statisticsNavigation.allReservations'),
              to: '/statistics/all-reservations',
            },
            {
              label: this.$t('statisticsNavigation.allTickets'),
              to: '/statistics/tickets',
            },
          ] as Array<MenuItem>,
        },
        {
          label: this.$t('statisticsNavigation.beachResortSummary'),
          icon: 'spi-home',
          visible:
            this.hasPermission(
              FEATURE_PERMISSION_ACTION_CONFIG.stats.RECAPS_BEACH_RESORT,
            ) ||
            this.hasPermission(
              FEATURE_PERMISSION_ACTION_CONFIG.stats.STATS_ONLINE_BOOKINGS,
            ),
          items: [
            {
              label: this.$t('statisticsNavigation.umbrella'),
              to: '/statistics/spot/umbrella/map',
              visible: this.hasPermission(
                FEATURE_PERMISSION_ACTION_CONFIG.stats.RECAPS_BEACH_RESORT,
              ),
            },
            {
              label: this.$t('statisticsNavigation.cabins'),
              to: '/statistics/spot/cabins/map',
              visible: this.hasPermission(
                FEATURE_PERMISSION_ACTION_CONFIG.stats.RECAPS_BEACH_RESORT,
              ),
            },
            {
              label: this.$t('statisticsNavigation.seasonalSummary'),
              to: '/statistics/seasonal-summary',
              visible: this.hasPermission(
                FEATURE_PERMISSION_ACTION_CONFIG.stats.RECAPS_BEACH_RESORT,
              ),
            },
            {
              label: this.$t('statisticsNavigation.onlineReservations'),
              to: '/statistics/online-reservations',
              visible: this.hasPermission(
                FEATURE_PERMISSION_ACTION_CONFIG.stats.STATS_ONLINE_BOOKINGS,
              ),
            },
            {
              label: this.$t('statisticsNavigation.unpaidReservationsSummary'),
              to: '/statistics/unpaid-reservations-summary',
              visible: this.hasPermission(
                FEATURE_PERMISSION_ACTION_CONFIG.stats.RECAPS_BEACH_RESORT,
              ),
            },
            {
              label: this.$t('statisticsNavigation.additionsSummary'),
              to: '/statistics/additions-summary',
              visible: this.hasPermission(
                FEATURE_PERMISSION_ACTION_CONFIG.stats.RECAPS_BEACH_RESORT,
              ),
            },
          ],
        },
        {
          label: this.$t('statisticsNavigation.beachResort'),
          icon: 'spi-home',
          visible:
            this.hasPermission(
              FEATURE_PERMISSION_ACTION_CONFIG.stats.STATS_BEACH_RESORT,
            ) ||
            this.hasPermission(
              FEATURE_PERMISSION_ACTION_CONFIG.stats.STATS_STRIPE,
            ),
          items: [
            /* {
              label: 'Statistiche per ombrellone',
              to: '/statistics/umbrellas',
            },
            {
              label: 'Statistiche per settore',
              to: '/statistics/sector',
            }, */
            {
              label: this.$t('statisticsNavigation.duration'),
              to: '/statistics/duration',
              visible: this.hasPermission(
                FEATURE_PERMISSION_ACTION_CONFIG.stats.STATS_BEACH_RESORT,
              ),
            },
            {
              label: this.$t('statisticsNavigation.extraServices'),
              to: '/statistics/extra-services',
              visible: this.hasPermission(
                FEATURE_PERMISSION_ACTION_CONFIG.stats.STATS_BEACH_RESORT,
              ),
            },
            {
              label: this.$t('statisticsNavigation.sectorSpots'),
              to: '/statistics/sector-spots',
              visible: this.hasPermission(
                FEATURE_PERMISSION_ACTION_CONFIG.stats.STATS_BEACH_RESORT,
              ),
            },
            {
              label: this.$t('statisticsNavigation.stripePayments'),
              to: '/statistics/stripe-payments',
              visible: this.hasPermission(
                FEATURE_PERMISSION_ACTION_CONFIG.stats.STATS_STRIPE,
              ),
            },
            {
              label: this.$t('statisticsNavigation.stripeTransfers'),
              to: '/statistics/stripe-transfers',
              visible: this.hasPermission(
                FEATURE_PERMISSION_ACTION_CONFIG.stats.STATS_STRIPE,
              ),
            },
          ],
        },
        {
          label: this.$t('statisticsNavigation.logs'),
          icon: 'spi-home',
          visible:
            this.hasPermission(
              FEATURE_PERMISSION_ACTION_CONFIG.stats.SHOW_LOG,
            ) ||
            this.hasPermission(FEATURE_PERMISSION_ACTION_CONFIG.stats.SHOW_LOG),
          items: [
            {
              label: this.$t('statisticsNavigation.logDiscounts'),
              to: '/statistics/log-discounts',
              visible: this.hasPermission(
                FEATURE_PERMISSION_ACTION_CONFIG.stats.SHOW_LOG,
              ),
            },
            {
              label: this.$t('statisticsNavigation.logCancellations'),
              to: '/statistics/log-cancellations',
              visible: this.hasPermission(
                FEATURE_PERMISSION_ACTION_CONFIG.stats.SHOW_LOG,
              ),
            },
            {
              label: this.$t('statisticsNavigation.cashflows'),
              to: '/statistics/cash-flows',
              visible: this.hasPermission(
                FEATURE_PERMISSION_ACTION_CONFIG.stats.SHOW_CASH_FLOWS_STATS,
              ),
            },
          ],
        },
        {
          label: this.$t('statisticsNavigation.counters'),
          icon: 'spi-home',
          visible: this.hasPermission(
            FEATURE_PERMISSION_ACTION_CONFIG.stats.COUNTERS_VOUCHER_HOTEL,
          ),
          items: [
            {
              label: this.$t('statisticsNavigation.countersHotel'),
              to: '/statistics/counters-hotel',
            },
            {
              label: this.$t('statisticsNavigation.countersVoucher'),
              to: '/statistics/counters-voucher',
            },
          ],
        },
      ];
    },
  },
});
