import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3886a838"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "logs-bin-container" }
const _hoisted_2 = { class: "mb-2 ml-2 mr-2" }
const _hoisted_3 = { class: "w-full flex justify-content-center" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.$t('database.logsBinTitle')), 1),
    _createVNode(_component_DataTable, {
      value: _ctx.logs,
      paginator: true,
      rows: _ctx.logsLimit,
      "onUpdate:rows": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.logsLimit) = $event)),
      loading: _ctx.loading,
      onPage: _ctx.page,
      selectionMode: "single",
      selection: _ctx.selectedLogs,
      "onUpdate:selection": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedLogs) = $event)),
      rowsPerPageOptions: [15, 20, 25],
      responsiveLayout: "scroll",
      currentPageReportTemplate: "Showing {first} to {last} of {totalRecords}",
      lazy: true,
      totalRecords: _ctx.count,
      "onUpdate:totalRecords": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.count) = $event)),
      scrollable: "",
      scrollHeight: "800px",
      class: "mr-2 ml-2"
    }, {
      empty: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Message, {
            severity: "info",
            closable: false
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('database.noRecords')), 1)
            ]),
            _: 1
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          header: _ctx.logsTable[0]
        }, {
          body: _withCtx((slotProps) => [
            _createElementVNode("img", {
              style: {"width":"15px","height":"15px"},
              class: "mr-2 ml-2",
              src: _ctx.onReg(slotProps.data)
            }, null, 8, _hoisted_4)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          header: _ctx.logsTable[1]
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(slotProps.data.value), 1)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "userId",
          header: _ctx.logsTable[2]
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          header: _ctx.logsTable[3]
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(slotProps.data.firstName) + " " + _toDisplayString(slotProps.data.lastName), 1)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          field: "action",
          header: _ctx.logsTable[4]
        }, null, 8, ["header"]),
        _createVNode(_component_Column, {
          header: _ctx.logsTable[5]
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(_ctx.$spiagge.date.fromMillis(slotProps.data.createdAt)), 1)
          ]),
          _: 1
        }, 8, ["header"]),
        _createVNode(_component_Column, {
          header: _ctx.logsTable[6]
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(_ctx.checkUpdatedAt(slotProps.data)), 1)
          ]),
          _: 1
        }, 8, ["header"])
      ]),
      _: 1
    }, 8, ["value", "rows", "loading", "onPage", "selection", "totalRecords"])
  ]))
}