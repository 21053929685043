/**
 * Toast util
 */

import { Toast, TOAST_DURATION, ToastSeverity } from '@/models/toast';

export default {
  /**
   * Build VuePrime toast object
   *
   * @param severity
   * @param title
   * @param content
   * @param closable
   * @param sticky
   * @return Toast
   */
  build(
    severity: ToastSeverity,
    title = '',
    content = '',
    group = 'app',
    closable = true,
    sticky = false,
  ): Toast {
    return {
      severity,
      summary: title,
      detail: content,
      life: sticky ? null : TOAST_DURATION,
      closable,
      group,
    } as Toast;
  },
};
