
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

interface Faq {
  label: string;
  url: string;
}

export default defineComponent({
  name: 'HelpView',
  data() {
    return {
      faq: [
        {
          label: this.$t('helpView.faq.priceList'),
          url: 'https://www.spiagge.it/supporto-gestionale/docs-category/listini',
        },
        {
          label: this.$t('helpView.faq.map'),
          url: 'https://www.spiagge.it/supporto-gestionale/docs-category/mappa',
        },
        {
          label: this.$t('helpView.faq.planner'),
          url: 'https://www.spiagge.it/supporto-gestionale/docs-category/planner',
        },
        {
          label: this.$t('helpView.faq.reservation'),
          url: 'https://www.spiagge.it/supporto-gestionale/docs-category/scheda-prenotazione',
        },
        {
          label: this.$t('helpView.faq.customerRegistry'),
          url: 'https://www.spiagge.it/supporto-gestionale/docs-category/scheda-prenotazione',
        },
        {
          label: this.$t('helpView.faq.settings'),
          url: 'https://www.spiagge.it/supporto-gestionale/docs-category/impostazioni',
        },
        {
          label: this.$t('helpView.faq.stats'),
          url: 'https://www.spiagge.it/supporto-gestionale/docs-category/statistiche',
        },
        {
          label: this.$t('helpView.faq.booking'),
          url: 'https://www.spiagge.it/supporto-gestionale/docs-category/booking',
        },
        {
          label: this.$t('helpView.faq.printers'),
          url: 'https://www.spiagge.it/supporto-gestionale/docs-category/stampanti-e-hardware',
        },
        {
          label: this.$t('helpView.faq.taxation'),
          url: 'https://www.spiagge.it/supporto-gestionale/docs-category/fiscalizzazione',
        },
        {
          label: this.$t('helpView.faq.videoGuides'),
          url: 'https://www.spiagge.it/supporto-gestionale/docs-category/video-guide',
        },
      ] as Array<Faq>,
    };
  },
  mounted() {
    if (
      this.license &&
      this.license.featureFlags &&
      this.license.featureFlags.chatBotEnabled &&
      !window.matchMedia('(min-width: 768px)').matches
    ) {
      const scriptList = document.querySelectorAll('script');
      const convertedNodeList = Array.from(scriptList);
      const scriptLoaded = convertedNodeList.find(
        (script) => script.id === 'iaw-guide-script',
      );

      if (!scriptLoaded) {
        const chatBotScript = document.createElement('script');
        chatBotScript.setAttribute(
          'src',
          'https://app.indigo.ai/widget.js?token=1ea7c933-91b6-4d07-acde-8bec193ff124&v=2',
        );
        chatBotScript.setAttribute('id', 'iaw-guide-script');
        document.head.appendChild(chatBotScript);
      }
      const element = document.getElementById('iaw-trigger');
      if (element) {
        element.style.display = 'flex';
      }
    }
  },
  unmounted() {
    const element = document.getElementById('iaw-trigger');
    if (element) {
      element.style.display = 'none';
    }
  },
  methods: {
    async setChatBotStatus(status: boolean): Promise<void> {
      await this.$store.dispatch('session/setChatBotStatus', status);
    },
  },
  computed: {
    ...mapState('app', ['breakpoints']),
    ...mapState('session', ['license']),
  },
});
