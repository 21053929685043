
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'Breadcrumbs',
  data() {
    return {
      sidebar: false,
    };
  },
  computed: {
    ...mapState('app', ['breadcrumbs']),
  },
});
