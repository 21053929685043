/* eslint-disable camelcase */

export interface SectorService {
  b_d: number;
  b_max: number;
  b_min: number;
  c_d: number;
  c_max: number;
  c_min: number;
  d_d: number;
  d_max: number;
  d_min: number;
  m_d: number;
  m_max: number;
  m_min: number;
}

export interface BookingSectorService {
  b_d: number;
  b_max: number;
  b_min: number;
  c_d: number;
  c_max: number;
  c_min: number;
  d_d: number;
  d_max: number;
  d_min: number;
  i_min: number;
  i_max: number;
  m_d: number;
  m_max: number;
  m_min: number;
}

export enum SectorsShiftMode {
  AVERAGE,
  RESTART,
  STANDARD,
  STANDARD_REVERSE,
}

export interface SectorHeader {
  id: number;
  oldId?: number;
  name: string;
  description: string;
  enabled: boolean;
  image: string;
}

export interface SectorDetail {
  id: number;
  keyName: string;
  keyType: string;
  keyValue: number | null;
  type: string;
}

export interface SectorDetailEmittedModel {
  id: number;
  sectorId: number;
  value: number | null;
}

export interface Sector {
  header: SectorHeader;
  details: Array<SectorDetail>;
  canBeDeleted: boolean;
}

export enum SectorDetailType {
  ONLINE = 'online',
  OFFLINE = 'offline',
}

export enum SectorDetailKeyName {
  BED = 'b',
  CHAIR = 'c',
  DECK_CHAIR = 'd',
  MAXI_BED = 'm',
  ITEM = 'i',
}

export enum SectorDetailKeyType {
  MIN = 'min',
  MAX = 'max',
  DEFAULT = 'd',
}
