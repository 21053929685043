
import { defineComponent } from 'vue';
import { PriceList } from '@/models/priceList';

export default defineComponent({
  name: 'PriceListSwitchDialog',
  emits: ['close', 'switch'],
  props: {
    priceLists: {
      type: Array as () => Array<PriceList>,
      default: [] as Array<PriceList>,
    },
    priceListSelected: {
      type: Object as () => PriceList,
      required: true,
    },
  },
  data() {
    return {
      selected: 0,
    };
  },
  computed: {
    priceListsOptions(): Array<PriceList> {
      const options: Array<PriceList> = [
        // TODO horrible, fix asap
        {
          name: 'Nessun listino',
          id: 0,
          numSectors: 0,
          listDaysLength: 0,
          extraDaysMode: 0,
          periodsMode: 0,
          periodsShiftMode: 0,
          periods: [],
          createdAt: 0,
          updatedAt: null,
        } as any,
      ];
      this.priceLists.map((p: PriceList) => {
        options.push({
          name: p.name,
          id: p.id,
          numSectors: p.numSectors,
          listDaysLength: p.listDaysLength,
          extraDaysMode: p.extraDaysMode,
          periodsMode: p.periodsMode,
          periodsShiftMode: p.periodsShiftMode,
          periods: p.periods,
        } as PriceList);
      });
      return options;
    },
  },
  methods: {
    onPriceListClick(priceList: PriceList): void {
      this.selected = priceList.id;
    },
    async onConfirmClick(): Promise<void> {
      this.$emit('switch', this.selected);
    },
  },
});
