import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { id: "top-bar-search" }
const _hoisted_2 = { class: "item" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.breakpoints.mobile)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          class: "p-button-white p-button-text p-button-lg",
          icon: "spi-search",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = true))
        }))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", {
      class: _normalizeClass(["search-input p-input-icon-left", _ctx.breakpoints.mobile ? 'p-input-icon-right' : ''])
    }, [
      _createElementVNode("i", {
        class: _normalizeClass(["pi pi-search", 'pi-' + (_ctx.searching ? 'spin pi-spinner' : 'search')])
      }, null, 2),
      _createVNode(_component_InputText, {
        ref: "searchInput",
        type: "text",
        modelValue: _ctx.searchQuery,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.searchQuery) = $event)),
        onInput: _ctx.runSearch,
        placeholder: _ctx.$t('topBarSearch.placeholder')
      }, null, 8, ["modelValue", "onInput", "placeholder"]),
      (_ctx.breakpoints.mobile)
        ? (_openBlock(), _createElementBlock("i", {
            key: 0,
            class: "pi pi-times cursor-pointer",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.show = false))
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_OverlayPanel, {
        ref: "op",
        id: "top-bar-search-overlay-panel",
        style: _normalizeStyle(`width: ${_ctx.panelWidth}px`),
        showCloseIcon: "",
        onHide: _ctx.onOverlayHide
      }, {
        default: _withCtx(() => [
          (_ctx.searched)
            ? (_openBlock(), _createBlock(_component_Listbox, {
                key: 0,
                options: _ctx.results,
                optionLabel: "label",
                optionGroupLabel: "label",
                optionGroupChildren: "items",
                emptyMessage: "Nessun risultato trovato",
                onChange: _ctx.onItemClick,
                dataKey: "dataKey"
              }, {
                header: _withCtx(() => [
                  _createElementVNode("header", null, [
                    _createElementVNode("h6", null, _toDisplayString(_ctx.$t('topBarSearch.title')), 1)
                  ])
                ]),
                optiongroup: _withCtx((slotProps) => [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t(slotProps.option.label)), 1)
                ]),
                option: _withCtx((slotProps) => [
                  _createElementVNode("div", _hoisted_2, [
                    (slotProps.option.img)
                      ? (_openBlock(), _createElementBlock("img", {
                          key: 0,
                          alt: "",
                          src: slotProps.option.img
                        }, null, 8, _hoisted_3))
                      : (_openBlock(), _createElementBlock("i", {
                          key: 1,
                          class: _normalizeClass(slotProps.option.icon),
                          style: _normalizeStyle(slotProps.option.iconStyle ?? '')
                        }, null, 6)),
                    _createElementVNode("span", null, _toDisplayString(slotProps.option.label), 1)
                  ])
                ]),
                _: 1
              }, 8, ["options", "onChange"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["style", "onHide"])
    ], 2), [
      [_vShow, _ctx.show || _ctx.breakpoints.desktop]
    ])
  ]))
}