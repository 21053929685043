
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import { cloneDeep, pick, clone } from 'lodash';
import { mapState } from 'vuex';
import webticTerminalService from '@/services/webticTerminalService';
import { WebticTerminal } from '@/models/webtic';
import { ApiWebticTerminalCreatePayload } from '@/models/api';
import { DropdownOption } from '@/models';
import { Sector, SectorHeader } from '@/models/sector';
import cookieUtil from '@/utils/cookieUtil';

type WebticTerminalFormModel = ApiWebticTerminalCreatePayload;

const DEFAULT_WEBTIC_TERMINAL_MODEL = {
  name: '',
  terminalId: 0,
  sectors: [],
} as WebticTerminalFormModel;

export default defineComponent({
  name: 'SettingsWebticTerminalView',
  components: { Form, Field },
  data() {
    return {
      webticTerminalId: 0,
      webticTerminalFormModel: {} as WebticTerminalFormModel,
      showDialog: false,
      sectorsOptions: [] as Array<DropdownOption>,
      validationSchema: yup.object({
        name: yup.string().required(),
        terminalId: yup.string().required(),
        sectors: yup.array().min(1),
      }),
      visibilities: [] as Array<boolean>,
    };
  },
  async beforeMount(): Promise<void> {
    /**
     * Get Webtic terminals
     */
    await this.$store.dispatch('session/setSectors');
    await this.getWebticTerminals();
  },
  mounted(): void {
    /**
     * Build sectors options
     */
    this.sectorsOptions = this.sectors.map(
      (sector: Sector) =>
        ({
          label: sector.header.name,
          value: sector.header.id,
        } as DropdownOption),
    );
  },
  async beforeRouteLeave(): Promise<void> {
    /**
     * Refresh Webtic terminals when leaving
     */
    await this.getWebticTerminals();
  },
  methods: {
    async getWebticTerminals(): Promise<void> {
      /**
       * Refresh store Webtic terminals
       */
      try {
        await this.$store.dispatch('session/setWebticTerminals');
      } catch (error) {
        this.$spiagge.toast.error(
          this.$t('settingsWebticTerminalView.toast.retrievalError'),
        );
      }
    },
    onWebticTerminalAdd() {
      /**
       * Init create form
       */
      // create
      this.webticTerminalId = 0;
      // init model
      this.webticTerminalFormModel = cloneDeep(DEFAULT_WEBTIC_TERMINAL_MODEL);
      // show dialog
      this.showDialog = true;
    },
    async onWebticTerminalDelete(
      webticTerminal: WebticTerminal,
    ): Promise<void> {
      /**
       * Delete Webtic terminal
       */
      this.$confirm.require({
        message: this.$t('settingsWebticTerminalView.confirm.message'),
        header: this.$t('settingsWebticTerminalView.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-danger p-button-outlined',
        acceptLabel: this.$t('button.delete'),
        rejectLabel: this.$t('button.cancel'),
        accept: async () => {
          try {
            // delete Webtic terminal
            await webticTerminalService.delete(webticTerminal.id);
            // refresh Webtic terminals
            await this.getWebticTerminals();
            this.$spiagge.toast.success(
              this.$t('settingsWebticTerminalView.toast.deleteSuccess'),
            );
          } catch (error) {
            this.$spiagge.toast.error(
              this.$t('settingsWebticTerminalView.toast.deleteError'),
            );
          }
        },
      });
    },
    onWebticTerminalUpdate(webticTerminal: WebticTerminal) {
      /**
       * Init update form
       */
      // set current service id
      this.webticTerminalId = webticTerminal.id;
      // init model
      this.webticTerminalFormModel = cloneDeep(DEFAULT_WEBTIC_TERMINAL_MODEL);
      // copy Webtic terminal property to model
      this.webticTerminalFormModel = pick(
        webticTerminal,
        Object.keys(this.webticTerminalFormModel),
      ) as unknown as WebticTerminalFormModel;

      this.webticTerminalFormModel.sectors = webticTerminal.sectors.map(
        (sectorHeader: SectorHeader) => sectorHeader.id,
      );

      // open dialog
      this.showDialog = true;
    },
    async onWebticTerminalSave() {
      /**
       * Form submit, create or update
       */
      if (this.webticTerminalId) {
        this.updateWebticTerminal();
      } else {
        this.createWebticTerminal();
      }
    },
    async createWebticTerminal(): Promise<void> {
      /**
       * Create a Webtic terminal
       */
      try {
        await webticTerminalService.create(this.webticTerminalFormModel);
        this.$spiagge.toast.success(
          this.$t('settingsWebticTerminalView.toast.createSuccess'),
        );
        this.reset();
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('settingsWebticTerminalView.toast.createError'),
        );
      }
    },
    async updateWebticTerminal(): Promise<void> {
      /**
       * Update a Webtic terminal
       */
      try {
        await webticTerminalService.update(
          this.webticTerminalId,
          this.webticTerminalFormModel,
        );
        this.$spiagge.toast.success(
          this.$t('settingsWebticTerminalView.toast.updateSuccess'),
        );
        this.reset();
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('settingsWebticTerminalView.toast.updateError'),
        );
      }
    },
    async reset(): Promise<void> {
      /**
       * Reset
       */
      this.showDialog = false;
      await this.getWebticTerminals();
    },
    async onWebticTerminalSwitch(
      webticTerminal: WebticTerminal,
    ): Promise<void> {
      /**
       * Update Webtic terminal disabled status
       */
      try {
        await webticTerminalService.toggle(webticTerminal.id);
        this.$spiagge.toast.success(
          this.$t('settingsWebticTerminalView.toast.updateSuccess'),
        );
        this.reset();
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('settingsWebticTerminalView.toast.updateError'),
        );
      }
    },
    toggleTerminalVisibility(id: number) {
      if (this.visibilities[id]) {
        cookieUtil.set(`spit_siaeterm-hide-${id}`, '1', 365 * 10);
      } else {
        cookieUtil.remove(`spit_siaeterm-hide-${id}`);
      }
      this.visibilities[id] = !this.visibilities[id];
    },
  },
  computed: {
    ...mapState('session', [
      'license',
      'webticTerminals',
      'permission',
      'sectors',
    ]),
  },
  created() {
    this.webticTerminals.forEach((webticTerminal: WebticTerminal) => {
      /** Visibilities (based on cookie) */
      this.visibilities[webticTerminal.id] = !cookieUtil.get(
        `spit_siaeterm-hide-${webticTerminal.id}`,
      );
    });
  },
});
