
import { DateTime } from 'luxon';
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { clone } from 'lodash';
import reservationService from '@/services/reservationService';
import {
  ApiPaymentLinkFeesPreviewPayload,
  ApiReservationOrderSummaryPayload,
} from '@/models/api';
import CalendarInput from '@/components/shared/CalendarInput.vue';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';
import { BookingPaymentFeesMode } from '@/models/booking';
import {
  OrderSummaryProviderData,
  OrderSummaryProviderType,
} from '@/models/reservation';

export default defineComponent({
  name: 'ReservationOrderSummary',
  components: {
    CalendarInput,
  },
  data() {
    const providers: OrderSummaryProviderData[] = [
      {
        type: OrderSummaryProviderType.WHATSAPP,
        label: this.$t('reservationOrderSummary.provider.whatsapp.name'),
        title: this.$t('reservationOrderSummary.provider.whatsapp.title'),
        icon: 'spi-whatsapp-o',
      },
      {
        type: OrderSummaryProviderType.EMAIL,
        label: this.$t('reservationOrderSummary.provider.email.name'),
        title: this.$t('reservationOrderSummary.provider.email.title'),
        icon: 'pi pi-envelope',
      },
    ];

    return {
      totalPreview: 0,
      totalToPayPreview: 0,
      show: false,
      sendPaymentLink: false,
      requestedAmount: 0,
      minDate: DateTime.now().startOf('day'), // Min end of today
      date: DateTime.now().plus({ months: 1 }).startOf('day'), // Default one month
      time: '23:59',
      emailSent: false,
      provider: null as OrderSummaryProviderData | null,
      providerType: clone(OrderSummaryProviderType),
      providers,
      summaryId: 0,
      linkWithExpiration: false,
      areFeesOnCustomer: false,
      feesAmount: 0.0,
      // forced en-US to have the '.' as decimal separator
      numberFormatter: new Intl.NumberFormat('en-US', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    };
  },
  beforeMount() {
    this.totalPreview = this.total;
    this.totalToPayPreview = this.totalToPay;
  },
  methods: {
    hasLinkPermission(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.reservations,
        FEATURE_PERMISSION_ACTION_CONFIG.reservations.LINK_EMAIL_WA,
      );
    },
    reset(): void {
      this.provider = null;
      this.date = DateTime.now().plus({ days: 7 });
      this.time = '23:59';
      this.requestedAmount = 0.0;
      this.linkWithExpiration = false;
      this.sendPaymentLink = false;
      this.areFeesOnCustomer = false;
      this.feesAmount = 0.0;
      this.totalPreview = 0.0;
      this.totalToPayPreview = 0.0;
    },
    open(providerType: OrderSummaryProviderType): void {
      this.reset();
      // Calc requested amount
      this.requestedAmount = this.totalToPay;
      this.areFeesOnCustomer =
        this.license.bookingPaymentFeesMode === BookingPaymentFeesMode.CUSTOMER;
      this.onRequestedAmountChanged();
      // Calc date based on reservation startDate
      const today = DateTime.now().startOf('day');
      if (this.startDate < this.date) {
        this.date = this.startDate.minus({ days: 1 });
        if (this.date < today) {
          this.date = today;
        }
        this.date = this.date.plus({ days: 7 });
      }
      const foundProvider = this.providers.find(
        (providerData: OrderSummaryProviderData) =>
          providerType === providerData.type,
      );

      if (!foundProvider) {
        return;
      }

      this.provider = foundProvider;

      // check whatsapp
      if (
        this.provider.type === OrderSummaryProviderType.WHATSAPP &&
        !this.customer.phoneNumber
      ) {
        this.$spiagge.toast.warn(
          this.$t('reservationOrderSummary.toast.phoneError'),
        );
        this.provider = null;
        return;
      }

      // check email address
      if (
        this.provider.type === OrderSummaryProviderType.EMAIL &&
        !this.customer.email
      ) {
        this.$spiagge.toast.warn(
          this.$t('reservationOrderSummary.toast.emailError'),
        );
        this.provider = null;
        return;
      }
      this.show = true;
    },
    onTimeChange(): void {
      if (!this.time) return;
      const regexTime = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
      if (!regexTime.test(this.time)) {
        this.time = '';
      }
    },
    async onSend(): Promise<void> {
      if (!this.provider) {
        return;
      }

      let timeObj = {};
      if (this.time) {
        const timeSplitted = this.time.split(':');
        timeObj = {
          hours: Number(timeSplitted[0]),
          minutes: Number(timeSplitted[1]),
        };
      }
      const date = this.date
        .set({ millisecond: 0 })
        .plus(timeObj)
        .setZone('Europe/Rome', { keepLocalTime: true });
      const payload: ApiReservationOrderSummaryPayload = {
        provider: this.provider.type,
        paymentLink: this.sendPaymentLink,
        amount: this.sendPaymentLink ? this.requestedAmount : null,
        // eslint-disable-next-line no-nested-ternary
        validUntil:
          this.sendPaymentLink && this.linkWithExpiration
            ? date.toSeconds()
            : null,
        areFeesOnCustomer: this.areFeesOnCustomer,
      };
      try {
        const response = await reservationService.orderSummary(
          this.id,
          payload,
        );
        this.summaryId = response.id;
        this.show = false;
        this.$spiagge.toast.success(
          this.$t('reservationOrderSummary.toast.summarySentSuccess'),
        );
        if (this.provider.type === OrderSummaryProviderType.WHATSAPP) {
          setTimeout(() => {
            if (this.appPlatform && this.appPlatform === 'ios') {
              window.webkit.messageHandlers.openOnBrowser.postMessage(
                this.whatsappLink,
              );
            } else if (this.appPlatform && this.appPlatform === 'android') {
              window.Android.openOnBrowser(this.whatsappLink);
            } else {
              (this.$refs.whatsappLink as HTMLAnchorElement).click();
            }
          }, 200);
        } else {
          this.emailSent = true;
        }
        // Update reservation if fees were generated
        this.$store.commit('reservation/setOrderSummary', {
          provider: this.provider.type,
          sendPaymentLink: this.sendPaymentLink,
        });
        await this.$store.dispatch('reservation/refresh');
        await this.$store.dispatch('reservation/getServices');
      } catch (error) {
        const errorCode: string = (error as any).response.data.error;
        let errMessage = this.$t(
          'reservationOrderSummary.toast.summarySentError',
        );
        if (['i_summary_001', 'i_summary_002'].includes(errorCode)) {
          errMessage = this.$t(`errors.${errorCode}`);
        }
        this.$spiagge.toast.error(errMessage);
      }
    },
    async onRequestedAmountChanged() {
      const payload: ApiPaymentLinkFeesPreviewPayload = {
        amount: this.requestedAmount ?? 0,
      };
      // Fetch for fees preview
      const feesPreview = await reservationService.paymentLinkFeesPreview(
        this.id,
        payload,
      );
      // Update UI with updated price preview
      const preview = feesPreview.preview;
      this.feesAmount = preview.feesAmount;
      this.totalPreview = preview.totalPrice;
      this.totalToPayPreview = preview.leftover;
      this.requestedAmount = Math.min(
        this.requestedAmount,
        this.totalToPayPreview,
      );
    },
  },
  computed: {
    ...mapState('reservation', ['id', 'token']),
    ...mapState('session', ['license', 'appPlatform']),
    ...mapGetters('reservation', [
      'startDate',
      'total',
      'totalToPay',
      'customer',
      'reminderLogs',
      'isJointAccount',
      'isJointAccountPart',
    ]),
    dialogTotal(): number {
      return (
        this.requestedAmount + (this.areFeesOnCustomer ? this.feesAmount : 0)
      );
    },
    visible: {
      get(): boolean {
        return true;
      },
      set(visible: boolean): void {
        this.$emit('close', visible);
      },
    },
    whatsappLink(): string {
      let url = 'https://api.whatsapp.com/send?';
      url += `phone=${this.customer.phoneAreaCode}${this.customer.phoneNumber}`;
      let text = `${this.$t('reservationOrderSummary.detailLink')} `;
      text += `https://widget.spiagge.it/stabilimenti-balneari/prenotazione/${this.license?.license}/registration-summary/?regId=${this.id}`;
      if (this.sendPaymentLink) {
        text += `&pay=${this.summaryId}`;
      }
      text += `&token=${this.token}`;
      url += `&text=${encodeURIComponent(text)}`;
      return url;
    },
  },
  watch: {
    async requestedAmount() {
      await this.onRequestedAmountChanged();
    },
    reminderLogs() {
      this.emailSent = this.reminderLogs && this.reminderLogs.length > 0;
    },
  },
});
