
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import {
  ApiStatisticsDurationPayload,
  ApiStatisticsDurationResponse,
} from '@/models/api';
import {
  StatisticBarChartModel,
  StatisticDurationReservation,
  StatisticTreeItem,
} from '@/models/statistic';
import StatisticsBarChart from '@/components/statistics/charts/StatisticsBarChart.vue';
import statisticService from '@/services/statisticService';
import StatisticsTree from '@/components/statistics/StatisticsTree.vue';

export default defineComponent({
  name: 'StatisticsDurationView',
  components: { StatisticsBarChart, StatisticsTree },
  data() {
    return {
      processing: false,
      reservations: [] as Array<StatisticDurationReservation>,
      countChart: {} as StatisticBarChartModel,
      valueChart: {} as StatisticBarChartModel,
    };
  },
  beforeMount(): void {
    /**
     * Set title, print function and run query
     */
    this.$store.commit(
      'statistic/setTitle',
      this.$t('statisticsDurationView.setTitle'),
    );
    this.$store.commit('statistic/setPrintFn', this.print);
    this.runQuery();
  },
  methods: {
    print(): void {
      /**
       * Print the page
       */
      this.$spiagge.utils.global.printHtml(
        this.$refs.printContent as HTMLElement,
        [],
        true,
      );
    },
    reset(): void {
      /**
       * Reset initial chart data and clear the reservations
       */
      this.countChart = {
        label: this.$t('statisticsDurationView.countChart.label'),
        chartData: {
          labels: [],
          datasets: [
            {
              label: this.$t(
                'statisticsDurationView.countChart.reservationNumberLabel',
              ),
              data: [],
              backgroundColor: '#FFC100',
              borderColor: '#FFC100',
            },
          ],
        },
      } as StatisticBarChartModel;
      this.valueChart = {
        label: this.$t('statisticsDurationView.valueChart.label'),
        chartData: {
          labels: [],
          datasets: [
            {
              label: this.$t(
                'statisticsDurationView.valueChart.reservationValueLabel',
              ),
              data: [],
              backgroundColor: '#FFC100',
              borderColor: '#FFC100',
            },
          ],
        },
      } as StatisticBarChartModel;
      this.reservations = [];
    },
    async runQuery(): Promise<void> {
      /**
       * Run the query
       */

      // processing
      this.processing = true;

      // reset data
      this.reset();

      // payload
      const payload = {
        startDate: this.calendar[0].toSeconds(),
        endDate: this.calendar[1].toSeconds(),
      } as ApiStatisticsDurationPayload;

      try {
        const res: ApiStatisticsDurationResponse =
          await statisticService.duration(payload);

        this.reservations = res.result;
        const labels = [] as Array<string>;

        // daily reservations
        const dailyReservations = this.reservations.filter(
          (reservation: StatisticDurationReservation) => reservation.days === 1,
        );
        if (dailyReservations) {
          labels.push(this.$t('common.daily'));
          this.valueChart.chartData.datasets[0].data.push(
            dailyReservations.reduce(
              (value, reservation: StatisticDurationReservation) =>
                value + reservation.value,
              0,
            ),
          );

          this.countChart.chartData.datasets[0].data.push(
            dailyReservations.reduce(
              (count, reservation: StatisticDurationReservation) =>
                count + reservation.count,
              0,
            ),
          );
        }

        // others reservations
        const otherReservations = this.reservations.filter(
          (reservation: StatisticDurationReservation) => reservation.days !== 1,
        );
        otherReservations.forEach(
          (reservation: StatisticDurationReservation) => {
            if (reservation.days === 32) {
              labels.push(this.$t('statisticsDurationView.greaterThanDays'));
            } else {
              labels.push(this.$tc('common.daysCount', reservation.days));
            }

            this.valueChart.chartData.datasets[0].data.push(reservation.value);
            this.countChart.chartData.datasets[0].data.push(reservation.count);
          },
        );

        this.countChart.chartData.labels = labels;
        this.valueChart.chartData.labels = labels;
      } catch (e) {
        // reset all on error
        this.reset();
        this.$spiagge.toast.error(
          this.$t('statisticsDurationView.toast.retrievalError'),
        );
      } finally {
        this.processing = false;
      }
    },
  },
  computed: {
    ...mapState('statistic', ['calendar']),
    dailyReservations(): Array<StatisticDurationReservation> {
      /**
       * Daily reservations
       */
      return this.reservations.filter(
        (reservation: StatisticDurationReservation) => reservation.days === 1,
      );
    },
    dailyReservationsTotalValue(): number {
      /**
       * Daily reservations total value
       */
      return this.dailyReservations.reduce(
        (value, reservation: StatisticDurationReservation) =>
          value + reservation.value,
        0,
      );
    },
    dailyReservationsTotalCount(): number {
      /**
       * Daily reservations total count
       */
      return this.dailyReservations.reduce(
        (count, reservation: StatisticDurationReservation) =>
          count + reservation.count,
        0,
      );
    },
    dailyCounters(): Array<StatisticTreeItem> {
      /**
       * Daily counters for StatisticsTree component
       */
      const dailyMidweek: StatisticDurationReservation | undefined =
        this.dailyReservations.find(
          (reservation: StatisticDurationReservation) =>
            reservation.weekend === false,
        );
      const dailyWeekend: StatisticDurationReservation | undefined =
        this.dailyReservations.find(
          (reservation: StatisticDurationReservation) =>
            reservation.weekend === true,
        );
      if (!dailyMidweek || !dailyWeekend) return [];
      return [
        {
          label: this.$t('statisticsDurationView.dailyCounters.label'),
          value: `${this.dailyReservationsTotalCount} - € ${this.dailyReservationsTotalValue}`,
          children: [
            {
              label: this.$t(
                'statisticsDurationView.dailyCounters.midweekLabel',
              ),
              value: `${dailyMidweek.count} - € ${dailyMidweek.value}`,
            },
            {
              label: this.$t(
                'statisticsDurationView.dailyCounters.weekendLabel',
              ),
              value: `${dailyWeekend.count} - € ${dailyWeekend.value}`,
            },
          ],
        },
      ];
    },
    counters(): Array<StatisticDurationReservation> {
      /**
       * Counters data without daily counters
       */
      return this.reservations.filter(
        (reservation: StatisticDurationReservation) => reservation.days !== 1,
      );
    },
  },
  watch: {
    calendar() {
      if (this.calendar[1] === null) return;
      this.runQuery();
    },
  },
  beforeUnmount(): void {
    /**
     * Reset statistics store when unmount
     */
    this.$store.commit('statistic/reset');
  },
});
