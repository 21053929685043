import axios, { AxiosResponse } from 'axios';
import {
  ApiCashFlowCreatePayload,
  ApiCashFlowCreateResponse,
  ApiCashFlowFindPayload,
  ApiCashFlowFindResponse,
  ApiCashFlowGetPayload,
  ApiCashFlowGetResponse,
  ApiCashFlowUpdatePayload,
  ApiCashFlowUpdateResponse,
} from '@/models/api';
import { CashFlow } from '@/models/cashFlow';
import apiService from './apiService';

export default {
  async get(payload: ApiCashFlowGetPayload): Promise<CashFlow> {
    const res: AxiosResponse<ApiCashFlowGetResponse> = await axios.get(
      apiService.cashFlow.get(payload),
    );
    return res.data.result.cashFlow;
  },
  async find(payload: ApiCashFlowFindPayload): Promise<Array<CashFlow>> {
    const res: AxiosResponse<ApiCashFlowFindResponse> = await axios.get(
      apiService.cashFlow.find(payload),
    );
    return res.data.result.cashFlows;
  },
  async create(payload: ApiCashFlowCreatePayload): Promise<CashFlow> {
    const res: AxiosResponse<ApiCashFlowCreateResponse> = await axios.post(
      apiService.cashFlow.create(),
      payload,
    );
    return res.data.result.cashFlow;
  },
  async update(payload: ApiCashFlowUpdatePayload): Promise<CashFlow> {
    const cashFlowId = payload.id;
    const res: AxiosResponse<ApiCashFlowUpdateResponse> = await axios.patch(
      apiService.cashFlow.update(cashFlowId),
      payload,
    );
    return res.data.result.cashFlow;
  },
};
