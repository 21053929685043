import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6dce380a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mb-4" }
const _hoisted_2 = { class: "divider" }
const _hoisted_3 = { class: "p-field" }
const _hoisted_4 = { class: "mt-3 mb-3 p-field" }
const _hoisted_5 = { class: "mt-3 mb-3 p-field" }
const _hoisted_6 = { class: "mt-3 mb-3 p-field" }
const _hoisted_7 = { class: "mb-3" }
const _hoisted_8 = { for: "filterDatesModeId" }
const _hoisted_9 = { class: "mt-3 mb-3 p-field" }
const _hoisted_10 = { for: "voucherDefaultBtnId" }
const _hoisted_11 = { class: "divider" }
const _hoisted_12 = { class: "mt-3 mb-3 p-field" }
const _hoisted_13 = { class: "mb-3" }
const _hoisted_14 = { class: "mt-3 mb-3 p-field" }
const _hoisted_15 = { for: "cashDeskPointId" }
const _hoisted_16 = { class: "mt-3 mb-3 p-field" }
const _hoisted_17 = { for: "printOrdersWithReceiptId" }
const _hoisted_18 = { class: "mt-3 mb-3 p-field" }
const _hoisted_19 = { for: "sunbedStandardBrnId" }
const _hoisted_20 = { class: "mt-3 mb-3 p-field" }
const _hoisted_21 = { class: "mb-3" }
const _hoisted_22 = { for: "AdvanceStatisticsId" }
const _hoisted_23 = { class: "mt-3 mb-3 p-field" }
const _hoisted_24 = { class: "mb-3" }
const _hoisted_25 = { for: "cashDeskFlowStaticsId" }
const _hoisted_26 = { class: "mt-3 mb-3 divider" }
const _hoisted_27 = { class: "flex align-items-center" }
const _hoisted_28 = { for: "countsRemoveId" }
const _hoisted_29 = { class: "mt-3 mb-3 p-field" }
const _hoisted_30 = { class: "mb-3" }
const _hoisted_31 = { for: "receiptPrintCustomerName" }
const _hoisted_32 = { class: "mt-3 mb-3 p-field" }
const _hoisted_33 = {
  key: 0,
  class: "mt-3 mb-3 p-field"
}
const _hoisted_34 = { key: 1 }
const _hoisted_35 = { class: "mt-3 mb-3 p-field" }
const _hoisted_36 = { class: "mb-3" }
const _hoisted_37 = { class: "mt-3 mb-3 p-field" }
const _hoisted_38 = { for: "printSpot" }
const _hoisted_39 = { class: "mt-3 mb-3 p-field" }
const _hoisted_40 = { for: "printHotelRoom" }
const _hoisted_41 = { class: "mt-3 mb-3 p-field" }
const _hoisted_42 = { for: "printCustomer" }
const _hoisted_43 = { class: "mt-3 mb-3 p-field" }
const _hoisted_44 = { for: "printReservationDates" }
const _hoisted_45 = { class: "mt-3 mb-3 p-field" }
const _hoisted_46 = { for: "printStaging" }
const _hoisted_47 = { class: "mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_InputTooltip = _resolveComponent("InputTooltip")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.$t('priceList.title')), 1),
    _createVNode(_component_Form, { class: "p-0 booking-form" }, {
      default: _withCtx(({ meta }) => [
        _createElementVNode("section", null, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h6", null, _toDisplayString(_ctx.$t('priceList.changeSectorMode.title')), 1),
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.localLicense.pricesChangeSectorMode,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localLicense.pricesChangeSectorMode) = $event)),
                options: _ctx.sectorsModes,
                optionLabel: "name",
                optionValue: "code",
                placeholder: _ctx.$t('priceList.changeSectorMode.placeholder'),
                style: {"width":"240px"}
              }, null, 8, ["modelValue", "options", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h6", null, _toDisplayString(_ctx.$t('priceList.calcUnionAccountMode.title')), 1),
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.localLicense.pricesMergedRegMode,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localLicense.pricesMergedRegMode) = $event)),
                options: _ctx.accountsCalc,
                optionLabel: "name",
                optionValue: "code",
                placeholder: _ctx.$t('priceList.calcUnionAccountMode.placeholder'),
                style: {"width":"240px"}
              }, null, 8, ["modelValue", "options", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h6", null, _toDisplayString(_ctx.$t('priceList.ticket.title')), 1),
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.localLicense.beachTickets,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localLicense.beachTickets) = $event)),
                options: _ctx.tickets,
                optionLabel: "name",
                optionValue: "code",
                placeholder: _ctx.$t('priceList.ticket.placeholder'),
                style: {"width":"240px"}
              }, null, 8, ["modelValue", "options", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("h6", _hoisted_7, _toDisplayString(_ctx.$t('priceList.voucher')), 1),
              _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('priceList.filterDatesMode.title')), 1),
              _createVNode(_component_Dropdown, {
                id: "filterDatesModeId",
                modelValue: _ctx.localLicense.pricesVoucherFilterMode,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localLicense.pricesVoucherFilterMode) = $event)),
                options: _ctx.filtersVoucherModes,
                optionLabel: "name",
                optionValue: "code",
                placeholder: _ctx.$t('priceList.filterDatesMode.placeholder'),
                style: {"width":"240px"}
              }, null, 8, ["modelValue", "options", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('priceList.voucherDefaultBtn')), 1),
              _createVNode(_component_InputSwitch, {
                id: "voucherDefaultBtnId",
                modelValue: _ctx.localLicense.pricesVoucherDefaultButton,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localLicense.pricesVoucherDefaultButton) = $event))
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("h6", _hoisted_13, _toDisplayString(_ctx.$t('priceList.cashDeskPoint')), 1)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('priceList.cashDeskPoint')), 1),
              _createVNode(_component_InputSwitch, {
                id: "cashDeskPointId",
                modelValue: _ctx.localLicense.fastCash,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.localLicense.fastCash) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('priceList.posCommandPaperOptions.title')), 1),
              _createVNode(_component_Dropdown, {
                id: "printOrdersWithReceiptId",
                modelValue: _ctx.localLicense.posCommandPaper,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.localLicense.posCommandPaper) = $event)),
                options: _ctx.posCommandPaperOptions,
                "option-label": "label",
                "option-value": "value",
                placeholder: _ctx.$t('priceList.posCommandPaperOptions.placeholder'),
                style: {"width":"240px"}
              }, null, 8, ["modelValue", "options", "placeholder"])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t('priceList.sunbedStandardBrn')), 1),
              _createVNode(_component_InputSwitch, {
                id: "sunbedStandardBrnId",
                modelValue: _ctx.localLicense.fastCashStdBtns,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.localLicense.fastCashStdBtns) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("h6", _hoisted_21, _toDisplayString(_ctx.$t('priceList.statistics')), 1),
              _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t('priceList.AdvanceStatistics')), 1),
              _createVNode(_component_InputSwitch, {
                id: "AdvanceStatisticsId",
                modelValue: _ctx.localLicense.statisticsAdvanced,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.localLicense.statisticsAdvanced) = $event))
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("h6", _hoisted_24, _toDisplayString(_ctx.$t('priceList.cashDeskFlow')), 1),
            _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t('priceList.cashDeskFlowStatics.title')), 1),
            _createVNode(_component_Dropdown, {
              id: "cashDeskFlowStaticsId",
              modelValue: _ctx.localLicense.pricesCashFlow,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.localLicense.pricesCashFlow) = $event)),
              options: _ctx.cashdesksFlowsStat,
              optionLabel: "name",
              optionValue: "code",
              placeholder: _ctx.$t('priceList.cashDeskFlowStatics.placeholder'),
              style: {"width":"240px"},
              class: "mb-3"
            }, null, 8, ["modelValue", "options", "placeholder"])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t('priceList.countsRemove')), 1),
              _createVNode(_component_InputTooltip, {
                message: _ctx.$t('priceList.countsRemoveHelper')
              }, null, 8, ["message"])
            ]),
            _createVNode(_component_InputSwitch, {
              id: "countsRemoveId",
              modelValue: _ctx.localLicense.pricesCashFlowDelete,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.localLicense.pricesCashFlowDelete) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("h6", _hoisted_30, _toDisplayString(_ctx.$t('priceList.receiptPrintingOptions')), 1),
            _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.$t('priceList.printCustomerName')), 1),
            _createVNode(_component_InputSwitch, {
              id: "receiptPrintCustomerName",
              modelValue: _ctx.localLicense.receiptPrintCustomerName,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.localLicense.receiptPrintCustomerName) = $event))
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("h6", null, _toDisplayString(_ctx.$t('priceList.sunbedPositionListMode.title')), 1),
            _createVNode(_component_Dropdown, {
              modelValue: _ctx.localLicense.pricesBedsOld,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.localLicense.pricesBedsOld) = $event)),
              options: _ctx.listPriceWorkstationsModes,
              optionLabel: "name",
              optionValue: "code",
              placeholder: _ctx.$t('priceList.sunbedPositionListMode.placeholder')
            }, null, 8, ["modelValue", "options", "placeholder"])
          ]),
          (_ctx.isCabinModeVisible)
            ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                _createElementVNode("h6", null, _toDisplayString(_ctx.$t('beachMapConfig.cabins.useCabinsMode.title')), 1),
                _createVNode(_component_Dropdown, {
                  id: "useCabinsModeId",
                  modelValue: _ctx.localLicense.cabinMode,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.localLicense.cabinMode) = $event)),
                  options: _ctx.settingsUseCabins,
                  optionLabel: "name",
                  optionValue: "code",
                  placeholder: _ctx.$t('beachMapConfig.cabins.useCabinsMode.placeholder')
                }, null, 8, ["modelValue", "options", "placeholder"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.localLicense.stickerSettingsFields)
            ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                _createElementVNode("div", _hoisted_35, [
                  _createElementVNode("h6", _hoisted_36, _toDisplayString(_ctx.$t('priceList.stickerSettings.title')), 1)
                ]),
                _createElementVNode("label", null, _toDisplayString(_ctx.$t('priceList.stickerSettings.description')), 1),
                _createElementVNode("div", _hoisted_37, [
                  _createElementVNode("label", _hoisted_38, _toDisplayString(_ctx.$t('priceList.stickerSettings.printSpot')), 1),
                  _createVNode(_component_InputSwitch, {
                    id: "printSpot",
                    modelValue: _ctx.localLicense.stickerSettingsFields.printSpot,
                    "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.localLicense.stickerSettingsFields.printSpot) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_39, [
                  _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.$t('priceList.stickerSettings.printHotelRoom')), 1),
                  _createVNode(_component_InputSwitch, {
                    id: "printHotelRoom",
                    modelValue: _ctx.localLicense.stickerSettingsFields.printHotelRoom,
                    "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.localLicense.stickerSettingsFields.printHotelRoom) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_41, [
                  _createElementVNode("label", _hoisted_42, _toDisplayString(_ctx.$t('priceList.stickerSettings.printCustomer')), 1),
                  _createVNode(_component_InputSwitch, {
                    id: "printCustomer",
                    modelValue: _ctx.localLicense.stickerSettingsFields.printCustomer,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.localLicense.stickerSettingsFields.printCustomer) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_43, [
                  _createElementVNode("label", _hoisted_44, _toDisplayString(_ctx.$t('priceList.stickerSettings.printReservationDates')), 1),
                  _createVNode(_component_InputSwitch, {
                    id: "printReservationDates",
                    modelValue: _ctx.localLicense.stickerSettingsFields.printReservationDates,
                    "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.localLicense.stickerSettingsFields.printReservationDates) = $event))
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", _hoisted_45, [
                  _createElementVNode("label", _hoisted_46, _toDisplayString(_ctx.$t('priceList.stickerSettings.printStaging')), 1),
                  _createVNode(_component_InputSwitch, {
                    id: "printStaging",
                    modelValue: _ctx.localLicense.stickerSettingsFields.printStaging,
                    "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.localLicense.stickerSettingsFields.printStaging) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_47, [
          _createVNode(_component_Button, {
            disabled: !meta.valid,
            onClick: _ctx.submit,
            icon: "pi pi-save",
            label: _ctx.$t('button.save'),
            style: {"width":"230px"}
          }, null, 8, ["disabled", "onClick", "label"])
        ])
      ]),
      _: 1
    })
  ], 64))
}