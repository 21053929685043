
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
import {
  ApiReservationUpdateServicePayload,
  ApiWebticMarkTicketPayload,
} from '@/models/api';
import { Reservation, ReservationService } from '@/models/reservation';
import {
  BEACH_TICKET_SERVICE_ID,
  Service,
  ServiceType,
} from '@/models/service';
import { LicenseBeachTicketsMode, QrCodeReadMode } from '@/models/license';
import reservationService from '@/services/reservationService';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  ActionPermissionType,
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';
import webticService from '@/services/webticService';

export default defineComponent({
  name: 'ReservationBeachTicketService',
  data() {
    return {
      beachTicketDialog: false,
      currentDay: DateTime.now().toFormat('dd/MM/yyyy'),
    };
  },
  async mounted(): Promise<void> {
    /**
     * Auto open dialog if qr param = 1 and service is enabled
     */
    if (!this.enabled) return;
    const qrParam = this.$route.query.qr;
    if (qrParam === '1') {
      // manual
      if (this.license.qrCodeReadMode === QrCodeReadMode.MANUAL) {
        this.beachTicketDialog = true;
      } else {
        try {
          // check in
          await this.$store.dispatch('reservation/checkIn');
          // refresh query string
          await this.$router.replace({ query: {} });
          // re-open qr scanner
          if (this.license.qrCodeReadMode === QrCodeReadMode.AUTO_QR_READER) {
            const qrScanButton: HTMLElement | null = document.getElementById(
              'qr-scan-navigation-item',
            );
            if (qrScanButton) {
              qrScanButton.click();
            }
          }
          // remain on reservation
          if (this.license.qrCodeReadMode === QrCodeReadMode.AUTO_RESERVATION) {
            this.$spiagge.toast.success(
              this.$t('reservationBeachTicketService.toast.success'),
            );
          }
        } catch (error) {
          this.$spiagge.utils.error.handle(error);
        }
      }
    }
  },
  methods: {
    hasUpdatePermission(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.reservations,
        FEATURE_PERMISSION_ACTION_CONFIG.reservations.UPDATE,
      );
    },
    async validateOne(): Promise<void> {
      /**
       * Validate one ticket
       */
      const used = this.reservationBeachTicketService
        ? this.reservationBeachTicketService.used + 1
        : 1;
      this.updateTicketService(used);
    },
    async validateAll(): Promise<void> {
      /**
       * Validate all tickets
       */
      this.updateTicketService(this.ticketsAvailable);
    },
    async invalidateOne(): Promise<void> {
      /**
       * Invalidate one ticket
       */
      this.updateTicketService(this.ticketsUsed - 1);
    },
    async invalidateAll(): Promise<void> {
      /**
       * Invalidate all tickets
       */
      this.updateTicketService(0);
    },
    async updateTicketService(used: number): Promise<void> {
      /**
       * Update ticket service
       */
      try {
        await this.$store.dispatch('reservation/updateService', {
          serviceId: BEACH_TICKET_SERVICE_ID,
          used,
        } as ApiReservationUpdateServicePayload);
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationBeachTicketService.toast.error'),
        );
      }
    },
    async markAllSiaeTicket(): Promise<void> {
      await this.$store.dispatch('reservation/checkIn');
      await this.refreshSiaeTicketsList();
    },
    async markSiaeTicket(barcode: string): Promise<void> {
      await webticService.markTicket({
        barcode,
      } as ApiWebticMarkTicketPayload);
      await this.refreshSiaeTicketsList();
    },
    async openBeachTicketDialog(): Promise<void> {
      this.beachTicketDialog = true;
      if (this.license.webticId) {
        await this.$store.dispatch('reservation/getWebticTickets');
      }
    },
    async refreshSiaeTicketsList(): Promise<void> {
      if (this.license.webticId) {
        await this.$store.dispatch('reservation/getWebticTickets');
      }
    },
  },
  computed: {
    ...mapState('session', ['license']),
    ...mapState('session', {
      licenseServices: 'services',
    }),
    ...mapState('reservation', [
      'id',
      'startDate',
      'endDate',
      'deleted',
      'absences',
      'beds',
      'chairs',
      'maxiBeds',
      'deckChairs',
      'webticTickets',
    ]),
    ...mapState('reservation', {
      reservationServices: 'services',
    }),
    enabled(): boolean {
      /**
       * Service enabled
       */
      return this.license.beachTickets > 0;
    },
    showActions(): boolean {
      /**
       * Actions enabled when
        - date is in range
        - reservation is not deleted
        - date is not an absence
       */
      const today = DateTime.now().startOf('day');
      const inRange = today >= this.startDate && today <= this.endDate;
      const isAbsence =
        this.absences.filter(
          (absence: Reservation) =>
            today >= DateTime.fromSeconds(absence.startDate) &&
            today <= DateTime.fromSeconds(absence.endDate),
        ).length > 0;
      return inRange && !this.deleted && !isAbsence;
    },
    ticketsAvailable(): number {
      /**
       * Get tickets available
       */
      let ticketsAvailable = 0;
      /* get all reservation services bought qty with daily ticket type */
      this.reservationServices.map((reservationService: ReservationService) => {
        const licenseService: Service | undefined = this.licenseServices.find(
          (ls: Service) =>
            ls.id === reservationService.serviceId &&
            ls.type === ServiceType.DAILY_TICKET,
        );
        if (licenseService) {
          ticketsAvailable +=
            reservationService.bought - reservationService.deleted;
        }
      });

      /* subtract service 517 qty if present */
      if (this.reservationBeachTicketService) {
        ticketsAvailable -=
          this.reservationBeachTicketService.bought -
          this.reservationBeachTicketService.deleted;
      }

      /* if ticket mode 1 then add staging pieces */
      if (this.license.beachTickets === LicenseBeachTicketsMode.BEDS) {
        const stagingPieces =
          this.beds + this.maxiBeds + this.deckChairs + this.chairs;
        ticketsAvailable += stagingPieces;
      }

      return ticketsAvailable;
    },
    ticketsUsed(): number {
      /**
       * Get tickets already used
       */
      return this.reservationBeachTicketService
        ? this.reservationBeachTicketService.used
        : 0;
    },
    reservationBeachTicketService(): ReservationService | undefined {
      /**
       * Get beach ticket service of reservation
       */
      return this.reservationServices.find(
        (reservationService: ReservationService) =>
          reservationService.serviceId === BEACH_TICKET_SERVICE_ID,
      );
    },
    canValidate(): boolean {
      /**
       * Can validate only if tickets are available
       */
      return this.ticketsUsed < this.ticketsAvailable;
    },
    canInvalidate(): boolean {
      /**
       * Can invalidate only if there are used tickets
       */
      return this.ticketsUsed > 0;
    },
  },
  async beforeMount() {
    if (this.license.webticId) {
      this.$store.dispatch('reservation/getWebticTickets');
    }
  },
});
