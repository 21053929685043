import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { id: "license-select-view" }
const _hoisted_2 = {
  key: 0,
  class: "licenses"
}
const _hoisted_3 = { class: "grid" }
const _hoisted_4 = ["src", "alt"]
const _hoisted_5 = {
  key: 0,
  class: "selected-label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Message = _resolveComponent("Message")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('licenseSelect.title')), 1),
    (_ctx.licenses.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.licenses, (license, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "col-12 sm:col-6 md:col-4 xl:col-3"
              }, [
                _createVNode(_component_Card, {
                  class: _normalizeClass(["license", _ctx.isSelected(license) ? 'selected' : ''])
                }, {
                  header: _withCtx(() => [
                    _createElementVNode("img", {
                      src: license.img,
                      alt: license.name
                    }, null, 8, _hoisted_4)
                  ]),
                  title: _withCtx(() => [
                    _createElementVNode("h5", null, _toDisplayString(license.name), 1)
                  ]),
                  footer: _withCtx(() => [
                    (_ctx.isSelected(license))
                      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('licenseSelect.currentLicense')), 1))
                      : (_openBlock(), _createBlock(_component_Button, {
                          key: 1,
                          label: _ctx.$t('common.select'),
                          disabled: 
                  _ctx.$store.getters['session/license']?.name === license.name
                ,
                          onClick: ($event: any) => (_ctx.onSelect(license))
                        }, null, 8, ["label", "disabled", "onClick"]))
                  ]),
                  _: 2
                }, 1032, ["class"])
              ]))
            }), 128))
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.licenses.length === 0 && !_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Message, {
          key: 1,
          severity: "info"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('licenseSelect.empty')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}