
import { defineComponent } from 'vue';
import { AppRequestStatus } from '@/models/app';

export default defineComponent({
  name: 'PendingRequestLoader',
  computed: {
    pendingRequest(): boolean {
      return (
        false &&
        this.$store.getters['app/requestStatus'] === AppRequestStatus.PENDING
      );
    },
  },
});
