import { resolveComponent as _resolveComponent, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26bd2344"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "statistics-counters-vouchers-view",
  class: "statistics-view view"
}
const _hoisted_2 = { id: "table" }
const _hoisted_3 = {
  key: 0,
  id: "chart"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ToggleButtonCustom = _resolveComponent("ToggleButtonCustom")!
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _component_StatisticsCard = _resolveComponent("StatisticsCard")!
  const _component_Slider = _resolveComponent("Slider")!
  const _component_StatisticsDataTable = _resolveComponent("StatisticsDataTable")!
  const _component_StatisticsBarChart = _resolveComponent("StatisticsBarChart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.hotelLink)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#statistics-header-title-after"
        }, [
          _createVNode(_component_Button, {
            label: _ctx.$t('statisticsCounters.copyLink'),
            class: "p-button-outlined p-button-sm",
            onClick: _ctx.onCopyLink
          }, null, 8, ["label", "onClick"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.license?.hasGroup)
      ? (_openBlock(), _createBlock(_component_ToggleButtonCustom, {
          key: 1,
          buttonWidth: _ctx.widthToggle,
          modelValue: _ctx.groupDataFilter,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.groupDataFilter) = $event)),
          trueLabel: _ctx.trueLabel,
          falseLabel: _ctx.falseLabel,
          class: "mt-2"
        }, null, 8, ["buttonWidth", "modelValue", "trueLabel", "falseLabel"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_TabMenu, {
        model: _ctx.tabs,
        activeIndex: _ctx.selectedTab,
        "onUpdate:activeIndex": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedTab) = $event))
      }, null, 8, ["model", "activeIndex"]),
      _withDirectives(_createElementVNode("section", _hoisted_2, [
        _createVNode(_component_StatisticsDataTable, {
          id: _ctx.id,
          columns: _ctx.columns,
          searchColumns: _ctx.searchColumns,
          defaultView: _ctx.defaultView,
          endpoint: _ctx.endpoint,
          basePayload: _ctx.basePayload,
          paginate: false,
          exportEndpoint: _ctx.exportEndpoint,
          onRowClick: _ctx.openReservation,
          rowClass: _ctx.rowClass,
          onResult: _ctx.onResult
        }, {
          tableAfter: _withCtx(() => [
            _withDirectives(_createVNode(_component_Slider, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card, index) => {
                  return (_openBlock(), _createBlock(_component_StatisticsCard, {
                    key: index,
                    label: card.label,
                    value: card.value
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 512), [
              [_vShow, _ctx.selectedTab === 0]
            ])
          ]),
          _: 1
        }, 8, ["id", "columns", "searchColumns", "defaultView", "endpoint", "basePayload", "exportEndpoint", "onRowClick", "rowClass", "onResult"])
      ], 512), [
        [_vShow, _ctx.selectedTab === 0]
      ]),
      (_ctx.selectedTab === 1)
        ? (_openBlock(), _createElementBlock("section", _hoisted_3, [
            _createVNode(_component_StatisticsBarChart, { chartData: _ctx.chartData }, null, 8, ["chartData"])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}