/* eslint-disable */

function androidShowAndroidToast(toast) {
    window['Android'].showToast(toast);
}

function androidChangeAndroidAppPage(tab) {
    window['Android'].changeAppPage(tab);
}

function androidChangeAppTabAndURL(tab, url) {
    window['Android'].changeAppTabAndURL(tab, url);
}

function androidSendCustomProtocolMsg(dataObjString) {
    const dataObj = JSON.parse(dataObjString);
    const ip = dataObj.ip;
    const port = dataObj.port;
    const msg = dataObj.msg;
    // console.log('AndroidMessageResponse:' + msg);
    window['Android'].sendCustomProtocolMsg(ip, port, msg);
}

function androidReceiveCustomProtocolMsgResponse(response) {
    var responseData = response;
    // console.log('AndroidMessageResponse:' + response);
    if (responseData.substring(10, 18) == '33303133') {
        if (typeof (printerCustomCallback) != "undefined") {
            if (printerCustomCallback instanceof Function) {
                printerCustomCallback();
            } else {
                window[printerCustomCallback]();
            }
        }
    } else if (responseData.substring(10, 18) == '34303034') {
        if (typeof (printerCustomCallback) != "undefined") {
            if (printerCustomCallback instanceof Function) {
                printerCustomCallback();
            } else {
                window[printerCustomCallback]();
            }
        }
    }
}

function androidFacebookLogin() {
    window['Android'].facebookLogin();
}

function androidFacebookLoginResponse(response) {
    // console.log(response);
}

export {
    androidShowAndroidToast,
    androidChangeAndroidAppPage,
    androidChangeAppTabAndURL,
    androidSendCustomProtocolMsg,
    androidReceiveCustomProtocolMsgResponse,
    androidFacebookLogin,
    androidFacebookLoginResponse
}
