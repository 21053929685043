import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "altered-prices" }
const _hoisted_2 = { class: "prices" }
const _hoisted_3 = {
  key: 0,
  class: "price-alteration-percentage"
}
const _hoisted_4 = { class: "value" }
const _hoisted_5 = { class: "actions" }
const _hoisted_6 = { class: "title" }
const _hoisted_7 = { class: "infos" }
const _hoisted_8 = { class: "info inverted" }
const _hoisted_9 = { class: "label" }
const _hoisted_10 = { class: "value" }
const _hoisted_11 = { class: "info inverted" }
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "value" }
const _hoisted_14 = {
  key: 1,
  class: "price-hint"
}
const _hoisted_15 = { class: "hint" }
const _hoisted_16 = { class: "prices" }
const _hoisted_17 = { class: "value" }
const _hoisted_18 = { class: "actions" }
const _hoisted_19 = { class: "info inverted" }
const _hoisted_20 = { class: "label" }
const _hoisted_21 = { class: "value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["revenue-management-card", _ctx.classes])
  }, [
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.alteredPricesLabel), 1),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.pricesLabel), 1),
    (_ctx.priceAlteration)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString((_ctx.priceAlteration.percentageDiscount > 0 ? '+' : '') +
          _ctx.priceAlteration.percentageDiscount) + "% ", 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.priceAlteration)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            icon: "pi pi-trash",
            class: "delete-button p-button-rounded p-button-text",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.deleteDialog = true), ["stop"]))
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_Button, {
        icon: "pi pi-info-circle",
        class: "info-button p-button-rounded p-button-text",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.onOpenDetail($event as PointerEvent)), ["stop"])),
        onFocusout: _ctx.onCloseDetail
      }, null, 8, ["onFocusout"]),
      _createVNode(_component_OverlayPanel, {
        id: "slot-detail-overlay-panel",
        ref: "op"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('revenueManagementCard.detailTitle')), 1),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('revenueManagementCard.occupancy')) + ":", 1),
              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.slotDetail.occupancy) + "%", 1)
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t('revenueManagementCard.revenue')) + ":", 1),
              _createElementVNode("span", _hoisted_13, " € " + _toDisplayString(_ctx.slotDetail.revenue), 1)
            ])
          ])
        ]),
        _: 1
      }, 512)
    ]),
    (_ctx.priceHint)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.getPriceRange(_ctx.priceHint.revenuePercentageValue)), 1),
            _createElementVNode("span", _hoisted_17, _toDisplayString((_ctx.priceHint.revenuePercentageValue > 0 ? '+' : '-') +
            _ctx.priceHint.revenuePercentageValue) + "% ", 1)
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_Button, {
              icon: "spi-check",
              class: "p-button-success",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('acceptHint', _ctx.priceHint)), ["stop"]))
            }),
            _createVNode(_component_Button, {
              icon: "spi-times",
              class: "p-button-danger",
              onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$emit('declineHint', _ctx.priceHint)), ["stop"]))
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Dialog, {
      id: "delete-dialog",
      visible: _ctx.deleteDialog,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.deleteDialog) = $event)),
      class: "dialog",
      modal: "",
      onHide: _cache[6] || (_cache[6] = ($event: any) => (_ctx.deleteDialog = false))
    }, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('revenueManagementCard.deleteRuleConfirm')), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button-outlined",
          label: _ctx.$t('button.cancel'),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.deleteDialog = false))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          class: "p-button-danger",
          label: _ctx.$t('button.delete'),
          onClick: _ctx.onDelete
        }, null, 8, ["label", "onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('revenueManagementCard.currentRule')) + ":", 1),
          _createElementVNode("span", _hoisted_21, _toDisplayString((_ctx.priceAlteration as PriceAlteration).percentageDiscount > 0 ? '+' : '') + " " + _toDisplayString((_ctx.priceAlteration as PriceAlteration).percentageDiscount) + "% ", 1)
        ]),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('revenueManagementCard.restoreInfo')), 1)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 2))
}