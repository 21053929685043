import { cloneDeep } from 'lodash';

import store from '@/store';
import {
  DEFAULT_PRINT,
  Print,
  PrintArticle,
  PrintDeposit,
  Printer,
  PrinterError,
  PrintMode,
  PrintPaymentMethod,
} from '@/models/printer';
import { CashFlowMethod } from '@/models/cashFlow';
import {
  PrintLineAction,
  PrintLineFontSize,
  PrintLineTextStyle,
} from '@/models/print';

export default {
  setup(): void {
    store.state.session.printers.map((printer: Printer) => {
      window.createPrinter(printer, `${process.env.VUE_APP_LEGACY_DOMAIN}`);
    });
  },
  paymentMethodToPrintMethod(
    paymentMethod: CashFlowMethod,
  ): PrintPaymentMethod {
    let pm = PrintPaymentMethod.CONTANTI;
    switch (paymentMethod) {
      case CashFlowMethod.BANCOMAT:
        pm = PrintPaymentMethod.BANCOMAT;
        break;
      case CashFlowMethod.CREDIT_CARD:
      case CashFlowMethod.SATISPAY:
      case CashFlowMethod.STRIPE:
      case CashFlowMethod.POS_STRIPE:
        pm = PrintPaymentMethod.CARTA;
        break;
      case CashFlowMethod.BANK_TRANSFER:
        pm = PrintPaymentMethod.BONIFICO;
        break;
      // case 7:
      // return PrintPaymentMethod.NON_RISCOSSO;
      case CashFlowMethod.CASH:
      case CashFlowMethod.PAYPAL:
      default:
        pm = PrintPaymentMethod.CONTANTI;
        break;
    }
    return pm;
  },
  buildPrint(printMode: PrintMode, cashFlowMethod: CashFlowMethod): Print {
    const print: Print = cloneDeep(DEFAULT_PRINT);
    print.printMode = printMode;
    print.paymentMethod = this.paymentMethodToPrintMethod(cashFlowMethod);
    return print;
  },
  buildRepPrint(printMode: PrintMode, cashFlowMethod: CashFlowMethod): any {
    const print = {} as any;
    print.printMode = printMode;
    print.articles = [] as Array<PrintArticle>;
    print.deposits = [] as Array<PrintDeposit>;
    print.discount = 0;
    return print;
  },
  print(
    id: number,
    print: Print,
    cb: (resData: any) => void,
    failCb?: (error: PrinterError) => void,
  ): void {
    (<any>window)[`world_printer_${id.toString()}`].print(print, cb, failCb);
  },
  readCard(
    id: number,
    cb: (resData: any) => void,
    failCb?: (error: PrinterError) => void,
  ): void {
    (<any>window)[`world_printer_${id.toString()}`].readCard(cb, failCb);
  },
  printLineTextStyle(
    bold = false,
    underline = false,
    reverse = false,
  ): PrintLineTextStyle {
    return {
      action: PrintLineAction.TEXT,
      reverse,
      underline,
      bold,
    } as PrintLineTextStyle;
  },
  printLineFontSize(width = 1, height = 1): PrintLineFontSize {
    return {
      action: PrintLineAction.FONT,
      height,
      width,
    } as PrintLineFontSize;
  },
};
