
import { mapState } from 'vuex';
import { defineComponent } from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { DateTime } from 'luxon';
import { Field, Form } from 'vee-validate';
import Chip from 'primevue/chip';
import { DropdownOption } from '@/models';
import { License } from '@/models/license';
import { Printer } from '@/models/printer';
import { Service, ServiceType } from '@/models/service';
import bookingService from '@/services/bookingService';
import { Sector, SectorHeader } from '@/models/sector';
import {
  Booking,
  Channel,
  Discount,
  Offer,
  PayloadReservation,
  ReservationBooking,
  ReservationBookingView,
} from '@/models/booking';
import toastUtil from '@/utils/toastUtil';
import { ToastSeverity } from '@/models/toast';
import BookingReceiptPrinterVue from '@/components/booking/BookingReceiptPrinter.vue';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

/**
 * TODO list
 * Refactor reservationToView method (use enum/constants)
 * Refactor beforeMount method
 * ...
 */

type OfferFormModel = {
  name: string;
  from: number;
  to: number;
  percentage: number;
  sectors: Array<number>;
};

const DEFAULT_OFFER: OfferFormModel = {
  name: '',
  from: 0,
  to: 0,
  percentage: 0,
  sectors: [],
};

export default defineComponent({
  components: {
    Form,
    Field,
    Chip,
    BookingReceiptPrinter: BookingReceiptPrinterVue,
  },
  data() {
    return {
      offerDialogShow: false,
      offerDeleteDialogShow: false,
      editDialog: false,
      selectedOfferId: 0,
      offerModel: {} as OfferFormModel,
      codeDialogShow: false,
      codeDeleteDialogShow: false,
      codeSelectedIndex: 0,
      codeSelected: {} as Discount,
      offerFrom: DateTime.now().startOf('day'),
      offerTo: DateTime.now().startOf('day'),
      discountApplyFrom: DateTime.now().startOf('day'),
      discountApplyTo: DateTime.now().startOf('day'),
      discountValidFrom: DateTime.now().startOf('day'),
      discountValidTo: DateTime.now().startOf('day'),
      validOnSectorIds: [] as Array<number>,
      localBookings: {
        bookingTest: false,
        bookingActive: false,
        lastBookingsRep: [] as Array<ReservationBookingView>,
        offersRep: [] as Array<Offer>,
        discountCodesRep: [] as Array<Discount>,
      } as Booking,

      servicesList: [
        { label: this.$t('common.none'), value: 0 },
      ] as Array<DropdownOption>,
    };
  },
  methods: {
    hasPermission(action: string): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.booking,
        action,
      );
    },
    goToStatisticsOnlineReservations(): void {
      this.$router.push('/statistics/online-reservations');
    },
    getSharingUrl(offer: Offer): string {
      return encodeURIComponent(
        `https://www.anm22.it/app/yourbeach/events_landing.php?beach=${this.license?.license}&offer=${offer.id}`,
      );
    },
    getOnlineReservationIcon(spotType: string | null): string {
      // Check if represents a ticket or a spot
      switch (spotType) {
        case null:
          return require('@/assets/images/ticket/ticket-blue.svg');
        default:
          return require('@/assets/images/map/umbrella/blue.svg');
      }
    },
    shareOnFb(offer: Offer): string {
      const url = this.getSharingUrl(offer);
      return `https://www.facebook.com/sharer.php?u=${url}`;
    },
    shareOnTwitter(offer: Offer): string {
      const offerNameURIencode = encodeURIComponent(`${offer.name} ⛱👉`);
      const url = this.getSharingUrl(offer);
      return `http://twitter.com/share?text=${offerNameURIencode}&url=${url}`;
    },
    shareOnLinkedIn(offer: Offer) {
      const offerNameURIencode = encodeURIComponent(`${offer.name} ⛱👉`);
      const url = this.getSharingUrl(offer);
      return `https://www.linkedin.com/shareArticle?url=${offerNameURIencode}&url=${url}`;
    },
    openBookingSettings(): void {
      this.$router.push('/settings/booking');
    },
    async copyUrl() {
      try {
        await navigator.clipboard.writeText(this.widgetUrl);
        this.$spiagge.toast.success(
          this.$t('bookingView.toast.copyLinkSuccess'),
        );
      } catch (error) {
        this.$spiagge.toast.error(this.$t('bookingView.toast.copyLinkError'));
      }
    },
    openWidget(): void {
      window.open(this.widgetUrl);
    },
    updateOfferDialog(offer: Offer): void {
      if (
        !this.hasPermission(
          FEATURE_PERMISSION_ACTION_CONFIG.booking.ADD_UPDATE_OFFERS,
        )
      ) {
        return;
      }
      this.selectedOfferId = offer.id;
      this.offerModel = cloneDeep(DEFAULT_OFFER);
      this.offerModel = cloneDeep(offer as unknown as OfferFormModel);
      this.offerFrom = DateTime.fromSeconds(this.offerModel.from);
      this.offerTo = DateTime.fromSeconds(this.offerModel.to);
      this.offerModel.sectors = offer.sectors
        ? offer.sectors.map((sector: SectorHeader) => sector.id)
        : [];

      this.offerDialogShow = true;
      this.editDialog = true;
    },
    newOfferDialog(): void {
      this.offerModel = cloneDeep(DEFAULT_OFFER);
      this.selectedOfferId = 0;
      this.offerModel.from = DateTime.now().startOf('day').toSeconds();
      this.offerModel.percentage = 0;
      this.offerModel.to = DateTime.now().startOf('day').toSeconds();
      this.offerFrom = DateTime.now().startOf('day');
      this.offerTo = DateTime.now().startOf('day');
      this.offerDialogShow = true;
      this.editDialog = false;
    },
    cancelOfferDialog(): boolean {
      this.offerDialogShow = false;
      this.editDialog = false;
      this.selectedOfferId = 0;
      return this.offerDialogShow;
    },
    openDeleteOfferDialog(offer: Offer): void {
      this.selectedOfferId = offer.id;
      this.offerDeleteDialogShow = true;
    },
    async closeDeleteOfferDialog() {
      try {
        await bookingService.deleteOffer(this.selectedOfferId);
        this.localBookings.offersRep = await bookingService.offerList();
      } catch (error) {
        // TODO
      }
      this.selectedOfferId = 0;
      this.offerDeleteDialogShow = false;
    },
    updateCodeDialog(index: number): void {
      if (
        !this.hasPermission(
          FEATURE_PERMISSION_ACTION_CONFIG.booking.ADD_UPDATE_DISCOUNT_CODES,
        )
      ) {
        return;
      }
      this.codeSelectedIndex = index;
      this.codeSelected = cloneDeep(
        this.localBookings.discountCodesRep[this.codeSelectedIndex],
      );
      this.codeDialogShow = true;
      this.editDialog = true;
      this.discountApplyFrom = DateTime.fromSeconds(
        this.codeSelected.applyFrom,
      );
      this.discountApplyTo = DateTime.fromSeconds(this.codeSelected.applyTo);
      this.discountValidFrom = DateTime.fromSeconds(
        this.codeSelected.validFrom,
      );
      this.discountValidTo = DateTime.fromSeconds(this.codeSelected.validTo);
    },
    newCodeDialog(): void {
      this.codeSelectedIndex = this.localBookings.discountCodesRep.length;
      this.codeSelected = {} as Discount;
      this.codeSelected.maxUses = 0;
      this.codeSelected.discountPercentage = 0;
      this.codeSelected.discountFixed = 0;
      this.codeSelected.flagWeek = true;
      this.codeSelected.flagPreHoliday = true;
      this.codeSelected.flagHoliday = true;
      this.codeSelected.validTo = DateTime.now().startOf('day').toSeconds();
      this.codeSelected.validFrom = DateTime.now().startOf('day').toSeconds();
      this.codeSelected.applyTo = DateTime.now().startOf('day').toSeconds();
      this.codeSelected.applyFrom = DateTime.now().startOf('day').toSeconds();
      this.codeSelected.validOnSectorIds = [];
      this.validOnSectorIds = [];
      this.discountApplyFrom = DateTime.now().startOf('day');
      this.discountApplyTo = DateTime.now().startOf('day');
      this.discountValidFrom = DateTime.now().startOf('day');
      this.discountValidTo = DateTime.now().startOf('day');
      this.codeDialogShow = true;
      this.editDialog = false;
    },
    cancelCodeDialog(): void {
      this.codeDialogShow = false;
      this.editDialog = false;
    },
    openDeleteCodeDialog(index: number): void {
      this.codeSelectedIndex = index;
      this.codeDeleteDialogShow = true;
    },
    async closeDeleteCodeDialog() {
      try {
        if (this.license) {
          await bookingService.deleteDiscount(
            this.localBookings.discountCodesRep[this.codeSelectedIndex].id,
          );
          this.localBookings.discountCodesRep.splice(this.codeSelectedIndex, 1);
        }
      } catch (error) {
        // TODO
      }
      this.codeDeleteDialogShow = false;
    },
    async submitOffer() {
      try {
        if (this.selectedOfferId) {
          await bookingService.updateOffer(
            this.selectedOfferId,
            this.offerModel,
          );
        } else if (this.license) {
          await bookingService.addNewOffer(this.offerModel);
        }
        this.offerDialogShow = false;
        this.localBookings.offersRep = await bookingService.offerList();
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
    async onSubmitDiscount() {
      let response = null;
      try {
        // Updating existing discount code
        if (this.editDialog && this.license) {
          response = await bookingService.updateDiscount(this.codeSelected);
          this.localBookings.discountCodesRep[this.codeSelectedIndex] =
            response;
        } else if (this.license) {
          response = await bookingService.addNewDiscount(this.codeSelected);
          this.localBookings.discountCodesRep.unshift(response);
        }
        this.codeDialogShow = false;
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
    combineDate(from: number, to: number): string {
      const fromDateTime = DateTime.fromSeconds(from).toFormat('dd/MM/yy');
      const toDateTime = DateTime.fromSeconds(to).toFormat('dd/MM/yy');
      return `${fromDateTime} - ${toDateTime}`;
    },
    getValueLabel(code: Discount) {
      const parts = [];
      if (code.discountPercentage > 0) {
        parts.push(`${Math.round(code.discountPercentage * 100) / 100}%`);
      }
      if (code.discountFixed > 0) {
        parts.push(
          `${Math.round(code.discountFixed * 100) / 100} ${this.$t(
            'common.currency',
          )}`,
        );
      }
      return parts.join(' + ');
    },
    getUsesLabel(code: Discount) {
      return code.maxUses > 0
        ? `${this.$t('bookingView.discountDialog.remainingUses')}: ${
            code.remainingUses
          }/${code.maxUses}`
        : this.$t('bookingView.discountDialog.unlimited');
    },
    getFlagsLabel(code: Discount) {
      const flags = [];
      if (code.flagWeek) {
        flags.push(this.$t('bookingView.discountDialog.weekly'));
      }
      if (code.flagPreHoliday) {
        flags.push(this.$t('bookingView.discountDialog.preHoliday'));
      }
      if (code.flagHoliday) {
        flags.push(this.$t('bookingView.discountDialog.holiday'));
      }
      return flags.join(' - ');
    },
    getSectorsLabel(discountCode: Discount): string {
      return discountCode.validOnSectorIds
        .map(
          (sectorId: number) =>
            this.sectors.find((sector: Sector) => sector.header.id === sectorId)
              ?.header?.name,
        )
        .filter((sectorName: string | undefined) => sectorName !== undefined)
        .join(', ');
    },
    showSectors(sectorHeaders: Array<SectorHeader>): string {
      return sectorHeaders
        .map((sectorHeader: SectorHeader) => sectorHeader.name)
        .join(', ');
    },
    showService(value: number): string {
      return this.services.find((s: Service) => s.id === value)?.name ?? '';
    },
    openReservation(index: number) {
      if (
        permissionsUtil.isActionPermissionAllowed(
          FEATURE_PERMISSION_CONFIG.reservations,
          FEATURE_PERMISSION_ACTION_CONFIG.reservations.PAGE_ACCESS,
        )
      ) {
        this.$router.push(
          `/reservation/${this.localBookings.lastBookingsRep[index].id}`,
        );
      }
    },
    reservationToView(value: ReservationBooking): ReservationBookingView {
      const ob = {
        firstName: value.firstName,
        lastName: value.lastName,
        spotName: value.spotName,
        spotType: value.spotType,
        startDate: value.startDate,
        endDate: value.endDate,
        id: value.id,
      } as ReservationBookingView;
      // eslint-disable-next-line default-case
      switch (value.channel) {
        case 'ol':
        case 'ombrellove':
          ob.channel = {
            title: 'Ombrellove',
            img: require('@/assets/images/bookings-portal/ombrellove.png'),
            code: value.channel,
          } as Channel;
          break;
        case 'trovaspiagge':
          ob.channel = {
            title: 'Trova Spiagge',
            img: require('@/assets/images/bookings-portal/trovaspiagge.png'),
            code: value.channel,
          } as Channel;
          break;
        case 'yb':
        case 'app-yb':
          ob.channel = {
            title: 'YourBeach',
            img: require('@/assets/images/bookings-portal/spiaggeit.svg'),
            code: value.channel,
          } as Channel;
          break;
        case 'fc':
          ob.channel = {
            title: 'Spiagge Cesenatico',
            img: require('@/assets/images/bookings-portal/spiaggecesenatico.png'),
            code: value.channel,
          } as Channel;
          break;
        case 'rm':
          ob.channel = {
            title: 'I Borghi Marinari di Roma',
            img: require('@/assets/images/bookings-portal/iborghimarinaridiroma.png'),
            code: value.channel,
          } as Channel;
          break;
        case 'rn':
        case 'spiaggiarimini':
          ob.channel = {
            title: 'Spiaggia Rimini Network',
            img: require('@/assets/images/bookings-portal/spiaggiarimini.png'),
            code: value.channel,
          } as Channel;
          break;
        default:
          ob.channel = { title: '', img: '', code: '' } as Channel;
          break;
      }
      return ob;
    },
    reservationToModel(value: ReservationBookingView): ReservationBooking {
      const ob = {
        firstName: value.firstName,
        lastName: value.lastName,
        spotName: value.spotName,
        spotType: value.spotType,
        channel: value.channel.code,
        startDate: value.startDate,
        endDate: value.endDate,
        id: value.id,
      } as ReservationBooking;
      return ob;
    },
    onDiscountCodeReservationClick(reservationId: number): void {
      const reservationUrl = `${process.env.VUE_APP_DOMAIN}${process.env.VUE_APP_BASE_URL}reservation/${reservationId}`;
      window.open(reservationUrl, '_blank');
    },
  },
  computed: {
    ...mapState('session', ['sectors']),
    FEATURE_PERMISSION_ACTION_CONFIG() {
      return FEATURE_PERMISSION_ACTION_CONFIG;
    },
    license(): License {
      return this.$store.getters['session/license'];
    },
    printersStored(): Array<Printer> | null {
      return this.$store.getters['session/printers'];
    },
    services(): Array<Service> {
      return [
        ...this.$store.getters['session/beachServices'],
        ...this.$store.getters['session/dailyTicketServices'](true),
      ];
    },
    isBookingTest(): boolean {
      return (
        (this.license?.bookingPaymentPaypalSandbox ||
          this.license?.bookingPaymentStripeTest) ??
        true
      );
    },
    widgetUrl(): string {
      return `https://widget.spiagge.it/stabilimenti-balneari/prenotazione/${this.license?.license}/`;
    },
    fromDateOffer: {
      get(): Date {
        return this.offerFrom.toJSDate();
      },
      set(d: Date): void {
        const dateTime = DateTime.fromFormat(
          `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`,
          'd-M-yyyy',
        );
        this.offerModel.from = dateTime.toSeconds();
        this.offerFrom = dateTime;
      },
    },
    toDateOffer: {
      get(): Date {
        return this.offerTo.toJSDate();
      },
      set(d: Date): void {
        const dateTime = DateTime.fromFormat(
          `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`,
          'd-M-yyyy',
        );
        this.offerModel.to = dateTime.toSeconds();
        this.offerTo = dateTime;
      },
    },
    fromApplyDateDiscount: {
      get(): Date {
        return this.discountApplyFrom.toJSDate();
      },
      set(d: Date): void {
        const dateTime = DateTime.fromFormat(
          `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`,
          'd-M-yyyy',
        );
        this.codeSelected.applyFrom = dateTime.toSeconds();
        this.discountApplyFrom = dateTime;
      },
    },
    toApplyDateDiscount: {
      get(): Date {
        return this.discountApplyTo.toJSDate();
      },
      set(d: Date): void {
        const dateTime = DateTime.fromFormat(
          `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`,
          'd-M-yyyy',
        );
        this.codeSelected.applyTo = dateTime.toSeconds();
        this.discountApplyTo = dateTime;
      },
    },
    fromValidDateDiscount: {
      get(): Date {
        return this.discountValidFrom.toJSDate();
      },
      set(d: Date): void {
        const dateTime = DateTime.fromFormat(
          `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`,
          'd-M-yyyy',
        );
        this.codeSelected.validFrom = dateTime.toSeconds();
        this.discountValidFrom = dateTime;
      },
    },
    toValidDateDiscount: {
      get(): Date {
        return this.discountValidTo.toJSDate();
      },
      set(d: Date): void {
        const dateTime = DateTime.fromFormat(
          `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`,
          'd-M-yyyy',
        );
        this.codeSelected.validTo = dateTime.toSeconds();
        this.discountValidTo = dateTime;
      },
    },
    windowWidth(): number {
      return this.$store.getters['app/windowWidth'];
    },
    windowHeight(): number {
      return this.$store.getters['app/windowHeight'];
    },
    dialogWidth(): string {
      const width = this.windowWidth;
      return width > 768 ? '50%' : '100%';
    },
    sectorOptions(): Array<DropdownOption> {
      return this.sectors.map((sector: Sector) => ({
        label: sector.header.name,
        value: sector.header.id,
      }));
    },
  },
  async beforeMount() {
    await this.$store.dispatch('session/setSectors');
    this.localBookings.bookingActive = this.license?.bookingStatus ?? false;
    this.localBookings.bookingTest =
      this.license?.bookingPaymentStripeTest ?? false;
    this.localBookings.offersRep = [] as Array<Offer>;
    this.localBookings.discountCodesRep = [] as Array<Discount>;
    this.localBookings.lastBookingsRep = [] as Array<ReservationBookingView>;
    const payload = {} as PayloadReservation;
    payload.type = '1,4,6';
    payload.online = '1';
    payload.deleted = '0';
    payload.orderBy = 'id_desc';
    payload.page = '0';
    payload.offset = '10';
    const url = `?type=${payload.type}&online=${payload.online}&deleted=${payload.deleted}&orderBy=${payload.orderBy}&page=${payload.page}&offset=${payload.offset}&fields=firstName,lastName,spotName,spotType,startDate,endDate,id,channel`;
    try {
      if (this.license) {
        const offerResponse = await bookingService.offerList();
        if (offerResponse && Object.keys(offerResponse).length > 0) {
          this.localBookings.offersRep = offerResponse;
        }
        const discountResponse = await bookingService.discountList();
        if (discountResponse && Object.keys(discountResponse).length > 0) {
          this.localBookings.discountCodesRep = discountResponse;
        }
        const reservationsResponse = await bookingService.resevationsList(url);
        if (
          reservationsResponse &&
          Object.keys(reservationsResponse).length > 0
        ) {
          this.localBookings.lastBookingsRep = reservationsResponse.map(
            (element) => this.reservationToView(element),
          );
        }

        if (this.$route.query.discountCode) {
          const discountCodeParam = this.$route.query.discountCode as string;
          const discountCode = this.localBookings.discountCodesRep.findIndex(
            (dc: Discount) => dc.code === discountCodeParam,
          );
          if (discountCode >= 0) {
            this.updateCodeDialog(discountCode);
          }
        }
      }
    } catch (error) {
      // TODO
    }
    if (this.services) {
      this.services.forEach((service) => {
        if (
          service.type !== ServiceType.BEACH
          || service.webticPriceId
          || service.licenseId !== this.license.license
        ) {
          return;
        }
        this.servicesList.push({
          label: service.name,
          value: service.id,
        } as DropdownOption);
      });
    }
  },
});
