
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import { mapState } from 'vuex';
import { DataTableRowClickEvent } from 'primevue/datatable';
import StatisticsCard from '@/components/statistics/StatisticsCard.vue';
import Slider from '@/components/shared/Slider.vue';
import {
  StatisticCard as StatisticCardI,
  StatisticDataTableColumn,
  StatisticDataTableColumnRender,
  StatisticDataTableView,
  StatisticDateMode,
} from '@/models/statistic';
import StatisticsDataTable from '@/components/statistics/StatisticsDataTable.vue';
import statisticService from '@/services/statisticService';
import ToggleButtonCustom from '@/components/shared/ToggleButtonCustom.vue';
import { ApiStatisticsCountersHotelDetailResponse } from '@/models/api';
import { SpotInfo, SPOT_INFOS } from '@/models/spot';
import StatisticsBarChart from '@/components/statistics/charts/StatisticsBarChart.vue';
import { ChartData } from '@/models/chart';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

export default defineComponent({
  name: 'StatisticsCountersHotelDetailView',
  components: {
    StatisticsDataTable,
    StatisticsBarChart,
    StatisticsCard,
    ToggleButtonCustom,
    Slider,
  },
  data() {
    return {
      hotelHash: '',
      groupFilter: false,
      widthToggle: 130,
      trueLabel: this.$t('statisticsCounters.trueLabel'),
      falseLabel: this.$t('statisticsCounters.falseLabel'),
      selectedTab: 0,
      tabs: [
        {
          label: this.$t('statisticsCounters.tab.tableView'),
          icon: 'pi pi-table',
        },
        {
          label: this.$t('statisticsCounters.tab.chartView'),
          icon: 'pi pi-chart-bar',
        },
      ],
      id: 'counters-hotel',
      endpoint: statisticService.countersHotelDetail,
      exportEndpoint: statisticService.exportCountersHotel,
      columns: [
        {
          label: this.$t('statisticsCountersHotelDetailView.column.spotType'),
          field: 'spotType',
          sortable: true,
          formatter: (value: string) =>
            SPOT_INFOS.find((spot: SpotInfo) => spot.type === value)?.label,
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.spotName'),
          field: 'spotName',
          sortable: true,
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.firstName'),
          field: 'firstName',
          sortable: true,
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.lastName'),
          field: 'lastName',
          sortable: true,
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.staging'),
          field: 'staging',
          sortable: false,
          formatter: (value: Array<number>) =>
            this.$spiagge.utils.global.stagingLabel(
              value[0],
              value[1],
              value[2],
              value[3],
            ),
        },
        {
          label: this.$tc('statisticsArrivalsDepartures.column.ticket', 2),
          field: 'tickets',
          sortable: false,
          renderer: 'tickets',
        },
        {
          label: this.$tc('statisticsArrivalsDepartures.column.service', 2),
          field: 'services',
          sortable: false,
          renderer: 'services',
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.startDate'),
          field: 'startDate',
          sortable: true,
          formatter: (value: number) => this.$spiagge.date.fromMillis(value),
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.endDate'),
          field: 'endDate',
          sortable: true,
          formatter: (value: number) => this.$spiagge.date.fromMillis(value),
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.hotel'),
          field: 'hotel',
          sortable: true,
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.duration'),
          field: 'duration',
          sortable: true,
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.online'),
          field: 'online',
          sortable: true,
          renderer: StatisticDataTableColumnRender.BOOLEAN_ICON,
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.channel'),
          field: 'channel',
          sortable: true,
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.priceTotal'),
          field: 'priceTotal',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.listTotal'),
          field: 'listTotal',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.paidTotal'),
          field: 'paidTotal',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.toPay'),
          field: 'toPay',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t(
            'statisticsCountersHotelDetailView.column.discountPercentage',
          ),
          field: 'discountPercentage',
          sortable: true,
          formatter: (value: string) => `${value} %`,
        },
        {
          label: this.$t(
            'statisticsCountersHotelDetailView.column.discountedBy',
          ),
          field: 'discountedBy',
          sortable: true,
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.createdBy'),
          field: 'createdBy',
          sortable: true,
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.deletedBy'),
          field: 'deletedBy',
          sortable: true,
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.createdAt'),
          field: 'createdAt',
          sortable: true,
          formatter: (value: number) =>
            this.$spiagge.date.fromMillis(value, 'dd-MM-yyyy HH:mm'),
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.deletedAt'),
          field: 'deletedAt',
          sortable: true,
          formatter: (value: number) =>
            this.$spiagge.date.fromMillis(value, 'dd-MM-yyyy HH:mm'),
        },
        {
          label: this.$t(
            'statisticsCountersHotelDetailView.column.discountedAt',
          ),
          field: 'discountedAt',
          sortable: true,
          formatter: (value: number) =>
            this.$spiagge.date.fromMillis(value, 'dd-MM-yyyy HH:mm'),
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.column.voucher'),
          field: 'voucher',
          sortable: true,
          renderer: StatisticDataTableColumnRender.BOOLEAN_ICON,
        },
        {
          label: this.$t(
            'statisticsCountersHotelDetailView.column.voucherAmount',
          ),
          field: 'voucherAmount',
          sortable: false,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t(
            'statisticsCountersHotelDetailView.column.licenseCode',
          ),
          field: 'licenseCode',
          sortable: true,
        },
      ] as Array<StatisticDataTableColumn>,
      searchColumns: [],
      defaultView: {
        id: DateTime.now().startOf('day').toSeconds(),
        label: this.$t('statisticsCounters.defaultViewLabel'),
        columns: [
          'licenseCode',
          'spotType',
          'spotName',
          'firstName',
          'lastName',
          'staging',
          'startDate',
          'endDate',
          'hotel',
          'voucher',
          'voucherAmount',
        ],
        orderBy: [],
      } as StatisticDataTableView,
      cards: [
        {
          label: this.$t(
            'statisticsCountersHotelDetailView.card.reservationsNumber',
          ),
          value: null,
        },
        {
          label: this.$t('statisticsCountersHotelDetailView.card.daysTotal'),
          value: null,
        },
      ] as Array<StatisticCardI>,
      chartData: {
        labels: [],
        datasets: [
          {
            label: this.$t(
              'statisticsCountersHotelDetailView.chartData.umbrellas',
            ),
            data: [],
            backgroundColor: '#FFC100',
          },
          {
            label: this.$t(
              'statisticsCountersHotelDetailView.chartData.bulkPieces',
            ),
            data: [],
            backgroundColor: '#E89300',
          },
        ],
      } as ChartData,
    };
  },
  beforeMount(): void {
    const id = this.$route.params.id as string;
    this.groupFilter = this.groupHotelData;

    if (this.windowWidth < 992) this.widthToggle = 108;
    else this.widthToggle = 130;

    if (!id) {
      this.$spiagge.toast.error(
        this.$t('statisticsCounters.toast.retrievalError'),
      );
      this.$router.push('/statistics/counters-hotel');
    } else {
      this.$store.commit(
        'statistic/setTitle',
        `${this.$t(
          'statisticsCountersHotelDetailView.setTitle',
        )} ${id}`,
      );
      this.$store.commit(
        'statistic/setBackRoute',
        '/statistics/counters-hotel',
      );
    }

    this.$store.dispatch('session/setLicense', this.license?.license);
  },
  methods: {
    onResult(res: ApiStatisticsCountersHotelDetailResponse): void {
      this.$store.commit(
        'statistic/setTitle',
        `${this.$t('statisticsCountersHotelDetailView.setTitle')} ${
          res.result.name
        }`,
      );
      // cards
      this.cards[0].value = `${res.result.cards.hotelTotal.toString()}`;
      this.cards[1].value = `${res.result.cards.totalDays.toString()}`;
      // chart
      const labels = [] as Array<string>;
      let currentDay = this.calendar[0].toSeconds();
      const endDay = this.calendar[1].toSeconds();
      while (currentDay <= endDay) {
        labels.push(DateTime.fromSeconds(currentDay).toFormat('dd/MM'));
        currentDay += 86400;
      }
      this.chartData.labels = labels;
      this.chartData.datasets[0].data = res.result.chart[0];
      this.chartData.datasets[1].data = res.result.chart[1];
      this.hotelHash = res.result.hash;
    },
    onCopyLink(): void {
      navigator.clipboard.writeText(this.hotelLink);
      this.$spiagge.toast.success(
        this.$t('statisticsCounters.toast.hotelLinkCopySuccess'),
      );
    },
    openReservation(event: DataTableRowClickEvent) {
      if (
        permissionsUtil.isActionPermissionAllowed(
          FEATURE_PERMISSION_CONFIG.reservations,
          FEATURE_PERMISSION_ACTION_CONFIG.reservations.PAGE_ACCESS,
        )
      ) {
        const routeData = this.$router.resolve({
          name: 'ReservationEdit',
          params: { id: `${event.data.reservationId}` },
        });
        this.$spiagge.utils.global.openLink(routeData.href, true);
      }
    },
    rowClass() {
      return 'row';
    },
  },
  computed: {
    ...mapState('statistic', ['calendar', 'groupHotelData']),
    ...mapState('app', ['windowWidth', 'windowHeight']),
    ...mapState('session', ['license']),
    groupDataFilter: {
      set(value: boolean) {
        this.groupFilter = !value;
        this.$store.commit('statistic/setGroupHotelData', this.groupFilter);
      },
      get(): boolean {
        return !this.groupFilter;
      },
    },
    hotelLink(): string {
      if (!this.hotelHash) return '';
      return `https://widget.spiagge.it/app/yourbeach/hotel_search?
        b=${this.license.license}&
        v=${encodeURIComponent(this.$route.params.id.toString())}&
        m=h&
        h=${this.hotelHash}`;
    },
    basePayload(): {
      [key: string]: string | boolean | number | StatisticDateMode;
    } {
      return {
        dateMode: StatisticDateMode.ATTENDANCE,
        hotel: this.$route.params.id as string,
        groupData: this.groupFilter,
      };
    },
  },
  beforeRouteLeave() {
    this.$store.commit('statistic/setBackRoute', null);
  },
  watch: {
    windowWidth() {
      if (this.windowWidth === null) return;
      if (this.windowWidth < 992) this.widthToggle = 108;
      else this.widthToggle = 130;
    },
  },
});
