
import { defineComponent } from 'vue';
import { cloneDeep } from 'lodash';
import { mapGetters, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { ServiceGroup } from '@/models/serviceGroup/serviceGroup';
import { Service } from '@/models/service';
import permissionsUtil from '@/utils/permissionsUtil';
import { FEATURE_PERMISSION_ACTION_CONFIG, FEATURE_PERMISSION_CONFIG } from '@/models/permissions';
import { ApiReservationUpdateServicePayload } from '@/models/api';
import { ReservationService, ReservationType } from '@/models/reservation';
import { ServiceGroupLimit } from '@/models/service/serviceAvailability';
import ReservationBeachTicketService from '@/components/reservation/services/ReservationBeachTicketService.vue';
import { SectorHeader } from '@/models/sector';
import { LimitInformation, ServiceGroupInfo, SingleServiceInfo } from '@/models/service/serviceInformation';

interface ServiceQuantityMap {
  [key: number]: number;
}

export default defineComponent({
  name: 'ReservationTickets',
  components: { ReservationBeachTicketService },
  data() {
    return {
      mappedPaidServices: {} as ServiceQuantityMap,
      mappedServicesToGroups: new Map<number, Array<Service>>(),
      ticketServiceIds: [] as Array<number>,
      updateServiceDebounce: debounce(
          this.updateService as (service: Service) => void,
          500,
      ),
    };
  },
  async beforeMount() {
    this.mappedServicesToGroups = new Map<number, Array<Service>>();
    await this.$store.dispatch('reservation/loadServiceAvailability');
    const tickets: Array<Service> = this.$store.getters['session/dailyTicketServices'](true);
    tickets.forEach((service: Service) => {
      const serviceGroupId = service.serviceGroupId;
      if (serviceGroupId === null || serviceGroupId === 0) {
        return;
      }
      this.ticketServiceIds.push(service.id);
      // Push service inside the right collection
      if (!this.mappedServicesToGroups.has(serviceGroupId)) {
        this.mappedServicesToGroups.set(serviceGroupId, []);
      }
      (this.mappedServicesToGroups.get(serviceGroupId) as Array<Service>).push(service);
    });
  },
  methods: {
    // Checks user's reservation update permission
    hasUpdatePermission(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
          FEATURE_PERMISSION_CONFIG.reservations,
          FEATURE_PERMISSION_ACTION_CONFIG.reservations.UPDATE,
      );
    },
    isServiceGroupVisible(serviceGroup: ServiceGroup): boolean {
      const groupServices = this.mappedServicesToGroups.get(serviceGroup.id) ?? [];
      return groupServices.filter(
          (ticket: Service) => this.isServiceVisible(ticket),
      ).length > 0;
    },
    isServiceVisible(service: Service): boolean {
      return this.isServiceSellable(service) || this.isServiceBought(service);
    },
    isServiceSellable(service: Service): boolean {
      if (this.reservationType === ReservationType.MOVING) {
        return service.reservationSchedule;
      }
      if (this.isBillReservation) {
        return service.canBeSoldAloneOffline;
      }
      const isRightSector = (service.sectors?.filter(
          (sector: SectorHeader) => this.reservationSector.id === sector.id,
      )?.length ?? 0) > 0;
      // Also check if it can be sold on a reservation
      return isRightSector && service.reservationSchedule;
    },
    isServiceBought(service: Service): boolean {
      return (this.reservationServices?.get(service.id)?.paid ?? 0) > 0;
    },
    formatLimitLabel(
        limit: LimitInformation | undefined,
    ): string {
      if (limit === undefined) {
        return '-/-';
      }
      return `${
        limit.reservedQuantity
      }/${
        limit.maximumQuantity > 0 ? limit.maximumQuantity : '∞'}`;
    },
    // Update reservation service
    async updateService(service: Service): Promise<void> {
      // Request Payload
      const payload = {
        serviceId: service.id,
      } as ApiReservationUpdateServicePayload;
      const currentService = this.reservationServices.get(service.id);
      // No Service found
      if (!currentService) {
        return;
      }
      // Compare new and old value
      const newValue: number | undefined = this.mappedPaidServices[service.id];
      const oldValue = currentService.paid;
      // Nothing changed
      if (newValue === undefined || newValue === oldValue) {
        return;
      }
      // Add bought or deleted to payload
      if (newValue > oldValue) {
        payload.bought =
            (currentService.bought as number) + (newValue - oldValue);
      } else {
        payload.deleted =
            (currentService.deleted as number) + (oldValue - newValue);
      }
      try {
        // Fetch availabilities
        await this.$store.dispatch('reservation/updateService', payload);
        await this.$store.dispatch('reservation/loadServiceAvailability');
      } catch (e) {
        this.$spiagge.toast.error(
            this.$t('reservationExtraServices.toast.error'),
        );
      }
    },
  },
  computed: {
    ...mapState('session', {
      licenseServiceGroups: 'serviceGroups',
      licenseServices: 'services',
    }),
    ...mapGetters('reservation', {
      reservationStartDate: 'startDate',
      reservationEndDate: 'endDate',
      reservationType: 'type',
      reservationSector: 'sector',
      isBillReservation: 'isBillReservation',
    }),
    serviceGroups(): Array<ServiceGroup> {
      return cloneDeep(this.licenseServiceGroups);
    },
    reservationServices(): Map<number, ReservationService> {
      // Every service associated to the reservation that is a ticket
      const result = new Map<number, ReservationService>();
      const reservationServices = this.$store.getters['reservation/services'];
      reservationServices.forEach(
        (resService: ReservationService) => {
          if (this.ticketServiceIds.includes(resService.serviceId)) {
            result.set(resService.serviceId, resService);
          }
        },
      );
      return result;
    },
    mappedServiceLimits(): Map<number, LimitInformation> {
      const result = new Map<number, LimitInformation>();
      const availabilities = this.$store.getters['reservation/serviceAvailability'];
      availabilities.serviceGroupLimits.forEach((groupLimit: ServiceGroupInfo) => {
        groupLimit.serviceLimits.forEach((serviceLimit: SingleServiceInfo) => {
          result.set(
            serviceLimit.serviceId,
            {
              hasLimit: serviceLimit.hasLimit,
              reservedQuantity: serviceLimit.reservedQuantity,
              availableQuantity: serviceLimit.availableQuantity,
              maximumQuantity: serviceLimit.maximumQuantity,
            } as LimitInformation,
          );
        });
      });
      return result;
    },
    mappedServiceGroupLimits(): Map<number, LimitInformation> {
      const result = new Map<number, LimitInformation>();
      const availabilities = this.$store.getters['reservation/serviceAvailability'];
      availabilities.serviceGroupLimits.forEach((groupLimit: ServiceGroupLimit) => {
        result.set(
          groupLimit.serviceGroupId,
          {
            hasLimit: groupLimit.hasLimit,
            reservedQuantity: groupLimit.reservedQuantity,
            availableQuantity: groupLimit.availableQuantity,
            maximumQuantity: groupLimit.maximumQuantity,
          } as LimitInformation,
        );
      });
      return result;
    },
  },
  watch: {
    // Remap paid services map after reservation service update
    reservationServices(services: Map<number, ReservationService>): void {
      services.forEach((rs: ReservationService, key: number) => {
          this.mappedPaidServices[key] = rs.paid;
      });
    },
  },
});
