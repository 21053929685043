/* eslint-disable no-param-reassign */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable import/no-cycle */
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { MapSpotStatus } from '@/models/map';
import {
  PlannerBuilder,
  PlannerData,
  PlannerElement,
  PlannerElementRaw,
  PlannerElementRawReservation,
  PlannerElementReservation,
  PlannerElementSegment,
} from '@/models/planner';
import { ReservationHalfDay, ReservationStatus } from '@/models/reservation';
import { SpotType } from '@/models/spot';

export default {
  /**
   * Convert a raw element into formatted element
   * @param {MapElementRaw} rawElement
   * @return {MapElement}
   */
  elementDeserialize(rawElement: PlannerElementRaw): PlannerElement {
    const element = {} as PlannerElement;

    element.type = rawElement.t;
    element.name = rawElement.n;
    element.sector = rawElement.s;
    element.hasFootboardBefore = rawElement.hfb;
    element.isFirstOfRow = rawElement.if;
    element.isLastOfRow = rawElement.il;
    element.reservations = new Array<PlannerElementReservation>();

    rawElement.r.forEach((r: PlannerElementRawReservation) => {
      const reservation = {} as PlannerElementReservation;
      reservation.id = r.id;
      reservation.masterId = r.mId;
      reservation.spotType = r.st;
      reservation.spotName = r.sn;
      reservation.status = r.s;
      reservation.seasonal = r.se;
      reservation.halfDay = r.h;
      reservation.startDate = r.sd;
      reservation.endDate = r.ed;
      reservation.customerFirstName = r.fn;
      reservation.customerLastName = r.ln;
      reservation.paid = r.p;
      reservation.segments = r.seg.map((s) => ({
        startDate: s.s,
        endDate: s.e,
        absence: s.a,
      }));
      reservation.voucherId = r.rv ?? 0;
      element.reservations.push(reservation);
    });

    return element;
  },
  /**
   * Get reservation status to display on planner
   * @param {MapSpotElement} element
   * @return {MapSpotStatus}
   */
  getPlannerStatus(reservation: PlannerElementReservation): MapSpotStatus {
    let status = MapSpotStatus.BUSY;
    if (reservation.seasonal) status = MapSpotStatus.SEASONAL;
    if (reservation.halfDay) {
      if (reservation.halfDay === ReservationHalfDay.MORNING) {
        status = MapSpotStatus.HALF_DAY_MORNING;
      } else {
        status = MapSpotStatus.HALF_DAY_AFTERNOON;
      }
    }
    if (reservation.status === ReservationStatus.NOT_CONFIRMED) {
      status = MapSpotStatus.TEMPORARY;
    }
    // TODO: Nice to have in future release
    // if (element.overbooking) status = MapSpotStatus.OVERBOOKING;
    // if (element.sharing) status = MapSpotStatus.SHARING;
    return status;
  },
  builderReset(builder: PlannerBuilder): void {
    builder.edited = false; // set as starting point
    builder.originals.clear();
    builder.movingId = null;
    builder.reservationId = null;
    builder.reservations.length = 0;
    builder.spotKeys.clear();
    builder.editDirection = null;
    builder.spotCategory = null;
  },
  builderStart(
    data: PlannerData | null,
    builder: PlannerBuilder,
    reservation: PlannerElementReservation,
  ): void {
    builder.edited = false; // set as starting point
    builder.movingId = reservation.masterId;
    builder.reservationId = reservation.id;
    builder.spotCategory =
      reservation.spotType === SpotType.UMBRELLA ||
      reservation.spotType === SpotType.GAZEBO
        ? SpotType.UMBRELLA
        : reservation.spotType;
    // Insert steps
    const steps = [] as Array<PlannerElementReservation>;
    if (data && builder.movingId) {
      for (let i = 0; i < data.spots.length; i += 1) {
        const spot = data.spots[i];
        steps.push(
          ...spot.reservations.filter((r) => r.masterId === builder.movingId),
        );
      }
    } else {
      steps.push(reservation);
    }
    steps.sort((a, b) => a.startDate - b.startDate);
    for (let i = 0; i < steps.length; i += 1) {
      const step = steps[i];
      builder.spotKeys.add(`${step.spotType}${step.spotName}`);
      builder.originals.set(step.id, cloneDeep(step));
      builder.reservations.push(cloneDeep(step));
    }
  },
  builderFreeCells(
    builder: PlannerBuilder,
    reservation: PlannerElementReservation,
  ): void {
    reservation.segments.map((s) => {
      if (!s.absence) {
        let cursor = s.startDate;
        while (cursor <= reservation.endDate) {
          builder.busyCells.delete(
            `${reservation.spotType}${reservation.spotName}${cursor}`,
          );
          cursor += 86400;
        }
      }
    });
  },
  builderBookCells(
    builder: PlannerBuilder,
    reservation: PlannerElementReservation,
  ): void {
    reservation.segments.map((s) => {
      if (!s.absence) {
        let cursor = s.startDate;
        while (cursor <= reservation.endDate) {
          builder.busyCells.add(
            `${reservation.spotType}${reservation.spotName}${cursor}`,
          );
          cursor += 86400;
        }
      }
    });
  },
  builderUpdateSpotKeys(builder: PlannerBuilder): void {
    builder.spotKeys.clear();
    // eslint-disable-next-line no-restricted-syntax
    for (const r of builder.reservations) {
      builder.spotKeys.add(`${r.spotType}${r.spotName}`);
    }
  },
  checkOverlap(
    builder: PlannerBuilder,
    reservation: PlannerElementReservation,
  ): boolean {
    const spotKey = `${reservation.spotType}${reservation.spotName}`;
    let overlap = false;
    reservation.segments.forEach((s) => {
      if (!s.absence) {
        const startDate = DateTime.fromSeconds(s.startDate);
        const endDate = DateTime.fromSeconds(s.endDate);
        let cursor = startDate;
        while (cursor <= endDate) {
          if (builder.busyCells.has(`${spotKey}${cursor.toSeconds()}`)) {
            overlap = true;
            return;
          }
          cursor = cursor.plus({ days: 1 });
        }
      }
    });
    return overlap;
  },
  // Edits reservation in place based on dates and original
  editCalcSegments(
    builder: PlannerBuilder,
    reservation: PlannerElementReservation,
  ): Array<PlannerElementSegment> {
    const segments: Array<PlannerElementSegment> = [];
    // check segments on original
    const original = cloneDeep(builder.originals.get(reservation.id));
    if (original) {
      for (let i = 0; i < original.segments.length; i += 1) {
        const segment = original.segments[i];
        // Out of bounds
        // if (
        //   segment.endDate < reservation.startDate ||
        //   segment.startDate > reservation.endDate
        // ) {
        //   // eslint-disable-next-line no-continue
        //   continue;
        // }
        if (i === 0 && reservation.startDate < segment.startDate) {
          segment.startDate = reservation.startDate;
        }
        if (reservation.startDate > segment.startDate) {
          segment.startDate = reservation.startDate;
        }
        if (reservation.endDate < segment.endDate) {
          segment.endDate = reservation.endDate;
        }
        if (
          i === original.segments.length - 1 &&
          reservation.endDate > segment.endDate
        ) {
          segment.endDate = reservation.endDate;
        }
        segments.push(segment);
      }
    } else {
      // TODO
    }
    return segments;
  },
  scrollToToday(behavior?: ScrollBehavior): void {
    const todayEl = document.querySelector('.grid-cell.today');

    if (!todayEl) {
      return;
    }

    todayEl.scrollIntoView({
      behavior: behavior ?? 'smooth',
      inline: 'center',
    });
  },
};
