
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { RefundType, RefundTypeData, REFUND_TYPES_DATA } from '@/models/refund';

export default defineComponent({
  name: 'MapRefundConfirmation',
  mounted() {
    const refundTypeLabel = REFUND_TYPES_DATA.find(
      (refundTypeData: RefundTypeData) =>
        refundTypeData.type === this.refundConfirmation.type,
    )?.label;
    this.$spiagge.toast.success(
      this.$t('mapRefundConfirmation.toast.success', {
        refundType: refundTypeLabel,
      }),
      '',
      'map',
    );
  },
  methods: {
    onConfirm(): void {
      this.$store.commit('map/setRefundConfirmation', null);
    },
  },
  computed: {
    ...mapState('map', ['refundConfirmation']),
    isStipeRefund(): boolean {
      return this.refundConfirmation.type === RefundType.STRIPE;
    },
    isCodeRefund(): boolean {
      return this.refundConfirmation.type === RefundType.CODE;
    },
  },
});
