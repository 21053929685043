/**
 * ApiKeys service
 */

import axios, { AxiosResponse } from 'axios';
import { ApiApiKeysListResponse } from '@/models/api';
import apiService from '@/services/apiService';

export default {
  async list(): Promise<ApiApiKeysListResponse> {
    const res: AxiosResponse<ApiApiKeysListResponse> = await axios.get(
      apiService.apiKey.list(),
    );
    return res.data;
  },
};
