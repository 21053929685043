
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { cloneDeep, pick } from 'lodash';
import { Field, Form } from 'vee-validate';
import {
  ApiSectorCreatePayload,
  ApiSectorDetailUpdatePayload,
  ApiSectorDetailsUpdatePayload,
} from '@/models/api';
import {
  Sector,
  SectorDetail,
  SectorDetailKeyType,
  SectorDetailKeyName,
} from '@/models/sector';
import sectorService from '@/services/sectorService';

type SectorFormModel = ApiSectorCreatePayload;

const DEFAULT_SECTOR_MODEL = {
  name: '',
  description: '',
  image: '',
  enabled: true,
} as SectorFormModel;

export default defineComponent({
  name: 'SettingsSectorsView',
  components: { Field, Form },
  data() {
    return {
      sectorModelId: 0,
      sectorFormModel: {} as SectorFormModel,
      sectorDialog: false,
      deleteSectorDialog: false,
      updatedSectorsDetailsId: [] as number[],
      offlineKeyNames: [] as Array<string>,
      onlineKeyNames: [] as Array<string>,
      keyTypes: [] as Array<string>,
      updatedSectors: [] as Sector[],
    };
  },
  async beforeMount(): Promise<void> {
    await this.loadData();
  },
  beforeUnmount(): void {
    this.updatedSectors.length = 0;
    this.sectorModelId = 0;
    this.sectorDialog = false;
    this.deleteSectorDialog = false;
    this.updatedSectorsDetailsId.length = 0;
    this.offlineKeyNames.length = 0;
    this.onlineKeyNames.length = 0;
    this.keyTypes.length = 0;
  },
  methods: {
    async loadData(): Promise<void> {
      await this.$store.dispatch('session/setSectors', true);
      this.updatedSectors = cloneDeep(this.$store.getters['session/sectors']);
      this.sectorModelId = 0;
      this.sectorFormModel = {} as SectorFormModel;
      this.sectorDialog = false;
      this.deleteSectorDialog = false;
      this.offlineKeyNames = Object.values(SectorDetailKeyName).filter(
          (el) => el !== SectorDetailKeyName.ITEM,
      );
      this.onlineKeyNames = Object.values(SectorDetailKeyName);
      this.keyTypes = Object.values(SectorDetailKeyType);
    },
    onSectorOpenCreateDialog(): void {
      this.sectorModelId = 0;
      this.sectorFormModel = cloneDeep(DEFAULT_SECTOR_MODEL);
      this.sectorDialog = true;
    },
    onSectorUpdate(sectorId: number): void {
      const sector = this.updatedSectors.find(
          (s: Sector) => s.header.id === sectorId,
      );
      if (!sector) {
        return;
      }
      this.sectorModelId = sector.header.id;
      this.sectorFormModel = cloneDeep(DEFAULT_SECTOR_MODEL);
      this.sectorFormModel = pick(
        sector.header,
        Object.keys(this.sectorFormModel),
      ) as unknown as SectorFormModel;
      this.sectorDialog = true;
    },
    async onSectorUpdateDetails(sectorId: number): Promise<void> {
      const sector = this.updatedSectors.find(
          (s: Sector) => s.header.id === sectorId,
      );
      if (!sector) {
        this.$spiagge.toast.error(
            this.$t('settingsSectorsView.toast.deleteError'),
        );
        return;
      }
      const indexToRemove = this.updatedSectorsDetailsId.indexOf(
        sector.header.id,
      );
      try {
        const mappedDetails = sector.details.map(
          (sectorDetail: SectorDetail) => {
            const detailUpdatePayload: ApiSectorDetailUpdatePayload = {
              id: sectorDetail.id,
              keyValue: sectorDetail.keyValue,
            };
            return detailUpdatePayload;
          },
        );
        const detailsPayload: ApiSectorDetailsUpdatePayload = {
          details: mappedDetails,
        };
        await sectorService.updateDetails(sector.header.id, detailsPayload);
        this.updatedSectorsDetailsId.splice(indexToRemove, 1);
        this.$spiagge.toast.success(
          this.$t('settingsSectorsView.toast.updateDetailsSuccess'),
        );
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('settingsSectorsView.toast.updateDetailsError'),
        );
      }
    },
    async onSectorDelete(sectorId: number): Promise<void> {
      const sector = this.updatedSectors.find(
        (s: Sector) => s.header.id === sectorId,
      );
      if (!sector) {
        this.$spiagge.toast.error(
            this.$t('settingsSectorsView.toast.deleteError'),
        );
        return;
      }
      this.sectorModelId = sector.header.id;
      this.$confirm.require({
        message: this.$t('settingsSectorsView.confirm.message', {
          n: sector.header.name,
        }),
        header: this.$t('settingsSectorsView.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-info p-button-outlined',
        acceptLabel: this.$t('button.delete'),
        rejectLabel: this.$t('button.cancel'),
        accept: async () => {
          try {
            await sectorService.delete(sector.header.id);
            this.$spiagge.toast.success(
              this.$t('settingsSectorsView.toast.deleteSuccess'),
            );
          } catch (e) {
            this.$spiagge.toast.error(
              this.$t('settingsSectorsView.toast.deleteError'),
            );
          } finally {
            this.loadData();
          }
        },
      });
    },
    async onSectorSubmit(): Promise<void> {
      this.sectorDialog = false;
      if (this.sectorModelId > 0) {
        try {
          await sectorService.update(this.sectorModelId, this.sectorFormModel);
          this.$spiagge.toast.success(
            this.$t('settingsSectorsView.toast.updateSuccess'),
          );
        } catch (e) {
          this.$spiagge.toast.error(
            this.$t('settingsSectorsView.toast.updateError'),
          );
        }
      } else {
        try {
          await sectorService.create(this.sectorFormModel);
          this.$spiagge.toast.success(
            this.$t('settingsSectorsView.toast.createSuccess'),
          );
        } catch (e) {
          this.$spiagge.toast.error(
            this.$t('settingsSectorsView.toast.createError'),
          );
        }
      }
      this.loadData();
    },
    getDetail(
      sectorId: number,
      keyName: string,
      keyType: string,
      type: string,
    ): SectorDetail | undefined {
      const sector = this.updatedSectors.find(
          (s: Sector) => s.header.id === sectorId,
      );
      if (!sector) {
        return undefined;
      }
      const r = sector.details.find(
        (sectorDetail: SectorDetail) =>
          sectorDetail.keyName === keyName &&
          sectorDetail.keyType === keyType &&
          sectorDetail.type === type,
      )!;
      return r;
    },
    onSectorDetailRowUpdated(
      sectorId: number,
    ): void {
      if (!this.updatedSectorsDetailsId.includes(sectorId)) {
        this.updatedSectorsDetailsId.push(sectorId);
      }
    },
    async onImageSet(event: any): Promise<void> {
      const data = new FormData();
      data.append('image', event.files[0]);
      // Uploading
      this.sectorFormModel.image = await sectorService.uploadImage(data);
    },
    getSectorCantBeDeletedMessage(): string {
      return this.$t('settingsSectorsView.sectorCantBeDeleted');
    },
  },
  computed: {
    ...mapState('session', ['sectors']),
  },
});
