import { DropdownOption } from '@/models/dropdownOption';
import i18n from '@/i18n';

export enum HalfDay {
    DAILY,
    MORNING,
    AFTERNOON,
}

export const HALF_DAY_OPTIONS: Array<DropdownOption<number>> = [
    {
        value: HalfDay.DAILY,
        label: i18n.global.t('extraServices.dialog.halfDayDaily'),
    },
    {
        value: HalfDay.MORNING,
        label: i18n.global.t('extraServices.dialog.halfDayMorning'),
    },
    {
        value: HalfDay.AFTERNOON,
        label: i18n.global.t('extraServices.dialog.halfDayAfternoon'),
    },
];
