import axios, { AxiosResponse } from 'axios';
import { ApiSpotFindPayload, ApiSpotFindResponse } from '@/models/api';
import apiService from './apiService';

export default {
  async find(payload: ApiSpotFindPayload): Promise<ApiSpotFindResponse> {
    const res: AxiosResponse<ApiSpotFindResponse> = await axios.get(
      apiService.spot.find(payload),
    );
    return res.data;
  },
};
