
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'TopBarChatBot',
  mounted() {
    // Importing the chatbot script
    document.addEventListener('widget-loaded', async (loadEvent) => {
      const customEvent = loadEvent as CustomEvent;
      const chatTrigger = document.getElementById('chat-trigger');
      // Closure callbacks for changing isWidgetOpen variable
      customEvent.detail.widget.on('close', async () => {
        await this.setChatBotStatus(false);
      });
      customEvent.detail.widget.on('open', async () => {
        await this.setChatBotStatus(true);
      });
      if (chatTrigger) {
        chatTrigger.addEventListener('click', () => {
          customEvent.detail.widget.setOpen(!this.chatBotOpen);
        });
      }
    });

    const chatBotScript = document.createElement('script');
    chatBotScript.setAttribute(
      'src',
      'https://app.indigo.ai/widget.js?token=1ea7c933-91b6-4d07-acde-8bec193ff124&v=2&trigger=off&fullpage=off',
    );
    chatBotScript.setAttribute('id', 'iaw-script');
    document.head.appendChild(chatBotScript);
  },
  methods: {
    async setChatBotStatus(status: boolean) {
      await this.$store.dispatch('session/setChatBotStatus', status);
    },
  },
  computed: {
    ...mapState('session', ['chatBotOpen']),
  },
});
