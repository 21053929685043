
import { PropType, defineComponent } from 'vue';
import { cloneDeep } from 'lodash';
import {
  RevenueManagementBaseRule,
  RevenueManagementOverrideRule,
} from '@/models/revenueManagement';
import RevenueManagementRule from '@/components/revenue-management/RevenueManagementRule.vue';

interface BaseRuleModel {
  threshold: number | null;
  value: number | null;
}

type Rule = RevenueManagementBaseRule | RevenueManagementOverrideRule;

export default defineComponent({
  name: 'RevenueManagementRules',
  emits: ['create', 'update', 'delete'],
  components: { RevenueManagementRule },
  props: {
    rules: {
      type: Array as PropType<Array<Rule>>,
      required: true,
    },
    thresholdLabel: {
      type: String,
      required: true,
    },
    valueLabel: {
      type: String,
      required: true,
    },
    addLabel: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      model: null as unknown as BaseRuleModel,
      addRule: false,
      editRule: null as unknown as RevenueManagementBaseRule,
      updateKey: 0,
    };
  },
  methods: {
    async onAddRule(): Promise<void> {
      this.addRule = true;
    },
    onEdit(rule: RevenueManagementBaseRule): void {
      this.editRule = rule;
    },
    onAbort(): void {
      this.addRule = false;
      this.editRule = null as unknown as RevenueManagementBaseRule;
    },
  },
  computed: {
    canAddRule(): boolean {
      return !this.addRule && this.editRule === null;
    },
    orderedRules(): Array<Rule> {
      return cloneDeep(this.rules).sort(
        (a: Rule, b: Rule) => a.applicationThreshold - b.applicationThreshold,
      );
    },
  },
  watch: {
    rules: {
      handler() {
        // console.log('changed!');
        this.onAbort();
        this.$nextTick(() => {
          this.updateKey += 1;
        });
      },
      deep: true,
    },
  },
});
