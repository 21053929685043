
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Slider',
  data() {
    return {
      isDown: false,
      startX: 0,
      scrollLeft: 0,
      slider: null as unknown as HTMLElement,
    };
  },

  mounted() {
    this.slider = this.$refs.slider as HTMLElement;
    this.slider.addEventListener('mousedown', this.start);
    this.slider.addEventListener('touchstart', this.start);
    this.slider.addEventListener('mousemove', this.move);
    this.slider.addEventListener('touchmove', this.move);
    this.slider.addEventListener('mouseleave', this.end);
    this.slider.addEventListener('mouseup', this.end);
    this.slider.addEventListener('touchend', this.end);
  },
  beforeUnmount() {
    this.slider.removeEventListener('mousedown', this.start);
    this.slider.removeEventListener('touchstart', this.start);
    this.slider.removeEventListener('mousemove', this.move);
    this.slider.removeEventListener('touchmove', this.move);
    this.slider.removeEventListener('mouseleave', this.end);
    this.slider.removeEventListener('mouseup', this.end);
    this.slider.removeEventListener('touchend', this.end);
  },
  methods: {
    end() {
      this.isDown = false;
      this.slider.classList.remove('active');
    },
    start(e: MouseEvent | TouchEvent) {
      this.isDown = true;
      this.slider.classList.add('active');
      this.startX =
        (e as MouseEvent).pageX ||
        (e as TouchEvent).touches[0].pageX - this.slider.offsetLeft;
      this.scrollLeft = this.slider.scrollLeft;
    },
    move(e: MouseEvent | TouchEvent) {
      if (!this.isDown) return;
      e.preventDefault();
      const x =
        (e as MouseEvent).pageX ||
        (e as TouchEvent).touches[0].pageX - this.slider.offsetLeft;
      const dist = x - this.startX;
      this.slider.scrollLeft = this.scrollLeft - dist;
    },
  },
});
