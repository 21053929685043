
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'InputTooltip',
  props: {
    message: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 14,
    },
  },
});
