
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import DocumentationUploader from '@/components/shared/DocumentationUploader.vue';

export default defineComponent({
  name: 'ReservationDocumentation',
  components: { DocumentationUploader },
  data() {
    return {};
  },
  computed: {
    ...mapState('reservation', ['id']),
    ...mapState('session', ['documentTemplates']),
  },
});
