
import { PropType, defineComponent } from 'vue';
import {
  RevenueManagementBaseRule as RevenueManagementBaseRuleI,
  RevenueManagementPeriod,
  RevenueManagementRuleCreateEmit,
  RevenueManagementRuleDeleteEmit,
  RevenueManagementRuleMode,
  RevenueManagementRuleType,
  RevenueManagementRuleUpdateEmit,
} from '@/models/revenueManagement';
import RevenueManagementRules from '@/components/revenue-management/RevenueManagementRules.vue';
import {
  ApiRevenueManagementBaseRuleCreatePayload,
  ApiRevenueManagementBaseRuleUpdatePayload,
} from '@/models/api';
import revenueManagementService from '@/services/revenueManagementService';

export default defineComponent({
  name: 'RevenueManagementPeriodPanel',
  emits: ['create', 'update', 'delete'],
  components: { RevenueManagementRules },
  props: {
    period: {
      type: Object as PropType<RevenueManagementPeriod>,
      required: true,
    },
    rules: {
      type: Array as PropType<Array<RevenueManagementBaseRuleI>>,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted(): void {
    // console.log(123);
  },
  methods: {
    async onCreate(payload: RevenueManagementRuleCreateEmit): Promise<void> {
      this.$store.commit('app/setProcessing', true);
      try {
        await revenueManagementService.baseRule.create({
          ruleType: RevenueManagementRuleMode.OCCUPANCY,
          applicationThreshold: payload.model.threshold,
          revenuePercentageValue: payload.model.value,
          periodId: this.period.id,
        } as ApiRevenueManagementBaseRuleCreatePayload);
        this.$spiagge.toast.success(
          this.$t('revenueManagementPeriodPanel.toast.createSuccess'),
        );
        payload.successCb();
        payload.successCb();
        payload.successCb();
        payload.successCb();
        this.$emit('create');
      } catch (error) {
        const errorCode: string = (error as any).response.data.error;
        let errMessage = this.$t('revenueManagementPeriodPanel.errorMessage');
        if (['i_rev_mgmt_base_rule_001'].includes(errorCode)) {
          errMessage = this.$t(`errors.${errorCode}`);
        }
        this.$spiagge.toast.error(errMessage);
      } finally {
        this.$store.commit('app/setProcessing', false);
      }
    },
    async onUpdate(payload: RevenueManagementRuleUpdateEmit): Promise<void> {
      this.$store.commit('app/setProcessing', true);
      try {
        await revenueManagementService.baseRule.update(payload.rule.id, {
          ruleType: RevenueManagementRuleMode.OCCUPANCY,
          applicationThreshold: payload.model.threshold,
          revenuePercentageValue: payload.model.value,
        } as ApiRevenueManagementBaseRuleUpdatePayload);
        this.$spiagge.toast.success(
          this.$t('revenueManagementPeriodPanel.toast.editSuccess'),
        );
        this.$emit('update');
        payload.successCb();
      } catch (error) {
        const errorCode: string = (error as any).response.data.error;
        let errMessage = this.$t('revenueManagementPeriodPanel.errorMessage');
        if (['i_rev_mgmt_base_rule_001'].includes(errorCode)) {
          errMessage = this.$t(`errors.${errorCode}`);
        }
        this.$spiagge.toast.error(errMessage);
      } finally {
        this.$store.commit('app/setProcessing', false);
      }
    },
    async onDelete(payload: RevenueManagementRuleDeleteEmit): Promise<void> {
      try {
        await revenueManagementService.baseRule.delete(payload.rule.id);
        this.$spiagge.toast.success(
          this.$t('revenueManagementPeriodPanel.toast.deleteSuccess'),
        );
        this.$emit('delete');
        payload.successCb();
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('revenueManagementPeriodPanel.toast.deleteError'),
        );
      }
    },
  },
  computed: {
    title(): string {
      let title = `${this.$t('revenueManagementPeriodPanel.period')} `;
      if (this.period.start === null) {
        title += this.$t('revenueManagementPeriodPanel.lessThanOneDay');
      } else {
        title += `${this.$t('revenueManagementPeriodPanel.from')} ${
          this.period.start
        } ${this.$t('revenueManagementPeriodPanel.to')} ${
          this.period.end
        } ${this.$t('revenueManagementPeriodPanel.days')}`;
      }
      return title;
    },
    description(): string {
      let description = `${this.$t('revenueManagementPeriodPanel.editRules')} `;
      if (this.period.start === null) {
        description += `${this.$t('revenueManagementPeriodPanel.within')} ${
          this.period.end
        } ${this.$t('revenueManagementPeriodPanel.fromCurrentDay')}`;
      } else {
        description += `${this.$t('revenueManagementPeriodPanel.from')} ${
          this.period.start
        } ${this.$t('revenueManagementPeriodPanel.to')} ${
          this.period.end
        } ${this.$t('revenueManagementPeriodPanel.fromCurrentDay')}`;
      }
      return description;
    },
  },
});
