
import { defineComponent } from 'vue';
import { Field, Form } from 'vee-validate';
import { License, LicenseType } from '@/models/license';
import UploadImg from '@/components/shared/UploadImg.vue';
import licenseService from '@/services/licenseService';
import toastUtil from '@/utils/toastUtil';
import { ToastSeverity } from '@/models/toast';

export default defineComponent({
  components: { Field, Form, UploadImg },
  name: 'SettingsView',
  data() {
    return {
      extension: '.png',
      size: 1000000,
      clear: false,
      localLicense: {} as License,
      urlImg: '',
      nameFile: '',
    };
  },
  methods: {
    async onSave() {
      this.clear = true;
      try {
        const response = await licenseService.update(
          this.localLicense.license,
          this.localLicense,
        );
        this.localLicense.name = response.result.license.name;
        if (this.license) {
          this.$store.commit('session/setLicense', response.result.license);
        }
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async catchFile(file: any) {
      const data = new FormData();
      data.append('logoImage', file);
      if (this.license) {
        try {
          await licenseService.logo(this.license?.license, data);
        } catch (error) {
          // TODO
        }
      }
    },
  },
  computed: {
    loading(): boolean {
      return this.$store.getters['app/loading'];
    },
    license(): License | null {
      return this.$store.getters['session/license'];
    },
    canEdit(): boolean {
      return this.license?.licenseType !== LicenseType.DEMO;
    },
  },
  async beforeMount() {
    if (this.license) {
      await this.$store.dispatch('session/setLicense', this.license.license);
      this.localLicense.name = this.license.name;
      this.localLicense.license = this.license.license;
      this.localLicense.licenseType = this.license.licenseType;
    }
  },
});
