
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { RouteLocationNormalized } from 'vue-router';
import PlannerHeader from '@/components/planner/PlannerHeader.vue';
import Planner from '@/components/planner/Planner.vue';
import PlannerTooltips from '@/components/planner/PlannerTooltips.vue';
import cookieUtil from '@/utils/cookieUtil';
import { PlannerMode } from '@/models/planner';
import { useLocalStorage } from '@/composables/useLocalStorage';
import sectorService from '@/services/sectorService';

export default defineComponent({
  name: 'PlannerView',
  components: {
    PlannerHeader,
    Planner,
    PlannerTooltips,
  },
  data() {
    return {
      showExitDialog: false,
      to: null as RouteLocationNormalized | null,
    };
  },
  setup() {
    const plannerLocalStorage = useLocalStorage<number[]>(
      'planner-sector-filter',
    );
    return { plannerLocalStorage };
  },
  computed: {
    ...mapState('app', ['windowWidth', 'windowHeight']),
    ...mapState('planner', ['data', 'year', 'mode']),
    pageWidth(): string {
      const width =
        this.windowWidth >= 992 ? this.windowWidth - 80 : this.windowWidth;
      return `${width}px`;
    },
    pageHeight(): string {
      return `${this.windowHeight - 64}px`;
    },
  },
  methods: {
    async loadData(): Promise<void> {
      try {
        await this.$store.dispatch('planner/loadData');
      } catch (e) {
        this.$spiagge.toast.error(this.$t('planner.toast.retrievalError'));
      }
    },
    onExit(to: RouteLocationNormalized): boolean {
      if (to.name === 'ReservationEdit') {
        this.$store.commit('app/setReturnToRoute', this.$route.path);
        if (this.mode === PlannerMode.NEW) {
          return true;
        }
      }
      if (this.mode !== PlannerMode.VIEW && !this.to) {
        this.to = to;
        this.showExitDialog = true;
        return false;
      }
      return true;
    },
    exit(): void {
      if (!this.to) return;
      this.$router.push(this.to);
    },
    cancelExit(): void {
      this.showExitDialog = false;
      this.to = null;
    },
    setVisibleSectors(): void {
      // check from localStorage
      const valueFromLocalStorage = this.plannerLocalStorage.getItem();
      if (Array.isArray(valueFromLocalStorage)) {
        this.$store.commit(
          'planner/setVisibleSectorIds',
          valueFromLocalStorage,
        );
        return;
      }

      // if no value is already present in localStorage,
      // then no sector is "filtered"
      const sectorIds: number[] = [];
      this.$store.commit('planner/setVisibleSectorIds', sectorIds);
      this.plannerLocalStorage.setItem(sectorIds);
    },
    setSectorIds(sectorIds: Array<number>): void {
      this.$store.commit(
        'planner/setSectorIds',
        sectorIds,
      );
    },
  },
  async beforeMount() {
    const zoom = Number(cookieUtil.get('spit_pl_z') ?? 100);
    this.$store.commit('planner/setZoom', zoom);
    // Load only visible sectors
    this.setVisibleSectors();
    const sectorIds = await sectorService.getPlannerSectors();
    this.setSectorIds(sectorIds);

    await this.loadData();
  },

  unmounted() {
    this.$store.commit('planner/reset');
  },
  beforeRouteLeave(to): boolean {
    return this.onExit(to);
  },
  beforeRouteUpdate(to): boolean {
    return this.onExit(to);
  },
});
