
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
import StatisticsCard from '@/components/statistics/StatisticsCard.vue';
import StatisticsDoughnutChart from '@/components/statistics/charts/StatisticsDoughnutChart.vue';
import StatisticsLineChart from '@/components/statistics/charts/StatisticsLineChart.vue';
import {
  ApiStatisticsDashboardExportPayload,
  ApiStatisticsDashboardPayload,
  ApiStatisticsDashboardResponse,
} from '@/models/api';
import statisticService from '@/services/statisticService';
import {
  StatisticCard,
  StatisticDoughnutChartModel,
  StatisticLineChartModel,
} from '@/models/statistic';
import fileUtil from '@/utils/fileUtil';

export default defineComponent({
  name: 'StatisticsDashboardView',
  components: { StatisticsCard, StatisticsDoughnutChart, StatisticsLineChart },
  data() {
    return {
      exporting: false,
      cardsLeft: [
        {
          label: this.$t('statisticsDashboardView.cardLeft.arrivals.label'),
          value: null,
          tooltip: this.$t('statisticsDashboardView.cardLeft.arrivals.tooltip'),
        },
        {
          label: this.$t('statisticsDashboardView.cardLeft.departures.label'),
          value: null,
          tooltip: this.$t(
            'statisticsDashboardView.cardLeft.departures.tooltip',
          ),
        },
        {
          label: this.$t(
            'statisticsDashboardView.cardLeft.cancellations.label',
          ),
          value: null,
          tooltip: this.$t(
            'statisticsDashboardView.cardLeft.cancellations.tooltip',
          ),
        },
        {
          label: this.$t(
            'statisticsDashboardView.cardLeft.occupancyRate.label',
          ),
          value: null,
          tooltip: this.$t(
            'statisticsDashboardView.cardLeft.occupancyRate.tooltip',
          ),
        },
      ] as Array<StatisticCard>,
      cardsRight: [
        {
          label: this.$t('statisticsDashboardView.cardRight.sales.label'),
          value: null,
          tooltip: this.$t('statisticsDashboardView.cardRight.sales.tooltip'),
          link: '/statistics/cash-flows',
        },
        {
          label: this.$t('statisticsDashboardView.cardRight.attendance.label'),
          value: null,
          tooltip: this.$t(
            'statisticsDashboardView.cardRight.attendance.tooltip',
          ),
        },
        {
          label: this.$t('statisticsDashboardView.cardRight.discounts.label'),
          value: null,
          tooltip: this.$t(
            'statisticsDashboardView.cardRight.discounts.tooltip',
          ),
        },
        {
          label: this.$t('statisticsDashboardView.cardRight.averageCart.label'),
          value: null,
          tooltip: this.$t(
            'statisticsDashboardView.cardRight.averageCart.tooltip',
          ),
        },
      ] as Array<StatisticCard>,
      doughnutCharts: [
        {
          label: this.$t(
            'statisticsDashboardView.doughnutChart.payments.label',
          ),
          chartData: {
            labels: [
              this.$t(
                'statisticsDashboardView.doughnutChart.payments.paidLabel',
              ),
              this.$t(
                'statisticsDashboardView.doughnutChart.payments.partiallyPaidLabel',
              ),
              this.$t(
                'statisticsDashboardView.doughnutChart.payments.unpaidLabel',
              ),
            ],
            datasets: [
              {
                data: [],
                backgroundColor: ['#0083E8', '#00B0FF', '#96DFFF'],
              },
            ],
          },
        },
        {
          label: this.$t('statisticsDashboardView.doughnutChart.channel.label'),
          chartData: {
            labels: [
              this.$t(
                'statisticsDashboardView.doughnutChart.channel.offlineLabel',
              ),
              this.$t(
                'statisticsDashboardView.doughnutChart.channel.widgetLabel',
              ),
              this.$t(
                'statisticsDashboardView.doughnutChart.channel.portalLabel',
              ),
              this.$t('statisticsDashboardView.doughnutChart.channel.apiLabel'),
              this.$t(
                'statisticsDashboardView.doughnutChart.channel.kioskLabel',
              ),
            ],
            datasets: [
              {
                data: [],
                backgroundColor: [
                  '#E89300',
                  '#FFC100',
                  '#FFE596',
                  '#B32D00',
                  '#9C27B0',
                ],
              },
            ],
          },
        },
      ] as Array<StatisticDoughnutChartModel>,
      lineCharts: [
        {
          label: this.$t('statisticsDashboardView.lineChart.attendance.label'),
          tooltip: this.$t(
            'statisticsDashboardView.lineChart.attendance.tooltip',
          ),
          chartData: {
            labels: [],
            datasets: [
              {
                label: this.$t(
                  'statisticsDashboardView.lineChart.attendance.peopleLabel',
                ),
                data: [],
                backgroundColor: '#42A5F5',
                borderColor: '#42A5F5',
                tension: 0,
              },
              {
                label: this.$t(
                  'statisticsDashboardView.lineChart.attendance.umbrellasLabel',
                ),
                data: [],
                backgroundColor: '#FFA726',
                borderColor: '#FFA726',
                tension: 0,
              },
            ],
          },
        },
        {
          label: this.$t(
            'statisticsDashboardView.lineChart.arrivalsDepartures.label',
          ),
          tooltip: this.$t(
            'statisticsDashboardView.lineChart.arrivalsDepartures.tooltip',
          ),
          chartData: {
            labels: [],
            datasets: [
              {
                label: this.$t(
                  'statisticsDashboardView.lineChart.arrivalsDepartures.arrivalsLabel',
                ),
                data: [],
                backgroundColor: '#42A5F5',
                borderColor: '#42A5F5',
                tension: 0,
              },
              {
                label: this.$t(
                  'statisticsDashboardView.lineChart.arrivalsDepartures.departuresLabel',
                ),
                data: [],
                backgroundColor: '#FFA726',
                borderColor: '#FFA726',
                tension: 0,
              },
            ],
          },
        },
      ] as Array<StatisticLineChartModel>,
    };
  },
  beforeMount(): void {
    this.$store.commit(
      'statistic/setTitle',
      this.$t('statisticsDashboardView.setTitle'),
    );
    this.runQuery();
  },
  methods: {
    async runQuery(): Promise<void> {
      const payload = {
        startDate: this.calendar[0].toSeconds(),
        endDate: this.calendar[1].toSeconds(),
      } as ApiStatisticsDashboardPayload;

      try {
        const res: ApiStatisticsDashboardResponse =
          await statisticService.dashboard(payload);
        // left
        this.cardsLeft[0].value = Math.round(
          res.result.cards.arrivals,
        ).toString();
        this.cardsLeft[1].value = Math.round(
          res.result.cards.departures,
        ).toString();
        this.cardsLeft[2].value = res.result.cards.cancellations[0].toString();
        this.cardsLeft[2].subValue = `/${res.result.cards.cancellations[1].toLocaleString()}€`;
        this.cardsLeft[3].value = `${res.result.cards.occupationRate.toString()}%`;
        // right
        this.cardsRight[0].value = `${res.result.cards.revenue.toLocaleString()}€`;
        this.cardsRight[1].value = Math.round(
          res.result.cards.attendances,
        ).toString();
        this.cardsRight[2].value = res.result.cards.discounts[0].toString();
        this.cardsRight[2].subValue = `/${res.result.cards.discounts[1].toLocaleString()}€`; // ???
        this.cardsRight[3].value = `${res.result.cards.mediumChart.toLocaleString()}€`;
        // doughnut charts
        this.doughnutCharts[0].chartData.datasets[0].data =
          res.result.charts.reservationsByPaymentStatus;
        this.doughnutCharts[1].chartData.datasets[0].data =
          res.result.charts.reservationsByChannel;
        // line charts
        const labels = [] as Array<string>;
        let currentDay = this.calendar[0].toSeconds();
        const endDay = this.calendar[1].toSeconds();
        while (currentDay <= endDay) {
          labels.push(DateTime.fromSeconds(currentDay).toFormat('dd/MM'));
          currentDay += 86400;
        }

        this.lineCharts[0].chartData.labels = labels;
        this.lineCharts[0].chartData.datasets[0].data =
          res.result.charts.attendances[0];
        this.lineCharts[0].chartData.datasets[1].data =
          res.result.charts.attendances[1];

        this.lineCharts[1].chartData.labels = labels;
        this.lineCharts[1].chartData.datasets[0].data =
          res.result.charts.arrivalsDepartures[0];
        this.lineCharts[1].chartData.datasets[1].data =
          res.result.charts.arrivalsDepartures[1];
      } catch (e) {
        // console.log
      }
    },
    async onExport(): Promise<void> {
      this.exporting = true;

      try {
        const payload = {
          startDate: this.calendar[0].toSeconds(),
          endDate: this.calendar[1].toSeconds(),
        } as ApiStatisticsDashboardExportPayload;

        const res: Blob = await statisticService.exportDashboard(payload);
        fileUtil.download(
          res,
          `dashboard-export-${Math.round(DateTime.now().toSeconds())}.csv`,
        );
        this.$spiagge.toast.success(
          this.$t('statisticsDashboardView.toast.exportSuccess'),
        );
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('statisticsDashboardView.toast.exportError'),
        );
      } finally {
        this.exporting = false;
      }
    },
  },
  computed: {
    ...mapState('statistic', ['calendar']),
  },
  watch: {
    calendar() {
      if (this.calendar[1] === null) return;
      this.runQuery();
    },
  },
});
