import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "revenue-management-view",
  class: "view"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LayoutFullContent = _resolveComponent("LayoutFullContent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_LayoutFullContent, null, {
      content: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    })
  ]))
}