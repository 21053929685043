import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex-column" }
const _hoisted_2 = { class: "mt-3 ml-2 mr-2" }
const _hoisted_3 = { class: "csv-options" }
const _hoisted_4 = { class: "mt-3 ml-2 mr-2 flex-column" }
const _hoisted_5 = { class: "mt-3 ml-2 mr-2 flex-column" }
const _hoisted_6 = { class: "mt-3 ml-2 mr-2 flex-column" }
const _hoisted_7 = { class: "mt-3 mb-3" }
const _hoisted_8 = { class: "csv-options" }
const _hoisted_9 = { class: "flex-card" }
const _hoisted_10 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$t('exportView.title')), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('exportView.filterByDate')) + ":", 1),
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.filtering.filterByDate,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filtering.filterByDate) = $event)),
            options: _ctx.dateFilter,
            optionLabel: "label",
            optionValue: "value",
            style: {"width":"200px"}
          }, null, 8, ["modelValue", "options"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('exportView.filterByContact')) + ":", 1),
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.filtering.filterByContact,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filtering.filterByContact) = $event)),
            options: _ctx.contactsFilter,
            optionLabel: "label",
            optionValue: "value",
            style: {"width":"200px"}
          }, null, 8, ["modelValue", "options"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('exportView.filterByBooking')) + ":", 1),
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.filtering.filterByBooking,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filtering.filterByBooking) = $event)),
            options: _ctx.bookingFilter,
            optionLabel: "label",
            optionValue: "value",
            style: {"width":"200px"}
          }, null, 8, ["modelValue", "options"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.csvOptions, (element) => {
          return (_openBlock(), _createElementBlock("div", { key: element }, [
            _createVNode(_component_Card, {
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onExportCSV())),
              class: "ml-2 mr-2 mt-3 mb-3 card-effect"
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("img", {
                    alt: "csv-options",
                    src: element.image,
                    class: "mt-3 mb-3 mr-3 ml-3"
                  }, null, 8, _hoisted_10),
                  _createElementVNode("h6", null, _toDisplayString(element.name), 1)
                ])
              ]),
              _: 2
            }, 1024)
          ]))
        }), 128))
      ])
    ])
  ]))
}