import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ed947e32"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "planner-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PlannerHeader = _resolveComponent("PlannerHeader")!
  const _component_Planner = _resolveComponent("Planner")!
  const _component_PlannerTooltips = _resolveComponent("PlannerTooltips")!

  return (_ctx.data)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        id: "planner-view",
        style: _normalizeStyle({ width: _ctx.pageWidth, height: _ctx.pageHeight })
      }, [
        _createVNode(_component_PlannerHeader, { year: _ctx.year }, null, 8, ["year"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Planner)
        ]),
        _createVNode(_component_PlannerTooltips, {
          showExitDialog: _ctx.showExitDialog,
          onExit: _ctx.exit,
          onCancelExit: _ctx.cancelExit
        }, null, 8, ["showExitDialog", "onExit", "onCancelExit"])
      ], 4))
    : _createCommentVNode("", true)
}