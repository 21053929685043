
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { clone, orderBy } from 'lodash';
import { DateTime } from 'luxon';
import { ReservationType } from '@/models/reservation';
import reservationService from '@/services/reservationService';
import {
  ApiReservationFindPayload,
  ApiReservationFindResponse,
} from '@/models/api';
import spotUtil from '@/utils/spotUtil';
import { SpotType } from '@/models/spot';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

enum Filter {
  FUTURE,
  PAST,
}

interface Reservation {
  id: number;
  firstName: string | null;
  lastName: string | null;
  endDate: number;
  startDate: number;
  paid: boolean;
}

export default defineComponent({
  name: 'SpotDialog',
  data() {
    return {
      loading: false,
      filters: clone(Filter),
      filter: Filter.FUTURE,
      reservations: [] as Array<Reservation>,
    };
  },
  methods: {
    async loadReservations(): Promise<void> {
      this.loading = true;
      const type: Array<ReservationType> = [ReservationType.STANDARD];
      if (
        [SpotType.PARKING, SpotType.CABIN].includes(this.spotDialog.spotType)
      ) {
        type.push(ReservationType.ADDITION);
      }
      const payload = {
        spotName: this.spotDialog.spotName,
        spotType: this.spotDialog.spotType,
        fields: ['id', 'startDate', 'endDate', 'paid', 'firstName', 'lastName'],
        type,
        deleted: false,
      } as ApiReservationFindPayload;
      try {
        const res: ApiReservationFindResponse = await reservationService.find(
          payload,
        );
        this.reservations = res.result
          .reservations as unknown as Array<Reservation>;
      } catch (e) {
        this.$spiagge.toast.error(this.$t('spotDialog.toast.error'));
      } finally {
        this.loading = false;
      }
    },
    onCreateReservation(): void {
      this.spotDialog.callback();
      this.show = false;
    },
    onReservationClick(reservation: Reservation): void {
      this.$router.push(`/reservation/${reservation.id}`);
      this.show = false;
    },
    reset(): void {
      this.filter = this.filters.FUTURE;
    },
  },
  computed: {
    ...mapState('app', ['spotDialog']),
    ...mapState('session', ['permissions']),
    show: {
      get(): boolean {
        return !!this.spotDialog.spotName && !!this.spotDialog.spotType;
      },
      set(show: boolean): void {
        if (!show) {
          this.$store.commit('app/resetSpotDialog');
        }
      },
    },
    title(): string {
      let title = this.spotName;
      if (this.spotDialog.spotType === SpotType.PLAYFIELD) {
        title = spotUtil.getPlayFieldDataByName(title).name;
      }
      return title;
    },
    subtitle(): string {
      let subtitle = '';
      if (this.spotDialog.spotType === SpotType.PLAYFIELD) {
        subtitle = spotUtil.getPlayFieldDataByName(
          this.spotDialog.spotName,
        ).timeSlot;
      }
      return subtitle;
    },
    spotName(): string {
      return `${spotUtil.getLabel(this.spotDialog.spotType)} ${
        this.spotDialog.spotName
      }`;
    },
    spotIcon(): string {
      // eslint-disable-next-line import/no-dynamic-require
      return require(`@/assets/images/map/${this.spotDialog.spotType}/grey.svg`);
    },
    reservationsInProgress(): Array<Reservation> {
      return this.reservations.filter(
        (res: Reservation) =>
          DateTime.now().startOf('day').toSeconds() >= res.startDate &&
          DateTime.now().startOf('day').toSeconds() <= res.endDate,
      );
    },
    reservationsFuture(): Array<Reservation> {
      return orderBy(
        this.reservations.filter(
          (res: Reservation) =>
            DateTime.now().startOf('day').toSeconds() < res.startDate &&
            DateTime.now().startOf('day').toSeconds() < res.endDate,
        ),
        (o) => [o.endDate],
      );
    },
    reservationsPast(): Array<Reservation> {
      return orderBy(
        this.reservations.filter(
          (res: Reservation) =>
            DateTime.now().startOf('day').toSeconds() > res.endDate,
        ),
        (o) => [o.startDate],
      ).reverse();
    },
    canCreate(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.map,
        FEATURE_PERMISSION_ACTION_CONFIG.map.ADD_UPDATE_RESERVATIONS,
      );
    },
  },
  watch: {
    show(value: boolean) {
      if (value) {
        this.loadReservations();
      } else {
        this.reset();
      }
    },
  },
});
