import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bb6428c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w-full" }
const _hoisted_2 = { class: "flex justify-content-between align-items-center" }
const _hoisted_3 = {
  key: 0,
  class: "w-full flex justify-content-center"
}
const _hoisted_4 = { class: "mb-2" }
const _hoisted_5 = { class: "font-bold mr-2" }
const _hoisted_6 = {
  key: 0,
  class: "grid mb-2",
  id: "invoice-dialog-info"
}
const _hoisted_7 = {
  key: 1,
  id: "invoice-rows",
  class: "flex flex-column w-full"
}
const _hoisted_8 = { class: "flex-grow-1 overflow-auto" }
const _hoisted_9 = {
  id: "invoice-dialog-total",
  class: "flex justify-content-end flex-shrink-0"
}
const _hoisted_10 = { class: "flex justify-content-end mt-2" }
const _hoisted_11 = { class: "flex justify-content-end my-2" }
const _hoisted_12 = { class: "flex justify-content-end mb-2 font-bold total" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_ShowInvoiceDialogInfo = _resolveComponent("ShowInvoiceDialogInfo")!
  const _component_ShowInvoiceDialogDataTable = _resolveComponent("ShowInvoiceDialogDataTable")!
  const _component_ShowInvoiceDialogCards = _resolveComponent("ShowInvoiceDialogCards")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_TabView = _resolveComponent("TabView")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    id: "show-invoice-dialog",
    ref: "dialogRef",
    modal: true,
    visible: true,
    closable: false
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t(
                'statisticsStripeTransfersView.showInvoiceDialog.thirdPartyInvoice',
              )), 1)
        ])
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-outlined",
        disabled: _ctx.isExporting,
        label: _ctx.$t('button.close'),
        icon: "spi-times",
        onClick: _ctx.reset
      }, null, 8, ["disabled", "label", "onClick"])
    ]),
    default: _withCtx(() => [
      (_ctx.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_ProgressSpinner)
          ]))
        : _createCommentVNode("", true),
      (!_ctx.isLoading && _ctx.invoice)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('statisticsStripeTransfersView.showInvoiceDialog.invoiceDate')), 1),
              _createElementVNode("span", null, _toDisplayString(_ctx.invoice.invoiceDate), 1)
            ]),
            (_ctx.invoice && _ctx.invoice.customer && _ctx.invoice.supplier)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_ShowInvoiceDialogInfo, {
                    "business-details": _ctx.invoice.customer,
                    title: 
            _ctx.$t('statisticsStripeTransfersView.showInvoiceDialog.customer')
          
                  }, null, 8, ["business-details", "title"]),
                  _createVNode(_component_ShowInvoiceDialogInfo, {
                    "business-details": _ctx.invoice.supplier,
                    title: 
            _ctx.$t('statisticsStripeTransfersView.showInvoiceDialog.supplier')
          
                  }, null, 8, ["business-details", "title"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.invoice)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_component_TabView, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_TabPanel, {
                          header: 
                _ctx.$t(
                  'statisticsStripeTransfersView.showInvoiceDialog.shortInvoice',
                )
              
                        }, {
                          default: _withCtx(() => [
                            (!_ctx.isMobile)
                              ? (_openBlock(), _createBlock(_component_ShowInvoiceDialogDataTable, {
                                  key: 0,
                                  "is-loading": _ctx.isLoading,
                                  rows: _ctx.invoice.aggregatedRows,
                                  "show-link-column": false,
                                  onGoToReservation: _ctx.goToReservation
                                }, null, 8, ["is-loading", "rows", "onGoToReservation"]))
                              : (_openBlock(), _createBlock(_component_ShowInvoiceDialogCards, {
                                  key: 1,
                                  rows: _ctx.invoice.aggregatedRows,
                                  "show-link-column": false,
                                  onGoToReservation: _ctx.goToReservation
                                }, null, 8, ["rows", "onGoToReservation"]))
                          ]),
                          _: 1
                        }, 8, ["header"]),
                        _createVNode(_component_TabPanel, {
                          header: 
                _ctx.$t(
                  'statisticsStripeTransfersView.showInvoiceDialog.longInvoice',
                )
              
                        }, {
                          default: _withCtx(() => [
                            (!_ctx.isMobile)
                              ? (_openBlock(), _createBlock(_component_ShowInvoiceDialogDataTable, {
                                  key: 0,
                                  "is-loading": _ctx.isLoading,
                                  rows: _ctx.invoice.rows,
                                  "show-link-column": "",
                                  onGoToReservation: _ctx.goToReservation
                                }, null, 8, ["is-loading", "rows", "onGoToReservation"]))
                              : (_openBlock(), _createBlock(_component_ShowInvoiceDialogCards, {
                                  key: 1,
                                  rows: _ctx.invoice.rows,
                                  "show-link-column": "",
                                  onGoToReservation: _ctx.goToReservation
                                }, null, 8, ["rows", "onGoToReservation"]))
                          ]),
                          _: 1
                        }, 8, ["header"])
                      ]),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", null, [
                      _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t(
                  'statisticsStripeTransfersView.showInvoiceDialog.totalWithoutVat',
                )) + " : " + _toDisplayString(_ctx.currencyUtil.format(_ctx.invoice.totalWithoutVat)), 1),
                      _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('statisticsStripeTransfersView.showInvoiceDialog.vatTotal')) + " : " + _toDisplayString(_ctx.currencyUtil.format(_ctx.invoice.vatTotal)), 1),
                      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('statisticsStripeTransfersView.showInvoiceDialog.total')) + " : " + _toDisplayString(_ctx.currencyUtil.format(_ctx.invoice.total)), 1)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 512))
}