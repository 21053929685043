
import { defineComponent, PropType } from 'vue';
import { cloneDeep, round } from 'lodash';
import { DateTime } from 'luxon';
import { Card, Transaction, TransactionPayload } from '@/models/card';
import { ListElementNumber, PaymentMethod } from '@/models';
import cardService from '@/services/cardService';
import {
  Print,
  PrintArticle,
  Printer,
  PrintMode,
  PrintPaymentMethod,
} from '@/models/printer';
import printerService from '@/services/printerService';
import { License } from '@/models/license';
import {
  ApiCardAstoriaOperationsPayload,
  ApiCardDetailsResponse,
  ApiLogCreatePayload,
} from '@/models/api';
import { CardOperationAstoria, SpecialLicense } from '@/enum';
import CalendarInput from '@/components/shared/CalendarInput.vue';
import { CashFlowMethod } from '@/models/cashFlow';
import { LogAction } from '@/models/log';
import logService from '@/services/logService';

const CARD_MODEL_DEFAULT = {
  idWorldCard: 0,
  name: '',
  cardType: 0,
  number: '',
  createdAt: 0,
  expirationDate: 0,
  firstName: '',
  lastName: '',
  email: '',
  phoneAreaCode: '',
  phoneNumber: '',
  balance: 0,
  deleted: 0,
  disabled: 0,
  billingData: 0,
  cardTypeName: '',
  cardImage: '',
  exchangeRate: '',
} as unknown as Card;

const TRANSACTION_PAYLOAD_MODEL_DEFAULT = {
  idWorldTransaction: 0,
  emitter: 0,
  paymentMethod: 0,
  amount: 0,
  appliedPrice: 0,
  idPrinter: 0,
} as unknown as TransactionPayload;

const CARD_ASTORIA_PAYLOAD = {
  emitter: 6,
  paymentMethod: PaymentMethod.CASH,
  amount: 0,
  appliedPrice: 0,
  printer: 1,
  notes: '',
  cardType: 1,
  hasUmbrella: false,
  beds: 0,
  exchangeRate: 1,
  date: DateTime.now().startOf('day').toSeconds(),
} as unknown as ApiCardAstoriaOperationsPayload;

interface PanelStates {
  [key: string]: boolean;
}

export default defineComponent({
  components: { CalendarInput },
  emits: ['destroy', 'cardEdit', 'closeDialog', 'updateCard'],
  props: {
    card: {
      type: Object as PropType<Card>,
      required: true,
    },
    canDialogVisibile: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      cardsDetailsDialog: this.canDialogVisibile,
      rechargeCardDialog: false,
      isCardEdit: false,
      loadingTransaction: true,
      printers: [] as Array<Printer>,
      cardTransactions: [] as Array<TransactionPayload>,
      transactions: [] as Array<Transaction>,
      cardPayAstoriaPayload: cloneDeep(CARD_ASTORIA_PAYLOAD),
      cardCashbackAstoriaPayload: cloneDeep(CARD_ASTORIA_PAYLOAD),
      paymentMethod: [
        { name: this.$t('cardsView.cash'), code: PaymentMethod.CASH },
        { name: this.$t('cardsView.credit'), code: PaymentMethod.CREDIT_CARD },
      ] as Array<ListElementNumber>,
      cardsViewTable: [
        this.$t('cardsView.tableNumber'),
        this.$t('cardsView.tableType'),
        this.$t('cardsView.tableFullName'),
        this.$t('cardsView.tableEmail'),
        this.$t('cardsView.tableBalance'),
      ],
      transactionTable: [
        this.$t('cardsView.transactionsDate'),
        this.$t('cardsView.transactionsType'),
        this.$t('cardsView.transactionsTotal'),
      ],
      panelStates: {
        pay: true,
        cashback: true,
      } as PanelStates,
      localCard: this.card,
      cardOperation: {} as ApiCardAstoriaOperationsPayload,
      transactionPayload: cloneDeep(TRANSACTION_PAYLOAD_MODEL_DEFAULT),
    };
  },
  computed: {
    license(): License {
      return this.$store.getters['session/license'];
    },
    showDialog: {
      get(): boolean {
        return this.cardsDetailsDialog;
      },
      set(value: boolean) {
        if (!value) {
          this.$emit('closeDialog');
        }
        this.cardsDetailsDialog = value;
      },
    },
    cashbackDate: {
      get(): DateTime {
        return DateTime.fromSeconds(this.cardCashbackAstoriaPayload.date || 0);
      },
      set(value: DateTime): void {
        this.cardCashbackAstoriaPayload.date = value.toSeconds();
      },
    },
    canSeeCustomComp(): boolean {
      return ['demo1', 'demo2', SpecialLicense.ASTORIA_CENTER].includes(
        this.license.license,
      );
    },
  },
  methods: {
    cardEdit() {
      this.$emit('cardEdit');
    },
    undoTransaction() {
      this.rechargeCardDialog = false;
    },
    getCardImageSource(card: Card) {
      if (!card.cardImage) {
        return require('@/assets/images/placeholders/card.svg');
      }
      return this.cardsUrlControl(card.cardImage);
    },
    cardsUrlControl(url: string): string {
      const check = url.includes('http');
      let result = url;
      if (!check) {
        result = `https://www.anm22.it/app/pay/utilities/img/cards/${url}`;
      }
      return result;
    },
    getDateString(timestamp: number): string {
      if (timestamp) {
        return DateTime.fromSeconds(timestamp)
          .setZone('Europe/Rome')
          .toFormat('dd/MM/yyyy HH:mm:ss');
      }
      return '-';
    },
    collapsePanel(name: string) {
      // eslint-disable-next-line no-param-reassign
      this.panelStates[name] = !this.panelStates[name];
      this.cardPayAstoriaPayload.date = Math.floor(DateTime.now().toSeconds());
      this.cardPayAstoriaPayload.hasUmbrella = false;
      this.cardPayAstoriaPayload.beds = 0;
      this.cardCashbackAstoriaPayload.hasUmbrella = false;
      this.cardCashbackAstoriaPayload.beds = 0;
    },
    async destroy() {
      try {
        await cardService.delete(this.card.idWorldCard);
        this.$spiagge.toast.success(this.$t('toast.save.content'));
        this.localCard = cloneDeep(CARD_MODEL_DEFAULT);
        this.cardsDetailsDialog = false;
        this.$emit('destroy');
      } catch (error) {
        this.$spiagge.toast.error(this.$t('toast.error.content'));
      }
    },
    async topUpCard() {
      this.transactionPayload.paymentMethod = this.paymentMethod[0].code;
      this.transactionPayload.appliedPrice =
        this.card.exchangeRate * this.transactionPayload.amount;
      this.rechargeCardDialog = true;
      try {
        const printers: Printer[] = await printerService.list();
        this.printers = printers;
      } catch (error) {
        this.$spiagge.toast.error(this.$t('cardDetailsDialog.operationError'));
      }
    },
    // @typescript-eslint/no-explicit-any
    async createCardTransaction(resData?: any) {
      const payload = {
        emitter: this.card.emitter,
        paymentMethod: this.transactionPayload.paymentMethod,
        amount: this.transactionPayload.amount,
        appliedPrice: this.transactionPayload.appliedPrice,
        printer: this.transactionPayload.idPrinter,
      } as unknown as TransactionPayload;
      try {
        // show loading in dataTable
        this.loadingTransaction = false;

        const receiptNumber = resData?.receiptNumber ?? null;
        const receiptAmount =
          resData?.receiptAmount ?? this.transactionPayload.amount;
        if (receiptNumber) {
          let printPaymentMethod = '';
          if (this.transactionPayload.paymentMethod === PaymentMethod.CASH) {
            printPaymentMethod = PrintPaymentMethod.CONTANTI;
          }
          if (
            this.transactionPayload.paymentMethod === PaymentMethod.CREDIT_CARD
          ) {
            printPaymentMethod = PrintPaymentMethod.CARTA;
          }
          payload.receipt = {
            printerId: this.transactionPayload.idPrinter,
            date: Math.round(DateTime.now().toSeconds()),
            method: printPaymentMethod,
            number: resData?.receiptNumber,
            total: receiptAmount,
          };
        }
        await cardService.createTransaction(this.card.idWorldCard, payload);
        this.localCard.balance = round(
          this.localCard.balance + payload.amount,
          2,
        );
        // Update card
        this.$emit('updateCard', this.localCard);
        // update dataTable
        await this.load();
        // show result message
        this.$spiagge.toast.success(this.$t('toast.save.content'));
        this.transactionPayload = cloneDeep(TRANSACTION_PAYLOAD_MODEL_DEFAULT);
        this.rechargeCardDialog = false;
      } catch (error) {
        this.$spiagge.toast.error(this.$t('toast.error.msg'));
      } finally {
        // hide loading in dataTable
        this.loadingTransaction = false;
      }
    },
    async cardPay() {
      this.cardPayAstoriaPayload.emitter = this.card.emitter;
      // calculation of credits to be deducted from the card
      this.cardPayAstoriaPayload.amount = this.cardPayAstoriaPayload.hasUmbrella
        ? this.cardPayAstoriaPayload.beds + 1
        : this.cardPayAstoriaPayload.beds;
      this.cardPayAstoriaPayload.cardType = this.card.cardType;
      this.cardPayAstoriaPayload.notes = CardOperationAstoria.PAY;
      this.cardPayAstoriaPayload.exchangeRate = this.card.exchangeRate;
      try {
        await cardService.pay(
          this.card.idWorldCard,
          this.cardPayAstoriaPayload,
        );
        this.$spiagge.toast.success(
          this.$t('cardDetailsDialog.operationSuccess'),
        );
        if (this.cardPayAstoriaPayload.hasUmbrella === true) {
          // url paramaters to send to map
          const qs = new URLSearchParams({
            startDate: Math.round(
              DateTime.now().startOf('day').toSeconds(),
            ).toString(),
            endDate: Math.round(
              DateTime.now().startOf('day').toSeconds(),
            ).toString(),
            name: encodeURIComponent(this.card.firstName),
            surname: encodeURIComponent(this.card.lastName),
            email: encodeURIComponent(this.card.email),
            b: this.cardPayAstoriaPayload.beds.toString(),
            c: '0',
            m: '0',
            d: '0',
            voucher: '2030', // FIXME: avoid magic numbers
            ft: '0',
          });
          const url = `/map?${qs.toString()}`;
          this.$router.push(url);
        }
      } catch (error) {
        this.$spiagge.toast.error(this.$t('cardDetailsDialog.operationError'));
      }
    },
    async cardCashback() {
      this.cardCashbackAstoriaPayload.emitter = this.card.emitter;
      this.cardCashbackAstoriaPayload.amount = 0;
      this.cardCashbackAstoriaPayload.cardType = this.card.cardType;
      this.cardCashbackAstoriaPayload.notes = CardOperationAstoria.CASHBACK;
      this.cardCashbackAstoriaPayload.exchangeRate = this.card.exchangeRate;
      this.cardCashbackAstoriaPayload.date = Math.floor(
        this.cashbackDate.toSeconds(),
      );
      try {
        await cardService.cashback(
          this.card.idWorldCard,
          this.cardCashbackAstoriaPayload,
        );
        this.$spiagge.toast.success(
          this.$t('cardDetailsDialog.operationSuccess'),
        );
        // url paramaters to send to map
        const qs = new URLSearchParams({
          startDate: this.cardCashbackAstoriaPayload.date.toString(),
          endDate: this.cardCashbackAstoriaPayload.date.toString(),
          name: encodeURIComponent(this.card.firstName),
          surname: encodeURIComponent(this.card.lastName),
          email: encodeURIComponent(this.card.email),
        });
        const url = `/map?${qs.toString()}`;
        this.$router.push(url);
      } catch (error) {
        this.$spiagge.toast.error(this.$t('cardDetailsDialog.operationError'));
      }
    },
    async load(): Promise<void> {
      try {
        const detailsRes: ApiCardDetailsResponse = await cardService.details(
          this.localCard.idWorldCard,
        );
        this.transactions = detailsRes.result.transactions.sort(
          (a: Transaction, b: Transaction) => (a.date > b.date ? -1 : 1),
        );
        this.loadingTransaction = false;
      } catch (error) {
        this.$spiagge.toast.error(this.$t('cardDetailsDialog.operationError'));
      }
    },
    async confirmTransaction() {
      if (
        this.transactionPayload.idPrinter &&
        this.transactionPayload.idPrinter > 0
      ) {
        const print = this.createReceipt(
          this.transactionPayload.paymentMethod,
          this.transactionPayload.appliedPrice,
        );
        const printerSelected = this.printers.find(
          (element: Printer) =>
            element.id === this.transactionPayload.idPrinter,
        );
        const logCreatePayload = {
          action: LogAction.FISCAL_PRINTER_REQUEST,
          value: printerSelected ? printerSelected.name : '',
        } as ApiLogCreatePayload;
        await logService.create(logCreatePayload);
        this.$spiagge.utils.printMode.print(
          this.transactionPayload.idPrinter,
          print,
          (resData: any) => this.createCardTransaction(resData),
        );
      } else {
        this.createCardTransaction();
      }
    },
    createReceipt(cashFlowMethod: CashFlowMethod, amount: number): Print {
      const print = this.$spiagge.utils.printMode.buildPrint(
        PrintMode.FISCAL,
        cashFlowMethod,
      );
      const article = {} as PrintArticle;
      article.price = amount;
      article.quantity = 1;
      article.description = this.$t('cardDetailsDialog.rechargeCard');
      article.vat = 22;
      print.articles = [article];

      return print;
    },
  },
  async mounted() {
    await this.load();
    this.$spiagge.utils.printMode.setup();
  },
});
