
import { defineComponent, PropType } from 'vue';
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import { DataTableRowClickEvent } from 'primevue/datatable';
import { StatisticCountersTableColumn } from '@/models/statistic';
import PrintButton from '../shared/PrintButton.vue';

interface Counter {
  [key: string]: string | number;
}

export default defineComponent({
  name: 'StatisticsCountersTable',
  components: { PrintButton },
  props: {
    columns: {
      type: Array as PropType<Array<StatisticCountersTableColumn>>,
      required: true,
    },
    counters: {
      type: Array as PropType<Array<Counter>>,
      required: true,
    },
    searchField: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      tableId: Math.floor(Math.random() * 1000) + 1,
      search: '',
    };
  },
  methods: {
    calcTotal(field: string): string {
      return this.elements
        .reduce(
          (count: number, counter: Counter) =>
            count + (counter[field] as number),
          0,
        )
        .toString();
    },
    async onPrint(): Promise<void> {
      const dataTableHtml: HTMLElement = document.getElementById(
        this.tableId.toString(),
      ) as HTMLElement;
      if (dataTableHtml) {
        this.$spiagge.utils.global.printHtml(dataTableHtml);
      }
    },
    onRowClick(event: DataTableRowClickEvent): void {
      this.$router.push(
        `${this.$route.path}/${encodeURIComponent(event.data.name)}`,
      );
    },
  },
  computed: {
    ...mapState('session', ['license']),
    ...mapState('statistic', ['calendar']),
    elements(): Array<{
      [key: string]: number | string;
    }> {
      if (this.search && this.searchField) {
        return this.counters.filter(
          (counter: Counter) =>
            counter[this.searchField as string] &&
            counter[this.searchField as string]
              .toString()
              .toLowerCase()
              .search(this.search.toLowerCase()) > -1,
        );
      }
      return cloneDeep(this.counters);
    },
  },
});
