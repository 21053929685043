/**
 * Role service
 */

import axios from 'axios';
import type { ApiRolesResponse, ApiRoleResponse } from '@/models/api';
import apiService from '@/services/apiService';
import type { RoleAdd, RoleUpdate } from '@/models/permissions';

export default {
  async list(license: string): Promise<ApiRolesResponse> {
    const res = await axios.get(apiService.roles.list(license));
    return res.data;
  },
  async add(role: RoleAdd, license: string): Promise<ApiRoleResponse> {
    const res = await axios.post(apiService.roles.add(license), role);
    return res.data;
  },
  async update(role: RoleUpdate, license: string, roleId: number): Promise<ApiRoleResponse> {
    const res = await axios.put(apiService.roles.update(license, roleId), role);
    return res.data;
  },
  async rolePermissions(license: string, roleId: number): Promise<ApiRoleResponse> {
    const res = await axios.get(apiService.roles.permissionsRole(license, roleId));
    return res.data;
  },
};
