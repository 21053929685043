import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "map-print",
  class: "map-control"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      class: "p-button-white print-button",
      icon: "spi-print",
      label: _ctx.$t('mapPrint.printMap'),
      onClick: _ctx.onOpen
    }, null, 8, ["label", "onClick"])
  ]))
}