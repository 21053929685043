
import { PropType, defineComponent } from 'vue';
import { mapState } from 'vuex';
import { ContentLoader } from 'vue-content-loader';

export default defineComponent({
  name: 'StatisticsCard',
  components: {
    ContentLoader,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, null] as PropType<string | null>,
      required: true,
    },
    subValue: {
      type: String,
      required: false,
    },
    tooltip: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      required: false,
    },
  },
  methods: {},
  computed: {
    ...mapState('app', ['windowWidth', 'breakpoints']),
  },
});
