
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { DateTime, Interval } from 'luxon';
import { clone, cloneDeep, get, merge, omit } from 'lodash';
import { Printer } from '@/models/printer';
import StatisticsTree from '@/components/statistics/StatisticsTree.vue';
import statisticService from '@/services/statisticService';
import {
  ApiAdminListResponse,
  ApiRolesResponse,
  ApiStatisticsCashFlowsPayload,
  ApiStatisticsCashFlowsResponse,
} from '@/models/api';
import {
  StatisticCashFlowsReservationTypeItem,
  StatisticCashFlowsPayment,
  StatisticCashFlowsPaymentMethodItem,
  StatisticTreeItem,
  StatisticFiscalMode,
} from '@/models/statistic';
import {
  CashFlowMethodOption,
  CASH_FLOW_METHOD_OPTIONS,
  CashFlowEditPrinter,
} from '@/models/cashFlow';
import adminService from '@/services/adminService';
import { Admin } from '@/models/admin';
import { DropdownOption } from '@/models';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  DefaultRoles,
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
  RolePermission,
} from '@/models/permissions';
import roleService from '@/services/roleService';
import StatisticsPaymentsHistoryDataTable from '@/components/statistics/StatisticsPaymentsHistoryDataTable.vue';
import spotUtil from '@/utils/spotUtil';
import { SpotType } from '@/models/spot';
import { Service } from '@/models/service';

enum TotalsFilter {
  PAYMENT_METHODS,
  RESERVATION_TYPES,
}

type SearchFilters = {
  users: Array<number>;
  paymentMethods: Array<number>;
  printers: Array<number>;
  channels: Array<string>;
};

export default defineComponent({
  name: 'StatisticsCashFlowsView',
  components: {
    StatisticsPaymentsHistoryDataTable,
    StatisticsTree,
  },
  data() {
    return {
      CASH_FLOW_METHOD_OPTIONS,
      statisticFiscalMode: clone(StatisticFiscalMode),
      selectedTab: 0,
      tabs: [
        { label: this.$t('statisticsCashFlowsView.tab.totals') },
        { label: this.$t('statisticsCashFlowsView.tab.checkoutPointsReport') },
        { label: this.$t('statisticsCashFlowsView.tab.paymentHistory') },
        {
          label: this.$t(
            'statisticsCashFlowsView.tab.onlineReservationReceipts',
          ),
        },
      ],
      channelsFilterOptions: [
        {
          label: this.$t('statisticsCashFlowsView.online'),
          value: 'online',
        },
        {
          label: this.$t('statisticsCashFlowsView.offline'),
          value: 'offline',
        },
      ] as Array<DropdownOption>,
      printerOptions: [],
      // totals
      totalsFilters: cloneDeep(TotalsFilter),
      totalsFilter: TotalsFilter.PAYMENT_METHODS,
      // totals (payment methods) section
      paymentMethods: [] as Array<StatisticTreeItem>,
      searchFilters: {
        users: [],
        paymentMethods: [],
        printers: [],
        channels: [],
      } as SearchFilters,
      paymentMethodsUserFilterOptions: [] as Array<DropdownOption>,
      printerIdsOptions: [
        {
          label: this.$t('cashFlow.printerOption.notPrinted'),
          value: CashFlowEditPrinter.NOT_PRINTED,
        },
        {
          label: this.$t('cashFlow.printerOption.invoice'),
          value: CashFlowEditPrinter.INVOICE,
        },
      ] as Array<DropdownOption>,
      paymentMethodsTotal: 0,
      // totals (reservations type) section
      reservationsTypeTree: [] as Array<StatisticTreeItem>,
      reservationsTypeTotal: 0,
      // reports section
      reportsDialog: false,
      reportsList: [] as Array<number>,
      selectedPrinter: null as unknown as Printer,
      printersTree: [] as Array<StatisticTreeItem>,
      // payments section
      payments: [] as Array<StatisticCashFlowsPayment>,
      // online reservations receipt
      onlineReceiptsAmount: 0.0,
      rolesList: [] as Array<RolePermission>,
      printing: false,
    };
  },
  async beforeMount(): Promise<void> {
    /**
     * Set page title
     * Get users list and init relative filter
     */
    this.$store.commit(
      'statistic/setTitle',
      this.$t('statisticsCashFlowsView.setTitle'),
    );
    this.$store.commit(
      'statistic/setSubtitle',
      this.$t('statisticsCashFlowsView.setSubtitle'),
    );

    const resRolesList: ApiRolesResponse = await roleService.list(
      this.license.license,
    );
    this.rolesList = resRolesList.result.roles;
    const res = await statisticService.cashFlowUsers();
    const users = res.result.users;
    const roleLoggedUser = this.$store.getters['session/roleUser'];
    if (users) {
      users.forEach((admin: Admin) => {
        if (
          roleLoggedUser === DefaultRoles.SUPERUSER
          || this.getRoleName(admin.role) !== DefaultRoles.SUPERUSER
        ) {
          this.paymentMethodsUserFilterOptions.push({
            label: this.formatUserLabel(admin),
            value: admin.id,
          });
        }
      });
    }
    // user param (from admin view)
    const userId = Number(this.$route.query.user ? this.$route.query.user : 0);
    if (userId) {
      const userOption = this.paymentMethodsUserFilterOptions.find(
        (option: DropdownOption) => option.value === userId,
      );
      if (userOption) {
        this.searchFilters.users = [userOption.value as number];
      } else {
        this.$spiagge.toast.warn(
          this.$t('statisticsCashFlowsView.toast.filterError'),
        );
        await this.$router.replace({
          query: {},
        });
      }
    }
    // Printers (invoice and not printed)
    this.printers.forEach((value: { name: string; id: number }) =>
      this.printerIdsOptions.push({
        label: value.name,
        value: value.id,
      }),
    );

    // filters initialization
    this.initFilters();

    this.runQuery();
  },
  methods: {
    initFilters(initialFilters: Partial<SearchFilters> | null = null): void {
      // By default no filters are enabled
      if (initialFilters !== null) {
        this.searchFilters = merge(this.searchFilters, initialFilters);
      }
    },
    getRoleName(id: number | null): string | null {
      if (id === null) {
        return null;
      }
      const res = this.rolesList.filter(
        (role: RolePermission) => role.id === id,
      );

      if (res.length === 1) {
        return res[0].name;
      }

      return null;
    },
    formatUserLabel(admin: Admin): string {
      // User permanently deleted
      if (admin.firstName === '' && admin.lastName === '') {
        return `${this.$t('statisticsCashFlowsView.unknownUser')} (ID: ${admin.id})`;
      }
      let result = `${admin.firstName} ${admin.lastName}`;
      // Current user
      if (admin.id === this.user.id) {
        result += ` ${this.$t('statisticsCashFlowsView.currentUser')}`;
      } else if (admin.role === null) {
        // The user is no more a bound to the license
        result += ` ${this.$t('statisticsCashFlowsView.userDeleted')}`;
      }
      return result;
    },
    openReservation(paymentRow: { data: StatisticCashFlowsPayment }): void {
      if (paymentRow.data.reservationId === null) {
        return;
      }
      if (
        permissionsUtil.isActionPermissionAllowed(
          FEATURE_PERMISSION_CONFIG.reservations,
          FEATURE_PERMISSION_ACTION_CONFIG.reservations.PAGE_ACCESS,
        )
      ) {
        const routeData = this.$router.resolve({
          name: 'ReservationEdit',
          params: { id: `${paymentRow.data.reservationId}` },
        });
        this.$spiagge.utils.global.openLink(routeData.href, true);
      }
    },
    onTotalsFilter(filter: TotalsFilter): void {
      /**
       * Update totals filter
       */
      if (filter === this.totalsFilter) return;
      this.totalsFilter = filter;
      this.runQuery();
    },
    onFilterChange(): void {
      /**
       * Re-run query on payment methods user filter change
       */
      this.runQuery();
    },
    async runQuery(): Promise<void> {
      /**
       * Get and build page data
       */
      const payload = {
        startDate: this.calendar[0].toSeconds(),
        endDate: this.calendar[1].toSeconds(),
      } as ApiStatisticsCashFlowsPayload;

      payload.channels = this.searchFilters.channels;
      payload.paymentMethods = this.searchFilters.paymentMethods;
      payload.printerIds = this.searchFilters.printers;
      payload.userIds = this.searchFilters.users;

      try {
        const res: ApiStatisticsCashFlowsResponse =
          await statisticService.cashFlows(payload);

        // online receipt online
        this.onlineReceiptsAmount = res.result.onlineReceiptsAmount;

        // totals
        this.paymentMethods = [];
        this.paymentMethodsTotal = res.result.methods.total;
        res.result.methods.items.map(
          (paymentMethodsItem: StatisticCashFlowsPaymentMethodItem) => {
            const cashFlowMethod = CASH_FLOW_METHOD_OPTIONS.find(
              (method: CashFlowMethodOption) =>
                method.value === paymentMethodsItem.method,
            );
            this.paymentMethods.push({
              label: cashFlowMethod?.label || '',
              icon: cashFlowMethod?.icon || '',
              value: `${paymentMethodsItem.total}€`,
              children: [
                {
                  label: this.$t(
                    'statisticsCashFlowsView.paymentMethod.fiscal',
                  ),
                  icon: 'spi-fiscal-print',
                  value: `${paymentMethodsItem.fiscal}€`,
                  children: [
                    {
                      label: this.$t(
                        'statisticsCashFlowsView.paymentMethod.withInvoice',
                      ),
                      value: `${paymentMethodsItem.withInvoice}€`,
                    },
                    {
                      label: this.$t(
                        'statisticsCashFlowsView.paymentMethod.withReceipt',
                      ),
                      value: `${paymentMethodsItem.withReceipt}€`,
                    },
                  ],
                },
                {
                  label: this.$t(
                    'statisticsCashFlowsView.paymentMethod.notFiscal',
                  ),
                  icon: 'spi-print',
                  value: `${paymentMethodsItem.notFiscal}€`,
                },
              ],
            });
          },
        );

        this.reservationsTypeTree = [];
        this.reservationsTypeTotal = res.result.reservations.total;
        res.result.reservations.items.forEach(
          (reservationTypeItem: StatisticCashFlowsReservationTypeItem) => {
            let children: Array<StatisticTreeItem> = [];

            const days = reservationTypeItem.days;
            const total = reservationTypeItem.total;
            const spots: Array<StatisticTreeItem> = [];
            const services: Array<StatisticTreeItem> = [];

            Object.keys(
              omit(reservationTypeItem, ['days', 'total', 'services']),
            ).forEach((spotType: string) => {
              const spotInfos = spotUtil.getInfos(spotType as SpotType);
              if (spotInfos) {
                spots.push({
                  label: spotInfos.label,
                  icon: spotInfos.icon,
                  value: `${get(reservationTypeItem, spotType)} €`,
                });
              }
            });

            if (reservationTypeItem.services) {
              Object.keys(
                omit(reservationTypeItem.services, ['total']) as Record<
                  number,
                  number
                >,
              ).forEach((serviceId: string) => {
                const service: Service | undefined = this.services.find(
                  (service: Service) => service.id === Number(serviceId),
                );
                if (service) {
                  services.push({
                    label: service.name,
                    // eslint-disable-next-line import/no-dynamic-require
                    image: require(`@/assets/images/extra-services/${service.icon}`),
                    value: `${reservationTypeItem.services[serviceId]} €`,
                  });
                }
              });
            }

            if (spots.length > 0) {
              children = children.concat(spots);
            }

            if (reservationTypeItem.cardRecharge) {
              children.push({
                label: this.$t('statisticsCashFlowsView.cardRecharge'),
                icon: 'pi spi-card',
                value: `${get(reservationTypeItem, 'cardRecharge')} €`,
              });
            }

            if (services.length > 0) {
              children.push({
                label: this.$t('statisticsCashFlowsView.extraServices'),
                icon: 'pi pi-tag',
                value: `${reservationTypeItem.services.total} €`,
                children: services,
              });
            }

            this.reservationsTypeTree.push({
              label: `${days.toString()}gg`,
              icon: 'spi-calendar',
              value: `${total} €`,
              children,
            });
          },
        );

        // reports
        this.printersTree = [];
        this.reportsList = [];
        this.fiscalPrinters.map((printer: Printer) => {
          this.printersTree.push({
            label: printer.name,
            icon: 'spi-print',
            children: [
              {
                label: this.$t('statisticsCashFlowsView.checkoutPointStats'),
                cta: () => {
                  this.initFilters({
                    printers: [printer.id],
                  });
                  this.selectedTab = 0;
                  this.runQuery();
                },
              },
              {
                label: this.$t('statisticsCashFlowsView.reports'),
                cta: () => {
                  this.selectedPrinter = printer;
                  this.reportsDialog = true;
                },
              },
            ],
          });
        });
        const currentDay = this.calendar[0];
        const endDay = this.calendar[1].plus({ days: 1 });
        this.reportsList = Interval.fromDateTimes(currentDay, endDay)
          .splitBy({
            days: 1,
          })
          .map((d) => d.start.toSeconds());

        // payments
        this.payments = res.result.cashFlows;
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('statisticsCashFlowsView.toast.retrievalError'),
        );
      }
    },
    async onPrint(): Promise<void> {
      window.print();
    },
    onOpenReport(report: number): void {
      /**
       * Open report
       */
      window.open(
        `http://${this.selectedPrinter.ip}/www/dati-rt/${DateTime.fromSeconds(
          report,
        ).toFormat('yyyyMMdd')}/`,
        `Stampante ${
          this.selectedPrinter.name
        } - Report del ${this.$spiagge.date.fromMillis(report, 'dd/MM/yyyy')}`,
        '_blank',
      );
    },
    getPaymentMethod(payment: StatisticCashFlowsPayment): string {
      /**
       * Get payment method name by payment
       */
      return (
        CASH_FLOW_METHOD_OPTIONS.find(
          (method: CashFlowMethodOption) => method.value === payment.method,
        )?.label || ''
      );
    },
    // getSpotType(payment: StatisticCashFlowsPayment): string {
    //   /**
    //    * Get spot type label by payment
    //    */
    //   return (
    //     SPOT_INFOS.find((spot: SpotInfo) => spot.type === payment.spotType)
    //       ?.label || ''
    //   );
    // },
  },
  computed: {
    ...mapState('session', ['user', 'license', 'services']),
    ...mapState('statistic', ['calendar']),
    ...mapGetters('session', ['fiscalPrinters', 'printers']),
  },
  watch: {
    calendar() {
      /**
       * Re-run query on calendar update
       */
      if (this.calendar[1] === null) return;
      this.runQuery();
    },
    reportsFilter() {
      /**
       * Re-run query on reports filter update
       */
      this.searchFilters.users = [];
      this.runQuery();
    },
  },
});
