import { EditableServiceGroup } from '@/models/serviceGroup/editableServiceGroup';

const SERVICE_GROUP_DEFAULT = {
  name: '',
  description: '',
  imageUri: '',
  dailyLimit: 0,
  reservationLimit: 0,
} as EditableServiceGroup;

export default SERVICE_GROUP_DEFAULT;
