
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import OverlayPanel from 'primevue/overlaypanel';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import {
  ReservationExpense,
  ReservationExpenseAppData,
  ReservationExpenseItem,
  ReservationExpensePaid,
  RESERVATION_EXPENSE_APP_DATA,
} from '@/models/reservation';
import {
  CashFlowMethod,
  CashFlowMethodOption,
  CASH_FLOW_METHOD_OPTIONS,
} from '@/models/cashFlow';
import CashFlows from '@/components/shared/CashFlows.vue';
import {
  Print,
  PrintArticle,
  Printer,
  PrinterResponse,
  PrinterResponseData,
  PrintMode,
} from '@/models/printer';
import {
  ApiCashFlowCreatePayload,
  ApiCashFlowUpdatePayload,
} from '@/models/api';
import reservationService from '@/services/reservationService';

export default defineComponent({
  name: 'ReservationExtraExpenses',
  components: {
    CashFlows,
  },
  data() {
    return {
      showOrderDetailsDialog: false,
      cashFlowMethods: cloneDeep(CASH_FLOW_METHOD_OPTIONS),
      cashFlowMethod: CashFlowMethod.CASH,
    };
  },
  methods: {
    getExpenseIcon(expense: ReservationExpense): string {
      return (
        RESERVATION_EXPENSE_APP_DATA.find(
          (appData: ReservationExpenseAppData) => appData.app === expense.app,
        )?.icon || ''
      );
    },
    getExpenseLabel(expense: ReservationExpense): string {
      return (
        RESERVATION_EXPENSE_APP_DATA.find(
          (appData: ReservationExpenseAppData) => appData.app === expense.app,
        )?.name || ''
      );
    },
    getExpenseDate(expense: ReservationExpense): string {
      const datetime = DateTime.fromSeconds(expense.date);
      return datetime.toFormat('dd-MM-yy HH:mm');
    },
    closeOrderDetailsDialog() {
      this.$store.commit('reservation/setSelectedExpensesStored', []);
      this.$store.commit('reservation/setDepositTransferStored', null);
      this.showOrderDetailsDialog = false;
      this.cashFlowMethod = CashFlowMethod.CASH;
    },
    showPastOrders(event: Event): void {
      (this.$refs.op as OverlayPanel).show(event);
    },
    onLabelClick(expense: ReservationExpense): void {
      this.selectedExpensesCheckboxModel = [expense];
      this.showOrderDetailsDialog = true;
    },
    async onDeleteOrder(): Promise<void> {
      try {
        await Promise.all(
          this.selectedExpenses.map((expense: ReservationExpense) =>
            /** Delete expense */
            reservationService.deleteExpense(expense.reservationId, expense.id),
          ),
        );
        /** Reload reservation */
        this.$store.dispatch('reservation/getReservation', this.id);
        this.$spiagge.toast.success(
          this.$t('reservationExtraExpenses.toast.deleteSuccess'),
        );
        this.closeOrderDetailsDialog();
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationExtraExpenses.toast.deleteError'),
        );
      }
    },
    onFiscalPrint(printer: Printer): void {
      this.printFunction(printer, PrintMode.FISCAL);
    },
    onNonFiscalPrint(printer: Printer): void {
      this.printFunction(printer, PrintMode.NON_FISCAL);
    },
    async onNoPrint() {
      this.pay();
    },
    /**
     * FIXME: Perchè paghiamo una spesa extra creando un cashflow
     * quando è il metodo di back che dovrebbe farlo?
     */
    async pay(resData?: PrinterResponse, print?: Print, printer?: Printer) {
      try {
        const payload = {
          reservationId: this.id,
          /** Amount has to be exactly the final price of the expenses */
          amount: this.orderTotal,
          method: this.cashFlowMethod,
          notes: null,
          cardId: null,
          expenses: [] as Array<number>,
        } as ApiCashFlowCreatePayload;
        if (this.selectedExpenses && this.selectedExpenses.length > 0) {
          payload.expenses = this.selectedExpenses.map(
            (element: ReservationExpense) => element.id,
          );
        } else if (this.expenses && this.expenses.length > 0) {
          payload.expenses = this.expenses
            .filter(
              (element: ReservationExpense) =>
                element.paid === ReservationExpensePaid.NOT_PAID,
            )
            .map((element: ReservationExpense) => element.id);
        }
        if (resData && print && printer) {
          payload.receipt = {
            printerId: printer.id,
            date: Math.round(DateTime.now().toSeconds()),
            method: print.paymentMethod,
            number: resData?.receiptNumber ?? null,
            total: Number(resData?.receiptAmount ?? this.orderTotal),
            subTotal1: null,
            vat1: null,
            subTotal2: null,
            vat2: null,
            subTotal3: null,
            vat3: null,
            subTotal4: null,
            vat4: null,
          };
        }
        await this.$store.dispatch('reservation/createCashFlow', payload);
        this.$spiagge.toast.success(
          this.$t('reservationExtraExpenses.toast.createSuccess'),
        );
        this.closeOrderDetailsDialog();
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationExtraExpenses.toast.createError'),
        );
      }
    },
    async fiscalize(resData: any, print: Print, printer: Printer) {
      /**
       * We have to fiscalize the existing cashflow related to this expense
       */
      if (this.selectedExpenses.length !== 1) {
        /** THIS SHOULD NEVER HAPPEN */
        return;
      }
      const expense = this.selectedExpenses[0];
      const payload = {
        id: expense.cashFlowId,
        fiscal: true,
        receipt: {
          printerId: printer.id,
          date: Math.round(DateTime.now().toSeconds()),
          method: print.paymentMethod,
          number: resData?.receiptNumber ?? null,
          total: Number(resData?.receiptAmount ?? this.orderTotal),
          subTotal1: null,
          vat1: null,
          subTotal2: null,
          vat2: null,
          subTotal3: null,
          vat3: null,
          subTotal4: null,
          vat4: null,
        },
      } as ApiCashFlowUpdatePayload;
      /** Update cashflow */
      try {
        this.$store.dispatch('reservation/updateCashFlow', payload);
        this.$spiagge.toast.success(
          this.$t('reservationExtraExpenses.toast.taxationSuccess'),
        );
        this.closeOrderDetailsDialog();
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationExtraExpenses.toast.taxationError'),
        );
      }
    },
    buildListOfArticles() {
      const printArticles = [] as Array<PrintArticle>;
      this.selectedExpenses.map((expense: ReservationExpense) => {
        expense.items.map((item: ReservationExpenseItem) => {
          const article = {} as PrintArticle;
          article.description = item.name ?? item.product;
          article.price = item.price / item.qnt;
          article.quantity = Number(item.qnt);
          article.vat = Number(item.vat);
          printArticles.push(article);
        });
      });
      return printArticles;
    },
    printFunction(printer: Printer, printMode: PrintMode): void {
      if (printer && printer.id) {
        const print = this.$spiagge.utils.printMode.buildPrint(
          printMode,
          this.cashFlowMethod,
        );
        print.articles = this.buildListOfArticles();
        /** Calculate discount */
        if (this.orderTotal !== this.orderItemsTotal) {
          print.discount = this.orderItemsTotal - this.orderTotal;
        }
        if (printMode === PrintMode.FISCAL) {
          this.$spiagge.utils.printMode.print(
            printer.id,
            print,
            (resData: any) => {
              if (this.canPay) {
                this.pay(resData, print, printer);
              } else {
                // This can only happen with one expense selected and already paid
                this.fiscalize(resData, print, printer);
              }
            },
          );
        } else {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          this.$spiagge.utils.printMode.print(printer.id, print, () => {});
        }
      }
    },
  },
  computed: {
    /** Checkbox */
    selectedExpensesCheckboxModel: {
      get(): Array<ReservationExpense> {
        return this.selectedExpenses;
      },
      set(value: Array<ReservationExpense>): void {
        const selected = cloneDeep(value) || ([] as Array<ReservationExpense>);
        this.$store.commit('reservation/setSelectedExpensesStored', selected);
        const amount =
          this.totalSelectedExpenses > 0 ? this.totalSelectedExpenses : null;
        this.$store.commit('reservation/setDepositTransferStored', amount);
      },
    },
    ...mapGetters('session', ['license', 'permissions']),
    ...mapGetters('reservation', [
      'isAddition',
      'isCabin',
      'totalSelectedExpenses',
      'cards',
    ]),
    ...mapState('reservation', ['id', 'startDate', 'endDate']),
    expenses(): Array<ReservationExpense> {
      return this.$store.getters['reservation/expenses'];
    },
    selectedExpenses(): Array<ReservationExpense> {
      return this.$store.getters['reservation/selectedExpensesStored'];
    },
    expensesPaid(): Array<ReservationExpense> {
      return this.expenses.filter(
        (expense: ReservationExpense) =>
          expense.paid !== ReservationExpensePaid.NOT_PAID,
      );
    },
    expensesUnpaid(): Array<ReservationExpense> {
      return this.expenses.filter(
        (expense: ReservationExpense) =>
          expense.paid === ReservationExpensePaid.NOT_PAID,
      );
    },
    /** SELECTION VARIABLES for Dialog */
    orderTotal(): number {
      return this.selectedExpenses && this.selectedExpenses.length > 0
        ? this.selectedExpenses
            .map((expense: ReservationExpense) => expense.value)
            .reduce(
              (value: number, expenseValue: number) => value + expenseValue,
            )
        : 0;
    },
    orderItemsTotal(): number {
      if (!this.selectedExpenses || this.selectedExpenses.length <= 0) {
        return 0;
      }
      let amount = 0;
      this.selectedExpenses.forEach((expense: ReservationExpense) => {
        expense.items.forEach((item: ReservationExpenseItem) => {
          amount += item.price;
        });
      });
      return amount;
    },
    cashFlowMethodsOptions(): Array<CashFlowMethodOption> {
      /**
       * Return cash flow methods. Remove YB_CARD if no card is available
       */
      return cloneDeep(CASH_FLOW_METHOD_OPTIONS).filter(
        (cashFlowMethod: CashFlowMethodOption) =>
          cashFlowMethod.value !== CashFlowMethod.YB_CARD,
      );
    },
    canDelete(): boolean {
      // Customization Bagno Vittoria, remove?
      return this.license.license !== 'it-lu-55042-13';
      /* this.license.license !== 'it-lu-55042-13' || this.permission > 2; */
    },
    canPrintFiscal(): boolean {
      return (
        this.selectedExpenses.filter(
          (expense: ReservationExpense) =>
            expense.paid === ReservationExpensePaid.FIS_PAID,
        ).length <= 0
      );
    },
    canPay(): boolean {
      return (
        this.selectedExpenses.filter(
          (expense: ReservationExpense) =>
            expense.paid !== ReservationExpensePaid.NOT_PAID,
        ).length <= 0
      );
    },
  },
});
