
import { DateTime } from 'luxon';
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import { DropdownOption } from '@/models';
import {
  DEFAULT_SMART_SEARCH_MODEL,
  SmartSearchModel,
} from '@/models/smartSearch';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

import { Sector } from '@/models/sector';

export default defineComponent({
  name: 'TopBarNewReservation',
  props: {
    isSmartSearch: Boolean,
  },
  data() {
    return {
      show: false,
      today: DateTime.now().startOf('day'),
      model: cloneDeep(DEFAULT_SMART_SEARCH_MODEL),
      processing: false,
      permissionsUtil,
      FEATURE_PERMISSION_ACTION_CONFIG,
      FEATURE_PERMISSION_CONFIG,
    };
  },
  methods: {
    closeOverlay(): void {
      this.show = false;
      this.$store.commit('map/setActiveOverlay', false);
    },
    openOverlay(): void {
      this.show = true;
      this.$store.commit('map/setActiveOverlay', true);
    },
    onNewReservation(): void {
      this.openOverlay();
      if (this.isSmartSearch && this.isRunning && this.suggestionAvailable) {
        this.model = cloneDeep(
          this.$store.state.smartSearch.model,
        ) as SmartSearchModel;
      } else {
        this.model = cloneDeep(DEFAULT_SMART_SEARCH_MODEL);

        if (this.isSmartSearch) {
          this.model.smartSearch = true;
        }
      }
    },
    onFromSelect(from: Date): void {
      if (this.toCalendar < from) {
        this.model.to = this.model.from;
      }
    },
    async onProceed(): Promise<void> {
      if (this.processing) {
        return;
      }
      this.processing = true;
      // Search or create
      this.closeOverlay();
      this.onSearch();
      this.processing = false;
    },
    onSearch(): void {
      // Add all sectors if no sector is selected
      if (this.model.smartSearch && this.model.sectors.length === 0) {
        this.model.sectors = Object.values(
          this.sectorsOptions.map((option: DropdownOption) => option.value),
        ) as number[];
      }
      if (!this.model.smartSearch) {
        this.$store.commit('smartSearch/setActive', false);
      }
      this.$store.commit('smartSearch/setModel', this.model);
      this.$router.push({ path: '/map', force: true });
    },
    canCreateReservations(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.map,
        FEATURE_PERMISSION_ACTION_CONFIG.map.ADD_UPDATE_RESERVATIONS,
      );
    },
    onSeasonal(): void {
      if (this.model.seasonal) {
        const seasonalDates = this.$spiagge.utils.global.seasonalDates();
        this.model.from = seasonalDates[0];
        this.model.to = seasonalDates[1];
        this.model.halfDay = false;
      } else {
        this.model.from = this.today;
        this.model.to = this.today;
      }
    },
  },
  computed: {
    ...mapState('app', ['breakpoints']),
    ...mapGetters('session', ['sectors']),
    ...mapGetters('smartSearch', ['isRunning', 'suggestionAvailable']),
    fromCalendar: {
      get(): Date {
        return this.model.from.toJSDate();
      },
      set(d: Date): void {
        this.model.from = DateTime.fromFormat(
          `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`,
          'd-M-yyyy',
        );
      },
    },
    toCalendar: {
      get(): Date {
        return this.model.to.toJSDate();
      },
      set(d: Date): void {
        this.model.to = DateTime.fromFormat(
          `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`,
          'd-M-yyyy',
        );
      },
    },
    days: {
      get(): number {
        return this.model.to.diff(this.model.from, 'days').days + 1;
      },
      set(d: number): void {
        this.model.to = this.model.from.plus({ days: d - 1 });
      },
    },
    sectorsOptions(): Array<DropdownOption> {
      return this.sectors.map((sector: Sector) => ({
        label: sector.header.name,
        value: sector.header.id,
      }));
    },
    buttonClasses(): Array<string> {
      const buttonClasses = [];
      if (this.isSmartSearch) {
        if (this.suggestionAvailable) {
          buttonClasses.push('p-button-outlined');
        }
      } else {
        buttonClasses.push('new-reservation-button');
      }
      return buttonClasses;
    },
    buttonLabel(): string {
      const buttonLabel = [];
      if (this.isSmartSearch) {
        buttonLabel.push(
          this.suggestionAvailable
            ? this.$t('topBarNewReservation.editSearch')
            : this.$t('topBarNewReservation.newSmartSearch'),
        );
      } else {
        buttonLabel.push(
          this.breakpoints.xlUp
            ? this.$t('topBarNewReservation.newReservation')
            : this.$t('topBarNewReservation.reservation'),
        );
      }
      return buttonLabel.join(' ');
    },
  },
  watch: {
    $route() {
      // close modal, il route changes and the modal is open
      if (this.show) {
        this.show = false;
      }
    },
  },
});
