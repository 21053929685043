import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17030dd3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "price-list-dialog" }
const _hoisted_2 = { class: "p-field" }
const _hoisted_3 = { class: "p-field" }
const _hoisted_4 = { class: "p-field" }
const _hoisted_5 = { class: "p-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_input_tooltip = _resolveComponent("input-tooltip")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t(
            `priceListDialog.title.${
              _ctx.mode === 'rename' ? 'rename' : _ctx.isNew ? 'new' : 'edit'
            }`,
          )), 1)
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onFormSubmit && _ctx.onFormSubmit(...args)), ["prevent"])),
      class: "form"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('priceListDialog.priceListName')) + ":", 1),
        _createVNode(_component_InputText, {
          type: "text",
          modelValue: _ctx.priceList.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.priceList.name) = $event))
        }, null, 8, ["modelValue"])
      ]),
      (_ctx.mode === 'edit')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('priceListDialog.listDaysLength')) + ":", 1),
              _createVNode(_component_InputNumber, {
                modelValue: _ctx.priceList.listDaysLength,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.priceList.listDaysLength) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('priceListDialog.extraDaysMode')) + ": ", 1),
                _createVNode(_component_input_tooltip, {
                  message: _ctx.$t('priceListDialog.tooltip.extra')
                }, null, 8, ["message"])
              ]),
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.priceList.extraDaysMode,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.priceList.extraDaysMode) = $event)),
                options: _ctx.extraDaysModeOptions,
                optionLabel: "label",
                optionValue: "value"
              }, null, 8, ["modelValue", "options"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", null, [
                _createTextVNode(_toDisplayString(_ctx.$t('priceListDialog.periodsShiftMode')) + ": ", 1),
                _createVNode(_component_input_tooltip, {
                  message: _ctx.$t('priceListDialog.tooltip.period')
                }, null, 8, ["message"])
              ]),
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.priceList.periodsShiftMode,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.priceList.periodsShiftMode) = $event)),
                options: _ctx.periodsShiftModeOptions,
                optionLabel: "label",
                optionValue: "value"
              }, null, 8, ["modelValue", "options"])
            ])
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_Button, {
        type: "submit",
        label: _ctx.$t('button.save'),
        icon: "spi-save",
        class: "p-button p-button-primary btn"
      }, null, 8, ["label"])
    ], 32)
  ]))
}