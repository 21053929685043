
import { defineComponent } from 'vue';
import { ToastSeverity } from '@/models/toast';
import toastUtil from '@/utils/toastUtil';
import {
  ApiFeaturesContract,
  ApiRoleResponse,
  ApiRolesResponse,
} from '@/models/api';
import permissionsUtil from '@/utils/permissionsUtil';
import roleService from '@/services/roleService';
import {
  ActionPermission,
  DefaultRoles,
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
  FeaturePermission,
  RoleAdd,
  RolePermission,
  RoleUpdate,
} from '@/models/permissions';
import { License } from '@/models/license';
import permissionsService from '@/services/permissionsService';

export default defineComponent({
  data() {
    return {
      adminDialog: false,
      logsDialog: false,
      rolesList: [] as Array<RolePermission>,
      featuresList: [] as Array<FeaturePermission>,
      roleAdd: {} as RoleAdd,
      roleUpdate: {} as RoleUpdate,
      roleRef: {} as RolePermission,
      showRoleUpdate: false,
      roleUpdateActions: {} as [number: boolean],
    };
  },
  computed: {
    FEATURE_PERMISSION_ACTION_CONFIG() {
      return FEATURE_PERMISSION_ACTION_CONFIG;
    },
    rolesVisible(): RolePermission[] {
      const roleLoggedUser = this.$store.getters['session/roleUser'];
      return this.rolesList.filter(
        (role: RolePermission) =>
          (role.name === DefaultRoles.SUPERUSER &&
            roleLoggedUser === DefaultRoles.SUPERUSER) ||
          role.name !== DefaultRoles.SUPERUSER,
      );
    },
    license(): string {
      return this.licenseData().license;
    },
    featureList() {
      const resp: {
        feature: string;
        permission: {
          id: number;
          name: string;
        };
      }[] = [];

      if (typeof this.roleRef.features !== 'undefined') {
        this.featuresList.forEach((feature: FeaturePermission) => {
          feature.actions.forEach((action: ActionPermission) => {
            resp.push({
              feature: feature.name,
              permission: {
                id: action.id,
                name: action.name,
              },
            });
          });
        });
      }
      return resp;
    },
  },
  methods: {
    hasPermission(action: string): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.settings,
        action,
      );
    },
    getFeaturedName(feature: string): string {
      return this.$t(`features.${feature}.name`);
    },
    getFeatureActionName(feature: string, action: string): string {
      return this.$t(`features.${feature}.actions.${action}`);
    },
    licenseData(): License {
      return this.$store.getters['session/license'];
    },
    async triggerEditRole(role: RolePermission) {
      this.roleRef = await this.getRoleDetails(role.id);

      this.roleUpdate.name = this.getRoleName(this.roleRef.name);
      this.roleUpdate.active = this.roleRef.active;
      this.roleUpdate.actionsIdList = [];
      this.roleUpdateActions = {} as [number: boolean];
      if (typeof this.roleRef.features !== 'undefined') {
        this.roleRef.features.forEach((feature: FeaturePermission) => {
          feature.actions.forEach((action: ActionPermission) => {
            this.roleUpdateActions[action.id] = true;
          });
        });
      }
      this.showRoleUpdate = true;
    },
    triggerUndoEditRole() {
      this.roleUpdate = {} as RoleUpdate;
      this.roleRef = {} as RolePermission;
      this.showRoleUpdate = false;
    },
    async addRole() {
      try {
        this.roleAdd.active = false;
        await roleService.add(this.roleAdd, this.license);
        await this.loadRolesList();
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
        this.roleAdd = {} as RoleAdd;
      } catch (error) {
        // console.log(error);
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
    async edit() {
      try {
        this.roleUpdate.actionsIdList = [];
        // console.log(this.roleUpdateActions);
        Object.keys(this.roleUpdateActions).forEach((idAction) => {
          const id: number = +idAction;
          if (this.roleUpdateActions[id]) {
            this.roleUpdate.actionsIdList.push(id);
          }
        });

        await roleService.update(
          this.roleUpdate,
          this.license,
          this.roleRef.id,
        );
        await this.loadRolesList();
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
        // this.triggerUndoEditRole();
        this.adminDialog = false;
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
    getRoleName(roleName: string) {
      let lang = this.$t(`rolesDefault.${roleName}`);
      if (lang.startsWith('rolesDefault.')) {
        lang = roleName;
      }
      return lang;
    },
    async loadRolesList() {
      const res: ApiRolesResponse = await roleService.list(this.license);
      this.rolesList = res.result.roles;
    },
    async loadFeaturesContractList() {
      const res: ApiFeaturesContract =
        await permissionsService.featuresContractList(this.license);
      this.featuresList = res.result.features;
    },
    async getRoleDetails(id: number): Promise<RolePermission> {
      const res: ApiRoleResponse = await roleService.rolePermissions(
        this.license,
        id,
      );
      return res.result.role;
    },
  },
  async beforeMount() {
    try {
      await this.loadRolesList();
      await this.loadFeaturesContractList();
    } catch (error) {
      // console.log(error);
    }
  },
});
