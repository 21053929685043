
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import { mapState } from 'vuex';
import StatisticsNavigation from '@/components/statistics/StatisticsNavigation.vue';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

export default defineComponent({
  name: 'StatisticsView',
  components: {
    StatisticsNavigation,
  },
  methods: {
    hasExportPermission(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.stats,
        FEATURE_PERMISSION_ACTION_CONFIG.stats.EXPORT_STATISTICHE,
      );
    },
    onDateSelect(): void {
      if (this.calendar[1] !== null) {
        (this.$refs.calendar as any).overlayVisible = false;
      }
    },
    checkCalendar(): void {
      if (this.calendarModel[1] === null) {
        this.calendarModel = [this.calendarModel[0], this.calendarModel[0]];
      }
    },
  },
  computed: {
    ...mapState('statistic', [
      'title',
      'subtitle',
      'calendar',
      'backRoute',
      'printFn',
      'exportFn',
    ]),
    calendarModel: {
      get(): Array<Date | null> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (this.calendar as any[]).map(
          (d: DateTime | null) => d?.toJSDate() ?? null,
        );
      },
      set(calendar: Array<Date>) {
        const datetimes = (calendar as any[]).map((d: Date | null) => {
          if (!d) return null;
          const date = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
          return DateTime.fromFormat(date, 'd-M-yyyy');
        });
        // Hack double click on same day
        if (
          this.calendar[0].toMillis() === datetimes[0]?.toMillis() &&
          !this.calendar[1] &&
          !datetimes[1]
        ) {
          // eslint-disable-next-line prefer-destructuring
          datetimes[1] = datetimes[0];
        }
        // this.calendar = datetimes;
        this.$store.commit('statistic/setCalendar', [
          datetimes[0],
          datetimes[1],
        ]);
      },
    },
  },
});
