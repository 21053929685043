
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import { DataTableRowClickEvent } from 'primevue/datatable';
import StatisticsCard from '@/components/statistics/StatisticsCard.vue';
import {
  StatisticCard as StatisticCardI,
  StatisticDataTableColumn,
  StatisticDataTableColumnIcon,
  StatisticDataTableColumnRender,
  StatisticDataTableView,
  StatisticDateMode,
} from '@/models/statistic';
import StatisticsDataTable from '@/components/statistics/StatisticsDataTable.vue';
import statisticService from '@/services/statisticService';
import { ApiStatisticsArrivalsTodayResponse } from '@/models/api';
import { SpotInfo, SPOT_INFOS } from '@/models/spot';
import Slider from '@/components/shared/Slider.vue';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

export default defineComponent({
  name: 'StatisticsArrivalsTodayView',
  components: { StatisticsCard, StatisticsDataTable, Slider },
  data() {
    return {
      id: 'arrivals-today',
      endpoint: statisticService.arrivalsToday,
      exportEndpoint: statisticService.exportReservations,
      columns: [
        {
          label: this.$t('statisticsArrivalsDepartures.column.spotType'),
          field: 'spotType',
          sortable: true,
          formatter: (value: string) =>
            SPOT_INFOS.find((spot: SpotInfo) => spot.type === value)?.label,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.spotName'),
          field: 'spotName',
          sortable: true,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.firstName'),
          field: 'firstName',
          sortable: true,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.lastName'),
          field: 'lastName',
          sortable: true,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.staging'),
          field: 'staging',
          sortable: false,
          formatter: (value: Array<number>) =>
            this.$spiagge.utils.global.stagingLabel(
              value[0],
              value[1],
              value[2],
              value[3],
            ),
        },
        {
          label: this.$tc('statisticsArrivalsDepartures.column.ticket', 2),
          field: 'tickets',
          sortable: false,
          renderer: 'tickets',
        },
        {
          label: this.$tc('statisticsArrivalsDepartures.column.service', 2),
          field: 'services',
          sortable: false,
          renderer: 'services',
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.startDate'),
          field: 'startDate',
          sortable: true,
          formatter: (value: number) => this.$spiagge.date.fromMillis(value),
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.endDate'),
          field: 'endDate',
          sortable: true,
          formatter: (value: number) => this.$spiagge.date.fromMillis(value),
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.hotel'),
          field: 'hotel',
          sortable: true,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.duration'),
          field: 'duration',
          sortable: true,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.online'),
          field: 'online',
          sortable: true,
          renderer: StatisticDataTableColumnRender.BOOLEAN_ICON,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.channel'),
          field: 'channel',
          sortable: true,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.paid'),
          field: 'paid',
          sortable: true,
          renderer: StatisticDataTableColumnRender.BOOLEAN_ICON,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.priceTotal'),
          field: 'priceTotal',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.listTotal'),
          field: 'listTotal',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.paidTotal'),
          field: 'paidTotal',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.toPay'),
          field: 'toPay',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t(
            'statisticsArrivalsDepartures.column.discountPercentage',
          ),
          field: 'discountPercentage',
          sortable: true,
          formatter: (value: string) => `${value} %`,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.discountedBy'),
          field: 'discountedBy',
          sortable: true,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.createdBy'),
          field: 'createdBy',
          sortable: true,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.deletedBy'),
          field: 'deletedBy',
          sortable: true,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.createdAt'),
          field: 'createdAt',
          sortable: true,
          formatter: (value: number) =>
            this.$spiagge.date.fromMillis(value, 'dd-MM-yyyy HH:mm'),
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.deletedAt'),
          field: 'deletedAt',
          sortable: true,
          formatter: (value: number) =>
            this.$spiagge.date.fromMillis(value, 'dd-MM-yyyy HH:mm'),
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.discountedAt'),
          field: 'discountedAt',
          sortable: true,
          formatter: (value: number) =>
            this.$spiagge.date.fromMillis(value, 'dd-MM-yyyy HH:mm'),
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.voucher'),
          field: 'voucher',
          sortable: true,
          renderer: StatisticDataTableColumnRender.BOOLEAN_ICON,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.voucherAmount'),
          field: 'voucherAmount',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.column.notes'),
          field: 'notes',
          sortable: false,
          classes: ['white-space-normal'],
        },
      ] as Array<StatisticDataTableColumn>,
      icons: [
        {
          label: this.$t('statisticsArrivalsDepartures.bookingTemp'),
          icon: 'spi-clock',
          show: (row: { [key: string]: boolean }) => {
            try {
              return row.hasBookingTemp;
            } catch (e) {
              return false;
            }
          },
        },
        {
          label: this.$t('statisticsArrivalsDepartures.typeMoving'),
          icon: 'spi-switch',
          show: (row: { [key: string]: number | string }) => {
            try {
              return !!row.movingId;
            } catch (e) {
              return false;
            }
          },
        },
        {
          label: this.$t('statisticsArrivalsDepartures.jointAccountPart'),
          icon: 'spi-joint',
          show: (row: { [key: string]: number | string | boolean }) =>
            row.isJointAccountPart ?? false,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.isTicket'),
          icon: 'spi-ticket',
          show: (row: { [key: string]: number | string | boolean }) =>
              row.isTicket ?? false,
        },
      ] as Array<StatisticDataTableColumnIcon>,
      basePayload: {
        dateMode: StatisticDateMode.ARRIVAL,
      },
      searchColumns: [],
      defaultView: {
        id: DateTime.now().startOf('day').toSeconds(),
        label: this.$t('statisticsArrivalsDepartures.defaultViewLabel'),
        columns: [
          'spotType',
          'spotName',
          'firstName',
          'lastName',
          'staging',
          'startDate',
          'endDate',
          'duration',
          'online',
        ],
        orderBy: [],
      } as StatisticDataTableView,
      cards: [
        {
          label: this.$t(
            'statisticsArrivalsDepartures.card.reservationsTotal.label',
          ),
          value: null,
          tooltip: this.$t(
            'statisticsArrivalsDepartures.card.reservationsTotal.tooltip',
          ),
        },
        {
          label: this.$t('statisticsArrivalsDepartures.card.averageStay.label'),
          value: null,
          tooltip: this.$t(
            'statisticsArrivalsDepartures.card.averageStay.tooltip',
          ),
        },
        {
          label: this.$t('statisticsArrivalsDepartures.card.averageCart.label'),
          value: null,
          tooltip: this.$t(
            'statisticsArrivalsDepartures.card.averageCart.tooltip',
          ),
        },
        {
          label: this.$t(
            'statisticsArrivalsDepartures.card.reservationsValue.label',
          ),
          value: null,
          tooltip: this.$t(
            'statisticsArrivalsDepartures.card.reservationsValue.tooltip',
          ),
        },
        {
          label: this.$t('statisticsArrivalsDepartures.card.valueToPay.label'),
          value: null,
          tooltip: this.$t(
            'statisticsArrivalsDepartures.card.valueToPay.tooltip',
          ),
        },
      ] as Array<StatisticCardI>,
      tooltipText: this.$t('statisticsArrivalsDepartures.tooltipText'),
    };
  },
  beforeMount(): void {
    this.$store.commit(
      'statistic/setTitle',
      this.$t('statisticsArrivalsDepartures.setTitle.arrivalsToday'),
    );
    this.$store.commit(
      'statistic/setSubtitle',
      this.$t('statisticsArrivalsDepartures.setSubtitle.arrivalsToday'),
    );
    this.$store.commit('statistic/setCalendar', [
      DateTime.now().startOf('day'),
      DateTime.now().startOf('day'),
    ]);
  },
  methods: {
    onResult(res: ApiStatisticsArrivalsTodayResponse): void {
      this.cards[0].value = Math.round(res.result.dataTable.count).toString();
      this.cards[1].value = `${Math.round(
        res.result.cards.averageDuration,
      ).toString()}gg`;
      this.cards[2].value = `${res.result.cards.averagePrice.toString()}€`;
      this.cards[3].value = `${res.result.cards.totalPrice.toString()}€`;
      this.cards[4].value = `${res.result.cards.toPay.toString()}€`;
    },
    rowClass() {
      return 'row';
    },
    openReservation(event: DataTableRowClickEvent) {
      if (
        permissionsUtil.isActionPermissionAllowed(
          FEATURE_PERMISSION_CONFIG.reservations,
          FEATURE_PERMISSION_ACTION_CONFIG.reservations.PAGE_ACCESS,
        )
      ) {
        const routeData = this.$router.resolve({
          name: 'ReservationEdit',
          params: { id: `${event.data.reservationId}` },
        });

        this.$spiagge.utils.global.openLink(routeData.href, true);
      }
    },
    hasPermission(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.stats,
        FEATURE_PERMISSION_ACTION_CONFIG.stats.SHOW_TOTAL_CARDS,
      );
    },
  },
});
