import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "yb-google-chrome-ext-banner" }
const _hoisted_2 = {
  href: "https://chrome.google.com/webstore/detail/spiaggeit-manager/kddlpmnnlnnlbdmlmneclkmgbgfifeag",
  target: "_blank"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("a", _hoisted_2, [
      _createElementVNode("div", null, _toDisplayString(_ctx.$t('cashDeskView.chromeExtension')), 1),
      _createElementVNode("img", {
        "data-v-5de9bce4": "",
        src: _ctx.chromeImageExtensionUrl
      }, null, 8, _hoisted_3)
    ])
  ]))
}