import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6ed4d9d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = {
  key: 0,
  class: "pi pi-chevron-down"
}
const _hoisted_3 = {
  key: 1,
  class: "pi pi-chevron-right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldPriceConfigWizardRow = _resolveComponent("FieldPriceConfigWizardRow")!

  return (_openBlock(), _createElementBlock("div", {
    id: `wrapper-${_ctx.data.fullToken}`,
    class: "field-price-config-wizard-expandable-row"
  }, [
    _createElementVNode("span", {
      class: "expander",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onExpander && _ctx.onExpander(...args)))
    }, [
      (_ctx.data.expanded)
        ? (_openBlock(), _createElementBlock("i", _hoisted_2))
        : (_openBlock(), _createElementBlock("i", _hoisted_3))
    ]),
    _createVNode(_component_FieldPriceConfigWizardRow, {
      data: _ctx.data,
      errors: _ctx.errors,
      onFieldInput: _ctx.onFieldInput,
      showSwitch: "",
      onSwitch: _ctx.onSwitch
    }, null, 8, ["data", "errors", "onFieldInput", "onSwitch"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getVisibleRows(), (row) => {
      return (_openBlock(), _createBlock(_component_FieldPriceConfigWizardRow, {
        key: row.fullToken,
        data: row,
        errors: _ctx.errors,
        showSwitch: "",
        onFieldInput: _ctx.onFieldInput,
        onSwitch: _ctx.onSwitch
      }, null, 8, ["data", "errors", "onFieldInput", "onSwitch"]))
    }), 128))
  ], 8, _hoisted_1))
}