import { ColorName } from './color';

export enum StyleSpotAttributeVariable {
  MAP_COLOR_FREE = 'mapColorFree',
  MAP_COLOR_RESERVED = 'mapColorReserved',
  MAP_COLOR_RESERVED_FIRST_DAY = 'mapColorReservedFirstDay',
  MAP_COLOR_RESERVED_LAST_DAY = 'mapColorReservedLastDay',
  MAP_COLOR_HALF_DAY_MORNING = 'mapColorHalfDayMorning',
  MAP_COLOR_HALF_DAY_AFTERNOON = 'mapColorHalfDayAfternoon',
  MAP_COLOR_FULL_DAY = 'mapColorFullDay',
  MAP_COLOR_TEMPORARY = 'mapColorTemporary',
  MAP_COLOR_SEASONAL = 'mapColorSeasonal',
  MAP_COLOR_NOT_PAID = 'mapColorNotPaid',
  // MAP_COLOR_OVERBOOKING = 'mapColoOverbooking',
  // MAP_COLOR_SHARING = 'mapColorSharing',
}

export interface StyleSpotAttributeIcons {
  [key: string]: string;
  umbrella: string;
  bed: string;
  cabins: string;
  parking: string;
  gazebo: string;
}

export interface StyleSpotAttribute {
  label: string;
  variable?: StyleSpotAttributeVariable;
  color?: ColorName;
  icons?: StyleSpotAttributeIcons;
  attributeIcon?: string;
  css: {
    [key: string]: string;
    borderColor: string;
    borderWidth: string;
    borderStyle: string;
    backgroundColor: string;
  };
}

export interface StyleSpot {
  [key: string]: StyleSpotAttribute;
  free: StyleSpotAttribute;
  busy: StyleSpotAttribute;
  halfDayMorning: StyleSpotAttribute;
  halfDayAfternoon: StyleSpotAttribute;
  fullDay: StyleSpotAttribute;
  temporary: StyleSpotAttribute;
  seasonal: StyleSpotAttribute;
  overbooking: StyleSpotAttribute;
  sharing: StyleSpotAttribute;
  unpaid: StyleSpotAttribute;
  unpaidLast: StyleSpotAttribute;
}
