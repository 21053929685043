/**
 * Cookie util
 */

import Cookies from 'js-cookie';

const DEFAULT_PATH = '/'; // readable on every route
const DEFAULT_DOMAIN = ''; // sent only on current domain
const DEFAULT_EXPIRATION = 365;

export default {
  set(
    key: string,
    value: string,
    expires: number | Date = DEFAULT_EXPIRATION,
    path = DEFAULT_PATH,
    domain = DEFAULT_DOMAIN,
  ): string | undefined {
    return Cookies.set(key, value, {
      expires,
      path,
      domain,
    });
  },
  get(key: string): string | undefined {
    return Cookies.get(key);
  },
  remove(key: string, path = DEFAULT_PATH, domain = DEFAULT_DOMAIN): void {
    Cookies.remove(key, {
      path,
      domain,
    });
  },
};
