import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-3 pl-4" }
const _hoisted_2 = { class: "col-12 sm:col-6 mt-2" }
const _hoisted_3 = { for: "input-service-group-name" }
const _hoisted_4 = {
  key: 0,
  class: "error-message"
}
const _hoisted_5 = {
  key: 0,
  class: "col-12 mt-2"
}
const _hoisted_6 = { for: "input-service-group-description" }
const _hoisted_7 = { class: "col-12 mt-2" }
const _hoisted_8 = { for: "" }
const _hoisted_9 = { class: "p-field-radio vertical col-12 sm:col-6 pl-0 mt-2" }
const _hoisted_10 = { class: "p-field-radiobutton" }
const _hoisted_11 = { class: "p-field-radiobutton" }
const _hoisted_12 = { class: "p-field" }
const _hoisted_13 = { class: "col-12 mt-2" }
const _hoisted_14 = { for: "" }
const _hoisted_15 = { class: "p-field-radio vertical col-12 sm:col-6 pl-0 mt-2" }
const _hoisted_16 = { class: "p-field-radiobutton" }
const _hoisted_17 = { class: "p-field-radiobutton" }
const _hoisted_18 = { class: "p-field" }
const _hoisted_19 = { class: "flex p-3 justify-content-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Field = _resolveComponent("Field")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    id: "service-groups-dialog",
    modal: true
  }, {
    header: _withCtx(() => [
      _createElementVNode("h6", _hoisted_1, _toDisplayString(this.getServiceGroupTitleDialog()), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_19, [
        _createVNode(_component_Button, {
          class: "p-button-outlined",
          label: _ctx.$t('button.cancel'),
          icon: "spi-times",
          onClick: _cache[13] || (_cache[13] = ($event: any) => (this.visible = false))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          id: "service-group-modal-save-button",
          form: "form-service-group",
          type: "submit",
          icon: "pi pi-save",
          class: "py-2 px-3",
          label: _ctx.$t('button.save')
        }, null, 8, ["label"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        id: "form-service-group",
        onSubmit: _cache[12] || (_cache[12] = ($event: any) => (_ctx.onSaveServiceGroup()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(this.$t('settingsServiceGroupView.dialog.name')), 1),
            _createVNode(_component_Field, {
              name: "input-sector-group-id",
              modelValue: this.model.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((this.model.name) = $event)),
              rules: "required"
            }, {
              default: _withCtx(({ field, meta, errorMessage }) => [
                _createVNode(_component_InputText, _mergeProps({
                  id: "input-service-group-name",
                  "max-length": "50"
                }, field, {
                  class: !meta.valid && meta.touched ? 'mt-2 p-invalid' : 'mt-2'
                }), null, 16, ["class"]),
                errorMessage
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(errorMessage), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["modelValue"])
          ]),
          false
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("label", _hoisted_6, _toDisplayString(this.$t('settingsServiceGroupView.dialog.description')), 1),
                _createVNode(_component_InputText, {
                  id: "input-service-group-description",
                  "max-length": "500",
                  modelValue: this.model.description,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((this.model.description) = $event)),
                  class: "mt-2"
                }, null, 8, ["modelValue"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('settingsServiceGroupView.dialog.dailyLimit')), 1),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_RadioButton, {
                  value: false,
                  modelValue: _ctx.hasDailyLimit,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.hasDailyLimit) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("label", {
                  class: "cursor-pointer",
                  for: "use-cabin-radio",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.hasDailyLimit = false))
                }, _toDisplayString(_ctx.$t('common.unlimited')), 1)
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createVNode(_component_RadioButton, {
                  value: true,
                  modelValue: _ctx.hasDailyLimit,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.hasDailyLimit) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("label", {
                  class: "cursor-pointer",
                  for: "use-cabin-radio",
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.hasDailyLimit = true))
                }, _toDisplayString(_ctx.$t('common.limited')), 1),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_InputNumber, {
                    id: "input-daily-items",
                    step: 1,
                    min: 0,
                    class: "col-6 pt-0 pb-0",
                    modelValue: this.model.dailyLimit,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((this.model.dailyLimit) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('settingsServiceGroupView.dialog.reservationLimit')), 1),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_RadioButton, {
                  value: false,
                  modelValue: _ctx.hasReservationLimit,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.hasReservationLimit) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("label", {
                  for: "use-cabin-radio",
                  class: "cursor-pointer",
                  onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.hasReservationLimit = false))
                }, _toDisplayString(_ctx.$t('common.unlimited')), 1)
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_RadioButton, {
                  value: true,
                  modelValue: _ctx.hasReservationLimit,
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.hasReservationLimit) = $event))
                }, null, 8, ["modelValue"]),
                _createElementVNode("label", {
                  for: "use-cabin-radio",
                  class: "cursor-pointer",
                  onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.hasReservationLimit = true))
                }, _toDisplayString(_ctx.$t('common.limited')), 1),
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(_component_InputNumber, {
                    id: "input-daily-items",
                    step: 1,
                    min: 0,
                    class: "col-6 pt-0 pb-0",
                    modelValue: this.model.reservationLimit,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((this.model.reservationLimit) = $event))
                  }, null, 8, ["modelValue"])
                ])
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}