import axios, { AxiosResponse } from 'axios';
import apiService from './apiService';
import {
  ApiReservationCopyPayload,
  ApiReservationCopyResponse,
  ApiReservationCreateAbsencePayload,
  ApiReservationCreateAbsenceResponse,
  ApiReservationCreateAdditionPayload,
  ApiReservationCreateAdditionResponse,
  ApiReservationCreateGuestResponse,
  ApiReservationCreatePayload,
  ApiReservationCreateResponse,
  ApiReservationCreateStepPayload,
  ApiReservationCreateStepResponse,
  ApiReservationDeletePayload,
  ApiReservationDeleteStepResponse,
  ApiReservationFindPayload,
  ApiReservationFindResponse,
  ApiReservationGetGuestsResponse,
  ApiReservationGetServicesResponse,
  ApiReservationMergePayload,
  ApiReservationMergeResponse,
  ApiReservationMovePayload,
  ApiReservationMoveResponse,
  ApiReservationOneResponse,
  ApiReservationOrderSummaryPayload,
  ApiReservationOrderSummaryResponse,
  ApiReservationSeasonalPayload,
  ApiReservationSeasonalResponse,
  ApiReservationUpdatePayload,
  ApiReservationUpdateResponse,
  ApiReservationUpdateServicePayload,
  ApiReservationUpdateServiceResponse,
  ApiReservationRecycleBinResponse,
  ApiReservationGetDocumentsPayload,
  ApiReservationGetDocumentsResponse,
  ApiReservationCreateDocumentPayload,
  ApiReservationCreateDocumentResponse,
  ApiReservationDeleteDocumentPayload,
  ApiReservationDeleteDocumentResponse,
  ApiReservationDeleteExpensesResponse,
  ApiReservationPayExpensePayload,
  ApiReservationPayExpenseResponse,
  ApiReservationRestoreResponse,
  ApiReservationSendInvoicePayload,
  ApiReservationSendInvoiceResponse,
  ApiPlannerReservationWithStepsPayload,
  ApiPlannerReservationWithStepsResponse,
  ApiReservationUpdateStepPayload,
  ApiReservationUpdateStepResponse,
  ApiReservationCopySeasonalResponse,
  ApiFiscalReservationFlowsPayload,
  ApiReservationGetExpensesResponse,
  ApiReservationDeleteResponse,
  ApiReservationDisjoinAccountPayload,
  ApiReservationDisjoinAccountResponse,
  ApiReservationDiscountCodeResponse,
  ApiReservationSiaeTicketsResponse,
  ApiPaymentLinkFeesPreviewPayload,
  ApiPaymentLinkFeesPreviewResponse,
  ApiBillReservationCreatePayload,
} from '@/models/api';
import {
  PaymentLinkFeesPreview,
  Reservation,
  ReservationDocument,
  ReservationExpandedField,
  ReservationExpense,
  ReservationExpenseRaw,
  ReservationService,
} from '@/models/reservation';
import { Guest } from '@/models/customer';
import { CashFlow } from '@/models/cashFlow';
import { DiscountCode } from '@/models/discountCode';
import { WebticTicket } from '@/models/webtic';

export default {
  async one(
    id: number,
    expand: Array<ReservationExpandedField> = [],
  ): Promise<Reservation> {
    const res: AxiosResponse<ApiReservationOneResponse> = await axios.get(
      apiService.reservation.one(id, expand),
    );
    return res.data.result.reservation;
  },
  async createOne(payload: ApiReservationCreatePayload): Promise<Reservation> {
    const res: AxiosResponse<ApiReservationCreateResponse> = await axios.post(
      apiService.reservation.create(),
      payload,
    );
    return res.data.result.reservations[0];
  },
  async createMany(
    payload: Array<ApiReservationCreatePayload>,
  ): Promise<Array<Reservation>> {
    const res: AxiosResponse<ApiReservationCreateResponse> = await axios.post(
      apiService.reservation.create(),
      {
        reservations: payload,
      },
    );
    return res.data.result.reservations;
  },
  async find(
    payload: ApiReservationFindPayload,
  ): Promise<ApiReservationFindResponse> {
    const res: AxiosResponse<ApiReservationFindResponse> = await axios.get(
      apiService.reservation.find(payload),
    );
    return res.data;
  },
  async update(
    id: number,
    payload: ApiReservationUpdatePayload,
  ): Promise<Reservation> {
    const res: AxiosResponse<ApiReservationUpdateResponse> = await axios.patch(
      apiService.reservation.update(id),
      payload,
    );
    return res.data.result.reservation;
  },
  async seasonal(
    id: number,
    payload: ApiReservationSeasonalPayload,
  ): Promise<Reservation> {
    const res: AxiosResponse<ApiReservationSeasonalResponse> = await axios.put(
      apiService.reservation.seasonal(id),
      payload,
    );
    return res.data.result.reservation;
  },
  async createAbsence(
    id: number,
    payload: ApiReservationCreateAbsencePayload,
  ): Promise<Reservation | null> {
    const res: AxiosResponse<ApiReservationCreateAbsenceResponse> =
      await axios.post(apiService.reservation.createAbsence(id), payload);
    return res.data.result.absence;
  },
  async delete(
    payload: ApiReservationDeletePayload,
  ): Promise<ApiReservationDeleteResponse> {
    const res: AxiosResponse<ApiReservationDeleteResponse> = await axios.delete(
      apiService.reservation.delete(payload),
    );
    return res.data;
  },
  async getServices(id: number): Promise<Array<ReservationService>> {
    const res: AxiosResponse<ApiReservationGetServicesResponse> =
      await axios.get(apiService.reservation.getServices(id));
    return res.data.result.services;
  },
  async updateService(
    id: number,
    payload: ApiReservationUpdateServicePayload,
  ): Promise<ReservationService> {
    const res: AxiosResponse<ApiReservationUpdateServiceResponse> =
      await axios.post(apiService.reservation.updateService(id), payload);
    return res.data.result.service;
  },
  /* GUEST */
  async getGuests(id: number): Promise<Array<Guest>> {
    const res: AxiosResponse<ApiReservationGetGuestsResponse> = await axios.get(
      apiService.reservation.getGuests(id),
    );
    return res.data.result.contacts;
  },
  async createGuest(id: number, payload: Guest): Promise<Guest> {
    const res: AxiosResponse<ApiReservationCreateGuestResponse> =
      await axios.patch(apiService.reservation.createGuest(id), payload);
    return res.data.result.contact;
  },
  async removeGuest(id: number, guestId: number): Promise<any> {
    const res: AxiosResponse<any> = await axios.delete(
      apiService.reservation.removeGuest(id, guestId),
    );
    return res.data.result;
  },
  async createAddition(
    id: number,
    payload: ApiReservationCreateAdditionPayload = {},
  ): Promise<Reservation> {
    const res: AxiosResponse<ApiReservationCreateAdditionResponse> =
      await axios.post(apiService.reservation.createAddition(id), payload);
    return res.data.result.addition;
  },
  async move(
    id: number,
    payload: ApiReservationMovePayload,
  ): Promise<Reservation> {
    const res: AxiosResponse<ApiReservationMoveResponse> = await axios.post(
      apiService.reservation.move(id),
      payload,
    );
    return res.data.result.reservation;
  },
  async createStep(
    id: number,
    payload: ApiReservationCreateStepPayload,
  ): Promise<Reservation> {
    const res: AxiosResponse<ApiReservationCreateStepResponse> =
      await axios.post(apiService.reservation.createStep(id), payload);
    return res.data.result.reservation;
  },
  async updateStep(
    id: number,
    payload: ApiReservationUpdateStepPayload,
  ): Promise<Reservation> {
    const res: AxiosResponse<ApiReservationUpdateStepResponse> =
      await axios.patch(apiService.reservation.updateStep(id), payload);
    return res.data.result.reservation;
  },
  async createReservationWithSteps(
    payload: ApiPlannerReservationWithStepsPayload,
  ): Promise<Reservation> {
    const res: AxiosResponse<ApiPlannerReservationWithStepsResponse> =
      await axios.post(
        apiService.reservation.createReservationWithSteps(),
        payload,
      );
    return res.data.result.reservation;
  },
  async createBillReservation(
    payload: ApiBillReservationCreatePayload,
  ): Promise<Reservation> {
    const res: AxiosResponse<ApiReservationOneResponse> = await axios.post(
      apiService.reservation.createBillReservation(),
      payload,
    );
    return res.data.result.reservation;
  },
  async deleteStep(id: number, stepId: number): Promise<Reservation> {
    const res: AxiosResponse<ApiReservationDeleteStepResponse> =
      await axios.delete(apiService.reservation.deleteStep(id, stepId));
    return res.data.result.reservation;
  },
  async copy(
    id: number,
    payload: ApiReservationCopyPayload,
  ): Promise<Array<Reservation>> {
    const res: AxiosResponse<ApiReservationCopyResponse> = await axios.post(
      apiService.reservation.copy(id),
      payload,
    );
    return res.data.result.reservations;
  },
  async orderSummary(
    id: number,
    payload: ApiReservationOrderSummaryPayload,
  ): Promise<{ id: number }> {
    const res: AxiosResponse<ApiReservationOrderSummaryResponse> =
      await axios.post(apiService.reservation.orderSummary(id), payload);
    return res.data.result;
  },
  async paymentLinkFeesPreview(
    id: number,
    payload: ApiPaymentLinkFeesPreviewPayload,
  ): Promise<PaymentLinkFeesPreview> {
    const res: AxiosResponse<ApiPaymentLinkFeesPreviewResponse> =
      await axios.post(
        apiService.reservation.paymentLinkFeesPreview(id),
        payload,
      );
    return res.data.result;
  },
  async merge(payload: ApiReservationMergePayload): Promise<Reservation> {
    const res: AxiosResponse<ApiReservationMergeResponse> = await axios.post(
      apiService.reservation.merge(),
      payload,
    );
    return res.data.result.reservation;
  },
  async getExpenses(id: number): Promise<Array<ReservationExpenseRaw>> {
    const res: AxiosResponse<ApiReservationGetExpensesResponse> =
      await axios.get(apiService.reservation.getExpenses(id));
    return res.data.result.expenses;
  },
  async deleteExpense(id: number, idExpense: number): Promise<boolean> {
    const res: AxiosResponse<ApiReservationDeleteExpensesResponse> =
      await axios.delete(apiService.reservation.deleteExpense(id, idExpense));
    return res.data.result;
  },
  async payExpense(
    id: number,
    payload: ApiReservationPayExpensePayload,
  ): Promise<ReservationExpense> {
    const res: AxiosResponse<ApiReservationPayExpenseResponse> =
      await axios.post(apiService.reservation.payExpense(id), payload);
    return res.data.result.expense;
  },
  async deleteAll(): Promise<ApiReservationRecycleBinResponse> {
    const res: AxiosResponse<ApiReservationRecycleBinResponse> =
      await axios.delete(apiService.reservation.recycleBin());
    return res.data;
  },
  async getDocuments(
    payload: ApiReservationGetDocumentsPayload,
  ): Promise<Array<ReservationDocument>> {
    const res: AxiosResponse<ApiReservationGetDocumentsResponse> =
      await axios.get(apiService.reservation.getDocuments(payload));
    return res.data.result.documents;
  },
  async createDocument(
    payload: ApiReservationCreateDocumentPayload,
  ): Promise<ReservationDocument> {
    const formData = new FormData();
    formData.append('documentImage', payload.documentImage);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    const res: AxiosResponse<ApiReservationCreateDocumentResponse> =
      await axios.post(
        apiService.reservation.createDocument(payload),
        formData,
        config,
      );
    return res.data.result.document;
  },
  async deleteDocument(
    payload: ApiReservationDeleteDocumentPayload,
  ): Promise<boolean> {
    const res: AxiosResponse<ApiReservationDeleteDocumentResponse> =
      await axios.delete(apiService.reservation.deleteDocument(payload));
    return res.data.result;
  },
  async restore(id: number): Promise<Reservation> {
    const res: AxiosResponse<ApiReservationRestoreResponse> = await axios.post(
      apiService.reservation.restore(id),
    );
    return res.data.result.reservation;
  },
  async sendInvoice(
    id: number,
    payload: ApiReservationSendInvoicePayload,
  ): Promise<ApiReservationSendInvoiceResponse> {
    const res: AxiosResponse<ApiReservationSendInvoiceResponse> =
      await axios.post(apiService.reservation.sendInvoice(), payload);
    return res.data;
  },
  async copySeasonal(): Promise<Array<Reservation>> {
    const res: AxiosResponse<ApiReservationCopySeasonalResponse> =
      await axios.get(apiService.reservation.copySeasonal());
    return res.data.result.reservation;
  },
  async fiscalReservationFlows(
    payload: ApiFiscalReservationFlowsPayload,
    reservationId: number,
  ): Promise<void> {
    await axios.post(
      apiService.reservation.fiscalReservationFlows(reservationId),
      payload,
    );
  },
  async disjoinAccount(
    idJointAccount: number,
    payload: ApiReservationDisjoinAccountPayload,
  ): Promise<Reservation | null> {
    const res: AxiosResponse<ApiReservationDisjoinAccountResponse> =
      await axios.post(
        apiService.reservation.disjoinAccount(idJointAccount),
        payload,
      );
    return res.data.result.reservation;
  },
  async discountCode(id: number): Promise<DiscountCode | null> {
    const res: AxiosResponse<ApiReservationDiscountCodeResponse> =
      await axios.get(apiService.reservation.discountCode(id));
    return res.data.result.discountCode;
  },
  async siaeTickets(id: number): Promise<Array<WebticTicket>> {
    const res: AxiosResponse<ApiReservationSiaeTicketsResponse> =
      await axios.get(apiService.reservation.siaeTickets(id));
    return res.data.result.webticTickets;
  },
  async checkIn(id: number): Promise<void> {
    return axios.post(apiService.reservation.checkIn(id));
  },
  async checkOut(id: number): Promise<void> {
    return axios.post(apiService.reservation.checkOut(id));
  },
};
