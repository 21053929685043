
import { defineComponent } from 'vue';
import _, { cloneDeep } from 'lodash';
import { mapState } from 'vuex';

import serviceGroupDefault from '@/models/serviceGroup/serviceGroupDefault';
import { ServiceGroup } from '@/models/serviceGroup/serviceGroup';
import { EditableServiceGroup } from '@/models/serviceGroup/editableServiceGroup';
import serviceGroupService from '@/services/serviceGroupService';
import SettingsServiceGroupDialog from '@/components/settings/SettingsServiceGroupDialog.vue';
import SettingsTicketLimitsDialog from '@/components/settings/SettingsTicketLimitsDialog.vue';
import { ServiceType } from '@/models/service';
import serviceService from '@/services/serviceService';
import { EditableService } from '@/models/service/editableService';
import priceListService from '@/services/priceListService';
import { PriceList } from '@/models/priceList';
import { DropdownOption } from '@/models/dropdownOption';
import SettingsServiceDialog from '@/components/settings/SettingsServiceDialog.vue';
import DEFAULT_SERVICE from '@/models/service/serviceDefault';
import { LicenseTicketLimits } from '@/models/license';

export default defineComponent({
  name: 'SettingsServiceGroupsView',
  components: {
    SettingsServiceGroupDialog,
    SettingsServiceDialog,
    SettingsTicketLimitsDialog,
  },
  data() {
    return {
      // Dialog
      serviceType: ServiceType.DAILY_TICKET,
      serviceShowDialog: false,
      serviceGroupShowDialog: false,
      settingsShowDialog: false,
      currentServiceGroup: {} as ServiceGroup,
      toSaveServiceGroup: {} as EditableServiceGroup,
      toSaveService: {} as EditableService,
      toSaveTicketLimits: {} as LicenseTicketLimits,
      // Fake flags to enable limits
      priceListOptions: [] as Array<DropdownOption<number>>,
      hasReservationLimit: false,
      hasDailyLimit: false,
    };
  },
  async beforeMount() {
    this.toSaveTicketLimits = _.pick(
        this.license,
        ['ticketMaximumCapacity', 'ticketReservationLimit'],
    );
    // force services / sectors refresh
    const priceLists = await Promise.all([
      this.$store.dispatch('session/setSectors'),
      this.$store.dispatch('session/setServiceGroups'),
      priceListService.find({}),
    ]);
    // price list options
    this.priceListOptions = priceLists[2].map(
      (priceList: PriceList) => ({
        label: priceList.name,
        value: priceList.id,
      }),
    );
  },
  methods: {
    formatServiceGroupName(serviceGroup: ServiceGroup): string {
      let result = serviceGroup.name;
      // Add number of related services
      result += ` (${
        serviceGroup?.serviceCounter ?? 0
      } ${
        this.$t('settingsExtraServicesView.dailyTicketTitle')
      })`;
      return result;
    },
    formatLimit(limit: number): string {
      return limit === 0 ? this.$t('common.none') : String(limit);
    },
    resetInput(): void {
      this.currentServiceGroup = {} as ServiceGroup;
      this.toSaveServiceGroup = {} as EditableServiceGroup;
      this.toSaveService = {} as EditableService;
    },
    onServiceGroupDialogOpen(
        serviceGroupId = 0,
    ): void {
      this.resetInput();
      const foundServiceGroup: ServiceGroup | undefined =
          this.serviceGroups.find(
              (group: ServiceGroup) => group.id === serviceGroupId,
          );
      // Check if the service group exists in the current view
      if (!foundServiceGroup) {
        this.toSaveServiceGroup = cloneDeep(serviceGroupDefault);
      } else {
        // Load data from instance (only partially)
        this.currentServiceGroup = foundServiceGroup;
        this.toSaveServiceGroup = {
          name: foundServiceGroup.name,
          description: foundServiceGroup.description,
          imageUri: foundServiceGroup.imageUri,
          dailyLimit: foundServiceGroup.dailyLimit,
          reservationLimit: foundServiceGroup.reservationLimit,
        };
      }
      this.serviceGroupShowDialog = true;
    },
    onServiceDialogOpen() {
      // init model
      this.toSaveService = cloneDeep(DEFAULT_SERVICE);
      this.toSaveService.type = this.serviceType;
      // show dialog
      this.serviceShowDialog = true;
    },
    onOpenGroupSettings(): void {
      this.settingsShowDialog = true;
    },
    navigateToGroup(serviceGroupId: number): void {
      this.$router.push(`/settings/tickets/${serviceGroupId}`);
    },
    // Event from dialog
    async onSubmit(model: EditableServiceGroup) {
      await this.createServiceGroup(model);
    },
    async onSubmitServiceForm(model: EditableService) {
      await this.createService(model);
    },
    async onSubmitSettings(model: LicenseTicketLimits) {
      await this.saveSettings(model);
    },
    async createService(model: EditableService): Promise<void> {
      /**
       * Create a service
       */
      try {
        await serviceService.create(model);
        this.$spiagge.toast.success(
            this.$t('settingsExtraServicesView.toast.createSuccess'),
        );
        await this.resetInput();
      } catch (e) {
        this.$spiagge.toast.error(
            this.$t('settingsExtraServicesView.toast.createError'),
        );
      }
    },
    async createServiceGroup(model: EditableServiceGroup): Promise<void> {
      try {
        await serviceGroupService.create(model);
        await this.$store.dispatch('session/setServiceGroups');
        this.$spiagge.toast.success(
            this.$t('settingsServiceGroupView.toast.createSuccess'),
        );
      } catch (e) {
        this.$spiagge.toast.error(
            this.$t('settingsServiceGroupView.toast.createError'),
        );
      }
    },
    async saveSettings(model: LicenseTicketLimits): Promise<void> {
      try {
        await this.$store.dispatch('session/updateLicense', model);
        this.$spiagge.toast.success(
            this.$t('settingsServiceGroupView.toast.settingsSaveSuccess'),
        );
      } catch (e) {
        this.$spiagge.toast.error(
            this.$t('settingsWarehouseView.toast.settingsSaveError'),
        );
      }
    },
  },
  computed: {
    ...mapState('session', ['serviceGroups', 'license']),
    computedServiceGroups(): ServiceGroup[] {
      return cloneDeep(this.serviceGroups);
    },
  },
});
