import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2528a66a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "reservation-alerts"
}
const _hoisted_2 = { class: "title" }
const _hoisted_3 = {
  key: 0,
  class: "description"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "title" }
const _hoisted_6 = {
  key: 0,
  class: "description"
}
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!

  return (_ctx.hasAlerts)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.errorAlerts.length > 0)
          ? (_openBlock(), _createBlock(_component_Message, {
              key: 0,
              severity: "error",
              closable: false
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errorAlerts, (alert, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "error alert"
                  }, [
                    _createElementVNode("span", _hoisted_2, _toDisplayString(alert.title), 1),
                    (alert.description)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(alert.description), 1))
                      : _createCommentVNode("", true),
                    (alert.cta)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          class: "cta",
                          onClick: ($event: any) => (alert?.cta?.action())
                        }, _toDisplayString(alert.cta.label), 9, _hoisted_4))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_ctx.warningAlerts.length > 0)
          ? (_openBlock(), _createBlock(_component_Message, {
              key: 1,
              severity: "warn",
              closable: false
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.warningAlerts, (alert, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: index,
                    class: "warning alert"
                  }, [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(alert.title), 1),
                    (alert.description)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(alert.description), 1))
                      : _createCommentVNode("", true),
                    (alert.cta)
                      ? (_openBlock(), _createElementBlock("span", {
                          key: 1,
                          class: "cta",
                          onClick: ($event: any) => (alert?.cta?.action())
                        }, _toDisplayString(alert.cta.label), 9, _hoisted_7))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}