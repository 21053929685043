
import { defineComponent, PropType } from 'vue';
import { cloneDeep } from 'lodash';
import { Customer } from '@/models/customer';
import CountryDropdown from '@/components/shared/CountryDropdown.vue';

export default defineComponent({
  name: 'InvoiceDialog',
  components: {
    CountryDropdown,
  },
  emits: ['updateCustomer', 'invoicePayment', 'closeInvoiceDialog'],
  props: {
    sendData: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object as PropType<Customer>,
      require: true,
      default: {} as Customer,
    },
    invoiceDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localCustomer: cloneDeep(this.customer),
      localInvoiceDialog: this.invoiceDialog,
    };
  },
  methods: {
    updateCustomer(value: FocusEvent, key: string): void {
      this.$emit('updateCustomer', { key, value });
    },
    invoicePayment(): void {
      this.$emit('invoicePayment', this.localCustomer);
    },
  },
  computed: {
    showInvoiceDialog: {
      get(): boolean {
        return this.localInvoiceDialog;
      },
      set(value: boolean): void {
        this.localInvoiceDialog = value;
        if (!value) {
          this.$emit('closeInvoiceDialog');
        }
      },
    },
  },
});
