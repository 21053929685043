
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import {
  StatisticDataTableColumn,
  StatisticDataTableView,
} from '@/models/statistic';
import StatisticsDataTable from '@/components/statistics/StatisticsDataTable.vue';
import statisticService from '@/services/statisticService';
import spotUtil from '@/utils/spotUtil';
import { SpotType } from '@/models/spot';

export default defineComponent({
  name: 'StatisticsSpotView',
  components: { StatisticsDataTable },
  data() {
    return {
      id: `spot-${this.$route.params.spotType}`,
      endpoint: statisticService.spot,
      exportEndpoint: statisticService.exportSpotAnalytics,
      columns: [
        {
          label: this.$t('statisticsSpotView.column.spotName'),
          field: 'spotName',
          sortable: true,
        },
        {
          label: this.$t('statisticsSpotView.column.sector'),
          field: 'sector',
          sortable: true,
        },
        {
          label: this.$t('statisticsSpotMapView.option.reservations'),
          field: 'reservations',
          sortable: true,
        },
        {
          label: this.getSpotReservationsColumnName(),
          field: 'spotReservations',
          sortable: true,
        },
        {
          label: this.$t('statisticsSpotMapView.option.extraReservations'),
          field: 'extraReservations',
          sortable: true,
        },
        {
          label: this.$t('statisticsSpotView.column.people'),
          field: 'people',
          sortable: true,
        },
        {
          label: this.$t('statisticsSpotView.column.totalDays'),
          field: 'totalDays',
          sortable: true,
        },
        {
          label: this.$t('statisticsSpotView.column.totalRevenue'),
          field: 'totalRevenue',
          sortable: true,
          formatter: (value: number) => `${value} €`,
        },
        {
          label: this.$t('statisticsSpotView.column.averageRevenue'),
          field: 'averageRevenue',
          sortable: true,
          formatter: (value: number) => `${value} €`,
        },
        {
          label: this.$t('statisticsSpotView.column.averageCart'),
          field: 'averageCart',
          sortable: true,
          formatter: (value: number) => `${value} €`,
        },
        {
          label: this.$t('statisticsSpotView.column.productivity'),
          field: 'productivity',
          sortable: true,
          formatter: (value: number) => `${value} €`,
        },
        {
          label: this.$t('statisticsSpotView.column.arrivedReservations'),
          field: 'arrivedReservations',
          sortable: true,
        },
        {
          label: this.$t('statisticsSpotView.column.arrivedPeople'),
          field: 'arrivedPeople',
          sortable: true,
        },
        {
          label: this.$t('statisticsSpotView.column.arrivalsDays'),
          field: 'arrivalsDays',
          sortable: true,
        },
        {
          label: this.$t('statisticsSpotView.column.arrivalsRevenue'),
          field: 'arrivalsRevenue',
          sortable: true,
          formatter: (value: number) => `${value} €`,
        },
        {
          label: this.$t('statisticsSpotView.column.cashFlow'),
          field: 'cashFlow',
          sortable: true,
          formatter: (value: number) => `${value} €`,
        },
      ] as Array<StatisticDataTableColumn>,
      basePayload: {
        filters: [
          {
            name: 'spotType',
            value: this.$route.params.spotType,
          },
        ],
      },
      searchColumns: [],
      defaultView: {
        id: DateTime.now().startOf('day').toSeconds(),
        label: this.$t('statisticsSpotView.defaultViewLabel'),
        columns: [
          'spotName',
          'sector',
          'reservations',
          'spotReservations',
          'extraReservations',
          'people',
          'totalDays',
          'totalRevenue',
          'averageRevenue',
          'averageCart',
          'productivity',
          'arrivalsDays',
          'arrivedReservations',
          'arrivedPeople',
          'arrivalsRevenue',
          'cashFlow',
        ],
        orderBy: [],
      } as StatisticDataTableView,
    };
  },
  async beforeMount(): Promise<void> {
    const spotType = this.$route.params.spotType as SpotType;
    const spotTypeLabel = spotUtil.getLabel(spotType);
    this.$store.commit(
      'statistic/setTitle',
      this.$t('statisticsSpotView.setTitle', { spotTypeLabel }),
    );
  },
  methods: {
    getSpotReservationsColumnName(): string {
      return this.$route.params.spotType === SpotType.CABIN
          ? this.$t('statisticsSpotMapView.option.cabinReservations')
          : this.$t('statisticsSpotMapView.option.umbrellaReservations');
    },
    onBackToMapView(): void {
      this.$router.push(`/statistics/spot/${this.$route.params.spotType}/map`);
    },
  },
});
