import axios, { AxiosResponse } from 'axios';
import {
  ApiPriceConfigCloneResponse,
  ApiPriceConfigCreatePayload,
  ApiPriceConfigCreateResponse,
  ApiPriceConfigEditResponse,
  ApiPriceConfigGetResponse,
  ApiPriceConfigListResponse,
  ApiPriceConfigUpdatePayload,
  ApiPriceConfigUpdateResponse,
  ApiPriceConfigWizardResponse,
} from '@/models/api';
import apiService from './apiService';
import { PriceConfig, PriceConfigWizard, PriceWizardStep } from '@/models/priceConfig';
import { PriceListType } from '@/models/priceList';

export default {
  async wizard(priceConfigId?: number, priceListType?: PriceListType): Promise<PriceConfigWizard> {
    const res: AxiosResponse<ApiPriceConfigWizardResponse> = await axios.get(
      apiService.priceConfig.wizard(priceConfigId, priceListType),
    );
    return res.data.result.priceConfigStructure;
  },
  async get(priceConfigId?: number): Promise<PriceConfig> {
    const res: AxiosResponse<ApiPriceConfigGetResponse> = await axios.get(
      apiService.priceConfig.get(priceConfigId),
    );
    return res.data.result.priceConfig;
  },
  async list(): Promise<Array<PriceConfig>> {
    const res: AxiosResponse<ApiPriceConfigListResponse> = await axios.get(
      apiService.priceConfig.list(),
    );
    return res.data.result.priceConfigs;
  },
  async create(payload: ApiPriceConfigCreatePayload): Promise<PriceConfig> {
    const res: AxiosResponse<ApiPriceConfigCreateResponse> = await axios.post(
      apiService.priceConfig.create(),
      payload,
    );
    return res.data.result.priceConfig;
  },
  async update(
    priceConfigId: number,
    payload: ApiPriceConfigUpdatePayload,
  ): Promise<PriceConfig> {
    const res: AxiosResponse<ApiPriceConfigUpdateResponse> = await axios.patch(
      apiService.priceConfig.update(priceConfigId),
      payload,
    );
    return res.data.result.priceConfig;
  },
  async delete(priceConfigId: number): Promise<void> {
    return axios.delete(apiService.priceConfig.delete(priceConfigId));
  },
  async generate(priceConfigId: number): Promise<void> {
    return axios.post(apiService.priceConfig.generate(priceConfigId));
  },
  async takeOwnership(priceConfigId: number): Promise<void> {
    return axios.post(apiService.priceConfig.takeOwnership(priceConfigId));
  },
  async edit(priceConfigId: number, forcedStep?: PriceWizardStep | null): Promise<PriceConfig> {
    const res: AxiosResponse<ApiPriceConfigEditResponse> = await axios.post(
      apiService.priceConfig.edit(priceConfigId),
 { forcedStep },
    );
    return res.data.result.priceConfig;
  },
  async clone(priceListId: number, name: string): Promise<PriceConfig> {
    const res: AxiosResponse<ApiPriceConfigCloneResponse> = await axios.post(
        apiService.priceConfig.clone(priceListId),
        { name },
    );
    return res.data.result.priceConfig;
  },
};
