/* eslint-disable import/no-cycle */
/**
 * Price lists store module
 */

import cloneDeep from 'lodash/cloneDeep';
import { MutationTree, ActionTree, ActionContext, GetterTree } from 'vuex';
import {
  RootState,
  PriceListsState,
  DEFAULT_PRICE_LISTS_STATE,
} from '@/models/store';
import priceListService from '@/services/priceListService';
import { PriceList } from '@/models/priceList';
import voucherService from '@/services/voucherService';
import { Voucher } from '@/models/voucher';
import { PriceConfig } from '@/models/priceConfig';
import priceConfigService from '@/services/priceConfigService';
import { ApiPriceListFindPayload } from '@/models/api';

type PriceListsContext = ActionContext<PriceListsState, RootState>;

const namespaced = true;

const state = (): PriceListsState => cloneDeep(DEFAULT_PRICE_LISTS_STATE);

const getters: GetterTree<PriceListsState, RootState> = {
  wizardEnabled: (state, getters, rootState) =>
    !!rootState.session.license?.priceListWizardEnabled,
  priceLists: (state) => state.priceLists,
  priceConfigs: (state) => state.priceConfigs,
  vouchers: (state) => state.vouchers,
} as GetterTree<PriceListsState, RootState>;

const mutations: MutationTree<PriceListsState> = {
  setPriceLists(state: PriceListsState, priceLists: Array<PriceList>) {
    state.priceLists = priceLists;
  },
  setPriceConfigs(state: PriceListsState, priceConfigs: Array<PriceConfig>) {
    state.priceConfigs = priceConfigs;
  },
  setVouchers(state: PriceListsState, vouchers: Array<Voucher>) {
    state.vouchers = vouchers;
  },
  setShowVoucherCreateDialog(
    state: PriceListsState,
    showVoucherCreateDialog: boolean,
  ) {
    state.showVoucherCreateDialog = showVoucherCreateDialog;
  },
  setShowPriceListCreateDialog(
    state: PriceListsState,
    showPriceListCreateDialog: boolean,
  ) {
    state.showPriceListCreateDialog = showPriceListCreateDialog;
  },
  setShowSeasonalSettingsDialog(
    state: PriceListsState,
    showSeasonalSettingsDialog: boolean,
  ) {
    state.showSeasonalSettingsDialog = showSeasonalSettingsDialog;
  },
  setShowPriceListCloneDialog(
      state: PriceListsState,
      showSeasonalSettingsDialog: boolean,
  ) {
    state.showSeasonalSettingsDialog = showSeasonalSettingsDialog;
  },
} as MutationTree<PriceListsState>;

const actions: ActionTree<PriceListsState, RootState> = {
  async init(context: PriceListsContext): Promise<void> {
    const res = await Promise.all([
      priceListService.find({
        includeDrafts: true,
      } as ApiPriceListFindPayload),
      priceConfigService.list(),
      voucherService.list(),
    ]);
    context.commit('setPriceLists', res[0]);
    context.commit('setPriceConfigs', res[1]);
    context.commit('setVouchers', res[2]);
  },
  async getPriceLists(context: PriceListsContext): Promise<void> {
    const priceLists: Array<PriceList> = await priceListService.find({
      includeDrafts: true,
    } as ApiPriceListFindPayload);
    context.commit('setPriceLists', priceLists);
  },
  async getVouchers(context: PriceListsContext): Promise<void> {
    const vouchers: Array<Voucher> = await voucherService.list();
    context.commit('setVouchers', vouchers);
  },
} as ActionTree<PriceListsState, RootState>;

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
