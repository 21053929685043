
import { defineComponent } from 'vue';
import { debounce } from 'lodash';
import { mapState } from 'vuex';
import OverlayPanel from 'primevue/overlaypanel';
import searchService from '@/services/searchService';
import { SearchResult } from '@/models/search';

export default defineComponent({
  name: 'TopBarSearch',
  data() {
    return {
      show: false,
      searching: false,
      searched: false,
      searchQuery: '',
      results: [] as SearchResult,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      runSearch: debounce(this.runSearchDebounced as any, 500),
      panelWidth: 0,
    };
  },
  methods: {
    onOverlayHide(): void {
      /**
       * Handle overlay hide
       */
      this.close();
    },
    close(): void {
      /**
       * Reset search query, results searched flag and hide the overlay panel
       */
      this.searchQuery = '';
      this.results = [];
      this.searched = false;
      (this.$refs.op as OverlayPanel).hide();
    },
    async runSearchDebounced(): Promise<void> {
      /**
       * Run search
       */
      // no search query -> reset
      if (this.searchQuery === '') {
        this.results = [];
        (this.$refs.op as OverlayPanel).hide();
        return;
      }
      this.searching = true;
      try {
        const service =
          this.$route.name === 'LicenceSelect'
            ? searchService.searchLicenses
            : searchService.search;

        this.results = await service(this.searchQuery);
      } catch (e) {
        // console.log(e);
      } finally {
        this.searching = false;
        this.searched = true;
        // open panel manually
        (this.$refs.op as OverlayPanel).show(
          new MouseEvent('click'),
          (this.$refs.searchInput as any).$el,
        );
        // set panel width equal to search input
        this.panelWidth = (this.$refs.searchInput as any).$el.offsetWidth;
      }
    },
    onItemClick(data: any): void {
      /**
       * On item click
       */
      const option = data.value;
      if (option.route) {
        this.$router.push(option.route);
      } else if (option.href) {
        window.open(option.href, '_blank');
      } else if (option.callback) {
        option.callback(this);
      }
      this.close();
    },
  },
  computed: {
    ...mapState('app', ['breakpoints']),
  },
});
