import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14d9edf7"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "statistics-bar-chart" }
const _hoisted_2 = {
  key: 0,
  class: "label"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("h6", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    (_ctx.ready)
      ? (_openBlock(), _createBlock(_component_Chart, {
          key: 1,
          class: "no-print",
          ref: "chart",
          type: "bar",
          data: _ctx.chartData,
          options: _ctx.options,
          plugins: _ctx.plugins,
          style: _normalizeStyle({ height: _ctx.height ? _ctx.height + 'px' : 'auto' })
        }, null, 8, ["data", "options", "plugins", "style"]))
      : (_openBlock(), _createBlock(_component_Skeleton, {
          key: 2,
          height: "300px",
          width: "100%"
        })),
    _createElementVNode("img", {
      class: "canvas-image print-only",
      src: _ctx.canvasImage,
      style: { width: '100%', height: 'auto' }
    }, null, 8, _hoisted_3)
  ]))
}