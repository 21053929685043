import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  id: "revenue-management-planner-view",
  class: "revenue-management-view view"
}
const _hoisted_2 = { class: "day-name" }
const _hoisted_3 = { class: "day-number" }
const _hoisted_4 = { class: "actions" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "infos" }
const _hoisted_7 = { class: "info inverted" }
const _hoisted_8 = { class: "label" }
const _hoisted_9 = { class: "value" }
const _hoisted_10 = { class: "info inverted" }
const _hoisted_11 = { class: "label" }
const _hoisted_12 = { class: "value" }
const _hoisted_13 = { class: "legend" }
const _hoisted_14 = { class: "label" }
const _hoisted_15 = { class: "label" }
const _hoisted_16 = {
  key: 0,
  class: "period-hints"
}
const _hoisted_17 = { class: "hint-apply-mode" }
const _hoisted_18 = { class: "p-field hint-apply-mode-field" }
const _hoisted_19 = { class: "label" }
const _hoisted_20 = { class: "p-field-radio vertical" }
const _hoisted_21 = ["for"]
const _hoisted_22 = {
  key: 1,
  class: "selected-day-hint"
}
const _hoisted_23 = { class: "rules" }
const _hoisted_24 = { class: "number" }
const _hoisted_25 = { class: "details" }
const _hoisted_26 = { class: "info inverted" }
const _hoisted_27 = { class: "label" }
const _hoisted_28 = { class: "value" }
const _hoisted_29 = { class: "info inverted" }
const _hoisted_30 = { class: "label" }
const _hoisted_31 = { class: "value" }
const _hoisted_32 = { class: "info inverted" }
const _hoisted_33 = { class: "label" }
const _hoisted_34 = { class: "value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_RevenueManagementChangeWeek = _resolveComponent("RevenueManagementChangeWeek")!
  const _component_Column = _resolveComponent("Column")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_RevenueManagementCard = _resolveComponent("RevenueManagementCard")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_RevenueManagementPriceAlterationDialog = _resolveComponent("RevenueManagementPriceAlterationDialog")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t('revenueManagementPlannerView.title')), 1),
      (
          _ctx.permissionsUtil.isActionPermissionAllowed(
            _ctx.FEATURE_PERMISSION_CONFIG.revenue_manager,
            _ctx.FEATURE_PERMISSION_ACTION_CONFIG.revenue_manager.ANALYST_PANEL,
          )
        )
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/revenue-management/analyst'))),
            class: "p-button-outlined",
            icon: "spi-statistics",
            label: _ctx.$t('revenueManagementPlannerView.analyst')
          }, null, 8, ["label"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_DataTable, {
      value: _ctx.datatableData,
      scrollable: "",
      scrollHeight: `calc(100vh - ${_ctx.breakpoints.mobile ? 260 : 300}px)`
    }, {
      header: _withCtx(() => [
        _createVNode(_component_RevenueManagementChangeWeek, { onChangeWeek: _ctx.onChangeWeek }, null, 8, ["onChangeWeek"])
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.$t('revenueManagementPlannerView.legend')), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.priceStatusData, (legendItem, legendIndex) => {
            return (_openBlock(), _createElementBlock("div", {
              key: legendIndex,
              class: "item"
            }, [
              _createElementVNode("span", {
                class: "color",
                style: _normalizeStyle({ backgroundColor: legendItem.color })
              }, null, 4),
              _createElementVNode("span", _hoisted_15, _toDisplayString(legendItem.label), 1)
            ]))
          }), 128))
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "sectorName",
          header: _ctx.$t('revenueManagementPlannerView.sectors'),
          frozen: ""
        }, null, 8, ["header"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.range, (day, dayIndex) => {
          return (_openBlock(), _createBlock(_component_Column, {
            key: dayIndex,
            field: `day${dayIndex}`,
            headerClass: 
          day.toSeconds() === _ctx.selectedDate.toSeconds() ? 'current' : ''
        
          }, {
            header: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(day.toLocaleString({ month: 'short' }, { locale: _ctx.$i18n.locale })), 1),
              _createElementVNode("span", _hoisted_3, _toDisplayString(day.toLocaleString({ day: 'numeric' }, { locale: _ctx.$i18n.locale })), 1),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_Button, {
                  icon: "pi pi-info-circle",
                  class: "info-button p-button-rounded p-button-text",
                  onClick: _withModifiers(($event: any) => (_ctx.onOpenDetail(day, dayIndex, $event as PointerEvent)), ["stop"]),
                  onFocusout: ($event: any) => (_ctx.onCloseDetail(dayIndex))
                }, null, 8, ["onClick", "onFocusout"]),
                _createVNode(_component_OverlayPanel, {
                  id: "slot-detail-overlay-panel",
                  ref: `op-${dayIndex}`
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('revenueManagementPlannerView.detailTitle')), 1),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('revenueManagementPlannerView.occupancy')) + ":", 1),
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.headerSlotDetail.occupancy) + "%", 1)
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('revenueManagementPlannerView.revenue')) + ":", 1),
                        _createElementVNode("span", _hoisted_12, "€ " + _toDisplayString(_ctx.headerSlotDetail.revenue), 1)
                      ])
                    ])
                  ]),
                  _: 2
                }, 1536)
              ])
            ]),
            body: _withCtx((slotProps) => [
              _withDirectives(_createVNode(_component_RevenueManagementCard, {
                priceAlteration: slotProps.data[`day${dayIndex}`].priceAlteration,
                prices: slotProps.data[`day${dayIndex}`].prices,
                priceHint: slotProps.data[`day${dayIndex}`].priceHint,
                day: day,
                sectorId: slotProps.data.sectorId,
                onAcceptHint: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onAcceptHint($event))),
                onDeclineHint: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onDeclineHint($event))),
                onDelete: _ctx.onPriceAlterationDelete,
                onClick: ($event: any) => (
              _ctx.onCard(
                slotProps.data[`day${dayIndex}`].priceAlteration,
                day,
                slotProps.data['sectorId'],
              )
            )
              }, null, 8, ["priceAlteration", "prices", "priceHint", "day", "sectorId", "onDelete", "onClick"]), [
                [_directive_ripple]
              ])
            ]),
            _: 2
          }, 1032, ["field", "headerClass"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "scrollHeight"]),
    (_ctx.priceAlterationDialog)
      ? (_openBlock(), _createBlock(_component_RevenueManagementPriceAlterationDialog, {
          key: 0,
          priceAlteration: _ctx.priceAlteration,
          prices: _ctx.prices,
          onDelete: _ctx.getData,
          onClose: _ctx.onPriceAlterationClose,
          onSave: _ctx.onPriceAlterationSave
        }, null, 8, ["priceAlteration", "prices", "onDelete", "onClose", "onSave"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Dialog, {
      id: "hint-accept-dialog",
      visible: _ctx.hintAcceptDialog,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.hintAcceptDialog) = $event)),
      class: "dialog",
      modal: "",
      onHide: _cache[6] || (_cache[6] = ($event: any) => (_ctx.hintAcceptDialog = false))
    }, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.periodHints.length > 1
            ? _ctx.$t('revenueManagementPlannerView.hint.days')
            : _ctx.priceHintOverwrite
            ? _ctx.$t('revenueManagementPlannerView.hint.override')
            : _ctx.$t('revenueManagementPlannerView.hint.newRule')), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button-outlined",
          label: _ctx.$t('button.cancel'),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.hintAcceptDialog = false))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: 
            _ctx.priceHintOverwrite ? _ctx.$t('button.overwrite') : _ctx.$t('button.apply')
          ,
          onClick: _ctx.acceptHint
        }, null, 8, ["label", "onClick"])
      ]),
      default: _withCtx(() => [
        (_ctx.periodHints.length > 1)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('revenueManagementPlannerView.hint.period')), 1),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t('revenueManagementPlannerView.applyRule')), 1),
                  _createElementVNode("div", _hoisted_20, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hintApplyModeOptions, (option, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "p-field-radiobutton"
                      }, [
                        _createVNode(_component_RadioButton, {
                          value: option.mode,
                          modelValue: _ctx.hintApplyMode,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.hintApplyMode) = $event))
                        }, null, 8, ["value", "modelValue"]),
                        _createElementVNode("label", {
                          for: `${option.mode}-radio`
                        }, [
                          _createTextVNode(_toDisplayString(option.label) + " ", 1),
                          _createElementVNode("strong", null, _toDisplayString(_ctx.getHintApplyModeDetail(option.mode)), 1)
                        ], 8, _hoisted_21)
                      ]))
                    }), 128))
                  ])
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_22))
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      id: "hint-decline-dialog",
      visible: _ctx.hintDeclineDialog,
      "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.hintDeclineDialog) = $event)),
      class: "dialog",
      modal: "",
      onHide: _cache[9] || (_cache[9] = ($event: any) => (_ctx.hintDeclineDialog = false))
    }, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('revenueManagementPlannerView.deleteHintConfirm')), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button-outlined p-button-danger",
          label: _ctx.$t('button.cancel'),
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.hintDeclineDialog = false))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          class: "p-button-danger",
          label: _ctx.$t('revenueManagementPlannerView.deleteHint'),
          onClick: _ctx.declineHint
        }, null, 8, ["label", "onClick"])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      id: "price-alteration-override-dialog",
      visible: _ctx.priceAlterationOverrideDialog,
      "onUpdate:visible": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.priceAlterationOverrideDialog) = $event)),
      class: "dialog",
      modal: "",
      onHide: _cache[12] || (_cache[12] = ($event: any) => (_ctx.priceAlterationOverrideDialog = false))
    }, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('revenueManagementPlannerView.overrideRuleTitle')), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button-outlined",
          label: _ctx.$t('button.cancel'),
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.priceAlterationOverrideDialog = false))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('button.apply'),
          onClick: _ctx.onPriceAlterationOverrideSave
        }, null, 8, ["label", "onClick"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('revenueManagementPlannerView.overrideRuleConfirm')), 1),
        _createElementVNode("div", _hoisted_23, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.priceAlterationsOverride, (rule, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "rule"
            }, [
              _createElementVNode("div", _hoisted_24, _toDisplayString(index + 1), 1),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", _hoisted_26, [
                  _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.$t('revenueManagementPlannerView.day')) + ":", 1),
                  _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.$spiagge.utils.date.fromSeconds(rule.day, 'dd/MM/yyyy')), 1)
                ]),
                _createElementVNode("div", _hoisted_29, [
                  _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.$t('revenueManagementPlannerView.percentage')) + ":", 1),
                  _createElementVNode("span", _hoisted_31, _toDisplayString(rule.percentageDiscount) + "% ", 1)
                ]),
                _createElementVNode("div", _hoisted_32, [
                  _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.$t('revenueManagementPlannerView.sector')) + ":", 1),
                  _createElementVNode("span", _hoisted_34, _toDisplayString(rule.sectorId), 1)
                ])
              ])
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}