/**
 * i18n
 */

import { createI18n } from 'vue-i18n';
import { Locale } from '@/models/locale';
import localeUtil from '@/utils/localeUtil';

/**
 * Return messages for each json locale file
 * @returns Record<string, Record<string, string>>
 */
function getLocaleMessages(): Record<string, Record<string, string>> {
  const locales = require.context('./', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: Record<string, Record<string, string>> = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    const locale = matched && matched.length > 1 ? matched[1] : undefined;
    if (locale) {
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const defaultLocale: Locale = localeUtil.getDefaultLocale();

const options = {
  locale: defaultLocale.id,
  fallbackLocale: 'it',
  messages: getLocaleMessages(),
};

const i18n = createI18n(options);

export default i18n;
