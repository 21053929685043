/**
 * Printer service
 */

import axios, { AxiosResponse } from 'axios';
import {
  ApiPrinterListResponse,
  ApiPrinterDeleteResponse,
  ApiPrinterResponse,
  ApiPrinterCreatePayload,
  ApiPrinterUpdatePayload,
} from '@/models/api';
import { Printer } from '@/models/printer';
import apiService from '@/services/apiService';

export default {
  async list(): Promise<Array<Printer>> {
    const res: AxiosResponse<ApiPrinterListResponse> = await axios.get(
      apiService.printer.list(),
    );
    return res.data.result.printers;
  },
  async delete(id: number): Promise<string> {
    const res: AxiosResponse<ApiPrinterDeleteResponse> = await axios.delete(
      apiService.printer.delete(id),
    );
    return res.data.result.status;
  },
  async update(id: number, payload: ApiPrinterUpdatePayload): Promise<Printer> {
    const res: AxiosResponse<ApiPrinterResponse> = await axios.patch(
      apiService.printer.update(id),
      payload,
    );
    return res.data.result.printer;
  },
  async create(payload: ApiPrinterCreatePayload): Promise<Printer> {
    const res: AxiosResponse<ApiPrinterResponse> = await axios.post(
      apiService.printer.create(),
      payload,
    );
    return res.data.result.printer;
  },
};
