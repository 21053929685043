
import { defineComponent } from 'vue';
import { Printer } from '@/models/printer';
import cookieUtil from '@/utils/cookieUtil';
import printerUtil from '@/utils/printerUtil';

export default defineComponent({
  name: 'TopBarCardReader',
  data() {
    return {
      cardDialog: false,
      cardCustomerName: '',
      cardHotelRoom: '',
      cardReservationNumber: null,
      cardReservationCheckIn: null,
      cardReservationCheckOut: null,
      cardReservationAdultsNum: null,
      cardReservationChildrenNum: null,
      cardReservationService: null,
      cardWallets: [] as Array<{ number: string; balance: number }>,
      cardPriceMode: null,
      cardReservationAgency: null,
      cardReservationOrigin: null,
    };
  },
  async beforeMount() {
    await this.$store.dispatch('session/setPrinters');
    this.$spiagge.utils.printMode.setup();
  },
  methods: {
    close(): void {
      this.cardDialog = false;
    },
    onReadCard(printer: Printer): void {
      printerUtil.readCard(printer.id, (response: any) => {
        // console.log(response);
        if (response && response.data) {
          const data = response.data;
          this.cardDialog = true;
          this.cardCustomerName = data.partner_id.name
            ? data.partner_id.name
            : '';

          this.cardHotelRoom = data.reservation_id.room;

          this.cardReservationNumber = data.reservation_id.external_code;
          this.cardReservationCheckIn = data.reservation_id.checkin;
          this.cardReservationCheckOut = data.reservation_id.checkout;
          this.cardReservationAdultsNum = data.reservation_id.adults;
          this.cardReservationChildrenNum = data.reservation_id.children;
          this.cardReservationService = data.reservation_id.service_type_name;
          this.cardWallets = [];
          if (data.wallet_ids) {
            for (let i = 0; i < data.wallet_ids.length; i += 1) {
              this.cardWallets.push({
                number: data.wallet_ids[i].wallet_id.name,
                balance: data.wallet_ids[i].balance,
              });
            }
          }
          if (
            data.partner_id.clusters &&
            Object.getOwnPropertyNames(data.partner_id.clusters)
          ) {
            this.cardPriceMode =
              data.partner_id.clusters[
                Object.getOwnPropertyNames(data.partner_id.clusters)[0]
              ].ClusterName;
          } else {
            this.cardPriceMode = null;
          }
          this.cardReservationAgency = data.reservation_id.travel_agent_name;
          this.cardReservationOrigin = data.reservation_id.source_name;
        }
      });
    },
  },
  computed: {
    cardReaders(): Array<Printer> {
      let printers = this.$store.getters['session/fiscalPrinters'].filter(
        (p: Printer) => !cookieUtil.get(`spit_prt-hide-${p.id}`),
      );
      // Considero solo la stampante con lettore tessere
      // eslint-disable-next-line no-confusing-arrow
      printers = printers.filter((p: Printer) => p.model && p.model === 63);
      return printers;
    },
    cardReader(): Printer | null {
      if (this.cardReaders.length > 0) {
        return this.cardReaders[0];
      }
      return null;
    },
  },
});
