<template>
  <div class="warning-message">
    <i class="pi pi-exclamation-circle" />

    <span>
      Questa icona indica la presenza di un ombrellone senza prenotazione ma con
      almeno un lettino aperto
    </span>
  </div>
</template>

<style scoped>
.warning-message {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border: 1px solid #d4d9df;
  border-radius: 8px;
  max-width: 400px;
  background-color: var(--surface-0);
}

i {
  color: #d80e22;
  font-size: 16px;
}

span {
  font-weight: 700;
}
</style>
