
import { DateTime } from 'luxon';
import { defineComponent } from 'vue';
import OverlayPanel from 'primevue/overlaypanel';
import { clone, cloneDeep, debounce } from 'lodash';
import { mapGetters, mapState } from 'vuex';
import i18n from '@/i18n';
import RevenueManagementPriceAlterationDialog from '@/components/revenue-management/RevenueManagementPriceAlterationDialog.vue';
import { PriceAlteration } from '@/models/priceAlteration';
import RevenueManagementCard from '@/components/revenue-management/RevenueManagementCard.vue';
import {
  RevenueManagementPriceHint,
  RevenueManagementPriceHintStatus,
  RevenueManagementPrices,
  RevenueManagementSlotDetail,
} from '@/models/revenueManagement';
import revenueManagementService from '@/services/revenueManagementService';
import {
  ApiRevenueManagementPriceAlterationCreatePayload,
  ApiRevenueManagementPriceHintListPayload,
  ApiRevenueManagementPriceAlterationListPayload,
  ApiRevenueManagementPriceHintHandlePayload,
  ApiRevenueManagementSlotDetailPayload,
} from '@/models/api';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';
import { Sector } from '@/models/sector';
import RevenueManagementChangeWeek from '@/components/revenue-management/RevenueManagementChangeWeek.vue';

/**
 * IMPORTANT: Avoided use luxon for range computation because
 * lack of performance when add/substract days
 */

const DAY_SECONDS = 86400;

enum HintApplyMode {
  SELECTED_DAY = 'selected-day',
  PERIOD = 'period',
}

interface HintApplyModeData {
  label: string;
  mode: HintApplyMode;
}

const HINT_APPLY_MODE_OPTIONS: Array<HintApplyModeData> = [
  {
    label: i18n.global.t(
      'revenueManagementPlannerView.hintApplyMode.selectedDay',
    ),
    mode: HintApplyMode.SELECTED_DAY,
  },
  {
    label: i18n.global.t('revenueManagementPlannerView.hintApplyMode.period'),
    mode: HintApplyMode.PERIOD,
  },
];

interface DayData {
  priceAlteration: PriceAlteration | null;
  prices: RevenueManagementPrices | null;
  priceHint: RevenueManagementPriceHint | null;
}

interface DatatableData {
  [key: string]: string | number | DayData;
  sectorName: string;
  sectorId: number;
  day0: DayData;
  day1: DayData;
  day2: DayData;
  day3: DayData;
  day4: DayData;
  day5: DayData;
  day6: DayData;
}

enum PriceStatus {
  INCREASED,
  DECREASED,
  DEFAULT,
}

interface PriceStatusData {
  status: PriceStatus;
  label: string;
  color: string;
}

const PRICE_STATUS_DATA: Array<PriceStatusData> = [
  {
    status: PriceStatus.INCREASED,
    label: i18n.global.t('revenueManagementPlannerView.priceStatus.increased'),
    color: '#E3F3E4',
  },
  {
    status: PriceStatus.DECREASED,
    label: i18n.global.t('revenueManagementPlannerView.priceStatus.decreased'),
    color: '#FFECB3',
  },
  {
    status: PriceStatus.DEFAULT,
    label: i18n.global.t('revenueManagementPlannerView.priceStatus.default'),
    color: '#F9F9F9',
  },
];

export default defineComponent({
  name: 'RevenueManagementPlannerView',
  components: {
    RevenueManagementPriceAlterationDialog,
    RevenueManagementCard,
    RevenueManagementChangeWeek,
  },
  data() {
    return {
      // misc
      from: 0,
      to: 0,
      range: [] as Array<DateTime>,
      selectedDate: DateTime.now().startOf('day'),
      priceStatusData: clone(PRICE_STATUS_DATA),
      // data
      getDataDebounced: debounce(
        this.getData as (updatePrices?: boolean) => Promise<void>,
        1000,
      ),
      datatableData: [] as Array<DatatableData>,
      // prices
      prices: [] as unknown as Array<RevenueManagementPrices>,
      // price alteration
      priceAlterations: [] as Array<PriceAlteration>,
      priceAlteration: null as unknown as PriceAlteration,
      priceAlterationDialog: false,
      priceAlterationOverrideDialog: false,
      priceAlterationsOverride: [] as Array<PriceAlteration>,
      priceAlterationOverridePayload:
        null as unknown as ApiRevenueManagementPriceAlterationCreatePayload,
      // hints
      pricesHints: [] as Array<RevenueManagementPriceHint>,
      hintAcceptDialog: false,
      hintDeclineDialog: false,
      selectedPriceHint: null as unknown as RevenueManagementPriceHint,
      periodHints: [] as Array<RevenueManagementPriceHint>,
      hintApplyModeOptions: cloneDeep(HINT_APPLY_MODE_OPTIONS),
      hintApplyMode: HintApplyMode.SELECTED_DAY,
      headerSlotDetail: null as unknown as RevenueManagementSlotDetail,
    };
  },
  methods: {
    /**
     * Build week datetime range
     */
    buildRange(): void {
      const range: Array<DateTime> = [];
      for (let i = 0; i < 7; i += 1) {
        range.push(DateTime.fromSeconds(this.from + DAY_SECONDS * i));
      }
      this.range = range;
    },
    async getData(updatePrices = false): Promise<void> {
      /**
       * Build range, get the data (prices, hints and alterations) and build it
       * Update prices only on week navigation
       */
      this.buildRange();
      await Promise.all([
        updatePrices ? this.getPrices() : Promise.resolve(),
        this.getPricesHint(),
        this.getPriceAlterations(),
      ]);
      this.buildData();
    },
    onChangeWeek(event: {
      from: DateTime;
      to: DateTime;
      selectedDate: DateTime;
    }): void {
      this.from = event.from.setZone('utc').toSeconds();
      this.to = event.to.setZone('utc').toSeconds();
      this.selectedDate = event.selectedDate;
      this.getDataDebounced(true);
    },
    async getPrices(): Promise<void> {
      /**
       * Prices
       */
      try {
        this.prices = await revenueManagementService.prices({
          from: this.from,
          to: this.from + DAY_SECONDS * 7,
        });
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('revenueManagementPlannerView.toast.pricesRetrievalError'),
        );
      }
    },
    async getPricesHint(): Promise<void> {
      /**
       * Get prices hint
       */
      try {
        this.pricesHints = await revenueManagementService.priceHints({
          from: this.from,
          to: this.from + DAY_SECONDS * 7,
        } as ApiRevenueManagementPriceHintListPayload);
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('revenueManagementPlannerView.toast.hintsRetrievalError'),
        );
      }
    },
    async getPriceAlterations(): Promise<void> {
      /**
       * Get price alterations
       */
      try {
        this.priceAlterations =
          await revenueManagementService.priceAlteration.list({
            from: this.from,
            to: this.from + DAY_SECONDS * 7,
          } as ApiRevenueManagementPriceAlterationListPayload);
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('revenueManagementPlannerView.toast.dataRetrievalError'),
        );
      }
    },
    buildData(): void {
      /**
       * Build datatable data
       */
      const data: Array<DatatableData> = [];
      this.sectors.forEach((sector: Sector) => {
        const sectorId = sector.header.id;
        const sectorName = `${sector.header.name} (ID: ${sector.header.oldId})`;
        const item = {
          sectorName,
          sectorId,
        } as DatatableData;

        this.range.forEach((day: DateTime, index: number) => {
          // price alterations
          const priceAlteration: PriceAlteration | null =
            this.priceAlterations.find(
              (priceAlteration: PriceAlteration) =>
                priceAlteration.day === day.toSeconds() &&
                priceAlteration.sectorId === sectorId,
            ) ?? null;

          // prices
          const prices: RevenueManagementPrices | null =
            this.prices.find(
              (prices: RevenueManagementPrices) =>
                DateTime.fromSeconds(prices.day).startOf('day').toSeconds() ===
                  day.toSeconds() && prices.sectorId === sectorId,
            ) ?? null;

          // price hint
          const priceHint: RevenueManagementPriceHint | null =
            this.pricesHints.find(
              (priceHint: RevenueManagementPriceHint) =>
                priceHint.day === day.toSeconds() &&
                priceHint.sector === sectorId,
            ) ?? null;

          item[`day${index}`] = {
            priceAlteration,
            prices,
            priceHint,
          };
        });
        data.push(item);
      });
      this.datatableData = data;
    },
    onAcceptHint(priceHint: RevenueManagementPriceHint): void {
      /**
       * Accept hint (confirmation)
       */
      this.periodHints = [];
      this.selectedPriceHint = clone(priceHint);

      // INFO this sections has been disabled at the moment (bulk accept).
      /*
      // check period
      const datatableRow = this.datatableData.find(
        (row: DatatableData) => row.sectorId === hint.sector,
      ) as DatatableData;

      // if no price hint or price hint with different percentage/rule set to null
      // es [null, null, priceHint1, priceHint2, null, priceHint3, null]
      const normalizedHints: Array<RevenueManagementPriceHint | null> = [];
      for (let i = 0; i < 7; i += 1) {
        if (
          (datatableRow[`day${i}`] as DayData).priceHint === null ||
          (datatableRow[`day${i}`] as DayData).priceHint?.discount !==
            this.selectedPriceHint.discount ||
          (datatableRow[`day${i}`] as DayData).priceHint?.ruleType !==
            this.selectedPriceHint.ruleType
        ) {
          normalizedHints.push(null);
        } else {
          normalizedHints.push((datatableRow[`day${i}`] as DayData).priceHint);
        }
      }

      // group in chunks
      // es [[priceHint1,priceHint2],[priceHint3]]
      const hintsChunks = [] as Array<Array<RevenueManagementPriceHint>>;
      for (let arr, i = 0; i < normalizedHints.length; i += 1) {
        if (normalizedHints[i] === null) {
          arr = null;
        } else {
          if (!arr) hintsChunks.push((arr = []));
          arr.push(normalizedHints[i]);
        }
      }

      let hintChunkIndex: number | undefined;
      hintsChunks.forEach(
        (chunks: Array<RevenueManagementPriceHint>, index: number) => {
          if (
            chunks.find((chunk: RevenueManagementPriceHint) =>
              isEqual(chunk, this.selectedPriceHint),
            )
          ) {
            hintChunkIndex = index;
          }
        },
      );

      if (hintChunkIndex !== undefined) {
        this.periodHints = hintsChunks[hintChunkIndex];
      }
      */

      this.hintAcceptDialog = true;
    },
    async acceptHint(): Promise<void> {
      /**
       * Accept hint
       */
      this.$store.commit('app/setProcessing', true);
      try {
        await revenueManagementService.handleHint({
          from: this.selectedPriceHint.day,
          to: this.selectedPriceHint.day,
          sectorIds: [this.selectedPriceHint.sector],
          ruleId: this.selectedPriceHint.ruleId,
          ruleType: this.selectedPriceHint.ruleType,
          status: RevenueManagementPriceHintStatus.ACCEPTED,
          revenuePercentageValue: this.selectedPriceHint.revenuePercentageValue,
        } as ApiRevenueManagementPriceHintHandlePayload);
        this.$spiagge.toast.success(
          this.$t('revenueManagementPlannerView.toast.hintAcceptanceSuccess'),
        );
        this.hintAcceptDialog = false;
        await this.getData();
      } catch (error) {
        this.$spiagge.toast.error(
          this.$t('revenueManagementPlannerView.toast.hintAcceptanceError'),
        );
      } finally {
        this.$store.commit('app/setProcessing', false);
      }
    },
    onDeclineHint(priceHint: RevenueManagementPriceHint): void {
      /**
       * Decline hint (confirmation)
       */
      this.selectedPriceHint = clone(priceHint);
      this.hintDeclineDialog = true;
    },
    async declineHint(): Promise<void> {
      /**
       * Decline hint
       */
      this.$store.commit('app/setProcessing', true);
      try {
        await revenueManagementService.handleHint({
          from: this.selectedPriceHint.day,
          to: this.selectedPriceHint.day,
          sectorIds: [this.selectedPriceHint.sector],
          ruleId: this.selectedPriceHint.ruleId,
          ruleType: this.selectedPriceHint.ruleType,
          status: RevenueManagementPriceHintStatus.DECLINED,
          revenuePercentageValue: this.selectedPriceHint.revenuePercentageValue,
        } as ApiRevenueManagementPriceHintHandlePayload);
        this.$spiagge.toast.success(
          this.$t('revenueManagementPlannerView.toast.hintDeleteSuccess'),
        );
        this.hintDeclineDialog = false;
        await this.getData();
      } catch (error) {
        this.$spiagge.toast.error(
          this.$t('revenueManagementPlannerView.toast.hintDeleteError'),
        );
      } finally {
        this.$store.commit('app/setProcessing', false);
      }
    },
    async onPriceAlterationDelete(): Promise<void> {
      /**
       * Price alteration delete
       */
      await this.getData();
    },
    async onPriceAlterationSave(
      payload: ApiRevenueManagementPriceAlterationCreatePayload,
      checkOverride = true,
    ): Promise<void> {
      /**
       * Price alteration create
       */
      if (checkOverride) {
        this.priceAlterationsOverride = this.priceAlterations
          .filter(
            (priceAlteration: PriceAlteration) =>
              payload.sectorIds.includes(priceAlteration.sectorId) &&
              priceAlteration.day >= payload.from &&
              priceAlteration.day <= payload.to,
          )
          .sort(
            (a: PriceAlteration, b: PriceAlteration) => a.sectorId - b.sectorId,
          );

        if (this.priceAlterationsOverride.length > 0) {
          this.priceAlterationOverridePayload = payload;
          this.priceAlterationOverrideDialog = true;
          return;
        }
      }

      this.$store.commit('app/setProcessing', true);
      try {
        await revenueManagementService.priceAlteration.create(payload);
        await this.getData();
        this.$spiagge.toast.success(
          this.$t('revenueManagementPlannerView.toast.saveSuccess'),
        );
        this.onPriceAlterationClose();
      } catch (error) {
        this.$spiagge.toast.error(
          this.$t('revenueManagementPlannerView.toast.saveError'),
        );
      } finally {
        this.$store.commit('app/setProcessing', false);
      }
    },
    async onPriceAlterationOverrideSave(): Promise<void> {
      await this.onPriceAlterationSave(
        this.priceAlterationOverridePayload,
        false,
      );
    },
    onSettingsSave(): void {
      //
    },
    onCard(
      priceAlteration: PriceAlteration | null,
      day: DateTime,
      sectorId: number,
    ): void {
      /**
       * Open price alteration dialog
       */
      if (priceAlteration) {
        this.priceAlteration = clone(priceAlteration);
      } else {
        this.priceAlteration = {
          day: day.toSeconds(),
          percentageDiscount: 0,
          sectorId,
        };
      }
      this.priceAlterationDialog = true;
    },
    onPriceAlterationClose(): void {
      /**
       * Close price alteration dialog
       */
      this.priceAlterationDialog = false;
      this.priceAlteration = null as unknown as PriceAlteration;
      // override case
      this.priceAlterationOverrideDialog = false;
      this.priceAlterationOverridePayload =
        null as unknown as ApiRevenueManagementPriceAlterationCreatePayload;
    },
    getHintApplyModeDetail(hintApplyMode: HintApplyMode): string {
      /**
       * Hint apply mode detail
       */
      let detail = '';
      if (hintApplyMode === HintApplyMode.SELECTED_DAY) {
        detail = `${DateTime.fromSeconds(this.selectedPriceHint.day).toFormat(
          'dd/MM/yyyy',
        )} ${this.selectedPriceHint.revenuePercentageValue.toString()}%`;
      }
      if (hintApplyMode === HintApplyMode.PERIOD) {
        detail = `${DateTime.fromSeconds(this.periodHints[0].day).toFormat(
          'dd/MM/yyyy',
        )} -  ${DateTime.fromSeconds(
          this.periodHints[this.periodHints.length - 1].day,
        ).toFormat(
          'dd/MM/yyyy',
        )} ${this.selectedPriceHint.revenuePercentageValue.toString()}%`;
      }
      return detail;
    },
    async onOpenDetail(
      day: DateTime,
      dayIndex: number,
      $event: PointerEvent,
    ): Promise<void> {
      // already open, close it
      if (this.headerSlotDetail) {
        this.onCloseDetail(dayIndex);
      } else {
        try {
          this.headerSlotDetail = await revenueManagementService.slotDetail({
            day: day.toSeconds(),
            sectorId: null,
          } as ApiRevenueManagementSlotDetailPayload);
          (this.$refs[`op-${dayIndex}`] as OverlayPanel).show(
            $event,
            $event.target,
          );
        } catch (e) {
          this.$spiagge.toast.error(
            this.$t('revenueManagementPlannerView.toast.infoRetrievalError'),
          );
        }
      }
    },
    onCloseDetail(dayIndex: number): void {
      this.headerSlotDetail = null as unknown as RevenueManagementSlotDetail;
      (this.$refs[`op-${dayIndex}`] as OverlayPanel).hide();
    },
  },
  computed: {
    FEATURE_PERMISSION_ACTION_CONFIG() {
      return FEATURE_PERMISSION_ACTION_CONFIG;
    },
    FEATURE_PERMISSION_CONFIG() {
      return FEATURE_PERMISSION_CONFIG;
    },
    permissionsUtil() {
      return permissionsUtil;
    },
    ...mapGetters('session', ['sectors']),
    ...mapState('app', ['breakpoints']),
    ...mapState('session', ['license']),
    priceHintOverwrite(): boolean {
      /**
       * Check if selected price hint overwrite another rule
       */
      if (!this.selectedPriceHint) return false;
      return (
        this.priceAlterations.find(
          (priceAlteration: PriceAlteration) =>
            priceAlteration.day === this.selectedPriceHint.day &&
            priceAlteration.sectorId === this.selectedPriceHint.sector,
        ) !== undefined
      );
    },
  },
});
