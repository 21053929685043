import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7db53134"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "map-ticket-warehouse"
}
const _hoisted_2 = { class: "flex flex-column w-full justify-content-start" }
const _hoisted_3 = { class: "col-12 flex flex-column" }
const _hoisted_4 = { class: "font-medium text-base" }
const _hoisted_5 = { class: "pl-2" }
const _hoisted_6 = {
  id: "warehouse-detail-flex",
  class: "p-0 flex flex-wrap align-content-start align-items-center"
}
const _hoisted_7 = {
  key: 0,
  class: "p-1 col-12 sm:col-6 justify-content-start"
}
const _hoisted_8 = { class: "flex font-bold col-12 p-0 align-items-center" }
const _hoisted_9 = { class: "flex-grow-0 p-0 pr-1 white-space-nowrap text-overflow-ellipsis overflow-hidden" }
const _hoisted_10 = { class: "p-0 flex-shrink-0 text-gray-600" }
const _hoisted_11 = { class: "mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_ProgressBar = _resolveComponent("ProgressBar")!

  return (!this.isOverlayActive && _ctx.hasActiveTickets())
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          id: "map-ticket-button-container",
          class: _normalizeClass(_ctx.buttonClasses)
        }, [
          _createVNode(_component_Button, {
            class: "ticket-warehouse-button p-button-white",
            icon: _ctx.isWarehouseOpen ? 'pi pi-times' : 'spi-ticket',
            label: 
            _ctx.isWarehouseOpen
              ? _ctx.$t('mapTicket.closeLabel')
              : _ctx.$t('mapTicket.openLabel')
          ,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openWarehouseDetails()))
          }, null, 8, ["icon", "label"])
        ], 2),
        _createElementVNode("div", {
          id: "warehouse-section-wrapper",
          class: _normalizeClass(_ctx.warehouseSectionClasses)
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h6", _hoisted_4, [
                _createTextVNode(_toDisplayString(this.$t('mapTicket.haveBeenBought')) + " ", 1),
                _createElementVNode("strong", null, _toDisplayString(` ${this.totalReservedTickets}`), 1),
                _createTextVNode(" " + _toDisplayString(` ${this.$t('mapTicket.title').toLowerCase()}`) + " ", 1),
                (this.upperLimit)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _createTextVNode(_toDisplayString(` ${this.$t('common.on')}`) + " ", 1),
                      _createElementVNode("strong", null, _toDisplayString(` ${this.upperLimit} `), 1),
                      _createTextVNode(" (" + _toDisplayString(Math.round(
                    (this.totalReservedTickets / this.upperLimit)
                    * 100 * 100
                ) / 100) + " %) ", 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ]),
              _createVNode(_component_ProgressBar, {
                "aria-valuemax": this.upperLimit,
                value: 
              this.upperLimit > 0
                ? (this.totalReservedTickets / this.upperLimit) * 100
                : 0
            
              }, null, 8, ["aria-valuemax", "value"]),
              _createElementVNode("span", {
                class: "text-center cursor-pointer text-blue-400 mt-2 mb-2 font-bold",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDetailOpen = !_ctx.isDetailOpen))
              }, [
                _createElementVNode("i", {
                  class: _normalizeClass(`pi pi-angle-${_ctx.isDetailOpen ? 'up' : 'down'}`)
                }, null, 2),
                _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.showDetailToggle), 1)
              ]),
              (this.isDetailOpen)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    id: "warehouse-detail",
                    class: _normalizeClass(_ctx.warehouseDetailClasses)
                  }, [
                    _createElementVNode("div", _hoisted_6, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.serviceGroups, (serviceGroup, index) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: serviceGroup.id
                        }, [
                          ((_ctx.mappedServicesToGroups.get(serviceGroup.id) ?? []).length > 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                _createElementVNode("div", {
                                  class: _normalizeClass(_ctx.boxClasses(index === (this.serviceGroups.length - 1)))
                                }, [
                                  _createElementVNode("div", _hoisted_8, [
                                    _createElementVNode("span", _hoisted_9, _toDisplayString(serviceGroup.name), 1),
                                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.formatGroupServices(serviceGroup)), 1)
                                  ]),
                                  _createElementVNode("span", _hoisted_11, _toDisplayString(this.$t('mapTicket.bought')) + ": " + _toDisplayString(_ctx.formatLimitLabel(_ctx.mappedServiceGroupLimits.get(serviceGroup.id))), 1)
                                ], 2)
                              ]))
                            : _createCommentVNode("", true)
                        ], 64))
                      }), 128))
                    ])
                  ], 2))
                : _createCommentVNode("", true)
            ])
          ])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}