/**
 * Printer models
 */

import { SectorHeader } from './sector';

export interface PrinterMode {
  fis: number;
  nfis: number;
  list: number;
  text: number;
}

export interface Printer {
  id: number;
  idPrinter: number;
  invoiceFooter: string;
  invoiceSubtitle: string;
  invoiceTitle: string;
  invoiceWidth: number;
  departments: Array<number>;
  licenseId: string;
  ip: string;
  mode: number;
  model: number;
  name: string;
  port: number;
  printMode: PrinterMode;
  remote: boolean;
  ssl: boolean;
  sectors: Array<SectorHeader>;
  registrationNumber: string;
}

/* PRINT */

export enum PrintMode {
  FISCAL = 'fis',
  NON_FISCAL = 'nfis',
  REFUND = 'canc',
  LIST = 'list',
  PLAIN_TEXT = 'text',
}

export enum PrintPaymentMethod {
  CONTANTI = 'contanti',
  BANCOMAT = 'bancomat',
  CARTA = 'carta',
  NON_RISCOSSO = 'non_riscosso',
  TICKET = 'ticket',
  BONIFICO = 'bonifico',
}

export interface PrintArticle {
  description: string;
  quantity: number;
  price: number;
  vat: number;
}

export interface PrintDeposit {
  description?: string;
  amount: number;
  vat?: number;
}

export interface InvoiceDeposit {
  description: string;
  amount: number;
  vat?: number;
}

export interface Print {
  printMode: PrintMode;
  paymentMethod: PrintPaymentMethod;
  articles: Array<PrintArticle>;
  deposits: Array<PrintDeposit>;
  discount: number;
  discountPercentage: number;
  notes: string;
  timestamp: number;
  codeType: string;
  codeText: string;
  text: string;
  lotteryCode: string;
  singleUseVouchers?: Array<string>;
  refund?: any;
  callbackMethod: () => void;
}

export const DEFAULT_PRINT = {
  printMode: PrintMode.FISCAL,
  paymentMethod: PrintPaymentMethod.CONTANTI,
  articles: [],
  deposits: [],
  discount: 0,
  discountPercentage: 0,
  notes: '',
  timestamp: Date.now(),
  codeType: '',
  codeText: '',
  text: '',
  lotteryCode: '',
  singleUseVouchers: [],
  callbackMethod: () => {
    // TODO
  },
} as Print;

export interface DepartmentsStruct {
  1: number;
  2: number;
  3: number;
  4: number;
  5: number;
}

export interface PrinterResponseData {
  receiptNumber?: string;
  receiptAmount?: string;
}

export type PrinterResponse = PrinterResponseData | null;

export interface PrinterError {
  status: number;
  responseText: string;
}

export interface PrinterFiscalResponse {
  receiptNumber: string;
  receiptAmount: number;
}
