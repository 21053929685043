/* eslint-disable import/no-cycle */
import axios, { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import {
  ApiMapDataPayload,
  ApiMapDataResponse,
  ApiMapPlannerPayload,
  ApiMapPlannerResponse,
} from '@/models/api';
import apiService from './apiService';
import {
  MapData,
  MapDataElements,
  MapDecoration,
  MapElementRaw,
  MapElementTypeRaw,
  MapPlannerData,
  MapSpot,
} from '@/models/map';
import mapUtil from '@/utils/mapUtil';

export default {
  async data(payload: ApiMapDataPayload): Promise<MapData> {
    const res: AxiosResponse<ApiMapDataResponse> = await axios.get(
      apiService.map.data(payload),
    );

    const decorations: Array<MapDecoration> = [];
    const spots: Array<MapSpot> = [];
    const decorationsType = [
      MapElementTypeRaw.MAP_LABEL,
      MapElementTypeRaw.MAP_LINE_H,
      MapElementTypeRaw.MAP_LINE_V,
      MapElementTypeRaw.FOOTBOARD,
      MapElementTypeRaw.ELEMENT,
    ];

    try {
      res.data.result.elements.map((el: MapElementRaw) => {
        if (decorationsType.includes(el.t)) {
          decorations.push(mapUtil.decorationDeserialize(el));
        } else {
          spots.push(
            mapUtil.spotDeserialize(
              el,
              DateTime.fromSeconds(payload.from).startOf('day'),
              payload.to
                ? DateTime.fromSeconds(payload.to).startOf('day')
                : DateTime.fromSeconds(payload.from).startOf('day'),
            ),
          );
        }
      });
    } catch (e) {
      // console.log(e);
    }

    return {
      height: res.data.result.height,
      width: res.data.result.width,
      elements: {
        spots,
        decorations,
      } as MapDataElements,
      background: res.data.result.background,
    } as MapData;
  },
  async planner(payload: ApiMapPlannerPayload): Promise<MapPlannerData> {
    const res: AxiosResponse<ApiMapPlannerResponse> = await axios.get(
      apiService.map.planner(payload),
    );
    return res.data.result;
  },
};
