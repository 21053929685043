import axios, { AxiosResponse } from 'axios';
import {
  ApiStatisticsAdditionsSummaryPayload,
  ApiStatisticsAdditionsSummaryResponse,
  ApiStatisticsAllReservationsPayload,
  ApiStatisticsAllReservationsResponse,
  ApiStatisticsArrivalsTodayPayload,
  ApiStatisticsArrivalsTodayResponse,
  ApiStatisticsArrivalsTomorrowPayload,
  ApiStatisticsArrivalsTomorrowResponse,
  ApiStatisticsAttendanceSummaryPayload,
  ApiStatisticsAttendanceSummaryResponse,
  ApiStatisticsCashFlowsPayload,
  ApiStatisticsCashFlowsResponse,
  ApiStatisticsCountersHotelDetailPayload,
  ApiStatisticsCountersHotelDetailResponse,
  ApiStatisticsCountersHotelDetailExportPayload,
  ApiStatisticsCountersHotelExportDetailResponse,
  ApiStatisticsCountersHotelPayload,
  ApiStatisticsCountersHotelResponse,
  ApiStatisticsCountersVoucherDetailPayload,
  ApiStatisticsCountersVoucherDetailResponse,
  ApiStatisticsCountersVoucherPayload,
  ApiStatisticsCountersVoucherResponse,
  ApiStatisticsDashboardExportPayload,
  ApiStatisticsDashboardExportResponse,
  ApiStatisticsDashboardPayload,
  ApiStatisticsDashboardResponse,
  ApiStatisticsVoucherDetailExportResponse,
  ApiStatisticsCountersVoucherDetailExportPayload,
  ApiStatisticsDeparturesTodayPayload,
  ApiStatisticsDeparturesTodayResponse,
  ApiStatisticsDeparturesTomorrowPayload,
  ApiStatisticsDeparturesTomorrowResponse,
  ApiStatisticsExtraServicePayload,
  ApiStatisticsExtraServiceResponse,
  ApiStatisticsExtraServicesPayload,
  ApiStatisticsLogCancellationsPayload,
  ApiStatisticsLogCancellationsResponse,
  ApiStatisticsLogDiscountsPayload,
  ApiStatisticsLogDiscountsResponse,
  ApiStatisticsOnlineReservationsPayload,
  ApiStatisticsOnlineReservationsResponse,
  ApiStatisticsSeasonalSummaryPayload,
  ApiStatisticsSeasonalSummaryResponse,
  ApiStatisticsSectorsPayload,
  ApiStatisticsSectorsResponse,
  ApiStatisticsStripePaymentsPayload,
  ApiStatisticsStripePaymentsResponse,
  ApiStatisticsStripePaymentsExportPayload,
  ApiStatisticsStripePaymentsExportResponse,
  ApiStatisticsStripeTransfersPayload,
  ApiStatisticsStripeTransfersResponse,
  ApiStatisticsStripeTransfersExportPayload,
  ApiStatisticsStripeTransfersExportResponse,
  ApiStatisticsUnpaidReservationsSummaryPayload,
  ApiStatisticsUnpaidReservationsSummaryResponse,
  StatisticsExtraServicesResponse,
  ApiCustomerStatisticsResponse,
  ApiCustomerStatisticsPayload,
  ApiStatisticsDurationPayload,
  ApiStatisticsDurationResponse,
  ApiStatisticsSpotPayload,
  ApiStatisticsSpotResponse,
  ApiGetStripeTransferInvoice,
  ApiStatisticsCashFlowsUsersResponse,
} from '@/models/api';
import apiService from './apiService';
import { StatisticCustomer } from '@/models/statistic';
import { StripeTransferInvoice } from '@/models/invoice';

export default {
  async dashboard(
    payload: ApiStatisticsDashboardPayload,
  ): Promise<ApiStatisticsDashboardResponse> {
    const res: AxiosResponse<ApiStatisticsDashboardResponse> = await axios.post(
      apiService.statistic.dashboard(),
      payload,
    );
    return res.data;
  },
  async arrivalsToday(
    payload: ApiStatisticsArrivalsTodayPayload,
  ): Promise<ApiStatisticsArrivalsTodayResponse> {
    const res: AxiosResponse<ApiStatisticsArrivalsTodayResponse> =
      await axios.post(apiService.statistic.arrivalsToday(), payload);
    return res.data;
  },
  async departuresToday(
    payload: ApiStatisticsDeparturesTodayPayload,
  ): Promise<ApiStatisticsDeparturesTodayResponse> {
    const res: AxiosResponse<ApiStatisticsDeparturesTodayResponse> =
      await axios.post(apiService.statistic.departuresToday(), payload);
    return res.data;
  },
  async arrivalsTomorrow(
    payload: ApiStatisticsArrivalsTomorrowPayload,
  ): Promise<ApiStatisticsArrivalsTomorrowResponse> {
    const res: AxiosResponse<ApiStatisticsArrivalsTomorrowResponse> =
      await axios.post(apiService.statistic.arrivalsTomorrow(), payload);
    return res.data;
  },
  async departuresTomorrow(
    payload: ApiStatisticsDeparturesTomorrowPayload,
  ): Promise<ApiStatisticsDeparturesTomorrowResponse> {
    const res: AxiosResponse<ApiStatisticsDeparturesTomorrowResponse> =
      await axios.post(apiService.statistic.departuresTomorrow(), payload);
    return res.data;
  },
  async allReservations(
    payload: ApiStatisticsAllReservationsPayload,
  ): Promise<ApiStatisticsAllReservationsResponse> {
    const res: AxiosResponse<ApiStatisticsAllReservationsResponse> =
      await axios.post(apiService.statistic.allReservations(), payload);
    return res.data;
  },
  async stripePayments(
    payload: ApiStatisticsStripePaymentsPayload,
  ): Promise<ApiStatisticsStripePaymentsResponse> {
    const res: AxiosResponse<ApiStatisticsStripePaymentsResponse> =
      await axios.post(apiService.statistic.stripePayments(), payload);
    return res.data;
  },
  async stripePaymentsV2(
    payload: ApiStatisticsStripePaymentsPayload,
  ): Promise<ApiStatisticsStripePaymentsResponse> {
    const res: AxiosResponse<ApiStatisticsStripePaymentsResponse> =
      await axios.post(apiService.statistic.stripePaymentsV2(), payload);
    return res.data;
  },
  async stripeTransfers(
    payload: ApiStatisticsStripeTransfersPayload,
  ): Promise<ApiStatisticsStripeTransfersResponse> {
    const res: AxiosResponse<ApiStatisticsStripeTransfersResponse> =
      await axios.post(apiService.statistic.stripeTransfers(), payload);
    return res.data;
  },
  async logDiscounts(
    payload: ApiStatisticsLogDiscountsPayload,
  ): Promise<ApiStatisticsLogDiscountsResponse> {
    const res: AxiosResponse<ApiStatisticsLogDiscountsResponse> =
      await axios.post(apiService.statistic.logDiscounts(), payload);
    return res.data;
  },
  async logCancellations(
    payload: ApiStatisticsLogCancellationsPayload,
  ): Promise<ApiStatisticsLogCancellationsResponse> {
    const res: AxiosResponse<ApiStatisticsLogCancellationsResponse> =
      await axios.post(apiService.statistic.logCancellations(), payload);
    return res.data;
  },
  async cashFlowUsers(): Promise<ApiStatisticsCashFlowsUsersResponse> {
    const res: AxiosResponse<ApiStatisticsCashFlowsUsersResponse> = await axios.get(
      apiService.statistic.cashFlowUsers(),
    );
    return res.data;
  },
  async cashFlows(
    payload: ApiStatisticsCashFlowsPayload,
  ): Promise<ApiStatisticsCashFlowsResponse> {
    const res: AxiosResponse<ApiStatisticsCashFlowsResponse> = await axios.post(
      apiService.statistic.cashFlows(),
      payload,
    );
    return res.data;
  },
  async countersHotel(
    payload: ApiStatisticsCountersHotelPayload,
  ): Promise<ApiStatisticsCountersHotelResponse> {
    const res: AxiosResponse<ApiStatisticsCountersHotelResponse> =
      await axios.post(apiService.statistic.countersHotel(), payload);
    return res.data;
  },
  async countersVoucher(
    payload: ApiStatisticsCountersVoucherPayload,
  ): Promise<ApiStatisticsCountersVoucherResponse> {
    const res: AxiosResponse<ApiStatisticsCountersVoucherResponse> =
      await axios.post(apiService.statistic.countersVoucher(), payload);
    return res.data;
  },
  async countersHotelDetail(
    payload: ApiStatisticsCountersHotelDetailPayload,
  ): Promise<ApiStatisticsCountersHotelDetailResponse> {
    const res: AxiosResponse<ApiStatisticsCountersHotelDetailResponse> =
      await axios.post(apiService.statistic.countersHotelDetail(), payload);
    return res.data;
  },
  async countersVoucherDetail(
    payload: ApiStatisticsCountersVoucherDetailPayload,
  ): Promise<ApiStatisticsCountersVoucherDetailResponse> {
    const res: AxiosResponse<ApiStatisticsCountersVoucherDetailResponse> =
      await axios.post(apiService.statistic.countersVoucherDetail(), payload);
    return res.data;
  },
  async attendanceSummary(
    payload: ApiStatisticsAttendanceSummaryPayload,
  ): Promise<ApiStatisticsAttendanceSummaryResponse> {
    const res: AxiosResponse<ApiStatisticsAttendanceSummaryResponse> =
      await axios.post(apiService.statistic.attendanceSummary(), payload);
    return res.data;
  },
  async seasonalSummary(
    payload: ApiStatisticsSeasonalSummaryPayload,
  ): Promise<ApiStatisticsSeasonalSummaryResponse> {
    const res: AxiosResponse<ApiStatisticsSeasonalSummaryResponse> =
      await axios.post(apiService.statistic.seasonalSummary(), payload);
    return res.data;
  },
  async unpaidReservationsSummary(
    payload: ApiStatisticsUnpaidReservationsSummaryPayload,
  ): Promise<ApiStatisticsUnpaidReservationsSummaryResponse> {
    const res: AxiosResponse<ApiStatisticsUnpaidReservationsSummaryResponse> =
      await axios.post(apiService.statistic.seasonalSummary(), payload);
    return res.data;
  },
  async additionsSummary(
    payload: ApiStatisticsAdditionsSummaryPayload,
  ): Promise<ApiStatisticsAdditionsSummaryResponse> {
    const res: AxiosResponse<ApiStatisticsAdditionsSummaryResponse> =
      await axios.post(apiService.statistic.additionsSummary(), payload);
    return res.data;
  },
  async onlineReservations(
    payload: ApiStatisticsOnlineReservationsPayload,
  ): Promise<ApiStatisticsOnlineReservationsResponse> {
    const res: AxiosResponse<ApiStatisticsOnlineReservationsResponse> =
      await axios.post(apiService.statistic.onlineReservations(), payload);
    return res.data;
  },
  async extraServicesStatistic(
    payload: ApiStatisticsExtraServicesPayload,
  ): Promise<StatisticsExtraServicesResponse> {
    const res: AxiosResponse<StatisticsExtraServicesResponse> =
      await axios.post(apiService.statistic.extraServices(), payload);
    return res.data;
  },
  async extraServiceStatistic(
    serviceId: number,
    payload: ApiStatisticsExtraServicePayload,
  ): Promise<ApiStatisticsExtraServiceResponse> {
    const res: AxiosResponse<ApiStatisticsExtraServiceResponse> =
      await axios.post(apiService.statistic.extraService(serviceId), payload);
    return res.data;
  },
  async exportExtraServiceStatistic(
    serviceId: number,
    payload: ApiStatisticsExtraServicePayload,
  ): Promise<Blob> {
    const res: AxiosResponse<Blob> = await axios.post(
        apiService.statistic.exportExtraService(serviceId),
        payload,
        {
          headers: {
            Accept: 'text/csv',
          },
        },
    );
    return new Blob([res.data]);
  },
  async spot(
    payload: ApiStatisticsSpotPayload,
  ): Promise<ApiStatisticsSpotResponse> {
    const res: AxiosResponse<ApiStatisticsSpotResponse> = await axios.post(
      apiService.statistic.spot(),
      payload,
    );
    return res.data;
  },
  async exportSpotAnalytics(
    payload: ApiStatisticsSpotPayload,
  ): Promise<Blob> {
    const res: AxiosResponse<Blob> = await axios.post(
        apiService.statistic.exportSpotAnalytics(),
        payload,
        {
          headers: {
            Accept: 'text/csv',
          },
        },
    );
    return new Blob([res.data]);
  },
  async exportReservations(payload: any): Promise<Blob> {
    const res: AxiosResponse<Blob> = await axios.post(
      apiService.statistic.exportReservations(),
      payload,
      {
        headers: {
          Accept: 'text/csv',
        },
      },
    );
    return new Blob([res.data]);
  },
  async exportCountersVoucher(
    payload: ApiStatisticsCountersVoucherDetailExportPayload,
  ): Promise<Blob> {
    const res: AxiosResponse<ApiStatisticsVoucherDetailExportResponse> =
      await axios.post(apiService.statistic.exportCountersVoucher(), payload, {
        headers: {
          Accept: 'text/csv',
        },
      });
    return new Blob([res.data]);
  },
  async exportStripePayments(
    payload: ApiStatisticsStripePaymentsExportPayload,
  ): Promise<Blob> {
    const res: AxiosResponse<ApiStatisticsStripePaymentsExportResponse> =
      await axios.post(apiService.statistic.exportStripePayments(), payload, {
        headers: {
          Accept: 'text/csv',
        },
      });
    return new Blob([res.data]);
  },
  async exportStripePaymentsV2(
    payload: ApiStatisticsStripePaymentsExportPayload,
  ): Promise<Blob> {
    const res: AxiosResponse<ApiStatisticsStripePaymentsExportResponse> =
      await axios.post(apiService.statistic.exportStripePaymentsV2(), payload, {
        headers: {
          Accept: 'text/csv',
        },
      });
    return new Blob([res.data]);
  },
  async exportDashboard(
    payload: ApiStatisticsDashboardExportPayload,
  ): Promise<Blob> {
    const res: AxiosResponse<ApiStatisticsDashboardExportResponse> =
      await axios.post(apiService.statistic.exportDashboard(), payload, {
        headers: {
          Accept: 'text/csv',
        },
      });
    return new Blob([res.data]);
  },
  async exportStripeTransfers(
    payload: ApiStatisticsStripeTransfersExportPayload,
  ): Promise<Blob> {
    const res: AxiosResponse<ApiStatisticsStripeTransfersExportResponse> =
      await axios.post(apiService.statistic.exportStripeTransfers(), payload, {
        headers: {
          Accept: 'text/csv',
        },
      });
    return new Blob([res.data]);
  },
  async exportCountersHotel(
    payload: ApiStatisticsCountersHotelDetailExportPayload,
  ): Promise<Blob> {
    const res: AxiosResponse<ApiStatisticsCountersHotelExportDetailResponse> =
      await axios.post(apiService.statistic.exportCountersHotel(), payload, {
        headers: {
          Accept: 'text/csv',
        },
      });
    return new Blob([res.data]);
  },
  async getCustomerStatistics(
    payload: ApiCustomerStatisticsPayload,
  ): Promise<StatisticCustomer> {
    const res: AxiosResponse<ApiCustomerStatisticsResponse> = await axios.post(
      apiService.statistic.customer(),
      payload,
    );
    return res.data.result;
  },
  async duration(
    payload: ApiStatisticsDurationPayload,
  ): Promise<ApiStatisticsDurationResponse> {
    const res: AxiosResponse<ApiStatisticsDurationResponse> = await axios.post(
      apiService.statistic.duration(),
      payload,
    );
    return res.data;
  },
  async sectors(
    payload: ApiStatisticsSectorsPayload,
  ): Promise<ApiStatisticsSectorsResponse> {
    const res: AxiosResponse<ApiStatisticsSectorsResponse> = await axios.post(
      apiService.statistic.sectors(),
      payload,
    );
    return res.data;
  },
  async sectorSpots(
    payload: ApiStatisticsSectorsPayload,
  ): Promise<ApiStatisticsSectorsResponse> {
    const res: AxiosResponse<ApiStatisticsSectorsResponse> = await axios.post(
      apiService.statistic.sectorSpots(),
      payload,
    );
    return res.data;
  },
  async exportSectorSpots(
    payload: ApiStatisticsSectorsPayload,
  ): Promise<Blob> {
    const res: AxiosResponse<Blob> = await axios.post(
        apiService.statistic.exportSectorSpots(),
        payload,
        {
          headers: {
            Accept: 'text/csv',
          },
        },
    );
    return new Blob([res.data]);
  },
  async loadStripeTransferInvoice(
    preinvoiceId: number,
  ): Promise<StripeTransferInvoice> {
    const res: AxiosResponse<ApiGetStripeTransferInvoice> = await axios.get(
      apiService.statistic.stripeTransferInvoice(preinvoiceId),
    );
    return res.data.result.invoice;
  },
};
