
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { DateTime } from 'luxon';
import reservationService from '@/services/reservationService';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_CONFIG,
  FEATURE_PERMISSION_ACTION_CONFIG,
} from '@/models/permissions';
import { ApiBillReservationCreatePayload } from '@/models/api';
import { AppAction } from '@/models/app';
import { Service } from '@/models/service';

export default defineComponent({
  name: 'TopBarNewTicketReservation',
  methods: {
    async onNewTicketReservation(): Promise<void> {
      const todayTS = DateTime.now().startOf('day').toSeconds();

      const payload: ApiBillReservationCreatePayload = {
        startDate: todayTS,
        endDate: todayTS,
      };
      const reservation = await reservationService.createBillReservation(payload);
      this.$store.commit('app/setAction', AppAction.CREATE_RESERVATION);
      await this.$router.push(`/reservation/${reservation.id}`);
    },
    canCreateReservations(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
          FEATURE_PERMISSION_CONFIG.map,
          FEATURE_PERMISSION_ACTION_CONFIG.map.ADD_UPDATE_RESERVATIONS,
      );
    },
    hasAloneOfflineTickets(): boolean {
      let result = false;
      const tickets: Array<Service> =
          this.$store.getters['session/dailyTicketServices'](true);
      tickets.forEach((ticket: Service) => {
        if (ticket.canBeSoldAloneOffline) {
          result = true;
        }
      });
      return result;
    },
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapGetters('map', ['calendar']),
    ...mapGetters('session', ['services']),
    buttonLabel(): string {
      if (this.windowWidth >= 1350) {
        return this.$t('topBarNewReservation.newTicketReservation');
      }
      return this.$t('mapTicket.title');
    },
  },
});
