
import { PropType, defineComponent } from 'vue';
import RevenueManagementRules from '@/components/revenue-management/RevenueManagementRules.vue';
import {
  ApiRevenueManagementOverrideRuleCreatePayload,
  ApiRevenueManagementOverrideRuleUpdatePayload,
} from '@/models/api';
import {
  RevenueManagementRuleCreateEmit,
  RevenueManagementRuleUpdateEmit,
  RevenueManagementRuleDeleteEmit,
  RevenueManagementBaseRule,
  RevenueManagementOverrideRule,
  RevenueManagementRuleDetail,
  RevenueManagementRuleDetailRule,
  RevenueManagementRuleMode,
} from '@/models/revenueManagement';
import revenueManagementService from '@/services/revenueManagementService';

export default defineComponent({
  name: 'RevenueManagementOverrideCard',
  emits: ['create', 'update', 'delete'],
  components: { RevenueManagementRules },
  props: {
    ruleDetail: {
      type: Object as PropType<RevenueManagementRuleDetail>,
      required: true,
    },
    baseRules: {
      type: Array as PropType<Array<RevenueManagementBaseRule>>,
      required: true,
    },
    overrideRules: {
      type: Array as PropType<Array<RevenueManagementOverrideRule>>,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    ruleAccepted(rule: RevenueManagementBaseRule): boolean {
      return (
        this.ruleDetail.baseRules.find(
          (ruleDetail: RevenueManagementRuleDetailRule) =>
            ruleDetail.accepted && ruleDetail.id === rule.id,
        ) !== undefined
      );
    },
    async onCreate(payload: RevenueManagementRuleCreateEmit): Promise<void> {
      this.$store.commit('app/setProcessing', true);
      try {
        await revenueManagementService.overrideRule.create({
          ruleType: RevenueManagementRuleMode.OCCUPANCY,
          applicationThreshold: payload.model.threshold,
          revenuePercentageValue: payload.model.value,
          day: this.ruleDetail.day,
        } as ApiRevenueManagementOverrideRuleCreatePayload);
        this.$spiagge.toast.success(
          this.$t('revenueManagementOverrideCard.toast.createSuccess'),
        );
        this.$emit('create');
      } catch (error) {
        const errorCode: string = (error as any).response.data.error;
        let errMessage = this.$t('revenueManagementOverrideCard.errorMessage');
        if (['i_rev_mgmt_base_rule_001'].includes(errorCode)) {
          errMessage = this.$t(`errors.${errorCode}`);
        }
        this.$spiagge.toast.error(errMessage);
      } finally {
        this.$store.commit('app/setProcessing', false);
      }
    },
    async onUpdate(payload: RevenueManagementRuleUpdateEmit): Promise<void> {
      this.$store.commit('app/setProcessing', true);
      try {
        await revenueManagementService.overrideRule.update(payload.rule.id, {
          applicationThreshold: payload.model.threshold,
          revenuePercentageValue: payload.model.value,
        } as ApiRevenueManagementOverrideRuleUpdatePayload);
        this.$spiagge.toast.success(
          this.$t('revenueManagementOverrideCard.toast.editSuccess'),
        );
        this.$emit('update');
      } catch (error) {
        const errorCode: string = (error as any).response.data.error;
        let errMessage = this.$t('revenueManagementOverrideCard.errorMessage');
        if (['i_rev_mgmt_base_rule_001'].includes(errorCode)) {
          errMessage = this.$t(`errors.${errorCode}`);
        }
        this.$spiagge.toast.error(errMessage);
      } finally {
        this.$store.commit('app/setProcessing', false);
      }
    },
    async onDelete(payload: RevenueManagementRuleDeleteEmit): Promise<void> {
      try {
        await revenueManagementService.overrideRule.delete(payload.rule.id);
        this.$spiagge.toast.success(
          this.$t('revenueManagementOverrideCard.toast.deleteSuccess'),
        );
        this.$emit('delete');
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('revenueManagementOverrideCard.toast.deleteError'),
        );
      }
    },
  },
  computed: {
    filteredBaseRules(): Array<RevenueManagementBaseRule> {
      const baseRulesIds: Array<number> = this.ruleDetail.baseRules.map(
        (detailRule: RevenueManagementRuleDetailRule) => detailRule.id,
      );
      return this.baseRules.filter((baseRule: RevenueManagementBaseRule) =>
        baseRulesIds.includes(baseRule.id),
      );
    },
    filteredOverrideRules(): Array<RevenueManagementOverrideRule> {
      const overrideRulesIds: Array<number> = this.ruleDetail.overrideRules.map(
        (detailRule: RevenueManagementRuleDetailRule) => detailRule.id,
      );
      return this.overrideRules.filter(
        (baseRule: RevenueManagementOverrideRule) =>
          overrideRulesIds.includes(baseRule.id),
      );
    },
  },
});
