/* eslint-disable import/no-cycle */
import axios, { AxiosResponse } from 'axios';
import apiService from './apiService';
import { ApiCheckoutPointListResponse } from '@/models/api';
import { CheckoutPoint } from '@/models/checkoutPoint';

export default {
  async list(): Promise<Array<CheckoutPoint>> {
    const res: AxiosResponse<ApiCheckoutPointListResponse> = await axios.get(
      apiService.checkoutPoint.list(),
    );
    return res.data.result.checkoutPoints;
  },
};
