import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c889daa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "customer-tags" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "p-multiselect-token-label" }
const _hoisted_4 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiSelect = _resolveComponent("MultiSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MultiSelect, {
      modelValue: _ctx.tagsModelcomputed,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tagsModelcomputed) = $event)),
      options: _ctx.tagsOptions,
      optionLabel: "label",
      optionValue: "idWorldTag",
      placeholder: _ctx.$t('customerTags.addTag'),
      filter: true,
      showToggleAll: false,
      display: "chip"
    }, {
      chip: _withCtx((slotProps) => [
        _createElementVNode("span", {
          id: `tag-${_ctx.getTag(slotProps.value).idWorldTag}`,
          class: "icon-colored",
          style: _normalizeStyle(`background: ${_ctx.getTag(slotProps.value).color}`)
        }, null, 12, _hoisted_2),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.getTag(slotProps.value).label), 1)
      ]),
      option: _withCtx((slotProps) => [
        _createElementVNode("span", {
          "aria-label": slotProps.option.label,
          class: "label"
        }, _toDisplayString(slotProps.option.label), 9, _hoisted_4)
      ]),
      _: 1
    }, 8, ["modelValue", "options", "placeholder"])
  ]))
}