import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e5d8fab"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "label" }
const _hoisted_3 = { class: "actions" }
const _hoisted_4 = { class: "fields" }
const _hoisted_5 = ["id"]
const _hoisted_6 = { for: "1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Button = _resolveComponent("Button")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.data.fullToken,
    class: _normalizeClass(["field-price-config-wizard-row", _ctx.data.status])
  }, [
    _createElementVNode("header", null, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.data.label), 1),
      (_ctx.showSwitch)
        ? (_openBlock(), _createBlock(_component_InputSwitch, {
            key: 0,
            class: "small",
            modelValue: _ctx.data.status !== _ctx.rowStatus.DISABLED,
            onChange: _ctx.onSwitch,
            tabindex: "-1"
          }, null, 8, ["modelValue", "onChange"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.actions, (action, index) => {
          return _withDirectives((_openBlock(), _createBlock(_component_Button, {
            key: index,
            icon: action.icon,
            class: "p-button-sm p-button-text",
            onClick: ($event: any) => (_ctx.onAction(action.value))
          }, null, 8, ["icon", "onClick"])), [
            [_directive_tooltip, action.label]
          ])
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.fields, (field) => {
        return (_openBlock(), _createElementBlock("div", {
          key: field.fullToken,
          id: field.fullToken,
          class: "field"
        }, [
          _createElementVNode("label", _hoisted_6, _toDisplayString(field.label), 1),
          _createVNode(_component_InputNumber, {
            id: `input-${field.fullToken}`,
            mode: "decimal",
            modelValue: _ctx.getValue(field),
            placeholder: _ctx.getPlaceholder(field, true),
            class: _normalizeClass(_ctx.errors.includes(field.fullToken) ? 'p-invalid' : ''),
            step: 0.01,
            min: 0,
            max: 999,
            minFractionDigits: 2,
            maxFractionDigits: 2,
            onFocusin: ($event: any) => (
            _ctx.selectedField = {
              token: field.token,
              value: field.value,
            }
          ),
            onInput: _ctx.onFieldInput,
            onFocusout: ($event: any) => (_ctx.updateField(field)),
            disabled: _ctx.data.status === _ctx.rowStatus.DISABLED,
            autocomplete: "off"
          }, null, 8, ["id", "modelValue", "placeholder", "class", "step", "onFocusin", "onInput", "onFocusout", "disabled"])
        ], 8, _hoisted_5))
      }), 128))
    ])
  ], 10, _hoisted_1))
}