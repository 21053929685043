import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8c730a8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["logo", _ctx.type])
  }, [
    _createElementVNode("img", {
      class: "cursor-pointer print-img",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/map'))),
      src: _ctx.src,
      alt: "logo"
    }, null, 8, _hoisted_1)
  ], 2))
}