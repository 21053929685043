import i18n from '@/i18n';
import { Discount } from './booking';
import { Reservation } from './reservation';

/* REFUND TYPE */

export enum RefundType {
  NONE,
  CODE,
  STRIPE,
}
export interface RefundTypeData {
  type: RefundType;
  label: string;
}

export const REFUND_TYPES_DATA: Array<RefundTypeData> = [
  {
    type: RefundType.NONE,
    label: i18n.global.t('refund.type.none'),
  },
  {
    type: RefundType.CODE,
    label: i18n.global.t('refund.type.voucher'),
  },
  {
    type: RefundType.STRIPE,
    label: i18n.global.t('refund.type.stripe'),
  },
];

/* REFUND STATUS */

export enum RefundStatus {
  WAITING = 1,
  PROCESSING,
  REJECTED,
  COMPLETED,
}

export interface RefundStatusData {
  status: RefundStatus;
  label: string;
}

export const REFUND_STATUS_DATA: Array<RefundStatusData> = [
  {
    status: RefundStatus.WAITING,
    label: i18n.global.t('refund.status.waiting'),
  },
  {
    status: RefundStatus.PROCESSING,
    label: i18n.global.t('refund.status.processing'),
  },
  {
    status: RefundStatus.REJECTED,
    label: i18n.global.t('refund.status.rejected'),
  },
  {
    status: RefundStatus.COMPLETED,
    label: i18n.global.t('refund.status.completed'),
  },
];

/* REFUND */

export interface Refund {
  id: number;
  licenseId: string;
  reservationId: number;
  type: RefundType;
  processedAt: number;
  status: RefundStatus;
  discountCodeId: number;
  amount: number;
  stripeChargeId: string;
  createdAt: number;
  updatedAt: number;
  // Relationships
  reservation?: Reservation;
  discountCode?: Discount | null;
}
