
import { defineComponent } from 'vue';
import { ToastSeverity } from '@/models/toast';
import toastUtil from '@/utils/toastUtil';
import {
  ApiAdminListResponse,
  ApiApiKeysListResponse,
  ApiRolesResponse,
  ApiWorkListResponse,
} from '@/models/api';
import { Admin } from '@/models/admin';
import { ApiKey } from '@/models/apiKey';
import { Work } from '@/models/work';
import adminService from '@/services/adminService';
import workService from '@/services/workService';
import apiKeyService from '@/services/apiKeyService';
import { License } from '@/models/license';
import permissionsUtil from '@/utils/permissionsUtil';
import roleService from '@/services/roleService';
import {
  DefaultRoles,
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
  RolePermission,
} from '@/models/permissions';
import CreateAdminDialog from './CreateAdminDialog.vue';
import errorUtil from '@/utils/errorUtil';

const ADMIN_MODEL_DEFAULT = {
  adminId: 0,
  email: '',
  facebook: 0,
  firstName: '',
  lastName: '',
  password: '',
  id: 0,
  role: 0,
} as Admin;

export default defineComponent({
  components: {
    CreateAdminDialog,
  },
  data() {
    return {
      adminDialog: false,
      logsDialog: false,
      admins: [] as Array<Admin>,
      works: [] as Array<Work>,
      apiKeys: [] as Array<ApiKey>,
      editUser: ADMIN_MODEL_DEFAULT,
      currentAdmin: {} as Admin,
      rolesList: [] as Array<RolePermission>,
    };
  },
  computed: {
    hasPermission(): boolean {
      return this.$store.getters['session/roleUser'] === 'superuser';
    },
    user(): any | null {
      return this.$store.getters['session/user'];
    },
    rolesVisibleList(): RolePermission[] {
      const roleLoggedUser = this.$store.getters['session/roleUser'];
      return this.rolesList.filter(
        (role: RolePermission) =>
          (role.name === DefaultRoles.SUPERUSER &&
            roleLoggedUser === DefaultRoles.SUPERUSER) ||
          role.name !== DefaultRoles.SUPERUSER,
      );
    },
    adminsVisibleList(): Admin[] {
      const roleLoggedUser = this.$store.getters['session/roleUser'];
      const roleList = this.rolesList;
      return this.admins.filter((admin: Admin) => {
        const rolesFilter = roleList.filter((item) => item.id === admin.role);
        if (rolesFilter.length === 1) {
          return (
            (rolesFilter[0].name === DefaultRoles.SUPERUSER &&
              roleLoggedUser === DefaultRoles.SUPERUSER) ||
            rolesFilter[0].name !== DefaultRoles.SUPERUSER
          );
        }

        return true;
      });
    },
  },
  methods: {
    async loadUserList() {
      const res: ApiAdminListResponse = await adminService.list();
      this.admins = res.result.admins;
    },
    license(): License {
      return this.$store.getters['session/license'];
    },
    hasDeletePermission(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.settings,
        FEATURE_PERMISSION_ACTION_CONFIG.settings.ADMIN_SETTINGS_DELETE_USER,
      );
    },
    hasUpdatePasswordPermission(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.settings,
        FEATURE_PERMISSION_ACTION_CONFIG.settings
          .ADMIN_SETTINGS_CHANGE_USER_PASSWORD,
      );
    },
    async onCreateAdminSuccess(): Promise<void> {
      try {
        await this.loadUserList();
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
      } catch (error) {
        this.handleError(error);
      }
    },
    handleError(error: unknown): void {
      const errorMsg = errorUtil.getErrorMessage(error);

      this.$toast.add(
        toastUtil.build(
          ToastSeverity.ERROR,
          this.$t('toast.error.title'),
          errorMsg,
        ),
      );
    },
    async edit() {
      try {
        await adminService.update(this.editUser.adminId, this.editUser);
        await this.loadUserList();
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
        this.adminDialog = false;
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
    async destroy(element: Admin) {
      try {
        await adminService.delete(element.adminId);
        await this.loadUserList();
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
    getRoleName(role: RolePermission) {
      let lang = this.$t(`rolesDefault.${role.name}`);
      if (lang.startsWith('rolesDefault.')) {
        lang = role.name;
      }
      return lang;
    },
    getUserRoleName(role: number | null) {
      let lang = null;
      if (
        typeof this.rolesList !== 'undefined' &&
        this.rolesList.length > 0 &&
        role !== null
      ) {
        const rolesFilter = this.rolesList.filter((item) => item.id === role);
        if (rolesFilter.length === 1) {
          lang = this.$t(`rolesDefault.${rolesFilter[0].name}`);
        }

        if (
          (lang === null || lang.startsWith('rolesDefault.')) &&
          rolesFilter.length > 0
        ) {
          lang = rolesFilter[0].name;
        }
      }
      return lang;
    },
    userSelect(admin: Admin) {
      this.currentAdmin = admin;
    },
    getSuperUserClass(admin: Admin) {
      if (this.user) {
        if (
          this.$store.getters['session/roleUser'] === 'superuser' &&
          admin.id === 1 &&
          this.user.admin === false
        ) {
          return 'user-permission-denied';
        }
      }
    },
    getWorkImageSource(element: Work) {
      if (!element.image) {
        return require('@/assets/images/placeholders/work.svg');
      }
      return element.image;
    },
    getApiImageSource(element: ApiKey) {
      if (!element.worldWork?.image) {
        return require('@/assets/images/placeholders/api.svg');
      }
      return element.worldWork.image;
    },
    getAvatarSource(element: Admin) {
      const fb = 'https://graph.facebook.com/';
      const token = '746342188714963|68064b3b41c925ad8347e24b65233d0e';
      if (!element.facebook) {
        return require('@/assets/iconset/user.svg');
      }
      return `${fb + element.facebook}/picture?access_token=${token}`;
    },
    getAvatarClass(element: Admin) {
      if (!element.facebook) {
        return 'admin-avatar';
      }
      return 'facebook-avatar';
    },
    confirmDelete(element: Admin): void {
      this.$confirm.require({
        message: this.$t('admins.confirm.message'),
        header: this.$t('admins.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('common.yes'),
        accept: () => {
          this.destroy(element);
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          // nothing
        },
      });
    },
    async editAdminDialog(user: Admin): Promise<void> {
      this.editUser = user;
      this.adminDialog = true;
    },
    undo() {
      this.adminDialog = false;
    },
  },
  async beforeMount() {
    try {
      const resRoles: ApiRolesResponse = await roleService.list(
        this.license().license,
      );
      this.rolesList = resRoles.result.roles;

      await this.loadUserList();
      const worksRes: ApiWorkListResponse = await workService.list();
      this.works = worksRes.result.works;
      const apiKeysRes: ApiApiKeysListResponse = await apiKeyService.list();
      this.apiKeys = apiKeysRes.result.apiKeys;
    } catch (error) {
      // console.log(error);
    }
  },
});
