
import { defineComponent, PropType } from 'vue';
import { LicenseTicketLimits } from '@/models/license';

export default defineComponent({
  name: 'SettingsTicketLimitsDialog',
  emits: ['close', 'submitForm'],
  props: {
    modelValue: {
      type: Object as PropType<LicenseTicketLimits>,
      required: true,
    },
    showDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      // Fake flags to enable limits
      hasReservationLimit: false,
      hasDailyLimit: false,
    };
  },
  methods: {
    updateLimitFlags(): void {
      this.hasReservationLimit = this.model.ticketReservationLimit > 0;
      this.hasDailyLimit = this.model.ticketMaximumCapacity > 0;
    },
    async onSaveSettings(): Promise<void> {
      // Check if limits are enabled or not
      if (!this.hasReservationLimit) {
        this.model.ticketReservationLimit = 0;
      }
      if (!this.hasDailyLimit) {
        this.model.ticketMaximumCapacity = 0;
      }
      // Emit submit event with updated model
      this.$emit('submitForm', this.model);
      // Close dialog
      this.visible = false;
    },
  },
  computed: {
    model: {
      get(): LicenseTicketLimits {
        return this.modelValue;
      },
      set(data: any) {
        //
      },
    },
    visible: {
      get(): boolean {
        return true;
      },
      set(visible: boolean): void {
        if (!visible) {
          this.$emit('close');
        }
      },
    },
  },
  watch: {
    showDialog(visible: boolean) {
      if (visible) {
        this.updateLimitFlags();
      }
    },
  },
});
