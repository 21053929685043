import i18n from '@/i18n';

export default {
  it: {
    startsWith: 'Starts with',
    contains: 'Contains',
    notContains: 'Not contains',
    endsWith: 'Ends with',
    equals: 'Equals',
    notEquals: 'Not equals',
    noFilter: 'No Filter',
    lt: 'Less than',
    lte: 'Less than or equal to',
    gt: 'Greater than',
    gte: 'Greater than or equal to',
    dateIs: 'Date is',
    dateIsNot: 'Date is not',
    dateBefore: 'Date is before',
    dateAfter: 'Date is after',
    clear: 'Clear',
    apply: i18n.global.t('button.apply'),
    matchAll: 'Match All',
    matchAny: 'Match Any',
    addRule: 'Add Rule',
    removeRule: 'Remove Rule',
    accept: i18n.global.t('common.yes'),
    reject: i18n.global.t('common.no'),
    choose: i18n.global.t('button.choose'),
    upload: i18n.global.t('button.upload'),
    cancel: i18n.global.t('button.cancel'),
    dayNames: [
      i18n.global.t('day.sunday'),
      i18n.global.t('day.monday'),
      i18n.global.t('day.tuesday'),
      i18n.global.t('day.wednesday'),
      i18n.global.t('day.thursday'),
      i18n.global.t('day.friday'),
      i18n.global.t('day.saturday'),
    ],
    dayNamesShort: [
      i18n.global.t('day.sun'),
      i18n.global.t('day.mon'),
      i18n.global.t('day.tue'),
      i18n.global.t('day.wed'),
      i18n.global.t('day.thu'),
      i18n.global.t('day.fri'),
      i18n.global.t('day.sat'),
    ],
    dayNamesMin: [
      i18n.global.t('day.su'),
      i18n.global.t('day.mo'),
      i18n.global.t('day.tu'),
      i18n.global.t('day.we'),
      i18n.global.t('day.th'),
      i18n.global.t('day.fr'),
      i18n.global.t('day.sa'),
    ],
    monthNames: [
      i18n.global.t('month.january'),
      i18n.global.t('month.february'),
      i18n.global.t('month.march'),
      i18n.global.t('month.april'),
      i18n.global.t('month.may'),
      i18n.global.t('month.june'),
      i18n.global.t('month.july'),
      i18n.global.t('month.august'),
      i18n.global.t('month.september'),
      i18n.global.t('month.october'),
      i18n.global.t('month.november'),
      i18n.global.t('month.december'),
    ],
    monthNamesShort: [
      i18n.global.t('month.janAbbr'),
      i18n.global.t('month.febAbbr'),
      i18n.global.t('month.marAbbr'),
      i18n.global.t('month.aprAbbr'),
      i18n.global.t('month.mayAbbr'),
      i18n.global.t('month.junAbbr'),
      i18n.global.t('month.julAbbr'),
      i18n.global.t('month.augAbbr'),
      i18n.global.t('month.sepAbbr'),
      i18n.global.t('month.octAbbr'),
      i18n.global.t('month.novAbbr'),
      i18n.global.t('month.decAbbr'),
    ],
    today: i18n.global.t('common.today'),
    weekHeader: i18n.global.t('common.weekAbbr'),
    firstDayOfWeek: 1,
    dateFormat: 'dd/mm/yy',
    weak: 'Weak',
    medium: 'Medium',
    strong: 'Strong',
    passwordPrompt: 'Enter a password',
    emptyFilterMessage: i18n.global.t('common.message.emptyFilter'),
    emptyMessage: i18n.global.t('common.message.emptyOption'),
  },
};
