/* eslint-disable import/no-dynamic-require */
/**
 * Service models
 */

import { Timestamp } from '.';
import i18n from '@/i18n';
import { SectorHeader } from './sector';
import { ReservationHalfDay } from '@/models/reservation';

export const ONLINE_PAYMENT_COMMISSION_SERVICE_ID = 512;
// export const OTHER_PORTAL_COMMISSION_SERVICE_ID = 513;
// export const OTHER_PORTAL_COMMISSION_SERVICE_ID = 514;
// export const OTHER_PORTAL_COMMISSION_SERVICE_ID = 515;
// export const OTHER_PORTAL_COMMISSION_SERVICE_ID = 516;
export const BEACH_TICKET_SERVICE_ID = 517;
export const DISCOUNT_CODE_SERVICE_ID = 1613;

/* SERVICE TYPE DATA */

export enum ServiceType {
  BEACH = 1,
  UTLITY,
  DAILY_TICKET,
}

export interface ServiceTypeInfo {
  type: ServiceType;
  label: string;
}

export const SERVICE_TYPE_INFOS: Array<ServiceTypeInfo> = [
  {
    type: ServiceType.BEACH,
    label: i18n.global.t('service.type.beach'),
  },
  {
    type: ServiceType.UTLITY,
    label: i18n.global.t('service.type.utility'),
  },
  {
    type: ServiceType.DAILY_TICKET,
    label: i18n.global.t('service.type.dailyTicket'),
  },
];

/* SERVICE ICON DATA */

export enum ServiceIcon {
  INSERT_COIN = 'insert-coin.svg',
  POLICEMAN = 'policeman.svg',
  CHILDREN = 'children.svg',
  CLOCK = 'clock.svg',
  BOAT = 'boat.svg',
  BIKINI = 'bikini.svg',
  CHAMPAGNE = 'champagne.svg',
  BEACH_VOLLEY = 'beach-volleyball.svg',
  DOG = 'dog.svg',
  DOG_F = 'dog-f.svg',
  DOG_L_F = 'dog-l-f.svg',
  DOG_L_F_PLUS = 'dog-l-f-plus.svg',
  DOG_L_F_LESS = 'dog-l-f-less.svg',
  DOG_L_M = 'dog-l-m.svg',
  DOG_L_M_PLUS = 'dog-l-m-plus.svg',
  DOG_L_M_LESS = 'dog-l-m-less.svg',
  CANOE = 'canoe.svg',
  SAFE_DEPOSIT = 'safe-deposit.svg',
  FLIP_FLOP = 'flip-flop.svg',
  SHOWER = 'shower.svg',
  HAMBURGHER = 'hamburger.svg',
  BASELINE_LOCK = 'baseline-lock.svg',
  OMBRELLONE = 'ombrellone.svg',
  PERSON_SHAPE = 'person-shape.svg',
  FAMILY_GROUP = 'family-group.svg',
  TRAVEL_GROUP = 'travel-group.svg',
  BEACH_BALL = 'beach-ball.svg',
  PALM_TREE = 'palm-tree.svg',
  PARKING = 'parking.svg',
  SWIMMING_POOL = 'swimming-pool.svg',
  POOL = 'pool.svg',
  SUN_PROTECTION = 'sun-protection.svg',
  LIFESAVER = 'lifesaver.svg',
  SAND_BUCKET = 'sand-bucket.svg',
  SURFBOARD = 'surfboard.svg',
  BEACH_TOWEL = 'beach-towel.svg',
  GIFT_CARD = 'gift-card.svg',
  BILBOA = 'bilboa.svg',
  APEROL = 'aperol.svg',
}

export interface ServiceIconInfo {
  icon: ServiceIcon;
  label: string;
  image: string;
}

export const SERVICE_ICON_INFOS: Array<ServiceIconInfo> = [
  {
    icon: ServiceIcon.INSERT_COIN,
    label: i18n.global.t('service.icon.insertCoin'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.INSERT_COIN}`),
  },
  {
    icon: ServiceIcon.POLICEMAN,
    label: i18n.global.t('service.icon.policeman'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.POLICEMAN}`),
  },
  {
    icon: ServiceIcon.CHILDREN,
    label: i18n.global.t('service.icon.children'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.CHILDREN}`),
  },
  {
    icon: ServiceIcon.CLOCK,
    label: i18n.global.t('service.icon.clock'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.CLOCK}`),
  },
  {
    icon: ServiceIcon.BOAT,
    label: i18n.global.t('service.icon.boat'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.BOAT}`),
  },
  {
    icon: ServiceIcon.BIKINI,
    label: i18n.global.t('service.icon.bikini'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.BIKINI}`),
  },
  {
    icon: ServiceIcon.CHAMPAGNE,
    label: i18n.global.t('service.icon.champagne'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.CHAMPAGNE}`),
  },
  {
    icon: ServiceIcon.BEACH_VOLLEY,
    label: i18n.global.t('service.icon.beachVolley'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.BEACH_VOLLEY}`),
  },
  {
    icon: ServiceIcon.DOG,
    label: i18n.global.t('service.icon.dog'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.DOG}`),
  },
  {
    icon: ServiceIcon.DOG_F,
    label: i18n.global.t('service.icon.dogF'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.DOG_F}`),
  },
  {
    icon: ServiceIcon.DOG_L_F,
    label: i18n.global.t('service.icon.letterDogF'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.DOG_L_F}`),
  },
  {
    icon: ServiceIcon.DOG_L_F_PLUS,
    label: i18n.global.t('service.icon.letterDogFPlus'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.DOG_L_F_PLUS}`),
  },
  {
    icon: ServiceIcon.DOG_L_F_LESS,
    label: i18n.global.t('service.icon.letterDogFMinus'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.DOG_L_F_LESS}`),
  },
  {
    icon: ServiceIcon.DOG_L_M,
    label: i18n.global.t('service.icon.letterDogM'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.DOG_L_M}`),
  },
  {
    icon: ServiceIcon.DOG_L_M_PLUS,
    label: i18n.global.t('service.icon.letterDogMPlus'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.DOG_L_M_PLUS}`),
  },
  {
    icon: ServiceIcon.DOG_L_M_LESS,
    label: i18n.global.t('service.icon.letterDogMMinus'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.DOG_L_M_LESS}`),
  },
  {
    icon: ServiceIcon.CANOE,
    label: i18n.global.t('service.icon.canoe'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.CANOE}`),
  },
  {
    icon: ServiceIcon.SAFE_DEPOSIT,
    label: i18n.global.t('service.icon.safeDeposit'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.SAFE_DEPOSIT}`),
  },
  {
    icon: ServiceIcon.FLIP_FLOP,
    label: i18n.global.t('service.icon.flipFlop'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.FLIP_FLOP}`),
  },
  {
    icon: ServiceIcon.SHOWER,
    label: i18n.global.t('service.icon.shower'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.SHOWER}`),
  },
  {
    icon: ServiceIcon.HAMBURGHER,
    label: i18n.global.t('service.icon.hamburger'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.HAMBURGHER}`),
  },
  {
    icon: ServiceIcon.BASELINE_LOCK,
    label: i18n.global.t('service.icon.baselineLock'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.BASELINE_LOCK}`),
  },
  {
    icon: ServiceIcon.OMBRELLONE,
    label: i18n.global.t('service.icon.umbrella'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.OMBRELLONE}`),
  },
  {
    icon: ServiceIcon.PERSON_SHAPE,
    label: i18n.global.t('service.icon.personShape'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.PERSON_SHAPE}`),
  },
  {
    icon: ServiceIcon.FAMILY_GROUP,
    label: i18n.global.t('service.icon.familyGroup'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.FAMILY_GROUP}`),
  },
  {
    icon: ServiceIcon.TRAVEL_GROUP,
    label: i18n.global.t('service.icon.travelGroup'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.TRAVEL_GROUP}`),
  },
  {
    icon: ServiceIcon.BEACH_BALL,
    label: i18n.global.t('service.icon.beachBall'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.BEACH_BALL}`),
  },
  {
    icon: ServiceIcon.PALM_TREE,
    label: i18n.global.t('service.icon.palmTree'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.PALM_TREE}`),
  },
  {
    icon: ServiceIcon.PARKING,
    label: i18n.global.t('service.icon.parking'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.PARKING}`),
  },
  {
    icon: ServiceIcon.SWIMMING_POOL,
    label: i18n.global.t('service.icon.swimmingPool'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.SWIMMING_POOL}`),
  },
  {
    icon: ServiceIcon.POOL,
    label: i18n.global.t('service.icon.pool'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.POOL}`),
  },
  {
    icon: ServiceIcon.SUN_PROTECTION,
    label: i18n.global.t('service.icon.sunProtection'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.SUN_PROTECTION}`),
  },
  {
    icon: ServiceIcon.LIFESAVER,
    label: i18n.global.t('service.icon.lifeSaver'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.SUN_PROTECTION}`),
  },

  {
    icon: ServiceIcon.SAND_BUCKET,
    label: i18n.global.t('service.icon.sandBucket'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.SAND_BUCKET}`),
  },
  {
    icon: ServiceIcon.SURFBOARD,
    label: i18n.global.t('service.icon.surfboard'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.SURFBOARD}`),
  },
  {
    icon: ServiceIcon.BEACH_TOWEL,
    label: i18n.global.t('service.icon.beachTowel'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.BEACH_TOWEL}`),
  },
  {
    icon: ServiceIcon.GIFT_CARD,
    label: i18n.global.t('service.icon.giftCard'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.GIFT_CARD}`),
  },
  {
    icon: ServiceIcon.BILBOA,
    label: i18n.global.t('service.icon.bilboa'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.BILBOA}`),
  },
  {
    icon: ServiceIcon.APEROL,
    label: i18n.global.t('service.icon.aperol'),
    image: require(`@/assets/images/extra-services/${ServiceIcon.APEROL}`),
  },
];

/* SERVICE PRICE MODE DATA */

export enum ServicePriceMode {
  FIXED,
  DAILY,
  TABLE,
  BEDS,
  DAILY_BEDS,
}

export interface ServicePriceModeInfo {
  mode: ServicePriceMode;
  label: string;
}

export const SERVICE_PRICE_MODE_INFOS: Array<ServicePriceModeInfo> = [
  {
    mode: ServicePriceMode.FIXED,
    label: i18n.global.t('service.priceMode.fixed'),
  },
  {
    mode: ServicePriceMode.DAILY,
    label: i18n.global.t('service.priceMode.daily'),
  },
  {
    mode: ServicePriceMode.TABLE,
    label: i18n.global.t('service.priceMode.table'),
  },
  {
    mode: ServicePriceMode.BEDS,
    label: i18n.global.t('service.priceMode.beds'),
  },
  {
    mode: ServicePriceMode.DAILY_BEDS,
    label: i18n.global.t('service.priceMode.dailyBeds'),
  },
];

/* SERVICE LIMIT DATA */

export enum ServiceLimit {
  NONE_LIMIT = 'none',
  BEDS_LIMIT = 'beds',
  DAYS_LIMIT = 'days',
  FIXED_LIMIT = 'fixed',
}

export enum ServiceSellMode {
  ALONE_ONLY = 'alone-only',
  SPOT_ONLY = 'spot-only',
  ALONE_AND_SPOT = 'alone-and-spot',
}

export interface ServiceLimitInfo {
  limit: ServiceLimit;
  label: string;
}

export const SERVICE_LIMIT_INFO_SPOT = [
  {
    limit: ServiceLimit.NONE_LIMIT,
    label: i18n.global.t('service.limit.none'),
  },
  {
    limit: ServiceLimit.BEDS_LIMIT,
    label: i18n.global.t('service.limit.beds'),
  },
  {
    limit: ServiceLimit.DAYS_LIMIT,
    label: i18n.global.t('service.limit.days'),
  },
  {
    limit: ServiceLimit.FIXED_LIMIT,
    label: i18n.global.t('service.limit.fixed'),
  },
];

export const SERVICE_LIMIT_INFO_NO_SPOT = [
  {
    limit: ServiceLimit.NONE_LIMIT,
    label: i18n.global.t('service.limit.none'),
  },
  {
    limit: ServiceLimit.FIXED_LIMIT,
    label: i18n.global.t('service.limit.fixed'),
  },
];

export interface Service {
  id: number;
  licenseId: string;
  serviceGroupId: number | null;
  type: ServiceType;
  halfDay: ReservationHalfDay;
  name: string;
  nameDe: string;
  nameEn: string;
  nameEs: string;
  nameFr: string;
  nameIt: string;
  nameRu: string;
  background: string;
  priceListId: number | null;
  cost: number;
  vat: number;
  dailyItems: number;
  icon: ServiceIcon;
  fastCash: boolean;
  reservationSchedule: boolean;
  online: boolean;
  map: boolean;
  canBeSoldAloneOffline: boolean;
  canBeSoldAloneOnline: boolean;
  sellableOnlineAlone: boolean;
  lockedBehindServices: boolean;
  maxItemsFixed: number | null;
  maxItemsType: ServiceLimit;
  minItemsFixed: number | null;
  minItemsType: ServiceLimit;
  maxItemsTypeAlone: ServiceLimit;
  maxItemsFixedAlone: number;
  minItemsTypeAlone: ServiceLimit;
  minItemsFixedAlone: number;
  hideWhenNotAvailableOnline: boolean;
  priceMode: ServicePriceMode;
  sectors?: Array<SectorHeader> | null;
  webtic?: number;
  webticPriceId?: number | null;
  updatedAt: Timestamp;
  createdAt: Timestamp;
}
