/**
 * Api models
 */

import {
  MapEditorElementRaw,
  MapEditorElementRawData,
  MapEditorElementRawJsonData,
  MapEditorGridAction,
} from '@/models/mapEditor';
import { Links } from './links';
import { User } from './user';
import {
  License,
  LicenseDocumentTemplate,
  LicenseList,
  StripeResponse,
} from './license';
import { Voucher } from './voucher';
import {
  InvoiceDeposit,
  PrintArticle,
  Printer,
  PrintPaymentMethod,
} from './printer';
import { Card, Transaction, TransactionPayload } from '@/models/card';
import { PermissionMode } from '@/models/permissionMode';
import { Admin } from '@/models/admin';
import { Work } from '@/models/work';
import { ApiKey } from '@/models/apiKey';
import { Service } from './service';
import {
  SearchCard,
  SearchCustomer,
  SearchLicense,
  SearchReservation,
  SearchSpot,
} from './search';
import { Customer, Guest } from './customer';
import {
  MapBackground,
  MapElementRaw,
  MapElementTypeRaw,
  MapPlannerData,
  MapSpot,
} from './map';
import { SmartSearchSpot } from './smartSearch';
import { CardType, CardTypeWork } from './cardType';
import { BookingRule, Discount, Offer, ReservationBooking } from './booking';
import {
  PaymentLinkFeesPreview,
  Reservation,
  ReservationDeleteMode,
  ReservationDocument,
  ReservationExpense,
  ReservationExpenseRaw,
  ReservationHalfDay,
  ReservationService,
  ReservationStatus,
  ReservationType,
} from './reservation';
import { CashFlow, CashFlowMethod } from './cashFlow';
import { PlannerElementRaw } from './planner';
import { Spot, SpotBasic, SpotType } from './spot';
import { Log, LogAction } from './log';
import {
  StatisticCashFlowsReservationTypeItem,
  StatisticCashFlowsPayment,
  StatisticCashFlowsPaymentMethodItem,
  StatisticDataTableOrderBy,
  StatisticDataTableSearch,
  StatisticDateMode,
  StatisticCounterHotel,
  StatisticCounterVoucher,
  StatisticAstoriaCashFlows,
  StatisticDataTableFilter,
  StatisticCustomer,
  StatisticDurationReservation,
} from './statistic';
import {
  PriceCombination,
  PriceList,
  PriceListQualityCheck,
  PricePeriod,
  PriceListEstimate,
  PriceListType,
} from './priceList';
import { OrderByItem, FilterByItem, Timestamp, Day } from '@/models';
import { Warehouse, WarehouseAlert } from './warehouse';
import {
  BusinessDetails,
  InvoiceCustomer,
  StripeTransferInvoice,
  StripeTransferInvoiceRow,
} from './invoice';
import { Refund, RefundType } from './refund';
import { DiscountCode } from './discountCode';
import { ColorName } from './color';
import {
  PriceConfig,
  PriceConfigModel,
  PriceConfigWizard,
} from './priceConfig';
import { CheckoutPoint } from './checkoutPoint';
import {
  RevenueManagementBaseRule,
  RevenueManagementHistoricalDetail,
  RevenueManagementOverrideRule,
  RevenueManagementPeriod,
  RevenueManagementPriceAlteration,
  RevenueManagementPriceHint,
  RevenueManagementPriceHintStatus,
  RevenueManagementPrices,
  RevenueManagementRuleDetail,
  RevenueManagementRuleMode,
  RevenueManagementRuleType,
  RevenueManagementSlotDetail,
} from './revenueManagement';
import { StripePaymentIntent } from './stripe';
import { PosTerminal, PosTerminalModel } from './posTerminal';
import { WebticTicket, WebticTerminal } from './webtic';
import { FeaturePermission, RolePermission } from '@/models/permissions';
import {
  Sector,
  SectorDetailKeyName,
  SectorDetailKeyType,
  SectorDetailType,
  SectorHeader,
} from './sector';
import { EditableService } from '@/models/service/editableService';

/* GLOBAL */

export interface ApiPaginationPayload {
  page?: number;
  offset?: number; // numero di elementi -> in futuro migrare a limit
}

export interface ApiOrderPayload {
  orderBy?: Array<OrderByItem>;
}

export interface ApiExpand {
  expand?: Array<string>;
}

export interface ApiResponse {
  error: boolean | null;
  errorEx: string | null;
}

/* AUTH */

export interface ApiAuthSignInResponse extends ApiResponse {
  result: {
    links: Links;
    user: User | null;
  };
}

export interface ApiLicenseListResponse extends ApiResponse {
  result: {
    licenses: Array<LicenseList>;
  };
}

export interface ApiLicenseLogoResponse extends ApiResponse {
  result: {
    status: string;
  };
}

export interface ApiLicenseMapBgResponse extends ApiResponse {
  result: {
    license: License;
  };
}
export interface ApiLicenseImgSectorsResponse extends ApiResponse {
  result: {
    url: string;
  };
}
export interface ApiLicenseResponse extends ApiResponse {
  result: {
    license: License;
    maxSector: number;
  };
}

export interface ApiBookingSectorsRulesResponse extends ApiResponse {
  result: {
    bookingRules: Array<BookingRule>;
  };
}
export interface ApiBookingSectorsRuleResponse extends ApiResponse {
  result: {
    bookingRule: BookingRule;
  };
}

export interface ApiStripeResponse extends ApiResponse {
  result: {
    link: StripeResponse;
  };
}

export interface ApiStripeAccountInitConnectionResponse extends ApiResponse {
  result: {
    stripeAccountLink: string;
  };
}

export interface ApiStripeFinalizeResponse extends ApiResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface ApiLicenseDocumentTemplatesResponse extends ApiResponse {
  result: {
    documentTemplates: Array<LicenseDocumentTemplate>;
  };
}

// WIP
export type ApiLicenseUpdatePayload = Partial<License>;

export interface ApiLicenseUpdateResponse extends ApiResponse {
  result: {
    license: License;
  };
}

export interface ApiPrinterListResponse extends ApiResponse {
  result: {
    printers: Array<Printer>;
  };
}

export interface ApiPrinterResponse extends ApiResponse {
  result: {
    printer: Printer;
  };
}

export interface ApiPrinterDeleteResponse extends ApiResponse {
  result: {
    status: string;
  };
}

export interface ApiPrinterCreatePayload {
  departments: Array<number>;
  invoiceFooter: string;
  invoiceTitle: string;
  invoiceSubtitle: string;
  ip: string;
  model: number;
  name: string;
  port: number;
  fis: boolean;
  nfis: boolean;
  list: boolean;
  text: boolean;
  remote: boolean;
  ssl: boolean;
  invoiceWidth: number;
  mode: number;
  sectors: Array<number>;
}

export type ApiPrinterUpdatePayload = ApiPrinterCreatePayload;

export interface ApiCardTypeListResponse extends ApiResponse {
  result: {
    cardTypes: Array<CardType>;
    works: Array<CardTypeWork>;
  };
}
export interface ApiCardTypeCreateResponse extends ApiResponse {
  result: {
    cardType: CardType;
  };
}
export interface ApiCardTypeUpdateResponse extends ApiResponse {
  result: {
    cardType: CardType;
  };
}

/* SERVICE */
export interface ApiServiceListResponse extends ApiResponse {
  result: {
    beachServices: Array<Service>;
  };
}

export type ApiServiceCreatePayload = EditableService;

export interface ApiServiceCreateResponse extends ApiResponse {
  result: {
    beachService: Service;
  };
}

export type ApiServiceUpdatePayload = ApiServiceCreatePayload;
export interface ApiServiceUpdateResponse extends ApiResponse {
  result: {
    beachService: Service;
  };
}
export interface ApiServiceDeleteResponse extends ApiResponse {
  result: {
    status: string;
  };
}

export interface ApiOfferCreatePayload {
  name: string;
  from: number;
  to: number;
  percentage: number;
  sectors: Array<number>;
}

export type ApiOfferUpdatePayload = ApiOfferCreatePayload;

export interface ApiOfferResponse extends ApiResponse {
  result: {
    offer: Offer;
  };
}

export interface ApiReservationListResponse extends ApiResponse {
  result: {
    reservations: Array<ReservationBooking>;
    count: number;
  };
}

export interface ApiReservationArrayResponse extends ApiResponse {
  result: {
    reservations: Array<Reservation>;
    count: number;
  };
}

export interface ApiOffersListResponse extends ApiResponse {
  result: {
    offers: Array<Offer>;
  };
}

export interface ApiOfferMessageResponse extends ApiResponse {
  result: string;
}

export interface ApiDiscountResponse extends ApiResponse {
  result: {
    discountCode: Discount;
  };
}

export interface ApiDiscountsListResponse extends ApiResponse {
  result: {
    discountCodes: Array<Discount>;
  };
}

export interface ApiDiscountMessageResponse extends ApiResponse {
  result: string;
}

export interface ApiSearchResponse extends ApiResponse {
  result: {
    reservations: Array<SearchReservation>;
    futureReservations: Array<SearchReservation>;
    // hotels: Array<SearchHotel>;
    customers: Array<SearchCustomer>;
    spots: Array<SearchSpot>;
    cards: Array<SearchCard>;
  };
}

export interface ApiSearchLicensesResponse extends ApiResponse {
  result: {
    licenses: Array<SearchLicense>;
  };
}

export interface ApiCustomerStatisticsPayload {
  startDate: number;
  endDate?: number | null;
  contactId?: number | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phoneAreaCode?: string | null;
  phoneNumber?: string | null;
}

export interface ApiCustomerStatisticsResponse extends ApiResponse {
  result: StatisticCustomer;
}

export interface ApiCustomerOneResponse extends ApiResponse {
  result: {
    customer: Customer;
    cards: Array<Card>;
  };
}

export interface ApiCustomerListResponse extends ApiResponse {
  result: {
    customers: Array<Customer>;
    totalRecords: number;
  };
}

export interface ApiCustomerIndexRequestPayload extends ApiResponse {
  orderBys: Array<OrderByItem>;
  filterBys: Array<FilterByItem>;
  searchBy?: string;
  limit: number;
  offset: number;
}

export interface ApiCustomerExportPayload {
  from?: number;
  to?: number;
  withEmail?: boolean;
  withPhone?: boolean;
  daily?: boolean;
  seasonal?: boolean;
  subscriptions?: boolean;
}

export interface ApiCustomerImportResponse {
  result: {
    import: boolean;
  };
}

// Card

export interface ApiCardListResponse extends ApiResponse {
  result: {
    cards: Array<Card>;
    cardTypes: Array<CardType>;
  };
}
export interface ApiCardDetailsResponse extends ApiResponse {
  result: {
    card: Card;
    transactions: Array<Transaction>;
  };
}
export interface ApiCardCreateResponse extends ApiResponse {
  result: {
    card: Card;
  };
}
export interface ApiCardUpdateResponse extends ApiResponse {
  result: {
    card: Card;
  };
}
export interface ApiCardDeleteResponse extends ApiResponse {
  result: {
    card: Card;
  };
}

export interface ApiTransactionCreateResponse extends ApiResponse {
  result: {
    transaction: TransactionPayload;
  };
}

// database

export interface ApiDatabaseCreateResponse extends ApiResponse {
  result: {
    mode: PermissionMode;
  };
}
export interface ApiDatabaseShowAllResponse extends ApiResponse {
  result: {
    reservations: Array<Reservation>;
  };
}
export interface ApiDatabaseShowOfflineResponse extends ApiResponse {
  result: {
    reservations: Array<Reservation>;
  };
}
export interface ApiDatabaseShowOnlineResponse extends ApiResponse {
  result: {
    reservations: Array<Reservation>;
  };
}
export interface ApiLogBinListResponse extends ApiResponse {
  result: {
    logs: Array<Log>;
  };
}
// Admin

export interface ApiAdminListResponse extends ApiResponse {
  result: {
    admins: Array<Admin>;
  };
}
export interface ApiWorkListResponse extends ApiResponse {
  result: {
    works: Array<Work>;
  };
}

export interface ApiApiKeysListResponse extends ApiResponse {
  result: {
    apiKeys: Array<ApiKey>;
  };
}

export interface ApiIsAdminResponse extends ApiResponse {
  result: {
    admin: Admin;
  };
}
export interface ApiAdminCreateResponse extends ApiResponse {
  result: {
    admin: Admin;
  };
}
export interface ApiAdminUpdateResponse extends ApiResponse {
  result: {
    admin: Admin;
  };
}
export interface ApiAdminDeleteResponse extends ApiResponse {
  result: {
    admin: Admin;
  };
}

export interface ApiLogListResponse extends ApiResponse {
  result: {
    logs: Array<Log>;
    count: number;
  };
}

/* MAP DATA */

export interface ApiMapDataPayload {
  from: number;
  to?: number;
  reservationId?: number; // spot has many reservations -> return the one with id provided
}

export interface ApiMapDataResponse extends ApiResponse {
  result: {
    height: number;
    width: number;
    elements: Array<MapElementRaw>;
    background: MapBackground;
  };
}

/* MAP PLANNER */

export interface ApiMapPlannerPayload {
  spot: MapSpot;
  from: number;
  to: number;
}

export interface ApiMapPlannerResponse extends ApiResponse {
  result: MapPlannerData;
}

/* PLANNER DATA */

export interface ApiPlannerReservationWithStepsPayload {
  startDate: number;
  endDate: number;
  spotType: SpotType;
  spotName: string;
  steps: Array<{
    spotType: SpotType;
    spotName: string;
    startDate: number;
    endDate?: number;
  }>;
}

export interface ApiPlannerReservationWithStepsResponse {
  result: {
    reservation: Reservation;
  };
}

export interface ApiPlannerDataPayload {
  year: number;
  sectorIds: number[];
}

export interface ApiPlannerDataResponse extends ApiResponse {
  result: {
    spots: Array<PlannerElementRaw>;
  };
}

/* SMART SEARCH */

export interface ApiSmartSearchPayload {
  from: number;
  to: number;
  halfDay?: boolean;
  spotType?: SpotType;
  sectors?: string;
  maxSwaps?: number;
}

export interface ApiSmartSearchResponse extends ApiResponse {
  result: {
    steps: Array<SmartSearchSpot>;
  };
}

/* RESERVATION */
export interface ApiReservationCreatePayload {
  spotType: SpotType;
  spotName: string;
  startDate?: number;
  endDate?: number;
  beds?: number;
  maxiBeds?: number;
  chairs?: number;
  deckChairs?: number;
  voucherId?: number;
  hotel?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export interface ApiReservationCreateResponse extends ApiResponse {
  result: {
    reservations: Array<Reservation>;
  };
}

export interface ApiReservationOneResponse extends ApiResponse {
  result: {
    reservation: Reservation;
  };
}

/* RESERVATION BILL */

export interface ApiBillReservationCreatePayload {
  startDate: number;
  endDate: number;
}

/* RESERVATION FIND */

export interface ApiReservationFindPayload
  extends ApiPaginationPayload,
    ApiOrderPayload {
  spotType: SpotType;
  spotName: string;
  startDate?: number;
  endDate?: number;
  type?: Array<ReservationType>;
  status?: ReservationStatus;
  fields?: Array<string>;
  paid?: boolean;
  deleted?: boolean;
  online?: boolean;
  seasonal?: boolean;
  locked?: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  contactId?: number;
}

export interface ApiReservationFindResponse extends ApiResponse {
  result: {
    reservations: Array<Reservation>;
    count: number;
  };
}

/* RESERVATION DELETE */

export interface ApiReservationDeletePayload {
  id: number;
  mode: ReservationDeleteMode;
  refundAmount?: number;
  refundType: RefundType;
  creating?: boolean;
}

export interface ApiReservationDeleteResponse extends ApiResponse {
  result: {
    discountCode: DiscountCode | null;
    refund: Refund | null;
  };
}

/* RESERVATION UPDATE */

export type ApiReservationUpdateType =
  | string
  | boolean
  | number
  | ReservationHalfDay
  | ReservationStatus
  | Array<number>
  | undefined
  | null;

export interface ApiReservationUpdatePayload {
  [key: string]: ApiReservationUpdateType;
  halfDay?: ReservationHalfDay; // check
  seasonal?: boolean;
  contactId?: number | null;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  invoiceCompany?: string;
  invoiceVatCode?: string;
  invoiceTaxCode?: string;
  invoiceLotteryCode?: string;
  invoicePec?: string;
  invoiceSdi?: string;
  invoiceAddress1?: string;
  invoiceAddress2?: string;
  invoiceCity?: string;
  invoiceZip?: string;
  invoiceState?: string;
  invoiceCountry?: string;
  startDate?: number;
  endDate?: number;
  beds?: number;
  maxiBeds?: number;
  deckChairs?: number;
  chairs?: number;
  status?: ReservationStatus;
  forcedTotal?: number | null;
  forcedBeach?: number | null;
  forcedServices?: number | null;
  notes?: string;
  vehiclePlate?: string;
  propagate?: Array<number>;
}

export interface ApiCardAstoriaOperationsPayload {
  emitter: number;
  amount: number;
  notes: string;
  cardType: number;
  hasUmbrella?: boolean;
  beds: number;
  exchangeRate?: number;
  date?: number;
}

export interface ApiReservationUpdateResponse extends ApiResponse {
  result: {
    reservation: Reservation;
  };
}

/* RESERVATION SEASONAL */

export interface ApiReservationSeasonalPayload {
  value: boolean;
  propagate?: Array<number>;
}

export interface ApiReservationSeasonalResponse extends ApiResponse {
  result: {
    reservation: Reservation;
  };
}

/* RESERVATION ABSENCE */

export interface ApiReservationCreateAbsencePayload {
  startDate: number;
  endDate: number;
  weekDays?: Array<Day>;
}

export interface ApiReservationCreateAbsenceResponse extends ApiResponse {
  result: {
    absence: Reservation | null;
  };
}

/* RESERVATION SERVICE */

export interface ApiReservationGetServicesResponse extends ApiResponse {
  result: {
    services: Array<ReservationService>;
  };
}

export interface ApiReservationUpdateServicePayload {
  serviceId: number;
  bought?: number;
  deleted?: number;
}

export interface ApiReservationUpdateServiceResponse extends ApiResponse {
  result: {
    service: ReservationService;
  };
}

/* RESERVATION GUEST */

export interface ApiReservationGetGuestsResponse extends ApiResponse {
  result: {
    contacts: Array<Guest>;
  };
}

export type ApiReservationCreateGuestPayload = Customer;

export interface ApiReservationCreateGuestResponse extends ApiResponse {
  result: {
    contact: Guest;
  };
}

/* RESERVATION EXTRA */

export interface ApiReservationCreateAdditionPayload {
  spotType?: SpotType;
  spotName?: string;
  beds?: number;
  maxiBeds?: number;
  deckChairs?: number;
  chairs?: number;
  startDate?: number;
  endDate?: number;
  vehiclePlate?: string;
}
export interface ApiReservationCreateAdditionResponse extends ApiResponse {
  result: {
    addition: Reservation;
  };
}

/* RESERVATION MERGE */

export interface ApiReservationMergePayload {
  reservations: Array<number>;
}

export interface ApiReservationMergeResponse extends ApiResponse {
  result: {
    reservation: Reservation;
  };
}
export interface ApiReservationRecycleBinResponse extends ApiResponse {
  result: {
    reservation: Reservation;
  };
}

/* RESERVATION MOVE */

export interface ApiReservationMovePayload {
  spotType: SpotType;
  spotName: string;
  startDate: number;
  // endDate?: number; TODO
}

export interface ApiReservationMoveResponse extends ApiResponse {
  result: {
    reservation: Reservation;
  };
}

/* RESERVATION STEP */

export interface ApiReservationCreateStepPayload {
  spotType: SpotType;
  spotName: string;
  startDate: number;
  endDate?: number;
}

export interface ApiReservationCreateStepResponse extends ApiResponse {
  result: {
    reservation: Reservation;
  };
}

export interface ApiReservationDeleteStepResponse extends ApiResponse {
  result: {
    reservation: Reservation;
  };
}

export interface ApiReservationUpdateStepPayload {
  id?: number;
  startDate?: number;
  endDate?: number;
}

export interface ApiReservationUpdateStepResponse extends ApiResponse {
  result: {
    reservation: Reservation;
  };
}

/* RESERVATION COPY */

export interface ApiReservationCopyPayload {
  spots: Array<SpotBasic>;
}

export interface ApiReservationCopyResponse extends ApiResponse {
  result: {
    reservations: Array<Reservation>;
  };
}

/* RESERVATION ORDER SUMMARY */

export interface ApiReservationOrderSummaryPayload {
  provider: string;
  paymentLink: boolean;
  amount: number | null;
  validUntil: number | null;
  areFeesOnCustomer: boolean;
}

export interface ApiReservationOrderSummaryResponse extends ApiResponse {
  result: { id: number };
}

export interface ApiPaymentLinkFeesPreviewPayload {
  amount: number;
}

export interface ApiPaymentLinkFeesPreviewResponse extends ApiResponse {
  result: PaymentLinkFeesPreview;
}

/* RESERVATION EXPENSES */

export interface ApiReservationPayExpensePayload {
  // TODO
  todo: string;
}
export interface ApiReservationPayExpenseResponse extends ApiResponse {
  result: {
    expense: ReservationExpense;
  };
}
export interface ApiReservationDeleteExpensesResponse extends ApiResponse {
  result: boolean; // CHECK
}

export interface ApiReservationGetExpensesResponse extends ApiResponse {
  result: {
    expenses: Array<ReservationExpenseRaw>;
  };
}

/* RESERVATION DOCUMENTS */

export interface ApiReservationGetDocumentsPayload {
  id: number;
  templateId: number;
}

export interface ApiReservationGetDocumentsResponse extends ApiResponse {
  result: {
    documents: Array<ReservationDocument>;
  };
}

export interface ApiReservationCreateDocumentPayload {
  id: number;
  templateId: number;
  documentImage: File;
}

export interface ApiReservationCreateDocumentResponse extends ApiResponse {
  result: {
    document: ReservationDocument;
  };
}

export interface ApiReservationDeleteDocumentPayload {
  id: number;
  templateId: number;
  documentId: number;
}

export interface ApiReservationDeleteDocumentResponse extends ApiResponse {
  result: boolean;
}

export interface ApiReservationRestoreResponse extends ApiResponse {
  result: {
    reservation: Reservation;
  };
}

/* DiSJOINT ACCOUNT */
export interface ApiReservationDisjoinAccountPayload {
  reservations: Array<number>;
}

export interface ApiReservationDisjoinAccountResponse extends ApiResponse {
  result: {
    reservation: Reservation | null;
  };
}

/* RESERVATION DISCOUNT CODE */

export interface ApiReservationDiscountCodeResponse extends ApiResponse {
  result: {
    discountCode: DiscountCode | null;
  };
}

/* RESERVATION SEND INVOICE */

export interface ApiReservationSendInvoicePayload {
  products: Array<PrintArticle>;
  deposits: Array<InvoiceDeposit>;
  customer: InvoiceCustomer;
  cashFlow: {
    reservationId: number;
    amount: number;
    invoiceAmount: number;
    method: number;
    notes: string | null;
    expenses: Array<number>;
    cardId?: number;
  };
}

export interface ApiReservationSendInvoiceResponse extends ApiResponse {
  result: {
    cashFlow: CashFlow;
    invoiceUrl: string;
  };
}

/* COPY SEASONAL */

export interface ApiReservationCopySeasonalResponse extends ApiResponse {
  result: {
    reservation: Array<Reservation>;
  };
}

/* CONTACT */

export interface ApiContactHintPayload {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
}

export interface ApiContactHintResponse extends ApiResponse {
  result: {
    hints: any;
  };
}

/* CASH FLOW */

export interface ApiCashFlowGetPayload {
  reservationId: number;
}

export interface ApiCashFlowGetResponse extends ApiResponse {
  result: {
    cashFlow: CashFlow;
  };
}

export interface ApiCashFlowFindPayload
  extends ApiPaginationPayload,
    ApiOrderPayload {
  reservationId?: number;
  receiptId?: number;
  invoiceId?: number;
  methods?: string;
  expand?: string;
  dateFrom?: number;
  discardInvoices?: boolean;
}

export interface ApiCashFlowFindResponse {
  result: {
    cashFlows: Array<CashFlow>;
  };
}

// FIXME: Check definition, too many nullables
export interface ApiCashFlowCreatePayload {
  reservationId: number;
  amount: number;
  method: number;
  notes: string | null;
  expenses: Array<number>;
  receipt?: {
    printerId: number | null; //
    date: number | null; //
    method: PrintPaymentMethod | null; //
    number: string | null;
    total: number; //
    subTotal1: number | null;
    vat1: number | null;
    subTotal2: number | null;
    vat2: number | null;
    subTotal3: number | null;
    vat3: number | null;
    subTotal4: number | null;
    vat4: number | null;
    refundedReceiptId?: number | null;
  };
  cardId: number | null;
}

export interface ApiCashFlowCreateResponse extends ApiResponse {
  result: {
    cashFlow: CashFlow;
  };
}

// TODO: this has changed! check with api
export interface ApiCashFlowUpdatePayload {
  id: number;
  date?: number;
  method?: CashFlowMethod;
  fiscal?: boolean;
  receipt?: {
    printerId: number | null;
    date: number | null;
    method: PrintPaymentMethod | null;
    number: string | null;
    total: number;
    subTotal1: number | null;
    vat1: number | null;
    subTotal2: number | null;
    vat2: number | null;
    subTotal3: number | null;
    vat3: number | null;
    subTotal4: number | null;
    vat4: number | null;
  };
  notes?: string;
}
export interface ApiFiscalReservationFlowsPayload {
  receipt: {
    printerId: number;
    date: number;
    method: PrintPaymentMethod;
    number: string;
    total: number;
    subTotal1: number | null;
    vat1: number | null;
    subTotal2: number | null;
    vat2: number | null;
    subTotal3: number | null;
    vat3: number | null;
    subTotal4: number | null;
    vat4: number | null;
  };
}

export interface ApiCashFlowUpdateResponse extends ApiResponse {
  result: {
    cashFlow: CashFlow;
  };
}

/* SPOT */

export interface ApiSpotFindPayload
  extends ApiPaginationPayload,
    ApiOrderPayload {
  startDate?: number;
  endDate?: number;
  type?: SpotType;
  spotName?: string;
}

export interface ApiSpotFindResponse extends ApiResponse {
  result: {
    count: number;
    spots: Array<Spot>;
  };
}

/* LOG */

export interface ApiLogFindPayload
  extends ApiPaginationPayload,
    ApiOrderPayload {
  reservationId?: number;
  userId?: number;
  action?: Array<LogAction>;
}

export interface ApiLogFindResponse extends ApiResponse {
  result: {
    logs: Array<Log>;
    count: number;
  };
}

export interface ApiLogCreatePayload {
  reservationId?: number;
  action: LogAction;
  value: string;
}

// export interface ApiLogStoreResponse extends ApiResponse {
//   result: {
//     log: Log;
//   };
// }

/* STATISTICS */

export interface ApiStatisticsDataTablePayload {
  startDate: number;
  endDate: number;
  limit: number;
  offset: number;
  search: StatisticDataTableSearch;
  orderBy: Array<StatisticDataTableOrderBy>;
  columns: Array<string>;
  filters?: Array<StatisticDataTableFilter>;
  absenceDays?: boolean;
  groupData: boolean;
}

export interface ApiStatisticsExtraServicesPayload {
  startDate: number;
  endDate: number;
}

export interface ApiStatisticsDataTableResponse {
  count: number;
  elements: Array<any>;
}

/* STATISTICS - DASHBOARD */

export interface ApiStatisticsDashboardPayload {
  startDate: number;
  endDate: number;
}

export interface ApiStatisticsDashboardResponse extends ApiResponse {
  result: {
    cards: {
      arrivals: number;
      departures: number;
      cancellations: Array<number>;
      occupationRate: number;
      revenue: number;
      attendances: number;
      discounts: Array<number>;
      mediumChart: number;
    };
    charts: {
      reservationsByChannel: Array<number>;
      reservationsByPaymentStatus: Array<number>;
      arrivalsDepartures: Array<Array<number>>;
      attendances: Array<Array<number>>;
    };
  };
}

export interface ApiStatisticsDashboardExportPayload {
  startDate: Timestamp;
  endDate: Timestamp;
}

export type ApiStatisticsDashboardExportResponse = Blob;

/* STATISTICS  - ARRIVALS TODAY */

export interface ApiStatisticsArrivalsTodayPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
}

export interface ApiStatisticsArrivalsTodayResponse extends ApiResponse {
  result: {
    cards: {
      averageDuration: number;
      averagePrice: number;
      toPay: number;
      totalPrice: number;
    };
    dataTable: ApiStatisticsDataTableResponse;
  };
}

/* STATISTICS  - DEPARTURES TODAY */

export interface ApiStatisticsDeparturesTodayPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
}

export interface ApiStatisticsDeparturesTodayResponse extends ApiResponse {
  result: {
    cards: {
      averageDuration: number;
      averagePrice: number;
      toPay: number;
      totalPrice: number;
    };
    dataTable: ApiStatisticsDataTableResponse;
  };
}

/* STATISTICS  - ARRIVALS TOMORROW */

export interface ApiStatisticsArrivalsTomorrowPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
}

export interface ApiStatisticsArrivalsTomorrowResponse extends ApiResponse {
  result: {
    cards: {
      averageDuration: number;
      averagePrice: number;
      toPay: number;
      totalPrice: number;
    };
    dataTable: ApiStatisticsDataTableResponse;
  };
}

/* STATISTICS  - DEPARTURES TOMORROW */

export interface ApiStatisticsDeparturesTomorrowPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
}

export interface ApiStatisticsDeparturesTomorrowResponse extends ApiResponse {
  result: {
    cards: {
      averageDuration: number;
      averagePrice: number;
      toPay: number;
      totalPrice: number;
    };
    dataTable: ApiStatisticsDataTableResponse;
  };
}

/* STATISTICS  - ALL RESERVATIONS */

export interface ApiStatisticsAllReservationsPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
}

export interface ApiStatisticsAllReservationsResponse extends ApiResponse {
  result: {
    cards: {
      averageDuration: number;
      averagePrice: number;
      toPay: number;
      totalPrice: number;
    };
    dataTable: ApiStatisticsDataTableResponse;
  };
}

/* STATISTICS  - LOG DISCOUNTS */

export interface ApiStatisticsLogDiscountsPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
}

export interface ApiStatisticsLogDiscountsResponse extends ApiResponse {
  result: {
    cards: {
      totalDiscounts: number;
      usersWithDiscounts: number;
      totalCancellations: number;
      usersWithCancellations: number;
      totalCancellationsAmount: number;
      totalDiscountsAmount: number;
    };
    dataTable: ApiStatisticsDataTableResponse;
  };
}

/* STATISTICS  - LOG CANCELLATIONS */

export interface ApiStatisticsLogCancellationsPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
}

export interface ApiStatisticsLogCancellationsResponse extends ApiResponse {
  result: {
    cards: {
      totalDiscounts: number;
      usersWithDiscounts: number;
      totalCancellations: number;
      usersWithCancellations: number;
      totalCancellationsAmount: number;
      totalDiscountsAmount: number;
    };
    dataTable: ApiStatisticsDataTableResponse;
  };
}

/* STATISTICS - CASH FLOWS */

export interface ApiStatisticsCashFlowsUsersResponse {
  result: {
    users: Array<Admin>;
  };
}

export interface ApiStatisticsCashFlowsPayload {
  startDate: number;
  endDate: number;
  userIds: Array<number>;
  channels: string[];
  printerIds: number[];
  paymentMethods: number[];
}

export interface ApiStatisticsCashFlowsResponse extends ApiResponse {
  result: {
    methods: {
      total: number;
      items: Array<StatisticCashFlowsPaymentMethodItem>;
    };
    reservations: {
      total: number;
      items: Array<StatisticCashFlowsReservationTypeItem>;
    };
    onlineReceiptsAmount: number;
    cashFlows: Array<StatisticCashFlowsPayment>;
    astoriaCashFlows?: StatisticAstoriaCashFlows;
  };
}

/* STATISTICS  - COUNTERS HOTEL */

export interface ApiStatisticsCountersHotelPayload {
  dateMode: StatisticDateMode;
  startDate: number;
  endDate: number;
  groupData: boolean;
}

export interface ApiStatisticsCountersHotelResponse extends ApiResponse {
  result: {
    counters: Array<StatisticCounterHotel>;
    charts: {
      reservations: Array<number>;
      umbrellas: Array<number>;
      bulkPieces: Array<number>;
    };
  };
}

/* STATISTICS  - COUNTERS VOUCHER */

export interface ApiStatisticsCountersVoucherPayload {
  dateMode: StatisticDateMode;
  startDate: number;
  endDate: number;
  groupData: boolean;
}

export interface ApiStatisticsCountersVoucherResponse extends ApiResponse {
  result: {
    counters: Array<StatisticCounterVoucher>;
    charts: {
      reservations: Array<number>;
      days: Array<number>;
    };
    cards: {
      voucherTotal: number;
      totalValue: number;
    };
  };
}

/* STATISTICS  - COUNTERS HOTEL DETAIL */

export interface ApiStatisticsCountersHotelDetailPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
  hotel: string;
}

export interface ApiStatisticsCountersHotelDetailResponse extends ApiResponse {
  result: {
    dataTable: ApiStatisticsDataTableResponse;
    chart: Array<Array<number>>;
    hash: string;
    cards: {
      hotelTotal: number;
      totalDays: number;
      totalValue: number;
    };
    name: string;
  };
}

export interface ApiStatisticsCountersHotelDetailExportPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
  hotel: string;
}

export type ApiStatisticsCountersHotelExportDetailResponse = Blob;

/* STATISTICS  - COUNTERS VOUCHER DETAIL */

export interface ApiStatisticsCountersVoucherDetailPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
  hotel: string;
}

export interface ApiStatisticsCountersVoucherDetailResponse
  extends ApiResponse {
  result: {
    dataTable: ApiStatisticsDataTableResponse;
    chart: Array<number>;
    cards: {
      reservationsNumber: number;
      daysNumber: number;
      totalPeriod: number;
    };
    hash: string;
  };
}
export interface ApiStatisticsCountersVoucherDetailExportPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
  hotel: string;
}
export type ApiStatisticsVoucherDetailExportResponse = Blob;

/* STATISTICS  - STRIPE PAYMENTS */

export interface ApiStatisticsStripePaymentsPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
  type: 'online' | 'offline';
}

export interface ApiStatisticsStripePaymentsResponse extends ApiResponse {
  result: {
    name: string;
    cards: {
      totalPayments: number;
      totalRefunds: number;
      stripeFees: number;
      transferTotal: number;
      portalFees: number;
    };
    dataTable: ApiStatisticsDataTableResponse;
  };
}
export interface ApiStatisticsStripePaymentsExportPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
}
export type ApiStatisticsStripePaymentsExportResponse = Blob;

/* STATISTICS  - STRIPE TRANSFERS */

export interface ApiStatisticsStripeTransfersPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
}

export interface ApiStatisticsStripeTransfersResponse extends ApiResponse {
  result: {
    cards: {
      chargesTotal: number;
      refundsTotal: number;
      stripeFees: number;
      transferTotal: number;
      portalFees: number;
      compensationTotal: number;
    };
    dataTable: ApiStatisticsDataTableResponse;
  };
}

export interface ApiStatisticsStripeTransfersExportPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
}

export type ApiStatisticsStripeTransfersExportResponse = Blob;

/* STATISTICS  - EXTRA SERVICES */
export interface StatisticsExtraServicesElement {
  id: number;
  name: string;
  quantity: number;
  value: number;
}

export interface StatisticsExtraServicesResponse {
  result: {
    totalQuantity: number;
    totalValue: number;
    services: Array<StatisticsExtraServicesElement>;
  };
}

/* STATISTICS  - ATTENDANCE SUMMARY */

export interface ApiStatisticsAttendanceSummaryPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
}

export interface ApiStatisticsAttendanceSummaryResponse extends ApiResponse {
  result: {
    cards: {
      averageDuration: number;
      averagePrice: number;
      toPay: number;
      totalPrice: number;
    };
    dataTable: ApiStatisticsDataTableResponse;
  };
}

/* STATISTICS  - SEASONAL SUMMARY */

export interface ApiStatisticsSeasonalSummaryPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
}

export interface ApiStatisticsSeasonalSummaryResponse extends ApiResponse {
  result: {
    cards: {
      averageDuration: number;
      averagePrice: number;
      toPay: number;
      totalPrice: number;
    };
    dataTable: ApiStatisticsDataTableResponse;
  };
}

/* STATISTICS  - UNPAID RESERVATIONS SUMMARY */

export interface ApiStatisticsUnpaidReservationsSummaryPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
}

export interface ApiStatisticsUnpaidReservationsSummaryResponse
  extends ApiResponse {
  result: {
    cards: {
      averageDuration: number;
      averagePrice: number;
      toPay: number;
      totalPrice: number;
    };
    dataTable: ApiStatisticsDataTableResponse;
  };
}

/* STATISTICS  - ADDITIONS SUMMARY */

export interface ApiStatisticsAdditionsSummaryPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
}

export interface ApiStatisticsAdditionsSummaryResponse extends ApiResponse {
  result: {
    cards: {
      averageDuration: number;
      averagePrice: number;
      toPay: number;
      totalPrice: number;
    };
    dataTable: ApiStatisticsDataTableResponse;
  };
}

/* STATISTICS  - ONLINE RESERVATIONS */

export interface ApiStatisticsOnlineReservationsPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
}

export interface ApiStatisticsOnlineReservationsResponse extends ApiResponse {
  result: {
    cards: {
      averageDuration: number;
      averagePrice: number;
      toPay: number;
      totalPrice: number;
    };
    dataTable: ApiStatisticsDataTableResponse;
  };
}
/* STATISTICS  - COMMISIONS ONLINE */

export interface ApiStatisticsExtraServicePayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
}

export interface ApiStatisticsExtraServiceResponse extends ApiResponse {
  result: {
    cards: {
      averageDuration: number;
      averagePrice: number;
      toPay: number;
      totalPrice: number;
    };
    dataTable: ApiStatisticsDataTableResponse;
  };
}

/* STATISTICS - DURATION */

export interface ApiStatisticsDurationPayload {
  startDate: number;
  endDate: number;
}

export interface ApiStatisticsDurationResponse extends ApiResponse {
  result: Array<StatisticDurationReservation>;
}

/* STATISTICS - SPOT */

export interface ApiStatisticsSpotPayload
  extends ApiStatisticsDataTablePayload {
  dateMode: StatisticDateMode;
}

export interface ApiStatisticsSpotResponse extends ApiResponse {
  result: {
    dataTable: ApiStatisticsDataTableResponse;
  };
}
/* STATISTICS - SECTORS */

export type ApiStatisticsSectorsPayload = ApiStatisticsDataTablePayload;

export interface ApiStatisticsSectorsResponse extends ApiResponse {
  result: {
    dataTable: ApiStatisticsDataTableResponse;
  };
}

/* MAP EDITOR */

export interface ApiMapEditorElementsResponse extends ApiResponse {
  result: {
    spots: Array<MapEditorElementRaw>;
  };
}

export interface ApiMapEditorElementPayload {
  name?: string;
  posX: number;
  posY: number;
  online?: boolean;
  priceListId?: number;
  priceListOnlineId?: number;
  sector?: number;
  type?: MapElementTypeRaw;
  data?: MapEditorElementRawData;
  calendar?: Array<Date>;
  jsonData?: MapEditorElementRawJsonData;
}

export interface ApiMapEditorElementResponse extends ApiResponse {
  result: {
    spot: MapEditorElementRaw;
  };
}

export interface ApiMapEditorUploadResponse extends ApiResponse {
  result: {
    url: string;
  };
}
export interface ApiSetAllOnlineResponse extends ApiResponse {
  result: string;
}

export interface ApiMapEditorEditGridPayload {
  op: MapEditorGridAction;
  from: number;
}

export interface ApiMapEditorEditGridResponse extends ApiResponse {
  result: any;
}

/* PRICE LIST */

export type ApiPriceListCreatePayload = Pick<PriceList, 'name'> &
  Partial<Omit<PriceList, 'id' | 'createdAt' | 'updatedAt'>>;

export interface ApiPriceListCreateResponse extends ApiResponse {
  result: {
    priceList: PriceList;
  };
}

export type ApiPriceListUpdatePayload = ApiPriceListCreatePayload;

export interface ApiPriceListUpdateResponse extends ApiResponse {
  result: {
    priceList: PriceList;
  };
}

export interface ApiPriceListGetResponse extends ApiResponse {
  result: {
    priceList: PriceList;
  };
}

export interface ApiPriceListListResponse extends ApiResponse {
  result: {
    priceLists: Array<PriceList>;
  };
}

export interface ApiPriceListSearchResponse extends ApiResponse {
  result: {
    priceLists: Array<PriceList>;
  };
}

export interface ApiPriceListFindPayload {
  includeDrafts?: boolean;
}

export interface ApiPriceListFindResponse extends ApiResponse {
  result: {
    priceLists: Array<PriceList>;
  };
}

export interface ApiPriceListSwitchPayload {
  targetId: number;
}

export type ApiPriceListSwitchResponse = void;

export interface ApiPriceListGetPeriodCombinationsPayload
  extends ApiPaginationPayload,
    ApiOrderPayload {
  pricePeriodId: number;
  weekend: number;
}

export interface ApiPriceListGetPeriodCombinationsResponse extends ApiResponse {
  result: {
    priceCombinations: Array<PriceCombination>;
  };
}

export interface ApiPricePeriodUpdatePayload {
  priceListId: number;
  name: string;
  startDate: string;
  endDate: string;
  useWeekend: boolean;
  weekendRules: Array<string>;
}

export interface ApiPricePeriodUpdateResponse extends ApiResponse {
  result: {
    pricePeriod: PricePeriod;
  };
}

export interface ApiPricePeriodUploadPayload {
  weekend: boolean;
  delimiter: string;
  listing: File;
}

export interface ApiPricePeriodUploadResponse extends ApiResponse {
  result: {
    priceCombinations: Array<PriceCombination>;
  };
}

export interface ApiPricePeriodCopyPayload {
  weekend: boolean;
  copyId: number;
  copyWeekend: boolean;
}

export interface ApiPriceListQualityCheckPayload {
  spot: string; // TODO
  numBeds: number;
  numDeckChairs: number;
  numChairs: number;
  numMaxiBeds: number;
  useCabin: boolean;
}

export interface ApiPriceListQualityCheckResponse extends ApiResponse {
  result: PriceListQualityCheck;
}

export interface ApiPriceListEstimatePayload
  extends ApiPriceListQualityCheckPayload {
  sectors: Array<number>;
  startDate: number;
  endDate: number;
}

export interface ApiPriceListEstimateResponse extends ApiResponse {
  result: {
    sectorEstimations: Array<PriceListEstimate>;
  };
}

/* VOUCHER */
export interface ApiVoucherGetResponse extends ApiResponse {
  result: {
    voucher: Voucher;
  };
}

export interface ApiVoucherListResponse extends ApiResponse {
  result: {
    vouchers: Array<Voucher>;
  };
}

export type ApiVoucherCreatePayload = Pick<Voucher, 'name'> &
  Partial<Omit<Voucher, 'id' | 'licenseId' | 'createdAt' | 'updatedAt'>>;

export interface ApiVoucherCreateResponse extends ApiResponse {
  result: {
    voucher: Voucher;
  };
}

export type ApiVoucherUpdatePayload = ApiVoucherCreatePayload;

export interface ApiVoucherUpdateResponse extends ApiResponse {
  result: {
    voucher: Voucher;
  };
}
export interface ApiVoucherDeleteResponse extends ApiResponse {
  result: string;
}

/* WAREHOUSE */

export interface ApiWarehouseListPayload {
  date: number;
}

export interface ApiWarehouseListResponse extends ApiResponse {
  result: {
    warehouses: Array<Warehouse>;
  };
}

export interface ApiWarehouseCreatePayload {
  name: string;
  beds: number;
  maxiBeds: number;
  deckChairs: number;
  chairs: number;
  sectors: Array<number> | null | undefined;
}

export interface ApiWarehouseCreateResponse extends ApiResponse {
  result: {
    warehouse: Warehouse;
  };
}

export type ApiWarehouseUpdatePayload = ApiWarehouseCreatePayload;

export type ApiWarehouseUpdateResponse = ApiWarehouseCreateResponse;

export interface ApiWarehouseAlertPayload {
  spotType: SpotType;
  spotName: string;
  startDate: number;
  endDate?: number;
}

export interface ApiWarehouseAlertResponse extends ApiResponse {
  result: {
    alerts: Array<WarehouseAlert>;
  };
}

/**
 * BOOKING RECEIPTS
 */
export interface ApiListBookingReceiptsToPrintResponse extends ApiResponse {
  result: {
    reservations: Array<Reservation>;
  };
}

/**
 * DELETE RESERVATIONS GROUP
 */
export interface DeleteReservationsGroupPayload {
  startDate: number;
  endDate: number;
  voucher: boolean | null;
  invoice: boolean | null;
  paid: boolean | null;
  bill: boolean | null;
  status: ReservationStatus | null;
  seasonal: boolean | null;
  dateMode: StatisticDateMode | null;
}

export interface ApiDeleteReservationsResultResponse extends ApiResponse {
  result: {
    reservations: Array<Reservation>;
  };
}

export interface ApiTagCustomerResponse extends ApiResponse {
  result: {
    tags: Array<number>;
  };
}

export interface ApiTagCustomerAssociationInsertDeleteResponse
  extends ApiResponse {
  result: {
    code: string;
  };
}

/* PRICE CONFIG */

export interface ApiPriceConfigWizardResponse extends ApiResponse {
  result: {
    priceConfigStructure: PriceConfigWizard;
  };
}

export interface ApiPriceConfigGetResponse extends ApiResponse {
  result: {
    priceConfig: PriceConfig;
  };
}

export interface ApiPriceConfigListResponse extends ApiResponse {
  result: {
    priceConfigs: Array<PriceConfig>;
  };
}

export interface ApiPriceConfigCreatePayload extends PriceConfigModel {
  shouldValidate?: boolean;
  priceListType?: PriceListType;
  step?: number;
}
export interface ApiPriceConfigCreateResponse extends ApiResponse {
  result: {
    priceConfig: PriceConfig;
  };
}

export type ApiPriceConfigUpdatePayload = ApiPriceConfigCreatePayload;

export interface ApiPriceConfigUpdateResponse extends ApiResponse {
  result: {
    priceConfig: PriceConfig;
  };
}

export interface ApiPriceConfigEditResponse extends ApiResponse {
  result: {
    priceConfig: PriceConfig;
  };
}

export interface ApiPriceConfigCloneResponse extends ApiResponse {
  result: {
    priceConfig: PriceConfig;
  };
}

/* CHECKOUT POINTS */
export interface ApiCheckoutPointListResponse extends ApiResponse {
  result: {
    checkoutPoints: Array<CheckoutPoint>;
  };
}

/* REVENUE MANAGEMENT */

export interface ApiRevenueManagementPricesPayload {
  from: Timestamp;
  to: Timestamp;
}

export interface ApiRevenueManagementPricesResponse extends ApiResponse {
  result: {
    revenueManagerPrices: Array<RevenueManagementPrices>;
  };
}

export interface ApiRevenueManagementSlotDetailPayload {
  day: Timestamp;
  sectorId: number | null;
}

export interface ApiRevenueManagementSlotDetailResponse extends ApiResponse {
  result: {
    slotDetail: RevenueManagementSlotDetail;
  };
}

export interface ApiRevenueManagementPeriodsResponse extends ApiResponse {
  result: {
    periods: Array<RevenueManagementPeriod>;
  };
}

export interface ApiRevenueManagementRuleDetailsResponse extends ApiResponse {
  result: {
    ruleDetails: Array<RevenueManagementRuleDetail>;
  };
}

/* BASE RULE */

export interface ApiRevenueManagementBaseRuleListResponse extends ApiResponse {
  result: {
    baseRules: Array<RevenueManagementBaseRule>;
  };
}

export interface ApiRevenueManagementBaseRuleCreatePayload {
  ruleType: RevenueManagementRuleMode;
  applicationThreshold: number;
  revenuePercentageValue: number;
  periodId: number;
}

export interface ApiRevenueManagementBaseRuleCreateResponse
  extends ApiResponse {
  result: {
    baseRule: RevenueManagementBaseRule;
  };
}

export type ApiRevenueManagementBaseRuleUpdatePayload = Omit<
  ApiRevenueManagementBaseRuleCreatePayload,
  'periodId'
>;

export type ApiRevenueManagementBaseRuleUpdateResponse =
  ApiRevenueManagementBaseRuleCreateResponse;

export type ApiRevenueManagementBaseRuleDeleteResponse = void;

/* OVERRIDE RULE */

export interface ApiRevenueManagementOverrideRuleListResponse
  extends ApiResponse {
  result: {
    overrideRules: Array<RevenueManagementOverrideRule>;
  };
}

export interface ApiRevenueManagementOverrideRuleCreatePayload {
  day: Timestamp;
  applicationThreshold: number;
  revenuePercentageValue: number;
}

export interface ApiRevenueManagementOverrideRuleCreateResponse
  extends ApiResponse {
  result: {
    overrideRule: RevenueManagementOverrideRule;
  };
}

export type ApiRevenueManagementOverrideRuleUpdatePayload = Omit<
  ApiRevenueManagementOverrideRuleCreatePayload,
  'day'
>;

export type ApiRevenueManagementOverrideRuleUpdateResponse =
  ApiRevenueManagementOverrideRuleCreateResponse;

export type ApiRevenueManagementOverrideRuleDeleteResponse = void;

export interface ApiRevenueManagementHistoricalDetailsResponse
  extends ApiResponse {
  result: {
    historicalDetails: Array<RevenueManagementHistoricalDetail>;
  };
}

/* PRICE ALTERATION */

export interface ApiRevenueManagementPriceAlterationListPayload {
  from: Timestamp;
  to: Timestamp;
}

export interface ApiRevenueManagementPriceAlterationListResponse
  extends ApiResponse {
  result: {
    priceAlterations: Array<RevenueManagementPriceAlteration>;
  };
}

export interface ApiRevenueManagementPriceAlterationCreatePayload {
  from: Timestamp;
  to: Timestamp;
  percentageDiscount: number;
  sectorIds: Array<number>;
}

export type ApiRevenueManagementPriceAlterationCreateResponse = void;

export interface ApiRevenueManagementPriceAlterationDeletePayload {
  from: Timestamp;
  to: Timestamp;
  sectorIds: Array<number>;
}

export type ApiRevenueManagementPriceAlterationDeleteResponse = void;

/* PRICE HINT */

export interface ApiRevenueManagementPriceHintListPayload {
  from: Timestamp;
  to: Timestamp;
}

export interface ApiRevenueManagementPriceHintListResponse extends ApiResponse {
  result: {
    priceHints: Array<RevenueManagementPriceHint>;
  };
}

export interface ApiRevenueManagementPriceHintHandlePayload {
  from: Timestamp;
  to: Timestamp;
  sectorIds: Array<number>;
  ruleId: number;
  ruleType: RevenueManagementRuleType;
  status: RevenueManagementPriceHintStatus;
  revenuePercentageValue: number;
}

export type ApiRevenueManagementPriceHintHandleResponse = void;

export interface ApiUserPermissionsResponse extends ApiResponse {
  result: {
    role: RolePermission;
  };
}

export interface ApiRolesResponse extends ApiResponse {
  result: {
    roles: Array<RolePermission>;
  };
}

export interface ApiRoleResponse extends ApiResponse {
  result: {
    role: RolePermission;
  };
}

export interface ApiFeaturesContract extends ApiResponse {
  result: {
    features: Array<FeaturePermission>;
  };
}

/* POS TERMINAL */

export interface ApiPosTerminalListResponse extends ApiResponse {
  result: {
    posTerminal: Array<PosTerminal>;
  };
}

export interface ApiPosTerminalCreatePayload {
  name: string;
  model: PosTerminalModel;
  posIdentifier: string;
  sectors: Array<number>;
}

export interface ApiPosTerminalCreateResponse extends ApiResponse {
  result: {
    posTerminal: PosTerminal;
  };
}

export type ApiPosTerminalUpdatePayload = ApiPosTerminalCreatePayload;

export type ApiPosTerminalUpdateResponse = ApiPosTerminalCreateResponse;

export type ApiPosTerminalToggleResponse = ApiPosTerminalCreateResponse;

/* STRIPE */

export interface ApiStripeCreatePaymentIntentPayload {
  reservation: number;
  description: string;
  amount: number;
  email?: string;
  terminal: string;
}

export interface ApiStripeCreatePaymentIntentResponse extends ApiResponse {
  result: {
    stripeIntent: {
      id: string;
    };
  };
}

export interface ApiStripeGetPaymentIntentResponse extends ApiResponse {
  result: StripePaymentIntent;
}

/* CASH DESK */
export interface ApiCashDeskCreateResponse extends ApiResponse {
  result: {
    reservation: Reservation;
  };
}

/* WEBTIC */
export interface ApiWebticOnsiteEmitTicketPayload {
  reservation: number;
  terminal?: number;
  paymentMethod?: number;
  tickets?: Array<WebticTicket>;
}
export interface ApiWebticOnsiteEmitTicketResponse extends ApiResponse {
  result: {
    tickets: Array<WebticTicket>;
  };
}
export interface ApiWebticMarkTicketPayload {
  barcode: string;
}
export interface ApiWebticMarkTicketResponse extends ApiResponse {
  result: {
    webticTicket?: WebticTicket;
  };
}

/* WEBTIC TICKETS */

export interface ApiReservationSiaeTicketsResponse extends ApiResponse {
  result: {
    webticTickets: Array<WebticTicket>;
  };
}

/* WEBTIC TERMINAL */

export interface ApiWebticTerminalListResponse extends ApiResponse {
  result: {
    webticTerminal: Array<WebticTerminal>;
  };
}

export interface ApiWebticTerminalCreatePayload {
  name: string;
  terminalId: number;
  sectors: Array<number>;
}

export interface ApiWebticTerminalCreateResponse extends ApiResponse {
  result: {
    webticTerminal: WebticTerminal;
  };
}

export type ApiWebticTerminalUpdatePayload = ApiWebticTerminalCreatePayload;

export type ApiWebticTerminalUpdateResponse = ApiWebticTerminalCreateResponse;

export type ApiWebticTerminalToggleResponse = ApiWebticTerminalCreateResponse;

/* SECTORS */
export interface ApiSectorListResponse extends ApiResponse {
  result: {
    sectors: Array<Sector>;
  };
}

export interface ApiSectorCreateResponse extends ApiResponse {
  result: {
    sector: SectorHeader;
  };
}

export interface ApiSectorUpdateResponse extends ApiResponse {
  result: {
    sector: SectorHeader;
  };
}

export interface ApiSectorDeleteResponse extends ApiResponse {
  result: {
    deleted: boolean;
  };
}

export interface ApiSectorUploadImageResponse extends ApiResponse {
  result: {
    url: string;
  };
}

export interface ApiGetPlannerSectorsResponse extends ApiResponse {
  result: {
    sectorIds: Array<number>;
  };
}

export interface ApiSectorListPayload {
  loadSectorDetails?: boolean;
  type?: SectorDetailType;
  sectorDetailKeyName?: SectorDetailKeyName;
  sectorDetailKeyType?: SectorDetailKeyType;
  enabled?: boolean;
  checkSectorRelationships?: boolean;
}

export interface ApiSectorCreatePayload {
  name: string;
  description?: string;
  enabled: boolean;
  image?: string;
}

export interface ApiSectorUpdatePayload {
  name: string;
  description?: string;
  enabled: boolean;
  image?: string;
}

export interface ApiSectorDetailUpdatePayload {
  id: number;
  keyValue: number | null;
}

export interface ApiSectorDetailsUpdatePayload {
  details: Array<ApiSectorDetailUpdatePayload>;
}

export interface ApiWhiteLabelUserResponse {
  redirectUrl: string;
}

export interface ApiGetStripeTransferInvoice {
  result: { invoice: StripeTransferInvoice };
}
