
import { defineComponent } from 'vue';
import ContactsNavigation from '@/components/contacts/ContactsNavigation.vue';

export default defineComponent({
  name: 'ContactsView',
  components: {
    ContactsNavigation,
  },
  methods: {},
  computed: {
    loading(): boolean {
      return this.$store.getters['app/loading'];
    },
    windowWidth(): number {
      return this.$store.getters['app/windowWidth'];
    },
    windowHeight(): number {
      return this.$store.getters['app/windowHeight'];
    },
    pageWidth(): string {
      const width =
        this.windowWidth >= 992 ? this.windowWidth - 80 : this.windowWidth;
      return `${width}px`;
    },
    panelHeight(): string {
      return `${this.windowHeight - 112}px`;
    },
  },
});
