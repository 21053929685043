
import { defineComponent, PropType } from 'vue';
import debounce from 'lodash/debounce';
import { clone } from 'lodash';
import {
  PriceConfigWizardSchemaRow,
  PriceConfigWizardSchemaInputData,
  PriceConfigWizardSchemaField,
  PriceConfigWizardSchemaSwitchData,
  PriceConfigWizardSchemaRowStatus,
  PriceConfigWizardSchemaActionData,
  PriceConfigWizardSchemaRowActionValue,
} from '@/models/priceConfig';

interface SelectedField {
  token: string;
  value: number | null;
}

export default defineComponent({
  name: 'FieldPriceConfigWizardRow',
  emits: ['fieldInput', 'switch', 'action'],
  props: {
    data: {
      required: true,
      type: Object as PropType<PriceConfigWizardSchemaRow>,
    },
    errors: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    showSwitch: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      rowStatus: clone(PriceConfigWizardSchemaRowStatus),
      selectedField: null as unknown as SelectedField,
      updateFieldDebounced: debounce(
        this.updateField as (field: PriceConfigWizardSchemaField) => void,
        250,
      ),
    };
  },
  methods: {
    getValue(field: PriceConfigWizardSchemaField): number | null {
      if (this.data.status === PriceConfigWizardSchemaRowStatus.DISABLED) {
        // if disabled the value is -1
        return null;
      }
      if (this.selectedField && this.selectedField.token === field.token) {
        return this.selectedField.value;
      }
      return field.value;
    },
    getPlaceholder(
      field: PriceConfigWizardSchemaField,
      withFormat = false,
    ): string | null {
      if (this.data.status === PriceConfigWizardSchemaRowStatus.DISABLED) {
        return 'N/A';
      }
      return withFormat
        ? this.formatPlaceholder(field.placeholder)
        : (field.placeholder?.toString() as string);
    },
    onFieldInput(event: {
      originalEvent: KeyboardEvent;
      value: number | null;
    }): void {
      this.selectedField.value = event.value;
    },
    updateField(field: PriceConfigWizardSchemaField): void {
      if (this.data.fields[field.token].value !== this.selectedField.value) {
        this.$emit('fieldInput', {
          value: this.selectedField.value,
          field,
          row: this.data,
        } as PriceConfigWizardSchemaInputData);
      }
      this.selectedField = null as unknown as SelectedField;
    },
    onSwitch(): void {
      const switchData: PriceConfigWizardSchemaSwitchData = {
        row: this.data,
      };
      this.$emit('switch', switchData);
    },
    onAction(action: PriceConfigWizardSchemaRowActionValue): void {
      const actionData: PriceConfigWizardSchemaActionData = {
        row: this.data,
        action,
      };
      this.$emit('action', actionData);
    },
    formatPlaceholder(
      placeholderValue: number | undefined | null,
    ): string | null {
      if (placeholderValue === undefined || placeholderValue === null) {
        return null;
      }

      let placeholderStringValue = placeholderValue.toString();
      if (placeholderStringValue.includes('.')) {
        const decimals = placeholderStringValue.substring(
          placeholderStringValue.indexOf('.') + 1,
        );
        if (decimals.length !== 2) {
          placeholderStringValue += '0';
        }
      } else {
        placeholderStringValue += '.00';
      }
      return placeholderStringValue;
    },
  },
});
