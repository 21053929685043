
import { defineComponent, WatchStopHandle } from 'vue';
import { mapGetters, mapState } from 'vuex';
import warehouseService from '@/services/warehouseService';
import { ApiWarehouseAlertPayload } from '@/models/api';
import {
  ReservationAlert,
  ReservationAlertCode,
  ReservationAlertSeverity,
  RESERVATION_ALERTS_DATA,
} from '@/models/reservation';
import { WarehouseAlert } from '@/models/warehouse';

export default defineComponent({
  name: 'ReservationAlerts',
  data() {
    return {
      alerts: [] as Array<ReservationAlert>,
      overbookingWatcher: null as unknown as WatchStopHandle,
      warehouseWatcher: null as unknown as WatchStopHandle,
      trashedWatcher: null as unknown as WatchStopHandle,
    };
  },
  beforeMount() {
    // warehouse watcher, triggered when some fields have changed
    this.warehouseWatcher = this.$watch(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (vm: any) => [
        vm.startDate,
        vm.endDate,
        vm.beds,
        vm.maxiBeds,
        vm.deckChairs,
        vm.chairs,
      ],
      async () => {
        if (
          !this.license.warehouseMode ||
          !this.id ||
          !this.spot.type ||
          !this.spot.name
        ) {
          return;
        }
        try {
          const warehouseAlerts: Array<WarehouseAlert> =
            await warehouseService.alert({
              spotType: this.spot.type,
              spotName: this.spot.name,
              startDate: this.startDate.toSeconds(),
              endDate: this.endDate.toSeconds(),
            } as ApiWarehouseAlertPayload);
          // remove all warehouse alert
          this.alerts = this.alerts.filter(
            (alert: ReservationAlert) =>
              ![
                ReservationAlertCode.LOW_BEDS,
                ReservationAlertCode.LOW_MAXI_BEDS,
                ReservationAlertCode.LOW_DECK_CHAIRS,
                ReservationAlertCode.LOW_CHAIRS,
                ReservationAlertCode.EMPTY_BEDS,
                ReservationAlertCode.EMPTY_MAXI_BEDS,
                ReservationAlertCode.EMPTY_DECK_CHAIRS,
                ReservationAlertCode.EMPTY_CHAIRS,
              ].includes(alert.code),
          );
          warehouseAlerts.map((warehouseAlert: WarehouseAlert) => {
            const alertData = RESERVATION_ALERTS_DATA.find(
              (alert: ReservationAlert) => alert.code === warehouseAlert.code,
            ) as ReservationAlert;
            const description = warehouseAlert.dates
              .map((date: number) => this.$spiagge.date.fromMillis(date))
              .join(', ');
            this.alerts.push({
              ...alertData,
              ...{
                description,
              },
            });
          });
        } catch (e) {
          //
        }
      },
      {
        immediate: true,
      },
    );
    // overbooking watcher
    this.overbookingWatcher = this.$watch(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (vm: any) => vm.overbooking,
      (overbooking: boolean) => {
        if (overbooking && !this.isAreaBeds) {
          this.alerts.push(
            RESERVATION_ALERTS_DATA.find(
              (alert: ReservationAlert) =>
                alert.code === ReservationAlertCode.OVERBOOKING,
            ) as ReservationAlert,
          );
        } else {
          this.alerts = this.alerts.filter(
            (alert: ReservationAlert) =>
              alert.code !== ReservationAlertCode.OVERBOOKING,
          );
        }
      },
      {
        immediate: true,
      },
    );
    this.trashedWatcher = this.$watch(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (vm: any) => vm.deleted,
      (deleted: boolean) => {
        if (deleted) {
          const alert = RESERVATION_ALERTS_DATA.find(
            (alert: ReservationAlert) =>
              alert.code === ReservationAlertCode.TRASHED,
          ) as ReservationAlert;
          // eslint-disable-next-line
          if (alert.cta) {
            alert.cta.action = async () => {
              try {
                await this.$store.dispatch('reservation/restore');
                this.$spiagge.toast.success(
                  this.$t('reservationAlerts.toast.restoreSuccess'),
                );
              } catch (e) {
                this.$spiagge.toast.error(
                  this.$t('reservationAlerts.toast.restoreError'),
                );
              }
            };
          }
          this.alerts.push(alert);
        } else {
          this.alerts = this.alerts.filter(
            (alert: ReservationAlert) =>
              alert.code !== ReservationAlertCode.TRASHED,
          );
        }
      },
      {
        immediate: true,
      },
    );
  },
  computed: {
    ...mapState('reservation', [
      'id',
      'spot',
      'startDate',
      'endDate',
      'beds',
      'maxiBeds',
      'deckChairs',
      'chairs',
      'deleted',
      'overbooking',
    ]),
    ...mapGetters('reservation', ['isAreaBeds']),
    ...mapState('session', ['license']),
    hasAlerts(): boolean {
      return this.alerts.length > 0;
    },
    warningAlerts(): Array<ReservationAlert> {
      return this.alerts
        .filter(
          (alert: ReservationAlert) =>
            alert.severity === ReservationAlertSeverity.WARNING,
        )
        .sort((a, b) => (a.weight > b.weight ? 1 : -1));
    },
    errorAlerts(): Array<ReservationAlert> {
      return this.alerts
        .filter(
          (alert: ReservationAlert) =>
            alert.severity === ReservationAlertSeverity.ERROR,
        )
        .sort((a, b) => (a.weight > b.weight ? 1 : -1));
    },
  },
  beforeUnmount(): void {
    this.warehouseWatcher();
    this.trashedWatcher();
    this.overbookingWatcher();
  },
});
