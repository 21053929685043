import store from '@/store';

export default {
  trackUser(): void {
    if (store.state.session.user && store.state.session.license) {
      window.dataLayer = window.dataLayer || [];

      window.dataLayer.push({
        userID: store.state.session.user.id,
        licenseID: store.state.session.license.id,
        userRole: store.state.session.roleUser ?? '',
        spiaggeTeam: store.state.session.user.email.endsWith('@spiagge.it'),
      });
    }
  },
};
