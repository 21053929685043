import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "field-price-config-holidays" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Calendar = _resolveComponent("Calendar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Calendar, {
      modelValue: _ctx.model,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
      dateFormat: "dd/mm",
      minDate: _ctx.minDate.toJSDate(),
      maxDate: _ctx.maxDate.toJSDate(),
      selectionMode: "multiple",
      disabled: _ctx.disabled
    }, null, 8, ["modelValue", "minDate", "maxDate", "disabled"])
  ]))
}