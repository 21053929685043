
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { NavigationItem } from '@/models/navigation';
import { LicenseType } from '@/models/license';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FeaturePermissionType,
  FEATURE_PERMISSION_CONFIG,
  FEATURE_PERMISSION_ACTION_CONFIG,
} from '@/models/permissions';
import whiteLabelService from '@/services/whiteLabelService';

export default defineComponent({
  name: 'Navigation',
  data() {
    return {
      active: 'beach',
    };
  },
  computed: {
    ...mapState('app', ['windowWidth']),
    ...mapState('session', [
      'license',
      'permissions',
      'appPlatform',
      'appPlatformBuild',
    ]),
    FeaturePermissionType() {
      return FeaturePermissionType;
    },
    labelUpgradedNeeded(): string {
      return this.$t('permissions.navigation.upgradeNeeded');
    },
    labelNotAllowed(): string {
      return this.$t('permissions.navigation.notAllowed');
    },
    isDemo(): boolean {
      return this.license.licenseType === LicenseType.DEMO;
    },
    open(): boolean {
      return this.$store.getters['app/showNavigation'];
    },
    items(): Array<NavigationItem> {
      return [
        {
          id: 'beach',
          label: this.$t('navigation.beach'),
          icon: 'spi-umbrella',
          to: '/map',
          permission: permissionsUtil.checkFeaturePermission(
            FEATURE_PERMISSION_CONFIG.map,
          ),
          cta: null,
        },
        {
          id: 'booking',
          label: this.$t('navigation.booking'),
          icon: 'spi-booking',
          to: '/booking',
          permission: permissionsUtil.checkFeaturePermission(
            FEATURE_PERMISSION_CONFIG.booking,
          ),
          cta: null,
          tag:
            this.license && this.license.bookingSponsorEnabled
              ? {
                  label: this.$t('navigation.sponsored'),
                  severity: 'danger',
                  tooltip: `${this.$t('navigation.sponsoredTooltip')} ${
                    this.license.portalPaymentFeesSponsoredPercentage
                  }%`,
                }
              : undefined,
        },
        {
          id: 'white-label',
          label: this.$t('navigation.whiteLabel.title'),
          icon: 'spi-world',
          permission: this.isWhiteLabelEnabled(),
          cta: async () => {
            try {
              const apiWhiteLabelUserResponse =
                await whiteLabelService.linkUserToWhiteLabel();
              window.open(apiWhiteLabelUserResponse.redirectUrl);
            } catch (e) {
              this.$spiagge.toast.error(this.$t('navigation.whiteLabel.error'));
            }
          },
        },
        {
          id: 'planner',
          label: this.$t('navigation.planner'),
          icon: 'spi-planner',
          to: '/planner',
          permission: permissionsUtil.checkFeaturePermission(
            FEATURE_PERMISSION_CONFIG.planning,
          ),
          cta: null,
        },
        {
          id: 'price-list',
          label: this.$t('navigation.priceList'),
          icon: 'spi-price-lists',
          to: '/price-lists',
          permission: permissionsUtil.checkFeaturePermission(
            FEATURE_PERMISSION_CONFIG.price_lists,
          ),
          cta: null,
        },
        {
          id: 'revenue-management',
          label: this.$t('navigation.revenue'),
          icon: 'spi-revenue',
          permission: this.isRevenueManagerEnabled(),
          to: '/revenue-management',
        },
        {
          id: 'cash-desk',
          label: this.$t('navigation.cashDesk'),
          icon: 'spi-cash-desk',
          to: '/cash-desk',
          permission: permissionsUtil.checkFeaturePermission(
            FEATURE_PERMISSION_CONFIG.cash_point,
          ),
          cta: null,
        },
        {
          id: 'statistics',
          label: this.$t('navigation.statistics'),
          icon: 'spi-statistics',
          to: '/statistics',
          permission: permissionsUtil.checkFeaturePermission(
            FEATURE_PERMISSION_CONFIG.stats,
          ),
          cta: null,
        },

        {
          id: 'customers',
          label: this.$t('navigation.customers'),
          icon: 'spi-user',
          to: '/customers/contacts',
          permission: permissionsUtil.checkFeaturePermission(
            FEATURE_PERMISSION_CONFIG.customers,
          ),
          cta: null,
        },
        {
          id: 'qr-scan',
          label: this.$t('navigation.qrScan'),
          icon: 'spi-qr-code',
          cta: () => {
            const scanParams: {
              [key: string]: string;
            } = {
              yb: this.license.license,
              cb: window.location.href,
            };

            const scanUrl = 'https://legacy.spiagge.it/app/scan/?'; // FIXME: move to env variables

            if (this.appPlatform && this.appPlatform === 'ios') {
              if (this.appPlatformBuild && this.appPlatformBuild >= 12) {
                window.webkit.messageHandlers.openQRReader.postMessage('');
              } else {
                window.webkit.messageHandlers.openOnBrowser.postMessage(
                  scanUrl + new URLSearchParams(scanParams).toString(),
                );
              }
            } else if (this.appPlatform && this.appPlatform === 'android') {
              if (this.appPlatformBuild && this.appPlatformBuild >= 13) {
                window.Android.openQRReader();
              } else {
                window.location.href =
                  scanUrl + new URLSearchParams(scanParams).toString();
              }
            } else {
              if (this.appPlatform) {
                scanParams.anm22_world_app_os = this.appPlatform;
              }
              window.location.href =
                scanUrl + new URLSearchParams(scanParams).toString();
            }
          },
          permission: permissionsUtil.checkFeaturePermission(
            FEATURE_PERMISSION_CONFIG.qr_scan,
          ),
        },
        {
          id: 'settings',
          label: this.$t('navigation.settings'),
          icon: 'spi-settings',
          to: '/settings/general',
          permission: permissionsUtil.checkFeaturePermission(
            FEATURE_PERMISSION_CONFIG.settings,
          ),
          cta: null,
        },
        {
          id: 'change',
          label: this.$t('navigation.change'),
          icon: 'spi-switch',
          to: '/license-select',
          permission: FeaturePermissionType.INCLUDED,
          cta: null,
        },
        {
          id: 'help',
          label: this.$t('navigation.help'),
          icon: 'spi-help',
          permission: FeaturePermissionType.INCLUDED,
          to: '/help',
          cta: null,
        },
      ] as Array<NavigationItem>;
    },
  },
  methods: {
    isRevenueManagerEnabled(): FeaturePermissionType {
      if (
        this.license &&
        this.license.featureFlags &&
        this.license.featureFlags.revenueManagementEnabled
      ) {
        return permissionsUtil.checkFeaturePermission(
          FEATURE_PERMISSION_CONFIG.revenue_manager,
        );
      }

      return FeaturePermissionType.NOT_INCLUDED;
    },
    isWhiteLabelEnabled(): FeaturePermissionType {
      if (
        this.license &&
        this.license.featureFlags &&
        this.license.featureFlags.whiteLabelEnabled
      ) {
        const whiteLabelEnabled = permissionsUtil.checkFeaturePermission(
          FEATURE_PERMISSION_CONFIG.white_label,
        );

        const whiteLabelButtonClickPermissions =
          permissionsUtil.isActionPermissionAllowed(
            FEATURE_PERMISSION_CONFIG.white_label,
            FEATURE_PERMISSION_ACTION_CONFIG.white_label
              .WHITE_LABEL_EDITOR_ROLE,
          ) ||
          permissionsUtil.isActionPermissionAllowed(
            FEATURE_PERMISSION_CONFIG.white_label,
            FEATURE_PERMISSION_ACTION_CONFIG.white_label
              .WHITE_LABEL_DESIGNER_ROLE,
          );

        let featurePermissionType = FeaturePermissionType.INCLUDED;
        if (!whiteLabelEnabled) {
          featurePermissionType = FeaturePermissionType.NOT_INCLUDED;
        }
        if (whiteLabelEnabled && !whiteLabelButtonClickPermissions) {
          featurePermissionType = FeaturePermissionType.NOT_ALLOWED;
        }
        return featurePermissionType;
      }
      return FeaturePermissionType.NOT_INCLUDED;
    },
    isCtaEnabled(item: NavigationItem): boolean {
      return (
        item.permission !== FeaturePermissionType.INCLUDED &&
        typeof item.cta === 'function'
      );
    },
    getTextTooltip(item: NavigationItem): string | null {
      if (item.permission === FeaturePermissionType.NOT_INCLUDED) {
        return this.labelUpgradedNeeded;
      }
      if (item.permission === FeaturePermissionType.NOT_ALLOWED) {
        return this.labelNotAllowed;
      }
      return null;
    },
    onClick(item: NavigationItem): void {
      if (this.windowWidth <= 991) {
        this.$store.commit('app/toggleNavigation');
      }
      this.active = item.id;
    },
  },
});
