
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import { defineComponent, PropType } from 'vue';
import { DropdownChangeEvent } from 'primevue/dropdown';
import { SectorCombinationPieces } from '@/models/priceConfig';
import { Sector, SectorHeader } from '@/models/sector';
import { Field } from '@/models/field';
import { DropdownOption } from '@/models';

export default defineComponent({
  name: 'FieldPriceConfigSectorCombinationPieces',
  emits: ['update:modelValue'],
  props: {
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Array as PropType<Array<SectorCombinationPieces>>,
      required: true,
      default: () => {
        //
      },
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    fields: {
      type: Array as PropType<Array<Field>>,
      required: true,
    },
  },
  data() {
    return {
      sectorHeaders:
        new Map<number, SectorHeader>() as Map<number, SectorHeader>,
      sectorOptions:
        new Map<number, Array<DropdownOption>>() as Map<number, Array<DropdownOption>>,
    };
  },
  beforeMount(): void {
    // Build headers + default through fields
    (this.sectors as Array<Sector>)
      .forEach((sector: Sector) => {
        this.sectorHeaders.set(
          sector.header.id,
          sector.header,
        );
      });
    // Check if model has already been loaded
    const isAlreadyLoaded = this.modelValue.length > 0;
    this.fields.forEach((field: Field) => {
      const item = {} as SectorCombinationPieces;
      let options = [] as Array<DropdownOption>;
      (field.o as Array<Field>).forEach(
        (f: Field) => {
          if (f.n === 'id') {
            item.id = f.dv as number;
          } else if (f.n === 'combinationPieces') {
            options = this.getDropdownIntegerOptions(f);
            item.combinationPieces = f.dv as number;
          }
        },
      );
      // this.sectorCombinationPieces.set(item.id, item);
      this.sectorOptions.set(item.id, options);
      if (!isAlreadyLoaded) {
        this.sectorCombinationPieces.push(item);
      }
    });
  },
  methods: {
    getSectorName(sectorId: number): string {
      return this.sectorHeaders.get(sectorId)?.name
        ?? '';
    },
    getDropdownIntegerOptions(field: Field): Array<DropdownOption> {
      return (field.o as Array<number>).map((option: number) => ({
        label: this.$t(`dynamicForm.priceListWizard.${field.n}.options.${option}`),
        value: option,
      }));
    },
    onChangePieces(event: DropdownChangeEvent, sectorId: number): void {
      const res = [] as Array<SectorCombinationPieces>;
      this.sectorCombinationPieces.forEach(
        (combo: SectorCombinationPieces) => {
          res.push({
            id: combo.id,
            combinationPieces: combo.id === sectorId
                ? event.value as number : combo.combinationPieces,
          });
        },
      );
      this.$emit('update:modelValue', res);
    },
  },
  computed: {
    ...mapState('session', ['sectors']),
    sectorCombinationPieces(): Array<SectorCombinationPieces> {
      return this.modelValue ? cloneDeep(this.modelValue) : [];
    },
  },
});
