import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46aae80d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "locale-selector"
}
const _hoisted_2 = { class: "font-bold mb-3" }
const _hoisted_3 = { class: "locale-selector-items" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = {
  key: 0,
  class: "pi pi-check"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.locales.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('topBarLocaleSelector.title')), 1),
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (locale, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["locale-item", { active: _ctx.currentLocale.id === locale.id }]),
              key: index,
              onClick: ($event: any) => (_ctx.onLocaleChange(locale))
            }, [
              _createElementVNode("img", {
                src: locale.image,
                alt: locale.label
              }, null, 8, _hoisted_5),
              _createElementVNode("span", null, _toDisplayString(locale.label), 1),
              (_ctx.currentLocale.id === locale.id)
                ? (_openBlock(), _createElementBlock("i", _hoisted_6))
                : _createCommentVNode("", true)
            ], 10, _hoisted_4))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}