
import { defineComponent, PropType } from 'vue';
import { Card } from '@/models/card';
import cardService from '@/services/cardService';
import toastUtil from '@/utils/toastUtil';
import { ToastSeverity } from '@/models/toast';
import { ApiCardListResponse } from '@/models/api';
import { CardType } from '@/models/cardType';

export default defineComponent({
  emits: ['cardSender', 'closeDialogSender'],
  props: {
    cardLoaded: {
      type: Object as PropType<Card>,
      required: true,
    },
    editProp: {
      type: Boolean,
      required: true,
    },
    disableProp: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      card: this.cardLoaded as Card,
      isCardEdit: this.editProp,
      isDisabled: this.disableProp,
      cardsDialog: false,
      cardsDetailsDialog: false,
      mockedOptions: [
        {
          label: '',
          value: 0,
        },
      ],
    };
  },
  methods: {
    async submit() {
      const payload = {
        cardType: this.card.cardType,
        number: this.card.number,
        firstName: this.card.firstName,
        lastName: this.card.lastName,
        email: this.card.email,
        phoneNumber: this.card.phoneNumber,
      } as Card;
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const card: Card = await cardService.create(payload);
        // this.cards.push(card);
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
        this.$emit('cardSender', card);
        this.$emit('closeDialogSender');
        this.card = {
          idWorldCard: 0,
          name: '',
          cardType: 0,
          number: '',
          createdAt: 0,
          expirationDate: 0,
          firstName: '',
          lastName: '',
          email: '',
          phoneAreaCode: '',
          phoneNumber: '',
          balance: 0,
          deleted: 0,
          disabled: 0,
          billingData: 0,
          cardTypeName: '',
          cardImage: '',
          exchangeRate: 0,
        } as Card;
        this.cardsDialog = false;
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
    async edit() {
      const payload = {
        cardType: this.card.cardType,
        number: this.card.number,
        firstName: this.card.firstName,
        lastName: this.card.lastName,
        email: this.card.email,
        phoneNumber: this.card.phoneNumber,
      } as Card;
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const card: Card = await cardService.update(
          this.card.idWorldCard,
          payload,
        );
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
        this.card = {
          idWorldCard: 0,
          name: '',
          cardType: 0,
          number: '',
          createdAt: 0,
          expirationDate: 0,
          firstName: '',
          lastName: '',
          email: '',
          phoneAreaCode: '',
          phoneNumber: '',
          balance: 0,
          deleted: 0,
          disabled: 0,
          billingData: 0,
          cardTypeName: '',
          cardImage: '',
          exchangeRate: 0,
        } as Card;
        this.cardsDetailsDialog = false;
        this.cardsDialog = false;
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
    undo() {
      this.cardsDialog = false;
      this.$emit('closeDialogSender');
    },
    closeDialogfunction() {
      this.$emit('closeDialogSender');
    },
  },
  async beforeMount() {
    try {
      const res: ApiCardListResponse = await cardService.list();
      this.mockedOptions = res.result.cardTypes.map((cardType: CardType) => ({
        label: cardType.name,
        value: cardType.idWorldCardType,
      }));
    } catch (error) {
      // TODO
    }
  },
});
