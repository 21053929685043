/* eslint-disable camelcase */

export enum StripeAccountType {
  STANDARD = 'standard',
  CUSTOM = 'custom',
}

export enum StripeTransferSchedule {
  WEEKLY = 1,
  BIWEEKLY,
  MONTHLY,
  OTHER_DAY,
}

export enum StripePaymentIntentStatus {
  SUCCEEDED = 'succeeded',
}

export interface StripePaymentIntent {
  id: string;
  license_id: string;
  reservation_id: number;
  completed: number;
  charge_status: null;
  charge_id: string | null;
}

export interface StripePaymentIntentCreate {
  id: string;
  // other stuff
  /*
"error":null,
   "errorEx":null,
   "result":{
      "stripeIntent":{
         "id":"pi_3NC13aJYhVpsZBXW1RZa6lST",
         "object":"payment_intent",
         "amount":31900,
         "amount_capturable":0,
         "amount_details":{
            "tip":[

            ]
         },
         "amount_received":0,
         "application":null,
         "application_fee_amount":389,
         "automatic_payment_methods":null,
         "canceled_at":null,
         "cancellation_reason":null,
         "capture_method":"automatic",
         "charges":{
            "object":"list",
            "data":[

            ],
            "has_more":false,
            "total_count":0,
            "url":"\/v1\/charges?payment_intent=pi_3NC13aJYhVpsZBXW1RZa6lST"
         },
         "client_secret":"pi_3NC13aJYhVpsZBXW1RZa6lST_secret_JUG35ILCybVCrBnxbvnSenC5G",
         "confirmation_method":"automatic",
         "created":1685109110,
         "currency":"eur",
         "customer":null,
         "description":".",
         "invoice":null,
         "last_payment_error":null,
         "latest_charge":null,
         "livemode":false,
         "metadata":{
            "license":"demo2",
            "order_id":"22834",
            "server":"spiagge"
         },
         "next_action":null,
         "on_behalf_of":"acct_1IECeOR757bxstyQ",
         "payment_method":null,
         "payment_method_options":{
            "card_present":{
               "request_extended_authorization":false,
               "request_incremental_authorization_support":false
            }
         },
         "payment_method_types":[
            "card_present"
         ],
         "processing":null,
         "receipt_email":null,
         "review":null,
         "setup_future_usage":null,
         "shipping":null,
         "source":null,
         "statement_descriptor":null,
         "statement_descriptor_suffix":null,
         "status":"requires_payment_method",
         "transfer_data":{
            "destination":"acct_1IECeOR757bxstyQ"
         },
         "transfer_group":"demo2"
      },
      "stripeTerminalProcessIntent":{
         "id":"tmr_E4Qgjgy8cTXIjA",
         "object":"terminal.reader",
         "action":{
            "failure_code":null,
            "failure_message":null,
            "process_payment_intent":{
               "payment_intent":"pi_3NC13aJYhVpsZBXW1RZa6lST"
            },
            "status":"in_progress",
            "type":"process_payment_intent"
         },
         "device_sw_version":"2.12.2.3",
         "device_type":"bbpos_wisepos_e",
         "ip_address":"192.168.148.97",
         "label":"zaffiro-dragoncello-esempio",
         "last_seen_at":1685109074546,
         "livemode":false,
         "location":"tml_E4QgVwjRzXsiPS",
         "metadata":[

         ],
         "serial_number":"WSC513105000671",
         "status":"online"
      },
      "stripeError":null,
      "reservation":22834,
      "stripeTerminal":null,
      "stripeTestMode":true,
      "stripeAcctId":"acct_1IECeOR757bxstyQ",
      "stripeAcctType":"custom",
      "stripeAcctActive":true
   }
}
*/
}

export enum StripePaymentIntentSourceAccountType {
  PHYSICAL_TERMINAL = 'physical_terminal',
  ONLINE_TERMINAL = 'online_terminal',
}
