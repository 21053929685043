import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-212f012c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-buttonset" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    id: "map-zoom",
    class: _normalizeClass(["map-control", _ctx.planner.active ? 'lift' : ''])
  }, [
    _createElementVNode("span", _hoisted_1, [
      _createVNode(_component_Button, {
        class: "p-button-white",
        icon: "spi-plus",
        onClick: _ctx.zoomIn
      }, null, 8, ["onClick"]),
      _createVNode(_component_Button, {
        class: "p-button-white",
        icon: "spi-minus",
        onClick: _ctx.zoomOut
      }, null, 8, ["onClick"])
    ])
  ], 2))
}