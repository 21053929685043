import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-687229ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "price-lists-create-view",
  class: "price-lists-view view"
}
const _hoisted_2 = { class: "col-12 sm:col-10 xl:col-8 col-box section-block" }
const _hoisted_3 = { id: "price-list-box flex-auto" }
const _hoisted_4 = { class: "col-12 sm:col-10 xl:col-8 col-box section-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.mounted)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#price-lists-header-teleport"
        }, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('priceListsCreateView.title')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('priceListsCreateView.subtitle')), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("section", _hoisted_3, [
          _createElementVNode("h6", null, _toDisplayString(_ctx.$t('priceListsCreateView.priceListCreate')), 1),
          _createVNode(_component_Button, {
            label: _ctx.$t('priceListsCreateView.wizardStandard'),
            icon: "spi-list-standard",
            "icon-pos": "top",
            class: "p-button-lg btn-box",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onPriceListWizard(this.standardOption)))
          }, null, 8, ["label"]),
          _createVNode(_component_Button, {
            label: _ctx.$t('priceListsCreateView.wizardExtraServices'),
            icon: "spi-list-extra",
            "icon-pos": "top",
            class: "p-button-lg p-button-outlined btn-box",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onPriceListWizard(this.extraServiceOption)))
          }, null, 8, ["label"]),
          _createVNode(_component_Button, {
            label: _ctx.$t('priceListsCreateView.wizardSeasonal'),
            icon: "spi-list-seasonal",
            "icon-pos": "top",
            class: "p-button-lg p-button-outlined btn-box",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onPriceListWizard(this.seasonalOption)))
          }, null, 8, ["label"]),
          _createVNode(_component_Button, {
            label: _ctx.$t('priceListsCreateView.uploadCsv'),
            icon: "spi-list-upload",
            "icon-pos": "top",
            class: "btn-box p-button-outlined p-button-lg border-gray-700 text-gray-700",
            onClick: _ctx.onPriceListUpload
          }, null, 8, ["label", "onClick"])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.$t('priceListsCreateView.voucherCreate')), 1),
        _createVNode(_component_Button, {
          label: _ctx.$t('priceListsCreateView.voucher'),
          icon: "spi-list-voucher",
          "icon-pos": "top",
          class: "p-button-lg btn-box",
          onClick: _ctx.onVoucherCreate
        }, null, 8, ["label", "onClick"])
      ])
    ])
  ], 64))
}