
import { defineComponent } from 'vue';
import { License } from '@/models/license';

export default defineComponent({
  data() {
    return {
      urlTeam:
        process.env.VUE_APP_LEGACY_DOMAIN + process.env.VUE_APP_LEGACY_TEAM,
    };
  },
  methods: {
    openTeam(): void {
      window.open(
        `${this.urlTeam}/contract?license=${this.license?.license}`,
        '_self',
      );
    },
  },
  computed: {
    license(): License | null {
      return this.$store.getters['session/license'];
    },
  },
});
