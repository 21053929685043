import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "reservation-documentation"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentationUploader = _resolveComponent("DocumentationUploader")!

  return (_ctx.documentTemplates.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.documentTemplates, (documentTemplate, index) => {
          return (_openBlock(), _createBlock(_component_DocumentationUploader, {
            key: index,
            template: documentTemplate
          }, null, 8, ["template"]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}