import { MapBackground, MapDecoration as MapDecorationI, MapSpot as MapSpotI } from '@/models/map';

export interface CalculateMapRectsInterface {
  mapLazyLoadingEnabled: boolean,
  width: number,
  height: number,
  cellWidth: number,
  cellHeight: number,
  containerWidth: number,
  containerHeight: number,
  seaHeight: number,
  innerSpacer: boolean,
  background: MapBackground | null,
}

export interface MapRectInterface {
  width: number,
  height: number,
  x: number,
  y: number,
  getMapElements(
      mapElements: (MapSpotI|MapDecorationI)[],
      cellWidth: number,
      cellHeight: number
  ): (MapSpotI|MapDecorationI)[]
}

class MapRect implements MapRectInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor(
      public x: number,
      public y: number,
      public width: number,
      public height: number,
  ) {}

  // eslint-disable-next-line max-len
  getMapElements(mapElements: (MapSpotI|MapDecorationI)[], cellWidth: number, cellHeight: number): (MapSpotI|MapDecorationI)[] {
    return mapElements.filter((mapElement) => this.x <= (mapElement.posX * cellWidth)
        && (mapElement.posX * cellWidth) <= this.x + this.width
        && this.y <= (mapElement.posY * cellHeight)
        && (mapElement.posY * cellHeight) <= this.y + this.height);
  }
}

const calculateMapSize = ({
  width,
  height,
  cellWidth,
  cellHeight,
  containerWidth,
  containerHeight,
  seaHeight,
  innerSpacer,
  background,
}: CalculateMapRectsInterface): { width: number, height: number } => {
  const mapWidth = width * cellWidth;
  const mapHeight = height * cellHeight;

  const mapBackgroundWidth = (() => {
    if (!background) return 0;

    let width = background.width;

    if (background.posX) {
      width += background.posX;
    }

    return width * cellWidth;
  })();

  const mapBackgroundHeight = (() => {
    if (!background) return 0;

    let height = background.height;

    if (background.posY) {
      height += background.posY;
    }

    return height * cellHeight;
  })();

  const panzoomWidth = Math.max(
      // right spacer only on standard mode
      mapWidth + (innerSpacer ? 90 : 0),
      mapBackgroundWidth,
  );

  const panzoomHeight =
      Math.max(
          // bottom spacer only on standard mode
          mapHeight + (innerSpacer ? 45 : 0),
          mapBackgroundHeight,
      ) + seaHeight;

  return {
    width: Math.max(panzoomHeight, containerHeight),
      height: Math.max(panzoomWidth, containerWidth),
  };
};

const createMapRects = (
  mapLazyLoadingEnabled: boolean,
  width: number,
  height: number,
): MapRectInterface[] => {
  const totalRows = mapLazyLoadingEnabled ? 10 : 1;
  const totalCells = mapLazyLoadingEnabled ? 60 : 1;
  const mapRects = [];

  for (let y = 0; y < totalRows; y += 1) {
    for (let x = 0; x < totalCells; x += 1) {
      mapRects.push(new MapRect(
        x * (width / totalCells),
        y * (height / totalRows),
        width / totalCells,
        height / totalRows,
      ));
    }
  }

  return mapRects;
};

export const calculateMapRects = (params: CalculateMapRectsInterface): MapRectInterface[] => {
  const { height, width } = calculateMapSize(params);

  return createMapRects(
      params.mapLazyLoadingEnabled,
      height,
      width,
  );
};
