
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import priceListService from '@/services/priceListService';
import voucherService from '@/services/voucherService';
import { PriceList, PricePeriod } from '@/models/priceList';
import { Voucher } from '@/models/voucher';
import PriceListList from '@/components/priceList/PriceListList.vue';
import PriceListDetails from '@/components/priceList/PriceListDetails.vue';
import PriceListDialog from '@/components/priceList/PriceListDialog.vue';
import PriceListSwitchDialog from '@/components/priceList/PriceListSwitchDialog.vue';
import SeasonalDialog from '@/components/priceList/SeasonalDialog.vue';
import VoucherDetails from '@/components/priceList/VoucherDetails.vue';
import { ToastSeverity } from '@/models/toast';
import toastUtil from '@/utils/toastUtil';
import { LicenseType, License } from '@/models/license';
import {
  ApiPriceListCreateResponse,
  ApiPriceListUpdateResponse,
} from '@/models/api';

export default defineComponent({
  name: 'PriceListsView',
  components: {
    PriceListList,
    PriceListDetails,
    PriceListDialog,
    SeasonalDialog,
    VoucherDetails,
    PriceListSwitchDialog,
  },
  data() {
    return {
      priceLists: [] as Array<PriceList>,
      priceListSelected: null as PriceList | null,
      // List edit
      showPriceListDialog: false,
      priceListDialogMode: 'edit',
      priceListEdit: null as PriceList | null,
      // Switch
      showPriceListSwitchDialog: false,
      // Seasonal
      showSeasonalDialog: false,
      // Voucher
      voucherSelected: null as Voucher | null,
      showVoucherDetails: false,
      voucherEdit: null as Voucher | null,
    };
  },
  computed: {
    ...mapState('app', ['windowWidth', 'windowHeight']),
    ...mapState('session', ['vouchers']),
    isDemo(): boolean {
      return this.license?.licenseType === LicenseType.DEMO;
    },
    license(): License | null {
      return this.$store.getters['session/license'];
    },
    loading(): boolean {
      return this.$store.getters['app/loading'];
    },
  },
  methods: {
    onPriceListSelect(priceListId: number): void {
      const priceList = this.priceLists.find((p) => p.id === priceListId);
      if (!priceList) return;
      this.priceListSelected = priceList;
      this.showVoucherDetails = false;
    },
    onVoucherSelect(voucherId: number): void {
      const voucher = this.vouchers.find((v: Voucher) => v.id === voucherId);
      if (!voucher) return;
      this.voucherSelected = voucher;
      this.voucherEdit = this.voucherSelected;
      this.priceListSelected = null;
      this.showVoucherDetails = true;
    },
    onNewList(): void {
      this.showPriceListDialog = true;
    },
    onNewVoucher(): void {
      this.voucherEdit = null;
      this.priceListSelected = null;
      this.showVoucherDetails = true;
    },
    onRenameList(): void {
      this.priceListEdit = this.priceListSelected;
      this.priceListDialogMode = 'rename';
      this.showPriceListDialog = true;
    },
    onEditList(): void {
      this.priceListEdit = this.priceListSelected;
      this.showPriceListDialog = true;
    },
    onSwitchList(): void {
      this.priceListEdit = this.priceListSelected;
      this.showPriceListSwitchDialog = true;
    },
    onDeleteList(): void {
      this.$confirm.require({
        message: this.$t('priceListView.confirm.deleteMessage'),
        header: this.$t('priceListView.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('common.yes'),
        accept: () => {
          this.delete();
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          // nothing
        },
      });
    },
    onUpdatePeriods(periods: Array<PricePeriod>): void {
      if (!this.priceListSelected) return;
      this.priceListSelected.periods = periods;
    },
    onPriceListDialogHide(): void {
      this.showPriceListDialog = false;
      this.priceListDialogMode = 'edit';
      this.priceListEdit = null;
    },
    onPriceListSwitchDialogHide(): void {
      this.showPriceListSwitchDialog = false;
      this.priceListEdit = null;
    },
    onPriceListSwitchEvent(targetId: number): void {
      if (!this.priceListEdit) return;
      const priceListId = this.priceListEdit.id;
      this.onPriceListSwitchDialogHide();
      this.$confirm.require({
        message: this.$t('priceListView.confirm.switchMessage'),
        header: this.$t('priceListView.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('common.yes'),
        accept: () => {
          this.onPriceListSwitch(priceListId, targetId);
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          // nothing
        },
      });
    },
    async onPriceListSwitch(
      priceListId: number,
      targetId: number,
    ): Promise<void> {
      try {
        const response = await priceListService.switch(priceListId, {
          targetId,
        });
        // Show success toast
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
      } catch (e) {
        // console.log(e);
      }
    },
    async save(priceListToSave: PriceList): Promise<void> {
      this.onPriceListDialogHide();
      try {
        let response;
        if (!priceListToSave.id) {
          response = (await priceListService.create(
            priceListToSave,
          )) as unknown as ApiPriceListCreateResponse;
        } else {
          response = (await priceListService.update(
            priceListToSave.id,
            priceListToSave,
          )) as unknown as ApiPriceListUpdateResponse;
        }
        const priceList = response.result.priceList;
        // Show success toast
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
        // Update state
        let index = this.priceLists.findIndex((p) => p.id === priceList.id);
        if (index !== -1) {
          this.priceLists[index] = cloneDeep(priceList);
        } else {
          this.priceLists.push(cloneDeep(priceList));
          index = this.priceLists.length - 1;
        }
        this.priceListSelected = this.priceLists[index];
      } catch (e) {
        // console.log(e);
      }
    },
    async delete(): Promise<void> {
      if (!this.priceListSelected) return;
      try {
        await priceListService.delete(this.priceListSelected.id);
        // Show success toast
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        ); // TODO: proper toast
        // Update state
        const index = this.priceLists.findIndex(
          (p) => p.id === this.priceListSelected?.id,
        );
        if (index !== -1) {
          this.priceLists.splice(index, 1);
        }
        this.priceListSelected = null;
      } catch (e) {
        // console.log(e);
      }
    },
    onVoucherDialogHide(): void {
      this.showVoucherDetails = false;
      this.voucherEdit = null;
    },
    async saveVoucher(voucherToSave: Voucher): Promise<void> {
      this.onVoucherDialogHide();
      try {
        let response;
        if (!voucherToSave.id) {
          response = await voucherService.create(voucherToSave);
        } else {
          response = await voucherService.update(
            voucherToSave.id,
            voucherToSave,
          );
        }
        this.$spiagge.toast.success(
          this.$t('voucherDetails.toast.elementSaved'),
        );
        // Open voucher in edit mode
        const voucher = response;
        this.vouchers.find((v: Voucher) => v.id === voucher.id);
        if (!voucher) return;
        this.voucherSelected = voucher;
        this.voucherEdit = this.voucherSelected;
        this.showVoucherDetails = true;
        // Update state
        let index = this.vouchers.findIndex(
          (v: Voucher) => v.id === voucher.id,
        );
        if (index !== -1) {
          this.vouchers[index] = cloneDeep(voucher);
        } else {
          this.vouchers.push(cloneDeep(voucher));
          index = this.vouchers.length - 1;
        }
      } catch (error) {
        // console.log(error);
        this.$spiagge.toast.error(
          this.$t('voucherDetails.toast.elementNotSaved'),
        );
      }
    },
    async deleteVoucher(): Promise<void> {
      if (!this.voucherSelected) return;
      try {
        await voucherService.delete(this.voucherSelected.id);
        // Show success toast
        this.$spiagge.toast.success(
          this.$t('voucherDetails.toast.elementDeleted'),
        );
        // Update state
        const index = this.vouchers.findIndex(
          (v: Voucher) => v.id === this.voucherSelected?.id,
        );
        if (index !== -1) {
          this.vouchers.splice(index, 1);
        }
        this.voucherSelected = null;
      } catch (e) {
        // console.log(e);
        this.$spiagge.toast.error(
          this.$t('voucherDetails.toast.elementNotDeleted'),
        );
      }
      this.showVoucherDetails = false;
    },
    onOpenSeasonal(): void {
      this.showSeasonalDialog = true;
    },
    onSeasonalDialogHide(): void {
      this.showSeasonalDialog = false;
    },
    async loadData(): Promise<void> {
      try {
        // Refresh vouchers
        await this.$store.dispatch('session/setVouchers');
        // Get price lists
        this.priceLists = await priceListService.find({});
      } catch (e) {
        // console.log(e);
        this.$spiagge.toast.error(
          this.$t('priceListView.toast.retrievalError'),
        );
      }
    },
  },
  async beforeMount() {
    await this.loadData();
    // handle redirects from new prices lists
    const action = this.$route.params.action;
    if (action) {
      switch (action) {
        case 'newPriceList':
          this.showPriceListDialog = true;
          break;
        case 'newVoucher':
          this.onNewVoucher();
          break;
        case 'editPriceList':
          this.onPriceListSelect(Number(this.$route.params.id));
          break;
        case 'editVoucher':
          this.onVoucherSelect(Number(this.$route.params.id));
          break;
        default:
          break;
      }
    }
  },
});
