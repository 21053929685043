import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-86110d78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "revenue-management-historical-details" }
const _hoisted_2 = {
  key: 0,
  class: "day-cell"
}
const _hoisted_3 = { class: "info inverted" }
const _hoisted_4 = { class: "label" }
const _hoisted_5 = { class: "value" }
const _hoisted_6 = { class: "info inverted" }
const _hoisted_7 = { class: "label" }
const _hoisted_8 = { class: "value" }
const _hoisted_9 = {
  key: 1,
  class: "standard-cell"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DataTable, {
      value: _ctx.data,
      scrollable: "",
      scrollHeight: `calc(100vh - ${_ctx.breakpoints.mobile ? 260 : 260}px)`
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "date",
          header: "Data",
          frozen: ""
        }, {
          body: _withCtx((slotProps) => [
            _createTextVNode(_toDisplayString(_ctx.$spiagge.utils.date.fromSeconds(slotProps.data.date, 'dd/MM')), 1)
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (column, columnIndex) => {
          return (_openBlock(), _createBlock(_component_Column, {
            key: columnIndex,
            field: column.field,
            header: column.header,
            style: { minWidth: '130px' }
          }, {
            body: _withCtx((slotProps) => [
              (column.day)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('revenueManagementHistoricalDetails.pickUp')) + ":", 1),
                      _createElementVNode("span", _hoisted_5, _toDisplayString(slotProps.data[column.field]['pickUp']), 1)
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('revenueManagementHistoricalDetails.occupancyPercentage')), 1),
                      _createElementVNode("span", _hoisted_8, _toDisplayString(slotProps.data[column.field]['occupancyPercentage']), 1)
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(slotProps.data[column.field]), 1))
            ]),
            _: 2
          }, 1032, ["field", "header"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["value", "scrollHeight"])
  ]))
}