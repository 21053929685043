
import { defineComponent, PropType } from 'vue';
import { DateTime, Interval } from 'luxon';
import { cloneDeep } from 'lodash';
import {
  PriceConfigErrors,
  PriceConfigPeriodData,
  PriceConfigPeriodDataInterval,
} from '@/models/priceConfig';

interface IntervalWithToken {
  interval: Interval;
  token: string;
}

export default defineComponent({
  name: 'FieldPriceConfigPeriods',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Array as PropType<Array<PriceConfigPeriodData>>,
      required: true,
    },
    errors: {
      type: Object as PropType<PriceConfigErrors>,
      required: false,
      default: () => ({}),
    },
    year: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      maxPeriodId: 0,
    };
  },
  beforeMount() {
    this.modelValue.forEach((period: PriceConfigPeriodData) => {
      this.maxPeriodId = Math.max(this.maxPeriodId, period.id);
    });
  },
  methods: {
    getCalendarModel(date: string): Date | undefined {
      if (!date) return undefined;
      const dateSplitted = date.split('-');
      return new Date(
        this.year,
        Number(dateSplitted[1]) - 1,
        Number(dateSplitted[0]),
      );
    },
    getCalendarMinDate(from: string): Date | undefined {
      if (!from) return undefined;
      const fromSplitted = from.split('-');
      return new Date(
        this.year,
        Number(fromSplitted[1]) - 1,
        Number(fromSplitted[0]),
      );
    },
    getCalendarMaxDate(to: string): Date {
      const toSplitted = to.split('-');
      return new Date(
        this.year,
        Number(toSplitted[1]) - 1,
        Number(toSplitted[0]),
      );
    },
    onAddPeriod(): void {
      const res = cloneDeep(this.periods);
      this.maxPeriodId += 1;
      res.push({
        id: this.maxPeriodId,
        name: '',
        intervals: [
          {
            startDate: '',
            endDate: '',
          },
        ],
      });
      this.$emit('update:modelValue', res);
    },
    onAddInterval(period: number): void {
      const res = cloneDeep(this.periods);
      res[period].intervals.push({
        startDate: '',
        endDate: '',
      });
      this.$emit('update:modelValue', res);
    },
    onRemoveInterval(periodIndex: number, intervalIndex: number): void {
      const res = cloneDeep(this.modelValue);
      res[periodIndex].intervals = res[periodIndex].intervals.filter(
        (interval: PriceConfigPeriodDataInterval, index: number) =>
          index !== intervalIndex,
      );
      this.$emit('update:modelValue', res);
    },
    onRemovePeriod(periodIndex: number): void {
      const res = cloneDeep(this.modelValue).filter(
        (period: PriceConfigPeriodData, index: number) => periodIndex !== index,
      );
      this.$emit('update:modelValue', res);
    },
    onNameChange(event: Event, index: number): void {
      const res = cloneDeep(this.modelValue);
      res[index].name = (event.target as HTMLInputElement).value;
      this.$emit('update:modelValue', res);
    },
    dateToString(date: DateTime): string {
      return date.toFormat('dd-MM');
    },
    onDateSelect(
      date: Date,
      indexPeriod: number,
      indexInterval: number,
      dateIndex: 'startDate' | 'endDate',
    ): void {
      const res = cloneDeep(this.modelValue);
      const intervalToUpdate = cloneDeep(
        res[indexPeriod].intervals[indexInterval],
      );
      const dtDate = DateTime.utc(
        this.year,
        date.getMonth() + 1,
        date.getDate(),
      );
      const dateString = this.dateToString(dtDate);
      intervalToUpdate[dateIndex] = dateString;

      let openEndDateCalendar = false;

      if (
        dateIndex === 'startDate' &&
        (intervalToUpdate.endDate === '' ||
          dtDate >
            DateTime.fromFormat(
              `${intervalToUpdate.endDate}-${this.year}`,
              'dd-MM-yyyy',
            ))
      ) {
        openEndDateCalendar = true;
        intervalToUpdate.endDate = dateString;
      }
      res[indexPeriod].intervals[indexInterval] = intervalToUpdate;

      // update model
      this.$emit('update:modelValue', res);

      // open end date calendar
      this.$nextTick(() => {
        if (openEndDateCalendar) {
          (this.$refs[`P${indexPeriod}_I${indexInterval}_ED`] as any).$el
            .getElementsByTagName('input')[0]
            .focus();
        }
      });
    },
  },
  computed: {
    periods(): Array<PriceConfigPeriodData> {
      return this.modelValue ? cloneDeep(this.modelValue) : [];
    },
    minDate(): Date {
      return new Date(this.year, 0, 1);
    },
    maxDate(): Date {
      return new Date(this.year, 11, 31);
    },
    overlappingIntervals(): Array<string> {
      const overlappingIntervals: Array<string> = [];

      this.intervalsFulfilled.forEach(
        (intervalWithToken: IntervalWithToken) => {
          const mainInterval = cloneDeep(intervalWithToken);

          mainInterval.interval = intervalWithToken.interval.set({
            end: intervalWithToken.interval.end.plus({ days: 1 }),
          });
          const otherIntervals = this.intervalsFulfilled.filter(
            (i: IntervalWithToken) => i !== intervalWithToken,
          );
          if (
            otherIntervals.some((i: IntervalWithToken) =>
              mainInterval.interval.overlaps(i.interval),
            )
          ) {
            overlappingIntervals.push(mainInterval.token);
          }
        },
      );
      return overlappingIntervals;
    },
    overlapping(): boolean {
      return this.overlappingIntervals.length > 0;
    },
    intervalsFulfilled(): Array<IntervalWithToken> {
      const intervals: Array<IntervalWithToken> = [];
      this.periods.forEach(
        (period: PriceConfigPeriodData, periodIndex: number) => {
          period.intervals.forEach(
            (
              interval: PriceConfigPeriodDataInterval,
              intervalIndex: number,
            ) => {
              if (interval.startDate && interval.endDate) {
                intervals.push({
                  token: `P${periodIndex + 1}_I${intervalIndex + 1}`,
                  interval: Interval.fromDateTimes(
                    DateTime.fromFormat(
                      `${interval.startDate}-${this.year}`,
                      'dd-MM-yyyy',
                    ),
                    DateTime.fromFormat(
                      `${interval.endDate}-${this.year}`,
                      'dd-MM-yyyy',
                    ),
                  ),
                });
              }
            },
          );
        },
      );
      // eslint-disable-next-line no-confusing-arrow
      return intervals.sort((a: IntervalWithToken, b: IntervalWithToken) =>
        a.interval.start > b.interval.start ? 1 : -1,
      );
    },
    yearFulfilled(): boolean {
      const yearInterval = Interval.fromDateTimes(
        DateTime.fromFormat(`01-01-${this.year}`, 'dd-MM-yyyy').startOf('day'),
        DateTime.fromFormat(`31-12-${this.year}`, 'dd-MM-yyyy').startOf('day'),
      );

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      const intervals = this.intervalsFulfilled
        // eslint-disable-next-line no-confusing-arrow
        .sort((a: IntervalWithToken, b: IntervalWithToken) =>
          a.interval.start < b.interval.start ? -1 : 1,
        )
        .map((intervalWithToken: IntervalWithToken, index: number) => {
          if (index === this.intervalsFulfilled.length - 1) {
            return intervalWithToken.interval;
          }
          return intervalWithToken.interval.set({
            end: intervalWithToken.interval.end.plus({ days: 1 }),
          });
        });
      return yearInterval.difference(...intervals).length === 0;
    },
    canAddInterval(): boolean {
      return (
        this.periods.reduce(
          (count: number, period: PriceConfigPeriodData) =>
            (period.intervals.length ?? 0) + count,
          0,
        ) !== this.intervalsFulfilled.length
      );
    },
    canAddPeriod(): boolean {
      return (
        this.canAddInterval &&
        this.periods.every((period: PriceConfigPeriodData) => period.name)
      );
    },
    hasErrors(): boolean {
      return Object.keys(this.errors).length > 0;
    },
  },
});
