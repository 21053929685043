import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0e0f0fa1"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  id: "price-lists-quality-check-view",
  class: "price-lists-view view"
}
const _hoisted_2 = { id: "configuration" }
const _hoisted_3 = { class: "spot" }
const _hoisted_4 = { class: "p-field spot-field" }
const _hoisted_5 = { for: "spot-dropdown" }
const _hoisted_6 = { class: "staging" }
const _hoisted_7 = { class: "p-field beds-field" }
const _hoisted_8 = { for: "beds-dropdown" }
const _hoisted_9 = { class: "p-field deckchairs-field" }
const _hoisted_10 = { for: "deckchairs-dropdown" }
const _hoisted_11 = { class: "p-field chairs-field" }
const _hoisted_12 = { for: "chairs-dropdown" }
const _hoisted_13 = { class: "p-field maxibeds-field" }
const _hoisted_14 = { for: "maxibeds-dropdown" }
const _hoisted_15 = { class: "cabin" }
const _hoisted_16 = { class: "p-field use-cabin-field" }
const _hoisted_17 = { for: "" }
const _hoisted_18 = { class: "p-field-radio horizontal" }
const _hoisted_19 = { class: "p-field-radiobutton" }
const _hoisted_20 = { for: "use-cabin-radio" }
const _hoisted_21 = { class: "p-field-radiobutton" }
const _hoisted_22 = { for: "use-cabin-radio" }
const _hoisted_23 = { class: "apply" }
const _hoisted_24 = { class: "title" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = {
  key: 0,
  class: "p-field switch-field__label-after show-all-switch"
}
const _hoisted_27 = { for: "show-all-switch" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Breadcrumbs = _resolveComponent("Breadcrumbs")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_Button = _resolveComponent("Button")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_SelectButton = _resolveComponent("SelectButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.mounted)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#price-lists-header-teleport"
        }, [
          _createVNode(_component_Breadcrumbs),
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('priceListsQualityCheckView.title')), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('priceListsQualityCheckView.subtitle')), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.ready)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("section", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('priceListsQualityCheckView.spot')), 1),
                _createVNode(_component_Dropdown, {
                  id: "spot-dropdown",
                  modelValue: _ctx.model.spot,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.spot) = $event)),
                  options: _ctx.spotOptions,
                  optionLabel: "label",
                  optionValue: "value"
                }, null, 8, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('priceListsQualityCheckView.staging.beds')), 1),
                _createVNode(_component_Dropdown, {
                  id: "beds-dropdown",
                  modelValue: _ctx.model.numBeds,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.numBeds) = $event)),
                  options: _ctx.stagingOptions,
                  optionLabel: "label",
                  optionValue: "value"
                }, null, 8, ["modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('priceListsQualityCheckView.staging.deckchairs')), 1),
                _createVNode(_component_Dropdown, {
                  id: "deckchairs-dropdown",
                  modelValue: _ctx.model.numDeckChairs,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.numDeckChairs) = $event)),
                  options: _ctx.stagingOptions,
                  optionLabel: "label",
                  optionValue: "value"
                }, null, 8, ["modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('priceListsQualityCheckView.staging.chairs')), 1),
                _createVNode(_component_Dropdown, {
                  id: "chairs-dropdown",
                  modelValue: _ctx.model.numChairs,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.numChairs) = $event)),
                  options: _ctx.stagingOptions,
                  optionLabel: "label",
                  optionValue: "value"
                }, null, 8, ["modelValue", "options"])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('priceListsQualityCheckView.staging.maxibeds')), 1),
                _createVNode(_component_Dropdown, {
                  id: "maxibeds-dropdown",
                  modelValue: _ctx.model.numMaxiBeds,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.numMaxiBeds) = $event)),
                  options: _ctx.stagingOptions,
                  optionLabel: "label",
                  optionValue: "value"
                }, null, 8, ["modelValue", "options"])
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('priceListsQualityCheckView.cabin')), 1),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(_component_RadioButton, {
                      value: false,
                      modelValue: _ctx.model.useCabin,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.useCabin) = $event))
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t('common.no')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode(_component_RadioButton, {
                      value: true,
                      modelValue: _ctx.model.useCabin,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.useCabin) = $event))
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t('common.yes')), 1)
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createVNode(_component_Button, {
                label: _ctx.$t('button.apply'),
                onClick: _ctx.onApply
              }, null, 8, ["label", "onClick"])
            ])
          ]),
          _createVNode(_component_Panel, {
            id: "price-estimate",
            header: _ctx.$t('priceListsQualityCheckView.priceEstimate')
          }, {
            icons: _withCtx(() => [
              (_ctx.priceEstimateFilters)
                ? (_openBlock(), _createBlock(_component_MultiSelect, {
                    key: 0,
                    modelValue: _ctx.priceEstimateFilters.sectors,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.priceEstimateFilters.sectors) = $event)),
                    options: _ctx.sectorsOptions,
                    optionLabel: "label",
                    optionValue: "value",
                    placeholder: 
            _ctx.$t('priceListsQualityCheckView.sectorFilterPlaceholder')
          ,
                    selectionLimit: _ctx.maxSectors
                  }, null, 8, ["modelValue", "options", "placeholder", "selectionLimit"]))
                : _createCommentVNode("", true),
              _createVNode(_component_Calendar, {
                inputId: "range",
                ref: "range",
                modelValue: _ctx.priceEstimateRange,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.priceEstimateRange) = $event)),
                selectionMode: "range",
                onDateSelect: _ctx.onPriceEstimateRangeSelect,
                numberOfMonths: 2,
                dateFormat: "dd/mm/yy",
                onHide: _ctx.onPriceEstimateRangeHide,
                "panel-class": "dense",
                manualInput: false,
                placeholder: 
            _ctx.$t('priceListsQualityCheckView.periodFilterPlaceholder')
          
              }, null, 8, ["modelValue", "onDateSelect", "onHide", "placeholder"]),
              _createVNode(_component_Button, {
                label: _ctx.$t('priceListsQualityCheckView.estimate'),
                onClick: _ctx.onPriceEstimate,
                disabled: !_ctx.canEstimate
              }, null, 8, ["label", "onClick", "disabled"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_DataTable, {
                value: _ctx.priceEstimateRows,
                responsiveLayout: "scroll",
                loading: _ctx.priceEstimateFilters.processing
              }, {
                empty: _withCtx(() => [
                  _createVNode(_component_Message, {
                    severity: "info",
                    closable: false
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('priceListsQualityCheckView.noDataMessage')), 1)
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.priceEstimateColumns, (column, index) => {
                    return (_openBlock(), _createBlock(_component_Column, {
                      key: index,
                      field: column.field,
                      header: column.header,
                      style: {"min-width":"200px"}
                    }, null, 8, ["field", "header"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "loading"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Panel, { id: "packets-table" }, {
            header: _withCtx(() => [
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("span", null, [
                  _createTextVNode(_toDisplayString(_ctx.$t('priceListsQualityCheckView.packetsTable')) + " ", 1),
                  (_ctx.hasPackets)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_25, " (" + _toDisplayString(_ctx.priceConfig?.discountType?.join(', ')) + ") ", 1))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.hasPackets)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                      _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t('priceListsQualityCheckView.showAll')), 1),
                      _createVNode(_component_InputSwitch, {
                        class: "small",
                        modelValue: _ctx.packetsTableFilters.allDays,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.packetsTableFilters.allDays) = $event))
                      }, null, 8, ["modelValue"])
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            icons: _withCtx(() => [
              (_ctx.packetsTableFilters.view === _ctx.packetsTableView.PERIOD)
                ? (_openBlock(), _createBlock(_component_SelectButton, {
                    key: 0,
                    modelValue: _ctx.packetsTableFilters.period,
                    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.packetsTableFilters.period) = $event)),
                    options: _ctx.periodsOptions,
                    optionLabel: "label",
                    optionValue: "value"
                  }, null, 8, ["modelValue", "options"]))
                : _createCommentVNode("", true),
              (_ctx.packetsTableFilters.view === _ctx.packetsTableView.SECTOR)
                ? (_openBlock(), _createBlock(_component_SelectButton, {
                    key: 1,
                    modelValue: _ctx.packetsTableFilters.sector,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.packetsTableFilters.sector) = $event)),
                    options: _ctx.sectorsOptions,
                    optionLabel: "label",
                    optionValue: "value"
                  }, null, 8, ["modelValue", "options"]))
                : _createCommentVNode("", true),
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.packetsTableFilters.view,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.packetsTableFilters.view) = $event)),
                options: _ctx.packetsTableViewOptions,
                optionLabel: "label",
                optionValue: "value"
              }, null, 8, ["modelValue", "options"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_DataTable, {
                value: _ctx.packetsTableRows,
                responsiveLayout: "scroll",
                loading: _ctx.packetsTableFilters.processing,
                rowClass: _ctx.packetsTableRowClass
              }, {
                empty: _withCtx(() => [
                  _createVNode(_component_Message, {
                    severity: "info",
                    closable: false
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('priceListsQualityCheckView.noDataMessage')), 1)
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.packetsTableColumns, (column, index) => {
                    return (_openBlock(), _createBlock(_component_Column, {
                      key: index,
                      field: column.field,
                      header: column.header,
                      style: {"min-width":"200px"}
                    }, null, 8, ["field", "header"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "loading", "rowClass"])
            ]),
            _: 1
          }),
          _createVNode(_component_Panel, {
            id: "sectors-shift",
            header: _ctx.$t('priceListsQualityCheckView.sectorsShift')
          }, {
            icons: _withCtx(() => [
              _createVNode(_component_SelectButton, {
                modelValue: _ctx.sectorsShiftFilters.period,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.sectorsShiftFilters.period) = $event)),
                options: _ctx.periodsOptions,
                optionLabel: "label",
                optionValue: "value"
              }, null, 8, ["modelValue", "options"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_DataTable, {
                value: _ctx.sectorsShiftRows,
                responsiveLayout: "scroll",
                loading: _ctx.sectorsShiftFilters.processing
              }, {
                empty: _withCtx(() => [
                  _createVNode(_component_Message, {
                    severity: "info",
                    closable: false
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('priceListsQualityCheckView.noDataMessage')), 1)
                    ]),
                    _: 1
                  })
                ]),
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sectorsShiftColumns, (column, index) => {
                    return (_openBlock(), _createBlock(_component_Column, {
                      key: index,
                      field: column.field,
                      header: column.header,
                      style: {"min-width":"200px"}
                    }, null, 8, ["field", "header"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "loading"])
            ]),
            _: 1
          }, 8, ["header"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}