import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3a2a2120"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "price-period" }
const _hoisted_2 = { class: "weekend-select" }
const _hoisted_3 = {
  key: 0,
  class: "weekend-info"
}
const _hoisted_4 = { class: "weekend-info-section" }
const _hoisted_5 = { class: "title" }
const _hoisted_6 = { class: "info" }
const _hoisted_7 = { class: "weekend-day-select" }
const _hoisted_8 = ["for"]
const _hoisted_9 = { class: "weekend-info-section" }
const _hoisted_10 = { class: "title" }
const _hoisted_11 = { class: "info" }
const _hoisted_12 = { class: "weekend-holidays-inputs" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "list-viewer" }
const _hoisted_15 = {
  key: 0,
  class: "list-tabs"
}
const _hoisted_16 = { class: "days" }
const _hoisted_17 = { class: "buttons" }
const _hoisted_18 = { class: "column" }
const _hoisted_19 = { class: "cell" }
const _hoisted_20 = { class: "cell" }
const _hoisted_21 = { class: "cell" }
const _hoisted_22 = { class: "cell" }
const _hoisted_23 = { class: "cell" }
const _hoisted_24 = { class: "cell" }
const _hoisted_25 = { class: "cell" }
const _hoisted_26 = { key: 1 }
const _hoisted_27 = { class: "list-legend" }
const _hoisted_28 = { class: "short" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ToggleButtonCustom = _resolveComponent("ToggleButtonCustom")!
  const _component_InputTooltip = _resolveComponent("InputTooltip")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ListUploadDialog = _resolveComponent("ListUploadDialog")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.$t('priceListView.pricePeriodHeading')), 1),
        _createVNode(_component_ToggleButtonCustom, {
          modelValue: _ctx.pricePeriod.useWeekend,
          "onUpdate:modelValue": _ctx.onWeekendToggle,
          buttonWidth: 60,
          trueLabel: _ctx.$t('common.yes'),
          falseLabel: _ctx.$t('common.no')
        }, null, 8, ["modelValue", "onUpdate:modelValue", "trueLabel", "falseLabel"]),
        _createVNode(_component_InputTooltip, {
          message: _ctx.$t('priceListView.pricePeriodWeekendTooltip')
        }, null, 8, ["message"])
      ]),
      (_ctx.pricePeriod.useWeekend)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('priceListView.pricePeriodWeekend')), 1),
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('priceListView.pricePeriodWeekendInfo')), 1),
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.daysOfWeek, (d) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `d-check-${d}`,
                    class: "day-checkbox"
                  }, [
                    _createVNode(_component_Checkbox, {
                      id: `w-${d}`,
                      value: `w-${d}`,
                      modelValue: _ctx.pricePeriod.weekendRules,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pricePeriod.weekendRules) = $event))
                    }, null, 8, ["id", "value", "modelValue"]),
                    _createElementVNode("label", {
                      for: `w-${d}`
                    }, _toDisplayString(_ctx.getDayName(d)), 9, _hoisted_8)
                  ]))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t('priceListView.pricePeriodHolidays')), 1),
              _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('priceListView.pricePeriodHolidaysInfo')), 1),
              _createElementVNode("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.holidayRules, (rule) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `d-check-${rule.index}`,
                    class: "weekend-holiday"
                  }, [
                    _createElementVNode("i", {
                      class: "spi-times text-sm",
                      onClick: ($event: any) => (_ctx.deleteHoliday(rule.index))
                    }, null, 8, _hoisted_13),
                    _createVNode(_component_Calendar, {
                      ref: `holiday-calendar-${rule.index}`,
                      modelValue: rule.date,
                      showIcon: true,
                      dateFormat: "dd-mm",
                      minDate: _ctx.minDate.toJSDate(),
                      maxDate: _ctx.maxDate.toJSDate(),
                      onDateSelect: ($event: any) => (_ctx.onHolidayRuleSelect($event, rule.index))
                    }, null, 8, ["modelValue", "minDate", "maxDate", "onDateSelect"])
                  ]))
                }), 128)),
                _createVNode(_component_Button, {
                  label: _ctx.$t('pricePeriodDetail.addHoliday'),
                  icon: "spi-plus",
                  class: "p-button-text p-button-plain btn",
                  onClick: _ctx.addHoliday
                }, null, 8, ["label", "onClick"])
              ])
            ]),
            _createVNode(_component_Button, {
              label: _ctx.$t('button.save'),
              icon: "spi-save",
              class: "p-button p-button-primary save",
              disabled: !_ctx.weekendEdited,
              onClick: _ctx.save
            }, null, 8, ["label", "disabled", "onClick"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_14, [
        (_ctx.pricePeriod.useWeekend)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createElementVNode("div", {
                class: _normalizeClass({ selected: _ctx.listPage === 'week' }),
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showWeekPage && _ctx.showWeekPage(...args)))
              }, _toDisplayString(_ctx.$t('pricePeriodDetail.midweek')), 3),
              _createElementVNode("div", {
                class: _normalizeClass({ selected: _ctx.listPage === 'weekend' }),
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showWeekendPage && _ctx.showWeekendPage(...args)))
              }, _toDisplayString(_ctx.$t('pricePeriodDetail.weekendHoliday')), 3)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", {
          class: _normalizeClass({ 'list-content': true, 'has-tabs': _ctx.pricePeriod.useWeekend })
        }, [
          _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.getListDaysString()), 1),
          _createElementVNode("div", _hoisted_17, [
            _createVNode(_component_Button, {
              label: _ctx.$t('button.upload'),
              icon: "spi-upload",
              class: "p-button p-button-primary",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showUploadDialog = true))
            }, null, 8, ["label"]),
            _createVNode(_component_Button, {
              label: _ctx.$t('button.export'),
              icon: "spi-download",
              class: "p-button-text p-button-plain",
              onClick: _ctx.onExportClick,
              disabled: !_ctx.hasListing
            }, null, 8, ["label", "onClick", "disabled"]),
            _createVNode(_component_Button, {
              label: _ctx.$t('button.delete'),
              icon: "spi-trash",
              class: "p-button-text p-button-plain",
              onClick: _ctx.onDeleteClick,
              disabled: !_ctx.hasListing
            }, null, 8, ["label", "onClick", "disabled"])
          ]),
          (_ctx.hasListing)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "list-grid",
                style: _normalizeStyle({ height: _ctx.gridHeight })
              }, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t('pricePeriodDetail.sector.id')), 1),
                  _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('pricePeriodDetail.sector.name')), 1),
                  _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('pricePeriodDetail.additions')), 1),
                  _createElementVNode("div", _hoisted_22, _toDisplayString(_ctx.$t('pricePeriodDetail.hd')), 1),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.priceList.listDaysLength, (d, i) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: `l-h-${i}`,
                      class: "cell"
                    }, _toDisplayString(d), 1))
                  }), 128)),
                  _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t('pricePeriodDetail.suspensionDots')), 1)
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.priceCombinations, (comb) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: `comb-${comb.id}`,
                    class: "column"
                  }, [
                    _createElementVNode("div", {
                      class: "cell",
                      style: _normalizeStyle({ backgroundColor: _ctx.sectorBgColors[comb.sector % 10] })
                    }, _toDisplayString(_ctx.mappedSectors.get(comb.sector)?.oldId ?? '-'), 5),
                    _createElementVNode("div", {
                      class: "cell",
                      style: _normalizeStyle({ backgroundColor: _ctx.sectorBgColors[comb.sector % 10] })
                    }, _toDisplayString(_ctx.mappedSectors.get(comb.sector)?.name), 5),
                    _createElementVNode("div", {
                      class: "cell",
                      style: _normalizeStyle({ backgroundColor: _ctx.sectorBgColors[comb.sector % 10] })
                    }, _toDisplayString(_ctx.getCombinationLabel(comb)), 5),
                    _createElementVNode("div", _hoisted_24, _toDisplayString(comb.value.halfDay), 1),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.priceList.listDaysLength, (d, i) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: `l-d-${i}`,
                        class: "cell"
                      }, _toDisplayString(comb.value.days[`d${d}`]), 1))
                    }), 128)),
                    _createElementVNode("div", _hoisted_25, _toDisplayString(comb.value.extraDay), 1)
                  ]))
                }), 128))
              ], 4))
            : (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(_ctx.$t('pricePeriodDetail.noPriceListMessage')), 1))
        ], 2),
        _createElementVNode("div", _hoisted_27, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.legendLabels, (l, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `legend-${i}`,
              class: ""
            }, [
              _createElementVNode("span", _hoisted_28, _toDisplayString(l.short), 1),
              _createTextVNode(_toDisplayString(` - ${l.long}`), 1)
            ]))
          }), 128))
        ])
      ])
    ]),
    _createVNode(_component_Dialog, {
      class: "custom-dialog",
      modal: true,
      visible: _ctx.showUploadDialog,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showUploadDialog) = $event)),
      onHide: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showUploadDialog = false))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ListUploadDialog, {
          pricePeriods: _ctx.priceList.periods,
          pricePeriod: _ctx.pricePeriod,
          weekend: _ctx.isWeekendSelected,
          onSuccess: _ctx.onListUploadSuccess
        }, null, 8, ["pricePeriods", "pricePeriod", "weekend", "onSuccess"])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}