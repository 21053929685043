
import { PropType, defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import { clone } from 'lodash';
import { mapState } from 'vuex';
import {
  POS_TERMINAL_MODEL_DATA,
  PosTerminal,
  PosTerminalModelData,
} from '@/models/posTerminal';
import { SectorHeader } from '@/models/sector';
import { AppProgressDialog } from '@/models/app';
import { ApiStripeCreatePaymentIntentPayload } from '@/models/api';
import {
  StripePaymentIntent,
  StripePaymentIntentCreate,
} from '@/models/stripe';
import stripeService from '@/services/stripeService';

interface PosTerminalPickerFormModel {
  posTerminal: PosTerminal | null;
}

interface PosTerminalOption {
  label: string;
  terminals: Array<PosTerminal>;
}

const DEFAULT_POS_TERMINAL_PICKER_FORM_MODEL = {
  posTerminal: null,
} as PosTerminalPickerFormModel;

export default defineComponent({
  name: 'PosTerminalPayment',
  components: { Form, Field },
  emit: [
    'picker',
    'before-intent',
    'after-intent',
    'success',
    'failure',
    'exit',
    'close',
  ],
  props: {
    payload: {
      type: Object as PropType<Partial<ApiStripeCreatePaymentIntentPayload>>,
      required: true,
    },
    sector: {
      type: Object as PropType<SectorHeader>,
      required: false,
    },
    pollingInterval: {
      type: Number,
      required: false,
      default: 2000,
    },
    pollingIterations: {
      type: Number,
      required: false,
      default: 60,
    },
  },
  data() {
    return {
      showPosTerminalPickerDialog: false,
      posTerminalPickerFormModel: {} as PosTerminalPickerFormModel,
      posTerminalPickerValidationSchema: yup.object({
        posTerminal: yup.object().required(),
      }),
      currentPollingIterations: 0,
      paymentIntent: null as unknown as StripePaymentIntentCreate,
      poller: 0,
    };
  },
  methods: {
    async getPosTerminals(): Promise<void> {
      /**
       * Refresh store pos terminals
       */
      try {
        await this.$store.dispatch('session/setPosTerminals');
      } catch (error) {
        this.$spiagge.toast.error(
          this.$t('posTerminalPayment.toast.retrievalError'),
        );
      }
    },
    async createPaymentIntent(): Promise<void> {
      /**
       * Create payment intent
       */
      this.$emit('before-intent');

      // wait for reservation id
      while (!this.payload?.reservation) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((r) => setTimeout(r, 500));
      }

      try {
        this.paymentIntent = await stripeService.createPaymentIntent({
          ...{
            terminal: (
              this.posTerminalPickerFormModel.posTerminal as PosTerminal
            ).posIdentifier,
          },
          ...this.payload,
        } as ApiStripeCreatePaymentIntentPayload);
        this.$emit('after-intent');
        this.pollingStart();
      } catch (error) {
        this.$spiagge.toast.error(
          this.$t('posTerminalPayment.toast.operationError'),
        );
      }
    },
    async onPosTerminalPayment(): Promise<void> {
      /**
       * Init pos terminal payment flow
       */
      // check terminals
      if (this.posTerminalsAvailable.length === 0) {
        this.$spiagge.toast.warn(this.$t('posTerminalPayment.toast.posError'));
        return;
      }

      // amount 0
      if (!this.payload.amount) {
        this.$spiagge.toast.warn(
          this.$t('posTerminalPayment.toast.amountError'),
        );
        return;
      }

      // if pos terminals > 1 open the picker dialog, otherwise go to next step
      if (this.posTerminalsAvailable.length > 1) {
        this.showPosTerminalPickerDialog = true;
        this.$emit('picker');
      } else {
        this.posTerminalPickerFormModel.posTerminal = clone(
          this.posTerminalsAvailable[0],
        );
        this.createPaymentIntent();
      }
    },
    onPosTerminalPick(): void {
      /**
       * Pos terminal selected
       */
      this.createPaymentIntent();
    },
    onAbort(): void {
      /**
       * Close picker dialog
       */
      this.reset();
    },
    pollingStart(): void {
      /**
       * Polling start
       */
      // set progress dialog
      this.$store.commit('app/setProgressDialog', {
        title: this.$t('posTerminalPayment.progressDialogTitle'),
        content: this.$t('posTerminalPayment.progressDialogContent'),
        actions: [
          {
            label: this.$t('posTerminalPayment.ignoreClose'),
            classes: ['p-button-danger'],
            icon: '',
            callback: () => {
              // log forced quit
              this.$emit('exit');
              this.reset();
            },
          },
        ],
      } as AppProgressDialog);

      // start poller iterations
      this.currentPollingIterations = 0;
      this.poller = setInterval(async () => {
        // stop polling
        if (this.currentPollingIterations === this.pollingIterations) {
          // KO
          this.$emit('failure');
          this.reset();
          return;
        }

        // get stripe payment intent
        const stripePaymentIntent: StripePaymentIntent =
          await stripeService.getPaymentIntent(this.paymentIntent.id);

        // check payment intent status
        if (stripePaymentIntent.completed === 1) {
          // OK
          this.$emit('success', stripePaymentIntent);
          this.reset();
          return;
        }

        // next iteration
        this.currentPollingIterations += 1;
      }, this.pollingInterval);
    },
    reset(): void {
      /**
       * Reset component
       */
      this.$emit('close');
      this.showPosTerminalPickerDialog = false;
      this.posTerminalPickerFormModel = clone(
        DEFAULT_POS_TERMINAL_PICKER_FORM_MODEL,
      );
      this.currentPollingIterations = 0;
      this.paymentIntent = null as unknown as StripePaymentIntent;
      this.$store.commit('app/setProgressDialog', null);
      if (this.poller > 0) {
        clearInterval(this.poller);
      }
    },
  },
  computed: {
    ...mapState('session', ['license', 'posTerminals', 'permission']),
    posTerminalOptions(): Array<PosTerminalOption> {
      /**
       * Pos terminal options grouped by model and filtered by disabled flag
       */
      const posTerminalOptions = [] as Array<PosTerminalOption>;
      POS_TERMINAL_MODEL_DATA.forEach(
        (posTerminalData: PosTerminalModelData) => {
          posTerminalOptions.push({
            label: `Terminali ${posTerminalData.name}`,
            terminals: this.posTerminalsAvailable.filter(
              (posTerminal: PosTerminal) =>
                posTerminal.model === posTerminalData.model,
            ),
          });
        },
      );

      return posTerminalOptions;
    },
    posTerminalsAvailable(): Array<PosTerminal> {
      return this.posTerminals.filter(
        (posTerminal: PosTerminal) =>
          !posTerminal.disabled &&
          (this.sector && this.sector.id !== 0
            ? posTerminal.sectors.find(
                (s: SectorHeader) => s.id === this.sector?.id,
              )
            : true),
      );
    },
  },
});
