/* eslint-disable import/no-dynamic-require */
/* eslint-disable import/no-cycle */
import isNil from 'lodash/isNil';
import merge from 'lodash/merge';
import { DateTime } from 'luxon';
import {
  MapElementRaw,
  MapDecoration,
  MapDecorationType,
  MapElementTypeRaw,
  MapDecorationOrientation,
  MapSpot,
} from '@/models/map';
import { ReservationHalfDay, ReservationStatus } from '@/models/reservation';
import store from '@/store';
import { SpotAttribute, SpotAttributesPayload, SpotType } from '@/models/spot';
import { StyleSpotAttribute } from '@/models/style';
import { colorSpecs } from '@/models/color';
import { Staging } from '@/models';
import globalUtils from '@/utils';

export default {
  /**
   * Convert a raw element into formatted decoration
   * @param {MapElementRaw} rawElement
   * @return {MapDecoration}
   */
  decorationDeserialize(rawElement: MapElementRaw): MapDecoration {
    const decoration = {} as MapDecoration;

    try {
      decoration.name = rawElement.n || '';
      decoration.posX = rawElement.pX;
      decoration.posY = rawElement.pY;
      decoration.height =
        rawElement.eh && rawElement.eh > 0 ? rawElement.eh : 1;
      decoration.width = rawElement.ew && rawElement.ew > 0 ? rawElement.ew : 1;
      decoration.rotation = rawElement.er || 0;
      decoration.orientation = MapDecorationOrientation.NONE;
      decoration.backgroundColor = '';
      decoration.backgroundUrl = rawElement.url || '';

      if (rawElement.t === MapElementTypeRaw.ELEMENT) {
        decoration.type = rawElement.et as unknown as MapDecorationType;
        if (decoration.type !== MapDecorationType.CUSTOM) {
          decoration.backgroundUrl = require(`@/assets/images/map/decorations/${rawElement.et}.svg`);
        }
      }

      if (rawElement.t === MapElementTypeRaw.FOOTBOARD) {
        decoration.type = MapDecorationType.FOOTBOARD;
        decoration.orientation =
          rawElement.fo === 'v'
            ? MapDecorationOrientation.VERTICAL
            : MapDecorationOrientation.HORIZONTAL;
        decoration.backgroundUrl = require(`@/assets/images/map/decorations/footboard-${decoration.orientation}.svg`);
      }

      if (rawElement.t === MapElementTypeRaw.MAP_LINE_H) {
        decoration.type = MapDecorationType.LINE;
        decoration.orientation = MapDecorationOrientation.HORIZONTAL;
        decoration.backgroundColor = rawElement.bgc || '';
      }

      if (rawElement.t === MapElementTypeRaw.MAP_LINE_V) {
        decoration.type = MapDecorationType.LINE;
        decoration.orientation = MapDecorationOrientation.VERTICAL;
        decoration.backgroundColor = rawElement.bgc || '';
      }

      if (rawElement.t === MapElementTypeRaw.MAP_LABEL) {
        decoration.type = MapDecorationType.LABEL;
        decoration.name = rawElement.mlt || '';
      }
    } catch (e) {
      // console.log(e);
    }
    return decoration;
  },
  /**
   * Convert a raw element into formatted spot
   * @param {MapElementRaw} rawElement
   * @return {MapSpot}
   */
  spotDeserialize(
    rawElement: MapElementRaw,
    startDateRef: DateTime,
    endDateRef: DateTime,
  ): MapSpot {
    const spot = {} as MapSpot;
    try {
      spot.type = rawElement.t as unknown as SpotType;
      spot.name = rawElement.n;
      spot.posX = rawElement.pX;
      spot.posY = rawElement.pY;
      spot.width = 1;
      spot.height = 1;
      spot.id = rawElement.rid || null;
      spot.customerName = rawElement.cn || '';

      // staging
      if (rawElement.rmb || rawElement.rb || rawElement.rc || rawElement.rdc) {
        spot.staging = {
          maxiBeds: rawElement.rmb || 0,
          beds: rawElement.rb || 0,
          chairs: rawElement.rc || 0,
          deckChairs: rawElement.rdc || 0,
        } as Staging;
      } else {
        spot.staging = null;
      }

      spot.status = isNil(rawElement.rs)
        ? ReservationStatus.AVAILABLE
        : rawElement.rs;

      if (rawElement.rid) {
        spot.endDate = rawElement.red || null;
      } else {
        // spot free but has a future reservation
        spot.endDate = rawElement.rsd || null;
      }

      spot.startDate = rawElement.rsd || null;
      spot.endDate = rawElement.red || null;

      spot.halfDay = rawElement.rhd || ReservationHalfDay.DAILY;
      spot.fullDay = rawElement.rfd || false;
      spot.paid = rawElement.rp || false;
      spot.servicesIcon =
        rawElement.rsv?.map((service: string) =>
          require(`@/assets/images/extra-services/${service}`),
        ) || [];
      spot.locked = rawElement.rl || false;
      spot.overbooking = rawElement.ob || false;
      spot.sharing = rawElement.sh || false;
      spot.seasonal = rawElement.rse || false;
      spot.voucher = rawElement.rv || 0;
      spot.notes = rawElement.rn || '';

      const spotAttributesPayload = {
        free: !spot.id,
        temporary: spot.status === ReservationStatus.NOT_CONFIRMED,
        seasonal: spot.seasonal,
        halfDay: spot.halfDay,
        fullDay: spot.fullDay,
        sharing: spot.sharing,
        overbooking: spot.overbooking,
        paid: spot.paid,
        startDateRef,
        endDateRef,
      } as SpotAttributesPayload;

      if (spot.startDate) {
        spotAttributesPayload.startDate = DateTime.fromSeconds(
          spot.startDate,
        ).startOf('day');
      }
      if (spot.endDate) {
        spotAttributesPayload.endDate = DateTime.fromSeconds(
          spot.endDate,
        ).startOf('day');
      }

      const attributes = globalUtils.spotAttributes(spotAttributesPayload);

      spot.attributes = attributes;

      const spotStyle = store.getters['style/spot'];

      const attributeStyle = {} as StyleSpotAttribute;
      attributes.map((attribute: SpotAttribute) => {
        merge(attributeStyle, spotStyle[attribute]);
      });

      spot.background =
        rawElement.bgc && colorSpecs.has(rawElement.bgc) ? rawElement.bgc : '';

      // override spot background if not overbooking/unpaid last day
      if (
        spot.background &&
        !attributes.includes(SpotAttribute.OVERBOOKING) &&
        !attributes.includes(SpotAttribute.UNPAID_LAST_DAY)
      ) {
        const colorSpec = colorSpecs.get(spot.background);
        if (colorSpec) {
          attributeStyle.css.backgroundColor = colorSpec.background;
        }
      }

      spot.style = attributeStyle as StyleSpotAttribute;
    } catch (e) {
      // console.log(e);
    }
    return spot;
  },
};
