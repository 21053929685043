import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-06f0e0d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "map-modes",
  class: "map-control"
}
const _hoisted_2 = { class: "modes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.canAddUpdateReservation())
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (
          _ctx.permissionsUtil.isActionPermissionAllowed(
            _ctx.FEATURE_PERMISSION_CONFIG.reservations,
            _ctx.FEATURE_PERMISSION_ACTION_CONFIG.reservations.MERGE_BILL,
          )
        )
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                class: _normalizeClass(["p-button-white mode", 
          _ctx.mode === _ctx.mapMode.MULTIPLE_SELECTION || _ctx.mode === _ctx.mapMode.COPY
            ? 'active'
            : ''
        ]),
                icon: "spi-multiple-selection",
                label: _ctx.$t('mapModes.multipleSelection'),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (
          _ctx.mode =
            _ctx.mode === _ctx.mapMode.MULTIPLE_SELECTION
              ? _ctx.mapMode.NONE
              : _ctx.mapMode.MULTIPLE_SELECTION
        ))
              }, null, 8, ["class", "label"]))
            : _createCommentVNode("", true),
          _createVNode(_component_Button, {
            class: _normalizeClass(["p-button-white mode", _ctx.mode === _ctx.mapMode.SHIFT ? 'active' : '']),
            icon: "spi-move",
            label: _ctx.$t('mapModes.reservationShift'),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.mode = _ctx.mode === _ctx.mapMode.SHIFT ? _ctx.mapMode.NONE : _ctx.mapMode.SHIFT))
          }, null, 8, ["class", "label"])
        ])
      ]))
    : _createCommentVNode("", true)
}