/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { isError } from 'lodash';
import {
  Error,
  ErrorAxiosData,
  ErrorJavascriptData,
  ErrorLog,
  ErrorSpiaggeData,
} from '@/models/error';
import i18n from '@/i18n';

function isApiError(error: any): error is {
  // eslint-disable-next-line camelcase
  response: {
    data: {
      // eslint-disable-next-line camelcase
      internal_error_code: string | undefined;
      // eslint-disable-next-line camelcase
      error_ex: {
        message: string;
      };
    };
  };
} {
  return typeof error?.response?.data?.error_ex?.message === 'string';
}

export default {
  /**
   * Check if error is an axios error
   * @param e
   * @returns
   */
  isAxiosError(e: any): boolean {
    return e.isAxiosError;
  },
  /**
   * Check if error is a javascript error
   */
  isJavascriptError(e: any): boolean {
    return isError(e);
  },
  /**
   * Check if error is a spiagge error
   */
  isSpiaggeError(e: any): boolean {
    if (!this.isAxiosError) return false;
    const errorData = e.response?.data;
    return (
      errorData && ['error', 'error_ex', 'result'].every((k) => k in errorData)
    );
  },
  /**
   * See i18n.errors
   * @param e Get error code by error
   * @returns errorCode string
   */
  getErrorCode(e: any): string {
    let errorCode = 'default';
    /* JAVASCRIPT ERROR */
    if (this.isJavascriptError(e)) {
      errorCode = `js_${e.name}`;
    }
    /* AXIOS ERROR */
    if (this.isAxiosError(e)) {
      errorCode = `axios_${e.response.status.toString()}`;
    }
    /* SPIAGGE ERROR */
    if (this.isSpiaggeError(e)) {
      const spiaggeErrorCode: string = e.response.data.error;
      if (spiaggeErrorCode && i18n.global.te(`errors.${spiaggeErrorCode}`)) {
        // error can be a specific code or a message
        errorCode = spiaggeErrorCode;
      }
    }
    return errorCode;
  },
  /**
   * Get error data
   * @param e any
   * @returns error Error
   */
  getError(e: any): Error {
    /* ERROR CODE */
    const errorCode = this.getErrorCode(e);
    /* ERROR */
    const error = {
      code: errorCode,
      message: i18n.global.te(`errors.${errorCode}`)
        ? i18n.global.t(`errors.${errorCode}`)
        : i18n.global.t('errors.default'),
    } as Error;
    /* JAVASCRIPT DATA */
    if (this.isJavascriptError(e)) {
      error.description = e.message;
      error.data = {
        stack: e.stack,
        fileName: e.fileName, // firefox
        lineNumber: e.lineNumber, // firefox
      } as ErrorJavascriptData;
    }
    /* AXIOS DATA */
    if (this.isAxiosError(e)) {
      error.description = `${e.response.status.toString()}-${
        e.response.statusText
      }`;
      error.data = {
        request: e.request,
        response: e.response,
      } as ErrorAxiosData;
    }
    /* SPIAGGE DATA */
    if (this.isSpiaggeError(e)) {
      error.description =
        error.code !== 'default'
          ? e.response.data.error_ex.message
          : e.response.data.error;
      error.data = {
        errors: e.response.data.error_ex.errors ?? [],
        file: e.response.data.error_ex.file,
        line: e.response.data.error_ex.line,
      } as ErrorSpiaggeData;
    }
    return error;
  },
  /**
   * Display error as toast
   * @param vm
   * @param e
   * @param customMessage
   * @param silent
   * @param log
   */
  handle(
    e: any,
    fallbackError = '',
    silent = false,
    log: Array<ErrorLog> = [ErrorLog.CONSOLE],
  ): void {
    const error: Error = this.getError(e);
    /* If fallback error is provided check for error code or use as message */
    /**
     * Priority:
     * 1 - errors.fallbackError (search on global errors)
     * 2 - *.fallbackError (search on specific path)
     * 3 - no match, use fallbackError as message
     */
    if (fallbackError) {
      error.message = i18n.global.te(`errors.${fallbackError}`)
        ? i18n.global.t(`errors.${fallbackError}`)
        : i18n.global.te(`${fallbackError}`)
        ? i18n.global.t(`${fallbackError}`)
        : fallbackError;
    }
    /* If log on console is enabled and isn't production environment */
    if (
      log.includes(ErrorLog.CONSOLE) &&
      process.env.NODE_ENV !== 'production'
    ) {
      console.log(error);
    }
    /* Display toast if not silent */
    if (!silent) {
      window.toast('error', error.message);
    }
  },
  getErrorMessage(error: unknown) {
    return isApiError(error)
      ? error.response.data.error_ex.message
      : i18n.global.t('toast.error.msg');
  },
  getInternalErrorCode(error: unknown) {
    return isApiError(error) ? error.response.data.internal_error_code : null;
  },
};
