
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['sending-file', 'clear'],
  name: 'UploadImg',
  props: {
    file: null,
    fileExtension: {
      type: String,
      require: false,
      default: 'image/*',
    },
    maxSize: {
      type: Number,
      require: false,
      default: 1000000,
    },
    urlImg: {
      type: String,
      require: false,
      default: '',
    },
    nameFile: {
      type: String,
      require: false,
      default: '',
    },
  },
  data() {
    return {
      uploaded: false,
      localFile: this.file,
      showImg: true,
    };
  },
  methods: {
    onUpload() {
      this.uploaded = true;
    },
    onClose(): void {
      this.uploaded = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSelect(event: any): void {
      this.showImg = false;
      this.$emit('sending-file', event.files[0]);
    },
  },
  computed: {
    show(): boolean {
      return this.showImg;
    },
  },
});
