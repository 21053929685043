/**
 * Beach service's api service
 */

import axios, { AxiosResponse } from 'axios';
import {
  ApiServiceCreatePayload,
  ApiServiceCreateResponse,
  ApiServiceDeleteResponse,
  ApiServiceListResponse,
  ApiServiceUpdatePayload,
  ApiServiceUpdateResponse,
} from '@/models/api';
import { Service } from '@/models/service';
import apiService from '@/services/apiService';
import {
  ServiceAvailabilityPayload,
} from '@/models/api/request/service/serviceAvailabilityPayload';
import {
  ServiceAvailabilityResponse,
} from '@/models/api/response/service/serviceAvailabilityResponse';
import { ServiceInformation } from '@/models/service/serviceInformation';

export default {
  async list(): Promise<Array<Service>> {
    const res: AxiosResponse<ApiServiceListResponse> = await axios.get(
      apiService.service.list(),
    );
    return res.data.result.beachServices;
  },
  async update(id: number, payload: ApiServiceUpdatePayload): Promise<Service> {
    const res: AxiosResponse<ApiServiceUpdateResponse> = await axios.patch(
      apiService.service.update(id),
      payload,
    );
    return res.data.result.beachService;
  },
  async create(payload: ApiServiceCreatePayload): Promise<Service> {
    const res: AxiosResponse<ApiServiceCreateResponse> = await axios.post(
      apiService.service.create(),
      payload,
    );
    return res.data.result.beachService;
  },
  async delete(id: number): Promise<string> {
    const res: AxiosResponse<ApiServiceDeleteResponse> = await axios.delete(
      apiService.service.delete(id),
    );
    return res.data.result.status;
  },
  async availability(payload: ServiceAvailabilityPayload): Promise<ServiceInformation> {
    const res: AxiosResponse<ServiceAvailabilityResponse> = await axios.post(
        apiService.service.availability(),
        payload,
    );
    return res.data.result.availabilities;
  },
};
