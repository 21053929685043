
import { defineComponent, WatchStopHandle } from 'vue';
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import priceListService from '@/services/priceListService';
import { Voucher, DefaultVoucher } from '@/models/voucher';
import { PriceList } from '@/models/priceList';
import { ColorName, VOUCHER_BG_COLORS } from '@/models/color';
import { ListElementString } from '@/models';

export default defineComponent({
  name: 'VoucherDetails',
  emits: ['saveVoucher', 'deleteVoucher'],
  props: {
    mode: {
      type: String,
      default: 'edit',
    },
    voucherRef: {
      type: Object as () => Voucher,
    },
  },
  data() {
    return {
      defaultHotelPriceListId: 0,
      defaultCustomerPriceListId: 0,
      voucher: this.voucherRef
        ? cloneDeep(this.voucherRef)
        : this.getNewVoucher(),
      priceLists: [] as Array<PriceList>,
      mapBackgroundColor: VOUCHER_BG_COLORS.map((name: ColorName | null) => {
        if (name) {
          return {
            name: this.$t(`color.${name.toLowerCase()}`),
            code: name,
          } as ListElementString;
        }
      }),
      onlineStatus: [
        { label: this.$t('voucherDetails.online'), value: true },
        { label: this.$t('voucherDetails.offline'), value: false },
      ],
      voucherRefWatcher: null as unknown as WatchStopHandle,
    };
  },
  computed: {
    ...mapState('session', ['license']),

    isNew(): boolean {
      return !this.voucher.id;
    },
  },
  methods: {
    initVoucher(): void {
      this.voucher = this.voucherRef
        ? cloneDeep(this.voucherRef)
        : this.getNewVoucher();
    },
    getNewVoucher(): DefaultVoucher {
      return {
        id: null,
        onlineDiscount: 0,
        hotelPriceListId: this.defaultHotelPriceListId,
        customerPriceListId: this.defaultCustomerPriceListId,
        onlineMode: true,
        onlinePayment: true,
      } as DefaultVoucher;
    },
    async onDeleteVoucher(): Promise<void> {
      this.$emit('deleteVoucher');
    },
    async onFormSubmit(): Promise<void> {
      this.$emit('saveVoucher', this.voucher);
    },
  },
  async beforeMount() {
    try {
      this.priceLists = await priceListService.find({});
      if (this.priceLists.length > 0) {
        this.defaultHotelPriceListId = this.priceLists[0].id;
        this.defaultCustomerPriceListId = this.priceLists[0].id;
      }
      this.initVoucher();
      this.voucherRefWatcher = this.$watch('voucherRef', () => {
        this.initVoucher();
      });
      // eslint-disable-next-line no-restricted-syntax
    } catch (error) {
      // console.log(error);
    }
  },
  beforeUnmount(): void {
    this.voucherRefWatcher();
  },
});
