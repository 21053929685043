
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import { clone } from 'lodash';
import { mapGetters } from 'vuex';
import StatisticsCard from '@/components/statistics/StatisticsCard.vue';
import {
  StatisticCard as StatisticCardI,
  StatisticDataTableColumn,
  StatisticDataTableColumnIcon,
  StatisticDataTableRow,
  StatisticDataTableView,
} from '@/models/statistic';
import StatisticsDataTable from '@/components/statistics/StatisticsDataTable.vue';
import statisticService from '@/services/statisticService';
import { ApiStatisticsStripePaymentsResponse } from '@/models/api';
import { SpotInfo, SPOT_INFOS } from '@/models/spot';
import Slider from '@/components/shared/Slider.vue';
import { StripePaymentIntentSourceAccountType } from '@/models/stripe';

export default defineComponent({
  name: 'StatisticsStripePaymentsV2View',
  components: { StatisticsCard, StatisticsDataTable, Slider },
  data() {
    return {
      id: 'stripe-payments-new',
      endpoint: statisticService.stripePaymentsV2,
      exportEndpoint: statisticService.exportStripePaymentsV2,
      columns: [
        {
          label: this.$t('statisticsStripePaymentsView.column.spotType'),
          field: 'spotType',
          sortable: true,
          formatter: (value: string) =>
            SPOT_INFOS.find((spot: SpotInfo) => spot.type === value)?.label,
        },
        {
          label: this.$t('statisticsStripePaymentsView.column.spotName'),
          field: 'spotName',
          sortable: true,
        },
        {
          label: this.$t('statisticsStripePaymentsView.column.firstName'),
          field: 'firstName',
          sortable: true,
        },
        {
          label: this.$t('statisticsStripePaymentsView.column.lastName'),
          field: 'lastName',
          sortable: true,
        },
        {
          label: this.$t('statisticsStripePaymentsView.column.updatedAt'),
          field: 'updatedAt',
          sortable: true,
          formatter: (value: number) =>
            this.$spiagge.date.fromMillis(value, 'dd-MM-yyyy HH:mm'),
        },
        {
          label: this.$t('statisticsStripePaymentsView.column.reservationId'),
          field: 'reservationId',
          sortable: true,
        },
        {
          label: this.$t('statisticsStripePaymentsView.column.amount'),
          field: 'amount',
          sortable: true,
          // eslint-disable-next-line no-confusing-arrow
          formatter: (value: string, row: StatisticDataTableRow) =>
            row.isJointAccountPart ? '-' : `${value} €`,
        },
        {
          label: this.$t(
            'statisticsStripePaymentsView.column.portalPaymentFees',
          ),
          field: 'portalPaymentFees',
          sortable: true,
          // eslint-disable-next-line no-confusing-arrow
          formatter: (value: string) =>
            value === 'unknown'
              ? this.$t('statisticsStripePaymentsView.column.notEvaluable')
              : `${value} €`,
        },
        {
          label: this.$t(
            'statisticsStripePaymentsView.column.portalPaymentFeesWithoutVat',
          ),
          field: 'portalPaymentFeesWithoutVat',
          sortable: true,
          // eslint-disable-next-line no-confusing-arrow
          formatter: (value: string) =>
            value === 'unknown' ? 'Non calcolabile' : `${value} €`,
        },
        {
          label: this.$t(
            'statisticsStripePaymentsView.column.portalPaymentFeesVatPercentage',
          ),
          field: 'portalPaymentFeesVatPercentage',
          formatter: (value: string) => `${value} %`,
        },
        {
          label: this.$t(
            'statisticsStripePaymentsView.column.onlinePaymentFees',
          ),
          field: 'onlinePaymentFees',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t(
            'statisticsStripePaymentsView.column.onlinePaymentFeesWithoutVat',
          ),
          field: 'onlinePaymentFeesWithoutVat',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t(
            'statisticsStripePaymentsView.column.onlinePaymentFeesVatPercentage',
          ),
          field: 'onlinePaymentFeesVatPercentage',
          formatter: (value: string) => `${value} %`,
        },
        {
          label: this.$t('statisticsStripePaymentsView.column.refund'),
          field: 'refund',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t('statisticsStripePaymentsView.column.refundType'),
          field: 'refundType',
          sortable: true,
        },
        {
          label: this.$t('statisticsStripePaymentsView.column.chargeStatus'),
          field: 'chargeStatus',
          sortable: true,
          // eslint-disable-next-line no-confusing-arrow
          formatter: (value: string) =>
            value === 'succeeded' ? 'Completato' : value,
        },
        {
          label: this.$t('statisticsStripePaymentsView.column.channel'),
          field: 'channel',
          sortable: true,
        },
        {
          label: this.$t(
            'statisticsStripePaymentsView.column.sourceAccountType',
          ),
          field: 'sourceAccountType',
          sortable: false,
          // eslint-disable-next-line no-confusing-arrow
          formatter: (value: StripePaymentIntentSourceAccountType) =>
            value === StripePaymentIntentSourceAccountType.ONLINE_TERMINAL
              ? this.$t('statisticsStripePaymentsView.online')
              : this.$t('statisticsStripePaymentsView.offline'),
        },
      ] as Array<StatisticDataTableColumn>,
      icons: [
        {
          label: this.$t('statisticsStripePaymentsView.refunded'),
          icon: 'spi-refund',
          show: (row: { [key: string]: number }) => {
            try {
              return row.refund > 0;
            } catch (e) {
              return false;
            }
          },
        },
        {
          label: this.$t('statisticsStripePaymentsView.transferred'),
          icon: 'spi-shift',
          show: (row: { [key: string]: boolean }) => {
            try {
              return row.isTransfered;
            } catch (e) {
              return false;
            }
          },
        },
        {
          label: this.$t('statisticsStripePaymentsView.jointAccountPart'),
          icon: 'spi-joint',
          show: (row: { [key: string]: number | string | boolean }) =>
            row.isJointAccountPart ?? false,
        },
        {
          label: this.$t('statisticsArrivalsDepartures.isTicket'),
          icon: 'spi-ticket',
          show: (row: { [key: string]: number | string | boolean }) =>
              row.isTicket ?? false,
        },
      ] as Array<StatisticDataTableColumnIcon>,
      basePayload: {
        sourceAccountType: StripePaymentIntentSourceAccountType.ONLINE_TERMINAL,
      },
      searchColumns: [],
      defaultView: {
        id: DateTime.now().startOf('day').toSeconds(),
        label: this.$t('statisticsStripePaymentsView.defaultViewLabel'),
        columns: [
          'spotType',
          'spotName',
          'firstName',
          'lastName',
          'reservationId',
          'amount',
          'channel',
          'sourceAccountType',
        ],
        orderBy: [],
      } as StatisticDataTableView,
      topCards: [
        {
          label: this.$t('statisticsStripePaymentsView.chargesTotal'),
          value: null,
          subValue: this.$t('statisticsStripePaymentsView.vatIncluded'),
        },
      ] as Array<StatisticCardI>,
      bottomCards: [
        {
          label: this.$t('statisticsStripePaymentsView.portalFeesTotal'),
          value: null,
          subValue: this.$t('statisticsStripePaymentsView.vatIncluded'),
        },
        {
          label: this.$t('statisticsStripePaymentsView.stripeFeesTotal'),
          value: null,
          subValue: this.$t('statisticsStripePaymentsView.vatIncluded'),
        },
        {
          label: this.$t('statisticsStripePaymentsView.refundsTotal'),
          value: null,
          subValue: this.$t('statisticsStripePaymentsView.vatIncluded'),
        },
      ] as Array<StatisticCardI>,
      stripePaymentIntentSourceAccountType: clone(
        StripePaymentIntentSourceAccountType,
      ),
      sourceAccountTypeOptions: [
        {
          label: this.$t('statisticsStripePaymentsView.onlineTerminal'),
          value: StripePaymentIntentSourceAccountType.ONLINE_TERMINAL,
        },
        {
          label: this.$t('statisticsStripePaymentsView.physicalTerminal'),
          value: StripePaymentIntentSourceAccountType.PHYSICAL_TERMINAL,
        },
      ],
    };
  },
  beforeMount(): void {
    this.$store.commit(
      'statistic/setTitle',
      this.$t('statisticsStripePaymentsView.setTitle'),
    );
    this.$store.commit(
      'statistic/setSubtitle',
      this.$t('statisticsStripePaymentsView.setSubtitle'),
    );
  },
  methods: {
    onResult(res: ApiStatisticsStripePaymentsResponse): void {
      // totals
      this.topCards[0].value = `${res.result.cards.totalPayments.toString()}€`;
      this.bottomCards[0].value = `${res.result.cards.portalFees.toString()}€`;
      this.bottomCards[1].value = `${res.result.cards.stripeFees.toString()}€`;
      this.bottomCards[2].value = `${res.result.cards.totalRefunds.toString()}€`;
    },
    hasStripePosEnabled(): boolean {
      return this.license.stripePosTerminalEnabled;
    },
  },
  computed: {
    ...mapGetters('session', ['license']),
  },
});
