
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import { mapState } from 'vuex';
import StatisticsCard from '@/components/statistics/StatisticsCard.vue';
import {
  StatisticCard as StatisticCardI,
  StatisticDataTableColumn,
  StatisticDataTableView,
} from '@/models/statistic';
import StatisticsDataTable from '@/components/statistics/StatisticsDataTable.vue';
import statisticService from '@/services/statisticService';
import { ApiStatisticsStripeTransfersResponse } from '@/models/api';
import Slider from '@/components/shared/Slider.vue';
import { BookingPaymentInvoiceMode } from '@/models/booking';
import ShowInvoiceDialog from '@/components/shared/ShowInvoiceDialog/ShowInvoiceDialog.vue';

export default defineComponent({
  name: 'StatisticsStripeTransfersView',
  components: {
    StatisticsCard,
    StatisticsDataTable,
    Slider,
    ShowInvoiceDialog,
  },
  data() {
    return {
      id: 'stripe-transfers',
      endpoint: statisticService.stripeTransfers,
      exportEndpoint: statisticService.exportStripeTransfers,
      columns: [
        {
          label: this.$t('statisticsStripeTransfersView.column.date'),
          field: 'date',
          sortable: true,
          formatter: (value: number) => this.$spiagge.date.fromMillis(value),
        },
        {
          label: this.$t('statisticsStripeTransfersView.column.period'),
          field: 'period',
          sortable: true,
        },
        {
          label: this.$t('statisticsStripeTransfersView.column.chargesTotal'),
          field: 'chargesTotal',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t('statisticsStripeTransfersView.column.transferTotal'),
          field: 'transferTotal',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t('statisticsStripeTransfersView.column.refundsTotal'),
          field: 'refundsTotal',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t('statisticsStripeTransfersView.column.portalFees'),
          field: 'portalFees',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t('statisticsStripeTransfersView.column.stripeFees'),
          field: 'stripeFees',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t(
            'statisticsStripeTransfersView.column.compensationTotal',
          ),
          field: 'compensationTotal',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
      ] as Array<StatisticDataTableColumn>,
      basePayload: {},
      searchColumns: [],
      defaultView: {
        id: DateTime.now().startOf('day').toSeconds(),
        label: this.$t('statisticsStripeTransfersView.defaultViewLabel'),
        columns: [
          'date',
          'period',
          'chargesTotal',
          'transferTotal',
          'portalFees',
          'stripeFees',
          'refundsTotal',
          'compensationTotal',
        ],
        orderBy: [],
      } as StatisticDataTableView,
      topCards: [
        {
          label: this.$t('statisticsStripeTransfersView.transferTotal'),
          value: null,
          subValue: this.$t('statisticsStripeTransfersView.vatIncluded'),
        },
      ] as Array<StatisticCardI>,
      bottomCards: [
        {
          label: this.$t('statisticsStripeTransfersView.portalFeesTotal'),
          value: null,
          subValue: this.$t('statisticsStripeTransfersView.vatIncluded'),
        },
        {
          label: this.$t('statisticsStripeTransfersView.stripeFeesTotal'),
          value: null,
          subValue: this.$t('statisticsStripeTransfersView.vatIncluded'),
        },
        {
          label: this.$t('statisticsStripeTransfersView.refundsTotal'),
          value: null,
          subValue: this.$t('statisticsStripeTransfersView.vatIncluded'),
        },
        {
          label: this.$t('statisticsStripeTransfersView.compensationTotal'),
          value: null,
          subValue: this.$t('statisticsStripeTransfersView.vatIncluded'),
        },
      ] as Array<StatisticCardI>,
      openInvoiceDialog: false as boolean,
      selectedInvoiceId: null as number | null | undefined,
    };
  },
  beforeMount(): void {
    this.$store.commit(
      'statistic/setTitle',
      this.$t('statisticsStripeTransfersView.setTitle'),
    );
    this.$store.commit(
      'statistic/setSubtitle',
      this.$t('statisticsStripeTransfersView.setSubtitle'),
    );
    // default values of dates filter
    const currentYear = DateTime.now().year;
    const defaultStartDate = DateTime.utc(currentYear, 1, 1, 0, 0, 0, 0);
    const defaultEndDate = DateTime.utc(currentYear, 12, 31, 0, 0, 0, 0);
    this.$store.commit('statistic/setCalendar', [
      defaultStartDate,
      defaultEndDate,
    ]);
  },
  methods: {
    onResult(res: ApiStatisticsStripeTransfersResponse): void {
      // top cards
      this.topCards[0].value = `${res.result.cards.transferTotal.toString()}€`;
      // bottom cards
      this.bottomCards[0].value = `${res.result.cards.portalFees.toString()}€`;
      this.bottomCards[1].value = `${res.result.cards.stripeFees.toString()}€`;
      this.bottomCards[2].value = `${res.result.cards.refundsTotal.toString()}€`;
      this.bottomCards[3].value = `${res.result.cards.compensationTotal.toString()}€`;
    },
    rowClick(transferEvent: {
      data: {
        id: number;
        licenseId: number;
        invoiceXml: string;
        mode: number;
      };
    }) {
      if (
        this.license.bookingPaymentInvoiceMode ===
          BookingPaymentInvoiceMode.THIRD_PARTY &&
        transferEvent.data.mode === 2
      ) {
        // Third party management
        this.manageThirdPartyInvoiceShow(transferEvent);
        return;
      }
      // Return if booking payment invoice mode own
      if (
        this.license.bookingPaymentInvoiceMode === BookingPaymentInvoiceMode.OWN
      ) {
        return;
      }
      let link =
        'https://legacy.spiagge.it/app/yourbeach/transfer-invoice-preview?';
      link += `transferId=${transferEvent.data.id}`;
      link += `&w=${this.license.license}`;
      link += `&mode=${transferEvent.data.mode}`;
      if (transferEvent.data.invoiceXml) {
        if (this.appPlatform && this.appPlatform === 'ios') {
          window.webkit.messageHandlers.openOnBrowser.postMessage(link);
        } else if (this.appPlatform && this.appPlatform === 'android') {
          window.Android.openOnBrowser(link);
        } else {
          window.open(link);
        }
      }
    },
    rowClass() {
      return 'row';
    },
    manageThirdPartyInvoiceShow(transferEvent: {
      data: {
        id: number;
        licenseId: number;
        invoiceXml: string;
      };
    }) {
      this.openInvoiceDialog = true;
      this.selectedInvoiceId = transferEvent.data.id;
    },
    onShowInvoiceDialogClose() {
      this.openInvoiceDialog = false;
      this.selectedInvoiceId = null;
    },
  },
  computed: {
    ...mapState('session', ['appPlatform', 'license']),
  },
});
