
import { defineComponent } from 'vue';
import { DataTableSortMeta } from 'primevue/datatable';
import { ToastSeverity } from '@/models/toast';
import toastUtil from '@/utils/toastUtil';
import {
  ApiReservationFindResponse,
  ApiReservationFindPayload,
  ApiReservationRecycleBinResponse,
} from '@/models/api';
import { Reservation, ReservationType } from '@/models/reservation';
import { User } from '@/models/user';
import reservationService from '@/services/reservationService';
import { DataTableRowSortPayload } from '@/models/dataTable';

export default defineComponent({
  data() {
    return {
      loading: true,
      online: null as boolean | null,
      selectFilter: false,
      currentPage: 0,
      reservationsLimit: 15,
      multiSortMeta: [] as Array<DataTableSortMeta>,
      orderBy: [
        {
          field: 'createdAt',
          value: 'desc',
        },
      ] as Array<DataTableRowSortPayload>,
      count: 0,
      id: [] as any,
      selectedReservations: null,
      reservationFilter: [],
      reservations: [] as Array<Reservation>,
      dataTableReservation: [
        this.$t('database.spotType'),
        this.$t('database.fullName'),
        this.$t('database.itemDetails'),
        this.$t('database.startDate'),
        this.$t('database.endDate'),
      ],
    };
  },
  methods: {
    async page(event: any) {
      this.runQuery(event.page);
    },

    async showOfflineBin() {
      this.online = false;
      this.runQuery(0);
      this.selectFilter = true;
    },
    async showOnlineBin() {
      this.online = true;
      this.runQuery(0);
      this.selectFilter = true;
    },
    async showAllBin() {
      this.online = null;
      this.runQuery(0);
      this.selectFilter = true;
    },
    async throwOutBin() {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const res: ApiReservationRecycleBinResponse =
          await reservationService.deleteAll();
        // TODO:: update Database after delete
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.dataBaseDeleteTitle'),
            this.$t('toast.save.dataBaseDeleteContent'),
          ),
        );
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
    onSpotType(element: Reservation) {
      switch (element.spotType) {
        case 'umbrella':
          return require('@/assets/images/map/umbrella/grey.svg');
        case 'bed':
          return require('@/assets/images/map/bed/grey.svg');
        case 'gazebo':
          return require('@/assets/images/map/gazebo/grey.svg');
        case 'cabins':
          return require('@/assets/images/map/cabins/grey.svg');
        case 'parking':
          return require('@/assets/images/map/parking/grey.svg');
        default:
          return require('@/assets/images/map/umbrella/grey.svg');
      }
    },
    onStatus(element: Reservation) {
      if (element.online) {
        return require('@/assets/iconset/world.svg');
      }
    },
    onRowClick(event: any): void {
      this.$router.push(`/reservation/${event.data.id}`);
    },
    onSort(e: { multiSortMeta: Array<DataTableSortMeta> }) {
      const order = [] as Array<DataTableRowSortPayload>;

      this.multiSortMeta = e.multiSortMeta;
      this.multiSortMeta.forEach((element: DataTableSortMeta) => {
        order.push({
          field: element.field,
          value:
            typeof element.order === 'number'
              ? element.order < 0
                ? 'desc'
                : 'asc'
              : 'asc',
        } as DataTableRowSortPayload);
      });

      this.orderBy =
        order.length > 0
          ? order
          : [
              {
                field: 'createdAt',
                value: 'desc',
              },
            ];
      // console.log(this.orderBy);
    },
    async runQuery(page: number) {
      this.currentPage = page;
      try {
        const payload = {
          orderBy: this.orderBy,
          page: this.currentPage,
          offset: this.reservationsLimit,
          type: this.typeList,
          deleted: true,
        } as ApiReservationFindPayload;

        if (this.selectFilter && this.online !== null) {
          payload.online = this.online;
        }
        const res: ApiReservationFindResponse = await reservationService.find(
          payload,
        );

        this.reservations = res.result.reservations;
        this.count = res.result.count;
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
  },
  computed: {
    user(): User | null {
      return this.$store.getters['session/user'];
    },
    typeList(): Array<string | ReservationType> {
      const types = Object.values(ReservationType).filter(
        (element: string | ReservationType) =>
          typeof element === 'number' && element !== ReservationType.ABSENCE,
      );
      return types;
    },
  },
  watch: {
    orderBy() {
      this.runQuery(this.currentPage);
    },
  },
  async created() {
    try {
      const res: ApiReservationFindResponse = await reservationService.find({
        orderBy: [
          {
            field: 'createdAt',
            value: 'desc',
          },
        ],
        page: 0,
        offset: this.reservationsLimit,
        type: this.typeList,
        deleted: true,
      } as ApiReservationFindPayload);

      this.reservations = res.result.reservations;
      this.count = res.result.count;
      this.loading = false;
    } catch (error) {
      this.$toast.add(
        toastUtil.build(
          ToastSeverity.ERROR,
          this.$t('toast.error.title'),
          this.$t('toast.error.msg'),
        ),
      );
    }
    this.selectFilter = true;
  },
});
