import axios, { AxiosResponse } from 'axios';

import apiService from './apiService';

import { ServiceGroup } from '@/models/serviceGroup/serviceGroup';

import {
    ApiServiceGroupListResponse,
} from '@/models/api/response/serviceGroup/serviceGroupListResponse';
import {
    ApiServiceGroupFindResponse,
} from '@/models/api/response/serviceGroup/serviceGroupFindResponse';
import {
    ApiServiceGroupUpdatePayload,
} from '@/models/api/request/serviceGroup/serviceGroupUpdatePayload';
import {
    ApiServiceGroupCreatePayload,
} from '@/models/api/request/serviceGroup/serviceGroupCreatePayload';

export default {
  // Get all sector groups for the current license
  async list(): Promise<ServiceGroup[]> {
    const res: AxiosResponse<ApiServiceGroupListResponse> = await axios.get(
      apiService.serviceGroup.list(true),
    );
    return res.data.result.serviceGroups;
  },
  // Create new service group
  async create(
    createServiceGroupPayload: ApiServiceGroupCreatePayload,
  ): Promise<ServiceGroup> {
    const res: AxiosResponse<ApiServiceGroupFindResponse> = await axios.post(
      apiService.serviceGroup.create(),
      createServiceGroupPayload,
    );
    return res.data.result.serviceGroup;
  },
  // Update a service group
  async update(
    serviceGroupId: number,
    updateServiceGroupPayload: ApiServiceGroupUpdatePayload,
  ): Promise<ServiceGroup> {
    const res: AxiosResponse<ApiServiceGroupFindResponse> = await axios.patch(
      apiService.serviceGroup.update(serviceGroupId),
      updateServiceGroupPayload,
    );
    return res.data.result.serviceGroup;
  },
  // Delete a service group (204 status code with empty body response)
  async delete(serviceGroupId: number): Promise<void> {
    await axios.delete(
      apiService.serviceGroup.delete(serviceGroupId),
    );
  },
};
