import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7246b75e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "statistics-extra-services",
  class: "py-4"
}
const _hoisted_2 = { class: "tab-menu" }
const _hoisted_3 = { class: "tab-menu-label" }
const _hoisted_4 = {
  key: 0,
  id: "stats-extra-services-list",
  class: "mb-3"
}
const _hoisted_5 = {
  key: 1,
  class: "table-wrapper"
}
const _hoisted_6 = {
  role: "table",
  id: "list-extra-services"
}
const _hoisted_7 = { role: "rowgroup" }
const _hoisted_8 = { role: "row" }
const _hoisted_9 = { role: "cell" }
const _hoisted_10 = { role: "cell" }
const _hoisted_11 = { role: "cell" }
const _hoisted_12 = { role: "rowgroup" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { role: "cell" }
const _hoisted_15 = { class: "extra-service-name" }
const _hoisted_16 = { role: "cell" }
const _hoisted_17 = { class: "font-bold white-space-nowrap" }
const _hoisted_18 = { role: "cell" }
const _hoisted_19 = { class: "white-space-nowrap" }
const _hoisted_20 = { role: "rowgroup" }
const _hoisted_21 = { role: "row" }
const _hoisted_22 = { role: "cell" }
const _hoisted_23 = { role: "cell" }
const _hoisted_24 = { class: "white-space-nowrap" }
const _hoisted_25 = { role: "cell" }
const _hoisted_26 = { class: "white-space-nowrap" }
const _hoisted_27 = {
  key: 1,
  id: "stats-extra-services-chart",
  class: "p-3 border-1 border-round mb-3"
}
const _hoisted_28 = { class: "tab-menu" }
const _hoisted_29 = { class: "tab-menu-label" }
const _hoisted_30 = {
  key: 0,
  id: "chart-extra-services-value"
}
const _hoisted_31 = {
  key: 1,
  id: "chart-extra-services-quantity"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TabMenu = _resolveComponent("TabMenu")!
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_Chart = _resolveComponent("Chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('statisticsExtraServicesView.view')), 1),
      _createVNode(_component_TabMenu, {
        model: _ctx.tabs,
        activeIndex: _ctx.selectedTab,
        "onUpdate:activeIndex": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTab) = $event))
      }, null, 8, ["model", "activeIndex"])
    ]),
    (_ctx.selectedTab === 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.loading)
            ? (_openBlock(), _createBlock(_component_Skeleton, {
                key: 0,
                width: "400px",
                class: "h-20rem"
              }))
            : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("table", _hoisted_6, [
                  _createElementVNode("thead", _hoisted_7, [
                    _createElementVNode("tr", _hoisted_8, [
                      _createElementVNode("th", _hoisted_9, _toDisplayString(_ctx.$t('statisticsExtraServicesView.listName')), 1),
                      _createElementVNode("th", _hoisted_10, _toDisplayString(_ctx.$t('statisticsExtraServicesView.listValue')), 1),
                      _createElementVNode("th", _hoisted_11, _toDisplayString(_ctx.$t('statisticsExtraServicesView.listQuantity')), 1)
                    ])
                  ]),
                  _createElementVNode("tbody", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elements, (element, index) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: index,
                        onClick: ($event: any) => (_ctx.openExtraServiceDetail(element.id)),
                        role: "row"
                      }, [
                        _createElementVNode("td", _hoisted_14, [
                          _createElementVNode("div", _hoisted_15, _toDisplayString(element.name), 1)
                        ]),
                        _createElementVNode("td", _hoisted_16, [
                          _createElementVNode("span", _hoisted_17, _toDisplayString(element.value) + " €", 1)
                        ]),
                        _createElementVNode("td", _hoisted_18, [
                          _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t('statisticsExtraServicesView.listPieces', {
                    n: element.quantity,
                  })), 1)
                        ])
                      ], 8, _hoisted_13))
                    }), 128))
                  ]),
                  _createElementVNode("tfoot", _hoisted_20, [
                    _createElementVNode("tr", _hoisted_21, [
                      _createElementVNode("td", _hoisted_22, _toDisplayString(_ctx.$t('statisticsExtraServicesView.listTotal')), 1),
                      _createElementVNode("td", _hoisted_23, [
                        _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.totalValue) + " €", 1)
                      ]),
                      _createElementVNode("td", _hoisted_25, [
                        _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.$t('statisticsExtraServicesView.listPieces', {
                    n: _ctx.totalQuantity,
                  })), 1)
                      ])
                    ])
                  ])
                ])
              ]))
        ]))
      : _createCommentVNode("", true),
    (_ctx.selectedTab === 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.$t('statisticsExtraServicesView.chartTitle')), 1),
            _createVNode(_component_TabMenu, {
              model: _ctx.chartTabs,
              activeIndex: _ctx.selectedChartTab,
              "onUpdate:activeIndex": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedChartTab) = $event))
            }, null, 8, ["model", "activeIndex"])
          ]),
          (_ctx.selectedChartTab === 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                (_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_Skeleton, {
                      key: 0,
                      class: "h-20rem"
                    }))
                  : (_openBlock(), _createBlock(_component_Chart, {
                      key: 1,
                      type: "bar",
                      data: _ctx.extraServicesValuesData,
                      options: _ctx.horizontalChartOptions,
                      height: _ctx.extraServicesValuesData.labels.length * 20
                    }, null, 8, ["data", "options", "height"]))
              ]))
            : _createCommentVNode("", true),
          (_ctx.selectedChartTab === 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                (_ctx.loading)
                  ? (_openBlock(), _createBlock(_component_Skeleton, {
                      key: 0,
                      class: "h-20rem"
                    }))
                  : (_openBlock(), _createBlock(_component_Chart, {
                      key: 1,
                      type: "bar",
                      data: _ctx.extraServicesQuantityData,
                      options: _ctx.horizontalChartOptions,
                      height: _ctx.extraServicesQuantityData.labels.length * 20
                    }, null, 8, ["data", "options", "height"]))
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}