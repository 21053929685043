/* eslint-disable import/no-cycle */
/**
 * Map store module
 */

import cloneDeep from 'lodash/cloneDeep';
import { MutationTree, ActionContext, GetterTree, ActionTree } from 'vuex';
import _ from 'lodash';
import mapEditorUtil from '@/utils/mapEditorUtil';
import {
  RootState,
  MapEditorState,
  DEFAULT_MAP_EDITOR_STATE,
} from '@/models/store';
import {
  MapDecoration,
  MapDecorationOrientation,
  MapDecorationType,
  MapDecorationTypeRaw,
  MapElementTypeRaw,
} from '@/models/map';

import {
  MapEditorElementRaw,
  MapEditorElementRawJsonData,
  MapEditorFormField,
  MapEditorGridHighlight,
  MapEditorOnlineNotAvailableDaysRaw,
  MapEditorSidebarStep,
  MapEditorSpot,
  MAP_EDITOR_DEFAULT_FORM,
  MAP_EDITOR_DEFAULT_GRID_HIGHLIGHT,
} from '@/models/mapEditor';
import mapEditorService from '@/services/mapEditorService';
import { SpotType } from '@/models/spot';
import { DropdownOption, Point2D } from '@/models';
import { PriceList } from '@/models/priceList';
import priceListService from '@/services/priceListService';
import { LicenseSectorFull } from '@/models/license';
import {
  ApiMapEditorEditGridPayload,
  ApiMapEditorElementPayload,
} from '@/models/api';
import spotUtil from '@/utils/spotUtil';
import { Sector } from '@/models/sector';

type MapEditorContext = ActionContext<MapEditorState, RootState>;

const namespaced = true;

const state = (): MapEditorState => cloneDeep(DEFAULT_MAP_EDITOR_STATE);

const getters: GetterTree<MapEditorState, RootState> = {
  elements: (state) => state.elements,
  decorations: (state) => state.decorations,
  spots: (state) => state.spots,
  // custom
  isCreate: (state) =>
    state.sidebarStep === MapEditorSidebarStep.FORM && state.form.id === 0,
  isEdit: (state) =>
    state.sidebarStep === MapEditorSidebarStep.FORM && state.form.id !== 0,
  isDecoration: (state): boolean =>
    Object.values(MapDecorationType).includes(
      state.form.type as MapDecorationType,
    ),
  isSpot: (state): boolean =>
    Object.values(SpotType).includes(state.form.type as SpotType),
  priceListOptions: (state): Array<DropdownOption> =>
    state.priceLists.map(
      (priceList: PriceList) =>
        ({
          label: priceList.name,
          value: priceList.id,
        } as DropdownOption),
    ),
  sectorOptions: (
    state,
    getters,
    rootState,
    rootGetters,
  ): Array<DropdownOption> => {
    const sectors = rootGetters['session/sectors'] as Array<Sector>;
    return sectors.map((sector: Sector) => ({
      label: `(Id: ${sector.header.oldId}) ${sector.header.name}`,
      value: sector.header.id,
    })) as Array<DropdownOption>;
  },
} as GetterTree<MapEditorState, RootState>;

const mutations: MutationTree<MapEditorState> = {
  setSpots(state: MapEditorState, spots: Array<MapEditorSpot>) {
    state.spots = spots;
  },
  setDecorations(state: MapEditorState, decorations: Array<MapDecoration>) {
    state.decorations = decorations;
  },
  setElements(state: MapEditorState, elements: Array<MapEditorElementRaw>) {
    state.elements = elements;
  },
  setFormField(state: MapEditorState, field: MapEditorFormField) {
    if (_.has(state.form, field.name)) {
      state.form[field.name] = field.value;
    }
  },
  setPricesList(state: MapEditorState, pricesList: Array<PriceList>) {
    state.priceLists = pricesList;
  },
  setSidebarStep(state: MapEditorState, step: MapEditorSidebarStep) {
    state.sidebarStep = step;
  },
  updateDecorationElement(state: MapEditorState, element: MapEditorElementRaw) {
    const elementIndex = state.elements.findIndex(
      (el: MapEditorElementRaw) => el.id === element.id,
    );
    state.elements[elementIndex] = element;
    const decorationIndex = state.decorations.findIndex(
      (decoration: MapDecoration) => decoration.id === element.id,
    );
    state.decorations[decorationIndex] =
      mapEditorUtil.decorationDeserialize(element);
  },
  updateSpotElement(state: MapEditorState, element: MapEditorElementRaw) {
    const elementIndex = state.elements.findIndex(
      (el: MapEditorElementRaw) => el.id === element.id,
    );
    state.elements[elementIndex] = element;
    const spotIndex = state.spots.findIndex(
      (spot: MapEditorSpot) => spot.id === element.id,
    );
    state.spots[spotIndex] = mapEditorUtil.spotDeserialize(element);
  },
  setGridHighlight(
    state: MapEditorState,
    gridHighlight: MapEditorGridHighlight,
  ) {
    state.gridHighlight = gridHighlight;
  },
  resetForm(state: MapEditorState) {
    Object.assign(state.form, cloneDeep(MAP_EDITOR_DEFAULT_FORM));
  },
  resetGridHighlight(state: MapEditorState) {
    Object.assign(
      state.gridHighlight,
      cloneDeep(MAP_EDITOR_DEFAULT_GRID_HIGHLIGHT),
    );
  },
  reset(state: MapEditorState) {
    Object.assign(state, cloneDeep(MAP_EDITOR_DEFAULT_GRID_HIGHLIGHT));
  },
} as MutationTree<MapEditorState>;

const actions: ActionTree<MapEditorState, RootState> = {
  async setPricesList(context: MapEditorContext): Promise<void> {
    const pricesList = await priceListService.find({});
    context.commit('setPricesList', pricesList);
  },
  async setElements(context: MapEditorContext): Promise<void> {
    const elements: Array<MapEditorElementRaw> =
      await mapEditorService.elements();
    const decorations: Array<MapDecoration> = [];
    const spots: Array<MapEditorSpot> = [];
    const decorationsType = [
      MapElementTypeRaw.MAP_LABEL,
      MapElementTypeRaw.MAP_LINE_H,
      MapElementTypeRaw.MAP_LINE_V,
      MapElementTypeRaw.FOOTBOARD,
      MapElementTypeRaw.ELEMENT,
    ] as Array<string>;
    elements.map((element: MapEditorElementRaw) => {
      if (decorationsType.includes(element.type)) {
        decorations.push(mapEditorUtil.decorationDeserialize(element));
      } else {
        spots.push(mapEditorUtil.spotDeserialize(element));
      }
    });
    context.commit('setElements', elements);
    context.commit('setDecorations', decorations);
    context.commit('setSpots', spots);
  },
  async initCreateSpotForm(context: MapEditorContext, spotType: SpotType) {
    context.commit('resetForm');
    context.commit('setFormField', {
      name: 'type',
      value: spotType,
    });
    const defaultPriceList =
      context.getters.priceListOptions.length > 0
        ? context.getters.priceListOptions[0].value
        : 0;
    const defaultSector =
      context.getters.sectorOptions.length > 0
        ? context.getters.sectorOptions[0].value
        : 0;
    context.commit('setFormField', {
      name: 'priceListId',
      value: defaultPriceList,
    });
    context.commit('setFormField', {
      name: 'priceListOnlineId',
      value: defaultPriceList,
    });
    context.commit('setFormField', {
      name: 'sector',
      value: defaultSector,
    });
    context.commit('setSidebarStep', MapEditorSidebarStep.FORM);
  },
  async initCreateDecorationForm(
    context: MapEditorContext,
    decorationType: MapDecorationType,
  ) {
    // console.log(decorationType);
    context.commit('resetForm');
    context.commit('setFormField', {
      name: 'type',
      value: decorationType,
    });
    context.commit('setSidebarStep', MapEditorSidebarStep.FORM);
  },
  async initEditDecorationForm(
    context: MapEditorContext,
    decoration: MapDecoration,
  ) {
    context.commit('setFormField', {
      name: 'id',
      value: decoration.id,
    });
    context.commit('setFormField', {
      name: 'type',
      value: decoration.type,
    });
    context.commit('setFormField', {
      name: 'posX',
      value: decoration.posX,
    });
    context.commit('setFormField', {
      name: 'posY',
      value: decoration.posY,
    });
    if (decoration.type === MapDecorationType.LINE) {
      context.commit('setFormField', {
        name: 'color',
        value: decoration.backgroundColor,
      });
      context.commit('setFormField', {
        name: 'orientation',
        value:
          decoration.orientation === MapDecorationOrientation.HORIZONTAL
            ? 'o'
            : 'v',
      });
    } else if (decoration.type === MapDecorationType.LABEL) {
      context.commit('setFormField', {
        name: 'name',
        value: decoration.name,
      });
    } else if (decoration.type === MapDecorationType.FOOTBOARD) {
      context.commit('setFormField', {
        name: 'orientation',
        value:
          decoration.orientation === MapDecorationOrientation.HORIZONTAL
            ? 'o'
            : 'v',
      });
      context.commit('setFormField', {
        name: 'name',
        value: decoration.name,
      });
    } else {
      context.commit('setFormField', {
        name: 'rotation',
        value: decoration.rotation,
      });
      context.commit('setFormField', {
        name: 'height',
        value: decoration.height,
      });
      context.commit('setFormField', {
        name: 'width',
        value: decoration.width,
      });
      if (decoration.type === MapDecorationType.CUSTOM) {
        context.commit('setFormField', {
          name: 'backgroundUrl',
          value: decoration.backgroundUrl,
        });
      }
    }
    context.commit('setSidebarStep', MapEditorSidebarStep.FORM);
  },
  async initEditSpotForm(context: MapEditorContext, spot: MapEditorSpot) {
    let name = spot.name;
    let timeSlot = '';
    if (spot.type === SpotType.PLAYFIELD) {
      const playFieldData = spotUtil.getPlayFieldDataByName(name);
      name = playFieldData.name;
      timeSlot = playFieldData.timeSlot;
    }
    context.commit('setFormField', {
      name: 'id',
      value: spot.id,
    });
    context.commit('setFormField', {
      name: 'type',
      value: spot.type,
    });
    context.commit('setFormField', {
      name: 'posX',
      value: spot.posX,
    });
    context.commit('setFormField', {
      name: 'posY',
      value: spot.posY,
    });
    context.commit('setFormField', {
      name: 'backgroundColor',
      value: spot.background,
    });
    context.commit('setFormField', {
      name: 'name',
      value: name,
    });
    context.commit('setFormField', {
      name: 'online',
      value: spot.online,
    });
    context.commit('setFormField', {
      name: 'priceListId',
      value: spot.priceListId,
    });
    context.commit('setFormField', {
      name: 'priceListOnlineId',
      value: spot.priceListOnlineId,
    });
    context.commit('setFormField', {
      name: 'sector',
      value: spot.sector,
    });
    context.commit('setFormField', {
      name: 'backgroundColor',
      value: spot.background,
    });
    context.commit('setFormField', {
      name: 'onlineNotAvailableDays',
      value: spot.onlineNotAvailableDays,
    });
    context.commit('setFormField', {
      name: 'timeSlot',
      value: timeSlot,
    });
    context.commit('setSidebarStep', MapEditorSidebarStep.FORM);
  },
  async createElement(context: MapEditorContext, coordinates: Point2D) {
    // set positions on form
    const posX = Math.floor(coordinates.x / 40); // cell
    const posY = Math.floor(coordinates.y / 40); // cell
    context.commit('setFormField', {
      name: 'posX',
      value: posX,
    });
    context.commit('setFormField', {
      name: 'posY',
      value: posY,
    });
    await context.dispatch('saveElement');
  },
  async saveElement(context: MapEditorContext) {
    const form = context.state.form;
    const isCreate = context.getters.isCreate;
    const isDecoration = context.getters.isDecoration;
    const payload: ApiMapEditorElementPayload = {
      posX: form.posX,
      posY: form.posY,
    };
    if (isDecoration) {
      // DECORATION
      const decoration: MapDecorationType = form.type as MapDecorationType;
      if (decoration === MapDecorationType.LINE) {
        payload.name = form.color;
        payload.type =
          form.orientation === 'v'
            ? MapElementTypeRaw.MAP_LINE_V
            : MapElementTypeRaw.MAP_LINE_H;
      } else if (decoration === MapDecorationType.LABEL) {
        payload.name = form.name;
        payload.type = MapElementTypeRaw.MAP_LABEL;
      } else if (decoration === MapDecorationType.FOOTBOARD) {
        payload.name = form.orientation;
        payload.type = MapElementTypeRaw.FOOTBOARD;
      } else {
        payload.type = MapElementTypeRaw.ELEMENT;
        payload.data = {
          rotation: form.rotation,
          height: form.height,
          width: form.width,
          elementType: decoration as unknown as MapDecorationTypeRaw,
        };
        if (decoration === MapDecorationType.CUSTOM) {
          payload.data.url = form.backgroundUrl;
        }
      }
    } else {
      // SPOT
      payload.type = form.type as unknown as MapElementTypeRaw;
      if (form.type !== SpotType.BEDS) {
        payload.name = isCreate
          ? form.nameBefore + form.nameNumber.toString() + form.nameAfter
          : form.name;
        if (form.type === SpotType.PLAYFIELD) {
          payload.name += ` ${form.timeSlot}`;
        }
      }
      payload.online = form.online;
      payload.priceListId = form.priceListId;
      payload.priceListOnlineId = form.priceListOnlineId;
      payload.sector = form.sector;
      payload.data = {
        'background-color': form.backgroundColor,
      };

      const onlineNotAvailableDays = {} as MapEditorOnlineNotAvailableDaysRaw;
      form.onlineNotAvailableDays.map((day: string) => {
        onlineNotAvailableDays[day] = false;
      });
      payload.jsonData = {
        onlineNotAvailableDays,
      } as MapEditorElementRawJsonData;
    }
    const element = isCreate
      ? await mapEditorService.create(payload)
      : await mapEditorService.update(form.id, payload);

    if (isCreate) {
      context.commit('setElements', context.state.elements.concat([element]));
      if (isDecoration) {
        context.commit(
          'setDecorations',
          context.state.decorations.concat([
            mapEditorUtil.decorationDeserialize(element),
          ]),
        );
      } else {
        context.commit(
          'setSpots',
          context.state.spots.concat([mapEditorUtil.spotDeserialize(element)]),
        );
        if (form.type !== SpotType.BEDS) {
          context.commit('setFormField', {
            name: 'nameNumber',
            value: context.state.form.nameNumber + 1,
          });
        }
        if (form.type === SpotType.PLAYFIELD) {
          context.commit('setFormField', {
            name: 'timeSlot',
            value: '',
          });
        }
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (isDecoration) {
        context.commit('updateDecorationElement', element);
      } else {
        context.commit('updateSpotElement', element);
      }
    }
  },
  async allOnline(context: MapEditorContext) {
    await mapEditorService.setAllOnline();
    context.commit(
      'setElements',
      context.state.elements.map((element: MapEditorElementRaw) => ({
        ...element,
        online: true,
      })),
    );
    context.commit(
      'setSpots',
      context.state.spots.map((spot: MapEditorSpot) => ({
        ...spot,
        online: true,
      })),
    );
  },
  async deleteElement(context: MapEditorContext) {
    const deleteElement = context.state.form.id;
    await mapEditorService.delete(deleteElement);
    context.commit(
      'setElements',
      context.state.elements.filter(
        (element: MapEditorElementRaw) => element.id !== deleteElement,
      ),
    );
    if (context.getters.isSpot) {
      context.commit(
        'setSpots',
        context.state.spots.filter(
          (spot: MapEditorSpot) => spot.id !== deleteElement,
        ),
      );
    } else {
      context.commit(
        'setDecorations',
        context.state.decorations.filter(
          (decoration: MapDecoration) => decoration.id !== deleteElement,
        ),
      );
    }
    context.commit('resetForm');
    context.commit('resetGridHighlight');
    context.commit('setSidebarStep', MapEditorSidebarStep.HOME);
  },
  async editGrid(
    context: MapEditorContext,
    payload: ApiMapEditorEditGridPayload,
  ) {
    await mapEditorService.editGrid(payload);
    await context.dispatch('setElements');
  },
} as ActionTree<MapEditorState, RootState>;

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
