
import cloneDeep from 'lodash/cloneDeep';
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { DateTime } from 'luxon';
import { orderBy } from 'lodash';
import { fromByteArray } from 'base64-js';
import {
  Reservation,
  ReservationExpense,
  ReservationService,
  ReservationType,
} from '@/models/reservation';
import { Printer, PrintMode } from '@/models/printer';
import { CashFlow, CashFlowMethod } from '@/models/cashFlow';
import { BEACH_TICKET_SERVICE_ID, Service } from '@/models/service';
import {
  LicenseBeachTicketsMode,
  LicenseSectorDetailFormatted,
} from '@/models/license';
import printerUtil from '@/utils/printerUtil';
import { PrintLine } from '@/models/print';
import cookieUtil from '@/utils/cookieUtil';
import spotUtil from '@/utils/spotUtil';
import { SectorHeader } from '@/models/sector';
import { SpotType } from '@/models/spot';

export default defineComponent({
  name: 'ReservationConfiguration',
  data() {
    return {
      showDialog: false,
    };
  },
  mounted() {
    this.$spiagge.utils.printMode.setup();
  },
  methods: {
    onPrintSummary(printer: Printer): void {
      /**
       * Print summary (printers)
       */
      const print = this.$spiagge.utils.printMode.buildRepPrint(
        PrintMode.PLAIN_TEXT,
        CashFlowMethod.CASH,
      );
      if (this.license.beachTickets !== LicenseBeachTicketsMode.DISABLED) {
        print.codeType = 'QR';
        print.codeText = this.qrCode;
      }
      print.textLines = this.buildPrintLines();
      this.$spiagge.utils.printMode.print(printer.id, print, () => null);
      this.showDialog = false;
    },
    onPrintLabel(printer: Printer): void {
      /**
       * Print label (printers)
       */
      const print = this.$spiagge.utils.printMode.buildRepPrint(
        PrintMode.PLAIN_TEXT,
        CashFlowMethod.CASH,
      );
      print.textLines = this.buildLabelPrintLines();
      if (print.textLines.length > 0) {
        this.$spiagge.utils.printMode.print(printer.id, print, () => null);
      }
      this.showDialog = false;
    },
    onQRCodeSummary(): void {
      /**
       * Print QR Code
       */
      this.$spiagge.utils.global.printHtml(
        this.$refs.qrCodePrintContent as unknown as HTMLElement,
      );
    },
    onBrowserPrintSummary() {
      /**
       * Print summary (browser)
       */
      this.showDialog = false;
      setTimeout(() => {
        window.print();
      }, 50);
    },
    buildPrintLines(): Array<PrintLine> {
      /**
       * Build print lines
       */
      const printLines: Array<PrintLine> = [];

      /* BEACH NAME */
      /*
      printLines.push(printerUtil.printLineFontSize(1, 2));
      printLines.push(printerUtil.printLineTextStyle(true));
      printLines.push(`${this.license.name}`);
      */

      /* CUSTOMER */
      if (this.customer) {
        printLines.push(printerUtil.printLineFontSize(1, 2));
        printLines.push(printerUtil.printLineTextStyle(true));
        printLines.push(`${this.customer.firstName} ${this.customer.lastName}`);
      }

      /* START/END DATES */
      if (this.startDate && this.endDate) {
        printLines.push(printerUtil.printLineFontSize());
        printLines.push(' ');
        printLines.push(printerUtil.printLineTextStyle(true));
        printLines.push(
          `${this.$t('common.from')} ${this.startDate.toFormat(
            'dd/MM',
          )} ${this.$t('common.to')} ${this.endDate.toFormat('dd/MM')}`,
        );
        printLines.push(printerUtil.printLineTextStyle());
      }

      /* SPOT/STAGING */
      if (this.spot.name && this.spot.type) {
        printLines.push(' ');
        printLines.push(
          this.$spiagge.utils.reservation.getReceiptDescription({
            spotType: this.spot.type,
            spotName: this.spot.name,
            maxiBeds: this.maxiBeds,
            beds: this.beds,
            chairs: this.chairs,
            deckChairs: this.deckChairs,
            startDate: this.startDate.toSeconds(),
            endDate: this.endDate.toSeconds(),
            type: this.type,
            sector: this.sector,
          } as Reservation),
        );

        printLines.push(printerUtil.printLineFontSize());
        printLines.push(printerUtil.printLineTextStyle());
        printLines.push(
          `${this.$t('reservationHeaderPrintSummary.sector')}: ${
            this.sector.name
          }`,
        );

        // AMOUNT
        const amount = this.totals.forced.beach
          ? this.totals.forced.beach
          : this.totals.list.beach;
        printLines.push(printerUtil.printLineTextStyle(true));
        printLines.push(
          `   1 x ${amount} ${this.$t('reservationHeaderPrintSummary.euro')}`,
        );
        printLines.push(printerUtil.printLineTextStyle());
      }

      // JOINTS
      if (this.isJointAccount && this.joints?.length > 0) {
        this.joints.forEach((element: Reservation) => {
          printLines.push(' ');
          printLines.push(
            this.$spiagge.utils.reservation.getReceiptDescription({
              spotType: element.spotType,
              spotName: element.spotName,
              maxiBeds: element.maxiBeds,
              beds: element.beds,
              chairs: element.chairs,
              deckChairs: element.deckChairs,
              startDate: element.startDate,
              endDate: element.endDate,
              type: element.type,
              sector: element.sector,
            } as Reservation),
          );

          /*
          printLines.push(printerUtil.printLineFontSize());
          printLines.push(printerUtil.printLineTextStyle());
          printLines.push(
            `${this.$t('reservationHeaderPrintSummary.sector')}: ${
              this.sector.name
            }`,
          );
          */
        });
      }

      /* SERVICES */
      if (this.reservationServices && this.reservationServices.length > 0) {
        this.reservationServices
          .filter(
            (reservationService: ReservationService) =>
              reservationService.bought - reservationService.deleted > 0,
          )
          .map((reservationService: ReservationService) => {
            const index = this.services.findIndex(
              (item: Service) => item.id === reservationService.serviceId,
            );
            const service = cloneDeep(this.services[index]);
            printLines.push(' ');
            printLines.push(printerUtil.printLineTextStyle());
            printLines.push(`   ${service.name}`);
            printLines.push(printerUtil.printLineTextStyle(true));
            printLines.push(
              `   ${reservationService.bought - reservationService.deleted} x ${
                reservationService.price /
                (reservationService.bought - reservationService.deleted)
              } ${this.$t('reservationHeaderPrintSummary.euro')}`,
            );
          });
      }

      /* EXPENSES */
      if (this.expenses && this.expenses.length > 0) {
        printLines.push(' ');
        printLines.push(printerUtil.printLineTextStyle());
        printLines.push(this.$t('reservationHeaderPrintSummary.expenses'));
        printLines.push(' ');
        this.expenses.map((element: ReservationExpense) => {
          printLines.push(printerUtil.printLineTextStyle(true));
          printLines.push(
            `   ${element.value} ${this.$t(
              'reservationHeaderPrintSummary.euroOf',
            )} ${DateTime.fromSeconds(element.date).toFormat('dd/MM')}`,
          );
          printLines.push(printerUtil.printLineTextStyle());
        });
      }

      /* CABINS */
      if (this.cabins && this.cabins.length > 0) {
        this.cabins.map((cabin: Reservation) => {
          if (cabin.deleted) {
            return;
          }
          printLines.push(' ');
          printLines.push(printerUtil.printLineTextStyle());
          let dateString = '';
          const startDate = cabin.startDate;
          const endDate = cabin.endDate;
          dateString += ` ${DateTime.fromSeconds(startDate).toFormat('dd/MM')}`;
          if (startDate !== endDate) {
            dateString += ` - ${DateTime.fromSeconds(endDate).toFormat(
              'dd/MM',
            )}`;
          }
          printLines.push(
            `${this.$t('reservationHeaderPrintSummary.cabin')} ${
              cabin.spotName
            } ${dateString}`,
          );
          let staging = '';
          if (cabin.beds && cabin.beds > 0) {
            staging += `   ${cabin.beds} ${this.$t(
              'reservationHeaderPrintSummary.beds',
            )}`;
          }
          if (cabin.maxiBeds && cabin.maxiBeds > 0) {
            staging += ` + ${cabin.maxiBeds} ${this.$t(
              'reservationHeaderPrintSummary.maxibeds',
            )}`;
          }
          if (cabin.chairs && cabin.chairs > 0) {
            staging += ` + ${cabin.chairs} ${this.$t(
              'reservationHeaderPrintSummary.chairs',
            )}`;
          }
          if (cabin.deckChairs && cabin.deckChairs > 0) {
            staging += ` + ${cabin.deckChairs} ${this.$t(
              'reservationHeaderPrintSummary.deckchairs',
            )}`;
          }
          if (staging.length > 0) {
            printLines.push(staging);
          }
          const amount =
            this.$spiagge.utils.reservation.getTotalOfReservation(cabin);
          printLines.push(printerUtil.printLineTextStyle(true));
          printLines.push(
            `   1 x ${amount} ${this.$t('reservationHeaderPrintSummary.euro')}`,
          );
          printLines.push(printerUtil.printLineTextStyle());
        });
      }

      /* ADDITIONS */
      if (this.additions && this.additions.length > 0) {
        this.additions.map((addition: Reservation) => {
          if (addition.deleted) {
            return;
          }
          printLines.push(' ');
          printLines.push(printerUtil.printLineTextStyle());
          let dateString = '';
          const startDate = addition.startDate;
          const endDate = addition.endDate;
          dateString += ` ${DateTime.fromSeconds(startDate).toFormat('dd/MM')}`;
          if (startDate !== endDate) {
            dateString += ` - ${DateTime.fromSeconds(endDate).toFormat(
              'dd/MM',
            )}`;
          }
          printLines.push(
            `${this.$t('reservationHeaderPrintSummary.addition')} ${
              addition.spotName
            } ${dateString}`,
          );
          let staging = '';
          if (addition.beds && addition.beds > 0) {
            staging += `   ${addition.beds} ${this.$t(
              'reservationHeaderPrintSummary.beds',
            )}`;
          }
          if (addition.maxiBeds && addition.maxiBeds > 0) {
            staging += ` + ${addition.maxiBeds} ${this.$t(
              'reservationHeaderPrintSummary.maxibeds',
            )}`;
          }
          if (addition.chairs && addition.chairs > 0) {
            staging += ` + ${addition.chairs} ${this.$t(
              'reservationHeaderPrintSummary.chairs',
            )}`;
          }
          if (addition.deckChairs && addition.deckChairs > 0) {
            staging += ` + ${addition.deckChairs} ${this.$t(
              'reservationHeaderPrintSummary.deckchairs',
            )}`;
          }
          if (staging.length > 0) {
            printLines.push(staging);
          }
          const amount =
            this.$spiagge.utils.reservation.getTotalOfReservation(addition);
          printLines.push(printerUtil.printLineTextStyle(true));
          printLines.push(
            `   1 x ${amount} ${this.$t('reservationHeaderPrintSummary.euro')}`,
          );
          printLines.push(printerUtil.printLineTextStyle());
        });
      }

      /* PARKINGS */
      if (this.parkings && this.parkings.length > 0) {
        this.parkings.map((parking: Reservation) => {
          if (parking.deleted) {
            return;
          }
          printLines.push(' ');
          printLines.push(printerUtil.printLineTextStyle());
          let dateString = '';
          const startDate = parking.startDate;
          const endDate = parking.endDate;
          dateString += ` ${DateTime.fromSeconds(startDate).toFormat('dd/MM')}`;
          if (startDate !== endDate) {
            dateString += ` - ${DateTime.fromSeconds(endDate).toFormat(
              'dd/MM',
            )}`;
          }
          printLines.push(
            `${this.$t('reservationHeaderPrintSummary.parking')} ${
              parking.spotName
            } ${dateString}`,
          );
          const amount =
            this.$spiagge.utils.reservation.getTotalOfReservation(parking);
          printLines.push(printerUtil.printLineTextStyle(true));
          printLines.push(
            `   1 x ${amount} ${this.$t('reservationHeaderPrintSummary.euro')}`,
          );
          printLines.push(printerUtil.printLineTextStyle());
        });
      }

      /* DISPLACEMENTS/ABSENCES */
      if (
        this.displacementsAbsencesList &&
        this.displacementsAbsencesList.length > 0
      ) {
        printLines.push(' ');
        printLines.push(printerUtil.printLineTextStyle(true));
        printLines.push(this.$t('reservationHeaderPrintSummary.displacements'));
        this.displacementsAbsencesList.map(
          (displacementAbsence: Reservation) => {
            // TODO: text
            let detail = '   ';
            printLines.push(printerUtil.printLineTextStyle());
            if (displacementAbsence.type === ReservationType.ABSENCE) {
              detail += `${this.$t(
                'reservationHeaderPrintSummary.absenceFrom',
              )} ${this.$spiagge.date.fromMillis(
                displacementAbsence.startDate,
              )} al ${this.$spiagge.date.fromMillis(
                displacementAbsence.endDate,
              )}`;
            }
            if (displacementAbsence.type === ReservationType.STANDARD) {
              detail += `${spotUtil.getLabel(this.spot.type)} ${
                displacementAbsence.spotName
              } ${this.$t('common.from')} ${this.$spiagge.date.fromMillis(
                displacementAbsence.startDate,
              )} ${this.$t('common.to')} ${this.$spiagge.date.fromMillis(
                displacementAbsence.endDate,
              )}`;
            }
            printLines.push(detail);
          },
        );
      }

      /* CASH FLOWS */
      let fiscCashFlow = {} as Array<CashFlow>;
      let noFiscCashFlow = {} as Array<CashFlow>;
      if (this.cashFlows && this.cashFlows.length > 0) {
        printLines.push(' ');
        printLines.push(printerUtil.printLineTextStyle(true));
        printLines.push(this.$t('reservationHeaderPrintSummary.sales'));
        printLines.push(printerUtil.printLineTextStyle());
        fiscCashFlow = this.cashFlows.filter(
          (cashFlow: CashFlow) => cashFlow.receiptId,
        );
        noFiscCashFlow = this.cashFlows.filter(
          (cashFlow: CashFlow) => !cashFlow.receiptId,
        );
        if (fiscCashFlow && fiscCashFlow.length > 0) {
          fiscCashFlow
            .map((cashFlow: CashFlow) => {
              printLines.push(
                `   ${DateTime.fromSeconds(cashFlow.date).toFormat(
                  'dd/MM/yyyy',
                )} - ${cashFlow.amount} ${this.$t(
                  'reservationHeaderPrintSummary.euro',
                )}`,
              );
              return cashFlow.amount;
            })
            .reduce(
              (previousValue: number, currentValue: number) =>
                previousValue + currentValue,
            );
        }
        if (noFiscCashFlow && noFiscCashFlow.length > 0) {
          noFiscCashFlow
            .map((cashFlow: CashFlow) => {
              printLines.push(
                `   ${DateTime.fromSeconds(cashFlow.date).toFormat(
                  'dd/MM/yyyy',
                )} - ${cashFlow.amount} ${this.$t(
                  'reservationHeaderPrintSummary.euro',
                )}`,
              );
              return cashFlow.amount;
            })
            .reduce(
              (previousValue: number, currentValue: number) =>
                previousValue + currentValue,
            );
        }
      }
      printLines.push(printerUtil.printLineTextStyle());
      printLines.push(' ');
      printLines.push(printerUtil.printLineTextStyle(true));
      printLines.push(this.$t('reservationHeaderPrintSummary.remainingAmount'));
      printLines.push(
        `   ${this.totalToPay} ${this.$t(
          'reservationHeaderPrintSummary.euro',
        )}`,
      );
      printLines.push(printerUtil.printLineTextStyle());
      printLines.push(' ');
      printLines.push(printerUtil.printLineTextStyle(true));
      printLines.push(printerUtil.printLineFontSize(1, 2));
      printLines.push(
        `${this.$t('reservationHeaderPrintSummary.euroTotal')} ${this.total}`,
      );
      printLines.push(' ');
      printLines.push(printerUtil.printLineTextStyle());
      printLines.push(' ');
      printLines.push(printerUtil.printLineFontSize());
      printLines.push(printerUtil.printLineTextStyle());

      return printLines;
    },
    buildLabelPrintLines(): Array<PrintLine> {
      /**
       * Build print lines
       */
      const printLines: Array<PrintLine> = [];

      /* SPOT */
      if (
        this.spot &&
        this.spot.type &&
        this.spot.name &&
        this.license.stickerSettingsFields.printSpot
      ) {
        let spotDescription = '';

        switch (this.spot.type) {
          case SpotType.CABIN:
            spotDescription = `${this.$t('reservationPayment.cabinsShort')} ${
              this.spot.name
            }`;
            break;
          case SpotType.PARKING:
            spotDescription = `${this.$t('reservationPayment.parkingShort')} ${
              this.spot.name
            }`;
            break;
          case SpotType.BEDS:
            spotDescription = `${this.$t('reservationPayment.areaShort')} ${
              this.spot.name
            }`;
            break;
          case SpotType.BED:
            spotDescription = `${this.$t('reservationPayment.bedShort')} ${
              this.spot.name
            }`;
            break;
          case SpotType.GAZEBO:
            spotDescription = `${this.$t('reservationPayment.gazeboShort')} ${
              this.spot.name
            }`;
            break;
          default:
            spotDescription = `${this.$t('reservationPayment.umbrellaShort')} ${
              this.spot.name
            }`;
        }

        printLines.push(printerUtil.printLineFontSize(2, 2));
        printLines.push(printerUtil.printLineTextStyle(true));
        printLines.push(`${spotDescription}`);
      }

      if (this.license.stickerSettingsFields.printStaging) {
        /* HOTEL ROOM */
        printLines.push(printerUtil.printLineFontSize(2, 2));
        printLines.push(printerUtil.printLineTextStyle(true));
        let stagingDescriptionRow = '';
        const maxiBeds = this.$store.getters['reservation/maxiBeds'];
        const beds = this.$store.getters['reservation/beds'];
        const deckChairs = this.$store.getters['reservation/deckChairs'];
        const chairs = this.$store.getters['reservation/chairs'];

        if (chairs && chairs > 0) {
          stagingDescriptionRow += ` ${chairs} ${this.$t(
            'reservationHeaderPrintSummary.chairs',
          )}`;
        }

        if (maxiBeds && maxiBeds > 0) {
          stagingDescriptionRow += ` ${maxiBeds} ${this.$t(
            'reservationHeaderPrintSummary.maxibeds',
          )}`;
        }

        if (beds && beds > 0) {
          stagingDescriptionRow += ` ${beds} ${this.$t(
            'reservationHeaderPrintSummary.beds',
          )}`;
        }

        if (deckChairs && deckChairs > 0) {
          stagingDescriptionRow += ` ${deckChairs} ${this.$t(
            'reservationHeaderPrintSummary.deckchairs',
          )}`;
        }

        if (stagingDescriptionRow.length > 0) {
          printLines.push(stagingDescriptionRow);
        }
      }

      /* HOTEL ROOM */
      if (
        this.hotelRoom &&
        this.license.stickerSettingsFields.printHotelRoom &&
        this.hotelRoom.length > 0
      ) {
        /* HOTEL ROOM */
        printLines.push(printerUtil.printLineFontSize(2, 2));
        printLines.push(printerUtil.printLineTextStyle(true));
        printLines.push(
          `${this.$t('reservationHeaderPrintSummary.room')} ${this.hotelRoom}`,
        );
      }

      /* CUSTOMER */
      if (
        this.customer &&
        this.license.stickerSettingsFields.printCustomer &&
        (this.customer.firstName.length > 0 ||
          this.customer.lastName.length > 0)
      ) {
        printLines.push(printerUtil.printLineFontSize(2, 2));
        printLines.push(printerUtil.printLineTextStyle(true));
        printLines.push(`${this.customer.firstName} ${this.customer.lastName}`);
      }

      /* START/END DATES */
      if (
        this.startDate &&
        this.endDate &&
        this.license.stickerSettingsFields.printReservationDates
      ) {
        printLines.push(printerUtil.printLineFontSize());
        printLines.push(' ');
        printLines.push(printerUtil.printLineFontSize(2, 2));
        printLines.push(printerUtil.printLineTextStyle(true));
        printLines.push(
          `${this.startDate.toFormat('dd/MM')} - ${this.endDate.toFormat(
            'dd/MM',
          )}`,
        );

        printLines.push(printerUtil.printLineFontSize());
        printLines.push(' ');
      }

      return printLines;
    },
    base64encode(text: string): string {
      const utf8Array = new TextEncoder().encode(text);
      return fromByteArray(utf8Array);
    },
  },
  computed: {
    ...mapGetters('reservation', [
      'id',
      'totalToPay',
      'total',
      'isJointAccount',
    ]),
    ...mapState('reservation', [
      'id',
      'spot',
      'totals',
      'cabins',
      'additions',
      'parkings',
      'expenses',
      'maxiBeds',
      'beds',
      'deckChairs',
      'chairs',
      'cashFlows',
      'customer',
      'absences',
      'displacements',
      'startDate',
      'endDate',
      'type',
      'sector',
      'joints',
      'hotelRoom',
      'qrCode',
    ]),
    ...mapState('session', ['services', 'license', 'printers']),
    ...mapGetters('session', ['sectorsDetails']),
    reservationServices(): Array<ReservationService> {
      return this.$store.getters['reservation/services'].filter(
        (reservationService: ReservationService) =>
          reservationService.serviceId !== BEACH_TICKET_SERVICE_ID,
      );
    },
    displacementsAbsencesList(): Array<Reservation> {
      return orderBy(
        [...this.absences, ...this.displacements],
        ['startDate'],
        ['asc'],
      );
    },
    reportPrinters(): Array<Printer> {
      let printers = this.$store.getters['session/nonFiscalPrinters'].filter(
        (p: Printer) => !cookieUtil.get(`spit_prt-hide-${p.id}`),
      );
      if (this.sector && this.sector?.id !== 0) {
        // eslint-disable-next-line no-confusing-arrow
        printers = printers.filter((p: Printer) =>
          p.sectors && p.sectors.length > 0
            ? p.sectors.find((s: SectorHeader) => s.id === this.sector.id)
            : true,
        );
      }
      return printers;
    },
    labelPrinters(): Array<Printer> {
      let printers = this.$store.getters['session/textPrinters'].filter(
        (p: Printer) => !cookieUtil.get(`spit_prt-hide-${p.id}`),
      );

      if (this.sector && this.sector?.id !== 0) {
        // eslint-disable-next-line no-confusing-arrow
        printers = printers.filter((p: Printer) =>
          p.sectors && p.sectors.length > 0
            ? p.sectors.find((s: SectorHeader) => s.id === this.sector.id)
            : true,
        );
      }
      return printers;
    },
  },
});
