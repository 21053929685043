/* eslint-disable import/no-cycle */
/**
 * Map store module
 */

import cloneDeep from 'lodash/cloneDeep';
import { MutationTree, ActionTree, ActionContext, GetterTree } from 'vuex';
import { RootState, PlannerState, DEFAULT_PLANNER_STATE } from '@/models/store';
import plannerService from '@/services/plannerService';
import { PlannerBuilder, PlannerData, PlannerMode } from '@/models/planner';
import plannerUtil from '@/utils/plannerUtil';
import { CABIN_SECTOR_ID, PARKING_SECTOR_ID } from '@/constants/planner';

type AppContext = ActionContext<PlannerState, RootState>;

const namespaced = true;

const state = (): PlannerState => cloneDeep(DEFAULT_PLANNER_STATE);

const getters: GetterTree<PlannerState, RootState> = {
  data: (state) => state.data,
  zoom: (state) => state.zoom,
  year: (state) => state.year,
  mode: (state) => state.mode,
  builder: (state) => state.builder,
  visibleSectorIds: (state) => state.visibleSectorIds,
  isCabinSectorVisible: (state): boolean =>
    state.sectorIds.includes(CABIN_SECTOR_ID)
    && (
      state.visibleSectorIds.length === 0
      || state.visibleSectorIds.includes(CABIN_SECTOR_ID)
    ),
  isParkSectorVisible: (state): boolean =>
    state.sectorIds.includes(PARKING_SECTOR_ID)
    && (
      state.visibleSectorIds.length === 0
      || state.visibleSectorIds.includes(PARKING_SECTOR_ID)
    ),
  today: (state) => state.now.startOf('day'),
  isCurrentYear: (state) => new Date().getFullYear() === Number(state.year),
};

const mutations: MutationTree<PlannerState> = {
  setData(state: PlannerState, data: PlannerData | null) {
    state.data = data;
  },
  setZoom(state: PlannerState, zoom: number) {
    state.zoom = zoom;
  },
  setYear(state: PlannerState, year: string) {
    state.year = year;
  },
  setMode(state: PlannerState, mode: PlannerMode) {
    state.mode = mode;
    // Reset builder
    const builder = state.builder;
    plannerUtil.builderReset(builder);
    state.builder = builder;
  },
  setBuilder(state: PlannerState, builder: PlannerBuilder) {
    state.builder = builder;
  },
  reset(state: PlannerState) {
    state.data = DEFAULT_PLANNER_STATE.data;
    state.mode = DEFAULT_PLANNER_STATE.mode;
    state.year = DEFAULT_PLANNER_STATE.year;
    state.zoom = DEFAULT_PLANNER_STATE.zoom;
    state.builder = DEFAULT_PLANNER_STATE.builder;
  },
  setVisibleSectorIds(
    state: PlannerState,
    ids: PlannerState['visibleSectorIds'],
  ) {
    state.visibleSectorIds = ids;
  },
  setSectorIds(
    state: PlannerState,
    ids: PlannerState['sectorIds'],
  ) {
    state.sectorIds = ids;
  },
  setIsLoading(state: PlannerState, isLoading: boolean): void {
    state.isLoading = isLoading;
  },
  setScrollToToday(state: PlannerState, scrollToToday: boolean): void {
    state.scrollToToday = scrollToToday;
  },
  setNow(state: PlannerState, now: PlannerState['now']): void {
    state.now = now;
  },
};

const actions: ActionTree<PlannerState, RootState> = {
  async loadData(context: AppContext): Promise<void> {
    try {
      context.commit('setIsLoading', true);

      const sectorIds: number[] =
        context.state.visibleSectorIds.length > 0
          ? context.state.visibleSectorIds
          : context.state.sectorIds;

      const data = await plannerService.data({
        sectorIds,
        year: +context.state.year,
      });
      context.commit('setData', data);
    } finally {
      context.commit('setIsLoading', false);
    }
  },
  setYear(context: AppContext, year: string) {
    context.commit('setYear', year);
    context.dispatch('loadData');
  },
  setVisibleSectorIds(
    context: AppContext,
    ids: PlannerState['visibleSectorIds'],
  ) {
    context.commit('setVisibleSectorIds', ids);
    context.dispatch('loadData');
  },
  setSectorIds(
    context: AppContext,
    ids: PlannerState['sectorIds'],
  ) {
    context.commit('setSectorIds', ids);
  },
};

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
