import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "statistics-edit-columns" }
const _hoisted_2 = ["aria-label"]
const _hoisted_3 = {
  key: 0,
  class: "columns-active"
}
const _hoisted_4 = { class: "column" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = { class: "actions-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_ScrollPanel = _resolveComponent("ScrollPanel")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      icon: "pi pi-pencil",
      label: _ctx.$t('statisticsEditColumns.editColumns'),
      class: "edit-columns-button p-button-white p-button-oulined",
      disabled: !_ctx.hasUpdatePermission(),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.dialog = true))
    }, null, 8, ["label", "disabled"]),
    _createVNode(_component_Dialog, {
      id: "statistics-edit-columns-dialog",
      class: "custom-dialog",
      modal: true,
      visible: _ctx.dialog,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dialog) = $event)),
      onHide: _cache[6] || (_cache[6] = ($event: any) => (_ctx.dialog = false))
    }, {
      default: _withCtx(() => [
        _createElementVNode("h2", null, _toDisplayString(_ctx.$t('statisticsEditColumns.title')), 1),
        _createVNode(_component_MultiSelect, {
          modelValue: _ctx.columnsModel,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.columnsModel) = $event)),
          options: _ctx.columnsOptions,
          optionLabel: "label",
          optionValue: "field",
          placeholder: _ctx.$t('statisticsEditColumns.selectColumns'),
          filter: true,
          showToggleAll: false
        }, {
          option: _withCtx((slotProps) => [
            _createElementVNode("span", {
              "aria-label": slotProps.option.label,
              class: "label"
            }, _toDisplayString(slotProps.option.label), 9, _hoisted_2)
          ]),
          _: 1
        }, 8, ["modelValue", "options", "placeholder"]),
        (_ctx.columnsActive.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("h6", null, _toDisplayString(_ctx.$t('statisticsEditColumns.activeColumns')), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('statisticsEditColumns.columnOrder')), 1),
              _createVNode(_component_ScrollPanel, { style: {"width":"100%","height":"300px"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_draggable, _mergeProps({
                    class: "columns",
                    modelValue: _ctx.columnsActive,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.columnsActive) = $event)),
                    onStart: _cache[3] || (_cache[3] = ($event: any) => (_ctx.drag = true)),
                    onEnd: _cache[4] || (_cache[4] = ($event: any) => (_ctx.drag = false)),
                    "item-key": "field",
                    "scroll-sensitivity": 50,
                    "force-fallback": true
                  }, _ctx.dragOptions), {
                    item: _withCtx(({ element }) => [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(element.label), 1),
                        _createVNode(_component_Button, {
                          icon: "pi pi-times",
                          class: "p-button-rounded p-button-danger p-button-text p-button-sm",
                          onClick: ($event: any) => (_ctx.onRemoveColumn(element))
                        }, null, 8, ["onClick"])
                      ])
                    ]),
                    _: 1
                  }, 16, ["modelValue"])
                ]),
                _: 1
              })
            ]))
          : (_openBlock(), _createBlock(_component_Message, {
              key: 1,
              class: "no-data",
              severity: "info",
              closable: false
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('statisticsEditColumns.noColumnMessage')), 1)
              ]),
              _: 1
            })),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Button, {
            label: _ctx.$t('button.cancel'),
            class: "p-button-outlined",
            onClick: _ctx.onAbort
          }, null, 8, ["label", "onClick"]),
          _createVNode(_component_Button, {
            label: _ctx.$t('button.save'),
            onClick: _ctx.onSave
          }, null, 8, ["label", "onClick"])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}