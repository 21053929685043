import { DateTime } from 'luxon';
import merge from 'lodash/merge';
import { has, round } from 'lodash';
import { SpotAttribute, SpotAttributesPayload } from '@/models/spot';
import { StyleSpotAttribute } from '@/models/style';
import store from '@/store';
import { License } from '@/models/license';
import { ReservationHalfDay } from '@/models/reservation';
import { PrintFormat, PrintOrientation } from '@/models/print';
import i18n from '@/i18n';

export default {
  /**
   * Open a url with apps support
   * @param url The url to open
   */
  openLink(url: string, blank = false): void {
    switch (store.getters['session/appPlatform']) {
      case 'android':
        window.Android.openOnBrowser(url);
        break;
      case 'ios':
        window.webkit.messageHandlers.openOnBrowser.postMessage(url);
        break;
      case 'electron':
        window.location.href = url;
        break;
      default:
        if (blank) {
          window.open(url);
        } else {
          window.location.href = url;
        }
    }
  },
  stagingLabel(
    maxiBeds: number,
    beds: number,
    deckChairs: number,
    chairs: number,
    separator = '',
  ): string {
    const staging = [] as Array<string>;
    if (maxiBeds > 0) {
      staging.push(`${maxiBeds}${i18n.global.t('stagingLabel.maxibed')}`);
    }
    if (beds > 0) {
      staging.push(`${beds}${i18n.global.t('stagingLabel.bed')}`);
    }
    if (deckChairs > 0) {
      staging.push(`${deckChairs}${i18n.global.t('stagingLabel.deckchair')}`);
    }
    if (chairs > 0) {
      staging.push(`${chairs}${i18n.global.t('stagingLabel.chair')}`);
    }
    return staging.join(separator);
  },
  customerLabel(firstName: string | null, lastName: string | null): string {
    let customer = '';
    if (firstName || lastName) {
      if (firstName) {
        customer += firstName;
      }
      if (firstName && lastName) {
        customer += ' ';
      }
      if (lastName) {
        customer += lastName;
      }
    } else {
      customer = i18n.global.t('customerLabel.noName');
    }
    return customer;
  },
  /**
   * Get spot attributes with informations provided.
   * Priority check order (lowest to highest):
   * Free -> Sharing -> END
   * Busy -> First/Last day -> Seasonal
   * -> Half day -> Temporary -> Sharing
   * -> Unpaid -> Unpaid last -> Overbooking -> END
   * @param payload The spot informations
   * @returns The spot attributes
   */
  spotAttributes(payload: SpotAttributesPayload): Array<SpotAttribute> {
    let attributes = [];
    if (has(payload, 'free') && payload.free) {
      /**
       * Spot is free, check for sharing only
       */
      // spot free
      attributes.push(SpotAttribute.FREE);
      // spot sharing
      if (has(payload, 'sharing') && payload.sharing) {
        attributes.push(SpotAttribute.SHARING);
      }
    } else {
      /**
       * Spot is busy
       */
      // spot busy
      attributes.push(SpotAttribute.BUSY);
      // spot reserved first/last day (exclude daily)
      if (
        has(payload, 'startDate') &&
        has(payload, 'endDate') &&
        has(payload, 'startDateRef') &&
        has(payload, 'startDateRef') &&
        !payload.startDate?.equals(payload.endDate as DateTime)
      ) {
        if (payload.startDate?.equals(payload.startDateRef as DateTime)) {
          attributes.push(SpotAttribute.BUSY_FIRST_DAY);
        }
        if (payload.endDate?.equals(payload.endDateRef as DateTime)) {
          attributes.push(SpotAttribute.BUSY_LAST_DAY);
        }
      }
      // spot seasonal
      if (has(payload, 'seasonal') && payload.seasonal) {
        attributes = [SpotAttribute.SEASONAL];
      }
      // spot half day
      if (has(payload, 'halfDay')) {
        if (payload.halfDay === ReservationHalfDay.MORNING) {
          attributes = [SpotAttribute.HALF_DAY_MORNING];
        }
        if (payload.halfDay === ReservationHalfDay.AFTERNOON) {
          attributes = [SpotAttribute.HALF_DAY_AFTERNOON];
        }
      }
      // spot half day full
      if (has(payload, 'fullDay')) {
        if (payload.fullDay) {
          attributes = [SpotAttribute.FULL_DAY];
        }
      }
      // spot temporary
      if (has(payload, 'temporary') && payload.temporary) {
        attributes = [SpotAttribute.TEMPORARY];
      }
      // spot sharing
      if (has(payload, 'sharing') && payload.sharing) {
        attributes.push(SpotAttribute.SHARING);
      }
      // spot paid
      if (has(payload, 'paid') && !payload.paid) {
        attributes.push(SpotAttribute.UNPAID);
      }
      // spot unpaid last day
      if (
        has(payload, 'paid') &&
        !payload.paid &&
        has(payload, 'endDate') &&
        has(payload, 'endDateRef') &&
        payload.endDate?.equals(payload.endDateRef as DateTime)
      ) {
        attributes.push(SpotAttribute.UNPAID_LAST_DAY);
      }
      // spot overbooking
      if (has(payload, 'overbooking') && payload.overbooking) {
        attributes.push(SpotAttribute.OVERBOOKING);
      }
    }
    return attributes;
  },
  spotStyleAttribute(attributes: Array<SpotAttribute>): StyleSpotAttribute {
    const spotStyle = store.getters['style/spot'];
    const style = {} as StyleSpotAttribute;
    attributes.map((attribute: SpotAttribute) => {
      merge(style, spotStyle[attribute]);
    });
    return style;
  },
  seasonalDates(): Array<DateTime> {
    const license: License = store.getters['session/license'];
    const startDate = DateTime.utc(
      DateTime.now().year,
      Number(license.seasonalStartDate?.split('-')[1]),
      Number(license.seasonalStartDate?.split('-')[0]),
    );
    const endDate = DateTime.utc(
      DateTime.now().year,
      Number(license.seasonalEndDate?.split('-')[1]),
      Number(license.seasonalEndDate?.split('-')[0]),
    );
    return [startDate, endDate];
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  loadScript(
    id: string,
    src?: string,
    content?: string,
    onload = () => {
      /** */
    },
  ): void {
    // no src or content, return
    if (!src && !content) return;

    // no head, return
    const head = document.getElementsByTagName('head')[0];
    if (document.getElementById(id)) {
      return;
    }

    const js = document.createElement('script');
    js.id = id;
    if (src) {
      js.src = src;
    } else {
      js.innerHTML = content as string;
    }
    // js.onreadystatechange = () => {
    //   if (js.readyState == "complete") callback();
    // };
    js.onload = onload;
    head.appendChild(js);
  },
  printHtml(
    html: HTMLElement,
    stylesheets: Array<string> = [],
    cloneHead = false,
    format = PrintFormat.A4,
    orientation = PrintOrientation.PORTRAIT,
  ): void {
    // Create iframe
    const iframe = window.document.createElement('iframe');
    iframe.setAttribute('src', 'about:blank');
    iframe.setAttribute(
      'style',
      'visibility:hidden;width:0;height:0;position:absolute;z-index:-9999;bottom:0;',
    );
    iframe.setAttribute('width', '0');
    iframe.setAttribute('height', '0');
    iframe.setAttribute('wmode', 'opaque');

    window.document.body.appendChild(iframe);

    const { contentWindow, contentDocument } = iframe;
    if (!contentDocument || !contentWindow) return;

    const doc = contentWindow.document;
    if (!doc) return;

    iframe.addEventListener(
      'load',
      () => {
        const result = contentWindow.document.execCommand('print', false);
        if (!result) {
          contentWindow.print();
        }
      },
      false,
    );

    contentWindow.addEventListener('afterprint', () => {
      window.document.body.removeChild(iframe);
    });

    doc.open();
    doc.write('<!DOCTYPE html>');

    const htmlElement: Node = doc.createElement('html');

    const head: Node = cloneHead
      ? document.getElementsByTagName('head')[0].cloneNode(true)
      : document.createElement('head');
    const body: Node = doc.createElement('body');
    // eslint-disable-next-line no-restricted-syntax
    htmlElement.appendChild(head);
    htmlElement.appendChild(body);
    doc.appendChild(htmlElement);

    // setup format/orientation
    const printStyle = document.createElement('style');
    printStyle.media = 'print';
    printStyle.innerText = `@page { size: ${format} ${orientation}; } .no-print { display: none; } .page-break-after { page-break-after: always; } img { width: 13px; margin-right: 4px; } .statistics-card { display: flex; gap: 8px; }`;
    doc.head.appendChild(printStyle);

    // append styles to head
    stylesheets.forEach((stylesheet: string) => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.type = 'text/css';
      link.href = stylesheet;
      doc.head.appendChild(link);
    });

    // Append the body
    doc.body.appendChild(html.cloneNode(true));

    // Close the document
    doc.close();
  },
  percentage(value: number, percentage: number, returnValue = false): number {
    if (percentage === 0) {
      return value;
    }
    const percentageValue = (value / 100) * percentage;
    return round(returnValue ? value + percentageValue : percentageValue, 2);
  },
};
