/**
 * Permissions service
 */

import axios from 'axios';
import type {
  ApiFeaturesContract,
  ApiUserPermissionsResponse,
} from '@/models/api';
import apiService from '@/services/apiService';

export default {
  async list(license: string): Promise<ApiUserPermissionsResponse> {
    const res = await axios.get(apiService.permissions.list(license));
    return res.data;
  },
  async featuresContractList(license: string): Promise<ApiFeaturesContract> {
    const res = await axios.get(apiService.roles.featuresContractList(license));
    return res.data;
  },
};
