/* eslint-disable import/no-cycle */
import axios, { AxiosResponse } from 'axios';
import { ApiPlannerDataPayload, ApiPlannerDataResponse } from '@/models/api';
import apiService from './apiService';
import plannerUtil from '@/utils/plannerUtil';
import { PlannerData, PlannerElement } from '@/models/planner';

export default {
  async data(payload: ApiPlannerDataPayload): Promise<PlannerData> {
    const res: AxiosResponse<ApiPlannerDataResponse> = await axios.get(
      apiService.planner.data(payload),
    );

    const deserializedSpots: Array<PlannerElement> = res.data.result.spots.map(
      (el) => plannerUtil.elementDeserialize(el),
    );

    return {
      spots: deserializedSpots,
    };
  },
};
