
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import {
  ApiReservationDeletePayload,
  ApiReservationDeleteResponse,
} from '@/models/api';
import { Reservation, ReservationDeleteMode } from '@/models/reservation';
import reservationService from '@/services/reservationService';
import { CashFlow, CashFlowMethod } from '@/models/cashFlow';
import { RefundType, REFUND_TYPES_DATA } from '@/models/refund';
import DisjoinAccountButton from '@/components/shared/DisjoinAccountButton.vue';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

export default defineComponent({
  name: 'ReservationDelete',
  components: { DisjoinAccountButton },
  data() {
    return {
      showDialog: false,
      refundAmount: 0,
      refundType: RefundType.NONE,
      refundTypeOptions: cloneDeep(REFUND_TYPES_DATA),
    };
  },
  methods: {
    async onDelete(trash: boolean): Promise<void> {
      /**
       * Delete the reservation
       */
      const mode = trash
        ? ReservationDeleteMode.MTB
        : ReservationDeleteMode.DEL;
      const payload: ApiReservationDeletePayload = {
        id: this.id,
        mode,
        refundType: RefundType.NONE,
      };
      if (this.canRefund && this.refundType !== RefundType.NONE) {
        if (this.refundAmount === 0) {
          this.$spiagge.toast.warn(
            this.$t('reservationDelete.toast.refundError'),
          );
          return;
        }
        payload.refundType = this.refundType;
        payload.refundAmount = this.refundAmount;
      }
      try {
        // If there are propagate reservations, then delete all before delete master
        if (this.propagate) {
          const propagatePromises: Array<
            Promise<ApiReservationDeleteResponse>
          > = [];
          this.propagate.map((propagate: Reservation) => {
            propagatePromises.push(
              reservationService.delete({
                id: propagate.id,
                refundType: RefundType.NONE,
                mode: ReservationDeleteMode.DEL,
              } as ApiReservationDeletePayload),
            );
          });
          await Promise.all(propagatePromises);
        }
        // Delete master reservation
        await this.$store.dispatch('reservation/deleteReservation', payload);
        /**
         * If refund, the success message is handled by the map component.
         * Otherwise display the default toast
         */
        if (this.refundType === RefundType.NONE) {
          this.$spiagge.toast.success(
            this.$t('reservationDelete.toast.deleteSuccess'),
          );
        }
        this.$nextTick(() => {
          // Redirect
          this.$router.push(this.$store.getters['app/returnToRoute'] ?? '/map');
        });
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationDelete.toast.deleteError'),
        );
      }
    },
    onRefundTypeChange(): void {
      /**
       * Reset refund amount when refund type selected is NONE
       */
      if (this.refundType === RefundType.NONE) {
        this.refundAmount = 0;
      }
    },
    hasPermission(action: string): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.reservations,
        action,
      );
    },
  },
  computed: {
    ...mapState('reservation', [
      'id',
      'totals',
      'deleted',
      'propagate',
      'cashFlows',
    ]),
    ...mapState('session', ['permissions', 'license']),
    ...mapGetters('reservation', ['isJointAccount']),
    FEATURE_PERMISSION_ACTION_CONFIG() {
      return FEATURE_PERMISSION_ACTION_CONFIG;
    },
    buttonLabel(): string {
      let buttonLabel = this.$t('button.delete');
      if (this.isJointAccount) {
        buttonLabel = this.$t('reservationDelete.deleteReservations');
      }
      return buttonLabel;
    },
    canRefund(): boolean {
      /**
       * Refund is available only if stripe cashflows are present
       */
      return (
        this.stripeCashFlows.length > 0 &&
        permissionsUtil.isActionPermissionAllowed(
          FEATURE_PERMISSION_CONFIG.reservations,
          FEATURE_PERMISSION_ACTION_CONFIG.reservations.REFUNDS_AUTH,
        )
      );
    },
    stripeCashFlows(): Array<CashFlow> {
      /**
       * Stripe cashflows
       */
      return this.cashFlows.filter(
        (cashFlow: CashFlow) => cashFlow.method === CashFlowMethod.STRIPE,
      );
    },
    refundAmountMax(): number {
      /**
       * Refund max value
       */
      return this.stripeCashFlows.reduce(
        (amount: number, cashFlow: CashFlow) => amount + cashFlow.amount,
        0,
      );
    },
    deleteMessage(): string {
      let deleteMessage = '';
      if (this.deleted) {
        deleteMessage = this.isJointAccount
          ? this.$t('reservationDelete.deleteJointAccountMessage')
          : this.$t('reservationDelete.deleteReservationMessage');
      } else {
        deleteMessage = this.isJointAccount
          ? this.$t('reservationDelete.trashJointAccountMessage')
          : this.$t('reservationDelete.trashReservationMessage');
      }
      return deleteMessage;
    },
  },
});
