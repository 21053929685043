export const NESTED_FIELDS = [
  'periodsConfigurations',
];

export type FieldDataType = 'integer' | 'string'; // TODO

export type FieldDefaultValue = number | string | null;

export enum FieldVisibilityConditionOperator {
  EQUAL = 'equal',
}

export enum FieldVisibilityConditionConnectorType {
  AND = 'AND',
  OR = 'OR',
}

export interface FieldVisibilityConditionComparison {
  field: string;
  operator: FieldVisibilityConditionOperator;
  value: string | number; // TODO improve
}

export interface FieldVisibilityConditionGroup {
  conn: FieldVisibilityConditionConnectorType;
  children: Array<
    FieldVisibilityConditionComparison | FieldVisibilityConditionGroup
  >;
}

export type FieldVisibilityCondition =
  | FieldVisibilityConditionComparison
  | FieldVisibilityConditionGroup;

export interface Field {
  n: string;
  dt: FieldDataType;
  dv: FieldDefaultValue;
  vc: Array<FieldVisibilityCondition>;
  o: Array<number | string | Field>;
  d: boolean;
  min?: number;
  max?: number;
}

export enum FieldType {
  INPUTTEXT = 'input-text',
  INPUTNUMBER = 'input-number',
  DROPDOWN = 'dropdown',
  YES_NO = 'yes/no',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  PRICE_CONFIG_HOLIDAYS = 'FieldPriceConfigHolidays',
  PRICE_CONFIG_PERIODS = 'FieldPriceConfigPeriods',
  PRICE_CONFIG_COMBINATIONS = 'FieldPriceConfigCombinations',
  MULTISELECT = 'multiselect',
  PRICE_CONFIG_THRESHOLD_PERCENTAGE_VALUES = 'FieldPriceConfigThresholdPercentageValues',
  PRICE_CONFIG_SECTOR_COMBO_PIECES = 'FieldPriceConfigSectorCombinationPieces',
}
