
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { PriceList } from '@/models/priceList';
import { Voucher } from '@/models/voucher';

interface SectionStates {
  [key: string]: boolean;
}

export default defineComponent({
  name: 'PriceListList',
  emits: [
    'select-pl',
    'select-voucher',
    'new-list',
    'open-seasonal',
    'new-voucher',
  ],
  props: {
    priceLists: {
      type: Array as () => Array<PriceList>,
      default: [] as Array<PriceList>,
    },
    vouchers: {
      type: Array as () => Array<Voucher>,
      default: [] as Array<Voucher>,
    },
  },
  data() {
    return {
      sectionStates: {
        lists: true,
        vouchers: true,
      } as SectionStates,
      selected: null as number | null,
    };
  },
  computed: {
    ...mapState('app', ['windowHeight']),
    ...mapState('session', ['license']),
  },
  methods: {
    onPriceListClick(priceList: PriceList): void {
      this.selected = priceList.id;
      this.$emit('select-pl', priceList.id);
    },
    onVoucherClick(voucher: Voucher): void {
      this.selected = voucher.id;
      this.$emit('select-voucher', voucher.id);
    },
    isOpen(sectionId: string): boolean {
      return this.sectionStates[sectionId];
    },
    toggleSection(sectionId: string): void {
      this.sectionStates[sectionId] = !this.sectionStates[sectionId];
    },
    onNewListClick(): void {
      this.$emit('new-list');
    },
    onSeasonalClick(): void {
      this.$emit('open-seasonal');
    },
    onNewVoucherClick(): void {
      this.$emit('new-voucher');
    },
  },
});
