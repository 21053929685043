import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    id: "price-lists-price-list-switch",
    class: "dialog",
    modal: "",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('priceListsPriceListSwitch.title')), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-outlined",
        label: _ctx.$t('button.cancel'),
        icon: "spi-times",
        onClick: _ctx.close
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('button.replace'),
        icon: "spi-check",
        onClick: _ctx.onSwitch,
        disabled: _ctx.selectedPriceListId === null
      }, null, 8, ["label", "onClick", "disabled"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Listbox, {
        modelValue: _ctx.selectedPriceListId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedPriceListId) = $event)),
        options: _ctx.priceListsOptions,
        optionValue: "value",
        filter: true,
        optionLabel: "label",
        filterPlaceholder: _ctx.$t('priceListsPriceListSwitch.searchPriceList'),
        listStyle: "height:300px"
      }, null, 8, ["modelValue", "options", "filterPlaceholder"])
    ]),
    _: 1
  }, 8, ["visible"]))
}