/**
 * User models
 */

export enum UserGender {
  MALE,
  FEMALE,
}

export interface UserAddress {
  city: string;
  country: string | null;
  number: string;
  state: string;
  street: string;
  zip: string;
}

export interface UserLinks {
  profile: string;
}

// FIXME!!!!! update interface (changed)
export interface User {
  id: number;
  email: string;
  name: string;
  surname: string;
  // address: UserAddress;
  addressCity: string;
  addressCountry: string | null;
  addressNumber: string;
  addressState: string;
  addressStreet: string;
  addressZip: string;
  image: string;
  language: string;
  gender: UserGender;
  birthday: number;
  lastPasswordUpdate: number;
  timezone: string;
  fbId: number;
  phoneAreaCode: string | null;
  phoneNumber: string;
  housePhoneAreaCode: string;
  housePhoneNumber: string;
  registrationDate: number;
  signatureDate: number;
  links: UserLinks;
}
