
import { defineComponent, PropType } from 'vue';
import {
  MapDecoration,
  MapDecorationType,
  MapDecorationOrientation,
  MapView,
} from '@/models/map';

export default defineComponent({
  name: 'MapDecoration',
  props: {
    cellWidth: {
      type: Number,
      required: true,
    },
    cellHeight: {
      type: Number,
      required: true,
    },
    decoration: {
      type: Object as PropType<MapDecoration>,
      required: true,
    },
    mapView: {
      type: String,
      required: false,
    },
  },
  computed: {
    decorationClasses() {
      const classes: Array<string> = [this.decoration.type];
      if (this.decoration.orientation !== MapDecorationOrientation.NONE) {
        classes.push(this.decoration.orientation);
      }
      if (this.decoration.rotation > 0) {
        classes.push(`rotation-${this.decoration.rotation}`);
      }
      if (this.decoration.backgroundUrl) {
        classes.push('background');
      }
      return classes;
    },
    decorationStyle() {
      return {
        backgroundColor: this.isLine && this.decoration.name.length > 0 ?
          this.decoration.name : this.decoration.backgroundColor,
        backgroundImage: this.decoration.backgroundUrl
          ? `url(${this.decoration.backgroundUrl})`
          : '',
        transform: `rotate(${this.decoration.rotation}deg)`,
        backgroundSize: this.mapView === MapView.FULL && this.isCustom ? '100% 100%' : 'contain',
      };
    },
    mapDecorationStyle(): { [key: string]: string } {
      let width = `${this.width}px`;
      let left = `${this.posX}px`;
      let top = `${this.posY}px`;
      let height = `${this.height}px`;
      if (this.isLabel) {
        width = 'auto';
      }
      if (this.isLine) {
        if (this.decoration.orientation === MapDecorationOrientation.VERTICAL) {
          width = '2px';
          left = `${this.posX + (this.cellWidth - 2) / 2}px`;
        } else {
          height = '2px';
          top = `${this.posY + (this.cellHeight - 2) / 2}px`;
        }
      }
      return {
        position: 'absolute',
        left,
        top,
        height,
        width,
        zIndex: (this.isLabel || this.isLine ? 5 : 1).toString(),
      };
    },
    posX(): number {
      return this.decoration.posX * this.cellWidth;
    },
    posY(): number {
      return this.decoration.posY * this.cellHeight;
    },
    width(): number {
      return this.decoration.width * this.cellWidth;
    },
    height(): number {
      return this.decoration.height * this.cellHeight;
    },
    isLabel(): boolean {
      return this.decoration.type === MapDecorationType.LABEL;
    },
    isLine(): boolean {
      return this.decoration.type === MapDecorationType.LINE;
    },
    isCustom(): boolean {
      return this.decoration.type === MapDecorationType.CUSTOM;
    },
  },
});
