
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { Reservation } from '@/models/reservation';
import reservationService from '@/services/reservationService';
import { AppAction } from '@/models/app';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  ActionPermissionType,
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

export default defineComponent({
  name: 'ReservationAdditionService',
  data() {
    return {};
  },
  methods: {
    hasUpdatePermission(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.reservations,
        FEATURE_PERMISSION_ACTION_CONFIG.reservations.UPDATE,
      );
    },
    composition(addition: Reservation): string {
      let composition = '';
      if (addition.maxiBeds) {
        composition += `${addition.maxiBeds} ${
          addition.maxiBeds === 1
            ? this.$t('reservationAdditionService.maxibed')
            : this.$t('reservationAdditionService.maxibeds')
        }`;
      }
      if (addition.beds) {
        composition += ` ${addition.beds} ${
          addition.beds === 1
            ? this.$t('reservationAdditionService.bed')
            : this.$t('reservationAdditionService.beds')
        }`;
      }
      if (addition.chairs) {
        composition += ` ${addition.chairs} ${
          addition.chairs === 1
            ? this.$t('reservationAdditionService.chair')
            : this.$t('reservationAdditionService.chairs')
        }`;
      }
      if (addition.deckChairs) {
        composition += ` ${addition.deckChairs} ${
          addition.deckChairs === 1
            ? this.$t('reservationAdditionService.deckchair')
            : this.$t('reservationAdditionService.deckchairs')
        }`;
      }
      return composition;
    },
    async onAdd(): Promise<void> {
      try {
        const addition: Reservation = await reservationService.createAddition(
          this.id,
        );
        this.$spiagge.toast.success(
          this.$t('reservationAdditionService.toast.success'),
        );
        this.$store.commit('reservation/setCanExit', true);
        this.$store.commit('app/setAction', AppAction.CREATE_RESERVATION);
        this.$router.push(`/reservation/${addition.id}`);
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationAdditionService.toast.error'),
        );
      }
    },
    total(addition: Reservation): number {
      if (addition.forcedTotal !== null) return addition.forcedTotal;
      return (
        (addition.forcedBeach
          ? addition.forcedBeach
          : addition.listBeach || 0) +
        (addition.listAdditions || 0) +
        (addition.listExpenses || 0) +
        (addition.listServices || 0)
      );
    },
  },
  computed: {
    ...mapState('reservation', ['id', 'startDate', 'endDate']),
    ...mapGetters('reservation', ['isAddition', 'isPlayField']),
    additions(): Array<Reservation> {
      const additions =
        this.$store.getters['reservation/additions'] ??
        ([] as Array<Reservation>);
      return additions.filter((element: Reservation) => !element.deleted);
    },
    enabled(): boolean {
      return !this.isAddition && !this.isPlayField;
    },
  },
});
