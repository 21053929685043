import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  id: "map-views",
  class: "map-control"
}
const _hoisted_2 = { class: "views" }
const _hoisted_3 = { class: "map-views-dropdown-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.views, (v, index) => {
        return (_openBlock(), _createBlock(_component_Button, {
          key: index,
          class: _normalizeClass(["p-button-white view", v.id === _ctx.view ? 'active' : '']),
          icon: v.icon,
          label: v.label,
          onClick: ($event: any) => (_ctx.view = v.id)
        }, null, 8, ["icon", "label", "class", "onClick"]))
      }), 128))
    ]),
    _createVNode(_component_Dropdown, {
      modelValue: _ctx.view,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.view) = $event)),
      options: _ctx.views,
      optionLabel: "label",
      optionValue: "id"
    }, {
      value: _withCtx((slotProps) => [
        _createElementVNode("i", {
          class: _normalizeClass(_ctx.views.find((view) => view.id === slotProps.value).icon)
        }, null, 2),
        _createElementVNode("span", null, _toDisplayString(_ctx.views.find((view) => view.id === slotProps.value).label), 1)
      ]),
      option: _withCtx((slotProps) => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("i", {
            class: _normalizeClass(slotProps.option.icon)
          }, null, 2),
          _createElementVNode("span", null, _toDisplayString(slotProps.option.label), 1)
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "options"])
  ]))
}