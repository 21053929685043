
import { DateTime } from 'luxon';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import {
  ApiStatisticsExtraServicesPayload,
  StatisticsExtraServicesElement,
  StatisticsExtraServicesResponse,
} from '@/models/api';
import extraServicesService from '@/services/statisticService';
import { CalendarRange } from '@/models';
import { StatisticDateMode } from '@/models/statistic';

const ignoredServiceIds = [513, 514, 515, 516];

export default defineComponent({
  name: 'StatisticsExtraServicesView',
  data() {
    return {
      tabs: [
        {
          label: this.$t('statisticsExtraServicesView.list'),
        },
        {
          label: this.$t('statisticsExtraServicesView.chart'),
        },
      ],
      selectedTab: 0,
      chartTabs: [
        {
          label: this.$t('statisticsExtraServicesView.chartValue'),
        },
        {
          label: this.$t('statisticsExtraServicesView.chartQuantity'),
        },
      ],
      selectedChartTab: 0,
      extraServicesValuesData: {
        labels: [],
        datasets: [
          {
            backgroundColor: '#ffc100',
            data: [],
          },
        ],
      },
      extraServicesQuantityData: {
        labels: [],
        datasets: [
          {
            backgroundColor: '#ffc100',
            data: [],
          },
        ],
      },
      horizontalChartOptions: {
        indexAxis: 'y',
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            ticks: {
              color: '#495057',
            },
            grid: {
              color: '#ebedef',
            },
          },
          y: {
            ticks: {
              color: '#495057',
              autoSkip: false,
            },
            grid: {
              color: '#ebedef',
            },
          },
        },
      },
      elements: [] as Array<StatisticsExtraServicesElement>,
      totalQuantity: 0,
      totalValue: 0,
    };
  },
  async beforeMount(): Promise<void> {
    this.$store.commit(
      'statistic/setTitle',
      this.$t('statisticsExtraServicesView.setTitle'),
    );
    this.$store.commit('statistic/setCalendar', [
      DateTime.now().startOf('day'),
      DateTime.now().startOf('day'),
    ]);
    await this.loadExtraServicesData();
  },
  methods: {
    openExtraServiceDetail(serviceId: number): void {
      this.$router.push(`/statistics/extra-service/${serviceId}`);
    },
    async loadExtraServicesData() {
      let res = {} as StatisticsExtraServicesResponse;
      try {
        this.$store.commit('app/setLoading', true);
        res = await extraServicesService.extraServicesStatistic({
          startDate: this.calendar[0].toSeconds(),
          endDate: this.calendar[1].toSeconds(),
          dateMode: StatisticDateMode.ATTENDANCE,
        } as ApiStatisticsExtraServicesPayload);
        this.elements = res.result.services.filter(
          (s) => !ignoredServiceIds.includes(s.id),
        );
        this.totalQuantity = res.result.totalQuantity;
        this.totalValue = res.result.totalValue;

        this.extraServicesValuesData.labels = [
          ...this.itemsLabelsChart,
        ] as unknown as never;
        this.extraServicesValuesData.datasets[0].data = [
          ...this.itemsValuesChart,
        ] as unknown as never;
        this.extraServicesQuantityData.labels = [
          ...this.itemsLabelsChart,
        ] as unknown as never;
        this.extraServicesQuantityData.datasets[0].data = [
          ...this.itemsQuantityChart,
        ] as unknown as never;
      } catch (error) {
        console.error(error);
      } finally {
        this.$store.commit('app/setLoading', false);
      }
    },
    truncateLabel(label: string) {
      return label.length > 30 ? `${label.substring(0, 30)}...` : label;
    },
  },
  computed: {
    ...mapState('app', ['loading']),
    ...mapState('statistic', ['calendar']),
    itemsLabelsChart(): Array<string> {
      return this.elements.map((element: StatisticsExtraServicesElement) =>
        this.truncateLabel(element.name),
      );
    },
    itemsQuantityChart(): Array<number> {
      return this.elements.map(
        (element: StatisticsExtraServicesElement) => element.quantity,
      );
    },
    itemsValuesChart(): Array<number> {
      return this.elements.map(
        (element: StatisticsExtraServicesElement) => element.value,
      );
    },
  },
  watch: {
    calendar(calendar: CalendarRange) {
      if (calendar[1]) {
        this.loadExtraServicesData();
      }
    },
  },
});
