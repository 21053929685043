
import { defineComponent } from 'vue';
import { MapMode, MapShift, MapShiftStep } from '@/models/map';

interface Tooltip {
  icon: string;
  title: string;
  content: string;
}

export default defineComponent({
  name: 'MapTooltips',
  computed: {
    tooltip(): Tooltip {
      const tooltip = {} as Tooltip;
      if (this.mode === MapMode.MULTIPLE_SELECTION) {
        tooltip.title = this.$t('mapTooltips.selectionTitle');
        tooltip.content = this.$t('mapTooltips.selectionContent');
        tooltip.icon = 'spi-multiple-selection';
      }
      if (
        this.mode === MapMode.SHIFT ||
        this.mode === MapMode.PROGRAMMED_SHIFT
      ) {
        tooltip.icon = 'spi-shift';
        if (this.shift.step === MapShiftStep.SELECT_FROM) {
          tooltip.title = this.$t('mapTooltips.shiftFromTitle');
          tooltip.content = this.$t('mapTooltips.shiftFromContent');
        }
        if (this.shift.step === MapShiftStep.SELECT_TO) {
          tooltip.title = this.$t('mapTooltips.shiftToTitle');
          tooltip.content = this.$t('mapTooltips.shiftToContent');
        }
      }
      if (this.mode === MapMode.COPY) {
        tooltip.title = this.$t('mapTooltips.copyTitle');
        tooltip.content = this.$t('mapTooltips.copyContent');
        tooltip.icon = 'spi-multiple-selection';
      }
      return tooltip;
    },
    active(): boolean {
      return !!this.mode;
    },
    mode(): MapMode {
      return this.$store.getters['map/mode'];
    },
    shift(): MapShift {
      return this.$store.getters['map/shift'];
    },
  },
});
