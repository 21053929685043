import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2034b21c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "voucher-view" }
const _hoisted_2 = { class: "mt-3 mb-1" }
const _hoisted_3 = { class: "voucher" }
const _hoisted_4 = { class: "flex-column align-items-start mt-3 mr-3" }
const _hoisted_5 = { class: "flex-column align-items-start mt-3 mr-3" }
const _hoisted_6 = { class: "flex-column align-items-start mt-3 mr-3" }
const _hoisted_7 = { class: "flex-column align-items-start mt-3" }
const _hoisted_8 = { class: "flex-column align-items-start divider mt-3" }
const _hoisted_9 = { class: "flex-column align-items-start mt-3" }
const _hoisted_10 = { class: "voucher" }
const _hoisted_11 = { class: "flex-column align-items-start mt-3 mr-3" }
const _hoisted_12 = { class: "flex-column align-items-start mt-3 mr-3" }
const _hoisted_13 = { class: "flex-column align-items-start mr-3 mt-3" }
const _hoisted_14 = { class: "voucher" }
const _hoisted_15 = { class: "flex-column align-items-start mt-3 mr-3" }
const _hoisted_16 = {
  key: 0,
  class: "flex-column align-items-start mt-3 mr-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.$t(
            `voucherDetails.title.${
              _ctx.mode === 'rename' ? 'rename' : _ctx.isNew ? 'new' : 'edit'
            }`,
          )), 1)
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onFormSubmit && _ctx.onFormSubmit(...args)), ["prevent"])),
      class: "flex flex-column justify-content-between"
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('voucherDetails.voucherName')) + ":", 1),
          _createVNode(_component_InputText, {
            modelValue: _ctx.voucher.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.voucher.name) = $event)),
            type: "text",
            placeholder: _ctx.$t('voucherDetails.voucherNamePlaceholder'),
            style: {"width":"210px","height":"37px"}
          }, null, 8, ["modelValue", "placeholder"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('voucherDetails.voucherList')) + ":", 1),
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.voucher.hotelPriceListId,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.voucher.hotelPriceListId) = $event)),
            options: _ctx.priceLists,
            optionLabel: "name",
            optionValue: "id",
            style: {"width":"210px"}
          }, null, 8, ["modelValue", "options"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('voucherDetails.difference')) + ":", 1),
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.voucher.customerPriceListId,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.voucher.customerPriceListId) = $event)),
            options: _ctx.priceLists,
            optionLabel: "name",
            optionValue: "id",
            style: {"width":"210px"}
          }, null, 8, ["modelValue", "options"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('voucherDetails.mapBackgroundColor')) + ":", 1),
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.voucher.background,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.voucher.background) = $event)),
          options: _ctx.mapBackgroundColor,
          optionLabel: "name",
          optionValue: "code",
          placeholder: _ctx.$t('color.placeholder'),
          style: {"width":"210px"}
        }, null, 8, ["modelValue", "options", "placeholder"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('voucherDetails.business')) + ":", 1),
        _createVNode(_component_InputText, {
          modelValue: _ctx.voucher.workId,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.voucher.workId) = $event)),
          type: "number",
          min: "0",
          step: "1",
          style: {"width":"210px","height":"37px"},
          class: "mb-3"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('voucherDetails.hotelEmail')) + ":", 1),
        _createVNode(_component_InputText, {
          modelValue: _ctx.voucher.email,
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.voucher.email) = $event)),
          type: "text",
          style: {"width":"210px","height":"37px"},
          placeholder: "example@spiagge.it"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('voucherDetails.purchasable')) + ":", 1),
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.voucher.onlineMode,
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.voucher.onlineMode) = $event)),
            options: _ctx.onlineStatus,
            optionLabel: "label",
            optionValue: "value",
            style: {"width":"210px"}
          }, null, 8, ["modelValue", "options"])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('voucherDetails.onlinePayment')) + ":", 1),
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.voucher.onlinePayment,
            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.voucher.onlinePayment) = $event)),
            options: _ctx.onlineStatus,
            optionLabel: "label",
            optionValue: "value",
            style: {"width":"210px"}
          }, null, 8, ["modelValue", "options"])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('voucherDetails.onlineDiscount')) + ":", 1),
          _createVNode(_component_InputNumber, {
            modelValue: _ctx.voucher.onlineDiscount,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.voucher.onlineDiscount) = $event)),
            showButtons: "",
            suffix: " %",
            min: 0,
            max: 100,
            placeholder: "0 %",
            style: {"width":"210px"}
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_Button, {
            type: "submit",
            label: _ctx.$t('button.save'),
            icon: "spi-save",
            class: "p-button p-button-primary btn",
            style: {"width":"210px"}
          }, null, 8, ["label"])
        ]),
        (!_ctx.isNew)
          ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
              _createVNode(_component_Button, {
                label: _ctx.$t('button.delete'),
                icon: "spi-trash",
                onClick: _ctx.onDeleteVoucher,
                class: "p-button p-button-danger btn",
                style: {"width":"210px"}
              }, null, 8, ["label", "onClick"])
            ]))
          : _createCommentVNode("", true)
      ])
    ], 32)
  ]))
}