import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  id: "statistics-departures-tomorrow-view",
  class: "statistics-view view"
}
const _hoisted_2 = {
  key: 0,
  id: "cards-slider"
}
const _hoisted_3 = { id: "table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsCard = _resolveComponent("StatisticsCard")!
  const _component_Slider = _resolveComponent("Slider")!
  const _component_StatisticsDataTable = _resolveComponent("StatisticsDataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hasPermission())
      ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
          _createVNode(_component_Slider, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card, index) => {
                return (_openBlock(), _createBlock(_component_StatisticsCard, {
                  key: index,
                  label: card.label,
                  value: card.value,
                  tooltip: card.tooltip || ''
                }, null, 8, ["label", "value", "tooltip"]))
              }), 128))
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("section", _hoisted_3, [
      _createVNode(_component_StatisticsDataTable, {
        id: _ctx.id,
        columns: _ctx.columns,
        searchColumns: _ctx.searchColumns,
        defaultView: _ctx.defaultView,
        endpoint: _ctx.endpoint,
        basePayload: _ctx.basePayload,
        rowClass: _ctx.rowClass,
        onRowClick: _ctx.openReservation,
        tooltipText: _ctx.tooltipText,
        onResult: _ctx.onResult,
        exportEndpoint: _ctx.exportEndpoint,
        icons: _ctx.icons
      }, null, 8, ["id", "columns", "searchColumns", "defaultView", "endpoint", "basePayload", "rowClass", "onRowClick", "tooltipText", "onResult", "exportEndpoint", "icons"])
    ])
  ]))
}