import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4f96f4b5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "top-bar-new-reservation" }
const _hoisted_2 = {
  key: 1,
  class: "overlay"
}
const _hoisted_3 = { class: "p-3 ml-2 mr-2 new-reservation" }
const _hoisted_4 = { class: "pr-7 mb-4" }
const _hoisted_5 = {
  id: "default-search",
  class: "mt-3 mb-3"
}
const _hoisted_6 = { class: "flex flex-row" }
const _hoisted_7 = { class: "col-6 pl-0 pr-2 p-3" }
const _hoisted_8 = { class: "p-field" }
const _hoisted_9 = { for: "from-field" }
const _hoisted_10 = { class: "col-6 pr-0 pl-2 p-3" }
const _hoisted_11 = { class: "p-field" }
const _hoisted_12 = { for: "to-field" }
const _hoisted_13 = { class: "days-duration mb-4" }
const _hoisted_14 = { class: "grid" }
const _hoisted_15 = { class: "col-6" }
const _hoisted_16 = { class: "p-field-checkbox" }
const _hoisted_17 = { for: "seasonal-field" }
const _hoisted_18 = { class: "col-6" }
const _hoisted_19 = { class: "p-field-checkbox" }
const _hoisted_20 = { for: "half-day-field" }
const _hoisted_21 = {
  key: 0,
  class: "smart-search-switch mb-4"
}
const _hoisted_22 = { for: "smart-search-toggle" }
const _hoisted_23 = {
  key: 1,
  id: "smart-search",
  class: "mb-4"
}
const _hoisted_24 = { class: "grid mb-4" }
const _hoisted_25 = { class: "col-12 sm:col-6 sm:pb-0" }
const _hoisted_26 = { class: "p-field" }
const _hoisted_27 = { for: "sectors-field" }
const _hoisted_28 = { class: "col-12 sm:col-6 pb-0" }
const _hoisted_29 = { class: "p-field" }
const _hoisted_30 = { for: "max-swaps-field" }
const _hoisted_31 = { class: "field-radiobutton mb-4" }
const _hoisted_32 = { for: "default-staging-field" }
const _hoisted_33 = { class: "mb-4" }
const _hoisted_34 = { class: "field-radiobutton" }
const _hoisted_35 = { for: "custom-staging-field" }
const _hoisted_36 = {
  key: 0,
  class: "grid"
}
const _hoisted_37 = { class: "col-6" }
const _hoisted_38 = { class: "col-6" }
const _hoisted_39 = { class: "col-6 pb-0" }
const _hoisted_40 = { class: "col-6 pb-0" }
const _hoisted_41 = { class: "grid" }
const _hoisted_42 = { class: "col-6 pb-0" }
const _hoisted_43 = { class: "col-6 pb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_RadioButton = _resolveComponent("RadioButton")!
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.canCreateReservations())
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          label: _ctx.buttonLabel,
          icon: _ctx.isSmartSearch ? '' : 'pi pi-plus',
          iconPos: "left",
          class: _normalizeClass(_ctx.buttonClasses),
          onClick: _ctx.onNewReservation
        }, null, 8, ["label", "icon", "class", "onClick"]))
      : _createCommentVNode("", true),
    (_ctx.show)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Button, {
              icon: "pi pi-times",
              class: "p-button-rounded p-button-text p-button-plain close-button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeOverlay()))
            }),
            _createElementVNode("h6", _hoisted_4, _toDisplayString(_ctx.$t('topBarNewReservation.title')), 1),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('common.from')), 1),
                    _createVNode(_component_Calendar, {
                      id: "from-field",
                      modelValue: _ctx.fromCalendar,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.fromCalendar) = $event)),
                      showIcon: "",
                      onDateSelect: _ctx.onFromSelect,
                      disabled: _ctx.model.seasonal,
                      dateFormat: "dd/mm/yy"
                    }, null, 8, ["modelValue", "onDateSelect", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('common.to')), 1),
                    _createVNode(_component_Calendar, {
                      id: "to-field",
                      modelValue: _ctx.toCalendar,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.toCalendar) = $event)),
                      showIcon: "",
                      disabled: _ctx.model.seasonal,
                      dateFormat: "dd/mm/yy",
                      minDate: _ctx.model.from.toJSDate()
                    }, null, 8, ["modelValue", "disabled", "minDate"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$tc('common.daysCount', _ctx.days)), 1),
              _withDirectives(_createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_Checkbox, {
                      id: "seasonal-field",
                      modelValue: _ctx.model.seasonal,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.seasonal) = $event)),
                      binary: true,
                      onChange: _ctx.onSeasonal
                    }, null, 8, ["modelValue", "onChange"]),
                    _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('topBarNewReservation.seasonalReservation')), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(_component_Checkbox, {
                      id: "half-day-field",
                      modelValue: _ctx.model.halfDay,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.halfDay) = $event)),
                      binary: true,
                      disabled: _ctx.model.seasonal
                    }, null, 8, ["modelValue", "disabled"]),
                    _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t('topBarNewReservation.halfDay')), 1)
                  ])
                ])
              ], 512), [
                [_vShow, false]
              ])
            ]),
            (
            _ctx.permissionsUtil.isActionPermissionAllowed(
              _ctx.FEATURE_PERMISSION_CONFIG.map,
              _ctx.FEATURE_PERMISSION_ACTION_CONFIG.map.SMART_SEARCH,
            )
          )
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                  _createVNode(_component_InputSwitch, {
                    id: "smart-search-toggle",
                    modelValue: _ctx.model.smartSearch,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.smartSearch) = $event))
                  }, null, 8, ["modelValue"]),
                  _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t('topBarNewReservation.smartSearch')), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.model.smartSearch)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("div", _hoisted_26, [
                        _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t('topBarNewReservation.sectors')), 1),
                        _createVNode(_component_MultiSelect, {
                          id: "sectors-field",
                          modelValue: _ctx.model.sectors,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.sectors) = $event)),
                          options: _ctx.sectorsOptions,
                          optionLabel: "label",
                          optionValue: "value",
                          placeholder: _ctx.$t('topBarNewReservation.allSectors')
                        }, null, 8, ["modelValue", "options", "placeholder"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_28, [
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.$t('topBarNewReservation.maxSwaps')), 1),
                        _createVNode(_component_InputNumber, {
                          id: "max-swaps-field",
                          modelValue: _ctx.model.maxSwaps,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.maxSwaps) = $event)),
                          min: 0,
                          max: 10,
                          step: 1,
                          showButtons: ""
                        }, null, 8, ["modelValue"])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _createVNode(_component_RadioButton, {
                      id: "default-staging-field",
                      value: false,
                      modelValue: _ctx.model.customStaging,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.customStaging) = $event))
                    }, null, 8, ["modelValue"]),
                    _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t('topBarNewReservation.defaultStaging')), 1)
                  ]),
                  _createElementVNode("div", _hoisted_33, [
                    _createElementVNode("div", _hoisted_34, [
                      _createVNode(_component_RadioButton, {
                        id: "custom-staging-field",
                        value: true,
                        modelValue: _ctx.model.customStaging,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model.customStaging) = $event))
                      }, null, 8, ["modelValue"]),
                      _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.$t('topBarNewReservation.customStaging')), 1)
                    ]),
                    (_ctx.model.customStaging)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                          _createElementVNode("div", _hoisted_37, [
                            _createVNode(_component_Dropdown, {
                              modelValue: _ctx.model.maxiBeds,
                              "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model.maxiBeds) = $event)),
                              options: [0, 1, 2, 3, 4, 5, 6]
                            }, {
                              value: _withCtx((slotProps) => [
                                _createTextVNode(_toDisplayString(slotProps.value) + " " + _toDisplayString(_ctx.$t('topBarNewReservation.customStagingMaxibeds')), 1)
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_38, [
                            _createVNode(_component_Dropdown, {
                              modelValue: _ctx.model.beds,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model.beds) = $event)),
                              options: [0, 1, 2, 3, 4, 5, 6]
                            }, {
                              value: _withCtx((slotProps) => [
                                _createTextVNode(_toDisplayString(slotProps.value) + " " + _toDisplayString(_ctx.$t('topBarNewReservation.customStagingBeds')), 1)
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_39, [
                            _createVNode(_component_Dropdown, {
                              modelValue: _ctx.model.deckChairs,
                              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model.deckChairs) = $event)),
                              options: [0, 1, 2, 3, 4, 5, 6]
                            }, {
                              value: _withCtx((slotProps) => [
                                _createTextVNode(_toDisplayString(slotProps.value) + " " + _toDisplayString(_ctx.$t('topBarNewReservation.customStagingDeckchairs')), 1)
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_40, [
                            _createVNode(_component_Dropdown, {
                              modelValue: _ctx.model.chairs,
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model.chairs) = $event)),
                              options: [0, 1, 2, 3, 4, 5, 6]
                            }, {
                              value: _withCtx((slotProps) => [
                                _createTextVNode(_toDisplayString(slotProps.value) + " " + _toDisplayString(_ctx.$t('topBarNewReservation.customStagingChairs')), 1)
                              ]),
                              _: 1
                            }, 8, ["modelValue"])
                          ])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_41, [
              _createElementVNode("div", _hoisted_42, [
                _createVNode(_component_Button, {
                  class: "p-button-outlined w-full",
                  label: _ctx.$t('button.cancel'),
                  onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.closeOverlay()))
                }, null, 8, ["label"])
              ]),
              _createElementVNode("div", _hoisted_43, [
                _createVNode(_component_Button, {
                  class: "w-full",
                  label: _ctx.$t('common.search'),
                  icon: "spi-search",
                  onClick: _ctx.onProceed
                }, null, 8, ["label", "onClick"])
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}