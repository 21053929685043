import { ComputedRef, computed } from 'vue';
import { useStore } from 'vuex';
import { DateTime } from 'luxon';
import { RootState } from '@/models/store';

type UsePlannerReturn = {
  zoom: ComputedRef<number>;
  cellWidth: ComputedRef<number>;
  cellHeight: ComputedRef<number>;
  getBgDatePosX: (dateTime: DateTime) => number;
};

export function usePlanner(): UsePlannerReturn {
  const store = useStore<RootState>();

  const zoom = computed(() => store.state.planner.zoom);

  const cellWidth = computed(() => 100 * (zoom.value / 100));
  const cellHeight = computed(
    () => 37 * ((zoom.value + 5 * ((100 - zoom.value) / 10)) / 100),
  );

  return {
    cellWidth,
    cellHeight,
    zoom,

    getBgDatePosX(dateTime) {
      return cellWidth.value * (dateTime.ordinal - 1);
    },
  };
}
