/**
 * Smart Search models
 */

import { DateTime } from 'luxon';
import { SpotBasic } from './spot';

export interface SmartSearchModel {
  from: DateTime;
  to: DateTime;
  seasonal: boolean;
  halfDay: boolean;
  smartSearch: boolean;
  sectors: Array<number>;
  maxSwaps: number;
  customStaging: boolean;
  maxiBeds: number;
  beds: number;
  deckChairs: number;
  chairs: number;
}

export interface SmartSearchSpot extends SpotBasic {
  from: number;
  to: number;
}

export const DEFAULT_SMART_SEARCH_MODEL: SmartSearchModel = {
  from: DateTime.now().startOf('day'),
  to: DateTime.now().startOf('day'),
  seasonal: false,
  halfDay: false,
  smartSearch: false,
  sectors: [],
  maxSwaps: 0,
  customStaging: false,
  maxiBeds: 0,
  beds: 0,
  deckChairs: 0,
  chairs: 0,
};
