import { DateTime } from 'luxon';
import i18n from '@/i18n';
import router from '@/router';
import { Timestamp } from '.';
import { DiscountCode } from './discountCode';
import { Print, Printer } from './printer';
import { SpotType } from './spot';
import { SectorHeader } from '@/models/sector';

export enum ReservationStatus {
  AVAILABLE,
  CONFIRMED,
  NOT_CONFIRMED,
}

export enum ReservationHalfDay {
  DAILY,
  MORNING,
  AFTERNOON,
}

export const RESERVATION_HALF_DAY_DATA = [
  {
    id: ReservationHalfDay.DAILY,
    label: i18n.global.t('reservation.halfDay.daily'),
  },
  {
    id: ReservationHalfDay.MORNING,
    label: i18n.global.t('reservation.halfDay.morning'),
  },
  {
    id: ReservationHalfDay.AFTERNOON,
    label: i18n.global.t('reservation.halfDay.afternoon'),
  },
];

export enum ReservationTransactionType {
  DEPOSIT,
  REFUND,
}

export interface ReservationTransaction {
  type: ReservationTransactionType;
  date: number;
  paymentMethod: string;
  amount: number;
}

/* export interface Reservation {
  from: number;
  to: number;
  seasonal: boolean;
  duration: ReservationHalfDay;
  beds: number;
  chairs: number;
  deckchairs: number;
  guests: Array<ReservationGuest>;
  hotel: string;
  group: string;
} */

export interface ReservationShift {
  from: number;
  spot: string;
  reservation: number;
}

export interface ReservationSpot {
  name: string;
  type: SpotType;
}

export enum ReservationType {
  STANDARD = 1,
  MOVING,
  ABSENCE,
  ADDITION,
  JOINT_ACCOUNT,
  BILL,
}

/* EXPENSE */

export interface ReservationExpenseItem {
  name: string;
  product?: string; // Some external managers save name it like this
  qnt: number;
  price: number;
  vat: number;
}

export enum ReservationExpensePaid {
  NOT_PAID,
  NFIS_PAID,
  FIS_PAID,
}

export enum ReservationExpenseApp {
  FOOD = 'fd',
  SCLOBY = '20',
  ZMENU = 'ZMENU',
  CASSA_IN_CLOUD = 'Cassa in C',
  ASTEN = '.Ofsale gy',
}

export interface ReservationExpenseAppData {
  app: ReservationExpenseApp;
  name: string;
  icon: string;
}

export const RESERVATION_EXPENSE_APP_DATA: Array<ReservationExpenseAppData> = [
  {
    app: ReservationExpenseApp.FOOD,
    name: 'YourFood',
    icon: require('@/assets/images/extra-expenses/food.svg'),
  },
  {
    app: ReservationExpenseApp.SCLOBY,
    name: 'Tilby',
    icon: require('@/assets/images/extra-expenses/tilby.svg'),
  },
  {
    app: ReservationExpenseApp.CASSA_IN_CLOUD,
    name: 'Cassa in Cloud',
    icon: require('@/assets/images/extra-expenses/food.svg'),
  },
  {
    app: ReservationExpenseApp.ASTEN,
    name: 'Offsale',
    icon: require('@/assets/images/extra-expenses/food.svg'),
  },
  {
    app: ReservationExpenseApp.ZMENU,
    name: 'Zmenu',
    icon: require('@/assets/images/extra-expenses/zmenu.png'),
  },
];

export type ReservationExpenseRawDataNormal = Array<ReservationExpenseItem>;
export type ReservationExpenseRawDataObjectWithProducts = {
  products: Array<ReservationExpenseItem>;
};
export type ReservationExpenseRawDataObjectWithoutProducts = {
  [key: number]: ReservationExpenseItem;
} & { signature: string };

type ReservationExpenseRawData =
  | ReservationExpenseRawDataNormal
  | ReservationExpenseRawDataObjectWithProducts
  | ReservationExpenseRawDataObjectWithoutProducts;

export interface ReservationExpenseRaw {
  id: number;
  licenseId: string;
  reservationId: number;
  cashFlowId: number | null;
  date: number;
  app: ReservationExpenseApp;
  refId: string;
  description: string;
  value: number;
  notes: string;
  data: ReservationExpenseRawData;
  vat: number;
  paid: ReservationExpensePaid;
  signature: string | null;
}

export interface ReservationExpense {
  id: number;
  licenseId: string;
  reservationId: number;
  cashFlowId: number | null;
  date: number;
  app: ReservationExpenseApp;
  refId: string;
  description: string;
  value: number;
  notes: string;
  items: Array<ReservationExpenseItem>;
  vat: number;
  paid: ReservationExpensePaid;
  signature: string;
}

/** Email reminder log */
export interface ReminderLog {
  id: number;
  licenseId: number;
  reservationId: number;
  time: number;
  to: string;
  createdAt: number;
  updateAt: number;
}
// interfaccia del Booking temp
export interface ReservationBookingTemporary {
  createdAt: Timestamp;
  licenseId: string;
  reservationId: number;
  updatedAt: Timestamp | null;
}

export interface ReservationWeatherPolicy {
  createdAt: Timestamp;
  id: number;
  licenseId: string;
  order: number;
  paid: boolean;
  policyKey: string;
  price: number;
  transfered: boolean;
  transferedAt: Timestamp | null;
  transferedId: number | null;
  updatedAt: Timestamp;
}

export enum ReservationNoShowPolicyStatus {
  SELECTED,
  EMITTED,
  REDEEMED,
  PROCESSED,
}

export interface ReservationNoShowPolicy {
  createdAt: Timestamp;
  id: number;
  licenseId: string;
  order: number;
  policyNumber: string;
  price: number;
  reservationAmount: number;
  status: ReservationNoShowPolicyStatus;
  transfered: boolean;
  transferedAt: Timestamp | null;
  transferedId: number | null;
  updatedAt: Timestamp;
}

export interface Reservation {
  beds: number;
  cartId: number | null;
  chairs: number;
  channel: number | null; // ?
  children?: Array<Reservation>;
  contactCode: number | null; // ?
  contactId: number | null; // ?;
  createdAt: number;
  deckChairs: number;
  deleted: boolean;
  discount: DiscountCode | null;
  email: string | null;
  endDate: number;
  expenses?: Array<ReservationExpense>;
  firstName: string | null;
  forcedAdditions: number | null;
  forcedBeach: number | null;
  forcedExpenses: number | null;
  forcedServices: number | null;
  forcedTotal: number | null;
  groupon: number;
  halfDay: number;
  hotel: string;
  hotelRoom: string | null;
  id: number;
  invoiceAddress1: string | null;
  invoiceAddress2: string | null;
  invoiceCity: string | null;
  invoiceCompany: string | null;
  invoiceCountry: string | null;
  invoiceDate: string | null;
  invoiceFirstName: string | null;
  invoiceLastName: string | null;
  invoiceLotteryCode: string | null;
  invoiceNumber: string | null;
  invoicePec: string | null;
  invoicePlaceId: string | null;
  invoiceSdi: string | null;
  invoiceState: string | null;
  invoiceTaxCode: string | null;
  invoiceType: string | null;
  invoiceVatCode: string | null;
  invoiceZip: string | null;
  lastName: string | null;
  listAdditions: number | null;
  listBeach: number | null;
  listExpenses: number | null;
  listServices: number | null;
  listTotal: number | null;
  locked: number;
  master?: Reservation | null;
  masterId: number | null;
  maxiBeds: number;
  notes: null;
  online: number;
  paid: number;
  paidAdditions: number | null;
  paidBeach: number | null;
  paidExpenses: number | null;
  paidServices: number | null;
  paidTotal: number | null;
  phoneAreaCode: string | null;
  phoneNumber: null;
  seasonal: number;
  spotName: string;
  spotType: string | null;
  startDate: number;
  status: ReservationStatus;
  topayBeach: null;
  travelGroup: null;
  type: ReservationType;
  updatedAt: number;
  voucherId: number;
  vehiclePlate: string | null;
  overbooking: boolean;
  reminderLogs: Array<ReminderLog>;
  token: string;
  qrCode: string;
  sector: SectorHeader;
  totalPrice: number;
  bookingTemp: ReservationBookingTemporary | null;
  weatherPolicy: ReservationWeatherPolicy | null;
  noShowPolicy: ReservationNoShowPolicy | null;
  isCheckInAvailable: boolean;
  isCheckOutAvailable: boolean;
}

export interface ReservationList {
  id: number;
  firstName: string | null;
  endDate: number;
  startDate: number;
  paid: boolean;
}

export enum ReservationExpandedField {
  MASTER = 'master',
  CHILDREN = 'children',
  CART = 'cart',
  CONTACT = 'contact',
  CASH_FLOWS = 'cashFlows',
  EXPENSES = 'expenses',
  REMINDER_LOGS = 'reminderLogs',
  SERVICES = 'services',
  BOOKING_TEMP = 'bookingTemp',
  WEATHER_POLICY = 'weatherPolicy',
  NOSHOW_POLICY = 'noShowPolicy',
}

export interface ReservationExpanded extends Reservation {
  children: Array<Reservation>;
}

export interface ReservationExtra {
  id: number;
  startDate: number;
  endDate: number;
  price: number;
}

export interface ReservationService {
  serviceId: number;
  bought: number;
  used: number;
  deleted: number;
  paid: number;
  price: number;
  forcedPrice: number;
  reservationId: number;
  createdAt: null;
  updatedAt: null;
}

export interface ReservationTotalSummary {
  additions: number | null;
  beach: number | null;
  expenses: number | null;
  services: number | null;
  total: number | null;
}
export interface ReservationTotals {
  list: ReservationTotalSummary;
  forced: ReservationTotalSummary;
  paid: ReservationTotalSummary;
}

export const DEFAULT_RESERVATION_TOTALS = {
  list: {
    additions: null,
    beach: null,
    expenses: null,
    services: null,
    total: null,
  },
  forced: {
    additions: null,
    beach: null,
    expenses: null,
    services: null,
    total: null,
  },
  paid: {
    additions: null,
    beach: null,
    expenses: null,
    services: null,
    total: null,
  },
};

export interface ReservationDocument {
  id: number;
  licenseId: string;
  reservationId: number;
  userId: number;
  template: string;
  fileName: string;
  url: string;
}

export enum ReservationDeleteMode {
  MTB = 'mtb',
  DEL = 'del',
}

/* RESERVATION ALERT */

export enum ReservationAlertSeverity {
  NOTICE,
  WARNING,
  ERROR,
}

export enum ReservationAlertCode {
  TRASHED = 'trashed',
  LOW_BEDS = 'lowBeds',
  EMPTY_BEDS = 'emptyBeds',
  LOW_MAXI_BEDS = 'lowMaxiBeds',
  EMPTY_MAXI_BEDS = 'emptyMaxiBeds',
  LOW_DECK_CHAIRS = 'lowDeckChairs',
  EMPTY_DECK_CHAIRS = 'emptyDeckChairs',
  LOW_CHAIRS = 'lowChairs',
  EMPTY_CHAIRS = 'emptyChairs',
  OVERBOOKING = 'overbooking',
}

export const RESERVATION_ALERTS_DATA = [
  {
    weight: 1,
    code: ReservationAlertCode.TRASHED,
    title: i18n.global.t('reservation.alert.trashed'),
    severity: ReservationAlertSeverity.ERROR,
    cta: {
      label: i18n.global.t('reservation.alert.cta.restore'),
      action: (): void => {
        // setted on ReservationAlerts components
      },
    },
  },
  {
    weight: 2,
    code: ReservationAlertCode.OVERBOOKING,
    title: i18n.global.t('reservation.alert.overbooking'),
    severity: ReservationAlertSeverity.ERROR,
  },
  {
    weight: 3,
    code: ReservationAlertCode.EMPTY_BEDS,
    title: i18n.global.t('reservation.alert.emptyBeds'),
    severity: ReservationAlertSeverity.ERROR,
    cta: {
      label: i18n.global.t('reservation.alert.cta.warehouse'),
      action: (): void => {
        router.push('/settings/warehouse');
      },
    },
  },
  {
    weight: 4,
    code: ReservationAlertCode.EMPTY_MAXI_BEDS,
    title: i18n.global.t('reservation.alert.emptyMaxiBeds'),
    severity: ReservationAlertSeverity.ERROR,
    cta: {
      label: i18n.global.t('reservation.alert.cta.warehouse'),
      action: (): void => {
        router.push('/settings/warehouse');
      },
    },
  },
  {
    weight: 5,
    code: ReservationAlertCode.EMPTY_DECK_CHAIRS,
    title: i18n.global.t('reservation.alert.emptyDeckchairs'),
    severity: ReservationAlertSeverity.ERROR,
    cta: {
      label: i18n.global.t('reservation.alert.cta.warehouse'),
      action: (): void => {
        router.push('/settings/warehouse');
      },
    },
  },
  {
    weight: 6,
    code: ReservationAlertCode.EMPTY_CHAIRS,
    title: i18n.global.t('reservation.alert.emptyChairs'),
    severity: ReservationAlertSeverity.ERROR,
    cta: {
      label: i18n.global.t('reservation.alert.cta.warehouse'),
      action: (): void => {
        router.push('/settings/warehouse');
      },
    },
  },
  // warning
  {
    weight: 1,
    code: ReservationAlertCode.LOW_BEDS,
    title: i18n.global.t('reservation.alert.lowBeds'),
    severity: ReservationAlertSeverity.WARNING,
    cta: {
      label: i18n.global.t('reservation.alert.cta.warehouse'),
      action: (): void => {
        router.push('/settings/warehouse');
      },
    },
  },
  {
    weight: 2,
    code: ReservationAlertCode.LOW_MAXI_BEDS,
    title: i18n.global.t('reservation.alert.lowMaxiBeds'),
    severity: ReservationAlertSeverity.WARNING,
    cta: {
      label: i18n.global.t('reservation.alert.cta.warehouse'),
      action: (): void => {
        router.push('/settings/warehouse');
      },
    },
  },
  {
    weight: 3,
    code: ReservationAlertCode.LOW_DECK_CHAIRS,
    title: i18n.global.t('reservation.alert.lowDeckchairs'),
    severity: ReservationAlertSeverity.WARNING,
    cta: {
      label: i18n.global.t('reservation.alert.cta.warehouse'),
      action: (): void => {
        router.push('/settings/warehouse');
      },
    },
  },
  {
    weight: 4,
    code: ReservationAlertCode.LOW_CHAIRS,
    title: i18n.global.t('reservation.alert.lowChairs'),
    severity: ReservationAlertSeverity.WARNING,
    cta: {
      label: i18n.global.t('reservation.alert.cta.warehouse'),
      action: (): void => {
        router.push('/settings/warehouse');
      },
    },
  },
];

export interface ReservationAlert {
  title: string;
  description?: string;
  cta?: {
    label: string;
    action: () => void;
  };
  severity: ReservationAlertSeverity;
  code: ReservationAlertCode;
  weight: number;
}

export interface ReservationFiscalPrint {
  reservationId: number;
  startedAt: DateTime;
  printer: Printer;
  print: Print;
  mode: string;
  callback: () => Promise<void>;
}

export interface PaymentLinkFeesPreview {
  preview: {
    feesAmount: number;
    totalPrice: number;
    leftover: number;
  };
}

export enum OrderSummaryProviderType {
  WHATSAPP = 'whatsapp',
  EMAIL = 'email',
}

export interface OrderSummaryProviderData {
  type: OrderSummaryProviderType;
  label: string;
  icon: string;
  title: string;
}
