/* eslint-disable import/no-dynamic-require */

import {
  MapDecoration,
  MapDecorationOrientation,
  MapDecorationType,
  MapElementTypeRaw,
} from '@/models/map';
import { MapEditorElementRaw, MapEditorSpot } from '@/models/mapEditor';
import { SpotType } from '@/models/spot';

export default {
  /**
   * Convert a raw element into formatted decoration
   * @param {MapElementRaw} rawElement
   * @return {MapDecoration}
   */
  decorationDeserialize(rawElement: MapEditorElementRaw): MapDecoration {
    const decoration = {
      id: rawElement.id,
    } as MapDecoration;

    try {
      decoration.name = rawElement.name || '';
      decoration.posX = rawElement.posX;
      decoration.posY = rawElement.posY;

      if (rawElement.data) {
        decoration.height = Number(rawElement.data.height) || 1;
        decoration.width = Number(rawElement.data.width) || 1;
        decoration.rotation = Number(rawElement.data.rotation) || 0;
        decoration.backgroundUrl = rawElement.data.url || '';
      } else {
        decoration.height = 1;
        decoration.width = 1;
        decoration.rotation = 0;
        decoration.backgroundUrl = '';
      }

      decoration.orientation = MapDecorationOrientation.NONE;
      decoration.backgroundColor = '';

      if (rawElement.type === MapElementTypeRaw.ELEMENT) {
        decoration.type = rawElement.data
          ?.elementType as unknown as MapDecorationType;
        if (decoration.type !== MapDecorationType.CUSTOM) {
          decoration.backgroundUrl = require(`@/assets/images/map/decorations/${rawElement.data?.elementType}.svg`);
        }
      }

      if (rawElement.type === MapElementTypeRaw.FOOTBOARD) {
        decoration.type = MapDecorationType.FOOTBOARD;
        decoration.orientation =
          rawElement.name === 'v'
            ? MapDecorationOrientation.VERTICAL
            : MapDecorationOrientation.HORIZONTAL;
        decoration.backgroundUrl = require(`@/assets/images/map/decorations/footboard-${decoration.orientation}.svg`);
      }

      if (
        [MapElementTypeRaw.MAP_LINE_H, MapElementTypeRaw.MAP_LINE_V].includes(
          rawElement.type,
        )
      ) {
        decoration.backgroundColor = decoration.name;
        decoration.name = '';
        decoration.type = MapDecorationType.LINE;
        decoration.orientation = MapDecorationOrientation.HORIZONTAL;
        if (rawElement.type === MapElementTypeRaw.MAP_LINE_H) {
          decoration.orientation = MapDecorationOrientation.HORIZONTAL;
        } else {
          decoration.orientation = MapDecorationOrientation.VERTICAL;
        }
      }

      if (rawElement.type === MapElementTypeRaw.MAP_LABEL) {
        decoration.type = MapDecorationType.LABEL;
        decoration.name = rawElement.name;
      }
    } catch (e) {
      // console.log(e);
    }
    return decoration;
  },
  /**
   * Convert a raw element into formatted spot
   * @param {MapElementRaw} rawElement
   * @return {MapSpot}
   */
  spotDeserialize(rawElement: MapEditorElementRaw): MapEditorSpot {
    const spot = {
      id: rawElement.id,
    } as MapEditorSpot;

    try {
      spot.type = rawElement.type as unknown as SpotType;
      spot.name = rawElement.name;
      spot.posX = rawElement.posX;
      spot.posY = rawElement.posY;
      spot.width = 1;
      spot.height = 1;
      spot.online = Boolean(rawElement.online);
      spot.priceListId = rawElement.priceListId;
      spot.priceListOnlineId = rawElement.priceListOnlineId;
      spot.sector = rawElement.sector;
      spot.background =
        rawElement.data && rawElement.data['background-color']
          ? rawElement.data['background-color']
          : '';
      spot.onlineNotAvailableDays =
        rawElement.jsonData && rawElement.jsonData.onlineNotAvailableDays
          ? Object.keys(rawElement.jsonData.onlineNotAvailableDays)
          : [];
      spot.timeSlot = rawElement?.data?.timeSlot || '';
    } catch (e) {
      // console.log(e);
    }
    return spot;
  },
};
