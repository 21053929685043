
import { defineComponent, PropType } from 'vue';
import { Field, Form } from 'vee-validate';
import { cloneDeep } from 'lodash';
import {
  ExtraDaysMode,
  EXTRA_DAYS_MODE_OPTIONS,
  PeriodsShiftMode,
  PERIODS_SHIFT_MODE_OPTIONS,
  PriceList,
} from '@/models/priceList';
import {
  ApiPriceListCreatePayload,
  ApiPriceListUpdatePayload,
} from '@/models/api';
import priceListService from '@/services/priceListService';
import InputTooltip from '@/components/shared/InputTooltip.vue';

export default defineComponent({
  name: 'PriceListsPriceListCreate',
  emits: ['update', 'close'],
  components: { Field, Form, InputTooltip },
  props: {
    priceList: {
      type: Object as PropType<PriceList>,
      required: false,
    },
  },
  data() {
    return {
      model: {} as ApiPriceListCreatePayload | ApiPriceListUpdatePayload,
      extraDaysModeOptions: cloneDeep(EXTRA_DAYS_MODE_OPTIONS),
      periodsShiftModeOptions: cloneDeep(PERIODS_SHIFT_MODE_OPTIONS),
    };
  },
  beforeMount(): void {
    this.initModel();
  },
  methods: {
    initModel(): void {
      /**
       * Init model
       */
      if (this.priceList) {
        this.model = {
          name: this.priceList.name,
          listDaysLength: this.priceList.listDaysLength,
          extraDaysMode: this.priceList.extraDaysMode,
          periodsShiftMode: this.priceList.periodsShiftMode,
        } as ApiPriceListUpdatePayload;
      } else {
        this.model = {
          name: '',
          listDaysLength: 31,
          extraDaysMode: ExtraDaysMode.STANDARD,
          periodsShiftMode: PeriodsShiftMode.SIMPLE,
        } as ApiPriceListCreatePayload;
      }
    },
    onSave(): void {
      if (this.priceList) {
        this.update();
      } else {
        this.create();
      }
    },
    async create(): Promise<void> {
      try {
        const priceList: PriceList = await priceListService.create(this.model);
        this.$spiagge.toast.success(
          this.$t('priceListsPriceListCreate.toast.createSuccess'),
        );
        this.close();
        this.$router.push(`/price-lists/${priceList.id}`);
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('priceListsPriceListCreate.toast.createError'),
        );
      }
    },
    async update(): Promise<void> {
      try {
        const priceList: PriceList = await priceListService.update(
          (this.priceList as PriceList).id,
          this.model,
        );
        await this.$store.dispatch('priceLists/getPriceLists');
        this.$emit('update', priceList);
        this.$spiagge.toast.success(
          this.$t('priceListsPriceListCreate.toast.updateSuccess'),
        );
        this.close();
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('priceListsPriceListCreate.toast.updateError'),
        );
      }
    },
    close(): void {
      this.visible = false;
    },
  },
  computed: {
    visible: {
      get(): boolean {
        return true;
      },
      set(visible: boolean): void {
        this.$emit('close');
        this.$store.commit('priceLists/setShowPriceListCreateDialog', visible);
      },
    },
  },
});
