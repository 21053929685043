/**
 * Local util
 */

import { Locale, LOCALES } from '@/models/locale';
import { SPIAGGE_COOKIES } from '@/models/cookies';
import cookieUtil from '@/utils/cookieUtil';
import i18n from '@/i18n';

export default {
  /**
   * Return supported locales
   * Supported locale must have relative flag enabled and json file
   *
   * @returns {Locale[]}
   */
  getSupportedLocales(): Locale[] {
    const jsonLocales = require
      .context('@/i18n', true, /[A-Za-z0-9-_,\s]+\.json$/i)
      .keys()
      .map((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
          return matched[1];
        }
      });
    return LOCALES.filter(
      (locale: Locale) => locale.supported && jsonLocales.includes(locale.id),
    );
  },

  /**
   * Return locale for current browser
   *
   * @returns {Locale | undefined}
   */
  getBrowserLocale(): Locale | undefined {
    const navigatorLocale =
      navigator.languages !== undefined
        ? navigator.languages[0]
        : navigator.language;

    if (!navigatorLocale) {
      return undefined;
    }
    const browserLocale = navigatorLocale.trim().split(/-|_/)[0];
    return this.getSupportedLocales().find(
      (locale: Locale) => locale.id === browserLocale,
    );
  },

  /**
   * Return locale by id
   *
   * @param id
   * @returns {Locale | undefined}
   */
  getLocaleById(id: string): Locale | undefined {
    return this.getSupportedLocales().find(
      (locale: Locale) => locale.id === id,
    );
  },

  /**
   * Return the default locale
   * App default locale -> browser locale -> cookie locale
   *
   * @returns {Locale}
   */
  getDefaultLocale(): Locale {
    let locale: Locale = this.getLocaleById('it') as Locale;
    const browserLocale: Locale | undefined = this.getBrowserLocale();
    if (browserLocale) {
      locale = browserLocale;
    }
    const langCookie = cookieUtil.get(SPIAGGE_COOKIES.LOCALE);
    if (langCookie) {
      const cookieLocale = this.getLocaleById(langCookie);
      if (cookieLocale) {
        locale = cookieLocale;
      }
    }
    return locale;
  },

  /**
   * Init app locale
   * Locale is already set to vue18n
   */
  initLocale(): Locale {
    const defaultLocale: Locale = this.getDefaultLocale() as Locale;
    this.setLocale(defaultLocale);
    return defaultLocale;
  },

  /**
   * Set locale
   * @param locale
   */
  setLocale(locale: Locale): void {
    cookieUtil.set(SPIAGGE_COOKIES.LOCALE, locale.id);
    i18n.global.locale = locale.id;
    const htmlElement: HTMLHtmlElement | null = document.querySelector('html');
    if (htmlElement) {
      htmlElement.setAttribute('lang', locale.id);
    }
  },
};
