
import { defineComponent } from 'vue';
import { Form, Field } from 'vee-validate';
import * as yup from 'yup';
import { cloneDeep, pick, clone } from 'lodash';
import { mapState } from 'vuex';
import posTerminalService from '@/services/posTerminalService';
import {
  POS_TERMINAL_MODEL_DATA,
  PosTerminal,
  PosTerminalModel,
} from '@/models/posTerminal';
import { ApiPosTerminalCreatePayload } from '@/models/api';
import { DropdownOption } from '@/models';
import { Sector, SectorHeader } from '@/models/sector';

type PosTerminalFormModel = ApiPosTerminalCreatePayload;

const DEFAULT_POS_TERMINAL_MODEL = {
  name: '',
  model: PosTerminalModel.STRIPE,
  posIdentifier: '',
  sectors: [],
} as PosTerminalFormModel;

export default defineComponent({
  name: 'SettingsPosTerminalView',
  components: { Form, Field },
  data() {
    return {
      posTerminalId: 0,
      posTerminalFormModel: {} as PosTerminalFormModel,
      showDialog: false,
      posTerminalModelOptions: clone(POS_TERMINAL_MODEL_DATA),
      sectorsOptions: [] as Array<DropdownOption>,
      validationSchema: yup.object({
        name: yup.string().required(),
        posIdentifier: yup.string().required(),
        sectors: yup.array().min(1),
      }),
    };
  },
  async beforeMount(): Promise<void> {
    /**
     * Get POS terminals
     */
    await this.$store.dispatch('session/setSectors');
    await this.getPosTerminals();
  },
  mounted(): void {
    /**
     * Build sectors options
     */
    this.sectorsOptions = this.sectors.map(
      (sector: Sector) =>
        ({
          label: sector.header.name,
          value: sector.header.id,
        } as DropdownOption),
    );
  },
  async beforeRouteLeave(): Promise<void> {
    /**
     * Refresh POS terminals when leaving
     */
    await this.getPosTerminals();
  },
  methods: {
    async getPosTerminals(): Promise<void> {
      /**
       * Refresh store pos terminals
       */
      try {
        await this.$store.dispatch('session/setPosTerminals');
      } catch (error) {
        this.$spiagge.toast.error(
          this.$t('settingsPosTerminalView.toast.retrievalError'),
        );
      }
    },
    onPosTerminalAdd() {
      /**
       * Init create form
       */
      // create
      this.posTerminalId = 0;
      // init model
      this.posTerminalFormModel = cloneDeep(DEFAULT_POS_TERMINAL_MODEL);
      // show dialog
      this.showDialog = true;
    },
    async onPosTerminalDelete(posTerminal: PosTerminal): Promise<void> {
      /**
       * Delete POS terminal
       */
      this.$confirm.require({
        message: this.$t('settingsPosTerminalView.confirm.message'),
        header: this.$t('settingsPosTerminalView.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-danger p-button-outlined',
        acceptLabel: this.$t('button.delete'),
        rejectLabel: this.$t('button.cancel'),
        accept: async () => {
          try {
            // delete pos terminal
            await posTerminalService.delete(posTerminal.id);
            // refresh pos terminals
            await this.getPosTerminals();
            this.$spiagge.toast.success(
              this.$t('settingsPosTerminalView.toast.deleteSuccess'),
            );
          } catch (error) {
            this.$spiagge.toast.error(
              this.$t('settingsPosTerminalView.toast.deleteError'),
            );
          }
        },
      });
    },
    onPosTerminalUpdate(posTerminal: PosTerminal) {
      /**
       * Init update form
       */
      // set current service id
      this.posTerminalId = posTerminal.id;
      // init model
      this.posTerminalFormModel = cloneDeep(DEFAULT_POS_TERMINAL_MODEL);
      // copy POS terminal property to model
      this.posTerminalFormModel = pick(
        posTerminal,
        Object.keys(this.posTerminalFormModel),
      ) as unknown as PosTerminalFormModel;

      this.posTerminalFormModel.sectors = posTerminal.sectors.map(
        (sectorHeader: SectorHeader) => sectorHeader.id,
      );

      // open dialog
      this.showDialog = true;
    },
    async onPosTerminalSave() {
      /**
       * Form submit, create or update
       */
      if (this.posTerminalId) {
        this.updatePosTerminal();
      } else {
        this.createPosTerminal();
      }
    },
    async createPosTerminal(): Promise<void> {
      /**
       * Create a POS terminal
       */
      try {
        await posTerminalService.create(this.posTerminalFormModel);
        this.$spiagge.toast.success(
          this.$t('settingsPosTerminalView.toast.createSuccess'),
        );
        this.reset();
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('settingsPosTerminalView.toast.createError'),
        );
      }
    },
    async updatePosTerminal(): Promise<void> {
      /**
       * Update a POS terminal
       */
      try {
        await posTerminalService.update(
          this.posTerminalId,
          this.posTerminalFormModel,
        );
        this.$spiagge.toast.success(
          this.$t('settingsPosTerminalView.toast.updateSuccess'),
        );
        this.reset();
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('settingsPosTerminalView.toast.updateError'),
        );
      }
    },
    async reset(): Promise<void> {
      /**
       * Reset
       */
      this.showDialog = false;
      await this.getPosTerminals();
    },
    async onPosTerminalSwitch(posTerminal: PosTerminal): Promise<void> {
      /**
       * Update POS terminal disabled status
       */
      try {
        await posTerminalService.toggle(posTerminal.id);
        this.$spiagge.toast.success(
          this.$t('settingsPosTerminalView.toast.updateSuccess'),
        );
        this.reset();
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('settingsPosTerminalView.toast.updateError'),
        );
      }
    },
  },
  computed: {
    ...mapState('session', [
      'license',
      'posTerminals',
      'permission',
      'sectors',
    ]),
  },
});
