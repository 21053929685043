import axios, { AxiosResponse } from 'axios';
import {
  ApiPosTerminalCreatePayload,
  ApiPosTerminalCreateResponse,
  ApiPosTerminalListResponse,
  ApiPosTerminalToggleResponse,
  ApiPosTerminalUpdatePayload,
  ApiPosTerminalUpdateResponse,
} from '@/models/api';
import { PosTerminal } from '@/models/posTerminal';
import apiService from './apiService';

export default {
  async list(): Promise<Array<PosTerminal>> {
    const res: AxiosResponse<ApiPosTerminalListResponse> = await axios.get(
      apiService.posTerminal.list(),
    );
    return res.data.result.posTerminal;
  },
  async create(payload: ApiPosTerminalCreatePayload): Promise<PosTerminal> {
    const res: AxiosResponse<ApiPosTerminalCreateResponse> = await axios.post(
      apiService.posTerminal.create(),
      payload,
    );
    return res.data.result.posTerminal;
  },
  async update(
    posTerminalId: number,
    payload: ApiPosTerminalUpdatePayload,
  ): Promise<PosTerminal> {
    const res: AxiosResponse<ApiPosTerminalUpdateResponse> = await axios.patch(
      apiService.posTerminal.update(posTerminalId),
      payload,
    );
    return res.data.result.posTerminal;
  },
  async delete(posTerminalId: number): Promise<void> {
    return axios.delete(apiService.posTerminal.delete(posTerminalId));
  },
  async toggle(posTerminalId: number): Promise<PosTerminal> {
    const res: AxiosResponse<ApiPosTerminalToggleResponse> = await axios.patch(
      apiService.posTerminal.toggle(posTerminalId),
    );
    return res.data.result.posTerminal;
  },
};
