
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import spotService from '@/services/spotService';
import { Spot, SpotType } from '@/models/spot';
import {
  ApiReservationCreateAdditionPayload,
  ApiSpotFindPayload,
  ApiSpotFindResponse,
} from '@/models/api';
import { StyleSpot, StyleSpotAttributeIcons } from '@/models/style';
import { Reservation } from '@/models/reservation';
import reservationService from '@/services/reservationService';
import { AppAction } from '@/models/app';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

export default defineComponent({
  name: 'ReservationParkingService',
  data() {
    return {
      search: '',
      ready: false,
      dialog: false,
      parkingsList: [] as Array<Spot>,
      selectedParking: null as unknown as Spot,
      vehiclePlate: '',
      error: false,
    };
  },
  methods: {
    hasUpdatePermission(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.reservations,
        FEATURE_PERMISSION_ACTION_CONFIG.reservations.UPDATE,
      );
    },
    async getParkings(): Promise<void> {
      try {
        const res: ApiSpotFindResponse = await spotService.find({
          type: SpotType.PARKING,
          startDate: this.startDate.toSeconds(),
          endDate: this.endDate.toSeconds(),
          /* orderBy: [
            {
              field: 'name',
              value: 'asc',
            },
          ], */
        } as ApiSpotFindPayload);
        // manual sorting (natural sort)
        this.parkingsList = res.result.spots.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          }),
        );
      } catch (e) {
        this.parkingsList = [];
      }
    },
    async onAdd(): Promise<void> {
      if (!this.selectedParking) {
        this.error = true;
      } else {
        try {
          const parkingAddition: Reservation =
            await reservationService.createAddition(this.id, {
              spotType: SpotType.PARKING,
              spotName: this.selectedParking.name,
              startDate: this.startDate.toSeconds(),
              endDate: this.endDate.toSeconds(),
              vehiclePlate: this.vehiclePlate,
            } as ApiReservationCreateAdditionPayload);
          this.dialog = false;
          this.$spiagge.toast.success(
            this.$t('reservationParkingService.toast.success'),
          );
          this.$store.commit('reservation/setCanExit', true);
          this.$store.commit('app/setAction', AppAction.CREATE_RESERVATION);
          this.$router.push(`/reservation/${parkingAddition.id}`);
        } catch (e) {
          this.$spiagge.toast.error(
            this.$t('reservationParkingService.toast.'),
          );
        }
      }
    },
    icon(parking: Spot): string {
      const status = parking.reservations.length > 0 ? 'busy' : 'free';
      return (this.style[status].icons as StyleSpotAttributeIcons).parking;
    },
    total(parking: Reservation): number {
      if (parking.forcedTotal !== null) return parking.forcedTotal;
      return (
        (parking.forcedBeach ? parking.forcedBeach : parking.listBeach || 0) +
        (parking.listAdditions || 0) +
        (parking.listExpenses || 0) +
        (parking.listServices || 0)
      );
    },
  },
  computed: {
    ...mapState('reservation', ['id', 'startDate', 'endDate']),
    ...mapGetters('reservation', [
      'isParking',
      'isAddition',
      'isSimpleAddition',
      'isPlayField',
    ]),
    parkings(): Array<Reservation> {
      const parkings =
        this.$store.getters['reservation/parkings'] ??
        ([] as Array<Reservation>);
      return parkings.filter((element: Reservation) => !element.deleted);
    },
    enabled(): boolean {
      let enabled = true;
      if (this.isParking || this.isPlayField) {
        enabled = false;
      }
      if (this.isAddition && !this.isSimpleAddition) {
        enabled = false;
      }
      return enabled;
    },
    style(): StyleSpot {
      return this.$store.getters['style/spot'];
    },
    filteredParkings(): Array<Spot> {
      if (this.search) {
        return this.parkingsList.filter(
          (parking: Spot) =>
            parking.name
              .toString()
              .toLowerCase()
              .search(this.search.toLowerCase()) > -1,
        );
      }
      return this.parkingsList;
    },
  },
  watch: {
    async dialog(show: boolean): Promise<void> {
      if (show) {
        this.ready = false;
        this.search = '';
        this.error = false;
        this.selectedParking = null as unknown as Spot;
        await this.getParkings();
        this.ready = true;
      }
    },
  },
});
