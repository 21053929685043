import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "reservation-displacements-absences"
}
const _hoisted_2 = { class: "label no-print" }
const _hoisted_3 = { class: "displacements-absences-buttons no-print" }
const _hoisted_4 = {
  key: 0,
  class: "displacements-absences-list"
}
const _hoisted_5 = {
  key: 0,
  class: "absence"
}
const _hoisted_6 = { class: "from" }
const _hoisted_7 = { class: "to" }
const _hoisted_8 = {
  key: 1,
  class: "displacement"
}
const _hoisted_9 = { class: "from" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "to" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "grid" }
const _hoisted_14 = { class: "col-12 sm:col-6" }
const _hoisted_15 = { class: "p-field start-date" }
const _hoisted_16 = { class: "days" }
const _hoisted_17 = { class: "col-12 sm:col-6" }
const _hoisted_18 = { class: "p-field end-date" }
const _hoisted_19 = {
  key: 0,
  class: "col-12"
}
const _hoisted_20 = { class: "p-field switch-field__label-after recursive-switch" }
const _hoisted_21 = { for: "recursive-switch" }
const _hoisted_22 = {
  key: 1,
  class: "col-12"
}
const _hoisted_23 = { class: "days" }
const _hoisted_24 = { class: "p-field-checkbox" }
const _hoisted_25 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t('reservationDisplacementsAbsences.title.main')) + ":", 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Button, {
            label: _ctx.$t('reservationDisplacementsAbsences.addDisplacement'),
            icon: "spi-plus",
            class: "p-button-outlined",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onDialog(_ctx.creationType.displacement)))
          }, null, 8, ["label"]),
          _createVNode(_component_Button, {
            label: _ctx.$t('reservationDisplacementsAbsences.addAbsence'),
            icon: "spi-plus",
            class: "p-button-outlined",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDialog(_ctx.creationType.absence)))
          }, null, 8, ["label"])
        ]),
        _createVNode(_component_OverlayPanel, {
          showCloseIcon: "",
          ref: "op",
          id: "displacement-edit"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Calendar, {
              modelValue: _ctx.modelUpdateDate,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.modelUpdateDate) = $event)),
              minDate: _ctx.updateModel.minDate,
              maxDate: _ctx.updateModel.maxDate,
              onDateSelect: _ctx.updateDisplacement,
              inline: true
            }, null, 8, ["modelValue", "minDate", "maxDate", "onDateSelect"])
          ]),
          _: 1
        }, 512),
        (_ctx.list.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "displacement-absence-item"
                }, [
                  (item.type === _ctx.reservationType.ABSENCE)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('reservationDisplacementsAbsences.absenceFrom')) + " " + _toDisplayString(_ctx.$spiagge.date.fromMillis(item.startDate)), 1),
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('reservationDisplacementsAbsences.absenceTo')) + " " + _toDisplayString(_ctx.$spiagge.date.fromMillis(item.endDate)), 1),
                        _createVNode(_component_Button, {
                          icon: "pi pi-times",
                          class: "p-button-rounded p-button-danger p-button-text",
                          onClick: ($event: any) => (_ctx.onAbsenceRemove(item))
                        }, null, 8, ["onClick"])
                      ]))
                    : _createCommentVNode("", true),
                  (item.type === _ctx.reservationType.STANDARD)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_Button, {
                          class: _normalizeClass(["spot p-button-sm", 
              item.id === Number(_ctx.$route.params.id)
                ? 'p-button-danger'
                : 'p-button-primary'
            ]),
                          onClick: ($event: any) => (_ctx.$router.push(`/reservation/${item.id}`))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(item.spotName), 1)
                          ]),
                          _: 2
                        }, 1032, ["class", "onClick"]),
                        _createElementVNode("span", _hoisted_9, [
                          _createTextVNode(_toDisplayString(_ctx.$t('reservationDisplacementsAbsences.displacementFrom')) + " ", 1),
                          _createElementVNode("span", {
                            class: _normalizeClass(["date", _ctx.canUpdateDisplacement ? 'editable' : 'disabled']),
                            onClick: ($event: any) => (_ctx.onUpdateDisplacement($event, item, _ctx.updateType.START_DATE))
                          }, _toDisplayString(_ctx.$spiagge.date.fromMillis(item.startDate)), 11, _hoisted_10)
                        ]),
                        _createElementVNode("span", _hoisted_11, [
                          _createTextVNode(_toDisplayString(_ctx.$t('reservationDisplacementsAbsences.displacementTo')) + " ", 1),
                          _createElementVNode("span", {
                            class: _normalizeClass(["date", _ctx.canUpdateDisplacement ? 'editable' : 'disabled']),
                            onClick: ($event: any) => (_ctx.onUpdateDisplacement($event, item, _ctx.updateType.END_DATE))
                          }, _toDisplayString(_ctx.$spiagge.date.fromMillis(item.endDate)), 11, _hoisted_12)
                        ]),
                        _createVNode(_component_Button, {
                          icon: "pi pi-times",
                          class: "p-button-rounded p-button-danger p-button-text",
                          onClick: ($event: any) => (_ctx.onDisplacementRemove(item))
                        }, null, 8, ["onClick"])
                      ]))
                    : _createCommentVNode("", true)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_Dialog, {
          id: "displacements-absences-dialog",
          class: "dialog",
          modal: true,
          visible: _ctx.dialog,
          "onUpdate:visible": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.dialog) = $event)),
          onHide: _cache[9] || (_cache[9] = ($event: any) => (_ctx.dialog = false))
        }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          footer: _withCtx(() => [
            _createVNode(_component_Button, {
              class: "p-button-outlined",
              label: _ctx.$t('button.cancel'),
              icon: "spi-times",
              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.dialog = false))
            }, null, 8, ["label"]),
            _createVNode(_component_Button, {
              label: _ctx.cta,
              icon: "pi pi-chevron-right",
              onClick: _ctx.onSubmit
            }, null, 8, ["label", "onClick"])
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('common.from')) + ":", 1),
                  _createVNode(_component_Calendar, {
                    modelValue: _ctx.modelStartDate,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.modelStartDate) = $event)),
                    showIcon: "",
                    onDateSelect: _ctx.onModelStartDateSelect,
                    dateFormat: "dd/mm/yy",
                    minDate: _ctx.startDateJS,
                    maxDate: _ctx.endDateJS
                  }, null, 8, ["modelValue", "onDateSelect", "minDate", "maxDate"]),
                  _createElementVNode("small", _hoisted_16, _toDisplayString(_ctx.$tc('common.daysCount', _ctx.days)), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('common.to')) + ":", 1),
                  _createVNode(_component_Calendar, {
                    modelValue: _ctx.modelEndDate,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.modelEndDate) = $event)),
                    minDate: _ctx.modelStartDate,
                    maxDate: _ctx.endDateJS,
                    showIcon: "",
                    dateFormat: "dd/mm/yy"
                  }, null, 8, ["modelValue", "minDate", "maxDate"])
                ])
              ]),
              (_ctx.creationModel.type === _ctx.creationType.absence)
                ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('reservationDisplacementsAbsences.recursiveAbsence')), 1),
                      _createVNode(_component_InputSwitch, {
                        id: "recursive-siwtch",
                        modelValue: _ctx.creationModel.recursive,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.creationModel.recursive) = $event))
                      }, null, 8, ["modelValue"])
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.creationModel.recursive)
                ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.daysOptions, (dayOption, indexDayOption) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "day",
                          key: indexDayOption
                        }, [
                          _createElementVNode("div", _hoisted_24, [
                            _createVNode(_component_Checkbox, {
                              inputId: dayOption.day,
                              value: dayOption.day,
                              modelValue: _ctx.creationModel.days,
                              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.creationModel.days) = $event))
                            }, null, 8, ["inputId", "value", "modelValue"]),
                            _createElementVNode("label", {
                              for: dayOption.day
                            }, _toDisplayString(dayOption.abbrLabel), 9, _hoisted_25)
                          ])
                        ]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["visible"])
      ]))
    : _createCommentVNode("", true)
}