/* eslint-disable import/no-cycle */
/**
 * Reservation store module
 */

import cloneDeep from 'lodash/cloneDeep';
import { DateTime } from 'luxon';
import { MutationTree, ActionTree, ActionContext, GetterTree } from 'vuex';
import isEqual from 'lodash/isEqual';
import {
  DEFAULT_RESERVATION_STATE,
  ReservationState,
  RootState,
} from '@/models/store';
import { Customer, Guest } from '@/models/customer';
import {
  Reservation,
  ReservationHalfDay,
  ReservationExpandedField,
  ReservationList,
  ReservationSpot,
  ReservationStatus,
  ReservationExpanded,
  ReservationType,
  ReservationService,
  ReservationTotals,
  ReservationExpense,
  ReservationDeleteMode,
  ReminderLog,
  ReservationExpenseRaw,
  ReservationBookingTemporary,
  ReservationWeatherPolicy,
  ReservationNoShowPolicy,
  ReservationFiscalPrint,
} from '@/models/reservation';
import reservationService from '@/services/reservationService';
import {
  ApiCashFlowCreatePayload,
  ApiCashFlowFindPayload,
  ApiCashFlowUpdatePayload,
  ApiLogCreatePayload,
  ApiReservationCreateAbsencePayload,
  ApiReservationDeletePayload,
  ApiReservationDeleteResponse,
  ApiReservationDisjoinAccountPayload,
  ApiReservationFindPayload,
  ApiReservationFindResponse,
  ApiReservationSeasonalPayload,
  ApiReservationSendInvoicePayload,
  ApiReservationSendInvoiceResponse,
  ApiReservationUpdatePayload,
  ApiReservationUpdateServicePayload,
  ApiReservationUpdateStepPayload,
} from '@/models/api';
import { BEACH_TICKET_SERVICE_ID, Service } from '@/models/service';
import { CashFlow } from '@/models/cashFlow';
import cashFlowService from '@/services/cashFlowService';
import { AppAction } from '@/models/app';
import { Card } from '@/models/card';
import { SpotType } from '@/models/spot';
import cardService from '@/services/cardService';
import logService from '@/services/logService';
import reservationUtil from '@/utils/reservationUtil';
import { RefundType } from '@/models/refund';
import customerService from '@/services/customerService';
import { MapRefundConfirmation } from '@/models/map';
import { DiscountCode } from '@/models/discountCode';
import localStorageUtil from '@/utils/localStorageUtil';
import { SectorHeader } from '@/models/sector';
import serviceService from '@/services/serviceService';
import { ServiceAvailabilityPayload } from '@/models/api/request/service/serviceAvailabilityPayload';
import { ServiceInformation } from '@/models/service/serviceInformation';
import { WebticTicket } from '@/models/webtic';

type ReservationContext = ActionContext<ReservationState, RootState>;

const namespaced = true;

const state = (): ReservationState => cloneDeep(DEFAULT_RESERVATION_STATE);

const getters: GetterTree<ReservationState, RootState> = {
  id: (state) => state.id,
  spot: (state) => state.spot,
  isEdit: (state, getters, rootState) =>
    rootState.app.action === AppAction.EDIT_RESERVATION,
  isCreate: (state, getters, rootState) =>
    rootState.app.action === AppAction.CREATE_RESERVATION,
  list: (state) => state.list,
  temporary: (state) => state.temporary,
  customer: (state) => state.customer,
  startDate: (state) => state.startDate,
  endDate: (state) => state.endDate,
  isCheckInAvailable: (state) => state.isCheckInAvailable,
  isCheckOutAvailable: (state) => state.isCheckOutAvailable,
  halfDay: (state) => state.halfDay,
  seasonal: (state) => state.seasonal,
  maxiBeds: (state) => state.maxiBeds,
  beds: (state) => state.beds,
  deckChairs: (state) => state.deckChairs,
  chairs: (state) => state.chairs,
  guests: (state) => state.guests,
  hotel: (state) => state.hotel,
  hotelRoom: (state) => state.hotelRoom,
  travelGroup: (state) => state.travelGroup,
  absences: (state) => state.absences,
  services: (state) => state.services,
  serviceAvailability: (state) => state.serviceAvailability,
  parkings: (state) => state.parkings,
  cabins: (state) => state.cabins,
  totals: (state) => state.totals,
  notes: (state) => state.notes,
  vehiclePlate: (state) => state.vehiclePlate,
  type: (state) => state.type,
  expenses: (state) => state.expenses,
  reminderLogs: (state) => state.reminderLogs,
  selectedExpensesStored: (state) => state.selectedExpensesStored,
  depositTransferStored: (state) => state.depositTransferStored,
  bookingTemp: (state) => state.bookingTemp,
  token: (state) => state.token,
  qrCode: (state) => state.qrCode,
  sector: (state) => state.sector,
  cards: (state) => state.cards,
  tags: (state) => state.tags,
  /* custom getters */
  // check se la prenotazione è temporanea e proviene dal booking
  hasBookingTemp: (state) => !!state.bookingTemp,
  additions: (state) => state.additions,
  totalDays: (state) => {
    const startDate = state.startDate;
    const endDate = state.endDate;
    const diff = endDate.diff(startDate, 'days');
    const diffObj = diff.toObject();
    return (diffObj.days as number) + 1;
  },
  isMoving: (state) => state.type === ReservationType.MOVING,
  isAddition: (state) => state.type === ReservationType.ADDITION,
  isJointAccount: (state) => state.type === ReservationType.JOINT_ACCOUNT,
  isBillReservation: (state) => state.type === ReservationType.BILL,
  isJointAccountPart: (state) =>
    state.master && state.master.type === ReservationType.JOINT_ACCOUNT,
  canDisjoinAccount: (state, getters) => {
    if (!getters.isJointAccount) {
      return false;
    }
    return !state.cashFlows.some(
      (cashFlow: CashFlow) => cashFlow.reservationId === state.id,
    );
  },
  isParkingAddition: (state, getters) =>
    getters.isAddition && getters.isParking,
  isCabinAddition: (state, getters) => getters.isAddition && getters.isCabin,
  isSimpleAddition: (state, getters) =>
    getters.isAddition &&
    !getters.isParkingAddition &&
    !getters.isCabinAddition,
  isParking: (state) => state.spot.type === SpotType.PARKING,
  isCabin: (state) => state.spot.type === SpotType.CABIN,
  isAreaBeds: (state) => state.spot.type === SpotType.BEDS,
  isBoat: (state) => state.spot.type === SpotType.BOAT,
  isPlayField: (state) => state.spot.type === SpotType.PLAYFIELD,
  totalSelectedExpenses: (getters, state) => {
    if (state.selectedExpensesStored) {
      let total = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const expense of state.selectedExpensesStored as Array<ReservationExpense>) {
        total += expense.value;
      }
      return total;
    }
    return 0;
  },
  total: (state, getters) => {
    if (state.totals.forced.total !== null) return state.totals.forced.total;
    return getters.totalBeforeForcedTotal;
  },
  totalBeforeForcedTotal: (state) => {
    let total = 0;
    // Forced beach
    if (state.totals.forced.beach !== null) {
      total += state.totals.forced.beach;
    } else {
      total += state.totals.list.beach || 0;
    }
    // Forced services
    if (state.totals.forced.services !== null) {
      total += state.totals.forced.services;
    } else {
      total += state.totals.list.services || 0;
    }
    total += state.totals.list.additions || 0;
    total += state.totals.list.expenses || 0;
    return Math.round(total * 100) / 100;
  },
  totalToPay: (state, getters) => {
    const toPay =
      Math.round((getters.total - (state.totals.paid.total as number)) * 100) /
      100;
    return toPay;
  },
  hasDeposit: (state) => (state.totals.paid.total as number) > 0 && !state.paid,
  hasFiscalDepositsOnly: (state) => {
    const count = state.cashFlows.filter(
      (c: CashFlow) => c.receiptId || c.invoiceId,
    )
      ? state.cashFlows.filter((c: CashFlow) => c.receiptId || c.invoiceId)
          .length
      : 0;
    return state.cashFlows.length === count;
  },
  /* services - custom getters */
  beachServices: (state, getters, rootState, rootGetters) => {
    const licenseBeachServicesIds = rootGetters['session/beachServices'].map(
      (service: Service) => service.id,
    );
    return state.services.filter((reservationService: ReservationService) =>
      licenseBeachServicesIds.includes(reservationService.serviceId),
    );
  },
  utilityServices: (state, getters, rootState, rootGetters) => {
    const licenseUtilityServicesIds = rootGetters[
      'session/utilityServices'
    ].map((service: Service) => service.id);
    return state.services.filter((reservationService: ReservationService) =>
      licenseUtilityServicesIds.includes(reservationService.serviceId),
    );
  },
  dailyTicketServices:
    (state, getters, rootState, rootGetters) =>
    (excludeBeachTicketService = false) => {
      const licenseDailyTicketServicesIds = rootGetters[
        'session/dailyTicketServices'
      ].map((service: Service) => service.id);
      let dailyTicketServices = state.services.filter(
        (reservationService: ReservationService) =>
          licenseDailyTicketServicesIds.includes(reservationService.serviceId),
      );
      if (excludeBeachTicketService) {
        dailyTicketServices = dailyTicketServices.filter(
          (reservationService: ReservationService) =>
            reservationService.serviceId !== BEACH_TICKET_SERVICE_ID,
        );
      }
      return dailyTicketServices;
    },
  hasOrderWithPaymentLink(state) {
    if (state.orderSummary === null) {
      return false;
    }

    return state.orderSummary.sendPaymentLink;
  },
};

const mutations: MutationTree<ReservationState> = {
  setId(state: ReservationState, id: number) {
    state.id = id;
  },
  setSpot(state: ReservationState, spot: ReservationSpot) {
    state.spot = spot;
  },
  setTemporary(state: ReservationState, temporary: boolean) {
    state.temporary = temporary;
  },
  setCustomer(state: ReservationState, customer: Customer) {
    state.customer = customer;
  },
  setStartDate(state: ReservationState, startDate: DateTime) {
    state.startDate = startDate;
  },
  setEndDate(state: ReservationState, endDate: DateTime) {
    state.endDate = endDate;
  },
  setHalfDay(state: ReservationState, halfDay: ReservationHalfDay) {
    state.halfDay = halfDay;
  },
  setStatus(state: ReservationState, status: ReservationStatus) {
    state.status = status;
  },
  setSeasonal(state: ReservationState, seasonal: boolean) {
    state.seasonal = seasonal;
  },
  setMaxiBeds(state: ReservationState, maxiBeds: number) {
    state.maxiBeds = maxiBeds;
  },
  setBeds(state: ReservationState, beds: number) {
    state.beds = beds;
  },
  setDeckChairs(state: ReservationState, deckChairs: number) {
    state.deckChairs = deckChairs;
  },
  setChairs(state: ReservationState, chairs: number) {
    state.chairs = chairs;
  },
  setHotel(state: ReservationState, hotel: string) {
    state.hotel = hotel;
  },
  setHotelRoom(state: ReservationState, hotelRoom: string) {
    state.hotelRoom = hotelRoom;
  },
  setTravelGroup(state: ReservationState, travelGroup: string) {
    state.travelGroup = travelGroup;
  },
  setGuests(state: ReservationState, guests: Array<Guest>) {
    state.guests = guests;
  },
  addGuest(state: ReservationState, guest: Guest) {
    state.guests.push(guest);
  },
  updateGuest(state: ReservationState, guest: Guest) {
    const index = state.guests.findIndex(
      (g) => guest.contactId === g.contactId,
    );
    if (index !== -1) {
      // update
      state.guests[index] = guest;
    } else {
      // creation with suggest
      state.guests.push(guest);
    }
  },
  removeGuest(state: ReservationState, guest: Guest) {
    state.guests = state.guests.filter((g: Guest) => !isEqual(g, guest));
  },
  setList(state: ReservationState, list: Array<ReservationList>) {
    state.list = list;
  },
  setAbsences(state: ReservationState, absences: Array<Reservation>) {
    state.absences = absences;
  },
  addAbsence(state: ReservationState, absence: Reservation) {
    state.absences.push(absence);
  },
  setDisplacements(state: ReservationState, displacements: Array<Reservation>) {
    state.displacements = displacements;
  },
  removeAbsence(state: ReservationState, absenceId: number) {
    state.absences = state.absences.filter(
      (absence: Reservation) => absence.id !== absenceId,
    );
  },
  removeDisplacement(state: ReservationState, displacementId: number) {
    state.displacements = state.displacements.filter(
      (displacement: Reservation) => displacement.id !== displacementId,
    );
  },
  setServices(state: ReservationState, services: Array<ReservationService>) {
    state.services = services;
  },
  setCabins(state: ReservationState, cabins: Array<Reservation>) {
    state.cabins = cabins;
  },
  setParkings(state: ReservationState, parkings: Array<Reservation>) {
    state.parkings = parkings;
  },
  setBookingTemp(
    state: ReservationState,
    bookingTemp: ReservationBookingTemporary | null,
  ) {
    state.bookingTemp = bookingTemp;
  },
  setListTotal(state: ReservationState, listTotal: Reservation['listTotal']) {
    state.listTotal = listTotal;
  },
  updateService(state: ReservationState, service: ReservationService) {
    const index = state.services.findIndex(
      (s: ReservationService) => s.serviceId === service.serviceId,
    );
    if (index !== -1) {
      state.services[index] = service;
    } else {
      state.services.push(service);
    }
  },
  loadServiceAvailability(
    state: ReservationState,
    availability: ServiceInformation,
  ) {
    state.serviceAvailability = availability;
  },
  setMaster(state: ReservationState, master: Reservation) {
    state.master = master;
  },
  setAdditions(state: ReservationState, additions: Array<Reservation>) {
    state.additions = additions;
  },
  setCanExit(state: ReservationState, canExit: boolean) {
    state.canExit = canExit;
  },
  setPaid(state: ReservationState, paid: boolean) {
    state.paid = paid;
  },
  setTotals(state: ReservationState, totals: ReservationTotals) {
    state.totals = totals;
  },
  setCashFlows(state: ReservationState, cashFlows: Array<CashFlow>) {
    state.cashFlows = cashFlows;
  },
  addCashFlow(state: ReservationState, cashFlow: CashFlow) {
    state.cashFlows.push(cashFlow);
  },
  updateCashFlow(state: ReservationState, cashFlow: CashFlow) {
    const index = state.cashFlows.findIndex(
      (cf: CashFlow) => cf.id === cashFlow.id,
    );
    if (index !== -1) {
      state.cashFlows[index] = cashFlow;
    }
  },
  setNotes(state: ReservationState, notes: string) {
    state.notes = notes;
  },
  setVehiclePlate(state: ReservationState, vehiclePlate: string) {
    state.vehiclePlate = vehiclePlate;
  },
  setType(state: ReservationState, type: ReservationType) {
    state.type = type;
  },
  setJoints(state: ReservationState, joints: Array<Reservation>) {
    state.joints = joints;
  },
  reset(state: ReservationState) {
    Object.assign(state, cloneDeep(DEFAULT_RESERVATION_STATE));
  },
  setExpenses(state: ReservationState, expenses: Array<ReservationExpenseRaw>) {
    const deserializedExpenses: Array<ReservationExpense> = expenses.map(
      (reservationExpense: ReservationExpenseRaw) =>
        reservationUtil.deserializeExpense(reservationExpense),
    );
    state.expenses = deserializedExpenses;
  },
  setSelectedExpensesStored(
    state: ReservationState,
    selectedExpensesStored: Array<ReservationExpense>,
  ) {
    state.selectedExpensesStored = selectedExpensesStored;
  },
  setDepositTransferStored(
    state: ReservationState,
    depositTransferStored: number,
  ) {
    state.depositTransferStored = depositTransferStored;
  },
  setOnline(state: ReservationState, online: boolean) {
    state.online = online;
  },
  setPropagate(state: ReservationState, propagate: Array<Reservation>) {
    state.propagate = propagate;
  },
  setVoucherId(state: ReservationState, voucherId: number) {
    state.voucherId = voucherId;
  },
  setDeleted(state: ReservationState, deleted: boolean) {
    state.deleted = deleted;
  },
  setOverbooking(state: ReservationState, overbooking: boolean) {
    state.overbooking = overbooking;
  },
  setReminderLogs(state: ReservationState, reminderLogs: Array<ReminderLog>) {
    state.reminderLogs = reminderLogs;
  },
  setToken(state: ReservationState, token: string) {
    state.token = token;
  },
  setQrCode(state: ReservationState, qrCode: string) {
    state.qrCode = qrCode;
  },
  setSector(state: ReservationState, sector: SectorHeader) {
    state.sector = sector;
  },
  setCards(state: ReservationState, cards: Array<Card>) {
    state.cards = cards;
  },
  setStepId(state: ReservationState, stepId: number | null) {
    state.stepId = stepId;
  },
  setTags(state: ReservationState, tags: Array<number>) {
    state.tags = tags;
  },
  setWeatherPolicy(
    state: ReservationState,
    weatherPolicy: ReservationWeatherPolicy | null,
  ) {
    state.weatherPolicy = weatherPolicy;
  },
  setNoShowPolicy(
    state: ReservationState,
    noShowPolicy: ReservationNoShowPolicy | null,
  ) {
    state.noShowPolicy = noShowPolicy;
  },
  setDiscountCode(state: ReservationState, discountCode: DiscountCode | null) {
    state.discountCode = discountCode;
  },
  setWebticTickets(
    state: ReservationState,
    webticTickets: Array<WebticTicket>,
  ) {
    state.webticTickets = webticTickets;
  },
  setFiscalPrint(state: ReservationState, fiscalPrint: ReservationFiscalPrint) {
    state.fiscalPrint = fiscalPrint;
  },
  setIsCheckInAvailable(state: ReservationState, isCheckInAvailable: boolean) {
    state.isCheckInAvailable = isCheckInAvailable;
  },
  setIsCheckOutAvailable(
    state: ReservationState,
    isCheckOutAvailable: boolean,
  ) {
    state.isCheckOutAvailable = isCheckOutAvailable;
  },
  setOrderSummary(
    state: ReservationState,
    orderSummary: ReservationState['orderSummary'],
  ) {
    state.orderSummary = orderSummary;
  },
  setIsFiscalPrinting(state: ReservationState, isFiscalPrinting: boolean) {
    state.isFiscalPrinting = isFiscalPrinting;
  },
};

const actions: ActionTree<ReservationState, RootState> = {
  async getReservation(context: ReservationContext, id: number) {
    const reservation: ReservationExpanded = (await reservationService.one(id, [
      ReservationExpandedField.MASTER,
      ReservationExpandedField.CHILDREN,
      ReservationExpandedField.CASH_FLOWS,
      ReservationExpandedField.CONTACT,
      ReservationExpandedField.EXPENSES,
      ReservationExpandedField.REMINDER_LOGS,
      ReservationExpandedField.BOOKING_TEMP,
      ReservationExpandedField.WEATHER_POLICY,
      ReservationExpandedField.NOSHOW_POLICY,
    ])) as ReservationExpanded;
    if (reservation.type === ReservationType.MOVING) {
      context.commit('setStepId', Number(id));
    }
    context.dispatch('setReservation', reservation);
  },
  async getList(context: ReservationContext) {
    const res: ApiReservationFindResponse = await reservationService.find({
      spotName: context.state.spot.name,
      spotType: context.state.spot.type,
      fields: ['id', 'startDate', 'endDate', 'paid', 'firstName', 'lastName'],
      type:
        context.state.spot.type === 'cabins' ||
        context.state.spot.type === 'parking'
          ? [ReservationType.STANDARD, ReservationType.ADDITION]
          : [ReservationType.STANDARD],
      deleted: false,
    } as ApiReservationFindPayload);
    context.commit('setList', res.result.reservations);
  },
  async updateReservation(
    context: ReservationContext,
    payload: ApiReservationUpdatePayload,
  ) {
    if (context.state.propagate.length > 0) {
      // eslint-disable-next-line no-param-reassign
      payload.propagate = context.state.propagate.map(
        (reservation) => reservation.id,
      );
    }
    const reservation: Reservation = await reservationService.update(
      context.state.id,
      payload,
    );
    reservation.expenses = cloneDeep(context.getters.expenses);
    // on update, prevent the override of the flags coming from the expand param (GET)
    reservation.weatherPolicy = context.state.weatherPolicy;
    reservation.bookingTemp = context.state.bookingTemp;
    await context.dispatch('setReservation', reservation);
    // If dates changed refresh service counters
    if (payload.startDate !== undefined || payload.endDate !== undefined) {
      await context.dispatch('loadServiceAvailability');
    }
    // context.commit('app/setAction', AppAction.EDIT_RESERVATION, { root: true });
  },
  async setReservation(context: ReservationContext, reservation: Reservation) {
    if (reservation.type === ReservationType.MOVING) {
      /** get spot from children with stepId, if moving should be not null */
      const step = reservation.children?.find(
        (r: Reservation) => r.id === context.state.stepId,
      );
      context.commit('setSpot', {
        name: step?.spotName ?? reservation.spotName,
        type: step?.spotType ?? reservation.spotType,
      });
    } else {
      context.commit('setSpot', {
        name: reservation.spotName,
        type: reservation.spotType,
      });
    }

    context.commit('setId', reservation.id);
    context.commit('setType', reservation.type);
    context.commit('setMaster', reservation.master ? reservation.master : null);
    context.commit('setToken', reservation.token);
    context.commit('setQrCode', reservation.qrCode);
    context.commit('setSector', reservation.sector);
    context.commit('setBookingTemp', reservation.bookingTemp);
    context.commit('setListTotal', reservation.listTotal);

    context.commit(
      'setAbsences',
      reservation.children?.filter(
        (res: Reservation) => res.type === ReservationType.ABSENCE,
      ) || [],
    );
    context.commit(
      'setAdditions',
      reservation.children?.filter(
        (res: Reservation) =>
          res.type === ReservationType.ADDITION &&
          res.spotType !== SpotType.CABIN &&
          res.spotType !== SpotType.PARKING,
      ) || [],
    );
    context.commit(
      'setCabins',
      reservation.children?.filter(
        (res: Reservation) =>
          res.type === ReservationType.ADDITION &&
          res.spotType === SpotType.CABIN,
      ) || [],
    );
    context.commit(
      'setParkings',
      reservation.children?.filter(
        (res: Reservation) =>
          res.type === ReservationType.ADDITION &&
          res.spotType === SpotType.PARKING,
      ) || [],
    );

    // MOVING
    if (reservation.type === ReservationType.MOVING) {
      context.commit(
        'setDisplacements',
        reservation.children?.filter(
          (res: Reservation) => res.type === ReservationType.STANDARD,
        ),
      );
    } else if (reservation.type === ReservationType.JOINT_ACCOUNT) {
      context.commit(
        'setJoints',
        reservation.children?.filter(
          (res: Reservation) =>
            res.type === ReservationType.STANDARD ||
            res.type === ReservationType.MOVING,
        ),
      );
    } else {
      context.commit('setDisplacements', []);
    }

    // Commit only if necessary otherwise the watcher will update
    if (context.getters.startDate.toSeconds() !== reservation.startDate) {
      // nella joint start/end date sono nulli
      context.commit(
        'setStartDate',
        reservation.startDate
          ? DateTime.fromSeconds(reservation.startDate)
          : DateTime.now(),
      );
    }
    if (context.getters.endDate.toSeconds() !== reservation.endDate) {
      context.commit(
        'setEndDate',
        reservation.endDate
          ? DateTime.fromSeconds(reservation.endDate)
          : DateTime.now(),
      );
    }
    if (context.getters.beds !== reservation.beds) {
      context.commit('setBeds', reservation.beds);
    }
    if (context.getters.maxiBeds !== reservation.maxiBeds) {
      context.commit('setMaxiBeds', reservation.maxiBeds);
    }
    if (context.getters.deckChairs !== reservation.deckChairs) {
      context.commit('setDeckChairs', reservation.deckChairs);
    }
    if (context.getters.chairs !== reservation.chairs) {
      context.commit('setChairs', reservation.chairs);
    }

    // // nella joint start/end date sono nulli
    // context.commit(
    //   'setStartDate',
    //   reservation.startDate
    //     ? DateTime.fromSeconds(reservation.startDate)
    //     : DateTime.now(),
    // );
    // context.commit(
    //   'setEndDate',
    //   reservation.endDate
    //     ? DateTime.fromSeconds(reservation.endDate)
    //     : DateTime.now(),
    // );
    //
    // context.commit('setBeds', reservation.beds);
    // context.commit('setMaxiBeds', reservation.maxiBeds);
    // context.commit('setDeckChairs', reservation.deckChairs);
    // context.commit('setChairs', reservation.chairs);
    context.commit('setSeasonal', reservation.seasonal);
    context.commit('setHalfDay', reservation.halfDay);
    context.commit('setStatus', reservation.status);
    context.commit('setTravelGroup', reservation.travelGroup);
    context.commit('setHotelRoom', reservation.hotelRoom);
    // FIXME (backend migration?)
    context.commit(
      'setHotel',
      reservation.hotel === '0' ? '' : reservation.hotel,
    );
    context.commit('setStatus', reservation.status);
    context.commit(
      'setTemporary',
      reservation.status !== ReservationStatus.CONFIRMED,
    );

    const license = context.rootGetters['session/license'];

    const customer = {
      firstName: reservation.firstName || '',
      lastName: reservation.lastName || '',
      email: reservation.email || '',
      phoneNumber: reservation.phoneNumber || '',
      phoneAreaCode: reservationUtil.getPhoneAreaCode(reservation, license),
      invoiceCompany: reservation.invoiceCompany || '',
      invoiceVatCode: reservation.invoiceVatCode || '',
      invoiceTaxCode: reservation.invoiceTaxCode || '',
      invoiceLotteryCode: reservation.invoiceLotteryCode || '',
      invoicePec: reservation.invoicePec || '',
      invoiceSdi: reservation.invoiceSdi || '',
      invoiceAddress1: reservation.invoiceAddress1 || '',
      invoiceAddress2: reservation.invoiceAddress2 || '',
      invoiceCity: reservation.invoiceCity || '',
      invoiceZip: reservation.invoiceZip || '',
      invoiceState: reservation.invoiceState || '',
      invoiceCountry: reservation.invoiceCountry || '',
    } as Customer;
    if (reservation.contactId) {
      customer.contactId = reservation.contactId;
    }
    context.commit('setCustomer', customer);
    context.commit('setPaid', reservation.paid);

    await context.dispatch('getCustomerTagIds', customer.contactId);

    const totals: ReservationTotals = {
      list: {
        additions: reservation.listAdditions || 0,
        beach: reservation.listBeach,
        expenses: reservation.listExpenses || 0,
        services: reservation.listServices || 0,
        total: reservation.listTotal,
      },
      forced: {
        additions: reservation.forcedAdditions || 0,
        beach: reservation.forcedBeach,
        expenses: reservation.forcedExpenses || 0,
        services: reservation.forcedServices,
        total: reservation.forcedTotal,
      },
      paid: {
        additions: reservation.paidAdditions || 0,
        beach: reservation.paidBeach || 0,
        expenses: reservation.paidExpenses || 0,
        services: reservation.paidServices || 0,
        total: reservation.paidTotal || 0,
      },
    };

    context.commit('setTotals', totals);
    context.commit('setNotes', reservation.notes);
    context.commit('setVehiclePlate', reservation.vehiclePlate || '');
    context.commit('setOnline', reservation.online);
    context.commit('setVoucherId', reservation.voucherId);

    const expenses = context.state.expenses;
    context.commit('setExpenses', reservation.expenses ?? expenses);
    context.commit('setDeleted', reservation.deleted);
    context.commit('setOverbooking', reservation.overbooking);

    const reminderLogs = context.state.reminderLogs;
    context.commit('setReminderLogs', reservation.reminderLogs ?? reminderLogs);
    context.commit('setWeatherPolicy', reservation.weatherPolicy);
    context.commit('setNoShowPolicy', reservation.noShowPolicy);

    // Check-in check-out
    context.commit('setIsCheckInAvailable', reservation.isCheckInAvailable);
    context.commit('setIsCheckOutAvailable', reservation.isCheckOutAvailable);

    // MOVING STEP
    // TODOOOOOOOO ASAP
    /*
    if (
      reservation.type === ReservationType.MOVING &&
      Number(context.rootState.app.route?.params.id) !== reservation.id
    ) {
      const step: Reservation = context.state.displacements.find(
        (displacement: Reservation) =>
          displacement.id === Number(context.rootState.app.route?.params.id),
      ) as Reservation;
      // its a moving step
      context.commit('setSpot', {
        name: step.spotName,
        type: reservation.spotType,
      });
      // context.commit('setId', step.id);

      // aggiornare lista reservation laterale
    }
    */
  },
  async setSeasonal(context: ReservationContext, seasonal: boolean) {
    const payload: ApiReservationSeasonalPayload = {
      value: seasonal,
    };
    if (context.state.propagate) {
      payload.propagate = context.state.propagate.map(
        (reservation: Reservation) => reservation.id,
      );
    }
    const reservation: Reservation = await reservationService.seasonal(
      context.state.id,
      payload,
    );
    context.dispatch('setReservation', reservation);
  },
  async createAbsence(
    context: ReservationContext,
    payload: ApiReservationCreateAbsencePayload,
  ) {
    const absence: Reservation | null = await reservationService.createAbsence(
      context.state.id,
      payload,
    );
    if (absence) {
      await context.dispatch('refresh');
    }
    return absence;
    // context.commit('addAbsence', reservation); deprecated with recursive absences
    // context.commit('app/setAction', AppAction.EDIT_RESERVATION, { root: true });
  },
  async removeAbsence(context: ReservationContext, absenceId: number) {
    await reservationService.delete({
      id: absenceId,
      mode: ReservationDeleteMode.DEL,
      refundType: RefundType.NONE,
    } as ApiReservationDeletePayload);
    context.commit('removeAbsence', absenceId);
    // context.commit('app/setAction', AppAction.EDIT_RESERVATION, { root: true });
  },
  async removeDisplacement(
    context: ReservationContext,
    displacementId: number,
  ) {
    await reservationService.deleteStep(context.state.id, displacementId);
    context.dispatch('refresh');
    // context.commit('app/setAction', AppAction.EDIT_RESERVATION, { root: true });
  },
  async getServices(context: ReservationContext): Promise<void> {
    const reservationServicesModel = [] as Array<ReservationService>;

    const reservationServices: Array<ReservationService> =
      await reservationService.getServices(context.state.id);

    const services = context.rootGetters['session/services'];

    services.map((service: Service) => {
      /**
       * made a change to this function to adapt
       * the behavior for reservations with a merged account type.
       * in the case of joint accounts it is possible that there are
       * the extra services of child reservation with the same serviceId.
       * in the previous version of the function this behavior
       * was not considered and consequently an error occurred in the merged accounts
       * SPIT-3619
       * old version:
       *   const reservationService = reservationServices.find(...
       *   ...
       *   if (reservationService) {
            // service exist on reservation
            reservationServicesModel.push(rs);
            }
       */
      const reservationServicesSameId = reservationServices.filter(
        (rs: ReservationService) => rs.serviceId === service.id,
      );

      if (reservationServicesSameId && reservationServicesSameId.length > 0) {
        reservationServicesSameId.forEach((rs: ReservationService) => {
          // service exist on reservation
          reservationServicesModel.push(rs);
        });
      } else {
        // service doesn't exist on reservation, create empty one
        reservationServicesModel.push({
          serviceId: service.id,
          bought: 0,
          used: 0,
          deleted: 0,
          paid: 0,
          price: 0,
          forcedPrice: 0,
          reservationId: context.state.id,
        } as ReservationService);
      }
    });
    context.commit('setServices', reservationServicesModel);
  },
  async updateService(
    context: ReservationContext,
    payload: ApiReservationUpdateServicePayload,
  ): Promise<void> {
    const service: ReservationService = await reservationService.updateService(
      context.state.id,
      payload,
    );
    context.commit('updateService', service);
    context.dispatch('refresh');
  },
  async loadServiceAvailability(context: ReservationContext): Promise<void> {
    const payload: ServiceAvailabilityPayload = {
      from: context.state.startDate.toSeconds(),
      to: context.state.endDate.toSeconds(),
    };
    const availabilities: ServiceInformation =
      await serviceService.availability(payload);
    context.commit('loadServiceAvailability', availabilities);
  },
  async getGuests(context: ReservationContext): Promise<void> {
    const guests: Array<Guest> = await reservationService.getGuests(
      context.state.id,
    );
    context.commit('setGuests', guests);
  },
  async createGuest(
    context: ReservationContext,
    payload: Guest,
  ): Promise<void> {
    const guest: Guest = await reservationService.createGuest(
      context.state.id,
      payload,
    );
    if (payload.contactId) {
      // edit or create with suggest
      context.commit('updateGuest', guest);
    } else {
      context.commit('addGuest', guest);
    }
    // context.commit('app/setAction', AppAction.EDIT_RESERVATION, { root: true });
  },
  async removeGuest(context: ReservationContext, guest: Guest): Promise<void> {
    await reservationService.removeGuest(
      context.state.id,
      guest.contactId as number,
    );
    context.commit('removeGuest', guest);
    // context.commit('app/setAction', AppAction.EDIT_RESERVATION, { root: true });
  },
  async deleteReservation(
    context: ReservationContext,
    payload: ApiReservationDeletePayload,
  ) {
    const res: ApiReservationDeleteResponse = await reservationService.delete(
      payload,
    );
    // If refund then setup the confirmation informations to display after the redirect
    if (payload.refundType !== RefundType.NONE) {
      context.commit(
        'map/setRefundConfirmation',
        {
          reservationId: payload.id,
          value: payload.refundAmount,
          type: payload.refundType,
          discountCode: res.result.discountCode,
        } as MapRefundConfirmation,
        { root: true },
      );
    }
    context.commit('setCanExit', true);
  },
  async getCashFlows(context: ReservationContext): Promise<void> {
    const cashFlows: Array<CashFlow> = await cashFlowService.find({
      reservationId: context.state.id,
    } as ApiCashFlowFindPayload);
    context.commit('setCashFlows', cashFlows);
  },
  async createCashFlow(
    context: ReservationContext,
    payload: ApiCashFlowCreatePayload,
  ): Promise<void> {
    const cashFlow = await cashFlowService.create(payload);
    context.dispatch('getReservation', context.state.id);
    context.commit('addCashFlow', cashFlow);
  },
  async updateCashFlow(
    context: ReservationContext,
    payload: ApiCashFlowUpdatePayload,
  ) {
    const cashFlow = await cashFlowService.update(payload);
    context.commit('updateCashFlow', cashFlow);
    await context.dispatch('getExpenses');
  },
  async createLog(context: ReservationContext, payload: ApiLogCreatePayload) {
    logService.create(payload);
  },
  async updateDisplacement(
    context: ReservationContext,
    payload: ApiReservationUpdateStepPayload,
  ) {
    await reservationService.updateStep(payload.id as number, payload);
    await context.dispatch('getReservation', payload.id);
  },
  async restore(context: ReservationContext) {
    const reservation: Reservation = await reservationService.restore(
      context.state.id,
    );
    context.dispatch('setReservation', reservation);
    context.dispatch('getCashFlows');
  },
  async refresh(context: ReservationContext) {
    context.dispatch('getReservation', context.state.id);
  },
  async getCards(context: ReservationContext) {
    if (context.state.customer.email && context.state.customer.email !== '') {
      const response = await cardService.listByEmail(
        context.state.customer.email,
      );
      context.commit('setCards', response);
    }
  },
  async getExpenses(context: ReservationContext) {
    const response = await reservationService.getExpenses(context.state.id);
    context.commit('setExpenses', response);
  },
  async sendInvoice(
    context: ReservationContext,
    payload: ApiReservationSendInvoicePayload,
  ) {
    const res: ApiReservationSendInvoiceResponse =
      await reservationService.sendInvoice(context.state.id, payload);
    context.commit('addCashFlow', res.result.cashFlow);
    window.open(res.result.invoiceUrl, '_blank');
  },
  async getCustomerTagIds(
    context: ReservationContext,
    contactId?: number | null,
  ) {
    if (contactId) {
      const response = await customerService.getCustomerTags(contactId);
      context.commit('setTags', response);
    }
  },
  async disjoinAccount(
    context: ReservationContext,
    payload: ApiReservationDisjoinAccountPayload,
  ) {
    const reservation: Reservation | null =
      await reservationService.disjoinAccount(context.state.id, payload);
    if (reservation === null) {
      context.commit('reset');
    } else {
      context.dispatch('setReservation', reservation);
    }
  },
  async getDiscountCode(context: ReservationContext) {
    const discountCode: DiscountCode | null =
      await reservationService.discountCode(context.state.id);
    context.commit('setDiscountCode', discountCode);
  },
  async getWebticTickets(context: ReservationContext) {
    const webticTickets: Array<WebticTicket> =
      await reservationService.siaeTickets(context.state.id);
    context.commit('setWebticTickets', webticTickets);
  },
  async fiscalPrintStart(
    context: ReservationContext,
    fiscalPrint: ReservationFiscalPrint,
  ) {
    context.commit('setFiscalPrint', fiscalPrint);
    context.commit('setCanExit', false);
    localStorageUtil.configure('spiagge', 'reservation');
    localStorageUtil.set('fiscalPrint', fiscalPrint);
  },
  async fiscalPrintEnd(context: ReservationContext) {
    context.commit('setFiscalPrint', null);
    context.commit('setIsFiscalPrinting', false);
    context.commit('setCanExit', true);
    localStorageUtil.configure('spiagge', 'reservation');
    localStorageUtil.clear();
    context.commit('app/setProgressDialog', null, { root: true });
  },
  async checkIn(context: ReservationContext) {
    await reservationService.checkIn(context.state.id);
    await Promise.all([
      context.dispatch('refresh'),
      context.dispatch('getServices'),
    ]);
  },
  async checkOut(context: ReservationContext) {
    await reservationService.checkOut(context.state.id);
    await Promise.all([
      context.dispatch('refresh'),
      context.dispatch('getServices'),
    ]);
  },
} as ActionTree<ReservationState, RootState>;

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
