
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'OverlayCard',
  props: {
    title: {
      type: String,
      rquired: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
  },
});
