import { DateTime } from 'luxon';
import {
  ApiReservationCreatePayload,
  ApiSearchLicensesResponse,
  ApiSearchResponse,
} from '@/models/api';
import { AppAction, AppSpotDialog } from '@/models/app';
import { ReservationStatus } from '@/models/reservation';
import i18n from '@/i18n';
import {
  SearchCard,
  SearchCustomer,
  SearchLicense,
  SearchReservation,
  SearchResult,
  SearchResultGroup,
  SearchResultItem,
  SearchSpot,
} from '@/models/search';
import { SpotAttribute, SpotAttributesPayload } from '@/models/spot';
import router from '@/router';
import reservationService from '@/services/reservationService';
import store from '@/store';
import cookieUtil from './cookieUtil';
import globalUtil from '.';
import { StyleSpotAttribute, StyleSpotAttributeIcons } from '@/models/style';
import dateUtil from './dateUtil';

export default {
  /**
   * Convert a raw element into formatted element
   * @param {MapElementRaw} rawElement
   * @return {MapElement}
   */
  deserialize(response: ApiSearchResponse): SearchResult {
    const groups = [] as Array<SearchResultGroup>;
    // Reservations
    const reservationsItems = response.result.reservations.map(
      (res: SearchReservation, index: number): SearchResultItem => {
        const attributesPayload: SpotAttributesPayload = {
          temporary: res.status === ReservationStatus.NOT_CONFIRMED,
          seasonal: res.seasonal,
          halfDay: res.halfDay,
          startDate: DateTime.fromSeconds(res.startDate).startOf('day'),
          endDate: DateTime.fromSeconds(res.endDate).startOf('day'),
          startDateRef: DateTime.utc().startOf('day'),
          endDateRef: DateTime.utc().startOf('day'),
        };
        const attributes: Array<SpotAttribute> =
          globalUtil.spotAttributes(attributesPayload);
        const style: StyleSpotAttribute =
          globalUtil.spotStyleAttribute(attributes);
        return {
          dataKey: `reservation-${index.toString()}`,
          img:
            res.spotType === null
              ? require('@/assets/images/ticket/ticket-blue.svg')
              : (style.icons as StyleSpotAttributeIcons)[res.spotType],
          label: `${
            res.spotName ?? i18n.global.t('topBarSearch.reservations.ticket')
          } - ${res.firstName ?? ''} ${res.lastName ?? ''}`,
          route: `/reservation/${res.id}`,
        };
      },
    );
    const reservationsGroup = {} as SearchResultGroup;
    reservationsGroup.label = 'topBarSearch.reservations.groupLabel'; // i18n
    // reservationsGroup.icon = require('@/assets/images/map/umbrella/grey.svg');
    reservationsGroup.items = reservationsItems;
    if (reservationsGroup.items.length > 0) groups.push(reservationsGroup);

    // Future reservations
    const futureReservationsItems = response.result.futureReservations.map(
      (res: SearchReservation, index: number): SearchResultItem => ({
        dataKey: `future-reservation-${index.toString()}`,
        img:
          res.spotType === null
            ? require('@/assets/images/ticket/ticket-red.svg')
            : // eslint-disable-next-line import/no-dynamic-require
              require(`@/assets/images/map/${res.spotType}/red.svg`),
        label: `${
          res.spotName ?? i18n.global.t('topBarSearch.reservations.ticket')
        } - ${res.firstName ?? ''} ${
          res.lastName ?? ''
        } - ${dateUtil.fromSeconds(res.startDate, 'dd/MM')}`,
        route: `/reservation/${res.id}`,
      }),
    );
    const futureReservationsGroup = {} as SearchResultGroup;
    futureReservationsGroup.label =
      'topBarSearch.futureReservations.groupLabel'; // i18n
    // reservationsGroup.icon = require('@/assets/images/map/umbrella/grey.svg');
    futureReservationsGroup.items = futureReservationsItems;
    if (futureReservationsGroup.items.length > 0) {
      groups.push(futureReservationsGroup);
    }

    // spots
    const spotsItems = response.result.spots.map(
      (res: SearchSpot, index: number): SearchResultItem => {
        const createReservationCallback =
          (item: SearchSpot) => async (): Promise<void> => {
            const reservation = await reservationService.createOne({
              spotName: item.name,
              spotType: item.type,
              startDate: store.getters['map/calendar'][0].toSeconds(),
              endDate: store.getters['map/calendar'][1].toSeconds(),
            } as ApiReservationCreatePayload);
            // store.commit('reservation/setSpot', this.element);
            store.commit('app/setAction', AppAction.CREATE_RESERVATION);
            router.push(`/reservation/${reservation.id}`);
          };

        const spotDialogCallback = (item: SearchSpot): void => {
          store.commit('app/setSpotDialog', {
            spotName: item.name,
            spotType: item.type,
            callback: createReservationCallback(res),
          } as AppSpotDialog);
        };

        return {
          // eslint-disable-next-line import/no-dynamic-require
          img: require(`@/assets/images/map/${res.type}/grey.svg`),
          label: `${res.name}`,
          dataKey: `spot-${index.toString()}`,
          callback: () => spotDialogCallback(res),
        };
      },
    );
    const spotsGroup = {} as SearchResultGroup;
    spotsGroup.label = 'topBarSearch.spots.groupLabel'; // i18n
    // spotsGroup.icon = require('@/assets/images/map/umbrella/grey.svg');
    spotsGroup.items = spotsItems;
    if (spotsGroup.items.length > 0) groups.push(spotsGroup);

    // customers
    const customersItems = response.result.customers.map(
      (res: SearchCustomer, index: number): SearchResultItem => ({
        dataKey: `customer-${index.toString()}`,
        icon: 'spi-user',
        iconStyle: res.contactId ? 'color: #00b0ff;' : '',
        label:
          (res.firstName && res.firstName !== '') ||
          (res.lastName && res.lastName !== '')
            ? `${res.firstName} ${res.lastName}`
            : `${res.email}`,
        route: res.contactId
          ? `/customers/contacts/${res.contactId}`
          : `/customers/contacts/${encodeURIComponent(
              res.firstName,
            )},${encodeURIComponent(res.lastName)},${encodeURIComponent(
              res.email,
            )},${encodeURIComponent(res.phoneNumber)}`,
      }),
    );
    const customersGroup = {} as SearchResultGroup;
    customersGroup.label = 'topBarSearch.customers.groupLabel'; // i18n
    // customersGroup.icon = require('@/assets/images/map/umbrella/grey.svg');
    customersGroup.items = customersItems;
    if (customersGroup.items.length > 0) groups.push(customersGroup);

    // cards
    const cardsItems = response.result.cards.map(
      (res: SearchCard, index: number): SearchResultItem => ({
        icon: 'spi-credit-card',
        label: `${res.firstName} ${res.lastName}`,
        route: `/customers/cards/${res.idWorldCard}`,
        dataKey: `card-${index.toString()}`,
      }),
    );
    const cardsGroup = {} as SearchResultGroup;
    cardsGroup.label = 'topBarSearch.cards.groupLabel'; // i18n
    // cardsGroup.icon = require('@/assets/images/map/umbrella/grey.svg');
    cardsGroup.items = cardsItems;
    if (cardsGroup.items.length > 0) groups.push(cardsGroup);

    return groups;
  },
  deserializeLicenses(response: ApiSearchLicensesResponse): SearchResult {
    const groups = [] as Array<SearchResultGroup>;
    const items = response.result.licenses.map(
      (license: SearchLicense, index: number): SearchResultItem => {
        // eslint-disable-next-line func-names
        const callback = async (_vm: any) => {
            // console.log(this);
          cookieUtil.set(
            process.env.VUE_APP_LICENSE_COOKIE,
            license.license,
            365,
          );
          await _vm.$store.dispatch('session/setLicense', license.license);
          await _vm.$store.dispatch('session/setup');
          _vm.$spiagge.toast.success('Licenza selezionata con successo');
          _vm.$router.push(
            _vm.$route.redirectedFrom
              ? _vm.$route.redirectedFrom.fullPath
              : '/',
          );
        };
        return {
          img: license.img,
          label: `${license.name}`,
          dataKey: `license-${index.toString()}`,
          callback,
        };
      },
    );
    const licensesGroup = {} as SearchResultGroup;
    licensesGroup.label = 'topBarSearch.licenses.groupLabel'; // i18n
    // licensesGroup.icon = require('@/assets/images/map/umbrella/grey.svg');
    licensesGroup.items = items;
    if (licensesGroup.items.length > 0) groups.push(licensesGroup);
    return groups;
  },
};
