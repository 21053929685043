
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'ProgressDialog',
  data() {
    return {
      sidebar: false,
    };
  },
  computed: {
    ...mapState('app', ['progressDialog']),
    isHtml(): boolean {
      return (
        this.progressDialog.content &&
        this.progressDialog.content.charAt(0) === '<'
      );
    },
  },
});
