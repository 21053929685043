import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveDynamicComponent as _resolveDynamicComponent, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ccef8e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "header-label label" }
const _hoisted_3 = { class: "header-label label" }
const _hoisted_4 = { class: "items" }
const _hoisted_5 = { class: "item-label label" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "item-label label" }
const _hoisted_8 = {
  key: 2,
  class: "sub-items items"
}
const _hoisted_9 = { class: "item-label label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_SplitButton = _resolveComponent("SplitButton")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.breakpoints.mobile)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 0,
          label: _ctx.$t('common.menu'),
          icon: "spi-bars",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sidebar = true))
        }, null, 8, ["label"]))
      : _createCommentVNode("", true),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.breakpoints.mobile ? 'Sidebar' : 'nav'), {
      visible: _ctx.sidebar,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sidebar) = $event)),
      class: "menu"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (block, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "block",
            key: index
          }, [
            (block.header)
              ? (_openBlock(), _createElementBlock("header", {
                  key: 0,
                  id: block.header.id ?? '',
                  class: "header"
                }, [
                  (block.header.to)
                    ? _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        class: "item-link action-link p-ripple",
                        to: block.header.to
                      }, {
                        default: _withCtx(() => [
                          (block.header.icon)
                            ? (_openBlock(), _createElementBlock("i", {
                                key: 0,
                                class: _normalizeClass([block.header.icon, "header-icon icon"])
                              }, null, 2))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", _hoisted_2, _toDisplayString(block.header.label), 1),
                          (block.header.splitButton)
                            ? (_openBlock(), _createBlock(_component_SplitButton, {
                                key: 1,
                                ref: `split-button-${index}`,
                                label: block.header.splitButton.label,
                                model: block.header.splitButton.items,
                                class: "p-button-outlined p-button-sm"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Button, {
                                    class: "p-splitbutton-defaultbutton",
                                    onClick: ($event: any) => (_ctx.onSplitButton(index)),
                                    label: _ctx.$t('button.new')
                                  }, null, 8, ["onClick", "label"])
                                ]),
                                _: 2
                              }, 1032, ["label", "model"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["to"])), [
                        [_directive_ripple]
                      ])
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        (block.header.icon)
                          ? (_openBlock(), _createElementBlock("i", {
                              key: 0,
                              class: _normalizeClass([block.header.icon, "header-icon icon"])
                            }, null, 2))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", _hoisted_3, _toDisplayString(block.header.label), 1),
                        (block.header.splitButton)
                          ? (_openBlock(), _createBlock(_component_SplitButton, {
                              key: 1,
                              ref: `split-button-${index}`,
                              label: block.header.splitButton.label,
                              model: block.header.splitButton.items,
                              class: "p-button-outlined p-button-sm"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Button, {
                                  class: "p-splitbutton-defaultbutton",
                                  onClick: ($event: any) => (_ctx.onSplitButton(index)),
                                  label: _ctx.$t('button.new')
                                }, null, 8, ["onClick", "label"])
                              ]),
                              _: 2
                            }, 1032, ["label", "model"]))
                          : _createCommentVNode("", true)
                      ], 64))
                ], 8, _hoisted_1))
              : _createCommentVNode("", true),
            _createElementVNode("ul", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(block.items, (item, index) => {
                return (_openBlock(), _createElementBlock("li", {
                  key: index,
                  class: _normalizeClass(["item", [
            item.classes ? item.classes.join() : '',
            {
              'has-sub-items': item.items,
            },
          ]])
                }, [
                  (item.to)
                    ? _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        class: "item-link router-link p-ripple",
                        to: item.to
                      }, {
                        default: _withCtx(() => [
                          (item.icon)
                            ? (_openBlock(), _createElementBlock("i", {
                                key: 0,
                                class: _normalizeClass(["item-icon icon", item.icon])
                              }, null, 2))
                            : _createCommentVNode("", true),
                          _createElementVNode("span", _hoisted_5, _toDisplayString(item.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["to"])), [
                        [_directive_ripple]
                      ])
                    : _createCommentVNode("", true),
                  (item.action)
                    ? _withDirectives((_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: "item-link action-link p-ripple",
                        onClick: ($event: any) => (item.action())
                      }, [
                        (item.icon)
                          ? (_openBlock(), _createElementBlock("i", {
                              key: 0,
                              class: _normalizeClass(["item-icon icon", item.icon])
                            }, null, 2))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", _hoisted_7, _toDisplayString(item.label), 1)
                      ], 8, _hoisted_6)), [
                        [_directive_ripple]
                      ])
                    : _createCommentVNode("", true),
                  (item.items)
                    ? (_openBlock(), _createElementBlock("ul", _hoisted_8, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, (subItem, index) => {
                          return (_openBlock(), _createElementBlock("li", {
                            key: index,
                            class: "sub-item item"
                          }, [
                            (item.to)
                              ? _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                                  key: 0,
                                  class: "item-link action-link p-ripple",
                                  to: item.to
                                }, {
                                  default: _withCtx(() => [
                                    (item.icon)
                                      ? (_openBlock(), _createElementBlock("i", {
                                          key: 0,
                                          class: _normalizeClass(["item-icon icon", item.icon])
                                        }, null, 2))
                                      : _createCommentVNode("", true),
                                    _createElementVNode("span", _hoisted_9, _toDisplayString(item.label), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to"])), [
                                  [_directive_ripple]
                                ])
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ], 2))
              }), 128))
            ])
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["visible"]))
  ], 64))
}