
import { DateTime } from 'luxon';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { PlannerMode } from '@/models/planner';
import PlannerLegend from './PlannerLegend.vue';
import PlannerTodayButton from './PlannerTodayButton.vue';
import PlannerSectorFilter from './PlannerSectorFilter.vue';
import cookieUtil from '@/utils/cookieUtil';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

export default defineComponent({
  name: 'PlannerHeader',
  emits: ['changeZoom', 'changeYear'],
  components: {
    PlannerLegend,
    PlannerSectorFilter,
    PlannerTodayButton,
  },
  data() {
    return {
      minZoom: 20,
      maxZoom: 100,
    };
  },
  computed: {
    ...mapState('planner', ['zoom', 'year']),
    mode(): PlannerMode {
      return this.$store.getters['planner/mode'];
    },
    loading(): boolean {
      return this.$store.getters['app/loading'];
    },
    yearClasses() {
      const classes = ['year'];
      if (
        !DateTime.now().hasSame(DateTime.fromFormat(this.year, 'yyyy'), 'year')
      ) {
        classes.push('diff-year');
      }
      return classes;
    },
    hasPermission(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.planning,
        FEATURE_PERMISSION_ACTION_CONFIG.planning.ADD_UPDATE_RESERVATIONS,
      );
    },
  },
  methods: {
    getBtnModeClasses(btnMode: keyof typeof PlannerMode): Array<string> {
      const classes = [
        'p-button',
        'p-component',
        'p-button-icon-only',
        'p-button-rounded',
        'control-button',
      ];
      const mode = PlannerMode[btnMode];
      if (mode === this.mode) {
        classes.push('active');
      }
      if (mode === PlannerMode.CUT) {
        classes.push('disabled');
      }
      return classes;
    },
    decZoom(): void {
      this.$store.commit('planner/setZoom', this.zoom - 10);
      cookieUtil.set('spit_pl_z', this.zoom);
    },
    incZoom(): void {
      this.$store.commit('planner/setZoom', this.zoom + 10);
      cookieUtil.set('spit_pl_z', this.zoom);
    },
    decYear(): void {
      const year = DateTime.fromFormat(this.year, 'yyyy')
        .minus({ years: 1 })
        .toFormat('yyyy');
      this.$store.dispatch('planner/setYear', year);
    },
    incYear(): void {
      const year = DateTime.fromFormat(this.year, 'yyyy')
        .plus({ years: 1 })
        .toFormat('yyyy');
      this.$store.dispatch('planner/setYear', year);
    },
    setMode(mode: PlannerMode): void {
      this.$store.commit('planner/setMode', mode);
    },
    toggleNewMode(): void {
      if (this.mode === PlannerMode.NEW) {
        this.setMode(PlannerMode.VIEW);
      } else {
        this.setMode(PlannerMode.NEW);
      }
    },
    toggleMoveMode(): void {
      if (this.mode === PlannerMode.MOVE) {
        this.setMode(PlannerMode.VIEW);
      } else {
        this.setMode(PlannerMode.MOVE);
      }
    },
    toggleEditMode(): void {
      if (this.mode === PlannerMode.EDIT) {
        this.setMode(PlannerMode.VIEW);
      } else {
        this.setMode(PlannerMode.EDIT);
      }
    },
  },
});
