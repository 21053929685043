import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  id: "statistics-log-cancellations-view",
  class: "statistics-view view"
}
const _hoisted_2 = { id: "cards-slider" }
const _hoisted_3 = { id: "table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsCard = _resolveComponent("StatisticsCard")!
  const _component_Slider = _resolveComponent("Slider")!
  const _component_StatisticsDataTable = _resolveComponent("StatisticsDataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_Slider, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cards, (card, index) => {
            return (_openBlock(), _createBlock(_component_StatisticsCard, {
              key: index,
              label: card.label,
              value: card.value,
              tooltip: card.tooltip ?? ''
            }, null, 8, ["label", "value", "tooltip"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createVNode(_component_StatisticsDataTable, {
        id: _ctx.id,
        columns: _ctx.columns,
        searchColumns: _ctx.searchColumns,
        defaultView: _ctx.defaultView,
        endpoint: _ctx.endpoint,
        basePayload: _ctx.basePayload,
        onRowClick: _ctx.openReservation,
        rowClass: _ctx.rowClass,
        onResult: _ctx.onResult
      }, null, 8, ["id", "columns", "searchColumns", "defaultView", "endpoint", "basePayload", "onRowClick", "rowClass", "onResult"])
    ])
  ]))
}