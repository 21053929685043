
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Legend',
  props: {
    view: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    legendClasses(): string {
      const classes = ['legend-dialog'];
      if (this.open) classes.push('open');
      if (this.view === 'planner') {
        classes.push('planner');
      } else {
        classes.push('map');
      }
      return classes.join(' ');
    },
  },
  methods: {
    toggleLegend() {
      this.open = !this.open;
    },
  },
});
