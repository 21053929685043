export enum ColorName {
  RED = 'Red',
  BLUE = 'Blue',
  GREEN = 'Green',
  YELLOW = 'Yellow',
  ORANGE = 'Orange',
  PINK = 'Pink',
  WHITE = 'White',
  BLACK = 'Black',
  PURPLE = 'Purple',
  // Fluo
  RED_FLUO = 'Fluored',
  BLUE_FLUO = 'Fluoblue',
  GREEN_FLUO = 'Fluogreen',
  YELLOW_FLUO = 'Fluoyellow',
  PINK_FLUO = 'Fluopink',
  WHITE_FLUO = 'Fluowhite',
  PURPLE_FLUO = 'Fluopurple',
  // Mixed
  BLACK_WHITE = 'Black-White',
  BLACK_BLUE = 'Black-Blue',
  BLACK_YELLOW = 'Black-Yellow',
  BLACK_GREY = 'Black-Grey',
  BLACK_RED = 'Black-Red',
  BLACK_GREEN = 'Black-Green',
  // BLACK_PINK = 'Black-Pink',
  BLACK_PURPLE = 'Black-Purple',
  // Only border and bg
  GREY = 'Grey',
  CYAN = 'Cyan',
  BROWN = 'Brown',
  LIME = 'Lime',
}

export const SPOT_ICON_COLORS = [
  ColorName.RED,
  ColorName.BLUE,
  ColorName.GREEN,
  ColorName.YELLOW,
  ColorName.ORANGE,
  ColorName.PINK,
  ColorName.WHITE,
  ColorName.BLACK,
  ColorName.PURPLE,
  ColorName.RED_FLUO,
  ColorName.BLUE_FLUO,
  ColorName.GREEN_FLUO,
  ColorName.YELLOW_FLUO,
  ColorName.PINK_FLUO,
  ColorName.WHITE_FLUO,
  ColorName.PURPLE_FLUO,
  ColorName.BLACK_WHITE,
  ColorName.BLACK_BLUE,
  ColorName.BLACK_YELLOW,
  ColorName.BLACK_GREY,
  ColorName.BLACK_RED,
  ColorName.BLACK_GREEN,
  // ColorName.BLACK_PINK,
  ColorName.BLACK_PURPLE,
];

export const BORDER_COLORS = [
  ColorName.RED,
  ColorName.BLUE,
  ColorName.GREEN,
  ColorName.YELLOW,
  ColorName.ORANGE,
  ColorName.PINK,
  ColorName.WHITE,
  ColorName.BLACK,
  ColorName.PURPLE,
  // ColorName.RED_FLUO,
  // ColorName.BLUE_FLUO,
  // ColorName.GREEN_FLUO,
  // ColorName.YELLOW_FLUO,
  // ColorName.PINK_FLUO,
  // ColorName.WHITE_FLUO,
  // ColorName.PURPLE_FLUO,
];

export const HOTEL_BG_COLORS = [
  null,
  ColorName.RED,
  ColorName.BLUE,
  ColorName.GREEN,
  ColorName.YELLOW,
  ColorName.ORANGE,
  ColorName.PINK,
];

export const VOUCHER_BG_COLORS = [
  null,
  // ColorName.WHITE,
  ColorName.RED,
  ColorName.BLUE,
  ColorName.GREEN,
  ColorName.YELLOW,
  ColorName.ORANGE,
  ColorName.PINK,
];

export const SERVICE_BG_COLORS = [
  null,
  ColorName.RED,
  ColorName.BLUE,
  ColorName.GREEN,
  ColorName.YELLOW,
  ColorName.ORANGE,
  ColorName.PINK,
  ColorName.GREY,
  ColorName.CYAN,
  ColorName.BROWN,
  ColorName.LIME,
];

export interface ColorSpec {
  icon?: string;
  rgb: string;
  background: string;
}

// Objects
export const colorSpecs = new Map<string, ColorSpec>([
  [
    'default',
    {
      rgb: '#FFFFFF',
      background: 'rgba(255, 255, 255, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.RED,
    {
      icon: 'red',
      rgb: '#FF1919',
      background: 'rgba(255, 171, 171, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.BLUE,
    {
      icon: 'blue',
      rgb: '#199CFF',
      background: 'rgba(171, 219, 255, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.GREEN,
    {
      icon: 'green',
      rgb: '#41F700',
      background: 'rgba(187, 247, 165, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.YELLOW,
    {
      icon: 'yellow',
      rgb: '#FFC100',
      background: 'rgba(255, 235, 171, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.ORANGE,
    {
      icon: 'orange',
      rgb: '#FFAA00',
      background: 'rgba(255, 220, 151, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.PINK,
    {
      icon: 'pink',
      rgb: '#FF80BF',
      background: 'rgba(255, 171, 213, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.GREY,
    {
      icon: 'grey',
      rgb: '#AFAFAF',
      background: 'rgba(238, 238, 238, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.CYAN,
    {
      icon: 'cyan',
      rgb: '#4DFFFF',
      background: 'rgba(171, 255, 255, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.BROWN,
    {
      icon: 'brown',
      rgb: '#964B00',
      background: 'rgba(237, 198, 159, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.LIME,
    {
      icon: 'lime',
      rgb: '#C7EA46',
      background: 'rgba(237, 255, 171, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.WHITE,
    {
      icon: 'white',
      rgb: '#FBFBFB',
      background: 'rgba(255, 255, 255, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.BLACK,
    {
      icon: 'black',
      rgb: '#242424',
      background: 'rgba(222, 222, 222, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.PURPLE,
    {
      icon: 'purple',
      rgb: '#CB4DFF',
      background: 'rgba(230, 171, 255, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.WHITE_FLUO,
    {
      icon: 'whitefluo',
      rgb: '#FBFBFB',
      background: 'rgba(255, 255, 255, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.RED_FLUO,
    {
      icon: 'redfluo',
      rgb: '#FF1919',
      background: 'rgba(255, 235, 171, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.BLUE_FLUO,
    {
      icon: 'bluefluo',
      rgb: '#199CFF',
      background: 'rgba(171, 219, 255, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.GREEN_FLUO,
    {
      icon: 'greenfluo',
      rgb: '#41F700',
      background: 'rgba(187, 247, 165, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.YELLOW_FLUO,
    {
      icon: 'yellowfluo',
      rgb: '#FFC100',
      background: 'rgba(255, 235, 171, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.PURPLE_FLUO,
    {
      icon: 'purplefluo',
      rgb: '#CB4DFF',
      background: 'rgba(230, 171, 255, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.BLACK_WHITE,
    {
      icon: 'black-white',
      rgb: '#FBFBFB',
      background: 'rgba(255, 255, 255, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.BLACK_RED,
    {
      icon: 'black-red',
      rgb: '#FF1919',
      background: 'rgba(255, 235, 171, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.BLACK_BLUE,
    {
      icon: 'black-blue',
      rgb: '#199CFF',
      background: 'rgba(171, 219, 255, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.BLACK_GREEN,
    {
      icon: 'black-green',
      rgb: '#41F700',
      background: 'rgba(187, 247, 165, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.BLACK_YELLOW,
    {
      icon: 'black-yellow',
      rgb: '#FFC100',
      background: 'rgba(255, 235, 171, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.BLACK_PURPLE,
    {
      icon: 'black-purple',
      rgb: '#CB4DFF',
      background: 'rgba(230, 171, 255, 1)',
    } as ColorSpec,
  ],
  [
    ColorName.BLACK_GREY,
    {
      icon: 'black-grey',
      rgb: '#AFAFAF',
      background: 'rgba(238, 238, 238, 1)',
    } as ColorSpec,
  ],
]);
