
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
import { LicenseRecycleBinMode } from '@/models/license';
import {
  ApiLogCreatePayload,
  DeleteReservationsGroupPayload,
} from '@/models/api';
import { ReservationStatus } from '@/models/reservation';
import databaseService from '@/services/databaseService';
import licenseService from '@/services/licenseService';
import fileUtil from '@/utils/fileUtil';
import { LogAction } from '@/models/log';
import logService from '@/services/logService';

interface Model {
  recycleBinMode: LicenseRecycleBinMode;
}

interface PanelStates {
  [key: string]: boolean;
}

enum State {
  occupied = 1,
  notConfirmed,
  seasonal,
  notConfirmedSeasonal,
  cashDesk,
}

export default defineComponent({
  name: 'DataBaseView',
  data() {
    return {
      model: {} as Model,
      startDate: DateTime.utc().startOf('day').toSeconds(),
      endDate: DateTime.utc().startOf('day').toSeconds(),
      confirmCancellationDialog: false,
      enableCancellationButton: false,
      isUploadFileEnabled: false,
      fileNameVisible: false,
      paid: null,
      voucher: null,
      invoice: null,
      fileUploadRerenderCount: 0,
      state: {} as State | null,
      yesNoAll: [
        {
          label: this.$t('common.yes'),
          value: true,
        },
        {
          label: this.$t('common.no'),
          value: false,
        },
        {
          label: this.$t('databaseView.allPlaceholder'),
          value: null,
        },
      ],
      printOptions: [
        {
          label: this.$t('databaseView.printOption.printed'),
          value: true,
        },
        {
          label: this.$t('databaseView.printOption.notPrinted'),
          value: false,
        },
        {
          label: this.$t('databaseView.allPlaceholder'),
          value: null,
        },
      ],
      stateOptions: [
        {
          label: this.$t('databaseView.stateOption.occupied'),
          value: State.occupied,
        },
        {
          label: this.$t('databaseView.stateOption.notConfirmed'),
          value: State.notConfirmed,
        },
        {
          label: this.$t('databaseView.stateOption.seasonal'),
          value: State.seasonal,
        },
        {
          label: this.$t('databaseView.stateOption.notConfirmedSeasonal'),
          value: State.notConfirmedSeasonal,
        },
        {
          label: this.$t('databaseView.stateOption.cashDesk'),
          value: State.cashDesk,
        },
        {
          label: this.$t('databaseView.allPlaceholder'),
          value: null,
        },
      ],
      disableBackupButton: false,
      panelStates: {
        export: true,
        delete: true,
        import: true,
        trash: true,
      } as PanelStates,
    };
  },
  methods: {
    confirmCancellation() {
      this.$confirm.require({
        message: this.$t('databaseView.confirm.deleteMessage'),
        header: this.$t('databaseView.confirm.deleteTitle'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('common.yes'),
        accept: () => {
          this.deleteReservations();
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          // do nothing
        },
      });
    },
    async deleteReservations() {
      this.confirmCancellationDialog = false;
      this.enableCancellationButton = true;
      const payload = {
        startDate: Math.round(this.startDate),
        endDate: Math.round(this.endDate),
        voucher: this.voucher,
        invoice: this.invoice,
        paid: this.paid,
      } as DeleteReservationsGroupPayload;

      switch (this.state) {
        case State.occupied:
          payload.status = ReservationStatus.CONFIRMED;
          break;
        case State.notConfirmed:
          payload.status = ReservationStatus.NOT_CONFIRMED;
          break;
        case State.seasonal:
          payload.seasonal = true;
          break;
        case State.notConfirmedSeasonal:
          payload.seasonal = true;
          payload.status = ReservationStatus.NOT_CONFIRMED;
          break;
        case State.cashDesk:
          payload.bill = true;
          break;
        default:
          break;
      }

      try {
        await databaseService.deleteReservationsGroup(payload);
        this.$spiagge.toast.success(
          this.$t('toast.save.dataBaseDeleteContent'),
        );
      } catch (error) {
        this.$spiagge.toast.error(this.$t('toast.error.title'));
      } finally {
        this.enableCancellationButton = false;
      }
    },
    async backupJson() {
      this.disableBackupButton = true;
      try {
        const res = await licenseService.backupJson();
        const dateString = DateTime.now().toFormat('d-MM-yyyy_HH:mm');
        dateString.replace(' ', '_');
        fileUtil.download(res, `${dateString}-${this.license?.license}.json`);
      } catch (error) {
        console.error(error);
        this.$spiagge.toast.error(this.$t('toast.error.msg'));
      } finally {
        this.disableBackupButton = false;
      }
    },
    confirmImport(event: any) {
      this.$confirm.require({
        message: this.$t('databaseView.confirm.importTitle'),
        header: this.$t('databaseView.confirm.importMessage'),
        icon: 'pi pi-info-circle',
        acceptLabel: this.$t('common.yes'),
        accept: () => {
          this.importJsonBackup(event);
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          // do nothing
        },
      });
    },
    async importJsonBackup(event: any) {
      const fileJson = event.files[0];
      const reader = new FileReader();
      this.isUploadFileEnabled = true;
      const logCreatePayload = {
        action: LogAction.IMPORT_BACKUP_FILE_REQUEST,
        value: `${DateTime.now().toSeconds()}`,
      } as ApiLogCreatePayload;
      logService.create(logCreatePayload);

      // create the file with the correct content
      const file = new FormData();
      file.append('importJson', fileJson);
      try {
        // send file to back
        const res1 = await licenseService.importJson(file);
      } catch (error) {
        console.error(error);
      } finally {
        this.isUploadFileEnabled = false;
      }

      this.fileNameVisible = false;
      this.fileUploadRerenderCount += 1;
      reader.readAsText(fileJson);
    },
    onSelect(event: any) {
      this.fileNameVisible = true;
      const spanFileName = this.$refs.fileName as HTMLElement;
      spanFileName.textContent = '';
      spanFileName.textContent = event.files[0].name;
    },
    onClear() {
      this.fileUploadRerenderCount += 1;
      const spanFileName = this.$refs.fileName as HTMLElement;
      spanFileName.textContent = '';
      this.fileNameVisible = false;
    },
    toggle(name: string) {
      // eslint-disable-next-line no-param-reassign
      this.panelStates[name] = !this.panelStates[name];
    },
  },
  computed: {
    ...mapState('session', ['license']),
    startDateJs: {
      get(): Date {
        return DateTime.fromSeconds(this.startDate).toJSDate();
      },
      set(value: Date) {
        const date = `${value.getDate()}-${
          value.getMonth() + 1
        }-${value.getFullYear()}`;
        this.startDate = DateTime.fromFormat(date, 'd-M-yyyy').toSeconds();
      },
    },
    endDateJs: {
      get(): Date {
        return DateTime.fromSeconds(this.endDate).toJSDate();
      },
      set(value: Date) {
        const date = `${value.getDate()}-${
          value.getMonth() + 1
        }-${value.getFullYear()}`;
        this.endDate = DateTime.fromFormat(date, 'd-M-yyyy').toSeconds();
      },
    },
  },
});
