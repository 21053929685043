
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import StatisticsDataTable from '@/components/statistics/StatisticsDataTable.vue';
import statisticService from '@/services/statisticService';
import { StatisticDataTableColumn, StatisticDataTableView } from '@/models/statistic';
import { SPOT_INFOS, SpotInfo } from '@/models/spot';

export default defineComponent({
  name: 'StatisticsSectorSpotsView',
  components: { StatisticsDataTable },
  data() {
    return {
      id: 'sector-spots',
      endpoint: statisticService.sectorSpots,
      exportEndpoint: statisticService.exportSectorSpots,
      columns: [
        {
          label: this.$t('statisticsSectorSpotsView.column.sectorName'),
          field: 'sectorName',
          sortable: false,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.spotType'),
          field: 'spotType',
          formatter: (value: string) =>
            SPOT_INFOS.find((spot: SpotInfo) => spot.type === value)?.label,
          sortable: false,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.totalSpots'),
          field: 'totalSpots',
          sortable: false,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.reservationsCount'),
          field: 'reservationsCount',
          sortable: true,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.averageOccupancy'),
          field: 'averageOccupancy',
          formatter: (value: number) => `${value} %`,
          sortable: true,
          average: true,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.people'),
          field: 'people',
          sortable: true,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.averageReservedDays'),
          field: 'averageReservedDays',
          sortable: true,
          average: true,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.seasonalReservations'),
          field: 'seasonalReservations',
          sortable: true,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.voucherReservations'),
          field: 'voucherReservations',
          sortable: true,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.freeReservations'),
          field: 'freeReservations',
          sortable: true,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.totalRevenue'),
          field: 'totalRevenue',
          sortable: true,
          formatter: (value: number) => `${value} €`,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.averageRevenue'),
          field: 'averageRevenue',
          sortable: true,
          formatter: (value: number) => `${value} €`,
          average: true,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.averageReservationValue'),
          field: 'averageReservationValue',
          sortable: true,
          formatter: (value: number) => `${value} €`,
          average: true,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.productivity'),
          field: 'productivity',
          sortable: true,
          formatter: (value: number) => `${value} €`,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.arrivedReservations'),
          field: 'arrivedReservations',
          sortable: true,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.arrivedPeople'),
          field: 'arrivedPeople',
          sortable: true,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.arrivalsRevenue'),
          field: 'arrivalsRevenue',
          sortable: true,
          formatter: (value: number) => `${value} €`,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.cashFlow'),
          field: 'cashFlow',
          sortable: true,
          formatter: (value: number) => `${value} €`,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.maximumRevenue'),
          field: 'maximumRevenue',
          sortable: true,
          formatter: (value: number) => `${value} €`,
        },
        {
          label: this.$t('statisticsSectorSpotsView.column.missedRevenue'),
          field: 'missedRevenue',
          sortable: true,
          formatter: (value: number) => `${value} €`,
        },
      ] as Array<StatisticDataTableColumn>,
      basePayload: {},
      searchColumns: [],
      defaultView: {
        id: DateTime.now().startOf('day').toSeconds(),
        label: this.$t('statisticsSectorSpotsView.defaultViewLabel'),
        columns: [
          'sectorName',
          'spotType',
          'totalSpots',
          'reservationsCount',
          'averageOccupancy',
          'people',
          'averageReservedDays',
          'seasonalReservations',
          'voucherReservations',
          'freeReservations',
          'totalRevenue',
          'averageRevenue',
          'averageReservationValue',
          'productivity',
          'arrivedReservations',
          'arrivedPeople',
          'arrivalsRevenue',
          'cashFlow',
          'maximumRevenue',
          'missedRevenue',
        ],
        orderBy: [],
      } as StatisticDataTableView,
    };
  },
  beforeMount(): void {
    this.$store.commit(
        'statistic/setTitle',
        this.$t('statisticsSectorSpotsView.setTitle'),
    );
  },
});
