
import { defineComponent } from 'vue';
import { cloneDeep } from 'lodash';
import { COUNTRIES, Country } from '@/models/country';

export default defineComponent({
  name: 'CountryDropdown',
  emits: ['update:modelValue', 'change'],
  props: {
    modelValue: {
      type: String,
      required: false,
      default: null,
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    optionValue: {
      type: String,
      required: false,
      default: 'alpha2',
    },
  },
  data() {
    return {
      countries: cloneDeep(COUNTRIES),
    };
  },
  beforeMount(): void {
    /**
     * Spiagge API use alpha2 in lowercase
     */
    this.countries.forEach((country: Country) => {
      // eslint-disable-next-line no-param-reassign
      country.alpha2 = country.alpha2.toLocaleLowerCase();
    });

    let country: Country | undefined;
    const defaultCountry: Country | undefined = this.countries.find(
      (country: Country) => country[this.optionValue] === this.default,
    );

    if (this.modelValue) {
      country = this.countries.find(
        (c: Country) => c.alpha2 === this.modelValue,
      );
    } else {
      country = defaultCountry;
    }

    this.model = country;
  },
  computed: {
    model: {
      get(): Country | undefined {
        return this.countries.find(
          (country: Country) => country[this.optionValue] === this.modelValue,
        );
      },
      set(country: Country | undefined): void {
        const value = country ? country[this.optionValue] : null;
        this.$emit('update:modelValue', value);
        this.$emit('change', value);
      },
    },
  },
});
