import axios, { AxiosResponse } from 'axios';
import {
  ApiWebticTerminalCreatePayload,
  ApiWebticTerminalCreateResponse,
  ApiWebticTerminalListResponse,
  ApiWebticTerminalToggleResponse,
  ApiWebticTerminalUpdatePayload,
  ApiWebticTerminalUpdateResponse,
} from '@/models/api';
import { WebticTerminal } from '@/models/webtic';
import apiService from './apiService';

export default {
  async list(): Promise<Array<WebticTerminal>> {
    const res: AxiosResponse<ApiWebticTerminalListResponse> = await axios.get(
      apiService.webticTerminal.list(),
    );
    return res.data.result.webticTerminal;
  },
  async create(
    payload: ApiWebticTerminalCreatePayload,
  ): Promise<WebticTerminal> {
    const res: AxiosResponse<ApiWebticTerminalCreateResponse> =
      await axios.post(apiService.webticTerminal.create(), payload);
    return res.data.result.webticTerminal;
  },
  async update(
    webticTerminalId: number,
    payload: ApiWebticTerminalUpdatePayload,
  ): Promise<WebticTerminal> {
    const res: AxiosResponse<ApiWebticTerminalUpdateResponse> =
      await axios.patch(
        apiService.webticTerminal.update(webticTerminalId),
        payload,
      );
    return res.data.result.webticTerminal;
  },
  async delete(webticTerminalId: number): Promise<void> {
    return axios.delete(apiService.webticTerminal.delete(webticTerminalId));
  },
  async toggle(webticTerminalId: number): Promise<WebticTerminal> {
    const res: AxiosResponse<ApiWebticTerminalToggleResponse> =
      await axios.patch(apiService.webticTerminal.toggle(webticTerminalId));
    return res.data.result.webticTerminal;
  },
};
