
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import OverlayPanel from 'primevue/overlaypanel';
import { User } from '@/models/user';
import { License } from '@/models/license';
import { NavigationItem } from '../../models/navigation';
import TopBarLocaleSelector from '@/components/topbar/TopBarLocaleSelector.vue';

export default defineComponent({
  name: 'TopBarProfile',
  components: { TopBarLocaleSelector },
  data() {
    return {
      menu: [
        {
          label: this.$t('common.profile'),
          icon: 'spi-user',
          cta: () => {
            const profileUrl = 'https://www.spiagge.it/impostazioni/';
            if (this.appPlatform && this.appPlatform.toString() === 'ios') {
              window.webkit.messageHandlers.openOnBrowser.postMessage(
                profileUrl,
              );
            } else if (
              this.appPlatform &&
              this.appPlatform.toString() === 'android'
            ) {
              window.Android.openOnBrowser(profileUrl);
            } else {
              window.location.href = profileUrl;
            }
          },
        },
        {
          label: this.$t('common.settings'),
          icon: 'spi-settings',
          cta: () => {
            this.$router.push('/settings');
            this.toggle();
          },
        },
        {
          label: this.$t('common.changeBeach'),
          icon: 'spi-change',
          cta: () => {
            this.$router.push('/license-select');
            this.toggle();
          },
        },
        {
          label: this.$t('common.guide'),
          icon: 'spi-help',
          cta: () => {
            // TODO
            this.toggle();
          },
        },
        {
          label: this.$t('common.logout'),
          icon: 'spi-logout',
          cta: () => {
            window.location.href = this.$store.getters['session/links'].logout;
          },
        },
      ] as Array<NavigationItem>,
    };
  },
  methods: {
    toggle(event: Event): void {
      (this.$refs.op as OverlayPanel).toggle(event);
    },
  },
  computed: {
    ...mapState('session', ['appPlatform', 'appPlatformBuild']),
    user(): User | null {
      return this.$store.getters['session/user'];
    },
    license(): License | null {
      return this.$store.getters['session/license'];
    },
    avatarLabel(): string {
      return this.user
        ? (this.user.name.charAt(0) + this.user.surname.charAt(0)).toUpperCase()
        : '';
    },
    roleLogged(): string {
      const role = this.$store.getters['session/roleUser'];
      let lang = this.$t(`rolesDefault.${role}`);
      if (lang.startsWith('rolesDefault.')) {
        lang = role;
      }
      return lang;
    },
  },
});
