
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { ContentLoader } from 'vue-content-loader';
import { has } from 'lodash';
import { DISCOUNT_CODE_SERVICE_ID, Service } from '@/models/service';
import { ApiReservationUpdateServicePayload } from '@/models/api';
import { ReservationService, ReservationType } from '@/models/reservation';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';
import { SectorHeader } from '@/models/sector';

interface CustomServiceModel {
  [key: number]: number;
}

export default defineComponent({
  name: 'ReservationExtraServices',
  components: {
    ContentLoader,
  },
  data() {
    return {
      customServiceModel: null as unknown as CustomServiceModel,
      updateServiceDebounce: debounce(
        this.updateService as (service: Service) => void,
        500,
      ),
    };
  },
  methods: {
    hasUpdatePermission(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.reservations,
        FEATURE_PERMISSION_ACTION_CONFIG.reservations.UPDATE,
      );
    },
    updateService(service: Service): void {
      const payload = {
        serviceId: service.id,
      } as ApiReservationUpdateServicePayload;
      const newValue = this.customServiceModel[service.id];
      const oldValue = this.reservationServices.get(service.id)?.paid as number;
      if (newValue === oldValue) return;
      if (newValue === null) {
        this.customServiceModel[service.id] = oldValue;
        return;
      }
      const currentService = this.reservationServices.get(service.id);
      if (newValue > oldValue) {
        payload.bought =
          (currentService?.bought as number) + (newValue - oldValue);
      } else {
        payload.deleted =
          (currentService?.deleted as number) + (oldValue - newValue);
      }
      try {
        this.$store.dispatch('reservation/updateService', payload);
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationExtraServices.toast.error'),
        );
      }
    },
    isServiceVisible(service: Service): boolean {
      return this.isServiceSellable(service) || this.isServiceBought(service);
    },
    isServiceSellable(service: Service): boolean {
      if (this.type === ReservationType.MOVING) {
        return service.reservationSchedule;
      }
      if (this.isBillReservation) {
        return service.fastCash;
      }
      const isRightSector =
        (service.sectors?.filter(
          (sector: SectorHeader) => this.sector.id === sector.id,
        )?.length ?? 0) > 0;
      // Also check if it can be sold on a reservation
      return isRightSector && service.reservationSchedule;
    },
    isServiceBought(service: Service): boolean {
      return (this.reservationServices?.get(service.id)?.paid ?? 0) > 0;
    },
  },
  computed: {
    ...mapState('reservation', [
      'id',
      'startDate',
      'endDate',
      'sector',
      'type',
    ]),
    ...mapGetters('reservation', ['isBillReservation']),
    // Only extra services
    visibleExtraServices(): Array<Service> {
      /**
       * Reservation customs services *visible* (beach and tickets) except beach ticket service
       */
      const services = this.$store.getters['session/beachServices'];
      return services.filter((service: Service) =>
        this.isServiceVisible(service),
      );
    },
    utilityServices(): Array<Service> {
      /**
       * Reservation utility services
       */
      return this.$store.getters['session/utilityServices'].filter(
        (service: Service) =>
          service.id !== DISCOUNT_CODE_SERVICE_ID &&
          has(this.customServiceModel, service.id) &&
          this.reservationServices.get(service.id)?.bought !== 0,
      );
    },
    reservationServices(): Map<number, ReservationService> {
      // Every service associated to the reservation that is a ticket
      const result = new Map<number, ReservationService>();
      const reservationServices = this.$store.getters['reservation/services'];
      reservationServices.forEach((resService: ReservationService) => {
        result.set(resService.serviceId, resService);
      });
      return result;
    },
  },
  watch: {
    reservationServices(services: Map<number, ReservationService>): void {
      /**
       * Rebuild custom service model on reservation services change
       */
      const customServiceModel = {} as CustomServiceModel;
      services.forEach((rs: ReservationService, key: number) => {
        customServiceModel[key] = rs.paid;
      });
      this.customServiceModel = customServiceModel;
    },
  },
});
