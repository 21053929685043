
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import { ApiReservationDisjoinAccountPayload } from '@/models/api';
import i18n from '@/i18n';

export default defineComponent({
  name: 'DisjoinAccountButton',
  props: {
    reservationId: {
      type: Number,
      required: false,
      default: 0,
    },
    spotName: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    onDisjoinAccount(): void {
      /**
       * Disjoin account or single account part
       */
      const message = this.reservationId
        ? this.$t('disjoinAccountButton.confirm.reservationMessage', {
            spotName: this.spotName,
          })
        : this.$t('disjoinAccountButton.confirm.accountMessage');
      this.$confirm.require({
        message,
        header: i18n.global.t('disjoinAccountButton.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: i18n.global.t('common.yes'),
        accept: async () => {
          const payload = {
            reservations: this.reservationId ? [this.reservationId] : [],
          } as ApiReservationDisjoinAccountPayload;

          try {
            await this.$store.dispatch('reservation/disjoinAccount', payload);
            if (this.reservationId && this.id) {
              // single delete, many joint account parts are still in the joint account
              this.$spiagge.toast.success(
                i18n.global.t('disjoinAccountButton.toast.success', {
                  spotName: this.spotName,
                }),
              );
            } else {
              // full delete or only one joint account part remained
              // (reservation module is resetted by dispatch)
              this.$spiagge.toast.success(
                i18n.global.t('disjoinAccountButton.toast.deleteSuccess'),
              );
              this.$router.push('/map');
            }
          } catch (error) {
            this.$spiagge.toast.error(
              i18n.global.t('disjoinAccountButton.toast.error'),
            );
          }
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          // nothing
        },
      });
    },
  },
  computed: {
    ...mapState('reservation', ['id']),
    ...mapGetters('reservation', ['isJointAccount', 'canDisjoinAccount']),
    label(): string {
      /**
       * Button label
       */
      return this.reservationId
        ? this.$t('disjoinAccountButton.disjoin', { spotName: this.spotName })
        : this.$t('disjoinAccountButton.disjoinAccount');
    },
  },
});
