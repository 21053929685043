import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeClass as _normalizeClass, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d4dfe01"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "price-lists-voucher-detail-view",
  class: "price-lists-view view"
}
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "col-12 sm:col-6 lg:col-4" }
const _hoisted_4 = { class: "p-field name-field" }
const _hoisted_5 = { for: "name-input" }
const _hoisted_6 = {
  key: 0,
  class: "p-error"
}
const _hoisted_7 = { class: "col-12 sm:col-6 lg:col-4" }
const _hoisted_8 = { class: "p-field hotel-price-list-id-field" }
const _hoisted_9 = { for: "hotel-price-list-id-dropdown" }
const _hoisted_10 = {
  key: 0,
  class: "p-error"
}
const _hoisted_11 = { class: "col-12 sm:col-6 lg:col-4" }
const _hoisted_12 = { class: "p-field customer-price-list-id-field" }
const _hoisted_13 = { for: "customer-price-list-id-dropdown" }
const _hoisted_14 = {
  key: 0,
  class: "p-error"
}
const _hoisted_15 = { class: "col-12 sm:col-6 lg:col-4" }
const _hoisted_16 = { class: "p-field background-field" }
const _hoisted_17 = { for: "background-dropdown" }
const _hoisted_18 = { class: "col-12 sm:col-6 lg:col-4" }
const _hoisted_19 = { class: "p-field work-id-field" }
const _hoisted_20 = { for: "work-id-input" }
const _hoisted_21 = { class: "col-12 sm:col-6 lg:col-4" }
const _hoisted_22 = { class: "p-field email-field" }
const _hoisted_23 = { for: "email-input" }
const _hoisted_24 = { class: "col-12 sm:col-6 lg:col-4" }
const _hoisted_25 = { class: "p-field online-mode-field" }
const _hoisted_26 = { for: "online-mode-switch" }
const _hoisted_27 = { class: "col-12 sm:col-6 lg:col-4" }
const _hoisted_28 = { class: "p-field online-payment-field" }
const _hoisted_29 = { for: "online-payment-switch" }
const _hoisted_30 = { class: "col-12 sm:col-6 lg:col-4" }
const _hoisted_31 = { class: "p-field online-discount-field" }
const _hoisted_32 = { for: "online-discount-input" }
const _hoisted_33 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Field = _resolveComponent("Field")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.mounted && _ctx.voucher)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#price-lists-header-teleport"
        }, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('priceListsVoucherDetailView.title')) + " " + _toDisplayString(_ctx.voucher.name), 1),
          _createElementVNode("p", null, _toDisplayString(_ctx.$t('priceListsVoucherDetailView.subtitle')), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Form, {
        onSubmit: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onSave()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_Field, {
                name: "name",
                modelValue: _ctx.model.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event)),
                rules: "required"
              }, {
                default: _withCtx(({ field, meta, errorMessage }) => [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('priceListsVoucherDetailView.name')), 1),
                    _createVNode(_component_InputText, _mergeProps({ id: "name-input" }, field, {
                      type: "text",
                      class: !meta.valid && meta.touched ? 'p-invalid' : ''
                    }), null, 16, ["class"]),
                    (!meta.valid && meta.touched)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(errorMessage), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_Field, {
                name: "hotel-price-list-id",
                modelValue: _ctx.model.hotelPriceListId,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.hotelPriceListId) = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, handleChange, meta, errorMessage }) => [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('priceListsVoucherDetailView.priceList')), 1),
                    _createVNode(_component_Dropdown, {
                      id: "hotel-price-list-id-dropdown",
                      options: _ctx.priceListsOptions,
                      optionLabel: "label",
                      optionValue: "value",
                      class: _normalizeClass(!meta.valid && meta.touched ? 'p-invalid' : ''),
                      "model-value": field.value,
                      "onUpdate:modelValue": handleChange,
                      placeholder: _ctx.$t('common.none')
                    }, null, 8, ["options", "class", "model-value", "onUpdate:modelValue", "placeholder"]),
                    (!meta.valid && meta.touched)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(errorMessage), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode(_component_Field, {
                name: "customer-price-list-id",
                modelValue: _ctx.model.customerPriceListId,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.customerPriceListId) = $event)),
                rules: ""
              }, {
                default: _withCtx(({ field, handleChange, meta, errorMessage }) => [
                  _createElementVNode("div", _hoisted_12, [
                    _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('priceListsVoucherDetailView.customerPriceList')), 1),
                    _createVNode(_component_Dropdown, {
                      id: "customer-price-list-id-dropdown",
                      options: _ctx.priceListsOptions,
                      optionLabel: "label",
                      optionValue: "value",
                      class: _normalizeClass(!meta.valid && meta.touched ? 'p-invalid' : ''),
                      "model-value": field.value,
                      "onUpdate:modelValue": handleChange,
                      placeholder: _ctx.$t('common.none')
                    }, null, 8, ["options", "class", "model-value", "onUpdate:modelValue", "placeholder"]),
                    (!meta.valid && meta.touched)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_14, _toDisplayString(errorMessage), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('priceListsVoucherDetailView.background')), 1),
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.model.background,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.background) = $event)),
                  options: _ctx.backgroundOptions,
                  optionLabel: "label",
                  optionValue: "value",
                  placeholder: _ctx.$t('common.none')
                }, null, 8, ["modelValue", "options", "placeholder"])
              ])
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t('priceListsVoucherDetailView.workId')), 1),
                _createVNode(_component_InputNumber, {
                  id: "work-id-input",
                  modelValue: _ctx.model.workId,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.workId) = $event)),
                  min: 0,
                  step: 1
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t('priceListsVoucherDetailView.email')), 1),
                _createVNode(_component_InputText, {
                  id: "email-input",
                  modelValue: _ctx.model.email,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model.email) = $event)),
                  type: "text"
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("label", _hoisted_26, _toDisplayString(_ctx.$t('priceListsVoucherDetailView.enableOnlineMode')), 1),
                _createVNode(_component_InputSwitch, {
                  modelValue: _ctx.model.onlineMode,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model.onlineMode) = $event))
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t('priceListsVoucherDetailView.enableOnlinePayment')), 1),
                _createVNode(_component_InputSwitch, {
                  modelValue: _ctx.model.onlinePayment,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model.onlinePayment) = $event))
                }, null, 8, ["modelValue"])
              ])
            ]),
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("div", _hoisted_31, [
                _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t('priceListsVoucherDetailView.onlineDiscount')), 1),
                _createVNode(_component_InputNumber, {
                  modelValue: _ctx.model.onlineDiscount,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model.onlineDiscount) = $event)),
                  showButtons: "",
                  suffix: " %",
                  min: 0,
                  max: 100,
                  allowEmpty: false
                }, null, 8, ["modelValue"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_33, [
            _createVNode(_component_Button, {
              icon: "spi-trash",
              class: "p-button-outlined p-button-danger",
              label: _ctx.$t('button.delete'),
              onClick: _ctx.onDelete
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_Button, {
              icon: "spi-check",
              label: _ctx.$t('button.save'),
              type: "submit"
            }, null, 8, ["label"])
          ])
        ]),
        _: 1
      })
    ])
  ], 64))
}