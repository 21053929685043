/**
 * Search models
 */

import { Timestamp } from '.';
import { AreaCode } from './areaCode';
import { ReservationHalfDay, ReservationStatus } from './reservation';
import { SpotType } from './spot';

export type SearchResultItem = { dataKey: string; label: string } & (
  | { icon: string; iconStyle?: string }
  | { img: string }
) &
  ({ route: string } | { href: string } | { callback: (res: any) => void });
export interface SearchResultGroup {
  label: string;
  icon: string;
  items: Array<SearchResultItem>;
}

export type SearchResult = Array<SearchResultGroup>;

// Search API response custom types
export interface SearchReservation {
  id: number;
  spotType: SpotType | null;
  spotName: string | null;
  firstName: string;
  lastName: string;
  startDate: Timestamp;
  endDate: Timestamp;
  halfDay: ReservationHalfDay;
  status: ReservationStatus;
  seasonal: boolean;
}

// export interface SearchHotel {
//   count: number;
//   hotel: string;
// }

export interface SearchCustomer {
  contactId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneAreaCode: AreaCode;
  phoneNumber: string;
}

export interface SearchSpot {
  type: SpotType;
  name: string;
}

export interface SearchCard {
  idWorldCard: number;
  number: string;
  token: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface SearchLicense {
  license: string;
  name: string;
  img: string;
}
