
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
// import { Address, Customer, OtherContact, Phone } from '@/models/customer';
import { DataTableSortMeta } from 'primevue/datatable';
import { Customer, CustomerTag } from '@/models/customer';
import { License } from '@/models/license';
import customerService from '@/services/customerService';
import bookingService from '@/services/bookingService';
import CustomerDialog from '@/components/shared/CustomerDialog.vue';
import CardsDialog from '@/components/shared/CardsDialog.vue';
import { Reservations } from '@/models/booking';
import { Reservation, ReservationType } from '@/models/reservation';
import { Card } from '@/models/card';
import {
  ApiCustomerStatisticsPayload,
  ApiReservationFindPayload,
} from '@/models/api';
import StatisticsCard from '@/components/statistics/StatisticsCard.vue';
import StatisticsDoughnutChart from '@/components/statistics/charts/StatisticsDoughnutChart.vue';
import {
  StatisticCustomer,
  StatisticCard as StatisticCardI,
  StatisticDoughnutChartModel,
} from '@/models/statistic';
import { OrderByItem } from '@/models';
import reservationService from '@/services/reservationService';
import Slider from '@/components/shared/Slider.vue';
import { SpotType } from '@/models/spot';

import { DataTableRowSortPayload } from '@/models/dataTable';
import statisticService from '@/services/statisticService';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

export default defineComponent({
  components: {
    CustomerDialog,
    CardsDialog,
    Slider,
    StatisticsCard,
    StatisticsDoughnutChart,
  },
  data() {
    return {
      isLoading: true,
      customerSelected: {} as Customer,
      reservationsList: [] as Array<Reservation>,
      dataTableSelect: [] as Array<Reservation>,
      resToMergeList: [] as Array<string>,
      multiSortMeta: [] as Array<DataTableSortMeta>,
      orderBy: [
        {
          field: 'createdAt',
          value: 'desc',
        },
      ] as Array<DataTableRowSortPayload>,
      pageRowCount: 10,
      cards: [] as Array<Card>,
      checkList: [] as Array<boolean>,
      newCard: {} as Card,
      show: false,
      showCardDialog: false,
      showDeleteDialog: false,
      totalCards: [
        {
          label: this.$t('contactView.totalCard.reservation.label'),
          value: null,
          tooltip: this.$t('contactView.totalCard.reservation.tooltip'),
        },
        {
          label: this.$t('contactView.totalCard.paid.label'),
          value: null,
          tooltip: this.$t('contactView.totalCard.paid.tooltip'),
        },
        {
          label: this.$t('contactView.totalCard.unpaid.label'),
          value: null,
          tooltip: this.$t('contactView.totalCard.unpaid.tooltip'),
        },
        {
          label: this.$t('contactView.totalCard.totalCost.label'),
          tooltip: this.$t('contactView.totalCard.totalCost.tooltip'),
          value: null,
        },
      ] as Array<StatisticCardI>,
      doughnutCharts: [
        {
          label: this.$t('contactView.doughnutChart.totalCost.label'),
          tooltip: this.$t('contactView.doughnutChart.totalCost.tooltip'),
          chartData: {
            labels: ['Offline', 'Online'],
            datasets: [
              {
                data: [],
                backgroundColor: ['#FFE596', '#E89300'],
              },
            ],
          },
        },
        {
          label: this.$t('contactView.doughnutChart.averageCost.label'),
          tooltip: this.$t('contactView.doughnutChart.averageCost.tooltip'),
          chartData: {
            labels: ['Offline', 'Online'],
            datasets: [
              {
                data: [],
                backgroundColor: ['#96DFFF', '#0083E8'],
              },
            ],
          },
          action: {
            label: this.$t('contactView.doughnutChart.averageCost.action'),
            icon: 'pi pi-arrow-right',
            cta: () => {
              // TODO
            },
          },
        },
        {
          label: this.$t(
            'contactView.doughnutChart.averageCostExtraServices.label',
          ),
          tooltip: this.$t(
            'contactView.doughnutChart.averageCostExtraServices.tooltip',
          ),
          chartData: {
            labels: ['Offline', 'Online'],
            datasets: [
              {
                data: [],
                backgroundColor: ['#FF80BF', '#CB4DFF'],
              },
            ],
          },
          action: {
            label: this.$t(
              'contactView.doughnutChart.averageCostExtraServices.action',
            ),
            icon: 'pi pi-arrow-right',
            cta: () => {
              // TODO
            },
          },
        },
      ] as Array<StatisticDoughnutChartModel>,
      calendar: [
        DateTime.now().startOf('day'),
        DateTime.now().startOf('day'),
      ] as Array<DateTime>,
      doughChartValueSymbol: '€',
    };
  },
  methods: {
    cardsUrlControl(url: string): string {
      const check = url.includes('http');
      let result = url;
      if (!check) {
        // https://com-spiagge-test.s3.amazonaws.com/WorldCardTypes/6242ce03d6e9e-1648545283.png
        result = `https://www.anm22.it/app/pay/utilities/img/cards/${url}`;
      }
      return result;
    },
    getSpotTypeIcon(spotType: SpotType | null): string {
      let spotIconUri = '';
      switch (spotType) {
        case SpotType.UMBRELLA:
          spotIconUri = require('@/assets/images/map/umbrella/red.svg');
          break;
        case SpotType.CABIN:
          spotIconUri = require('@/assets/images/map/cabins/red.svg');
          break;
        case SpotType.BED:
          spotIconUri = require('@/assets/images/map/bed/red.svg');
          break;
        case SpotType.BEDS:
          spotIconUri = require('@/assets/images/map/beds/red.svg');
          break;
        case SpotType.PARKING:
          spotIconUri = require('@/assets/images/map/parking/red.svg');
          break;
        case SpotType.GAZEBO:
          spotIconUri = require('@/assets/images/map/gazebo/red.svg');
          break;
        case null:
          spotIconUri = require('@/assets/images/ticket/ticket-red.svg');
          break;
        default:
          spotIconUri = require('@/assets/images/map/umbrella/red.svg');
          break;
      }
      return spotIconUri;
    },
    updateCustomer() {
      this.show = true;
    },
    closeDialog() {
      this.show = false;
    },
    async mergeContacts() {
      if (this.dataTableSelect && this.dataTableSelect.length > 0) {
        this.dataTableSelect.forEach((element) => {
          if (element) {
            this.resToMergeList.push(element.id.toString());
          }
        });
      }
      try {
        const send = {} as Reservations;
        send.reservations = this.resToMergeList;
        if (this.license) {
          const response = await bookingService.resevationsMerge(send);
          const url = this.$router.resolve({
            name: 'ReservationEdit',
            params: { id: response.id },
          });
          this.$router.push(url);
        }
      } catch (error) {
        // TODO
      }
      this.dataTableSelect = [] as Array<Reservation>;
    },
    getRegElementsString(reg: Reservation): string {
      if (reg.spotType === null) {
        return '';
      }

      if (!reg.beds && !reg.deckChairs && !reg.chairs && !reg.maxiBeds) {
        return '0L';
      }
      const string = this.$spiagge.utils.global.stagingLabel(
        reg.maxiBeds,
        reg.beds,
        reg.deckChairs,
        reg.chairs,
        ' ',
      );
      return string;
    },
    getRegDateString(reservation: Reservation): string {
      const startDate = DateTime.fromSeconds(reservation.startDate);
      const endDate = DateTime.fromSeconds(reservation.endDate);
      return `${this.$t(
        'common.from',
      )}: ${startDate.toLocaleString()} ${this.$t(
        'common.to',
      )}: ${endDate.toLocaleString()}`;
    },
    deleteDialogShow() {
      this.showDeleteDialog = true;
    },
    deleteDialogClose() {
      this.showDeleteDialog = false;
    },
    async deleteCustomer() {
      try {
        if (this.license) {
          await customerService.delete(this.customerSelected.contactId);
          this.$router.push('/customers/contacts');
          this.showDeleteDialog = false;
        }
      } catch (error) {
        this.$spiagge.toast.error(this.$t('contactView.toast.deleteError'));
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    checkTypeReservation(event: any) {
      if (event.data.type === 4) {
        const index = this.dataTableSelect.findIndex(
          (element) => element.id === event.data.id,
        );
        this.dataTableSelect.splice(index, 1);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    allCheckTypeReservation(event: any) {
      this.dataTableSelect = event.data.map((element: Reservation) => {
        if (element.type !== 4) {
          return element;
        }
      });
    },
    cathCard(value: Card) {
      if (value) {
        this.cards.push(value);
      }
    },
    cathClose() {
      this.showCardDialog = false;
    },
    createCards() {
      this.showCardDialog = true;
      this.newCard.email = this.customerSelected.email;
    },
    openCardView(cardId: number) {
      const url = this.$router.resolve({
        name: 'Cards',
        params: { idWorldCard: this.cards[cardId].idWorldCard },
      });
      this.$router.push(url);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    openReservation(row: any) {
      if (
        (row.originalEvent.target as HTMLElement).className !==
          'p-checkbox-icon' &&
        permissionsUtil.isActionPermissionAllowed(
          FEATURE_PERMISSION_CONFIG.reservations,
          FEATURE_PERMISSION_ACTION_CONFIG.reservations.PAGE_ACCESS,
        )
      ) {
        this.$router.push(`/reservation/${row.data.id}`);
      }
    },
    getRowClass(reservation: Reservation): string {
      return reservation.type === 4 ? 'hide-check' : '';
    },
    onCustomerSave(customer: Customer) {
      this.customerSelected = customer;
    },
    onSort(e: { multiSortMeta: Array<DataTableSortMeta> }) {
      const order = [] as Array<DataTableRowSortPayload>;

      this.multiSortMeta = e.multiSortMeta;
      this.multiSortMeta.forEach((element: DataTableSortMeta) => {
        order.push({
          field: element.field,
          value:
            typeof element.order === 'number'
              ? element.order < 0
                ? 'desc'
                : 'asc'
              : 'asc',
        } as DataTableRowSortPayload);
      });

      this.orderBy =
        order.length > 0
          ? order
          : [
              {
                field: 'createdAt',
                value: 'desc',
              },
            ];
    },
    onDateSelect(): void {
      if (this.calendar[1] !== null) {
        (this.$refs.calendar as any).overlayVisible = false;
      }
    },
    checkCalendar(): void {
      if (this.calendarModel[1] === null) {
        this.calendarModel = [this.calendarModel[0], this.calendarModel[0]];
      }
    },
    updateStatisticCharts(data: StatisticCustomer): void {
      this.totalCards[0].value = data.cards.reservationsTotal.toString();
      this.totalCards[1].value = `${data.cards.paidTotal.toString()}€`;
      this.totalCards[2].value = `${data.cards.toPay.toString()}€`;
      this.totalCards[3].value = `${data.cards.priceTotal.toString()}€`;
      this.doughnutCharts[0].chartData.datasets[0].data =
        data.doughnutChart.priceTotal;
      this.doughnutCharts[1].chartData.datasets[0].data =
        data.doughnutChart.priceTotalAverage;
      this.doughnutCharts[2].chartData.datasets[0].data =
        data.doughnutChart.priceTotalServiceAverage;
    },
  },
  computed: {
    loading(): boolean {
      return this.$store.getters['app/loading'];
    },
    license(): License {
      return this.$store.getters['session/license'];
    },
    mergeButtonShow(): boolean {
      return this.dataTableSelect.length > 1;
    },
    addressString(): string {
      if (!this.customerSelected) return '';
      return [
        this.customerSelected.invoiceAddress1,
        this.customerSelected.invoiceAddress2,
        this.customerSelected.invoiceCity,
        this.customerSelected.invoiceState,
        this.customerSelected.invoiceCountry,
      ].join(', ');
    },
    customerTagIds(): Array<number> {
      return this.customerSelected.tags ?? [];
    },
    customerTags(): Array<CustomerTag> {
      return this.license.tags.filter((x: CustomerTag) =>
        this.customerTagIds.includes(x.idWorldTag),
      );
    },
    calendarModel: {
      get(): Array<Date | null> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (this.calendar as any[]).map(
          (d: DateTime | null) => d?.toJSDate() ?? null,
        );
      },
      async set(calendar: Array<Date>) {
        const datetimes = (calendar as any[]).map((d: Date | null) => {
          if (!d) return null;
          const date = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
          return DateTime.fromFormat(date, 'd-M-yyyy');
        }) as Array<DateTime>;
        // Hack double click on same day
        if (
          this.calendar[0].toMillis() === datetimes[0]?.toMillis() &&
          !this.calendar[1] &&
          !datetimes[1]
        ) {
          // eslint-disable-next-line prefer-destructuring
          datetimes[1] = datetimes[0];
        }
        this.calendar = datetimes;
        const payload = {
          startDate: datetimes[0].toSeconds(),
          endDate: datetimes[1]
            ? datetimes[1].toSeconds()
            : datetimes[0].toSeconds(),
          contactId: this.customerSelected.contactId,
          firstName: this.customerSelected.firstName,
          lastName: this.customerSelected.lastName,
          email: this.customerSelected.email,
        } as ApiCustomerStatisticsPayload;
        const response = await statisticService.getCustomerStatistics(payload);
        this.updateStatisticCharts(response);
      },
    },
  },
  async mounted() {
    if (!this.$route.params.contactId) {
      this.$router.push('Contacts');
    }
    const currentYear = DateTime.now().year;
    const startYear = DateTime.fromISO(`${currentYear}-01-01T00:00:00.000`, {
      zone: 'utc',
    });
    const endYear = DateTime.fromISO(`${currentYear}-12-31T00:00:00.000`, {
      zone: 'utc',
    });

    try {
      if (Number(this.$route.params.contactId)) {
        const response = await customerService.one(
          Number(this.$route.params.contactId),
        );
        this.customerSelected = response.result.customer;
        this.cards = response.result.cards;
      } else {
        // Load contacts by fields
        const params = String(this.$route.params.contactId).split(',');
        this.customerSelected = {
          firstName: params[0],
          lastName: params[1],
          email: params[2],
          phoneNumber: params[3],
        } as Customer;
      }

      const payload = {} as ApiReservationFindPayload;
      payload.type = [
        ReservationType.STANDARD,
        ReservationType.ADDITION,
        ReservationType.BILL,
      ];
      payload.deleted = false;
      payload.orderBy = [
        {
          field: 'id',
          value: 'desc',
        } as OrderByItem,
      ];
      payload.contactId = this.customerSelected.contactId ?? 0;
      payload.firstName = this.customerSelected.firstName;
      payload.lastName = this.customerSelected.lastName;
      payload.email = this.customerSelected.email;
      payload.phoneNumber = this.customerSelected.phoneNumber;

      this.calendarModel = [startYear.toJSDate(), endYear.toJSDate()];
      const responseReservation = await reservationService.find(payload);

      this.reservationsList = responseReservation.result.reservations;
      this.reservationsList.forEach(() => {
        this.checkList.push(false);
      });
    } catch (e) {
      this.$spiagge.toast.error('si è verificato un errore');
    } finally {
      this.isLoading = false;
    }
  },
});
