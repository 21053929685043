
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { DateTime } from 'luxon';
import { orderBy } from 'lodash';
import { ReservationList } from '@/models/reservation';
import spotUtil from '@/utils/spotUtil';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

enum Filter {
  CURRENT,
  PAST,
}

export default defineComponent({
  name: 'ReservationList',
  data() {
    return {
      today: DateTime.now().startOf('day').toSeconds(),
      filter: Filter.CURRENT,
      filters: Filter,
      showFuture: true,
      showInProgress: true,
      showPast: true,
    };
  },
  methods: {
    async onNewReservation(): Promise<void> {
      this.$emit('newReservation');
    },
    canAddUpdateReservation(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.map,
        FEATURE_PERMISSION_ACTION_CONFIG.map.ADD_UPDATE_RESERVATIONS,
      );
    },
  },
  computed: {
    ...mapState('reservation', ['spot']),
    ...mapGetters('reservation', [
      'isEdit',
      'isCreate',
      'isJointAccount',
      'isPlayField',
    ]),
    ...mapState('app', ['action']),
    reservations(): Array<ReservationList> {
      return this.$store.getters['reservation/list'];
    },
    reservationsInProgress(): Array<ReservationList> {
      return this.reservations.filter(
        (res: ReservationList) =>
          this.today >= res.startDate && this.today <= res.endDate,
      );
    },
    reservationsFuture(): Array<ReservationList> {
      return orderBy(
        this.reservations.filter(
          (res: ReservationList) =>
            this.today < res.startDate && this.today < res.endDate,
        ),
        (o) => [o.endDate],
      );
    },
    reservationsPast(): Array<ReservationList> {
      return orderBy(
        this.reservations.filter(
          (res: ReservationList) => this.today > res.endDate,
        ),
        (o) => [o.startDate],
      ).reverse();
    },
    buttonLabel(): string {
      return `${this.$t('reservationList.newReservation')} ${
        this.isPlayField
          ? spotUtil.getPlayFieldDataByName(this.spot.name).name
          : this.spot.name
      }`;
    },
  },
});
