import { ServiceIcon, ServiceLimit, ServicePriceMode, ServiceType } from '@/models/service';
import { ReservationHalfDay } from '@/models/reservation';

const DEFAULT_SERVICE = {
    name: '',
    cost: 0,
    vat: 22,
    type: ServiceType.BEACH,
    halfDay: ReservationHalfDay.DAILY,
    priceMode: ServicePriceMode.FIXED,
    priceListId: null,
    fastCash: false,
    reservationSchedule: false,
    map: false,
    online: false,
    onlineEnabled: false,
    offlineEnabled: false,
    canBeSoldAloneOffline: false,
    canBeSoldAloneOnline: false,
    sellableOnlineAlone: false,
    lockedBehindServices: false,
    unlockServiceIds: [] as number[],
    serviceGroupId: null,
    sectors: [],
    maxItemsType: ServiceLimit.NONE_LIMIT,
    maxItemsFixed: 0,
    minItemsType: ServiceLimit.NONE_LIMIT,
    minItemsFixedAlone: 0,
    maxItemsTypeAlone: ServiceLimit.NONE_LIMIT,
    maxItemsFixedAlone: 0,
    minItemsTypeAlone: ServiceLimit.NONE_LIMIT,
    minItemsFixed: 0,
    hideWhenNotAvailableOnline: false,
    dailyItems: 0,
    icon: ServiceIcon.INSERT_COIN,
    background: null,
};

export default DEFAULT_SERVICE;
