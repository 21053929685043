import { SectorHeader } from './sector';

export interface Warehouse {
  id: number;
  name: string;
  licenseId: string;
  beds: number;
  maxiBeds: number;
  deckChairs: number;
  chairs: number;
  sectors: Array<SectorHeader>;
  counters: {
    beds: number;
    maxiBeds: number;
    deckChairs: number;
    chairs: number;
  };
}

export interface WarehouseAlert {
  code: string; // make better
  dates: Array<number>;
}
