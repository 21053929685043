import { DateTime } from 'luxon';
import i18n from '@/i18n';
import { Card } from './card';
import { Customer } from './customer';

export type Timestamp = number;

export enum Day {
  MONDAY = 'monday',
  TUESDAY = 'tuesday',
  WEDNESDAY = 'wednesday',
  THURSDAY = 'thursday',
  FRIDAY = 'friday',
  SATURDAY = 'saturday',
  SUNDAY = 'sunday',
}

export interface DayData {
  day: Day;
  label: string;
  abbrLabel: string;
}

export const DAYS_DATA: Array<DayData> = [
  {
    day: Day.MONDAY,
    label: i18n.global.t('day.monday'),
    abbrLabel: i18n.global.t('day.mon'),
  },
  {
    day: Day.TUESDAY,
    label: i18n.global.t('day.tuesday'),
    abbrLabel: i18n.global.t('day.tue'),
  },
  {
    day: Day.WEDNESDAY,
    label: i18n.global.t('day.wednesday'),
    abbrLabel: i18n.global.t('day.wed'),
  },
  {
    day: Day.THURSDAY,
    label: i18n.global.t('day.thursday'),
    abbrLabel: i18n.global.t('day.thu'),
  },
  {
    day: Day.FRIDAY,
    label: i18n.global.t('day.friday'),
    abbrLabel: i18n.global.t('day.fri'),
  },
  {
    day: Day.SATURDAY,
    label: i18n.global.t('day.saturday'),
    abbrLabel: i18n.global.t('day.sat'),
  },
  {
    day: Day.SUNDAY,
    label: i18n.global.t('day.sunday'),
    abbrLabel: i18n.global.t('day.sun'),
  },
];

export interface Staging {
  maxiBeds: number;
  beds: number;
  chairs: number;
  deckChairs: number;
}

// TODO this could be a generic interface DropdownOption<T>
export interface DropdownOption {
  label: string;
  value: string | number | boolean | null;
}

export interface StagingConfigurator {
  default: number;
  min: number;
  max: number;
  sector: number;
  type: string;
}

export interface ListElementString {
  name: string;
  code: string;
}

export interface ListElementNumber {
  name: string;
  code: number;
}

export interface ListElementBool {
  name: string;
  code: boolean;
}

export enum YBStripeTransferSchedule {
  WEEKLY = 1,
  BIWEEKLY,
  MONTHLY,
  OTHERDAY,
}
export interface Point2D {
  x: number;
  y: number;
}

export const PHONE_PREFIXES = [
  {
    value: '+39',
    label: '+39 (Italia)',
  },
];

export const NATION_PREFIXES = [
  {
    value: 'it',
    label: 'Italia',
  },
];

export enum Printers {
  CORISTECH_PRINTF = 31,
  CUSTOM = 41,
  CUSTOM_RAW = 42,
  CUSTOM_ESC_POS = 43,
  CUSTOM_REMOTE = 44,
  EPSON_FP81II = 21,
  EPSON_TM_P80_021 = 22,
  EPSON_TM_T20II = 23,
  EPSON_ESC_POS = 24,
  ITALRETAIL_PRX = 51,
  OLIVETTI_NETTUNO7000_OLD_DRIVER = 11,
  OLIVETTI_NETTUNO7000_OLD_DRIVER_V2 = 12,
  OLIVETTI_NETTUNA_300 = 13,
  OLIVETTI_NETTUNA_PRT200FX_OLD_DRIVER = 14,
  OLIVETTI_NETTUNA_PRT200FX = 15,
  OLIVETTI_NETTUNO7000 = 16,
  RCH_PRINTF = 2,
  RCH_PRINTF_FIDELITY = 3,
  RETEX_FISCAL = 61,
  RETEX_FISCAL_EFT = 62,
  RETEX_FISCAL_WALLET = 63,
}

export enum PaymentMethod {
  CASH = 1,
  BANCOMAT = 2,
  CREDIT_CARD = 3,
  PAYPAL = 9,
  SATISPAY = 10,
  LOYALTY_CARD = 11,
  BANK_TRANSFER = 12,
  CHEQUE = 13,
  STRIPE = 14,
  YB_CARD = 15,
}
export enum Credentials {
  SUPERUSER = 1,
  MANAGER = 2,
  ANALYST = 3,
  CASH_POINT_OPERATOR = 4,
  BEACH_ASSISTANT = 5,
  ADVERTISER = 6,
  CASH = 1,
  BANCOMAT = 2,
  CREDIT_CARD = 3,
  PAYPAL = 9,
  SATISPAY = 10,
  LOYALTY_CARD = 11,
  BANK_TRANSFER = 12,
  CHEQUE = 13,
  STRIPE = 14,
  YB_CARD = 15,
}

export interface CustomerCard {
  customer: Customer;
  cards: Array<Card>;
}

export interface OrderByItem {
  field: string;
  value: 'asc' | 'desc';
}

export interface FilterByItem {
  field: string;
  value: string;
}

export interface Breakpoints {
  mobile: boolean;
  desktop: boolean;
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  smUp: boolean;
  // smUp smDown ecc
}

export type CalendarRange = [DateTime, null] | [DateTime, DateTime];

export interface StripePayload {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  scope?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  code?: any;
}

export interface StripeFinalizeAccountPayload {
  type: string;

  state?: string;

  code?: string;
}

export interface Css {
  [key: string]: string;
}

export interface Breadcrumb {
  label: string;
  icon?: string;
  to?: string;
  action?: () => void;
}

export enum PriceRoundingMode {
  CLOSEST_INT = 'closest-int',
  CLOSEST_HALF = 'closest-half',
  DISABLED = 'no-rounding',
}
