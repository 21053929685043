
import { defineComponent } from 'vue';

import { RouteLocationRaw, RouterView } from 'vue-router';
import { mapGetters, mapState } from 'vuex';
import LayoutAsideContent from '@/layouts/LayoutAsideContent.vue';
import { Menu, MenuBlockItem } from '@/models/menu';
import { PriceList, PriceListType } from '@/models/priceList';
import { Voucher } from '@/models/voucher';
import localStorageUtil from '@/utils/localStorageUtil';
import { PriceConfigSession } from '@/models/priceConfig';
import PriceListsVoucherCreate from '@/components/price-lists/PriceListsVoucherCreate.vue';
import PriceListsSeasonalSettings from '@/components/price-lists/PriceListsSeasonalSettings.vue';
import PriceListsPriceListCreate from '@/components/price-lists/PriceListsPriceListCreate.vue';

interface CreateOption {
  label: string;
  command: () => void;
}

export default defineComponent({
  name: 'PriceListsView',
  components: {
    LayoutAsideContent,
    RouterView,
    PriceListsVoucherCreate,
    PriceListsSeasonalSettings,
    PriceListsPriceListCreate,
  },
  data() {
    return {
      isWizardOpen: true,
      showRouter: false,
      mounted: false,
      priceListOptions: [
        {
          label: this.$t('priceListsCreateView.wizardStandard'),
          command: () => {
            this.$router.push(`/price-lists/wizard?priceListType=${PriceListType.STANDARD}`);
          },
        },
        {
          label: this.$t('priceListsCreateView.wizardExtraServices'),
          command: () => {
            this.$router.push(`/price-lists/wizard?priceListType=${PriceListType.EXTRA_SERVICES}`);
          },
        },
        {
          label: this.$t('priceListsCreateView.wizardSeasonal'),
          command: () => {
            this.$router.push(`/price-lists/wizard?priceListType=${PriceListType.SEASONAL}`);
          },
        },
        {
          label: this.$t('priceListsView.uploadCsv'),
          command: () => {
            this.$store.commit('priceLists/setShowPriceListCreateDialog', true);
          },
        },
      ] as Array<CreateOption>,
      voucherOptions: [
        {
          label: this.$t('priceListsView.standard'),
          command: () => {
            this.$store.commit('priceLists/setShowVoucherCreateDialog', true);
          },
        },
      ] as Array<CreateOption>,
    };
  },
  async beforeMount(): Promise<void> {
    // module initialization
    await this.$store.dispatch('priceLists/init');

    // wizard disabled, just continue
    if (!this.wizardEnabled) {
      this.showRouter = true;
      return;
    }

    // init localstorage
    localStorageUtil.configure('spiagge', 'priceConfig');
    let restorePriceConfigSession = false;
    // get previous price config session
    const priceConfigSession: PriceConfigSession | null =
      await localStorageUtil.get('session');

    /**
     * Restore price config handler (a session is present on localstorage)
     * route /wizard and session id is 0 -> no restore (restored automatically after redirect)
     * route /wizard/:id and session id is :id -> no restore (restored automatically after redirect)
     */
    if (priceConfigSession) {
      restorePriceConfigSession = !(
        this.$route.name === 'PriceListsWizardView' &&
        ((!this.$route.params.id && !priceConfigSession.id) ||
          Number(this.$route.params.id) === priceConfigSession.id)
      );
    }

    /**
     * Restoring confirmation
     */
    if (priceConfigSession && restorePriceConfigSession) {
      this.$confirm.require({
        message: this.$t('priceListsView.confirm.message'),
        header: this.$t('priceListsView.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('common.yes'),
        accept: () => {
          let url = '/price-lists/wizard';
          if (priceConfigSession.id) {
            url += `/${priceConfigSession.id}`;
          }
          this.$router.push(url);
        },
        rejectLabel: this.$t('common.no'),
        reject: async () => {
          await localStorageUtil.clear();
          this.showRouter = true;
        },
      });
    } else {
      this.showRouter = true;
    }
  },
  mounted() {
    // New price list dropdown menu
    this.priceListOptions = [
      ...this.priceListOptions,
    ];
    this.mounted = true;
  },
  computed: {
    ...mapState('session', ['license']),
    ...mapState('priceLists', [
      'priceLists',
      'priceConfigs',
      'vouchers',
      'showPriceListCreateDialog',
      'showVoucherCreateDialog',
      'showSeasonalSettingsDialog',
    ]),
    ...mapGetters('priceLists', ['wizardEnabled']),
    isWizardView(): boolean {
      return (this.$route.name as string).includes('wizard');
    },
    menu(): Menu {
      const menu: Menu = [
        {
          header: {
            id: 'price-lists-home',
            label: this.$t('priceListsView.menu.home'),
            icon: 'spi-list-standard',
            to: '/price-lists/',
          },
          items: [],
        },
        {
          header: {
            id: 'price-lists',
            label: this.$t('priceListsView.menu.priceLists'),
            icon: 'spi-list-standard',
          },
          items: [],
        },
        {
          header: {
            id: 'vouchers',
            label: this.$t('priceListsView.menu.vouchers'),
            icon: 'spi-pricetag-alt',
          },
          items: [],
        },
      ];
      // price lists
      this.priceLists.forEach((priceList: PriceList) => {
        // price list without wizard
        if (priceList.draftPriceConfigId && !priceList.activePriceConfigId) {
          menu[1].items.push({
            label: `${priceList.name} (${this.$t('priceListsView.draft')})`,
            to: `/price-lists/wizard/${priceList.draftPriceConfigId}`,
          } as MenuBlockItem);
        }

        // price list generated
        if (
          priceList.activePriceConfigId ||
          (!priceList.draftPriceConfigId && !priceList.activePriceConfigId)
        ) {
          menu[1].items.push({
            label: `${priceList.name}`,
            to: `/price-lists/${priceList.id}`,
          } as MenuBlockItem);
        }
      });
      // price lists seasonal option
      menu[1].items.push({
        label: this.$t('common.seasonal'),
        classes: ['primary'],
        icon: 'pi pi-cog',
        action: () => {
          this.$store.commit('priceLists/setShowSeasonalSettingsDialog', true);
        },
      });
      // vouchers
      this.vouchers.forEach((voucher: Voucher) => {
        menu[2].items.push({
          label: voucher.name,
          to: `/price-lists/voucher/${voucher.id}`,
        } as MenuBlockItem);
      });
      return menu;
    },
  },
  async beforeRouteLeave(
    to: RouteLocationRaw,
    from: RouteLocationRaw,
    next: () => void,
  ) {
    /**
     * Refresh license vouchers
     */
    await this.$store.dispatch('session/setVouchers');
    next();
  },
});
