
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import { Point2D } from '@/models';

export default defineComponent({
  name: 'FreeDraw',
  props: {
    width: {
      type: Number,
      required: false,
      default: 130,
    },
    height: {
      type: Number,
      required: false,
      default: 80,
    },
    fileName: {
      type: String,
      required: true,
    },
    clear: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      canvas: null as unknown as HTMLCanvasElement,
      ctx: null as unknown as CanvasRenderingContext2D,
      coord: {
        x: 0,
        y: 0,
      } as Point2D,
    };
  },
  mounted() {
    this.canvas = document.getElementById('canvas') as HTMLCanvasElement;
    this.ctx = this.canvas.getContext('2d') as CanvasRenderingContext2D;
    this.ctx.fillStyle = '#FFFFFF';

    this.canvas.addEventListener('mousedown', this.start);
    this.canvas.addEventListener('mouseup', this.stop);
    // document.addEventListener('touchstart', this.start);
    // document.addEventListener('touchend', this.stop);
  },
  beforeUnmount() {
    this.canvas.removeEventListener('mousedown', this.start);
    this.canvas.removeEventListener('mouseup', this.stop);
  },
  methods: {
    start(event: MouseEvent) {
      this.canvas.addEventListener('mousemove', this.draw);
      this.reposition(event);
    },
    reposition(event: MouseEvent) {
      const bounds = (
        this.canvas.parentElement as HTMLElement
      ).getBoundingClientRect();
      const x = event.clientX - bounds.left;
      const y = event.clientY - bounds.top;
      this.coord.x = x;
      this.coord.y = y;
    },
    stop() {
      this.canvas.removeEventListener('mousemove', this.draw);
      this.canvas.toBlob(
        (blob) => {
          this.$emit(
            'update',
            new File(
              [blob as Blob],
              `${this.fileName}_${DateTime.now().toFormat('dd_MM_yyyy')}.jpg`,
              {
                type: 'image/jpg',
              },
            ),
          );
        },
        'image/png',
        1,
      );
    },
    draw(event: MouseEvent) {
      this.ctx.beginPath();
      this.ctx.lineWidth = 2;
      this.ctx.lineCap = 'round';
      this.ctx.strokeStyle = '#242424';
      this.ctx.moveTo(this.coord.x, this.coord.y);
      this.reposition(event);
      this.ctx.lineTo(this.coord.x, this.coord.y);
      this.ctx.stroke();
    },
    onClear(): void {
      this.ctx.clearRect(0, 0, this.width, this.height);
      this.$emit('update', null);
    },
  },
  computed: {
    style() {
      return {
        height: `${this.height}px`,
        width: `${this.width}px`,
      };
    },
  },
});
