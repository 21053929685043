import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42b96204"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex align-items-center justify-content-center" }
const _hoisted_2 = {
  key: 1,
  class: "reservations-actions"
}
const _hoisted_3 = { class: "reservations-wrapper" }
const _hoisted_4 = {
  key: 0,
  ref: "printContent",
  class: "reservations"
}
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "customer" }
const _hoisted_8 = { class: "staging" }
const _hoisted_9 = { class: "date" }
const _hoisted_10 = { class: "actions-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    id: "map-beds-area",
    class: "custom-dialog",
    modal: true,
    visible: _ctx.show,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.show) = $event)),
    onHide: _cache[2] || (_cache[2] = ($event: any) => (_ctx.show = false))
  }, {
    default: _withCtx(() => [
      _createElementVNode("h2", _hoisted_1, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t('mapBedsArea.title')) + " " + _toDisplayString(_ctx.spotName), 1),
        (_ctx.reservations.length > 0 && _ctx.ready)
          ? (_openBlock(), _createElementBlock("i", {
              key: 0,
              class: "spi-print ml-4",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onPrint && _ctx.onPrint(...args)))
            }))
          : _createCommentVNode("", true)
      ]),
      (!_ctx.ready)
        ? (_openBlock(), _createBlock(_component_Skeleton, {
            key: 0,
            width: "100%",
            height: "400px"
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              (_ctx.reservations.length > 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reservations, (reservation, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: _normalizeClass(["reservation spot", _ctx.attributes(reservation).join(' ')]),
                        style: _normalizeStyle(_ctx.style(reservation).css),
                        onClick: ($event: any) => (_ctx.$router.push(`/reservation/${reservation.id}`))
                      }, [
                        _createElementVNode("img", {
                          class: "icon",
                          src: _ctx.icon(reservation),
                          alt: "bed"
                        }, null, 8, _hoisted_6),
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$spiagge.utils.global.customerLabel(
                  reservation.firstName,
                  reservation.lastName,
                )), 1),
                        _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$spiagge.utils.global.stagingLabel(
                reservation.maxiBeds,
                reservation.beds,
                reservation.deckChairs,
                reservation.chairs,
              )), 1),
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t('common.to')) + ": " + _toDisplayString(_ctx.$spiagge.date.fromMillis(reservation.endDate, 'dd/MM')), 1)
                      ], 14, _hoisted_5))
                    }), 128))
                  ], 512))
                : (_openBlock(), _createBlock(_component_Message, {
                    key: 1,
                    severity: "info",
                    class: "m-0",
                    closable: false
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('mapBedsArea.noReservationMessage')), 1)
                    ]),
                    _: 1
                  }))
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_Button, {
                class: "new-reservation-button",
                onClick: _ctx.onNewReservation,
                label: _ctx.$t('mapBedsArea.addReservation')
              }, null, 8, ["onClick", "label"])
            ])
          ]))
    ]),
    _: 1
  }, 8, ["visible"]))
}