/**
 * License service
 */

import axios, { AxiosResponse } from 'axios';
import { ApiSearchLicensesResponse, ApiSearchResponse } from '@/models/api';
import apiService from './apiService';
import { SearchResult } from '@/models/search';
import searchUtil from '@/utils/searchUtil';

export default {
  async search(query: string): Promise<SearchResult> {
    const res: AxiosResponse<ApiSearchResponse> = await axios.get(
      apiService.search.search(query),
    );
    const result = searchUtil.deserialize(res.data);
    return result;
  },
  async searchLicenses(query: string): Promise<SearchResult> {
    const res: AxiosResponse<ApiSearchLicensesResponse> = await axios.get(
      apiService.search.searchLicenses(query),
    );
    const result = searchUtil.deserializeLicenses(res.data);
    return result;
  },
};
