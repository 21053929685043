import store from '@/store';
import {
  FeaturePermissionType,
  ActionPermissionType,
  RolePermission,
  DEFAULT_NAME_BASE_ALLOWED_FEATURE,
} from '@/models/permissions';
import type { StorePermission } from '@/models/permissions';
import permissionService from '@/services/permissionsService';

function checkActionPermission(
  feature: string,
  action: string,
): ActionPermissionType {
  const mapPermission: StorePermission = store.getters['session/permissions'];
  if (
    typeof mapPermission !== 'undefined' &&
    mapPermission !== null &&
    mapPermission[feature]
  ) {
    return mapPermission[feature].actions[action];
  }
  return ActionPermissionType.NOT_INCLUDED;
}

export default {
  /**
   * Store features and actions permissions
   * @param {RolePermission} role
   */
  storePermissionsData(role: RolePermission): void {
    const mapPermissions: StorePermission = {};

    if (typeof role.features !== 'undefined') {
      role.features.forEach((feature) => {
        mapPermissions[feature.name] = {
          permissionType: feature.permissionType,
          actions: {},
        };

        feature.actions.forEach((action) => {
          mapPermissions[feature.name].actions[action.name] =
            action.permissionType;
        });
      });
    }

    store.commit('session/setRoleUser', role.name);
    store.commit('session/setPermissions', mapPermissions);
  },
  checkAllowedShowFeaturePage(
    feature: string,
    action: string = DEFAULT_NAME_BASE_ALLOWED_FEATURE,
  ): boolean {
    const checkShowBaseAllowed = checkActionPermission(feature, action);
    if (typeof checkShowBaseAllowed !== 'undefined') {
      return checkShowBaseAllowed === ActionPermissionType.ALLOWED;
    }

    return (
      this.checkFeaturePermission(feature) === FeaturePermissionType.INCLUDED
    );
  },
  checkFeaturePermission(feature: string): FeaturePermissionType {
    const mapPermission: StorePermission = store.getters['session/permissions'];
    if (feature === 'revenue_manager' && mapPermission !== null) {
      // console.log(feature, mapPermission, typeof mapPermission[feature]);
    }
    if (
      typeof mapPermission !== 'undefined' &&
      mapPermission !== null &&
      typeof mapPermission[feature] !== 'undefined'
    ) {
      return mapPermission[feature].permissionType;
    }
    return FeaturePermissionType.NOT_INCLUDED;
  },
  isActionPermissionAllowed(feature: string, action: string): boolean {
    const res = checkActionPermission(feature, action);
    if (typeof res === 'undefined') {
      console.warn(`Action ${action} for feature ${feature} is not setted`);
    }
    return res === ActionPermissionType.ALLOWED;
  },
  async retrieveAndStoreDataPermission(license: string): Promise<void> {
    try {
      const resultRolePermissions = await permissionService.list(license);
      this.storePermissionsData(resultRolePermissions.result.role);
    } catch (error) {
      let errorMessage = error.message;
      if (
        error.response.data.error_ex &&
        error.response.data.error_ex.message
      ) {
        errorMessage = error.response.data.error_ex.message;
      }
      console.error(errorMessage);
    }
  },
};
