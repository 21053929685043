
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import { DataTableRowClickEvent } from 'primevue/datatable';
import StatisticsCard from '@/components/statistics/StatisticsCard.vue';
import {
  StatisticCard as StatisticCardI,
  StatisticDataTableColumn,
  StatisticDataTableColumnRender,
  StatisticDataTableView,
} from '@/models/statistic';
import StatisticsDataTable from '@/components/statistics/StatisticsDataTable.vue';
import statisticService from '@/services/statisticService';
import { ApiStatisticsLogCancellationsResponse } from '@/models/api';
import { SpotInfo, SPOT_INFOS } from '@/models/spot';
import Slider from '@/components/shared/Slider.vue';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

export default defineComponent({
  name: 'StatisticsLogCancellationsView',
  components: { StatisticsCard, StatisticsDataTable, Slider },
  data() {
    return {
      id: 'log-cancellations',
      endpoint: statisticService.logCancellations,
      columns: [
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.reservationId',
          ),
          field: 'reservationId',
          sortable: true,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.spotType',
          ),
          field: 'spotType',
          sortable: true,
          formatter: (value: string) =>
            SPOT_INFOS.find((spot: SpotInfo) => spot.type === value)?.label,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.spotName',
          ),
          field: 'spotName',
          sortable: true,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.firstName',
          ),
          field: 'firstName',
          sortable: true,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.lastName',
          ),
          field: 'lastName',
          sortable: true,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.staging',
          ),
          field: 'staging',
          sortable: false,
          formatter: (value: Array<number>) =>
            this.$spiagge.utils.global.stagingLabel(
              value[0],
              value[1],
              value[2],
              value[3],
            ),
        },
        {
          label: this.$tc('statisticsArrivalsDepartures.column.ticket', 2),
          field: 'tickets',
          sortable: false,
          renderer: 'tickets',
        },
        {
          label: this.$tc('statisticsArrivalsDepartures.column.service', 2),
          field: 'services',
          sortable: false,
          renderer: 'services',
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.startDate',
          ),
          field: 'startDate',
          sortable: true,
          formatter: (value: number) => this.$spiagge.date.fromMillis(value),
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.endDate',
          ),
          field: 'endDate',
          sortable: true,
          formatter: (value: number) => this.$spiagge.date.fromMillis(value),
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.hotel',
          ),
          field: 'hotel',
          sortable: true,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.duration',
          ),
          field: 'duration',
          sortable: true,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.online',
          ),
          field: 'online',
          sortable: true,
          renderer: StatisticDataTableColumnRender.BOOLEAN_ICON,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.channel',
          ),
          field: 'channel',
          sortable: true,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.priceTotal',
          ),
          field: 'priceTotal',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.listTotal',
          ),
          field: 'listTotal',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.paidTotal',
          ),
          field: 'paidTotal',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.toPay',
          ),
          field: 'toPay',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.discountPercentage',
          ),
          field: 'discountPercentage',
          sortable: true,
          formatter: (value: string) => `${value} %`,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.discountedBy',
          ),
          field: 'discountedBy',
          sortable: true,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.createdBy',
          ),
          field: 'createdBy',
          sortable: true,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.deletedBy',
          ),
          field: 'deletedBy',
          sortable: true,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.createdAt',
          ),
          field: 'createdAt',
          sortable: true,
          formatter: (value: number) =>
            this.$spiagge.date.fromMillis(value, 'dd-MM-yyyy HH:mm'),
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.deletedAt',
          ),
          field: 'deletedAt',
          sortable: true,
          formatter: (value: number) =>
            this.$spiagge.date.fromMillis(value, 'dd-MM-yyyy HH:mm'),
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.discountedAt',
          ),
          field: 'discountedAt',
          sortable: true,
          formatter: (value: number) =>
            this.$spiagge.date.fromMillis(value, 'dd-MM-yyyy HH:mm'),
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.voucher',
          ),
          field: 'voucher',
          sortable: true,
          renderer: StatisticDataTableColumnRender.BOOLEAN_ICON,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.voucherAmount',
          ),
          field: 'voucherAmount',
          sortable: true,
          formatter: (value: string) => `${value} €`,
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.column.notes',
          ),
          field: 'notes',
          sortable: false,
          classes: ['white-space-normal'],
        },
      ] as Array<StatisticDataTableColumn>,
      basePayload: {},
      searchColumns: [],
      defaultView: {
        id: DateTime.now().startOf('day').toSeconds(),
        label: this.$t(
          'statisticsLogCancellationsDiscountsView.defaultViewLabel',
        ),
        columns: [
          'reservationId',
          'spotType',
          'spotName',
          'createdBy',
          'createdAt',
          'deletedBy',
          'deletedAt',
          'paidTotal',
        ],
        orderBy: [],
      } as StatisticDataTableView,
      cards: [
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.card.discountsTotal.label',
          ),
          value: null,
          tooltip: this.$t(
            'statisticsLogCancellationsDiscountsView.card.discountsTotal.tooltip',
          ),
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.card.cancellationsAmountTotal.label',
          ),
          value: null,
          tooltip: this.$t(
            'statisticsLogCancellationsDiscountsView.card.cancellationsAmountTotal.tooltip',
          ),
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.card.usersDiscounts.label',
          ),
          value: null,
          tooltip: this.$t(
            'statisticsLogCancellationsDiscountsView.card.usersDiscounts.tooltip',
          ),
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.card.cancellationsTotal.label',
          ),
          value: null,
          tooltip: this.$t(
            'statisticsLogCancellationsDiscountsView.card.cancellationsTotal.tooltip',
          ),
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.card.discountsAmountTotal.label',
          ),
          value: null,
          tooltip: this.$t(
            'statisticsLogCancellationsDiscountsView.card.discountsAmountTotal.tooltip',
          ),
        },
        {
          label: this.$t(
            'statisticsLogCancellationsDiscountsView.card.usersCancellations.label',
          ),
          value: null,
          tooltip: this.$t(
            'statisticsLogCancellationsDiscountsView.card.usersCancellations.tooltip',
          ),
        },
      ] as Array<StatisticCardI>,
    };
  },
  beforeMount(): void {
    this.$store.commit(
      'statistic/setTitle',
      this.$t(
        'statisticsLogCancellationsDiscountsView.setTitle.logCancellations',
      ),
    );
  },
  methods: {
    onResult(res: ApiStatisticsLogCancellationsResponse): void {
      this.cards[0].value = `${res.result.cards.totalDiscounts.toString()}`;
      this.cards[1].value = `${res.result.cards.totalDiscountsAmount.toString()}€`;
      this.cards[2].value = `${res.result.cards.usersWithDiscounts.toString()}`;
      this.cards[3].value = `${res.result.cards.totalCancellations.toString()}`;
      this.cards[4].value = `${res.result.cards.totalCancellationsAmount.toString()}€`;
      this.cards[5].value = `${res.result.cards.usersWithCancellations.toString()}`;
    },
    openReservation(event: DataTableRowClickEvent) {
      if (
        permissionsUtil.isActionPermissionAllowed(
          FEATURE_PERMISSION_CONFIG.reservations,
          FEATURE_PERMISSION_ACTION_CONFIG.reservations.PAGE_ACCESS,
        )
      ) {
        const routeData = this.$router.resolve({
          name: 'ReservationEdit',
          params: { id: `${event.data.reservationId}` },
        });
        this.$spiagge.utils.global.openLink(routeData.href, true);
      }
    },
    rowClass() {
      return 'row';
    },
  },
});
