
import { defineComponent } from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters } from 'vuex';
import {
  StagingConfigurator,
  ListElementString,
  ListElementNumber,
  DropdownOption,
  PriceRoundingMode,
} from '@/models';
import UploadImg from '@/components/shared/UploadImg.vue';
import InputTooltip from '@/components/shared/InputTooltip.vue';
import {
  License,
  LicenseFreeSpotOpeningMode,
  LicenseMapCabinMode,
  // LicenseSector,
  LicenseSectorDetail,
  LicenseSectorFull,
  LicenseStandardSectorServices,
  LicenseUmbrellaMultipleBookings,
  PayloadBeachSettings,
  TemporaryFutureReservation,
  QrCodeReadMode,
  TemporaryOnCheckOut,
} from '@/models/license';
import licenseService from '@/services/licenseService';
import toastUtil from '@/utils/toastUtil';
import { ToastSeverity } from '@/models/toast';
import {
  BORDER_COLORS,
  ColorName,
  HOTEL_BG_COLORS,
  SPOT_ICON_COLORS,
} from '@/models/color';
import SettingsSpotColorDropdown from '@/components/settings/SettingsSpotColorDropdown.vue';

interface PanelStates {
  [key: string]: boolean;
}

export default defineComponent({
  name: 'BeachMapConfig',
  components: {
    UploadImg,
    InputTooltip,
    SettingsSpotColorDropdown,
  },
  data() {
    return {
      defaultMapCollapsed: true,
      colorMapCollapsed: true,
      bookingSettingsCollapsed: true,
      sectorsCollapsed: true,
      cabinsCollapsed: true,
      numberSector: 0,
      deviceMapOffset: 0,
      selectedSectors: {} as LicenseSectorDetail,
      showSectorDetails: false,
      dropListSettingList: [
        {
          name: this.$t('beachMapConfig.map.dropListSetting.square'),
          code: 'square',
        },
        {
          name: this.$t('beachMapConfig.map.dropListSetting.number'),
          code: 'number',
        },
        {
          name: this.$t('beachMapConfig.map.dropListSetting.endDate'),
          code: 'end-date',
        },
        {
          name: this.$t('beachMapConfig.map.dropListSetting.service'),
          code: 'service',
        },
        {
          name: this.$t('beachMapConfig.map.dropListSetting.planning'),
          code: 'planning',
        },
      ] as Array<ListElementString>,
      formsIcons: [
        {
          name: this.$t(
            'beachMapConfig.map.littleUmbrellaIconFormat.rectangles',
          ),
          code: 0,
        },
        {
          name: this.$t('beachMapConfig.map.littleUmbrellaIconFormat.squares'),
          code: 1,
        },
      ] as Array<ListElementNumber>,
      sunbedsNum: [
        {
          name: this.$t('beachMapConfig.map.umbrellaNumber.staging'),
          code: null,
        },
        { name: '0L', code: 0 },
        { name: '1L', code: 1 },
        { name: '2L', code: 2 },
        { name: '3L', code: 3 },
      ] as Array<ListElementNumber>,
      orders: [
        { name: this.$t('beachMapConfig.map.orders.file'), code: 0 },
        { name: this.$t('beachMapConfig.map.orders.fileRL'), code: 3 },
        { name: this.$t('beachMapConfig.map.orders.columns'), code: 2 },
        { name: this.$t('beachMapConfig.map.orders.name'), code: 1 },
      ] as Array<ListElementNumber>,
      umbrellaColors: SPOT_ICON_COLORS.map(
        (name: ColorName) =>
          ({
            name: this.$t(`color.${name.toLowerCase()}`),
            code: name,
          } as ListElementString),
      ),
      borderColors: BORDER_COLORS.map(
        (name: ColorName) =>
          ({
            name: this.$t(`color.${name.toLowerCase()}`),
            code: name,
          } as ListElementString),
      ),
      backgroundColors: HOTEL_BG_COLORS.map((name: ColorName | null) => {
        if (!name) {
          return {
            name: this.$t('color.standard'),
            code: 'default',
          } as ListElementString;
        }
        return {
          name: this.$t(`color.${name.toLowerCase()}`),
          code: name,
        } as ListElementString;
      }),
      umbrellaMultipleBookingsOptions: [
        {
          label: this.$t(
            'beachMapConfig.reservations.overlaidReservation.notify',
          ),
          value: LicenseUmbrellaMultipleBookings.NOTICE,
        },
        {
          label: this.$t(
            'beachMapConfig.reservations.overlaidReservation.blocked',
          ),
          value: LicenseUmbrellaMultipleBookings.BLOCKED,
        },
      ] as Array<DropdownOption>,
      graphics: [
        {
          name: this.$t('beachMapConfig.reservations.reservationsGraphic.new'),
          code: 1,
        },
      ] as Array<ListElementNumber>,
      abititations: [
        { name: this.$t('common.enabled'), code: '0' },
        { name: this.$t('common.disabled'), code: '1' },
      ] as Array<ListElementString>,
      yesNo: [
        { name: this.$t('common.yes'), code: '0' },
        { name: this.$t('common.no'), code: '1' },
      ] as Array<ListElementString>,
      mapCabinModeOptions: [
        {
          name: this.$t('beachMapConfig.cabins.iconModeCM.openCabin'),
          code: LicenseMapCabinMode.OPEN_CABIN,
        },
        {
          name: this.$t('beachMapConfig.cabins.iconModeCM.openCabinList'),
          code: LicenseMapCabinMode.OPEN_LIST,
        },
      ] as Array<ListElementNumber>,
      freeSpotOpeningModeOptions: [
        {
          label: this.$t('beachMapConfig.freeSpotOpeningMode.modal'),
          value: LicenseFreeSpotOpeningMode.MODAL,
        },
        {
          label: this.$t('beachMapConfig.freeSpotOpeningMode.direct'),
          value: LicenseFreeSpotOpeningMode.DIRECT,
        },
      ] as Array<DropdownOption>,
      temporaryFutureReservationOptions: [
        {
          label: this.$t(
            'beachMapConfig.reservations.temporaryFutureReservationOptions.disabled',
          ),
          value: TemporaryFutureReservation.DISABLED,
        },
        {
          label: this.$t(
            'beachMapConfig.reservations.temporaryFutureReservationOptions.enabledNextDay',
          ),
          value: TemporaryFutureReservation.ENABLED_NEXT_DAY,
        },
      ] as Array<DropdownOption>,
      temporaryOnCheckOutOptions: [
        {
          label: this.$t(
            'beachMapConfig.reservations.temporaryOnCheckOutOptions.disabled',
          ),
          value: TemporaryOnCheckOut.DISABLED,
        },
        {
          label: this.$t(
            'beachMapConfig.reservations.temporaryOnCheckOutOptions.daily',
          ),
          value: TemporaryOnCheckOut.DAILY,
        },
        {
          label: this.$t(
            'beachMapConfig.reservations.temporaryOnCheckOutOptions.dailyNoSeasonal',
          ),
          value: TemporaryOnCheckOut.DAILY_NO_SEASONAL,
        },
      ] as Array<DropdownOption>,
      qrCodeReadModeOptions: [
        {
          label: this.$t(
            'beachMapConfig.reservations.qrCodeReadModeOptions.manual',
          ),
          value: QrCodeReadMode.MANUAL,
        },
        {
          label: this.$t(
            'beachMapConfig.reservations.qrCodeReadModeOptions.autoQrReader',
          ),
          value: QrCodeReadMode.AUTO_QR_READER,
        },
        {
          label: this.$t(
            'beachMapConfig.reservations.qrCodeReadModeOptions.autoReservation',
          ),
          value: QrCodeReadMode.AUTO_RESERVATION,
        },
      ] as Array<DropdownOption>,
      localLicense: {} as License,
      extension: '.svg',
      size: 10000000,
      panelStates: {
        map: true,
        color: true,
        booking: true,
        sectors: true,
        cabins: true,
      } as PanelStates,
      priceRoundingModeOptions: [
        {
          label: this.$t('beachMapConfig.priceRoundingMode.disabled'),
          value: PriceRoundingMode.DISABLED,
        },
        {
          label: this.$t('beachMapConfig.priceRoundingMode.closestInt'),
          value: PriceRoundingMode.CLOSEST_INT,
        },
        {
          label: this.$t('beachMapConfig.priceRoundingMode.closestHalf'),
          value: PriceRoundingMode.CLOSEST_HALF,
        },
      ],
    };
  },
  methods: {
    saveSectorDetails() {
      this.showSectorDetails = false;
      this.localLicense.sectorsDetails[this.numberSector] = cloneDeep(
        this.selectedSectors,
      );
    },
    cancelSectorDetails() {
      this.showSectorDetails = false;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async catchImgSectors(fileRecieve: any) {
      const data = new FormData();
      data.append('sectorImage', fileRecieve);
      if (this.license) {
        try {
          const response = await licenseService.sectorImage(
            this.license?.license,
            data,
          );
          this.selectedSectors.image = response.result.url;
        } catch (error) {
          // console.log(error);
        }
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async catchFile(fileRecieve: any) {
      const data = new FormData();
      data.append('mapBgImage', fileRecieve);
      if (this.license) {
        try {
          await licenseService.mapBackground(this.license?.license, data);
        } catch (error) {
          // TODO
        }
      }
    },
    openSectorDetails(index: number) {
      this.numberSector = Number(index) + 1;
      if (!this.localLicense.sectorsDetails[index + 1]) {
        this.localLicense.sectorsDetails[index + 1] = {
          name: index.toString(),
          description: '',
          image: '',
        } as LicenseSectorDetail;
      }
      this.selectedSectors = cloneDeep(
        this.localLicense.sectorsDetails[index + 1],
      );
      this.showSectorDetails = true;
    },
    catchValueSunBed(response: StagingConfigurator) {
      const index = this.sectors.findIndex(
        (s: LicenseSectorFull) => s.id === response.sector,
      );
      this.sectors[index].staging.beds = {
        default: response.default,
        min: response.min,
        max: response.max,
      };
    },
    catchValueLieDown(response: StagingConfigurator) {
      const index = this.sectors.findIndex(
        (s: LicenseSectorFull) => s.id === response.sector,
      );
      this.sectors[index].staging.deckChairs = {
        default: response.default,
        min: response.min,
        max: response.max,
      };
    },
    catchValueChair(response: StagingConfigurator) {
      const index = this.sectors.findIndex(
        (s: LicenseSectorFull) => s.id === response.sector,
      );
      this.sectors[index].staging.chairs = {
        default: response.default,
        min: response.min,
        max: response.max,
      };
    },
    catchValueBigsunbed(response: StagingConfigurator) {
      const index = this.sectors.findIndex(
        (s: LicenseSectorFull) => s.id === response.sector,
      );
      this.sectors[index].staging.maxiBeds = {
        default: response.default,
        min: response.min,
        max: response.max,
      };
    },
    async submit() {
      try {
        const sectors = cloneDeep(this.sectors);
        this.sectors = sectors;
        const licenseToSend = cloneDeep(this.localLicense);
        const send = {
          beachDefaultView: licenseToSend.beachDefaultView,
          mapOffset: licenseToSend.mapOffset,
          mapOffsetVertical: licenseToSend.mapOffsetVertical,
          mapZoom: licenseToSend.mapZoom,
          mapIconSmallFormat: licenseToSend.mapIconSmallFormat,
          mapVoidUmbrellaBeds: licenseToSend.mapVoidUmbrellaBeds,
          mapQOrder: licenseToSend.mapQOrder,
          mapBgWidth: licenseToSend.mapBgWidth,
          mapBgHeight: licenseToSend.mapBgHeight,
          mapBgPosX: licenseToSend.mapBgPosX,
          mapBgPosY: licenseToSend.mapBgPosY,
          mapColorFree: licenseToSend.mapColorFree,
          mapColorReserved: licenseToSend.mapColorReserved,
          mapColorTemporary: licenseToSend.mapColorTemporary,
          mapColorHalfDayMorning: licenseToSend.mapColorHalfDayMorning,
          mapColorHalfDayAfternoon: licenseToSend.mapColorHalfDayAfternoon,
          mapColorFullDay: licenseToSend.mapColorFullDay,
          mapColorSeasonal: licenseToSend.mapColorSeasonal,
          mapColorHotel: licenseToSend.mapColorHotel,
          mapColorNotPaid: licenseToSend.mapColorNotPaid,
          mapColorReservedFirstDay: licenseToSend.mapColorReservedFirstDay,
          mapColorReservedLastDay: licenseToSend.mapColorReservedLastDay,
          umbrellaMultipleBookings: licenseToSend.umbrellaMultipleBookings,
          registrationView: licenseToSend.registrationView,
          standardSectorServices: licenseToSend.standardSectorServices,
          sectorsDetails: licenseToSend.sectorsDetails,
          mapCabinMode: licenseToSend.mapCabinMode,
          cabinMultipleBookings: licenseToSend.cabinMultipleBookings,
          freeSpotOpeningMode: licenseToSend.freeSpotOpeningMode,
          halfDay: licenseToSend.halfDay,
          temporaryOnCheckOut: licenseToSend.temporaryOnCheckOut,
          temporaryFutureReservation: licenseToSend.temporaryFutureReservation,
          qrCodeReadMode: licenseToSend.qrCodeReadMode,
          reservationMultiContact: licenseToSend.reservationMultiContact,
          reservationCopyServices: licenseToSend.reservationCopyServices,
          priceRoundingMode: licenseToSend.priceRoundingMode,
          checkInOutEnabled: licenseToSend.checkInOutEnabled,
        } as PayloadBeachSettings;
        const response = await licenseService.updateBeachSettings(
          this.license?.license as string,
          send,
        );
        this.$store.commit('session/setLicense', response.result.license);
        // rebuild spot style
        this.$store.dispatch('style/build');
        this.localLicense = cloneDeep(response.result.license);
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
        this.scrollToTop();
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    toggle(name: string) {
      // eslint-disable-next-line no-param-reassign
      this.panelStates[name] = !this.panelStates[name];
    },
  },
  computed: {
    loading(): boolean {
      return this.$store.getters['app/loading'];
    },
    license(): License | null {
      return this.$store.getters['session/license'];
    },
    ...mapGetters('session', ['maxSector', 'standardSectors']),
    sectors: {
      get(): Array<LicenseSectorFull> {
        const sectors = [] as Array<LicenseSectorFull>;
        for (let i = 1; i <= this.maxSector; i += 1) {
          const config = this.standardSectors.find(
            (s: LicenseSectorFull) => s.id === i,
          );
          if (config) {
            sectors.push(cloneDeep(config));
          } else {
            sectors.push({
              id: i,
              name: i.toString(),
              description: '',
              image: '',
              staging: {
                maxiBeds: {
                  default: 0,
                  min: 0,
                  max: 0,
                },
                beds: {
                  default: 0,
                  min: 0,
                  max: 0,
                },
                deckChairs: {
                  default: 0,
                  min: 0,
                  max: 0,
                },
                chairs: {
                  default: 0,
                  min: 0,
                  max: 0,
                },
              },
            } as LicenseSectorFull);
          }
        }
        return sectors;
      },
      set(value: Array<LicenseSectorFull>): void {
        this.localLicense.standardSectorServices =
          {} as LicenseStandardSectorServices;
        // eslint-disable-next-line no-restricted-syntax
        for (const sector of value) {
          this.localLicense.standardSectorServices[sector.id] = {
            b_d: sector.staging.beds ? sector.staging.beds.default : 0,
            b_min: sector.staging.beds ? sector.staging.beds.min : 0,
            b_max: sector.staging.beds ? sector.staging.beds.max : 0,
            c_d: sector.staging.chairs ? sector.staging.chairs.default : 0,
            c_min: sector.staging.chairs ? sector.staging.chairs.min : 0,
            c_max: sector.staging.chairs ? sector.staging.chairs.max : 0,
            m_d: sector.staging.maxiBeds ? sector.staging.maxiBeds.default : 0,
            m_min: sector.staging.maxiBeds ? sector.staging.maxiBeds.min : 0,
            m_max: sector.staging.maxiBeds ? sector.staging.maxiBeds.max : 0,
            d_d: sector.staging.deckChairs
              ? sector.staging.deckChairs.default
              : 0,
            d_min: sector.staging.deckChairs
              ? sector.staging.deckChairs.min
              : 0,
            d_max: sector.staging.deckChairs
              ? sector.staging.deckChairs.max
              : 0,
          };
        }
      },
    },
  },
  async beforeMount() {
    if (this.license) {
      await this.$store.dispatch('session/setLicense', this.license.license);
    }
    this.localLicense = cloneDeep(this.license as License);
    if (!this.localLicense.sectorsDetails) {
      this.localLicense.sectorsDetails = {};
    }
    if (!this.localLicense.registrationView) {
      this.graphics.push({
        name: this.$t('beachMapConfig.reservations.reservationsGraphic.new'),
        code: 1,
      } as ListElementNumber);
    }
  },
});
