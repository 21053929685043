
import { defineComponent } from 'vue';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import {
  ExtraDaysMode,
  EXTRA_DAYS_MODE_OPTIONS,
  PeriodsMode,
  PeriodsShiftMode,
  PERIODS_SHIFT_MODE_OPTIONS,
  PriceList,
  PricePeriod,
} from '@/models/priceList';
import InputTooltip from '../shared/InputTooltip.vue';
import { License } from '@/models/license';

export default defineComponent({
  name: 'PriceListDialog',
  emits: ['save'],
  components: {
    InputTooltip,
  },
  props: {
    mode: {
      type: String,
      default: 'edit',
    },
    priceListRef: {
      type: Object as () => PriceList,
    },
  },
  data() {
    return {
      priceList: this.priceListRef
        ? cloneDeep(this.priceListRef)
        : this.getNewPriceList(),
      extraDaysModeOptions: cloneDeep(EXTRA_DAYS_MODE_OPTIONS),
      periodsShiftModeOptions: cloneDeep(PERIODS_SHIFT_MODE_OPTIONS),
      currentYear: DateTime.now().year,
    };
  },
  computed: {
    isNew(): boolean {
      return !this.priceList.id;
    },
    license(): License {
      return this.$store.getters['session/license'];
    },
  },
  methods: {
    getNewPriceList(): PriceList {
      return {
        listDaysLength: 31,
        extraDaysMode: ExtraDaysMode.STANDARD,
        periodsMode: PeriodsMode.LINEAR,
        periodsShiftMode: PeriodsShiftMode.AVERAGE,
        periods: [] as Array<PricePeriod>,
      } as PriceList;
    },
    async onFormSubmit(): Promise<void> {
      this.$emit('save', this.priceList);
    },
  },
  beforeMount() {
    this.$store.dispatch('session/setLicense', this.license?.license);
  },
});
