
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'Logo',
  props: {
    type: {
      required: false,
      type: String,
      default: 'horizontal',
    },
    homeLink: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState('app', ['breakpoints']),
    src(): string {
      // eslint-disable-next-line import/no-dynamic-require
      return require(`@/assets/images/logos/${
        this.breakpoints.xs ? 'logo-mobile' : 'horizontal-negative'
      }.svg`);
    },
    hasLink(): boolean {
      return this.homeLink && this.$route.path !== '/map';
    },
  },
});
