
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import debounce from 'lodash/debounce';
import { mapState } from 'vuex';
import OverlayPanel from 'primevue/overlaypanel';
import { MapCalendar } from '@/models/map';
import { ApiMapDataPayload } from '@/models/api';
import cookieUtil from '@/utils/cookieUtil';
import { SPIAGGE_COOKIES } from '@/models/cookies';

export default defineComponent({
  name: 'MapCalendar',
  data() {
    return {
      update: debounce(this.updateMap as () => void, 500),
    };
  },
  methods: {
    onDateClick($event: PointerEvent): void {
      if (this.disabled) return;
      (this.$refs.op as OverlayPanel).toggle($event);
    },
    onToday(): void {
      if (this.disabled) return;
      if (!this.today) {
        this.calendar = [
          DateTime.now().startOf('day'),
          DateTime.now().startOf('day'),
        ];
        (this.$refs.op as OverlayPanel).hide();
        this.updateCookies();
      }
    },
    onNext(): void {
      if (this.disabled) return;
      const date = this.calendar[0];
      const newDate = date?.plus({ days: 1 }) ?? null;
      this.calendar = [newDate, newDate];
      this.update();
      this.updateCookies();
    },
    onPrev(): void {
      if (this.disabled) return;
      const date = this.calendar[0];
      const newDate = date?.minus({ days: 1 }) ?? null;
      this.calendar = [newDate, newDate];
      this.update();
      this.updateCookies();
    },
    updateMap(): void {
      const payload = {} as ApiMapDataPayload;
      payload.from = this.calendar[0].toSeconds();
      if (
        this.calendar[1] &&
        this.calendar[1].toMillis() !== this.calendar[0].toMillis()
      ) {
        payload.to = this.calendar[1].toSeconds();
      }
      this.$store.dispatch('map/setData', payload);
    },
    updateCookies(): void {
      /**
       * Update map calendar cookies
       */
      cookieUtil.set(
        SPIAGGE_COOKIES.MAP_DATE_FROM,
        this.calendar[0].toSeconds().toString(),
        1 / 24,
      );
      cookieUtil.set(
        SPIAGGE_COOKIES.MAP_DATE_TO,
        (this.calendar[1] as DateTime).toSeconds().toString(),
        1 / 24,
      );
    },
    checkDates(): void {
      if (this.calendar[1] === null) {
        this.calendar = [this.calendar[0], this.calendar[0]];
        this.updateCookies();
      }
    },
  },
  computed: {
    ...mapState('map', ['mode']),
    calendar: {
      get(): MapCalendar {
        return this.$store.getters['map/calendar'];
      },
      set(calendar: MapCalendar) {
        this.$store.commit('map/setCalendar', calendar);
        if (this.$store.getters['map/calendar'][1]) {
          this.update();
        }
      },
    },
    calendarModel: {
      get(): Array<Date | null> {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (this.calendar as any[]).map(
          (d: DateTime | null) => d?.toJSDate() ?? null,
        );
      },
      set(calendar: Array<Date>) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const datetimes = (calendar as any[]).map((d: Date | null) => {
          if (!d) return null;
          const date = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
          return DateTime.fromFormat(date, 'd-M-yyyy');
        });
        // Hack double click on same day
        if (
          this.calendar[0].toMillis() === datetimes[0]?.toMillis() &&
          !this.calendar[1] &&
          !datetimes[1]
        ) {
          // eslint-disable-next-line prefer-destructuring
          datetimes[1] = datetimes[0];
        }
        this.calendar = datetimes as MapCalendar;
        if (this.calendar[0] && this.calendar[1]) {
          (this.$refs.op as OverlayPanel).hide();
          this.updateCookies();
        }
      },
    },
    showToDate(): boolean {
      if (!this.calendar[1]) return false;
      return !this.equal;
    },
    fromDate(): string {
      return this.calendar[0].toFormat('dd-MM-yyyy');
    },
    toDate(): string {
      return this.calendar[1]?.toFormat('dd-MM-yyyy') ?? '';
    },
    today(): boolean {
      if (!this.toDate) return false;
      return (
        this.fromDate === this.toDate &&
        this.fromDate === DateTime.now().startOf('day').toFormat('dd-MM-yyyy')
      );
    },
    equal(): boolean {
      if (!this.toDate) return false;
      return this.fromDate === this.toDate;
    },
    disabled(): boolean {
      // disable if is shift/multiple selection mode
      return this.mode;
    },
  },
});
