import { Timestamp } from '.';
import { SectorHeader } from './sector';

export enum PosTerminalModel {
  STRIPE = 'stripe',
  RETEX = 'retex',
}

export interface PosTerminalModelData {
  name: string;
  model: PosTerminalModel;
}

export const POS_TERMINAL_MODEL_DATA: Array<PosTerminalModelData> = [
  {
    name: 'Stripe',
    model: PosTerminalModel.STRIPE,
  },
  {
    name: 'Retex',
    model: PosTerminalModel.RETEX,
  },
];

export interface PosTerminal {
  id: number;
  licenseId: number;
  name: string;
  model: PosTerminalModel;
  posIdentifier: string;
  sectors: Array<SectorHeader>;
  disabled: boolean;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}
