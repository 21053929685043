
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { PriceList, PricePeriod } from '@/models/priceList';
import priceListService from '@/services/priceListService';
import PriceListDetails from '@/components/priceList/PriceListDetails.vue';
import PriceListsPriceListCreate from '@/components/price-lists/PriceListsPriceListCreate.vue';
import PriceListsPriceListSwitch from '@/components/price-lists/PriceListsPriceListSwitch.vue';
import PriceListsPriceListClone from '@/components/price-lists/PriceListsPriceListClone.vue';

export default defineComponent({
  name: 'PriceListsPriceListDetailView',
  components: {
    PriceListsPriceListClone,
    PriceListDetails,
    PriceListsPriceListSwitch,
    PriceListsPriceListCreate,
  },
  data() {
    return {
      priceList: undefined as unknown as PriceList,
      mounted: false,
      //
      priceListEdit: null as PriceList | null,
      showEditDialog: false,
      showSwitchDialog: false,
      showCloneDialog: false,
      priceListDialogMode: 'edit',
      showPriceListSwitchDialog: false,
    };
  },
  async beforeMount(): Promise<void> {
    const priceListId = this.$route.params.id
      ? Number(this.$route.params.id)
      : undefined;
    if (!priceListId) {
      this.$spiagge.toast.error(
        this.$t('priceListsPriceListDetailView.toast.error'),
      );
      this.$router.push('/price-lists');
    }
    this.loadPriceList(priceListId as number);
  },
  mounted() {
    this.mounted = true;
  },
  methods: {
    async loadPriceList(priceListId: number): Promise<void> {
      /**
       * Load price list
       */
      try {
        this.priceList = await priceListService.get(priceListId);
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('priceListsPriceListDetailView.toast.retrievalError'),
        );
      }
    },
    onDelete(): void {
      /**
       * Delete price list (confirmation)
       */
      this.$confirm.require({
        message: this.$t('priceListsPriceListDetailView.confirm.message'),
        header: this.$t('priceListsPriceListDetailView.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('common.yes'),
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-danger p-button-outlined',
        accept: () => {
          this.delete();
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          // nothing
        },
      });
    },
    async delete(): Promise<void> {
      /**
       * Delete price list
       */
      try {
        await priceListService.delete(this.priceList.id);
        this.$spiagge.toast.success(
          this.$t('priceListsPriceListDetailView.toast.deleteSuccess'),
        );
        this.$router.push('/price-lists');
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('priceListsPriceListDetailView.toast.deleteError'),
        );
      }
    },
    onUpdatePeriods(periods: Array<PricePeriod>): void {
      this.priceList.periods = periods;
    },
    onSwitch(): void {
      /**
       * Refresh price list
       */
      this.loadPriceList(this.priceList.id);
    },
    onWizard(): void {
      if (this.priceList.draftPriceConfigId) {
        this.$router.push(
          `/price-lists/wizard/${this.priceList.draftPriceConfigId}?forcedStep=price&forcedMode=edit`,
        );
      } else {
        this.$router.push(
          `/price-lists/wizard/${this.priceList.activePriceConfigId}?forcedStep=price&forcedMode=edit`,
        );
      }
    },
    onQualityCheck(): void {
      this.$router.push(`/price-lists/quality-check/${this.priceList.id}`);
    },
  },
  computed: {
    ...mapState('session', ['license']),
    ...mapGetters('priceLists', ['priceLists', 'vouchers', 'wizardEnabled']),
    generatedByWizard(): boolean {
      return this.priceList.activePriceConfigId !== null;
    },
  },
});
