import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "statistics-navigation" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.breakpoints.mobile)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_Button, {
            class: "toggle",
            type: "button",
            label: _ctx.$t('common.menu'),
            icon: "pi pi-bars",
            onClick: _ctx.toggle
          }, null, 8, ["label", "onClick"]),
          _createVNode(_component_Menu, {
            id: "statistics-mobile-navigation",
            ref: "menu",
            model: _ctx.menuItems,
            popup: true
          }, null, 8, ["model"])
        ], 64))
      : (_openBlock(), _createBlock(_component_Menu, {
          key: 1,
          model: _ctx.menuItems
        }, null, 8, ["model"]))
  ]))
}