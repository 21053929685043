import axios, { AxiosResponse } from 'axios';
import {
  ApiRevenueManagementBaseRuleCreatePayload,
  ApiRevenueManagementBaseRuleCreateResponse,
  ApiRevenueManagementBaseRuleListResponse,
  ApiRevenueManagementBaseRuleUpdatePayload,
  ApiRevenueManagementBaseRuleUpdateResponse,
  ApiRevenueManagementHistoricalDetailsResponse,
  ApiRevenueManagementOverrideRuleCreatePayload,
  ApiRevenueManagementOverrideRuleCreateResponse,
  ApiRevenueManagementOverrideRuleListResponse,
  ApiRevenueManagementOverrideRuleUpdatePayload,
  ApiRevenueManagementOverrideRuleUpdateResponse,
  ApiRevenueManagementPeriodsResponse,
  ApiRevenueManagementPriceAlterationCreatePayload,
  ApiRevenueManagementPriceAlterationCreateResponse,
  ApiRevenueManagementPriceAlterationDeletePayload,
  ApiRevenueManagementPriceAlterationDeleteResponse,
  ApiRevenueManagementPriceAlterationListPayload,
  ApiRevenueManagementPriceAlterationListResponse,
  ApiRevenueManagementPriceHintHandlePayload,
  ApiRevenueManagementPriceHintHandleResponse,
  ApiRevenueManagementPriceHintListPayload,
  ApiRevenueManagementPriceHintListResponse,
  ApiRevenueManagementPricesPayload,
  ApiRevenueManagementPricesResponse,
  ApiRevenueManagementRuleDetailsResponse,
  ApiRevenueManagementSlotDetailPayload,
  ApiRevenueManagementSlotDetailResponse,
} from '@/models/api';
import {
  RevenueManagementBaseRule,
  RevenueManagementHistoricalDetail,
  RevenueManagementOverrideRule,
  RevenueManagementPeriod,
  RevenueManagementPriceAlteration,
  RevenueManagementPriceHint,
  RevenueManagementPrices,
  RevenueManagementRuleDetail,
  RevenueManagementSlotDetail,
} from '@/models/revenueManagement';
import apiService from './apiService';

export default {
  async prices(
    payload: ApiRevenueManagementPricesPayload,
  ): Promise<Array<RevenueManagementPrices>> {
    const res: AxiosResponse<ApiRevenueManagementPricesResponse> =
      await axios.post(apiService.revenueManagement.prices(), payload);
    return res.data.result.revenueManagerPrices;
  },
  async slotDetail(
    payload: ApiRevenueManagementSlotDetailPayload,
  ): Promise<RevenueManagementSlotDetail> {
    const res: AxiosResponse<ApiRevenueManagementSlotDetailResponse> =
      await axios.post(apiService.revenueManagement.slotDetail(), payload);
    return res.data.result.slotDetail;
  },
  async periods(): Promise<Array<RevenueManagementPeriod>> {
    const res: AxiosResponse<ApiRevenueManagementPeriodsResponse> =
      await axios.get(apiService.revenueManagement.periods());
    return res.data.result.periods;
  },
  async ruleDetails(
    from: number,
    to: number,
  ): Promise<Array<RevenueManagementRuleDetail>> {
    const res: AxiosResponse<ApiRevenueManagementRuleDetailsResponse> =
      await axios.get(apiService.revenueManagement.ruleDetails(from, to));
    return res.data.result.ruleDetails;
  },
  async historicalDetails(): Promise<Array<RevenueManagementHistoricalDetail>> {
    const res: AxiosResponse<ApiRevenueManagementHistoricalDetailsResponse> =
      await axios.get(apiService.revenueManagement.historicalDetails());
    return res.data.result.historicalDetails;
  },
  async priceHints(
    payload: ApiRevenueManagementPriceHintListPayload,
  ): Promise<Array<RevenueManagementPriceHint>> {
    const res: AxiosResponse<ApiRevenueManagementPriceHintListResponse> =
      await axios.post(apiService.revenueManagement.priceHints(), payload);
    return res.data.result.priceHints;
  },
  async handleHint(
    payload: ApiRevenueManagementPriceHintHandlePayload,
  ): Promise<ApiRevenueManagementPriceHintHandleResponse> {
    return axios.post(apiService.revenueManagement.handleHint(), payload);
  },
  baseRule: {
    async list(): Promise<Array<RevenueManagementBaseRule>> {
      const res: AxiosResponse<ApiRevenueManagementBaseRuleListResponse> =
        await axios.get(apiService.revenueManagement.baseRule.list());
      return res.data.result.baseRules;
    },
    async create(
      payload: ApiRevenueManagementBaseRuleCreatePayload,
    ): Promise<RevenueManagementBaseRule> {
      const res: AxiosResponse<ApiRevenueManagementBaseRuleCreateResponse> =
        await axios.post(
          apiService.revenueManagement.baseRule.create(),
          payload,
        );
      return res.data.result.baseRule;
    },
    async update(
      id: number,
      payload: ApiRevenueManagementBaseRuleUpdatePayload,
    ): Promise<RevenueManagementBaseRule> {
      const res: AxiosResponse<ApiRevenueManagementBaseRuleUpdateResponse> =
        await axios.patch(
          apiService.revenueManagement.baseRule.update(id),
          payload,
        );
      return res.data.result.baseRule;
    },
    async delete(id: number): Promise<void> {
      return axios.delete(apiService.revenueManagement.baseRule.delete(id));
    },
  },
  overrideRule: {
    async list(): Promise<Array<RevenueManagementOverrideRule>> {
      const res: AxiosResponse<ApiRevenueManagementOverrideRuleListResponse> =
        await axios.get(apiService.revenueManagement.overrideRule.list());
      return res.data.result.overrideRules;
    },
    async create(
      payload: ApiRevenueManagementOverrideRuleCreatePayload,
    ): Promise<RevenueManagementOverrideRule> {
      const res: AxiosResponse<ApiRevenueManagementOverrideRuleCreateResponse> =
        await axios.post(
          apiService.revenueManagement.overrideRule.create(),
          payload,
        );
      return res.data.result.overrideRule;
    },
    async update(
      id: number,
      payload: ApiRevenueManagementOverrideRuleUpdatePayload,
    ): Promise<RevenueManagementOverrideRule> {
      const res: AxiosResponse<ApiRevenueManagementOverrideRuleUpdateResponse> =
        await axios.patch(
          apiService.revenueManagement.overrideRule.update(id),
          payload,
        );
      return res.data.result.overrideRule;
    },
    async delete(id: number): Promise<void> {
      return axios.delete(apiService.revenueManagement.overrideRule.delete(id));
    },
  },
  priceAlteration: {
    async list(
      payload: ApiRevenueManagementPriceAlterationListPayload,
    ): Promise<Array<RevenueManagementPriceAlteration>> {
      const res: AxiosResponse<ApiRevenueManagementPriceAlterationListResponse> =
        await axios.get(apiService.revenueManagement.priceAlteration.list(), {
          params: payload,
        });
      return res.data.result.priceAlterations;
    },
    async create(
      payload: ApiRevenueManagementPriceAlterationCreatePayload,
    ): Promise<ApiRevenueManagementPriceAlterationCreateResponse> {
      const res: AxiosResponse<ApiRevenueManagementPriceAlterationCreateResponse> =
        await axios.post(
          apiService.revenueManagement.priceAlteration.create(),
          payload,
        );
      return res.data;
    },
    async delete(
      payload: ApiRevenueManagementPriceAlterationDeletePayload,
    ): Promise<ApiRevenueManagementPriceAlterationDeleteResponse> {
      const res: AxiosResponse<ApiRevenueManagementPriceAlterationDeleteResponse> =
        await axios.post(
          apiService.revenueManagement.priceAlteration.delete(),
          payload,
        );
      return res.data;
    },
  },
};
