
import { defineComponent } from 'vue';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { PricePeriod } from '@/models/priceList';
import CalendarInput from '../shared/CalendarInput.vue';
import { ToastSeverity } from '@/models/toast';
import toastUtil from '@/utils/toastUtil';

interface PeriodInterval {
  startDate: string;
  endDate: string;
}

export default defineComponent({
  name: 'PricePeriodDialog',
  emits: ['save', 'delete'],
  components: {
    CalendarInput,
  },
  props: {
    pricePeriodRef: {
      type: Object as () => PricePeriod,
      required: true,
    },
    takenIntervals: {
      type: Array as () => Array<PeriodInterval>,
      required: true,
    },
  },
  data() {
    return {
      pricePeriod: cloneDeep(this.pricePeriodRef),
      extraIntervals: [] as Array<PeriodInterval>,
      intervalDisabledDates: [] as Array<DateTime>,
    };
  },
  computed: {
    isNew(): boolean {
      return !this.pricePeriod.id;
    },
    // Year represented in the calendar - in september the next year is unlocked
    year(): number {
      return DateTime.utc().month < 9
        ? DateTime.utc().year
        : DateTime.utc().year + 1;
    },
    minDate(): DateTime {
      return (
        DateTime.fromFormat(
          `${this.pricePeriod.startDate}-${this.year}`,
          'dd-MM-yyyy',
        )
          .startOf('year')
          // Just a quick patch to avoid having the 1st of january enabled
          .set({ second: 0, minute: 0, hour: 12 })
      );
    },
    maxDate(): DateTime {
      return DateTime.fromFormat(
        `${this.pricePeriod.endDate}-${this.year}`,
        'dd-MM-yyyy',
      )
        .endOf('year')
        .set({ second: 0, minute: 0, hour: 12 });
    },
    // Dates disabled for all calendar inputs
    disabledDates(): Array<DateTime> {
      const disabledDates = [] as Array<DateTime>;
      // eslint-disable-next-line no-restricted-syntax
      for (const interval of this.takenIntervals) {
        disabledDates.push(
          ...this.getDates(interval.startDate, interval.endDate),
        );
      }
      return disabledDates;
    },
    nextSlot(): PeriodInterval | null {
      if (!this.isNew) return null;
      let startDate = DateTime.fromFormat(`01-01-${this.year}`, 'dd-MM-yyyy');
      let endDate = DateTime.fromFormat(`31-12-${this.year}`, 'dd-MM-yyyy');
      const toCheck = this.takenIntervals
        .concat({
          startDate: this.pricePeriod.startDate,
          endDate: this.pricePeriod.endDate,
        })
        .concat(this.extraIntervals)
        .sort(
          (a, b) =>
            DateTime.fromFormat(a.startDate, 'dd-MM').toSeconds() -
            DateTime.fromFormat(b.startDate, 'dd-MM').toSeconds(),
        );
      // eslint-disable-next-line no-restricted-syntax
      for (let i = 0; i < toCheck.length; i += 1) {
        const period = toCheck[i];
        const pStartDate = DateTime.fromFormat(
          `${period.startDate}-${this.year}`,
          'dd-MM-yyyy',
        );
        const pEndDate = DateTime.fromFormat(
          `${period.endDate}-${this.year}`,
          'dd-MM-yyyy',
        );
        if (startDate >= pStartDate) {
          startDate = pEndDate.plus({ days: 1 });
        }
        if (startDate >= endDate) {
          endDate = DateTime.fromFormat(`31-12-${this.year}`, 'dd-MM-yyyy');
        }
        if (toCheck[i + 1]) {
          endDate = DateTime.fromFormat(
            `${toCheck[i + 1].startDate}-${this.year}`,
            'dd-MM-yyyy',
          ).minus({ days: 1 });
        }
      }
      // No space available
      if (startDate > endDate) return null;
      return {
        startDate: startDate.toFormat('dd-MM'),
        endDate: endDate.toFormat('dd-MM'),
      };
    },
  },
  methods: {
    async onFormSubmit(): Promise<void> {
      let error = false;
      if (
        DateTime.fromFormat(this.pricePeriod.startDate, 'dd-MM') >
        DateTime.fromFormat(this.pricePeriod.endDate, 'dd-MM')
      ) {
        error = true;
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const i of this.extraIntervals) {
        if (
          DateTime.fromFormat(i.startDate, 'dd-MM') >
          DateTime.fromFormat(i.endDate, 'dd-MM')
        ) {
          error = true;
        }
      }
      if (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('pricePeriodDialog.toast.error'),
          ),
        );
        return;
      }
      this.$emit('save', {
        period: this.pricePeriod,
        extras: this.extraIntervals,
      });
    },
    async onPeriodDelete(): Promise<void> {
      this.$emit('delete');
    },
    onAddButtonClick(): void {
      if (!this.nextSlot) return;
      this.extraIntervals.push({
        startDate: this.nextSlot.startDate,
        endDate: this.nextSlot.endDate,
      });
    },
    onDeleteExtraClick(index: number) {
      this.extraIntervals.splice(index, 1);
    },
    onShow(index: number) {
      this.setIntervalDisabledDates(index);
    },
    getDates(startDate: string, endDate: string): Array<DateTime> {
      const dates = [] as Array<DateTime>;
      const startDateTime = DateTime.fromFormat(
        `${startDate}-${this.year}`,
        'dd-MM-yyyy',
      );
      const endDateTime = DateTime.fromFormat(
        `${endDate}-${this.year}`,
        'dd-MM-yyyy',
      );
      let cursor = startDateTime;
      while (cursor <= endDateTime) {
        dates.push(cursor);
        cursor = cursor.plus({ days: 1 });
      }
      return dates;
    },
    setIntervalDisabledDates(index: number) {
      const disabledDates = [] as Array<DateTime>;
      if (this.isNew) {
        // Remove dates from other local intervals
        if (index !== -1) {
          disabledDates.push(
            ...this.getDates(
              this.pricePeriod.startDate,
              this.pricePeriod.endDate,
            ),
          );
        }
        // eslint-disable-next-line no-restricted-syntax
        for (let i = 0; i < this.extraIntervals.length; i += 1) {
          const interval = this.extraIntervals[i];
          if (i !== index) {
            disabledDates.push(
              ...this.getDates(interval.startDate, interval.endDate),
            );
          }
        }
      }
      this.intervalDisabledDates = disabledDates;
    },
  },
});
