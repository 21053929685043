
import { defineComponent, PropType } from 'vue';
import { clone, cloneDeep, has } from 'lodash';
import { mapState } from 'vuex';
import FieldPriceConfigWizardExpandableRow from './FieldPriceConfigWizardExpandableRow.vue';
import FieldPriceConfigWizardRow from './FieldPriceConfigWizardRow.vue';
import {
  PeriodConfiguration,
  PRICE_CONFIG_WIZARD_SCHEMA_DICTIONARY,
  PriceConfigCombinations,
  PriceConfigErrors,
  PriceConfigExtraDaysMode,
  PriceConfigModel,
  PriceConfigPeriodData,
  PriceConfigPriceMode,
  PriceConfigThresholdCalculationMode as ThresholdCalcMode,
  PriceConfigWizardSchema,
  PriceConfigWizardSchemaActionData,
  PriceConfigWizardSchemaCombinations,
  PriceConfigWizardSchemaCombinationsRow as SchemaComboRow,
  PriceConfigWizardSchemaCombinationsRows as SchemaComboRows,
  PriceConfigWizardSchemaDictionaryItem as DictionaryItem,
  PriceConfigWizardSchemaField as SchemaField,
  PriceConfigWizardSchemaFields as SchemaFields,
  PriceConfigWizardSchemaInputData,
  PriceConfigWizardSchemaPeriod,
  PriceConfigWizardSchemaRow as SchemaRow,
  PriceConfigWizardSchemaRowAction as SchemaRowAction,
  PriceConfigWizardSchemaRowActionValue as SchemaRowActionValue,
  PriceConfigWizardSchemaRows as SchemaRows,
  PriceConfigWizardSchemaRowStatus as SchemaRowStatus,
  PriceConfigWizardSchemaRowType as SchemaRowType,
  PriceConfigWizardSchemaSector as SchemaSector,
  PriceConfigWizardSchemaSectorRows as SchemaSectorRows,
  PriceConfigWizardSchemaSwitchData,
  PriceConfigWizardSchemaToken as SchemaToken,
  SectorCombinationPieces,
} from '@/models/priceConfig';
import { Sector as SectorModel } from '@/models/sector';
import Combination from '@/components/dynamic-form/fields/Combinations';

const combinationTypes: Array<SchemaRowType> = [
  SchemaRowType.COMBINATION,
  SchemaRowType.CABIN_COMBINATION,
];

export interface Model {
  [key: string]: number | null;
}

interface Period {
  id: number;
  label: string;
  token: string;
}

interface Sector {
  id: number;
  label: string;
  token: string;
}

interface PeriodTab {
  label: string;
  icon: string;
}

interface CopySectorOption {
  label: string;
  sector: Sector;
  value: string;
}

interface CopyPeriodOption {
  label: string;
  period: Period;
  items: Array<CopySectorOption>;
}

enum CopyMode {
  SPOT,
  STAGING,
  UMBRELLA_COMBINATION,
  CABIN_COMBINATION,
}

interface CopyRowOption {
  label: string;
  disabled: boolean;
  value: string;
}

export default defineComponent({
  name: 'FieldPriceListCombinations',
  components: {
    FieldPriceConfigWizardRow,
    FieldPriceConfigWizardExpandableRow,
  },
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: [Object, null] as PropType<PriceConfigCombinations>,
      required: true,
    },
    data: {
      type: Object as PropType<PriceConfigModel>,
      required: false,
      default: () => ({}),
    },
    errors: {
      type: Object as PropType<PriceConfigErrors>,
      required: false,
      default: () => ({}),
    },
    optionNames: {
      type: Array as PropType<string[]>,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      extraTokens: [
        SchemaToken.EXTRA,
      ],
      spotsTokens: [
        SchemaToken.UMBRELLA,
        SchemaToken.CABIN,
      ],
      stagingTokens: [
        SchemaToken.BED,
        SchemaToken.MAXIBED,
        SchemaToken.DECKCHAIR,
        SchemaToken.CHAIR,
      ],
      combinationCopyModes: [
        CopyMode.UMBRELLA_COMBINATION,
        CopyMode.CABIN_COMBINATION,
      ],
      cabinCombinations: this.data.useCabinCombination,
      model: {} as Model,
      dictionary: cloneDeep(PRICE_CONFIG_WIZARD_SCHEMA_DICTIONARY),
      dictionaryToken: cloneDeep(SchemaToken),
      wizardSchema: {} as PriceConfigWizardSchema,
      rowType: clone(SchemaRowType),
      ready: false,
      selectedPeriodTab: 0,
      selectedPeriodToken: '',
      isBuilding: false,
      // copy
      copyDialog: false,
      copyRowsOptions: [] as Array<CopyRowOption>,
      copyRows: [] as Array<string>,
      copyMode: CopyMode.SPOT,
      copySectors: [] as Array<string>,
      copyPeriodsOptions: [] as Array<CopyPeriodOption>,
      copySectorId: 0,
      copyPeriodId: 0,
      copyModes: clone(CopyMode),
      comboGenerator: {} as Combination,
      cachedCombos: new Map<number, Array<string>>(),
    };
  },
  beforeMount() {
    // build wizard model
    this.model = Object.assign(this.defaultModel, this.modelValue ?? {});
    // initial selected period token
    this.selectedPeriodToken = this.periods[0].token;
    // Initialize combinations
    this.comboGenerator = new Combination(this.stagingTokens);
  },
  mounted(): void {
    // build wizard schema
    this.buildWizardSchema();
    // update model to dynamic form
    this.$emit('update:modelValue', this.model);
    setTimeout(() => {
      this.ready = true;
    }, 1000);
  },
  methods: {
    buildWizardSchema(_period?: Period): void {
      // period parameter optional
      const period = _period ?? this.periods[this.selectedPeriodTab];

      // wizard schema already have the period (already built)
      if (has(this.wizardSchema, period.token)) return;

      // building start
      this.$nextTick(() => {
        this.isBuilding = true;
        this.$nextTick(() => {
          setTimeout(() => {
            // Fetch for period related fields
            const periodFields: Array<string> = this.optionNames.filter(
              (fieldName: string) => fieldName.startsWith(`${period.token}_`),
            );
            // Period schema
            this.wizardSchema[period.token] = {
              id: period.id,
              label: period.label,
              token: period.token,
              sectors: {},
              error: false,
            } as PriceConfigWizardSchemaPeriod;
            // Map all the sectors to avoid duplicates
            // Parse each field and explode the name to find out the combinations
            let isFirstSector = false;
            periodFields.forEach((fieldName: string) => {
              const fieldSlices: Array<string> = fieldName.split('_');
              const fieldSlicesLength = fieldSlices.length;
              // Check type first so we can skip immediatly if it's an invalid name
              const tokenType = this.getTokenTypeFromField(fieldSlices);
              if (tokenType === null) {
                return;
              }
              // First two items are always period + sector Pn_Sn
              const periodSectorPrefix: string = fieldSlices[0].concat(`_${fieldSlices[1]}`);
              // Remove the "P" gives the sector id
              const sectorId = Number(fieldSlices[1].substring(1));
              const sector: Sector = this.sectors.get(sectorId) as Sector;
              // Check if the sector was already created
              let sectorSchema: SchemaSector | undefined =
                this.wizardSchema[period.token]?.sectors[sector.token];
              // Sector not yet configured
              if (!sectorSchema) {
                // Create schema if not found
                sectorSchema = {
                  id: sector.id,
                  label: sector.label,
                  token: fieldSlices[1],
                  fullToken: periodSectorPrefix,
                  collapsed: isFirstSector,
                  rows: {} as
                    | SchemaRows
                    | SchemaComboRows,
                  error: false,
                } as SchemaSector;
                this.wizardSchema[period.token].sectors[sector.token] = sectorSchema;
                // First sector got opened as soon as the first one is created
                // These are ordered by the back-end
                isFirstSector = true;
              }
              // Probably a bug somewhere the condition is true
              // Period + Sector + Piece/Combo + Column is the minimum
              if (fieldSlicesLength < 4) return;
              // Wildcard + umbrella + cabin
              const isCabinCombo =
                tokenType === SchemaRowType.CABIN_COMBINATION;
              // Wildcard + umbrella
              const isSimpleCombo =
                tokenType === SchemaRowType.COMBINATION;
              // Combinations
              if (isCabinCombo || isSimpleCombo) {
                const pieces = fieldSlices.find(
                  (slice: string) => slice.includes(SchemaToken.PIECE),
                )?.substring(1);
                if (!pieces) return;
                // Build rows if pieces are found
                const baseToken = this.getBaseTokenForCombo(Number(pieces), isCabinCombo);
                let row = this.wizardSchema[period.token]
                  .sectors[sector.token]
                  .rows[baseToken] as SchemaComboRow | undefined;
                // Check if row already exists
                if (!row) {
                  // Create new row if not exists
                  row = this.buildCombinationsRow(
                    periodSectorPrefix,
                    Number(pieces),
                    this.stagingTokens,
                    isCabinCombo,
                  ) as SchemaComboRow;
                  // Push the row
                  Object.assign(
                    this.wizardSchema[period.token].sectors[sector.token].rows,
                    { [baseToken]: row },
                  );
                }
                // Create field (updates through reference)
                this.buildFieldForComboRow(
                  row as SchemaComboRow,
                  fieldSlices[fieldSlicesLength - 1],
                );
                this.setRowStatus(row);
              } else {
                // 1 Piece no combination
                const itemToken = String(fieldSlices[2].substring(0, 1));
                const baseToken = this.getBaseToken(itemToken);
                // Check if field exists already
                let row = this.wizardSchema[period.token]
                  .sectors[sector.token]
                  .rows[baseToken] as SchemaRow | undefined;
                // Field doesn't exixts
                if (!row) {
                  row = this.buildIndexedRow(
                    periodSectorPrefix,
                    itemToken,
                    tokenType,
                  ) as SchemaRow;
                  // Add row
                  Object.assign(
                    this.wizardSchema[period.token].sectors[sector.token].rows,
                    { [baseToken]: row },
                  );
                }
                // Create field (updates through reference)
                this.buildField(row, fieldSlices[fieldSlicesLength - 1]);
                this.setRowStatus(row);
              }
              // building end
              this.$nextTick(() => {
                this.isBuilding = false;
              });
            }, 200);
          });
        });
      });
    },
    getDictionaryItem(token: string): DictionaryItem {
      return this.dictionary.find(
        (dictionaryItem: DictionaryItem) =>
          dictionaryItem.token === token,
      ) as DictionaryItem;
    },
    getTokenTypeFromField(fieldSlices: Array<string>): SchemaRowType | null {
      const fieldLength = fieldSlices.length;
      // P + S + Item# + Column
      if (fieldLength === 4) {
        const mainToken = String(fieldSlices[2].substring(0, 1)) as SchemaToken;
        if (this.extraTokens.includes(mainToken)) {
          return SchemaRowType.EXTRA;
        }
        if (this.spotsTokens.includes(mainToken)) {
          return SchemaRowType.SPOT;
        }
        // Both previous checks failed so it's a staging item
        return SchemaRowType.STAGING;
      }
      // P + S + U + Item (#) + Column
      if (fieldLength === 5) {
        const firstItem = String(fieldSlices[2].substring(0, 1));
        const secondItem = String(fieldSlices[3].substring(0, 1));
        const items = [firstItem, secondItem] as Array<SchemaToken>;
        const hasUmbrella = items.includes(SchemaToken.UMBRELLA);
        const hasWildcard = items.includes(SchemaToken.PIECE);
        if (hasUmbrella && hasWildcard) {
          return SchemaRowType.COMBINATION;
        }
      }
      // P + S + U + W (cabin) + Item (#) + Column
      if (fieldLength === 6) {
        const firstItem = String(fieldSlices[2].substring(0, 1));
        const secondItem = String(fieldSlices[3].substring(0, 1));
        const thirdItem = String(fieldSlices[4].substring(0, 1));
        const items = [firstItem, secondItem, thirdItem] as Array<SchemaToken>;
        const hasUmbrella = items.includes(SchemaToken.UMBRELLA);
        const hasWildcard = items.includes(SchemaToken.PIECE);
        const hasCabin = items.includes(SchemaToken.CABIN);
        if (hasUmbrella && hasWildcard && hasCabin) {
          return SchemaRowType.CABIN_COMBINATION;
        }
      }
      return null;
    },
    getBaseToken(
      token: string,
    ): string {
      const dictItem = this.getDictionaryItem(token);
      return `${dictItem.token}1`;
    },
    getBaseTokenForCombo(
      pieces: number,
      withCabin: boolean,
    ): string {
      return `${
          SchemaToken.UMBRELLA
        }1${
          withCabin ? '_'.concat(SchemaToken.CABIN, '1') : ''
        }_${
          SchemaToken.PIECE.concat(String(pieces))
        }`;
    },
    buildIndexedRow(
      prefix: string,
      token: string,
      rowType: SchemaRowType,
    ): SchemaRow {
      const rows = {} as SchemaRow;
      const dictItem = this.getDictionaryItem(token);
      const baseToken = `${dictItem.token}1`;
      const prefixdBaseToken = `${prefix}_${dictItem.token}1`;
      const row = {
        label: dictItem.label,
        token: baseToken,
        fullToken: prefixdBaseToken,
        fields: {} as SchemaFields,
        status: SchemaRowStatus.EMPTY,
        type: rowType,
        actions: [] as Array<SchemaRowAction>,
      } as SchemaRow;
      // Copy action on umbrella row only
      if (token === SchemaToken.UMBRELLA) {
        row.actions.push({
          label: this.$t('fieldPriceListCombinations.copyInCombinations'),
          icon: 'spi-file-export',
          value: SchemaRowActionValue.UMBRELLA_TO_COMBINATIONS,
        });
      } else if (token === SchemaToken.CABIN) {
        row.actions.push({
          label: this.$t('fieldPriceListCombinations.copyInCombinations'),
          icon: 'spi-file-export',
          value: SchemaRowActionValue.CABIN_TO_COMBINATIONS,
        });
      }
      return row;
    },
    // BUILD COMBINATIONS ROWS
    buildCombinationsRow(
      prefix: string,
      pieces: number,
      stagingsTokens: Array<string>,
      withCabin = false,
    ): SchemaComboRow {
      // Combination
      const combinationToken = this.getBaseTokenForCombo(pieces, withCabin);
      const combinationFullToken = `${prefix}_${combinationToken}`;

      const comboRow = {
        expanded: false,
        rows: {} as SchemaRows,
        pieces,
        withCabin,
        //
        label: `${this.$tc(
          'fieldPriceListCombinations.combinationSchema',
            pieces,
        )}${
          withCabin ? this.$t('fieldPriceListCombinations.plusCabin') : ''
        }`,
        token: combinationToken,
        fullToken: combinationFullToken,
        fields: {},
        status: SchemaRowStatus.EMPTY,
        type: withCabin
          ? SchemaRowType.CABIN_COMBINATION
          : SchemaRowType.COMBINATION,
      } as SchemaComboRow;
      // Take from already generated ones
      if (!this.cachedCombos.has(pieces)) {
        this.cachedCombos.set(
          pieces,
          this.comboGenerator.getCombinations(pieces),
        );
      }
      const explodedCombos: Array<string> = this.cachedCombos.get(pieces) ?? [];
      explodedCombos.forEach((combination: string) => {
        const specificCombinationFullToken = `${prefix}_U1${
          withCabin ? '_W1' : ''
        }_${combination}`;
        const specificCombinationToken = `U1${
          withCabin ? '_W1' : ''
        }_${combination}`;
        // Exploded combination row
        const specificCombinationSchema = {
          label: this.getCombinationRowLabel(combination, withCabin),
          token: specificCombinationToken,
          fullToken: specificCombinationFullToken,
          fields: {},
          status: SchemaRowStatus.EMPTY,
          type: withCabin
            ? SchemaRowType.CABIN_COMBINATION
            : SchemaRowType.COMBINATION,
          withCabin,
          pieces,
        } as SchemaComboRow;
        // Add exploded row to the mainRow
        comboRow.rows[specificCombinationToken] = specificCombinationSchema;
      });
      return comboRow;
    },
    buildField(
      row: SchemaRow,
      fieldToken: string,
      placeholdersFields?: SchemaFields,
    ): void {
      // Prefix (includes pieces as well) + column key
      const fullToken = `${row.fullToken}_${fieldToken}`;
      const value = this.model[fullToken] ?? null;
      // e.g WE10 => WE, 10
      // Key
      const tokenWithoutNumber = fieldToken.replace(/[0-9]/g, '');
      // Number associated to key
      const tokenNumber = Number(fieldToken.replace(tokenWithoutNumber, ''));
      const dictItem = this.getDictionaryItem(tokenWithoutNumber);
      // Format label
      let label = dictItem.token as string;
      if (tokenNumber > 0) {
        label += `${tokenNumber}`;
      }
      // Resulting field
      const field = {
        label,
        token: fieldToken,
        fullToken,
        value,
        placeholder: placeholdersFields
          ? placeholdersFields[fieldToken].value
          : null,
        error: false,
      } as SchemaField;
      // Add field
      Object.assign(
        row.fields,
        { [fieldToken as string]: field } as SchemaFields,
      );
    },
    buildFieldForComboRow(
      comboRow: SchemaComboRow,
      fieldToken: string,
    ): void {
      // Build fields -> then update the explosions
      this.buildField(comboRow, fieldToken);
      const comboFields = comboRow.fields;
      // Build columns for explosions
      Object.keys(comboRow.rows).forEach((key: string) => {
        const currentRow = comboRow.rows[key];
        this.buildField(
          currentRow,
          fieldToken,
          comboFields,
        );
        this.setRowStatus(currentRow, comboRow);
      });
    },
    getCombinationRowLabel(combination: string, withCabin = false): string {
      let combinationLabel = this.$t('fieldPriceListCombinations.oneUmbrella');
      const combinationsPieces = combination.split('_');
      combinationsPieces.forEach((combinationPiece: string) => {
        const qty = Number(combinationPiece.charAt(1));
        const stagingToken = combinationPiece.charAt(0);
        const dictionaryItem: DictionaryItem =
          this.dictionary.find(
            (dictionaryItem: DictionaryItem) =>
              dictionaryItem.token === stagingToken,
          ) as DictionaryItem;
        combinationLabel += ` + ${qty} ${(qty > 1
          ? dictionaryItem.pluralLabel
          : dictionaryItem.label
        ).toLowerCase()}`;
      });
      if (withCabin) {
        combinationLabel += this.$t('fieldPriceListCombinations.plusCabin');
      }
      return combinationLabel;
    },
    //
    setRowStatus(
      row: SchemaRow,
      mainRow?: SchemaComboRow,
    ): void {
      const splitToken = row.fullToken.split('_');
      // if combination (specific) check for staging row and main row
      if (
        combinationTypes.includes(row.type)
        && !has(row, 'rows')
      ) {
        // es ['B', 'M']
        const stagingRowsDisabledTokens: Array<string> = Object.values(
          this.wizardSchema[splitToken[0]].sectors[splitToken[1]].rows,
        )
          .filter(
            (row: SchemaRow) =>
              row.type === SchemaRowType.STAGING
              && row.status === SchemaRowStatus.DISABLED,
          )
          .map((stagingRowDisabled: SchemaRow) =>
            stagingRowDisabled.token.charAt(0),
          );

        const mainCombinationRow: SchemaComboRow =
          mainRow ||
          (this.wizardSchema[splitToken[0]].sectors[splitToken[1]].rows[
            `U1${
              (row as SchemaComboRow).withCabin
                ? '_W1'
                : ''
            }_#${(row as SchemaComboRow).pieces}`
          ] as SchemaComboRow);

        const mainCombinationRowIsDisabled = Object.values(
          mainCombinationRow.fields,
        ).some((field: SchemaField) => field.value === -1);

        const stagingRowIsDisabled = stagingRowsDisabledTokens.some(
          (token: string) => row.token.includes(token),
        );

        if (mainCombinationRowIsDisabled || stagingRowIsDisabled) {
          // eslint-disable-next-line no-param-reassign
          row.status = SchemaRowStatus.HIDDEN;
          return;
        }
      }

      let status = SchemaRowStatus.EMPTY;
      let filledFields = 0;
      let disabledFields = 0;
      const fieldsCount = Object.values(row.fields).length;

      Object.values(row.fields).forEach(
        (field: SchemaField) => {
          if (field.value !== null) {
            if (field.value < 0) {
              disabledFields += 1;
            }
            if (field.value >= 0) {
              filledFields += 1;
            }
          }
        },
      );

      // if at least one field is filled
      if (filledFields > 0) {
        status = SchemaRowStatus.FILLED;
        // all field are filled
        if (filledFields === fieldsCount) {
          status = SchemaRowStatus.FULFILLED;
        }
      }

      // if at least one field is disabled then all row is considered disabled
      if (disabledFields > 0) {
        status = SchemaRowStatus.DISABLED;
      }

      // eslint-disable-next-line no-param-reassign
      row.status = status;
    },
    //
    onFieldInput(data: PriceConfigWizardSchemaInputData): void {
      const { value, field, row, pieces, withCabin } = data;

      const splitToken = field.fullToken.split('_');
      // Spot or staging combinations
      if (!combinationTypes.includes(row.type)) {
        const schemaRow: SchemaRow =
          this.wizardSchema[splitToken[0]]
            .sectors[splitToken[1]]
            .rows[splitToken[2]];
        schemaRow.fields[splitToken[3]].value = value;
        this.setRowStatus(schemaRow);
      // Combination type of rows
      } else if ((row as SchemaComboRow).rows) {
        // It's a main combination
        const schemaRow = this.wizardSchema[splitToken[0]]
          .sectors[splitToken[1]]
          .rows[row.token] as SchemaComboRow;
        schemaRow.fields[field.token].value = value;
        // rebuild placeholders
        // (maybe set on main combination row and
        // avoid to set placeholder on each combination row (props))
        Object.values(schemaRow.rows).forEach(
          (row: SchemaRow) => {
            // eslint-disable-next-line no-param-reassign
            row.fields[field.token].placeholder = value;
          },
        );
        this.setRowStatus(schemaRow);
      } else {
        // It's an exploded combination
        const schemaRow: SchemaRow = (
          this.wizardSchema[splitToken[0]].sectors[splitToken[1]].rows[
            `U1${withCabin ? '_W1' : ''}_#${pieces}`
          ] as SchemaComboRow
        ).rows[row.token];
        schemaRow.fields[field.token].value = value;
        this.setRowStatus(schemaRow);
      }
      // update and emit model
      this.model[field.fullToken] = value;
      this.$emit('update:modelValue', this.model);
    },
    setRowExpand(
      row: SchemaComboRow,
      expanded: boolean,
    ): void {
      const splitToken = row.fullToken.split('_');
      const comboToken = this.getBaseTokenForCombo(row.pieces, row.withCabin);
      const combinationRow =
        this.wizardSchema[splitToken[0]]
        .sectors[splitToken[1]]
        .rows[comboToken] as SchemaComboRow;

      combinationRow.expanded = expanded;
    },
    onCollapse(row: SchemaComboRow): void {
      this.setRowExpand(row, false);
    },
    onExpand(row: SchemaComboRow): void {
      this.setRowExpand(row, true);
    },
    onPeriodChange(): void {
      const period: Period = this.periods[this.selectedPeriodTab];
      if (!has(this.wizardSchema, period.token)) {
        this.buildWizardSchema(period);
      }
      this.selectedPeriodToken = period.token;
    },
    // Updates the given fields (and related model key) with the given value
    updateRowFieldsAndValues(
      fields: SchemaFields,
      value: number | null,
    ): void {
      Object.values(fields).forEach(
        (field: SchemaField) => {
          // Update field and model
          // eslint-disable-next-line no-param-reassign
          field.value = value;
          this.model[field.fullToken] = value;
        },
      );
    },
    updateRowsWithDisabledToken(
      combinationRows: Array<SchemaComboRow>,
      disabledToken: string,
      value: number | null,
      includeMainRow = false,
      status: SchemaRowStatus | undefined = undefined,
    ): void {
      combinationRows.forEach(
        (combinationRow: SchemaComboRow) => {
          // Updating the main row also takes care of the exploded combinations
          // This behaviour is a bit different than the other staging items
          // The others can only disabled some of the combinations
          if (
            combinationRow.token.includes(SchemaToken.PIECE)
            && combinationRow.token.includes(disabledToken)
            && includeMainRow
            && !!status
          ) {
            this.handleCombinationSwitchEvent(
              combinationRow as SchemaComboRow,
              status,
              value,
            );
            return;
          }
          const comboRows = Object.values(combinationRow.rows).filter(
            (row: SchemaRow) => row.token.includes(disabledToken),
          );
          // Combinations
          comboRows.forEach(
            (row: SchemaRow | SchemaComboRow) => {
              // Update only the fields
              this.updateRowFieldsAndValues(row.fields, value);
              this.setRowStatus(row);
            },
          );
        },
      );
    },
    getRowsByType(
      sectorRows: SchemaSectorRows,
    ): Array<SchemaComboRow> {
      return Object.values(sectorRows).filter(
        (sectorRow) => combinationTypes.includes(sectorRow.type),
      ) as Array<SchemaComboRow>;
    },
    // Disable cabin (maybe umbrella too in the future?)
    handleSpotSwitchEvent(
      row: SchemaRow,
      status: SchemaRowStatus,
      value: number | null,
    ): void {
      // Wrong method I guess
      if (row.type !== SchemaRowType.SPOT) {
        return;
      }
      // Split the token to make use of the keys
      const spotToken: string = row.token; // U1 or W1
      // Disabling umbrellas is not allowed
      if (spotToken.includes(SchemaToken.UMBRELLA)) {
        return;
      }
      // Exploded Token
      const splitToken: Array<string> = row.fullToken.split('_');
      // Rows for the given period and sector
      const sectorRows: SchemaSectorRows =
        this.wizardSchema[splitToken[0]].sectors[splitToken[1]].rows;
      const spotRow = sectorRows[spotToken];
      spotRow.status = status;
      this.updateRowFieldsAndValues(spotRow.fields, value);
      // Combinations for the given sector
      const combinationsRows = this.getRowsByType(sectorRows);
      // Can just be W but we keep it abstract in case we want to disable umbrellas one day
      const disabledToken = spotToken.charAt(0);
      // Update the combinations that include the disabled item (Just the cabin combos)
      this.updateRowsWithDisabledToken(
        combinationsRows,
        spotToken.charAt(0),
        value,
        true,
        status,
      );
    },
    handleStagingSwitchEvent(
      row: SchemaRow,
      status: SchemaRowStatus,
      value: number | null,
    ): void {
      // Wrong method I guess
      if (row.type !== SchemaRowType.STAGING) {
        return;
      }
      const splitToken: Array<string> = row.fullToken.split('_');
      // B1, C1, D1 or M1
      const stagingToken: string = row.token;
      // Rows for the given period and sector
      const sectorRows: SchemaSectorRows =
        this.wizardSchema[splitToken[0]].sectors[splitToken[1]].rows;
      // Exact row that was disabled
      const stagingRow: SchemaRow = sectorRows[stagingToken];
      // update staging row
      stagingRow.status = status;
      // Upodate fields and values
      this.updateRowFieldsAndValues(row.fields, value);
      // sector combinations
      const combinationsRows = this.getRowsByType(sectorRows);
      // Update the combinations containing the token
      this.updateRowsWithDisabledToken(
        combinationsRows,
        stagingToken.charAt(0),
        value,
      );
    },
    handleCombinationSwitchEvent(
      row: SchemaComboRow,
      status: SchemaRowStatus,
      value: number | null,
    ): void {
      const splitToken: Array<string> = row.fullToken.split('_');
      const mainCombinationRow: SchemaComboRow = this
        .wizardSchema[splitToken[0]].sectors[splitToken[1]].rows[
        `U1${row.withCabin ? '_W1' : ''}_#${row.pieces}`
        ] as SchemaComboRow;
      // Something's wrong
      if (!mainCombinationRow) return;
      // Update status in every case
      // No further actions required for sub combinations
      const combinationRow = mainCombinationRow.rows[row.token];
      if (combinationRow) {
        combinationRow.status = status;
        this.updateRowFieldsAndValues(combinationRow.fields, value);
        return;
      }
      mainCombinationRow.status = status;
      this.updateRowFieldsAndValues(mainCombinationRow.fields, value);
      // Disable / Re-enable the exploded combinations
      Object.values(mainCombinationRow.rows).forEach(
        (row: SchemaRow) => {
          // If re-enabled we need to display the value of the placeholder
          if (value === null) {
            Object.values(row.fields).forEach(
              (field: SchemaField) => {
                // eslint-disable-next-line no-param-reassign
                field.placeholder = mainCombinationRow.fields[field.token].value;
              },
            );
            this.updateRowFieldsAndValues(row.fields, value);
          }
          // update row status
          this.setRowStatus(row);
        },
      );
    },
    // Handle the switch (both on and off) event of the given row
    onSwitch(data: PriceConfigWizardSchemaSwitchData): void {
      const splitToken: Array<string> = data.row.fullToken.split('_');
      // Just re-enable / disable the row
      const status =
        data.row.status === SchemaRowStatus.DISABLED
          ? SchemaRowStatus.EMPTY
          : SchemaRowStatus.DISABLED;
      // -1 is the value for disabled, null for empty
      const value = status === SchemaRowStatus.DISABLED ? -1 : null;
      // Check which type or row we should handle (spot, staging or combination)
      switch (data.row.type as SchemaRowType) {
        case SchemaRowType.SPOT:
          this.handleSpotSwitchEvent(data.row, status, value);
          break;
        case SchemaRowType.STAGING:
          this.handleStagingSwitchEvent(data.row, status, value);
          break;
        // Combinations (with / without cabin)
        default:
          this.handleCombinationSwitchEvent(
            data.row as SchemaComboRow,
            status,
            value,
          );
      }
      // emit updated model
      this.$emit('update:modelValue', this.model);
    },
    // DIALOG SECTION
    // "Copy in" dialog header description
    getDialogHeader(): string {
      switch (this.copyMode) {
        case CopyMode.STAGING:
          return this.$t('fieldPriceListCombinations.copyStaging');
        case CopyMode.UMBRELLA_COMBINATION:
        case CopyMode.CABIN_COMBINATION:
          return this.$t('fieldPriceListCombinations.combinations');
        default:
          return this.$t('fieldPriceListCombinations.copySpot');
      }
    },
    // Initialize "Copy in" dialog (periods and sectors)
    initCopyDialog(): void {
      this.periods.forEach((period: Period) => {
        const copySectorOption = {
          label: period.label,
          period,
          items: [] as Array<CopySectorOption>,
        };
        // sectors
        this.sectors.forEach((sector: Sector) => {
          copySectorOption.items.push({
            label: sector.label,
            sector,
            value: `${period.token}_${sector.token}`,
          });
        });
        this.copyPeriodsOptions.push(copySectorOption);
      });
    },
    // Sets the options for the copy modal
    calculateCopyRowOptions(
        tokens: Array<SchemaToken>,
    ): void {
      const sectorRows =
        this.wizardSchema[`P${this.copyPeriodId}`]
          .sectors[`S${this.copySectorId}`]
          .rows;
      this.copyRowsOptions = this.dictionary
        .filter((dictionaryItem: DictionaryItem) =>
          tokens.includes(dictionaryItem.token),
        )
        .map(
          (dictionaryItem: DictionaryItem) => {
            const token = dictionaryItem.token;
            return {
              label: dictionaryItem.label,
              value: token,
              disabled: sectorRows[`${token}1`].status === SchemaRowStatus.DISABLED,
            } as CopyRowOption;
          },
        );
      // Set the rows that will be copied
      this.copyRows = this.copyRowsOptions
        .map((option: CopyRowOption) => option.value);
    },
    // Dialog checkboxes for combinations
    calculateComboRowCopyOptions(
      period: Period,
      sector: Sector,
      withCabin: boolean,
    ): void {
      const sectorRows: SchemaSectorRows =
        this.wizardSchema[`P${this.copyPeriodId}`]
          .sectors[`S${this.copySectorId}`]
          .rows;
      // Filter only combo rows with cabins
      const combos = Object.values(sectorRows).filter(
        (r: SchemaComboRow | SchemaRow) => {
          if (!r.token.includes(SchemaToken.PIECE)) return false;
          const hasCabin = r.token.includes(SchemaToken.CABIN);
          if (withCabin && !hasCabin) return false;
          if (!withCabin && hasCabin) return false;
          return true;
        }) as Array<SchemaComboRow>;
      // Options based on the number of pieces
      this.copyRowsOptions = combos.map((c: SchemaComboRow) => {
        const tokenPieces = c.token.split('_');
        const token = tokenPieces[tokenPieces.length - 1].substring(1);
        return {
          label: this.$tc(
            'fieldPriceListCombinations.combinationOption',
            Number(token),
          ),
          value: token,
          disabled: sectorRows[c.token].status === SchemaRowStatus.DISABLED,
        } as CopyRowOption;
      });
      // Set the rows that will be copied
      this.copyRows = this.copyRowsOptions
        .map((option: CopyRowOption) => option.value);
    },
    // Copy dialog
    onCopyDialog(
      period: Period,
      sector: Sector,
      copyMode: CopyMode,
    ): void {
      // Init dialog if it's the first time we open it
      if (this.copyPeriodsOptions.length === 0) {
        this.initCopyDialog();
      }
      this.copyMode = copyMode;
      this.copyPeriodId = period.id;
      this.copySectorId = sector.id;
      this.copyDialog = true;
      this.copySectors = [];
      // Spot copy
      if (copyMode === CopyMode.SPOT) {
        this.calculateCopyRowOptions(cloneDeep(this.spotsTokens));
      } else if (copyMode === CopyMode.STAGING) {
        // Staging items copy
        this.calculateCopyRowOptions(cloneDeep(this.stagingTokens));
      } else {
        // Calculate options above on combos (list on # of pieces)
        const withCabin = copyMode === CopyMode.CABIN_COMBINATION;
        this.calculateComboRowCopyOptions(period, sector, withCabin);
      }
    },
    // Copy values for spots and staging (simple rows)
    copySimpleRows(): void {
      const toBeCopiedSectorRows = this.wizardSchema[`P${this.copyPeriodId}`]
        .sectors[`S${this.copySectorId}`]
        .rows;
      this.copySectors.forEach((sectorFullToken: string) => {
        // Find the right rows to capy the values to
        const tokenSlices = sectorFullToken.split('_');
        const period = Number(tokenSlices[0].substring(1));
        const sector = Number(tokenSlices[1].substring(1));
        const copyToSectorRows = this.wizardSchema[`P${period}`]
          ?.sectors[`S${sector}`]
          ?.rows;
        const hasPeriod = Boolean(copyToSectorRows);
        // Get current period tokens
        const tokens = this.fieldsTokens.get(period);
        if (!tokens) {
          return;
        }
        const baseCopyToken = `P${this.copyPeriodId}_S${this.copySectorId}`;
        const baseCopyToToken = `P${period}_S${sector}`;
        this.copyRows.forEach((row: string) => {
          tokens.forEach((fieldToken: string) => {
            const value = this.model[`${baseCopyToken}_${row}1_${fieldToken}`];
            // Not copying null or undefined ones -> zero is fine
            if (value === undefined || value === null) {
              return;
            }
            this.model[`${baseCopyToToken}_${row}1_${fieldToken}`] = value;
            // update wizard schema only if period is already initialized
            if (!hasPeriod) {
              return;
            }
            copyToSectorRows[`${row}1`].fields[fieldToken].value = value;
          });
          if (hasPeriod) {
            this.setRowStatus(copyToSectorRows[`${row}1`]);
          }
        });
      });
    },
    // Used to copy entire combinations from sector to sector / period
    copyComboRows(): void {
      const withCabin = this.copyMode === CopyMode.CABIN_COMBINATION;
      // Source
      const toBeCopiedSectorRows = this.wizardSchema[`P${this.copyPeriodId}`]
        .sectors[`S${this.copySectorId}`]
        .rows;
      this.copySectors.forEach((sectorFullToken: string) => {
        // Find the right rows to capy the values to
        const tokenSlices = sectorFullToken.split('_');
        const period = Number(tokenSlices[0].substring(1));
        const sector = Number(tokenSlices[1].substring(1));
        // Get current period tokens
        const tokens = this.fieldsTokens.get(period);
        if (!tokens) {
          return;
        }
        // Pre calculate token prefixes and period init flag
        const baseCopyToken = `P${this.copyPeriodId}_S${this.copySectorId}`;
        const baseCopyToToken = `P${period}_S${sector}`;
        const copyToSectorRows = this.wizardSchema[`P${period}`]
          ?.sectors[`S${sector}`]
          ?.rows;
        const hasPeriod = Boolean(copyToSectorRows);
        // Update wizard schema
        // Copy rows contains the number of pieces that the user wants to copy
        this.copyRows.forEach((pieces: string) => {
          // Key of the wildcard combination (U1_{W1}_#{n})
          const comboKey = this.getBaseTokenForCombo(Number(pieces), withCabin);
          // Rows to be copied forthe given number of pieces
          const toBeCopiedRows = toBeCopiedSectorRows[comboKey] as SchemaComboRow;
          const copyToRows = copyToSectorRows?.[comboKey] as SchemaComboRow;
          // Could happen that we try to copy from a n to a n - m (m > 0) combination
          // So the index might not exist
          if (!copyToRows) {
            return;
          }
          // Keep values of the main row for placeholders
          const mainRowValues = new Map<string, number|null>();
          // Wildcard combinations
          tokens.forEach((fieldToken: string) => {
            // Rows to be copied (also explosions)
            const value = this.model[`${baseCopyToken}_${comboKey}_${fieldToken}`];
            // Not copying null or undefined ones -> zero is fine
            if (value === undefined || value === null) {
              return;
            }
            this.model[`${baseCopyToToken}_${comboKey}_${fieldToken}`] = value;
            mainRowValues.set(fieldToken, value);
            // Check if period is already initialized
            if (!hasPeriod) {
              return;
            }
            // Update schema
            copyToRows.fields[fieldToken].value = value;
          });
          // Update status of the main row
          this.setRowStatus(copyToRows);
          // Exploded combinations
          Object.values(toBeCopiedRows.rows).forEach(
            (r: SchemaRow) => {
              tokens.forEach((fieldToken: string) => {
                const toCopyValue = r.fields[fieldToken]?.value;
                // Columns might not match exactly
                if (toCopyValue === null || toCopyValue === undefined) {
                  return;
                }
                // Update model
                this.model[`${baseCopyToToken}_${r.token}_${fieldToken}`] = toCopyValue;
                // Check if period is already initialized
                if (!hasPeriod) {
                  return;
                }
                // Update schema
                const explodedCombo = copyToRows.rows[r.token];
                explodedCombo.fields[fieldToken].value = toCopyValue;
                // Update placeholder - If null it takes the value from the parent row
                const placeholderValue = toCopyValue === null
                  ? (mainRowValues.get(fieldToken) ?? null)
                  : toCopyValue;
                explodedCombo.fields[fieldToken].placeholder = placeholderValue;
                // Update row status
                this.setRowStatus(explodedCombo, copyToRows);
              });
            });
        });
      });
    },
    // Copy values for combinations (explandable rows)
    // Updates the model with the values to be copied
    onCopy(): void {
      // Copy values - Already enabled / disabled in the dialog
      if (this.combinationCopyModes.includes(this.copyMode)) {
        this.copyComboRows();
      } else {
        // Copy combination values
        this.copySimpleRows();
      }
      this.$emit('update:modelValue', this.model);
      // Update disabled rows
      this.copyDialog = false;
    },
    onSelectAllPeriodSectors(option: CopyPeriodOption): void {
      /**
       * Select all sectors of desired period (except the current)
       */
      const currentValue = `P${this.copyPeriodId}_S${this.copySectorId}`;
      const periodSectors = this.copyPeriodsOptions.filter(
        (o: CopyPeriodOption) => o.period.id === option.period.id,
      )[0].items;

      const sectorsOptions = periodSectors
        .filter((item: CopySectorOption) => item.value !== currentValue)
        .reduce(
          (array: Array<string>, a: CopySectorOption) =>
            array.concat([a.value]),
          [],
        );
      this.copySectors = [...new Set([...this.copySectors, ...sectorsOptions])];
    },
    onDeselectAllPeriodSectors(option: CopyPeriodOption): void {
      this.copySectors = this.copySectors.filter(
        (copySector: string) => !copySector.includes(`P${option.period.id}`),
      );
    },
    copySectorsOptionDisabled(option: CopySectorOption): boolean {
      return option.value === `P${this.copyPeriodId}_S${this.copySectorId}`;
    },
    // Copy direcly on row (umbrella or cabin)
    onRowAction(actionData: PriceConfigWizardSchemaActionData): void {
      if (actionData.row.status === SchemaRowStatus.DISABLED) {
        return;
      }
      /**
       * Row action
       */
      // Find the right rows to capy the values to
      const tokenSlices = actionData.row.fullToken.split('_');
      const period = Number(tokenSlices[0].replace('P', ''));
      const sector = Number(tokenSlices[1].replace('S', ''));
      // Check if we are copying an umbrella or a cabin
      let spotToken = '';
      let comboToken = '';
      let baseToken = '';
      if (actionData.action === SchemaRowActionValue.UMBRELLA_TO_COMBINATIONS) {
        spotToken = SchemaToken.UMBRELLA;
        baseToken = `${spotToken}1`;
        comboToken = `${spotToken}1`;
      } else if (actionData.action === SchemaRowActionValue.CABIN_TO_COMBINATIONS) {
        spotToken = SchemaToken.CABIN;
        baseToken = `${spotToken}1`;
        comboToken = `${SchemaToken.UMBRELLA}1_${spotToken}1`;
      }
      // Action not supported so we skip
      if (comboToken === null) {
        return;
      }
      // Get current period tokens
      const tokens = Object.keys(actionData.row.fields);
      if (!tokens) {
        return;
      }
      // Parse all the tokens of the current period
      // If no sector detail just fallback to the general setting
      const numPieces = this.data?.sectorCombinationPieces.find(
        (p) => p.id === sector,
      )?.combinationPieces ?? this.data?.combinationPieces ?? 0;
      tokens.forEach((fieldToken: string) => {
        Array.from(
          { length: numPieces },
          (_, i) => i + 1,
        ).forEach((combinationPieces: number) => {
          const mainToken = `P${period}_S${sector}_${baseToken}_${fieldToken}`;
          const combinationToken = `P${period}_S${sector}_${comboToken}_#${combinationPieces}_${fieldToken}`;
          const value = this.model[mainToken];
          this.model[combinationToken] = value;
          // Update wizard schema
          // Always works on combination rows that's why I just assume the type
          const currentRows = this.wizardSchema[`P${period}`]
            .sectors[`S${sector}`]
            .rows[`${comboToken}_#${combinationPieces}`] as SchemaComboRow;
          // There might be combination pieces for umbrella and not for cabins
          if (!currentRows) {
            return;
          }
          // Update placeholder
          Object.values(currentRows.rows).forEach(
            (row: SchemaRow) => {
              // eslint-disable-next-line no-param-reassign
              row.fields[fieldToken].placeholder = value;
            },
          );
          currentRows.fields[fieldToken].value = value;
        });
      });
      this.$emit('update:modelValue', this.model);
    },
  },
  computed: {
    ...mapState('session', {
      license: 'license',
      licenseSectors: 'sectors',
    }),
    wizardSchemaComputed(): PriceConfigWizardSchema {
      return this.wizardSchema;
    },
    periods(): Array<Period> {
      /**
       * Periods
       */
      return this.data.periodsData.map(
        (periodData: PriceConfigPeriodData) =>
          ({
            id: periodData.id,
            label: periodData.name,
            token: `${this.dictionaryToken.PERIOD}${periodData.id}`,
          } as Period),
      );
    },
    sectors(): Map<number, Sector> {
      /**
       * Sectors
       */
      const sectors: Map<number, Sector> = new Map();
      this.licenseSectors.forEach(
        (sector: SectorModel) => {
          sectors.set(
            sector.header.id,
            {
              id: sector.header.id,
              label: sector.header.name,
              token: `${this.dictionaryToken.SECTOR}${sector.header.id}`,
            } as Sector,
          );
        },
      );
      return sectors;
    },
    defaultModel(): Model {
      // Wizard matrix field from back-end
      const defaultModel: Model = {};
      if (this.optionNames.length > 0) {
        this.optionNames.forEach((fieldName: string) => {
          defaultModel[fieldName] = null;
        });
      }
      return defaultModel;
    },
    periodsTabs(): Array<PeriodTab> {
      return this.periods.map(
        (period: Period) =>
          ({
            label: period.label,
            icon: this.parsedErrors.includes(period.token)
              ? 'pi pi-exclamation-triangle text-orange-600'
              : 'pi pi-bars',
          } as PeriodTab),
      );
    },
    fieldsTokens(): Map<number, Array<string>> {
      /**
       * Fields tokens
       */
      // daily
      const fieldsTokens: Map<number, Array<string>> = new Map();
      this.periods.forEach((period: Period) => {
        // Find the right configuration to configure the columns
        const currentPeriod: PeriodConfiguration | undefined =
          this.data?.periodsConfigurations.find(
            (periodConfig: PeriodConfiguration) =>
              periodConfig.periodId === period.id,
          );
        // Exit if we don't find any configuration
        if (!currentPeriod) {
          return;
        }
        const currentPeriodTokens = new Array<string>();
        currentPeriodTokens.push(`${SchemaToken.DAY}1`);
        // weekend
        const hasWeekendEnabled = currentPeriod.useWeekend;
        if (hasWeekendEnabled) {
          currentPeriodTokens.push(`${SchemaToken.WEEKEND}1`);
        }
        // discounts (only numeric mode)
        if (
          currentPeriod.priceMode === PriceConfigPriceMode.DISCOUNT
          && currentPeriod.thresholdCalculationMode === ThresholdCalcMode.NUMERIC
          && currentPeriod.discountType
        ) {
          currentPeriod.discountType.forEach((day: number) => {
            currentPeriodTokens.push(`${SchemaToken.DAY}${day}`);
            if (hasWeekendEnabled) {
              currentPeriodTokens.push(`${SchemaToken.WEEKEND}${day}`);
            }
          });
        }
        // half day
        if (currentPeriod.useHalfDay) {
          currentPeriodTokens.push(SchemaToken.HALF_DAY);
          if (hasWeekendEnabled) {
            currentPeriodTokens.push(SchemaToken.HALF_DAY_WEEKEND);
          }
        }
        // day extra
        if (currentPeriod.extraDaysMode === PriceConfigExtraDaysMode.STANDARD) {
          currentPeriodTokens.push(SchemaToken.DAY_EXTRA);
        }
        // Push the period tokens in the map
        fieldsTokens.set(period.id, currentPeriodTokens);
      });
      return fieldsTokens;
    },
    parsedErrors(): Array<string> {
      /**
       * An array of tokens errors
       * P1 -> error on period 1
       * P1_S1 -> error on sector 1 of period 1
       * P1_S1_U1 -> error on specific input
       */
      let combinationsErrors = [] as Array<string>;
      if (!this.errors) return combinationsErrors;
      combinationsErrors = clone(Object.keys(this.errors));
      // custom periods/sectors errors`
      let sectorsErrors = [] as Array<string>;
      const periodsErrors = [] as Array<string>;
      this.periods.forEach((period: Period) => {
        const errors = [] as Array<string>;
        this.sectors.forEach((sector: Sector) => {
          const key = `P${period.id}_S${sector.id}`;
          if (combinationsErrors.some((error: string) => error.includes(key))) {
            errors.push(key);
          }
        });
        if (errors.length > 0) {
          periodsErrors.push(`P${period.id}`);
          sectorsErrors = sectorsErrors.concat(errors);
        }
      });
      return [...periodsErrors, ...sectorsErrors, ...combinationsErrors];
    },
  },
});
