export enum StatusItemCategory {
  BEDS = 'Lettini',
  UMBRELLAS = 'Ombrelloni',
}

export type StatusItem = {
  battery: boolean;
  category: StatusItemCategory;
  imei: string;
  name: string;
  opened: boolean;
  opening: null | boolean;
  signal: number;
};

export type OpeningStatus = 'opening' | 'closing' | 'idle';

export type StatusItemWithId = StatusItem & {
  id: number;
  status: OpeningStatus;
};

export type SectorItem = {
  id: number;
  items: StatusItemWithId[];
  label: string;
};
