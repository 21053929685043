import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a1a24cf"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "revenue-management-period-panel" }
const _hoisted_2 = { class: "wrapper" }
const _hoisted_3 = { class: "p-panel-title" }
const _hoisted_4 = { class: "p-panel-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RevenueManagementRules = _resolveComponent("RevenueManagementRules")!
  const _component_Panel = _resolveComponent("Panel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Panel, {
      header: "Header",
      toggleable: ""
    }, {
      header: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.description), 1)
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_RevenueManagementRules, {
          thresholdLabel: _ctx.$t('revenueManagementPeriodPanel.thresholdLabel'),
          valueLabel: _ctx.$t('revenueManagementPeriodPanel.suggest'),
          addLabel: _ctx.$t('revenueManagementPeriodPanel.addNewRule'),
          rules: _ctx.rules,
          onCreate: _ctx.onCreate,
          onUpdate: _ctx.onUpdate,
          onDelete: _ctx.onDelete
        }, null, 8, ["thresholdLabel", "valueLabel", "addLabel", "rules", "onCreate", "onUpdate", "onDelete"])
      ]),
      _: 1
    })
  ]))
}