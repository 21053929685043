import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "contacts-management" }
const _hoisted_2 = {
  key: 0,
  class: "flex align-items-center"
}
const _hoisted_3 = ["href", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.breakpoints.mobile)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_Button, {
            class: "toggle",
            type: "button",
            label: _ctx.$t('common.menu'),
            icon: "pi pi-bars",
            onClick: _ctx.toggle
          }, null, 8, ["label", "onClick"]),
          _createVNode(_component_Menu, {
            id: "contacts-mobile-navigation",
            ref: "menu",
            model: _ctx.menuItems,
            popup: true
          }, null, 8, ["model"])
        ], 64))
      : (_openBlock(), _createBlock(_component_Menu, {
          key: 1,
          model: _ctx.menuItems
        }, {
          item: _withCtx(({ item }) => [
            (item.items)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
                  _createElementVNode("i", {
                    class: _normalizeClass(item.icon + ' mr-2')
                  }, null, 2),
                  _createTextVNode(" " + _toDisplayString(item.label), 1)
                ]))
              : (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: item.to,
                  custom: ""
                }, {
                  default: _withCtx(({ href, navigate, isActive, isExactActive }) => [
                    _createElementVNode("a", {
                      href: href,
                      onClick: navigate,
                      class: _normalizeClass({
              'active-link': isActive,
              'active-link-exact': isExactActive,
              'p-menuitem-link': true,
              'p-disabled': item.disabled,
            })
                    }, _toDisplayString(item.label), 11, _hoisted_3)
                  ]),
                  _: 2
                }, 1032, ["to"]))
          ]),
          _: 1
        }, 8, ["model"]))
  ]))
}