import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ac43b4d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "statistics-duration-view",
  class: "statistics-view view",
  ref: "printContent"
}
const _hoisted_2 = { id: "charts" }
const _hoisted_3 = { id: "counters" }
const _hoisted_4 = {
  key: 1,
  class: "counters-list"
}
const _hoisted_5 = { class: "counter-item daily" }
const _hoisted_6 = { class: "days" }
const _hoisted_7 = { class: "count" }
const _hoisted_8 = { class: "value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsBarChart = _resolveComponent("StatisticsBarChart")!
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_StatisticsTree = _resolveComponent("StatisticsTree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_StatisticsBarChart, {
        label: _ctx.countChart.label,
        chartData: _ctx.countChart.chartData,
        height: 600,
        horizontal: false,
        useTicks: ""
      }, null, 8, ["label", "chartData"]),
      _createVNode(_component_StatisticsBarChart, {
        label: _ctx.valueChart.label,
        chartData: _ctx.valueChart.chartData,
        height: 600,
        repositionLabel: true,
        horizontal: false,
        preValue: "€",
        useTicks: ""
      }, null, 8, ["label", "chartData"])
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createElementVNode("h6", null, _toDisplayString(_ctx.$t('statisticsDurationView.countersTitle')), 1),
      (_ctx.processing)
        ? (_openBlock(), _createBlock(_component_Skeleton, {
            key: 0,
            height: "300px",
            width: "100%"
          }))
        : (_openBlock(), _createElementBlock("ul", _hoisted_4, [
            _createElementVNode("li", _hoisted_5, [
              _createVNode(_component_StatisticsTree, { items: _ctx.dailyCounters }, null, 8, ["items"])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.counters, (counter, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                class: "counter-item"
              }, [
                _createElementVNode("span", _hoisted_6, _toDisplayString(counter.days === 32
                ? _ctx.$t('statisticsDurationView.greaterThanDays')
                : _ctx.$tc('common.daysCount', counter.days)), 1),
                _createElementVNode("span", _hoisted_7, _toDisplayString(counter.count), 1),
                _createElementVNode("span", _hoisted_8, " € " + _toDisplayString(counter.value), 1)
              ]))
            }), 128))
          ]))
    ])
  ], 512))
}