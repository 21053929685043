
import { defineComponent, PropType } from 'vue';
import {
  StatisticCashFlowsPayment,
  StatisticFiscalMode,
} from '@/models/statistic';
import {
  CASH_FLOW_METHOD_OPTIONS,
  CashFlowMethod,
  CashFlowMethodOption,
} from '@/models/cashFlow';
import currencyUtil from '@/utils/currencyUtil';

const EMPTY_TEXT = '-';

export default defineComponent({
  name: 'StatisticsPaymentsHistoryDataTable',
  props: {
    source: {
      type: Array as PropType<StatisticCashFlowsPayment[]>,
      default: null,
    },
  },
  emits: ['rowClick'],
  data() {
    return {
      EMPTY_TEXT,
      StatisticFiscalMode,
    };
  },
  methods: {
    format: currencyUtil.format,
    getCashFlowAmountsCssClasses(cashFlowAmount: number) {
      return {
        'w-full text-right': true,
        'text-green-500': cashFlowAmount > 0,
        'text-red-500': cashFlowAmount < 0,
      };
    },
    rowClass(row: StatisticCashFlowsPayment) {
      let result = 'row';
      if (row.reservationId !== null) {
        result += ' cursor-active';
      }
      return result;
    },
    getPaymentMethod(paymentMethod: CashFlowMethod): string {
      return (
        CASH_FLOW_METHOD_OPTIONS.find(
          (method: CashFlowMethodOption) => method.value === paymentMethod,
        )?.label || EMPTY_TEXT
      );
    },
    getFiscalModeTagProps(
      fiscalMode: StatisticFiscalMode,
    ): Record<string, string> | null {
      if (
        ![StatisticFiscalMode.RECEIPT, StatisticFiscalMode.INVOICE].includes(
          fiscalMode,
        )
      ) {
        return null;
      }

      return {
        value:
          fiscalMode === StatisticFiscalMode.RECEIPT
            ? this.$t('statisticsPaymentsHistoryDataTable.receipt')
            : this.$t('statisticsPaymentsHistoryDataTable.invoice'),
        class: 'bg-yellow-100 text-yellow-700 font-normal border-round',
      };
    },
    getOnlineTagProps(online: boolean): Record<string, string> {
      return {
        value: online
          ? this.$t('statisticsPaymentsHistoryDataTable.online')
          : this.$t('statisticsPaymentsHistoryDataTable.offline'),
        class: `font-normal border-round ${
          online ? 'text-purple-700 bg-purple-100' : 'text-gray-700 bg-gray-100'
        }`,
      };
    },
  },
  computed: {
    totalCashFlowAmount(): number {
      return (
        this.source?.reduce(
          (total: number, row: StatisticCashFlowsPayment) =>
            total + (row.cashFlowAmount ?? 0),
          0,
        ) ?? 0
      );
    },
    totalCashFlowFiscalAmount(): number {
      return (
        this.source?.reduce(
          (total: number, row: StatisticCashFlowsPayment) =>
            total + (row.cashFlowFiscalAmount ?? 0),
          0,
        ) ?? 0
      );
    },
  },
});
