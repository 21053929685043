import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-1 flex justify-content-between"
}
const _hoisted_2 = { class: "flex flex-column" }
const _hoisted_3 = { class: "flex w-full align-items-center mt-3" }
const _hoisted_4 = { class: "flex flex-row" }
const _hoisted_5 = {
  key: 1,
  class: "message-box flex flex-column"
}
const _hoisted_6 = { class: "flex flex-column md:flex-row justify-content-between" }
const _hoisted_7 = {
  key: 0,
  class: "msg-success mt-2"
}
const _hoisted_8 = {
  key: 1,
  class: "msg-fail mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Skeleton, {
            width: "60px",
            height: "30px"
          }),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Skeleton, {
              width: "250px",
              height: "30px",
              class: "mr-1"
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Skeleton, {
            class: "mr-2",
            width: "200px",
            height: "30px"
          }),
          _createVNode(_component_Skeleton, {
            width: "200px",
            height: "30px"
          })
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("h5", null, _toDisplayString(_ctx.$t('stripePosConnectionReturnView.title')), 1),
          _createElementVNode("div", null, [
            (_ctx.isCustom)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  class: "btn mr-2",
                  label: "Aggiorna dati",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.stripePosAccountInitConnection()))
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_Button, {
              class: "btn",
              label: "Ritorna alle Impostazioni",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToBookingConfig()))
            })
          ])
        ]),
        (_ctx.isStripePosAccountConnected)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('stripePosConnectionReturnView.success')), 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t(`stripePosConnectionReturnView.${_ctx.errorType}`)), 1))
      ]))
}