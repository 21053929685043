import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

export const ERROR_DEFAULT_MESSAGE = i18n.global.t('errors.default');
export enum ErrorLog {
  CONSOLE,
  // EMAIL,
  // BACKEND,
}
export interface ErrorJavascriptData {
  stack: string;
  fileName: string | undefined;
  lineNumber: number | undefined;
}
export interface ErrorAxiosData {
  response: AxiosResponse;
  request: any;
}
export interface ErrorSpiaggeData {
  errors: string;
  file: string;
  line: number;
}

export type ErrorData = ErrorJavascriptData | ErrorAxiosData | ErrorSpiaggeData;

export interface Error {
  code: string;
  message: string; // for user
  description?: string; // for devs
  data?: ErrorData;
}
