import { Timestamp } from '.';

export interface DiscountCode {
  allowPortals: boolean;
  applyFrom: Timestamp;
  applyTo: Timestamp;
  code: string;
  createdAt: Timestamp;
  customerEmail: string;
  deleted: boolean;
  discountFixed: string; // TODO number would be better
  discountPercentage: string; // TODO number would be better
  flagHoliday: boolean;
  flagPreHoliday: boolean;
  flagWeek: boolean;
  id: number;
  influencer: boolean;
  licenseId: string;
  maxUses: number;
  payAtBeach: boolean;
  remainingUses: number;
  serviceId: number;
  type?: string;
  updatedAt: Timestamp;
  userId: number;
  validFrom: Timestamp;
  validTo: Timestamp;
  voucherId: number | null;
}
