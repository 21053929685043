import { DateTime } from 'luxon';
import i18n from '@/i18n';
import { MapElementTypeRaw } from './map';
import { Reservation, ReservationHalfDay } from './reservation';

export interface SpotBasic {
  spotType: string;
  spotName: string;
}

export enum SpotAttribute {
  FREE = 'free',
  OVERBOOKING = 'overbooking',
  UNPAID = 'unpaid',
  UNPAID_LAST_DAY = 'unpaidLast',
  SHARING = 'sharing',
  SEASONAL = 'seasonal',
  BUSY = 'busy',
  BUSY_FIRST_DAY = 'busyFirstDay',
  BUSY_LAST_DAY = 'busyLastDay',
  TEMPORARY = 'temporary',
  HALF_DAY_MORNING = 'halfDayMorning',
  HALF_DAY_AFTERNOON = 'halfDayAfternoon',
  FULL_DAY = 'fullDay',
}

export enum SpotType {
  UMBRELLA = 'umbrella',
  GAZEBO = 'gazebo',
  PARKING = 'parking',
  BED = 'bed',
  BEDS = 'beds',
  CABIN = 'cabins',
  BOAT = 'boat',
  PLAYFIELD = 'playfield',
}

export interface Spot {
  createdAt: number | null;
  data: Array<any>;
  id: number;
  jsonData: null;
  name: string;
  online: number;
  posX: number;
  posY: number;
  priceListId: number;
  priceListOnlineId: number;
  reservations: Array<Reservation>;
  sector: number;
  type: MapElementTypeRaw;
  updatedAt: number | null;
}

export interface SpotInfo {
  type: SpotType;
  label: string;
  icon: string;
  custom: boolean;
  shiftableTo: Array<SpotType>;
  copyableTo: Array<SpotType>;
  selectableWith: Array<SpotType>;
}

export const SPOT_INFOS: Array<SpotInfo> = [
  {
    type: SpotType.UMBRELLA,
    label: i18n.global.t('spotType.umbrella'),
    icon: 'spi-sunumbrella',
    custom: false,
    shiftableTo: [SpotType.UMBRELLA, SpotType.GAZEBO, SpotType.BED],
    copyableTo: [SpotType.UMBRELLA, SpotType.GAZEBO, SpotType.BED],
    selectableWith: [SpotType.UMBRELLA, SpotType.GAZEBO, SpotType.BED],
  },
  {
    type: SpotType.GAZEBO,
    label: i18n.global.t('spotType.gazebo'),
    icon: 'spi-gazebo',
    custom: false,
    shiftableTo: [SpotType.UMBRELLA, SpotType.GAZEBO, SpotType.BED],
    copyableTo: [SpotType.UMBRELLA, SpotType.GAZEBO, SpotType.BED],
    selectableWith: [SpotType.UMBRELLA, SpotType.GAZEBO, SpotType.BED],
  },
  {
    type: SpotType.PARKING,
    label: i18n.global.t('spotType.parking'),
    icon: 'spi-parking',
    custom: false,
    shiftableTo: [SpotType.PARKING],
    copyableTo: [SpotType.PARKING],
    selectableWith: [SpotType.PARKING],
  },
  {
    type: SpotType.BED,
    label: i18n.global.t('spotType.bed'),
    icon: 'spi-sunbed',
    custom: false,
    shiftableTo: [SpotType.UMBRELLA, SpotType.GAZEBO, SpotType.BED],
    copyableTo: [SpotType.UMBRELLA, SpotType.GAZEBO, SpotType.BED],
    selectableWith: [SpotType.UMBRELLA, SpotType.GAZEBO, SpotType.BED],
  },
  {
    type: SpotType.BEDS,
    label: i18n.global.t('spotType.bedsArea'),
    icon: 'spi-beds', // TODO
    custom: false,
    shiftableTo: [],
    copyableTo: [],
    selectableWith: [],
  },
  {
    type: SpotType.CABIN,
    label: i18n.global.t('spotType.cabin'),
    icon: 'spi-cabin',
    custom: false,
    shiftableTo: [SpotType.CABIN],
    copyableTo: [SpotType.CABIN],
    selectableWith: [SpotType.CABIN],
  },
  // custom
  {
    type: SpotType.BOAT,
    label: i18n.global.t('spotType.boat'),
    icon: 'spi-boat',
    custom: true,
    shiftableTo: [SpotType.BOAT],
    copyableTo: [SpotType.BOAT],
    selectableWith: [SpotType.BOAT],
  },
  {
    type: SpotType.PLAYFIELD,
    label: i18n.global.t('spotType.playfield'),
    icon: 'spi-fbasket-ball',
    custom: true,
    shiftableTo: [SpotType.PLAYFIELD],
    copyableTo: [SpotType.PLAYFIELD],
    selectableWith: [SpotType.PLAYFIELD],
  },
];

export interface SpotAttributesPayload {
  free?: boolean;
  temporary?: boolean;
  seasonal?: boolean;
  halfDay?: ReservationHalfDay;
  fullDay?: boolean;
  sharing?: boolean;
  overbooking?: boolean;
  paid?: boolean;
  startDate?: DateTime;
  endDate?: DateTime;
  startDateRef?: DateTime;
  endDateRef?: DateTime;
}

export interface SpotPlayFieldData {
  name: string;
  timeSlot: string;
}
