
import { defineComponent } from 'vue';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { ToastSeverity } from '@/models/toast';
import toastUtil from '@/utils/toastUtil';
import CardDetailsDialog from '@/components/shared/ CardDetailsDialog.vue';
import { License } from '@/models/license';
import { Card, TransactionPayload } from '@/models/card';
import { CardType } from '@/models/cardType';
import cardService from '@/services/cardService';
import { ApiCardListResponse } from '@/models/api';

const CARD_MODEL_DEFAULT = {
  idWorldCard: 0,
  name: '',
  cardType: 0,
  number: '',
  createdAt: 0,
  expirationDate: 0,
  firstName: '',
  lastName: '',
  email: '',
  phoneAreaCode: '',
  phoneNumber: '',
  balance: 0,
  deleted: 0,
  disabled: 0,
  billingData: 0,
  cardTypeName: '',
  cardImage: '',
  exchangeRate: '',
} as unknown as Card;

const TRANSACTIONPAYLOAD_MODEL_DEFAULT = {
  idWorldTransaction: 0,
  emitter: 0,
  paymentMethod: 0,
  amount: 0,
  appliedPrice: 0,
  idPrinter: 0,
} as unknown as TransactionPayload;

export default defineComponent({
  components: { CardDetailsDialog },
  data() {
    return {
      loading: true,
      loadingTransaction: true,
      selectedPrinter: null,
      selectedProduct1: null,
      cardsDialog: false,
      cardsDetailsDialog: false,
      rechargeCardDialog: false,
      isCardEdit: false,
      isDisabled: false,
      isCardType: false,
      creditInput: 0,
      cards: [] as Array<Card>,
      // transactions: [] as Array<Transaction>,
      // cardTransactions: [] as Array<TransactionPayload>,
      // printers: [] as Array<Printer>,
      cardsViewTable: [
        this.$t('cardsView.tableNumber'),
        this.$t('cardsView.tableType'),
        this.$t('cardsView.tableFullName'),
        this.$t('cardsView.tableEmail'),
        this.$t('cardsView.tableBalance'),
      ],
      // transactionTable: [
      //   this.$t('cardsView.transactionsDate'),
      //   this.$t('cardsView.transactionsType'),
      //   this.$t('cardsView.transactionsTotal'),
      // ],
      // paymentMethod: [
      //   { name: this.$t('cardsView.cash'), code: PaymentMethod.CASH },
      //   { name: this.$t('cardsView.credit'), code: PaymentMethod.CREDIT_CARD },
      // ] as Array<ListElementNumber>,
      card: cloneDeep(CARD_MODEL_DEFAULT),
      transactionPayload: cloneDeep(TRANSACTIONPAYLOAD_MODEL_DEFAULT),
      mockedOptions: [
        {
          label: '',
          value: 0,
        },
      ],
    };
  },
  computed: {
    license(): License | null {
      return this.$store.getters['session/license'];
    },
  },
  methods: {
    async submit() {
      const payload = {
        cardType: this.card.cardType,
        number: this.card.number,
        firstName: this.card.firstName,
        lastName: this.card.lastName,
        email: this.card.email,
        phoneNumber: this.card.phoneNumber,
      } as Card;
      try {
        const card: Card = await cardService.create(payload);
        this.cards.push(card);
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
        this.card = cloneDeep(CARD_MODEL_DEFAULT);
        this.cardsDialog = false;
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
    async edit() {
      const payload = {
        cardType: this.card.cardType,
        number: this.card.number,
        firstName: this.card.firstName,
        lastName: this.card.lastName,
        email: this.card.email,
        phoneNumber: this.card.phoneNumber,
      } as Card;
      try {
        const card: Card = await cardService.update(
          this.card.idWorldCard,
          payload,
        );
        const idres = this.cards.findIndex(
          (c: Card) => c.idWorldCard === this.card.idWorldCard,
        );
        this.cards[idres] = card;
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
        this.card = cloneDeep(CARD_MODEL_DEFAULT);
        this.cardsDetailsDialog = false;
        this.cardsDialog = false;
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
    async destroy() {
      this.cards = this.cards.filter(
        (card: Card) => card.idWorldCard !== this.card.idWorldCard,
      );
    },
    async onRowSelect(event: any): Promise<void> {
      this.onCardOpen(event.data);
    },
    async onCardOpen(card: Card): Promise<void> {
      this.card = cloneDeep(card);
      this.cardsDetailsDialog = true;
    },
    async onUpdateCard(card: Card) {
      const index = this.cards.findIndex(
        (c: Card) => c.idWorldCard === card.idWorldCard,
      );
      this.cards[index] = card;
    },
    cardCreate(): void {
      this.cardsDialog = true;
      this.isCardEdit = false;
      this.isDisabled = false;
      this.card.cardType = this.mockedOptions[0].value;
    },
    cardEdit() {
      this.cardsDialog = true;
      this.isCardEdit = true;
      this.isDisabled = true;
    },
    undo() {
      this.cardsDialog = false;
    },
    undoTransaction() {
      this.rechargeCardDialog = false;
    },
    getCardImageSource(card: Card) {
      if (!card.cardImage) {
        return require('@/assets/images/placeholders/card.svg');
      }
      return this.cardsUrlControl(card.cardImage);
    },
    cardsUrlControl(url: string): string {
      const check = url.includes('http');
      let result = url;
      if (!check) {
        result = `https://www.anm22.it/app/pay/utilities/img/cards/${url}`;
      }
      return result;
    },
  },
  async beforeMount() {
    try {
      const res: ApiCardListResponse = await cardService.list();
      this.mockedOptions = res.result.cardTypes.map((cardType: CardType) => ({
        label: cardType.name,
        value: cardType.idWorldCardType,
      }));
      this.cards = res.result.cards;
      this.loading = false;
      if (this.$route.params.idWorldCard) {
        this.onCardOpen(
          this.cards.find(
            (c) => c.idWorldCard === Number(this.$route.params.idWorldCard),
          ) as Card,
        );
      }
      if (this.mockedOptions[0].value === 0) {
        this.isCardType = false;
      } else {
        this.isCardType = true;
      }
    } catch (error) {
      // console.log(error);
    }
  },
});
