
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import { mapState, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import { PanzoomObject } from '@panzoom/panzoom';
import Map from '@/components/shared/Map.vue';
import MapDecoration from '@/components/shared/MapDecoration.vue';
import {
  MapBackground,
  MapMode,
  MapShiftMode,
  MapShiftStep,
  MapType,
  MapView,
  MapSpot as MapSpotI,
  MapDecoration as MapDecorationI,
} from '@/models/map';
import {
  ApiMapDataPayload,
  ApiSmartSearchPayload,
  ApiReservationCreatePayload,
} from '@/models/api';
import cookieUtil from '@/utils/cookieUtil';
import { SPIAGGE_COOKIES } from '@/models/cookies';
import MapRect from '@/components/map/MapRect.vue';
import MapPlanner from '@/components/map/MapPlanner.vue';
import MapViews from '@/components/map/MapViews.vue';
import MapModes from '@/components/map/MapModes.vue';
import MapZoom from '@/components/map/MapZoom.vue';
import MapCalendar from '@/components/map/MapCalendar.vue';
import MapLegend from '@/components/map/MapLegend.vue';
import MapSelection from '@/components/map/MapSelection.vue';
import MapTooltips from '@/components/map/MapTooltips.vue';
import MapShift from '@/components/map/MapShift.vue';
import MapBedsArea from '@/components/map/MapBedsArea.vue';
import MapSmartSearch from '@/components/map/MapSmartSearch.vue';
import MapSpot from '@/components/map/MapSpot.vue';
import MapPrint from '@/components/map/MapPrint.vue';
import MapRefundConfirmation from '@/components/map/MapRefundConfirmation.vue';
import CabinsDialog from '@/components/shared/CabinsDialog.vue';
import { Spot, SpotType } from '@/models/spot';
import { AppAction } from '@/models/app';
import { Reservation } from '@/models/reservation';
import { calculateMapRects, MapRectInterface } from '@/utils/mapRectsUtils';
import reservationService from '@/services/reservationService';
import MapTicketWarehouse from '@/components/map/MapTicketWarehouse.vue';

export default defineComponent({
  name: 'MapView',
  components: {
    MapTicketWarehouse,
    Map,
    MapViews,
    MapModes,
    MapZoom,
    MapCalendar,
    MapLegend,
    MapSelection,
    MapTooltips,
    MapShift,
    MapBedsArea,
    MapSmartSearch,
    MapPlanner,
    MapDecoration,
    MapSpot,
    MapPrint,
    MapRect,
    MapRefundConfirmation,
    CabinsDialog,
  },
  data() {
    return {
      draw: false,
      mapRects: [] as MapRectInterface[],
      background: null as unknown as MapBackground,
      cellHeight: 40,
      type: MapType.STANDARD,
      width: 0,
      height: 0,
      bedsArea: '',
      updateMapPlannerDebounce: debounce(
        this.updateMapPlanner as (spot: MapSpotI) => void,
        250,
      ),
      refreshInterval: setInterval(this.refresh, 300000), // 5m
      showCabinsDialog: false,
    };
  },
  beforeMount() {
    this.mapInit();
  },
  methods: {
    async mapInit(): Promise<void> {
      try {
        /* SET DEFAULT MAP VIEW */
        const view = Object.values(MapView)
          .filter((view: MapView) => view !== MapView.PLANNER)
          .includes(this.license.beachDefaultView)
          ? this.license.beachDefaultView
          : MapView.FULL;
        this.$store.commit('map/setView', view);

        /* QS PARAMS */
        const params = this.$route.query;

        /* BUILD RESERVATION ADDITIONAL PAYLOAD */
        this.$store.dispatch('map/buildReservationPayloadFromQuery', params);

        /* QS PARAMS */
        const { spotName, spotType } = params;
        let startDate = Number(params.startDate) || Number(params.from);
        let endDate = Number(params.endDate) || Number(params.to);
        const mode = Number(params.mode);
        const reservationId = Number(params.reservationId);

        /* SMART SEARCH */
        if (this.isRunning) {
          startDate = this.smartSearchModel.from.toSeconds();
          endDate = this.smartSearchModel.to.toSeconds();
        }

        // Map dates cookies
        const cookieStartDate = cookieUtil.get(SPIAGGE_COOKIES.MAP_DATE_FROM);
        const cookieEndDate = cookieUtil.get(SPIAGGE_COOKIES.MAP_DATE_TO);

        if (startDate) {
          this.$store.commit('map/setCalendar', [
            DateTime.fromSeconds(startDate),
            DateTime.fromSeconds(endDate ?? startDate),
          ]);
        } else if (cookieStartDate) {
          const from = DateTime.fromSeconds(Number(cookieStartDate));
          const to = DateTime.fromSeconds(Number(cookieEndDate));
          if (from && from.isValid) {
            const calendar = [from, from];
            if (to && to.isValid) {
              calendar[1] = to;
            }
            this.$store.commit('map/setCalendar', calendar);
          }
        }
        const calendar = this.$store.getters['map/calendar'];

        /* MAP PAYLOAD */
        const payload = {} as ApiMapDataPayload;
        payload.from = calendar[0].toSeconds();
        if (calendar[1] && calendar[1].toMillis() !== calendar[0].toMillis()) {
          payload.to = calendar[1].toSeconds();
        }
        if (reservationId) {
          payload.reservationId = reservationId;
        }

        /* MAP DATA */
        await this.$store.dispatch('map/setData', payload);
        if (this.data) {
          // add an offset to avoid the map to be cut off
          const RIGHT_OFFSET = 2;
          this.width = this.data.width + RIGHT_OFFSET;
          this.height = this.data.height;
          if (this.data.background) {
            this.background = this.data.background;
          }
        }

        /* MAP SPOT */
        const spot = this.$store.getters['map/data'].elements.spots.find(
          (el: MapSpotI) => el.name === spotName && el.type === spotType,
        );

        /* MAP MODE */
        if (mode) {
          this.$store.commit('map/setMode', Number(mode));
          if (mode === MapMode.SHIFT) {
            this.$store.commit('map/setShiftFrom', spot);
            this.$store.commit('map/setShiftStep', MapShiftStep.SELECT_MODE);
          }
          if (mode === MapMode.PROGRAMMED_SHIFT) {
            this.$store.commit('map/setShiftFrom', spot);
            this.$store.commit('map/setShiftMode', MapShiftMode.CUSTOM);
            this.$store.commit('map/setShiftRange', [startDate, endDate]);
            this.$store.commit('map/setShiftStep', MapShiftStep.SELECT_TO);
          }
          if (mode === MapMode.COPY) {
            this.$store.commit('map/setSelected', []);
          }
        }

        /* MAP SMART SEARCH */
        if (this.smartSearchModel?.smartSearch) {
          const sectors: string = this.smartSearchModel.sectors.join(',');

          const payload = {} as ApiSmartSearchPayload;
          payload.from = calendar[0].toSeconds();
          payload.to = calendar[1].toSeconds();
          payload.sectors = sectors as string;
          payload.maxSwaps = this.smartSearchModel.maxSwaps;
          payload.halfDay = this.smartSearchModel.halfDay;

          await this.$store.dispatch('smartSearch/runSmartSearch', payload);
        }

        /* DRAW */
        this.draw = true;
      } catch (e) {
        this.$spiagge.toast.error(this.$t('mapView.toast.retrievalError'));
      }
    },
    setMapRects(): void {
      this.mapRects = calculateMapRects({
        mapLazyLoadingEnabled: !!this.license.mapLazyLoadingEnabled,
        width: this.width,
        height: this.height,
        cellWidth: this.cellWidth,
        cellHeight: this.cellHeight,
        containerWidth: this.containerWidth,
        containerHeight: this.containerHeight,
        background: this.background,
        seaHeight: 100,
        innerSpacer: true,
      });
    },
    onBedsArea(spotName: string): void {
      /**
       * Set beds area spot name on click
       */
      this.bedsArea = spotName;
    },
    onCabinsDialog(): void {
      /**
       * Open cabins dialog
       */
      this.showCabinsDialog = true;
    },
    async onSelectCabin(cabin: Spot): Promise<void> {
      /**
       * Create reservation on cabin select
       */
      try {
        const reservation: Reservation = await reservationService.createOne({
          spotType: SpotType.CABIN,
          spotName: cabin.name,
          startDate: this.calendar[0].toSeconds(),
          endDate: this.calendar[1].toSeconds(),
        } as ApiReservationCreatePayload);
        this.$spiagge.toast.success(
          this.$t('mapView.toast.cabinReservationCreateSuccess'),
        );
        this.$store.commit('app/setAction', AppAction.CREATE_RESERVATION);
        this.$router.push(`/reservation/${reservation.id}`);
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('mapView.toast.cabinReservationCreateError'),
        );
      }
    },
    onPanzoomReady(): void {
      if (this.mapRects.length) {
        return;
      }

      this.setMapRects();
    },
    updateMapPlanner(spot: MapSpotI): void {
      /**
       * Update map planner data if active
       */
      if (!this.$store.getters['map/planner'].active) return;
      this.$store.dispatch('map/setPlanner', {
        spot,
        from: this.calendar[0].toSeconds(),
        to: this.calendar[1].toSeconds(),
      });
    },
    refresh(): void {
      /**
       * Refresh map data (only if no operation is running)
       */
      if (this.mode === MapMode.NONE) {
        this.$store.dispatch('map/refresh');
      }
    },
  },
  computed: {
    ...mapState('map', [
      'view',
      'data',
      'calendar',
      'mode',
      'refundConfirmation',
    ]),
    ...mapState('app', [
      'windowWidth',
      'windowHeight',
      'breakpoints',
      'reservationPayload',
    ]),
    ...mapState('session', ['license', 'permissions']),
    ...mapState('smartSearch', { smartSearchModel: 'model' }),
    ...mapGetters('smartSearch', ['isRunning']),
    cellWidth(): number {
      /**
       * Map cell width
       */
      return this.license.forceRectangularMapSpotsEnabled ||
        this.view === MapView.FULL
        ? 60
        : 40;
    },
    containerWidth(): number {
      /**
       * Map container width
       */
      return this.breakpoints.desktop
        ? this.windowWidth - 80
        : this.windowWidth;
    },
    containerHeight(): number {
      /**
       * Map container height
       */
      return this.windowHeight - 64;
    },
    spots(): Array<MapSpotI> {
      /**
       * Map spots
       */
      return this.data.elements.spots || [];
    },
    decorations(): Array<MapDecorationI> {
      /**
       * Map decorations
       */
      return this.data.elements.decorations || [];
    },
  },
  beforeUnmount() {
    /**
     * Reset map store module and detach the refresh interval
     */
    this.$store.commit('map/reset');
    clearInterval(this.refreshInterval);
    this.updateMapPlannerDebounce.cancel();
  },
  beforeRouteLeave(to, from, next) {
    /**
     * Reset smart search if it is running when leaving map page
     */
    if (this.isRunning) {
      this.$store.commit('smartSearch/reset');
    }
    /**
     * If navigating to reservation page then save return route
     */
    if (to.name === 'ReservationEdit') {
      this.$store.commit('app/setReturnToRoute', this.$route.path);
    }
    next();
  },
  beforeRouteUpdate() {
    /**
     * Re-init map on route update (see TopBarNewReservation:onSearch)
     */
    this.mapInit();
  },
});
