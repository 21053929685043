
import { defineComponent, PropType } from 'vue';
import { mapState } from 'vuex';
import { PriceList } from '@/models/priceList';
import priceListService from '@/services/priceListService';
import { DropdownOption } from '@/models';

export default defineComponent({
  name: 'PriceListsPriceListSwitch',
  emits: ['close', 'switch'],
  props: {
    priceList: {
      type: Object as PropType<PriceList>,
      required: true,
    },
  },
  data() {
    return {
      selectedPriceListId: null as unknown as number,
      priceListsOptions: [] as Array<DropdownOption>,
    };
  },
  beforeMount(): void {
    /**
     * Load options
     */
    this.priceListsOptions = [
      ...[
        {
          value: 0,
          label: this.$t('priceListsPriceListSwitch.noPriceList'),
        },
      ],
      ...this.priceLists
        .filter((priceList: PriceList) => priceList.id !== this.priceList.id)
        .map((priceList: PriceList) => ({
          value: priceList.id,
          label: priceList.name,
        })),
    ];
  },
  methods: {
    onSwitch(): void {
      /**
       * Switch price list (confirmation)
       */
      this.$confirm.require({
        message: this.$t('priceListsPriceListSwitch.confirm.message'),
        header: this.$t('priceListsPriceListSwitch.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('common.yes'),
        accept: () => {
          this.switch();
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          // nothing
        },
      });
    },
    async switch(): Promise<void> {
      /**
       * Switch price list
       */
      try {
        await priceListService.switch(this.priceList.id, {
          targetId: this.selectedPriceListId as number,
        });
        this.$spiagge.toast.success(
          this.$t('priceListsPriceListSwitch.toast.success'),
        );
        this.$emit('switch');
        this.close();
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('priceListsPriceListSwitch.toast.error'),
        );
      }
    },
    close(): void {
      /**
       * Close
       */
      this.visible = false;
    },
  },
  computed: {
    ...mapState('priceLists', ['priceLists']),
    visible: {
      get(): boolean {
        return true;
      },
      set(): void {
        this.$emit('close');
      },
    },
  },
});
