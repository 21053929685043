/* eslint-disable import/no-cycle */
/**
 * Api service, an api list for each service
 */
import has from 'lodash/has';
import {
  ApiCashFlowFindPayload,
  ApiCashFlowGetPayload,
  ApiCustomerIndexRequestPayload,
  ApiLogFindPayload,
  ApiMapDataPayload,
  ApiMapPlannerPayload,
  ApiPlannerDataPayload,
  ApiPriceListFindPayload,
  ApiReservationCreateDocumentPayload,
  ApiReservationDeleteDocumentPayload,
  ApiReservationDeletePayload,
  ApiReservationFindPayload,
  ApiReservationGetDocumentsPayload,
  ApiSpotFindPayload,
  ApiSectorListPayload,
} from '@/models/api';
import { ReservationExpandedField } from '@/models/reservation';
import store from '@/store';
import apiUtil from '@/utils/apiUtil';
import { FilterByItem, OrderByItem } from '@/models';
import { PriceListType } from '@/models/priceList';

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;
const API_VERSION = process.env.VUE_APP_API_VERSION;

export default {
  auth: {
    signInUrl(): string {
      return `${API_BASE_URL}/${API_VERSION}/users/me`;
    },
  },
  license: {
    list(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/me`;
    },
    one(id: string): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${id}?expand=beach,hasGroup,receiptPrintCustomerName,featureFlags`;
    },
    update(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}`;
    },
    logo(id: string): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${id}/logo`;
    },
    sectorImage(id: string): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${id}/upload-sector-image`;
    },
    mapBg(id: string): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${id}/mapBg`;
    },
    documentTemplates(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/documentTemplates`;
    },
    rules(id: string): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${id}/booking-rules`;
    },
    rule(id: string, ruleId: string): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${id}/booking-rules/${ruleId}`;
    },
    stripe(id: string): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${id}/stripe/init`;
    },
    stripePosAccountInitConnection(id: string): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${id}/stripe/init-pos-account`;
    },
    finalize(id: string): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${id}/stripe/finalize`;
    },
    stripePosAccountFinalizeConnection(id: string): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${id}/stripe/finalize-pos-account`;
    },
    backupJson(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/export-json`;
    },
    importJson(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/import-json`;
    },
  },
  voucher: {
    get(voucherId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/vouchers/${voucherId}`;
    },
    list(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/vouchers`;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/vouchers`;
    },
    update(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/vouchers/${id.toString()}`;
    },
    delete(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/vouchers/${id.toString()}`;
    },
  },
  printer: {
    list(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/printers`;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/printers`;
    },
    delete(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/printers/${id}`;
    },
    update(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/printers/${id}`;
    },
  },
  cardtype: {
    list(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/cardTypes`;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/cardTypes`;
    },
    update(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/cardTypes/${id.toString()}`;
    },
  },
  card: {
    list(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/cards`;
    },
    listByEmail(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/cards/find-by-email`;
    },
    details(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/cards/${id.toString()}`;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/cards`;
    },
    createTransaction(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/cards/${id.toString()}/recharge`;
    },
    update(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/cards/${id.toString()}`;
    },
    delete(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/cards/${id.toString()}`;
    },
    pay(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/cards/${id.toString()}/pay`;
    },
    cashback(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/cards/${id.toString()}/cashback`;
    },
  },
  admin: {
    list(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/admins`;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/admins`;
    },
    update(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/admins/${id.toString()}`;
    },
    delete(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/admins/${id.toString()}`;
    },
  },
  work: {
    list(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/works`;
    },
  },
  apiKey: {
    list(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/api-keys`;
    },
  },
  database: {
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}`;
    },
    showAll(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/reservations?deleted=1&page=0&offset=10`;
    },
    deleteReservationsGroup(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/reservations/group-delete`;
    },
    recycleBin(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/recycleBin`;
    },
  },
  service: {
    list(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/services`;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/services`;
    },
    update(serviceId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/services/${serviceId.toString()}`;
    },
    delete(serviceId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/services/${serviceId.toString()}`;
    },
    availability(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/services/availability`;
    },
  },
  serviceGroup: {
    list(includeServiceCount: boolean): string {
      let result = `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/service-groups`;
      if (includeServiceCount) {
        result += '?includeServiceCount=1';
      }
      return result;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/service-groups`;
    },
    update(serviceGroupId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/service-groups/${serviceGroupId.toString()}`;
    },
    delete(serviceGroupId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/service-groups/${serviceGroupId.toString()}`;
    },
  },
  offers: {
    list(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/offers`;
    },
    get(offerId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/offers/${offerId}`;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/offers`;
    },
    update(offerId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/offers/${offerId}`;
    },
    delete(offerId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/offers/${offerId}`;
    },
  },
  discountCodes: {
    list(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/discount-codes`;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/discount-codes`;
    },
    delete(discountCodeId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/discount-codes/${discountCodeId}`;
    },
    update(discountCodeId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/discount-codes/${discountCodeId}`;
    },
  },
  search: {
    search(query: string): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/search?query=${query}`;
    },
    searchLicenses(query: string): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/search?query=${query}`;
    },
  },
  smartSearch: {
    smartSearch(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/smart-search`;
    },
  },
  customer: {
    list(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/customers/all`;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/customers`;
    },
    one(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/customers/${id}`;
    },
    update(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/customers/${id}`;
    },
    export(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/customers/export-customers`;
    },
    import(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/customers/import-customers`;
    },
    customerTags(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/customers/${id}/tags`;
    },
  },
  map: {
    data(payload: ApiMapDataPayload): string {
      let url = `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/map?from=${payload.from}&next=1`;
      if (payload.to) {
        url += `&to=${payload.to}`;
      }
      if (payload.reservationId) {
        url += `&reservationId=${payload.reservationId}`;
      }
      return url;
    },
    planner(payload: ApiMapPlannerPayload): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/map-spot-preview/${payload.spot.type}/${payload.spot.name}?from=${payload.from}&to=${payload.to}`;
    },
  },
  mapEditor: {
    elements(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/spots`;
    },
    uploadImage(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/spots/upload-image`;
    },
    setAllOnline(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/map/all-online`;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/spots`;
    },
    update(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/spots/${id.toString()}`;
    },
    delete(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/spots/${id.toString()}`;
    },
    editGrid(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/map/edit-grid`;
    },
  },
  planner: {
    data(payload: ApiPlannerDataPayload): string {
      const url = `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/planner?year=${payload.year}&sectorIds=${payload.sectorIds.join(',')}`;
      return url;
    },
  },
  reservation: {
    one(id: number, expand: Array<ReservationExpandedField>): string {
      const expandedFields = expand.join(',');
      let url = `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}`;
      if (expandedFields) {
        url += `?expand=${expandedFields}`;
      }
      return url;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/reservations`;
    },
    update(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}`;
    },
    delete(payload: ApiReservationDeletePayload): string {
      let url = `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${payload.id.toString()}?mode=${payload.mode}&refundType=${
        payload.refundType
      }`;
      if (payload.refundAmount) {
        url += `&refundAmount=${payload.refundAmount.toString()}`;
      }
      if (payload.creating) {
        url += `&creating=${payload.creating ? 1 : 0}`;
      }
      return url;
    },
    seasonal(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/seasonal`;
    },
    find(payload: ApiReservationFindPayload): string {
      // TODO horrible, make an util to build query string
      let url = `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/reservations?`;
      if (payload.spotName) {
        url += `spotName=${payload.spotName}&`;
      }
      if (payload.spotType) {
        url += `spotType=${payload.spotType}&`;
      }
      if (payload.fields) {
        url += `fields=${payload.fields.join(',')}&`;
      }
      if (payload.type) {
        url += `type=${payload.type.join(',')}&`;
      }
      if (payload.startDate) {
        url += `startDate=${payload.startDate.toString()}&`;
      }
      if (payload.endDate) {
        url += `endDate=${payload.endDate.toString()}&`;
      }
      if (has(payload, 'page')) {
        url += `page=${payload.page}&`;
      }
      if (has(payload, 'deleted')) {
        const stringDeleted = payload.deleted ? '1' : '0';
        url += `deleted=${stringDeleted}&`;
      }
      if (has(payload, 'online')) {
        const stringOnline = payload.online ? '1' : '0';
        url += `online=${stringOnline}&`;
      }
      if (has(payload, 'contactId')) {
        url += `contactId=${payload.contactId}&`;
      }
      if (has(payload, 'firstName')) {
        url += `firstName=${payload.firstName}&`;
      }
      if (has(payload, 'lastName')) {
        url += `lastName=${payload.lastName}&`;
      }
      if (has(payload, 'email')) {
        url += `email=${payload.email}&`;
      }
      if (has(payload, 'phoneNumber')) {
        url += `phoneNumber=${payload.phoneNumber}&`;
      }
      if (has(payload, 'offset')) {
        url += `offset=${payload.offset}&`;
      }
      if (payload.orderBy) {
        url += `orderBy=${apiUtil.buildOrderBy(payload.orderBy)}`;
      }
      return url;
    },
    createAbsence(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/absences`;
    },
    getServices(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/services`;
    },
    updateService(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/services`;
    },
    getGuests(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/contacts`;
    },
    createGuest(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/contacts`;
    },
    removeGuest(id: number, guestId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/contacts/${guestId.toString()}`;
    },
    createAddition(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/additions`;
    },
    move(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/move`;
    },
    createStep(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/steps`;
    },
    updateStep(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}`;
    },
    createReservationWithSteps(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/reservations/steps`;
    },
    deleteStep(id: number, stepId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/steps/${stepId}`;
    },
    copy(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/copy`;
    },
    orderSummary(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/send-summary`;
    },
    paymentLinkFeesPreview(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/preview-payment-link`;
    },
    merge(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/reservations/merge`;
    },
    getExpenses(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/expenses`;
    },
    deleteExpense(id: number, idExpense: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/expenses/${idExpense.toString()}`;
    },
    payExpense(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/expenses/pay`;
    },
    recycleBin(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/recycleBin`;
    },
    getDocuments(payload: ApiReservationGetDocumentsPayload): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${payload.id.toString()}/documentTemplates/${payload.templateId.toString()}/documents`;
    },
    createDocument(payload: ApiReservationCreateDocumentPayload): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${payload.id.toString()}/documentTemplates/${payload.templateId.toString()}/documents`;
    },
    deleteDocument(payload: ApiReservationDeleteDocumentPayload): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${payload.id.toString()}/documentTemplates/${payload.templateId.toString()}/documents/${payload.documentId.toString()}`;
    },
    restore(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/restore`;
    },
    sendInvoice(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/invoices`;
    },
    copySeasonal(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/seasonal-copy`;
    },
    fiscalReservationFlows(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/reservations/${id}/fiscal-cash-flows`;
    },
    disjoinAccount(idJointAccount: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${idJointAccount.toString()}/disjoin`;
    },
    discountCode(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/discount-code`;
    },
    siaeTickets(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/siae-tickets`;
    },
    checkIn(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/check-in`;
    },
    checkOut(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/reservations/${id.toString()}/check-out`;
    },
    reservationsContacts(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/reservations`;
    },
    mergeReservations(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/reservations/merge`;
    },
    createBillReservation(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/reservations/bill`;
    },
  },
  contact: {
    hint(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/contacts`;
    },
  },
  cashFlow: {
    get(payload: ApiCashFlowGetPayload): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/cash-flows/${payload.reservationId}`;
    },
    find(payload: ApiCashFlowFindPayload): string {
      let url = `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/cash-flows?`;
      const params = [];
      if (payload.reservationId) {
        params.push(`reservationId=${payload.reservationId}`);
      }
      if (payload.receiptId || payload.receiptId === 0) {
        params.push(`receiptId=${payload.receiptId}`);
      }
      if (payload.invoiceId || payload.invoiceId === 0) {
        params.push(`invoiceId=${payload.invoiceId}`);
      }
      if (payload.methods) {
        params.push(`methods=${payload.methods}`);
      }
      if (payload.expand) {
        params.push(`expand=${payload.expand}`);
      }
      if (payload.dateFrom) {
        params.push(`dateFrom=${payload.dateFrom}`);
      }
      if (payload.discardInvoices) {
        params.push(`discardInvoices=${Number(payload.discardInvoices)}`);
      }
      if (payload.orderBy) {
        params.push(`orderBy=${apiUtil.buildOrderBy(payload.orderBy)}`);
      }
      url += params.join('&');
      return url;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/cash-flows`;
    },
    update(cashFlowId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/cash-flows/${cashFlowId}`;
    },
  },
  cashDesk: {
    store(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/cash-desk`;
    },
  },
  spot: {
    find(payload: ApiSpotFindPayload): string {
      let url = `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/spots?`;
      if (payload.spotName) {
        url += `spotName=${payload.spotName}&`;
      }
      if (payload.type) {
        url += `type=${payload.type}&`;
      }
      if (payload.startDate) {
        url += `startDate=${payload.startDate}&`;
      }
      if (payload.endDate) {
        url += `endDate=${payload.endDate}&`;
      }
      if (has(payload, 'page')) {
        url += `page=${payload.page}&`;
      }
      if (has(payload, 'offset')) {
        url += `offset=${payload.offset}&`;
      }
      if (payload.orderBy) {
        url += `orderBy=${apiUtil.buildOrderBy(payload.orderBy)}`;
      }
      return url;
    },
  },
  log: {
    find(payload: ApiLogFindPayload): string {
      let url = `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/logs?`;
      if (payload.reservationId) {
        url += `reservationId=${payload.reservationId}&`;
      }
      if (payload.userId) {
        url += `userId=${payload.userId}&`;
      }
      if (payload.action && payload.action.length > 0) {
        url += `action=${(payload.action as unknown as Array<string>).join(
          ',',
        )}&`;
      }
      if (payload.orderBy) {
        url += `orderBy=${apiUtil.buildOrderBy(payload.orderBy)}&`;
      }
      if (has(payload, 'page')) {
        url += `page=${payload.page}&`;
      }
      if (has(payload, 'offset')) {
        url += `offset=${payload.offset}&`;
      }
      return url;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/logs`;
    },
  },
  statistic: {
    dashboard(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/dashboard`;
    },
    arrivalsToday(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/reservations-filter`;
    },
    departuresToday(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/reservations-filter`;
    },
    arrivalsTomorrow(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/reservations-filter`;
    },
    departuresTomorrow(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/reservations-filter`;
    },
    allReservations(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/reservations-filter`;
    },
    attendanceSummary(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/reservations-filter`;
    },
    seasonalSummary(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/reservations-filter`;
    },
    unpaidReservationsSummary(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/reservations-filter`;
    },
    additionsSummary(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/reservations-filter`;
    },
    billSummary(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/reservations-filter`;
    },
    onlineReservations(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/reservations-filter`;
    },
    stripePayments(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/stripe-payments`;
    },
    stripePaymentsV2(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/stripe-payments-v2`;
    },
    stripeTransfers(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/stripe-transfers`;
    },
    logDiscounts(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/logs-discount`;
    },
    logCancellations(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/logs-deleted`;
    },
    cashFlowUsers(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/cash-flow-users`;
    },
    cashFlows(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/cash-flows`;
    },
    countersHotel(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/hotels`;
    },
    countersVoucher(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/vouchers`;
    },
    countersHotelDetail(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/hotels/reservations`;
    },
    countersVoucherDetail(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/vouchers/reservations`;
    },
    exportReservations(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/export-reservations`;
    },
    exportCountersHotel(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/hotels/export-reservations`;
    },
    exportStripePayments(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/export-stripe-payments`;
    },
    exportStripePaymentsV2(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/export-stripe-payments-v2`;
    },
    exportCountersVoucher(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/vouchers/export-reservations`;
    },
    exportStripeTransfers(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/export-stripe-transfers`;
    },
    extraServices(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/reservation-services`;
    },
    extraService(serviceId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/reservation-services/${serviceId}`;
    },
    exportExtraService(serviceId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/export-reservation-services/${serviceId}`;
    },
    sectors(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/sectors`;
    },
    sectorSpots(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/sector-spots`;
    },
    exportSectorSpots(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/export-sector-spots`;
    },
    exportDashboard(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/export-dashboard`;
    },
    customer(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/customer`;
    },
    duration(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/duration`;
    },
    spot(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/spot-type`;
    },
    exportSpotAnalytics(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/export-spot-type`;
    },
    stripeTransferInvoice(preinvoiceId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/statistics/stripe-transfer-invoice/${preinvoiceId}`;
    },
  },
  priceList: {
    get(priceListId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-lists/${priceListId}`;
    },
    list(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-lists`;
    },
    find(payload: ApiPriceListFindPayload): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${
        store.getters['session/license'].license
      }/price-lists${apiUtil.buildQueryString(payload)}`;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-lists`;
    },
    update(priceListId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-lists/${priceListId}`;
    },
    switch(priceListId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-lists/${priceListId}/switch`;
    },
    delete(priceListId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-lists/${priceListId}`;
    },
    getPeriodCombinations(pricePeriodId: number, weekend: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-combinations?pricePeriodId=${pricePeriodId}&weekend=${weekend}`;
    },
    createPeriod(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-periods`;
    },
    updatePeriod(pricePeriodId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-periods/${pricePeriodId}`;
    },
    deletePeriod(pricePeriodId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-periods/${pricePeriodId}`;
    },
    importPeriod(pricePeriodId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-periods/${pricePeriodId}/import`;
    },
    copyPeriod(pricePeriodId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-periods/${pricePeriodId}/copy`;
    },
    exportPeriod(pricePeriodId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-periods/${pricePeriodId}/export`;
    },
    deleteCombinations(pricePeriodId: number, weekend: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-periods/${pricePeriodId}/listing?weekend=${weekend}`;
    },
    qualityCheck(priceListId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-lists/${priceListId}/quality-check`;
    },
    estimate(priceListId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-lists/${priceListId}/estimate`;
    },
  },
  priceConfig: {
    wizard(priceConfigId?: number, priceListType?: PriceListType): string {
      let url = `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-configs/wizard`;
      if (priceConfigId) {
        url += `/${priceConfigId}`;
      } else if (priceListType) {
        url += `?priceListType=${priceListType}`;
      }
      return url;
    },
    get(priceConfigId?: number): string {
      let url = `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-configs`;
      if (priceConfigId) {
        url += `/${priceConfigId}`;
      }
      return url;
    },
    list(): string {
      const url = `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-configs`;
      return url;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-configs`;
    },
    update(priceConfigId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-configs/${priceConfigId}`;
    },
    delete(priceConfigId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-configs/${priceConfigId}`;
    },
    save(priceConfigId?: number): string {
      let url = `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-configs/save`;
      if (priceConfigId) {
        url += `/${priceConfigId}`;
      }
      return url;
    },
    generate(priceConfigId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-configs/${priceConfigId}/generate`;
    },
    takeOwnership(priceConfigId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-configs/${priceConfigId}/take-ownership`;
    },
    edit(priceConfigId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-configs/${priceConfigId}/edit`;
    },
    clone(priceConfigId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/price-configs/${priceConfigId}/clone`;
    },
  },
  warehouse: {
    list(date: number | null): string {
      let url = `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/warehouses`;
      if (date) {
        url += `?date=${date}`;
      }
      return url;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/warehouses`;
    },
    update(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/warehouses/${id}`;
    },
    delete(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/warehouses/${id}`;
    },
    alert(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/warehouses/alert`;
    },
  },
  checkoutPoint: {
    list(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/checkout-points`;
    },
  },
  revenueManagement: {
    prices(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/revenue-management/prices`;
    },
    slotDetail(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/revenue-management/slot-detail`;
    },
    periods(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/revenue-management/periods`;
    },
    ruleDetails(from: number, to: number): string {
      const searchParams = new URLSearchParams({
        from: String(from),
        to: String(to),
      });
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/revenue-management/rule-details?${searchParams}`;
    },
    historicalDetails(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/revenue-management/historical-details`;
    },
    priceHints(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/revenue-management/price-hints`;
    },
    handleHint(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/revenue-management/handle-hint`;
    },
    baseRule: {
      list(): string {
        return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/revenue-management/base-rules`;
      },
      create(): string {
        return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/revenue-management/base-rules`;
      },
      update(id: number): string {
        return `${API_BASE_URL}/${API_VERSION}/beaches/${
          store.getters['session/license'].license
        }/revenue-management/base-rules/${id.toString()}`;
      },
      delete(id: number): string {
        return `${API_BASE_URL}/${API_VERSION}/beaches/${
          store.getters['session/license'].license
        }/revenue-management/base-rules/${id.toString()}`;
      },
    },
    overrideRule: {
      list(): string {
        return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/revenue-management/override-rules`;
      },
      create(): string {
        return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/revenue-management/override-rules`;
      },
      update(id: number): string {
        return `${API_BASE_URL}/${API_VERSION}/beaches/${
          store.getters['session/license'].license
        }/revenue-management/override-rules/${id.toString()}`;
      },
      delete(id: number): string {
        return `${API_BASE_URL}/${API_VERSION}/beaches/${
          store.getters['session/license'].license
        }/revenue-management/override-rules/${id.toString()}`;
      },
    },
    priceAlteration: {
      list(): string {
        return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/revenue-management/price-alterations`;
      },
      create(): string {
        return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/revenue-management/price-alterations/create`;
      },
      delete(): string {
        return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/revenue-management/price-alterations/destroy`;
      },
    },
  },
  permissions: {
    list(licenseCode: string): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${licenseCode}/permissions`;
    },
  },
  roles: {
    add(licenseCode: string): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${licenseCode}/permissions/roles`;
    },
    update(licenseCode: string, roleId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${licenseCode}/permissions/roles/${roleId}`;
    },
    list(licenseCode: string): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${licenseCode}/permissions/roles`;
    },
    featuresContractList(licenseCode: string): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${licenseCode}/permissions/contract/features`;
    },
    permissionsRole(licenseCode: string, roleId: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${licenseCode}/permissions/roles/${roleId}`;
    },
  },
  posTerminal: {
    list(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/pos-terminal`;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/pos-terminal`;
    },
    update(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/pos-terminal/${id}`;
    },
    delete(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/pos-terminal/${id}`;
    },
    toggle(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/pos-terminal/${id}/toggle-disabled`;
    },
  },
  stripe: {
    // init - move here from license
    // finalize - move here from license
    createPaymentIntent(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/stripe/payment-intents`;
    },
    getPaymentIntent(paymentIntentId: string): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/stripe/payment-intents/${paymentIntentId}`;
    },
  },
  webtic: {
    onsiteEmitTickets(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/webtic/onsite-emit-tickets`;
    },
    markTicket(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/webtic/mark-barcode`;
    },
    checkTicket(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/webtic/check-barcode`;
    },
  },
  webticTerminal: {
    list(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/webtic-terminal`;
    },
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/webtic-terminal`;
    },
    update(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/webtic-terminal/${id}`;
    },
    delete(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/webtic-terminal/${id}`;
    },
    toggle(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/webtic-terminal/${id}/toggle-disabled`;
    },
  },
  sectors: {
    create(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/sectors`;
    },
    update(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/sectors/${id}`;
    },
    delete(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/sectors/${id}`;
    },
    get(getSectorsPayload: ApiSectorListPayload): string {
      let url = `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/sectors`;

      url += apiUtil.buildQueryString(getSectorsPayload);

      return url;
    },
    updateDetails(id: number): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/sectors/${id}/update-details`;
    },
    uploadImage(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/sectors/upload-sector-image`;
    },
    getPlannerSectors(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/planner-sectors`;
    },
  },
  whiteLabel: {
    linkUserToWhiteLabel(): string {
      return `${API_BASE_URL}/${API_VERSION}/beaches/${store.getters['session/license'].license}/white-label/link-user`;
    },
  },
};
