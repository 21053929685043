import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { novalidate: "" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "col-12 sm:col-6" }
const _hoisted_4 = { class: "p-field price-list-field" }
const _hoisted_5 = { for: "price-list-dropdown" }
const _hoisted_6 = { class: "grid" }
const _hoisted_7 = { class: "col-12 sm:col-6" }
const _hoisted_8 = { class: "p-field start-date-field" }
const _hoisted_9 = { for: "start-date-calendar" }
const _hoisted_10 = { class: "col-12 sm:col-6" }
const _hoisted_11 = { class: "p-field end-date-field" }
const _hoisted_12 = { for: "end-date-calendar" }
const _hoisted_13 = { class: "grid" }
const _hoisted_14 = { class: "col-12" }
const _hoisted_15 = { class: "p-field copy-seasonal-field" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_CalendarInput = _resolveComponent("CalendarInput")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Button = _resolveComponent("Button")!
  const _component_InlineMessage = _resolveComponent("InlineMessage")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    id: "price-lists-seasonal-settings",
    class: "dialog",
    modal: "",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.visible) = $event))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('priceListsSeasonalSettings.title')), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.$t('button.cancel'),
        icon: "spi-times",
        class: "p-button-outlined",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (
          _ctx.$store.commit('priceLists/setShowSeasonalSettingsDialog', false)
        ))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('button.save'),
        icon: "spi-check",
        onClick: _ctx.onSave
      }, null, 8, ["label", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('priceListsSeasonalSettings.seasonalPriceList')), 1),
              _createVNode(_component_Dropdown, {
                id: "price-list-dropdown",
                modelValue: _ctx.model.seasonalPriceListId,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.seasonalPriceListId) = $event)),
                options: _ctx.priceListOptions,
                optionLabel: "label",
                optionValue: "value",
                placeholder: _ctx.$t('priceListsSeasonalSettings.noPriceList')
              }, null, 8, ["modelValue", "options", "placeholder"])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('priceListsSeasonalSettings.seasonalStartDate')), 1),
              _createVNode(_component_CalendarInput, {
                id: "start-date-calendar",
                modelValue: _ctx.model.seasonalStartDate,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.seasonalStartDate) = $event)),
                format: "dd-MM"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('priceListsSeasonalSettings.seasonalEndDate')), 1),
              _createVNode(_component_CalendarInput, {
                id: "end-date-calendar",
                modelValue: _ctx.model.seasonalEndDate,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.seasonalEndDate) = $event)),
                format: "dd-MM"
              }, null, 8, ["modelValue"])
            ])
          ])
        ]),
        _createVNode(_component_Divider),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("label", null, _toDisplayString(_ctx.$t('priceListsSeasonalSettings.copySeasonal')), 1),
              (_ctx.canCopySeasonal)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    label: 
                _ctx.$t('priceListsSeasonalSettings.copySeasonalYear', {
                  copyYear: _ctx.copyYear,
                })
              ,
                    disabled: _ctx.isCopyngSeasonals,
                    onClick: _ctx.onCopySeasonal,
                    class: "p-button-link",
                    icon: "pi pi-copy"
                  }, null, 8, ["label", "disabled", "onClick"]))
                : (_openBlock(), _createBlock(_component_InlineMessage, {
                    key: 1,
                    severity: "success",
                    closable: false
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('priceListsSeasonalSettings.copyMessage')), 1)
                    ]),
                    _: 1
                  }))
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible"]))
}