
import { defineComponent } from 'vue';

import { mountMap } from '@spiaggeit/map-viewer';
import { License } from '@/models/license';

import { MapBackground, MapType } from '@/models/map';

import '@spiaggeit/map-viewer/style.css';
import permissionsUtil from '@/utils/permissionsUtil';
import { FEATURE_PERMISSION_ACTION_CONFIG, FEATURE_PERMISSION_CONFIG } from '@/models/permissions';

export default defineComponent({
  name: 'SettingsMapEditorView',
  computed: {
    license(): License {
      return this.$store.getters['session/license'];
    },
  },
  data() {
    return {
      draw: false,
      type: MapType.EDITOR,
      background: null as unknown as MapBackground,
    };
  },
  mounted() {
    const canAccessSettings = permissionsUtil.isActionPermissionAllowed(
      FEATURE_PERMISSION_CONFIG.settings,
      FEATURE_PERMISSION_ACTION_CONFIG.settings.PAGE_ACCESS,
    );
    // There is no action for map editor access -> fallback on settings page access permission
    if (canAccessSettings) {
      mountMap({
        asset_base_url: process.env.VUE_APP_MAP_ASSET_BASE_URL,
        api_base_url: `${process.env.VUE_APP_API_BASE_URL}/${process.env.VUE_APP_API_VERSION}`,
        license: this.license.license,
        locale: this.$store.getters['session/locale']?.id ?? 'it',
        mode: 1,
        selector: '#map',
      });
    }
  },
  unmounted() {
    /**
     * Reset map editor module when leaving
     */
    this.$store.commit('mapEditor/reset');
  },
});
