
import { omit } from 'lodash';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'ProcessingButton',
  props: ['handler', 'processingLabel'],
  inheritAttrs: false,
  data() {
    return {
      id: Math.random().toString(36).slice(2, 7),
    };
  },
  methods: {
    async onHandler(): Promise<void> {
      this.$store.commit('app/setProcessing', this.id);
      await this.handler();
    },
  },
  computed: {
    ...mapState('app', ['processing']),
    isProcessing(): boolean {
      /**
       * Processing status
       */
      return this.processing === this.id;
    },
    label(): string {
      /**
       * Label
       */
      let label: string = this.$attrs.label as string;
      if (this.isProcessing && this.processingLabel) {
        label = this.processingLabel;
      }
      return label;
    },
    disabled(): boolean {
      /**
       * Disabled
       */
      let disabled: boolean = (this.$attrs.disabled as boolean) ?? false;
      if (
        this.isProcessing ||
        (this.processing !== null && !this.isProcessing)
      ) {
        disabled = true;
      }
      return disabled;
    },
    attrs(): any {
      /**
       * Props (without label)
       */
      return omit(this.$attrs, ['label', 'disabled']);
    },
  },
});
