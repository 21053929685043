
import { defineComponent } from 'vue';
import { debounce } from 'lodash';
import ConfirmDialog from 'primevue/confirmdialog';
import { mapState } from 'vuex';
import Navigation from '@/components/Navigation.vue';
import TopBar from '@/components/TopBar.vue';
import localeUtil from '@/utils/localeUtil';
import { Locale } from '@/models/locale';
import { DeviceSpecs } from './models/store';
import SpotDialog from '@/components/SpotDialog.vue';
import PendingRequestLoader from '@/components/PendingRequestLoader.vue';
import ProgressDialog from '@/components/ProgressDialog.vue';
import ProductFruits from './components/ProductFruits.vue';
import CheckToday from './components/CheckToday.vue';

export default defineComponent({
  name: 'App',
  components: {
    Navigation,
    TopBar,
    ConfirmDialog,
    SpotDialog,
    PendingRequestLoader,
    ProgressDialog,
    ProductFruits,
    CheckToday,
  },
  data() {
    return {
      showRefreshDialog: false,
    };
  },
  beforeMount() {
    const locale: Locale = localeUtil.initLocale();
    this.$store.commit('session/setLocale', locale);

    // add toast functionality to window
    window.toast = this.$spiagge.toast.show;

    // retro-compatibilty function
    window.com_anm22_wg_headbar_search_showMessage = (
      message: string,
      type: string | undefined,
    ) => {
      let messageType = type;
      if (typeof type === 'undefined') {
        messageType = 'neutral';
      }
      switch (messageType) {
        case 'neutral':
        case 'suggestion':
          window.toast('info', message);
          break;
        case 'alarm':
          window.toast('error', message);
          break;
        case 'confirm':
          window.toast('success', message);
          break;
        case 'warning':
          window.toast('warn', message);
          break;
        default:
          break;
      }
    };

    // window.addEventListener('sw-updated', () => {
    //   this.showRefreshDialog = true;
    // });
  },
  methods: {
    onRefresh(): void {
      this.showRefreshDialog = false;
      window.location.reload();
    },
  },
  mounted() {
    this.$store.commit('app/setWindowWidth', window.innerWidth);
    this.$store.commit('app/setWindowHeight', window.innerHeight);
    const ua = navigator.userAgent;
    // prettier-ignore
    const specs: DeviceSpecs = {
      ua,
      // eslint-disable-next-line no-nested-ternary
      browser: /Edge\/\d+/.test(ua) ? 'ed' : /MSIE 9/.test(ua) ? 'ie9' : /MSIE 10/.test(ua) ? 'ie10' : /MSIE 11/.test(ua) ? 'ie11' : /MSIE\s\d/.test(ua) ? 'ie?' : /rv:11/.test(ua) ? 'ie11' : /Firefox\W\d/.test(ua) ? 'ff' : /Chrome\W\d/.test(ua) ? 'gc' : /Chromium\W\d/.test(ua) ? 'oc' : /\bSafari\W\d/.test(ua) ? 'sa' : /\bOpera\W\d/.test(ua) ? 'op' : /\bOPR\W\d/i.test(ua) ? 'op' : typeof MSPointerEvent !== 'undefined' ? 'ie?' : '',
      // eslint-disable-next-line no-nested-ternary
      os: /Windows NT 10/.test(ua) ? 'win10' : /Windows NT 6\.0/.test(ua) ? 'winvista' : /Windows NT 6\.1/.test(ua) ? 'win7' : /Windows NT 6\.\d/.test(ua) ? 'win8' : /Windows NT 5\.1/.test(ua) ? 'winxp' : /Windows NT [1-5]\./.test(ua) ? 'winnt' : /Mac/.test(ua) ? 'mac' : /Linux/.test(ua) ? 'linux' : /X11/.test(ua) ? 'nix' : '',
      // eslint-disable-next-line no-nested-ternary
      mobile: /IEMobile|Windows Phone|Lumia/i.test(ua) ? 'w' : /iPhone|iP[oa]d/.test(ua) ? 'i' : /Android/.test(ua) ? 'a' : /BlackBerry|PlayBook|BB10/.test(ua) ? 'b' : /Mobile Safari/.test(ua) ? 's' : !!/webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(ua),
      tablet: /Tablet|iPad/i.test(ua),
      touch: 'ontouchstart' in document.documentElement,
    };

    this.$store.commit('app/setDeviceSpecs', specs);
    this.$nextTick(() => {
      window.addEventListener(
        'resize',
        debounce(() => {
          this.$store.commit('app/setWindowWidth', window.innerWidth);
          this.$store.commit('app/setWindowHeight', window.innerHeight);
        }, 200),
      );
    });
    this.$spiagge.utils.global.loadScript(
      'chrome_extension_script',
      'chrome-extension://kddlpmnnlnnlbdmlmneclkmgbgfifeag/enableChromeExt.js',
    );
  },
  computed: {
    ...mapState('session', ['user', 'roleUser']),
  },
});
