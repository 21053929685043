
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { MenuItem } from '@/models/menu';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  ActionPermissionType,
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

export default defineComponent({
  name: 'ContactsNavigation',
  methods: {
    toggle(event: any) {
      (this.$refs as any).menu.toggle(event);
    },
  },
  computed: {
    ...mapState('app', ['windowWidth', 'breakpoints']),
    windowHeight(): number {
      return this.$store.getters['app/windowHeight'];
    },
    menuItems(): Array<MenuItem> {
      return [
        // Main section
        {
          label: `${this.$t('contactsNavigation.clients').toUpperCase()}`,
          icon: 'spi-user',
          items: [
            // General
            {
              label: this.$t('contactsNavigation.clients'),
              icon: 'spi-user',
              to: '/customers/contacts',
            },
            {
              label: this.$t('contactsNavigation.cards'),
              icon: 'spi-credit-card',
              to: '/customers/cards',
              visible: permissionsUtil.isActionPermissionAllowed(
                FEATURE_PERMISSION_CONFIG.customers,
                FEATURE_PERMISSION_ACTION_CONFIG.customers.CARD_MANAGEMENT,
              ),
            },
            {
              label: this.$t('contactsNavigation.export'),
              icon: 'spi-download',
              to: '/customers/export',
              visible: permissionsUtil.isActionPermissionAllowed(
                FEATURE_PERMISSION_CONFIG.customers,
                FEATURE_PERMISSION_ACTION_CONFIG.customers.IMPORT_EXPORT,
              ),
            },
            {
              label: this.$t('contactsNavigation.import'),
              icon: 'spi-upload',
              to: '/customers/import',
              visible: permissionsUtil.isActionPermissionAllowed(
                FEATURE_PERMISSION_CONFIG.customers,
                FEATURE_PERMISSION_ACTION_CONFIG.customers.IMPORT_EXPORT,
              ),
            },
          ] as Array<MenuItem>,
        },
      ];
    },
  },
});
