import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1b707f8b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height"]
const _hoisted_2 = { id: "beach" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "map",
    class: _normalizeClass(_ctx.mapClasses.join(' ')),
    style: _normalizeStyle(_ctx.mapStyle)
  }, [
    _renderSlot(_ctx.$slots, "root", {}, undefined, true),
    _createElementVNode("div", {
      id: "panzoom-parent",
      style: _normalizeStyle(_ctx.mapStyle)
    }, [
      _createElementVNode("div", {
        id: "panzoom",
        width: _ctx.panzoomWidth,
        height: _ctx.panzoomHeight,
        style: _normalizeStyle(_ctx.panzoomStyle)
      }, [
        _createElementVNode("div", {
          id: "sea",
          style: _normalizeStyle(_ctx.seaStyle)
        }, null, 4),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            id: "elements-wrapper",
            style: _normalizeStyle(_ctx.elementsWrapperStyle)
          }, [
            (_ctx.background)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: _ctx.background.url,
                  id: "background-image",
                  style: _normalizeStyle(_ctx.backgroundImageStyle)
                }, null, 12, _hoisted_3))
              : _createCommentVNode("", true),
            _renderSlot(_ctx.$slots, "elements", {}, undefined, true)
          ], 4)
        ])
      ], 12, _hoisted_1)
    ], 4)
  ], 6))
}