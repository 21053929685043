
import { defineComponent } from 'vue';
import { debounce } from 'lodash';
import { mapGetters, mapState } from 'vuex';
import { DateTime } from 'luxon';
import { License } from '@/models/license';
import { CashFlow, CashFlowMethod } from '@/models/cashFlow';
import cashFlowService from '@/services/cashFlowService';
import {
  ApiCashFlowFindPayload,
  ApiCashFlowUpdatePayload,
  ApiLogCreatePayload,
} from '@/models/api';
import {
  Print,
  PrintArticle,
  Printer,
  PrinterResponse,
  PrintMode,
} from '@/models/printer';
import cookieUtil from '@/utils/cookieUtil';
import printerUtil from '@/utils/printerUtil';
import {
  ReservationExpandedField,
  ReservationExpanded,
  Reservation,
  ReservationService,
} from '@/models/reservation';
import reservationService from '@/services/reservationService';
import { BEACH_TICKET_SERVICE_ID, Service } from '@/models/service';
import { LogAction } from '@/models/log';
import { User } from '@/models/user';
import logService from '@/services/logService';

export default defineComponent({
  name: 'BookingReceiptPrinter',
  data() {
    return {
      showBookingPrintFiscalDialog: true,
      cashFlows: [] as Array<CashFlow>,
      selectedCashFlow: null as CashFlow | null,
      onPrintClickDebounced: debounce(
        this.onPrintClick as (printer: Printer) => void,
        2000,
      ),
      isPrinting: false,
      printer: null as Printer | null,
    };
  },
  methods: {
    getReceiptDescription(): string {
      return this.$spiagge.utils.reservation.getReceiptDescription({
        spotType: this.spot.type,
        spotName: this.spot.name,
        maxiBeds: this.maxiBeds,
        beds: this.beds,
        chairs: this.chairs,
        deckChairs: this.deckChairs,
        startDate: this.startDate.toSeconds(),
        endDate: this.endDate.toSeconds(),
      } as Reservation);
    },
    async load(): Promise<void> {
      this.cashFlows = await cashFlowService.find({
        methods: [CashFlowMethod.PAYPAL, CashFlowMethod.STRIPE].join(','),
        receiptId: 0, // Search for receipt_id NULL,
        invoiceId: 0, // Search for invoice_id NULL,
        dateFrom: DateTime.fromISO('2022-01-01T00:00:00.000', {
          zone: 'utc',
        }).toSeconds(),
        discardInvoices: true,
        orderBy: [
          {
            field: 'date',
            value: 'asc',
          },
        ],
      } as ApiCashFlowFindPayload);
      if (this.cashFlows.length > 0) {
        const cashFlow = this.cashFlows[0];
        // Retrieve reservation data and set into store
        try {
          const reservation = (await reservationService.one(
            cashFlow.reservationId,
            [
              // ReservationExpandedField.MASTER,
              ReservationExpandedField.CHILDREN,
              ReservationExpandedField.CONTACT,
              // ReservationExpandedField.SERVICES,
            ],
          )) as ReservationExpanded;
          await this.$store.dispatch('reservation/setReservation', reservation);
          await this.$store.dispatch('reservation/getServices');
        } catch (e) {
          // TODO:
        }
        this.selectedCashFlow = cashFlow;
        if (this.isPrinting) {
          this.print();
        }
      } else {
        this.selectedCashFlow = null;
        this.$store.commit('reservation/reset');
        this.isPrinting = false;
        this.printer = null;
      }
    },
    closeDialog(): void {
      this.selectedCashFlow = null;
    },
    onPrintClick(printer: Printer): void {
      if (!this.selectedCashFlow) return;
      if (this.isPrinting) return;
      this.isPrinting = true;
      this.printer = printer;
      this.print();
    },
    print(): void {
      const printer = this.printer;
      if (!printer || !this.selectedCashFlow) {
        return;
      }
      const receipt = printerUtil.buildPrint(
        PrintMode.FISCAL,
        CashFlowMethod.CREDIT_CARD,
      );
      let totalArticles = 0;
      /**
       * Main spot component
       */
      const article = {} as PrintArticle;
      article.description = `#${
        this.id
      } - ${this.$spiagge.utils.reservation.getReceiptDescription({
        spotType: this.spot.type,
        spotName: this.spot.name,
        maxiBeds: this.maxiBeds,
        beds: this.beds,
        chairs: this.chairs,
        deckChairs: this.deckChairs,
        startDate: this.startDate.toSeconds(),
        endDate: this.endDate.toSeconds(),
      } as Reservation)}`;
      article.price = this.totals.forced.beach ?? this.totals.list.beach;
      article.vat = 22;
      article.quantity = 1;
      receipt.articles.push(article);
      totalArticles += article.price;
      /**
       * Pezzi extra
       */
      if (this.cabins) {
        this.cabins.forEach((cabin: Reservation) => {
          const article = {} as PrintArticle;
          article.description =
            this.$spiagge.utils.reservation.getReceiptDescription(cabin);
          article.price = cabin.forcedTotal ?? cabin.listTotal ?? 0;
          article.vat = 22;
          article.quantity = 1;
          receipt.articles.push(article);
          totalArticles += article.price;
        });
      }
      if (this.additions) {
        this.additions.forEach((addition: Reservation) => {
          const article = {} as PrintArticle;
          article.description =
            this.$spiagge.utils.reservation.getReceiptDescription(addition);
          article.price = addition.forcedTotal ?? addition.listTotal ?? 0;
          article.vat = 22;
          article.quantity = 1;
          receipt.articles.push(article);
          totalArticles += article.price;
        });
      }
      if (this.parkings) {
        this.parkings.forEach((parking: Reservation) => {
          const article = {} as PrintArticle;
          article.description =
            this.$spiagge.utils.reservation.getReceiptDescription(parking);
          article.price = parking.forcedTotal ?? parking.listTotal ?? 0;
          article.vat = 22;
          article.quantity = 1;
          receipt.articles.push(article);
          totalArticles += article.price;
        });
      }
      /**
       * Services
       */
      if (this.reservationServices) {
        this.reservationServices
          .filter(
            (element: ReservationService) =>
              element.bought - element.deleted > 0 && element.price > 0,
          )
          .forEach((rs: ReservationService) => {
            const article = {} as PrintArticle;
            const service = this.services.find(
              (item: Service) => item.id === rs.serviceId,
            );
            if (rs.serviceId === 512) {
              article.description = `${service?.name}`;
              article.quantity = 1;
              article.price = rs.price;
              article.vat = 22;
              receipt.articles.push(article);
              totalArticles += article.price * article.quantity;
            } else if (!service || !service?.webticPriceId) {
              article.description = `Serv. ext. ${service?.name}`;
              article.quantity = rs.bought - rs.deleted;
              article.price =
                Math.round((rs.price / article.quantity) * 100) / 100;
              article.vat = service?.vat ?? 22;
              receipt.articles.push(article);
              totalArticles += article.price * article.quantity;
            }
          });
      }
      /**
       * Calc discount
       */
      let discount = totalArticles - this.selectedCashFlow.amount;
      discount = Math.round(discount * 100) / 100;
      if (discount) {
        receipt.discount = discount;
      }
      totalArticles = Math.round(totalArticles * 100) / 100;
      // Log the print attempt before connecting to the printer
      const logCreatePayload = {
        reservationId: this.id,
        action: LogAction.ONLINE_PRINT_BOOKING_RECEIPT_REQUEST,
        value: `printId: ${
          printer.id
        } printName: ${
          printer.name
        } total: ${
          totalArticles
        } discount: ${discount}`,
      } as ApiLogCreatePayload;
      this.$store.dispatch('reservation/createLog', logCreatePayload);
      /**
       * Send to printer
       */
      this.$spiagge.utils.printMode.print(
        printer.id,
        receipt,
        (resData: PrinterResponse) =>
          this.onPrintSuccess(resData, receipt, printer),
      );
    },
    async onPrintSuccess(
      resData: PrinterResponse,
      receipt: Print,
      printer: Printer,
    ): Promise<void> {
      if (!this.selectedCashFlow) return;
      /**
       * Register receipt in cashFlow
       */
      const payload = {
        id: this.selectedCashFlow.id,
        fiscal: true,
        receipt: {
          printerId: printer.id,
          date: DateTime.now().startOf('day').toSeconds(),
          method: receipt.paymentMethod,
          number: resData?.receiptNumber ?? null,
          total: resData?.receiptAmount ?? this.selectedCashFlow.amount,
          subTotal1: null,
          vat1: null,
          subTotal2: null,
          vat2: null,
          subTotal3: null,
          vat3: null,
          subTotal4: null,
          vat4: null,
        },
      } as ApiCashFlowUpdatePayload;
      await cashFlowService.update(payload);
      /**
       * Create log for this action
       */
      const logPayload = {
        reservationId: this.id,
        action: LogAction.ONLINE_PRINT_BOOKING_RECEIPT,
        value: `${this.selectedCashFlow.amount}`,
      } as ApiLogCreatePayload;
      await logService.create(logPayload);
      /**
       * Continue if new cashFlows to print
       */
      this.load();
    },
  },
  computed: {
    user(): User {
      return this.$store.getters['session/user'];
    },
    license(): License {
      return this.$store.getters['session/license'];
    },
    services(): Array<Service> {
      return this.$store.getters['session/services'];
    },
    fiscalPrinters(): Array<Printer> {
      return this.$store.getters['session/fiscalPrinters'].filter(
        (p: Printer) => !cookieUtil.get(`spit_prt-hide-${p.id}`),
      );
    },
    ...mapState('reservation', [
      'id',
      'spot',
      'totals',
      'hotel',
      'cabins',
      'additions',
      'parkings',
      'maxiBeds',
      'beds',
      'deckChairs',
      'chairs',
      'startDate',
      'endDate',
      'voucherId',
    ]),
    ...mapGetters('reservation', ['total', 'totalToPay']),
    reservationServices(): Array<ReservationService> {
      return this.$store.getters['reservation/services'].filter(
        (reservationService: ReservationService) =>
          reservationService.serviceId !== BEACH_TICKET_SERVICE_ID,
      );
    },
  },
  async beforeMount() {
    /**
     * Retrieve online cashflows not fiscal printed
     */
    this.load();
  },
  mounted() {
    this.$spiagge.utils.printMode.setup();
  },
});
