
import { defineComponent, PropType } from 'vue';
import { mapState } from 'vuex';
import { ContentLoader } from 'vue-content-loader';
import { cloneDeep } from 'lodash';
import { ChartData } from '@/models/chart';

export default defineComponent({
  name: 'StatisticsLineChart',
  components: {
    ContentLoader,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    chartData: {
      type: Object as PropType<ChartData>,
      required: true,
    },
    tooltip: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      options: {
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              precision: 0,
            },
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              usePointStyle: true,
              boxWidth: 6,
              boxHeight: 6,
            },
          },
        },
      },
    };
  },
  computed: {
    ...mapState('app', ['windowWidth', 'breakpoints']),
    formattedChartData(): ChartData {
      const formattedChartData = cloneDeep(this.chartData);
      if (this.chartData.labels.length !== 1) {
        return formattedChartData;
      }
      // if only one day -> add same day for a better view
      formattedChartData.labels.push(formattedChartData.labels[0]);
      formattedChartData.datasets.map((dataset, index) => {
        formattedChartData.datasets[index].data.push(
          formattedChartData.datasets[index].data[0],
        );
      });
      return formattedChartData;
    },
  },
});
