import { SpotType } from './spot';

// eslint-disable-next-line import/prefer-default-export
export enum AppAction {
  NONE = '',
  CREATE_RESERVATION = 'create-reservation',
  VIEW_RESERVATION = 'view-reservation',
  EDIT_RESERVATION = 'edit-reservation',
  CREATE_ADDITION = 'create-addition',
}

export interface AppSpotDialog {
  spotName: string;
  spotType: SpotType;
  callback: (data?: any) => void;
}

export const APP_REQUEST_PENDING_TIMEOUT = 2500;

export enum AppRequestStatus {
  NONE, // request performed
  STARTED, // request started
  PENDING, // request pending (after 2.5s)
}

export interface AppProgressDialog {
  title?: string;
  content?: string;
  actions?: Array<{
    label: string;
    icon: string;
    classes: Array<string>;
    callback: () => void;
  }>;
}
