import { Timestamp } from '.';

export enum RevenueManagementRuleType {
  BASE = 'base',
  OVERRIDE = 'override',
}

export enum RevenueManagementPriceHintStatus {
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
}

export interface RevenueManagementPrices {
  day: number;
  sectorId: number;
  offlinePrice: number | null;
  onlinePrice: number | null;
}

export interface RevenueManagementSlotDetail {
  occupancy: number;
  revenue: number;
}

export interface RevenueManagementPeriod {
  id: number;
  start: number; // day number
  end: number; // day number
}

export enum RevenueManagementRuleMode {
  OCCUPANCY = 'occupancy',
}

export interface RevenueManagementBaseRule {
  applicationThreshold: number;
  createdAt: Timestamp;
  createdBy: number;
  deleted: boolean;
  id: number;
  licenseCode: string;
  licenseId: number;
  periodId: number;
  revenuePercentageValue: number;
  ruleType: RevenueManagementRuleMode;
  updatedAt: Timestamp;
  updatedBy: number;
}

export interface RevenueManagementOverrideRule {
  applicationThreshold: number;
  createdAt: Timestamp;
  createdBy: number;
  deleted: boolean;
  id: number;
  licenseCode: string;
  licenseId: number;
  periodId: number;
  revenuePercentageValue: number;
  ruleType: RevenueManagementRuleType;
  updatedAt: Timestamp;
  updatedBy: number;
}

export interface RevenueManagementRuleBaseModel {
  threshold: number | null;
  value: number | null;
}

export interface RevenueManagementRuleCreateEmit {
  rule: RevenueManagementBaseRule | RevenueManagementOverrideRule;
  model: RevenueManagementRuleBaseModel;
  successCb: () => void;
  errorCb: () => void;
}

export type RevenueManagementRuleUpdateEmit = RevenueManagementRuleCreateEmit;

export interface RevenueManagementRuleDeleteEmit {
  rule: RevenueManagementBaseRule | RevenueManagementOverrideRule;
  successCb: () => void;
  errorCb: () => void;
}

export interface RevenueManagementHistoricalDetailDay {
  pickUp: number;
  occupancy: number;
}

export interface RevenueManagementHistoricalDetail {
  date: Timestamp;
  reservationsCount: number;
  occupancy: number;
  totalSpots: number;
  totalAvailableSpots: number;
  revenue: number;
  averageRevenue: number;
  dayZero: RevenueManagementHistoricalDetailDay;
  dayOne: RevenueManagementHistoricalDetailDay;
  dayTwo: RevenueManagementHistoricalDetailDay;
  dayThree: RevenueManagementHistoricalDetailDay;
  dayFour: RevenueManagementHistoricalDetailDay;
  dayFive: RevenueManagementHistoricalDetailDay;
  daySix: RevenueManagementHistoricalDetailDay;
  daySeven: RevenueManagementHistoricalDetailDay;
  daySevenPlus: RevenueManagementHistoricalDetailDay;
}

export interface RevenueManagementRuleDetailRule {
  accepted: boolean;
  id: number;
}

export interface RevenueManagementRuleDetail {
  appliedPrice: number;
  baseRules: Array<RevenueManagementRuleDetailRule>;
  day: Timestamp;
  overrideRules: Array<RevenueManagementRuleDetailRule>;
  price: number;
  occupancy: number;
}

export interface RevenueManagementPriceHint {
  day: Timestamp;
  revenuePercentageValue: number;
  ruleId: number;
  ruleType: RevenueManagementRuleType;
  sector: number;
}

export interface RevenueManagementPriceAlteration {
  day: Timestamp;
  percentageDiscount: number;
  sectorId: number;
}
