
import { defineComponent } from 'vue';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import OverlayPanel from 'primevue/overlaypanel';
import { mapGetters, mapState } from 'vuex';
import { DropdownChangeEvent } from 'primevue/dropdown';
import { Contact, Customer, DEFAULT_CUSTOMER, Guest } from '@/models/customer';
import ReservationOrderSummary from '@/components/reservation/ReservationOrderSummary.vue';
import {
  ApiReservationCreateGuestPayload,
  ApiReservationUpdatePayload,
  ApiContactHintPayload,
} from '@/models/api';
import { DropdownOption } from '@/models';
import { Voucher } from '@/models/voucher';
import { License } from '@/models/license';
import contactService from '@/services/contactService';
import { AreaCode } from '@/enum';
import customerService from '@/services/customerService';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  DefaultRoles,
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';
import { Printer } from '@/models/printer';
import cookieUtil from '@/utils/cookieUtil';
import printerUtil from '@/utils/printerUtil';
import CustomerTags from '../shared/CustomerTags.vue';
import InvoiceDialog from '../shared/InvoiceDialog.vue';
import ContactSuggestion from '../shared/ContactSuggestion.vue';
import { Card } from '@/models/card';

export default defineComponent({
  components: {
    ContactSuggestion,
    ReservationOrderSummary,
    InvoiceDialog,
    CustomerTags,
  },
  name: 'ReservationCustomer',
  data() {
    return {
      /* customer */
      customerHotel: '',
      customerOtherHotel: '',
      billingDialog: false,
      newOrEditDialog: false,
      phoneAreaCodeOptions: [] as Array<DropdownOption>,
      /* guest */
      guest: null as unknown as Guest,
      guestDialog: false,
      guestEdit: 0,
      /** hints */
      hints: [] as Array<Contact>,
      guestHints: [] as Array<Contact>,
      getHintsDebounced: debounce(
        this.getHints as (event: KeyboardEvent, field?: string) => void,
        1000,
      ),
      /** card reader */
      cardDialog: false,
      cardCustomerName: '',
      cardHotelRoom: '',
      cardReservationNumber: null,
      cardReservationCheckIn: null,
      cardReservationCheckOut: null,
      cardReservationAdultsNum: null,
      cardReservationChildrenNum: null,
      cardReservationService: null,
      cardWallets: [] as Array<{ number: string; balance: number }>,
      cardPriceMode: null,
      cardReservationAgency: null,
      cardReservationOrigin: null,
    };
  },
  methods: {
    hasUpdatePermission(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.reservations,
        FEATURE_PERMISSION_ACTION_CONFIG.reservations.UPDATE,
      );
    },
    hasVoucherPermission(): boolean {
      const currentUserRole = this.$store.getters['session/roleUser'];
      return (
        permissionsUtil.isActionPermissionAllowed(
          FEATURE_PERMISSION_CONFIG.reservations,
          FEATURE_PERMISSION_ACTION_CONFIG.reservations.VOUCHER_SELECTION,
        ) &&
        (!this.license.pricesVoucherLock ||
          currentUserRole === DefaultRoles.SUPERUSER)
      );
    },
    onCustomerDetail(): void {
      if (typeof this.customer.contactId === 'number') {
        this.$router.push(`/customers/contacts/${this.customer.contactId}`);
      }
    },
    async onOtherHotelUpdate(event: FocusEvent): Promise<void> {
      const value = (event.target as unknown as { value: string }).value;
      if (value === '') {
        this.updateReservation({ hotel: '' });
        this.customerHotel = '';
        return;
      }
      const voucher = this.vouchers.find(
        (voucher: Voucher) => voucher.name === value,
      );

      if (voucher) {
        this.updateReservation({ hotel: voucher.name });
        this.customerHotel = voucher.name;
        this.customerOtherHotel = '';
        return;
      }
      this.updateReservation({ hotel: value });
      this.customerHotel = 'other';
    },
    onUpdateHotel(event: DropdownChangeEvent): void {
      const value = event.value;
      // console.log(value);
      if (value === '') {
        this.customerHotel = '';
        this.customerOtherHotel = '';
        this.updateReservation({ hotel: '', voucherId: 0 });
        return;
      }
      if (value === 'other') {
        this.customerHotel = 'other';
        this.customerOtherHotel = '';
        return;
      }
      // hotel selected
      this.customerHotel = value;
      this.customerOtherHotel = '';
      this.updateReservation({ hotel: value });
    },
    onUpdateHotelRoom(event: FocusEvent): void {
      const value = (event.target as unknown as { value: string }).value;
      this.updateReservation({ hotelRoom: value });
    },
    onUpdatePrefix(event: DropdownChangeEvent): void {
      const value = event.value;
      this.customer.phoneAreaCode = value;
      this.syncCustomer(this.customer);
    },
    onUpdateTravelGroup(event: FocusEvent): void {
      const value = (event.target as unknown as { value: string }).value;
      this.updateReservation({ travelGroup: value });
    },
    async updateReservation(
      payload: ApiReservationUpdatePayload,
    ): Promise<void> {
      try {
        await this.$store.dispatch('reservation/updateReservation', payload);
        // this.$spiagge.toast.success('Cliente aggiornato con successo');
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationCustomer.toast.reservationUpdateError'),
        );
      }
    },
    /* CUSTOMER METHODS */
    onNewCustomer(): void {
      const customer = cloneDeep(this.customer);
      delete customer.contactId;
      this.syncCustomer(customer);
      this.newOrEditDialog = false;
    },
    onEditCustomer(): void {
      this.syncCustomer(this.customer);
      this.newOrEditDialog = false;
    },
    async syncCustomer(customer: Customer): Promise<void> {
      try {
        await this.$store.dispatch('reservation/updateReservation', customer);
        // this.$spiagge.toast.success('Cliente aggiornato con successo');
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationCustomer.toast.customerUpdateError'),
        );
      }
    },
    onUpdateCustomer(data: { key: string; value: FocusEvent }) {
      this.updateCustomer(data.value, data.key);
    },
    onCloseInvoiceDialog() {
      this.billingDialog = false;
    },
    async updateCustomer(event: FocusEvent, key: string): Promise<void> {
      const { customer } = this;
      const oldFirstName = customer.firstName.trim().toLowerCase();
      const oldLastName = customer.lastName.trim().toLowerCase();
      const value = (event.target as unknown as { value: string | number })
        .value;
      set(customer, key, value);
      if (
        customer.contactId &&
        ((key === 'firstName' &&
          oldFirstName.length !== 0 &&
          value.toString().trim().toLowerCase() !== oldFirstName) ||
          (key === 'lastName' &&
            oldLastName.length !== 0 &&
            value.toString().trim().toLowerCase() !== oldLastName))
      ) {
        this.newOrEditDialog = true;
      } else {
        this.syncCustomer(this.customer);
      }
      if (key === 'email') {
        this.$store.dispatch('reservation/getCards');
      }
    },
    /* GUESTS METHODS */
    async onGuestSuggestion(contact: Contact): Promise<void> {
      const payload: ApiReservationCreateGuestPayload = cloneDeep(contact);
      try {
        await this.$store.dispatch('reservation/createGuest', payload);
        this.guest = contact;
        this.guestEdit = contact.contactId;
        this.$spiagge.toast.success(
          this.$t('reservationCustomer.toast.guestAddSuccess'),
        );
        this.guestHints = [];
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationCustomer.toast.guestAddError'),
        );
      }
    },
    onGuestsAdd(): void {
      this.guest = cloneDeep(DEFAULT_CUSTOMER) as Guest;
      this.guestEdit = 0;
      this.guestDialog = true;
    },
    async onGuestAdd(): Promise<void> {
      const payload: ApiReservationCreateGuestPayload = cloneDeep(this.guest);
      try {
        await this.$store.dispatch('reservation/createGuest', payload);
        this.guest = null as unknown as Guest;
        this.guestDialog = false;
        this.$spiagge.toast.success(
          this.$t('reservationCustomer.toast.guestAddSuccess'),
        );
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationCustomer.toast.guestAddError'),
        );
      }
    },
    onGuestEdit(guest: Guest): void {
      if (this.hasUpdatePermission()) {
        this.guestEdit = guest.contactId as number;
        this.guest = cloneDeep(guest);
        this.guestDialog = true;
      }
    },
    async onGuestRemove(guest: Guest): Promise<void> {
      try {
        await this.$store.dispatch('reservation/removeGuest', guest);
        this.guestDialog = false;
        this.$spiagge.toast.success(
          this.$t('reservationCustomer.toast.guestRemoveSuccess'),
        );
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationCustomer.toast.guestRemoveError'),
        );
      }
    },
    async onGuestSave(): Promise<void> {
      const payload: ApiReservationCreateGuestPayload = cloneDeep(this.guest);
      payload.contactId = this.guestEdit;
      try {
        this.$store.dispatch('reservation/createGuest', this.guest);
        this.guest = null as unknown as Guest;
        this.guestDialog = false;
        this.$spiagge.toast.success(
          this.$t('reservationCustomer.toast.guestEditSuccess'),
        );
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationCustomer.toast.guestEditError'),
        );
      } finally {
        this.guestEdit = 0;
      }
    },
    onGuestsPanel(event: Event): void {
      (this.$refs.op as OverlayPanel).toggle(event);
    },
    // Hints
    async getHints(event: KeyboardEvent, field = ''): Promise<void> {
      // Get data from inputs
      const value = (event.target as unknown as { value: string }).value;
      const firstName =
        field === 'firstName'
          ? value
          : this.guestDialog
          ? (this.$refs.guestFirstName as unknown as { modelValue: string })
              .modelValue
          : (this.$refs.firstName as unknown as { modelValue: string })
              .modelValue;
      const lastName =
        field === 'lastName'
          ? value
          : this.guestDialog
          ? (this.$refs.guestLastName as unknown as { modelValue: string })
              .modelValue
          : (this.$refs.lastName as unknown as { modelValue: string })
              .modelValue;
      const email =
        field === 'email'
          ? value
          : this.guestDialog
          ? (this.$refs.guestEmail as unknown as { modelValue: string })
              .modelValue
          : (this.$refs.email as unknown as { modelValue: string }).modelValue;
      const phoneNumber =
        field === 'phoneNumber'
          ? value
          : this.guestDialog
          ? (this.$refs.guestPhoneNumber as unknown as { modelValue: string })
              .modelValue
          : (this.$refs.phoneNumber as unknown as { modelValue: string })
              .modelValue;
      // Get hints
      if (
        firstName.length >= 3 ||
        lastName.length >= 3 ||
        email.length >= 3 ||
        phoneNumber.length >= 3
      ) {
        try {
          const response = await contactService.hint({
            firstName,
            lastName,
            email,
            phoneNumber,
          } as ApiContactHintPayload);
          if (this.guestDialog) {
            this.guestHints = response;
          } else {
            this.hints = response;
          }
        } catch (e) {
          // console.log(e);
          this.hints = [];
          this.guestHints = [];
        }
      }
    },
    async onCustomerSuggestion(contact: Contact): Promise<void> {
      try {
        if (contact.contactId) {
          await this.$store.dispatch('reservation/updateReservation', {
            contactId: contact.contactId,
          } as ApiReservationUpdatePayload);
        } else {
          const payload = {
            firstName: contact.firstName,
            lastName: contact.lastName,
            email: contact.email,
            phoneAreaCode: contact.phoneAreaCode,
            phoneNumber: contact.phoneNumber,
          } as ApiReservationUpdatePayload;
          await this.$store.dispatch('reservation/updateReservation', payload);
        }
        if (contact.email) {
          this.$store.dispatch('reservation/getCards');
        }
        this.$spiagge.toast.success(
          this.$t('reservationCustomer.toast.customerUpdateSuccess'),
        );
        this.hints = [];
      } catch (e) {
        this.$spiagge.toast.error('onCustomerSuggestion');
      }
    },
    async onChangeTags(value: Array<number>) {
      try {
        if (this.customer && this.customer.contactId) {
          const response = await customerService.updateCustomerTags(
            this.customer.contactId,
            value,
          );
          // console.log(response);
          this.$store.commit('reservation/setTags', response);
        }
      } catch (error) {
        console.error(error);
        this.$spiagge.toast.error(
          this.$t('reservationCustomer.toast.tagError'),
        );
      }
    },
    onReadCard(printer: Printer): void {
      printerUtil.readCard(printer.id, (response: any) => {
        // console.log(response);
        if (response && response.data) {
          const data = response.data;
          this.cardDialog = true;
          this.cardCustomerName = data.partner_id.name
            ? data.partner_id.name
            : '';
          this.customer.firstName = this.cardCustomerName;

          this.cardHotelRoom = data.reservation_id.room;
          this.hotelRoom = this.cardHotelRoom ? this.cardHotelRoom : '';
          if (
            this.cardHotelRoom &&
            (!this.customerHotel || this.customerHotel === 'other') &&
            !this.customerOtherHotel
          ) {
            this.customerHotel = 'NAXOS';
          }

          this.cardReservationNumber = data.reservation_id.external_code;
          this.cardReservationCheckIn = data.reservation_id.checkin;
          this.cardReservationCheckOut = data.reservation_id.checkout;
          this.cardReservationAdultsNum = data.reservation_id.adults;
          this.cardReservationChildrenNum = data.reservation_id.children;
          this.cardReservationService = data.reservation_id.service_type_name;
          this.cardWallets = [];
          if (data.wallet_ids) {
            for (let i = 0; i < data.wallet_ids.length; i += 1) {
              this.cardWallets.push({
                number: data.wallet_ids[i].wallet_id.name,
                balance: data.wallet_ids[i].balance,
              });
            }
          }
          if (
            data.partner_id.clusters &&
            Object.getOwnPropertyNames(data.partner_id.clusters) &&
            Object.getOwnPropertyNames(data.partner_id.clusters).length > 0
          ) {
            this.cardPriceMode =
              data.partner_id.clusters[
                Object.getOwnPropertyNames(data.partner_id.clusters)[0]
              ].ClusterName;
          } else {
            this.cardPriceMode = null;
          }
          this.cardReservationAgency = data.reservation_id.travel_agent_name;
          this.cardReservationOrigin = data.reservation_id.source_name;

          this.customer.phoneNumber = data.partner_id.phone;
          this.customer.email = data.partner_id.email;

          this.updateReservation({
            hotelRoom: this.cardHotelRoom,
            hotel: this.customerHotel,
            firstName: this.cardCustomerName,
            lastName: this.customer.lastName,
            email: this.customer.email,
            phoneNumber: this.customer.phoneNumber,
          });
        }
      });
    },
    initializeHotelFields(reservationHotel: string): void {
      if (reservationHotel === '') {
        this.customerOtherHotel = '';
        this.customerHotel = '';
      } else {
        const voucher = this.vouchers.find(
          (voucher: Voucher) => voucher.name === reservationHotel,
        );

        if (voucher) {
          this.customerOtherHotel = '';
          this.customerHotel = voucher.name;
        } else {
          this.customerOtherHotel = reservationHotel;
          this.customerHotel = 'other';
        }
      }
    },
  },
  computed: {
    ...mapState('reservation', ['tags', 'hotel', 'otherHotel']),
    ...mapGetters('reservation', ['isEdit', 'isCreate', 'sector']),
    ...mapGetters('session', ['hasWorkTags']),
    card(): Card | undefined {
      return this.$store.state.reservation.cards.find(
        (card) => card.balance > 0,
      );
    },
    balance(): string {
      return this.card ? `${this.card.balance}` : '';
    },
    classes(): Array<string> {
      const classes = [];
      if (this.customer.contactId) {
        classes.push('has-customer');
      }
      if (this.guests.length > 0) {
        classes.push('has-guests');
      }
      return classes;
    },
    vouchers(): Array<Voucher> {
      return this.$store.getters['session/vouchers'];
    },
    id(): number {
      return this.$store.getters['reservation/id'];
    },
    customer(): Customer {
      return this.$store.getters['reservation/customer'];
    },
    guests(): Array<Guest> {
      return this.$store.getters['reservation/guests'];
    },
    hotelRoom(): string {
      return this.$store.getters['reservation/hotelRoom'];
    },
    travelGroup(): string {
      return this.$store.getters['reservation/travelGroup'];
    },
    license(): License {
      return this.$store.getters['session/license'];
    },
    hotelOptions(): Array<DropdownOption> {
      const options = [
        {
          label: this.$t('reservationCustomer.hotelOption.noHotel'),
          value: '',
        } as DropdownOption,
      ] as Array<DropdownOption>;

      const sortedOptions: Array<Voucher> = cloneDeep(this.vouchers).sort(
        (a: Voucher, b: Voucher) => a.name.localeCompare(b.name),
      );

      sortedOptions.map((voucher: Voucher) => {
        options.push({
          label: voucher.name,
          value: voucher.name,
        } as DropdownOption);
      });

      options.push({
        label: this.$t('reservationCustomer.hotelOption.other'),
        value: 'other',
      });

      return options;
    },
    cardReaders(): Array<Printer> {
      let printers = this.$store.getters['session/fiscalPrinters'].filter(
        (p: Printer) => !cookieUtil.get(`spit_prt-hide-${p.id}`),
      );
      if (this.sector && this.sector > 0) {
        // eslint-disable-next-line no-confusing-arrow
        printers = printers.filter((p: Printer) =>
          p.sectors && p.sectors.length > 0
            ? p.sectors.includes(this.sector || 0)
            : true,
        );
      }
      // Considero solo la stampante con lettore tessere
      // eslint-disable-next-line no-confusing-arrow
      printers = printers.filter((p: Printer) => p.model && p.model === 63);
      return printers;
    },
    showHotelRoomInput(): boolean {
      // Verifico se la licenza ha la funzionalità attiva
      if (
        this.license.license === 'it-me-98035-naxos-beach---unahotel' ||
        this.license.license === 'demo2'
      ) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    this.phoneAreaCodeOptions = Object.values(AreaCode).map<DropdownOption>(
      (element: string) => ({
        label: this.$t(`areaCode.${element}`),
        value: element,
      }),
    );
    const reservationHotel = this.$store.getters['reservation/hotel'];
    this.initializeHotelFields(reservationHotel);
  },
  watch: {
    hotel(newValue: string) {
      this.initializeHotelFields(newValue);
    },
  },
});
