import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-442f36ec"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "price-list-dialog" }
const _hoisted_2 = { class: "subtitle" }
const _hoisted_3 = { class: "elements" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('priceListSwitchDialog.title')), 1),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('priceListSwitchDialog.subtitle', { list: _ctx.priceListSelected.name })), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.priceListsOptions.filter(
          (p) => p.id !== _ctx.priceListSelected.id,
        ), (priceListOption) => {
        return (_openBlock(), _createElementBlock("div", {
          key: 'pl-' + priceListOption.id,
          class: _normalizeClass({ selected: priceListOption.id === _ctx.selected }),
          onClick: ($event: any) => (_ctx.onPriceListClick(priceListOption))
        }, _toDisplayString(priceListOption.name), 11, _hoisted_4))
      }), 128))
    ]),
    _createVNode(_component_Button, {
      label: _ctx.$t('button.confirm'),
      icon: "spi-check",
      class: "p-button p-button-primary btn",
      onClick: _ctx.onConfirmClick
    }, null, 8, ["label", "onClick"])
  ]))
}