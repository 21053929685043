
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'PrintButton',
  props: {
    element: {
      type: [HTMLElement],
      rquired: true,
    },
    stylesheets: {
      required: false,
      type: Array as PropType<Array<string>>,
      default: () => [],
    },
    cloneHead: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onPrint(): void {
      this.$spiagge.utils.global.printHtml(
        this.element as HTMLElement,
        this.stylesheets,
        this.cloneHead,
      );
    },
  },
});
