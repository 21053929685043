import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21f81c9c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "breadcrumbs"
}
const _hoisted_2 = { class: "breadcrumbs-list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "item-label label" }
const _hoisted_5 = { class: "item-label label" }
const _hoisted_6 = {
  key: 2,
  class: "no-link"
}
const _hoisted_7 = { class: "item-label label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_ctx.breadcrumbs)
    ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (item, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: index,
              class: "breadcrumb-item"
            }, [
              (item.action)
                ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "item-link action-link p-ripple",
                    onClick: ($event: any) => (item.action())
                  }, [
                    (item.icon)
                      ? (_openBlock(), _createElementBlock("i", {
                          key: 0,
                          class: _normalizeClass(["item-icon icon", item.icon])
                        }, null, 2))
                      : _createCommentVNode("", true),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(item.label), 1)
                  ], 8, _hoisted_3)), [
                    [_directive_ripple]
                  ])
                : (item.to)
                  ? _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                      key: 1,
                      class: "item-link router-link p-ripple",
                      to: item.to
                    }, {
                      default: _withCtx(() => [
                        (item.icon)
                          ? (_openBlock(), _createElementBlock("i", {
                              key: 0,
                              class: _normalizeClass(["item-icon icon", item.icon])
                            }, null, 2))
                          : _createCommentVNode("", true),
                        _createElementVNode("span", _hoisted_5, _toDisplayString(item.label), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])), [
                      [_directive_ripple]
                    ])
                  : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createElementVNode("span", _hoisted_7, _toDisplayString(item.label), 1)
                    ]))
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}