
import { defineComponent, PropType } from 'vue';
import { cloneDeep, debounce } from 'lodash';
import { mapState } from 'vuex';
import { CustomerTag } from '@/models/customer';

export default defineComponent({
  name: 'CustomerTags',
  emits: ['changeTags'],
  props: {
    tagIds: {
      type: Array as PropType<Array<number>>,
    },
  },
  data() {
    return {
      tagsModel: cloneDeep(this.tagIds) ?? [],
      modelLength: this.tagIds?.length,
      onChangeTagsDebounced: debounce(
        this.onChangeTags as (value: Array<number>) => void,
        1000,
      ),
    };
  },
  methods: {
    onChangeTags(value: Array<number>) {
      this.$emit('changeTags', value);
    },
    getTag(tagId: number): CustomerTag {
      const tag =
        this.tagsOptions.find(
          (element: CustomerTag) => element.idWorldTag === tagId,
        ) ?? ({} as CustomerTag);
      const element = document.getElementById(`tag-${tagId}`);
      if (element) {
        element.style.backgroundColor = tag.color;
      }
      return tag;
    },
  },
  computed: {
    ...mapState('session', ['license']),
    tagsOptions(): Array<CustomerTag> {
      return this.license?.tags ?? ([] as Array<CustomerTag>);
    },
    tagsModelcomputed: {
      set(tagIds: Array<number>): void {
        this.onChangeTagsDebounced(tagIds);
        this.tagsModel = tagIds;
      },
      get(): Array<number> {
        return this.tagsModel;
      },
    },
  },
  watch: {
    tagIds() {
      this.tagsModel = this.tagIds ?? [];
    },
  },
});
