import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb59262e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "sector-combination-pieces",
  class: "col-8 p-0"
}
const _hoisted_2 = { class: "grid sector-block" }
const _hoisted_3 = { class: "label-block" }
const _hoisted_4 = { class: "sector-label" }
const _hoisted_5 = { class: "sector-sub-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sectorCombinationPieces, (combo) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "flex",
        key: combo.id
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, _toDisplayString(this.sectorHeaders.get(combo.id).name), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(this.$t('dynamicForm.priceListWizard.combinationPieces.sector')
              .concat(` ${this.sectorHeaders.get(combo.id).oldId}`)), 1)
          ]),
          _createVNode(_component_Dropdown, {
            class: "sector-selection",
            modelValue: combo.combinationPieces,
            optionLabel: "label",
            optionValue: "value",
            options: this.sectorOptions.get(combo.id),
            placeholder: _ctx.$t('button.select'),
            onChange: ($event: any) => (this.onChangePieces($event, combo.id))
          }, null, 8, ["modelValue", "options", "placeholder", "onChange"])
        ])
      ]))
    }), 128))
  ]))
}