import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68618c36"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "seasonal-dialog" }
const _hoisted_2 = { class: "p-field" }
const _hoisted_3 = { class: "p-field" }
const _hoisted_4 = { class: "p-field" }
const _hoisted_5 = { class: "copy-seasonal" }
const _hoisted_6 = {
  key: 0,
  class: "sub-description mt-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_CalendarInput = _resolveComponent("CalendarInput")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t('seasonalDialog.title')), 1)
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onFormSubmit && _ctx.onFormSubmit(...args)), ["prevent"])),
      class: "form"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('seasonalDialog.seasonalPriceList')) + ":", 1),
        _createVNode(_component_Dropdown, {
          modelValue: _ctx.model.seasonalPriceListId,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.seasonalPriceListId) = $event)),
          options: _ctx.priceListOptions,
          optionLabel: "label",
          optionValue: "value",
          placeholder: _ctx.$t('seasonalDialog.noPriceList')
        }, null, 8, ["modelValue", "options", "placeholder"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('seasonalDialog.seasonalStartDate')) + ":", 1),
        _createVNode(_component_CalendarInput, {
          modelValue: _ctx.model.seasonalStartDate,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.seasonalStartDate) = $event)),
          format: "dd-MM"
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('seasonalDialog.seasonalEndDate')) + ":", 1),
        _createVNode(_component_CalendarInput, {
          modelValue: _ctx.model.seasonalEndDate,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.seasonalEndDate) = $event)),
          format: "dd-MM"
        }, null, 8, ["modelValue"])
      ]),
      _createVNode(_component_Button, {
        type: "submit",
        label: _ctx.$t('button.confirm'),
        icon: "spi-check",
        class: "p-button p-button-primary btn",
        iconPos: "right"
      }, null, 8, ["label"])
    ], 32),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Button, {
        label: _ctx.$t('seasonalDialog.copySeasonal', { copyYear: _ctx.copyYear }),
        disabled: _ctx.copySeasonalDisabled || !_ctx.canCopySeasonalRes,
        onClick: _ctx.confirmSeasonalCopy
      }, null, 8, ["label", "disabled", "onClick"]),
      (!_ctx.canCopySeasonalRes)
        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t('seasonalDialog.copySeasonalMessage')), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}