import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9fe01076"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "top-bar-new-ticket"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.canCreateReservations() && _ctx.hasAloneOfflineTickets())
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Button, {
          id: "new-ticket-header-button",
          label: _ctx.buttonLabel,
          icon: "pi pi-plus",
          iconPos: "left",
          class: "p-button p-button-outlined",
          onClick: _ctx.onNewTicketReservation
        }, null, 8, ["label", "onClick"])
      ]))
    : _createCommentVNode("", true)
}