
import { defineComponent } from 'vue';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { License } from '@/models/license';
import { ToastSeverity } from '@/models/toast';
import toastUtil from '@/utils/toastUtil';
import { Log } from '@/models/log';
import { Admin } from '@/models/admin';
import { ApiLogFindPayload, ApiLogFindResponse } from '@/models/api';
import logService from '@/services/logService';

const ADMIN_MODEL_DEFAULT = {
  adminId: 0,
  email: '',
  facebook: 0,
  firstName: '',
  lastName: '',
  password: '',
  id: 0,
  role: 0,
} as Admin;

export default defineComponent({
  data() {
    return {
      loading: true,
      logsLimit: 15,
      count: 0,
      id: [] as any,
      admin: cloneDeep(ADMIN_MODEL_DEFAULT),
      logs: [] as Array<Log>,
      selectedLogs: null,
      logsTable: [
        this.$t('admins.license'),
        this.$t('admins.reservationId'),
        this.$t('admins.logId'),
        this.$t('admins.logFullName'),
        this.$t('admins.logAction'),
        this.$t('admins.logCreatedAt'),
        this.$t('admins.logUpdatedAt'),
      ],
    };
  },
  methods: {
    getLogImageSource(element: Log) {
      switch (element.action) {
        case 'import-backup-file-confirm':
        case 'import-backup-file-request':
          return require('@/assets/iconset/settings.svg');
        case 'reg-name':
          return require('@/assets/iconset/user.svg');
        case 'reg-name-edit':
          return require('@/assets/iconset/user.svg');
        case 'reg-surname':
          return require('@/assets/iconset/user.svg');
        case 'reg-surname-edit':
          return require('@/assets/iconset/user.svg');
        case 'reg-email':
          return require('@/assets/iconset/email.svg');
        case 'reg-email-edit':
          return require('@/assets/iconset/email.svg');
        case 'reg-phone':
          return require('@/assets/iconset/phone.svg');
        case 'reg-phone-edit':
          return require('@/assets/iconset/phone.svg');
        case 'reg-date-afternoon':
          return require('@/assets/iconset/calendar-today.svg');
        case 'reg-date-to':
          return require('@/assets/iconset/calendar.svg');
        case 'reg-new':
          return require('@/assets/iconset/plus.svg');
        case 'reg-date-seasonal':
          return require('@/assets/iconset/calendar.svg');
        case 'reg-date-not-seasonal':
          return require('@/assets/iconset/calendar.svg');
        case 'reg-move-to-bin':
          return require('@/assets/iconset/trash.svg');
        case 'reg-move-to':
          return require('@/assets/iconset/arrow-left.svg');
        case 'reg-date-from':
          return require('@/assets/iconset/calendar.svg');
        case 'reg-beach-amount':
          return require('@/assets/iconset/map.svg');
        case 'reg-date-day':
          return require('@/assets/iconset/calendar-today.svg');
        case 'reg-date-morning':
          return require('@/assets/iconset/calendar-today.svg');
        case 'reg-delete':
          return require('@/assets/iconset/trash.svg');
        case 'reg-restore':
          return require('@/assets/iconset/trash.svg');
        case 'reg-notes':
          return require('@/assets/iconset/document.svg');
        case 'reg-tag-add':
          return require('@/assets/iconset/tag.svg');
        case 'reg-tag-remove':
          return require('@/assets/iconset/tag.svg');
        case 'reg-status-confirmed':
          return require('@/assets/iconset/check.svg');
        case 'reg-status-available':
          return require('@/assets/iconset/check.svg');
        case 'reg-status-not-confirmed':
          return require('@/assets/iconset/question.svg');
        case 'reg-copy':
          return require('@/assets/iconset/multiple-selection.svg');
        case 'reg-maxi-beds':
          return require('@/assets/iconset/map.svg');
        case 'reg-beds':
          return require('@/assets/iconset/bed.svg');
        case 'reg-deck-chairs':
          return require('@/assets/iconset/map.svg');
        case 'reg-chairs':
          return require('@/assets/iconset/map.svg');
        case 'reg-update-cash-flow-date':
        case 'reg-update-cash-flow-type':
        case 'cash-set-fiscal':
        case 'cash-set-not-fiscal':
        case 'fiscal-printer-request':
          return require('@/assets/iconset/print.svg');
        case 'fiscal-printer-confirm':
          return require('@/assets/iconset/print.svg');
        case 'online-fiscal-printer-receipt':
          return require('@/assets/iconset/print.svg');
        case 'summary-send-whatsapp':
          return require('@/assets/iconset/phone.svg');
        case 'summary-send-email':
          return require('@/assets/iconset/email.svg');
        case 'check-in':
          return require('@/assets/iconset/check-in.svg');
        case 'check-out':
          return require('@/assets/iconset/check-out.svg');
        case 'pos-polling-interrupted':
        case 'pos-polling-expired':
        case 'pos-payment-request':
        case 'pos-payment-confirm':
          return require('@/assets/iconset/pos.svg');
        default:
          return require('@/assets/iconset/umbrella.svg');
      }
    },
    getDateString(timestamp: number): string {
      if (timestamp) {
        return DateTime.fromSeconds(timestamp)
          .setZone('Europe/Rome')
          .toFormat('dd/MM/yyyy HH:mm:ss');
      }
      return '-';
    },
    async onLogsPagination(event: any) {
      this.id = this.$route.params.id;
      const currentPage = event.page;
      this.id = this.$route.params.id;
      try {
        const res: ApiLogFindResponse = await logService.find({
          userId: this.id,
          orderBy: [
            {
              field: 'createdAt',
              value: 'desc',
            },
          ],
          page: currentPage,
          offset: this.logsLimit,
        } as ApiLogFindPayload);

        this.logs = res.result.logs;
        this.count = res.result.count;
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
  },
  computed: {
    license(): License | null {
      return this.$store.getters['session/license'];
    },
  },
  async created() {
    this.id = this.$route.params.id;
    try {
      const res: ApiLogFindResponse = await logService.find({
        userId: this.id,
        orderBy: [
          {
            field: 'createdAt',
            value: 'desc',
          },
        ],
        page: 0,
        offset: this.logsLimit,
      } as ApiLogFindPayload);

      this.logs = res.result.logs;
      this.count = res.result.count;
      this.loading = false;
    } catch (error) {
      this.$toast.add(
        toastUtil.build(
          ToastSeverity.ERROR,
          this.$t('toast.error.title'),
          this.$t('toast.error.msg'),
        ),
      );
    }
  },
});
