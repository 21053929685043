import { DateTime } from 'luxon';
import { Staging } from '.';
import { DiscountCode } from './discountCode';
import { RefundType } from './refund';
import { ReservationHalfDay, ReservationStatus } from './reservation';
import { SpotAttribute, SpotType } from './spot';
import { StyleSpotAttribute } from './style';

export const MAP_SPOT_WIDTH = 60;
export const MAP_SPOT_HEIGHT = 40;
export const MAP_PAN_MOBILE_SENSIBILITY = 10;

export type MapCalendar = [DateTime, null] | [DateTime, DateTime];

export enum MapType {
  STANDARD = 'standard',
  EDITOR = 'editor',
  STATISTICS = 'statistics',
}

export enum MapDecorationOrientation {
  NONE = '',
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

export enum MapDecorationType {
  FOOTBOARD = 'footboard',
  LINE = 'line',
  LABEL = 'label',
  /* element type */
  CUSTOM = 'custom',
  BENCH = 'bench',
  CAR = 'car',
  CHIRINGUITO = 'chiringuito',
  RECEPTION = 'reception',
  DESK = 'desk',
  BEACH_VOLLEY = 'beach-volley',
  BASKET_FIELD = 'basket-field',
  COURT = 'court',
  SOCCER_FIELD = 'soccer-field',
  LIFEBELT = 'lifebelt',
  GYM_BARBELL = 'gym-barbell',
  GYM_EXERCISE = 'gym-exercise',
  GYM_TREADMILL = 'gym-treadmill',
  PALM = 'palm',
  PLAYGROUND = 'playground',
  POOL = 'pool',
  POOL_ROUNDED = 'pool-rounded',
  SHOWER = 'shower',
  ROWBOAT = 'rowboat',
  TENT = 'tent',
  TENT_BIG = 'tent-big',
  TREE = 'tree',
  TREE1 = 'tree1',
}
export interface MapDecoration {
  id?: number; // map editor
  width: number;
  height: number;
  rotation: number;
  posX: number;
  posY: number;
  type: MapDecorationType;
  name: string; // text
  orientation: MapDecorationOrientation; // footboard, line
  backgroundColor: string; // line
  backgroundUrl: string; // check
}

export interface MapSpot {
  id: number | null;
  name: string;
  type: SpotType;
  posX: number;
  posY: number;
  width: number;
  height: number;
  background: string;
  customerName: string;
  staging: Staging | null;
  status: ReservationStatus | null;
  startDate: number | null;
  endDate: number | null;
  halfDay: ReservationHalfDay;
  fullDay: boolean;
  paid: boolean;
  servicesIcon: Array<string>;
  locked: boolean;
  voucher: number;
  notes: string;
  /* status */
  overbooking: boolean;
  sharing: boolean;
  seasonal: boolean;
  icon: string;
  style: StyleSpotAttribute;
  attributes: Array<SpotAttribute>;
}

// da far saltare per poi usare SpotAttribute
export enum MapSpotStatus {
  FREE,
  OVERBOOKING,
  UNPAID,
  UNPAID_LAST_DAY,
  SHARING,
  SEASONAL,
  BUSY,
  TEMPORARY,
  HALF_DAY_MORNING,
  HALF_DAY_AFTERNOON,
  FULL_DAY,
}

/* MAP ELEMENTS */

export enum MapElementType {
  /* with interaction */
  UMBRELLA = 'umbrella',
  GAZEBO = 'gazebo',
  PARKING = 'parking',
  BED = 'bed',
  BEDS = 'beds',
  CABIN = 'cabins',
  BOAT = 'boat',
  PLAYFIELD = 'playfield',
  /* without interaction */
  FOOTBOARD = 'footboard',
  MAP_ELEMENT = 'element',
  MAP_LINE_H = 'mapLineH',
  MAP_LINE_V = 'mapLineV',
  MAP_LINE = 'mapLine',
  MAP_LABEL = 'mapLabel',
}

export interface MapBaseElement {
  width: number;
  height: number;
  type: MapElementType;
  name: string;
  x: number;
  y: number;
  background: string | null;
}

export interface MapSpotElement extends MapBaseElement {
  customerName: string | null;
  /* reservation */
  id: number | null;
  status: ReservationStatus | null;
  endDate: number | null;
  halfDay: ReservationHalfDay;
  paid: boolean;
  servicesIcon: Array<string>;
  locked: boolean;
  voucher: number;
  notes: string;
  /* status */
  overbooking: boolean;
  sharing: boolean;
  seasonal: boolean;
  icon: string;
  style: StyleSpotAttribute;
  attributes: Array<SpotAttribute>;
}

export interface MapStagingElement extends MapSpotElement {
  beds: number;
  maxiBeds: number;
  chairs: number;
  deckChairs: number;
}

export interface MapDecorativeElement extends MapBaseElement {
  decoration: string;
  rotation: number;
  url: string | null;
  orientation: string | null;
}

export type MapElement =
  | MapBaseElement
  | MapSpotElement
  | MapStagingElement
  | MapDecorativeElement;

export interface MapBackground {
  height: number;
  width: number;
  posX: number;
  posY: number;
  url: string;
}

export enum MapFilter {
  FULL,
  NUMBER,
  STAGING,
  EXPIRATION,
}

export enum MapMode {
  NONE,
  MULTIPLE_SELECTION,
  SHIFT,
  COPY, // from reservation
  PROGRAMMED_SHIFT, // from reservation
}

export enum MapShiftMode {
  TODAY,
  TOMORROW,
  CUSTOM,
}

export enum MapView {
  FULL = 'square',
  NUMBERS = 'number',
  DATES = 'end-date',
  STAGING = 'service',
  PLANNER = 'planning',
}

export interface MapPlannerDataToPay {
  id: number;
  startDate: number;
  endDate: number;
  notes: string;
}
export interface MapPlannerData {
  preview: Record<number, number> | null;
  price: number | null;
  toPay: Array<MapPlannerDataToPay>;
}

export interface MapPlanner {
  active: boolean;
  data: MapPlannerData | null;
  spot: MapSpot | null;
}

export interface MapSpotStatusData {
  status: MapSpotStatus;
  field: string;
}

export const MAP_SPOT_STATUS_DATA: Array<MapSpotStatusData> = [
  {
    status: MapSpotStatus.FREE,
    field: 'mapColorFree',
  },
  {
    status: MapSpotStatus.BUSY,
    field: 'mapColorReserved',
  },
  {
    status: MapSpotStatus.OVERBOOKING,
    field: 'mapColorReserved',
  },
  {
    status: MapSpotStatus.SHARING,
    field: 'mapColorTemporary',
  },
  {
    status: MapSpotStatus.UNPAID,
    field: 'mapColorNotPaid',
  },
  {
    status: MapSpotStatus.HALF_DAY_MORNING,
    field: 'mapColorHalfDayMorning',
  },
  {
    status: MapSpotStatus.HALF_DAY_AFTERNOON,
    field: 'mapColorHalfDayAfternoon',
  },
  {
    status: MapSpotStatus.FULL_DAY,
    field: 'mapColorFullDay',
  },
  {
    status: MapSpotStatus.SEASONAL,
    field: 'mapColorSeasonal',
  },
  {
    status: MapSpotStatus.TEMPORARY,
    field: 'mapColorTemporary',
  },
];

export interface MapSpotData {
  backgroundColor?: string;
  elementType?: string;
  height?: number;
  width?: number;
  rotation?: string;
  url?: string;
}

export interface MapUmbrellaSpot {
  label: string;
  left: number;
  top: number;
  staging: string;
  expiration: string;
}

export enum MapShiftStep {
  SELECT_FROM,
  SELECT_MODE,
  SELECT_TO,
  CONFIRM,
}

export interface MapShift {
  step: MapShiftStep;
  from: MapSpotElement | null;
  mode: MapShiftMode;
  range: [DateTime, DateTime];
  to: MapSpotElement | null;
}

export enum MapElementTypeRaw {
  /* with interaction */
  UMBRELLA = 'umbrella',
  GAZEBO = 'gazebo',
  PARKING = 'parking',
  BED = 'bed',
  BEDS = 'beds',
  CABIN = 'cabins',
  BOAT = 'boat',
  PLAYFIELD = 'playfield',
  /* without interaction (decorations) */
  FOOTBOARD = 'footboard',
  ELEMENT = 'element',
  MAP_LINE_H = 'mapLineH',
  MAP_LINE_V = 'mapLineV',
  MAP_LABEL = 'mapLabel',
}

export enum MapDecorationTypeRaw {
  CUSTOM = 'custom',
  BENCH = 'bench',
  CAR = 'car',
  CHIRINGUITO = 'chiringuito',
  RECEPTION = 'reception',
  DESK = 'desk',
  BEACH_VOLLEY = 'beach-volley',
  BASKET_FIELD = 'basket-field',
  COURT = 'court',
  SOCCER_FIELD = 'soccer-field',
  LIFEBELT = 'lifebelt',
  GYM_BARBELL = 'gym-barbell',
  GYM_EXERCISE = 'gym-exercise',
  GYM_TREADMILL = 'gym-treadmill',
  PALM = 'palm',
  PLAYGROUND = 'playground',
  POOL = 'pool',
  POOL_ROUNDED = 'pool-rounded',
  SHOWER = 'shower',
  ROWBOAT = 'rowboat',
  TENT = 'tent',
  TENT_BIG = 'tent-big',
  TREE = 'tree',
  TREE1 = 'tree1',
}

export interface MapElementRaw {
  t: MapElementTypeRaw; // type
  pX: number; // position x
  pY: number; // position y
  n: string; // name
  bgc?: string; // background
  cn?: string; // customer name
  rid?: number | null; // reservation id
  rs?: number; // reservation status
  red?: number; // reservation end date
  rhd?: ReservationHalfDay; // reservation half date
  rfd?: boolean; // reservation full day
  rp?: boolean; // reservation paid
  rse?: boolean; // reservation seasonal
  rsv?: Array<string>; // reservation services icon
  rl?: boolean; // reservation locked
  rmb?: number; // reservation maxi beds
  rc?: number; // reservation chairs
  rdc?: number; // reservation deck chairs
  rb?: number; // reservation beds
  ob?: boolean; // overbooking
  sh?: boolean; // sharing
  fo?: string; // footboard orientation (v/o)
  et?: MapDecorationTypeRaw; // decorative element type
  ew?: number; // decorative element width
  eh?: number; // decorative element height
  er?: number; // decorative element rotation
  mlt?: string; // map label text
  url?: string; // custom element image url
  rsd?: number; // reservation start date
  rv?: number; // reservation voucher id
  rn?: string; // reservation notes
  o?: boolean; // reservation from booking
  s?: number; // spot sector
}

export interface MapDataElements {
  decorations: Array<MapDecoration>;
  spots: Array<MapSpot>;
}

export interface MapData {
  height: number;
  width: number;
  elements: MapDataElements;
  background: MapBackground;
}

export interface MapRefundConfirmation {
  type: RefundType.STRIPE | RefundType.CODE;
  value: number;
  reservationId: number;
  discountCode: DiscountCode | null;
}
