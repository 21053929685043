import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Teleport as _Teleport, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e992beaa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header-flex" }
const _hoisted_2 = { class: "list-title-box" }
const _hoisted_3 = { class: "actions-list" }
const _hoisted_4 = {
  id: "price-lists-price-list-detail-view",
  class: "price-lists-view view"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_PriceListDetails = _resolveComponent("PriceListDetails")!
  const _component_PriceListsPriceListCreate = _resolveComponent("PriceListsPriceListCreate")!
  const _component_PriceListsPriceListSwitch = _resolveComponent("PriceListsPriceListSwitch")!
  const _component_PriceListsPriceListClone = _resolveComponent("PriceListsPriceListClone")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.mounted && _ctx.priceList)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "#price-lists-header-teleport"
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t('priceListsPriceListDetailView.title')) + " " + _toDisplayString(_ctx.priceList.name), 1),
              _createElementVNode("p", null, _toDisplayString(_ctx.$t('priceListsPriceListDetailView.subtitle')), 1)
            ]),
            _createElementVNode("div", _hoisted_3, [
              (!_ctx.generatedByWizard)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 0,
                    label: _ctx.$t('priceListsPriceListDetailView.settings'),
                    icon: "spi-settings",
                    class: "p-button-outlined",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showEditDialog = true))
                  }, null, 8, ["label"]))
                : _createCommentVNode("", true),
              _createVNode(_component_Button, {
                label: _ctx.$t('button.replace'),
                icon: "spi-switch",
                class: "p-button-outlined",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showSwitchDialog = true))
              }, null, 8, ["label"]),
              (_ctx.generatedByWizard)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 1,
                    label: _ctx.$t('button.edit'),
                    icon: "spi-edit",
                    iconPos: "left",
                    class: "p-button-outlined",
                    onClick: _ctx.onWizard
                  }, null, 8, ["label", "onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_Button, {
                label: _ctx.$t('priceListsPriceListDetailView.qualityCheck'),
                icon: "spi-file-check-alt",
                iconPos: "left",
                class: "p-button-outlined",
                onClick: _ctx.onQualityCheck
              }, null, 8, ["label", "onClick"]),
              (_ctx.priceList.activePriceConfigId !== null)
                ? (_openBlock(), _createBlock(_component_Button, {
                    key: 2,
                    label: _ctx.$t('priceListsPriceListDetailView.clone'),
                    icon: "spi-exclamation-triangle",
                    iconPos: "left",
                    class: "p-button-outlined",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showCloneDialog = true))
                  }, null, 8, ["label"]))
                : _createCommentVNode("", true),
              _createVNode(_component_Button, {
                label: _ctx.$t('button.delete'),
                icon: "spi-trash",
                iconPos: "left",
                class: "p-button p-button-danger p-button-outlined",
                onClick: _ctx.onDelete
              }, null, 8, ["label", "onClick"])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.priceList)
        ? (_openBlock(), _createBlock(_component_PriceListDetails, {
            key: 0,
            priceList: _ctx.priceList,
            onUpdatePeriods: _ctx.onUpdatePeriods
          }, null, 8, ["priceList", "onUpdatePeriods"]))
        : _createCommentVNode("", true),
      (_ctx.showEditDialog)
        ? (_openBlock(), _createBlock(_component_PriceListsPriceListCreate, {
            key: 1,
            priceList: _ctx.priceList,
            onUpdate: _cache[3] || (_cache[3] = ($event: any) => (_ctx.priceList = $event)),
            onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showEditDialog = false))
          }, null, 8, ["priceList"]))
        : _createCommentVNode("", true),
      (_ctx.showSwitchDialog)
        ? (_openBlock(), _createBlock(_component_PriceListsPriceListSwitch, {
            key: 2,
            priceList: _ctx.priceList,
            onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showSwitchDialog = false)),
            onSwitch: _ctx.onSwitch
          }, null, 8, ["priceList", "onSwitch"]))
        : _createCommentVNode("", true),
      (_ctx.showCloneDialog)
        ? (_openBlock(), _createBlock(_component_PriceListsPriceListClone, {
            key: 3,
            priceList: _ctx.priceList,
            onUpdate: _cache[6] || (_cache[6] = ($event: any) => (_ctx.priceList = $event)),
            onClose: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showCloneDialog = false))
          }, null, 8, ["priceList"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}