
import axios, { AxiosError } from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import { computed, defineComponent } from 'vue';
import { mapGetters, mapState, useStore } from 'vuex';
import { DateTime } from 'luxon';
import orderBy from 'lodash/orderBy';
import { fromByteArray } from 'base64-js';
import { debounce } from 'lodash';
import { DropdownChangeEvent } from 'primevue/dropdown';
import {
  InvoiceDeposit,
  Print,
  PrintArticle,
  PrintDeposit,
  Printer,
  PrinterError,
  PrinterFiscalResponse,
  PrintMode,
} from '@/models/printer';
import ReservationDocumentation from '@/components/reservation/payment/ReservationDocumentation.vue';
import ReservationCashFlows from '@/components/reservation/payment/ReservationCashFlows.vue';
import {
  ApiCashFlowCreatePayload,
  ApiFiscalReservationFlowsPayload,
  ApiLogCreatePayload,
  ApiReservationDeletePayload,
  ApiReservationUpdatePayload,
  ApiReservationSendInvoicePayload,
  ApiStripeCreatePaymentIntentPayload,
  ApiWebticOnsiteEmitTicketPayload,
} from '@/models/api';
import {
  CashFlowMethod,
  CASH_FLOW_METHOD_OPTIONS,
  CashFlow as CashFlowI,
  CashFlowMethodOption,
  CashFlow,
  CashFlowUpdateModel,
} from '@/models/cashFlow';
import CashFlows from '@/components/shared/CashFlows.vue';
import ExtentionChromeMessage from '@/components/shared/ExtentionChromeMessage.vue';
import InvoiceDialog from '../shared/InvoiceDialog.vue';
import { AppAction } from '@/models/app';
import ReservationLogs from '@/components/reservation/payment/ReservationLogs.vue';
import ReservationDelete from '@/components/reservation/payment/ReservationDelete.vue';
import { DeviceSpecs, RootState } from '@/models/store';
import {
  Reservation,
  ReservationDeleteMode,
  ReservationExpense,
  ReservationExpenseItem,
  ReservationExpensePaid,
  ReservationFiscalPrint,
  ReservationService,
} from '@/models/reservation';
import {
  BEACH_TICKET_SERVICE_ID,
  DISCOUNT_CODE_SERVICE_ID,
  Service,
} from '@/models/service';
import { Voucher } from '@/models/voucher';
import { InvoiceCustomer } from '@/models/invoice';
import reservationService from '@/services/reservationService';
import { Customer } from '@/models/customer';
import { Card } from '@/models/card';
import { DropdownOption, ListElementNumber } from '@/models';
import { CabinMode, PosCommandPaper } from '@/models/license';
import { LogAction } from '@/models/log';
import cookieUtil from '@/utils/cookieUtil';
import { RefundType } from '@/models/refund';
import DisjoinAccountButton from '../shared/DisjoinAccountButton.vue';
import localStorageUtil from '@/utils/localStorageUtil';
import permissionsUtil from '@/utils/permissionsUtil';
import PosTerminalPayment from '@/components/shared/PosTerminalPayment.vue';
import WebticTicketEmission from '@/components/shared/WebticTicketEmission.vue';
import logService from '@/services/logService';
import printerUtil from '@/utils/printerUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';
import { StripePaymentIntent } from '@/models/stripe';
import cashFlowService from '@/services/cashFlowService';
import { ToastSeverity } from '@/models/toast';
import ReservationSummaryDiscountCode from './ReservationSummaryDiscountCode.vue';
import currencyUtil from '@/utils/currencyUtil';

interface CustomerDataToSync {
  invoiceData: InvoiceCustomer;
  customer: Customer;
}

export default defineComponent({
  name: 'ReservationPayment',
  components: {
    ReservationDocumentation,
    ReservationLogs,
    ReservationDelete,
    ReservationCashFlows,
    CashFlows,
    ExtentionChromeMessage,
    InvoiceDialog,
    DisjoinAccountButton,
    PosTerminalPayment,
    WebticTicketEmission,
    ReservationSummaryDiscountCode,
  },
  data() {
    return {
      voucherHotelOptions: [
        {
          label: this.$t('common.yes'),
          value: true,
        },
        {
          label: this.$t('common.no'),
          value: false,
        },
      ],
      cardList: [] as Array<ListElementNumber>,
      cardIdSelected: 0,
      withAcc: false,
      /* PRINTERS */
      builtPrint: {},
      fiscalPrinterDialog: false,
      nonFiscalPrinterDialog: false,
      printArticles: [] as Array<PrintArticle>,
      printDeposit: [] as Array<PrintDeposit>,
      invoiceDeposits: [] as Array<InvoiceDeposit>,
      selectedPrinter: null as unknown as Printer,
      canPayFiscalCashFlow: true,
      canPayNoFiscalCashFlow: true,
      /* CASH FLOW */
      cashFlowMethod: CashFlowMethod.CASH,
      totalWithoutFiscAcc: 0,
      timeoutId: 0,
      // PAYMENT
      isInvoiceIssue: false,
      invoicePaymentDialog: false,
      // MISC
      showTotalAppliedPriceConfirm: false,
      showBeachAppliedPriceConfirm: false,
      showServicesAppliedPriceConfirm: false,
      // RECEIPT DISCOUNT
      discount: 0,
      // POS
      isPrintingAfterPOS: false,
      stripePaymentIntent: null as unknown as StripePaymentIntent,
      posCashFlow: null as unknown as CashFlow,
      // WEBTIC
      SIAE_INVOICE_ID: 1,
      debounceUpdateReservation: debounce(
        (payload: ApiReservationUpdatePayload): void => {
          this.$store.dispatch('reservation/updateReservation', payload);
        },
        350,
      ),
    };
  },
  mounted() {
    this.$spiagge.utils.printMode.setup();
  },
  setup() {
    const store = useStore<RootState>();

    const discountServicePrice = computed<number>(() => {
      const discountService = store.state.reservation.services.find(
        (service) => service.serviceId === DISCOUNT_CODE_SERVICE_ID,
      );
      return discountService?.price ?? 0;
    });

    return {
      discountServicePrice,
    };
  },
  methods: {
    selectInputNumber(event: FocusEvent) {
      if (!(event.target instanceof HTMLInputElement)) {
        return;
      }

      const target = event.target;
      const split = target.value.trim().split('');
      if (split.length > 2) {
        setTimeout(() => {
          target.setSelectionRange(0, split.length - 2);
        });
      }
    },
    showOriginalValue(v1: unknown, v2: unknown): boolean {
      return (
        v1 !== null &&
        v2 !== null &&
        this.formatPrice(v1) !== this.formatPrice(v2)
      );
    },
    formatPrice(value: unknown): string {
      if (typeof value !== 'number') {
        return '';
      }

      return currencyUtil.format(value, this.$i18n.locale);
    },
    onFiscalPrinterDialogClose(): void {
      this.fiscalPrinterDialog = false;
      this.$store.commit('reservation/setIsFiscalPrinting', false);
    },
    resetPosFiscalFlow(): void {
      this.isPrintingAfterPOS = false;
      this.depositTransferLocal = null;
      this.stripePaymentIntent = null as unknown as StripePaymentIntent;
      this.posCashFlow = null as unknown as CashFlow;
    },
    onCashFlowsAbort(): void {
      /**
       * Reset printing after pos flags
       */
      if (this.isPrintingAfterPOS) {
        this.resetPosFiscalFlow();
      }
    },
    async onExitWithoutSave(): Promise<void> {
      if (this.action === AppAction.CREATE_RESERVATION) {
        await this.$store.dispatch('reservation/deleteReservation', {
          id: this.id,
          refundType: RefundType.NONE,
          mode: ReservationDeleteMode.DEL,
          creating: true,
        } as ApiReservationDeletePayload);
      }
      this.onClose();
    },
    onSaveAndExit(): void {
      // maybe patch all?
      this.onClose();
    },
    onClose(): void {
      this.$store.commit('reservation/setCanExit', true);
      this.$store.commit('app/setAction', AppAction.NONE);
      this.$router.push(this.$store.getters['app/returnToRoute'] ?? '/map');
    },
    /* PRINTERS METHOD */
    async onFiscalPrint(printer: Printer): Promise<void> {
      /**
       * Stampa fiscale
       */

      // pulsante disattivato
      if (!this.canPayFiscalCashFlow) {
        return;
      }

      // 'start' fiscal printing
      this.$store.commit('reservation/setIsFiscalPrinting', true);

      // ci sono richieste pendenti (ad es. è stato applicato un prezzo forzato), attendo
      while (this.httpPendingRequests) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((r) => setTimeout(r, 500));
      }

      this.isInvoiceIssue = false;
      this.selectedPrinter = printer;

      // acconti fiscali
      const fisAcc = this.cashFlows
        .filter((element: CashFlow) => element.receiptId || element.invoiceId)
        .reduce(
          (total: number, element: CashFlow) => total + element.amount,
          0,
        );

      // totale senza acconti fiscali
      this.totalWithoutFiscAcc = this.total - fisAcc;

      // costruzione scontrino
      this.builtPrint = printerUtil.buildPrint(
        PrintMode.FISCAL,
        this.cashFlowMethod,
      );

      // non so il motivo sinceramente.. forse un gigio copia/incolla
      if (!this.canExit) {
        this.$store.commit('reservation/setCanExit', true);
        this.$store.commit('app/setAction', AppAction.NONE);
      }

      if (this.isPrintingAfterPOS) {
        this.printFiscalAll(false);
        return;
      }

      // ci sono acconti e non sono tutti fiscalizzati (almeno uno non è fiscale)
      if (this.hasDeposit && !this.hasFiscalDepositsOnly) {
        // pago tutto -> chiedo all'utente se vuole fiscalizzare con/senza acconto
        // altrimenti stampo senza acconto
        if (this.depositTransferLocal === null) {
          this.fiscalPrinterDialog = true;
        } else {
          this.printFiscalAll(false);
        }
      } else {
        // stampo senza acconto
        this.printFiscalAll(false);
      }
    },
    async onNonFiscalPrint(printer: Printer): Promise<void> {
      // disable double click
      if (!this.canPayNoFiscalCashFlow) {
        return;
      }

      // wait for pending requests (like forced price)
      while (this.httpPendingRequests) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((r) => setTimeout(r, 500));
      }

      this.selectedPrinter = printer;
      this.nonFiscalPrinterDialog = true;
      if (!this.canExit) {
        this.$store.commit('reservation/setCanExit', true);
        this.$store.commit('app/setAction', AppAction.NONE);
      }
    },
    printFiscalAll(includeNoFisDeposits: boolean): void {
      // chiudo modale scelta stampante
      this.fiscalPrinterDialog = false;

      // preparo la lista articoli e depositi scontrino
      this.getListOfArticle(includeNoFisDeposits);

      this.withAcc = includeNoFisDeposits;
      if (!this.isInvoiceIssue) {
        // scontrino
        this.printFunction(this.selectedPrinter, PrintMode.FISCAL, true);
      } else {
        // fattura
        this.invoicePaymentDialog = true;
      }
      this.resetFiscalCashFlowsButtons();
    },
    onPreAccountPrint(): void {
      this.nonFiscalPrinterDialog = false;
      this.getListOfArticle(true, true);
      this.printFunction(this.selectedPrinter, PrintMode.NON_FISCAL, false);
    },
    onPrintAndCollect(): void {
      this.getListOfArticle(true, true);
      this.nonFiscalPrinterDialog = false;
      this.printFunction(this.selectedPrinter, PrintMode.NON_FISCAL, true);
      if (!this.paid) {
        this.resetNoFiscalCashFlowsButtons();
      }
    },
    onCloseInvoiceDialog() {
      this.invoicePaymentDialog = false;
      this.isInvoiceIssue = false;
    },
    async onInvoiceIssue(): Promise<void> {
      if (this.paid) {
        return;
      }
      // disable double click
      if (!this.canPayFiscalCashFlow) {
        return;
      }

      // wait for pending requests (like forced price)
      while (this.httpPendingRequests) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((r) => setTimeout(r, 500));
      }

      this.isInvoiceIssue = true;
      let fisAcc = 0;
      this.cashFlows
        .filter((element: any) => element.receiptId || element.invoiceId)
        .forEach((element: any) => {
          fisAcc += element.amount;
        });
      this.totalWithoutFiscAcc = this.total - fisAcc;
      if (
        this.depositTransferLocal !== null ||
        !this.hasDeposit ||
        this.hasFiscalDepositsOnly
      ) {
        this.invoicePaymentDialog = true;
      } else {
        this.fiscalPrinterDialog = true;
      }
    },
    // Invoice
    async onInvoicePayment(customerInvoiceReceived: InvoiceCustomer) {
      // Prevent double-click
      this.canPayFiscalCashFlow = false;
      // Retrieve products
      this.getListOfArticle(this.withAcc);
      const invoiceProducts = this.printArticles;
      const invoiceDeposits = this.invoiceDeposits;
      const data = this.updateCustomerInvoiceData(
        customerInvoiceReceived,
        this.customer,
      );
      const invoiceCustomer = data.invoiceData;
      const customer = data.customer;

      let noFisAcc = 0;
      this.cashFlows
        .filter((element: any) => !element.receiptId && !element.invoiceId)
        .forEach((element: any) => {
          noFisAcc += element.amount;
        });
      let fisAcc = 0;
      this.cashFlows
        .filter((element: any) => element.receiptId || element.invoiceId)
        .forEach((element: any) => {
          fisAcc += element.amount;
        });
      const payload = {} as ApiReservationSendInvoicePayload;
      payload.products = invoiceProducts;
      payload.deposits = invoiceDeposits;
      payload.customer = invoiceCustomer;
      payload.cashFlow = {
        reservationId: this.id,
        amount: this.totalToPay,
        invoiceAmount: this.totalToPay,
        method: this.cashFlowMethod,
        notes: '',
        expenses: [],
        cardId:
          this.cashFlowMethod !== CashFlowMethod.YB_CARD
            ? undefined
            : this.cards && this.cards.length > 0
              ? this.cards.idWorldCard
              : undefined,
      };
      /* check expenses */
      if (
        this.expenses &&
        this.expenses.length > 0 &&
        this.depositTransferLocal === null
      ) {
        payload.cashFlow.expenses = this.expenses
          .filter(
            (element: ReservationExpense) =>
              element.paid === ReservationExpensePaid.NOT_PAID,
          )
          .map((element: ReservationExpense) => element.id);
      }
      /* check selected exenses */
      if (
        this.selectedExpensesStored &&
        this.selectedExpensesStored.length > 0
      ) {
        payload.cashFlow.expenses = this.selectedExpensesStored.map(
          (element: ReservationExpense) => element.id,
        );
      }
      if (this.depositTransferLocal !== null && this.depositTransferLocal > 0) {
        payload.cashFlow.amount = this.depositTransferLocal;
        payload.cashFlow.invoiceAmount = this.depositTransferLocal;
        payload.cashFlow.expenses = [] as Array<number>;
      } else if (this.withAcc) {
        payload.cashFlow.amount = this.total - fisAcc - noFisAcc;
        payload.cashFlow.invoiceAmount = this.total - fisAcc;
      } else {
        payload.cashFlow.amount = this.total - fisAcc - noFisAcc;
        payload.cashFlow.invoiceAmount = this.total - fisAcc - noFisAcc;
      }

      this.withAcc = false;

      try {
        await this.$store.dispatch('reservation/sendInvoice', payload);
        this.$spiagge.toast.success(
          this.$t('reservationPayment.toast.invoiceSuccess'),
        );
        await this.$store.dispatch('reservation/updateReservation', customer);

        /**
         * Reset the fiscal cash flow button
         * after the end of the call
         */
        this.canPayFiscalCashFlow = true;

        this.invoicePaymentDialog = false;
        this.isInvoiceIssue = false;
        this.depositTransferLocal = null;
        // Refresh cashflows
        await this.$store.dispatch('reservation/getCashFlows');

        if (!this.canExit) {
          this.$store.commit('reservation/setCanExit', true);
          this.$store.commit('app/setAction', AppAction.NONE);
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const axiosError: AxiosError = error;

          if (axiosError.response?.status === 422) {
            const errors = axiosError.response.data.error_ex?.errors;
            if (errors !== null) {
              this.$toast.add({
                severity: ToastSeverity.ERROR,
                life: 10000,
                group: 'invoice',
                detail: Object.keys(errors)
                  .flatMap((key) => errors[key])
                  .join('<br />'),
              });
            } else {
              this.$spiagge.toast.error(
                this.$t('reservationPayment.toast.invoiceDataError'),
              );
            }
          }
        }

        this.$spiagge.toast.error(
          this.$t('reservationPayment.toast.paymentError'),
        );
      }
    },
    updateCustomerInvoiceData(
      invoiceCustomer: InvoiceCustomer,
      customer: Customer,
    ): CustomerDataToSync {
      const invoiceCustomerLocal = cloneDeep(invoiceCustomer);
      const customerLocal = cloneDeep(customer);

      customerLocal.invoiceCompany = invoiceCustomerLocal.invoiceCompany;
      customerLocal.invoiceVatCode = invoiceCustomerLocal.invoiceVatCode;
      customerLocal.invoiceTaxCode = invoiceCustomerLocal.invoiceTaxCode;
      customerLocal.invoiceAddress1 = invoiceCustomerLocal.invoiceAddress1;
      customerLocal.invoiceAddress2 = invoiceCustomerLocal.invoiceAddress2;
      customerLocal.invoiceCity = invoiceCustomerLocal.invoiceCity;
      customerLocal.invoiceZip = invoiceCustomerLocal.invoiceZip;
      customerLocal.invoiceState = invoiceCustomerLocal.invoiceState;
      customerLocal.invoiceCountry = invoiceCustomerLocal.invoiceCountry;
      customerLocal.invoicePec = invoiceCustomerLocal.invoicePec;
      customerLocal.invoiceSdi = invoiceCustomerLocal.invoiceSdi;
      customerLocal.invoiceLotteryCode =
        invoiceCustomerLocal.invoiceLotteryCode;

      return {
        invoiceData: invoiceCustomerLocal,
        customer: customerLocal,
      } as CustomerDataToSync;
    },
    printFunction(printer: Printer, printMode: PrintMode, pay: boolean): void {
      /* CONTROLLI */

      // acconto con valore negativo
      if (this.depositTransferLocal !== null && this.depositTransferLocal < 0) {
        // Check refundable receipted amount
        let refundableReceiptedAmount = 0;
        let receiptNumberToRefund = '';
        let receiptDateToRefund = '';
        this.cashFlows.forEach((cashFlow: CashFlow) => {
          if (
            cashFlow.receipt &&
            cashFlow.receipt.number &&
            cashFlow.receipt.total &&
            parseFloat(cashFlow.receipt.total)
          ) {
            refundableReceiptedAmount += parseFloat(cashFlow.receipt.total);

            if (
              parseFloat(cashFlow.receipt.total) > 0 &&
              !cashFlow.receipt.refunded
            ) {
              receiptNumberToRefund = cashFlow.receipt.number;
              receiptDateToRefund = cashFlow.receipt.date;
            }
          }
        });

        if (refundableReceiptedAmount + this.depositTransferLocal < 0) {
          // console.log('Negative error');
          this.$spiagge.toast.warn(
            this.$t('reservationPayment.toast.negativeValueReceiptError'),
          );
          return;
        }

        // Emit refund receipt.
        const virtualCashFlow: CashFlowUpdateModel = {
          id: 0,
          method: this.cashFlowMethod,
          amount: this.depositTransferLocal * -1,
          date: DateTime.now(),
          notes: '',
          fiscal: false,
          receipt: {
            printerId: printer.id,
            total: this.depositTransferLocal * -1,
            number: receiptNumberToRefund,
            date: receiptDateToRefund,
            paymentMethod:
              this.$spiagge.utils.printMode.paymentMethodToPrintMethod(
                this.cashFlowMethod,
              ),
          },
        };
        this.printRefundFunction(virtualCashFlow, true);
        return;
      }

      // stampa totale fiscale ma la prenotazione è già pagata con acconti fiscali
      if (
        this.depositTransferLocal === null &&
        printMode === PrintMode.FISCAL &&
        this.paid &&
        this.hasFiscalDepositsOnly
      ) {
        this.$store.commit('reservation/setIsFiscalPrinting', false);
        this.$spiagge.toast.warn(
          this.$t('reservationPayment.toast.paidReservationError'),
        );
        return;
      }

      // stampa totale (non fiscale) ma la prenotazione è gia pagata e si vuole pagare
      if (
        this.depositTransferLocal === null &&
        printMode !== PrintMode.FISCAL &&
        this.paid &&
        pay
      ) {
        this.$spiagge.toast.warn(
          this.$t('reservationPayment.toast.paidReservationError'),
        );
        return;
      }

      // stampa totale fiscale ma la prenotazione è già pagata ma ci sono acconti non fiscali
      if (
        this.depositTransferLocal === null &&
        printMode === PrintMode.FISCAL &&
        this.paid &&
        !this.hasFiscalDepositsOnly
      ) {
        this.getListOfArticle(true);
      }

      // controllo dati stampante
      if (!printer || !printer.id) {
        return;
      }

      /* INIZIO */

      // costruzione scontrino
      const print = this.$spiagge.utils.printMode.buildPrint(
        printMode,
        this.cashFlowMethod,
      );
      print.articles = cloneDeep(this.printArticles);
      print.deposits = cloneDeep(this.printDeposit);

      // se la prenotazione è già pagata e c'è un flusso
      // di cassa non fiscale allora setto il suo metodo
      // (fiscalizzare tutti gli acconti non fiscali)
      const notFiscalCashflowsWithPaymentMethod = this.cashFlows.filter(
        (cf: CashFlow) => cf.method && !cf.invoiceId && !cf.receiptId,
      );
      if (this.paid && notFiscalCashflowsWithPaymentMethod.length > 0) {
        print.paymentMethod =
          this.$spiagge.utils.printMode.paymentMethodToPrintMethod(
            notFiscalCashflowsWithPaymentMethod[0].method,
          );
      }

      // in caso di pagamento totale bisogna settare lo sconto in caso di total forzato
      if (this.depositTransferLocal === null && this.totalAppliedPrice) {
        const discountPriceForced =
          this.totalBeforeForcedTotal - this.totalAppliedPrice;
        if (discountPriceForced > 0) {
          this.discount += discountPriceForced;
        } else {
          // dobbiamo aggiungere il supplemento alla componente spiaggia della ricevuta
          print.articles[0].price += -1 * discountPriceForced;
        }
      }

      // sconto
      print.discount = this.discount;

      // qr code
      if (this.license.beachTickets > 0) {
        print.codeType = 'QR';
        print.codeText = this.qrCode;
      }

      // nome cliente
      if (
        this.license?.receiptPrintCustomerName &&
        (this.customer.firstName !== '' || this.customer.lastName !== '')
      ) {
        print.notes = `${this.customer.firstName ?? ''} ${
          this.customer.lastName ?? ''
        }`;
      }

      // in caso di stampa fiscale loggo l'azione e dispatcho la routine
      if (printMode === PrintMode.FISCAL) {
        // create log of fiscal payment
        const logCreatePayload = {
          reservationId: this.id,
          action: LogAction.FISCAL_PRINTER_REQUEST,
          value: JSON.stringify({
            printerId: printer.id,
            printerName: printer.name,
            content: print,
          }),
        } as ApiLogCreatePayload;

        this.$store.dispatch('reservation/fiscalPrintStart', {
          reservationId: this.id,
          startedAt: DateTime.now(),
          printer,
          print,
          mode: printMode,
          callback: this.loadPayment,
        } as ReservationFiscalPrint);
        this.$store.dispatch('reservation/createLog', logCreatePayload);
      }

      // pagamento
      if (pay) {
        this.$spiagge.utils.printMode.print(
          printer.id,
          print,
          async (printData: any) => {
            // se stampa fiscale
            if (printMode === PrintMode.FISCAL) {
              localStorageUtil.configure('spiagge', 'reservation');
              const fiscalPrint: ReservationFiscalPrint | undefined =
                await localStorageUtil.get('fiscalPrint');
              // if reservation id (started printing process) mismatch with the current
              // -> exited, ignore everything
              if (!fiscalPrint || fiscalPrint.reservationId !== this.id) {
                return;
              }

              // dispatch termine flusso fiscale
              await this.$store.dispatch('reservation/fiscalPrintEnd');
            }

            // pagamento con pos -> modifico il cash flow creato dal webhook settandolo a fiscale
            if (this.isPrintingAfterPOS) {
              await cashFlowService.update({
                id: this.posCashFlow.id,
                fiscal: true,
              });
              await this.$store.dispatch('reservation/getCashFlows');
              // reset vari
              this.depositTransferLocal = null;
              this.cashFlowMethod = CashFlowMethod.CASH;
            } else {
              // registrazione pagamento
              this.loadPayment(printData, print, printer, printMode);
            }

            // stampa ricevuta
            this.printOrderReceipt(print);
          },
          async (error: PrinterError) => {
            // errore stampa
            // se stampa fiscale: log dell'errore e terminazione stampa fiscale
            if (printMode === PrintMode.FISCAL) {
              localStorageUtil.configure('spiagge', 'reservation');
              const fiscalPrint: ReservationFiscalPrint | undefined =
                await localStorageUtil.get('fiscalPrint');
              // if reservation id (started printing process) mismatch with the current
              // -> exited, ignore everything
              if (!fiscalPrint || fiscalPrint.reservationId !== this.id) {
                return;
              }
              // fiscal print end
              await this.$store.dispatch('reservation/fiscalPrintEnd');

              // log timeout
              this.$store.dispatch('reservation/createLog', {
                reservationId: this.id,
                action: LogAction.FISCAL_PRINTER_ERROR,
                value: JSON.stringify(error),
              });
            }
          },
        );
      }

      // senza pagamento stampo solo la ricevuta
      if (!pay) {
        this.$spiagge.utils.printMode.print(printer.id, print, () => {
          this.printOrderReceipt(print);
        });
      }

      // reset sconto
      this.discount = 0;
    },
    printRefundFunction(cashflow: CashFlowUpdateModel, pay: boolean): void {
      const printer: Printer = this.fiscalPrinters.find(
        (fiscalPrinter: Printer) =>
          fiscalPrinter.id === cashflow.receipt.printerId,
      );

      // Check if the printer is initialized
      if (typeof printer === 'undefined') {
        // console.log('Printer not found');
        return;
      }

      /* INIZIO */

      // Receipt articles
      const print = this.$spiagge.utils.printMode.buildPrint(
        PrintMode.REFUND,
        cashflow.receipt.paymentMethod,
      );
      print.articles = [
        {
          description: 'Storno servizi',
          quantity: 1,
          vat: 22,
          price: cashflow.receipt.total,
        },
      ];
      const sourceReceiptDate = new Date(cashflow.receipt.date * 1000);

      // Estraiamo giorno, mese e anno dalla data
      const sourceReceiptDay = String(sourceReceiptDate.getDate()).padStart(
        2,
        '0',
      );
      const sourceReceiptMonth = String(
        sourceReceiptDate.getMonth() + 1,
      ).padStart(2, '0');
      const sourceReceiptYear = sourceReceiptDate.getFullYear();
      print.refund = {
        receiptNumber: cashflow.receipt.number,
        year: sourceReceiptYear,
        month: sourceReceiptMonth,
        day: sourceReceiptDay,
        printerSerial: printer.registrationNumber,
      };

      // create log of fiscal payment
      const logCreatePayload = {
        reservationId: this.id,
        action: LogAction.FISCAL_PRINTER_REQUEST,
        value: JSON.stringify({
          printerId: printer.id,
          printerName: printer.name,
          content: print,
        }),
      } as ApiLogCreatePayload;

      this.$store.dispatch('reservation/fiscalPrintStart', {
        reservationId: this.id,
        startedAt: DateTime.now(),
        printer,
        print,
        mode: PrintMode.FISCAL,
        callback: this.loadPayment,
      } as ReservationFiscalPrint);
      this.$store.dispatch('reservation/createLog', logCreatePayload);

      // pagamento
      if (pay) {
        this.$spiagge.utils.printMode.print(
          printer.id,
          print,
          async (printData: any) => {
            // se stampa fiscale
            localStorageUtil.configure('spiagge', 'reservation');
            const fiscalPrint: ReservationFiscalPrint | undefined =
              await localStorageUtil.get('fiscalPrint');
            // if reservation id (started printing process) mismatch with the current
            // -> exited, ignore everything
            if (!fiscalPrint || fiscalPrint.reservationId !== this.id) {
              return;
            }

            // dispatch termine flusso fiscale
            await this.$store.dispatch('reservation/fiscalPrintEnd');

            // registrazione pagamento
            this.addRefundCashflow(
              printData,
              print,
              printer,
              cashflow.method,
              cashflow.receipt,
            );
          },
          async (error: PrinterError) => {
            // errore stampa
            // Log dell'errore e terminazione stampa fiscale
            localStorageUtil.configure('spiagge', 'reservation');
            const fiscalPrint: ReservationFiscalPrint | undefined =
              await localStorageUtil.get('fiscalPrint');
            // if reservation id (started printing process) mismatch with the current
            // -> exited, ignore everything
            if (!fiscalPrint || fiscalPrint.reservationId !== this.id) {
              return;
            }
            // fiscal print end
            await this.$store.dispatch('reservation/fiscalPrintEnd');

            // log timeout
            this.$store.dispatch('reservation/createLog', {
              reservationId: this.id,
              action: LogAction.FISCAL_PRINTER_ERROR,
              value: JSON.stringify(error),
            });
          },
        );
      }

      // senza pagamento stampo solo la ricevuta
      if (!pay) {
        this.$spiagge.utils.printMode.print(printer.id, print, () => {
          this.$store.dispatch('reservation/fiscalPrintEnd');
        });
      }

      // reset sconto
      this.discount = 0;
    },
    async loadPayment(
      printData: PrinterFiscalResponse,
      print: Print,
      printer: Printer,
      mode: string,
    ) {
      /**
       * Creazione del flusso di cassa a seguito di una stampa
       */
      // totale
      let amount = this.totalToPay;
      if (this.depositTransferLocal !== null) {
        amount = this.depositTransferLocal;
      }

      // stampa fiscale ma prenotazione già pagata, fiscalizzo gli acconti non fiscali
      if (mode === PrintMode.FISCAL && this.paid) {
        amount = this.cashFlows
          .filter(
            (cashFlow: CashFlow) =>
              cashFlow.receiptId === null && cashFlow.invoiceId === null,
          )
          .reduce(
            (amount: number, cashFlow: CashFlow) => amount + cashFlow.amount,
            0,
          );

        try {
          const receiptNumber = printData.receiptNumber ?? null;
          const receiptAmount = printData.receiptAmount ?? amount;

          const payload = {
            receipt: {
              printerId: printer.id,
              date: Math.round(DateTime.now().toSeconds()),
              number: receiptNumber,
              total: receiptAmount,
              method: this.$spiagge.utils.printMode.paymentMethodToPrintMethod(
                this.cashFlowMethod,
              ),
            },
          } as ApiFiscalReservationFlowsPayload;

          await reservationService.fiscalReservationFlows(payload, this.id);
          this.$spiagge.toast.success(
            this.$t('reservationPayment.toast.operationSuccess'),
          );
          const noFiscStripeAmount = this.getStripeCashFlowsAmount();
          // create log of stripe payment
          if (noFiscStripeAmount > 0) {
            const logCreatePayload = {
              reservationId: this.id,
              action: LogAction.ONLINE_PRINT_BOOKING_RECEIPT,
              value: `${noFiscStripeAmount}`,
            } as ApiLogCreatePayload;
            this.$store.dispatch('reservation/createLog', logCreatePayload);
          }
        } catch (e) {
          this.$spiagge.toast.error(
            this.$t('reservationPayment.toast.operationError'),
          );
        } finally {
          if (mode === PrintMode.FISCAL) {
            this.canPayFiscalCashFlow = true;
          } else {
            this.canPayNoFiscalCashFlow = true;
          }
          if (this.timeoutId) {
            clearTimeout(this.timeoutId);
          }
          this.$store.dispatch('reservation/getCashFlows');
        }
        return;
      }

      try {
        // payload
        const payload = {
          reservationId: this.id,
          amount,
          method: this.cashFlowMethod,
          notes: null,
          cardId:
            this.cashFlowMethod === CashFlowMethod.YB_CARD
              ? this.cardIdSelected
              : null,
          expenses: [] as Array<number>,
        } as ApiCashFlowCreatePayload;

        // spese extra, in caso di pagamento totale aggiungo quelle non pagate
        if (
          this.expenses &&
          this.expenses.length > 0 &&
          this.depositTransferLocal === null
        ) {
          const listExpenses = this.expenses
            .filter(
              (expense: ReservationExpense) =>
                expense.paid === ReservationExpensePaid.NOT_PAID,
            )
            .map((expense: ReservationExpense) => expense.id);
          payload.expenses = listExpenses;
        }

        // spese extra selezionate manualmente
        if (
          this.selectedExpensesStored &&
          this.selectedExpensesStored.length > 0
        ) {
          payload.expenses = this.selectedExpensesStored.map(
            (expense: ReservationExpense) => expense.id,
          );
        }

        // pagamento fiscale, aggiungo la ricevuta al payload
        if (mode === PrintMode.FISCAL) {
          const receiptNumber = printData?.receiptNumber ?? null;
          const receiptAmount = printData?.receiptAmount ?? amount;

          payload.receipt = {
            printerId: printer.id,
            date: Math.round(DateTime.now().toSeconds()),
            method: print.paymentMethod,
            number: receiptNumber,
            total: receiptAmount,
            subTotal1: null,
            vat1: null,
            subTotal2: null,
            vat2: null,
            subTotal3: null,
            vat3: null,
            subTotal4: null,
            vat4: null,
          };
        }

        // creazione del flusso di cassa
        await this.$store.dispatch('reservation/createCashFlow', payload);
        if (amount > 0) {
          this.$spiagge.toast.success(
            this.$t('reservationPayment.toast.paymentSuccess'),
          );
        } else {
          this.$spiagge.toast.success(
            this.$t('reservationPayment.toast.transferSuccess'),
          );
        }

        // log in caso di pagamento fiscale (booking)
        if (
          mode === PrintMode.FISCAL &&
          this.cashFlowMethod === CashFlowMethod.STRIPE
        ) {
          const logCreatePayload = {
            reservationId: this.id,
            action: LogAction.ONLINE_PRINT_BOOKING_RECEIPT,
            value: `${amount}`,
          } as ApiLogCreatePayload;
          this.$store.dispatch('reservation/createLog', logCreatePayload);
        }

        // stampa riepilogo automatica se abilita
        if (this.license.posCommandPaper === PosCommandPaper.PRINT_SUMMARY) {
          const printSummary: HTMLElement | null = document.getElementById(
            'reservation-header-print-summary',
          );

          if (printSummary && Number(printSummary.dataset.reportPrinters) > 0) {
            // refresh reservation and cash flows
            await this.$store.dispatch('reservation/refresh');
            await this.$store.dispatch('reservation/getCashFlows');

            // wait for rendering
            await new Promise((resolve) => setTimeout(resolve, 100));

            (printSummary.firstElementChild as HTMLButtonElement).click();
            await this.$nextTick();
            const printSummaryDialog: HTMLElement | null =
              document.getElementById(
                'reservation-header-print-summary-dialog',
              );

            if (printSummaryDialog) {
              const printers: Array<HTMLButtonElement> = Array.from(
                printSummaryDialog.getElementsByClassName('report-printer'),
              ) as Array<HTMLButtonElement>;
              if (printers.length === 1) {
                printers[0].click();
              } else {
                // more printers, let the choice to the user
              }
            }
          } else {
            this.$spiagge.toast.warn(
              this.$t('reservationPayment.toast.printerError'),
            );
          }
        }

        // reset vari
        this.depositTransferLocal = null;
        this.$store.commit('reservation/setSelectedExpensesStored', []);
        this.cashFlowMethod = CashFlowMethod.CASH;
        this.fiscalPrinterDialog = false;
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationPayment.toast.paymentCreateError'),
        );
      } finally {
        /** reset the cash flow button
         * after the end of the call
         */
        if (mode === PrintMode.FISCAL) {
          this.canPayFiscalCashFlow = true;
        } else {
          this.canPayNoFiscalCashFlow = true;
        }
        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }
        this.$store.dispatch('reservation/getCashFlows');
      }
    },
    async addRefundCashflow(
      printData: PrinterFiscalResponse,
      print: Print,
      printer: Printer,
      paymentMethod: CashFlowMethod,
      refundedReceipt: any = null,
    ) {
      /**
       * Create cashflow after a refund receipt print.
       */

      // Refund value
      const amount = printData.receiptAmount * -1;

      try {
        // payload
        const payload = {
          reservationId: this.id,
          amount,
          method: paymentMethod,
          notes: null,
          cardId: null,
          expenses: [] as Array<number>,
        } as ApiCashFlowCreatePayload;

        // Add refund receipt data to the payload
        const receiptNumber = printData?.receiptNumber ?? null;
        const receiptAmount = printData?.receiptAmount ?? amount;

        payload.receipt = {
          printerId: printer.id,
          date: Math.round(DateTime.now().toSeconds()),
          method: print.paymentMethod,
          number: receiptNumber,
          total: receiptAmount * -1,
          subTotal1: null,
          vat1: null,
          subTotal2: null,
          vat2: null,
          subTotal3: null,
          vat3: null,
          subTotal4: null,
          vat4: null,
          refundedReceiptId:
            refundedReceipt && refundedReceipt.id ? refundedReceipt.id : null,
        };

        // Send cashflow creation request
        await this.$store.dispatch('reservation/createCashFlow', payload);
        this.$spiagge.toast.success(
          this.$t('reservationPayment.toast.transferSuccess'),
        );

        // reset vari
        this.depositTransferLocal = null;
        this.$store.commit('reservation/setSelectedExpensesStored', []);
        this.cashFlowMethod = CashFlowMethod.CASH;
        this.fiscalPrinterDialog = false;
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationPayment.toast.paymentCreateError'),
        );
      } finally {
        /** reset the cash flow button
         * after the end of the call
         */
        this.canPayFiscalCashFlow = true;
        this.canPayNoFiscalCashFlow = true;

        if (this.timeoutId) {
          clearTimeout(this.timeoutId);
        }
        this.$store.dispatch('reservation/getCashFlows');
      }
    },
    async onNoPrint(): Promise<void> {
      // wait for pending requests (like forced price)
      while (this.httpPendingRequests) {
        // eslint-disable-next-line no-await-in-loop
        await new Promise((r) => setTimeout(r, 500));
      }

      let amount = 0;

      if (this.depositTransferLocal === null) {
        if (this.paid) {
          this.$spiagge.toast.warn(
            this.$t('reservationPayment.toast.paidReservationError'),
          );
          return;
        }
        // no value -> pay
        amount = this.totalToPay;
      } else {
        amount = this.depositTransferLocal as unknown as number;
      }
      // disable double click
      if (!this.canPayNoFiscalCashFlow) {
        return;
      }
      this.canPayNoFiscalCashFlow = false;

      if (!this.canExit) {
        this.$store.commit('reservation/setCanExit', true);
        this.$store.commit('app/setAction', AppAction.NONE);
      }
      try {
        const payload = {
          reservationId: this.id,
          amount,
          method: this.cashFlowMethod,
          notes: null,
          cardId:
            this.cashFlowMethod === CashFlowMethod.YB_CARD
              ? this.cardIdSelected
              : null,
          expenses: [] as Array<number>,
        } as ApiCashFlowCreatePayload;
        if (
          this.selectedExpensesStored &&
          this.selectedExpensesStored.length > 0
        ) {
          payload.expenses = this.selectedExpensesStored.map(
            (element: ReservationExpense) => element.id,
          );
        } else if (
          this.expenses &&
          this.expenses.length > 0 &&
          this.depositTransferLocal === null
        ) {
          payload.expenses = this.expenses
            .filter(
              (element: ReservationExpense) =>
                element.paid === ReservationExpensePaid.NOT_PAID,
            )
            .map((element: ReservationExpense) => element.id);
        }
        await this.$store.dispatch('reservation/createCashFlow', payload);
        if (amount > 0) {
          this.$spiagge.toast.success(
            this.$t('reservationPayment.toast.paymentSuccess'),
          );
        } else {
          this.$spiagge.toast.success(
            this.$t('reservationPayment.toast.transferSuccess'),
          );
        }
        this.depositTransferLocal = null;
        this.$store.commit('reservation/setSelectedExpensesStored', []);
        this.cashFlowMethod = CashFlowMethod.CASH;
        this.fiscalPrinterDialog = false;
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationPayment.toast.paymentCreateError'),
        );
      } finally {
        this.canPayNoFiscalCashFlow = true;
      }
    },
    /**
     * Function to print the order receipt after printing a fis/nfis receipt
     * @param receiptContent Receipt already printed
     */
    printOrderReceipt(receiptContent: Print): void {
      if (!this.orderPrinter) {
        return;
      }
      const receiptToPrint = receiptContent;
      receiptToPrint.printMode = PrintMode.LIST;
      this.$spiagge.utils.printMode.print(
        this.orderPrinter.id,
        receiptToPrint,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        () => {},
      );
    },
    updateNotes(): void {
      this.$store.dispatch('reservation/updateReservation', {
        notes: this.notes,
      } as ApiReservationUpdatePayload);
    },
    getListOfArticle(
      includeNoFisDeposits: boolean,
      includeSiaeArticles?: boolean,
    ) {
      /**
       * Get print articles
       */

      // articoli scontrino
      this.printArticles = [];

      // acconti scontrino
      this.printDeposit = [];

      // acconti fattura
      this.invoiceDeposits = [];

      // pago una parte
      if (this.depositTransferLocal !== null) {
        // TODO: HACK, this is horrible
        /**
         * If we have selected some expenses to pay or fiscalize
         * (depositTransferLocal is gonna be the total amount of the selected expenses)
         * then we want to print only the details of those expenses in the receipt
         */
        if (
          this.selectedExpensesStored &&
          this.selectedExpensesStored.length > 0
        ) {
          const listExpenses = cloneDeep(this.selectedExpensesStored);
          listExpenses.map((expense: ReservationExpense) => {
            let itemsTotal = 0;
            expense.items.map((item: ReservationExpenseItem) => {
              const article = {} as PrintArticle;
              article.description = item.name ?? item.product;
              article.price = item.price / item.qnt;
              article.quantity = Number(item.qnt);
              article.vat = Number(item.vat ?? 10);
              this.printArticles.push(article);
              itemsTotal += item.price;
            });
            if (expense.value !== itemsTotal) {
              this.discount += itemsTotal - expense.value;
            }
          });
        } else {
          // linea generica dello scontrino
          let nameSpotType = ' ';
          if (this.spot.type === 'umbrella') {
            nameSpotType = this.$t('reservationPayment.umbrellaShort');
          }
          if (this.spot.type === 'cabins') {
            nameSpotType = this.$t('reservationPayment.cabinsShort');
          }
          if (this.spot.type === 'parking') {
            nameSpotType = this.$t('reservationPayment.parkingShort');
          }
          if (this.spot.type === 'bed') {
            nameSpotType = this.$t('reservationPayment.bedShort');
          }
          if (this.spot.type === 'gazebo') {
            nameSpotType = this.$t('reservationPayment.gazeboShort');
          }
          if (this.spot.type === null && this.spot.name === null) {
            nameSpotType = `${this.$t(
              'reservationPayment.receiptJointAccount',
            )} #${this.id}`;
          }
          this.printArticles.push({
            description: `${nameSpotType} ${this.spot.name ?? ''} ${this.$t(
              'reservationPayment.receiptServicesDeposit',
            )}`,
            price: this.depositTransferLocal || 0,
            vat: 22,
            quantity: 1,
          } as PrintArticle);
        }
      }

      // pago tutto
      if (this.depositTransferLocal === null) {
        // prezzo spiaggia (eventualmente forzato)
        let beachAppliedPrice = this.totals.list.beach;
        if (this.beachAppliedPrice !== null) {
          beachAppliedPrice = this.beachAppliedPrice;
        }

        // riga scontrino prenotazione principale
        const mainReservationArticle = {} as PrintArticle;
        mainReservationArticle.description =
          this.$spiagge.utils.reservation.getReceiptDescription({
            id: this.id,
            spotType: this.spot.type,
            spotName: this.spot.name,
            maxiBeds: this.maxiBeds,
            beds: this.beds,
            chairs: this.chairs,
            deckChairs: this.deckChairs,
            startDate: this.startDate.toSeconds(),
            endDate: this.endDate.toSeconds(),
            type: this.type,
            sector: this.sector,
            children: this.joints,
          } as Reservation);
        mainReservationArticle.price = 0;
        mainReservationArticle.vat = 22;
        mainReservationArticle.quantity = 1;

        // se il prezzo è >= 0 aggiungo la riga allo scontrino
        if (beachAppliedPrice >= 0) {
          mainReservationArticle.price = beachAppliedPrice;
          this.printArticles = [...this.printArticles, mainReservationArticle];
        }

        // costruisco i dettagli della prenotazione

        // cabine
        if (this.cabins) {
          this.printArticles = [
            ...this.printArticles,
            ...this.getArticlesFromReservations(
              this.cabins,
              includeNoFisDeposits,
            ),
          ];
        }

        // pezzi extra
        if (this.additions) {
          this.printArticles = [
            ...this.printArticles,
            ...this.getArticlesFromReservations(
              this.additions,
              includeNoFisDeposits,
            ),
          ];
        }

        // parcheggi
        if (this.parkings) {
          this.printArticles = [
            ...this.printArticles,
            ...this.getArticlesFromReservations(
              this.parkings,
              includeNoFisDeposits,
            ),
          ];
        }

        // servizi extra
        if (this.reservationServicesArticles) {
          this.printArticles = [
            ...this.printArticles,
            ...this.reservationServicesArticles(includeSiaeArticles),
          ];
          // se ci sono servizi extra con prezzo negativo li applico come sconto
          this.discount += this.reservationServicesDiscount;
        }

        // aggiungo le spese extra se presenti (TODO)
        const cashFlowsToIgnore = new Map<number, number>();
        if (this.expenses && this.expenses.length > 0) {
          /** Always ignore fiscalized expenses */
          const statusesToIgnore = [ReservationExpensePaid.FIS_PAID];
          // let listExpenses = cloneDeep(this.expenses);
          if (!includeNoFisDeposits) {
            /**
             * Ignore expenses paid without fiscal receipt
             * if we have to ignore non fiscal deposits
             */
            statusesToIgnore.push(ReservationExpensePaid.NFIS_PAID);
          }
          this.expenses.forEach((expense: ReservationExpense) => {
            /**
             * Calculate the amount to be removed from fiscalized cashflows
             * connected to fiscalized expenses
             */

            /** excluding all extra expenses already paid */
            if (expense.cashFlowId) {
              let value = expense.value;
              if (cashFlowsToIgnore.has(expense.cashFlowId)) {
                value += cashFlowsToIgnore.get(expense.cashFlowId) ?? 0;
              }
              cashFlowsToIgnore.set(expense.cashFlowId, value);
            }
            /** Add items to receipt if to be included */
            if (!statusesToIgnore.includes(expense.paid)) {
              let itemsTotal = 0;
              expense.items.map((item: ReservationExpenseItem) => {
                const article = {} as PrintArticle;
                article.description = item.name ?? item.product;
                article.price = item.price / item.qnt;
                article.quantity = Number(item.qnt);
                article.vat = Number(item.vat ?? 10);
                this.printArticles.push(article);
                itemsTotal += item.price;
              });
              if (expense.value !== itemsTotal) {
                this.discount += itemsTotal - expense.value;
              }
            }
          });
        }

        if (this.isPrintingAfterPOS) {
          cashFlowsToIgnore.set(this.posCashFlow.id, this.posCashFlow.amount);
        }

        // acconti non fiscali
        // se ho effettuato un pagamento con il pos il flusso di cassa non sarà ancora fiscale
        const noFisDeposits = this.cashFlows
          .filter(
            (cashFlow: CashFlow) =>
              cashFlow.reservationId === this.id &&
              !cashFlowsToIgnore.has(cashFlow.id) &&
              !cashFlow.receiptId &&
              !cashFlow.invoiceId, // &&
            // (this.posCashFlow ? cashFlow.id !== this.posCashFlow.id : true),
          )
          .reduce(
            (amount: number, cashFlow: CashFlow) => amount + cashFlow.amount,
            0,
          );

        // acconti fiscali da rimuovere
        // (se fattura includo gli scontrini e se scontrino includo fatture)
        const fisDeposits = this.cashFlows
          .filter(
            (cashFlow: CashFlow) =>
              cashFlow.reservationId === this.id &&
              !cashFlowsToIgnore.has(cashFlow.id) &&
              cashFlow.invoiceId !== this.SIAE_INVOICE_ID &&
              ((this.isInvoiceIssue && cashFlow.receiptId) ||
                (!this.isInvoiceIssue && cashFlow.invoiceId)),
          )
          .reduce(
            (amount: number, cashFlow: CashFlow) => amount + cashFlow.amount,
            0,
          );

        // acconti da rimuovere
        let deposits =
          fisDeposits + (!includeNoFisDeposits ? noFisDeposits : 0);

        // sottraggo i depositi fiscali alle righe
        this.printArticles = this.printArticles
          .map((article: PrintArticle) => {
            const result = cloneDeep(article);
            if (deposits <= 0) {
              return result;
            }
            const sub = Math.min(article.price, deposits);
            deposits -= sub;
            result.price -= sub;
            return result;
          })
          .filter((article: PrintArticle) => article.price > 0);

        // se non ci sono righe aggiunge la prenotazione principale
        if (this.printArticles.length === 0) {
          this.printArticles.push(mainReservationArticle);
        }

        // costruzione acconti

        // acconti fattura
        if (this.isInvoiceIssue) {
          this.invoiceDeposits = this.cashFlows
            .filter(
              (cashFlow: CashFlow) =>
                cashFlow.amount > 0 &&
                cashFlow.invoiceId &&
                cashFlow.invoiceId > 0,
            )
            .map((cashFlow: CashFlow) => {
              let amount = cashFlow.amount;
              if (cashFlowsToIgnore.has(cashFlow.id)) {
                amount -= cashFlowsToIgnore.get(cashFlow.id) ?? 0;
              }
              const invoiceDate = DateTime.fromSeconds(cashFlow.createdAt)
                .setZone('Europe/Rome')
                .toLocaleString(DateTime.DATE_SHORT);
              const description = `${this.$t(
                'reservationPayment.depositInvoice',
              )} ${invoiceDate}`;
              return { description, amount } as InvoiceDeposit;
            })
            .filter((deposit: InvoiceDeposit) => deposit.amount > 0);
        }

        // acconti scontrino
        if (!this.isInvoiceIssue) {
          this.printDeposit = this.cashFlows
            .filter(
              (cashFlow: CashFlowI) =>
                cashFlow.amount > 0 &&
                cashFlow.receiptId &&
                cashFlow.receiptId > 0 &&
                (!cashFlow.receipt || !cashFlow.receipt.refunded),
            )
            .map((cashFlow: CashFlow) => {
              let amount = cashFlow.amount;
              if (cashFlowsToIgnore.has(cashFlow.id)) {
                amount -= cashFlowsToIgnore.get(cashFlow.id) ?? 0;
              }
              return { amount } as PrintDeposit;
            })
            .filter((deposit: PrintDeposit) => deposit.amount > 0);
        }
      }
    },
    getArticlesFromReservations(
      reservations: Array<Reservation>,
      includeNoFisDeposits: boolean,
    ): Array<PrintArticle> {
      /**
       * Get articles from reservations provided
       */
      return reservations
        .filter((element: Reservation) => !element.deleted)
        .map((element: Reservation) => {
          const article = {} as PrintArticle;

          // acconti non fiscali
          const noFisDeposits = this.cashFlows.reduce(
            (a: number, b: CashFlowI) => {
              if (
                b.reservationId === element.id &&
                !b.receiptId &&
                !b.invoiceId
              ) {
                return a + b.amount;
              }
              return a;
            },
            0,
          );

          article.description =
            this.$spiagge.utils.reservation.getReceiptDescription(element);
          let price = 0;
          if (element.forcedTotal) {
            price = element.forcedTotal;
          } else if (this.isJointAccount) {
            price = element.forcedBeach ?? element.listBeach ?? 0;
          } else {
            price = element.forcedBeach ?? element.listBeach ?? 0;
            price += element.forcedAdditions ?? element.listAdditions ?? 0;
            price += element.forcedServices ?? element.listServices ?? 0;
            price += element.forcedExpenses ?? element.listExpenses ?? 0;
          }
          if (!includeNoFisDeposits) {
            price -= noFisDeposits;
          }
          article.price = price;
          article.quantity = 1;
          article.vat = 22;
          return article;
        });
    },
    async onVoucherHotelChange(event: any): Promise<void> {
      const booleanValue: boolean = event.value;
      if (booleanValue === this.voucherHotel) return;
      const value = booleanValue
        ? this.vouchers.find((v: Voucher) => v.name === this.hotel).id
        : 0;
      try {
        await this.$store.dispatch('reservation/updateReservation', {
          voucherId: value,
        });
        this.$spiagge.toast.success(
          this.$t('reservationPayment.toast.voucherSuccess'),
        );
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('reservationPayment.toast.voucherError'),
        );
      }
    },
    onCashFlowMethodChange(event: DropdownChangeEvent): void {
      if (event.value === CashFlowMethod.YB_CARD) {
        this.cardIdSelected = this.cardsOptions[0].value as number;
      }
    },
    resetFiscalCashFlowsButtons() {
      // disable fiscal cash flows button
      this.canPayFiscalCashFlow = false;
      // reset fiscal cash flows button after 3 sec
      this.timeoutId = setTimeout(() => {
        this.canPayFiscalCashFlow = true;
      }, 3000);
    },
    resetNoFiscalCashFlowsButtons() {
      // disable fiscal cash flows button
      this.canPayNoFiscalCashFlow = false;
      // reset fiscal cash flows button after 3 sec
      this.timeoutId = setTimeout(() => {
        this.canPayNoFiscalCashFlow = true;
      }, 3000);
    },
    getStripeCashFlowsAmount(): number {
      return this.cashFlows.reduce((a: number, b: CashFlowI) => {
        if (
          b.method === CashFlowMethod.STRIPE &&
          b.invoiceId === null &&
          b.receiptId === null
        ) {
          return a + b.amount;
        }
        return a;
      }, 0);
    },
    // pos terminal
    onPosTerminalBeforeIntent(): void {
      /**
       * Set pos stripe terminal as cash flow method
       */
      this.cashFlowMethod = CashFlowMethod.POS_STRIPE;
    },
    async onPosTerminalSuccess(
      stripePaymentIntent: StripePaymentIntent,
    ): Promise<void> {
      /**
       * Refresh cash flows
       */
      this.stripePaymentIntent = stripePaymentIntent;
      try {
        await this.$store.dispatch('reservation/refresh');
        await this.$store.dispatch('reservation/getCashFlows');
        // this.depositTransferLocal = null;
        this.$spiagge.toast.success(
          this.$t('reservationPayment.toast.operationPosSuccess'),
        );

        const fiscalPrintBtn = document.querySelector(
          '.cash-flow.fiscal',
        ) as HTMLElement;
        if (fiscalPrintBtn) {
          this.isPrintingAfterPOS = true;
          // flussi fiscali pagati con il pos
          const posCashFlows: Array<CashFlow> = orderBy(
            this.cashFlows.filter(
              (cashFlow: CashFlow) =>
                cashFlow.reservationId === this.id &&
                cashFlow.method === CashFlowMethod.POS_STRIPE &&
                cashFlow.receiptId === null &&
                cashFlow.invoiceId === null,
            ),
            ['id'],
            ['desc'],
          );
          if (posCashFlows.length > 0) {
            this.posCashFlow = posCashFlows[0];
            fiscalPrintBtn.click();
          }
        }
      } catch (error) {
        this.$spiagge.toast.error(
          this.$t('reservationPayment.toast.retrievalError'),
        );
      }
    },
    async onPosTerminalFailure(): Promise<void> {
      /**
       * Pos terminal failure
       */
      this.$spiagge.toast.error(
        this.$t('reservationPayment.toast.paymentPosError'),
      );
      await logService.create({
        reservationId: this.id,
        action: LogAction.POS_POLLING_EXPIRED,
        value: '',
      } as ApiLogCreatePayload);
    },
    async onPosTerminalExit(): Promise<void> {
      /**
       * Pos terminal exit
       */
      await logService.create({
        reservationId: this.id,
        action: LogAction.POS_POLLING_INTERRUPED,
        value: '',
      } as ApiLogCreatePayload);
    },
    // Webtic
    onWebticTicketBeforeEmit(): void {
      // console.log('Before emission.');
    },
    async onWebticTicketEmitSuccess(): Promise<void> {
      /**
       * Refresh cash flows
       */
      try {
        await this.$store.dispatch('reservation/refresh');
        await this.$store.dispatch('reservation/getCashFlows');
        // this.depositTransferLocal = null;
        this.$spiagge.toast.success(
          this.$t('reservationPayment.toast.webticTicketEmissionSuccess'),
        );

        const fiscalPrintBtn = document.querySelector(
          '.cash-flow.fiscal',
        ) as HTMLElement;
        if (fiscalPrintBtn) {
          fiscalPrintBtn.click();
        }
      } catch (error) {
        this.$spiagge.toast.error(
          this.$t('reservationPayment.toast.retrievalError'),
        );
      }
    },
    async onWebticTicketEmitFailure(): Promise<void> {
      /**
       * Webtic ticket emission failure
       */
      this.$spiagge.toast.error(
        this.$t('reservationPayment.toast.webticTicketEmissionError'),
      );
    },
    async onWebticTicketEmitExit(): Promise<void> {
      /**
       * Webtic ticket emission exit
       */
      // console.log('Webtic ticket emission exit');
    },
    hasPermission(action: string): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.reservations,
        action,
      );
    },
    hasPosPermission(): boolean {
      return (
        this.license.stripePosTerminalEnabled &&
        permissionsUtil.isActionPermissionAllowed(
          FEATURE_PERMISSION_CONFIG.pos,
          FEATURE_PERMISSION_ACTION_CONFIG.pos.SHOW_BUTTON_POS,
        )
      );
    },
    base64encode(text: string): string {
      const utf8Array = new TextEncoder().encode(text);
      return fromByteArray(utf8Array);
    },
    reservationServicesArticles(
      includeSiaeArticles?: boolean,
    ): Array<PrintArticle> {
      // Discount percentage
      let discountPercentage = 1;
      const totalListServicesNoSiae =
        this.totals.list.services - this.siaeServicesTotal;

      // Residual: removing siae total value from this
      const residualTotal = this.servicesAppliedPrice
        ? this.servicesAppliedPrice
        : totalListServicesNoSiae;

      if (totalListServicesNoSiae && this.servicesAppliedPrice) {
        discountPercentage =
          totalListServicesNoSiae / this.servicesAppliedPrice;
      }

      const elements = this.$store.getters['reservation/services']
        .filter(
          (reservationService: ReservationService) =>
            reservationService.serviceId !== BEACH_TICKET_SERVICE_ID &&
            reservationService.bought - reservationService.deleted > 0 &&
            reservationService.price > 0 &&
            (includeSiaeArticles ||
              !this.services[
                this.services.findIndex(
                  (item: Service) => item.id === reservationService.serviceId,
                )
              ].webticPriceId),
        )
        .map((element: ReservationService) => {
          const article = {} as PrintArticle;
          const indexService = this.services.findIndex(
            (item: Service) => item.id === element.serviceId,
          );
          const service = this.services[indexService] as Service;

          article.description = `Serv. ext. ${service.name}`;
          article.vat = service.vat;
          if (service.type === 2) {
            // Servizi extra a valore economico senza quantità (ad esempio le commissioni online)
            article.price = element.bought / 100;
            article.quantity = 1;
          } else {
            // Servizi extra a quantità
            const serviceIndex = this.services.findIndex(
              (item: Service) => item.id === element.serviceId,
            );

            const serviceElement = this.services[serviceIndex];

            let articlePrice = 0;

            // If is siae
            if (serviceElement.webticPriceId) {
              articlePrice = element.price;
            } else {
              articlePrice = element.price / discountPercentage;
            }

            article.price =
              Math.round(
                (articlePrice / (element.bought - element.deleted)) * 100,
              ) / 100;
            article.quantity = element.bought - element.deleted;
          }

          return article;
        });

      if (residualTotal !== 0) {
        const elementToAddDifferenceIndex = elements.findIndex(
          (el: PrintArticle) => el.quantity === 1,
        );

        if (elementToAddDifferenceIndex > -1) {
          elements[elementToAddDifferenceIndex].price += Number(
            residualTotal.toFixed(2),
          );
        }
      }

      return elements;
    },
  },
  computed: {
    FEATURE_PERMISSION_ACTION_CONFIG() {
      return FEATURE_PERMISSION_ACTION_CONFIG;
    },
    ...mapState('reservation', [
      'id',
      'edit',
      'paid',
      'spot',
      'totals',
      'master',
      'hotel',
      'cashFlows',
      'cabins',
      'additions',
      'parkings',
      'selectedExpensesStored',
      'depositTransferStored',
      'maxiBeds',
      'beds',
      'deckChairs',
      'chairs',
      'startDate',
      'endDate',
      'voucherId',
      'cards',
      'canExit',
      'sector',
      'type',
      'joints',
      'online',
      'fiscalPrint',
      'qrCode',
      'listTotal',
    ]),
    ...mapState('session', ['printers', 'services', 'vouchers', 'locale']),
    ...mapGetters('reservation', [
      'total',
      'totalToPay',
      'hasDeposit',
      'isJointAccountPart',
      'isJointAccount',
      'isCreate',
      'isBillReservation',
      'totalSelectedExpenses',
      'totalBeforeForcedTotal',
      'canDisjoinAccount',
      'hasOrderWithPaymentLink',
    ]),
    ...mapGetters('session', [
      'fiscalPrinters',
      'nonFiscalPrinters',
      'license',
    ]),
    ...mapGetters('app', ['action', 'httpPendingRequests']),
    ticketPrice(): number {
      return this.totals.list.services - this.discountServicePrice;
    },
    voucherHotel(): boolean {
      return (
        this.hotel &&
        this.vouchers.find((voucher: Voucher) => voucher.name === this.hotel)
          .id === this.voucherId
      );
    },
    reservationServicesDiscount(): number {
      /**
       * Return all reservation services with negative price (discount)
       */
      const services = this.$store.getters['reservation/services']
        .filter(
          (reservationService: ReservationService) =>
            reservationService.serviceId !== BEACH_TICKET_SERVICE_ID &&
            reservationService.bought - reservationService.deleted > 0 &&
            reservationService.price < 0,
        )
        .map((element: ReservationService) => element.price);
      let amount = 0;
      if (services.length > 0) {
        amount = services.reduce((a: number, b: number) => a + b);
      }
      return amount < 0 ? -amount : amount;
    },
    depositTransferLocal: {
      get(): number | null {
        return this.depositTransferStored;
      },
      set(value: number | null): void {
        this.$store.commit(
          'reservation/setDepositTransferStored',
          cloneDeep(value),
        );
      },
    },
    totalPlaceholder(): string {
      if (typeof this.total !== 'number') {
        return '';
      }

      return this.formatPrice(Math.max(this.total, 0));
    },
    totalToPayFormatted(): string {
      if (typeof this.totalToPay !== 'number') {
        return '';
      }

      return this.formatPrice(this.totalToPay);
    },
    expenses(): Array<ReservationExpense> {
      return this.$store.getters['reservation/expenses'];
    },
    hasFiscalDepositsOnly(): boolean {
      return this.$store.getters['reservation/hasFiscalDepositsOnly'];
    },
    customer(): Customer {
      return this.$store.getters['reservation/customer'];
    },
    cardsOptions(): Array<DropdownOption> {
      return this.cards.map(
        (card: Card) =>
          ({
            label: `${card.cardTypeName} ${card.number}`,
            value: card.idWorldCard,
          }) as DropdownOption,
      );
    },
    showCardsDropdown(): boolean {
      return this.cashFlowMethod === CashFlowMethod.YB_CARD;
    },
    beachAppliedPrice: {
      get(): number | null {
        return this.totals.forced.beach;
      },
      set(forcedBeach: number | null): void {
        this.debounceUpdateReservation({
          forcedBeach,
        });
      },
    },
    servicesAppliedPrice: {
      get(): number | null {
        return typeof this.totals.forced.services === 'number'
          ? this.totals.forced.services
          : null;
      },
      set(forcedServices: number | null): void {
        this.debounceUpdateReservation({
          forcedServices,
        });
      },
    },
    totalAppliedPrice: {
      get(): number | null {
        return this.totals.forced.total;
      },
      set(forcedTotal: number | null): void {
        this.debounceUpdateReservation({
          forcedTotal,
        });
      },
    },
    notes: {
      get(): string {
        return this.$store.getters['reservation/notes'];
      },
      set(notes: string): void {
        this.$store.commit('reservation/setNotes', notes);
      },
    },
    extraTotal(): boolean {
      return (
        this.totals.list.additions ||
        this.totals.list.expenses ||
        this.totals.list.services
      );
    },
    showVoucherHotel(): boolean {
      return (
        this.hotel !== '' &&
        this.vouchers.find((voucher: Voucher) => voucher.name === this.hotel)
      );
    },
    deviceSpecs(): DeviceSpecs {
      return this.$store.getters['app/deviceSpecs'];
    },
    isChrome(): boolean {
      if (this.deviceSpecs.browser === 'gc' && !this.deviceSpecs.mobile) {
        return (
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).chromeExtensionEnable !== 'yb' &&
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).chromeExtensionId !==
            'kddlpmnnlnnlbdmlmneclkmgbgfifeag'
        );
      }
      return false;
    },
    isElectron(): boolean {
      if (cookieUtil.get('anm22_world_app_os') === 'electron') {
        return true;
      }
      return false;
    },
    isAndroid(): boolean {
      if (cookieUtil.get('anm22_world_app_os') === 'android') {
        return true;
      }
      return false;
    },
    isIos(): boolean {
      if (cookieUtil.get('anm22_world_app_os') === 'ios') {
        return true;
      }
      return false;
    },
    isCabinAsService(): boolean {
      return (
        this.license?.cabinMode === CabinMode.SERVICE &&
        this.spot.type === 'cabins' &&
        this.master !== null &&
        this.master.startDate === this.startDate.toSeconds() &&
        this.master.endDate === this.endDate.toSeconds()
      );
    },
    canDelete(): boolean {
      /**
       * Check can delete reservation
       */
      let canDelete = true;
      if (
        this.isJointAccount &&
        this.cashFlows.some(
          (cashFlow: CashFlowI) => cashFlow.reservationId === this.id,
        )
      ) {
        canDelete = false;
      }
      return canDelete;
    },
    cashFlowMethodsOptions(): Array<CashFlowMethodOption> {
      /**
       * Return cash flow methods. Remove YB_CARD if no card is available
       */
      let cashFlowMethodsOptions = cloneDeep(CASH_FLOW_METHOD_OPTIONS);
      if (this.cards.length === 0) {
        cashFlowMethodsOptions = cashFlowMethodsOptions.filter(
          (cashFlowMethod: CashFlowMethodOption) =>
            cashFlowMethod.value !== CashFlowMethod.YB_CARD,
        );
      }
      return cashFlowMethodsOptions;
    },
    /**
     * Order receipt after fiscal/non-fiscal receipt
     */
    orderPrinter(): Printer | null {
      if (!this.license.posCommandPaper) {
        return null;
      }
      return (
        (this.printers as Array<Printer>).filter(
          (p: Printer) =>
            p.printMode.list === 1 && !cookieUtil.get(`spit_prt-hide-${p.id}`),
        )[0] ?? null
      );
    },
    posTerminalPaymentPayload(): Partial<ApiStripeCreatePaymentIntentPayload> {
      return {
        amount: this.depositTransferLocal ?? this.totalToPay,
        email: this.customer.email ?? '',
        reservation: this.id,
        description: '.',
        // type: 'reservation',
      };
    },
    webticEmitPayload(): ApiWebticOnsiteEmitTicketPayload {
      const payload: ApiWebticOnsiteEmitTicketPayload = {
        reservation: this.id,
        paymentMethod: this.cashFlowMethod,
      };

      return payload;
    },
    siaeServicesTotal(): number {
      let siaeServiceTotal = 0;
      const siaeServices = this.services
        .filter((item: Service) => item.webticPriceId !== null)
        .map((item: Service) => item.webticPriceId);

      const reservationServices = this.$store.getters['reservation/services'];

      reservationServices.forEach((reservationService: ReservationService) => {
        // If the siaeServices array includes the service id , sum on the siaeServiceTotal
        if (siaeServices.includes(reservationService.serviceId)) {
          siaeServiceTotal += reservationService.price;
        }
      });

      return siaeServiceTotal;
    },
  },
});
