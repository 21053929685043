import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b4203d78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "threshold" }
const _hoisted_2 = { class: "threshold-label" }
const _hoisted_3 = { class: "threshold-input" }
const _hoisted_4 = { class: "value" }
const _hoisted_5 = { class: "value-label" }
const _hoisted_6 = { class: "value-input" }
const _hoisted_7 = {
  key: 0,
  class: "preview"
}
const _hoisted_8 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_InputNumberThreshold = _resolveComponent("InputNumberThreshold")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["revenue-management-rule", _ctx.classes])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.thresholdLabel), 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_InputNumber, {
          modelValue: _ctx.model.threshold,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.threshold) = $event)),
          placeholder: _ctx.$t('revenueManagementRule.thresholdExamplePlaceholder'),
          min: 0,
          max: 100,
          step: 1,
          suffix: "%",
          disabled: !_ctx.editEnabled
        }, null, 8, ["modelValue", "placeholder", "disabled"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.valueLabel), 1),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_InputNumberThreshold, {
          modelValue: _ctx.model.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.value) = $event)),
          placeholder: _ctx.$t('revenueManagementRule.valueExamplePlaceholder'),
          min: -100,
          max: 100,
          step: 1,
          suffix: "%",
          disabled: !_ctx.editEnabled
        }, null, 8, ["modelValue", "placeholder", "disabled"])
      ])
    ]),
    (_ctx.preview !== undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createElementVNode("span", null, " = " + _toDisplayString(_ctx.preview), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_8, [
      (!_ctx.editEnabled)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_Button, {
              class: "p-button-rounded p-button-text",
              icon: "spi-edit",
              onClick: _ctx.onEdit,
              disabled: _ctx.disabled || _ctx.processing
            }, null, 8, ["onClick", "disabled"]),
            _createVNode(_component_Button, {
              class: "p-button-rounded p-button-text p-button-danger",
              icon: "spi-trash",
              onClick: _ctx.onDelete,
              disabled: _ctx.disabled || _ctx.processing
            }, null, 8, ["onClick", "disabled"])
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createVNode(_component_Button, {
              class: "p-button-rounded p-button-text p-button-success",
              icon: "spi-check",
              disabled: _ctx.disabled
          || _ctx.processing
          || _ctx.model.threshold === undefined
          || _ctx.model.threshold === null
          || _ctx.model.value === undefined
          || _ctx.model.value === null,
              onClick: _ctx.onSave
            }, null, 8, ["disabled", "onClick"]),
            _createVNode(_component_Button, {
              class: "p-button-rounded p-button-text p-button-danger",
              icon: "spi-times",
              onClick: _ctx.onAbort,
              disabled: _ctx.disabled || _ctx.processing
            }, null, 8, ["onClick", "disabled"])
          ], 64))
    ])
  ], 2))
}