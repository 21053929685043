
import { PropType, defineComponent, getCurrentInstance } from 'vue';
import { mapState } from 'vuex';
import {
  RevenueManagementBaseRule,
  RevenueManagementOverrideRule,
  RevenueManagementRuleBaseModel,
  RevenueManagementRuleCreateEmit,
  RevenueManagementRuleDeleteEmit,
  RevenueManagementRuleUpdateEmit,
} from '@/models/revenueManagement';
import InputNumberThreshold from '../shared/InputNumberThreshold.vue';

enum SaveAction {
  CREATE,
  UPDATE,
}

export default defineComponent({
  name: 'RevenueManagementRule',
  emits: ['create', 'update', 'delete', 'abort', 'edit'],
  components: { InputNumberThreshold },
  props: {
    thresholdLabel: {
      type: String,
      required: true,
    },
    valueLabel: {
      type: String,
      required: true,
    },
    rule: {
      type: Object as PropType<
        RevenueManagementBaseRule | RevenueManagementOverrideRule
      >,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    price: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      classes: [] as Array<string>,
      model: { threshold: null, value: null } as RevenueManagementRuleBaseModel,
      editEnabled: false,
    };
  },
  mounted(): void {
    if (this.rule) {
      this.initModel();
    } else {
      this.editEnabled = true;
    }
  },
  methods: {
    initModel(): void {
      if (this.rule) {
        this.model.threshold = this.rule.applicationThreshold;
        this.model.value = this.rule.revenuePercentageValue;
      }
    },
    async onSave(): Promise<void> {
      if (this.saveAction === SaveAction.UPDATE) {
        this.$emit('update', {
          rule: this.rule,
          model: this.model,
          successCb: () => {
            // this.initModel();
            // this.onAbort();
          },
        } as RevenueManagementRuleUpdateEmit);
      } else {
        this.$emit('create', {
          rule: this.rule,
          model: this.model,
          successCb: () => {
            // this.initModel();
            // this.onAbort();
          },
        } as RevenueManagementRuleCreateEmit);
      }
    },
    onEdit(): void {
      this.editEnabled = true;
      this.$emit('edit', this.rule);
    },
    onAbort(): void {
      this.editEnabled = false;
      // restore model on updated rule
      this.initModel();
      this.$emit('abort');
    },
    onDelete(): void {
      this.$confirm.require({
        message: this.$t('revenueManagementRule.confirm.message'),
        header: this.$t('revenueManagementRule.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('common.yes'),
        accept: () => {
          if (this.rule) {
            this.$emit('delete', {
              rule: this.rule,
              successCb: () => {
                // this.$forceUpdate();
              },
            } as RevenueManagementRuleDeleteEmit);
          }
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          // nothing
        },
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-danger p-button-outlined',
      });
    },
  },
  computed: {
    ...mapState('app', ['processing']),
    saveAction(): SaveAction {
      return this.rule !== undefined ? SaveAction.UPDATE : SaveAction.CREATE;
    },
    preview(): number | undefined {
      if (this.price === undefined || this.model.value === null) {
        return undefined;
      }
      return this.$spiagge.utils.global.percentage(
        this.price,
        this.model.value,
        true,
      );
    },
  },
  watch: {
    rule(): void {
      // this.initModel();
    },
  },
});
