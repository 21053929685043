/* eslint-disable no-param-reassign */
/* eslint-disable import/no-dynamic-require */
/**
 * Style store module
 */

import cloneDeep from 'lodash/cloneDeep';
import { MutationTree, ActionTree, ActionContext, GetterTree } from 'vuex';
import forOwn from 'lodash/forOwn';
import { RootState, StyleState, DEFAULT_STYLE_STATE } from '@/models/store';
import { StyleSpot, StyleSpotAttribute } from '@/models/style';
import { ColorName, colorSpecs } from '@/models/color';

type StyleContext = ActionContext<StyleState, RootState>;

const namespaced = true;

const state = (): StyleState => cloneDeep(DEFAULT_STYLE_STATE);

const getters: GetterTree<StyleState, RootState> = {
  spot: (state) => state.spot,
} as GetterTree<StyleState, RootState>;

const mutations: MutationTree<StyleState> = {
  setSpot(state: StyleState, spot: StyleSpot) {
    state.spot = spot;
  },
} as MutationTree<StyleState>;

const actions: ActionTree<StyleState, RootState> = {
  async build(context: StyleContext): Promise<void> {
    const license = context.rootGetters['session/license'];
    const spot: StyleSpot = cloneDeep(DEFAULT_STYLE_STATE.spot);
    forOwn(spot, (spotAttribute: StyleSpotAttribute, attribute: string) => {
      // if no attribute then cannot override
      if (!spotAttribute.variable) return;
      const color = license[spotAttribute.variable] as ColorName;
      // If color is outside of specs cannot override
      if (!colorSpecs.has(color)) return;
      spot[attribute].color = color;
      // If no icons defined in default state then no override
      if (spotAttribute.icons) {
        spot[attribute].icons = {
          umbrella: require(`@/assets/images/map/umbrella/${
            colorSpecs.get(color)?.icon
          }.svg`),
          bed: require(`@/assets/images/map/bed/${
            colorSpecs.get(color)?.icon
          }.svg`),
          cabins: require(`@/assets/images/map/cabins/${
            colorSpecs.get(color)?.icon
          }.svg`),
          parking: require(`@/assets/images/map/parking/${
            colorSpecs.get(color)?.icon
          }.svg`),
          gazebo: require(`@/assets/images/map/gazebo/${
            colorSpecs.get(color)?.icon
          }.svg`),
          beds: require(`@/assets/images/map/beds/${
            colorSpecs.get(color)?.icon
          }.svg`),
          boat: require(`@/assets/images/map/boat/${
            colorSpecs.get(color)?.icon
          }.svg`),
          playfield: require(`@/assets/images/map/playfield/${
            colorSpecs.get(color)?.icon
          }.svg`),
        };
      }
      if (!['#FFFFFF', '#878787'].includes(spotAttribute.css.borderColor)) {
        spot[attribute].css.borderColor =
          colorSpecs.get(color)?.rgb ?? spotAttribute.css.borderColor;
      }
      if (!['#FFFFFF'].includes(spotAttribute.css.backgroundColor)) {
        spot[attribute].css.backgroundColor =
          colorSpecs.get(color)?.background ??
          spotAttribute.css.backgroundColor;
      }
    });
    context.commit('setSpot', spot);
  },
} as ActionTree<StyleState, RootState>;

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
