import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cb567eb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "settings-menu" }
const _hoisted_2 = { class: "settings-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_settings_navigation = _resolveComponent("settings-navigation")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", {
    id: "settings-view",
    class: _normalizeClass(["lg:flex-row flex-column", _ctx.isEditorView ? 'map-editor' : ' '])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_settings_navigation)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ])
  ], 2))
}