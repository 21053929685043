
import { defineComponent } from 'vue';
import { Contact } from '@/models/customer';

export default defineComponent({
  name: 'ContactSuggestion',
  props: {
    hints: {
      type: Array as () => Array<Contact>,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    onSuggest(hint: Contact): void {
      this.$emit('suggestion', hint);
    },
  },
});
