
import { defineComponent } from 'vue';
import forOwn from 'lodash/forOwn';
import { StyleSpot, StyleSpotAttribute } from '@/models/style';
import Legend from '@/components/shared/Legend.vue';

interface LegendItem {
  label: string;
  icon: string | null;
  attributeIcon: string | null;
  style: {
    [key: string]: string;
  };
  attribute: string;
}

export default defineComponent({
  name: 'MapLegend',
  components: {
    Legend,
  },
  data() {
    return {
      id: 'legend',
    };
  },
  computed: {
    open: {
      get(): string {
        return this.$store.getters['app/focus'];
      },
      set(id: string): void {
        this.$store.commit('app/setFocus', id);
      },
    },
    spotStyle(): StyleSpot {
      return this.$store.getters['style/spot'];
    },
    items(): Array<LegendItem> {
      const items = [] as Array<LegendItem>;
      forOwn(this.spotStyle, (spot: StyleSpotAttribute, attribute: string) => {
        items.push({
          label: spot.label,
          icon: spot.icons ? spot.icons.umbrella : null,
          attributeIcon: spot.attributeIcon ?? null,
          style: spot.css,
          attribute,
        } as LegendItem);
      });
      return items;
    },
  },
  methods: {},
});
