
import { defineComponent, PropType } from 'vue';
import { cloneDeep, pick } from 'lodash';
import { mapState } from 'vuex';
import { Service, ServicePriceMode, ServiceType } from '@/models/service';
import serviceService from '@/services/serviceService';
import { Sector, SectorHeader } from '@/models/sector';
import { PriceList } from '@/models/priceList';
import priceListService from '@/services/priceListService';
import { DropdownOption } from '@/models';
import SettingsServiceDialog from '@/components/settings/SettingsServiceDialog.vue';
import { ServiceGroup } from '@/models/serviceGroup/serviceGroup';
import SettingsServiceGroupDialog from '@/components/settings/SettingsServiceGroupDialog.vue';
import { EditableServiceGroup } from '@/models/serviceGroup/editableServiceGroup';
import serviceGroupService from '@/services/serviceGroupService';
import { EditableService } from '@/models/service/editableService';
import DEFAULT_SERVICE from '@/models/service/serviceDefault';

export default defineComponent({
  name: 'SettingsExtraServicesView',
  components: { SettingsServiceGroupDialog, SettingsServiceDialog },
  data() {
    return {
      currentServiceGroup: undefined as ServiceGroup | undefined,
      toSaveServiceGroup: undefined as EditableServiceGroup | undefined,
      servicePriceMode: cloneDeep(ServicePriceMode),
      serviceModel: {} as EditableService,
      serviceId: 0,
      showDialog: false,
      priceListOptions: [] as Array<DropdownOption>,
      // Service group variables
      showServiceGroupDialog: false,
    };
  },
  props: {
    serviceType: {
      type: Number as PropType<ServiceType>,
      required: true,
    },
  },
  async beforeMount() {
    // Load data all at once
    try {
      this.$store.commit('app/setLoading', true);
      // Load service group if needed
      if (this.$props.serviceType === ServiceType.DAILY_TICKET) {
        this.refreshCurrentGroup();
        // Go back to groups if route is invalid
        // Ticket route needs the group id
        await this.refreshToSaveGroupOrRedirect();
      }
      // force services / sectors refresh
      const priceLists = await Promise.all([
        this.$store.dispatch('session/setSectors'),
        this.$store.dispatch('session/setServices'),
        this.$store.dispatch('session/setServiceGroups'),
        priceListService.find({}),
      ]);
      // price list options
      this.priceListOptions = priceLists[3].map((priceList: PriceList) => ({
        label: priceList.name,
        value: priceList.id,
      }));
    } catch (e) {
      this.$spiagge.toast.error(
        this.$t('settingsExtraServicesView.toast.retrievalError'),
      );
    } finally {
      this.$store.commit('app/setLoading', false);
    }
  },
  methods: {
    /**
     * The view supports both extra services and tickets
     * Many labels will need to be switched based on the service type of the view
     * The type is defined as a prop: serviceType
     */
    hasTablePriceMode(priceMode: ServicePriceMode): boolean {
      return [
        ServicePriceMode.BEDS,
        ServicePriceMode.DAILY_BEDS,
        ServicePriceMode.DAILY,
      ].includes(priceMode);
    },
    getPriceModeLabel(priceMode: ServicePriceMode): string {
      switch (priceMode) {
        case ServicePriceMode.BEDS:
          return this.$t('settingsExtraServicesView.nBeds');
        case ServicePriceMode.DAILY_BEDS:
          return this.$t('settingsExtraServicesView.nDaysBeds');
        case ServicePriceMode.DAILY:
          return this.$t('settingsExtraServicesView.nDays');
        default:
          return '';
      }
    },
    getChipIconClass(flag: boolean): string {
      return flag ? 'pi pi-check' : 'pi pi-times';
    },
    refreshCurrentGroup(): void {
      const serviceGroupId = Number(this.$route.params.id ?? 0);
      this.currentServiceGroup = this.serviceGroups.find(
        (serviceGroup: ServiceGroup) => serviceGroup.id === serviceGroupId,
      );
    },
    isTicket(): boolean {
      return this.$props.serviceType === ServiceType.DAILY_TICKET;
    },
    async refreshToSaveGroupOrRedirect(): Promise<void> {
      if (this.currentServiceGroup !== undefined) {
        this.toSaveServiceGroup = {
          name: this.currentServiceGroup.name,
          description: this.currentServiceGroup.description,
          imageUri: this.currentServiceGroup.imageUri,
          dailyLimit: this.currentServiceGroup.dailyLimit,
          reservationLimit: this.currentServiceGroup.reservationLimit,
        };
      } else {
        await this.redirectToServiceGroups();
      }
    },
    getSubtitle(): string {
      return this.$props.serviceType === ServiceType.BEACH
        ? this.$t('settingsExtraServicesView.extraServiceSubtitle')
        : this.$t('settingsExtraServicesView.dailyTicketSubtitle');
    },
    getCreateButtonLabel(): string {
      return this.$props.serviceType === ServiceType.BEACH
        ? this.$t('settingsExtraServicesView.addService')
        : this.$t('settingsExtraServicesView.addTicket');
    },
    onUpdateService(service: Service) {
      /**
       * Init update form
       */
      // set current service id
      this.serviceId = service.id;
      this.serviceModel = cloneDeep(DEFAULT_SERVICE);
      // copy service property to model
      this.serviceModel = pick(
        service,
        Object.keys(this.serviceModel),
      ) as unknown as EditableService;
      this.serviceModel.sectors = service.sectors
        ? (service.sectors as Array<SectorHeader>).map(
            (sector: SectorHeader) => sector.id,
          )
        : [];
      // open dialog
      this.showDialog = true;
    },
    onAddService() {
      /**
       * Init create form
       */
      // create
      this.serviceId = 0;
      // init model
      this.serviceModel = cloneDeep(DEFAULT_SERVICE);
      this.serviceModel.type = this.$props.serviceType;
      // show dialog
      this.showDialog = true;
    },
    // Redirect to groups
    async redirectToServiceGroups() {
      await this.$router.push('/settings/service-groups');
    },
    // SUBMIT SECTION FOR SERVICES
    async onSubmitServiceForm(model: EditableService) {
      /**
       * Form submit, create or update
       */
      if (this.serviceId) {
        await this.updateService(model);
      } else {
        await this.createService(model);
      }
    },
    async createService(model: EditableService): Promise<void> {
      /**
       * Create a service
       */
      try {
        await serviceService.create(model);
        this.$spiagge.toast.success(
          this.$t('settingsExtraServicesView.toast.createSuccess'),
        );
        await this.reset();
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('settingsExtraServicesView.toast.createError'),
        );
      }
    },
    async updateService(model: EditableService): Promise<void> {
      /**
       * Update a service
       */
      try {
        await serviceService.update(this.serviceId, model);
        this.$spiagge.toast.success(
          this.$t('settingsExtraServicesView.toast.updateSuccess'),
        );
        await this.reset();
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('settingsExtraServicesView.toast.updateError'),
        );
      }
    },
    async reset(): Promise<void> {
      /**
       * Reset
       */
      this.serviceModel = {} as EditableService;
      this.serviceId = 0;
      this.showDialog = false;
      await this.$store.dispatch('session/setServices');
    },
    // Delete from listing
    async onDeleteService(serviceId: number): Promise<void> {
      /**
       * Delete a service
       */
      this.$confirm.require({
        message: this.$t('settingsExtraServicesView.confirm.message'),
        header: this.$t('settingsExtraServicesView.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-danger p-button-outlined',
        acceptLabel: this.$t('button.delete'),
        rejectLabel: this.$t('button.cancel'),
        accept: async () => {
          try {
            // delete service
            await serviceService.delete(serviceId);
            // refresh state
            await this.$store.dispatch('session/setServices');
            this.$spiagge.toast.success(
              this.$t('settingsExtraServicesView.toast.deleteSuccess'),
            );
          } catch (error) {
            this.$spiagge.toast.error(
              this.$t('settingsExtraServicesView.toast.deleteError'),
            );
          }
        },
      });
    },
    // SUBMIT SECTION FOR GROUPS
    async onSubmitGroupForm(model: EditableServiceGroup) {
      await this.updateServiceGroup(model);
    },
    async updateServiceGroup(model: EditableServiceGroup): Promise<void> {
      try {
        await serviceGroupService.update(
          this.currentServiceGroup?.id ?? 0,
          model,
        );
        await this.$store.dispatch('session/setServiceGroups');
        this.refreshCurrentGroup();
        await this.refreshToSaveGroupOrRedirect();
        this.$spiagge.toast.success(
          this.$t('settingsServiceGroupView.toast.updateSuccess'),
        );
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('settingsServiceGroupView.toast.updateError'),
        );
      }
    },
    async onDeleteServiceGroup(): Promise<void> {
      /**
       * Delete a service
       */
      this.$confirm.require({
        message: this.$t('settingsServiceGroupView.confirm.message'),
        header: this.$t('settingsServiceGroupView.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-danger p-button-outlined',
        acceptLabel: this.$t('button.delete'),
        rejectLabel: this.$t('button.cancel'),
        accept: async () => {
          try {
            await serviceGroupService.delete(this.currentServiceGroup?.id ?? 0);
            this.$spiagge.toast.success(
              this.$t('settingsServiceGroupView.toast.deleteSuccess'),
            );
            await this.redirectToServiceGroups();
          } catch (e) {
            this.$spiagge.toast.error(
              this.$t('settingsServiceGroupView.toast.deleteError'),
            );
          }
        },
      });
    },
  },
  computed: {
    ...mapState('session', [
      'license',
      'permissions',
      'sectors',
      'serviceGroups',
    ]),
    ...mapState('app', ['loading']),
    // Get the list of services or tickets
    extraServices(): Array<Service> {
      if (this.$props.serviceType === ServiceType.BEACH) {
        return this.$store.getters['session/beachServices'];
      }
      // Return only those tickets associated to the given group
      return this.$store.getters['session/dailyTicketServices'](true).filter(
        (service: Service) =>
          service.serviceGroupId === this.currentServiceGroup?.id,
      );
    },
    sectorOptions(): Array<DropdownOption> {
      return this.sectors.map((sector: Sector) => ({
        label: sector.header.name,
        value: sector.header.id,
      }));
    },
    mainTitle(): string {
      if (this.$props.serviceType === ServiceType.BEACH) {
        return this.$t('settingsExtraServicesView.extraServiceTitle');
      }
      // Concat "Group" to its name
      return `${this.$t('settingsExtraServicesView.serviceGroupTitle')} ${
        this.currentServiceGroup?.name
      }`;
    },
  },
});
