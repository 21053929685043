/**
 * Card service
 */

import axios, { AxiosResponse } from 'axios';
import {
  ApiCardListResponse,
  ApiCardCreateResponse,
  ApiCardUpdateResponse,
  ApiCardDeleteResponse,
  ApiCardDetailsResponse,
  ApiTransactionCreateResponse,
  ApiCardAstoriaOperationsPayload,
} from '@/models/api';
import { Card, TransactionPayload } from '@/models/card';
import apiService from '@/services/apiService';

export default {
  async list(): Promise<ApiCardListResponse> {
    const res: AxiosResponse<ApiCardListResponse> = await axios.get(
      apiService.card.list(),
    );
    return res.data;
  },
  async listByEmail(email: string): Promise<Array<Card>> {
    const res: AxiosResponse<ApiCardListResponse> = await axios.post(
      apiService.card.listByEmail(),
      { email },
    );
    return res.data.result.cards;
  },
  async details(id: number): Promise<ApiCardDetailsResponse> {
    const res: AxiosResponse<ApiCardDetailsResponse> = await axios.get(
      apiService.card.details(id),
    );
    return res.data;
  },
  async create(payload: Card): Promise<Card> {
    const res: AxiosResponse<ApiCardCreateResponse> = await axios.post(
      apiService.card.create(),
      payload,
    );
    return res.data.result.card;
  },
  async createTransaction(
    id: number,
    payload: TransactionPayload,
  ): Promise<TransactionPayload> {
    const res: AxiosResponse<ApiTransactionCreateResponse> = await axios.post(
      apiService.card.createTransaction(id),
      payload,
    );
    return res.data.result.transaction;
  },
  async update(id: number, payload: Card): Promise<Card> {
    const res: AxiosResponse<ApiCardUpdateResponse> = await axios.patch(
      apiService.card.update(id),
      payload,
    );
    return res.data.result.card;
  },
  async delete(id: number): Promise<Card> {
    const res: AxiosResponse<ApiCardDeleteResponse> = await axios.delete(
      apiService.card.delete(id),
    );
    return res.data.result.card;
  },
  async pay(
    id: number,
    payload: ApiCardAstoriaOperationsPayload,
  ): Promise<void> {
    await axios.post(apiService.card.pay(id), payload);
  },
  async cashback(
    id: number,
    payload: ApiCardAstoriaOperationsPayload,
  ): Promise<void> {
    await axios.post(apiService.card.cashback(id), payload);
  },
};
