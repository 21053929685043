import localforage from 'localforage';

export default {
  configure(name: string, storeName: string): void {
    localforage.config({
      driver: localforage.LOCALSTORAGE,
      name,
      version: 1.0,
      storeName, // Should be alphanumeric, with underscores.
      description: '',
    });
  },
  createInstance(name: string): void {
    localforage.createInstance({
      name,
    });
  },
  async set(key: string, value: any): Promise<void> {
    return localforage.setItem(key, value);
  },
  async get(key: string): Promise<any> {
    // TODO return null if not exist, check interface
    return localforage.getItem(key);
  },
  clear(): void {
    localforage.clear();
  },
};
