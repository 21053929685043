
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import {
  ApiPlannerReservationWithStepsPayload,
  ApiReservationCreatePayload,
} from '@/models/api';
import { SmartSearchSpot } from '@/models/smartSearch';
import { Reservation } from '@/models/reservation';
import reservationService from '@/services/reservationService';
import TopBarNewReservation from '@/components/topbar/TopBarNewReservation.vue';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

export default defineComponent({
  name: 'MapSmartSearch',
  components: { TopBarNewReservation },
  methods: {
    async onReservation(): Promise<void> {
      if (this.spots.length > 1) {
        /**
         * Multiple spots
         */
        const payload = {
          spotType: this.spots[0].spotType,
          spotName: this.spots[0].spotName,
          startDate: this.spots[0].from,
          endDate: this.spots[this.spots.length - 1].to,
          steps: this.spots.slice(1).map((spot: SmartSearchSpot) => ({
            spotType: spot.spotType,
            spotName: spot.spotName,
            startDate: spot.from,
            endDate: spot.to,
          })),
        } as ApiPlannerReservationWithStepsPayload;
        // create reservation with steps
        const reservation: Reservation =
          await reservationService.createReservationWithSteps(payload);
        this.$router.push(`/reservation/${reservation.id}`);
      } else {
        /**
         * Single spot
         */
        const payload = {
          spotType: this.spots[0].spotType,
          spotName: this.spots[0].spotName,
          startDate: this.spots[0].from,
          endDate: this.spots[0].to,
        } as ApiReservationCreatePayload;
        // create reservation
        const reservation: Reservation = await reservationService.createOne(
          payload,
        );
        this.$router.push(`/reservation/${reservation.id}`);
      }
    },
  },
  computed: {
    FEATURE_PERMISSION_ACTION_CONFIG() {
      return FEATURE_PERMISSION_ACTION_CONFIG;
    },
    FEATURE_PERMISSION_CONFIG() {
      return FEATURE_PERMISSION_CONFIG;
    },
    permissionsUtil() {
      return permissionsUtil;
    },
    ...mapState('smartSearch', ['spots']),
    ...mapGetters('smartSearch', ['suggestionAvailable']),
    ...mapGetters('map', ['planner']),
    smartSearchActive: {
      get(): boolean {
        return this.$store.state.smartSearch.active;
      },
      set(active: boolean): void {
        this.$store.commit('smartSearch/setActive', active);
      },
    },
    classes(): Array<string> {
      const classes = [
        'map-control',
        this.smartSearchActive ? 'open' : 'closed',
      ];
      if (this.suggestionAvailable) {
        classes.push('has-suggestion');
      } else {
        classes.push('no-data');
      }
      if (this.planner.active) {
        classes.push('lift');
      }
      return classes;
    },
  },
});
