
import OverlayPanel from 'primevue/overlaypanel';
import { defineComponent } from 'vue';
import { NavigationItem } from '../../models/navigation';

export default defineComponent({
  name: 'TopBarOtherServices',
  data() {
    return {
      menu: [
        {
          label: this.$t('common.yourFood'),
          icon: require('@/assets/images/other-services/yourfood.png'),
          cta: () => {
            window.open('https://yourfood.spiagge.it', '_blank');
          },
        },
        {
          label: this.$t('common.spiaggeVoucher'),
          icon: require('@/assets/images/other-services/spiagge.jpg'),
          cta: () => {
            window.open('https://legacy.spiagge.it/app/voucher/', '_blank');
          },
        },
        {
          label: this.$t('common.anm22Pay'),
          icon: require('@/assets/images/other-services/anm22-pay.png'),
          cta: () => {
            window.open('https://www.anm22.it/app/pay/', '_blank');
          },
        },
        {
          label: this.$t('common.scloby'),
          icon: require('@/assets/images/other-services/tilby.jpg'),
          cta: () => {
            window.open('https://login.tilby.com/?spiagge-it', '_blank');
          },
        },
        {
          label: this.$t('common.cassaInCloud'),
          icon: require('@/assets/images/other-services/cassa-in-cloud.png'),
          cta: () => {
            window.open('https://my.cassanova.com/?spiagge-it', '_blank');
          },
        },
      ] as Array<NavigationItem>,
    };
  },
  methods: {
    toggle(event: Event): void {
      (this.$refs.op as OverlayPanel).toggle(event);
    },
  },
});
