
import { defineComponent, PropType } from 'vue';
import { mapState } from 'vuex';
import { ContentLoader } from 'vue-content-loader';
import { StatisticTreeItem } from '@/models/statistic';

export default defineComponent({
  name: 'StatisticsTree',
  components: {
    ContentLoader,
  },
  props: {
    items: {
      type: Array as PropType<Array<StatisticTreeItem>>,
      required: true,
    },
    showToggle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showChildren: !this.showToggle,
    };
  },
  methods: {},
  computed: {
    ...mapState('app', ['windowWidth', 'breakpoints']),
  },
});
