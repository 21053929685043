// TODO: Find a way to use Vue toast component
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

function hex2a(hexx) {
  const hex = hexx.toString();
  let str = '';
  for (let i = 0; i < hex.length; i += 2) {
    str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
  }
  return str;
}

function messageHandler(event: MessageEvent): void {
  // console.log(event);
  if (event.data && typeof event.data === 'string') {
    try {
      const dataObj = JSON.parse(event.data);
      if (dataObj.cmd) {
        const data = dataObj.data as string;
        const cmd = dataObj.cmd as string;
        switch (cmd) {
          case 'postCustomProtocolMsgResponse':
            // console.log(`ElectronMessageCUSTOMResponse: ${dataObj.data}`);
            // TODO: make constant
            if (
              data.substring(10, 18) === '33303133' ||
              data.substring(8, 16) === '33303133'
            ) {
              if (globalThis.printerCustomCallback) {
                if (globalThis.printerCustomCallback instanceof Function) {
                  printerCustomCallback();
                } else {
                  window.printerCustomCallback();
                }
              }
            } else if (
              dataObj.data.substring(10, 18) === '34303034' ||
              dataObj.data.substring(8, 16) === '34303034'
            ) {
              if (globalThis.printerCustomCallback) {
                if (globalThis.printerCustomCallback instanceof Function) {
                  printerCustomCallback();
                } else {
                  window.printerCustomCallback();
                }
              }
            } else if (dataObj.data.substring(10, 16) === '455252') {
              const customError = hex2a(dataObj.data.substring(16, 20));
              // console.log(`CUSTOM error ${customError}`);
              window.com_anm22_wg_headbar_search_showMessage(
                `CUSTOM ERR ${customError}`,
                'alarm',
              );
              if (globalThis.printerCustomCallback) {
                if (globalThis.printerCustomCallback instanceof Function) {
                  printerCustomCallbackFail();
                } else {
                  window.printerCustomCallbackFail();
                }
              }
            }
            break;
          case 'postCustomProtocolError':
            // console.log(`ElectronError: ${dataObj.data}`);
            // com_anm22_wg_headbar_search_showMessage(
            //   'Controllare la stampante',
            //   'alarm',
            // );
            break;
          default:
            // console.log('Electron: Invalid command', 'alarm');
        }
      } else {
        // console.log('ElectronError: Command missing');
        // com_anm22_wg_headbar_search_showMessage('Electron: Command missing','alarm');
      }
    } catch (e) {
      // console.log('ElectronError: Invalid JSON');
      // com_anm22_wg_headbar_search_showMessage('Electron: Invalid JSON','alarm');
    }
  }
}

export default {
  attachElectronListener(): void {
    window.addEventListener('message', messageHandler, false);
  },
};
