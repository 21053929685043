import { ServiceIcon, ServiceLimit, ServicePriceMode, ServiceType } from '@/models/service';
import { ColorName } from '@/models/color';
import { ReservationHalfDay } from '@/models/reservation';

export interface EditableService {
    name: string;
    serviceGroupId: number | null;
    cost: number;
    vat: number;
    type: ServiceType;
    halfDay: ReservationHalfDay;
    priceMode: ServicePriceMode;
    priceListId: number | null;
    fastCash: boolean;
    reservationSchedule: boolean;
    map: boolean;
    online: boolean;
    canBeSoldAloneOffline: boolean;
    canBeSoldAloneOnline: boolean;
    sellableOnlineAlone: boolean;
    lockedBehindServices: boolean;
    unlockServiceIds: Array<number>;
    sectors: Array<number>;
    maxItemsType: ServiceLimit;
    maxItemsFixed: number;
    minItemsType: ServiceLimit;
    minItemsFixed: number;
    maxItemsTypeAlone: ServiceLimit;
    maxItemsFixedAlone: number;
    minItemsTypeAlone: ServiceLimit;
    minItemsFixedAlone: number;
    hideWhenNotAvailableOnline: boolean;
    dailyItems: number;
    icon: ServiceIcon;
    background: ColorName | null;
}
