import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  id: "map-calendar",
  class: "map-control"
}
const _hoisted_2 = {
  class: "calendar",
  ref: "calendar"
}
const _hoisted_3 = { class: "from" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 0,
  class: "to"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.equal)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            class: "p-button-white prev",
            onClick: _withModifiers(_ctx.onPrev, ["stop"]),
            icon: "pi pi-angle-left"
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true),
      _createVNode(_component_Button, {
        class: "p-button-white date",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onDateClick($event)))
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_3, [
            (_ctx.showToDate)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('common.from')) + ":", 1))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.fromDate), 1)
          ]),
          (_ctx.showToDate)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.to')) + ":", 1),
                _createTextVNode(" " + _toDisplayString(_ctx.toDate), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      (_ctx.equal)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            class: "p-button-white next",
            onClick: _withModifiers(_ctx.onNext, ["stop"]),
            icon: "pi pi-angle-right"
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ], 512),
    _createVNode(_component_Button, {
      class: _normalizeClass(["p-button-white today", !_ctx.today ? 'not' : '']),
      onClick: _ctx.onToday,
      icon: _ctx.today ? 'pi pi-check' : 'pi pi-times',
      label: _ctx.today ? _ctx.$t('common.today') : _ctx.$t('common.notToday')
    }, null, 8, ["class", "onClick", "icon", "label"]),
    _createVNode(_component_OverlayPanel, {
      ref: "op",
      id: "map-calendar-overlay-panel",
      onHide: _ctx.checkDates
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Calendar, {
          modelValue: _ctx.calendarModel,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.calendarModel) = $event)),
          inline: "",
          selectionMode: "range",
          numberOfMonths: 2,
          panelClass: "dense"
        }, null, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["onHide"])
  ]))
}