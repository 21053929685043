import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d7eab84a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = {
  key: 0,
  class: "smart-search-header"
}
const _hoisted_3 = {
  key: 1,
  class: "smart-search"
}
const _hoisted_4 = { class: "spots" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { class: "end-date" }
const _hoisted_7 = {
  key: 2,
  class: "no-data"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_TopBarNewReservation = _resolveComponent("TopBarNewReservation")!

  return (_openBlock(), _createElementBlock("div", {
    id: "map-smart-search",
    class: _normalizeClass(_ctx.classes)
  }, [
    _createVNode(_component_Button, {
      class: "smart-search-button p-button-white",
      icon: _ctx.smartSearchActive ? 'pi pi-times' : 'spi-search-alt',
      label: 
        _ctx.smartSearchActive
          ? _ctx.$t('mapSmartSearch.closeLabel')
          : _ctx.$t('mapSmartSearch.openLabel')
      ,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.smartSearchActive = !_ctx.smartSearchActive))
    }, null, 8, ["icon", "label"]),
    _createElementVNode("div", _hoisted_1, [
      (
          _ctx.permissionsUtil.isActionPermissionAllowed(
            _ctx.FEATURE_PERMISSION_CONFIG.map,
            _ctx.FEATURE_PERMISSION_ACTION_CONFIG.map.SMART_SEARCH,
          )
        )
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("h6", null, _toDisplayString(_ctx.$t('mapSmartSearch.title')), 1),
            (_ctx.suggestionAvailable)
              ? (_openBlock(), _createBlock(_component_Button, {
                  key: 0,
                  label: _ctx.$t('mapSmartSearch.reservationLabel'),
                  onClick: _ctx.onReservation,
                  class: "reservation-button"
                }, null, 8, ["label", "onClick"]))
              : _createCommentVNode("", true),
            (_ctx.suggestionAvailable)
              ? (_openBlock(), _createBlock(_component_TopBarNewReservation, {
                  key: 1,
                  isSmartSearch: ""
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.suggestionAvailable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.spots, (spot, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "spot",
                  key: index
                }, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(spot.spotName), 1),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$spiagge.date.fromMillis(spot.to, 'dd/MM')), 1)
                ]))
              }), 128))
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('mapSmartSearch.noSuggestionMessagge')), 1),
            _createVNode(_component_TopBarNewReservation, { isSmartSearch: "" })
          ]))
    ])
  ], 2))
}