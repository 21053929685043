/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/**
 * Voucher models
 */

import { Timestamp } from '.';

export interface Voucher {
  id: number;
  licenseId: string;
  workId: number | null;
  name: string;
  hotelPriceListId: number | null;
  customerPriceListId: number | null;
  background: string | null;
  email: string | null;
  onlineDiscount: number;
  onlineMode: boolean;
  onlinePayment: boolean;
  createdAt: Timestamp | null;
  updatedAt: Timestamp | null;
}

export interface DefaultVoucher {
  id: number | null;
  licenseId: number;
  workId: number;
  // work: any | null;
  name: string;
  hotelPriceListId: number;
  customerPriceListId: number;
  background: string;
  email: string | null;
  onlineDiscount: number;
  onlineMode: boolean;
  onlinePayment: boolean;
}
