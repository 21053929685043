
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import merge from 'lodash/merge';
import { cloneDeep } from 'lodash';
import { DropdownOption, ListElementNumber, ListElementString } from '@/models';
import {
  CabinMode,
  License,
  PayloadPriceListSettings,
  PosCommandPaper,
} from '@/models/license';
import licenseService from '@/services/licenseService';
import toastUtil from '@/utils/toastUtil';
import { ToastSeverity } from '@/models/toast';
import InputTooltip from '@/components/shared/InputTooltip.vue';

export default defineComponent({
  components: { Form, InputTooltip },
  data() {
    return {
      sectorsModes: [
        { name: this.$t('priceList.changeSectorMode.media'), code: 0 },
        {
          name: this.$t('priceList.changeSectorMode.repeatFirstDay'),
          code: 1,
        },
        { name: this.$t('priceList.changeSectorMode.startSector'), code: 2 },
        { name: this.$t('priceList.changeSectorMode.finalSector'), code: 3 },
      ] as Array<ListElementNumber>,
      accountsCalc: [
        { name: this.$t('priceList.calcUnionAccountMode.sum'), code: 0 },
        { name: this.$t('priceList.calcUnionAccountMode.price'), code: 1 },
      ] as Array<ListElementNumber>,
      tickets: [
        { name: this.$t('priceList.ticket.disable'), code: 0 },
        { name: this.$t('priceList.ticket.activeForPrices'), code: 1 },
        { name: this.$t('priceList.ticket.activeForExtraService'), code: 2 },
      ] as Array<ListElementNumber>,
      filtersVoucherModes: [
        { name: this.$t('priceList.filterDatesMode.closePeriod'), code: 0 },
        { name: this.$t('priceList.filterDatesMode.startPeriod'), code: 1 },
      ] as Array<ListElementNumber>,
      posCommandPaperOptions: [
        {
          label: this.$t('priceList.posCommandPaperOptions.printOrder'),
          value: PosCommandPaper.PRINT_ORDER,
        },
        {
          label: this.$t('priceList.posCommandPaperOptions.printSummary'),
          value: PosCommandPaper.PRINT_SUMMARY,
        },
        {
          label: this.$t('priceList.posCommandPaperOptions.noPrint'),
          value: PosCommandPaper.NO_PRINT,
        },
      ] as Array<DropdownOption>,
      abilitations: [
        { name: this.$t('common.enabled'), code: 0 },
        { name: this.$t('common.disabled'), code: 1 },
      ] as Array<ListElementNumber>,
      cashdesksFlowsStat: [
        { name: this.$t('priceList.cashDeskFlowStatics.active'), code: 1 },
        { name: this.$t('priceList.cashDeskFlowStatics.disable'), code: 0 },
        { name: this.$t('priceList.cashDeskFlowStatics.print'), code: 2 },
      ] as Array<ListElementNumber>,
      yesNo: [
        { name: this.$t('common.yes'), code: '0' },
        { name: this.$t('common.no'), code: '1' },
      ] as Array<ListElementString>,
      listPriceWorkstationsModes: [
        {
          name: this.$t('priceList.sunbedPositionListMode.sectorPrice'),
          code: 0,
        },
        {
          name: this.$t('priceList.sunbedPositionListMode.sunbedPrice'),
          code: 1,
        },
      ] as Array<ListElementNumber>,
      settingsUseCabins: [
        {
          name: this.$t(
            'beachMapConfig.cabins.useCabinsMode.extraServiceReservation',
          ),
          code: CabinMode.SERVICE,
        },
        {
          name: this.$t(
            'beachMapConfig.cabins.useCabinsMode.independentReservation',
          ),
          code: CabinMode.RESERVATION,
        },
      ] as Array<ListElementNumber>,
      localLicense: {} as License,
      priceList: {} as PayloadPriceListSettings,
    };
  },
  methods: {
    async submit() {
      this.priceList.pricesVoucherDefaultButton =
        this.localLicense.pricesVoucherDefaultButton;
      this.priceList.pricesVoucherFilterMode =
        this.localLicense.pricesVoucherFilterMode;
      this.priceList.statisticsAdvanced = this.localLicense.statisticsAdvanced;
      this.priceList.beachTickets = this.localLicense.beachTickets;
      this.priceList.pricesChangeSectorMode =
        this.localLicense.pricesChangeSectorMode;
      this.priceList.pricesMergedRegMode =
        this.localLicense.pricesMergedRegMode;
      this.priceList.fastCash = this.localLicense.fastCash;
      this.priceList.fastCashStdBtns = this.localLicense.fastCashStdBtns;
      this.priceList.posCommandPaper = this.localLicense.posCommandPaper;
      this.priceList.pricesCashFlow = this.localLicense.pricesCashFlow;
      this.priceList.pricesCashFlowDelete =
        this.localLicense.pricesCashFlowDelete;
      this.priceList.pricesBedsOld = this.localLicense.pricesBedsOld;
      this.priceList.cabinMode = this.localLicense.cabinMode;
      this.priceList.receiptPrintCustomerName =
        this.localLicense.receiptPrintCustomerName;
      this.priceList.stickerSettingsPrintCustomer =
        this.localLicense.stickerSettingsFields.printCustomer;
      this.priceList.stickerSettingsPrintHotelRoom =
        this.localLicense.stickerSettingsFields.printHotelRoom;
      this.priceList.stickerSettingsPrintSpot =
        this.localLicense.stickerSettingsFields.printSpot;
      this.priceList.stickerSettingsPrintReservationDates =
        this.localLicense.stickerSettingsFields.printReservationDates;
      this.priceList.stickerSettingsPrintStaging =
        this.localLicense.stickerSettingsFields.printStaging;

      try {
        const response = await licenseService.updatePricesSettings(
          this.license?.license as string,
          this.priceList,
        );
        this.localLicense = cloneDeep(response.result.license);
        this.$store.commit('session/setLicense', response.result.license);
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.SUCCESS,
            this.$t('toast.save.title'),
            this.$t('toast.save.content'),
          ),
        );
        this.scrollToTop();
        this.priceList = {} as PayloadPriceListSettings;
      } catch (error) {
        this.$toast.add(
          toastUtil.build(
            ToastSeverity.ERROR,
            this.$t('toast.error.title'),
            this.$t('toast.error.msg'),
          ),
        );
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  computed: {
    loading(): boolean {
      return this.$store.getters['app/loading'];
    },
    license(): License | null {
      return this.$store.getters['session/license'];
    },
    isCabinModeVisible(): boolean {
      // Always visible because supported by the backend
      return true;
      // The function below hides the dropdown
      // when cabin price mode changes
      // return (
      //   this.localLicense.cabinMode === CabinMode.SERVICE
      // );
    },
  },
  async beforeMount() {
    if (this.license) {
      await this.$store.dispatch('session/setLicense', this.license.license);
    }
    merge(this.localLicense, this.license);
  },
});
