
import { defineComponent, PropType } from 'vue';
import clone from 'lodash/clone';
import { mapState } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { round } from 'lodash';
import { MapMode, MapShiftStep, MapView, MapSpot } from '@/models/map';
import reservationService from '@/services/reservationService';
import { AppAction, AppSpotDialog } from '@/models/app';
import { ApiReservationCreatePayload } from '@/models/api';
import { SpotType } from '@/models/spot';
import {
  LicenseFreeSpotOpeningMode,
  LicenseMapCabinMode,
  LicenseUmbrellaMultipleBookings,
} from '@/models/license';
import { Css } from '@/models';
import spotUtil from '@/utils/spotUtil';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

export default defineComponent({
  name: 'MapSpot',
  props: {
    cellWidth: {
      type: Number,
      required: true,
    },
    cellHeight: {
      type: Number,
      required: true,
    },
    spot: {
      type: Object as PropType<MapSpot>,
      required: true,
    },
    shiftRecap: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      mapView: clone(MapView),
      mapMode: clone(MapMode),
      processing: false,
    };
  },
  methods: {
    async onClick(): Promise<void> {
      /* prevent if shift recap  */
      if (this.shiftRecap) return;
      /* prevent if panning  */
      if (this.$store.getters['map/panning']) return;

      /* if beds area emit the event and prevent (only if no mode is active)  */
      if (this.spot.type === SpotType.BEDS) {
        if (this.mode === MapMode.NONE) {
          this.$emit('beds-area', this.spot.name);
        }
        return;
      }

      /* if cabin check map cabin mode is OPEN_LIST */
      if (
        this.spot.type === SpotType.CABIN
        && this.license.mapCabinMode === LicenseMapCabinMode.OPEN_LIST
        && this.mode === MapMode.NONE
      ) {
        this.$emit('cabins-dialog');
        return;
      }

      /* ----- NO MODE ----- */
      // TODO prevent if is selecting and spot is busy or locked
      if (this.mode === MapMode.NONE) {
        // create or view
        const reservationId = this.spot.id;
        if (reservationId) {
          if (
            permissionsUtil.isActionPermissionAllowed(
              FEATURE_PERMISSION_CONFIG.reservations,
              FEATURE_PERMISSION_ACTION_CONFIG.reservations.PAGE_ACCESS,
            )
          ) {
            // view
            this.$store.commit('app/setAction', AppAction.VIEW_RESERVATION);
            this.$router.push(`/reservation/${reservationId}`);
          }
        } else {
          // create (check spot opening mode)
          // eslint-disable-next-line no-lonely-if
          if (
            this.license.freeSpotOpeningMode ===
            LicenseFreeSpotOpeningMode.DIRECT
          ) {
            if (
              permissionsUtil.isActionPermissionAllowed(
                FEATURE_PERMISSION_CONFIG.map,
                FEATURE_PERMISSION_ACTION_CONFIG.map.ADD_UPDATE_RESERVATIONS,
              )
            ) {
              this.createReservation();
            }
          } else {
            this.$store.commit('app/setSpotDialog', {
              spotName: this.spot.name,
              spotType: this.spot.type,
              callback: this.createReservation,
            } as AppSpotDialog);
          }
        }
      }

      /* ----- SHITFT/PROGRAMMED SHIFT MODE ----- */
      if (
        this.mode === MapMode.SHIFT ||
        this.mode === MapMode.PROGRAMMED_SHIFT
      ) {
        if (this.shift.step === MapShiftStep.SELECT_FROM) {
          // select the starting spot
          // prevent on free spot
          if (!this.spot.id) return;
          this.$store.commit(
            'map/setShiftFrom',
            this.equalSpots(this.spot, this.shift.from)
              ? null
              : cloneDeep(this.spot),
          );
          // 02/03/2021 prevent confirm action in map selction component
          this.$store.commit('map/setShiftStep', MapShiftStep.SELECT_MODE);
        } else {
          // select the spot destination
          // prevent on destination spot equal starting spot
          if (this.equalSpots(this.spot, this.shift.from)) return;
          // prevent on not shiftable spot
          if (!spotUtil.isShiftable(this.shift.from.type, this.spot.type)) {
            return;
          }
          // prevent on busy spot if overbooking block enabled
          if (
            this.spot.id &&
            this.license.umbrellaMultipleBookings ===
              LicenseUmbrellaMultipleBookings.BLOCKED
          ) {
            this.$spiagge.toast.error(
              this.$t('mapSpot.toast.overbookingBlockError'),
              '',
              'map',
            );
            return;
          }
          // spot is already the destination spot, remove it. Otherwise add it
          if (this.equalSpots(this.spot, this.shift.to)) {
            this.$store.commit('map/setShiftTo', null);
          } else {
            this.$store.commit('map/setShiftTo', cloneDeep(this.spot));
            // 02/03/2021 prevent confirm action in map selction component
            this.$store.commit('map/setShiftStep', MapShiftStep.CONFIRM);
          }
        }
      }

      /* ----- MULTIPLE SELECTION MODE ----- */
      if (this.mode === MapMode.MULTIPLE_SELECTION) {
        // prevent on busy spot
        if (this.spot.id) return;
        // add spot only if is selectable (first spot added to selected is the main spot type)
        if (
          this.selected.length > 0 &&
          !spotUtil.isSelectable(this.selected[0].type, this.spot.type)
        ) {
          return;
        }
        if (!this.isSelected) {
          // spot is not selected, add to the list
          this.$store.commit(
            'map/setSelected',
            cloneDeep(this.selected).concat(this.spot),
          );
        } else {
          // spot is already selected, remove from the list
          this.$store.commit(
            'map/setSelected',
            this.selected.filter(
              (spot: MapSpot) => !this.equalSpots(spot, this.spot),
            ),
          );
        }
      }

      /* ----- COPY MODE ----- */
      if (this.mode === MapMode.COPY) {
        const spotType = this.$route.query.spotType as SpotType;
        // prevent on busy spot
        if (this.spot.id) return;
        // check if spot is copyable
        if (!spotUtil.isCopyable(spotType, this.spot.type)) return;

        if (!this.isSelected) {
          // spot is not selected, add to the list
          this.$store.commit(
            'map/setSelected',
            cloneDeep(this.selected).concat(this.spot),
          );
        } else {
          // spot is already selected, remove from the list
          this.$store.commit(
            'map/setSelected',
            this.selected.filter(
              (spot: MapSpot) => !this.equalSpots(spot, this.spot),
            ),
          );
        }
      }
    },
    async createReservation(): Promise<void> {
      if (this.processing) {
        return;
      }
      this.processing = true;
      try {
        const payload = {
          spotName: this.spot.name,
          spotType: this.spot.type,
          startDate: this.$store.getters['map/calendar'][0].toSeconds(),
          endDate: this.$store.getters['map/calendar'][1].toSeconds(),
          ...this.reservationPayload,
        } as ApiReservationCreatePayload;

        const res = await reservationService.createOne(payload);
        this.$store.commit('reservation/setSpot', this.spot);
        this.$store.commit('app/setAction', AppAction.CREATE_RESERVATION);
        this.$router.push(`/reservation/${res.id}`);
      } catch (e) {
        this.processing = false;
        this.$spiagge.toast.error(
          this.$t('mapSpot.toast.createReservationError'),
        );
      }
    },
    equalSpots(spot1: MapSpot, spot2: MapSpot | null) {
      if (spot2 === null) return false;
      return spot1.name === spot2.name && spot1.type === spot2.type;
    },
  },
  computed: {
    ...mapState('map', [
      'mode',
      'view',
      'scale',
      'selected',
      'shift',
      'data',
      'calendar',
      'reservationPayload',
    ]),
    ...mapState('session', ['license']),
    isSelected(): boolean {
      if (
        this.mode === MapMode.MULTIPLE_SELECTION ||
        this.mode === MapMode.COPY
      ) {
        return this.selected.some((spot: MapSpot) =>
          this.equalSpots(this.spot, spot),
        );
      }
      if (
        this.mode === MapMode.SHIFT ||
        this.mode === MapMode.PROGRAMMED_SHIFT
      ) {
        return (
          this.equalSpots(this.spot, this.shift.from) ||
          this.equalSpots(this.spot, this.shift.to)
        );
      }
      return false;
    },
    isSelectedLocked(): boolean {
      /**
       * If copy check the spot informations provided in the url
       */
      if (this.mode === MapMode.COPY) {
        const spotType = this.$route.query.spotType;
        const spotName = this.$route.query.spotName;
        return this.spot.type === spotType && this.spot.name === spotName;
      }
      /**
       * If shift/programmed shift and shift step is SELECT_TO check the shift from spot
       */
      if (
        (this.mode === MapMode.SHIFT ||
          this.mode === MapMode.PROGRAMMED_SHIFT) &&
        this.shift.step === MapShiftStep.SELECT_TO
      ) {
        return this.equalSpots(this.spot, this.shift.from);
      }
      /**
       * No selected locked
       */
      return false;
    },
    isBedsArea(): boolean {
      return this.spot.type === SpotType.BEDS;
    },
    isPlayField(): boolean {
      return this.spot.type === SpotType.PLAYFIELD;
    },
    mapSpotClasses() {
      const classes = [`${this.view}-view`];
      return classes;
    },
    mapSpotStyle(): { [key: string]: string } {
      if (this.shiftRecap) {
        return {
          height: `${round(this.cellHeight)}px`,
          width: `${round(this.cellWidth)}px`,
        };
      }
      return {
        position: 'absolute',
        left: `${this.posX}px`,
        top: `${this.posY}px`,
        height: `${this.height}px`,
        width: `${this.width}px`,
      };
    },
    spotClasses() {
      const classes = [];
      classes.push(this.spot.type);
      classes.push(this.spot.attributes.join(' '));
      if (this.spot.locked) {
        classes.push('locked');
      }
      if (this.shiftRecap) {
        return classes;
      }
      if (this.isSelected) classes.push('selected');
      if (this.isSelectedLocked) classes.push('selected-locked');
      return classes;
    },
    spotStyle(): Css {
      return this.spot.style.css;
    },
    posX(): number {
      return round(this.spot.posX * this.cellWidth, 0);
    },
    posY(): number {
      return round(this.spot.posY * this.cellHeight, 0);
    },
    width(): number {
      if (this.isBedsArea) {
        return 52 - 1;
      }
      return round(this.spot.width * this.cellWidth - 1, 0);
    },
    height(): number {
      if (this.isBedsArea) {
        return 52;
      }
      return round(this.spot.height * this.cellHeight - 1, 0);
    },
    date(): string {
      let date = '';
      if (this.spot.startDate) {
        if (this.spot.endDate) {
          // if end date use it
          date = this.$spiagge.date.fromMillis(this.spot.endDate, 'd/M');
        } else {
          // future
          date = this.$spiagge.date.fromMillis(this.spot.startDate, 'd/M');
        }
      }
      return date;
    },
    name(): string {
      let name = this.spot.name ?? '';
      if (this.spot.type === SpotType.PLAYFIELD) {
        name = spotUtil.getPlayFieldDataByName(name).name;
      }
      return name;
    },
    staging(): string {
      let staging = '';
      if (this.isPlayField) {
        staging = spotUtil.getPlayFieldDataByName(this.spot.name).timeSlot;
      } else {
        staging = this.$spiagge.utils.global.stagingLabel(
          this.spot?.staging?.maxiBeds || 0,
          this.spot?.staging?.beds || 0,
          this.spot?.staging?.deckChairs || 0,
          this.spot?.staging?.chairs || 0,
        );
      }
      return staging;
    },
  },
});
