
import { defineComponent } from 'vue';
import { License } from '@/models/license';
import licenseService from '@/services/licenseService';
import { StripePayload } from '@/models';

export default defineComponent({
  data() {
    return {
      isCustom: false,
      successConnection: false,
      errorType: '',
      response: null,
      isLoading: true,
    };
  },
  methods: {
    async stripeConnectionFunction() {
      try {
        if (this.license) {
          const payload = { type: 'custom' } as StripePayload;
          const response = await licenseService.stripeConnectionInit(this.license.license, payload);
          window.open(response.url);
        }
      } catch (error) {
        // console.log(error);
      }
    },
    goToBookingConfing() {
      this.$router.push('/settings/booking');
    },
  },
  computed: {
    license(): License | null {
      return this.$store.getters['session/license'];
    },
    isConnect(): boolean {
      return this.successConnection;
    },
  },
  async beforeMount() {
    const param = this.$route.params.type;
    if (param.length > 0) {
      this.isCustom = param === 'custom';
      try {
        if (this.license) {
          const payload = { type: param } as StripePayload;
          if (!this.isCustom) {
            // console.log(this.$route.query);
            payload.code = this.$route.query.code;
            payload.scope = this.$route.query.scope;
            payload.state = this.$route.query.state;
          }
          this.response = await licenseService
            .stripeConnectionFinalize(this.license.license, payload);
          this.successConnection = true;
          // console.log(this.response);
          this.isLoading = false;
        }
      } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.errorType = (error as any).response.data.error;
        this.successConnection = false;
        this.isLoading = false;
      }
    }
  },
});
