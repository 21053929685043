
import { defineComponent } from 'vue';
import { FilterMatchMode } from 'primevue/api';
import { cloneDeep, forOwn } from 'lodash';

import debounce from 'lodash/debounce';
import {
  DataTablePageEvent,
  DataTableSortEvent,
  DataTableFilterEvent,
} from 'primevue/datatable';
import { License } from '@/models/license';
import CustomerDialog from '@/components/shared/CustomerDialog.vue';
import customerService from '@/services/customerService';
import {
  Address,
  Customer,
  DEFAULT_CUSTOMER,
  OtherContact,
  Phone,
} from '@/models/customer';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';
import { OrderByItem, FilterByItem } from '@/models';
import { ApiCustomerIndexRequestPayload } from '@/models/api';
import { phoneUtils } from '@/utils/phoneUtils';

export default defineComponent({
  components: {
    CustomerDialog,
  },
  data() {
    return {
      isLoading: true,
      show: false,
      showDeleteDialog: false,
      offset: 0,
      limit: 20,
      totalRecords: 0,
      orderBys: [
        {
          field: 'fullName',
          value: 'asc',
        },
      ] as Array<OrderByItem>,
      filterBys: [] as Array<FilterByItem>,
      customerSelected: null as Customer | null,
      customerList: [] as Array<Customer>,
      search: undefined as string | undefined,
      filters: {
        fullName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        phone: { value: null, matchMode: FilterMatchMode.CONTAINS },
        hasAssociatedCard: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      debouncedFilter: debounce(
        this.onFilter as (event: DataTableFilterEvent) => void,
        300,
      ),
      debouncedSearch: debounce(this.runCustomerQuery as () => void, 300),
      hasAssociatedCardPossibilities: [
        { key: this.$t('contactsView.anyone'), value: '' },
        { key: this.$t('common.yes'), value: true },
        { key: this.$t('common.no'), value: false },
      ],
    };
  },
  methods: {
    hasCustomerStatisticsPermission(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.customers,
        FEATURE_PERMISSION_ACTION_CONFIG.customers.CUSTOMER_STATS,
      );
    },
    getFullName(firstName: string, lastName: string): string {
      const out =
        firstName || lastName
          ? `${firstName} ${lastName}`
          : this.$t('common.noName');
      return out;
    },
    newContact() {
      this.customerSelected = cloneDeep(DEFAULT_CUSTOMER);
      this.customerSelected.otherContacts = {
        emails: [],
        phones: [],
      };
      this.customerSelected.phoneAreaCode =
        phoneUtils.getPhoneAreaCodeByLicense(this.license);
      this.show = true;
    },
    addOtherEmail() {
      if (!this.customerSelected) return;
      this.customerSelected.otherContacts.emails.push({} as Address);
    },
    addOtherPhone() {
      if (!this.customerSelected) return;
      this.customerSelected.otherContacts.phones.push({} as Phone);
    },
    onCustomerSave(result: Customer) {
      if (result) {
        this.customerList = [result, ...this.customerList];
      }
    },
    onCustomerClose() {
      this.show = false;
    },
    openReservationContact(data: any) {
      if (!this.hasCustomerStatisticsPermission()) {
        return;
      }
      let id;
      if (data.contactId) {
        id = data.contactId;
      } else {
        const parts = [
          data.firstName ?? '',
          data.lastName ?? '',
          data.email ?? '',
          data.phoneNumber ?? '',
        ];
        id = parts.join(',');
      }
      this.$router.push({
        name: 'ContactReservations',
        params: { contactId: id },
      });
    },
    deleteDialogShow(id: number) {
      const customer = this.customerList.find(
        (customer: Customer) => customer.contactId === id,
      );
      if (!customer) return;
      this.customerSelected = customer;
      this.showDeleteDialog = true;
    },
    deleteDialogClose() {
      this.showDeleteDialog = false;
    },
    async deleteCustomer() {
      if (!this.customerSelected) return;
      try {
        await customerService.delete(this.customerSelected.contactId);
        const index = this.customerList.findIndex(
          (c) => c.contactId === this.customerSelected?.contactId,
        );
        this.customerList.splice(index, 1);
        this.showDeleteDialog = false;
      } catch (e) {
        // console.log(e);
      }
    },
    onPageNavigation(event: DataTablePageEvent) {
      this.offset = event.page * event.rows;
      this.limit = event.rows;
      this.runCustomerQuery();
    },
    onSort(event: DataTableSortEvent) {
      this.offset = 0;
      this.orderBys = [];
      this.orderBys.push({
        field: event.sortField as string,
        value:
          typeof event.sortOrder === 'number'
            ? event.sortOrder < 0
              ? 'desc'
              : 'asc'
            : 'asc',
      });
      this.runCustomerQuery();
    },
    onFilter(event: DataTableFilterEvent) {
      this.offset = 0;
      this.filterBys = [];
      forOwn(event.filters, (filter: any, key: string) => {
        if (filter.value !== null && filter.value !== '') {
          const f = {
            field: key,
            value: filter.value,
          };
          this.filterBys.push(f);
        }
      });
      this.runCustomerQuery();
    },
    async runCustomerQuery() {
      this.isLoading = true;
      try {
        const payload = {
          orderBys: this.orderBys,
          filterBys: this.filterBys,
          limit: this.limit,
          offset: this.offset,
          searchBy: this.search,
        } as ApiCustomerIndexRequestPayload;
        // Fetch customers
        const queryResult = await customerService.list(payload);
        this.customerList = queryResult.result.customers;
        this.totalRecords = queryResult.result.totalRecords;
        this.isLoading = false;
      } catch (e) {
        // Customers fetch failed
        // console.log(e);
        this.$spiagge.toast.error(this.$t('contactsView.toast.retrievalError'));
      }
    },
  },
  computed: {
    loading(): boolean {
      return this.$store.getters['app/loading'];
    },
    license(): License {
      return this.$store.getters['session/license'];
    },
  },
  async beforeMount() {
    if (!this.license) return;
    await this.runCustomerQuery();
  },
});
