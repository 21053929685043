/**
 * Voucher service
 */

import axios, { AxiosResponse } from 'axios';
import {
  ApiVoucherListResponse,
  ApiVoucherCreateResponse,
  ApiVoucherUpdateResponse,
  ApiVoucherDeleteResponse,
  ApiVoucherCreatePayload,
  ApiVoucherGetResponse,
  ApiVoucherUpdatePayload,
} from '@/models/api';
import { Voucher } from '@/models/voucher';
import apiService from '@/services/apiService';

export default {
  async get(voucherId: number): Promise<Voucher> {
    const res: AxiosResponse<ApiVoucherGetResponse> = await axios.get(
      apiService.voucher.get(voucherId),
    );
    return res.data.result.voucher;
  },
  async list(): Promise<Array<Voucher>> {
    const res: AxiosResponse<ApiVoucherListResponse> = await axios.get(
      apiService.voucher.list(),
    );
    return res.data.result.vouchers;
  },
  async create(payload: ApiVoucherCreatePayload): Promise<Voucher> {
    const res: AxiosResponse<ApiVoucherCreateResponse> = await axios.post(
      apiService.voucher.create(),
      payload,
    );
    return res.data.result.voucher;
  },
  async update(id: number, payload: ApiVoucherUpdatePayload): Promise<Voucher> {
    const res: AxiosResponse<ApiVoucherUpdateResponse> = await axios.patch(
      apiService.voucher.update(id),
      payload,
    );
    return res.data.result.voucher;
  },
  async delete(id: number): Promise<string> {
    const res: AxiosResponse<ApiVoucherDeleteResponse> = await axios.delete(
      apiService.voucher.delete(id),
    );
    return res.data.result;
  },
};
