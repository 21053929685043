import { DateTime } from 'luxon';
import { ReservationStatus, ReservationHalfDay } from './reservation';
import { SpotType } from './spot';

export enum PlannerMode {
  VIEW = 'view',
  NEW = 'new',
  MOVE = 'move',
  EDIT = 'edit',
  CUT = 'cut',
}

export interface PlannerElementSegment {
  startDate: number;
  endDate: number;
  absence: boolean;
}

// export interface GridBarStyle {
//   top: string;
//   left: string;
//   width: string;
//   height: string;
//   backgroundColor: string;
// }

// export interface GridUnpaidStyle {
//   top: string;
//   right: string;
//   width: string;
//   height: string;
//   backgroundColor: string;
// }

// export interface PlannerElementBar {
//   classes: Array<string>;
//   style: GridBarStyle;
//   unpaidStyle: GridUnpaidStyle;
// }
export interface PlannerElementReservation {
  id: number;
  masterId: number | null;
  spotType: SpotType;
  spotName: string;
  status: ReservationStatus;
  seasonal: boolean;
  halfDay: ReservationHalfDay;
  startDate: number;
  endDate: number;
  customerFirstName: string;
  customerLastName: string;
  paid: boolean; // paid
  segments: Array<PlannerElementSegment>;
  voucherId: number;
}

export interface PlannerElement {
  type: SpotType; // spot type
  name: string; // spot name
  sector: number; // spot sector
  hasFootboardBefore: boolean;
  isFirstOfRow: boolean;
  isLastOfRow: boolean;
  reservations: Array<PlannerElementReservation>; // reservations per spot
  // Rendering props
  classes: Array<string>;
}

/* Serialized response from server */

export interface PlannerElementRawSegment {
  s: number;
  e: number;
  a: boolean;
}

export interface PlannerElementRawReservation {
  id: number;
  mId: number | null;
  st: SpotType;
  sn: string;
  s: ReservationStatus;
  se: boolean;
  h: ReservationHalfDay;
  sd: number;
  ed: number;
  fn: string;
  ln: string;
  p: boolean; // paid
  seg: Array<PlannerElementRawSegment>;
  rv: number; // reservation voucher
}

export interface PlannerElementRaw {
  t: SpotType; // type
  n: string; // name
  s: number; // sector
  hfb: boolean;
  if: boolean;
  il: boolean;
  r: Array<PlannerElementRawReservation>; // reservations per spot
}

export interface PlannerData {
  spots: Array<PlannerElement>;
}

export enum EditDirection {
  RIGHT = 'right',
  LEFT = 'left',
}

export interface PlannerBuilder {
  edited: boolean;
  spotCategory: SpotType | null;
  spotKeys: Set<string>;
  busyCells: Set<string>;
  originals: Map<number, PlannerElementReservation>;
  movingId: number | null;
  reservationId: number | null;
  reservations: Array<PlannerElementReservation>;
  editDirection: EditDirection | null;
}

export type HeaderDate = {
  dateTime: DateTime;
  classes: Array<string>;
  style: { [key: string]: string };
  label: string;
  month: string;
};
