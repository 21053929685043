
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
import spotService from '@/services/spotService';
import { Spot, SpotType } from '@/models/spot';
import { ApiSpotFindPayload, ApiSpotFindResponse } from '@/models/api';
import { StyleSpotAttributeIcons } from '@/models/style';
import PrintButton from '@/components/shared/PrintButton.vue';
import { LicenseUmbrellaMultipleBookings } from '@/models/license';

export default defineComponent({
  name: 'CabinsDialog',
  components: { PrintButton },
  props: {
    startDate: {
      type: DateTime,
      required: false,
      default: DateTime.utc().startOf('day'),
    },
    endDate: {
      type: DateTime,
      required: false,
      default: DateTime.utc().startOf('day'),
    },
    disabled: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      search: '',
      ready: false,
      show: true,
      cabinsList: [] as Array<Spot>,
    };
  },
  async beforeMount() {
    /**
     * Load cabins and set the component ready
     */
    await this.loadCabins();
    this.ready = true;
  },
  methods: {
    async loadCabins(): Promise<void> {
      /**
       * Load cabins
       */
      try {
        const res: ApiSpotFindResponse = await spotService.find({
          type: SpotType.CABIN,
          startDate: this.startDate.toSeconds(),
          endDate: this.endDate.toSeconds(),
        } as ApiSpotFindPayload);
        // manual sorting (natural sort)
        this.cabinsList = res.result.spots.sort((a, b) =>
          a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: 'base',
          }),
        );
      } catch (e) {
        this.cabinsList = [];
      }
    },
    icon(cabin: Spot): string {
      /**
       * Cabin icon style
       */
      const status = cabin.reservations.length > 0 ? 'busy' : 'free';
      return (this.spot[status].icons as StyleSpotAttributeIcons).cabins;
    },
    onSelect(cabin: Spot): void {
      /**
       * Select cabin, prevent on busy spot if overbooking block enabled
       */
      // console.log(cabin, this.license.umbrellaMultipleBookings);
      if (
        cabin.reservations.length > 0 &&
        this.license.umbrellaMultipleBookings ===
          LicenseUmbrellaMultipleBookings.BLOCKED
      ) {
        this.$spiagge.toast.error(this.$t('cabinsDialog.toast.error'));
        return;
      }
      this.$emit('select', cabin);
    },
  },
  computed: {
    ...mapState('session', ['license']),
    ...mapState('style', ['spot']),
    filteredCabins(): Array<Spot> {
      /**
       * Return cabins filtered by search
       */
      if (this.search) {
        return this.cabinsList.filter(
          (cabin: Spot) =>
            cabin.name
              .toString()
              .toLowerCase()
              .search(this.search.toLowerCase()) > -1,
        );
      }
      return this.cabinsList;
    },
  },
});
