/**
 * Router
 */

import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';
import axios from 'axios';
import { createApp } from 'vue';
import ToastService from 'primevue/toastservice';
import LicenseSelectView from '@/views/LicenseSelectView.vue';
import MapView from '@/views/MapView.vue';
import LogsView from '@/views/LogsView.vue';
import BookingView from '@/views/BookingView.vue';
import PlannerView from '@/views/PlannerView.vue';
import SettingsView from '@/views/SettingsView.vue';
import ReservationView from '@/views/ReservationView.vue';
import CustomersView from '@/views/CustomersView.vue';
import CardsView from '@/views/contacts/CardsView.vue';
import ExportView from '@/views/contacts/ExportView.vue';
import ImportView from '@/views/contacts/ImportView.vue';
import ContactsView from '@/views/contacts/ContactsView.vue';
import ContactView from '@/views/contacts/ContactView.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import SettingsGeneralView from '@/views/settings/SettingsGeneralView.vue';
import StripeConnectionReturnView from '@/views/settings/StripeConnectionReturnView.vue';
import StripePosConnectionReturnView from '@/views/settings/StripePosConnectionReturnView.vue';
import BeachMapConfigView from '@/views/settings/BeachMapConfigView.vue';
import PriceListsConfigView from '@/views/settings/PriceListsConfigView.vue';
import SettingsExtraServicesView from '@/views/settings/SettingsExtraServicesView.vue';
import SettingsServiceGroupsView from '@/views/settings/SettingsServiceGroupsView.vue';
import BookingConfigView from '@/views/settings/BookingConfigView.vue';
import PrinterSettingsView from '@/views/settings/PrinterSettingsView.vue';
import CashDeskView from '@/views/CashDeskView.vue';
import AdminsView from '@/views/settings/SettingsAdminsView.vue';
import RoleView from '@/views/settings/SettingsRolesView.vue';
import CardTypesView from '@/views/settings/CardTypesView.vue';
import SettingsMapEditorView from '@/views/settings/SettingsMapEditorView.vue';
import DataBaseView from '@/views/settings/DataBaseView.vue';
import ContractConfigView from '@/views/settings/ContractConfigView.vue';
import RecycleBinView from '@/views/RecycleBinView.vue';
import LogsBinView from '@/views/LogsBinView.vue';
import VersionView from '@/views/settings/VersionView.vue';
import PriceListView from '@/views/PriceListView.vue';
import store from '@/store';
import cookieUtil from '@/utils/cookieUtil';
import StatisticsView from '@/views/StatisticsView.vue';
import StatisticsDashboardView from '@/views/statistics/dashboard/StatisticsDashboardView.vue';
import StatisticsArrivalsTodayView from '@/views/statistics/arrivals-departures/StatisticsArrivalsTodayView.vue';
import StatisticsDeparturesTodayView from '@/views/statistics/arrivals-departures/StatisticsDeparturesTodayView.vue';
import StatisticsArrivalsTomorrowView from '@/views/statistics/arrivals-departures/StatisticsArrivalsTomorrowView.vue';
import StatisticsDeparturesTomorrowView from '@/views/statistics/arrivals-departures/StatisticsDeparturesTomorrowView.vue';
import StatisticsAllReservationsView from '@/views/statistics/arrivals-departures/StatisticsAllReservationsView.vue';
import StatisticsStripePaymentsView from '@/views/statistics/establishment/StatisticsStripePaymentsView.vue';
import StatisticsStripeTransfersView from '@/views/statistics/establishment/StatisticsStripeTransfersView.vue';
import StatisticsLogDiscountsView from '@/views/statistics/logs-flows/StatisticsLogDiscountsView.vue';
import StatisticsLogCancellationsView from '@/views/statistics/logs-flows/StatisticsLogCancellationsView.vue';
import StatisticsCashFlowsView from '@/views/statistics/logs-flows/StatisticsCashFlowsView.vue';
import StatisticsCountersHotelView from '@/views/statistics/counters/StatisticsCountersHotelView.vue';
import StatisticsCountersVoucherView from '@/views/statistics/counters/StatisticsCountersVoucherView.vue';
import StatisticsCountersHotelDetailView from '@/views/statistics/counters/StatisticsCountersHotelDetailView.vue';
import StatisticsCountersVoucherDetailView from '@/views/statistics/counters/StatisticsCountersVoucherDetailView.vue';
import StatisticsSeasonalSummaryView from '@/views/statistics/summary/StatisticsSeasonalSummaryView.vue';
import StatisticsUnpaidReservationsSummaryView from '@/views/statistics/summary/StatisticsUnpaidReservationsSummaryView.vue';
import StatisticsAdditionsSummaryView from '@/views/statistics/summary/StatisticsAdditionsSummaryView.vue';
import StatisticsOnlineReservationsView from '@/views/statistics/summary/StatisticsOnlineReservationsView.vue';
import StatisticsExtraServicesView from '@/views/statistics/establishment/StatisticsExtraServicesView.vue';
import StatisticsExtraServiceView from '@/views/statistics/establishment/StatisticsExtraServiceView.vue';
import StatisticsDurationView from '@/views/statistics/establishment/StatisticsDurationView.vue';
import StatisticsSpotView from '@/views/statistics/establishment/spot/StatisticsSpotView.vue';
import StatisticsSpotMapView from '@/views/statistics/establishment/spot/StatisticsSpotMapView.vue';
import SettingsWarehouseView from '@/views/settings/SettingsWarehouseView.vue';
import PriceListsView from '@/views/PriceListsView.vue';
import PriceListsCreateView from '@/views/price-lists/PriceListsCreateView.vue';
import PriceListsWizardView from '@/views/price-lists/PriceListsWizardView.vue';
import PriceListsVoucherDetailView from '@/views/price-lists/PriceListsVoucherDetailView.vue';
import PriceListsPriceListDetailView from '@/views/price-lists/PriceListsPriceListDetailView.vue';
import MapPrintView from '@/views/MapPrintView.vue';
import HelpView from '@/views/HelpView.vue';
import RevenueManagementView from '@/views/RevenueManagementView.vue';
import RevenueManagementPlannerView from '@/views/revenue-management/RevenueManagementPlannerView.vue';
import RevenueManagementAnalystView from '@/views/revenue-management/RevenueManagementAnalystView.vue';
import PriceListsQualityCheckView from '@/views/price-lists/PriceListsQualityCheckView.vue';
import SettingsPosTerminalsView from '@/views/settings/SettingsPosTerminalsView.vue';
import SettingsWebticTerminalsView from '@/views/settings/SettingsWebticTerminalsView.vue';
import SettingsSectorsView from '@/views/settings/SettingsSectorsView.vue';
import { License } from '@/models/license';
import { MapView as MapViewE } from '@/models/map';
import { SpotType } from '@/models/spot';
import globalUtil from '@/utils';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  DefaultRoles,
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';
import toastUtil from '@/utils/toastUtil';
import { ToastSeverity } from '@/models/toast';
import App from '@/App.vue';
import i18n from '@/i18n';
import { ServiceType } from '@/models/service';
import StatisticsAllTicketsView from '@/views/statistics/arrivals-departures/StatisticsAllTicketsView.vue';
import StatisticsStripePaymentsV2View from '@/views/statistics/establishment/StatisticsStripePaymentsV2View.vue';
import StatisticsSectorSpotsView from '@/views/statistics/establishment/StatisticsSectorSpotsView.vue';
import DemoTtgView from '@/views/DemoTtgView.vue';

function triggerPermissionError() {
  const fakeApp = createApp(App);
  fakeApp.use(ToastService);

  fakeApp.config.globalProperties.$toast.add(
    toastUtil.build(
      ToastSeverity.WARN,
      i18n.global.t('permissions.accessDenied.title'),
      i18n.global.t('permissions.accessDenied.text'),
    ),
  );
}

function checkPermission(
  feature: string,
  next: NavigationGuardNext,
  action?: string,
): void {
  if (permissionsUtil.checkAllowedShowFeaturePage(feature, action)) {
    next();
    return;
  }

  triggerPermissionError();
  next({ path: '/license-select' });
}

function checkStripePaymentStatisticsV2Permission(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const license: License = store.getters['session/license'];
  if (
    permissionsUtil.checkAllowedShowFeaturePage(FEATURE_PERMISSION_CONFIG.stats)
  ) {
    if (
      license &&
      license.featureFlags &&
      license.featureFlags.stripePaymentsPageV2 &&
      to.fullPath === '/statistics/stripe-payments-new'
    ) {
      next();
    } else if (
      license &&
      license.featureFlags &&
      !license.featureFlags.stripePaymentsPageV2 &&
      to.fullPath === '/statistics/stripe-payments'
    ) {
      next();
    } else if (
      license &&
      license.featureFlags &&
      license.featureFlags.stripePaymentsPageV2 &&
      to.fullPath === '/statistics/stripe-payments'
    ) {
      next('/statistics/stripe-payments-new');
    } else if (
      license &&
      license.featureFlags &&
      !license.featureFlags.stripePaymentsPageV2 &&
      to.fullPath === '/statistics/stripe-payments-new'
    ) {
      next('/statistics/stripe-payments');
    }
  } else {
    triggerPermissionError();
    next({ path: '/license-select' });
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    name: 'home',
    path: '/',
    redirect: 'map',
  },
  {
    path: '/map',
    name: 'Map',
    meta: {
      title: i18n.global.t('app.metaTitle.map'),
    },
    component: MapView,
    beforeEnter: (to, from, next) => {
      checkPermission(FEATURE_PERMISSION_CONFIG.map, next);
    },
  },
  {
    path: '/map-print',
    name: 'MapPrint',
    meta: {
      title: i18n.global.t('app.metaTitle.mapPrint'),
    },
    component: MapPrintView,
  },
  {
    path: '/planner',
    name: 'Planner',
    meta: {
      title: i18n.global.t('app.metaTitle.planner'),
    },
    component: PlannerView,
    beforeEnter: (to, from, next) => {
      checkPermission(FEATURE_PERMISSION_CONFIG.planning, next);
    },
  },
  {
    path: '/license-select',
    name: 'LicenceSelect',
    meta: {
      title: i18n.global.t('app.metaTitle.licenseSelect'),
    },
    component: LicenseSelectView,
  },
  {
    path: '/help',
    name: 'Guida',
    meta: {
      title: i18n.global.t('app.metaTitle.help'),
    },
    component: HelpView,
  },
  {
    path: '/logs/:id',
    name: 'Logs',
    meta: {
      title: i18n.global.t('app.metaTitle.logs'),
    },
    component: LogsView,
  },
  {
    path: '/logs-bin/:id',
    name: 'LogsBin',
    meta: {
      title: i18n.global.t('app.metaTitle.logsBin'),
    },
    component: LogsBinView,
  },
  {
    path: '/recyclebin',
    name: 'RecycleBin',
    meta: {
      title: i18n.global.t('app.metaTitle.recycleBin'),
    },
    component: RecycleBinView,
  },
  {
    path: '/cash-desk',
    name: 'CashDesk',
    meta: {
      title: i18n.global.t('app.metaTitle.cashDesk'),
    },
    component: CashDeskView,
    beforeEnter: (to, from, next) => {
      checkPermission(FEATURE_PERMISSION_CONFIG.cash_point, next);
    },
  },
  {
    path: '/customers',
    name: 'Customers',
    meta: {
      title: i18n.global.t('app.metaTitle.customers'),
    },
    component: CustomersView,
    beforeEnter: (to, from, next) => {
      checkPermission(FEATURE_PERMISSION_CONFIG.customers, next);
    },
    children: [
      {
        path: '/customers/contacts',
        name: 'Contacts',
        meta: {
          title: i18n.global.t('app.metaTitle.customersContacts'),
        },
        component: ContactsView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.customers, next);
        },
      },
      {
        path: '/customers/contacts/:contactId',
        name: 'ContactReservations',
        meta: {
          title: i18n.global.t('app.metaTitle.customersContact'),
        },
        component: ContactView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.customers, next);
        },
      },
      {
        path: '/customers/cards/:idWorldCard?',
        name: 'Cards',
        meta: {
          title: i18n.global.t('app.metaTitle.customersCards'),
        },
        component: CardsView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.customers, next);
        },
      },
      {
        path: '/customers/export',
        name: 'Export',
        meta: {
          title: i18n.global.t('app.metaTitle.customersExport'),
        },
        component: ExportView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.customers, next);
        },
      },
      {
        path: '/customers/import',
        name: 'Import',
        meta: {
          title: i18n.global.t('app.metaTitle.customersImport'),
        },
        component: ImportView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.customers, next);
        },
      },
    ],
  },
  {
    path: '/booking',
    name: 'Booking',
    meta: {
      title: i18n.global.t('app.metaTitle.booking'),
    },
    component: BookingView,
    beforeEnter: (to, from, next) => {
      checkPermission(FEATURE_PERMISSION_CONFIG.booking, next);
    },
  },
  {
    path: '/price-list',
    name: 'PriceListView',
    meta: {
      title: i18n.global.t('app.metaTitle.priceList'),
    },
    component: PriceListView,
    beforeEnter: (to, from, next) => {
      checkPermission(FEATURE_PERMISSION_CONFIG.price_lists, next);
    },
  },
  {
    path: '/price-lists',
    name: 'PriceListsView',
    meta: {
      title: i18n.global.t('app.metaTitle.priceLists'),
    },
    component: PriceListsView,
    beforeEnter: (to, from, next) => {
      checkPermission(FEATURE_PERMISSION_CONFIG.price_lists, next);
    },
    children: [
      {
        path: '',
        name: 'PriceListsCreateView',
        meta: {
          title: i18n.global.t('app.metaTitle.priceListsCreate'),
        },
        component: PriceListsCreateView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.price_lists, next);
        },
      },
      {
        path: 'wizard/:id?',
        name: 'PriceListsWizardView',
        meta: {
          title: i18n.global.t('app.metaTitle.priceListsWizard'),
        },
        component: PriceListsWizardView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.price_lists, next);
        },
      },
      {
        path: 'voucher/:id?',
        name: 'PriceListsVoucherDetailView',
        meta: {
          title: i18n.global.t('app.metaTitle.priceListsVoucherDetail'),
        },
        component: PriceListsVoucherDetailView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.price_lists, next);
        },
      },
      {
        path: ':id',
        name: 'PriceListsPriceListDetailView',
        meta: {
          title: i18n.global.t('app.metaTitle.priceListsDetail'),
        },
        component: PriceListsPriceListDetailView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.price_lists, next);
        },
      },
      {
        path: 'quality-check/:id',
        name: 'PriceListsQualityCheckView',
        meta: {
          title: i18n.global.t('app.metaTitle.priceListsQualityCheck'),
        },
        component: PriceListsQualityCheckView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.price_lists, next);
        },
      },
    ],
  },
  {
    path: '/settings',
    name: 'Settings',
    meta: {
      title: i18n.global.t('app.metaTitle.settings'),
    },
    component: SettingsView,
    beforeEnter: (to, from, next) => {
      checkPermission(FEATURE_PERMISSION_CONFIG.settings, next);
    },
    children: [
      {
        path: 'general',
        name: 'SettingsGeneral',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsGeneral'),
        },
        component: SettingsGeneralView,
      },
      {
        path: 'beach',
        name: 'BeachMapConfigView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsBeachMap'),
        },
        component: BeachMapConfigView,
      },
      {
        path: 'sectors',
        name: 'SettingsSectorsView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsSectors'),
        },
        component: SettingsSectorsView,
      },
      {
        path: 'price-list',
        name: 'PriceListsConfigView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsPriceLists'),
        },
        component: PriceListsConfigView,
      },
      {
        path: 'tickets/:id',
        name: 'SettingsTicketView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsTickets'),
        },
        component: SettingsExtraServicesView,
        props: {
          serviceType: ServiceType.DAILY_TICKET,
        },
      },
      {
        path: 'extra-service',
        name: 'SettingsExtraServicesView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsExtraServices'),
        },
        component: SettingsExtraServicesView,
        props: {
          serviceType: ServiceType.BEACH,
        },
      },
      {
        path: 'service-groups',
        name: 'SettingsServiceGroupView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsServiceGroups'),
        },
        component: SettingsServiceGroupsView,
      },
      {
        path: 'booking',
        name: 'BookingConfigView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsBooking'),
        },
        component: BookingConfigView,
      },
      {
        path: 'printers',
        name: 'PrinterSettingsView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsPrinters'),
        },
        component: PrinterSettingsView,
      },
      {
        path: 'pos-terminals',
        name: 'SettingsPosTerminalsView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsPosTerminals'),
        },
        component: SettingsPosTerminalsView,
      },
      {
        path: 'webtic-terminals',
        name: 'SettingsWebticTerminalsView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsWebticTerminals'),
        },
        component: SettingsWebticTerminalsView,
      },
      {
        path: 'admins',
        name: 'AdminsView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsAdmins'),
        },
        component: AdminsView,
      },
      {
        path: 'roles',
        name: 'RolesView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsRoles'),
        },
        component: RoleView,
      },
      {
        path: 'card-types',
        name: 'CardTypesView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsCardTypes'),
        },
        component: CardTypesView,
      },
      {
        path: 'map-editor',
        name: 'SettingsMapEditorView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsMapEditor'),
        },
        component: SettingsMapEditorView,
      },
      {
        path: 'database',
        name: 'DataBaseView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsDatabase'),
        },
        component: DataBaseView,
      },
      {
        path: 'version',
        name: 'VersionView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsVersion'),
        },
        component: VersionView,
      },
      {
        path: 'contract',
        name: 'ContractConfigView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsContract'),
        },
        component: ContractConfigView,
      },
      {
        path: 'warehouse',
        name: 'SettingsWarehouseView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsWarehouse'),
        },
        component: SettingsWarehouseView,
      },
      {
        path: 'stripe/:type',
        name: 'StripeConnectionReturnView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsStripeConnection'),
        },
        component: StripeConnectionReturnView,
      },
      {
        path: 'stripe-pos/:type',
        name: 'StripePosConnectionReturnView',
        meta: {
          title: i18n.global.t('app.metaTitle.settingsStripePos'),
        },
        component: StripePosConnectionReturnView,
      },
    ],
  },
  {
    path: '/reservation/:id',
    name: 'ReservationEdit',
    meta: {
      title: i18n.global.t('app.metaTitle.reservation'),
    },
    component: ReservationView,
    beforeEnter: (to, from, next) => {
      checkPermission(FEATURE_PERMISSION_CONFIG.reservations, next);
    },
  },
  {
    path: '/revenue-management',
    name: 'RevenueManagementView',
    meta: {
      title: i18n.global.t('app.metaTitle.revenueManagement'),
    },
    component: RevenueManagementView,
    redirect() {
      if (
        permissionsUtil.checkAllowedShowFeaturePage(
          FEATURE_PERMISSION_CONFIG.revenue_manager,
        )
      ) {
        if (
          permissionsUtil.isActionPermissionAllowed(
            FEATURE_PERMISSION_CONFIG.revenue_manager,
            FEATURE_PERMISSION_ACTION_CONFIG.revenue_manager.ANALYST_PANEL,
          )
        ) {
          return {
            name: 'RevenueManagementAnalystView',
          };
        }

        const license: License = store.getters['session/license'];

        if (
          license?.featureFlags?.revenueManagementEnabled &&
          permissionsUtil.checkAllowedShowFeaturePage(
            FEATURE_PERMISSION_CONFIG.revenue_manager,
          )
        ) {
          return {
            name: 'RevenueManagementPlannerView',
          };
        }
      }

      triggerPermissionError();
      return { path: '/license-select' };
    },
    children: [
      {
        path: 'planner',
        name: 'RevenueManagementPlannerView',
        meta: {
          title: i18n.global.t('app.metaTitle.revenueManagementPlanner'),
        },
        beforeEnter: (to, from, next) => {
          checkPermission(
            FEATURE_PERMISSION_CONFIG.revenue_manager,
            next,
            FEATURE_PERMISSION_ACTION_CONFIG.revenue_manager.MANAGER_PANEL,
          );
        },
        component: RevenueManagementPlannerView,
      },
      {
        path: 'analyst',
        name: 'RevenueManagementAnalystView',
        meta: {
          title: i18n.global.t('app.metaTitle.revenueManagementAnalyst'),
        },
        beforeEnter: (to, from, next) => {
          checkPermission(
            FEATURE_PERMISSION_CONFIG.revenue_manager,
            next,
            FEATURE_PERMISSION_ACTION_CONFIG.revenue_manager.ANALYST_PANEL,
          );
        },
        component: RevenueManagementAnalystView,
      },
    ],
  },
  {
    path: '/statistics',
    name: 'StatisticsView',
    meta: {
      title: i18n.global.t('app.metaTitle.statistics'),
    },
    component: StatisticsView,
    redirect: { name: 'StatisticsDashboardView' },
    children: [
      {
        path: 'dashboard',
        name: 'StatisticsDashboardView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsDashboard'),
        },
        component: StatisticsDashboardView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'arrivals-today',
        name: 'StatisticsArrivalsTodayView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsArrivalsToday'),
        },
        component: StatisticsArrivalsTodayView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'departures-today',
        name: 'StatisticsDeparturesTodayView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsDeparturesToday'),
        },
        component: StatisticsDeparturesTodayView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'arrivals-tomorrow',
        name: 'StatisticsArrivalsTomorrowView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsArrivalsTomorrow'),
        },
        component: StatisticsArrivalsTomorrowView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'departures-tomorrow',
        name: 'StatisticsDeparturesTomorrowView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsDeparturesTomorrow'),
        },
        component: StatisticsDeparturesTomorrowView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'all-reservations',
        name: 'StatisticsAllReservationsView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsAllReservations'),
        },
        component: StatisticsAllReservationsView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'tickets',
        name: 'StatisticsAllTicketsView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsTicketSummary'),
        },
        component: StatisticsAllTicketsView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        // this page is deprecated, redirect to the new page
        path: 'sectors',
        redirect: {
          name: 'StatisticsSectorSpotsView',
        },
      },
      {
        path: 'sector-spots',
        name: 'StatisticsSectorSpotsView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsSectorSpots'),
        },
        component: StatisticsSectorSpotsView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'stripe-payments',
        name: 'StatisticsStripePaymentsView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsStripePayments'),
        },
        component: StatisticsStripePaymentsView,
        beforeEnter: (to, from, next) => {
          checkStripePaymentStatisticsV2Permission(to, from, next);
        },
      },
      {
        path: 'stripe-payments-new',
        name: 'StatisticsStripePaymentsV2View',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsStripePayments'),
        },
        component: StatisticsStripePaymentsV2View,
        beforeEnter: (to, from, next) => {
          checkStripePaymentStatisticsV2Permission(to, from, next);
        },
      },
      {
        path: 'stripe-transfers',
        name: 'StatisticsStripeTransfersView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsStripeTransfers'),
        },
        component: StatisticsStripeTransfersView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'extra-services',
        name: 'StatisticsExtraServicesView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsExtraServices'),
        },
        component: StatisticsExtraServicesView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'extra-service/:id',
        name: 'StatisticsExtraServiceView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsExtraService'),
        },
        component: StatisticsExtraServiceView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'duration',
        name: 'StatisticsDurationView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsDuration'),
        },
        component: StatisticsDurationView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'spot/:spotType/map',
        name: 'StatisticsSpotMapView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsSpotMap'),
        },
        component: StatisticsSpotMapView,
        beforeEnter: (to, from, next) => {
          if (
            ![SpotType.UMBRELLA, SpotType.CABIN].includes(
              to.params.spotType as SpotType,
            )
          ) {
            next('/statistics/spot/umbrella/map');
          }
          next();
        },
      },
      {
        path: 'spot/:spotType',
        name: 'StatisticsSpotView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsSpot'),
        },
        component: StatisticsSpotView,
        beforeEnter: (to, from, next) => {
          if (
            ![SpotType.UMBRELLA, SpotType.CABIN].includes(
              to.params.spotType as SpotType,
            )
          ) {
            next('/statistics/spot/umbrella');
          }
          next();
        },
      },
      {
        path: 'log-discounts',
        name: 'StatisticsLogDiscountsView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsLogDiscounts'),
        },
        component: StatisticsLogDiscountsView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'log-cancellations',
        name: 'StatisticsLogCancellationsView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsLogCancellations'),
        },
        component: StatisticsLogCancellationsView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'cash-flows',
        name: 'StatisticsCashFlowsView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsCashFlows'),
        },
        component: StatisticsCashFlowsView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'counters-hotel/:id',
        name: 'StatisticsCountersHotelDetailView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsCountersHotelDetail'),
        },
        component: StatisticsCountersHotelDetailView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'counters-hotel',
        name: 'StatisticsCountersHotelView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsCountersHotel'),
        },
        component: StatisticsCountersHotelView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'counters-voucher/:id',
        name: 'StatisticsCountersVoucherDetailView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsCountersVoucherDetail'),
        },
        component: StatisticsCountersVoucherDetailView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'counters-voucher',
        name: 'StatisticsCountersVoucherView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsCountersVoucher'),
        },
        component: StatisticsCountersVoucherView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'seasonal-summary',
        name: 'StatisticsSeasonalSummaryView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsSeasonalSummary'),
        },
        component: StatisticsSeasonalSummaryView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'unpaid-reservations-summary',
        name: 'StatisticsUnpaidReservationsSummaryView',
        meta: {
          title: i18n.global.t(
            'app.metaTitle.statisticsUnpaidReservationsSummary',
          ),
        },
        component: StatisticsUnpaidReservationsSummaryView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'additions-summary',
        name: 'StatisticsAdditionsSummaryView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsAdditionsSummary'),
        },
        component: StatisticsAdditionsSummaryView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
      {
        path: 'online-reservations',
        name: 'StatisticsOnlineReservationsView',
        meta: {
          title: i18n.global.t('app.metaTitle.statisticsOnlineReservations'),
        },
        component: StatisticsOnlineReservationsView,
        beforeEnter: (to, from, next) => {
          checkPermission(FEATURE_PERMISSION_CONFIG.stats, next);
        },
      },
    ],
  },
  {
    name: 'demo-ttg',
    path: '/demo-ttg',
    component: DemoTtgView,
    beforeEnter(to, from, next) {
      // if user is not a superuser, redirect to homepage
      if (store.state.session.roleUser !== DefaultRoles.SUPERUSER) {
        next({ path: '/ ' });
      } else {
        next();
      }
    },
  },
  { path: '/not-found', name: 'NotFound', component: NotFoundView },
  {
    path: '/:catchAll(.*)',
    redirect: '/not-found',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  routes,
});

/**
 * Prevent setup on map page.
 * @param to
 */
async function setup(to: RouteLocationNormalized): Promise<void> {
  if (to.name !== 'Map' && !store.getters['session/setup']) {
    await store.dispatch('session/setup');
  }
}

function loadHotjarUserScript(
  userId: number,
  permission: number,
  licenseId: string,
) {
  if (store.getters['session/roleUser'] === 'manager') {
    globalUtil.loadScript(
      'hj-user',
      '',
      'window.hj=window.hj||function(){(hj.q=hj.q||[]).push(arguments)};' +
        `window.hj('identify', ${userId}, {` +
        `license: '${licenseId}',` +
        `permission: ${permission}` +
        '});',
    );
  }
}

router.beforeEach(async (to, from, next) => {
  // reset focus
  store.commit('app/setFocus', '');
  // set current route
  store.commit('app/setRoute', to);
  // reset breadcrumbs
  store.commit('app/setBreadcrumbs', []);
  // Check app platform
  const appPlatform = to.query.anm22_world_app_os;
  if (appPlatform) {
    store.commit('session/setAppPlatform', appPlatform);
  }
  // Check app platform build
  const appPlatformBuild = to.query.anm22_world_app_build;
  if (appPlatformBuild) {
    store.commit('session/setAppPlatformBuild', Number(appPlatformBuild));
  }
  // Check if user is logged in
  let user = store.getters['session/user'];
  if (!user) {
    // Try to sign in
    await store.dispatch('session/signIn');
    user = store.getters['session/user'];
    // Redirect to login if not signed in
    if (user === null) {
      window.location.href = store.getters['session/links'].login;
      return;
    }
  }
  // LOGGED IN
  if (to.fullPath === '/license-select') next();
  const storeLicense = store.getters['session/license'];
  if (storeLicense) {
    await setup(to);
    next(); // already have a license setted, continue
  } else {
    let licenseCode = cookieUtil.get(process.env.VUE_APP_LICENSE_COOKIE);
    const w = to.query.w;
    // if W param use it instead cookie license
    if (w) {
      licenseCode = w.toString();
    }
    if (licenseCode) {
      // license code present, try to retrieve the license data
      try {
        await store.dispatch('session/setLicense', licenseCode);
        if (w) {
          // license passed by query params is valid, update cookie license
          cookieUtil.set(process.env.VUE_APP_LICENSE_COOKIE, licenseCode);
        }
      } catch (e) {
        if (axios.isAxiosError(e)) {
          const stringStatus = e.response?.status.toString().substring(0, 1);
          if (stringStatus === '4') {
            // 4** error, remove license/login cookie and redirect to login
            cookieUtil.remove(process.env.VUE_APP_LICENSE_COOKIE);
            cookieUtil.remove('anm22-w-l');
            window.location.href = store.getters['session/links'].login;
            return;
          }
        }
      }
      // check after set license, if null redirect to select license
      if (store.getters['session/license'] === null) {
        next('/license-select');
      } else {
        // license ok, setup the application
        await setup(to);
        const license: License = store.getters['session/license'];
        // setup next payload
        let path = to.path;
        const query = to.query;
        // Remove W parameter if present
        if (w) {
          delete query.w;
        }
        // Remove anm22_world_app_os if present
        if (appPlatform) {
          delete query.anm22_world_app_os;
        }
        // Remove anm22_world_app_build if present
        if (appPlatformBuild) {
          delete query.anm22_world_app_build;
        }
        if (path === '/map' && license.beachDefaultView === MapViewE.PLANNER) {
          path = '/planner';
        }
        loadHotjarUserScript(
          user.id,
          store.getters['session/permissions'],
          license.license,
        );
        // call next
        next({
          path,
          query,
        });
      }
    } else {
      // no license code detected, select license
      next('/license-select');
    }
  }
});

router.afterEach((to) => {
  document.title = (to.meta.title ??
    i18n.global.t('app.metaTitle.default')) as string;
});

export default router;
