
import { defineComponent } from 'vue';
import clone from 'lodash/clone';
import { MapView } from '@/models/map';

interface MapViewItem {
  id: MapView;
  label: string;
  icon: string;
}

export default defineComponent({
  name: 'MapViews',
  data() {
    return {
      mapView: clone(MapView),
      views: [
        {
          id: MapView.FULL,
          label: this.$t('mapViews.full'),
          icon: 'spi-frame',
        },
        {
          id: MapView.NUMBERS,
          label: this.$t('mapViews.number'),
          icon: 'spi-hash',
        },
        {
          id: MapView.DATES,
          label: this.$t('mapViews.expiration'),
          icon: 'spi-calendar',
        },
        {
          id: MapView.STAGING,
          label: this.$t('mapViews.staging'),
          icon: 'spi-bed',
        },
      ] as Array<MapViewItem>,
    };
  },
  computed: {
    view: {
      get(): MapView {
        return this.$store.getters['map/view'];
      },
      set(view: MapView): void {
        this.$store.commit('map/setView', view);
      },
    },
  },
});
