// eslint-disable-next-line import/prefer-default-export
export enum AreaCode {
    ITALY = '+39',
    SAN_MARINO = '+378',
    UNITED_STATES = '+1',
    CANADA = '+1',
    UNITED_STATES_VIRGIN_ISLANDS = '+1 340',
    NORTHERN_MARIANA_ISLANDS = '+1 670',
    GUAM = '+1 671',
    AMERICAN_SAMOA = '+1 684',
    PUERTO_RICO = '+1 787',
    PUERTO_RICO2 = '+1 939',
    BAHAMAS = '+1 242',
    BARBADOS = '+1 246',
    ANGUILLA = '+1 264',
    ANTIGUA = '+1 268',
    BARBUDA = '+1 268',
    BRITISH_VIRGIN_ISLANDS = '+1 284',
    CAYMAN_ISLANDS = '+1 345',
    BERMUDA = '+1 441',
    GRENADA = '+1 473',
    TURKS = '+1 649',
    CAICOS_ISLANDS = '+1 649',
    MONTSERRAT = '+1 664',
    SINT_MAARTEN = '+1 721',
    SAINT_LUCIA = '+1 758',
    DOMINICA = '+1 767',
    SAINT_VINCENT = '+1 784',
    THE_GRENADINES = '+1 784',
    DOMINICAN_REPUBLIC = '+1 809',
    DOMINICAN_REPUBLIC2 = '+1 829',
    DOMINICAN_REPUBLIC3 = '+1 849',
    TRINIDAD = '+1 868',
    TOBAGO = '+1 868',
    SAINT_KITTS = '+1 869',
    NEVIS = '+1 869',
    JAMAICA = '+1 876',
    JAMAICA2 = '+1 658',
    EGYPT = '+20',
    SOUTH_SUDAN = '+211 ',
    MOROCCO = '+212',
    ALGERIA = '+213',
    TUNISIA = '+216',
    LIBYA = '+218',
    GAMBIA = '+220',
    SENEGAL = '+221',
    MAURITANIA = '+222',
    MALI = '+223',
    GUINEA = '+224',
    IVORY_COAST = '+225',
    BURKINA_FASO = '+226',
    NIGER = '+227',
    TOGO = '+228',
    BENIN = '+229',
    MAURITIUS = '+230',
    LIBERIA = '+231',
    SIERRA_LEONE = '+232',
    GHANA = '+233',
    NIGERIA = '+234',
    CHAD = '+235',
    CENTRAL_AFRICAN_REPUBLIC = '+236',
    CAMEROON = '+237',
    CAPE_VERDE = '+238',
    SAO_TOME = '+239',
    PRINCIPE = '+239',
    EQUATORIAL_GUINEA = '+240',
    GABON = '+241',
    REPUBLICOFTHE_CONGO = '+242',
    DEMOCRATIC_REPUBLICOFTHE_CONGO = '+243',
    ANGOLA = '+244',
    GUINEA_BISSAU = '+245',
    BRITISH_INDIAN_OCEAN_TERRITORY = '+246',
    ASCENSION_ISLAND = '+247',
    SEYCHELLES = '+248',
    SUDAN = '+249',
    RWANDA = '+250',
    ETHIOPIA = '+251',
    SOMALIA = '+252',
    DJIBOUTI = '+253',
    KENYA = '+254',
    TANZANIA = '+255',
    ZANZIBAR = '+255 24',
    UGANDA = '+256',
    BURUNDI = '+257',
    MOZAMBIQUE = '+258',
    ZAMBIA = '+260',
    MADAGASCAR = '+261',
    REUNION = '+262',
    ZIMBABWE = '+263',
    NAMIBIA = '+264',
    MALAWI = '+265',
    LESOTHO = '+266',
    BOTSWANA = '+267',
    ESWATINI = '+268',
    COMOROS = '+269',
    SOUTH_AFRICA = '+27',
    SAINT_HELENA = '+290',
    TRISTANDA_CUNHA = '+290 8',
    ERITREA = '+291',
    ARUBA = '+297',
    ALAND_ISLANDS = '+358 18',
    BULGARIA = '+359',
    HUNGARY = '+36',
    LITHUANIA = '+370',
    LATVIA = '+371',
    ESTONIA = '+372',
    MOLDOVA = '+373',
    ARMENIA = '+374',
    ARTSAKH = '+374 47',
    ARTSAKH2 = '+374 97',
    BELARUS = '+375',
    ANDORRA = '+376',
    MONACO = '+377',
    VATICAN_CITY = '+379',
    UKRAINE = '+380',
    SERBIA = '+381',
    MONTENEGRO = '+382',
    KOSOVO = '+383',
    CROATIA = '+385',
    SLOVENIA = '+386',
    BOSNIA = '+387',
    HERZEGOVINA = '+387',
    NORTH_MACEDONIA = '+389',
    ROMANIA = '+40',
    SWITZERLAND = '+41',
    CZECH_REPUBLIC = '+420',
    SLOVAKIA = '+421',
    LIECHTENSTEIN = '+423',
    AUSTRIA = '+43',
    UNITED_KINGDOM = '+44',
    GUERNSEY = '+44 1481',
    JERSEY = '+44 1534',
    ISLEOF_MAN = '+44 1624',
    DENMARK = '+45',
    SWEDEN = '+46',
    NORWAY = '+47',
    SVALBARD = '+47 79',
    POLAND = '+48',
    GERMANY = '+49',
    FALKLAND_ISLANDS = '+500',
    BELIZE = '+501',
    GUATEMALA = '+502',
    EL_SALVADOR = '+503',
    HONDURAS = '+504',
    NICARAGUA = '+505',
    COSTA_RICA = '+506',
    PANAMA = '+507',
    SAINT_PIERRE = '+508',
    MIQUELON = '+508',
    HAITI = '+509',
    PERU = '+51',
    MEXICO = '+52',
    CUBA = '+53',
    ARGENTINA = '+54',
    BRAZIL = '+55',
    CHILE = '+56',
    COLUMBIA = '+57',
    VENEZUELA = '+58',
    GUADELOUPE = '+590',
    SAINT_BARTHELEMY = '+590',
    SAINT_MARTIN = '+590',
    BOLIVIA = '+591',
    GUYANA = '+592',
    ECUADOR = '+593',
    FRENCH_GUIANA = '+594',
    PARAGUAY = '+595',
    MARTINIQUE = '+596',
    SURINAME = '+597',
    URUGUAY = '+598',
    NETHERLANDS_ANTILLES = '+599',
    SINT_EUSTATIUS = '+599 3',
    SABA = '+599 4',
    BONAIRE = '+599 7',
    CURACAO = '+599 9',
    MALAYSIA = '+60',
    AUSTRALIA = '+61',
    COCOS_ISLANDS = '+61 8 9162',
    CHRISTMAS_ISLAND = '+61 8 9164',
    INDONESIA = '+62',
    PHILIPPINES = '+63',
    NEW_ZEALAND = '+64',
    PITCAIRN_ISLANDS = '+64',
    SINGAPORE = '+65',
    THAILAND = '+66',
    EAST_TIMOR = '+670',
    AUSTRALIAN_ANTARCTIC_TERRITORY = '+672 1',
    NORFOLK_ISLAND = '+672 3',
    BRUNEI = '+673',
    NAURU = '+674',
    PAPUA_NEW_GUINEA = '+675',
    TONGA = '+676',
    SOLOMON_ISLANDS = '+677',
    VANUATU = '+678',
    FIJI = '+679',
    PALAU = '+680',
    WALLIS = '+681',
    FUTUNA = '+681',
    COOK_ISLANDS = '+682',
    NIUE = '+683',
    SAMOA = '+685',
    KIRIBATI = '+686',
    NEW_CALEDONIA = '+687',
    TUVALU = '+688',
    FRENCH_POLYNESIA = '+689',
    TOKELAU = '+690',
    FEDERATED_STATESOF_MICRONESIA = '+691',
    MARSHALL_ISLANDS = '+692',
    RUSSIA = '+7',
    KAZAKHSTAN = '+7 6',
    ABKHAZIA = '+7 840',
    JAPAN = '+81',
    SOUTH_KOREA = '+82',
    VIETNAM = '+84',
    NORTH_KOREA = '+850',
    HONG_KONG = '+852',
    MACAU = '+853',
    CAMBODIA = '+855',
    LAOS = '+856',
    CHINA = '+86',
    BANGLADESH = '+880',
    TAIWAN = '+886',
    TURKEY = '+90',
    NORTHERN_CYPRUS = '+90 392',
    INDIA = '+91',
    PAKISTAN = '+92',
    AZAD_KASHMIR = '+92 582',
    GILGIT_BALTISTAN = '+92 581',
    AFGHANISTAN = '+93',
    SRI_LANKA = '+94',
    MYANMAR = '+95',
    MALDIVES = '+960',
    LEBANON = '+961',
    JORDAN = '+962',
    SYRIA = '+963',
    IRAQ = '+964',
    KUWAIT = '+965',
    SAUDI_ARABIA = '+966',
    YEMEN = '+967',
    OMAN = '+968',
    PALESTINE = '+970',
    UNITED_ARAB_EMIRATES = '+971',
    ISRAEL = '+972',
    BAHRAIN = '+973',
    QATAR = '+974',
    BHUTAN = '+975',
    MONGOLIA = '+976',
    NEPAL = '+977',
    IRAN = '+98',
    TAJIKISTAN = '+992',
    TURKMENISTAN = '+993',
    AZERBAIJAN = '+994',
    GEORGIA = '+995',
    SOUTH_OSSETIA = '+995 34',
    KYRGYZSTAN = '+996',
    UZBEKISTAN = '+998',
}
