import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d339197a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "items"
}
const _hoisted_2 = { class: "inner" }
const _hoisted_3 = ["id", "href", "target"]
const _hoisted_4 = { class: "inner" }
const _hoisted_5 = ["id", "onClick"]
const _hoisted_6 = { class: "inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    id: "navigation",
    class: _normalizeClass(_ctx.$store.getters['app/navigation'] ? 'open' : 'closed')
  }, [
    (_ctx.items.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
            return _withDirectives((_openBlock(), _createElementBlock("div", {
              key: index,
              tabindex: "1"
            }, [
              (item.to)
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 0,
                    id: `${item.id}-navigation-item`,
                    class: _normalizeClass(["item", {
              disabled: item.permission !== _ctx.FeaturePermissionType.INCLUDED,
            }]),
                    to: item.to,
                    onClick: ($event: any) => (_ctx.onClick(item))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("i", {
                          class: _normalizeClass(item.icon)
                        }, null, 2),
                        _createElementVNode("span", null, _toDisplayString(item.label), 1),
                        (item.tag)
                          ? _withDirectives((_openBlock(), _createBlock(_component_Tag, {
                              key: 0,
                              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
                              severity: item.tag.severity,
                              value: item.tag.label,
                              tabindex: "1"
                            }, null, 8, ["severity", "value"])), [
                              [
                                _directive_tooltip,
                                
                  item.tag
                    ? {
                        value: item.tag.tooltip,
                        disabled:
                          item.tag.tooltip === undefined ||
                          item.tag.tooltip === '',
                      }
                    : null
                ,
                                void 0,
                                {
                                  right: true,
                                  focus: true
                                }
                              ]
                            ])
                          : _createCommentVNode("", true)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["id", "class", "to", "onClick"]))
                : (item.href)
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 1,
                      id: `${item.id}-navigation-item`,
                      class: _normalizeClass(["item", {
              disabled: item.permission !== _ctx.FeaturePermissionType.INCLUDED,
            }]),
                      href: item.href,
                      target: item.target ? item.target : ''
                    }, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("i", {
                          class: _normalizeClass(item.icon)
                        }, null, 2),
                        _createElementVNode("span", null, _toDisplayString(item.label), 1)
                      ])
                    ], 10, _hoisted_3))
                  : (item.cta)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        id: `${item.id}-navigation-item`,
                        class: _normalizeClass(["item", {
              disabled: item.permission !== _ctx.FeaturePermissionType.INCLUDED,
            }]),
                        onClick: ($event: any) => (_ctx.isCtaEnabled ? item.cta() : '')
                      }, [
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("i", {
                            class: _normalizeClass(item.icon)
                          }, null, 2),
                          _createElementVNode("span", null, _toDisplayString(item.label), 1)
                        ])
                      ], 10, _hoisted_5))
                    : _createCommentVNode("", true)
            ], 512)), [
              [
                _directive_tooltip,
                {
            value: _ctx.getTextTooltip(item),
            disabled: item.permission === _ctx.FeaturePermissionType.INCLUDED,
          },
                void 0,
                {
                  right: true,
                  focus: true
                }
              ]
            ])
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}