import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f3086bb"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "settings-pos-terminals-view",
  class: "settings-view view"
}
const _hoisted_2 = {
  key: 0,
  class: "pos-terminals"
}
const _hoisted_3 = { class: "grid" }
const _hoisted_4 = { class: "pos-terminal" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { class: "infos" }
const _hoisted_7 = { class: "info model" }
const _hoisted_8 = { class: "label" }
const _hoisted_9 = { class: "value" }
const _hoisted_10 = { class: "info identifier" }
const _hoisted_11 = { class: "label" }
const _hoisted_12 = { class: "value" }
const _hoisted_13 = { class: "actions" }
const _hoisted_14 = { class: "grid" }
const _hoisted_15 = { class: "col-12" }
const _hoisted_16 = { class: "p-field name-field" }
const _hoisted_17 = { for: "name" }
const _hoisted_18 = {
  key: 0,
  class: "error-message"
}
const _hoisted_19 = { class: "col-12 sm:col-6" }
const _hoisted_20 = { class: "p-field model-field" }
const _hoisted_21 = { for: "model" }
const _hoisted_22 = {
  key: 0,
  class: "error-message"
}
const _hoisted_23 = { class: "col-12 sm:col-6" }
const _hoisted_24 = { class: "p-field identifier-field" }
const _hoisted_25 = { for: "identifier" }
const _hoisted_26 = {
  key: 0,
  class: "error-message"
}
const _hoisted_27 = { class: "col-12" }
const _hoisted_28 = { for: "sectors" }
const _hoisted_29 = {
  key: 0,
  class: "error-message"
}
const _hoisted_30 = {
  ref: "submit",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputSwitch = _resolveComponent("InputSwitch")!
  const _component_Message = _resolveComponent("Message")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Field = _resolveComponent("Field")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Listbox = _resolveComponent("Listbox")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('settingsPosTerminalView.title')), 1),
      _createVNode(_component_Button, {
        label: _ctx.$t('button.add'),
        icon: "spi-plus",
        class: "p-button-sm",
        onClick: _ctx.onPosTerminalAdd
      }, null, 8, ["label", "onClick"])
    ]),
    (_ctx.posTerminals.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posTerminals, (posTerminal, posTerminalIndex) => {
              return (_openBlock(), _createElementBlock("div", {
                key: posTerminalIndex,
                class: "col-12 sm:col-6 lg:col-12 xl:col-6"
              }, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("header", null, [
                    _createElementVNode("h6", _hoisted_5, _toDisplayString(posTerminal.name), 1)
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('settingsPosTerminalView.model')) + ":", 1),
                      _createElementVNode("span", _hoisted_9, _toDisplayString(posTerminal.model), 1)
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t('settingsPosTerminalView.identifier')) + ":", 1),
                      _createElementVNode("span", _hoisted_12, _toDisplayString(posTerminal.posIdentifier), 1)
                    ])
                  ]),
                  _createElementVNode("footer", _hoisted_13, [
                    _createVNode(_component_Button, {
                      icon: "pi pi-trash",
                      class: "p-button-danger p-button-sm",
                      onClick: ($event: any) => (_ctx.onPosTerminalDelete(posTerminal)),
                      label: _ctx.$t('button.delete')
                    }, null, 8, ["onClick", "label"]),
                    _createVNode(_component_Button, {
                      icon: "pi pi-pencil",
                      class: "p-button-primary p-button-sm",
                      onClick: ($event: any) => (_ctx.onPosTerminalUpdate(posTerminal)),
                      label: _ctx.$t('button.edit')
                    }, null, 8, ["onClick", "label"]),
                    _createVNode(_component_InputSwitch, {
                      modelValue: !posTerminal.disabled,
                      onClick: ($event: any) => (_ctx.onPosTerminalSwitch(posTerminal))
                    }, null, 8, ["modelValue", "onClick"])
                  ])
                ])
              ]))
            }), 128))
          ])
        ]))
      : (_openBlock(), _createBlock(_component_Message, {
          key: 1,
          severity: "info",
          closable: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('settingsPosTerminalView.noPosMessage')), 1)
          ]),
          _: 1
        })),
    _createVNode(_component_Dialog, {
      id: "pos-terminal-dialog",
      class: "dialog",
      modal: true,
      visible: _ctx.showDialog,
      "onUpdate:visible": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.showDialog) = $event)),
      onHide: _cache[7] || (_cache[7] = ($event: any) => (_ctx.showDialog = false))
    }, {
      header: _withCtx(() => [
        _createElementVNode("h6", null, _toDisplayString(_ctx.posTerminalId
              ? _ctx.$t('settingsPosTerminalView.editPosTerminal')
              : _ctx.$t('settingsPosTerminalView.newPosTerminal')), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button-outlined",
          label: _ctx.$t('button.cancel'),
          icon: "spi-times",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showDialog = false))
        }, null, 8, ["label"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('button.save'),
          icon: "spi-check",
          onClick: _cache[5] || (_cache[5] = ($event: any) => ((_ctx.$refs.submit as HTMLButtonElement).click()))
        }, null, 8, ["label"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Form, {
          initialValues: _ctx.posTerminalFormModel,
          validationSchema: _ctx.validationSchema,
          onSubmit: _ctx.onPosTerminalSave
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t('settingsPosTerminalView.form.name')), 1),
                  _createVNode(_component_Field, {
                    name: "name",
                    modelValue: _ctx.posTerminalFormModel.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.posTerminalFormModel.name) = $event))
                  }, {
                    default: _withCtx(({ field, meta, errorMessage }) => [
                      _createVNode(_component_InputText, _mergeProps({ id: "name" }, field, {
                        class: !meta.valid && meta.touched ? 'p-invalid' : ''
                      }), null, 16, ["class"]),
                      errorMessage
                        ? (_openBlock(), _createElementBlock("span", _hoisted_18, _toDisplayString(errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('settingsPosTerminalView.form.model')), 1),
                  _createVNode(_component_Field, {
                    name: "model",
                    modelValue: _ctx.posTerminalFormModel.model,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.posTerminalFormModel.model) = $event))
                  }, {
                    default: _withCtx(({ handleChange, meta, errorMessage, value }) => [
                      _createVNode(_component_Dropdown, {
                        "onUpdate:modelValue": handleChange,
                        modelValue: value,
                        id: "model",
                        options: _ctx.posTerminalModelOptions,
                        optionLabel: "name",
                        optionValue: "model",
                        class: _normalizeClass(!meta.valid && meta.touched ? 'p-invalid' : '')
                      }, null, 8, ["onUpdate:modelValue", "modelValue", "options", "class"]),
                      errorMessage
                        ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t('settingsPosTerminalView.form.identifier')), 1),
                  _createVNode(_component_Field, {
                    name: "posIdentifier",
                    modelValue: _ctx.posTerminalFormModel.posIdentifier,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.posTerminalFormModel.posIdentifier) = $event))
                  }, {
                    default: _withCtx(({ field, meta, errorMessage }) => [
                      _createVNode(_component_InputText, _mergeProps({ id: "identifier" }, field, {
                        class: !meta.valid && meta.touched ? 'p-invalid' : ''
                      }), null, 16, ["class"]),
                      errorMessage
                        ? (_openBlock(), _createElementBlock("span", _hoisted_26, _toDisplayString(errorMessage), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_27, [
                _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t('settingsPosTerminalView.sectors')), 1),
                _createVNode(_component_Field, {
                  name: "sectors",
                  modelValue: _ctx.posTerminalFormModel.sectors,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.posTerminalFormModel.sectors) = $event))
                }, {
                  default: _withCtx(({ handleChange, meta, errorMessage, value }) => [
                    _createVNode(_component_Listbox, {
                      id: "sectors",
                      options: _ctx.sectorsOptions,
                      multiple: "",
                      optionLabel: "label",
                      optionValue: "value",
                      filter: true,
                      filterPlaceholder: _ctx.$t('settingsPosTerminalView.searchSector'),
                      listStyle: "height:200px",
                      "onUpdate:modelValue": handleChange,
                      modelValue: value,
                      class: _normalizeClass(!meta.valid && meta.touched ? 'p-invalid' : '')
                    }, null, 8, ["options", "filterPlaceholder", "onUpdate:modelValue", "modelValue", "class"]),
                    errorMessage
                      ? (_openBlock(), _createElementBlock("span", _hoisted_29, _toDisplayString(errorMessage), 1))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ]),
            _withDirectives(_createElementVNode("button", _hoisted_30, null, 512), [
              [_vShow, false]
            ])
          ]),
          _: 1
        }, 8, ["initialValues", "validationSchema", "onSubmit"])
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}