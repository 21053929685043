import { useI18n } from 'vue-i18n';
import {
  StripeTransferInvoice,
  StripeTransferInvoiceRow,
} from '@/models/invoice';

type UseGenerateInvoicePdf = {
  generateInvoiceDate: (invoice: StripeTransferInvoice) => HTMLDivElement;
  generateInvoiceInfo: () => HTMLDivElement;
  generateInvoiceTotal: () => HTMLDivElement;
  generateTable: (rows: StripeTransferInvoiceRow[]) => HTMLTableElement;
};

export function useGenerateInvoicePdf(): UseGenerateInvoicePdf {
  const { t } = useI18n();

  function generateTable(rows: StripeTransferInvoiceRow[]) {
    const table = document.createElement('table');
    const thead = document.createElement('thead');
    const tbody = document.createElement('tbody');

    table.style.width = '100%';

    table.appendChild(thead);
    table.appendChild(tbody);

    const headerRow = document.createElement('tr');
    thead.appendChild(headerRow);
    [
      t('statisticsStripeTransfersView.showInvoiceDialog.productCode'),
      t('statisticsStripeTransfersView.showInvoiceDialog.quantity'),
      t('statisticsStripeTransfersView.showInvoiceDialog.unitPrice'),
      t('statisticsStripeTransfersView.showInvoiceDialog.description'),
      t('statisticsStripeTransfersView.showInvoiceDialog.vatPercentage'),
    ].forEach((value) => {
      const td = document.createElement('td');
      td.style.fontWeight = '700';
      td.innerText = value;
      headerRow.appendChild(td);
    });

    rows.forEach((row) => {
      const tr = document.createElement('tr');
      tbody.appendChild(tr);

      (
        [
          'code',
          'quantity',
          'unitPrice',
          'description',
          'vatPercentage',
        ] as const
      ).forEach((code) => {
        const td = document.createElement('td');
        td.innerText = `${row[code]}`;
        tr.appendChild(td);
      });
    });

    return table;
  }

  function generateInvoiceDate(invoice: StripeTransferInvoice): HTMLDivElement {
    const div = document.createElement('div');
    div.style.marginBottom = '16px';

    div.innerHTML = `
      <h6>${t(
        'statisticsStripeTransfersView.showInvoiceDialog.invoiceDate',
      )}</h6>
      <div>
        ${invoice.invoiceDate}
      </div>
    `;

    return div;
  }

  function generateInvoiceInfo(): HTMLDivElement {
    const div = document.createElement('div');
    div.style.display = 'flex';
    div.style.marginBottom = '16px';

    const infoEl = document.getElementById('invoice-dialog-info');
    if (infoEl) {
      infoEl.childNodes.forEach((node) => {
        const clone = node.cloneNode(true);
        div.appendChild(clone);
      });
    }

    return div;
  }

  return {
    generateInvoiceDate,
    generateInvoiceInfo,
    generateInvoiceTotal() {
      const div = document.createElement('div');

      const totalEl = document.getElementById('invoice-dialog-total');
      if (totalEl) {
        const clone = totalEl.cloneNode(true);
        div.appendChild(clone);
      }

      return div;
    },
    generateTable,
  };
}
