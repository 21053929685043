
import cloneDeep from 'lodash/cloneDeep';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
import { ApiCashFlowUpdatePayload } from '@/models/api';
import {
  CashFlow,
  CashFlowUpdateModel,
  CASH_FLOW_METHOD_OPTIONS,
} from '@/models/cashFlow';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

interface CashFlowReceiptOption {
  label: string;
  value: boolean;
}

export default defineComponent({
  name: 'ReservationCashFlows',
  emits: ['printRefundInvoice'],
  data() {
    return {
      cashFlowDialog: false,
      cashFlowsCollapse: true,
      cashFlow: null as unknown as CashFlowUpdateModel,
      cashFlowMethods: cloneDeep(CASH_FLOW_METHOD_OPTIONS),
      cashFlowReceiptOptions: [
        {
          label: this.$t('reservationCashFlows.receiptOption.fiscalized'),
          value: true,
        },
        {
          label: this.$t('reservationCashFlows.receiptOption.notFiscalized'),
          value: false,
        },
      ] as Array<CashFlowReceiptOption>,
    };
  },
  methods: {
    onCashFlowEdit(cashFlow: CashFlow): void {
      if (
        !this.hasPermission(
          FEATURE_PERMISSION_ACTION_CONFIG.reservations.UPDATE_CASH_FLOWS,
        )
      ) {
        return;
      }

      this.cashFlow = {
        id: cashFlow.id,
        date: DateTime.fromSeconds(cashFlow.date),
        method: cashFlow.method,
        fiscal: !!cashFlow.receiptId || !!cashFlow.invoiceId,
        amount: cashFlow.amount,
        notes: cashFlow.notes || '',
        receipt: cashFlow.receipt || null,
      } as CashFlowUpdateModel;
      this.cashFlowDialog = true;
    },
    onCashFlowUpdate(): void {
      try {
        this.$store.dispatch('reservation/updateCashFlow', {
          id: this.cashFlow.id,
          date: this.cashFlow.date.toSeconds(),
          method: this.cashFlow.method,
          fiscal: this.cashFlow.fiscal,
          notes: this.cashFlow.notes,
        } as ApiCashFlowUpdatePayload);
        this.$spiagge.toast.success(
          this.$t('reservationCashFlows.toast.success'),
        );
        this.cashFlowDialog = false;
      } catch (e) {
        this.$spiagge.toast.error(this.$t('reservationCashFlows.toast.error'));
      }
    },
    hasPermission(action: string): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.reservations,
        action,
      );
    },
    onFiscalRefund(): void {
      if (this.cashFlow && this.cashFlow.receipt) {
        this.$emit('printRefundInvoice', this.cashFlow, true);
        this.cashFlowDialog = false;
      }
    },
  },
  computed: {
    FEATURE_PERMISSION_ACTION_CONFIG() {
      return FEATURE_PERMISSION_ACTION_CONFIG;
    },
    ...mapState('reservation', ['cashFlows', 'deleted']),
    filteredCashFlows(): Array<CashFlow> {
      return this.cashFlows.filter(
        (cashFlow: CashFlow) =>
          cashFlow.deleted === !!this.deleted || !cashFlow.deleted,
      );
    },
    cashFlowDate: {
      get(): Date {
        return this.cashFlow.date.toJSDate();
      },
      set(d: Date): void {
        const dateTime = DateTime.fromFormat(
          `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`,
          'd-M-yyyy',
        );
        this.cashFlow.date = dateTime;
      },
    },
  },
});
