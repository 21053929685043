export enum Country {
  ANDORRA = 'ae',
  UNITED_ARAB_EMIRATES = 'ad',
  AFGHANISTAN = 'af',
  ANTIGUA_AND_BARBUDA = 'ag',
  ANGUILLA = 'ai',
  ALBANIA = 'al',
  ARMENIA = 'am',
  ANGOLA = 'ao',
  ANTARCTICA = 'aq',
  ARGENTINA = 'ar',
  AMERICAN_SAMOA = 'as',
  AUSTRALIA = 'au',
  AUSTRIA = 'at',
  ARUBA = 'aw',
  ALAND_ISLANDS = 'ax',
  AZERBAIJAN = 'az',
  BOSNIA_AND_HERZEGOVINA = 'ba',
  BARBADOS = 'bb',
  BANGLADESH = 'bd',
  BELGIUM = 'be',
  BURKINA_FASO = 'bf',
  BULGARIA = 'bg',
  BAHRAIN = 'bh',
  BURUNDI = 'bi',
  BENIN = 'bj',
  SAINT_BARTHELEMY = 'bl',
  BERMUDA = 'bm',
  BRUNEI_DARUSSALAM = 'bn',
  BOLIVIA = 'bo',
  BONAIRE = 'bq',
  BRAZIL = 'br',
  BAHAMAS = 'bs',
  BHUTAN = 'bt',
  BOUVET_ISLAND = 'bv',
  BOTSWANA = 'bw',
  BELARUS = 'by',
  BELIZE = 'bz',
  CANADA = 'ca',
  COCOS_KEELING_ISLANDS = 'cc',
  THE_DEMOCRATIC_REPUBLIC_OF_THE_CONGO = 'cd',
  CENTRAL_AFRICAN_REPUBLIC = 'cf',
  CONGO = 'cg',
  SWITZERLAND = 'ch',
  COTE_IVOIRE = 'ci',
  COOK_ISLANDS = 'ck',
  CHILE = 'cl',
  CAMEROON = 'cm',
  CHINA = 'cn',
  COLOMBIA = 'co',
  COSTA_RICA = 'cr',
  CUBA = 'cu',
  CABO_VERDE = 'cv',
  CURACAO = 'cw',
  CHRISTMAS_ISLAND = 'cx',
  CYPRUS = 'cy',
  CZECHIA = 'cz',
  GERMANY = 'de',
  DJIBOUTI = 'dj',
  DENMARK = 'dk',
  DOMINICA = 'dm',
  DOMINICAN_REPUBLIC = 'do',
  ALGERIA = 'dz',
  ECUADOR = 'ec',
  ESTONIA = 'ee',
  EGYPT = 'eg',
  WESTERN_SAHARA = 'eh',
  ERITREA = 'er',
  SPAIN = 'es',
  ETHIOPIA = 'et',
  FINLAND = 'fi',
  FIJI = 'fj',
  FALKLAND_ISLANDS = 'fk',
  MICRONESIA = 'fm',
  FAROE_ISLANDS = 'fo',
  FRANCE = 'fr',
  GABON = 'ga',
  UNITED_KINGDOM = 'gb',
  GRENADA = 'gd',
  GEORGIA = 'ge',
  FRENCH_GUIANA = 'gf',
  GUERNSEY = 'gg',
  GHANA = 'gh',
  GIBRALTAR = 'gi',
  GREENLAND = 'gl',
  GAMBIA = 'gm',
  GUINEA = 'gn',
  GUADELOUPE = 'gp',
  EQUATORIAL_GUINEA = 'gq',
  GREECE = 'gr',
  SOUTH_GEORGIA = 'gs',
  GUATEMALE = 'gt',
  GUAM = 'gu',
  GUYANA = 'gy',
  HONG_KONG = 'hk',
  HEARD_ISLAND_AND_MC_DONALD_ISLANDS = 'hm',
  HONDURAS = 'hn',
  CROATIA = 'hr',
  HAITI = 'ht',
  HUNGARY = 'hu',
  INDONESIA = 'id',
  IRELAND = 'ie',
  ISRAEL = 'il',
  ISLE_OF_MAN = 'im',
  INDIA = 'in',
  BRITISH_INDIAN_OCEAN_TERRITORY = 'io',
  IRAQ = 'iq',
  IRAN = 'ir',
  ICELAND = 'is',
  ITALY = 'it',
  JERSEY = 'je',
  JAMAICA = 'jm',
  JORDAN = 'jo',
  JAPAN = 'jp',
  KENYA = 'ke',
  KYRGYZSTAN = 'kg',
  CAMBODIA = 'kh',
  KIRIBATI = 'ki',
  COMOROS = 'km',
  SAINT_KITTS_AND_NEVIS = 'kn',
  NORTH_KOREA = 'kp',
  SOUTH_KOREA = 'kr',
  KUWAIT = 'kw',
  CAYMAN_ISLANDS = 'ky',
  KAZAKHSTAN = 'kz',
  LAOS = 'la',
  LEBANON = 'lb',
  SAINT_LUCIA = 'lc',
  LIECHTENSTEIN = 'li',
  SRI_LANKA = 'lk',
  LIBERIA = 'lr',
  LESOTHO = 'ls',
  LITHUANIA = 'lt',
  LUXEMBOURG = 'lu',
  LATVIA = 'lv',
  LIBYA = 'ly',
  MOROCCO = 'ma',
  MONACO = 'mc',
  MOLDOVA = 'md',
  MONTENEGRO = 'me',
  SAINT_MARTIN = 'mf',
  MADAGASCAR = 'mg',
  MARSHALL_ISLANDS = 'mh',
  MACEDONIA = 'mk',
  MALI = 'ml',
  MYANMAR = 'mm',
  MONGOLIA = 'mn',
  MACAO = 'mo',
  NORTHERN_MARIANA_ISLANDS = 'mp',
  MARTINIQUE = 'mq',
  MAURITANIA = 'mr',
  MONTSERRAT = 'ms',
  MALTA = 'mt',
  MAURITIUS = 'mu',
  MALDIVES = 'mv',
  MALAWI = 'mw',
  MEXICO = 'mx',
  MALAYSIA = 'my',
  MOZAMBIQUE = 'mz',
  NAMIBIA = 'na',
  NEW_CALEDONIA = 'nc',
  NIGER = 'ne',
  NORFOLK_ISLAND = 'nf',
  NIGERIA = 'ng',
  NICARAGUA = 'ni',
  NETHERLANDS = 'nl',
  NORWAY = 'no',
  NEPAL = 'np',
  NAURU = 'nr',
  NIUE = 'nu',
  NEW_ZEALAND = 'nz',
  OMAN = 'om',
  PANAMA = 'pa',
  PERU = 'pe',
  FRENCH_POLYNESIA = 'pf',
  PAPUA_NEW_GUINEA = 'pg',
  PHILIPPINES = 'ph',
  PAKISTAN = 'pk',
  POLAND = 'pl',
  SAINT_PIERRE_AND_MIQUELON = 'pm',
  PITCAIRN = 'pn',
  PUERTO_RICO = 'pr',
  PALESTINE = 'ps',
  PORTUGAL = 'pt',
  PALAU = 'pw',
  PARAGUAY = 'py',
  QATAR = 'qa',
  REUNION = 're',
  ROMANIA = 'ro',
  SERBIA = 'rs',
  RUSSIAN = 'ru',
  RWANDA = 'rw',
  SAUDI_ARABIA = 'sa',
  SOLOMON_ISLANDS = 'sb',
  SEYCHELLES = 'sc',
  SUDAN = 'sd',
  SWEDEN = 'se',
  SINGAPORE = 'sg',
  SAINT_HELENA = 'sh',
  SLOVENIA = 'si',
  SVALBARD_AND_JAN_MAYEN = 'sj',
  SLOVAKIA = 'sk',
  SIERRA_LEONE = 'sl',
  SAN_MARINO = 'sm',
  SENEGAL = 'sn',
  SOMALIA = 'so',
  SURINAME = 'sr',
  SOUTH_SUDAN = 'ss',
  SAO_TOME_AND_PRINCIPE = 'st',
  EL_SALVADOR = 'sv',
  SINT_MAARTEN = 'sx',
  SYRIA = 'sy',
  SWAZILAND = 'sz',
  TURKS_AND_CAICOS_ISLANDS = 'tc',
  CHAD = 'td',
  FRENCH_SOUTHERN_TERRITORIES = 'tf',
  TOGO = 'tg',
  THAILAND = 'th',
  TAJIKISTAN = 'tj',
  TOKELAU = 'tk',
  TIMOR_LESTE = 'tl',
  TURKMENISTAN = 'tm',
  TUNISIA = 'tn',
  TONGA = 'to',
  TURKEY = 'tr',
  TRINIDAD_AND_TOBAGO = 'tt',
  TUVALU = 'tv',
  TAIWAN = 'tw',
  TANZANIA = 'tz',
  UKRAINE = 'ua',
  UGANDA = 'ug',
  UNITED_STATES_MINOR_OUTLYING_ISLANDS = 'um',
  UNITED_STATES_OF_AMERICA = 'us',
  URUGUAY = 'uy',
  UZBEKISTAN = 'uz',
  HOLY_SEE = 'va',
  SAINT_VINCENT_AND_GRENADINES = 'vc',
  VENEZUELA = 've',
  VIRGIN_ISLANDS_BRITISH = 'vg',
  VIRGIN_ISLAND_U_S = 'vi',
  VIETNAM = 'vn',
  VANUATU = 'vu',
  WALLIS_AND_FUTUNA = 'wf',
  SAMOA = 'ws',
  YEMEN = 'ye',
  MAYOTTE = 'yt',
  SOUTH_AFRICA = 'za',
  ZAMBIA = 'zm',
  ZIMBABWE = 'zw',
}

export enum AreaCode {
  AREA_CODE_ITALY = '+39',
  AREA_CODE_SAN_MARINO = '+378',
  AREA_CODE_UNITED_STATES_CANADA = '+1',
  AREA_CODE_UNITED_STATES_VIRGIN_ISLANDS = '+1 340',
  AREA_CODE_NORTHERN_MARIANA_ISLANDS = '+1 670',
  AREA_CODE_GUAM = '+1 671',
  AREA_CODE_AMERICAN_SAMOA = '+1 684',
  AREA_CODE_PUERTO_RICO = '+1 787',
  AREA_CODE_PUERTO_RICO2 = '+1 939',
  AREA_CODE_BAHAMAS = '+1 242',
  AREA_CODE_BARBADOS = '+1 246',
  AREA_CODE_ANGUILLA = '+1 264',
  AREA_CODE_ANTIGUA_BARBUDA = '+1 268',
  AREA_CODE_BRITISH_VIRGIN_ISLANDS = '+1 284',
  AREA_CODE_CAYMAN_ISLANDS = '+1 345',
  AREA_CODE_BERMUDA = '+1 441',
  AREA_CODE_GRENADA = '+1 473',
  AREA_CODE_TURKS = '+1 649',
  AREA_CODE_MONTSERRAT = '+1 664',
  AREA_CODE_SINT_MAARTEN = '+1 721',
  AREA_CODE_SAINT_LUCIA = '+1 758',
  AREA_CODE_DOMINICA = '+1 767',
  AREA_CODE_SAINT_VINCENT = '+1 784',
  AREA_CODE_DOMINICAN_REPUBLIC = '+1 809',
  AREA_CODE_DOMINICAN_REPUBLIC2 = '+1 829',
  AREA_CODE_DOMINICAN_REPUBLIC3 = '+1 849',
  AREA_CODE_TRINIDAD_TOBAGO = '+1 868',
  AREA_CODE_SAINT_KITTS = '+1 869',
  AREA_CODE_JAMAICA = '+1 876',
  AREA_CODE_JAMAICA2 = '+1 658',
  AREA_CODE_EGYPT = '+20',
  AREA_CODE_SOUTH_SUDAN = '+211 ',
  AREA_CODE_MOROCCO = '+212',
  AREA_CODE_ALGERIA = '+213',
  AREA_CODE_TUNISIA = '+216',
  AREA_CODE_LIBYA = '+218',
  AREA_CODE_GAMBIA = '+220',
  AREA_CODE_SENEGAL = '+221',
  AREA_CODE_MAURITANIA = '+222',
  AREA_CODE_MALI = '+223',
  AREA_CODE_GUINEA = '+224',
  AREA_CODE_IVORY_COAST = '+225',
  AREA_CODE_BURKINA_FASO = '+226',
  AREA_CODE_NIGER = '+227',
  AREA_CODE_TOGO = '+228',
  AREA_CODE_BENIN = '+229',
  AREA_CODE_MAURITIUS = '+230',
  AREA_CODE_LIBERIA = '+231',
  AREA_CODE_SIERRA_LEONE = '+232',
  AREA_CODE_GHANA = '+233',
  AREA_CODE_NIGERIA = '+234',
  AREA_CODE_CHAD = '+235',
  AREA_CODE_CENTRAL_AFRICAN_REPUBLIC = '+236',
  AREA_CODE_CAMEROON = '+237',
  AREA_CODE_CAPE_VERDE = '+238',
  AREA_CODE_SAO_TOME_PRINCIPE = '+239',
  AREA_CODE_EQUATORIAL_GUINEA = '+240',
  AREA_CODE_GABON = '+241',
  AREA_CODE_REPUBLICOFTHE_CONGO = '+242',
  AREA_CODE_DEMOCRATIC_REPUBLICOFTHE_CONGO = '+243',
  AREA_CODE_ANGOLA = '+244',
  AREA_CODE_GUINEA_BISSAU = '+245',
  AREA_CODE_BRITISH_INDIAN_OCEAN_TERRITORY = '+246',
  AREA_CODE_ASCENSION_ISLAND = '+247',
  AREA_CODE_SEYCHELLES = '+248',
  AREA_CODE_SUDAN = '+249',
  AREA_CODE_RWANDA = '+250',
  AREA_CODE_ETHIOPIA = '+251',
  AREA_CODE_SOMALIA = '+252',
  AREA_CODE_DJIBOUTI = '+253',
  AREA_CODE_KENYA = '+254',
  AREA_CODE_TANZANIA = '+255',
  AREA_CODE_ZANZIBAR = '+255 24',
  AREA_CODE_UGANDA = '+256',
  AREA_CODE_BURUNDI = '+257',
  AREA_CODE_MOZAMBIQUE = '+258',
  AREA_CODE_ZAMBIA = '+260',
  AREA_CODE_MADAGASCAR = '+261',
  AREA_CODE_REUNION = '+262',
  AREA_CODE_ZIMBABWE = '+263',
  AREA_CODE_NAMIBIA = '+264',
  AREA_CODE_MALAWI = '+265',
  AREA_CODE_LESOTHO = '+266',
  AREA_CODE_BOTSWANA = '+267',
  AREA_CODE_ESWATINI = '+268',
  AREA_CODE_COMOROS = '+269',
  AREA_CODE_SOUTH_AFRICA = '+27',
  AREA_CODE_SAINT_HELENA = '+290',
  AREA_CODE_TRISTANDA_CUNHA = '+290 8',
  AREA_CODE_ERITREA = '+291',
  AREA_CODE_ARUBA = '+297',
  AREA_CODE_ALAND_ISLANDS = '+358 18',
  AREA_CODE_BULGARIA = '+359',
  AREA_CODE_FINLAND = '+358',
  AREA_CODE_CYPRUS = '+357',
  AREA_CODE_MALTA = '+356',
  AREA_CODE_ALBANIA = '+355',
  AREA_CODE_ICELAND = '+354',
  AREA_CODE_IRELAND = '+353',
  AREA_CODE_LUXEMBOURG = '+352',
  AREA_CODE_PORTUGAL = '+351',
  AREA_CODE_GIBRALTAR = '+350',
  AREA_CODE_JUGOSLAVIA = '+38',
  AREA_CODE_GERMAN_DEMOCRATIC_REPUBLIC = '+37',
  AREA_CODE_HUNGARY = '+36',
  AREA_CODE_SPAIN = '+34',
  AREA_CODE_FRANCE = '+33',
  AREA_CODE_BELGIUM = '+32',
  AREA_CODE_NETHERLANDS = '+31',
  AREA_CODE_GREECE = '+30',
  AREA_CODE_LITHUANIA = '+370',
  AREA_CODE_LATVIA = '+371',
  AREA_CODE_ESTONIA = '+372',
  AREA_CODE_MOLDOVA = '+373',
  AREA_CODE_ARMENIA = '+374',
  AREA_CODE_ARTSAKH = '+374 47',
  AREA_CODE_ARTSAKH2 = '+374 97',
  AREA_CODE_BELARUS = '+375',
  AREA_CODE_ANDORRA = '+376',
  AREA_CODE_MONACO = '+377',
  AREA_CODE_VATICAN_CITY = '+379',
  AREA_CODE_UKRAINE = '+380',
  AREA_CODE_SERBIA = '+381',
  AREA_CODE_MONTENEGRO = '+382',
  AREA_CODE_KOSOVO = '+383',
  AREA_CODE_CROATIA = '+385',
  AREA_CODE_SLOVENIA = '+386',
  AREA_CODE_BOSNIA_HERZEGOVINA = '+387',
  AREA_CODE_NORTH_MACEDONIA = '+389',
  AREA_CODE_ROMANIA = '+40',
  AREA_CODE_SWITZERLAND = '+41',
  AREA_CODE_CZECH_REPUBLIC = '+420',
  AREA_CODE_SLOVAKIA = '+421',
  AREA_CODE_LIECHTENSTEIN = '+423',
  AREA_CODE_AUSTRIA = '+43',
  AREA_CODE_UNITED_KINGDOM = '+44',
  AREA_CODE_GUERNSEY = '+44 1481',
  AREA_CODE_JERSEY = '+44 1534',
  AREA_CODE_ISLEOF_MAN = '+44 1624',
  AREA_CODE_DENMARK = '+45',
  AREA_CODE_SWEDEN = '+46',
  AREA_CODE_NORWAY = '+47',
  AREA_CODE_SVALBARD = '+47 79',
  AREA_CODE_POLAND = '+48',
  AREA_CODE_GERMANY = '+49',
  AREA_CODE_FALKLAND_ISLANDS = '+500',
  AREA_CODE_BELIZE = '+501',
  AREA_CODE_GUATEMALA = '+502',
  AREA_CODE_EL_SALVADOR = '+503',
  AREA_CODE_HONDURAS = '+504',
  AREA_CODE_NICARAGUA = '+505',
  AREA_CODE_COSTA_RICA = '+506',
  AREA_CODE_PANAMA = '+507',
  AREA_CODE_SAINT_PIERRE = '+508',
  AREA_CODE_HAITI = '+509',
  AREA_CODE_PERU = '+51',
  AREA_CODE_MEXICO = '+52',
  AREA_CODE_CUBA = '+53',
  AREA_CODE_ARGENTINA = '+54',
  AREA_CODE_BRAZIL = '+55',
  AREA_CODE_CHILE = '+56',
  AREA_CODE_COLUMBIA = '+57',
  AREA_CODE_VENEZUELA = '+58',
  AREA_CODE_GUADELOUPE = '+590',
  AREA_CODE_BOLIVIA = '+591',
  AREA_CODE_GUYANA = '+592',
  AREA_CODE_ECUADOR = '+593',
  AREA_CODE_FRENCH_GUIANA = '+594',
  AREA_CODE_PARAGUAY = '+595',
  AREA_CODE_MARTINIQUE = '+596',
  AREA_CODE_SURINAME = '+597',
  AREA_CODE_URUGUAY = '+598',
  AREA_CODE_NETHERLANDS_ANTILLES = '+599',
  AREA_CODE_SINT_EUSTATIUS = '+599 3',
  AREA_CODE_SABA = '+599 4',
  AREA_CODE_BONAIRE = '+599 7',
  AREA_CODE_CURACAO = '+599 9',
  AREA_CODE_MALAYSIA = '+60',
  AREA_CODE_AUSTRALIA = '+61',
  AREA_CODE_COCOS_ISLANDS = '+61 8 9162',
  AREA_CODE_CHRISTMAS_ISLAND = '+61 8 9164',
  AREA_CODE_INDONESIA = '+62',
  AREA_CODE_PHILIPPINES = '+63',
  AREA_CODE_NEW_ZEALAND = '+64',
  AREA_CODE_SINGAPORE = '+65',
  AREA_CODE_THAILAND = '+66',
  AREA_CODE_EAST_TIMOR = '+670',
  AREA_CODE_AUSTRALIAN_ANTARCTIC_TERRITORY = '+672 1',
  AREA_CODE_NORFOLK_ISLAND = '+672 3',
  AREA_CODE_BRUNEI = '+673',
  AREA_CODE_NAURU = '+674',
  AREA_CODE_PAPUA_NEW_GUINEA = '+675',
  AREA_CODE_TONGA = '+676',
  AREA_CODE_SOLOMON_ISLANDS = '+677',
  AREA_CODE_VANUATU = '+678',
  AREA_CODE_FIJI = '+679',
  AREA_CODE_PALAU = '+680',
  AREA_CODE_WALLIS_FUTUNA = '+681',
  AREA_CODE_COOK_ISLANDS = '+682',
  AREA_CODE_NIUE = '+683',
  AREA_CODE_SAMOA = '+685',
  AREA_CODE_KIRIBATI = '+686',
  AREA_CODE_NEW_CALEDONIA = '+687',
  AREA_CODE_TUVALU = '+688',
  AREA_CODE_FRENCH_POLYNESIA = '+689',
  AREA_CODE_TOKELAU = '+690',
  AREA_CODE_FEDERATED_STATESOF_MICRONESIA = '+691',
  AREA_CODE_MARSHALL_ISLANDS = '+692',
  AREA_CODE_RUSSIA = '+7',
  AREA_CODE_KAZAKHSTAN = '+7 6',
  AREA_CODE_ABKHAZIA = '+7 840',
  AREA_CODE_JAPAN = '+81',
  AREA_CODE_SOUTH_KOREA = '+82',
  AREA_CODE_VIETNAM = '+84',
  AREA_CODE_NORTH_KOREA = '+850',
  AREA_CODE_HONG_KONG = '+852',
  AREA_CODE_MACAU = '+853',
  AREA_CODE_CAMBODIA = '+855',
  AREA_CODE_LAOS = '+856',
  AREA_CODE_CHINA = '+86',
  AREA_CODE_BANGLADESH = '+880',
  AREA_CODE_TAIWAN = '+886',
  AREA_CODE_TURKEY = '+90',
  AREA_CODE_NORTHERN_CYPRUS = '+90 392',
  AREA_CODE_INDIA = '+91',
  AREA_CODE_PAKISTAN = '+92',
  AREA_CODE_AZAD_KASHMIR = '+92 582',
  AREA_CODE_GILGIT_BALTISTAN = '+92 581',
  AREA_CODE_AFGHANISTAN = '+93',
  AREA_CODE_SRI_LANKA = '+94',
  AREA_CODE_MYANMAR = '+95',
  AREA_CODE_MALDIVES = '+960',
  AREA_CODE_LEBANON = '+961',
  AREA_CODE_JORDAN = '+962',
  AREA_CODE_SYRIA = '+963',
  AREA_CODE_IRAQ = '+964',
  AREA_CODE_KUWAIT = '+965',
  AREA_CODE_SAUDI_ARABIA = '+966',
  AREA_CODE_YEMEN = '+967',
  AREA_CODE_OMAN = '+968',
  AREA_CODE_PALESTINE = '+970',
  AREA_CODE_UNITED_ARAB_EMIRATES = '+971',
  AREA_CODE_ISRAEL = '+972',
  AREA_CODE_BAHRAIN = '+973',
  AREA_CODE_QATAR = '+974',
  AREA_CODE_BHUTAN = '+975',
  AREA_CODE_MONGOLIA = '+976',
  AREA_CODE_NEPAL = '+977',
  AREA_CODE_IRAN = '+98',
  AREA_CODE_TAJIKISTAN = '+992',
  AREA_CODE_TURKMENISTAN = '+993',
  AREA_CODE_AZERBAIJAN = '+994',
  AREA_CODE_GEORGIA = '+995',
  AREA_CODE_SOUTH_OSSETIA = '+995 34',
  AREA_CODE_KYRGYZSTAN = '+996',
  AREA_CODE_UZBEKISTAN = '+998',
}

export enum CardOperationAstoria {
  PAY = 'Pagamento con tessera',
  CASHBACK = 'Cashback',
}

export enum SpecialLicense {
  ASTORIA_CENTER = 'it-ve-30015-centro-astoria',
}

export enum ArticleType {
  MAIN_ITEM,
  CABIN,
  ADDITION,
  PARKING,
  SERVICE,
  EXPENSES,
}
