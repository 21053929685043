
import { defineComponent, PropType } from 'vue';
import { mapState } from 'vuex';
import FileUpload from 'primevue/fileupload';
import { ReservationDocument } from '@/models/reservation';
import reservationService from '@/services/reservationService';
import {
  ApiReservationCreateDocumentPayload,
  ApiReservationDeleteDocumentPayload,
  ApiReservationGetDocumentsPayload,
} from '@/models/api';
import { LicenseDocumentTemplate } from '@/models/license';
import FreeDraw from '@/components/shared/FreeDraw.vue';

export default defineComponent({
  name: 'DocumentationUploader',
  components: { FreeDraw },
  props: {
    template: {
      type: Object as PropType<LicenseDocumentTemplate>,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      ready: false,
      documents: [] as Array<ReservationDocument>,
      fileDraw: null as unknown as File,
    };
  },
  methods: {
    async createDocument(file: File): Promise<void> {
      try {
        const document: ReservationDocument =
          await reservationService.createDocument({
            id: this.id,
            templateId: this.template.id,
            documentImage: file,
          } as ApiReservationCreateDocumentPayload);
        this.documents.push(document);
        this.$spiagge.toast.success(
          this.$t('documentationUploader.toast.uploadSuccess'),
        );
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('documentationUploader.toast.uploadError'),
        );
      }
    },
    onFileDrawUpdate(file: File) {
      this.fileDraw = file;
    },
    onFileDrawUpload() {
      this.createDocument(this.fileDraw);
      (
        this.$refs.filedraw as any & {
          onClear: () => void;
        }
      ).onClear();
    },
    onFileUpload(event: any): void {
      this.createDocument(event.files[0]);
      (
        this.$refs.fileUpload as FileUpload & {
          clear: () => void;
        }
      ).clear();
    },
    async onOpen(): Promise<void> {
      this.show = true;
      this.ready = false;
      try {
        this.documents = await reservationService.getDocuments({
          id: this.id,
          templateId: this.template.id,
        } as ApiReservationGetDocumentsPayload);
      } catch (e) {
        this.documents = [];
      } finally {
        this.ready = true;
      }
    },
    async onPrint(): Promise<void> {
      if (
        (!this.template.url || this.template.url === '') &&
        this.template.templateData &&
        this.template.templateData !== ''
      ) {
        window.open(
          `https://legacy.spiagge.it/app/yourbeach/documents/generate.php?regId=${this.id}&w=${this.template.licenseId}&templateId=${this.template.id}`,
          '_blank',
        );
      }
      window.open(this.template.url, '_blank');
    },
    async onDeleteDocument(document: ReservationDocument): Promise<void> {
      try {
        await reservationService.deleteDocument({
          id: this.id,
          templateId: this.template.id,
          documentId: document.id,
        } as ApiReservationDeleteDocumentPayload);
        this.documents = this.documents.filter(
          (d: ReservationDocument) => document.id !== d.id,
        );
        this.$spiagge.toast.success(
          this.$t('documentationUploader.toast.deleteSuccess'),
        );
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('documentationUploader.toast.deleteError'),
        );
      }
    },
    onOpenDocument(document: ReservationDocument): void {
      window.open(document.url, '_blank');
    },
  },
  computed: {
    ...mapState('reservation', ['id']),
    canPrint(): boolean {
      return !(
        (!this.template.url || this.template.url === '') &&
        (!this.template.templateData || this.template.templateData === '')
      );
    },
  },
});
