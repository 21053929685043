import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "content"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    id: "progress-dialog",
    class: "dialog",
    modal: "",
    visible: _ctx.progressDialog !== null,
    closable: false
  }, _createSlots({
    default: _withCtx(() => [
      (_ctx.progressDialog.content)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_ProgressSpinner, { strokeWidth: "4" }),
            (_ctx.isHtml)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  innerHTML: _ctx.progressDialog.content
                }, null, 8, _hoisted_2))
              : (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.progressDialog.content), 1))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 2
  }, [
    (_ctx.progressDialog && _ctx.progressDialog.title)
      ? {
          name: "header",
          fn: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.progressDialog.title), 1)
          ])
        }
      : undefined,
    (_ctx.progressDialog && _ctx.progressDialog.actions)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.progressDialog.actions, (action, index) => {
              return (_openBlock(), _createBlock(_component_Button, {
                key: index,
                class: _normalizeClass(action.classes.join(' ')),
                label: action.label,
                icon: action.icon,
                onClick: ($event: any) => (action.callback())
              }, null, 8, ["class", "label", "icon", "onClick"]))
            }), 128))
          ])
        }
      : undefined
  ]), 1032, ["visible"]))
}