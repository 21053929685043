/**
 * App store module
 */

import cloneDeep from 'lodash/cloneDeep';
import { MutationTree, ActionTree, GetterTree } from 'vuex';
import { _RouteLocationBase } from 'vue-router';
import {
  RootState,
  AppState,
  DEFAULT_APP_STATE,
  DeviceSpecs,
} from '@/models/store';
import {
  AppAction,
  AppProgressDialog,
  AppRequestStatus,
  AppSpotDialog,
} from '@/models/app';
import { Breadcrumb, Breakpoints } from '@/models';

const namespaced = true;

const state = (): AppState => cloneDeep(DEFAULT_APP_STATE);

const getters: GetterTree<AppState, RootState> = {
  navigation: (state) => state.navigation,
  loading: (state) => state.loading,
  processing: (state) => state.processing,
  focus: (state) => state.focus,
  action: (state) => state.action,
  returnToRoute: (state) => state.returnToRoute,
  windowWidth: (state) => state.windowWidth,
  windowHeight: (state) => state.windowHeight,
  deviceSpecs: (state) => state.deviceSpecs,
  breakpoints: (state) => state.breakpoints,
  requestStatus: (state) => state.requestStatus,
  httpPendingRequests: (state) => state.httpRequests.length > 0,
} as GetterTree<AppState, RootState>;

const mutations: MutationTree<AppState> = {
  setNavigation(state: AppState, navigation: boolean) {
    state.navigation = navigation;
  },
  toggleNavigation(state: AppState) {
    state.navigation = !state.navigation;
  },
  setLoading(state: AppState, loading) {
    state.loading = loading;
  },
  setProcessing(state: AppState, processing) {
    state.processing = processing;
  },
  setFocus(state: AppState, focus: string) {
    state.focus = focus;
  },
  setAction(state: AppState, action: AppAction) {
    state.action = action;
  },
  setReturnToRoute(state: AppState, returnToRoute: string) {
    state.returnToRoute = returnToRoute;
  },
  setReservationEntryPoint(state: AppState, reservationEntryPoint: string) {
    state.reservationEntryPoint = reservationEntryPoint;
  },
  setWindowWidth(state: AppState, windowWidth: number) {
    state.windowWidth = windowWidth;
    state.breakpoints = {
      mobile: windowWidth < 992,
      desktop: windowWidth >= 992,
      xs: windowWidth < 576,
      sm: windowWidth >= 576 && windowWidth < 768,
      md: windowWidth >= 768 && windowWidth < 992,
      lg: windowWidth >= 992 && windowWidth < 1200,
      smUp: windowWidth >= 576,
      mdUp: windowWidth >= 768,
      lgUp: windowWidth >= 992,
      xlUp: windowWidth >= 1200,
      smDown: windowWidth < 576,
      mdDown: windowWidth < 768,
      lgDown: windowWidth < 992,
      xlDown: windowWidth < 1200,
    } as Breakpoints;
  },
  setWindowHeight(state: AppState, windowHeight: number) {
    state.windowHeight = windowHeight;
  },
  setDeviceSpecs(state: AppState, deviceSpecs: DeviceSpecs) {
    state.deviceSpecs = deviceSpecs;
  },
  setRoute(state: AppState, route: _RouteLocationBase) {
    state.route = route;
  },
  setSpotDialog(state: AppState, spotDialog: AppSpotDialog) {
    state.spotDialog = spotDialog;
  },
  resetSpotDialog(state: AppState) {
    Object.assign(state.spotDialog, DEFAULT_APP_STATE.spotDialog);
  },
  setRequestStatus(state: AppState, requestStatus: AppRequestStatus) {
    state.requestStatus = requestStatus;
  },
  setBreadcrumbs(state: AppState, breadcrumbs: Array<Breadcrumb>) {
    state.breadcrumbs = cloneDeep(breadcrumbs);
  },
  httpRequestStart(state: AppState, request: string) {
    state.httpRequests.push(request);
  },
  httpRequestEnd(state: AppState, request: string) {
    state.httpRequests = state.httpRequests.filter(
      (r: string) => r !== request,
    );
  },
  setProgressDialog(state: AppState, progressDialog: AppProgressDialog) {
    state.progressDialog = progressDialog;
  },
} as MutationTree<AppState>;

const actions: ActionTree<AppState, RootState> = {} as ActionTree<
  AppState,
  RootState
>;

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
