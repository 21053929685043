import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, renderList as _renderList, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "beds-area-title" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "name" }
const _hoisted_4 = { class: "customer" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "name" }
const _hoisted_8 = { class: "services" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "staging" }
const _hoisted_11 = { class: "date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["map-spot", _ctx.mapSpotClasses]),
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClick && _ctx.onClick(...args))),
    style: _normalizeStyle(_ctx.mapSpotStyle)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["spot", _ctx.spotClasses]),
      style: _normalizeStyle(_ctx.spotStyle)
    }, [
      (_ctx.isBedsArea)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t('spotType.bedsArea')), 1),
            _createElementVNode("img", {
              class: "icon",
              src: _ctx.spot.style.icons[_ctx.spot.type],
              alt: ""
            }, null, 8, _hoisted_2),
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.name), 1)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.spot.customerName), 1),
            _withDirectives(_createElementVNode("img", {
              class: "attr-icon",
              style: _normalizeStyle({ backgroundColor: _ctx.spot.style.css.borderColor }),
              src: _ctx.spot.style.attributeIcon
            }, null, 12, _hoisted_5), [
              [_vShow, _ctx.spot.style.attributeIcon && !_ctx.isSelected]
            ]),
            _createElementVNode("img", {
              class: "icon",
              src: _ctx.spot.style.icons[_ctx.spot.type],
              alt: ""
            }, null, 8, _hoisted_6),
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.name), 1),
            _createElementVNode("div", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.spot.servicesIcon, (service, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "service"
                }, [
                  _createElementVNode("img", {
                    src: service,
                    alt: "service"
                  }, null, 8, _hoisted_9)
                ]))
              }), 128))
            ]),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.staging), 1),
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.date), 1)
          ], 64))
    ], 6)
  ], 6))
}