import _ from 'lodash';

export default class Combination {
  elements: Array<string>;

  constructor(elements: Array<string>) {
    this.elements = elements;
  }

  * combinations(
    length: number,
    withRepetition = false,
    position = 0,
    elements: Array<string> = [],
  ): Generator<string[]> {
    const size = this.elements.length;
    for (let i = position; i < size; i += 1) {
      elements.push(this.elements[i]);
      if (elements.length === length) {
        yield elements;
      } else {
        yield* this.combinations(
          length,
          withRepetition,
          withRepetition ? i : i + 1,
          elements,
        );
      }
      elements.pop();
    }
  }

  buildCombination = (itemKey: Array<string>): string => {
    const combinationsParts: Array<string> = [];
    _.forOwn(
      _.countBy(itemKey),
      (value, key) => {
        combinationsParts.push(`${key}${value}`);
      },
    );
    return combinationsParts.join('_');
  }

  getCombinations(
    numPieces: number,
  ): Array<string> {
    const result: Array<string> = [];
    const generator = this.combinations(numPieces, true);
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const item: IteratorResult<Array<string>> = generator.next();
      if (item.done) {
        break;
      }
      result.push(this.buildCombination(item.value));
    }
    return result;
  }
}
