/**
 * Customer service
 */

import axios, { AxiosResponse } from 'axios';
import {
  ApiCustomerOneResponse,
  ApiCustomerListResponse,
  ApiCustomerExportPayload,
  ApiTagCustomerResponse,
  ApiCustomerImportResponse,
  ApiCustomerIndexRequestPayload,
} from '@/models/api';
import apiService from '@/services/apiService';
import { Customer } from '@/models/customer';

export default {
  async list(
    payload: ApiCustomerIndexRequestPayload,
  ): Promise<ApiCustomerListResponse> {
    const res: AxiosResponse<ApiCustomerListResponse> = await axios.post(
      apiService.customer.list(),
      payload,
    );
    return res.data;
  },
  async one(id: number): Promise<ApiCustomerOneResponse> {
    const res: AxiosResponse<ApiCustomerOneResponse> = await axios.get(
      apiService.customer.one(id),
    );
    return res.data;
  },
  async create(customer: Customer): Promise<Customer> {
    const res: AxiosResponse<ApiCustomerOneResponse> = await axios.post(
      apiService.customer.create(),
      customer,
    );
    return res.data.result.customer;
  },
  async update(customer: Customer): Promise<Customer> {
    if (!customer.contactId) return {} as Customer;
    const contactId = customer.contactId;
    const res: AxiosResponse<ApiCustomerOneResponse> = await axios.patch(
      apiService.customer.update(contactId),
      customer,
    );
    return res.data.result.customer;
  },
  async delete(id: number | undefined): Promise<void> {
    if (!id) return;
    await axios.delete(apiService.customer.update(id));
  },
  async exportCustomers(payload: ApiCustomerExportPayload): Promise<Blob> {
    const res: AxiosResponse<Blob> = await axios.post(
      apiService.customer.export(),
      payload,
      {
        headers: {
          Accept: 'text/csv',
        },
      },
    );
    return new Blob([res.data]);
  },
  async importCustomers(payload: FormData): Promise<boolean> {
    const res: AxiosResponse<ApiCustomerImportResponse> = await axios.post(
      apiService.customer.import(),
      payload,
      {
        headers: { 'content-type': 'multipart/form-data' },
      },
    );
    return res.data.result.import;
  },
  async getCustomerTags(contactId: number): Promise<Array<number>> {
    const res: AxiosResponse<ApiTagCustomerResponse> = await axios.get(
      apiService.customer.customerTags(contactId),
    );
    return res.data.result.tags;
  },
  async updateCustomerTags(
    contactId: number,
    tags: Array<number>,
  ): Promise<Array<number>> {
    const res: AxiosResponse<ApiTagCustomerResponse> = await axios.patch(
      apiService.customer.customerTags(contactId),
      {
        tagIds: tags,
      } as { [key: string]: Array<number> },
    );
    return res.data.result.tags;
  },
};
