
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import _ from 'lodash';

export default defineComponent({
  name: 'MapPrint',
  methods: {
    onOpen(): void {
      this.$router.push(
        `/map-print?startDate=${this.calendar[0].toSeconds()}&endDate=${this.calendar[1].toSeconds()}&view=${
          this.view
        }`,
      );
    },
  },
  computed: {
    ...mapState('map', ['calendar', 'instance', 'data', 'view']),
    ...mapState('app', ['breakpoints']),
    ...mapState('session', ['license']),
  },
});
