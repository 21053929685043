
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
import licenseService from '@/services/licenseService';
import { License } from '@/models/license';
import { DropdownOption } from '@/models';
import { PriceList } from '@/models/priceList';
import CalendarInput from '@/components/shared/CalendarInput.vue';
import reservationService from '@/services/reservationService';
import { ApiLicenseUpdatePayload } from '@/models/api';

export default defineComponent({
  name: 'PriceListsSeasonalSettings',
  emits: ['close'],
  components: {
    CalendarInput,
  },
  data() {
    return {
      model: {} as ApiLicenseUpdatePayload,
      currentYear: DateTime.now().year,
      isCopyngSeasonals: false,
    };
  },
  created() {
    /**
     * Init model
     */
    this.model = {
      seasonalPriceListId: this.license.seasonalPriceListId,
      seasonalStartDate: this.license.seasonalStartDate,
      seasonalEndDate: this.license.seasonalEndDate,
    } as ApiLicenseUpdatePayload;
  },
  computed: {
    ...mapState('session', ['license']),
    ...mapState('priceLists', ['priceLists']),
    priceListOptions(): Array<DropdownOption> {
      /**
       * Price lists options
       */
      const options: Array<DropdownOption> = [
        { value: null, label: 'Nessun listino' },
      ];
      this.priceLists.map((priceList: PriceList) => {
        options.push({
          value: priceList.id,
          label: priceList.name,
        } as DropdownOption);
      });
      return options;
    },
    canCopySeasonal(): boolean {
      /**
       * Check if seasonal copy is available
       */
      let canCopySeasonal = true;
      if (this.license) {
        canCopySeasonal = this.license.seasonalLastCopy < this.copyYear;
      }
      return canCopySeasonal;
    },
    copyYear(): number {
      /**
       * Copy year
       */
      return DateTime.now().month < 9
        ? DateTime.now().year
        : DateTime.now().year + 1;
    },
    visible: {
      get(): boolean {
        return true;
      },
      set(visible: boolean): void {
        this.$store.commit('priceLists/setShowSeasonalSettingsDialog', visible);
      },
    },
  },
  methods: {
    async onSave(): Promise<void> {
      /**
       * Save seasonal settings
       */
      try {
        const license: License = await licenseService.updateNew(this.model);
        this.$store.commit('session/setLicense', license);
        this.$spiagge.toast.success(
          this.$t('priceListsSeasonalSettings.toast.saveSuccess'),
        );
        this.$store.commit('priceLists/setShowSeasonalSettingsDialog', false);
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('priceListsSeasonalSettings.toast.saveError'),
        );
      }
    },
    async copySeasonal(): Promise<void> {
      /**
       * Copy seasonal
       */
      this.isCopyngSeasonals = true;
      try {
        await reservationService.copySeasonal();
        this.$spiagge.toast.success(
          this.$t('priceListsSeasonalSettings.toast.copySuccess'),
        );
        await this.$store.dispatch('session/setLicense', this.license?.license);
      } catch (error) {
        this.$spiagge.toast.error(
          this.$t('priceListsSeasonalSettings.toast.copyError'),
        );
      } finally {
        this.isCopyngSeasonals = false;
      }
    },
    onCopySeasonal() {
      /**
       * Copy seasonal (confirmation)
       */
      this.$confirm.require({
        message: this.$t('priceListsSeasonalSettings.confirm.message'),
        header: this.$t('priceListsSeasonalSettings.confirm.title'),
        icon: 'pi pi-info-circle',
        acceptLabel: this.$t('common.yes'),
        accept: () => {
          this.copySeasonal();
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          // do nothing
        },
      });
    },
  },
});
