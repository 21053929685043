import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4850c534"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "rules"
}
const _hoisted_2 = { class: "footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RevenueManagementRule = _resolveComponent("RevenueManagementRule")!
  const _component_Message = _resolveComponent("Message")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", {
    class: "revenue-management-rules",
    key: _ctx.updateKey
  }, [
    (_ctx.rules.length > 0 || _ctx.addRule)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.orderedRules, (rule, ruleIndex) => {
            return (_openBlock(), _createBlock(_component_RevenueManagementRule, {
              key: ruleIndex,
              rule: rule,
              thresholdLabel: _ctx.thresholdLabel,
              valueLabel: _ctx.valueLabel,
              disabled: _ctx.addRule || (_ctx.editRule && _ctx.editRule.id !== rule.id),
              price: _ctx.price,
              onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update', $event))),
              onDelete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('delete', $event))),
              onEdit: _ctx.onEdit,
              onAbort: _ctx.onAbort
            }, null, 8, ["rule", "thresholdLabel", "valueLabel", "disabled", "price", "onEdit", "onAbort"]))
          }), 128)),
          (_ctx.addRule)
            ? (_openBlock(), _createBlock(_component_RevenueManagementRule, {
                key: 0,
                thresholdLabel: _ctx.thresholdLabel,
                valueLabel: _ctx.valueLabel,
                price: _ctx.price,
                onCreate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('create', $event))),
                onAbort: _ctx.onAbort
              }, null, 8, ["thresholdLabel", "valueLabel", "price", "onAbort"]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createBlock(_component_Message, {
          key: 1,
          severity: "info",
          closable: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('revenueManagementRules.noRuleMessage')), 1)
          ]),
          _: 1
        })),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Button, {
        class: "p-button-text",
        icon: "spi-plus",
        label: _ctx.addLabel,
        onClick: _ctx.onAddRule,
        disabled: !_ctx.canAddRule
      }, null, 8, ["label", "onClick", "disabled"])
    ])
  ]))
}