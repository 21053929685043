import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "p-field name-field" }
const _hoisted_4 = { for: "name-input" }
const _hoisted_5 = {
  key: 0,
  class: "p-error"
}
const _hoisted_6 = {
  ref: "submit",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Field = _resolveComponent("Field")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    id: "price-lists-voucher-create",
    class: "dialog",
    modal: "",
    visible: _ctx.visible,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.visible) = $event))
  }, {
    header: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.$t('priceListsVoucherCreate.newVoucher')), 1)
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        class: "p-button-outlined",
        label: _ctx.$t('button.cancel'),
        icon: "spi-times",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.visible = false))
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        label: _ctx.$t('button.save'),
        icon: "spi-check",
        onClick: _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$refs.submit as HTMLButtonElement).click()))
      }, null, 8, ["label"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        ref: "form",
        onSubmit: _ctx.onCreate
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_Field, {
                name: "name",
                modelValue: _ctx.model.name,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event)),
                rules: "required"
              }, {
                default: _withCtx(({ field, meta, errorMessage }) => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('priceListsVoucherCreate.name')), 1),
                    _createVNode(_component_InputText, _mergeProps({ id: "name-input" }, field, {
                      type: "text",
                      class: !meta.valid && meta.touched ? 'p-invalid' : ''
                    }), null, 16, ["class"]),
                    (!meta.valid && meta.touched)
                      ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(errorMessage), 1))
                      : _createCommentVNode("", true)
                  ])
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _withDirectives(_createElementVNode("button", _hoisted_6, null, 512), [
                [_vShow, false]
              ])
            ])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ]),
    _: 1
  }, 8, ["visible"]))
}