
import { defineComponent } from 'vue';
import { RouteLocationNormalized } from 'vue-router';
import { AxiosError } from 'axios';
import _, { cloneDeep } from 'lodash';
import { mapState } from 'vuex';
import DynamicForm from '@/components/dynamic-form/DynamicForm.vue';
import localStorageUtil from '@/utils/localStorageUtil';
import priceConfigService from '@/services/priceConfigService';
import { Field, FieldType, NESTED_FIELDS } from '@/models/field';
import {
  PeriodConfiguration,
  PriceConfig,
  PriceConfigModel,
  PriceConfigSession,
  PriceConfigStatus,
  PriceConfigWizard,
  PriceWizardStep,
  Step,
} from '@/models/priceConfig';
import ProcessingButton from '@/components/shared/ProcessingButton.vue';
import { PriceListType } from '@/models/priceList';
import priceWizardUtil from '@/utils/priceWizardUtil';
import { ApiPriceConfigCreatePayload } from '@/models/api';

export default defineComponent({
  name: 'PriceListsWizardView',
  components: {
    DynamicForm,
    ProcessingButton,
  },
  data() {
    return {
      id: 0,
      priceListType: PriceListType.STANDARD,
      confirmExit: false,
      mounted: false,
      wizardSteps: [] as Array<PriceWizardStep>,
      // wizard data
      wizardInstance: undefined as unknown as PriceConfig,
      wizardStructure: undefined as unknown as PriceConfigWizard,
      wizardModel: undefined as unknown as PriceConfigModel,
      wizardDefaultModel: undefined as unknown as PriceConfigModel,
      // dynamic form fields types
      types: {
        // step 1
        name: FieldType.INPUTTEXT,
        year: FieldType.INPUTNUMBER,
        useSingleComboPiecesLimit: FieldType.YES_NO,
        priceMode: FieldType.RADIO,
        discountType: FieldType.MULTISELECT,
        thresholdCalculationMode: FieldType.DROPDOWN,
        thresholdPercentageValues:
          FieldType.PRICE_CONFIG_THRESHOLD_PERCENTAGE_VALUES,
        extraDaysMode: FieldType.DROPDOWN,
        nextDayMode: FieldType.DROPDOWN,
        dailyValuesCalculationMode: FieldType.DROPDOWN,
        periodsShiftMode: FieldType.DROPDOWN,
        useHalfDay: FieldType.YES_NO,
        listingMode: FieldType.RADIO,
        periodsData: FieldType.PRICE_CONFIG_PERIODS,
        useWeekend: FieldType.YES_NO,
        weekendDays: FieldType.CHECKBOX,
        holidays: FieldType.PRICE_CONFIG_HOLIDAYS,
        useCabinCombination: FieldType.YES_NO,
        combinationPieces: FieldType.DROPDOWN,
        sectorCombinationPieces: FieldType.PRICE_CONFIG_SECTOR_COMBO_PIECES,
        // prices step
        priceCombinations: FieldType.PRICE_CONFIG_COMBINATIONS,
      },
      errors: {},
      autoSaveInterval: 0,
      locked: false,
    };
  },
  async beforeMount() {
    /**
     * Wizard initialization
     */
    try {
      this.$store.commit('app/setLoading', true);

      // get id from route
      this.id = this.$route.params.id ? Number(this.$route.params.id) : 0;

      this.priceListType = this.$route.query.priceListType
        ? String(this.$route.query.priceListType) as PriceListType
        : PriceListType.STANDARD;

      // init localstorage
      localStorageUtil.configure('spiagge', 'priceConfig');

      // build
      await this.buildWizard();

      // get local wizard session
      const wizardSession: PriceConfigSession | null =
        await localStorageUtil.get('session');

      if (wizardSession) {
        // use local wizard session
        this.wizardModel = wizardSession.model;
        this.$spiagge.toast.info(this.$t('priceListsWizardView.toast.info'));
      } else {
        // build wizard model
        this.buildWizardModel();
      }

      if (
        this.wizardInstance &&
        this.wizardInstance.lockUserId !== this.user.id
      ) {
        this.locked = true;
        localStorageUtil.clear();
      }

      // autosave every 5s (only if not locked or active)
      if (!this.locked && !this.isActive) {
        this.autoSaveInterval = setInterval(async () => {
          try {
            await localStorageUtil.set('session', {
              id: this.id,
              model: this.wizardModel,
            } as PriceConfigSession);
          } catch (e) {
            //
          }
        }, 5000);
      }
    } catch (e) {
      this.$spiagge.toast.error(
        this.$t('priceListsWizardView.toast.initError'),
      );
    } finally {
      this.$store.commit('app/setLoading', false);
    }
  },
  mounted() {
    this.mounted = true;
  },
  methods: {
    async buildWizard(buildModel = false): Promise<void> {
      /**
       * Build wizard structure, instance, default model and model (optional)
       */

      // wizard structure
      const wizardPromises: Array<Promise<PriceConfigWizard | PriceConfig>> = [
        priceConfigService.wizard(this.id, this.priceListType),
      ];

      // wizard instance (only with id)
      if (this.id) {
        wizardPromises.push(priceConfigService.get(this.id));
      }

      const res = await Promise.all(wizardPromises);

      const wizardStructureResponse = res[0] as PriceConfigWizard;
      this.wizardSteps = wizardStructureResponse.steps;
      this.wizardStructure = wizardStructureResponse;
      if (this.id) {
        this.wizardInstance = res[1] as PriceConfig;
      }

      this.forceChangeStep();
      // wizard default module
      const tempModel = {} as PriceConfigModel;
      [
        ...this.wizardStructure.basicStep,
        ...this.wizardStructure.periodStep,
        ...this.wizardStructure.priceStep,
      ].forEach(
        (field: Field) => {
          const mainFieldName = field.n as string;
          if (NESTED_FIELDS.includes(mainFieldName)) {
            const periodsConfigs = [] as Array<PeriodConfiguration>;
            (field.o as Array<Field>).forEach((nestedField: Field) => {
              // field.n is the main field
              // nestedField.n is the index of the list of tabs
              const fieldsContainer = {} as PeriodConfiguration;
              (nestedField.o as Array<Field>).forEach(
                (o: Field) => {
                  const fieldName = o.n as string;
                  // fieldName is the field itself, visible in the form
                  fieldsContainer[fieldName] = o.dv;
                },
              );
              periodsConfigs.push(cloneDeep(fieldsContainer));
            });
            tempModel[mainFieldName] = cloneDeep(periodsConfigs);
          } else {
            tempModel[mainFieldName] = field.dv;
          }
        },
      );
      this.wizardDefaultModel = tempModel;

      if (buildModel) {
        this.buildWizardModel();
      }
    },
    forceChangeStep(): void {
      if (!this.$route.query.forcedStep) {
        return;
      }

      switch (this.$route.query.forcedStep) {
        case PriceWizardStep.PRICES:
          this.wizardStructure.currentStep = PriceWizardStep.PRICES;
          break;

        default:
          this.$spiagge.toast.error(
            this.$t('priceListsWizardView.toast.forcedStepError'),
          );
          break;
      }

      if (
        this.$route.query.forcedMode === 'edit'
        && this.wizardInstance
        && this.wizardInstance.lockUserId === this.user.id
        && this.wizardInstance.status !== PriceConfigStatus.DRAFT
        && this.wizardInstance.status !== PriceConfigStatus.READY
      ) {
        this.onEdit(this.wizardStructure.currentStep);
      }
    },
    buildWizardModel(): void {
      let modelKeys = Object.keys(this.wizardDefaultModel);
      // Empty period configs - just take defaults
      const instancePeriodLength =
        this.wizardInstance?.periodsConfigurations?.length ?? 0;
      const defaultPeriodLength =
        this.wizardDefaultModel?.periodsConfigurations?.length ?? 0;
      // If the length of the period config fields doesn't match we just reset them
      // const didPeriodsChange = instancePeriodLength !== defaultPeriodLength;
      const hasPeriodConfigs = instancePeriodLength > 0 && defaultPeriodLength > 0;
      const periodsConfigurations = [] as Array<PeriodConfiguration>;
      if (hasPeriodConfigs) {
        const mappedPeriods: Map<number, PeriodConfiguration> = new Map();
        this.wizardInstance.periodsConfigurations.map(
          (p: PeriodConfiguration) => {
            mappedPeriods.set(p.periodId, p);
          },
        );
        this.wizardDefaultModel.periodsConfigurations.forEach(
          (defaultPeriod: PeriodConfiguration) => {
            if (mappedPeriods.has(defaultPeriod.periodId)) {
              periodsConfigurations.push(
                mappedPeriods.get(defaultPeriod.periodId) as PeriodConfiguration,
              );
              return;
            }
            periodsConfigurations.push(defaultPeriod);
          },
        );
      }
      // Force default values
      modelKeys = modelKeys.filter((k: string) => k !== 'periodsConfigurations');
      const modelByInstance = _.pick(this.wizardInstance, modelKeys);
      this.wizardModel = Object.assign(
        this.wizardDefaultModel,
        modelByInstance,
      );
      // Update periods based on already compiled data
      if (hasPeriodConfigs) {
        this.wizardModel.periodsConfigurations = periodsConfigurations;
      }
      // Seasonal at last step - Can't really be in the last step without periods anyway
      // Fill up period configurations that are automatically generated by the back-end
      if (
        this.currentStep.value === PriceWizardStep.PRICES
        && defaultPeriodLength === 0
      ) {
        this.wizardModel.periodsConfigurations = this.wizardInstance.periodsConfigurations;
      }
    },
    onEditStep(step: PriceWizardStep): void {
      /**
       * Step navigation - only backwards
       */
      if (!this.currentStep) return;
      // Moving forward not allowed
      if (priceWizardUtil.compareStep(step, this.currentStep.value) >= 0) {
        return;
      }
      if (this.locked || this.isActive) {
        this.wizardStructure.currentStep = step;
      } else {
        this.onSaveDraft(step);
      }
    },
    async onProceed(): Promise<void> {
      if (this.locked || this.isActive) {
        this.wizardStructure.currentStep = this.currentStep.value;
      } else {
        await this.save(true);
      }
    },
    clearForcedStep(): void {
      const query = { ...this.$route.query };
      delete query.forcedStep;
      this.$router.replace({ query });
    },
    onSaveDraft(step: PriceWizardStep | null = null): void {
      this.clearForcedStep();
      this.save(false, step);
    },
    async save(
      validation: boolean,
      step: PriceWizardStep | null = null,
    ): Promise<void> {
      try {
        // build payload with validation
        const payload: PriceConfigModel = {
          ...this.wizardModel,
          ...{ shouldValidate: validation },
        };

        if (this.id) {
          // update OK, rebuild model? or...?
          if (step !== null) {
            payload.step = step;
          }
          this.wizardInstance = await priceConfigService.update(
            this.id,
            payload,
          );
          // Check if we're at the last step
          if (
              validation
              && this.currentStep.value === PriceWizardStep.PRICES
          ) {
            // If so and the validation was requested
            // We generate the price list and exit
            await this.onGenerate();
            return;
          }
          // rebuild wizard instance, structure, default model and model
          await this.buildWizard(true);
          // clear errors
          this.errors = {};
          // clear localstorage
          localStorageUtil.clear();
          this.clearForcedStep();
          // notification
          this.$spiagge.toast.success(
            this.$t('priceListsWizardView.toast.stepSaveSuccess'),
          );
          // refresh price lists (side navigation)
          await this.$store.dispatch('priceLists/getPriceLists');
        } else {
          // create OK, redirect on new url (cleaner)
          const createPayload = {
            ...payload,
            priceListType: this.priceListType,
          } as ApiPriceConfigCreatePayload;
          const res: PriceConfig = await priceConfigService.create(createPayload);
          this.$spiagge.toast.success(
            this.$t('priceListsWizardView.toast.wizardCreateSuccess'),
          );
          this.$spiagge.toast.success(
            this.$t('priceListsWizardView.toast.stepSaveSuccess'),
          );
          // clear localstorage
          localStorageUtil.clear();
          this.clearForcedStep();
          this.$store.commit('app/setProcessing', null);
          await this.$router.push(`/price-lists/wizard/${res.id}`);
        }
      } catch (error) {
        const errorCode: string = (error as AxiosError)?.response?.data.error;
        if (errorCode === 'i_validation_001') {
          this.errors = (
            error as AxiosError
          )?.response?.data.result.validationErrors;
          this.$spiagge.toast.error(
            this.$t('priceListsWizardView.toast.validationError'),
          );
        } else {
          this.$spiagge.toast.error(
            this.$t('priceListsWizardView.toast.stepSaveError'),
          );
        }
      } finally {
        this.$store.commit('app/setProcessing', null);
      }
    },
    async onDelete(): Promise<void> {
      /**
       * Delete wizard (confirm)
       */
      this.$confirm.require({
        message: this.$t('priceListsWizardView.confirm.deleteMessage'),
        header: this.$t('priceListsWizardView.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('common.yes'),
        accept: async () => {
          try {
            await priceConfigService.delete(this.id);
            localStorageUtil.clear();
            this.confirmExit = true;
            this.$spiagge.toast.success(
              this.$t('priceListsWizardView.toast.deleteSuccess'),
            );
            this.confirmExit = true;
            this.$store.commit('app/setProcessing', null);
            await this.$router.push('/price-lists');
          } catch (error) {
            this.$store.commit('app/setProcessing', null);
            this.$spiagge.toast.error(
              this.$t('priceListsWizardView.toast.deleteError'),
            );
          }
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          this.$store.commit('app/setProcessing', null);
        },
      });
    },
    async onGenerate(): Promise<void> {
      /**
       * Generate price list
       */
      try {
        await priceConfigService.generate(this.id);
        localStorageUtil.clear();
        this.$spiagge.toast.success(
          this.$t('priceListsWizardView.toast.createSuccess'),
        );
        this.confirmExit = true;
        this.$store.commit('app/setProcessing', null);
        await this.$router.push(
          `/price-lists/${this.wizardInstance.priceListId}`,
        );
      } catch (error) {
        this.$store.commit('app/setProcessing', null);
        this.$spiagge.toast.error(
          this.$t('priceListsWizardView.toast.createError'),
        );
      }
    },
    async onEdit(forcedStep: string | null): Promise<void> {
      /**
       * Create new copy
       */
      try {
        const priceConfigToEdit = await priceConfigService.edit(
          this.id,
          forcedStep ? this.currentStep?.value : null,
        );
        localStorageUtil.clear();
        this.confirmExit = true;
        this.$store.commit('app/setProcessing', null);
        const params = forcedStep === null ? '' : `?forcedStep=${forcedStep}`;
        await this.$router.push(
          `/price-lists/wizard/${priceConfigToEdit.id}${params}`,
        );
      } catch (error) {
        this.$store.commit('app/setProcessing', null);
        this.$spiagge.toast.error(
          this.$t('priceListsWizardView.toast.editError'),
        );
      }
    },
    onTakeOwnership(): void {
      /**
       * Take ownership wizard (confirm)
       */
      this.$confirm.require({
        message: this.$t('priceListsWizardView.confirm.takeControlMessage'),
        header: this.$t('priceListsWizardView.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('common.yes'),
        accept: async () => {
          try {
            await priceConfigService.takeOwnership(this.id);
            localStorageUtil.clear();
            this.$spiagge.toast.success(
              this.$t('priceListsWizardView.toast.takeControlSuccess'),
            );
            this.$store.commit('app/setProcessing', null);
            setTimeout(() => {
              this.$router.go(0);
            }, 200);
          } catch (error) {
            this.$store.commit('app/setProcessing', null);
            this.$spiagge.toast.error(
              this.$t('priceListsWizardView.toast.takeControlError'),
            );
          }
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          this.$store.commit('app/setProcessing', null);
        },
      });
    },
    onBackToPriceList(): void {
      this.confirmExit = true;
      this.$router.push(`/price-lists/${this.wizardInstance.priceListId}`);
    },
    handleExit(to: RouteLocationNormalized): boolean {
      /**
       * Exit handler
       */
      if (!this.confirmExit) {
        this.$confirm.require({
          message: this.$t('priceListsWizardView.confirm.exitMessage'),
          header: this.$t('priceListsWizardView.confirm.title'),
          icon: 'pi pi-exclamation-triangle',
          acceptLabel: this.$t('common.yes'),
          accept: () => {
            localStorageUtil.clear();
            this.confirmExit = true;
            this.$router.push(to);
          },
          rejectLabel: this.$t('common.no'),
          reject: () => {
            // do nothing
          },
        });
        return false;
      }
      return true;
    },
    getProceedLabel(): string {
      // First step moving to periods
      if (this.currentStep.value === PriceWizardStep.BASIC) {
        if (this.steps.length > 2) {
          return this.$t('priceListsWizardView.action.proceed.actionLabel') ?? '';
        }
        return this.$t('priceListsWizardView.action.goToPrices.actionLabel') ?? '';
      }
      // First step moving to prices
      if (this.currentStep.value === PriceWizardStep.PERIODS) {
        return this.$t('priceListsWizardView.action.goToPrices.actionLabel') ?? '';
      }
      // Prices step
      if (this.currentStep.value === PriceWizardStep.PRICES) {
        return this.$t('priceListsWizardView.action.generate.actionLabel') ?? '';
      }
      return '';
    },
    getProceedLoadingLabel(): string {
      // First step moving to periods
      if (this.currentStep.value === PriceWizardStep.BASIC) {
        if (this.steps.length > 2) {
          return this.$t('priceListsWizardView.action.proceed.processingLabel') ?? '';
        }
        return this.$t('priceListsWizardView.action.goToPrices.processingLabel') ?? '';
      }
      // First step moving to prices
      if (this.currentStep.value === PriceWizardStep.PERIODS) {
        return this.$t('priceListsWizardView.action.goToPrices.processingLabel') ?? '';
      }
      // Prices step
      if (this.currentStep.value === PriceWizardStep.PRICES) {
        return this.$t('priceListsWizardView.action.generate.processingLabel') ?? '';
      }
      return '';
    },
  },
  computed: {
    ...mapState('session', ['user']),
    ...mapState('app', ['loading', 'processing']),
    progressBarClass(): string {
      const totalSteps = this.wizardSteps.length;
      let res = 'step-';
      res += priceWizardUtil.convertStepToString(
        this.currentStep.value,
        totalSteps,
      );
      res += '-on-';
      const totalDesc = totalSteps === 2 ? 'two' : 'three';
      res += totalDesc;
      return res;
    },
    isEdit(): boolean {
      /**
       * Is edit mode
       */
      return this.id > 0;
    },
    isActive(): boolean {
      /**
       * Is active
       */
      return (
        this.wizardInstance &&
        this.wizardInstance.status === PriceConfigStatus.ACTIVE
      );
    },
    oldTitle(): string {
      /**
       * Page title
       */
      let title = this.$t('priceListsWizardView.title.new');
      if (this.isEdit) {
        title = this.$t('priceListsWizardView.title.edit');
      }
      if (this.isActive) {
        title = this.$t('priceListsWizardView.title.settings');
      }
      return title;
    },
    steps(): Array<Step> {
      return this.wizardSteps.map(
        (step: PriceWizardStep) =>
          priceWizardUtil.initStepFromEnum(step, this.wizardSteps.length),
      );
    },
    title(): string {
      if (!this.wizardModel?.name || this.wizardModel?.name === '') {
        return this.$t('priceListsWizardView.title.new');
      }
      return this.wizardModel.name;
    },
    currentStep(): Step {
      /**
       * Current step
       */
      if (this.wizardStructure && this.wizardStructure.currentStep) {
        return priceWizardUtil.initStepFromEnum(
          this.wizardStructure.currentStep,
          this.wizardSteps.length,
        );
      }
      const basicStep = PriceWizardStep.BASIC;
      return {
        value: basicStep,
        label: priceWizardUtil.getStepLabel(basicStep),
        number: priceWizardUtil.getStepNumber(
          basicStep,
          this.wizardSteps.length,
        ),
        action: () => {
          //
        },
      } as Step;
    },
    fields(): Array<Field> {
      /**
       * Fields to display (step based)
       */
      if (!this.currentStep || !this.wizardStructure) return [];
      return this.wizardStructure[
        priceWizardUtil.getPropertyName(this.currentStep.value)
      ] as Array<Field>;
    },
    canProceed(): boolean {
      /**
       * If locked, can proceed only if wizard's step is greater than the currently selected step
       */
      if (this.locked || this.isActive) {
        return priceWizardUtil.compareStep(
          this.currentStep.value,
          this.wizardInstance.step,
        ) < 0;
      }
      // can always proceed if not locked
      return true;
    },
  },
  beforeRouteLeave(to): boolean {
    /**
     * On router leave run the handler
     */
    return this.handleExit(to);
  },
  beforeUnmount(): void {
    /**
     * Remove auto save (if present)
     */
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval);
    }
  },
});
