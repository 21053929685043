
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { DateTime } from 'luxon';
import orderBy from 'lodash/orderBy';
import { cloneDeep } from 'lodash';
import { Reservation, ReservationType } from '@/models/reservation';
import {
  ApiReservationCreateAbsencePayload,
  ApiReservationUpdateStepPayload,
} from '@/models/api';
import { LICENSE_FIVE_BEACH } from '@/models/license';
import { Day, DAYS_DATA } from '@/models';

enum CreationType {
  DISPLACEMENT = 'displacement',
  ABSENCE = 'absence',
}

enum UpdateType {
  START_DATE = 'start-date',
  END_DATE = 'end-date',
}

interface CreationModel {
  type: CreationType;
  startDate: DateTime;
  endDate: DateTime;
  recursive: boolean;
  days: Array<Day>;
}

interface UpdateModel {
  id: number;
  type: UpdateType;
  date: DateTime;
  minDate: Date | null;
  maxDate: Date | null;
}

export default defineComponent({
  name: 'ReservationDisplacementsAbsences',
  data() {
    return {
      dialog: false,
      creationType: {
        absence: CreationType.ABSENCE,
        displacement: CreationType.DISPLACEMENT,
      },
      creationModel: {
        type: CreationType.DISPLACEMENT,
        startDate: null as unknown as DateTime,
        endDate: null as unknown as DateTime,
        recursive: false,
        days: [],
      } as CreationModel,
      updateModel: {
        id: 0,
        type: UpdateType.START_DATE,
        date: null as unknown as DateTime,
        minDate: new Date(),
        maxDate: new Date(),
      } as UpdateModel,
      updateType: cloneDeep(UpdateType),
      reservationType: cloneDeep(ReservationType),
      // options
      daysOptions: cloneDeep(DAYS_DATA),
    };
  },
  methods: {
    onDialog(type: CreationType): void {
      /**
       * Open dialog and init displacement form
       */
      this.initModel(type);
      this.dialog = true;
    },
    initModel(type: CreationType): void {
      /**
       * Init displacement form
       */
      const dateToday = DateTime.now().startOf('day');
      const defaultDate =
        dateToday > this.startDate && dateToday <= this.endDate
          ? dateToday
          : this.startDate;
      this.creationModel = {
        startDate: defaultDate,
        endDate: defaultDate,
        type,
        recursive: false,
        days: [],
      };
    },
    onModelStartDateSelect(): void {
      /**
       * Adjust end date on start date change
       */
      if (this.creationModel.startDate > this.creationModel.endDate) {
        this.creationModel.endDate = this.creationModel.startDate;
      }
    },
    async onSubmit(): Promise<void> {
      /**
       * Submit the form
       */

      // DISPLACEMENT
      if (this.creationModel.type === CreationType.DISPLACEMENT) {
        /** If moving we have to find the step based on dates */
        let step = null;
        if (this.type === ReservationType.MOVING) {
          const displacements = this.displacements.sort(
            (a: Reservation, b: Reservation) => b.startDate - a.startDate,
          );
          // eslint-disable-next-line no-restricted-syntax
          for (const s of displacements as Array<Reservation>) {
            if (s.startDate <= this.creationModel.startDate.toSeconds()) {
              step = s;
              break;
            }
          }
        }
        const reservationId = step?.id ?? this.id;
        const spotType = step?.spotType ?? this.spot.type;
        const spotName = step?.spotName ?? this.spot.name;
        this.$router.push(
          `/map?mode=4&startDate=${this.creationModel.startDate.toSeconds()}&endDate=${this.creationModel.endDate.toSeconds()}&spotType=${spotType}&spotName=${spotName}&reservationId=${reservationId}`,
        );
      }
      // ABSENCE
      if (this.creationModel.type === CreationType.ABSENCE) {
        const payload: ApiReservationCreateAbsencePayload = {
          startDate: this.creationModel.startDate.toSeconds(),
          endDate: this.creationModel.endDate.toSeconds(),
        };
        if (this.creationModel.recursive) {
          payload.weekDays = this.creationModel.days;
        }
        try {
          const absence: Reservation | null = await this.$store.dispatch(
            'reservation/createAbsence',
            payload,
          );
          if (!absence) {
            this.$spiagge.toast.warn(
              this.$t('reservationDisplacementsAbsences.toast.absenceError'),
            );
          } else {
            this.$spiagge.toast.success(
              this.$t(
                'reservationDisplacementsAbsences.toast.absenceCreateSuccess',
              ),
            );
            this.dialog = false;
          }
        } catch (e) {
          this.$spiagge.toast.error(
            this.$t(
              'reservationDisplacementsAbsences.toast.absenceCreateError',
            ),
          );
        }
      }
    },
    async onAbsenceRemove(absence: Reservation): Promise<void> {
      /**
       * Remove an absence
       */
      this.$confirm.require({
        header: this.$t('reservationDisplacementsAbsences.confirm.deleteTitle'),
        message: this.$t(
          'reservationDisplacementsAbsences.confirm.absenceDeleteMessage',
        ),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('button.delete'),
        acceptClass: 'p-button-danger',
        rejectLabel: this.$t('button.cancel'),
        rejectClass: 'p-button-danger p-button-outlined',
        accept: async () => {
          try {
            await this.$store.dispatch('reservation/removeAbsence', absence.id);
            // Need to refresh the reservation to have the check-in-out flags updated
            await this.$store.dispatch('reservation/refresh', this.id);
            this.$spiagge.toast.success(
              this.$t(
                'reservationDisplacementsAbsences.toast.absenceDeleteSuccess',
              ),
            );
          } catch (e) {
            this.$spiagge.toast.error(
              this.$t(
                'reservationDisplacementsAbsences.toast.absenceDeleteError',
              ),
            );
          }
        },
      });
    },
    async onDisplacementRemove(displacement: Reservation): Promise<void> {
      /**
       * Remove a displacement
       */
      this.$confirm.require({
        header: this.$t('reservationDisplacementsAbsences.confirm.deleteTitle'),
        message: this.$t(
          'reservationDisplacementsAbsences.confirm.displacementDeleteMessage',
        ),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('button.delete'),
        acceptClass: 'p-button-danger',
        rejectLabel: this.$t('button.cancel'),
        rejectClass: 'p-button-danger p-button-outlined',
        accept: async () => {
          try {
            this.$store.dispatch(
              'reservation/removeDisplacement',
              displacement.id,
            );
            this.$spiagge.toast.success(
              this.$t(
                'reservationDisplacementsAbsences.toast.displacementDeleteSuccess',
              ),
            );
          } catch (e) {
            this.$spiagge.toast.error(
              this.$t(
                'reservationDisplacementsAbsences.toast.displacementDeleteError',
              ),
            );
          }
        },
      });
    },
    onUpdateDisplacement(
      event: MouseEvent,
      displacement: Reservation,
      updateType: UpdateType,
    ) {
      this.updateModel.id = displacement.id;
      if (updateType === UpdateType.START_DATE) {
        this.updateModel.date = DateTime.fromSeconds(
          displacement.startDate,
        ).startOf('day');
        this.updateModel.minDate = null;
        this.updateModel.maxDate = DateTime.fromSeconds(
          displacement.endDate,
        ).toJSDate();
      } else {
        this.updateModel.date = DateTime.fromSeconds(
          displacement.endDate,
        ).startOf('day');
        this.updateModel.minDate = DateTime.fromSeconds(
          displacement.startDate,
        ).toJSDate();
        this.updateModel.maxDate = null;
      }
      this.updateModel.type = updateType;
      (this.$refs as unknown as any).op.show(event);
    },
    async updateDisplacement(): Promise<void> {
      const payload = {
        id: this.updateModel.id,
      } as ApiReservationUpdateStepPayload;
      if (this.updateModel.type === UpdateType.START_DATE) {
        payload.startDate = this.updateModel.date.toSeconds();
      } else {
        payload.endDate = this.updateModel.date.toSeconds();
      }
      try {
        await this.$store.dispatch('reservation/updateDisplacement', payload);
        this.$spiagge.toast.success(
          this.$t(
            'reservationDisplacementsAbsences.toast.displacementEditSuccess',
          ),
        );
        (this.$refs as unknown as any).op.hide();
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t(
            'reservationDisplacementsAbsences.toast.displacementEditError',
          ),
        );
      }
    },
  },
  computed: {
    ...mapGetters('reservation', [
      'id',
      'type',
      'isAddition',
      'isBoat',
      'isPlayField',
    ]),
    ...mapState('reservation', [
      'spot',
      'absences',
      'displacements',
      'startDate',
      'endDate',
      'voucherId',
    ]),
    ...mapState('session', ['license']),
    show(): boolean {
      return !this.isAddition;
    },
    title(): string {
      return this.creationModel.type === CreationType.DISPLACEMENT
        ? this.$t('reservationDisplacementsAbsences.title.displacement')
        : this.$t('reservationDisplacementsAbsences.title.absence');
    },
    cta(): string {
      return this.creationModel.type === CreationType.DISPLACEMENT
        ? this.$t('reservationDisplacementsAbsences.cta.displacement')
        : this.$t('reservationDisplacementsAbsences.cta.absence');
    },
    startDateJS(): Date {
      return this.startDate.toJSDate();
    },
    endDateJS(): Date {
      return this.endDate.toJSDate();
    },
    modelStartDate: {
      get(): Date {
        return this.creationModel.startDate.toJSDate();
      },
      set(d: Date): void {
        const date = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
        this.creationModel.startDate = DateTime.fromFormat(date, 'd-M-yyyy');
      },
    },
    modelEndDate: {
      get(): Date {
        return this.creationModel.endDate.toJSDate();
      },
      set(d: Date): void {
        const date = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
        this.creationModel.endDate = DateTime.fromFormat(date, 'd-M-yyyy');
      },
    },
    modelUpdateDate: {
      get(): Date {
        return this.updateModel.date.toJSDate();
      },
      set(d: Date): void {
        const date = `${d.getDate()}-${d.getMonth() + 1}-${d.getFullYear()}`;
        this.updateModel.date = DateTime.fromFormat(date, 'd-M-yyyy');
      },
    },
    list(): Array<Reservation> {
      return orderBy(
        [...this.absences, ...this.displacements],
        ['startDate'],
        ['asc'],
      );
    },
    canUpdateDisplacement(): boolean {
      /**
       * Disabled update if voucher applied and five beach
       */
      let canUpdateDisplacement = true;
      if (this.voucherId && LICENSE_FIVE_BEACH.includes(this.license.license)) {
        canUpdateDisplacement = false;
      }
      return canUpdateDisplacement;
    },
    days(): number {
      return (
        this.creationModel.endDate.diff(this.creationModel.startDate, ['days'])
          .days + 1
      );
    },
  },
});
