
import { defineComponent } from 'vue';
import Menu from '@/components/Menu.vue';

export default defineComponent({
  name: 'LayoutAsideContent',
  components: { Menu },
  props: {
    menu: {
      type: Object,
      required: false,
    },
    showPadding: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
});
