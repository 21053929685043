import { StatusItem, StatusItemWithId } from '@/models/aplos';

const BASE_PATH = 'https://a-plos.app/api/v1/spiagge';

function getStatus(opening: StatusItem['opening']): StatusItemWithId['status'] {
  if (opening === null) {
    return 'idle';
  }
  return opening ? 'opening' : 'closing';
}

export const aplosService = {
  async load(ids: number[]): Promise<StatusItemWithId[]> {
    const resp = await fetch(`${BASE_PATH}/status`, {
      method: 'POST',
      body: JSON.stringify(ids),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (!resp.ok) {
      throw new Error(`Invalid response: ${resp.status} - ${resp.statusText}`);
    }

    const data = (await resp.json()) as Record<string, StatusItem>;
    return Object.entries(data).reduce<StatusItemWithId[]>((acc, item) => {
      const [key, value] = item;
      return [
        ...acc,
        {
          ...value,
          id: Number(key),
          status: getStatus(value.opening),
        },
      ];
    }, []);
  },

  async open(ids: number[]): Promise<boolean> {
    try {
      const resp = await fetch(`${BASE_PATH}/open`, {
        method: 'POST',
        body: JSON.stringify(ids),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!resp.ok) {
        // TODO
        return false;
      }

      const data = await resp.json();
      if (typeof data === 'boolean') {
        return data;
      }
    } catch {
      // TODO
    }

    return false;
  },

  async close(ids: number[]): Promise<boolean> {
    try {
      const resp = await fetch(`${BASE_PATH}/close`, {
        method: 'POST',
        body: JSON.stringify(ids),
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!resp.ok) {
        // TODO
        return false;
      }

      const data = await resp.json();
      if (typeof data === 'boolean') {
        return data;
      }
    } catch {
      // TODO
    }

    return false;
  },
};
