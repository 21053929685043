
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { License, LicenseType } from '@/models/license';
import { MenuItem } from '@/models/menu';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';

export default defineComponent({
  name: 'SettingsNavigation',
  // data() {
  //   return {},
  // },
  methods: {
    toggle(event: any) {
      (this.$refs as any).menu.toggle(event);
    },
  },
  computed: {
    ...mapState('app', ['windowWidth', 'breakpoints']),
    ...mapState('session', ['license']),
    windowHeight(): number {
      return this.$store.getters['app/windowHeight'];
    },
    menuHeight(): string {
      return `${this.windowHeight - 64}px`;
    },
    loading(): boolean {
      return this.$store.getters['app/loading'];
    },
    license(): License {
      return this.$store.getters['session/license'];
    },
    isDemo(): boolean {
      return this.license.licenseType === LicenseType.DEMO;
    },
    mapEditorOpen(): boolean {
      return this.$route.name === 'MapEditorView';
    },
    menuItems(): Array<MenuItem> {
      const menuItems: Array<MenuItem> = [
        // Main section
        {
          label: `${this.$t('settingsNavigation.mainSection').toUpperCase()}`,
          visible: permissionsUtil.isActionPermissionAllowed(
            FEATURE_PERMISSION_CONFIG.settings,
            FEATURE_PERMISSION_ACTION_CONFIG.settings.PAGE_ACCESS,
          ),
          icon: 'spi-settings',
          items: [
            // General
            {
              label: this.$t('settingsNavigation.general'),
              to: '/settings/general',
            },
            // Contract
            {
              label: this.$t('settingsNavigation.contract'),
              to: '/settings/contract',
              // visible: this.$store.getters['session/roleUser'] === 'superuser',
            },
            // Beach settings
            {
              label: this.$t('settingsNavigation.beach'),
              to: '/settings/beach',
            },
            // Sectors
            {
              label: this.$t('settingsNavigation.sectors'),
              to: '/settings/sectors',
            },
            // Price list
            {
              label: this.$t('settingsNavigation.priceList'),
              to: '/settings/price-list',
            },
            // Printers
            {
              label: this.$t('settingsNavigation.printers'),
              to: '/settings/printers',
            },
            // POS terminals
            {
              label: this.$t('settingsNavigation.posTerminals'),
              to: '/settings/pos-terminals',
              visible:
                this.license.stripePosTerminalEnabled &&
                permissionsUtil.isActionPermissionAllowed(
                  FEATURE_PERMISSION_CONFIG.settings,
                  FEATURE_PERMISSION_ACTION_CONFIG.settings
                    .TERMINAL_POS_SETTINGS_PAGE_ACCESS,
                ),
            },
            // Webtic terminals
            {
              label: this.$t('settingsNavigation.webticTerminals'),
              to: '/settings/webtic-terminals',
              visible: this.license.webticId !== null,
            },
            // Tickets
            {
              label: this.$t('settingsNavigation.ticket'),
              to: '/settings/service-groups',
            },
            // Extra services
            {
              label: this.$t('settingsNavigation.extraService'),
              to: '/settings/extra-service',
            },
            // Booking
            {
              label: this.$t('settingsNavigation.booking'),
              to: '/settings/booking',
            },
            // Weather
            {
              label: this.$t('settingsNavigation.weather'),
              to: '/settings/weather',
              visible: false,
            },
            // Card types
            {
              label: this.$t('settingsNavigation.cardTypes'),
              to: '/settings/card-types',
            },
            // Warehouse
            {
              label: this.$t('settingsNavigation.warehouse'),
              to: '/settings/warehouse',
            },
          ] as Array<MenuItem>,
        },
        {
          label: this.$t('settingsNavigation.mapSection').toUpperCase(),
          icon: 'spi-map',
          items: [
            {
              label: this.$t('settingsNavigation.beachEditor'),
              icon: 'pi pi-fw pi-cog',
              to: '/settings/map-editor',
            },
          ],
        },
        {
          label: this.$t('settingsNavigation.adminSection').toUpperCase(),
          icon: 'spi-user',
          visible:
            permissionsUtil.isActionPermissionAllowed(
              FEATURE_PERMISSION_CONFIG.settings,
              FEATURE_PERMISSION_ACTION_CONFIG.settings
                .ROLE_SETTINGS_PAGE_ACCESS,
            ) ||
            permissionsUtil.isActionPermissionAllowed(
              FEATURE_PERMISSION_CONFIG.settings,
              FEATURE_PERMISSION_ACTION_CONFIG.settings
                .ADMIN_SETTINGS_PAGE_ACCESS,
            ),
          items: [
            {
              label: this.$t('settingsNavigation.admins'),
              to: '/settings/admins',
              visible: permissionsUtil.isActionPermissionAllowed(
                FEATURE_PERMISSION_CONFIG.settings,
                FEATURE_PERMISSION_ACTION_CONFIG.settings
                  .ADMIN_SETTINGS_PAGE_ACCESS,
              ),
            },
            {
              label: this.$t('settingsNavigation.roles'),
              to: '/settings/roles',
              visible: permissionsUtil.isActionPermissionAllowed(
                FEATURE_PERMISSION_CONFIG.settings,
                FEATURE_PERMISSION_ACTION_CONFIG.settings
                  .ROLE_SETTINGS_PAGE_ACCESS,
              ),
            },
          ],
        },
        {
          label: this.$t('settingsNavigation.databaseSection').toUpperCase(),
          visible: permissionsUtil.isActionPermissionAllowed(
            FEATURE_PERMISSION_CONFIG.settings,
            FEATURE_PERMISSION_ACTION_CONFIG.settings
              .DATABASE_SETTINGS_IMPORT_EXPORT,
          ),
          icon: 'spi-database',
          items: [
            {
              label: this.$t('settingsNavigation.database'),
              to: '/settings/database',
            },
          ],
        },
        {
          label: this.$t('settingsNavigation.infoSection').toUpperCase(),
          icon: 'spi-information',
          items: [
            {
              label: this.$t('settingsNavigation.version'),
              to: '/settings/version',
            },
          ],
        },
      ];

      // REVENUE MANAGMENT SETTINGS
      /* if (
        this.license.featureFlags &&
        this.license.featureFlags.revenueManagementEnabled &&
        menuItems[0].items
      ) {
        menuItems[0].items.push({
          label: 'Revenue management',
          to: '/settings/revenue-management',
        });
      } */

      return menuItems;
    },
  },
});
