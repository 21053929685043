import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5e7c27e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pos-terminal-payment" }
const _hoisted_2 = { class: "p-field model-field" }
const _hoisted_3 = { for: "pos-terminal" }
const _hoisted_4 = { class: "option" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { class: "identifier" }
const _hoisted_7 = {
  key: 0,
  class: "error-message"
}
const _hoisted_8 = {
  ref: "submit",
  type: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Field = _resolveComponent("Field")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "slot-activator",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onOnsiteEmitTicket && _ctx.onOnsiteEmitTicket(...args)))
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createVNode(_component_Dialog, {
      id: "webtic-reflector-picker-dialog",
      class: "dialog",
      modal: true,
      visible: _ctx.showWebticTerminalPickerDialog,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showWebticTerminalPickerDialog) = $event)),
      onHide: _ctx.onAbort
    }, {
      header: _withCtx(() => [
        _createElementVNode("h6", null, _toDisplayString(_ctx.$t('webticTerminalPayment.selectTerminalTitle')), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          class: "p-button-outlined",
          label: _ctx.$t('button.cancel'),
          icon: "spi-times",
          onClick: _ctx.onAbort
        }, null, 8, ["label", "onClick"]),
        _createVNode(_component_Button, {
          label: _ctx.$t('button.proceed'),
          icon: "spi-check",
          onClick: _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$refs.submit as HTMLButtonElement).click()))
        }, null, 8, ["label"])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_Form, {
          initialValues: _ctx.webticTerminalPickerFormModel,
          validationSchema: _ctx.webticTerminalPickerValidationSchema,
          onSubmit: _ctx.onWebticTerminalPick
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t('webticTerminalPayment.webticTerminal')), 1),
              _createVNode(_component_Field, {
                name: "webticTerminal",
                modelValue: _ctx.webticTerminalPickerFormModel.webticTerminal,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.webticTerminalPickerFormModel.webticTerminal) = $event))
              }, {
                default: _withCtx(({ handleChange, meta, errorMessage, value }) => [
                  _createVNode(_component_Dropdown, {
                    "onUpdate:modelValue": handleChange,
                    modelValue: value,
                    id: "pos-terminal",
                    options: _ctx.webticTerminalOptions,
                    class: _normalizeClass(!meta.valid && meta.touched ? 'p-invalid' : ''),
                    optionGroupLabel: "label",
                    optionLabel: "name",
                    optionGroupChildren: "terminals",
                    placeholder: 
                _ctx.$t('webticTerminalPayment.selectTerminalPlaceholder')
              ,
                    panelClass: "pos-terminals-dropdown-panel"
                  }, {
                    option: _withCtx((slotProps) => [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(slotProps.option.name), 1),
                        _createElementVNode("span", _hoisted_6, _toDisplayString(slotProps.option.posIdentifier), 1)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["onUpdate:modelValue", "modelValue", "options", "class", "placeholder"]),
                  errorMessage
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(errorMessage), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _withDirectives(_createElementVNode("button", _hoisted_8, null, 512), [
              [_vShow, false]
            ])
          ]),
          _: 1
        }, 8, ["initialValues", "validationSchema", "onSubmit"])
      ]),
      _: 1
    }, 8, ["visible", "onHide"])
  ]))
}