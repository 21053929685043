
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import { cloneDeep } from 'lodash';
import { DropdownOption } from '@/models';
import { ApiCustomerExportPayload } from '@/models/api';
import fileUtil from '@/utils/fileUtil';
import customerService from '@/services/customerService';

interface CsvFilter {
  filterByDate: string;
  filterByContact: string;
  filterByBooking: string;
}

const CSV_FILTER_MODEL_DEFAULT = {
  filterByDate: 'all',
  filterByContact: 'all',
  filterByBooking: 'all',
} as CsvFilter;
export default defineComponent({
  name: 'ExportView',
  data() {
    return {
      filtering: cloneDeep(CSV_FILTER_MODEL_DEFAULT),
      csvOptions: [
        {
          name: this.$t('exportView.csv'),
          image: require('@/assets/iconset/csv.svg'),
        },
      ],
      dateFilter: [{ label: this.$t('exportView.always'), value: 'all' }],
      contactsFilter: [
        {
          label: this.$t('exportView.all'),
          value: 'all',
        },
        { label: this.$t('exportView.withMail'), value: 'email' },
        { label: this.$t('exportView.withPhone'), value: 'phone' },
      ] as Array<DropdownOption>,
      bookingFilter: [
        {
          label: this.$t('exportView.all'),
          value: 'all',
        },
        { label: this.$t('exportView.seasonal'), value: 'seasonal' },
        { label: this.$t('exportView.day'), value: 'day' },
        { label: this.$t('exportView.notDaily'), value: 'not-daily' },
        { label: this.$t('exportView.long'), value: 'long' },
      ] as Array<DropdownOption>,
    };
  },
  methods: {
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    async onExportCSV(): Promise<void> {
      /** Init payload */
      const payload: ApiCustomerExportPayload = {};
      /** Parse Date filter */
      if (this.filtering.filterByDate !== 'all') {
        payload.from = DateTime.fromFormat(
          `01-01-${this.filtering.filterByDate} 00:00:00`,
          'dd-MM-yyyy HH:mm:ss',
        ).toSeconds();
        /** To the 31th of december */
        payload.to = DateTime.fromFormat(
          `31-12-${this.filtering.filterByDate} 00:00:00`,
          'dd-MM-yyyy HH:mm:ss',
        ).toSeconds();
      }
      /** Parse email - phone filters */
      switch (this.filtering.filterByContact) {
        case 'phone':
          payload.withPhone = true;
          break;
        case 'email':
          payload.withEmail = true;
          break;
        default:
      }
      /** Parse Reservation filters */
      switch (this.filtering.filterByBooking) {
        case 'seasonal':
          payload.seasonal = true;
          break;
        case 'day':
          payload.daily = true;
          break;
        case 'not-daily':
          payload.daily = false;
          break;
        case 'long':
          payload.subscriptions = true;
          break;
        default:
      }
      const res = await customerService.exportCustomers(payload);
      try {
        fileUtil.download(
          res,
          `Export-Clienti-${Math.round(DateTime.now().toSeconds())}.csv`,
        );
        this.$spiagge.toast.success(this.$t('exportView.toast.success'));
      } catch (e) {
        this.$spiagge.toast.error(this.$t('exportView.toast.error'));
      }
    },
    /** Old placeholder */
    createFile(element: any) {
      let url;
      if (element.name === this.$t('exportView.csv')) {
        url = 'usersManager_exportCSV';
      }
      if (element.name === this.$t('exportView.mail')) {
        url = 'usersManager_exportCSV';
      }
      if (element.name === this.$t('exportView.gmail')) {
        url = 'contacts-export-gmail';
      }
      if (element.name === this.$t('exportView.mailchimp')) {
        url = 'usersManager_exportMailchimp';
      }

      url += `?year=${this.filtering.filterByDate}&contacts=${this.filtering.filterByContact}&type=${this.filtering.filterByBooking}`;
      window.open(url);

      // console.log(url);
    },
  },
  beforeMount() {
    const dt = DateTime.now().year;
    // eslint-disable-next-line no-plusplus
    for (let i = dt; i >= 2015; i--) {
      this.dateFilter.push({
        label: i.toString(),
        value: i.toString(),
      });
    }
  },
});
