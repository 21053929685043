import {
  Service,
  SERVICE_ICON_INFOS,
  ServiceIconInfo,
  SERVICE_TYPE_INFOS,
  ServiceTypeInfo,
} from '@/models/service';

export default {
  getServiceImage(service: Service): string {
    /**
     * Get image of a service
     */
    return (
      SERVICE_ICON_INFOS.find(
        (serviceIconInfo: ServiceIconInfo) =>
          serviceIconInfo.icon === service.icon,
      )?.image ?? ''
    );
  },
  getServiceLabel(service: Service): string {
    /**
     * Get label of a service
     */
    return (
      SERVICE_ICON_INFOS.find(
        (serviceIconInfo: ServiceIconInfo) =>
          serviceIconInfo.icon === service.icon,
      )?.label ?? ''
    );
  },
  getServiceType(service: Service): string {
    /**
     * Get type of a service
     */
    return (
      SERVICE_TYPE_INFOS.find(
        (serviceTypeInfo: ServiceTypeInfo) =>
          serviceTypeInfo.type === service.type,
      )?.label ?? ''
    );
  },
};
