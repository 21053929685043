/* eslint-disable import/no-cycle */
/**
 * Map store module
 */

import cloneDeep from 'lodash/cloneDeep';
import { DateTime } from 'luxon';
import { MutationTree, ActionTree, ActionContext, GetterTree } from 'vuex';
import { PanzoomObject } from '@panzoom/panzoom';
import { LocationQuery } from 'vue-router';
import { RootState, MapState, DEFAULT_MAP_STATE } from '@/models/store';
import {
  MapCalendar,
  MapData,
  MapElement,
  MapMode,
  MapPlanner,
  MapPlannerData,
  MapRefundConfirmation,
  MapShiftMode,
  MapShiftStep,
  MapSpotElement,
  MapView,
} from '@/models/map';
import mapService from '@/services/mapService';
import {
  ApiMapDataPayload,
  ApiMapPlannerPayload,
  ApiReservationUpdatePayload,
} from '@/models/api';
import { ServiceAvailability } from '@/models/service/serviceAvailability';
import {
  ServiceAvailabilityPayload,
} from '@/models/api/request/service/serviceAvailabilityPayload';
import serviceService from '@/services/serviceService';
import { ServiceInformation } from '@/models/service/serviceInformation';

type AppContext = ActionContext<MapState, RootState>;

const namespaced = true;

const state = (): MapState => cloneDeep(DEFAULT_MAP_STATE);

const getters: GetterTree<MapState, RootState> = {
  instance: (state) => state.instance,
  panning: (state) => state.panning,
  scale: (state) => state.scale,
  mode: (state) => state.mode,
  view: (state) => state.view,
  elements: (state) => state.elements,
  spots: (state) => state.spots,
  planner: (state) => state.planner,
  calendar: (state) => state.calendar,
  selected: (state) => state.selected,
  shift: (state) => state.shift,
  data: (state) => state.data,
  reservationPayload: (state) => state.reservationPayload,
  ticketAvailabilities: (state) => state.ticketAvailabilities,
  activeOverlay: (state) => state.activeOverlay,
  ticketDetailOpen: (state) => state.ticketDetailOpen,
} as GetterTree<MapState, RootState>;

const mutations: MutationTree<MapState> = {
  setInstance(state: MapState, instance: PanzoomObject) {
    state.instance = instance;
  },
  setPanning(state: MapState, panning: boolean) {
    state.panning = panning;
  },
  setScale(state: MapState, scale: number) {
    state.scale = scale;
  },
  setMode(state: MapState, mode: MapMode) {
    state.mode = mode;
    state.selected = [];
    state.shift = cloneDeep(DEFAULT_MAP_STATE.shift);
    state.planner.active = false;
  },
  setView(state: MapState, view: MapView) {
    state.view = view;
  },
  setElements(state: MapState, elements: Array<MapElement>) {
    state.elements = elements;
  },
  setSpots(state: MapState, spots: Array<MapElement>) {
    state.spots = spots;
  },
  setPlanner(state: MapState, planner: MapPlanner) {
    state.planner = cloneDeep(planner);
  },
  setPlannerActive(state: MapState, active: boolean) {
    state.planner.active = active;
  },
  setPlannerData(state: MapState, data: MapPlannerData) {
    state.planner.data = data;
  },
  setCalendar(state: MapState, calendar: MapCalendar) {
    state.calendar = calendar;
  },
  setSelected(state: MapState, selected: Array<MapElement>) {
    state.selected = selected;
  },
  setShiftFrom(state: MapState, from: MapSpotElement | null) {
    state.shift.from = from;
  },
  setShiftTo(state: MapState, to: MapSpotElement | null) {
    state.shift.to = to;
  },
  resetShift(state: MapState) {
    state.shift = cloneDeep(DEFAULT_MAP_STATE.shift);
  },
  setShiftStep(state: MapState, step: MapShiftStep) {
    state.shift.step = step;
  },
  setShiftMode(state: MapState, mode: MapShiftMode) {
    state.shift.mode = mode;
  },
  setShiftRange(state: MapState, range: [DateTime, DateTime]) {
    state.shift.range = range;
  },
  setData(state: MapState, data: MapData | null) {
    state.data = data;
  },
  setReservationPayload(
    state: MapState,
    reservationPayload: ApiReservationUpdatePayload,
  ) {
    state.reservationPayload = reservationPayload;
  },
  setRefundConfirmation(
    state: MapState,
    refundConfirmation: MapRefundConfirmation,
  ) {
    state.refundConfirmation = refundConfirmation;
  },
  reset(state: MapState) {
    Object.assign(state, cloneDeep(DEFAULT_MAP_STATE));
  },
  setTicketAvailabilities(state: MapState, availabilities: ServiceInformation) {
    state.ticketAvailabilities = availabilities;
  },
  setActiveOverlay(state: MapState, active: boolean) {
    state.activeOverlay = active;
  },
  setTicketDetailOpen(state: MapState, open: boolean) {
    state.ticketDetailOpen = open;
  },
} as MutationTree<MapState>;

const actions: ActionTree<MapState, RootState> = {
  async setData(
    context: AppContext,
    payload: ApiMapDataPayload,
  ): Promise<void> {
    const data = await mapService.data(payload);
    context.commit('setData', data);
  },
  async setTicketAvailabilities(
    context: AppContext,
    payload: ServiceAvailabilityPayload,
  ): Promise<void> {
    const data = await serviceService.availability(payload);
    context.commit('setTicketAvailabilities', data);
  },
  async setPlanner(
    context: AppContext,
    payload: ApiMapPlannerPayload,
  ): Promise<void> {
    const data: MapPlannerData = await mapService.planner(payload);
    context.commit('setPlanner', {
      active: true,
      data: {
        preview: data.preview || null,
        price: data.price || null,
        toPay: data.toPay || [],
      } as MapPlannerData,
      spot: payload.spot,
    } as MapPlanner);
  },
  buildReservationPayloadFromQuery(
    context: AppContext,
    query: LocationQuery,
  ): void {
    const reservationPayload = {} as ApiReservationUpdatePayload;
    if (query.b) {
      reservationPayload.beds = Number(query.b);
    }
    if (query.m) {
      reservationPayload.maxiBeds = Number(query.m);
    }
    if (query.d) {
      reservationPayload.deckChairs = Number(query.d);
    }
    if (query.c) {
      reservationPayload.chairs = Number(query.c);
    }
    if (query.name) {
      reservationPayload.firstName = query.name as string;
    }
    if (query.surname) {
      reservationPayload.lastName = query.surname as string;
    }
    if (query.email) {
      reservationPayload.email = decodeURIComponent(query.email as string);
    }
    if (query.h) {
      reservationPayload.hotel = query.h as string;
    }
    if (query.voucher) {
      reservationPayload.voucherId = Number(query.voucher);
    }
    if (query.ft) {
      reservationPayload.forcedTotal = Number(query.ft);
    }
    context.commit('setReservationPayload', reservationPayload);
  },
  async refresh(context: AppContext): Promise<void> {
    await context.dispatch('setData', {
      from: context.state.calendar[0].toSeconds(),
      to: (context.state.calendar[1] as DateTime).toSeconds(),
    } as ApiMapDataPayload);
  },
  async reset(context: AppContext) {
    context.commit('reset');
  },
} as ActionTree<MapState, RootState>;

export default {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
