
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-case-declarations */
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import { mapGetters, mapState } from 'vuex';
import { cloneDeep, debounce } from 'lodash';
import {
  EditDirection,
  HeaderDate,
  PlannerBuilder,
  PlannerElement,
  PlannerElementReservation,
  PlannerElementSegment,
  PlannerMode,
} from '@/models/planner';
import plannerUtil from '@/utils/plannerUtil';
import { MapSpotStatus } from '@/models/map';
import cookieUtil from '@/utils/cookieUtil';
import { colorSpecs } from '@/models/color';
import { AppAction } from '@/models/app';
import { SpotType } from '@/models/spot';
import spotUtil from '@/utils/spotUtil';
import {
  LICENSE_FIVE_BEACH,
  LicenseUmbrellaMultipleBookings,
} from '@/models/license';
import permissionsUtil from '@/utils/permissionsUtil';
import {
  FEATURE_PERMISSION_ACTION_CONFIG,
  FEATURE_PERMISSION_CONFIG,
} from '@/models/permissions';
import { Sector } from '@/models/sector';
import dateUtil from '@/utils/dateUtil';
import { CABIN_SECTOR_ID, PARKING_SECTOR_ID } from '@/constants/planner';
import { useLocalStorage } from '@/composables/useLocalStorage';
import { PlannerSectorState } from '@/models/localStorage';
import { usePlanner } from '@/composables/usePlanner';
import i18n from '@/i18n';
import PlannerBackground from './PlannerBackground.vue';
import PlannerTodayMarker from './PlannerTodayMarker.vue';
import PlannerSelectedDays from './PlannerSelectedDays.vue';

type CellWidthStyle = {
  minWidth: string;
  width: string;
};
type CellHeightStyle = {
  minHeight: string;
  height: string;
};

type PlannerSector = {
  open: boolean;
  spots: Array<PlannerElement>;
};

type PlannerPosition = {
  x: number;
  y: number;
};

type GridBarStyle = {
  top: string;
  left: string;
  width: string;
  height: string;
  backgroundColor: string;
  cursor?: string;
};

export default defineComponent({
  name: 'Planner',
  components: {
    PlannerBackground,
    PlannerTodayMarker,
    PlannerSelectedDays,
  },
  data() {
    return {
      holidays: new Set<string>([
        '01-01',
        '06-01',
        '25-04',
        '01-01',
        '02-06',
        '15-08',
        '01-11',
        '08-12',
        '25-12',
        '26-12',
      ]),
      gridTopOffset: 166,
      sectors: new Map<number, PlannerSector>(),
      mappedSectors: new Map<number, string>(),
      // Temp grid while editing
      hoveredDate: null as DateTime | null,
      // Position tracking
      position: { x: 0, y: 0 },
      onGridScrollDebounce: debounce(this.onGridScroll as () => void, 200),
      // Edit reservation hover
      hoveredReservation: null as PlannerElementReservation | null,
      selectedDays: [] as HeaderDate[],
      isFirstOpening: true,
      firstColumnWidth: 200,
    };
  },
  setup() {
    const { cellWidth, cellHeight, zoom } = usePlanner();

    const plannerSectorState = useLocalStorage<PlannerSectorState>(
      'planner-sector-state',
    );
    return {
      plannerSectorState,
      cellWidth,
      cellHeight,
      zoom,
    };
  },
  computed: {
    ...mapState('app', [
      'loading',
      'windowWidth',
      'windowHeight',
      'breakpoints',
    ]),
    ...mapState('session', {
      license: 'license',
      licenseSectors: 'sectors',
    }),
    ...mapState('planner', [
      'data',
      'year',
      'mode',
      'isLoading',
      'scrollToToday',
    ]),
    ...mapGetters('planner', [
      'isCabinSectorVisible',
      'isParkSectorVisible',
      'today',
    ]),
    builder(): PlannerBuilder {
      return this.$store.getters['planner/builder'];
    },
    firstColumCellStyle(): CellWidthStyle {
      return {
        width: `${this.firstColumnWidth}px`,
        minWidth: `${this.firstColumnWidth}px`,
      };
    },
    isExpanded(): boolean {
      return this.firstColumnWidth === 200;
    },
    isSmallZoom(): boolean {
      return this.zoom <= 50;
    },
    builderBars(): Array<PlannerElementReservation> {
      const toReturn = [] as Array<PlannerElementReservation>;
      for (
        let rIndex = 0;
        rIndex < this.builder.reservations.length;
        rIndex += 1
      ) {
        const reservation = cloneDeep(this.builder.reservations[rIndex]);
        // checks to edit lengths based on stretch
        if (
          this.mode === PlannerMode.EDIT &&
          reservation.id !== this.builder.reservationId
        ) {
          let deleted = false;
          let edited = false;
          const step = this.builder.reservations.find(
            (r) => r.id === this.builder.reservationId,
          );
          // eslint-disable-next-line no-continue
          if (!step) continue;
          // step deletes this one so flag and exit loop
          if (
            step.startDate <= reservation.startDate &&
            step.endDate >= reservation.endDate
          ) {
            deleted = true;
          } else if (
            step.startDate > reservation.startDate &&
            step.startDate <= reservation.endDate
          ) {
            reservation.endDate = step.startDate - 86400;
            edited = true;
          } else if (
            step.endDate < reservation.endDate &&
            step.endDate >= reservation.startDate
          ) {
            reservation.startDate = step.endDate + 86400;
            edited = true;
          }
          if (deleted) {
            // eslint-disable-next-line no-continue
            continue;
          }
          if (edited) {
            reservation.segments = plannerUtil.editCalcSegments(
              this.builder,
              reservation,
            );
          }
        }
        const previous = toReturn[toReturn.length - 1] ?? null;
        if (
          previous &&
          previous.spotType === reservation.spotType &&
          previous.spotName === reservation.spotName &&
          !reservation.segments[0].absence &&
          previous.segments[previous.segments.length - 1].endDate ===
            reservation.segments[0].startDate - 86400
        ) {
          // Change previous last segment
          previous.segments[previous.segments.length - 1].endDate =
            reservation.segments[0].endDate;
          // add other segments
          previous.segments.push(...reservation.segments.slice(1));
          // don't add to return
        } else {
          toReturn.push(reservation);
        }
      }
      return toReturn;
    },
    from(): DateTime {
      return DateTime.fromFormat(this.year, 'yyyy').startOf('year');
    },
    to(): DateTime {
      return DateTime.fromFormat(this.year, 'yyyy')
        .endOf('year')
        .startOf('day');
    },
    gridLeftOffest(): number {
      return this.isExpanded ? 279 : 100;
    },
    scrollAreaWidth(): string {
      const width = this.breakpoints.desktop
        ? this.windowWidth - 80
        : this.windowWidth;
      return `${width}px`;
    },
    scrollAreaHeight(): string {
      return `${this.windowHeight - 128}px`;
    },
    cellWidthStyle(): CellWidthStyle {
      return { minWidth: `${this.cellWidth}px`, width: `${this.cellWidth}px` };
    },
    cellHeightStyle(): CellHeightStyle {
      return {
        minHeight: `${this.cellHeight}px`,
        height: `${this.cellHeight}px`,
      };
    },
    // Compound measures
    gridWidth(): number {
      const year = new Date().getFullYear();
      return this.cellWidth * (dateUtil.isLeapYear(year) ? 366 : 365);
    },
    gridHeight(): number {
      let numRows = 0;
      for (const sector of this.sectors.values()) {
        numRows += 1 + (sector.open ? sector.spots.length ?? 0 : 0);
      }
      return this.cellHeight * numRows;
    },
    totalWidth(): string {
      return `${this.gridWidth + 100}px`;
    },
    // Column days array
    days(): Array<HeaderDate> {
      const days = Array<HeaderDate>();
      let cursor = this.from;
      while (cursor <= this.to) {
        days.push({
          dateTime: cursor,
          classes: this.getDayClasses(cursor),
          style: {
            ...this.cellWidthStyle,
            ...this.cellHeightStyle,
          },
          label: this.getDayString(cursor),
          month: cursor.toFormat('MMMM', { locale: i18n.global.locale }),
        });
        cursor = cursor.plus({ days: 1 });
      }
      return days;
    },
    weekendDays(): Array<HeaderDate> {
      return this.days.filter(
        (d) => d.dateTime.weekday === 6 || d.dateTime.weekday === 7,
      );
    },
    allSectorsOpen(): boolean {
      let result = true;
      for (const sector of this.sectors.values()) {
        result &&= sector.open;
      }
      return result;
    },
  },
  methods: {
    getReservationTooltip(reservation: PlannerElementReservation): {
      value: string;
      disabled?: boolean;
    } {
      const noTooltip = { disabled: true, value: '' };
      if (this.zoom > 60) {
        return noTooltip;
      }

      const reservationName = this.getReservationName(reservation);
      if (!reservationName) {
        return noTooltip;
      }

      const startDate = DateTime.fromSeconds(reservation.startDate);
      const endDate = DateTime.fromSeconds(reservation.endDate)
        .plus({ days: 1 })
        .startOf('day');
      const days = Math.floor(endDate.diff(startDate, 'days').days);

      if (days >= 3) {
        return noTooltip;
      }

      if ((this.zoom > 30 && days < 2) || (this.zoom <= 30 && days <= 2)) {
        return {
          value: reservationName,
        };
      }

      return noTooltip;
    },
    getReservationName(reservation: PlannerElementReservation): string {
      return [reservation.customerFirstName, reservation.customerLastName]
        .filter((value) => typeof value === 'string')
        .join(' ')
        .trim();
    },
    isSectorOpen(sectorId: number): boolean {
      const valueFromLocalStorage = this.plannerSectorState.getItem();
      if (!valueFromLocalStorage) {
        return false;
      }

      return valueFromLocalStorage[sectorId] ?? false;
    },
    saveSectorState(): void {
      const sectorState: PlannerSectorState = {};

      for (const [id, sector] of this.sectors.entries()) {
        sectorState[id] = sector.open;
      }

      this.plannerSectorState.setItem(sectorState);
    },
    hasPermissionSchedaPrenotazione(): boolean {
      return permissionsUtil.isActionPermissionAllowed(
        FEATURE_PERMISSION_CONFIG.reservations,
        FEATURE_PERMISSION_ACTION_CONFIG.reservations.PAGE_ACCESS,
      );
    },
    getDayClasses(dateTime: DateTime): Array<string> {
      const classes = ['grid-cell', 'h-day'];
      if (this.cellWidth <= 60) {
        classes.push('short');
      }
      if (dateTime.toSeconds() === this.today.toSeconds()) {
        classes.push('today');
      }
      const index = this.selectedDays.findIndex((selectedDate: HeaderDate) =>
        dateTime.equals(selectedDate.dateTime),
      );
      if (index !== -1) {
        classes.push('selected');
      }
      if (
        dateTime.weekday === 7 ||
        this.holidays.has(dateTime.toFormat('dd-MM'))
      ) {
        classes.push('holiday');
      }
      return classes;
    },
    getDayString(dateTime: DateTime): string {
      // console.log(dateTime);
      if (this.cellWidth <= 40) {
        return dateTime.toFormat('d', { locale: i18n.global.locale });
      }
      if (this.cellWidth <= 80) {
        return dateTime.toFormat('d MMM', { locale: i18n.global.locale });
      }
      return dateTime.toFormat('EEEEE d MMM', { locale: i18n.global.locale });
    },
    getSectorName(sectorId: number): string {
      if (sectorId === CABIN_SECTOR_ID) {
        return this.$t('planner.cabinSector');
      }
      if (sectorId === PARKING_SECTOR_ID) {
        return this.$t('planner.parkingSector');
      }
      return this.mappedSectors.get(sectorId) ?? String(sectorId);
    },
    toggleSector(sectorId: number): void {
      const sector = this.sectors.get(sectorId) ?? null;
      if (sector) {
        sector.open = !sector.open;
      }

      this.saveSectorState();
    },
    toggleAllSectors(): void {
      let allOpen = true;
      // Avoid exploding all sectors if the lazy opening feature is enabled
      // Becomes a permanent close all feature
      if (this.license.explodePlannerOnFirstLoading) {
        for (const sector of this.sectors.values()) {
          allOpen &&= sector.open;
        }
      }
      for (const sector of this.sectors.values()) {
        sector.open = !allOpen;
      }

      this.saveSectorState();
    },
    getGridBarClasses(
      reservation: PlannerElementReservation,
      temp: boolean,
    ): Array<string> {
      const classes = [] as Array<string>;
      if (!reservation) return classes;

      if (reservation.seasonal) {
        classes.push('seasonal');
      }

      if (temp) {
        classes.push('temp');
        classes.push('selected');
      }

      if (
        this.hoveredReservation?.id === reservation.id ||
        (this.hoveredReservation?.masterId &&
          this.hoveredReservation?.masterId === reservation.masterId)
      ) {
        classes.push('hovered');
      }

      if (
        this.mode !== PlannerMode.VIEW &&
        this.builder.originals.has(reservation.id)
      ) {
        classes.push('selected');
      }

      return classes;
    },
    getSegmentStyle(
      start: DateTime,
      end: DateTime,
      status: MapSpotStatus,
    ): GridBarStyle {
      const style = {} as GridBarStyle;

      style.top = '1px';
      const left = Math.ceil(
        this.cellWidth * (start.ordinal - 1) + this.firstColumnWidth,
      );
      style.left = `${left}px`;
      let days = Math.ceil(end.diff(start, 'days').days + 1);
      if (days > 365) {
        days = 365;
      }
      const width = this.cellWidth * days - 2;
      style.width = `${width}px`;

      const height = this.cellHeight - 2;
      style.height = `${height}px`;
      // backgroundColor
      let colorSpec = colorSpecs.get('default');
      switch (status) {
        case MapSpotStatus.HALF_DAY_MORNING:
          colorSpec = colorSpecs.get(this.license.mapColorHalfDayMorning);
          break;
        case MapSpotStatus.HALF_DAY_AFTERNOON:
          colorSpec = colorSpecs.get(this.license.mapColorHalfDayAfternoon);
          break;
        case MapSpotStatus.TEMPORARY:
          colorSpec = colorSpecs.get(this.license.mapColorTemporary);
          break;
        case MapSpotStatus.SEASONAL:
          colorSpec = colorSpecs.get(this.license.mapColorSeasonal);
          break;
        default:
          // Busy
          colorSpec = colorSpecs.get(this.license.mapColorReserved);
      }
      style.backgroundColor = colorSpec?.rgb ?? '';

      if (!this.hasPermissionSchedaPrenotazione()) {
        style.cursor = 'default';
      }
      return style;
    },
    getFixedStyle(
      reservation: PlannerElementReservation,
      segment: PlannerElementSegment,
    ): GridBarStyle {
      if (!reservation) return {} as GridBarStyle;

      const dateTimeStart = DateTime.fromSeconds(segment.startDate);
      const dateTimeEnd = DateTime.fromSeconds(segment.endDate);
      return this.getSegmentStyle(
        dateTimeStart,
        dateTimeEnd,
        plannerUtil.getPlannerStatus(reservation),
      );
    },
    getTempStyle(
      reservation: PlannerElementReservation,
      segment: PlannerElementSegment,
    ): GridBarStyle {
      if (!reservation) return {} as GridBarStyle;

      const dateTimeStart = DateTime.fromSeconds(segment.startDate);
      let dateTimeEnd;
      const isOverlappingAllowed =
        this.license.umbrellaMultipleBookings ===
        LicenseUmbrellaMultipleBookings.NOTICE;
      if (this.mode === PlannerMode.NEW) {
        if (reservation.endDate) {
          dateTimeEnd = DateTime.fromSeconds(segment.endDate);
        } else if (this.hoveredDate && this.hoveredDate > dateTimeStart) {
          let toDate = dateTimeStart;
          // decrease endDate in case of collision (only if overlapping is not allowed)
          while (
            (!this.builder.busyCells.has(
              `${reservation.spotType}${
                reservation.spotName
              }${toDate.toSeconds()}`,
            ) ||
              isOverlappingAllowed) &&
            toDate <= this.hoveredDate
          ) {
            toDate = toDate.plus({ days: 1 });
          }
          dateTimeEnd = toDate.minus({ days: 1 });
        } else {
          dateTimeEnd = dateTimeStart;
        }
      } else if (this.mode === PlannerMode.EDIT) {
        // TODO: Edit res bar on hover
        dateTimeEnd = DateTime.fromSeconds(segment.endDate);
      } else {
        dateTimeEnd = DateTime.fromSeconds(segment.endDate);
      }
      return this.getSegmentStyle(
        dateTimeStart,
        dateTimeEnd,
        plannerUtil.getPlannerStatus(reservation),
      );
    },
    getUnpaidStyle() {
      const size = this.zoom >= 80 ? 16 : this.isSmallZoom ? 8 : 12;
      const height = size;
      const width = size;
      const top = Math.floor((this.cellHeight - height) / 2);
      const backgroundColor =
        colorSpecs.get(this.license.mapColorNotPaid)?.rgb ?? '';
      return {
        right: this.isSmallZoom ? '4px' : '12px',
        top: `${top}px`,
        height: `${height}px`,
        width: `${width}px`,
        backgroundColor,
      };
    },
    generateRows(): void {
      const builder = cloneDeep(this.builder);
      builder.busyCells.clear();
      const sectors = new Map<number, PlannerSector>();

      // Add cabins and parkings sectors
      // hide if sectors' explosion is disabled
      const cabinSector: PlannerSector = {
        open: this.isSectorOpen(CABIN_SECTOR_ID),
        spots: [],
      };
      const parkSector: PlannerSector = {
        open: this.isSectorOpen(PARKING_SECTOR_ID),
        spots: [],
      };

      // Cycle through spots
      for (let i = 0; i < this.data.spots.length; i += 1) {
        const spot: PlannerElement = this.data.spots[i];
        // Calc classes for this spot
        const classes = ['grid-cell', 'hor-sticky', 'spot-cell'];
        if (spot.isFirstOfRow) {
          classes.push('external', 'first');
        }
        if (spot.isLastOfRow) {
          classes.push('external', 'last');
        }
        spot.classes = classes;
        // Calc sector and add to Map
        let sector: PlannerSector | undefined;
        if (spot.type === SpotType.CABIN) {
          sector = cabinSector;
        } else if (spot.type === SpotType.PARKING) {
          sector = parkSector;
        } else {
          sector = sectors.get(spot.sector);
        }
        // build rows
        if (!sector) {
          const isOpen = this.isSectorOpen(spot.sector);
          // New sector
          sectors.set(spot.sector, {
            open: isOpen,
            spots: [spot],
          });
        } else {
          // Add spot to existing one
          sector.spots.push(spot);
        }
        // Calc busy cells
        for (let j = 0; j < spot.reservations.length; j += 1) {
          const r = spot.reservations[j];
          for (const s of r.segments) {
            // occupy cells based on segments
            if (!s.absence) {
              let cursor = s.startDate;
              while (cursor <= s.endDate) {
                builder.busyCells.add(`${spot.type}${spot.name}${cursor}`);
                cursor += 86400;
              }
            }
          }
        }
      }
      this.isFirstOpening = false;
      this.sectors = new Map([...sectors].sort((a, b) => a[0] - b[0]));

      // add cabin and park sectors
      if (this.isCabinSectorVisible && cabinSector.spots.length > 0) {
        this.sectors.set(CABIN_SECTOR_ID, cabinSector);
      }
      if (this.isParkSectorVisible && parkSector.spots.length > 0) {
        this.sectors.set(PARKING_SECTOR_ID, parkSector);
      }

      this.$store.commit('planner/setBuilder', builder);
    },
    setupSectors(): void {
      let maxSector = 0;
      this.licenseSectors.forEach((s: Sector) => {
        maxSector = Math.max(maxSector, s.header.id);
        this.mappedSectors.set(s.header.id, s.header.name);
      });
    },
    onReservationClick(
      event: MouseEvent,
      reservation: PlannerElementReservation,
      spot: PlannerElement,
    ): void {
      if (!this.hasPermissionSchedaPrenotazione()) {
        return;
      }
      const date: DateTime = this.getDateFromEvent(event);
      // Overlap blocked or just noticed
      const isOverlapAllowed =
        this.license.umbrellaMultipleBookings ===
        LicenseUmbrellaMultipleBookings.NOTICE;
      switch (this.mode) {
        case PlannerMode.NEW:
          // Means we are overlapping - If it's not allowed just block the user
          if (!isOverlapAllowed) {
            this.$spiagge.toast.error(this.$t('planner.toast.overlap'));
          } else {
            this.handleClickNewMode(date, spot);
          }
          break;
        case PlannerMode.MOVE:
          // Set moving reservation
          if (!isOverlapAllowed || this.builder.reservationId === null) {
            const builder = cloneDeep(this.builder);
            plannerUtil.builderReset(builder);
            plannerUtil.builderStart(this.data, builder, reservation);
            this.$store.commit('planner/setBuilder', builder);
          } else {
            this.handleClickMoveMode(date, spot);
          }
          break;
        case PlannerMode.EDIT:
          if (!isOverlapAllowed || this.builder.reservationId === null) {
            this.$spiagge.toast.error(this.$t('planner.toast.overlap'));
          } else {
            this.handleClickEditMode(date);
          }
          break;
        case PlannerMode.VIEW:
        default:
          this.$store.commit('app/setAction', AppAction.VIEW_RESERVATION);
          this.$router.push(`/reservation/${reservation.id}`);
      }
    },
    onReservationMouseEnter(
      event: MouseEvent,
      reservation: PlannerElementReservation,
    ): void {
      this.hoveredReservation = reservation;
      // If stretch mode show stretch layer only if not editing already
      if (this.mode === PlannerMode.EDIT && this.builder.originals.size === 0) {
        const builder = cloneDeep(this.builder);
        // For performance reason in this case we only add the single step
        plannerUtil.builderStart(null, builder, reservation);
        this.$store.commit('planner/setBuilder', builder);
      }
    },
    onReservationMouseLeave(): void {
      this.hoveredReservation = null;
    },
    onStretchMouseLeave(): void {
      if (this.builder.editDirection) return;
      this.hoveredReservation = null;
      const builder = cloneDeep(this.builder);
      plannerUtil.builderReset(builder);
      this.$store.commit('planner/setBuilder', builder);
    },
    onStretchInit(spot: PlannerElement, direction: string): void {
      // Set starting reservation
      const builder = cloneDeep(this.builder);
      if (!builder.editDirection) {
        const reservation = builder.originals.values().next()
          .value as PlannerElementReservation;
        plannerUtil.builderReset(builder);
        plannerUtil.builderStart(this.data, builder, reservation);
      }
      builder.editDirection = direction as EditDirection;
      this.$store.commit('planner/setBuilder', builder);
    },
    // ACTIONS
    getDateFromEvent(event: MouseEvent): DateTime {
      // Get cell X position
      const horPos = event.pageX;
      const horScroll = (this.$refs.gridScrollArea as HTMLElement).scrollLeft;
      const cellPosX = Math.ceil(
        (horPos + horScroll - this.gridLeftOffest) / this.cellWidth,
      );

      const currentYear = new Date().getFullYear();

      return DateTime.fromFormat(
        `${cellPosX.toString()}-${
          this.$store.getters['planner/year'] || currentYear
        }`,
        'o-yyyy',
      );
    },
    // Click over grid row
    onRowClick(event: MouseEvent, spot: PlannerElement): void {
      const date = this.getDateFromEvent(event);
      // Perform action based on mode and state
      switch (this.mode) {
        case PlannerMode.NEW:
          this.handleClickNewMode(date, spot);
          break;
        case PlannerMode.MOVE:
          this.handleClickMoveMode(date, spot);
          break;
        case PlannerMode.EDIT:
          this.handleClickEditMode(date);
          break;
        default:
      }
    },
    // Update hovered cell
    onMouseMove(event: MouseEvent): void {
      if (this.mode !== PlannerMode.VIEW) {
        const date = this.getDateFromEvent(event);
        if (date.toMillis() !== this.hoveredDate?.toMillis()) {
          this.hoveredDate = date;
        }
      }
    },
    onGridScroll(): void {
      this.position.x = (this.$refs.gridScrollArea as HTMLElement).scrollLeft;
      this.position.y = (this.$refs.gridScrollArea as HTMLElement).scrollTop;
      cookieUtil.set('spit_pl_pos', JSON.stringify(this.position));
    },
    // Single actions
    handleClickNewMode(date: DateTime, spot: PlannerElement): void {
      // copy of builder
      const builder = cloneDeep(this.builder);
      const spotKey = `${spot.type}${spot.name}`;

      const spotCategory = spot.type;

      // check spot type
      if (
        builder.reservations.length > 0 &&
        !spotUtil.isShiftable(spotCategory, builder.spotCategory as SpotType)
      ) {
        this.$spiagge.toast.error(this.$t('planner.toast.incompatibleSpot'));
        return;
      }
      builder.spotCategory = spotCategory;

      if (!builder.spotKeys.has(spotKey)) builder.spotKeys.add(spotKey);
      // if (isCabin) builder.isCabin = isCabin;
      // if (isParking) builder.isParking = isParking;

      const previous = builder.reservations[builder.reservations.length - 1];
      // Ending started segment or continuing on same spot
      if (
        previous &&
        (!previous.endDate ||
          `${previous.spotType}${previous.spotName}` === spotKey)
      ) {
        const previousSpotKey = `${previous.spotType}${previous.spotName}`;
        if (previousSpotKey !== spotKey) {
          this.$spiagge.toast.error(this.$t('planner.toast.diffSpot'));
          return;
        }
        if (previous.startDate > date.toSeconds()) {
          this.$spiagge.toast.error(this.$t('planner.toast.dateBefore'));
          return;
        }
        previous.endDate = date.toSeconds();
        previous.segments[0].endDate = date.toSeconds();

        if (
          this.license.umbrellaMultipleBookings ===
            LicenseUmbrellaMultipleBookings.BLOCKED &&
          plannerUtil.checkOverlap(builder, previous)
        ) {
          this.$spiagge.toast.error(this.$t('planner.toast.overlap'));
          return;
        }
      } else {
        // calc min start date
        let nextDate = 0;
        builder.reservations.forEach((r: PlannerElementReservation) => {
          nextDate = Math.max(nextDate, r.endDate);
        });
        const nextDateTime = nextDate ? DateTime.fromSeconds(nextDate) : null;
        if (nextDateTime && nextDateTime >= date) {
          this.$spiagge.toast.error(this.$t('planner.toast.dateBeforeStep'));
          return;
        }
        let startDate;
        let endDate;
        if (nextDateTime && date.diff(nextDateTime, 'days').days > 1) {
          startDate = nextDateTime.plus({ days: 1 }).toSeconds();
          endDate = date.toSeconds();
        } else {
          startDate = date.toSeconds();
          endDate = 0;
        }
        const newReservation = {
          spotType: spot.type,
          spotName: spot.name,
          startDate,
          endDate,
          segments: [
            {
              startDate,
              endDate,
              absence: false,
            },
          ] as Array<PlannerElementSegment>,
        } as PlannerElementReservation;
        if (plannerUtil.checkOverlap(builder, newReservation)) {
          this.$spiagge.toast.error(this.$t('planner.toast.overlap'));
          return;
        }
        builder.reservations.push(newReservation);
      }
      plannerUtil.builderUpdateSpotKeys(builder);
      this.$store.commit('planner/setBuilder', builder);
    },
    handleClickMoveMode(date: DateTime, spot: PlannerElement): void {
      // Check if already selected a reservation to move
      if (this.builder.originals.size === 0) {
        // TODO: Toast need to select res first
        return;
      }

      // check spot type
      const spotCategory = spot.type;
      const builder = cloneDeep(this.builder);
      if (
        !spotUtil.isShiftable(spotCategory, builder.spotCategory as SpotType)
      ) {
        this.$spiagge.toast.error(this.$t('planner.toast.incompatibleSpot'));
        return;
      }

      // Find moving
      const rIndex = builder.reservations.findIndex(
        (r) => r.id === builder.reservationId,
      );
      if (rIndex === -1) return;
      const reservation = builder.reservations[rIndex];

      // If moving a step then we cannot change date
      if (reservation.masterId && date.toSeconds() !== reservation.startDate) {
        this.$spiagge.toast.error(this.$t('planner.toast.dateStep'));
        return;
      }

      // Free cells occupied by old reservation
      plannerUtil.builderFreeCells(builder, reservation);

      // Calc movement from orig
      const rStartDate = DateTime.fromSeconds(reservation.startDate);
      const rEndDate = DateTime.fromSeconds(reservation.endDate);
      const days = date.diff(rStartDate, 'days').days;
      // Set new dates and spot
      reservation.spotType = spot.type;
      reservation.spotName = spot.name;
      reservation.startDate = rStartDate.plus({ days }).toSeconds();
      reservation.endDate = rEndDate.plus({ days }).toSeconds();
      reservation.segments = reservation.segments.map((s) => ({
        startDate: DateTime.fromSeconds(s.startDate).plus({ days }).toSeconds(),
        endDate: DateTime.fromSeconds(s.endDate).plus({ days }).toSeconds(),
        absence: s.absence,
      }));
      // Check overlap with new dates and spot
      if (
        this.license.umbrellaMultipleBookings ===
          LicenseUmbrellaMultipleBookings.BLOCKED &&
        plannerUtil.checkOverlap(builder, reservation)
      ) {
        this.$spiagge.toast.error(this.$t('planner.toast.overlap'));
        return;
      }
      // Occupy new cells
      plannerUtil.builderBookCells(builder, reservation);

      // Set edited reservation
      builder.reservations[rIndex] = reservation;
      builder.edited = true;
      // Update spots
      plannerUtil.builderUpdateSpotKeys(builder);

      this.$store.commit('planner/setBuilder', builder);
    },
    handleClickEditMode(date: DateTime): void {
      const builder = cloneDeep(this.builder);
      if (builder.originals.size === 0 || builder.reservations.length === 0) {
        // TODO: Toast need to select res first
        return;
      }
      if (!builder.editDirection) {
        // TODO: Toast select direction first
        return;
      }

      // Find moving
      const rIndex = builder.reservations.findIndex(
        (r) => r.id === builder.reservationId,
      );
      if (rIndex === -1) return;
      const reservation = builder.reservations[rIndex];

      // prevent stretch if voucher applied && five beach
      if (
        reservation.voucherId &&
        LICENSE_FIVE_BEACH.includes(this.license.license)
      ) {
        this.$spiagge.toast.warn(this.$t('planner.toast.voucher'));
        return;
      }

      // Free cells occupied by old reservation
      builder.reservations.forEach((r) =>
        plannerUtil.builderFreeCells(builder, r),
      );

      const rStartDate = DateTime.fromSeconds(reservation.startDate);
      const rEndDate = DateTime.fromSeconds(reservation.endDate);
      // const days = date.diff(rStartDate, 'days').days;

      // Check if endDate crossed startDate
      if (builder.editDirection === EditDirection.RIGHT && date < rStartDate) {
        this.$spiagge.toast.error(this.$t('planner.toast.endStartCross'));
        return;
      }
      if (builder.editDirection === EditDirection.LEFT && date > rEndDate) {
        this.$spiagge.toast.error(this.$t('planner.toast.startEndCross'));
        return;
      }

      // Edit reservation
      if (builder.editDirection === EditDirection.RIGHT) {
        reservation.endDate = date.toSeconds();
      } else {
        reservation.startDate = date.toSeconds();
      }
      reservation.segments = plannerUtil.editCalcSegments(builder, reservation);

      // Check overlap with new dates and spot
      if (
        this.license.umbrellaMultipleBookings ===
          LicenseUmbrellaMultipleBookings.BLOCKED &&
        plannerUtil.checkOverlap(builder, reservation)
      ) {
        this.$spiagge.toast.error(this.$t('planner.toast.overlap'));
        return;
      }

      // Set edited reservation
      builder.reservations[rIndex] = reservation;
      builder.edited = true;
      // Update spots
      plannerUtil.builderUpdateSpotKeys(builder);

      // Book cells again
      builder.reservations.forEach((r) =>
        plannerUtil.builderBookCells(builder, r),
      );

      this.$store.commit('planner/setBuilder', builder);
    },
    // Day
    onDayClick(headerDate: HeaderDate) {
      const index = this.selectedDays.findIndex((selectedDate: HeaderDate) =>
        headerDate.dateTime.equals(selectedDate.dateTime),
      );
      if (index === -1) {
        this.selectedDays.push(headerDate);
      } else {
        this.selectedDays = this.selectedDays.filter(
          (selectedDate: HeaderDate, i: number) => index !== i,
        );
      }
    },
  },
  beforeMount() {
    // Generate planner rows
    this.setupSectors();
    this.generateRows();
  },
  mounted() {
    // Init position
    const positionCookie = cookieUtil.get('spit_pl_pos') ?? null;
    if (positionCookie) {
      this.position = JSON.parse(positionCookie) as PlannerPosition;
      (this.$refs.gridScrollArea as HTMLElement).scrollLeft = this.position.x;
      (this.$refs.gridScrollArea as HTMLElement).scrollTop = this.position.y;
    }

    if (this.breakpoints.mobile) {
      this.firstColumnWidth = 100;
    }

    plannerUtil.scrollToToday('auto');
  },
  watch: {
    data() {
      this.sectors.clear();
      this.generateRows();

      if (this.scrollToToday) {
        // scroll after a little timeout
        // to give vue time to render the page
        setTimeout(() => {
          plannerUtil.scrollToToday('auto');
        }, 50);
        // reset flag
        this.$store.commit('planner/setScrollToToday', false);
      }
    },
  },
});
