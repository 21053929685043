import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  id: "map-print-view",
  class: "view"
}
const _hoisted_2 = { class: "settings" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { class: "p-field format-field" }
const _hoisted_5 = { for: "format" }
const _hoisted_6 = { class: "p-field orientation-field" }
const _hoisted_7 = { for: "orientation" }
const _hoisted_8 = { class: "p-field scale-field" }
const _hoisted_9 = { for: "scale" }
const _hoisted_10 = { class: "flex" }
const _hoisted_11 = { class: "p-field field-checkbox" }
const _hoisted_12 = { for: "print-background" }
const _hoisted_13 = { class: "p-field field-checkbox" }
const _hoisted_14 = { for: "print-decorations" }
const _hoisted_15 = { class: "p-field field-checkbox" }
const _hoisted_16 = { for: "remove-empty" }
const _hoisted_17 = { class: "preview" }
const _hoisted_18 = { id: "screenshots" }
const _hoisted_19 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_MapDecoration = _resolveComponent("MapDecoration")!
  const _component_MapSpot = _resolveComponent("MapSpot")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h2", null, _toDisplayString(_ctx.$t('mapPrint.title')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('mapPrint.format')), 1),
          _createVNode(_component_Dropdown, {
            id: "format",
            modelValue: _ctx.format,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.format) = $event)),
            options: _ctx.formatOptions,
            optionLabel: "label",
            optionValue: "value",
            onChange: _ctx.updatePreferences
          }, null, 8, ["modelValue", "options", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t('mapPrint.orientation')), 1),
          _createVNode(_component_Dropdown, {
            id: "orientation",
            modelValue: _ctx.orientation,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.orientation) = $event)),
            options: _ctx.orientationOptions,
            optionLabel: "label",
            optionValue: "value",
            onChange: _ctx.updatePreferences
          }, null, 8, ["modelValue", "options", "onChange"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t('mapPrint.scale')), 1),
          _createVNode(_component_InputNumber, {
            id: "horizontal",
            modelValue: _ctx.scale,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.scale) = $event)),
            showButtons: "",
            step: 1,
            mode: "decimal",
            min: 75,
            max: 125,
            onInput: _ctx.updatePreferences
          }, null, 8, ["modelValue", "onInput"])
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_Checkbox, {
            modelValue: _ctx.printBackground,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.printBackground) = $event)),
            binary: true,
            onChange: _ctx.updatePreferences
          }, null, 8, ["modelValue", "onChange"]),
          _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('mapPrint.printBackground')), 1)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_Checkbox, {
            modelValue: _ctx.printDecorations,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.printDecorations) = $event)),
            binary: true,
            onChange: _ctx.updatePreferences
          }, null, 8, ["modelValue", "onChange"]),
          _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('mapPrint.printDecorations')), 1)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_Checkbox, {
            modelValue: _ctx.removeEmpty,
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.removeEmpty) = $event)),
            binary: true,
            onChange: _ctx.updatePreferences
          }, null, 8, ["modelValue", "onChange"]),
          _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('mapPrint.removeEmpty')), 1)
        ])
      ]),
      _createVNode(_component_Button, {
        class: "print-button mt-2",
        icon: "spi-print",
        onClick: _ctx.onPrint,
        label: _ctx.$t('button.print')
      }, null, 8, ["onClick", "label"])
    ]),
    _createVNode(_component_Divider),
    _createElementVNode("div", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.screenshots, (screenshot, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: _normalizeClass(["screenshot page-break-after", _ctx.screenshotClasses(screenshot)]),
            style: _normalizeStyle(_ctx.screenshotStyle(screenshot))
          }, [
            (_ctx.startDate && _ctx.endDate && _ctx.previewDate)
              ? (_openBlock(), _createElementBlock("footer", _hoisted_19, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('mapPrint.mapPeriodPrintInfo', {
                startDate: _ctx.$spiagge.date.fromMillis(_ctx.startDate.toMillis()),
                endDate: _ctx.$spiagge.date.fromMillis(_ctx.endDate.toMillis()),
                previewDate: _ctx.previewDate,
              })), 1)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "wrapper",
              style: _normalizeStyle(_ctx.wrapperStyle(screenshot))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(screenshot.decorations, (decoration, index) => {
                return (_openBlock(), _createBlock(_component_MapDecoration, {
                  key: index,
                  decoration: decoration,
                  cellWidth: _ctx.cellWidth,
                  cellHeight: _ctx.cellHeight,
                  class: _normalizeClass(!_ctx.printDecorations ? 'hidden no-print' : '')
                }, null, 8, ["decoration", "cellWidth", "cellHeight", "class"]))
              }), 128)),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(screenshot.spots, (spot, index) => {
                return (_openBlock(), _createBlock(_component_MapSpot, {
                  key: index,
                  spot: spot,
                  cellWidth: _ctx.cellWidth,
                  cellHeight: _ctx.cellHeight
                }, null, 8, ["spot", "cellWidth", "cellHeight"]))
              }), 128))
            ], 4)
          ], 6))
        }), 128))
      ])
    ])
  ]))
}