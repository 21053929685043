
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';
import { Field, Form } from 'vee-validate';
import { DropdownOption } from '@/models';
import voucherService from '@/services/voucherService';
import { Voucher } from '@/models/voucher';
import { ApiVoucherUpdatePayload } from '@/models/api';
import { ColorName, VOUCHER_BG_COLORS } from '@/models/color';
import { PriceList } from '@/models/priceList';

export default defineComponent({
  name: 'PriceListsVoucherDetailView',
  components: { Field, Form },
  data() {
    return {
      voucher: undefined as unknown as Voucher,
      // model
      mounted: false,
      model: {} as ApiVoucherUpdatePayload,
      // options
      backgroundOptions: VOUCHER_BG_COLORS.map((name: ColorName | null) => {
        if (name) {
          return {
            label: this.$t(`color.${name.toLowerCase()}`),
            value: name,
          } as DropdownOption;
        }
        return {
          label: this.$t('common.none'),
          value: null,
        } as DropdownOption;
      }),
    };
  },
  async beforeMount(): Promise<void> {
    /**
     * Init view
     */
    const voucherId = this.$route.params.id
      ? Number(this.$route.params.id)
      : undefined;
    if (!voucherId) {
      this.$spiagge.toast.error(
        this.$t('priceListsVoucherDetailView.toast.error'),
      );
      this.$router.push('/price-lists');
    }
    try {
      this.voucher = await voucherService.get(voucherId as number);
      this.loadModel();
    } catch (e) {
      this.$spiagge.toast.error(
        this.$t('priceListsVoucherDetailView.toast.retrievalError'),
      );
      this.$router.push('/price-lists');
    }
  },
  mounted() {
    this.mounted = true;
  },
  methods: {
    loadModel(): void {
      /**
       * Load voucher model from voucher
       */
      this.model = _.pick(this.voucher, [
        'name',
        'hotelPriceListId',
        'customerPriceListId',
        'background',
        'workId',
        'email',
        'onlineMode',
        'onlinePayment',
        'onlineDiscount',
      ]) as ApiVoucherUpdatePayload;
    },
    async onSave(): Promise<void> {
      /**
       * Update voucher
       */
      try {
        this.voucher = await voucherService.update(this.voucher.id, this.model);
        this.loadModel();
        await this.$store.dispatch('priceLists/getVouchers');
        this.$spiagge.toast.success(
          this.$t('priceListsVoucherDetailView.toast.saveSuccess'),
        );
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('priceListsVoucherDetailView.toast.saveError'),
        );
      }
    },
    onDelete(): void {
      /**
       * Delete voucher (confirm)
       */
      this.$confirm.require({
        message: this.$t('priceListsVoucherDetailView.confirm.message'),
        header: this.$t('priceListsVoucherDetailView.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.$t('common.yes'),
        accept: () => {
          this.delete();
        },
        rejectLabel: this.$t('common.no'),
        reject: () => {
          // nothing
        },
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-danger p-button-outlined',
      });
    },
    async delete(): Promise<void> {
      /**
       * Delete voucher
       */
      try {
        await voucherService.delete(this.voucher.id);
        this.$spiagge.toast.success(
          this.$t('priceListsVoucherDetailView.toast.deleteSuccess'),
        );
        this.$router.push('/price-lists');
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('priceListsVoucherDetailView.toast.deleteError'),
        );
      }
    },
  },
  computed: {
    ...mapState('session', ['license']),
    ...mapGetters('priceLists', ['priceLists', 'vouchers', 'wizardEnabled']),
    priceListsOptions(): Array<DropdownOption> {
      return [
        {
          label: this.$t('common.none'),
          value: null,
        },
        ...this.priceLists.map(
          (priceList: PriceList) =>
            ({
              label: priceList.name,
              value: priceList.id,
            } as DropdownOption),
        ),
      ];
    },
  },
});
