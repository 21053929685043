import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d906420"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "price-list-view" }
const _hoisted_2 = { class: "list-col" }
const _hoisted_3 = { class: "content-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PriceListList = _resolveComponent("PriceListList")!
  const _component_PriceListDetails = _resolveComponent("PriceListDetails")!
  const _component_VoucherDetails = _resolveComponent("VoucherDetails")!
  const _component_PriceListDialog = _resolveComponent("PriceListDialog")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_PriceListSwitchDialog = _resolveComponent("PriceListSwitchDialog")!
  const _component_SeasonalDialog = _resolveComponent("SeasonalDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PriceListList, {
        priceLists: _ctx.priceLists,
        vouchers: _ctx.vouchers,
        onSelectPl: _ctx.onPriceListSelect,
        onSelectVoucher: _ctx.onVoucherSelect,
        onNewList: _ctx.onNewList,
        onOpenSeasonal: _ctx.onOpenSeasonal,
        onNewVoucher: _ctx.onNewVoucher
      }, null, 8, ["priceLists", "vouchers", "onSelectPl", "onSelectVoucher", "onNewList", "onOpenSeasonal", "onNewVoucher"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.priceListSelected)
        ? (_openBlock(), _createBlock(_component_PriceListDetails, {
            key: 0,
            priceList: _ctx.priceListSelected,
            onEditList: _ctx.onEditList,
            onRenameList: _ctx.onRenameList,
            onSwitchList: _ctx.onSwitchList,
            onDeleteList: _ctx.onDeleteList,
            onUpdatePeriods: _ctx.onUpdatePeriods
          }, null, 8, ["priceList", "onEditList", "onRenameList", "onSwitchList", "onDeleteList", "onUpdatePeriods"]))
        : _createCommentVNode("", true),
      (_ctx.showVoucherDetails)
        ? (_openBlock(), _createBlock(_component_VoucherDetails, {
            key: 1,
            voucherRef: _ctx.voucherEdit ?? undefined,
            onSaveVoucher: _ctx.saveVoucher,
            onDeleteVoucher: _ctx.deleteVoucher
          }, null, 8, ["voucherRef", "onSaveVoucher", "onDeleteVoucher"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Dialog, {
      class: "custom-dialog",
      modal: true,
      visible: _ctx.showPriceListDialog,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showPriceListDialog) = $event)),
      onHide: _ctx.onPriceListDialogHide
    }, {
      default: _withCtx(() => [
        _createVNode(_component_PriceListDialog, {
          mode: _ctx.priceListDialogMode,
          priceListRef: _ctx.priceListEdit ?? undefined,
          onSave: _ctx.save
        }, null, 8, ["mode", "priceListRef", "onSave"])
      ]),
      _: 1
    }, 8, ["visible", "onHide"]),
    (_ctx.priceListEdit)
      ? (_openBlock(), _createBlock(_component_Dialog, {
          key: 0,
          class: "custom-dialog",
          modal: true,
          visible: _ctx.showPriceListSwitchDialog,
          "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showPriceListSwitchDialog) = $event)),
          onHide: _ctx.onPriceListSwitchDialogHide
        }, {
          default: _withCtx(() => [
            _createVNode(_component_PriceListSwitchDialog, {
              priceLists: _ctx.priceLists,
              priceListSelected: _ctx.priceListEdit,
              onClose: _ctx.onPriceListSwitchDialogHide,
              onSwitch: _ctx.onPriceListSwitchEvent
            }, null, 8, ["priceLists", "priceListSelected", "onClose", "onSwitch"])
          ]),
          _: 1
        }, 8, ["visible", "onHide"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Dialog, {
      class: "custom-dialog",
      modal: true,
      visible: _ctx.showSeasonalDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showSeasonalDialog) = $event)),
      onHide: _ctx.onSeasonalDialogHide
    }, {
      default: _withCtx(() => [
        _createVNode(_component_SeasonalDialog, {
          priceLists: _ctx.priceLists,
          onClose: _ctx.onSeasonalDialogHide
        }, null, 8, ["priceLists", "onClose"])
      ]),
      _: 1
    }, 8, ["visible", "onHide"])
  ]))
}