import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5601a337"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "price-period-dialog" }
const _hoisted_2 = { class: "p-field" }
const _hoisted_3 = { class: "dates" }
const _hoisted_4 = { class: "p-field" }
const _hoisted_5 = { class: "p-field" }
const _hoisted_6 = { class: "p-field" }
const _hoisted_7 = { class: "p-field" }
const _hoisted_8 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_CalendarInput = _resolveComponent("CalendarInput")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h2", null, _toDisplayString(_ctx.$t(`pricePeriodDialog.title.${_ctx.isNew ? 'new' : 'edit'}`)), 1)
    ]),
    _createElementVNode("form", {
      onSubmit: _cache[7] || (_cache[7] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onFormSubmit && _ctx.onFormSubmit(...args)), ["prevent"])),
      class: "form"
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("label", null, _toDisplayString(_ctx.$t('pricePeriodDialog.periodName')) + ":", 1),
        _createVNode(_component_InputText, {
          type: "text",
          modelValue: _ctx.pricePeriod.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pricePeriod.name) = $event))
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('common.from')) + ":", 1),
          _createVNode(_component_CalendarInput, {
            modelValue: _ctx.pricePeriod.startDate,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pricePeriod.startDate) = $event)),
            format: "dd-MM",
            minDate: _ctx.minDate,
            maxDate: _ctx.maxDate,
            year: _ctx.year,
            disabledDates: _ctx.disabledDates.concat(_ctx.intervalDisabledDates),
            onMonthChange: _cache[2] || (_cache[2] = (event) => _ctx.setIntervalDisabledDates(-1)),
            onShow: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onShow(-1)))
          }, null, 8, ["modelValue", "minDate", "maxDate", "year", "disabledDates"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('common.to')) + ":", 1),
          _createVNode(_component_CalendarInput, {
            modelValue: _ctx.pricePeriod.endDate,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pricePeriod.endDate) = $event)),
            format: "dd-MM",
            minDate: _ctx.minDate,
            maxDate: _ctx.maxDate,
            year: _ctx.year,
            disabledDates: _ctx.disabledDates.concat(_ctx.intervalDisabledDates),
            onMonthChange: _cache[5] || (_cache[5] = (event) => _ctx.setIntervalDisabledDates(-1)),
            onShow: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onShow(-1)))
          }, null, 8, ["modelValue", "minDate", "maxDate", "year", "disabledDates"])
        ])
      ]),
      (_ctx.isNew)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.extraIntervals, (ei, i) => {
              return (_openBlock(), _createElementBlock("div", {
                key: `ei-${i}`,
                class: "dates extra"
              }, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('common.from')) + ":", 1),
                  _createVNode(_component_CalendarInput, {
                    modelValue: ei.startDate,
                    "onUpdate:modelValue": ($event: any) => ((ei.startDate) = $event),
                    format: "dd-MM",
                    minDate: _ctx.minDate,
                    maxDate: _ctx.maxDate,
                    year: _ctx.year,
                    disabledDates: _ctx.disabledDates.concat(_ctx.intervalDisabledDates),
                    onMonthChange: (event) => _ctx.setIntervalDisabledDates(i),
                    onShow: ($event: any) => (_ctx.onShow(i))
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "minDate", "maxDate", "year", "disabledDates", "onMonthChange", "onShow"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('common.to')) + ":", 1),
                  _createVNode(_component_CalendarInput, {
                    modelValue: ei.endDate,
                    "onUpdate:modelValue": ($event: any) => ((ei.endDate) = $event),
                    format: "dd-MM",
                    minDate: _ctx.minDate,
                    maxDate: _ctx.maxDate,
                    year: _ctx.year,
                    disabledDates: _ctx.disabledDates.concat(_ctx.intervalDisabledDates),
                    onMonthChange: (event) => _ctx.setIntervalDisabledDates(i),
                    onShow: ($event: any) => (_ctx.onShow(i))
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "minDate", "maxDate", "year", "disabledDates", "onMonthChange", "onShow"])
                ]),
                _createElementVNode("i", {
                  class: "spi-times text-sm",
                  onClick: ($event: any) => (_ctx.onDeleteExtraClick(i))
                }, null, 8, _hoisted_8)
              ]))
            }), 128)),
            _createVNode(_component_Button, {
              type: "button",
              label: _ctx.$t('pricePeriodDialog.addInterval'),
              icon: "spi-plus",
              class: "p-button-text p-button-primary btn",
              iconPos: "right",
              onClick: _ctx.onAddButtonClick,
              disabled: !_ctx.nextSlot
            }, null, 8, ["label", "onClick", "disabled"])
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_Button, {
        type: "submit",
        label: _ctx.$t('button.save'),
        icon: "spi-save",
        class: "p-button p-button-primary btn",
        iconPos: "right"
      }, null, 8, ["label"]),
      _createVNode(_component_Button, {
        type: "button",
        label: 
          _ctx.isNew ? _ctx.$t('button.cancel') : _ctx.$t('pricePeriodDialog.deletePeriod')
        ,
        icon: "spi-times",
        class: "p-button-text p-button-danger btn",
        iconPos: "right",
        onClick: _ctx.onPeriodDelete
      }, null, 8, ["label", "onClick"])
    ], 32)
  ]))
}