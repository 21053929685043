
import { computed, defineComponent } from 'vue';
import { jsPDF as JsPDF } from 'jspdf';
import { useStore } from 'vuex';
import { StripeTransferInvoice } from '@/models/invoice';
import statisticService from '@/services/statisticService';
import { useGenerateInvoicePdf } from '@/composables/useGenerateInvoicePdf';
import { RootState } from '@/models/store';
import ShowInvoiceDialogDataTable from './ShowInvoiceDialogDataTable.vue';
import ShowInvoiceDialogInfo from './ShowInvoiceDialogInfo.vue';
import ShowInvoiceDialogCards from './ShowInvoiceDialogCards.vue';
import currencyUtil from '@/utils/currencyUtil';

export default defineComponent({
  name: 'ShowInvoiceDialog',
  components: {
    ShowInvoiceDialogCards,
    ShowInvoiceDialogDataTable,
    ShowInvoiceDialogInfo,
  },
  emit: ['close'],
  props: {
    preinvoiceId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      invoice: null as null | StripeTransferInvoice,
      isLoading: false,
      isExporting: false,
      activeTab: 0,
    };
  },
  setup() {
    const {
      generateInvoiceDate,
      generateInvoiceInfo,
      generateInvoiceTotal,
      generateTable,
    } = useGenerateInvoicePdf();

    const store = useStore<RootState>();

    const isMobile = computed(() => store.state.app.breakpoints.mobile);

    return {
      currencyUtil,
      generateInvoiceDate,
      generateInvoiceInfo,
      generateInvoiceTotal,
      generateTable,
      isMobile,
    };
  },
  methods: {
    async loadInvoice(): Promise<void> {
      this.isLoading = true;
      // Loading the invoice
      try {
        this.invoice = await statisticService.loadStripeTransferInvoice(
          this.preinvoiceId,
        );
      } catch (error) {
        this.$spiagge.toast.error(
          this.$t(
            'statisticsStripeTransfersView.showInvoiceDialog.retrievalError',
          ),
        );
      } finally {
        this.isLoading = false;
      }
    },
    reset(): void {
      /**
       * Reset component
       */
      this.$emit('close');
    },
    goToReservation(link: string): void {
      window.open(link);
    },
    exportInvoice(): void {
      if (this.invoice === null) {
        return;
      }

      // Convert HTML table to PNG image using dom-to-image
      const dialogRef = document.getElementById('show-invoice-dialog');

      const container = document.createElement('div');
      container.style.width = '800px';
      container.style.padding = '1.5rem';

      document.body.appendChild(container);

      const h1 = document.createElement('h1');
      h1.innerHTML = this.$t(
        'statisticsStripeTransfersView.showInvoiceDialog.thirdPartyInvoice',
      );
      container.appendChild(h1);
      container.appendChild(this.generateInvoiceDate(this.invoice));
      container.appendChild(this.generateInvoiceInfo());
      container.appendChild(this.generateTable(this.invoice.aggregatedRows));
      container.appendChild(this.generateInvoiceTotal());

      if (dialogRef && !this.isExporting) {
        this.isExporting = true;

        queueMicrotask(() => {
          const pdf = new JsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: 'a3',
          });

          // Add the PNG image to the PDF document
          pdf.html(container, {
            autoPaging: 'text',
            callback: (pdf) => {
              if (this.invoice === null) {
                return;
              }

              // Save the PDF document
              const invoiceName = `fattura_conto_terzi_spiagge_${this.invoice.invoiceDate}`;
              pdf.save(`${invoiceName}.pdf`);
              dialogRef.querySelectorAll('button').forEach((el) => {
                // eslint-disable-next-line no-param-reassign
                el.style.visibility = 'visible';
              });
              this.isExporting = false;
              document.body.removeChild(container);
            },
          });
        });
      }
    },
  },
  async beforeMount() {
    await this.loadInvoice();
  },
});
