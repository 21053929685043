import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-12 flex justify-content-between" }
const _hoisted_2 = { class: "input-gap mt-3 mb-3 input-dialog" }
const _hoisted_3 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_4 = { for: "name" }
const _hoisted_5 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_6 = { for: "lastName" }
const _hoisted_7 = { class: "mt-3 mb-3 flex-column" }
const _hoisted_8 = { for: "email" }
const _hoisted_9 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_10 = { class: "flex align-items-center" }
const _hoisted_11 = ["for"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "mt-3 mb-3 flex-column" }
const _hoisted_14 = { for: "phoneAreaCode" }
const _hoisted_15 = { class: "input-dialog input-gap" }
const _hoisted_16 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_17 = ["for"]
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "input-dialog input-gap" }
const _hoisted_20 = { class: "mt-3 mb-3 flex-column tags" }
const _hoisted_21 = { class: "input-gap mt-3 mb-3 input-dialog" }
const _hoisted_22 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_23 = { for: "invoiceAddress1" }
const _hoisted_24 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_25 = { for: "invoiceAddress1" }
const _hoisted_26 = { class: "input-gap mt-3 mb-3 input-dialog" }
const _hoisted_27 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_28 = { for: "cap" }
const _hoisted_29 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_30 = { for: "city" }
const _hoisted_31 = { class: "input-gap mt-3 mb-3 input-dialog" }
const _hoisted_32 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_33 = { for: "province" }
const _hoisted_34 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_35 = { for: "country" }
const _hoisted_36 = { class: "input-gap mt-3 mb-3 input-dialog" }
const _hoisted_37 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_38 = { for: "lotCode" }
const _hoisted_39 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_40 = { for: "cf" }
const _hoisted_41 = { class: "input-gap mt-3 mb-3 input-dialog" }
const _hoisted_42 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_43 = { for: "invoiceCompany" }
const _hoisted_44 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_45 = { for: "lotCode" }
const _hoisted_46 = { class: "input-gap mt-3 mb-3 input-dialog" }
const _hoisted_47 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_48 = { for: "pec" }
const _hoisted_49 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_50 = { for: "sdi" }
const _hoisted_51 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_52 = { class: "flex" }
const _hoisted_53 = { for: "petName" }
const _hoisted_54 = ["onClick"]
const _hoisted_55 = { class: "input-gap flex flex-column lg:flex-row" }
const _hoisted_56 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_57 = { for: "petGender" }
const _hoisted_58 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_59 = { for: "petBreed" }
const _hoisted_60 = { class: "input-gap flex flex-column lg:flex-row" }
const _hoisted_61 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_62 = { for: "documentNumber" }
const _hoisted_63 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_64 = { for: "petChipcode" }
const _hoisted_65 = { class: "mt-1 mb-1 flex-column" }
const _hoisted_66 = { class: "input-gap mt-3 mb-3 input-dialog" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Field = _resolveComponent("Field")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_CustomerTags = _resolveComponent("CustomerTags")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    style: { width: 'auto' },
    modal: true,
    visible: _ctx.show,
    "onUpdate:visible": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.show) = $event)),
    onHide: _cache[22] || (_cache[22] = ($event: any) => (_ctx.show = false)),
    id: "contact-dialog"
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h6", null, _toDisplayString(_ctx.edit
              ? _ctx.$t('contactsView.editContact')
              : _ctx.$t('contactsView.newContact')), 1)
      ])
    ]),
    footer: _withCtx(() => []),
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        onSubmit: _cache[20] || (_cache[20] = ($event: any) => (_ctx.submitCustomer()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.$t('extraServices.dialog.name')), 1),
              _createVNode(_component_InputText, {
                style: {"width":"200px"},
                id: "firstName",
                modelValue: _ctx.customer.firstName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.customer.firstName) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t('contactsView.lastname')) + ":", 1),
              _createVNode(_component_InputText, {
                style: {"width":"200px"},
                id: "lastName",
                modelValue: _ctx.customer.lastName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.customer.lastName) = $event))
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('BookingConfigView.notify.email.title')) + ":", 1),
            _createVNode(_component_Field, {
              name: "email",
              modelValue: _ctx.customer.email,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.customer.email) = $event)),
              rules: "email"
            }, {
              default: _withCtx(({ field, meta }) => [
                _createVNode(_component_InputText, _mergeProps({ style: {"width":"auto"} }, field, {
                  class: !meta.valid && meta.touched ? 'p-invalid' : ''
                }), null, 16, ["class"])
              ]),
              _: 1
            }, 8, ["modelValue"]),
            _createElementVNode("div", _hoisted_9, [
              _createVNode(_component_Button, {
                label: _ctx.$t('button.add'),
                icon: "pi pi-plus",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.addOtherEmail())),
                class: "p-button-outlined",
                style: {"width":"120px"}
              }, null, 8, ["label"])
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherEmails, (e, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "mt-3 mb-3 flex-column",
              key: `email-${i}`
            }, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("label", {
                  for: `email${i}`
                }, [
                  _createTextVNode(_toDisplayString(_ctx.$t('BookingConfigView.notify.email.title') + ` ${i + 1}`) + " ", 1),
                  _createElementVNode("i", {
                    class: "pi pi-times btn-close ml-3",
                    onClick: ($event: any) => (_ctx.deleteOtherEmail(i))
                  }, null, 8, _hoisted_12)
                ], 8, _hoisted_11)
              ]),
              _createVNode(_component_InputText, {
                id: `email${i}`,
                style: {"width":"auto"},
                modelValue: e.address,
                "onUpdate:modelValue": ($event: any) => ((e.address) = $event)
              }, null, 8, ["id", "modelValue", "onUpdate:modelValue"])
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t('cardsView.cardPhone')) + ":", 1),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_Dropdown, {
                id: "phoneAreaCode",
                modelValue: _ctx.customer.phoneAreaCode,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.customer.phoneAreaCode) = $event)),
                options: _ctx.phonesAreaCodes,
                optionLabel: "label",
                optionValue: "value",
                class: "mt-1 mb-1",
                style: {"width":"200px"}
              }, null, 8, ["modelValue", "options"]),
              _createVNode(_component_InputText, {
                class: "mt-1 mb-1",
                style: {"width":"200px"},
                modelValue: _ctx.customer.phoneNumber,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.customer.phoneNumber) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createVNode(_component_Button, {
                label: _ctx.$t('button.add'),
                icon: "pi pi-plus",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.addOtherPhone())),
                class: "p-button-outlined",
                style: {"width":"120px"}
              }, null, 8, ["label"])
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherPhones, (e, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "mt-3 mb-3 flex-column",
              key: `phone-${i}`
            }, [
              _createElementVNode("label", {
                for: `phone-${i}`,
                class: "flex align-items-center"
              }, [
                _createTextVNode(_toDisplayString(_ctx.$t('cardsView.cardPhone') + ` ${i + 1}`) + " ", 1),
                _createElementVNode("i", {
                  class: "pi pi-times btn-close ml-3",
                  onClick: ($event: any) => (_ctx.deleteOtherPhone(i))
                }, null, 8, _hoisted_18)
              ], 8, _hoisted_17),
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_Dropdown, {
                  modelValue: e.phoneAreaCode,
                  "onUpdate:modelValue": ($event: any) => ((e.phoneAreaCode) = $event),
                  options: _ctx.phonesAreaCodes,
                  optionLabel: "label",
                  optionValue: "value",
                  class: "mt-1 mb-1",
                  style: {"width":"200px"}
                }, null, 8, ["modelValue", "onUpdate:modelValue", "options"]),
                _createVNode(_component_InputText, {
                  id: `phone-${i}`,
                  modelValue: e.phoneNumber,
                  "onUpdate:modelValue": ($event: any) => ((e.phoneNumber) = $event),
                  class: "mt-1 mb-1",
                  style: {"width":"200px"}
                }, null, 8, ["id", "modelValue", "onUpdate:modelValue"])
              ])
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_20, [
            _createVNode(_component_CustomerTags, {
              tagIds: _ctx.customer.tags,
              onChangeTags: _ctx.onChangeTags
            }, null, 8, ["tagIds", "onChangeTags"])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t('customerDialog.address')) + ":", 1),
              _createVNode(_component_InputText, {
                modelValue: _ctx.customer.invoiceAddress1,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.customer.invoiceAddress1) = $event)),
                id: "invoiceAddress1",
                style: {"width":"200px"}
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t('customerDialog.houseNumber')) + ":", 1),
              _createVNode(_component_InputText, {
                modelValue: _ctx.customer.invoiceAddress2,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.customer.invoiceAddress2) = $event)),
                style: {"width":"200px"}
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t('customerDialog.zipCode')) + ":", 1),
              _createVNode(_component_InputText, {
                style: {"width":"200px"},
                modelValue: _ctx.customer.invoiceZip,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.customer.invoiceZip) = $event)),
                id: "cap"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.$t('customerDialog.city')) + ":", 1),
              _createVNode(_component_InputText, {
                style: {"width":"200px"},
                modelValue: _ctx.customer.invoiceCity,
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.customer.invoiceCity) = $event)),
                id: "city"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_31, [
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t('customerDialog.state')) + ":", 1),
              _createVNode(_component_InputText, {
                style: {"width":"200px"},
                modelValue: _ctx.customer.invoiceState,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.customer.invoiceState) = $event)),
                id: "province"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.$t('customerDialog.country')) + ":", 1),
              _createVNode(_component_Dropdown, {
                id: "country",
                modelValue: _ctx.customer.invoiceCountry,
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.customer.invoiceCountry) = $event)),
                options: _ctx.nationCode,
                optionLabel: "label",
                optionValue: "value",
                style: {"width":"200px","height":"35px"}
              }, null, 8, ["modelValue", "options"])
            ])
          ]),
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("div", _hoisted_37, [
              _createElementVNode("label", _hoisted_38, _toDisplayString(_ctx.$t('customerDialog.lotCode')) + ":", 1),
              _createVNode(_component_InputText, {
                style: {"width":"200px"},
                modelValue: _ctx.customer.invoiceLotteryCode,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.customer.invoiceLotteryCode) = $event)),
                id: "lotCode"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_39, [
              _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.$t('customerDialog.cf')) + ":", 1),
              _createVNode(_component_InputText, {
                style: {"width":"200px"},
                modelValue: _ctx.customer.invoiceTaxCode,
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.customer.invoiceTaxCode) = $event)),
                id: "cf"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_41, [
            _createElementVNode("div", _hoisted_42, [
              _createElementVNode("label", _hoisted_43, _toDisplayString(_ctx.$t('cardTypesView.businessName')) + ":", 1),
              _createVNode(_component_InputText, {
                modelValue: _ctx.customer.invoiceCompany,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.customer.invoiceCompany) = $event)),
                id: "invoiceCompany",
                style: {"width":"200px"}
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_44, [
              _createElementVNode("label", _hoisted_45, _toDisplayString(_ctx.$t('customerDialog.vat')) + ":", 1),
              _createVNode(_component_InputText, {
                style: {"width":"200px"},
                modelValue: _ctx.customer.invoiceVatCode,
                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.customer.invoiceVatCode) = $event)),
                id: "iva"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_46, [
            _createElementVNode("div", _hoisted_47, [
              _createElementVNode("label", _hoisted_48, _toDisplayString(_ctx.$t('customerDialog.pec')) + ":", 1),
              _createVNode(_component_InputText, {
                style: {"width":"200px"},
                modelValue: _ctx.customer.invoicePec,
                "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.customer.invoicePec) = $event)),
                id: "pec"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_49, [
              _createElementVNode("label", _hoisted_50, _toDisplayString(_ctx.$t('customerDialog.sdi')) + ":", 1),
              _createVNode(_component_InputText, {
                style: {"width":"200px"},
                modelValue: _ctx.customer.invoiceSdi,
                "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.customer.invoiceSdi) = $event)),
                id: "sdi"
              }, null, 8, ["modelValue"])
            ])
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pets, (pet, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: i,
              class: "flex flex-column pet-container"
            }, [
              _createElementVNode("div", _hoisted_51, [
                _createElementVNode("div", _hoisted_52, [
                  _createElementVNode("label", _hoisted_53, _toDisplayString(_ctx.$t('customerDialog.pet.name')) + " " + _toDisplayString(i + 1) + ":", 1),
                  _createElementVNode("i", {
                    class: "ml-2 pi pi-times cursor-pointer flex align-items-center",
                    onClick: ($event: any) => (_ctx.deletePet(i))
                  }, null, 8, _hoisted_54)
                ]),
                _createVNode(_component_InputText, {
                  style: {"width":"auto"},
                  modelValue: pet.name,
                  "onUpdate:modelValue": ($event: any) => ((pet.name) = $event),
                  id: "petName"
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _createElementVNode("div", _hoisted_55, [
                _createElementVNode("div", _hoisted_56, [
                  _createElementVNode("label", _hoisted_57, _toDisplayString(_ctx.$t('customerDialog.pet.gender')) + ":", 1),
                  _createVNode(_component_Dropdown, {
                    style: {"width":"200px"},
                    modelValue: pet.gender,
                    "onUpdate:modelValue": ($event: any) => ((pet.gender) = $event),
                    options: _ctx.genderDropDownList,
                    optionLabel: "name",
                    optionValue: "code",
                    id: "petGender"
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
                ]),
                _createElementVNode("div", _hoisted_58, [
                  _createElementVNode("label", _hoisted_59, _toDisplayString(_ctx.$t('customerDialog.pet.breed')), 1),
                  _createVNode(_component_InputText, {
                    style: {"width":"200px"},
                    modelValue: pet.breed,
                    "onUpdate:modelValue": ($event: any) => ((pet.breed) = $event),
                    id: "petBreed"
                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                ])
              ]),
              _createElementVNode("div", _hoisted_60, [
                _createElementVNode("div", _hoisted_61, [
                  _createElementVNode("label", _hoisted_62, _toDisplayString(_ctx.$t('customerDialog.pet.documentNumber')) + ":", 1),
                  _createVNode(_component_InputText, {
                    style: {"width":"200px"},
                    modelValue: pet.documentNumber,
                    "onUpdate:modelValue": ($event: any) => ((pet.documentNumber) = $event),
                    id: "documentNumber"
                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                ]),
                _createElementVNode("div", _hoisted_63, [
                  _createElementVNode("label", _hoisted_64, _toDisplayString(_ctx.$t('customerDialog.pet.chipCode')) + ":", 1),
                  _createVNode(_component_InputText, {
                    style: {"width":"200px"},
                    modelValue: pet.chipCode,
                    "onUpdate:modelValue": ($event: any) => ((pet.chipCode) = $event),
                    id: "petChipcode"
                  }, null, 8, ["modelValue", "onUpdate:modelValue"])
                ])
              ])
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_65, [
            _createVNode(_component_Button, {
              label: _ctx.$t('button.add'),
              icon: "pi pi-plus",
              onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.addPet())),
              class: "p-button-outlined",
              style: {"width":"120px"}
            }, null, 8, ["label"])
          ]),
          _createElementVNode("div", _hoisted_66, [
            _createVNode(_component_Button, {
              label: _ctx.$t('button.cancel'),
              icon: "pi pi-times",
              onClick: _ctx.closeShowDialog,
              class: "mt-1 mb-1 p-button-outlined",
              style: {"width":"200px"}
            }, null, 8, ["label", "onClick"]),
            _createVNode(_component_Button, {
              label: _ctx.$t('button.save'),
              icon: "pi pi-save",
              type: "submit",
              style: {"width":"200px"},
              class: "mt-1 mb-1"
            }, null, 8, ["label"])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible"]))
}