
import { DateTime } from 'luxon';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CalendarInput',
  emits: [
    'update:modelValue',
    'show',
    'date-select',
    'month-change',
    'year-change',
  ],
  props: {
    modelValue: {
      type: [Object as () => DateTime | null, String],
      required: true,
    },
    format: {
      type: String,
      default: 'dd/MM/yy',
    },
    minDate: {
      type: DateTime,
    },
    maxDate: {
      type: DateTime,
    },
    year: {
      type: Number,
    },
    disabledDates: {
      type: Array as () => Array<DateTime>,
    },
  },
  data() {
    return {};
  },
  computed: {
    model: {
      get(): Date | null {
        if (!this.modelValue) return null;
        if (this.modelValue instanceof DateTime) {
          return this.modelValue.toJSDate();
        }
        if (this.year !== undefined && this.format.indexOf('y') === -1) {
          return DateTime.fromFormat(
            `${this.modelValue}-${this.year}`,
            'dd-MM-yyyy',
          ).toJSDate();
        }
        return DateTime.fromFormat(this.modelValue, this.format).toJSDate();
      },
      set(value: Date): void {
        const dateString = `${value.getDate()}-${
          value.getMonth() + 1
        }-${value.getFullYear()}`;
        if (this.modelValue instanceof DateTime) {
          this.$emit(
            'update:modelValue',
            DateTime.fromFormat(dateString, 'd-M-yyyy'),
          );
        } else {
          this.$emit(
            'update:modelValue',
            DateTime.fromFormat(dateString, 'd-M-yyyy').toFormat(this.format),
          );
        }
      },
    },
    dateFormat(): string {
      // FIXME: HACK
      let format = this.format;
      format = this.format.replace('MM', 'mm');
      return format;
    },
  },
  methods: {
    onMonthChange(event: any) {
      this.$emit('month-change', event);
    },
    onYearChange(event: any) {
      this.$emit('year-change', event);
    },
    onShow() {
      this.$emit('show');
    },
    onDateSelect(value: Date) {
      this.$emit('date-select', value);
    },
  },
});
