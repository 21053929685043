
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { PriceListType } from '@/models/priceList';

export default defineComponent({
  name: 'PriceListsCreateView',
  data() {
    return {
      mounted: false,
      standardOption: PriceListType.STANDARD,
      seasonalOption: PriceListType.SEASONAL,
      extraServiceOption: PriceListType.EXTRA_SERVICES,
    };
  },
  mounted() {
    this.mounted = true;
  },
  methods: {
    async onPriceListWizard(type: PriceListType): Promise<void> {
      /**
       * New price list wizard
       */
      this.$router.push(`/price-lists/wizard?priceListType=${type}`);
    },
    onPriceListUpload(): void {
      /**
       * New price list with upload logic
       */
      this.$store.commit('priceLists/setShowPriceListCreateDialog', true);
    },
    onVoucherCreate(): void {
      /**
       * New voucher
       */
      this.$store.commit('priceLists/setShowVoucherCreateDialog', true);
    },
  },
  computed: {
    ...mapState('session', ['license']),
    ...mapGetters('priceLists', ['wizardEnabled']),
  },
});
