
import { defineComponent } from 'vue';
import { DateTime } from 'luxon';
import checkoutPointService from '@/services/checkoutPointService';
import { CheckoutPoint } from '@/models/checkoutPoint';
import { DropdownOption } from '@/models';
import { SPIAGGE_COOKIES } from '@/models/cookies';
import cookieUtil from '@/utils/cookieUtil';

export default defineComponent({
  name: 'TopBarCheckoutPointSelector',
  data() {
    return {
      visible: false,
      checkoutPoints: [] as Array<CheckoutPoint>,
      checkoutPointOptions: [] as Array<DropdownOption>,
      selectedCheckoutPointId: null as unknown as number,
      selectedCheckoutPointCookie: SPIAGGE_COOKIES.CHECKOUT_POINT,
    };
  },
  async beforeMount(): Promise<void> {
    try {
      this.checkoutPoints = await checkoutPointService.list();

      this.checkoutPointOptions = this.checkoutPoints.map(
        (checkoutPoint: CheckoutPoint) => ({
          label: checkoutPoint.name,
          value: checkoutPoint.id,
        }),
      );
    } catch {
      this.$spiagge.toast.error(
        this.$t('topBarCheckoutPointSelector.toast.error'),
      );
    }
  },
  mounted(): void {
    if (!this.checkoutPointSelected) {
      this.visible = true;
    }
  },
  methods: {
    close(): void {
      this.visible = false;
    },
    onSelected(id: number): void {
      const checkoutPoint = this.checkoutPoints.find(
        (c: CheckoutPoint) => c.id === id,
      );

      if (checkoutPoint) {
        const configuration = Object.entries(checkoutPoint.configuration);
        configuration.forEach(([printerCookieName, printerCookieValue]) => {
          if (printerCookieValue === '0') {
            // Printer active
            cookieUtil.remove(printerCookieName);
          } else {
            // Printer hidden
            cookieUtil.set(printerCookieName, printerCookieValue);
          }
        });

        // Checkout Point selected
        const expires = DateTime.now().endOf('day').toJSDate();
        cookieUtil.set(
          this.selectedCheckoutPointCookie,
          id.toString(),
          expires,
        );
      }
      this.close();
    },
    hideCallback(): void {
      if (this.selectedCheckoutPointId && !this.checkoutPointSelected) {
        this.selectedCheckoutPointId = null as unknown as number;
      }
    },
  },
  computed: {
    checkoutPointSelected(): boolean {
      const selectionCookie = cookieUtil.get(this.selectedCheckoutPointCookie);
      return selectionCookie !== undefined;
    },
  },
});
