
import { cloneDeep, difference } from 'lodash';
import { DateTime } from 'luxon';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'FieldPriceConfigHolidays',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Array as PropType<Array<string>>,
      required: false,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      year: undefined as unknown as number,
      minDate: undefined as unknown as DateTime,
      maxDate: undefined as unknown as DateTime,
    };
  },
  beforeMount(): void {
    this.year =
      DateTime.utc().month < 9 ? DateTime.utc().year : DateTime.utc().year + 1;
    this.minDate = DateTime.fromFormat(`01-01-${this.year}`, 'dd-MM-yyyy');
    this.maxDate = DateTime.fromFormat(`31-12-${this.year}`, 'dd-MM-yyyy');
  },
  computed: {
    model: {
      get(): Array<Date> {
        const dates: Array<Date> = [];
        if (!this.modelValue) return dates;
        this.modelValue.forEach((date: string) => {
          dates.push(
            DateTime.fromFormat(
              `${date}-${this.year}`,
              'dd-MM-yyyy',
            ).toJSDate(),
          );
        });
        return dates;
      },
      set(dates: Array<Date>): void {
        const model = this.modelValue ? cloneDeep(this.modelValue) : [];
        const stringDates: Array<string> = [];

        dates.forEach((jsDate: Date) => {
          const day = `${jsDate.getDate() < 10 ? '0' : ''}${jsDate.getDate()}`;
          const month = `${jsDate.getMonth() + 1 < 10 ? '0' : ''}${
            jsDate.getMonth() + 1
          }`;
          stringDates.push(`${day}-${month}`);
        });

        // move added date at start
        const addedDate = difference(stringDates, model ?? []);
        const updatedModel =
          addedDate.length > 0 ? [...addedDate, ...model] : stringDates;
        this.$emit('update:modelValue', updatedModel);
      },
    },
  },
});
