import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11c15eb9"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field-price-config-periods" }
const _hoisted_2 = {
  key: 0,
  class: "periods"
}
const _hoisted_3 = { class: "grid" }
const _hoisted_4 = { class: "col-12 sm:col-6" }
const _hoisted_5 = { class: "period-name" }
const _hoisted_6 = { class: "col-12 sm:col-6" }
const _hoisted_7 = { class: "period-intervals" }
const _hoisted_8 = { class: "grid" }
const _hoisted_9 = { class: "col-5" }
const _hoisted_10 = { class: "interval-start" }
const _hoisted_11 = { class: "col-5" }
const _hoisted_12 = { class: "interval-end" }
const _hoisted_13 = { class: "col-2 col-action" }
const _hoisted_14 = { class: "wrapper" }
const _hoisted_15 = {
  key: 0,
  class: "pi pi-exclamation-triangle p-error"
}
const _hoisted_16 = { class: "add-period" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Message = _resolveComponent("Message")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.periods.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.periods, (period, indexPeriod) => {
            return (_openBlock(), _createElementBlock("div", {
              key: indexPeriod,
              class: "period"
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("label", {
                      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
                    }, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('fieldPriceConfigPeriods.period')) + " " + _toDisplayString(indexPeriod + 1), 1),
                      _createVNode(_component_Button, {
                        class: "p-button-rounded p-button-danger p-button-text",
                        icon: "spi-trash",
                        onClick: ($event: any) => (_ctx.onRemovePeriod(indexPeriod)),
                        disabled: _ctx.disabled
                      }, null, 8, ["onClick", "disabled"])
                    ]),
                    _createVNode(_component_InputText, {
                      type: "text",
                      modelValue: period.name,
                      onChange: ($event: any) => (_ctx.onNameChange($event, indexPeriod)),
                      disabled: _ctx.disabled
                    }, null, 8, ["modelValue", "onChange", "disabled"])
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(period.intervals, (interval, indexInterval) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: `interval-${indexInterval}`,
                        class: _normalizeClass(["interval", {
                  overlapping: _ctx.overlappingIntervals.includes(
                    `P${indexPeriod + 1}_I${indexInterval + 1}`,
                  ),
                }])
                      }, [
                        _createElementVNode("div", _hoisted_8, [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("div", _hoisted_10, [
                              _createElementVNode("label", null, _toDisplayString(_ctx.$t('common.from')) + ":", 1),
                              _createVNode(_component_Calendar, {
                                ref: `P${indexPeriod}_I${indexInterval}_ST`,
                                modelValue: _ctx.getCalendarModel(interval.startDate),
                                dateFormat: "dd/mm",
                                minDate: _ctx.minDate,
                                maxDate: _ctx.maxDate,
                                selectionMode: "single",
                                onDateSelect: ($event: any) => (
                          _ctx.onDateSelect(
                            $event,
                            indexPeriod,
                            indexInterval,
                            'startDate',
                          )
                        ),
                                disabled: _ctx.disabled
                              }, null, 8, ["modelValue", "minDate", "maxDate", "onDateSelect", "disabled"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_11, [
                            _createElementVNode("div", _hoisted_12, [
                              _createElementVNode("label", null, _toDisplayString(_ctx.$t('common.to')) + ":", 1),
                              _createVNode(_component_Calendar, {
                                ref: `P${indexPeriod}_I${indexInterval}_ED`,
                                modelValue: _ctx.getCalendarModel(interval.endDate),
                                dateFormat: "dd/mm",
                                minDate: _ctx.getCalendarMinDate(interval.startDate),
                                maxDate: _ctx.maxDate,
                                selectionMode: "single",
                                onDateSelect: ($event: any) => (
                          _ctx.onDateSelect(
                            $event,
                            indexPeriod,
                            indexInterval,
                            'endDate',
                          )
                        ),
                                disabled: _ctx.disabled || !interval.startDate
                              }, null, 8, ["modelValue", "minDate", "maxDate", "onDateSelect", "disabled"])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                              _createVNode(_component_Button, {
                                class: "p-button-rounded p-button-danger p-button-text",
                                icon: "spi-trash",
                                onClick: ($event: any) => (_ctx.onRemoveInterval(indexPeriod, indexInterval)),
                                disabled: _ctx.disabled || indexInterval === 0
                              }, null, 8, ["onClick", "disabled"]),
                              (
                          _ctx.overlappingIntervals.includes(
                            `P${indexPeriod + 1}_I${indexInterval + 1}`,
                          )
                        )
                                ? (_openBlock(), _createElementBlock("i", _hoisted_15))
                                : _createCommentVNode("", true)
                            ])
                          ])
                        ])
                      ], 2))
                    }), 128)),
                    _createVNode(_component_Button, {
                      label: _ctx.$t('fieldPriceConfigPeriods.addInterval'),
                      class: "add-interval-button p-button-outlined",
                      icon: "spi-plus",
                      onClick: ($event: any) => (_ctx.onAddInterval(indexPeriod)),
                      disabled: _ctx.disabled
                    }, null, 8, ["label", "onClick", "disabled"])
                  ])
                ])
              ])
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createBlock(_component_Message, {
          key: 1,
          severity: "info",
          closable: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('fieldPriceConfigPeriods.noPeriodMessage')), 1)
          ]),
          _: 1
        })),
    _createElementVNode("div", _hoisted_16, [
      _createVNode(_component_Button, {
        class: "add-period-button p-button-outlined",
        label: _ctx.$t('fieldPriceConfigPeriods.addPeriod'),
        icon: "spi-plus",
        onClick: _ctx.onAddPeriod,
        disabled: _ctx.disabled
      }, null, 8, ["label", "onClick", "disabled"])
    ]),
    (_ctx.overlapping && !_ctx.hasErrors)
      ? (_openBlock(), _createBlock(_component_Message, {
          key: 2,
          severity: "warn",
          closable: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('fieldPriceConfigPeriods.overlappingMessage')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.yearFulfilled && !_ctx.hasErrors)
      ? (_openBlock(), _createBlock(_component_Message, {
          key: 3,
          severity: "warn",
          closable: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('fieldPriceConfigPeriods.yearFulfilledMessage')), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}