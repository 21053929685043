import axios, { AxiosResponse } from 'axios';
import {
  ApiWebticOnsiteEmitTicketResponse,
  ApiWebticOnsiteEmitTicketPayload,
  ApiWebticMarkTicketResponse,
  ApiWebticMarkTicketPayload,
} from '@/models/api';
import apiService from './apiService';
import { WebticTicket } from '@/models/webtic';

export default {
  async emitTickets(
    payload: ApiWebticOnsiteEmitTicketPayload,
  ): Promise<Array<WebticTicket>> {
    const res: AxiosResponse<ApiWebticOnsiteEmitTicketResponse> =
      await axios.post(apiService.webtic.onsiteEmitTickets(), payload);
    return res.data.result.tickets;
  },
  async markTicket(payload: ApiWebticMarkTicketPayload): Promise<null> {
    const res: AxiosResponse<ApiWebticMarkTicketResponse> = await axios.post(
      apiService.webtic.markTicket(),
      payload,
    );
    return null;
  },
};
