import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-863a428e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "revenue-management-analyst-view",
  class: "revenue-management-view view"
}
const _hoisted_2 = {
  key: 0,
  id: "periods-panels"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_RevenueManagementHistoricalDetails = _resolveComponent("RevenueManagementHistoricalDetails")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_RevenueManagementPeriodPanel = _resolveComponent("RevenueManagementPeriodPanel")!
  const _component_RevenueManagerOverrideRulePanel = _resolveComponent("RevenueManagerOverrideRulePanel")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_ctx.periods)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("header", null, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.$t('revenueManagementAnalystView.title')), 1),
          (
          _ctx.permissionsUtil.isActionPermissionAllowed(
            _ctx.FEATURE_PERMISSION_CONFIG.revenue_manager,
            _ctx.FEATURE_PERMISSION_ACTION_CONFIG.revenue_manager.MANAGER_PANEL,
          )
        )
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/revenue-management/planner'))),
                class: "p-button-outlined",
                icon: "spi-planner",
                label: _ctx.$t('revenueManagementAnalystView.priceManagement')
              }, null, 8, ["label"]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_TabView, { id: "tabs" }, {
          default: _withCtx(() => [
            _createVNode(_component_TabPanel, {
              header: _ctx.$t('revenueManagementAnalystView.dataAnalysis')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_RevenueManagementHistoricalDetails)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_TabPanel, {
              header: _ctx.$t('revenueManagementAnalystView.baseRules')
            }, {
              default: _withCtx(() => [
                (_ctx.periods)
                  ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.periods, (period, periodIndex) => {
                        return (_openBlock(), _createBlock(_component_RevenueManagementPeriodPanel, {
                          key: periodIndex,
                          rules: _ctx.periodRules
      .filter(
        (baseRule) => baseRule.periodId === period.id
      )
      .sort(
        (a, b) =>
          b.applicationThreshold - a.applicationThreshold
      )
      ,
                          period: period,
                          onCreate: _ctx.getData,
                          onUpdate: _ctx.getData,
                          onDelete: _ctx.getData
                        }, null, 8, ["rules", "period", "onCreate", "onUpdate", "onDelete"]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_TabPanel, {
              header: _ctx.$t('revenueManagementAnalystView.overrideRules')
            }, {
              default: _withCtx(() => [
                _createVNode(_component_RevenueManagerOverrideRulePanel, {
                  baseRules: _ctx.baseRules,
                  onGetData: _ctx.getData
                }, null, 8, ["baseRules", "onGetData"])
              ]),
              _: 1
            }, 8, ["header"])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}