
import { defineComponent } from 'vue';

export default defineComponent({
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    trueLabel: {
      type: String,
      required: true,
    },
    falseLabel: {
      type: String,
      required: true,
    },
    buttonWidth: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    onClick(value: boolean): void {
      this.$emit('update:modelValue', value);
    },
  },
});
