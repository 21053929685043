/**
 * Axios plugin
 */

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import store from '@/store';
import { AppRequestStatus, APP_REQUEST_PENDING_TIMEOUT } from '@/models/app';
import { SPIAGGE_COOKIES } from '@/models/cookies';
import cookieUtil from '@/utils/cookieUtil';

export default function start(): void {
  axios.interceptors.request.use(
    async (c: AxiosRequestConfig) => {
      /* const headers: AxiosRequestHeaders = {
        ...c?.headers,
      }; */
      const config = { ...c, headers: {} as Record<string, string> };
      config.headers['Content-Type'] =
        c.headers?.['Content-Type'] ?? 'application/json';
      config.headers.Accept = c.headers?.Accept ?? 'application/json';
      config.headers['Accept-Language'] = cookieUtil.get(SPIAGGE_COOKIES.LOCALE) ?? 'it';
      // config.headers['Timezone-Offset'] = store.getters['session/timezone'].offset;
      config.timeout = 30000;
      config.withCredentials = true;
      store.commit('app/setRequestStatus', AppRequestStatus.STARTED);
      store.commit('app/httpRequestStart', config.url);
      // setTimeout(() => {
      //   if (store.getters['app/requestStatus'] === AppRequestStatus.STARTED) {
      //     store.commit('app/setRequestStatus', AppRequestStatus.PENDING);
      //   }
      // }, APP_REQUEST_PENDING_TIMEOUT);
      return config;
    },
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      store.commit('app/setRequestStatus', AppRequestStatus.NONE);
      store.commit('app/httpRequestEnd', response.config.url);
      return response;
    },
    (error: AxiosError) => {
      store.commit('app/setRequestStatus', AppRequestStatus.NONE);
      store.commit('app/httpRequestEnd', error.config.url);
      if (!error.response) {
        const errorString = error.toString();
        if (errorString === 'Error: Network Error') {
          // serviceUnavailable
        }
        if (errorString === 'Error: timeout of 30000ms exceeded') {
          // requestTimeout
        }
        return Promise.reject(error);
      }
      if (error.response.status === 401) {
        window.location.href = store.getters['session/links'].logout;
      }
      return Promise.reject(error);
    },
  );
}
