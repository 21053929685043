import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-296cf7ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "stripe-refund refund-type"
}
const _hoisted_2 = {
  key: 1,
  class: "code-refund refund-type"
}
const _hoisted_3 = { class: "discount-code" }
const _hoisted_4 = { class: "value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    id: "map-refund-confirmation",
    class: "custom-dialog",
    modal: true,
    visible: "",
    onHide: _ctx.onConfirm,
    "onUpdate:visible": _ctx.onConfirm
  }, {
    default: _withCtx(() => [
      _createElementVNode("h3", null, _toDisplayString(_ctx.$t('mapRefundConfirmation.title', {
          reservationId: _ctx.refundConfirmation.reservationId,
        })), 1),
      (_ctx.isStipeRefund)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('mapRefundConfirmation.stripeRefundMessage')), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.isCodeRefund)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t('mapRefundConfirmation.refundCodeMessage')), 1),
            _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.refundConfirmation.discountCode.code), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.$t('mapRefundConfirmation.refundValue')) + ": " + _toDisplayString(_ctx.refundConfirmation.value) + "€ ", 1),
      _createVNode(_component_Button, {
        label: _ctx.$t('mapRefundConfirmation.confirmLabel'),
        iconPos: "right",
        icon: "spi-check",
        onClick: _ctx.onConfirm
      }, null, 8, ["label", "onClick"])
    ]),
    _: 1
  }, 8, ["onHide", "onUpdate:visible"]))
}