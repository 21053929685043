import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.isJointAccount)
    ? (_openBlock(), _createBlock(_component_Button, {
        key: 0,
        label: _ctx.label,
        icon: "spi-ungroup",
        class: "p-button-outlined disjoin-account-button",
        onClick: _ctx.onDisjoinAccount,
        disabled: !_ctx.canDisjoinAccount
      }, null, 8, ["label", "onClick", "disabled"]))
    : _createCommentVNode("", true)
}