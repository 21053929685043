import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { id: "top-bar-other-services" }
const _hoisted_2 = { class: "services" }
const _hoisted_3 = { class: "content" }
const _hoisted_4 = {
  key: 0,
  class: "menu"
}
const _hoisted_5 = { class: "grid" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      class: "p-button-outlined p-button-rounded p-button-white app-button",
      icon: "spi-apps",
      iconPos: "right",
      onClick: _ctx.toggle
    }, null, 8, ["onClick"]),
    _createVNode(_component_OverlayPanel, {
      ref: "op",
      id: "top-bar-other-services-overlay-panel"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Button, {
            icon: "pi pi-times",
            class: "p-button-rounded p-button-text p-button-plain close-button",
            onClick: _ctx.toggle
          }, null, 8, ["onClick"]),
          _createElementVNode("header", null, [
            _createElementVNode("h5", null, _toDisplayString(_ctx.$t('topBarOtherServices.title')), 1)
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_ctx.menu.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "col-4"
                      }, [
                        _createElementVNode("div", {
                          class: "item",
                          onClick: ($event: any) => (item.cta ? item.cta() : () => {})
                        }, [
                          _createElementVNode("img", {
                            src: item.icon,
                            alt: ""
                          }, null, 8, _hoisted_7),
                          _createElementVNode("span", _hoisted_8, _toDisplayString(item.label), 1)
                        ], 8, _hoisted_6)
                      ]))
                    }), 128))
                  ])
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _: 1
    }, 512)
  ]))
}