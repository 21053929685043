/* eslint-disable no-template-curly-in-string */
/* eslint-disable import/first */
import { createApp } from 'vue';
/* prime vue */
import PrimeVue from 'primevue/config';
import 'primeflex/primeflex.scss';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import Avatar from 'primevue/avatar';
import Badge from 'primevue/badge';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import FileUpload from 'primevue/fileupload';
import ToggleButton from 'primevue/togglebutton';
import InlineMessage from 'primevue/inlinemessage';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Listbox from 'primevue/listbox';
import Menu from 'primevue/menu';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import Panel from 'primevue/panel';
import ScrollPanel from 'primevue/scrollpanel';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Row from 'primevue/row';
import ColumnGroup from 'primevue/columngroup';
import Paginator from 'primevue/paginator';
import RadioButton from 'primevue/radiobutton';
import SelectButton from 'primevue/selectbutton';
import TabMenu from 'primevue/tabmenu';
import TabPanel from 'primevue/tabpanel';
import TabView from 'primevue/tabview';
import Tag from 'primevue/tag';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import ProgressBar from 'primevue/progressbar';
import Password from 'primevue/password';
import OverlayPanel from 'primevue/overlaypanel';
import TooltipDirective from 'primevue/tooltip';
import BadgeDirective from 'primevue/badgedirective';
import ConfirmationService from 'primevue/confirmationservice';
import Skeleton from 'primevue/skeleton';
import ContextMenu from 'primevue/contextmenu';
import Ripple from 'primevue/ripple';
import Timeline from 'primevue/timeline';
import Sidebar from 'primevue/sidebar';
import SplitButton from 'primevue/splitbutton';
import ProgressSpinner from 'primevue/progressspinner';

// validators
import { defineRule, configure } from 'vee-validate';
import Rules from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
// Luxon config
import { Settings } from 'luxon';

Settings.defaultZone = 'utc';
Settings.defaultLocale = 'it';

// yup configuration
import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: 'Campo richiesto',
  },
  array: {
    min: 'Il campo deve avere almeno ${min} elemento/i',
  },
});

import router from './router';

import App from './App.vue';
import './registerServiceWorker';
import store from './store';
/** i18n */
import i18n from './i18n';
import PrimeLocale from '@/i18n/primevue';
/* plugins */
import axios from '@/plugins/axios';
import spiagge from '@/plugins/spiagge';
import '@/assets/scss/style.scss';

/** Android util */
import * as androidInterfaceIncludes from './utils/androidUtil.js';

Object.assign(window, androidInterfaceIncludes);

/** Electron util */
import electronUtil from './utils/electronUtil';

Object.keys(Rules).forEach((rule: string) => {
  defineRule(rule, Rules[rule]);
});

configure({
  validateOnInput: true,
  generateMessage: localize('it', {
    messages: {
      required: 'Il campo è richiesto',
    },
  }),
});

axios();

/** Attach electron message listener */
electronUtil.attachElectronListener();

/* app / components */
const app = createApp(App);
app.use(router);
app.use(store);
app.use(i18n);
app.use(spiagge);
app.use(PrimeVue, {
  ripple: true,
  inputStyle: 'outlined',
  locale: PrimeLocale.it,
});

/** PRIMEVUE */
app.component('Avatar', Avatar);
app.component('Badge', Badge);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dropdown', Dropdown);
app.component('FileUpload', FileUpload);
app.component('ToggleButton', ToggleButton);
app.component('InlineMessage', InlineMessage);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Listbox', Listbox);
app.component('Menu', Menu);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('Panel', Panel);
app.component('ScrollPanel', ScrollPanel);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Row', Row);
app.component('ColumnGroup', ColumnGroup);
app.component('Paginator', Paginator);
app.component('RadioButton', RadioButton);
app.component('SelectButton', SelectButton);
app.component('TabMenu', TabMenu);
app.component('TabPanel', TabPanel);
app.component('TabView', TabView);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('Toast', Toast);
app.component('ProgressBar', ProgressBar);
app.component('Password', Password);
app.component('OverlayPanel', OverlayPanel);
app.component('Skeleton', Skeleton);
app.component('ContextMenu', ContextMenu);
app.component('Timeline', Timeline);
app.component('Sidebar', Sidebar);
app.component('SplitButton', SplitButton);
app.component('ProgressSpinner', ProgressSpinner);

app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);
app.directive('tooltip', TooltipDirective);

app.use(ToastService);
app.use(ConfirmationService);

app.mount('#spiagge');
