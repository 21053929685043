import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a3283b98"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "statistics-stripe-transfers-view",
  class: "statistics-view view"
}
const _hoisted_2 = { id: "cards-slider" }
const _hoisted_3 = { id: "table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsCard = _resolveComponent("StatisticsCard")!
  const _component_Slider = _resolveComponent("Slider")!
  const _component_StatisticsDataTable = _resolveComponent("StatisticsDataTable")!
  const _component_ShowInvoiceDialog = _resolveComponent("ShowInvoiceDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_Slider, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topCards, (card, index) => {
            return (_openBlock(), _createBlock(_component_StatisticsCard, {
              key: index,
              label: card.label,
              value: card.value
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("section", _hoisted_3, [
      _createVNode(_component_StatisticsDataTable, {
        id: _ctx.id,
        columns: _ctx.columns,
        searchColumns: _ctx.searchColumns,
        defaultView: _ctx.defaultView,
        endpoint: _ctx.endpoint,
        basePayload: _ctx.basePayload,
        onRowClick: _ctx.rowClick,
        rowClass: _ctx.rowClass,
        exportEndpoint: _ctx.exportEndpoint,
        onResult: _ctx.onResult
      }, {
        tableAfter: _withCtx(() => [
          _createVNode(_component_Slider, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bottomCards, (card, index) => {
                return (_openBlock(), _createBlock(_component_StatisticsCard, {
                  key: index,
                  label: card.label,
                  value: card.value,
                  subValue: card.subValue
                }, null, 8, ["label", "value", "subValue"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["id", "columns", "searchColumns", "defaultView", "endpoint", "basePayload", "onRowClick", "rowClass", "exportEndpoint", "onResult"])
    ]),
    (_ctx.openInvoiceDialog && _ctx.selectedInvoiceId)
      ? (_openBlock(), _createBlock(_component_ShowInvoiceDialog, {
          key: 0,
          "preinvoice-id": _ctx.selectedInvoiceId,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onShowInvoiceDialogClose()))
        }, null, 8, ["preinvoice-id"]))
      : _createCommentVNode("", true)
  ]))
}