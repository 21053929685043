/* eslint-disable import/no-cycle */
/**
 * Smart Search store module
 */

import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';
import { cloneDeep } from 'lodash';
import {
  RootState,
  SmartSearchState,
  DEFAULT_SMART_SEARCH_STATE,
} from '@/models/store';
import smartSearchService from '@/services/smartSearchService';
import { ApiSmartSearchPayload } from '@/models/api';
import { SmartSearchModel, SmartSearchSpot } from '@/models/smartSearch';

type AppContext = ActionContext<SmartSearchState, RootState>;

const namespaced = true;

const state = (): SmartSearchState => cloneDeep(DEFAULT_SMART_SEARCH_STATE);

const getters: GetterTree<SmartSearchState, RootState> = {
  // isSmart: (state) =>
  //   state.model?.maxSwaps !== undefined && state.model?.sectors !== undefined,
  isRunning: (state) => state.model !== null,
  suggestionAvailable: (state) =>
    state.model !== null && state.model.smartSearch && state.spots.length > 0,
} as GetterTree<SmartSearchState, RootState>;

const mutations: MutationTree<SmartSearchState> = {
  setModel(state: SmartSearchState, model: SmartSearchModel) {
    state.model = cloneDeep(model);
  },
  setActive(state: SmartSearchState, active: boolean) {
    state.active = active;
  },
  setSpots(state: SmartSearchState, spots: Array<SmartSearchSpot>) {
    state.spots = spots;
  },
  reset(state: SmartSearchState) {
    Object.assign(state, cloneDeep(DEFAULT_SMART_SEARCH_STATE));
  },
} as MutationTree<SmartSearchState>;

const actions: ActionTree<SmartSearchState, RootState> = {
  async runSmartSearch(
    context: AppContext,
    payload: ApiSmartSearchPayload,
  ): Promise<void> {
    const spots = await smartSearchService.smartSearch(payload);
    context.commit('setSpots', spots);
    context.commit('setActive', true);
  },
} as ActionTree<SmartSearchState, RootState>;

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
