
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import Legend from '@/components/shared/Legend.vue';
import { colorSpecs } from '@/models/color';

export default defineComponent({
  name: 'PlannerLegend',
  components: {
    Legend,
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    toggleLegend() {
      this.open = !this.open;
    },
    getLegendItemStyle(item: string) {
      let colorSpec = colorSpecs.get('default');
      switch (item) {
        case 'notConfirmed':
          colorSpec = colorSpecs.get(this.license.mapColorTemporary);
          break;
        case 'halfDayMorning':
          colorSpec = colorSpecs.get(this.license.mapColorHalfDayMorning);
          break;
        case 'halfDayAfternoon':
          colorSpec = colorSpecs.get(this.license.mapColorHalfDayAfternoon);
          break;
        case 'booked':
          colorSpec = colorSpecs.get(this.license.mapColorReserved);
          break;
        case 'seasonal':
          colorSpec = colorSpecs.get(this.license.mapColorSeasonal);
          break;
        // case 'overbooking':
        //   return {
        //     border: '2px solid #FF0000',
        //     backgroundColor: '#FF000080',
        //   };
        case 'unpaid':
          colorSpec = colorSpecs.get(this.license.mapColorNotPaid);
          break;
        // case 'sharing':
        //   return {
        //     border: `2px solid ${colorUtil.licenseColorToRGB(
        //       this.license.mapColorUmbSharing as UmbrellaColor)}`,
        //     backgroundColor: `${colorUtil.licenseColorToRGB(
        //       this.license.mapColorUmbSharing as UmbrellaColor)}80`,
        //   };
        default:
      }
      return { backgroundColor: colorSpec?.rgb ?? '' };
    },
  },
  computed: {
    ...mapState('session', ['license']),
  },
});
