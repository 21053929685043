import { PriceWizardStep, Step } from '@/models/priceConfig';
import i18n from '@/i18n';

const STEP_WITH_TABS: Array<PriceWizardStep> = [
  PriceWizardStep.PERIODS,
];

export default {
  getPropertyName(step: PriceWizardStep): string {
    switch (step) {
      case PriceWizardStep.PERIODS: return 'periodStep';
      case PriceWizardStep.PRICES: return 'priceStep';
      // Basic step
      default: return 'basicStep';
    }
  },
  getStepNumber(step: PriceWizardStep, totalSteps: number): number {
    switch (step) {
      case PriceWizardStep.PERIODS: return 2;
      case PriceWizardStep.PRICES: return totalSteps === 2 ? 2 : 3;
      // Basic step
      default: return 1;
    }
  },
  getStepLabel(step: PriceWizardStep): string {
    switch (step) {
      case PriceWizardStep.PERIODS: return i18n.global.t('priceListsWizardView.step.periods');
      case PriceWizardStep.PRICES: return i18n.global.t('priceListsWizardView.step.prices');
      // Basic step
      default: return i18n.global.t('priceListsWizardView.step.settings');
    }
  },
  // returns 0 if the steps match, -1 if is a previous one and 1 if next
  compareStep(
    step: PriceWizardStep,
    stepToCompare: PriceWizardStep,
  ): number {
    // Just to simplify the switch
    if (step === stepToCompare) return 0;
    // Mismatching
    switch (step) {
      case PriceWizardStep.BASIC:
        return -1;
      case PriceWizardStep.PERIODS:
        return stepToCompare === PriceWizardStep.BASIC ? 1 : -1;
      case PriceWizardStep.PRICES:
        return 1;
      // This one should never reach
      default: return 0;
    }
  },
  initStepFromEnum(step: PriceWizardStep, totalSteps = 3): Step {
    return {
      value: step,
      label: this.getStepLabel(step),
      number: this.getStepNumber(step, totalSteps),
      action: () => {
        //
      },
    };
  },
  convertStepToString(
    step: PriceWizardStep,
    totalSteps: number,
  ): string {
    switch (step) {
      case PriceWizardStep.PERIODS:
        return 'two';
      case PriceWizardStep.PRICES:
        return totalSteps === 2 ? 'two' : 'three';
      // Default basic
      default: return 'one';
    }
  },
  hasTabs(step: PriceWizardStep): boolean {
    return STEP_WITH_TABS.includes(step);
  },
};
