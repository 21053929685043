
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import { Field, Form } from 'vee-validate';
import { DateTime } from 'luxon';
import { Warehouse } from '@/models/warehouse';
import warehouseService from '@/services/warehouseService';
import {
  ApiLicenseUpdatePayload,
  ApiWarehouseCreatePayload,
  ApiWarehouseUpdatePayload,
  ApiSectorListPayload,
} from '@/models/api';
import CalendarInput from '@/components/shared/CalendarInput.vue';
import { Sector, SectorHeader } from '@/models/sector';
import { DropdownOption } from '@/models';

type WarehouseModel = {
  name: string;
  beds: number;
  maxiBeds: number;
  deckChairs: number;
  chairs: number;
  sectors: Array<number>;
};

const DEFAULT_WAREHOUSE: WarehouseModel = {
  name: '',
  beds: 0,
  maxiBeds: 0,
  deckChairs: 0,
  chairs: 0,
  sectors: [],
};

export default defineComponent({
  name: 'SettingsWarehouseView',
  components: { Form, Field, CalendarInput },
  data() {
    return {
      warehouses: [] as Array<Warehouse>,
      date: null,
      warehouseAlerts: false,
      warehouseOnlineBlock: false,
      warehouseCalendar: DateTime.now().startOf('day'),
      warehouseEdit: 0,
      warehouseDialog: false,
      warehouse: null as unknown as WarehouseModel,
      loading: false,
    };
  },
  async beforeMount(): Promise<void> {
    await this.$store.dispatch('session/setSectors');
    this.loadData();
  },
  methods: {
    async loadData(): Promise<void> {
      try {
        this.warehouseAlerts = this.license.warehouseMode;
        this.warehouseOnlineBlock = this.license.warehouseOnline;
        this.warehouses = await warehouseService.list(
          this.warehouseCalendar.toSeconds(),
        );
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('settingsWarehouseView.toast.retrievalError'),
        );
      }
    },
    onWarehouseAdd(): void {
      this.reset();
      this.warehouse = cloneDeep(DEFAULT_WAREHOUSE);
      this.warehouseDialog = true;
    },
    onWarehouseEdit(warehouse: Warehouse): void {
      this.warehouseEdit = warehouse.id;
      const model: Partial<Warehouse> = cloneDeep(warehouse);
      delete model.id;
      delete model.licenseId;
      this.warehouse = model as unknown as WarehouseModel;
      this.warehouse.sectors = model.sectors
        ? model.sectors.map((sector: SectorHeader) => sector.id)
        : [];

      this.warehouseDialog = true;
    },
    onWarehouseSubmit(): void {
      if (this.isEdit) {
        this.onWarehouseUpdate();
      } else {
        this.onWarehouseCreate();
      }
    },
    async onWarehouseCreate(): Promise<void> {
      try {
        const warehouseCreatePayload: ApiWarehouseCreatePayload = {
          name: this.warehouse.name,
          beds: this.warehouse.beds,
          maxiBeds: this.warehouse.maxiBeds,
          deckChairs: this.warehouse.deckChairs,
          chairs: this.warehouse.chairs,
          sectors: this.warehouse.sectors,
        };

        await warehouseService.create(warehouseCreatePayload);

        // call GET again to obtain the counters (POST return entity without counters)
        this.loadData();
        this.$spiagge.toast.success(
          this.$t('settingsWarehouseView.toast.createSuccess'),
        );
        this.reset();
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('settingsWarehouseView.toast.createError'),
        );
      }
    },
    async onWarehouseUpdate(): Promise<void> {
      try {
        const warehouseUpdatePayload: ApiWarehouseUpdatePayload = {
          name: this.warehouse.name,
          beds: this.warehouse.beds,
          maxiBeds: this.warehouse.maxiBeds,
          deckChairs: this.warehouse.deckChairs,
          chairs: this.warehouse.chairs,
          sectors: this.warehouse.sectors,
        };

        await warehouseService.update(
          this.warehouseEdit,
          warehouseUpdatePayload,
        );
        // call GET again to obtain the counters (PATCH return entity without counters)
        this.loadData();
        this.$spiagge.toast.success(
          this.$t('settingsWarehouseView.toast.updateSuccess'),
        );
        this.reset();
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('settingsWarehouseView.toast.updateError'),
        );
      }
    },
    async onWarehouseDelete(warehouse: Warehouse): Promise<void> {
      this.$confirm.require({
        message: this.$t('settingsWarehouseView.confirm.message'),
        header: this.$t('settingsWarehouseView.confirm.title'),
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-danger p-button-outlined',
        acceptLabel: this.$t('button.delete'),
        rejectLabel: this.$t('button.cancel'),
        accept: async () => {
          try {
            await warehouseService.delete(warehouse.id);
            this.warehouses = this.warehouses.filter(
              (w: Warehouse) => w.id !== warehouse.id,
            );
            this.$spiagge.toast.success(
              this.$t('settingsWarehouseView.toast.deleteSuccess'),
            );
          } catch (e) {
            this.$spiagge.toast.error(
              this.$t('settingsWarehouseView.toast.deleteError'),
            );
          }
        },
      });
    },
    reset(): void {
      this.warehouseEdit = 0;
      this.warehouseDialog = false;
    },
    async onWarehouseOnlineBlockChange(value: boolean): Promise<void> {
      try {
        await this.$store.dispatch('session/updateLicense', {
          warehouseOnline: value,
        } as ApiLicenseUpdatePayload);
        this.warehouseOnlineBlock = this.license.warehouseOnline;
        this.$spiagge.toast.success(
          this.$t('settingsWarehouseView.toast.editSuccess'),
        );
      } catch (e) {
        this.$spiagge.toast.error(this.$t('settingsWarehouseView.toast.error'));
      }
    },
    async onWarehouseAlertsChange(value: boolean): Promise<void> {
      try {
        await this.$store.dispatch('session/updateLicense', {
          warehouseMode: value,
        } as ApiLicenseUpdatePayload);
        this.warehouseAlerts = this.license.warehouseMode;
        this.$spiagge.toast.success(
          this.$t('settingsWarehouseView.toast.editSuccess'),
        );
      } catch (e) {
        this.$spiagge.toast.error(this.$t('settingsWarehouseView.toast.error'));
      }
    },
    getStagingClass(counter: number, total: number): string {
      let stagingClass = '';
      if (total - counter <= 5) {
        stagingClass = 'low';
      }
      if (counter >= total) {
        stagingClass = 'empty';
      }
      return stagingClass;
    },
  },
  computed: {
    ...mapState('session', ['license', 'sectors']),
    isEdit(): boolean {
      return this.warehouseEdit > 0;
    },
    sectorOptions(): Array<DropdownOption> {
      return this.sectors.map((sector: Sector) => ({
        label: sector.header.name,
        value: sector.header.id,
      }));
    },
  },
  watch: {
    warehouseCalendar() {
      this.loadData();
    },
  },
});
