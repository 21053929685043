
import { PropType, defineComponent } from 'vue';
import { Form } from 'vee-validate';
import { mapGetters, mapState } from 'vuex';
import { DateTime } from 'luxon';
import { PriceAlteration } from '@/models/priceAlteration';
import { RevenueManagementPrices } from '@/models/revenueManagement';
import InputNumberThreshold from '@/components/shared/InputNumberThreshold.vue';
import { ApiRevenueManagementPriceAlterationCreatePayload } from '@/models/api';
import revenueManagementService from '@/services/revenueManagementService';

interface PriceAlterationModel {
  from: DateTime;
  to: DateTime;
  percentage: number;
  sectorIds: Array<number>;
}

export default defineComponent({
  name: 'RevenueManagementPriceAlterationDialog',
  components: {
    Form,
    InputNumberThreshold,
  },
  emits: ['close', 'save', 'delete'],
  props: {
    priceAlteration: {
      type: Object as PropType<PriceAlteration>,
      required: true,
    },
    prices: {
      type: Array as PropType<Array<RevenueManagementPrices>>,
      required: true,
    },
  },
  data() {
    return {
      dialogPrices: [...this.prices],
      ready: false,
      model: undefined as unknown as PriceAlterationModel,
      percentageSignError: false,
    };
  },
  beforeMount(): void {
    /**
     * Load options
     */
    this.model = {
      from: DateTime.fromSeconds(this.priceAlteration.day).startOf('day'),
      to: DateTime.fromSeconds(this.priceAlteration.day).startOf('day'),
      percentage: this.priceAlteration.percentageDiscount,
      sectorIds: [this.priceAlteration.sectorId],
    };

    this.ready = true;
  },
  methods: {
    onSectorChange(): void {
      this.model.sectorIds = this.model.sectorIds.sort(
        (a: number, b: number) => a - b,
      );
    },
    async onSave(): Promise<void> {
      this.$emit('save', {
        from: this.model.from.toSeconds(),
        to: this.model.to.toSeconds(),
        percentageDiscount: Number(this.model.percentage),
        sectorIds: this.model.sectorIds,
      } as ApiRevenueManagementPriceAlterationCreatePayload);
    },
    close(): void {
      /**
       * Close
       */
      this.$emit('close');
      this.visible = false;
    },
    getOldPrice(sectorId: number): number {
      const price = this.dialogPrices.find(
        (price: RevenueManagementPrices) =>
          price.day === this.model.from.toSeconds() &&
          price.sectorId === sectorId,
      );
      if (price) {
        return price.offlinePrice ?? 0;
      }
      return 0;
    },
    getNewPrice(sectorId: number): number {
      const price = this.dialogPrices.find(
        (price: RevenueManagementPrices) =>
          price.day === this.model.from.toSeconds() &&
          price.sectorId === sectorId,
      );
      if (price) {
        return this.$spiagge.utils.global.percentage(
          price.offlinePrice ?? 0,
          this.model.percentage ?? 0,
          true,
        );
      }
      return 0;
    },
    async onDateChanged(): Promise<void> {
      try {
        this.dialogPrices = await revenueManagementService.prices({
          from: DateTime.fromJSDate(this.from).startOf('day').toSeconds(),
          to: DateTime.fromJSDate(this.to).startOf('day').toSeconds(),
        });
      } catch (e) {
        this.$spiagge.toast.error(
          this.$t('revenueManagementPlannerView.toast.pricesRetrievalError'),
        );
      }
    },
  },
  computed: {
    ...mapState('app', ['processing']),
    ...mapGetters('session', ['sectors']),
    from: {
      get(): Date {
        return this.model.from.toJSDate();
      },
      set(date: Date): void {
        const dateString = `${date.getDate()}-${
          date.getMonth() + 1
        }-${date.getFullYear()}`;
        this.model.from = DateTime.fromFormat(dateString, 'd-M-yyyy');
        if (this.model.from > this.model.to) {
          this.model.to = this.model.from;
        }
        this.onDateChanged();
      },
    },
    to: {
      get(): Date {
        return this.model.to.toJSDate();
      },
      set(date: Date): void {
        const dateString = `${date.getDate()}-${
          date.getMonth() + 1
        }-${date.getFullYear()}`;
        this.model.to = DateTime.fromFormat(dateString, 'd-M-yyyy');
        this.onDateChanged();
      },
    },
    visible: {
      get(): boolean {
        return true;
      },
      set(): void {
        this.$emit('close');
      },
    },
  },
});
