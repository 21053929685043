
import { defineComponent, PropType } from 'vue';
import { mapState } from 'vuex';
import { DateTime } from 'luxon';
import FieldPriceConfigHolidays from '@/components/dynamic-form/fields/FieldPriceConfigHolidays.vue';
import FieldPriceConfigPeriods from '@/components/dynamic-form/fields/FieldPriceConfigPeriods.vue';
import FieldPriceConfigCombinations from '@/components/dynamic-form/fields/FieldPriceConfigCombinations.vue';
import FieldPriceConfigThresholdPercentageValues from '@/components/dynamic-form/fields/FieldPriceConfigThresholdPercentageValues.vue';

import { DropdownOption } from '@/models';
import {
  Field,
  FieldVisibilityCondition,
  FieldVisibilityConditionOperator,
  FieldVisibilityConditionConnectorType,
  FieldVisibilityConditionComparison,
  FieldVisibilityConditionGroup,
} from '@/models/field';
import FieldPriceConfigSectorCombinationPieces
  from '@/components/dynamic-form/fields/FieldPriceConfigSectorCombinationPieces.vue';

export default defineComponent({
  name: 'FieldBinder',
  emits: ['update:modelValue'],
  components: {
    FieldPriceConfigSectorCombinationPieces,
    FieldPriceConfigHolidays,
    FieldPriceConfigPeriods,
    FieldPriceConfigCombinations,
    FieldPriceConfigThresholdPercentageValues,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array as PropType<Array<Field>>,
      required: true,
    },
    types: {
      type: Object as PropType<{ [key: string]: string }>,
      required: true,
    },
    errors: {
      type: Object as PropType<{ [key: string]: string }>,
      required: false,
    },
    data: {
      type: Object as PropType<{ [key: string]: any }>,
      required: false,
    },
  },
  data() {
    return {
      fieldsVisibility: {} as { [key: string]: boolean },
    };
  },
  beforeMount() {
    this.fields.forEach((field: Field) => {
      this.fieldsVisibility[field.n] = false;
    });
  },
  mounted() {
    this.checkFieldsVisibility();
  },
  methods: {
    getDropdownOptions(field: Field): Array<DropdownOption> {
      return field.o.map((option: any) => ({
        label: this.$t(`dynamicForm.${this.id}.${field.n}.options.${option}`),
        value: option,
      }));
    },
    getFieldConfigCombinations(options: Array<Field>): Array<string> {
      return options.map((option: Field) => option.n) as Array<string>;
    },
    getNestedFields(field: Field): Array<Field> {
      return field.o as Array<Field>;
    },
    checkFieldsVisibility(): void {
      this.fields.forEach((field: Field) => {
        if (!field.vc || field.vc.length === 0) {
          this.fieldsVisibility[field.n] = true;
        } else {
          this.fieldsVisibility[field.n] = this.getFieldVisibility(field);
        }
      });
    },
    getFieldVisibility(field: Field): boolean {
      let conditions = field.vc;
      // Input check
      if (!conditions || conditions.length === 0) {
        return true;
      }
      // Wrap in AND condition if we don't have a single AND connector as only element
      if (conditions.length > 1) {
        conditions = [
          {
            conn: 'AND',
            children: conditions,
          },
        ] as Array<FieldVisibilityCondition>;
      }
      return this.checkVisibilityConditions(conditions[0]);
    },
    checkVisibilityConditions(node: FieldVisibilityCondition): boolean {
      // Base case of recursion, we evaluate an 'operator' condition
      if ('operator' in node) {
        return this.checkVisibilityConditionComparison(node);
      }

      // Recursively evaluate node children with correct logical operator
      return this.checkVisibilityConditionGroup(node);
    },
    checkVisibilityConditionComparison(
        node: FieldVisibilityConditionComparison,
    ): boolean {
      switch (node.operator) {
        case FieldVisibilityConditionOperator.EQUAL:
        default:
          return this.modelValue[node.field] === node.value;
      }
    },
    checkVisibilityConditionGroup(
        node: FieldVisibilityConditionGroup,
    ): boolean {
      if (!node.children || node.children.length === 0) {
        return true;
      }
      let result: boolean;
      switch (node.conn) {
        case FieldVisibilityConditionConnectorType.OR:
          result = node.children.reduce(
              (carry, child) => carry || this.checkVisibilityConditions(child),
              false,
          ) as unknown as boolean;
          break;
        case FieldVisibilityConditionConnectorType.AND:
        default:
          result = node.children.reduce(
              (carry, child) => carry && this.checkVisibilityConditions(child),
              true,
          ) as unknown as boolean;
      }
      return result;
    },
  },
  computed: {
    ...mapState('app', ['breakpoints']),
    model: {
      get(): any {
        return this.modelValue;
      },
      set(data: any) {
        // this.$emit('update:modelValue', this.model);
      },
    },
    fieldsVisibilityC() {
      return this.fieldsVisibility;
    },
    selectedYear() {
      if (this.model.year) {
        return this.model.year;
      }
      return DateTime.utc().month < 9
        ? DateTime.utc().year
        : DateTime.utc().year + 1;
    },
  },
  watch: {
    model: {
      handler() {
        this.checkFieldsVisibility();
      },
      immediate: true,
      deep: true,
    },
    errors: {
      handler(errors: { [key: string]: string }) {
        if (Object.keys(errors).length > 0) {
          // eslint-disable-next-line no-unused-expressions
          document
              .getElementById(`${Object.keys(errors)[0]}-field`)
              ?.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              });
        }
      },
      immediate: false,
      deep: true,
    },
  },
});
