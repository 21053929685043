import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8cdf2ac8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "settings-extra-services-view",
  class: "settings-view view"
}
const _hoisted_2 = { class: "header p-5" }
const _hoisted_3 = { class: "spi-arrow-left pr-1 align-items-center" }
const _hoisted_4 = { class: "flex tw-gap-4 align-items-center" }
const _hoisted_5 = { class: "mt-1" }
const _hoisted_6 = {
  key: 1,
  class: "extra-services mt-0 p-5"
}
const _hoisted_7 = { class: "grid" }
const _hoisted_8 = { class: "extra-service" }
const _hoisted_9 = { class: "left" }
const _hoisted_10 = { class: "wrapper" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "name-price" }
const _hoisted_13 = { class: "name" }
const _hoisted_14 = { class: "price" }
const _hoisted_15 = { class: "cost" }
const _hoisted_16 = {
  key: 0,
  class: "price-mode"
}
const _hoisted_17 = { class: "vat" }
const _hoisted_18 = { class: "flex flex-column mt-4" }
const _hoisted_19 = { class: "font-bold" }
const _hoisted_20 = { class: "flex-inline align-items-center" }
const _hoisted_21 = { class: "flex-inline align-items-center" }
const _hoisted_22 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Skeleton = _resolveComponent("Skeleton")!
  const _component_Chip = _resolveComponent("Chip")!
  const _component_SettingsServiceDialog = _resolveComponent("SettingsServiceDialog")!
  const _component_SettingsServiceGroupDialog = _resolveComponent("SettingsServiceGroupDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", _hoisted_2, [
      (_ctx.currentServiceGroup)
        ? (_openBlock(), _createBlock(_component_router_link, {
            key: 0,
            to: "../service-groups"
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('settingsExtraServicesView.serviceGroupList')), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h6", _hoisted_5, _toDisplayString(_ctx.mainTitle), 1),
        (_ctx.currentServiceGroup)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 0,
              label: _ctx.$t('button.edit'),
              icon: "pi pi-pencil",
              class: "p-button-outlined",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showServiceGroupDialog = true))
            }, null, 8, ["label"]))
          : _createCommentVNode("", true),
        (_ctx.currentServiceGroup)
          ? (_openBlock(), _createBlock(_component_Button, {
              key: 1,
              disabled: _ctx.extraServices.length > 0,
              label: _ctx.$t('button.delete'),
              icon: "pi pi-trash",
              class: "p-button-danger p-button-outlined",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDeleteServiceGroup()))
            }, null, 8, ["disabled", "label"]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("p", null, _toDisplayString(_ctx.getSubtitle()), 1),
      _createVNode(_component_Button, {
        label: _ctx.getCreateButtonLabel(),
        icon: "spi-plus",
        class: "p-button mt-3",
        onClick: _ctx.onAddService
      }, null, 8, ["label", "onClick"])
    ]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_Skeleton, {
          key: 0,
          width: "100%",
          height: "220px"
        }))
      : (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.extraServices, (service, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "col-12 md:col-6 lg:col-12 xl:col-6"
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("header", null, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("img", {
                          class: "image",
                          src: _ctx.$spiagge.utils.service.getServiceImage(service),
                          alt: "service"
                        }, null, 8, _hoisted_11),
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, _toDisplayString(service.name), 1),
                          _createElementVNode("div", _hoisted_14, [
                            _createElementVNode("span", _hoisted_15, _toDisplayString(service.cost) + " €", 1),
                            (!_ctx.hasTablePriceMode(service.priceMode))
                              ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                                  _createElementVNode("span", null, _toDisplayString(_ctx.getPriceModeLabel(service.priceMode)), 1)
                                ]))
                              : _createCommentVNode("", true),
                            _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t('settingsExtraServicesView.vat')) + " " + _toDisplayString(service.vat) + "%", 1)
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t('settingsExtraServicesView.canBePurchased')) + ": ", 1),
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.offline')) + ":", 1),
                      _createVNode(_component_Chip, {
                        label: _ctx.$t('settingsExtraServicesView.withSpot'),
                        icon: _ctx.getChipIconClass(service.reservationSchedule),
                        class: _normalizeClass({ active: service.reservationSchedule })
                      }, null, 8, ["label", "icon", "class"]),
                      (_ctx.isTicket())
                        ? (_openBlock(), _createBlock(_component_Chip, {
                            key: 0,
                            label: _ctx.$t('settingsExtraServicesView.singleTicket'),
                            icon: _ctx.getChipIconClass(service.canBeSoldAloneOffline),
                            class: _normalizeClass({ active: service.canBeSoldAloneOffline })
                          }, null, 8, ["label", "icon", "class"]))
                        : _createCommentVNode("", true),
                      _createVNode(_component_Chip, {
                        label: _ctx.$t('settingsExtraServicesView.fastCash'),
                        icon: _ctx.getChipIconClass(service.fastCash),
                        class: _normalizeClass({ active: service.fastCash })
                      }, null, 8, ["label", "icon", "class"])
                    ]),
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('common.online')) + ":", 1),
                      _createVNode(_component_Chip, {
                        label: _ctx.$t('settingsExtraServicesView.withSpot'),
                        icon: _ctx.getChipIconClass(service.online),
                        class: _normalizeClass({ active: service.online })
                      }, null, 8, ["label", "icon", "class"]),
                      (_ctx.isTicket())
                        ? (_openBlock(), _createBlock(_component_Chip, {
                            key: 0,
                            label: _ctx.$t('settingsExtraServicesView.singleTicket'),
                            icon: _ctx.getChipIconClass(service.canBeSoldAloneOnline),
                            class: _normalizeClass({ active: service.canBeSoldAloneOnline })
                          }, null, 8, ["label", "icon", "class"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _createElementVNode("footer", _hoisted_22, [
                    _createVNode(_component_Button, {
                      icon: "pi pi-pencil",
                      class: "p-button-primary p-button-outlined p-button-sm",
                      onClick: ($event: any) => (_ctx.onUpdateService(service)),
                      label: _ctx.$t('button.edit')
                    }, null, 8, ["onClick", "label"]),
                    (!_ctx.isTicket())
                      ? (_openBlock(), _createBlock(_component_Button, {
                          key: 0,
                          icon: "pi pi-trash",
                          class: "p-button-danger p-button-outlined p-button-sm",
                          onClick: ($event: any) => (_ctx.onDeleteService(service.id)),
                          label: _ctx.$t('button.delete')
                        }, null, 8, ["onClick", "label"]))
                      : _createCommentVNode("", true)
                  ])
                ])
              ]))
            }), 128))
          ])
        ])),
    _createVNode(_component_SettingsServiceDialog, {
      visible: _ctx.showDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showDialog) = $event)),
      "show-dialog": _ctx.showDialog,
      "model-value": _ctx.serviceModel,
      "price-list-options": _ctx.priceListOptions,
      "service-id": _ctx.serviceId,
      "service-type": _ctx.serviceType,
      "default-service-group": _ctx.currentServiceGroup,
      onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showDialog = false)),
      onSubmitForm: _ctx.onSubmitServiceForm
    }, null, 8, ["visible", "show-dialog", "model-value", "price-list-options", "service-id", "service-type", "default-service-group", "onSubmitForm"]),
    _createVNode(_component_SettingsServiceGroupDialog, {
      visible: _ctx.showServiceGroupDialog,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showServiceGroupDialog) = $event)),
      "show-dialog": _ctx.showServiceGroupDialog,
      "model-value": _ctx.toSaveServiceGroup,
      "group-id": _ctx.currentServiceGroup?.id ?? 0,
      onClose: _cache[5] || (_cache[5] = ($event: any) => (_ctx.showServiceGroupDialog = false)),
      onSubmitForm: _ctx.onSubmitGroupForm
    }, null, 8, ["visible", "show-dialog", "model-value", "group-id", "onSubmitForm"])
  ]))
}