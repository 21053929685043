import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "statistics-sectors-view",
  class: "statistics-view view"
}
const _hoisted_2 = { id: "table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatisticsDataTable = _resolveComponent("StatisticsDataTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createVNode(_component_StatisticsDataTable, {
        id: _ctx.id,
        columns: _ctx.columns,
        searchColumns: _ctx.searchColumns,
        defaultView: _ctx.defaultView,
        endpoint: _ctx.endpoint,
        basePayload: _ctx.basePayload,
        paginate: false,
        serverSorting: false,
        exportEndpoint: _ctx.exportEndpoint,
        totalsRow: ""
      }, null, 8, ["id", "columns", "searchColumns", "defaultView", "endpoint", "basePayload", "exportEndpoint"])
    ])
  ]))
}