import { DateTime } from 'luxon';
import { Timestamp } from '@/models';

export const TIMEZONE_OFFSET = new Date().getTimezoneOffset() * 60000;

export default {
  isToday(d: number): boolean {
    const date = new Date(d);
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  },
  fromMillis(date: number, format = 'dd-MM-yyyy'): string {
    if (date.toString().length === 10) {
      // eslint-disable-next-line no-param-reassign
      date *= 1000;
    }
    return DateTime.fromMillis(date).toFormat(format);
  },
  fromSeconds(timestamp: Timestamp, format = 'dd-MM-yyyy'): string {
    return DateTime.fromSeconds(timestamp).toFormat(format);
  },
  // FIXME: Usare date corrette da impostazioni licenza
  // da smettere di usare -> usare seasonalDates() dentro index.ts
  seasonDate(): Array<DateTime> {
    let { year } = DateTime.now();
    if (new Date() > new Date(year, 8, 15)) {
      year += 1;
    }
    return [DateTime.utc(year, 4, 1), DateTime.utc(year, 8, 15)];
  },
  withoutTime(d: number): number {
    const date = new Date(d);
    return date.setHours(0, 0, 0, 0);
  },
  isSameDay(d1: number, d2: number): boolean {
    return (
      new Date(d1).setHours(0, 0, 0, 0) === new Date(d2).setHours(0, 0, 0, 0)
    );
  },
  /**
   * Get next day
   * @param d {number}
   * @return {number} Return next day in milliseconds
   */
  nextDay(d: number): number {
    return new Date(new Date(d).setHours(0, 0, 0, 0)).getTime() + 86400000;
  },
  /**
   * Get days number between two dates
   * @param d1 {number}
   * @param d2 {number}
   * @return {number} Return total days
   */
  totalDays(d1: number, d2: number): number {
    return Math.ceil(Math.abs(d2 - d1) / 86400000) + 1;
  },

  isLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  },
};
