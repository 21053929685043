export enum ActionPermissionType {
  ALLOWED = 'allowed',
  NOT_ALLOWED = 'not_allowed',
  NOT_INCLUDED = 'not_included',
}

export enum FeaturePermissionType {
  INCLUDED = 'included',
  NOT_INCLUDED = 'not_included',
  NOT_ALLOWED = 'not_allowed',
}

export interface ActionPermission {
  id: number;
  name: string;
  active: boolean;
  permissionType: ActionPermissionType;
}

export interface FeaturePermission {
  id: number;
  name: string;
  active: boolean;
  actions: ActionPermission[];
  permissionType: FeaturePermissionType;
}

export interface RolePermission {
  id: number;
  name: string;
  licenseCode: string;
  active: boolean;
  custom: boolean;
  features?: FeaturePermission[];
}

export interface RoleAdd {
  name: string;
  active: boolean;
}

export interface RoleUpdate {
  name: string;
  active: boolean;
  actionsIdList: number[];
}

export interface StoreAction {
  [featureAction: string]: ActionPermissionType;
}

interface StoreFeature {
  permissionType: FeaturePermissionType;
  actions: StoreAction;
}

export interface StorePermission {
  [feature: string]: StoreFeature;
}

enum MapFeatureActionPermissionList {
  PAGE_ACCESS = 'page_access',
  ADD_UPDATE_RESERVATIONS = 'add_update_reservations',
  SHOW_MINI_PLANNER = 'show_mini_planner',
  SMART_SEARCH = 'smart_search',
}

enum PlanningFeatureActionPermissionList {
  PAGE_ACCESS = 'page_access',
  ADD_UPDATE_RESERVATIONS = 'add_update_reservations',
}

enum ReservationsFeatureActionPermissionList {
  PAGE_ACCESS = 'page_access',
  UPDATE = 'update',
  SHOW_PAYMENT_DETAILS = 'show_payment_details',
  UPDATE_PRICES = 'update_prices',
  VOUCHER_SELECTION = 'voucher_selection',
  VOUCHER_HOTEL = 'voucher_hotel',
  PAY_WITHOUT_PRINT = 'pay_without_print',
  TAX_PRINT = 'tax_print',
  UPDATE_CASH_FLOWS = 'update_cash_flows',
  MOVE_TRASH = 'move_trash',
  PERMANENT_DELETE = 'permanent_delete',
  REFUNDS_AUTH = 'refunds_auth',
  LINK_EMAIL_WA = 'link_email_wa',
  MERGE_BILL = 'merge_bill',
  MOVE = 'move',
  INVOICE_ISSUE = 'invoice_issue',
  CHECK_IN = 'check_in',
  CHECK_OUT = 'check_out',
}

enum StatsFeatureActionPermissionList {
  PAGE_ACCESS = 'page_access',
  SHOW_ARRIVALS_DEPARTURES = 'show_arrivals_departures',
  EDIT_DISPLAY_COLUMNS = 'edit_display_columns',
  EXPORT_STATISTICHE = 'export',
  SHOW_TOTAL_CARDS = 'show_total_cards',
  RECAPS_BEACH_RESORT = 'recaps_beach_resort',
  STATS_BEACH_RESORT = 'stats_beach_resort',
  STATS_ONLINE_BOOKINGS = 'stats_online_bookings',
  STATS_STRIPE = 'stats_stripe',
  COUNTERS_VOUCHER_HOTEL = 'counters_voucher_hotel',
  SHOW_CASH_FLOWS_STATS = 'show_cash_flows_stats',
  SHOW_LOG = 'show_log',
}

enum BookingFeatureActionPermissionList {
  PAGE_ACCESS = 'page_access',
  ADD_UPDATE_DISCOUNT_CODES = 'add_update_discount_codes',
  ADD_UPDATE_OFFERS = 'add_update_offers',
}

enum SettingsFeatureActionPermissionList {
  PAGE_ACCESS = 'page_access',

  ADMIN_SETTINGS_PAGE_ACCESS = 'admin_settings_page_access',
  ADMIN_SETTINGS_ADD_USER = 'admin_settings_add_user',
  ADMIN_SETTINGS_CHANGE_USER_PASSWORD = 'admin_settings_change_user_password',
  ADMIN_SETTINGS_DELETE_USER = 'admin_settings_delete_user',

  BOOKING_SETTINGS_SHARING_BEACH_UMBRELLA = 'booking_settings_sharing_beach_umbrella',
  DATABASE_SETTINGS_IMPORT_EXPORT = 'database_settings_import_export',
  ROLE_SETTINGS_PAGE_ACCESS = 'role_settings_page_access',
  ROLE_SETTINGS_UPDATE_ROLE = 'role_settings_update_role',
  TERMINAL_POS_SETTINGS_PAGE_ACCESS = 'terminal_pos_settings_page_access',
}

enum PriceListFeatureActionPermissionList {
  PAGE_ACCESS = 'page_access',
}

enum QrScanFeatureActionPermissionList {
  PAGE_ACCESS = 'page_access',
}

enum CustomerFeatureActionPermissionList {
  PAGE_ACCESS = 'page_access',
  IMPORT_EXPORT = 'import_export',
  CUSTOMER_STATS = 'customer_stats',
  CARD_MANAGEMENT = 'card_management',
}

enum CashPointFeatureActionPermissionList {
  PAGE_ACCESS = 'page_access',
}

enum RevenueManagerFeatureActionPermissionList {
  MANAGER_PANEL = 'manager_panel',
  ANALYST_PANEL = 'analyst_panel',
}

enum PosFeatureActionPermissionList {
  SHOW_BUTTON_POS = 'show_button_pos',
}

enum WhiteLabelActionPermissionList {
  WHITE_LABEL_BUTTON_ENABLED = 'white_label_button_enabled',
  WHITE_LABEL_EDITOR_ROLE = 'white_label_editor_role',
  WHITE_LABEL_DESIGNER_ROLE = 'white_label_designer_role',
}

export const FEATURE_PERMISSION_ACTION_CONFIG = {
  map: MapFeatureActionPermissionList,
  planning: PlanningFeatureActionPermissionList,
  reservations: ReservationsFeatureActionPermissionList,
  stats: StatsFeatureActionPermissionList,
  booking: BookingFeatureActionPermissionList,
  settings: SettingsFeatureActionPermissionList,
  price_lists: PriceListFeatureActionPermissionList,
  qr_scan: QrScanFeatureActionPermissionList,
  customers: CustomerFeatureActionPermissionList,
  cash_point: CashPointFeatureActionPermissionList,
  revenue_manager: RevenueManagerFeatureActionPermissionList,
  pos: PosFeatureActionPermissionList,
  white_label: WhiteLabelActionPermissionList,
};

export const FEATURE_PERMISSION_CONFIG = {
  map: 'map',
  planning: 'planning',
  reservations: 'reservations',
  stats: 'stats',
  booking: 'booking',
  settings: 'settings',
  price_lists: 'price_lists',
  qr_scan: 'qr_scan',
  customers: 'customers',
  cash_point: 'cash_point',
  revenue_manager: 'revenue_manager',
  pos: 'pos',
  white_label: 'white_label',
};

export const DEFAULT_NAME_BASE_ALLOWED_FEATURE = 'page_access';

export enum DefaultRoles {
  SUPERUSER = 'superuser',
  MANAGER = 'manager',
  ANALYST = 'analyst',
  CASH_POINT_CLERK = 'cash_point_clerk',
  BEACH_ASSISTANT = 'beach_assistant',
  STATISTICS_COLLABORATOR = 'statistics_collaborator',
  REVENUE_MANAGER = 'revenue_manager',
}
