import axios, { AxiosResponse } from 'axios';
import {
    ApiSectorListResponse,
    ApiSectorListPayload,
    ApiSectorCreatePayload,
    ApiSectorCreateResponse,
    ApiSectorUpdatePayload,
    ApiSectorUpdateResponse,
    ApiSectorDeleteResponse,
    ApiSectorUploadImageResponse,
    ApiSectorDetailsUpdatePayload,
    ApiGetPlannerSectorsResponse,
} from '@/models/api';
import apiService from './apiService';
import { Sector, SectorDetail, SectorHeader } from '@/models/sector';

export default {
    async list(getSectorsPayload: ApiSectorListPayload):
        Promise<Array<Sector>> {
        const res: AxiosResponse<ApiSectorListResponse> = await axios.get(
            apiService.sectors.get(getSectorsPayload),
        );
        return res.data.result.sectors;
    },
    async create(createSectorPayload: ApiSectorCreatePayload): Promise<SectorHeader> {
        const res: AxiosResponse<ApiSectorCreateResponse> = await axios.post(
            apiService.sectors.create(),
            createSectorPayload,
        );
        return res.data.result.sector;
    },
    async update(id: number, updateSectorPayload: ApiSectorUpdatePayload): Promise<SectorHeader> {
        const res: AxiosResponse<ApiSectorUpdateResponse> = await axios.patch(
            apiService.sectors.update(id),
            updateSectorPayload,
        );
        return res.data.result.sector;
    },
    async delete(id: number): Promise<boolean> {
        const res: AxiosResponse<ApiSectorDeleteResponse> = await axios.delete(
            apiService.sectors.delete(id));
        return res.data.result.deleted;
    },
    async updateDetails(id: number, detailsPayload: ApiSectorDetailsUpdatePayload):
        Promise<Array<SectorDetail>> {
        const res: AxiosResponse<any> = await axios.patch(
            apiService.sectors.updateDetails(id),
            detailsPayload);
        return res.data.result.sectorDetails;
    },
    async uploadImage(file: unknown): Promise<string> {
        const config = {
            headers: { 'content-type': 'multipart/form-data' },
        };
        const res: AxiosResponse<ApiSectorUploadImageResponse> = await axios.post(
            apiService.sectors.uploadImage(),
            file,
            config,
        );
        return res.data.result.url;
    },
    async getPlannerSectors(): Promise<Array<number>> {
        const res: AxiosResponse<ApiGetPlannerSectorsResponse> = await axios.get(
            apiService.sectors.getPlannerSectors(),
        );
        return res.data.result.sectorIds;
    },
};
