import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-51085e2d"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "field-price-config-threshold-percentage-values",
  class: "dynamic-form-field"
}
const _hoisted_2 = {
  key: 0,
  class: "thresholds"
}
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Message = _resolveComponent("Message")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.thresholdPercentageValues.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.thresholdPercentageValues, (thresholdPercentageValue, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "p-field threshold-field"
            }, [
              _createElementVNode("label", {
                for: `threshold-${thresholdPercentageValue.threshold}-input`
              }, _toDisplayString(_ctx.$t('fieldPriceConfigThresholdPercentageValues.packageDiscount')) + " +" + _toDisplayString(thresholdPercentageValue.threshold), 9, _hoisted_3),
              _createVNode(_component_InputNumber, {
                id: `threshold-${thresholdPercentageValue.threshold}-input`,
                modelValue: _ctx.thresholdPercentageValues[index].value,
                suffix: " %",
                min: 0,
                max: 100,
                allowEmpty: false,
                onInput: ($event: any) => (_ctx.onInput($event, thresholdPercentageValue))
              }, null, 8, ["id", "modelValue", "onInput"])
            ]))
          }), 128))
        ]))
      : (_openBlock(), _createBlock(_component_Message, {
          key: 1,
          severity: "info",
          closable: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('fieldPriceConfigThresholdPercentageValues.noPackageMessage')), 1)
          ]),
          _: 1
        }))
  ]))
}