import { DateTime } from 'luxon';
import i18n from '@/i18n';
import { User } from './user';
import { Reservation } from './reservation';

export enum CashFlowMethod {
  OTHER = 0, // only here, not backend
  CASH = 1,
  BANCOMAT = 2,
  CREDIT_CARD = 3,
  PAYPAL = 9,
  SATISPAY = 10,
  LOYALTY_CARD = 11,
  BANK_TRANSFER = 12,
  CHEQUE = 13,
  STRIPE = 14,
  YB_CARD = 15,
  POS_STRIPE = 17,
}

export enum CashFlowEditPrinter {
  NOT_PRINTED = 0,
  INVOICE = -1,
}

export interface CashFlow {
  id: number;
  reservationId: number;
  userId: number;
  method: CashFlowMethod;
  amount: number;
  date: number;
  deleted: boolean;
  onlinePaymentFees: number | null;
  stripeChargeId: string | null;
  invoiceId: number | null;
  receiptId: number | null;
  notes: string;
  createdAt: number;
  updatedAt: number;
  user?: User;
  reservation?: Reservation;
  // expand receipt
  receipt?: any;
}

export interface CashFlowUpdateModel {
  id: number;
  date: DateTime;
  method: CashFlowMethod;
  fiscal: boolean;
  amount: number;
  notes: string;
  receipt: any;
}

export interface CashFlowEditPrinterOption {
  label: string;
  value: number;
}

export interface CashFlowMethodOption {
  value: CashFlowMethod;
  label: string;
  icon: string;
}

export const CASH_FLOW_ANALYTICS_PRINTER_OPTIONS = [
  {
    name: i18n.global.t('cashFlow.printerOption.notPrinted'),
    id: CashFlowEditPrinter.NOT_PRINTED,
  },
  {
    name: i18n.global.t('cashFlow.printerOption.invoice'),
    id: CashFlowEditPrinter.INVOICE,
  },
];

export const CASH_FLOW_METHOD_OPTIONS: Array<CashFlowMethodOption> = [
  {
    value: CashFlowMethod.OTHER,
    label: i18n.global.t('paymentMethods.other'),
    icon: 'spi-document', // TODO
  },
  {
    value: CashFlowMethod.CASH,
    label: i18n.global.t('paymentMethods.cash'),
    icon: 'spi-document', // TODO
  },
  {
    value: CashFlowMethod.BANCOMAT,
    label: i18n.global.t('paymentMethods.bancomat'),
    icon: 'spi-document', // TODO
  },
  {
    value: CashFlowMethod.CREDIT_CARD,
    label: i18n.global.t('paymentMethods.creditCard'),
    icon: 'spi-document', // TODO
  },
  {
    value: CashFlowMethod.PAYPAL,
    label: i18n.global.t('paymentMethods.payPal'),
    icon: 'spi-document', // TODO
  },
  {
    value: CashFlowMethod.SATISPAY,
    label: i18n.global.t('paymentMethods.satispay'),
    icon: 'spi-document', // TODO
  },
  {
    value: CashFlowMethod.LOYALTY_CARD,
    label: i18n.global.t('paymentMethods.loyaltyCard'),
    icon: 'spi-document', // TODO
  },
  {
    value: CashFlowMethod.BANK_TRANSFER,
    label: i18n.global.t('paymentMethods.bankTransfer'),
    icon: 'spi-document', // TODO
  },
  {
    value: CashFlowMethod.CHEQUE,
    label: i18n.global.t('paymentMethods.cheque'),
    icon: 'spi-document', // TODO
  },
  {
    value: CashFlowMethod.STRIPE,
    label: i18n.global.t('paymentMethods.stripe'),
    icon: 'spi-document', // TODO
  },
  {
    value: CashFlowMethod.YB_CARD,
    label: i18n.global.t('paymentMethods.ybCard'),
    icon: 'spi-document', // TODO
  },
  {
    value: CashFlowMethod.POS_STRIPE,
    label: i18n.global.t('paymentMethods.posStripe'),
    icon: 'spi-document', // TODO
  },
];
