/**
 * Database service
 */

import axios, { AxiosResponse } from 'axios';
import { ApiDatabaseCreateResponse, ApiDatabaseShowAllResponse, ApiDeleteReservationsResultResponse, DeleteReservationsGroupPayload } from '@/models/api';
import { PermissionMode } from '@/models/permissionMode';
import apiService from '@/services/apiService';
import { Reservations } from '@/models/booking';
import { Reservation } from '@/models/reservation';

export default {
  async showAll(): Promise<ApiDatabaseShowAllResponse> {
    const res: AxiosResponse<ApiDatabaseShowAllResponse> = await axios.get(
      apiService.database.showAll(),
    );
    return res.data;
  },
  async create(payload: PermissionMode):Promise<PermissionMode> {
    const res: AxiosResponse<ApiDatabaseCreateResponse> = await axios.patch(
      apiService.database.create(),
      payload,
      {
        headers: {
          'Content-Type': 'application/json ',
        },
      },
    );
    return res.data.result.mode;
  },
  async deleteReservationsGroup(payload: DeleteReservationsGroupPayload)
    : Promise<Array<Reservation>> {
    const res: AxiosResponse<ApiDeleteReservationsResultResponse> = await axios.post(
      apiService.database.deleteReservationsGroup(),
      payload,
    );
    return res.data.result.reservations;
  },
};
