import axios from 'axios';
import apiService from './apiService';
import { ApiWhiteLabelUserResponse } from '@/models/api';

export default {
  async linkUserToWhiteLabel(): Promise<ApiWhiteLabelUserResponse> {
    const res = await axios.post(apiService.whiteLabel.linkUserToWhiteLabel());

    return res.data.result;
  },
};
